import get from "lodash.get";
import type { AnyAction } from "redux";
import {
    CLEAR_CAMPAIGNS_FILTERS,
    RECEIVE_CAMPAIGNS,
    RECEIVE_UPSTREAM_CAMPAIGNS,
    REQUEST_CAMPAIGNS,
    REQUEST_UPSTREAM_CAMPAIGNS,
    UPDATE_CAMPAIGNS_FILTER_END_DATE,
    UPDATE_CAMPAIGNS_FILTER_HOLIDAY,
    UPDATE_CAMPAIGNS_FILTER_PRODUCT,
    UPDATE_CAMPAIGNS_FILTER_REGION,
    UPDATE_CAMPAIGNS_FILTER_RETAILER,
    UPDATE_CAMPAIGNS_FILTER_START_DATE,
    UPDATE_CAMPAIGNS_SEARCH,
    UPDATE_CAMPAIGNS_SORT,
    UPDATE_CAMPAIGNS_START_INDEX,
    UPDATE_CAMPAIGNS_SUBTAB,
    UPDATE_UPSTREAM_CAMPAIGNS_SORT,
    UPDATE_UPSTREAM_CAMPAIGNS_START_INDEX
} from "campaign/state/campaignsActions";
import {
    CAMPAIGN_STATE_DRAFT,
    defaultCampaignsState,
    defaultFiltersState
} from "campaign/state/campaignStateUtils";
import type { CampaignsState } from "campaign/state/campaignStateUtils";
import { CAMPAIGNS_PAGE } from "shell/state/pageActions";

function campaigns(
    state: CampaignsState = defaultCampaignsState,
    action: AnyAction
) {
    const subTab = state.subTab;
    switch (action.type) {
        case CAMPAIGNS_PAGE: {
            let sTab =
                action.payload &&
                action.payload.subTab &&
                action.payload.subTab.toUpperCase();
            if (!sTab) {
                sTab = CAMPAIGN_STATE_DRAFT;
            }
            return Object.assign({}, state, {
                subTab: sTab
            });
        }
        case CLEAR_CAMPAIGNS_FILTERS:
            return Object.assign({}, state, {
                [subTab]: {
                    ...state[subTab],
                    filters: defaultFiltersState.filters
                }
            });
        case REQUEST_CAMPAIGNS:
            return state;
        case RECEIVE_CAMPAIGNS:
            return Object.assign({}, state, {
                [subTab]: {
                    ...state[subTab],
                    totalCount: get(action, "campaigns.totalCount")
                }
            });
        case UPDATE_CAMPAIGNS_FILTER_END_DATE:
            return Object.assign({}, state, {
                [subTab]: {
                    ...state[subTab],
                    filters: {
                        ...state[subTab].filters,
                        endDate: action.endDate
                    }
                }
            });
        case UPDATE_CAMPAIGNS_FILTER_HOLIDAY:
            return Object.assign({}, state, {
                [subTab]: {
                    ...state[subTab],
                    filters: {
                        ...state[subTab].filters,
                        holidays: action.holidays
                    }
                }
            });
        case UPDATE_CAMPAIGNS_FILTER_PRODUCT:
            return Object.assign({}, state, {
                [subTab]: {
                    ...state[subTab],
                    filters: {
                        ...state[subTab].filters,
                        products: action.products
                    }
                }
            });
        case UPDATE_CAMPAIGNS_FILTER_REGION:
            return Object.assign({}, state, {
                [subTab]: {
                    ...state[subTab],
                    filters: {
                        ...state[subTab].filters,
                        regions: action.regions
                    }
                }
            });
        case UPDATE_CAMPAIGNS_FILTER_RETAILER:
            return Object.assign({}, state, {
                [subTab]: {
                    ...state[subTab],
                    filters: {
                        ...state[subTab].filters,
                        retailers: action.retailers
                    }
                }
            });
        case UPDATE_CAMPAIGNS_FILTER_START_DATE:
            return Object.assign({}, state, {
                [subTab]: {
                    ...state[subTab],
                    filters: {
                        ...state[subTab].filters,
                        startDate: action.startDate
                    }
                }
            });
        case UPDATE_CAMPAIGNS_START_INDEX:
            return Object.assign({}, state, {
                [subTab]: {
                    ...state[subTab],
                    startIndex: action.startIndex
                }
            });
        case UPDATE_CAMPAIGNS_SEARCH:
            return Object.assign({}, state, {
                [subTab]: {
                    ...state[subTab],
                    search: action.search
                }
            });
        case UPDATE_CAMPAIGNS_SORT:
            return Object.assign({}, state, {
                [subTab]: {
                    ...state[subTab],
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder
                }
            });
        case UPDATE_CAMPAIGNS_SUBTAB:
            return Object.assign({}, state, {
                subTab: action.subTab
            });

        case REQUEST_UPSTREAM_CAMPAIGNS:
            return state;
        case RECEIVE_UPSTREAM_CAMPAIGNS:
            return Object.assign({}, state, {
                upstreamCampaigns: {
                    ...state.upstreamCampaigns,
                    totalCount: get(action, "json.totalCount")
                }
            });
        case UPDATE_UPSTREAM_CAMPAIGNS_START_INDEX:
            return Object.assign({}, state, {
                upstreamCampaigns: {
                    ...state.upstreamCampaigns,
                    startIndex: action.startIndex
                }
            });
        case UPDATE_UPSTREAM_CAMPAIGNS_SORT:
            return Object.assign({}, state, {
                upstreamCampaigns: {
                    ...state.upstreamCampaigns,
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder
                }
            });

        default:
            return state;
    }
}

export default campaigns;
