import * as React from "react";
import type { TFunction } from "i18next";
import isFunction from "lodash.isfunction";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import Button, { BUTTON_TYPE_TERTIARY } from "common/components/Button";
import type { ButtonType } from "common/components/Button";
import ExportOptionSelector from "common/components/AgExportOptionSelector";
import type { ExportOption } from "common/components/AgExportOptionSelector";
import { marginPropTypes } from "common/components/styled/util";
import { ReactComponent as ExportIcon } from "common/icons/Export.svg";
import { actionTokenDownloadThunk } from "common/shell/state/fileActions";
import type {
    ActionTokenUrl,
    DownloadUrl,
    ExportInfo
} from "common/shell/state/fileActions";
import { info } from "common/shell/state/toastActions";
import { reduceObject } from "common/util/object";
import {
    EVENT_NAME_EXECUTE_ACTION,
    EVENT_VALUE_DOWNLOAD_EXPORT
} from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";
import { addUrlParameter } from "common/util/url";
import { composeTrackingComponentLabel } from "common/util/tracking";
import type { AppDispatch, RootState } from "store";

type ExportFileProps = {
    actionTokenUrl?: ActionTokenUrl;
    closeExportModal?: AnyFunction;
    disableExportButton?: AnyFunction;
    disableInfoMessage?: boolean;
    dispatch: AppDispatch;
    downloadUrl?: DownloadUrl;
    enableExportButton?: AnyFunction;
    exportData?: () => Promise<TokenResult | ResponseSuccessResult>;
    exportHandler?: AnyFunction;
    exportInfo?: ExportInfo;
    exportModalInfo?: AnyFunction;
    optionsChecked?: string[];
    showModal?: boolean;
    t: TFunction;
    useOTAT?: boolean;
};

// Helper for exporting files
export const exportFile = (props: ExportFileProps) => {
    const {
        actionTokenUrl,
        closeExportModal,
        disableExportButton,
        disableInfoMessage,
        dispatch,
        downloadUrl,
        enableExportButton,
        exportData,
        exportHandler,
        exportInfo,
        exportModalInfo,
        optionsChecked,
        showModal,
        t,
        useOTAT = true
    } = props;
    let exportFunc: (() => Promise<any>) | null = null;
    if (
        showModal &&
        exportModalInfo &&
        optionsChecked &&
        downloadUrl &&
        actionTokenUrl
    ) {
        const exportModalFunc = (state: RootState) => {
            return exportModalInfo(state, optionsChecked);
        };
        exportFunc = function () {
            return dispatch(
                actionTokenDownloadThunk(
                    actionTokenUrl,
                    exportModalFunc,
                    useOTAT
                        ? (token, state) => {
                              return generateOTATUrl(token, state, downloadUrl);
                          }
                        : downloadUrl
                )
            );
        };
    } else if (exportData) {
        exportFunc = exportData;
    } else {
        if (downloadUrl && actionTokenUrl) {
            exportFunc = function () {
                return dispatch(
                    actionTokenDownloadThunk(
                        actionTokenUrl,
                        exportInfo,
                        useOTAT
                            ? (token, state) => {
                                  return generateOTATUrl(
                                      token,
                                      state,
                                      downloadUrl
                                  );
                              }
                            : downloadUrl
                    )
                );
            };
        }
    }

    if (exportFunc) {
        if (disableExportButton) {
            disableExportButton();
        }
        exportFunc()
            .then(
                () => {
                    if (!disableInfoMessage) {
                        dispatch(
                            info(t("common:general.your_file_is_downloading"))
                        );
                    }
                    if (enableExportButton) {
                        enableExportButton();
                    }
                },
                () => {
                    if (enableExportButton) {
                        enableExportButton();
                    }
                }
            )
            .finally(() => {
                if (exportHandler) {
                    exportHandler();
                }
                if (closeExportModal) {
                    closeExportModal();
                }
            });
    }
};

const generateOTATUrl = (token: any, state: any, downloadUrl: DownloadUrl) => {
    const url = isFunction(downloadUrl)
        ? downloadUrl(token, state)
        : downloadUrl;
    return addUrlParameter(url, "otat", token.token);
};

/*  The following props are used for the export column selection modal:
    exportModalInfo
    exportOptions
    exportOptionsChecked
    modalMessage
    showModal

    These are used to pass a body to request. When using modal use exportModalInfo instead
    exportInfo
    exportData

    These are used to hit the token then download endpoints
    actionTokenURL
    downloadURL

    useOTAT defaulted to true but may be turned off. (one time authentication token)
*/
type ExportButtonOwnProps = {
    actionTokenUrl?: ActionTokenUrl;
    buttonType?: ButtonType;
    cssHeight?: string;
    cssWidth?: string;
    disabled?: boolean;
    disableInfoMessage?: boolean;
    downloadUrl?: DownloadUrl;
    exportData?: () => Promise<TokenResult | ResponseSuccessResult>;
    exportHandler?: AnyFunction;
    exportInfo?: ExportInfo;
    exportModalInfo?: AnyFunction;
    exportName: string;
    exportOptions?: ExportOption[];
    exportOptionsChecked?: string[];
    modalMessage?: string;
    showModal?: boolean;
    text?: string;
    title?: string;
    trackingComponentLabel: TrackingComponentLabel;
    useOTAT?: boolean;
} & marginPropsType;

type ExportButtonProps = ExportButtonOwnProps &
    PropsFromRedux &
    WithTranslation;

type ExportButtonState = {
    disabled: boolean;
    openModal: boolean;
};

class ExportButton extends React.Component<
    ExportButtonProps,
    ExportButtonState
> {
    static displayName = "ExportButton";
    static defaultProps = {
        buttonType: BUTTON_TYPE_TERTIARY,
        disabled: false,
        useOTAT: true
    };

    state: ExportButtonState = {
        disabled: this.props.disabled || false,
        openModal: false
    };

    componentDidUpdate(prevProps: ExportButtonProps) {
        const disabled = this.props.disabled || false;
        if (disabled !== prevProps.disabled) {
            this.setState({
                disabled
            });
        }
    }

    // dispatch had to be moved inside so that the modal can pass back checked options
    export = (optionsChecked?: string[]) => {
        const {
            actionTokenUrl,
            disableInfoMessage,
            dispatch,
            downloadUrl,
            exportData,
            exportHandler,
            exportInfo,
            exportModalInfo,
            showModal,
            t,
            useOTAT
        } = this.props;
        const enableExportButton = () => this.setState({ disabled: false });
        const disableExportButton = () => this.setState({ disabled: true });
        exportFile({
            actionTokenUrl,
            closeExportModal: this.closeExportModal,
            disableExportButton,
            disableInfoMessage,
            dispatch,
            downloadUrl,
            enableExportButton,
            exportData,
            exportHandler,
            exportInfo,
            exportModalInfo,
            optionsChecked,
            showModal,
            t,
            useOTAT
        });
    };

    closeExportModal = () => {
        this.setState({ openModal: false });
    };

    openExportModal = () => {
        this.setState({ openModal: true });
    };

    render() {
        const {
                buttonType,
                cssHeight,
                cssWidth,
                exportName,
                exportOptions,
                exportOptionsChecked,
                modalMessage,
                showModal,
                t,
                text,
                trackingComponentLabel
            } = this.props,
            { disabled, openModal } = this.state;

        let { title } = this.props;
        if (!title) {
            title = t("common:general.export");
        }
        let height = cssHeight;
        if (!height) {
            height = "36px";
        }
        const onExportClick = showModal ? this.openExportModal : this.export;
        const exportButton = (
            <Button
                cssHeight={height}
                cssWidth={cssWidth}
                disabled={disabled}
                icon={ExportIcon}
                onClick={onExportClick}
                text={text}
                title={title}
                trackingComponentLabel={composeTrackingComponentLabel([
                    trackingComponentLabel,
                    ExportButton.displayName
                ])}
                trackingEventName={EVENT_NAME_EXECUTE_ACTION}
                trackingEventValue={EVENT_VALUE_DOWNLOAD_EXPORT}
                type={buttonType}
                {...reduceObject(this.props, ...marginPropTypes)}
            />
        );

        return (
            <React.Fragment>
                {exportButton}
                {showModal && exportOptions && (
                    <ExportOptionSelector
                        closeModalHandler={this.closeExportModal}
                        exportHandler={this.export}
                        exportName={exportName}
                        exportOptions={exportOptions}
                        exportOptionsChecked={exportOptionsChecked}
                        isOpen={openModal}
                        subText={modalMessage}
                        trackingComponentLabel={trackingComponentLabel}
                    />
                )}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        dispatch: dispatch
    };
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(ExportButton));
