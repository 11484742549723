import type { Action } from "redux";
import type { LOGGED_OUT } from "common/auth/state/commonAuthActions";
import {
    appRoot,
    loggedOut,
    loggedOutAndAppRoot
} from "common/auth/state/commonAuthActions";
import { initializationError } from "common/shell/state/initActions";
import {
    displayError,
    makeRequestThunk,
    METHOD_POST,
    METHOD_PUT
} from "common/shell/state/requestActions";
import type { ShowToast } from "common/shell/state/toastActions";
import type { Cookies, LocalTenant, Question } from "common/shell/util/types";
import { getTrackingEventData, trackEvent } from "common/util/tracking";
import { EVENT_NAME_LOGGED_IN } from "common/util/trackingEvents";
import { isSecure } from "common/util/url";
import type { ActionOrThunk, Actions, RootState } from "store";

// cookie functions
const ONE_YEAR = 365 * 24 * 60 * 60 * 1000;
export const AUTH_TOKEN_COOKIE = "authToken_manufacturer";
export const USERNAME_COOKIE = "username";
export const PATH = "/";

const setCookie = (
    cookies: Cookies,
    name: string,
    value: string,
    expires?: Date
) => {
    const options = {
        expires: expires,
        path: PATH,
        secure: isSecure()
    };
    cookies.set(name, value, options);
};

const getAuthTokenFromCookie = (cookies: Cookies): string => {
    if (cookies) {
        return cookies.get(AUTH_TOKEN_COOKIE);
    }
    return "";
};

const setAuthTokenCookie = (cookies: Cookies, token: string) => {
    setCookie(cookies, AUTH_TOKEN_COOKIE, token);
};

export const getUserNameFromCookie = (cookies: Cookies): string => {
    return cookies.get(USERNAME_COOKIE);
};

export const removeUserNameCookie = (cookies: Cookies) => {
    cookies.remove(USERNAME_COOKIE, { path: PATH });
};

export const setUserNameCookie = (cookies: Cookies, token: string) => {
    setCookie(
        cookies,
        USERNAME_COOKIE,
        token,
        new Date(new Date().getTime() + ONE_YEAR)
    );
};

// action types
export const AUTHORIZATION_ERROR = "AUTHORIZATION_ERROR";
export const AUTHORIZATION_RENEWED = "AUTHORIZATION_RENEWED";
export const CHOOSE_TENANT = "CHOOSE_TENANT";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const PASSWORD_EMAIL_SENT = "PASSWORD_EMAIL_SENT";
export const PASSWORD_EXPIRED = "PASSWORD_EXPIRED";
export const SECURITY_QUESTION_REQUIRED = "SECURITY_QUESTION_REQUIRED";
export const SET_SECURITY_QUESTIONS = "SET_SECURITY_QUESTIONS";
export const TO_LOG_IN = "TO_LOG_IN";
export const ASK_SECURITY_QUESTION = "ASK_SECURITY_QUESTION";
export const RETRIEVE_PASSWORD = "RETRIEVE_PASSWORD";
export const RESET_PASSWORD_DIALOG = "RESET_PASSWORD_DIALOG";
export const CHANGE_PASSWORD_DIALOG = "CHANGE_PASSWORD_DIALOG";
export const CANCEL_CHANGE_PASSWORD = "CANCEL_CHANGE_PASSWORD";

export type AuthActions =
    | AskSecurityQuestion
    | AuthorizationRenewed
    | ChooseTenant
    | ErrorAction<typeof AUTHORIZATION_ERROR>
    | OptionalErrorAction<typeof CHANGE_PASSWORD_DIALOG>
    | OptionalErrorAction<typeof RESET_PASSWORD_DIALOG>
    | PasswordExpired
    | RetrievePasswordAction
    | SecurityQuestionRequired
    | SentPasswordEmail
    | SimpleAction<typeof CANCEL_CHANGE_PASSWORD>
    | SimpleAction<typeof CLEAR_ERROR>
    | SimpleAction<typeof FORGOT_PASSWORD>
    | SimpleAction<typeof LOGGED_OUT>
    | SimpleAction<typeof TO_LOG_IN>
    | SetSecurityQuestions;

interface RetrievePasswordAction extends Action {
    payload: {
        token: string;
    };
    type: typeof RETRIEVE_PASSWORD;
}

export const authorizationError = (
    error: string
): ErrorAction<typeof AUTHORIZATION_ERROR> => ({
    type: AUTHORIZATION_ERROR,
    error: error
});

interface AuthorizationRenewed extends Action {
    token: string;
    type: typeof AUTHORIZATION_RENEWED;
}

export const authorizationRenewed = (token: string): AuthorizationRenewed => ({
    type: AUTHORIZATION_RENEWED,
    token: token
});

interface ChooseTenant extends Action {
    tenants: LocalTenant[];
    token: string;
    type: typeof CHOOSE_TENANT;
}

export const chooseTenant = (
    token: string,
    tenants: LocalTenant[]
): ChooseTenant => ({
    type: CHOOSE_TENANT,
    token: token,
    tenants: tenants
});

export const clearError = (): SimpleAction<typeof CLEAR_ERROR> => ({
    type: CLEAR_ERROR
});

export const forgotPassword = (): SimpleAction<typeof FORGOT_PASSWORD> => ({
    type: FORGOT_PASSWORD
});

interface PasswordExpired extends Action {
    error: string;
    tenants: LocalTenant[];
    token: string;
    type: typeof PASSWORD_EXPIRED;
}

export const passwordExpired = (
    token: string,
    tenants: LocalTenant[],
    error: string
): PasswordExpired => ({
    type: PASSWORD_EXPIRED,
    error: error,
    token: token,
    tenants: tenants
});

interface SentPasswordEmail extends Action {
    email: string;
    type: typeof PASSWORD_EMAIL_SENT;
}

export const sentPasswordEmail = (email: string): SentPasswordEmail => ({
    type: PASSWORD_EMAIL_SENT,
    email: email
});

export const toLogIn = (): SimpleAction<typeof TO_LOG_IN> => ({
    type: TO_LOG_IN
});

const resetPasswordDialog = (
    error?: string | null
): OptionalErrorAction<typeof RESET_PASSWORD_DIALOG> => ({
    type: RESET_PASSWORD_DIALOG,
    error: error
});

export const changePasswordDialog = (
    error?: string | null
): OptionalErrorAction<typeof CHANGE_PASSWORD_DIALOG> => ({
    type: CHANGE_PASSWORD_DIALOG,
    error: error
});

export const cancelChangePassword = (): SimpleAction<
    typeof CANCEL_CHANGE_PASSWORD
> => ({
    type: CANCEL_CHANGE_PASSWORD
});

export const loggedIn = (token: string): AuthorizationRenewed => {
    trackEvent(
        getTrackingEventData("Action", "Login Dialog", EVENT_NAME_LOGGED_IN)
    );
    return authorizationRenewed(token);
};

interface SecurityQuestionRequired extends Action {
    questions: Question[];
    tenants: LocalTenant[];
    token: string;
    type: typeof SECURITY_QUESTION_REQUIRED;
}

export const securityQuestionRequired = (
    token: string,
    tenants: LocalTenant[],
    questions: Question[]
): SecurityQuestionRequired => ({
    type: SECURITY_QUESTION_REQUIRED,
    questions: questions,
    token: token,
    tenants: tenants
});

interface SetSecurityQuestions extends Action {
    questions: Question[];
    type: typeof SET_SECURITY_QUESTIONS;
}

export const setSecurityQuestions = (
    questions: Question[]
): SetSecurityQuestions => ({
    type: SET_SECURITY_QUESTIONS,
    questions: questions
});

interface AskSecurityQuestion extends Action {
    error?: string | null;
    questionId: string;
    question: string;
    type: typeof ASK_SECURITY_QUESTION;
}

const askSecurityQuestion = (
    questionId: string,
    question: string,
    error?: string | null
): AskSecurityQuestion => ({
    type: ASK_SECURITY_QUESTION,
    questionId: questionId,
    question: question,
    error: error
});

export const changePassword = (
    cookies: Cookies,
    oldPassword: string,
    newPassword: string,
    questionId: string,
    answer: string
) => {
    return makeRequestThunk("api/auth/changePassword", {
        body: {
            objectType: "ChangePasswordRequest",
            newPassword: newPassword,
            oldPassword: oldPassword,
            questionId: questionId,
            securityAnswer: answer
        },
        errorFunc: authorizationError,
        isCancellable: false,
        method: METHOD_POST,
        okDispatchFunc: (json: EmptyObject, state: RootState): Actions => {
            const authState = state.auth;
            if (authState.tenants.length > 0) {
                return chooseTenant(authState.token, authState.tenants);
            } else {
                setAuthTokenCookie(cookies, authState.token);
                return loggedIn(authState.token);
            }
        }
    });
};

type PasswordExpiredDto = {
    message: string;
} & AuthTokenDto;

type SecurityAnswerRequiredDto = {
    securityQuestions: Question[];
} & AuthTokenDto;

export const login = (cookies: Cookies, username: string, password: string) => {
    return makeRequestThunk("api/auth/login", {
        body: { username, password },
        bodyType: "queryString",
        checkLoggedOut: false,
        errorFunc: authorizationError,
        isCancellable: false,
        method: METHOD_POST,
        notOkDispatchFunc: (
            response: Response,
            json: PasswordExpiredDto,
            state: RootState
        ): Actions | undefined | null => {
            if (json.objectType === "PasswordExpiredDto") {
                return passwordExpired(
                    json.token,
                    json.additionalTenants,
                    json.message
                );
            }
        },
        okDispatchFunc: (
            json: SecurityAnswerRequiredDto,
            state: RootState
        ): Actions => {
            if (json.objectType === "SecurityAnswerRequiredDto") {
                setAuthTokenCookie(cookies, json.token);
                return securityQuestionRequired(
                    json.token,
                    json.additionalTenants,
                    json.securityQuestions
                );
            } else if (
                json.canAccessMultipleTenants &&
                json.additionalTenants
            ) {
                return chooseTenant(json.token, json.additionalTenants);
            } else {
                setAuthTokenCookie(cookies, json.token);
                return loggedIn(json.token);
            }
        }
    });
};

export const logout = () => {
    return makeRequestThunk("api/auth/logout", {
        isCancellable: false,
        method: METHOD_POST,
        okDispatchFunc: loggedOutAndAppRoot
    });
};

export const renewAuthorization = (cookies: Cookies) => {
    const authToken = getAuthTokenFromCookie(cookies);
    return makeRequestThunk("api/auth/renew", {
        authToken: authToken,
        errorFunc: initializationError,
        isCancellable: false,
        loggedOutFunc: loggedOut,
        method: METHOD_POST,
        okDispatchFunc: (json: AuthTokenDto, state: RootState) => {
            return authorizationRenewed(authToken);
        }
    });
};

export const securityQuestion = (questionId: string, answer: string) => {
    return makeRequestThunk("api/auth/securityQuestion", {
        body: {
            objectType: "SecurityQuestionRequest",
            securityQuestionId: questionId,
            answer: answer
        },
        errorFunc: authorizationError,
        isCancellable: false,
        method: METHOD_POST,
        okDispatchFunc: (json: EmptyObject, state: RootState): Actions => {
            const authState = state.auth;
            if (authState.tenants.length > 0) {
                return chooseTenant(authState.token, authState.tenants);
            } else {
                return loggedIn(authState.token);
            }
        }
    });
};

export const securityQuestions = () => {
    return makeRequestThunk("api/auth/securityQuestion", {
        errorFunc: authorizationError,
        isCancellable: false,
        okDispatchFunc: (json: Question[], state: RootState): Actions => {
            return setSecurityQuestions(json);
        }
    });
};

export const sendForgotPasswordEmail = (
    email: string,
    token: string | null
) => {
    return makeRequestThunk("api/auth/forgotPassword", {
        body: {
            email: email,
            token: token
        },
        errorFunc: authorizationError,
        isCancellable: false,
        method: METHOD_POST,
        okDispatchFunc: (json: SuccessDto, state: RootState): Actions => {
            return sentPasswordEmail(email);
        },
        okTest: (response: Response, json: SuccessDto): boolean => {
            return response.ok && json.success;
        }
    });
};

const tenant = (
    cookies: Cookies,
    tenantId: string,
    initialize: boolean,
    errorAction: (
        message: string
    ) => ErrorAction<typeof AUTHORIZATION_ERROR> | ShowToast
) => {
    return makeRequestThunk("api/auth/tenant/" + tenantId, {
        errorFunc: errorAction,
        isCancellable: false,
        method: METHOD_PUT,
        okDispatchFunc: (json: AuthTokenDto, state: RootState) => {
            setAuthTokenCookie(cookies, json.token);
            const actions: ActionOrThunk[] = [];
            if (initialize) {
                loggedOutAndAppRoot(json, state).forEach(a => actions.push(a));
            }
            actions.push(loggedIn(json.token));
            return actions;
        }
    });
};

export const changeTenant = (cookies: Cookies, tenantId: string) => {
    return tenant(cookies, tenantId, true, displayError);
};

export const selectTenant = (cookies: Cookies, tenantId: string) => {
    return tenant(cookies, tenantId, false, authorizationError);
};

export const retrievePassword = (token: string) => {
    return makeRequestThunk("api/auth/retrievePassword/" + token, {
        isCancellable: false,
        okDispatchFunc: (json: Question, state: RootState): Actions => {
            return askSecurityQuestion(json.entityId, json.question);
        },
        notOkDispatchFunc: (
            response: Response,
            json: SuccessDto,
            state: RootState
        ): Actions => {
            return askSecurityQuestion(
                state.auth.questionId,
                state.auth.question,
                json.message
            );
        }
    });
};

export const answerSecurityQuestion = (
    token: string,
    questionId: string,
    answer: string
) => {
    return makeRequestThunk("api/auth/answerQuestion/" + token, {
        method: METHOD_POST,
        body: {
            objectType: "SecurityQuestionRequest",
            securityQuestionId: questionId,
            answer: answer
        },
        isCancellable: false,
        preRequestFunc: clearError,
        okDispatchFunc: (json: EmptyObject, state: RootState): Actions => {
            return resetPasswordDialog("");
        },
        notOkDispatchFunc: (
            response: Response,
            json: SuccessDto,
            state: RootState
        ): Actions => {
            return askSecurityQuestion(
                state.auth.questionId,
                state.auth.question,
                json.message
            );
        }
    });
};

export const resetPassword = (
    cookies: Cookies,
    token: string,
    newPassword: string,
    confirmNewPassword: string
) => {
    return makeRequestThunk("api/auth/retrievePassword/" + token, {
        method: METHOD_POST,
        body: {
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword
        },
        isCancellable: false,
        preRequestFunc: clearError,
        okDispatchFunc: (json: AuthTokenDto, state: RootState): Actions => {
            const { token, canAccessMultipleTenants, additionalTenants } = json;
            const actions: ActionOrThunk[] = [];
            actions.push(appRoot());
            if (
                canAccessMultipleTenants &&
                additionalTenants &&
                additionalTenants.length
            ) {
                actions.push(chooseTenant(token, additionalTenants));
            } else {
                setAuthTokenCookie(cookies, token);
            }
            return actions;
        },
        notOkDispatchFunc: (
            response: Response,
            json: SuccessDto,
            state: RootState
        ): Actions => {
            return resetPasswordDialog(json.message);
        }
    });
};
