import type { TFunction } from "i18next";
import get from "lodash.get";
import type { AnyAction } from "redux";
import {
    openMessageBox,
    BUTTON_CANCELYES,
    BUTTON_YES,
    ICON_QUESTION,
    ICON_WARNING
} from "common/shell/state/messageBoxActions";
import {
    BODY_TYPE_FILE,
    formatSortOrderForQueryAPI,
    makeRequestThunk,
    METHOD_DELETE,
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT
} from "common/shell/state/requestActions";
import { processExportSuccess } from "common/shell/state/shellStateUtils";
import { success } from "common/shell/state/toastActions";
import {
    andFilterCondition,
    booleanFilterCondition,
    freeFormFilterCondition,
    orFilterCondition,
    stringFilterCondition
} from "common/util/filter";
import {
    ASCENDING_QUERY_API,
    PAGE_SIZE_UNLIMITED,
    sortCondition
} from "common/util/query";
import type { OfferExportField } from "promotions/components/ExportOffersModal";
import { reducePromotion } from "promotions/state/promotionStateUtils";
import { REPLACE_IMAGE_PAGE } from "shell/state/pageActions";
import type { ActionOrThunk, Actions, AppDispatch, RootState } from "store";

export const RECEIVE_PROMOTIONS = "RECEIVE_PROMOTIONS";
export const REQUEST_PROMOTIONS = "REQUEST_PROMOTIONS";
export const UPDATE_PROMOTIONS_CONTROL_OFFERS_ONLY =
    "UPDATE_PROMOTIONS_CONTROL_OFFERS_ONLY";
export const UPDATE_PROMOTIONS_LAYOUT_ISSUES_ONLY =
    "UPDATE_PROMOTIONS_LAYOUT_ISSUES_ONLY";
export const UPDATE_PROMOTIONS_SEARCH = "UPDATE_PROMOTIONS_SEARCH";
export const UPDATE_PROMOTIONS_SORT = "UPDATE_PROMOTIONS_SORT";

export const RECEIVE_CAMPAIGN_OFFERS = "RECEIVE_CAMPAIGN_OFFERS";
export const REQUEST_CAMPAIGN_OFFERS = "REQUEST_CAMPAIGN_OFFERS";
export const RECEIVE_CONTROL_OFFERS = "RECEIVE_CONTROL_OFFERS";
export const REQUEST_CONTROL_OFFERS = "REQUEST_CONTROL_OFFERS";
export const UPDATE_CAMPAIGN_OFFERS_SORT = "UPDATE_CAMPAIGN_OFFERS_SORT";
export const UPDATE_CONTROL_OFFERS_OFFSET = "UPDATE_CONTROL_OFFERS_OFFSET";
export const UPDATE_CONTROL_OFFERS_SORT = "UPDATE_CONTROL_OFFERS_SORT";

export const replaceImagePage = (entityId: string): AnyAction => ({
    type: REPLACE_IMAGE_PAGE,
    payload: {
        entityId
    }
});

const requestPromotions = (): AnyAction => ({
    type: REQUEST_PROMOTIONS
});

const receivePromotions = (json: Json): AnyAction => ({
    type: RECEIVE_PROMOTIONS,
    json: json
});

export const updatePromotionsControlOffersOnly = (
    controlOffersOnly: boolean
): AnyAction => {
    return {
        type: UPDATE_PROMOTIONS_CONTROL_OFFERS_ONLY,
        controlOffersOnly
    };
};

export const updatePromotionsLayoutIssuesOnly = (
    layoutIssuesOnly: boolean
): AnyAction => {
    return {
        type: UPDATE_PROMOTIONS_LAYOUT_ISSUES_ONLY,
        layoutIssuesOnly
    };
};

export const updatePromotionsSearch = (search: string): AnyAction => {
    return {
        type: UPDATE_PROMOTIONS_SEARCH,
        search
    };
};

export const updatePromotionsSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => {
    return {
        type: UPDATE_PROMOTIONS_SORT,
        sortBy,
        sortOrder
    };
};

const requestCampaignOffers = (): AnyAction => ({
    type: REQUEST_CAMPAIGN_OFFERS
});

const receiveCampaignOffers = (json: Json): AnyAction => ({
    type: RECEIVE_CAMPAIGN_OFFERS,
    json: json
});

export const updateCampaignOffersSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => {
    return {
        type: UPDATE_CAMPAIGN_OFFERS_SORT,
        sortBy,
        sortOrder
    };
};

const requestControlOffers = (): AnyAction => ({
    type: REQUEST_CONTROL_OFFERS
});

const receiveControlOffers = (json: Json): AnyAction => ({
    type: RECEIVE_CONTROL_OFFERS,
    json: json
});

export const updateControlOffersOffset = (offset: number): AnyAction => {
    return {
        type: UPDATE_CONTROL_OFFERS_OFFSET,
        offset
    };
};

export const updateControlOffersSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => {
    return {
        type: UPDATE_CONTROL_OFFERS_SORT,
        sortBy,
        sortOrder
    };
};

export const deleteOfferConfirmation = (
    offers: Promotion[],
    t: TFunction,
    dispatch: AppDispatch,
    refreshFunction?: NoArgsHandler
) => {
    let message: any = {
        resourceKey: "offer.delete_offer_confirmation_plural",
        values: {
            count: offers.length
        }
    };
    let title = t("offer.delete_offer_plural");
    if (offers.length === 1) {
        title = t("offer.delete_offer");
        message = {
            resourceKey: "offer.delete_offer_confirmation",
            values: {
                name: offers[0].name,
                offerLabel: offers[0].offerLabelFormatHtml
            }
        };
    }
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.yes")
            },
            callbackData: {
                offers,
                refreshFunction: refreshFunction
            },
            callbackFunction: handleDeleteOfferConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: message,
            showCloseIcon: false,
            title: title
        })
    );
};

const handleDeleteOfferConfirmation = (
    button: string,
    callbackData: {
        offers: Promotion[];
        refreshFunction: NoArgsHandler;
    },
    dispatch: AppDispatch
) => {
    if (button === BUTTON_YES) {
        dispatch(
            deleteOffers(callbackData.offers, callbackData.refreshFunction)
        );
    }
};

const deleteOffers = (
    offers: Promotion[],
    refreshFunction: NoArgsHandler | undefined
) => {
    const entityIds = offers.map(offer => offer.entityId);
    const deleteIds = entityIds.join(",");
    const url = "api/entity/promotion/";
    let successMessage: any = {
        resourceKey: "offer.delete_offer_success_plural"
    };
    if (entityIds.length === 1) {
        successMessage = {
            resourceKey: "offer.delete_offer_success",
            values: {
                name: offers[0].name,
                offerLabel: offers[0].offerLabelFormatHtml
            }
        };
    }
    return makeRequestThunk(url + deleteIds, {
        isCancellable: false,
        method: METHOD_DELETE,
        okDispatchFunc: (json: EmptyObject, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            if (refreshFunction) {
                refreshFunction();
            }
            actions.push(success(successMessage));
            return actions;
        }
    });
};

export const setIsControl = (
    entityId: string,
    name: number,
    offerLabel: string,
    isControl: boolean,
    callbackFunction?: NoArgsHandler
) => {
    const url = "api/entity/promotion/";
    return makeRequestThunk(url + entityId, {
        isCancellable: false,
        method: METHOD_PUT,
        body: {
            entityId: entityId,
            fieldSet: "controlOffer",
            isControl: isControl,
            objectType: "model/PromotionModel"
        },
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const resourceKey = isControl
                ? "offer.set_as_control_offer_notification"
                : "offer.unset_as_control_offer_notification";
            const successMessage = {
                resourceKey: resourceKey,
                values: {
                    name: name,
                    offerLabel: offerLabel
                }
            };
            if (callbackFunction) {
                callbackFunction();
            }
            actions.push(success(successMessage));
            return actions;
        }
    });
};

export const stopOfferConfirmation = (
    testPhaseData: TestPhaseData[],
    t: TFunction,
    dispatch: AppDispatch,
    refreshFunction?: NoArgsHandler
) => {
    let message: any = {
        resourceKey: "promotion.stop_offer_confirmation_plural",
        values: {
            count: testPhaseData.length
        }
    };
    let title = t("promotion.stop_promotion_plural");
    if (testPhaseData.length === 1) {
        title = t("promotion.stop_promotion");
        message = {
            resourceKey: "promotion.stop_offer_confirmation",
            values: { name: testPhaseData[0].promotion_name }
        };
    }
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: title
            },
            callbackData: {
                testPhaseData,
                refreshFunction: refreshFunction
            },
            callbackFunction: handleStopOfferConfirmation,
            cssWidth: "640px",
            dispatch: dispatch,
            icon: ICON_WARNING,
            message: message,
            showCloseIcon: false,
            title: title
        })
    );
};

const handleStopOfferConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
) => {
    if (button === BUTTON_YES) {
        dispatch(
            stopOffer(callbackData.testPhaseData, callbackData.refreshFunction)
        );
    }
};

const stopOffer = (
    testPhaseData: TestPhaseData[],
    refreshFunction: NoArgsHandler
) => {
    const entityIds = testPhaseData.map(offer => offer.promotion_entityId);
    let successMessage: any = {
        resourceKey: "promotion.stop_offer_success_plural"
    };
    if (entityIds.length === 1) {
        successMessage = {
            resourceKey: "promotion.stop_offer_success",
            values: {
                name: testPhaseData[0].promotion_name
            }
        };
    }
    const url = "api/promotion/stop";
    return makeRequestThunk(url, {
        body: {
            objectType: "PromotionStateUpdateRequest",
            promotionIds: entityIds
        },
        isCancellable: false,
        method: METHOD_PUT,
        okDispatchFunc: (json: EmptyObject, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            if (refreshFunction) {
                refreshFunction();
            }
            actions.push(success(successMessage));
            return actions;
        }
    });
};

export const startOfferConfirmation = (
    testPhaseData: TestPhaseData[],
    t: TFunction,
    dispatch: AppDispatch,
    refreshFunction?: NoArgsHandler
) => {
    let message: any = {
        resourceKey: "promotion.restart_offer_confirmation_plural",
        values: {
            count: testPhaseData.length
        }
    };
    let title = t("promotion.restart_promotion_plural");
    if (testPhaseData.length === 1) {
        title = t("promotion.restart_promotion");
        message = {
            resourceKey: "promotion.restart_offer_confirmation",
            values: {
                name: testPhaseData[0].promotion_name
            }
        };
    }

    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: title
            },
            callbackData: {
                testPhaseData,
                refreshFunction: refreshFunction
            },
            callbackFunction: handleStartOfferConfirmation,
            dispatch: dispatch,
            icon: ICON_WARNING,
            message: message,
            showCloseIcon: false,
            title: title
        })
    );
};

const handleStartOfferConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
) => {
    if (button === BUTTON_YES) {
        dispatch(
            startOffer(callbackData.testPhaseData, callbackData.refreshFunction)
        );
    }
};

const startOffer = (
    testPhaseData: TestPhaseData[],
    refreshFunction: NoArgsHandler
) => {
    const entityIds = testPhaseData.map(offer => offer.promotion_entityId);
    const url = "api/promotion/start";
    let successMessage: any = {
        resourceKey: "promotion.restart_offer_success_plural"
    };
    if (entityIds.length === 1) {
        successMessage = {
            resourceKey: "promotion.restart_offer_success",
            values: {
                name: testPhaseData[0].promotion_name
            }
        };
    }
    return makeRequestThunk(url, {
        body: {
            objectType: "PromotionStateUpdateRequest",
            promotionIds: entityIds
        },
        isCancellable: false,
        method: METHOD_PUT,
        okDispatchFunc: (json: EmptyObject, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            if (refreshFunction) {
                refreshFunction();
            }
            actions.push(success(successMessage));
            return actions;
        }
    });
};

export const removeAvailableAtConfirmation = (
    campaignEntityId: string,
    t: TFunction,
    dispatch: AppDispatch,
    refreshFunction?: NoArgsHandler
) => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.remove")
            },
            callbackData: {
                campaignEntityId,
                refreshFunction: refreshFunction
            },
            callbackFunction: handleRemoveAvailableAtConfirmation,
            dispatch: dispatch,
            icon: ICON_WARNING,
            message: {
                resourceKey: "offer.remove_available_at_confirmation"
            },
            showCloseIcon: false,
            title: t("offer.remove_available_at_for_all_offers")
        })
    );
};

const handleRemoveAvailableAtConfirmation = (
    button: string,
    callbackData: DeleteCallbackData,
    dispatch: AppDispatch
) => {
    if (button === BUTTON_YES) {
        dispatch(
            removeAvailableAt(
                get(callbackData, "campaignEntityId", ""),
                callbackData.refreshFunction
            )
        );
    }
};

const removeAvailableAt = (
    campaignEntityId: string,
    refreshFunction: NoArgsHandler
) => {
    const url = "api/availableat/" + campaignEntityId;
    return makeRequestThunk(url, {
        isCancellable: false,
        method: METHOD_DELETE,
        okDispatchFunc: (json: EmptyObject, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const successMessage = {
                resourceKey: "offer.remove_available_at_success"
            };
            if (refreshFunction) {
                refreshFunction();
            }
            actions.push(success(successMessage));
            return actions;
        }
    });
};

const getPromotionsRequest = (state: RootState): ListQueryRequest => {
    const campaignEntityId = get(state, "campaign.entityId");
    const sortOrder = formatSortOrderForQueryAPI(
        state.promotions.offers.sortOrder
    );
    const sortConditions = [
        sortCondition(get(state, "promotions.offers.sortBy"), sortOrder)
    ];
    const search = get(state, "promotions.offers.filters.search");
    const filterSearchCondition = search
        ? freeFormFilterCondition(search, ["name", "offerLabel", "sentence"])
        : null;
    const entityIdCondition = stringFilterCondition(
        "campaign.entityId",
        campaignEntityId,
        "EQUALS"
    );
    const controlOffersOnly = get(
        state,
        "promotions.offers.filters.controlOffersOnly"
    );
    let isControlFilterCondition = null;
    if (controlOffersOnly) {
        isControlFilterCondition = booleanFilterCondition("isControl", true);
    }
    const layoutIssuesOnly = get(
        state,
        "promotions.offers.filters.layoutIssuesOnly"
    );
    let layoutIssuesFilterCondition = null;
    if (layoutIssuesOnly) {
        layoutIssuesFilterCondition = orFilterCondition([
            booleanFilterCondition("hasErrors", true),
            booleanFilterCondition("hasWarnings", true)
        ]);
    }

    const filterConditions: FilterCondition[] = [];
    if (isControlFilterCondition) {
        filterConditions.push(isControlFilterCondition);
    }
    if (layoutIssuesFilterCondition) {
        filterConditions.push(layoutIssuesFilterCondition);
    }
    if (filterSearchCondition) {
        filterConditions.push(filterSearchCondition);
    }

    let filterCondition: FilterCondition = entityIdCondition;
    if (filterConditions.length > 0) {
        filterConditions.push(entityIdCondition);
        filterCondition = andFilterCondition(filterConditions);
    }

    return {
        entity: "Promotion",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: PAGE_SIZE_UNLIMITED,
        model: "PromotionModel/offerLayoutGrid",
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: null,
        sortConditions: sortConditions,
        startIndex: get(state, "promotions.offers.startIndex", 0)
    };
};

export function fetchPromotions() {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getPromotionsRequest(state);
        },
        preRequestFunc: requestPromotions,
        okDispatchFunc: receivePromotions,
        okResultFunc: (json: Json, state: RootState) => {
            const testPlatformName = get(
                state,
                "campaign.tenantTestPlatform.testPlatform.name"
            );
            return {
                items: json.result.map((promotion: any) => {
                    return reducePromotion(promotion, testPlatformName);
                }),
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

const getCampaignOffersRequest = (
    campaignEntityId: string,
    search: string,
    state: RootState
): ListQueryRequest => {
    const sortOrder = formatSortOrderForQueryAPI(
        state.promotions.campaignOffers.sortOrder
    );
    const sortConditions = [
        sortCondition(get(state, "promotions.campaignOffers.sortBy"), sortOrder)
    ];
    const filterSearchCondition = search
        ? freeFormFilterCondition(search, ["name", "offerLabel", "sentence"])
        : null;
    const entityIdCondition = stringFilterCondition(
        "campaign.entityId",
        campaignEntityId,
        "EQUALS"
    );

    let filterCondition: FilterCondition = entityIdCondition;

    if (filterSearchCondition) {
        filterCondition = andFilterCondition([
            entityIdCondition,
            filterSearchCondition
        ]);
    }

    return {
        entity: "Promotion",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: PAGE_SIZE_UNLIMITED,
        model: "PromotionModel/offerLayoutGrid",
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: null,
        sortConditions: sortConditions,
        startIndex: 0
    };
};

export function fetchCampaignOffers(campaignEntityId: string, search: string) {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getCampaignOffersRequest(campaignEntityId, search, state);
        },
        preRequestFunc: requestCampaignOffers,
        okDispatchFunc: receiveCampaignOffers,
        okResultFunc: (json: Json, state: RootState) => {
            return {
                items: json.result,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

export function fetchMatchingCampaigns(campaignEntityId: string) {
    return makeRequestThunk("api/campaign/query/matching", {
        method: METHOD_POST,
        body: {
            campaignId: campaignEntityId,
            objectType: "MatchingCampaignRequest"
        },
        okResultFunc: (json: Json, state: RootState) => {
            return json.result;
        },
        showLoadMask: false
    });
}

const getMatchingControlOffersRequest = (
    campaignEntityId: string,
    search: string,
    state: RootState
): ListQueryRequest & { campaignId: string } => {
    const sortOrder = formatSortOrderForQueryAPI(
        state.promotions.campaignOffers.sortOrder
    );
    const sortConditions = [
        sortCondition(get(state, "promotions.controlOffers.sortBy"), sortOrder)
    ];
    const filterSearchCondition = search
        ? freeFormFilterCondition(search, [
              "promotion.offerLabel",
              "productGroup.name"
          ])
        : null;
    const productACondition = stringFilterCondition(
        "identifier",
        "productA",
        "EQUALS"
    );

    let filterCondition: FilterCondition = productACondition;

    if (filterSearchCondition) {
        filterCondition = andFilterCondition([
            productACondition,
            filterSearchCondition
        ]);
    }

    return {
        entity: "PromotionProductData",
        campaignId: campaignEntityId,
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: get(state, "promotions.controlOffers.pageSize"),
        model: "ControlOfferModel/all",
        objectType: "MatchingControlOfferRequest",
        postSortCondition: null,
        propertySelection: null,
        sortConditions: sortConditions,
        startIndex: get(state, "promotions.controlOffers.startIndex", 0)
    };
};

export function fetchMatchingControlOffers(
    campaignEntityId: string,
    search: string
) {
    return makeRequestThunk("api/campaign/query/matching/controloffers", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getMatchingControlOffersRequest(
                campaignEntityId,
                search,
                state
            );
        },
        preRequestFunc: requestControlOffers,
        okDispatchFunc: receiveControlOffers,
        okResultFunc: (json: Json, state: RootState) => {
            return {
                items: json.result,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

export function getPdfLayouts(campaignEntityId: string) {
    return makeRequestThunk("api/promotion/pdf/layouts/" + campaignEntityId, {
        method: METHOD_GET,
        okResultFunc: (json: Json, state: RootState) => {
            return json;
        }
    });
}

const getDownloadPreviewsRequest = (
    campaignEntityId: string,
    layoutNames: string[],
    state: RootState
): any => {
    const sortConditions = [sortCondition("name", ASCENDING_QUERY_API)];

    const entityIdCondition = stringFilterCondition(
        "campaign.entityId",
        campaignEntityId,
        "EQUALS"
    );

    const filterCondition: FilterCondition = entityIdCondition;

    return {
        layoutNames: layoutNames.join(","),
        objectType: "PdfDownloadRequestBody",
        query: {
            objectType: "ListQuery",
            entity: "Promotion",
            filterCondition: filterCondition,
            sortConditions: sortConditions
        },
        sendToEmail: false
    };
};

export const downloadOfferPreviews = (
    campaignEntityId: string,
    layoutNames: string[]
) => {
    return makeRequestThunk("api/promotion/pdf/init", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getDownloadPreviewsRequest(
                campaignEntityId,
                layoutNames,
                state
            );
        },
        okDispatchFunc: processExportSuccess
    });
};

export const getExportableFields = (
    campaignEntityId: string,
    fieldSet: string
) => {
    return makeRequestThunk(
        "api/promotion/export/fields/" +
            campaignEntityId +
            "?fieldSet=" +
            fieldSet,
        {
            method: METHOD_GET,
            okResultFunc: (json: Json, state: RootState) => {
                return json;
            }
        }
    );
};

export const getExportOffersRequest = (
    campaignEntityId: string,
    fieldSet: string,
    fieldsToExport: OfferExportField[]
) => {
    const entityIdCondition = stringFilterCondition(
        "campaign.entityId",
        campaignEntityId,
        "EQUALS"
    );
    const filterCondition: FilterCondition = entityIdCondition;
    return {
        objectType: "ExportFieldDownloadRequestBody",
        fields: fieldsToExport,
        fieldSet: fieldSet,
        query: {
            objectType: "ListQuery",
            entity: "Promotion",
            filterCondition: filterCondition
        },
        sendToEmail: false
    };
};

export function importOffers(file: File, campaignId: string) {
    const formData = new FormData();
    formData.append("file", file);
    // @ts-expect-error - non-string form body
    formData.append("campaignIds", [campaignId]);
    return makeRequestThunk("api/promotion/import", {
        method: METHOD_POST,
        urlPartFunc: function (state: RootState) {
            return "?requestSource=react";
        },
        bodyType: BODY_TYPE_FILE,
        body: formData,
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const successMessage = {
                resourceKey: "promotion.import_offers_success"
            };
            actions.push(success(successMessage));
            return actions;
        },
        okResultFunc: (json: Json, state: RootState): any => {
            return json;
        },
        showLoadMask: true
    });
}

export function editAvailableAtText(text: string, campaignEntityId: string) {
    const formData = new FormData();
    formData.append("text", text);
    return makeRequestThunk("api/availableat/" + campaignEntityId + "/text", {
        method: METHOD_POST,
        bodyType: BODY_TYPE_FILE,
        body: formData,
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const successMessage = {
                resourceKey: "offer.edit_available_at_succes"
            };
            actions.push(success(successMessage));
            return actions;
        },
        showLoadMask: true
    });
}

export function editAvailableAtLogo(file: File, campaignEntityId: string) {
    const formData = new FormData();
    formData.append("file", file);
    return makeRequestThunk(
        "api/availableat/" + campaignEntityId + "/logo?requestSource=react",
        {
            method: METHOD_POST,
            bodyType: BODY_TYPE_FILE,
            body: formData,
            okDispatchFunc: (json: Json, state: RootState): Actions => {
                const actions: ActionOrThunk[] = [];
                const successMessage = {
                    resourceKey: "offer.edit_available_at_success"
                };
                actions.push(success(successMessage));
                return actions;
            },
            showLoadMask: true
        }
    );
}

export function getAvailableAtDefaultText(campaignEntityId: string) {
    return makeRequestThunk(
        "api/availableat/" + campaignEntityId + "/text/default",
        {
            method: METHOD_GET,
            okResultFunc: (json: Json, state: RootState): any => {
                return json.data;
            },
            showLoadMask: true
        }
    );
}

const getPromotionsForOfferLabelsRequest = (
    state: RootState
): ListQueryRequest => {
    const campaignEntityId = get(state, "campaign.entityId");

    const entityIdCondition = stringFilterCondition(
        "campaign.entityId",
        campaignEntityId,
        "EQUALS"
    );

    const sortConditions = [sortCondition("name", ASCENDING_QUERY_API)];

    return {
        entity: "Promotion",
        filterCondition: entityIdCondition,
        includeCrossTenantInstances: false,
        maxResults: -1,
        model: "PromotionModel/offerLayoutGrid",
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: null,
        sortConditions: sortConditions,
        startIndex: 0
    };
};

export function fetchOfferLabelPromotions() {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getPromotionsForOfferLabelsRequest(state);
        },
        okResultFunc: (json: Json, state: RootState) => {
            const testPlatformName = get(
                state,
                "campaign.tenantTestPlatform.testPlatform.name"
            );
            return {
                items: json.result.map((promotion: any) => {
                    return reducePromotion(promotion, testPlatformName);
                }),
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}
