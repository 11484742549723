import get from "lodash.get";
import type { ICellRendererParams } from "ag-grid-community";
import type { ParseKeys, TFunction } from "i18next";
import isEqual from "lodash.isequal";
import isNil from "lodash.isnil";
import { Trans } from "react-i18next";
import {
    COLUMN_CUSTOM_RENDERER_ALIGN_LEFT,
    COLUMN_CUSTOM_RENDERER_ALIGN_RIGHT,
    COLUMN_TYPE_AGGREGATION,
    COLUMN_TYPE_NUMERIC,
    getAgDataGridTextTruncateNode
} from "common/components/grid/AgDataGridUtil";
import type { DataPoint } from "common/components/HighchartsFactory";
import IncrementalValue from "common/components/IncrementalValue";
import theme from "common/components/theme";
import Tooltip from "common/components/Tooltip";
import i18n from "common/i18n";
import type { ReportStyle } from "common/reports/state/reportsStateUtils";
import { DATA_TABLE, TREND } from "common/reports/state/reportsStateUtils";
import type { ReportDef } from "common/shell/state/initStateUtils";
import { LAST_MONTH } from "common/util/date";
import type { DateFilter } from "common/util/date";
import { isEmptyString } from "common/util/lang";
import { DEFAULT_PAGE_SIZE, DESCENDING } from "common/util/query";
import { ComparisonMetricWrapper } from "reports/components/reportsViewUtils";
import {
    CONSUMER,
    CONSUMER_CUSTOM,
    CONSUMER_PRODUCT_TRIAL,
    CONSUMER_PURCHASE_QUANTITIES,
    defaultConsumerDataTableState,
    reduceConsumerSavedReport
} from "reports/consumer/state/consumerStateUtils";
import type {
    ConsumerReportDataTableState,
    ConsumerReportFilters,
    ConsumerMetric,
    ConsumerTrendSeries
} from "reports/consumer/state/consumerStateUtils";
import {
    defaultPurchaseCycleDataTableState,
    reducePurchaseCycleSavedReport,
    PURCHASE_CYCLE,
    PURCHASE_CYCLE_AND_PROMOTIONS
} from "reports/purchaseCycle/state/purchaseCycleStateUtils";
import type {
    PurchaseCycleReportDataTableState,
    PurchaseCycleReportFilters
} from "reports/purchaseCycle/state/purchaseCycleStateUtils";
import {
    METRIC_TYPE_INTEGER_COMPARISON,
    METRIC_TYPE_MONEY_COMPARISON,
    METRIC_TYPE_NUMBER_COMPARISON,
    METRIC_TYPE_PERCENT_COMPARISON
} from "reports/state/metrics";
import type { MetricTypeComparison } from "reports/state/metrics";
import {
    defaultSubstitutionComparisonState,
    defaultSubstitutionSummaryState,
    reduceSubstitutionSavedReport,
    SUBSTITUTION,
    SUBSTITUTION_COMPARISON,
    SUBSTITUTION_SUMMARY
} from "reports/substitution/state/substitutionStateUtils";
import type {
    SubstitutionComparisonState,
    SubstitutionSummaryState
} from "reports/substitution/state/substitutionStateUtils";
import {
    defaultTakeRateStackedBar,
    reduceTakeRateSavedReport,
    TAKE_RATE,
    TAKE_RATE_PURCHASE_DISTRIBUTION,
    TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON
} from "reports/takeRate/state/takeRateStateUtils";
import type {
    TakeRateStackedBarFilters,
    TakeRateStackedBarState,
    TakeRateTrendSeries
} from "reports/takeRate/state/takeRateStateUtils";
import { CATEGORY_SUBCATEGORY_SEPARATOR } from "shell/state/initReducers";
import type { TreeFilterNode } from "shell/state/initReducers";
import type { RootState } from "store";

// Saved report columns
export const SAVED_REPORT_CREATED_BY = "creatorName";
export const SAVED_REPORT_NAME = "name";
export const SAVED_REPORT_DESCRIPTION = "description";
export const SAVED_REPORT_REPORT_TYPE = "type";
export const SAVED_REPORT_MODIFIED_BY = "modifiedByName";
export const SAVED_REPORT_MODIFIED_DATE = "modifiedDateFormatDate";

export const PROMO_REPORT_TIME_PERIOD = "PromoReportTimePeriod";

// Report types
export type ReportType =
    | typeof CONSUMER
    | typeof TAKE_RATE
    | typeof PURCHASE_CYCLE
    | typeof SUBSTITUTION;

// All report view types
export type ReportView =
    | typeof CONSUMER_CUSTOM
    | typeof CONSUMER_PURCHASE_QUANTITIES
    | typeof CONSUMER_PRODUCT_TRIAL
    | typeof TAKE_RATE_PURCHASE_DISTRIBUTION
    | typeof TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON
    | typeof PURCHASE_CYCLE_AND_PROMOTIONS
    | typeof SUBSTITUTION_COMPARISON
    | typeof SUBSTITUTION_SUMMARY;

export const reportMap: {
    [key in ReportView]: ReportType;
} = {
    [CONSUMER_CUSTOM]: CONSUMER,
    [CONSUMER_PURCHASE_QUANTITIES]: CONSUMER,
    [CONSUMER_PRODUCT_TRIAL]: CONSUMER,
    [TAKE_RATE_PURCHASE_DISTRIBUTION]: TAKE_RATE,
    [TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON]: TAKE_RATE,
    [PURCHASE_CYCLE_AND_PROMOTIONS]: PURCHASE_CYCLE,
    [SUBSTITUTION_COMPARISON]: SUBSTITUTION,
    [SUBSTITUTION_SUMMARY]: SUBSTITUTION
};

// Make sure these match the ReportTableFilterDefinition (also in meta/init)
export const tableFilterMap: {
    [key in ReportView]: string;
} = {
    [CONSUMER_CUSTOM]: "CONSUMER_CUSTOM",
    [CONSUMER_PURCHASE_QUANTITIES]: "CONSUMER_PURCHASE_QUANTITIES",
    [CONSUMER_PRODUCT_TRIAL]: "CONSUMER_PRODUCT_TRIAL",
    [TAKE_RATE_PURCHASE_DISTRIBUTION]: "PURCHASE_DISTRIBUTION",
    [TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON]:
        "PURCHASE_DISTRIBUTION_COMPARISON",
    [PURCHASE_CYCLE_AND_PROMOTIONS]: "PURCHASE_CYCLE_AND_PROMOTIONS",
    [SUBSTITUTION_COMPARISON]: "SUBSTITUTION_COMPARISON",
    [SUBSTITUTION_SUMMARY]: "SUBSTITUTION_SUMMARY"
};

// Report Filter types for reports using report data table view
export type ReportFilters =
    | ConsumerReportFilters
    | PurchaseCycleReportFilters
    | TakeRateStackedBarFilters;
export type SeriesMetric = ConsumerMetric;
export type TrendSeries = ConsumerTrendSeries | TakeRateTrendSeries;

// Report data table types
type DataTableState =
    | ConsumerReportDataTableState
    | PurchaseCycleReportDataTableState;

type ReportDataTableTrendState = {
    dataTable: DataTableState;
    reportStyle: ReportStyle;
    trend: TrendState;
};

type ReportDataTableState = Pick<
    ReportDataTableTrendState,
    "dataTable" | "reportStyle"
>;

type ReportTrendState = Pick<
    ReportDataTableTrendState,
    "reportStyle" | "trend"
>;

// Chart types to display charts properly for configurable charts
type BaseChartTrendData = {
    color: string;
    data: DataPoint[];
    metric: SeriesMetric;
    name: string;
    series: TrendSeries;
};

type ConsumerChartTrendData = {
    hasHiddenDataPoints: boolean;
} & BaseChartTrendData;

export type ChartTrendData = ConsumerChartTrendData;

export type ReportTrendChartState = {
    data: ChartTrendData[];
    endDate: string;
    leftBound: null | number;
    rightBound: null | number;
    startDate: string;
};

// Bar Chart
export type BarChartLabels = {
    horizontalTitle: string;
    title: string;
    verticalTitle: string;
};

export type BarChartSeries = {
    color: string;
    data: number[];
    name: string;
    options?: any;
};

type ReportBarChartState = {
    categories: string[];
    data: BarChartSeries[];
    labels: BarChartLabels;
};

const defaultBarChartLabels: BarChartLabels = {
    horizontalTitle: "",
    title: "",
    verticalTitle: ""
};

const defaultBarChartState: ReportBarChartState = {
    categories: [],
    data: [],
    labels: defaultBarChartLabels
};

type ReportTrendChartType = ReportTrendChartState | ReportBarChartState;

// Report configuration types for trend
type ReportTrendConfigurationState = {
    date: DateFilter;
    series: TrendSeries[];
};

type TrendState = {
    chart: ReportTrendChartType;
    configuration: ReportTrendConfigurationState;
};

type SeriesJsonType = {
    color: string;
    dataPoints: DataPoint[];
    name: string;
};

type ConsumerSeriesJsonType = {
    hasHiddenDataPoints: boolean;
} & SeriesJsonType;

type TakeRateSeriesJsonType = {
    channels: NameValueType[];
    color: string;
    endDate: string;
    holidays: NameValueType[];
    items: {
        numBaskets: number;
        pctPurchasedAtLeastFormatted: string;
        purchaseQuantityFormatted: string;
    }[];
    meetsAggregationRequirement: boolean;
    name: string;
    offerTypes: string[];
    productGroups: NameValueType[];
    promotionQuantities: number[];
    startDate: string;
    timePeriod: DisplayNameEntityIdType;
};

const defaultTrendChartState: ReportTrendChartState = {
    data: [],
    endDate: "",
    leftBound: null,
    rightBound: null,
    startDate: ""
};

const defaultTrendConfigurationState: ReportTrendConfigurationState = {
    date: { time: LAST_MONTH },
    series: []
};

const defaultTrendState: TrendState = {
    chart: defaultTrendChartState,
    configuration: defaultTrendConfigurationState
};

const defaultTrendBarChartState: TrendState = {
    chart: defaultBarChartState,
    configuration: defaultTrendConfigurationState
};

// Full report state
export type ReportsState = {
    [CONSUMER]: {
        [CONSUMER_CUSTOM]: ReportDataTableTrendState;
        [CONSUMER_PRODUCT_TRIAL]: ReportDataTableTrendState;
        [CONSUMER_PURCHASE_QUANTITIES]: ReportDataTableTrendState;
    };
    reportType: ReportType;
    reportView: {
        label?: string | undefined;
        value: ReportView;
    };
    [TAKE_RATE]: {
        [TAKE_RATE_PURCHASE_DISTRIBUTION]: ReportTrendState;
        [TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON]: TakeRateStackedBarState;
    };
    [PURCHASE_CYCLE]: {
        [PURCHASE_CYCLE_AND_PROMOTIONS]: ReportDataTableState;
    };
    savedReport: SavedReport | null;
    savedReports: QueryState & {
        reportTypes: string[];
    };
    [SUBSTITUTION]: {
        [SUBSTITUTION_COMPARISON]: SubstitutionComparisonState;
        [SUBSTITUTION_SUMMARY]: SubstitutionSummaryState;
    };
};

export const defaultState: ReportsState = {
    [CONSUMER]: {
        [CONSUMER_PRODUCT_TRIAL]: {
            dataTable: defaultConsumerDataTableState,
            reportStyle: DATA_TABLE,
            trend: defaultTrendState
        },
        [CONSUMER_PURCHASE_QUANTITIES]: {
            dataTable: defaultConsumerDataTableState,
            reportStyle: DATA_TABLE,
            trend: defaultTrendState
        },
        [CONSUMER_CUSTOM]: {
            dataTable: defaultConsumerDataTableState,
            reportStyle: DATA_TABLE,
            trend: defaultTrendState
        }
    },
    reportType: CONSUMER,
    reportView: { value: CONSUMER_PRODUCT_TRIAL },
    [TAKE_RATE]: {
        [TAKE_RATE_PURCHASE_DISTRIBUTION]: {
            reportStyle: TREND,
            trend: defaultTrendBarChartState
        },
        [TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON]: defaultTakeRateStackedBar
    },
    [PURCHASE_CYCLE]: {
        [PURCHASE_CYCLE_AND_PROMOTIONS]: {
            dataTable: defaultPurchaseCycleDataTableState,
            reportStyle: DATA_TABLE
        }
    },
    savedReport: null,
    savedReports: {
        maxResults: DEFAULT_PAGE_SIZE,
        reportTypes: [],
        search: "",
        sortBy: "modifiedDate",
        sortOrder: DESCENDING,
        startIndex: 0,
        totalCount: -1
    },
    [SUBSTITUTION]: {
        [SUBSTITUTION_COMPARISON]: defaultSubstitutionComparisonState,
        [SUBSTITUTION_SUMMARY]: defaultSubstitutionSummaryState
    }
};

const reduceSeriesData = (data: DataPoint[]): DataPoint[] => {
    return data
        .map(seriesData => ({
            x: seriesData.x,
            y: seriesData.y
        }))
        .sort((a, b) => (a.x > b.x ? 1 : -1));
};

const reduceConsumerReportSeries = (
    allData: ConsumerSeriesJsonType[],
    allSeries: ConsumerTrendSeries[]
): ChartTrendData[] => {
    const chartTrendData: ChartTrendData[] = [];
    allData.forEach(data => {
        const series = allSeries.find(x => {
            return isEqual(data.name, x.name);
        });
        if (series && series.metric) {
            chartTrendData.push({
                color: data.color,
                data: reduceSeriesData(data.dataPoints),
                hasHiddenDataPoints: data.hasHiddenDataPoints,
                name: series.name,
                metric: series.metric,
                series: series
            });
        }
    });
    return chartTrendData;
};

const reduceConsumerTrendChart = (
    data: {
        endDate: string;
        leftBound: number;
        rightBound: number;
        series: ConsumerSeriesJsonType[];
        startDate: string;
    },
    series: ConsumerTrendSeries[]
): ReportTrendChartState => {
    return {
        data: reduceConsumerReportSeries(data.series, series),
        endDate: data.endDate,
        leftBound: data.leftBound,
        rightBound: data.rightBound,
        startDate: data.startDate
    };
};

const reduceTakeRateTrendChart = (
    data: {
        series: TakeRateSeriesJsonType[];
    },
    series: TakeRateTrendSeries[]
): ReportBarChartState => {
    const dataSeries = data.series;
    const categories =
        dataSeries.length > 0
            ? dataSeries[0]?.items?.map((item, index) => {
                  // Last category should be "X+"" for the label
                  if (index === dataSeries[0]?.items?.length - 1) {
                      return item.purchaseQuantityFormatted + "+";
                  } else {
                      return item.purchaseQuantityFormatted;
                  }
              }) ?? []
            : [];
    const chartSeries: BarChartSeries[] = [];
    dataSeries.forEach(item => {
        // Need to get time period object from series stored in redux store
        const configuredSeries = series.find(x => x.name === item.name);
        chartSeries.push({
            color: item.color,
            data: item.items.map(bar => bar.numBaskets),
            name: item.name,
            options: {
                channels: item.channels.map(channel => channel.name),
                endDate: item.endDate,
                holidays: item.holidays.map(holiday => holiday.name),
                offerTypes: item.offerTypes,
                percentPurchased: item.items.map(
                    bar => bar.pctPurchasedAtLeastFormatted
                ),
                productGroups: item.productGroups.map(ppg => ppg.name),
                promoQuantities: item.promotionQuantities.map(promoQuantity =>
                    promoQuantity.toString()
                ),
                startDate: item.startDate,
                timePeriod: configuredSeries
                    ? configuredSeries.time
                    : { label: item.timePeriod.displayName }
            }
        });
    });
    return {
        categories: categories,
        data: chartSeries,
        labels: {
            horizontalTitle: i18n.t("reports.purchase_quantity"),
            title: "",
            verticalTitle: i18n.t("reports.num_of_baskets")
        }
    };
};

export const reduceTrendChart = (
    reportType: ReportType,
    data: {
        series: ConsumerSeriesJsonType[] | TakeRateSeriesJsonType[];
    },
    series: TrendSeries[]
): ReportTrendChartType => {
    if (reportType === CONSUMER) {
        return reduceConsumerTrendChart(
            data as {
                endDate: string;
                leftBound: number;
                rightBound: number;
                series: ConsumerSeriesJsonType[];
                startDate: string;
            },
            series as ConsumerTrendSeries[]
        );
    } else if (reportType === TAKE_RATE) {
        return reduceTakeRateTrendChart(
            data as {
                series: TakeRateSeriesJsonType[];
            },
            series as TakeRateTrendSeries[]
        );
    } else {
        return defaultTrendChartState;
    }
};

// Helpers for formatting data table columns

const NilReportDataComponent = ({ data }: { data: any }) => {
    const tooltipKey = data?.meetsAggregationRequirement
        ? "reports.data_value_could_not_be_calculated"
        : "common:reports.data_cannot_be_showng_due_to_aggregation_req";
    return (
        <Tooltip>
            <div>
                <Trans i18nKey="common:general.n_a" />
            </div>
            <div>
                <Trans i18nKey={tooltipKey} />
            </div>
        </Tooltip>
    );
};

const ReportComparisonMetric = ({
    value,
    incrementalValue,
    incrementalValueFormatted
}: {
    value: string;
    incrementalValue: number;
    incrementalValueFormatted: string;
}) => {
    if (value) {
        return (
            <ComparisonMetricWrapper>
                <IncrementalValue color={theme.text} value={incrementalValue}>
                    <div>{value}</div>
                </IncrementalValue>
                <div>{incrementalValueFormatted}</div>
            </ComparisonMetricWrapper>
        );
    } else {
        return null;
    }
};

export const ValueCellRenderer = (params: ICellRendererParams) => {
    const { colDef, data } = params;
    if (colDef && data) {
        const field = colDef.cellRendererParams.field;
        const text = data[field];
        if (!(isNil(text) || isEmptyString(text))) {
            return getAgDataGridTextTruncateNode(text);
        } else {
            return <NilReportDataComponent data={data} />;
        }
    }
    return null;
};

const ValueComparisonCellRenderer = (params: ICellRendererParams) => {
    const { colDef, data } = params;
    if (colDef && data) {
        const field = colDef.cellRendererParams.field;
        const comparisonType = colDef.cellRendererParams.type;
        const comparison = data[field];
        if (!isNil(comparison)) {
            let content = null;
            switch (comparisonType) {
                case METRIC_TYPE_INTEGER_COMPARISON:
                case METRIC_TYPE_NUMBER_COMPARISON:
                    if (!isNil(comparison.changePercent)) {
                        content = (
                            <ReportComparisonMetric
                                value={comparison.changePercentFormatPercent}
                                incrementalValue={comparison.changePercent}
                                incrementalValueFormatted={
                                    comparison.changeFormatNumber
                                }
                            />
                        );
                    } else {
                        content = <NilReportDataComponent data={data} />;
                    }
                    break;
                case METRIC_TYPE_MONEY_COMPARISON:
                    if (!isNil(comparison.changePercent)) {
                        content = (
                            <ReportComparisonMetric
                                value={comparison.changePercentFormatPercent}
                                incrementalValue={comparison.changePercent}
                                incrementalValueFormatted={
                                    comparison.changeFormatCurrency
                                }
                            />
                        );
                    } else {
                        content = <NilReportDataComponent data={data} />;
                    }
                    break;
                case METRIC_TYPE_PERCENT_COMPARISON:
                    if (!isNil(comparison.change)) {
                        content = (
                            <ComparisonMetricWrapper>
                                <IncrementalValue
                                    color={theme.text}
                                    value={comparison.change}
                                >
                                    <div>{comparison.changeFormatBps}</div>
                                </IncrementalValue>
                            </ComparisonMetricWrapper>
                        );
                    } else {
                        content = <NilReportDataComponent data={data} />;
                    }
                    break;
                default:
                    break;
            }
            return content;
        } else {
            return <NilReportDataComponent data={data} />;
        }
    }
    return null;
};

export const getComparisonColumnDefinition = (
    name: string,
    headerKey: string,
    tooltipKey: string,
    t: TFunction,
    type: MetricTypeComparison,
    minWidth = 180,
    hide = false
): ColumnDefinition => {
    return {
        cellClass: [COLUMN_CUSTOM_RENDERER_ALIGN_RIGHT],
        cellRenderer: ValueComparisonCellRenderer,
        cellRendererParams: {
            field: name,
            type: type
        },
        colId: name,
        field: name,
        headerName: t(headerKey as ParseKeys),
        headerTooltip: t(tooltipKey as ParseKeys),
        hide: hide,
        minWidth: minWidth,
        sortable: true,
        type: COLUMN_TYPE_NUMERIC
    };
};

export const getNumericColumnDefinition = (
    name: string,
    headerKey: string,
    tooltipKey: string,
    t: TFunction,
    minWidth = 180,
    hide = false
): ColumnDefinition => {
    return {
        cellClass: [COLUMN_CUSTOM_RENDERER_ALIGN_RIGHT],
        cellRenderer: ValueCellRenderer,
        cellRendererParams: {
            field: name + "Formatted"
        },
        colId: name,
        field: name,
        headerName: t(headerKey as ParseKeys),
        headerTooltip: t(tooltipKey as ParseKeys),
        hide: hide,
        minWidth: minWidth,
        sortable: true,
        type: COLUMN_TYPE_NUMERIC
    };
};

export const getTextColumnDefinition = (
    name: string,
    headerKey: string,
    tooltipKey: string,
    t: TFunction,
    pinned = false,
    width = 200
): ColumnDefinition => {
    return {
        cellClass: [COLUMN_CUSTOM_RENDERER_ALIGN_LEFT],
        cellRenderer: ValueCellRenderer,
        cellRendererParams: {
            field: name
        },
        colId: name,
        field: name,
        headerName: t(headerKey as ParseKeys),
        headerTooltip: t(tooltipKey as ParseKeys),
        lockPosition: pinned,
        lockVisible: pinned,
        pinned: pinned,
        sortable: true,
        type: pinned ? COLUMN_TYPE_AGGREGATION : "",
        width: width
    };
};

// transform categories into subcategories
export const getSubcategories = (
    categories: string[],
    categoryNodes: TreeFilterNode[]
) => {
    // CheckboxTreeFilterView sends up only parent if all children selected
    // Don't want to mess with that processing so translate back what we want here
    const subcategories: any[] = [];

    categories.forEach((value: string) => {
        // is it a subcategory or a category?
        // search for :::
        if (value.includes(CATEGORY_SUBCATEGORY_SEPARATOR)) {
            const [categoryValue, subcategoryValue] = value.split(
                CATEGORY_SUBCATEGORY_SEPARATOR
            );
            const subcat = subcategories.find((cat: any) => {
                return cat.name === categoryValue;
            });
            if (subcat) {
                subcat.subcategories.push({
                    name: subcategoryValue,
                    objectType: "IcCategory"
                });
            } else {
                subcategories.push({
                    name: categoryValue,
                    objectType: "IcCategory",
                    subcategories: [
                        { name: subcategoryValue, objectType: "IcCategory" }
                    ]
                });
            }
        } else {
            const category = categoryNodes.find((node: TreeFilterNode) => {
                return node.value === value;
            });
            if (category) {
                const subs = category.children.map((child: TreeFilterNode) => {
                    // label is actual value to send to backend, value has been changed with parent prefix to maintain uniqueness
                    return {
                        name: child.label,
                        objectType: "IcCategory"
                    };
                });
                subcategories.push({
                    name: category.label,
                    objectType: "IcCategory",
                    subcategories: subs
                });
            }
        }
    });
    return subcategories;
};

export const reduceSavedReport = (
    savedReport: SavedReport,
    state: ReportsState
): any => {
    // set it as SavedReport
    // translate back into current redux state
    // unique to each report view
    const reportView = savedReport.type;
    const requests = savedReport.requests.map(request => {
        return JSON.parse(request.serializedRequest);
    });
    const uiState = JSON.parse(savedReport.state);
    let reducedState;
    switch (reportView) {
        case CONSUMER_CUSTOM:
        case CONSUMER_PRODUCT_TRIAL:
        case CONSUMER_PURCHASE_QUANTITIES:
            reducedState = reduceConsumerSavedReport(
                savedReport,
                requests,
                uiState,
                state
            );
            break;
        case TAKE_RATE_PURCHASE_DISTRIBUTION:
        case TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON:
            reducedState = reduceTakeRateSavedReport(
                savedReport,
                requests,
                uiState,
                state
            );
            break;
        case PURCHASE_CYCLE_AND_PROMOTIONS:
            reducedState = reducePurchaseCycleSavedReport(
                savedReport,
                requests,
                uiState,
                state
            );
            break;
        case SUBSTITUTION_COMPARISON:
        case SUBSTITUTION_SUMMARY:
            reducedState = reduceSubstitutionSavedReport(
                savedReport,
                requests,
                uiState,
                state
            );
            break;
        default:
            reducedState = state;
            break;
    }
    return Object.assign({}, reducedState, { savedReport: savedReport });
};

export const getReportTableFilterDefinitions = (
    state: RootState
): ReportDef[] => {
    return get(
        state,
        "init.preloadedEntities.metadata.reportTableFilterDefinitions",
        []
    );
};
