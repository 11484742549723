import {
    OPEN_MESSAGE_BOX,
    CLOSE_MESSAGE_BOX,
    BUTTON_OK
} from "common/shell/state/messageBoxActions";
import type { MessageBoxActions } from "common/shell/state/messageBoxActions";
import type { MessageBoxOptions } from "common/shell/state/messageBoxActions";
import noAction from "common/util/noAction";

type MessageBoxState = {
    isOpen: boolean;
} & Omit<MessageBoxOptions, "callbackFunction">;

export const defaultState: MessageBoxState = {
    isOpen: false,
    showCloseIcon: true,
    buttonText: {},
    buttons: BUTTON_OK,
    title: "",
    message: { resourceKey: "" },
    callbackData: {}
};

const messageBox = (
    state: MessageBoxState = defaultState,
    action: MessageBoxActions = noAction
): MessageBoxState => {
    switch (action.type) {
        case OPEN_MESSAGE_BOX: {
            const { type, ...rest } = action;
            return Object.assign({}, state, {
                ...rest
            });
        }
        case CLOSE_MESSAGE_BOX:
            return defaultState;
        default:
            return state;
    }
};

export default messageBox;
