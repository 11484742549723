import * as React from "react";
import styled from "styled-components/macro";
import type { Placement } from "tippy.js";
import { ClickableSvgIcon } from "common/components/SvgIcon";
import theme from "common/components/theme";
import Tooltip from "common/components/Tooltip";
import { ReactComponent as XIcon } from "common/icons/X.svg";
import { emptyFn } from "common/util/lang";

type PillWrapperProps = {
    borderColor?: string;
    isSquircle?: boolean;
} & backgroundColorPropsType;

const PillWrapper = styled.div<PillWrapperProps>`
    color: ${props => props.color};
    background-color: ${props => props.backgroundColor};
    border: 1px solid
        ${props => (props.borderColor ? props.borderColor : "transparent")};
    border-radius: ${props => (props.isSquircle ? "7px" : "500px")};
    font-variant: common-ligatures tabular-nums;
    display: inline-block;
    margin: auto;
    position: relative;
`;
PillWrapper.displayName = "PillWrapper";

type TextAlignerProps = {
    fontSize: string;
    height?: string;
    minWidth?: string;
} & Pick<paddingPropsType, "padding"> &
    Pick<PillProps, "maxWidth">;

// Adding 2px to width for styling
const TextAligner = styled.div<TextAlignerProps>`
    align-items: center;
    display: flex;
    font-size: ${props => props.fontSize};
    ${props => props.height && `height: ${props.height};`}
    justify-content: center;
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    padding: ${props => (props.padding ? props.padding : "2px")};
    text-align: center;

    ${props =>
        props.maxWidth &&
        `
        display: block;
        max-width: ${props.maxWidth};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `}
`;
TextAligner.displayName = "TextAligner";

type PillProps = {
    backgroundColor: string; // color of pill
    borderColor?: string; // color of pill border
    circle?: boolean;
    color: string; // color of text
    fontSize: string; // font size in pixels
    iconColor?: string; // color of icon
    isSquircle?: boolean; // squircle design (border radius)
    maxWidth?: string; // set width for the pill
    onClick?: NoArgsHandler;
    onDelete?: AnyFunction;
    padding?: string; // custom padding for inside
    text: string; // text
    textComponent?: React.ReactNode;
    title?: string; // title
    tooltip?: React.ReactNode; //tooltip
    tooltipPosition?: Placement;
};

const Pill = (props: PillProps) => {
    const {
        backgroundColor,
        borderColor,
        circle,
        color,
        iconColor,
        isSquircle,
        maxWidth,
        padding,
        text,
        textComponent,
        title,
        tooltip
    } = props;
    const fontSize = parseInt(props.fontSize.split("px")[0], 10);
    // 2px for a more circular pill
    // 5px for pills that have to be truncated since display will be a block
    // 1px and 3px for extra room around text also keeps pill reasonable height size
    let height = "";
    let minWidth = "";
    if (circle) {
        height = fontSize + 2 + "px";
        minWidth = height;
    } else if (maxWidth) {
        height = fontSize + 5 + "px";
    } else {
        height = fontSize + 3 + "px";
        minWidth = fontSize + 3 + "px";
    }
    const isEditable = textComponent ? true : false;
    const onDelete = props.onDelete ? props.onDelete : emptyFn;
    const pillText = (
        <TextAligner
            fontSize={props.fontSize}
            height={height}
            maxWidth={maxWidth}
            minWidth={minWidth}
            padding={padding}
        >
            {isEditable ? textComponent : text}
            {props.onDelete && (
                <ClickableSvgIcon
                    color={iconColor}
                    height="8px"
                    icon={XIcon}
                    marginLeft="8px"
                    onClick={evt => {
                        evt.stopPropagation();
                        onDelete();
                    }}
                    trackingComponentLabel={[Pill.displayName, "Delete"]}
                    width="8px"
                />
            )}
        </TextAligner>
    );
    const onClick = isEditable ? props.onClick : emptyFn;
    const pill = (
        <PillWrapper
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            color={color}
            isSquircle={isSquircle}
            onClick={onClick}
            title={title}
        >
            {pillText}
        </PillWrapper>
    );

    const tooltipPosition = props.tooltipPosition
        ? props.tooltipPosition
        : "left";

    return tooltip ? (
        <Tooltip placement={tooltipPosition}>
            {pill}
            {tooltip}
        </Tooltip>
    ) : (
        pill
    );
};

Pill.defaultProps = {
    circle: false,
    iconColor: theme.lightText,
    isSquircle: false,
    title: ""
};

Pill.displayName = "Pill";

export default Pill;
