import * as React from "react";
import type { WithTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import styled from "styled-components/macro";
import Button, {
    BUTTON_TYPE_SPLIT_LEFT,
    BUTTON_TYPE_SPLIT_RIGHT,
    ICON_RIGHT
} from "common/components/Button";
import {
    DropdownContainer,
    DropdownContent,
    DropdownItem,
    POSITION_LEFT,
    POSITION_BELOW
} from "common/components/Dropdown";
import { ReactComponent as DownCaretIcon } from "common/icons/DownCaret.svg";
import Flexbox from "common/components/styled/Flexbox";
import { containsIgnoreReactOutsideClick } from "common/util/outsideClick";
import { getTrackingEventData, trackEvent } from "common/util/tracking";
import {
    EVENT_NAME_EXECUTE_ACTION,
    EVENT_NAME_OPEN_CLOSE,
    EVENT_NAME_SELECTION_CHOICE
} from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";

const ButtonWrapper = styled(Flexbox)``;
ButtonWrapper.displayName = "ButtonWrapper";

type SplitButtonOwnProps = {
    defaultOption: MenuItemType;
    disabled?: boolean;
    menuOptions: MenuItemType[];
    onClickHandler: (item: string) => void;
    trackingComponentLabel: TrackingComponentLabel;
};

type SplitButtonProps = SplitButtonOwnProps & WithTranslation;

type SplitButtonState = {
    isOpen?: boolean;
};

class SplitButton extends React.Component<SplitButtonProps, SplitButtonState> {
    static displayName = "SplitButton";
    constructor(props: SplitButtonProps) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    onClickHandler = (item: string) => {
        const { trackingComponentLabel } = this.props;
        trackEvent(
            getTrackingEventData(
                SplitButton.displayName,
                trackingComponentLabel,
                EVENT_NAME_SELECTION_CHOICE,
                item
            )
        );
        this.props.onClickHandler(item);
        this.setState({ isOpen: false });
    };

    onTriggerClick = (evt: React.SyntheticEvent) => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    onOutsideClick = (event: MouseEvent) => {
        if (!containsIgnoreReactOutsideClick(event)) {
            if (this.state.isOpen) {
                this.setState({ isOpen: false });
            }
        }
    };

    onDefaultActionClick = () => {
        const { defaultOption } = this.props;
        this.onClickHandler(defaultOption.value);
    };

    render() {
        const {
            defaultOption,
            disabled,
            menuOptions,
            trackingComponentLabel
        } = this.props;
        const ButtonTrigger = (
            <ButtonWrapper>
                <Button
                    disabled={disabled}
                    noMinWidth={true}
                    onClick={this.onDefaultActionClick}
                    text={defaultOption.label}
                    trackingComponentLabel={trackingComponentLabel}
                    trackingEventName={EVENT_NAME_EXECUTE_ACTION}
                    type={BUTTON_TYPE_SPLIT_LEFT}
                />
                <Button
                    disabled={disabled}
                    icon={DownCaretIcon}
                    iconHeight="8px"
                    iconPosition={ICON_RIGHT}
                    iconWidth="8px"
                    noMinWidth={true}
                    onClick={this.onTriggerClick}
                    trackingComponentLabel={trackingComponentLabel}
                    trackingEventName={EVENT_NAME_OPEN_CLOSE}
                    type={BUTTON_TYPE_SPLIT_RIGHT}
                />
            </ButtonWrapper>
        );
        return (
            <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
                <DropdownContainer>
                    {ButtonTrigger}
                    {this.state.isOpen && (
                        <DropdownContent
                            horizontalPosition={POSITION_LEFT}
                            verticalPosition={POSITION_BELOW}
                        >
                            {menuOptions.map(option => (
                                <DropdownItem
                                    data-id={option.value}
                                    disabled={option.disabled}
                                    key={option.value}
                                    onClick={
                                        !option.disabled
                                            ? this.onClickHandler.bind(
                                                  null,
                                                  option.value
                                              )
                                            : undefined
                                    }
                                >
                                    {option.label}
                                </DropdownItem>
                            ))}
                        </DropdownContent>
                    )}
                </DropdownContainer>
            </OutsideClickHandler>
        );
    }
}

export default withTranslation()(SplitButton);
