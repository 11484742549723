import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
import get from "lodash.get";
import isNil from "lodash.isnil";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import Select, { components } from "react-select";
import type {
    ControlProps,
    IndicatorProps,
    OptionProps,
    OptionTypeBase,
    Props,
    Styles
} from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import styled from "styled-components/macro";
import ActionsDropdownView from "common/components/ActionsDropdownView";
import { DropdownHeader } from "common/components/Dropdown";
import Flexbox from "common/components/styled/Flexbox";
import type { FlexboxProps } from "common/components/styled/Flexbox";
import FlexGrow from "common/components/styled/FlexGrow";
import { FieldLabel } from "common/components/styled/Label";
import { UntrackedLink } from "common/components/styled/Link";
import SubText from "common/components/styled/SubText";
import TooltipWrapper from "common/components/styled/TooltipWrapper";
import {
    borderRadius,
    marginPropTypes,
    marginProps,
    paddingPropTypes,
    sizeProps
} from "common/components/styled/util";
import SvgIcon, { ClickableSvgIcon } from "common/components/SvgIcon";
import theme from "common/components/theme";
import Tooltip from "common/components/Tooltip";
import { withChangeTracking } from "common/components/withChangeTracking";
import { withClickTracking } from "common/components/withClickTracking";
import { ReactComponent as DeleteIcon } from "common/icons/Delete.svg";
import { ReactComponent as XCircleIcon } from "common/icons/XCircle.svg";
import FilterDropdownIcon from "common/filter/components/FilterDropdownIcon";
import {
    FilterApplyButtonComponent,
    FilterDropdownContainer,
    FilterDropdownContent,
    FilterDropdownHeader,
    FilterDropdownHeaderClearComponent,
    FilterDropdownScrollableContent,
    FilterDropdownText,
    FilterDropdownTitle,
    FilterDropdownTrigger
} from "common/filter/components/FilterDropdownView";
import { sortByLabel } from "common/util/format";
import { isEmptyString } from "common/util/lang";
import { reduceObject, removeFromArrayAtIndex } from "common/util/object";
import { containsIgnoreReactOutsideClick } from "common/util/outsideClick";
import {
    EVENT_NAME_OPEN_CLOSE,
    EVENT_NAME_SELECTION_CHOICE,
    EVENT_VALUE_OPEN_DRAWER
} from "common/util/trackingEvents";
import type {
    TrackingComponentLabel,
    TrackingEventName
} from "common/util/trackingEvents";

export const FORM_CARD_DEFAULT_WIDTH = "736px";
export const FORM_CARD_LARGE_WIDTH = "1024px";
export const FORM_CARD_XLARGE_WIDTH = "1200px";
export const SELECT_WIDTH = "302px";

export const COPY_ACTION = "COPY" as string;
export const DELETE_ACTION = "DELETE" as string;
export const EDIT_ACTION = "EDIT" as string;
export const EXPORT_ACTION = "EXPORT" as string;
export const REASSIGN_ACTION = "REASSIGN" as string;

type FieldWarningProps = Pick<marginPropsType, "marginBottom">;

export const FieldWarning = styled.div<FieldWarningProps>`
    color: ${theme.error};
    font-size: 11px;
    margin-bottom: ${props =>
        props.marginBottom ? props.marginBottom : "0px"};
`;

type FormRowProps = {
    fieldLabelWidth?: string;
    flexWrap?: string;
};

export const FormRow = styled(Flexbox)<FormRowProps>`
    align-items: ${props => (props.alignItems ? props.alignItems : "center")};
    flex-wrap: ${props => (props.flexWrap ? props.flexWrap : "wrap")};

    ${FieldLabel} {
        width: ${props =>
            props.fieldLabelWidth ? props.fieldLabelWidth : "160px"};
    }
`;
FormRow.displayName = "FormRow";

type FormSectionProps = {
    cssWidth?: string;
    rowMarginBottom?: string;
} & marginPropsType;

export const FormSection = styled.div<FormSectionProps>`
    margin-bottom: ${props =>
        props.marginBottom ? props.marginBottom : "12px"};
    margin-top: ${props => (props.marginTop ? props.marginTop : "12px")};
    ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
    ${props => props.marginRight && `margin-right: ${props.marginRight};`}
    ${props => props.cssWidth && `width: ${props.cssWidth};`}
    & > ${FormRow} {
        & > ${FieldLabel} {
            margin-bottom: 0px;
        }
    }

    & > ${FormRow}:not(:last-child) {
        margin-bottom: ${props =>
            props.rowMarginBottom ? props.rowMarginBottom : "24px"};
    }

    &:last-child {
        margin-bottom: 0px;
    }
`;
FormSection.displayName = "FormSection";

export const FormControlWithTip = styled.div<Pick<sizePropsType, "cssWidth">>`
    white-space: normal;
    ${props => props.cssWidth && `width: ${props.cssWidth}`};
`;

export const FormControlTip = styled("div")`
    color: ${theme.defaultGreyText};
    font-size: 12px;
`;

type FormCardProps = backgroundColorPropsType &
    Pick<marginPropsType, "marginBottom"> &
    Pick<paddingPropsType, "padding"> &
    Pick<sizePropsType, "cssHeight"> &
    Pick<sizePropsType, "cssWidth"> &
    Pick<flexboxPropsType, "overflow"> &
    disabledPropsType;

export const FormCard = styled.div<FormCardProps>`
    background-color: ${props =>
        props.backgroundColor ? props.backgroundColor : theme.background};
    border: solid 1px ${theme.lightGreyText};
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    margin-bottom: ${props =>
        props.marginBottom ? props.marginBottom : "16px"};
    padding: ${props => (props.padding ? props.padding : "28px")};
    width: ${props =>
        props.cssWidth ? props.cssWidth : FORM_CARD_DEFAULT_WIDTH};
    ${props => props.cssHeight && `height: ${props.cssHeight};`}
    ${props => props.disabled && "opacity: 0.5; pointer-events: none;"}
    ${props => props.overflow && `overflow: ${props.overflow};`}

    & ${FormSection}:first-child {
        margin-top: 0px;
    }
`;
FormCard.displayName = "FormCard";

export const FormTitle = styled.div`
    font-size: 24px;
    font-family: "BrandonTextBold";
`;
FormTitle.displayName = "FormTitle";

type ActionHeaderProps = {
    actions: MenuItem[];
    clickHandler: IdHandler;
    displayCount?: boolean;
    name: string;
    titleLeft?: React.ReactNode;
    trackingComponentLabel: TrackingComponentLabel;
} & paddingPropsType;

export const ActionHeader = (props: ActionHeaderProps) => {
    const {
        actions = [],
        clickHandler,
        displayCount = false,
        name,
        titleLeft = null,
        trackingComponentLabel
    } = props;

    return (
        <Flexbox {...reduceObject(props, ...paddingPropTypes)}>
            {titleLeft}
            <FormTitle>{name}</FormTitle>
            <FlexGrow />
            {actions.length > 0 && (
                <ActionsDropdownView
                    actions={actions}
                    clickHandler={clickHandler}
                    displayCount={displayCount}
                    trackingComponentLabel={trackingComponentLabel}
                />
            )}
        </Flexbox>
    );
};
ActionHeader.displayName = "ActionHeader";

// Special components for custom select (react-select)
const ClearIndicator = (props: IndicatorProps<OptionTypeBase, boolean>) => {
    return (
        <components.ClearIndicator {...props}>
            <SvgIcon color={theme.greyIcon} icon={XCircleIcon} zIndex={0} />
        </components.ClearIndicator>
    );
};

const DropdownIndicator = (props: IndicatorProps<OptionTypeBase, boolean>) => {
    return (
        <components.DropdownIndicator {...props}>
            <FilterDropdownIcon
                zIndex={0}
                isOpen={get(props, "selectProps.menuIsOpen")}
            />
        </components.DropdownIndicator>
    );
};

const IndicatorSeparator = () => {
    return null;
};

const IconAndText = styled(Flexbox)`
    align-items: center;
    align-self: center;
    flex-direction: row;
    justify-content: space-between;
`;

const SingleValue = (props: any) => {
    const icon = get(props, "selectProps.icon");
    if (icon) {
        // check to see if specific option should use icon
        const options = get(props, "options", []);
        const value = get(props, "data.value");
        let useIcon = false;
        for (let i = 0; i < options.length; i += 1) {
            if (options[i].value === value && options[i].useIcon === true) {
                useIcon = true;
                break;
            }
        }
        if (useIcon) {
            const label = get(props, "data.label");
            return (
                <components.SingleValue {...props}>
                    <IconAndText>
                        <div>{label}</div>
                        <SvgIcon
                            icon={icon}
                            height="16px"
                            marginLeft="5px"
                            marginTop="-1px"
                            width="16px"
                        />
                    </IconAndText>
                </components.SingleValue>
            );
        }
    }
    return (
        <components.SingleValue {...props}>
            {props.children}
        </components.SingleValue>
    );
};

export const Option = (props: any) => {
    const subLabel = get(props, "data.subLabel")
        ? get(props, "data.subLabel")
        : null;
    const { isSelected } = props;
    const icon = get(props, "selectProps.icon");
    const label = get(props, "data.label");
    if (icon) {
        const options = get(props, "options", []);
        const value = get(props, "data.value");
        let useIcon = false;
        for (let i = 0; i < options.length; i += 1) {
            if (options[i].value === value && options[i].useIcon === true) {
                useIcon = true;
                break;
            }
        }
        if (useIcon) {
            let color = theme.text;
            if (isSelected) {
                color = theme.background;
            }
            return (
                <components.Option {...props}>
                    <IconAndText>
                        <div>{label}</div>
                        <SvgIcon
                            color={color}
                            icon={icon}
                            height="16px"
                            marginTop="-1px"
                            width="16px"
                        />
                    </IconAndText>
                </components.Option>
            );
        }
    }
    return (
        <components.Option {...props}>
            <div>{label}</div>
            {subLabel && (
                <div
                    style={{
                        color: !isSelected ? theme.subText : undefined,
                        fontSize: 12
                    }}
                >
                    {subLabel}
                </div>
            )}
        </components.Option>
    );
};

// "Provided" is the existing css styling for react-select because we only want to specify
// specific styling for some of the parts or react-select.
const getCustomSelectStyles = (props: Props) => {
    const baseStyle = {
        control: (
            provided: Styles<OptionTypeBase, boolean>["control"],
            { isFocused, isDisabled }: ControlProps<OptionTypeBase, boolean>
        ) => ({
            ...provided,
            backgroundColor: props.highlightSelected
                ? theme.dropdownSelectedBg
                : isDisabled
                ? theme.btnDisabledBg
                : theme.background,
            borderColor:
                isFocused || props.highlightSelected
                    ? theme.linkText
                    : theme.border,
            outline: isFocused ? "none" : "inherit",
            boxShadow: isFocused
                ? "0 0 4px 0 " +
                  theme.inputFocusBorder +
                  ", inset 0 1px 3px 0 " +
                  theme.inputInsetBorder
                : "none",
            minHeight: "36px",
            ...(props.borderRadius ? { borderRadius: props.borderRadius } : {}),

            "&:hover": {
                borderColor: theme.filterDropdownHover,
                cursor: "pointer"
            },
            "&:hover *": {
                fill: theme.text
            }
        }),
        dropdownIndicator: (
            provided: Styles<OptionTypeBase, boolean>["dropdownIndicator"]
        ) => ({
            paddingRight: "12px"
        }),
        menu: (provided: Styles<OptionTypeBase, boolean>["menu"]) => ({
            ...provided,
            ...(props.menuWidth ? { width: props.menuWidth } : {}),
            ...(props.zIndexMenu
                ? { zIndex: props.zIndexMenu }
                : { zIndex: 3 }),
            marginTop: "2px"
        }),
        option: (
            provided: Styles<OptionTypeBase, boolean>["option"],
            {
                isDisabled,
                isFocused,
                isSelected
            }: OptionProps<OptionType, boolean>
        ) => ({
            ...provided,
            backgroundColor: isSelected
                ? theme.dropdownSelected
                : isFocused
                ? theme.dropdownHover
                : null,
            cursor: "pointer",

            "&:active": {
                backgroundColor:
                    !isDisabled &&
                    (isSelected ? theme.dropdownSelected : theme.dropdownHover)
            }
        }),
        placeholder: (
            provided: Styles<OptionTypeBase, boolean>["placeholder"]
        ) => {
            return {
                ...provided,
                color: theme.darkGreyText
            };
        },
        // For async selector, change color of the single value if search text is empty and menu is open
        // Helps prompt user to type/search even though there is a selection
        singleValue: (
            provided: Styles<OptionTypeBase, boolean>["singleValue"]
        ) => ({
            ...provided,
            color:
                (isNil(props.inputValue) || isEmptyString(props.inputValue)) &&
                props.menuIsOpen
                    ? theme.lightGreyText
                    : null
        }),
        valueContainer: (
            provided: Styles<OptionTypeBase, boolean>["valueContainer"]
        ) => ({
            ...provided,
            paddingLeft: "12px"
        })
    };
    let customStyle = Object.assign({}, baseStyle);
    // fix height for date chooser month/year height
    if (Object.prototype.hasOwnProperty.call(props, "maxMenuHeight")) {
        customStyle = Object.assign({}, customStyle, {
            maxMenuHeight: props.maxMenuHeight
        });
    }
    // Add additional styling to menu placement for portal so it shows up on top
    if (props.menuPortalTarget) {
        customStyle = Object.assign({}, customStyle, {
            menuPortal: (styles: Styles<OptionTypeBase, boolean>) => ({
                ...styles,
                zIndex: props.zIndexMenuPortal ? props.zIndexMenuPortal : 5000
            }) //  >= dialog's overlay z-index which is 5000, can set zIndex to be higher if needed
        });
    }
    return customStyle;
};

const CustomSelectComponent = styled(Select).attrs((props: Props) => ({
    components: {
        ClearIndicator,
        DropdownIndicator,
        IndicatorSeparator,
        SingleValue,
        Option
    },
    isSearchable: Object.prototype.hasOwnProperty.call(props, "isSearchable")
        ? props.isSearchable
        : props.options && props.options.length > 8,
    menuPlacement: "auto",
    styles: getCustomSelectStyles(props)
}))`
    ${props => props.height && `height: ${props.height}`};
    ${props => props.width && `width: ${props.width}`};
    ${props => props.zIndex && `z-index: ${props.zIndex}`};
    ${marginProps}
    ${sizeProps}
`;
CustomSelectComponent.displayName = "CustomSelect";

export const CustomSelect = withChangeTracking(
    CustomSelectComponent,
    CustomSelectComponent.displayName,
    EVENT_NAME_SELECTION_CHOICE
);

export const UntrackedCustomSelect = CustomSelectComponent;

const CustomAsyncSelectWrapper = styled(AsyncPaginate).attrs(
    (props: Props) => ({
        components: { ...props.components },
        debounceTimeout: 500,
        menuPlacement: props.menuPlacement,
        styles: getCustomSelectStyles(props)
    })
)`
    ${props => props.zIndex && `z-index: ${props.zIndex}`};
    ${marginProps}
    ${sizeProps}
`;

type CustomAsyncSelectProps = {
    autoFocus?: boolean;
    borderRadius?: string;
    cacheUniqs?: any[];
    formatOptionLabel?: (option: OptionType, meta: any) => string | JSX.Element;
    getOptionValue?: (option: OptionType) => string;
    isClearable?: boolean;
    isOptionDisabled?: (option: OptionType) => boolean;
    loadOptions: (inputValue: string, prevOptions: any[]) => Promise<any>;
    menuPortalTarget?: HTMLElement | null;
    menuWidth?: string;
    name: string;
    onChange: (value: OptionType) => void;
    optionLayout?: (props: any) => React.ReactNode;
    placeholder: string;
    retainTypeAhead?: boolean;
    tabIndex?: string;
    value: OptionType | undefined | null;
    zIndexMenu?: number;
    zIndexMenuPortal?: number;
} & marginPropsType &
    sizePropsType;

// Consts for react select input actions
const SET_VALUE_ACTION = "set-value";
const INPUT_CHANGE_ACTION = "input-change";
const INPUT_BLUR_ACTION = "input-blur";
const MENU_CLOSE_ACTION = "menu-close";

// Have to use functional component leveraging AsyncPaginate in order to override some default functionalities
const CustomAsyncSelecComponent = (props: CustomAsyncSelectProps) => {
    const {
        loadOptions,
        onChange,
        optionLayout,
        retainTypeAhead,
        value,
        ...rest
    } = props;
    const [inputValue, updateInput] = useState("");
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [menuPlacement, setMenuPlacement] = useState("auto");
    const selectContainer = useRef<HTMLDivElement>(null);

    // react select async does not handle menuPlacement correctly - only calculates it for a very small menu
    // as it doesn't know actually how many results will be returned
    // calculate menu placement based on position of select component on viewport
    // handle window resize

    const calculateMenuPlacement = useCallback(() => {
        if (selectContainer.current) {
            const elementDimensions =
                selectContainer.current.getBoundingClientRect();
            // height of largest default menu is 300, this would need to change it we allow for menu height size to be sent in as prop
            // not considering issue of whether there isn't enough room to put the menu top/up - if the window is so small that down and up doens't work, oh well
            const selectorDimensionCheck =
                elementDimensions.top + elementDimensions.height + 300;
            if (selectorDimensionCheck > window.innerHeight) {
                setMenuPlacement("top");
            } else {
                // reset to auto
                setMenuPlacement("auto");
            }
        }
    }, []);

    // if window resizes, need to reset menu placement
    useEffect(() => {
        // let's not call resize a million times
        const debouncedHandleResize = debounce(calculateMenuPlacement, 500);
        window.addEventListener("resize", debouncedHandleResize);
        // remove event listener, cancel any outstanding debounce calls
        return () => {
            debouncedHandleResize.cancel();
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, [calculateMenuPlacement]);

    // on initial load, set menu placement
    useEffect(() => {
        if (selectContainer.current) {
            calculateMenuPlacement();
        }
    }, [calculateMenuPlacement]);

    const onInputChange = (inputValue: string, action: any) => {
        const inputAction = action.action;
        if (retainTypeAhead) {
            // Only update typeahead on input change and set value
            // Allow for update on blur and close only when option is selected - used to clear typeahead
            if (
                inputAction === INPUT_CHANGE_ACTION ||
                inputAction === SET_VALUE_ACTION ||
                (!isNil(value) &&
                    (inputAction === INPUT_BLUR_ACTION ||
                        inputAction === MENU_CLOSE_ACTION))
            ) {
                updateInput(inputValue);
            }
        } else {
            updateInput(inputValue);
        }
    };

    const onMenuOpen = () => {
        setMenuIsOpen(true);
    };

    const onMenuClose = () => {
        setMenuIsOpen(false);
    };

    const components: any = {
        ClearIndicator,
        DropdownIndicator,
        IndicatorSeparator
    };
    if (optionLayout) {
        components.Option = optionLayout;
    }

    // needed enclosing div to get ref to work properly
    return (
        <div ref={selectContainer}>
            <CustomAsyncSelectWrapper
                components={components}
                inputValue={inputValue}
                loadOptions={loadOptions}
                menuIsOpen={menuIsOpen}
                menuPlacement={menuPlacement}
                onChange={onChange}
                onInputChange={onInputChange}
                onMenuClose={onMenuClose}
                onMenuOpen={onMenuOpen}
                value={value}
                {...rest}
            />
        </div>
    );
};

CustomAsyncSelecComponent.displayName = "CustomAsyncSelect";

export const CustomAsyncSelect = withChangeTracking(
    CustomAsyncSelecComponent,
    CustomAsyncSelecComponent.displayName,
    EVENT_NAME_SELECTION_CHOICE
);

type CustomAsyncMultiSelectProps = {
    buttonWidth?: string;
    cacheUniqs?: any[];
    filterHeaderText: string;
    formatOptionLabel?: (option: OptionType, meta: any) => string | JSX.Element;
    getItems: (inputValue: string, prevOptionsLength: number) => Promise<any>;
    maxItems: number;
    onChange: (selectedItems: OptionType[]) => void;
    placeholder: string;
    selectedItems: OptionType[];
    selectedText: string;
    trackingLabel: TrackingComponentLabel;
} & marginPropsType &
    paddingPropsType &
    Pick<sizePropsType, "cssWidth">;

export const CustomAsyncMultiSelect = (props: CustomAsyncMultiSelectProps) => {
    const {
        cacheUniqs,
        filterHeaderText,
        getItems,
        formatOptionLabel,
        maxItems,
        onChange,
        placeholder,
        selectedItems,
        selectedText,
        trackingLabel
    } = props;
    const { t } = useTranslation();

    // Component state
    const [isOpen, setIsOpen] = useState(false);
    const [currSelectedItems, setCurrSelectedItems] = useState(selectedItems);

    useEffect(() => {
        if (isOpen) {
            setCurrSelectedItems(selectedItems);
        }
    }, [isOpen, selectedItems]);

    // Async selector item getter
    const getDropdownItems = async (
        inputValue: string,
        prevOptions: OptionType[]
    ) => {
        return await getItems(inputValue, prevOptions.length).then(
            ({ result }) => {
                const { items, totalCount } = result;
                const selectedValues = currSelectedItems.map(
                    (item: OptionType) => item.value
                );
                const processedItems = items.map((item: OptionType) => ({
                    ...item,
                    isDisabled: selectedValues.includes(item.value)
                }));
                return processOptions(
                    processedItems,
                    maxItems,
                    totalCount,
                    prevOptions
                );
            }
        );
    };

    // On change handlers
    const applyChanges = () => {
        onChange(currSelectedItems);
        setIsOpen(false);
    };

    const onClear = () => {
        setCurrSelectedItems([]);
    };

    const onItemRemove = (index: number) => {
        setCurrSelectedItems(removeFromArrayAtIndex(currSelectedItems, index));
    };

    const onItemSelect = (option: OptionTypeOrNullOrUndefined) => {
        if (option) {
            setCurrSelectedItems(sortByLabel(currSelectedItems.concat(option)));
        }
    };

    const onOutsideClick = (event: MouseEvent) => {
        if (!containsIgnoreReactOutsideClick(event)) {
            if (isOpen) {
                applyChanges();
            }
        }
    };

    const onTriggerClick = () => {
        setIsOpen(!isOpen);
    };

    // Filter Trigger
    const dropdownText = selectedItems.length > 0 ? selectedText : placeholder;
    const FilterTriggerComponent = (
        <FilterDropdownTrigger
            color={theme.text}
            textColor={
                selectedItems.length > 0 ? theme.text : theme.placeholder
            }
            onClick={onTriggerClick}
            {...reduceObject(props, ...paddingPropTypes)}
        >
            <Flexbox justifyContent="space-between">
                <FilterDropdownText>{dropdownText} </FilterDropdownText>
                <FilterDropdownIcon zIndex={0} />
            </Flexbox>
        </FilterDropdownTrigger>
    );
    const selectedTextTooltip = selectedItems.map((item, index) => {
        return <div key={"tooltip-" + index}>{item.label}</div>;
    });
    const FilterTrigger =
        !isOpen && selectedItems.length > 0 ? (
            <Tooltip>
                {FilterTriggerComponent}
                <TooltipWrapper>{selectedTextTooltip}</TooltipWrapper>
            </Tooltip>
        ) : (
            FilterTriggerComponent
        );
    const customSelectOptionalOptions: any = {};
    if (formatOptionLabel) {
        customSelectOptionalOptions.formatOptionLabel = formatOptionLabel;
    }
    return (
        <OutsideClickHandler onOutsideClick={onOutsideClick}>
            <FilterDropdownContainer
                {...reduceObject(props, ...marginPropTypes, [
                    "buttonWidth",
                    "cssWidth"
                ])}
            >
                {FilterTrigger}
                {isOpen && (
                    <FilterDropdownContent>
                        <FilterDropdownHeader hideBottomBorder={true}>
                            <FilterDropdownTitle>
                                <DropdownHeader>
                                    {filterHeaderText}
                                </DropdownHeader>
                                {currSelectedItems.length > 0 && (
                                    <FilterDropdownHeaderClearComponent
                                        onClick={onClear}
                                        trackingComponentLabel={trackingLabel}
                                    >
                                        {t("common:filter.clear_all_x", {
                                            count: currSelectedItems.length
                                        })}
                                    </FilterDropdownHeaderClearComponent>
                                )}
                            </FilterDropdownTitle>
                        </FilterDropdownHeader>
                        <CustomAsyncSelect
                            autoFocus={true}
                            borderRadius="0px"
                            cacheUniqs={
                                cacheUniqs
                                    ? [currSelectedItems].concat(cacheUniqs)
                                    : [currSelectedItems]
                            }
                            cssWidth="100%"
                            loadOptions={getOptions(getDropdownItems)}
                            name="Custom Async Multi Select Search"
                            onChange={onItemSelect}
                            placeholder={t(
                                "common:general.start_typing_to_add_items"
                            )}
                            trackingComponentLabel={trackingLabel}
                            value={null}
                            {...customSelectOptionalOptions}
                        />
                        <FilterDropdownScrollableContent
                            cssHeight="260px"
                            minWidth="325px"
                        >
                            {currSelectedItems.length > 0 ? (
                                <Flexbox flexDirection="column">
                                    {currSelectedItems.map((item, index) => {
                                        // @ts-expect-error - subLabel is not a standard property
                                        const subLabel = item.subLabel;
                                        return (
                                            <Flexbox
                                                justifyContent="space-between"
                                                key={"item_" + index}
                                                margin="8px"
                                            >
                                                <Flexbox flexDirection="column">
                                                    <div>{item.label}</div>
                                                    {subLabel && (
                                                        <div
                                                            style={{
                                                                color: theme.subText,
                                                                fontSize: 12
                                                            }}
                                                        >
                                                            {subLabel}
                                                        </div>
                                                    )}
                                                </Flexbox>
                                                <Flexbox>
                                                    <ClickableSvgIcon
                                                        align="baseline"
                                                        color={theme.iconMenu}
                                                        cursor="pointer"
                                                        hoverColor={theme.text}
                                                        icon={DeleteIcon}
                                                        marginLeft="8px"
                                                        marginTop="2px"
                                                        onClick={onItemRemove.bind(
                                                            null,
                                                            index
                                                        )}
                                                        trackingComponentLabel={
                                                            trackingLabel
                                                        }
                                                    />
                                                </Flexbox>
                                            </Flexbox>
                                        );
                                    })}
                                </Flexbox>
                            ) : (
                                <Flexbox
                                    alignItems="center"
                                    cssHeight="100%"
                                    cssWidth="100%"
                                    justifyContent="center"
                                >
                                    {t("common:general.no_items_selected")}
                                </Flexbox>
                            )}
                        </FilterDropdownScrollableContent>
                        <FilterApplyButtonComponent
                            onClick={applyChanges}
                            trackingComponentLabel={trackingLabel}
                        >
                            {t("common:general.apply")}
                        </FilterApplyButtonComponent>
                    </FilterDropdownContent>
                )}
            </FilterDropdownContainer>
        </OutsideClickHandler>
    );
};

/* Helper function to create a function that returns an async function
   Use the function with the loadOptions property in CustomAsyncSelect to get options
   Note: The async function must return an object with {options: {label: string, value: string}[], hasMore: boolean}
   hasMore: tells us that when user scroll we will fetch next X options
   options: An array of pair value of label and value
*/
export const getOptions = (
    asyncFetch: (inputValue: string, prevOptions: any[]) => Promise<any>
): ((inputValue: string, prevOptions: any[]) => Promise<any>) => {
    return (inputValue: string, prevOptions: any[]) => {
        return asyncFetch(inputValue, prevOptions);
    };
};

/*
 * Note: This was broken out as a function since an async function previously sets
 * the redux state so we need the updated values
 * Note: The options will be a displayName, entityId pairs that then get converted to label value pairs.
 */
export const processOptions = (
    options: any[],
    maxResults: number,
    totalCount: number,
    prevOptions: OptionType[]
): any => {
    const hasMore = totalCount > prevOptions.length + maxResults;
    return {
        hasMore,
        options
    };
};

// Helps transform a list of options into OptionType options for custom select
export const processCustomSelectOptions = (
    options: any[],
    labelIdentifier: string,
    valueIdentifier: string
): OptionType[] => {
    return options.map(option => {
        return {
            label: option[labelIdentifier],
            value: option[valueIdentifier]
        };
    });
};

export const WarningMessage = styled(Flexbox)`
    color: ${theme.warning};
    ${marginProps}
`;
WarningMessage.displayName = "WarningMessage";

export const ButtonBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    &:not(:last-child) {
        padding-right: 24px;
    }
    padding-left: 24px;
    background-color: ${theme.background};
`;
ButtonBar.displayName = "ButtonBar";

type PlaceholderWrapperProps = {
    trackingComponentLabel: TrackingComponentLabel;
    trackingEventName?: TrackingEventName;
    trackingEventValue?: string;
} & Pick<sizePropsType, "cssHeight" | "cssWidth">;

const PlaceholderWrapper = styled(Flexbox)<PlaceholderWrapperProps>`
    align-items: center;
    background-color: ${theme.universalBackground};
    border: 1px dashed ${theme.border};
    border-radius: ${borderRadius("card")};
    color: ${theme.defaultGreyText};
    flex-direction: column;
    font-size: 12px;
    height: ${props => (props.cssHeight ? props.cssHeight : "80px")};
    justify-content: center;
    margin-bottom: 12px;
    padding-left: ${props => (props.paddingLeft ? props.paddingLeft : "16px")};
    padding-right: ${props =>
        props.paddingRight ? props.paddingRight : "16px"};
    text-align: center;
    width: ${props => (props.cssWidth ? props.cssWidth : "calc(100% - 32px)")};

    ${UntrackedLink} {
        font-size: 14px;
    }

    :hover {
        background-color: ${theme.emptyStateHover};
        border: 1px dashed ${theme.dottedBorder};
        cursor: pointer;

        ${UntrackedLink} {
            text-decoration: underline;
        }
    }

    ${marginProps}
`;
PlaceholderWrapper.displayName = "PlaceholderWrapper";

// Can pass props to PlaceHolderWrapper for styling
type AddPlaceholderProps = {
    onClick: ClickHandler;
    subText?: string;
    title: string;
    trackingComponentLabel: TrackingComponentLabel;
    trackingEventName?: TrackingEventName;
    trackingEventValue?: string;
    wrapperProps?: FlexboxProps;
};

const AddPlaceholderComponent = (props: AddPlaceholderProps) => {
    const {
        onClick,
        subText,
        title,
        trackingComponentLabel,
        trackingEventName,
        trackingEventValue,
        wrapperProps
    } = props;
    return (
        <PlaceholderWrapper
            onClick={onClick}
            trackingComponentLabel={trackingComponentLabel}
            trackingEventName={trackingEventName}
            trackingEventValue={trackingEventValue}
            {...wrapperProps}
        >
            <div>
                <UntrackedLink textDecoration="none">{title}</UntrackedLink>
            </div>
            <div>{subText}</div>
        </PlaceholderWrapper>
    );
};

AddPlaceholderComponent.displayName = "AddPlaceholder";

export const AddPlaceholder = withClickTracking(
    AddPlaceholderComponent,
    AddPlaceholderComponent.displayName,
    EVENT_NAME_OPEN_CLOSE,
    EVENT_VALUE_OPEN_DRAWER
);

export const FormColumnSection = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 15px;
`;
FormColumnSection.displayName = "FormColumnSection";

export const FieldTip = styled(SubText)<{ maxWidth?: string }>`
    text-wrap: wrap;
    ${props => props.maxWidth && `max-width: ${props.maxWidth};`}
`;

type FieldTipInputContainerProps = Pick<sizePropsType, "cssWidth">;

export const FieldTipInputContainer = styled.div<FieldTipInputContainerProps>`
    margin-bottom: 5px;
    width: ${props => (props.cssWidth ? props.cssWidth : "180px")};
`;
