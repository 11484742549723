import type { AnyAction } from "redux";
import {
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import { getSortOrder } from "offerRecommendations/state/offerRecommendationStateUtils";
import type {
    OfferCategory,
    OfferMove
} from "offerRecommendations/state/offerRecommendationStateUtils";
import {
    OFFER_RECOMMENDATIONS_PAGE,
    OFFER_RECOMMENDATIONS_REPORT_PRINT_PAGE
} from "shell/state/pageActions";
import type { RootState } from "store";

type OfferRecommendationsPagePayload = {
    controlId: string;
    metric?: string;
    offerIds?: string;
};

type OfferRecommendationsPageAction = {
    payload: OfferRecommendationsPagePayload;
    type: string;
};

export const offerRecommendationsPage = (
    controlId: string
): OfferRecommendationsPageAction => {
    return {
        type: OFFER_RECOMMENDATIONS_PAGE,
        payload: { controlId }
    };
};

export const offerRecommendationsReportPrintPage = (
    controlId: string,
    metric: string,
    offerIds: string
): OfferRecommendationsPageAction => {
    return {
        type: OFFER_RECOMMENDATIONS_REPORT_PRINT_PAGE,
        payload: { controlId, metric, offerIds }
    };
};

// action types
export const REQUEST_OFFER_RECOMMENDATIONS = "REQUEST_OFFER_RECOMMENDATIONS";
export const RECEIVE_OFFER_RECOMMENDATIONS = "RECEIVE_OFFER_RECOMMENDATIONS";

export const REQUEST_OFFER_RECOMMENDATIONS_REPORT =
    "REQUEST_OFFER_RECOMMENDATIONS_REPORT";
export const RECEIVE_OFFER_RECOMMENDATIONS_REPORT =
    "RECEIVE_OFFER_RECOMMENDATIONS_REPORT";

export const RECEIVE_CATEGORY_SORTED = "RECEIVE_CATEGORY_SORTED";
export const OPEN_CAMPAIGN_LIST = "OPEN_CAMPAIGN_LIST";
export const CLOSE_CAMPAIGN_LIST = "CLOSE_CAMPAIGN_LIST";

// action creators

export const requestOfferRecommendations = (): AnyAction => ({
    type: REQUEST_OFFER_RECOMMENDATIONS
});

export const receiveOfferRecommendations = (json: any): AnyAction => ({
    type: RECEIVE_OFFER_RECOMMENDATIONS,
    recommendations: json
});

export const requestOfferRecommendationsReport = (): AnyAction => ({
    type: REQUEST_OFFER_RECOMMENDATIONS_REPORT
});

export const receiveOfferRecommendationsReport = (json: any): AnyAction => ({
    type: RECEIVE_OFFER_RECOMMENDATIONS_REPORT,
    report: json
});

export const receiveCategorySorted = (
    category: OfferCategory,
    sortBy: string,
    json: Json
): AnyAction => ({
    type: RECEIVE_CATEGORY_SORTED,
    category: category,
    offers: json,
    sortBy: sortBy
});

export const openCampaignList = (
    campaigns: MatrixRecommendationObjectCampaign[],
    isSingleOffer = true
): AnyAction => {
    return {
        type: OPEN_CAMPAIGN_LIST,
        campaigns: campaigns,
        isSingleOffer: isSingleOffer
    };
};

export const closeCampaignList = (): AnyAction => ({
    type: CLOSE_CAMPAIGN_LIST
});

// Thunks

export function fetchOfferRecommendations(controlId: string) {
    return makeRequestThunk("api/matrix/recommendation/view/" + controlId, {
        okDispatchFunc: receiveOfferRecommendations,
        preRequestFunc: requestOfferRecommendations
    });
}

export function fetchOfferRecommendationsReport(
    controlId: string,
    offerIds: string[],
    metric: string
) {
    return makeRequestThunk(
        "api/matrix/recommendation/report/" +
            controlId +
            "/" +
            metric +
            "/" +
            offerIds.join(","),
        {
            okDispatchFunc: receiveOfferRecommendationsReport,
            preRequestFunc: requestOfferRecommendationsReport
        }
    );
}

export function moveOffer(offerId: string, action: OfferMove) {
    // Send in correct request body.
    return makeRequestThunk("api/matrix/recommendation/change", {
        bodyFunc: function (state: RootState): any {
            return {
                objectType: "MatrixRecommendationRequest",
                controlId: state.offerRecommendations.controlId,
                offerId: offerId,
                action: {
                    objectType: "MatrixRecommendationRequestAction",
                    value: action
                },
                recommendedSortBy: state.offerRecommendations.recommendedSortBy,
                recommendedSortOrder:
                    state.offerRecommendations.recommendedSortOrder,
                considerSortBy: state.offerRecommendations.considerSortBy,
                considerSortOrder: state.offerRecommendations.considerSortOrder,
                abandonSortBy: state.offerRecommendations.abandonSortBy,
                abandonSortOrder: state.offerRecommendations.abandonSortOrder
            };
        },
        isCancellable: false,
        method: METHOD_POST,
        okDispatchFunc: receiveOfferRecommendations
    });
}

export function resetRecommendations(controlId: string) {
    return makeRequestThunk("api/matrix/recommendation/reset/" + controlId, {
        isCancellable: false,
        okDispatchFunc: receiveOfferRecommendations
    });
}

export function sortOffers(category: OfferCategory, sortBy: string) {
    return makeRequestThunk("api/matrix/recommendation/sort", {
        bodyFunc: function (state: RootState): any {
            return {
                objectType: "MatrixRecommendationSortRequestTO",
                controlId: state.offerRecommendations.controlId,
                category: {
                    objectType: "RecommendationCategory",
                    value: category
                },
                sortBy: sortBy,
                sortOrder: getSortOrder(
                    state.offerRecommendations,
                    category,
                    sortBy
                )
            };
        },
        method: METHOD_POST,
        okDispatchFunc: function (json: Json, state: RootState): AnyAction {
            return receiveCategorySorted(category, sortBy, json);
        }
    });
}
