import * as React from "react";
import { getTrackingEventData, trackEvent } from "common/util/tracking";
import type {
    TrackingComponentLabel,
    TrackingEventName
} from "common/util/trackingEvents";

export type WithClickTrackingProps = {
    children?: React.ReactNode | React.ReactNode[];
    onClick: ClickHandler;
    trackingComponentLabel: TrackingComponentLabel;
    trackingEventName?: TrackingEventName;
    trackingEventValue?: string;
};

export function withClickTracking<Props>(
    WrappedComponent: ReactComponentCreator<Props & WithClickTrackingProps>,
    trackingComponentType: string,
    trackingEventName: TrackingEventName = "",
    trackingEventValue = ""
) {
    const WithClickTracking = React.forwardRef(
        (props: Props & WithClickTrackingProps, ref) => {
            let eventName = props.trackingEventName;
            if (!eventName) {
                eventName = trackingEventName;
            }
            let eventValue = props.trackingEventValue;
            if (!eventValue) {
                eventValue = trackingEventValue;
            }
            const trackingComponentLabel = props.trackingComponentLabel;
            let wrappedProps = { ...props };
            if (props.onClick) {
                wrappedProps = Object.assign({}, props, {
                    onClick: function (event: React.SyntheticEvent<any>) {
                        if (eventName) {
                            trackEvent(
                                getTrackingEventData(
                                    trackingComponentType,
                                    trackingComponentLabel,
                                    eventName,
                                    eventValue
                                )
                            );
                        }
                        if (props.onClick) {
                            props.onClick(event);
                        }
                    }
                });
            }
            return <WrappedComponent {...wrappedProps} ref={ref} />;
        }
    );
    WithClickTracking.displayName =
        "WithClickTracking(" + trackingComponentType + ")";
    return WithClickTracking;
}

export function withOutsideClickTracking(
    trackingComponentLabel: TrackingComponentLabel,
    trackingEventName: TrackingEventName = "",
    trackingEventValue = ""
) {
    return trackEvent(
        getTrackingEventData(
            "Outside Click",
            trackingComponentLabel,
            trackingEventName,
            trackingEventValue
        )
    );
}
