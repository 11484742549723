import * as React from "react";
import styled from "styled-components/macro";
import ExpandIcon from "common/images/plus_square.png";
import CollapseIcon from "common/images/minus_square.png";

export const TreeNodeItem = styled.div`
    align-items: center;
    display: flex;
    padding: 9px 0px 10px 0px;
    white-space: normal;
`;
TreeNodeItem.displayName = "TreeNodeItem";

type TreeNodeLabelProps = {
    checkboxDisabled?: boolean;
};

export const TreeNodeLabel = styled.label<TreeNodeLabelProps>`
    display: flex;
    align-items: center;
    ${props => props.checkboxDisabled && `opacity: .75;`}
    cursor: ${props => (props.checkboxDisabled ? "not-allowed" : "pointer")};
    margin-bottom: 0;
`;
TreeNodeLabel.displayName = "TreeNodeLabel";

export const ExpandCollapseButton = styled.button`
    display: flex;
    align-items: center;
    border: 0;
    background: none;
    outline: none;
    line-height: normal;
    padding: 0 10px 0 0;
    color: inherit;
    cursor: pointer;
    :focus {
        outline: none;
    }
    :disabled {
        cursor: not-allowed;
    }
`;
ExpandCollapseButton.displayName = "ExpandCollapseButton";

type TreeNodeIconWrapperProps = {
    hasCheckbox?: boolean;
};

export const TreeNodeIconWrapper = styled.div<TreeNodeIconWrapperProps>`
    display: flex;
    padding: ${props => (props.hasCheckbox ? "0 0 0 8px" : "0")};
`;
TreeNodeIconWrapper.displayName = "TreeNodeIconWrapper";

type TreeNodeTitleProps = TreeNodeIconWrapperProps & {
    hasIcon?: boolean;
};

export const TreeNodeTitle = styled.span<TreeNodeTitleProps>`
    padding: ${props =>
        props.hasCheckbox || props.hasIcon ? "0 0 0 8px" : "0"};
`;
TreeNodeTitle.displayName = "TreeNodeTitle";

export const TreeNodeIcon = styled.img`
    user-select: none;
`;
TreeNodeIcon.displayName = "TreeNodeIcon";

type TreeNodeProps = {
    children: React.ReactNode;
    disabled: boolean;
    expanded: boolean;
    label: string;
    onExpand: (expandData: any) => void;
    treeId: string;
    value: string;
};

class TreeNode extends React.Component<TreeNodeProps> {
    static defaultProps = {
        children: null,
        disabled: false
    };

    onExpand = () => {
        this.props.onExpand({
            value: this.props.value,
            expanded: !this.props.expanded
        });
    };

    renderCollapseButton = () => {
        const { disabled } = this.props;
        return (
            <ExpandCollapseButton
                aria-label="Toggle"
                disabled={disabled}
                title="Toggle"
                type="button"
                onClick={this.onExpand}
            >
                {this.renderCollapseIcon()}
            </ExpandCollapseButton>
        );
    };

    renderCollapseIcon = () => {
        const icon = !this.props.expanded ? ExpandIcon : CollapseIcon;
        return <TreeNodeIcon src={icon} />;
    };

    renderChildren = () => {
        if (!this.props.expanded) {
            return null;
        }

        return this.props.children;
    };

    render() {
        const { label, treeId, value } = this.props;
        const inputId = `${treeId}-${value.split(" ").join("_")}`;

        return (
            <React.Fragment>
                <TreeNodeItem>
                    {this.renderCollapseButton()}
                    <TreeNodeLabel htmlFor={inputId}>
                        <TreeNodeTitle>{label}</TreeNodeTitle>
                    </TreeNodeLabel>
                </TreeNodeItem>
                {this.renderChildren()}
            </React.Fragment>
        );
    }
}

export default TreeNode;
