import get from "lodash.get";
import { getReportStateKey } from "common/reports/state/reportsStateUtils";
import { CUSTOM, getStaticStartAndEndDates } from "common/util/date";
import type { DateFilter } from "common/util/date";
import type { RootState } from "store";

const PROMO_REPORT_COMPARISON_TIME_PERIOD = "PromoReportComparisonTimePeriod";

export function setRequestDates(
    request: any,
    date: any,
    timePeriodType: string
): void {
    request.startDate =
        get(date, "time") === CUSTOM ? get(date, "startDate", "") : null;
    request.endDate =
        get(date, "time") === CUSTOM ? get(date, "endDate", "") : null;
    request.timePeriod = {
        objectType: timePeriodType,
        value: get(date, "time", null)
    };
}

export function setRequestDatesStatic(
    request: any,
    date: DateFilter,
    timePeriodType: string,
    state: RootState,
    reportType: string,
    reportView: string,
    stateKey = "dataTable"
): void {
    const startDate = get(
        state,
        getReportStateKey(reportType, reportView, stateKey + ".startDate")
    );
    const endDate = get(
        state,
        getReportStateKey(reportType, reportView, stateKey + ".endDate")
    );
    // Set custom date if start and end dates are provided
    // Else derive static dates from the date filter
    if (startDate && endDate) {
        request.startDate = startDate;
        request.endDate = endDate;
        request.timePeriod = {
            objectType: timePeriodType,
            value: CUSTOM
        };
    } else if (date) {
        const dates = getStaticStartAndEndDates(date.time);
        if (dates.startDate && dates.endDate) {
            request.startDate = dates.startDate;
            request.endDate = dates.endDate;
            request.timePeriod = {
                objectType: timePeriodType,
                value: CUSTOM
            };
        }
    }
}

export function setRequestComparisonDate(request: any, date: any): void {
    request.comparisonTimePeriod = {
        objectType: PROMO_REPORT_COMPARISON_TIME_PERIOD,
        value: get(date, "time", null)
    };
}

export function setRequestSearch<ReportType, ReportView>(
    request: any,
    reportType: ReportType,
    reportView: ReportView,
    state: RootState
): void {
    request.search = get(
        state,
        getReportStateKey(reportType, reportView, "dataTable.search")
    );
}

export function setRequestSort<ReportType, ReportView>(
    request: any,
    reportType: ReportType,
    reportView: ReportView,
    state: RootState
): void {
    request.sortBy = get(
        state,
        getReportStateKey(reportType, reportView, "dataTable.sortBy")
    );
    request.sortOrder = get(
        state,
        getReportStateKey(reportType, reportView, "dataTable.sortOrder")
    );
}
