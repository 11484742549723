import noAction from "common/util/noAction";
import {
    RECEIVE_TEAM,
    RECEIVE_TEAM_RETAILERS_DRAWER,
    RECEIVE_TEAM_USERS_DRAWER,
    REQUEST_TEAM,
    REQUEST_TEAM_RETAILERS_DRAWER,
    REQUEST_TEAM_USERS_DRAWER,
    UPDATE_TEAM_RETAILERS_DRAWER_SORT,
    UPDATE_TEAM_USERS_DRAWER_SORT
} from "common/administration/teams/state/teamActions";
import type { TeamActions } from "common/administration/teams/state/teamActions";
import {
    TEAM_PAGE,
    TEAM_CREATE_PAGE,
    TEAM_EDIT_PAGE
} from "common/administration/state/pageActions";
import type { TeamState } from "common/administration/teams/state/teamStateUtils";
import {
    defaultState,
    reduceTeam
} from "common/administration/teams/state/teamStateUtils";

const team = (
    state: TeamState = defaultState,
    action: TeamActions = noAction
): TeamState => {
    switch (action.type) {
        case TEAM_PAGE:
            return Object.assign({}, defaultState, {
                entityId: action.payload && action.payload.entityId
            });
        case TEAM_CREATE_PAGE:
            return defaultState;
        case TEAM_EDIT_PAGE:
            return Object.assign({}, defaultState, {
                entityId: action.payload && action.payload.entityId
            });
        case REQUEST_TEAM:
            return state;
        case RECEIVE_TEAM:
            return Object.assign({}, state, reduceTeam(action.item, state));
        case RECEIVE_TEAM_RETAILERS_DRAWER:
            return Object.assign({}, state, {
                retailersDrawer: Object.assign(
                    {},
                    {
                        ...state.retailersDrawer,
                        totalCount: action.items.totalCount
                    }
                )
            });
        case RECEIVE_TEAM_USERS_DRAWER:
            return Object.assign({}, state, {
                usersDrawer: Object.assign(
                    {},
                    {
                        ...state.usersDrawer,
                        totalCount: action.items.totalCount
                    }
                )
            });
        case REQUEST_TEAM_RETAILERS_DRAWER:
            return state;
        case REQUEST_TEAM_USERS_DRAWER:
            return state;
        case UPDATE_TEAM_RETAILERS_DRAWER_SORT:
            return Object.assign({}, state, {
                retailersDrawer: Object.assign(
                    {},
                    {
                        ...state.retailersDrawer,
                        sortBy: action.sortBy,
                        sortOrder: action.sortOrder
                    }
                )
            });
        case UPDATE_TEAM_USERS_DRAWER_SORT:
            return Object.assign({}, state, {
                usersDrawer: Object.assign(
                    {},
                    {
                        ...state.usersDrawer,
                        sortBy: action.sortBy,
                        sortOrder: action.sortOrder
                    }
                )
            });
        default:
            return state;
    }
};

export default team;
