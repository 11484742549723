import get from "lodash.get";

export type AnalysisType =
    | typeof ANALYSIS_TYPE_CAMPAIGN_COMPARISON
    | typeof ANALYSIS_TYPE_CONSUMER_ENGAGEMENT
    | typeof ANALYSIS_TYPE_DATA_TABLE
    | typeof ANALYSIS_TYPE_FACTOR
    | typeof ANALYSIS_TYPE_OFFER_PERFORMANCE
    | typeof ANALYSIS_TYPE_OFFER_PERFORMANCE_PRODUCT_BREAKDOWN
    | typeof ANALYSIS_TYPE_SCATTER_PLOT
    | typeof ANALYSIS_TYPE_SEGMENTED_CONSUMER_ENGAGEMENT
    | typeof ANALYSIS_TYPE_TAKE_RATE;

export const ANALYSIS_TYPE_CAMPAIGN_COMPARISON = "CAMPAIGN_COMPARISON";
export const ANALYSIS_TYPE_CONSUMER_ENGAGEMENT = "CONSUMER_ENGAGEMENT";
export const ANALYSIS_TYPE_DATA_TABLE = "DATA_TABLE";
export const ANALYSIS_TYPE_FACTOR = "CROSS_CAMPAIGN_FACTOR_ANALYSIS";
export const ANALYSIS_TYPE_OFFER_PERFORMANCE = "OFFER_PERFORMANCE";
export const ANALYSIS_TYPE_OFFER_PERFORMANCE_PRODUCT_BREAKDOWN =
    "OFFER_PERFORMANCE_PRODUCT_BREAKDOWN";
export const ANALYSIS_TYPE_SCATTER_PLOT = "SCATTER_PLOT";
export const ANALYSIS_TYPE_SEGMENTED_CONSUMER_ENGAGEMENT =
    "SEGMENTED_CONSUMER_ENGAGEMENT";
export const ANALYSIS_TYPE_TAKE_RATE = "TAKE_RATE";

// Typing for some of factor analysis init values
export type InitFactorAnalysisFormValuesType = {
    discountPercentRangeMaximum: number;
    discountPercentRangeMinimum: number;
    totalCampaigns: number;
    totalOffers: number;
    unitPriceToConsumerMaximum: number | null;
    unitPriceToConsumerMinimum: number | null;
};

export const defaultInitFactorAnalysisFormValues: InitFactorAnalysisFormValuesType = {
    discountPercentRangeMaximum: 0,
    discountPercentRangeMinimum: 0,
    totalCampaigns: 0,
    totalOffers: 0,
    unitPriceToConsumerMaximum: null,
    unitPriceToConsumerMinimum: null
};

type AnalysisDisplayType =
    | typeof ANALYSIS_DISPLAY_TYPE_BAR
    | typeof ANALYSIS_DISPLAY_TYPE_LIST
    | typeof ANALYSIS_DISPLAY_TYPE_SCATTER;

export const ANALYSIS_DISPLAY_TYPE_BAR = "BAR";
export const ANALYSIS_DISPLAY_TYPE_LIST = "LIST";
export const ANALYSIS_DISPLAY_TYPE_SCATTER = "SCATTER";

// Const for cross campaign factor analysis types
export const ANALYSIS_FACTOR_TOTAL_QUANTITY = "TOTAL_QUANTITY";

export type OfferLabelPersist = {
    entityId: string;
    fieldSet: string;
    objectType: string;
    offerLabel: string;
};

// offers
export const PROMOTION_NAME = "promotion_name";
export const PROMOTION_OFFER_LABEL = "promotion_offerLabel";

// factors
export const FACTOR_GROUPS = "customFactorGroupsFlattened";
export const FACTOR_NAME = "name";
export const FACTOR_VALUE_GROUP = "groupName";

// chart records
export const CHART_RECORD_CEI = "consumerEngagementIndexFormatNumber";
export const CHART_RECORD_CONFIDENCE_INTERVAL = "confidenceInterval";
export const CHART_RECORD_IS_CONTROL = "isControl";
export const CHART_RECORD_IS_REFERENCE = "isReferencePromotion";
export const CHART_RECORD_OFFER_LABEL = "offerLabel";
export const CHART_RECORD_PROMOTION_NAME = "promotionName";
export const CHART_RECORD_REFERENCE_LIFT = "referenceLiftFormatPercent";
export const CHART_RECORD_REFERENCE_CONFIDENCE =
    "referenceConfidenceFormatPercent";
export const CHART_RECORD_PRICE_PER_UNIT = "pricePerUnitFormatCurrency";
export const CHART_RECORD_EFFECTIVE_PRICE_TO_CONSUMER =
    "effectivePriceToConsumerFormatCurrency";
export const CHART_RECORD_MARGIN_PER_UNIT_AFTER_DISCOUNT =
    "marginPerUnitAfterDiscountFormatCurrency";
export const CHART_RECORD_TOTAL_DOLLARS_OFF = "totalDollarsOffFormatCurrency";
export const CHART_RECORD_DISCOUNT_PERCENT = "discountPercentFormatPercent";
export const CHART_RECORD_EFFECTIVE_DISCOUNT_PERCENT =
    "effectiveDiscountPercentFormatPercent";
export const CHART_RECORD_EFFECTIVE_TRADE_RATE =
    "effectiveTradeRateFormatPercent";

export const CHART_DEFINITION_NAME = "name";
export const CHART_DEFINITION_CAMPAIGN = "campaign_name";
export const CHART_DEFINITION_CHART_TYPE = "chartType.displayName";
export const CHART_DEFINITION_CREATED_BY = "creator_fullName";
export const CHART_DEFINITION_DESCRIPTION = "headline";
export const CHART_DEFINITION_MODIFIED_BY = "modifiedBy_fullName";
export const CHART_DEFINITION_MODIFIED_DATE = "modifiedDateFormatDate";

export type BreakdownSegmentSetGroup = {
    group: boolean;
    displayName: string;
} & BreakdownSegmentSet;

export const getChartDisplayType = (
    analysisType: AnalysisType | null
): AnalysisDisplayType => {
    let displayType: AnalysisDisplayType;

    switch (analysisType) {
        case ANALYSIS_TYPE_CONSUMER_ENGAGEMENT:
            displayType = ANALYSIS_DISPLAY_TYPE_BAR;
            break;
        case ANALYSIS_TYPE_DATA_TABLE:
            displayType = ANALYSIS_DISPLAY_TYPE_LIST;
            break;
        case ANALYSIS_TYPE_SCATTER_PLOT:
            displayType = ANALYSIS_DISPLAY_TYPE_SCATTER;
            break;
        case ANALYSIS_TYPE_SEGMENTED_CONSUMER_ENGAGEMENT:
            displayType = ANALYSIS_DISPLAY_TYPE_BAR;
            break;
        case ANALYSIS_TYPE_OFFER_PERFORMANCE:
            displayType = ANALYSIS_DISPLAY_TYPE_BAR;
            break;
        case ANALYSIS_TYPE_OFFER_PERFORMANCE_PRODUCT_BREAKDOWN:
            displayType = ANALYSIS_DISPLAY_TYPE_BAR;
            break;
        case ANALYSIS_TYPE_CAMPAIGN_COMPARISON:
            displayType = ANALYSIS_DISPLAY_TYPE_BAR;
            break;
        default:
            displayType = ANALYSIS_DISPLAY_TYPE_BAR;
    }
    return displayType;
};

export const reduceChartRecordData = (data: ChartRecord) => {
    const ceLower = get(data, "consumerEngagement90LowerFormatNumber");
    const ceUpper = get(data, "consumerEngagement90UpperFormatNumber");
    let confidenceInterval = "";
    if (ceLower && ceUpper) {
        confidenceInterval = ceLower + " - " + ceUpper;
    }
    return {
        ...data,
        confidenceInterval: confidenceInterval
    };
};
