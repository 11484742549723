import * as React from "react";
import type { TFunction } from "i18next";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import isNil from "lodash.isnil";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { ExpandCollapseSpacer } from "common/components/CheckboxTreeNode";
import Flexbox from "common/components/styled/Flexbox";
import FlexboxWithBorder from "common/components/styled/FlexboxWithBorder";
import theme from "common/components/theme";
import Tooltip from "common/components/Tooltip";
import {
    ExpandCollapseButton,
    TreeNodeIcon,
    TreeNodeItem,
    TreeNodeLabel,
    TreeNodeTitle
} from "common/components/TreeNode";
import FilterDropdownSearchBar from "common/filter/components/FilterDropdownSearchBar";
import { FilterDropdownHeaderClearComponent } from "common/filter/components/FilterDropdownView";
import TreeContainer from "common/filter/reactVirtualizedTree/components/TreeContainer";
import { UPDATE_TYPE } from "common/filter/reactVirtualizedTree/state/treeStateUtils";
import type { TreeNode } from "common/filter/reactVirtualizedTree/state/treeStateUtils";
import checkboxDashSelectedIcon from "common/images/checkbox_dash_selected.png";
import checkboxSelectedIcon from "common/images/checkbox_selected.png";
import checkboxUnselectedIcon from "common/images/checkbox_unselected.png";
import collapseIcon from "common/images/minus_square.png";
import expandIcon from "common/images/plus_square.png";
import { processSelectedItemsText } from "common/util/filter";
import { isEmptyString } from "common/util/lang";
import { getEntityIdWithoutVersion } from "common/util/object";
import { getTrackingEventData, trackEvent } from "common/util/tracking";
import { EVENT_NAME_SELECTION_CHOICE } from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";

export type NodeLevel = {
    name: string;
    level: number;
    pluralName: string;
};

// Creates a tree to a certain level
export const buildTreeToLevel = (nodes: any[], levelLimit: number): any[] => {
    return nodes.map(node => {
        if (node.level < levelLimit) {
            const children =
                node.children && node.children.length > 0
                    ? buildTreeToLevel(node.children, levelLimit)
                    : [];
            return {
                ...node,
                children
            };
        } else if (node.level === levelLimit) {
            return {
                ...node,
                children: []
            };
        } else {
            return node;
        }
    });
};

// Add node to newTree if level matches, else go down tree until node level is found

type FindNodeType = {
    children: FindNodeType[];
    value: string;
};

const findNodeChildren = (
    node: FindNodeType,
    nodeValue: string
): any[] | undefined | null => {
    if (
        isEqual(
            getEntityIdWithoutVersion(node.value),
            getEntityIdWithoutVersion(nodeValue)
        )
    ) {
        return node.children;
    } else if (node.children) {
        return getNodeChildren(node.children, nodeValue);
    }
    return null;
};

// Find node and return its children as tree
export const getNodeChildren = (
    nodes: FindNodeType[],
    nodeValue: string
): TreeNode[] | undefined | null => {
    if (isNil(nodeValue) || isEmptyString(nodeValue)) {
        return null;
    }
    for (let x = 0; x < nodes.length; x++) {
        const children = findNodeChildren(nodes[x], nodeValue);
        if (children) {
            return children;
        }
    }
    return null;
};

// Builds filter text tooltips
type NodeFilterType = {
    children?: NodeFilterType[];
    label: string;
    level: number | string;
    value: string;
};

export const getCheckboxTreeFilterTooltips = (
    treeNodes: NodeFilterType[],
    nodeLevels: NodeLevel[],
    selected: string[],
    t: TFunction
) => {
    // Mapping selected nodes to correct levels
    const levelMap: Dictionary<string[]> = {};
    const selectedPills: React.ReactNode[] = [];
    const selectedValues = selected || [];

    // Go through tree and push labels to map
    // Only grab highest node level if it is selected
    // No need to list all children labels
    const mapLabelToLevel = (nodes: NodeFilterType[]) => {
        nodes.forEach((node: NodeFilterType) => {
            const found = selectedValues.find(value => value === node.value);
            if (found) {
                if (!levelMap[node.level]) {
                    levelMap[node.level] = [node.label];
                } else {
                    levelMap[node.level].push(node.label);
                }
            } else if (node.children && node.children.length > 0) {
                mapLabelToLevel(node.children);
            }
        });
    };

    mapLabelToLevel(treeNodes);

    Object.keys(levelMap).forEach((key, index) => {
        const selectedLabels = levelMap[key];
        const selectedLevel = nodeLevels.find(
            nodeLevel => nodeLevel.level.toString() === key
        );
        if (selectedLevel) {
            const levelDisplayName = t("common:general.label_count", {
                label: selectedLevel.name,
                labelPlural: selectedLevel.pluralName,
                count: selectedLabels.length
            });
            const tooltipNode = processSelectedItemsText(selectedLabels, t).map(
                (label, index) => <div key={index}>{label}</div>
            );
            selectedPills.push(
                <Tooltip key={levelDisplayName + "_" + index}>
                    <PillWrapper>{levelDisplayName}</PillWrapper>
                    <div>{tooltipNode}</div>
                </Tooltip>
            );
        }
    });

    return selectedPills;
};

const DEFAULT_HEIGHT = "275px";

const CheckboxTreeFilterContainer = styled.div`
    width: 100%;
`;
CheckboxTreeFilterContainer.displayName = "CheckboxTreeFilterContainer";

const TreeSelectWrapper = styled.div`
    display: flex;

    /* don't show hidden input field */
    input {
        display: none;
    }
`;
TreeSelectWrapper.displayName = "TreeSelectWrapper";

const PillsContainerWrapper = styled.div`
    overflow: hidden;
    white-space: nowrap;
`;

const PillWrapper = styled.div`
    align-items: center;
    display: inline-flex;
    margin-right: 8px;

    &:last-child {
        margin-right: 0px;
    }
`;
PillWrapper.displayName = "PillWrapper";

type TreeNodeOnChangeProps = {
    node: TreeNode;
    type: number;
};

type TreeNodeProps = {
    children: React.ReactNode[];
    hasMultipleLevels: boolean;
    node: TreeNode;
    onChange: (props: TreeNodeOnChangeProps) => void;
};

const TreeNodeValue = ({
    children,
    hasMultipleLevels,
    node,
    onChange,
    ...rest
}: TreeNodeProps) => {
    const isExpanded = get(node, "state.expanded", false);
    const isSelected = get(node, "state.selected", false);
    const allChildrenSelected = get(node, "state.allChildrenSelected", false);

    const onExpand = () => {
        onChange({
            node: {
                ...node,
                state: {
                    ...node.state,
                    expanded: !isExpanded
                }
            },
            type: UPDATE_TYPE.UPDATE
        });
    };
    // State transitions:
    // Not selected -> Selected
    // Partially selected -> Selected
    // Selected -> Not selected
    const onSelect = () => {
        onChange({
            node: {
                ...node,
                state: {
                    ...node.state,
                    selected: !isSelected
                        ? true
                        : allChildrenSelected
                        ? false
                        : true
                }
            },
            type: UPDATE_TYPE.SELECT
        });
    };

    const expandCollapseIcon = isExpanded ? collapseIcon : expandIcon;
    const expandCollapseButton =
        node.children && node.children.length > 0 ? (
            <ExpandCollapseButton
                aria-label="Toggle"
                onClick={onExpand}
                title="Toggle"
                type="button"
            >
                <TreeNodeIcon src={expandCollapseIcon} />
            </ExpandCollapseButton>
        ) : hasMultipleLevels ? (
            <ExpandCollapseSpacer hasCheckbox={true} />
        ) : null;
    const checkboxIcon = isSelected
        ? allChildrenSelected
            ? checkboxSelectedIcon
            : checkboxDashSelectedIcon
        : checkboxUnselectedIcon;

    return (
        <TreeNodeItem>
            {expandCollapseButton}
            <TreeNodeLabel>
                <TreeSelectWrapper>
                    <input
                        checked={isSelected}
                        type="checkbox"
                        onChange={onSelect}
                    />
                    <TreeNodeIcon src={checkboxIcon} />
                </TreeSelectWrapper>
                <TreeNodeTitle hasCheckbox={true}>{children}</TreeNodeTitle>
            </TreeNodeLabel>
        </TreeNodeItem>
    );
};

type CheckboxTreeFilterViewOwnProps = {
    cssHeight?: string;
    name: string;
    nodeLevels: NodeLevel[];
    nodes: any[];
    onFilterChange: (checked: string[]) => void;
    selected: string[];
    trackingComponentLabel: TrackingComponentLabel;
};

type CheckboxTreeFilterViewProps = CheckboxTreeFilterViewOwnProps &
    WithTranslation;

type CheckboxTreeFilterViewState = {
    nodes: TreeNode[];
    search: string;
    selectedNodeValues: string[];
};

class CheckboxTreeFilterView extends React.Component<
    CheckboxTreeFilterViewProps,
    CheckboxTreeFilterViewState
> {
    static displayName = "CheckboxTreeFilterView";
    static defaultProps: { nodeLevels: NodeLevel[]; nodes: any[] } = {
        nodeLevels: [],
        nodes: []
    };

    state = {
        nodes: [],
        search: "",
        selectedNodeValues: []
    };

    componentDidMount() {
        const { nodes, selected } = this.props;
        const initialNodes = this.updateTreeSelectValues(
            this.initNodes(nodes),
            selected
        );
        this.setState({
            nodes: initialNodes,
            selectedNodeValues: selected
        });
    }

    componentDidUpdate(
        prevProps: CheckboxTreeFilterViewProps,
        prevState: CheckboxTreeFilterViewState
    ) {
        const { nodes, onFilterChange, selected } = this.props;
        const { nodes: stateNodes, selectedNodeValues } = this.state;
        // Nodes changed so update state
        if (!isEqual(prevProps.nodes, nodes)) {
            const initialNodes = this.updateTreeSelectValues(
                this.initNodes(nodes),
                selected
            );
            this.setState({
                nodes: initialNodes,
                search: "",
                selectedNodeValues: selected
            });
        }
        // Node state changed, check if selected nodes changed and update state and trigger onFilterChange
        if (!isEqual(prevState.nodes, stateNodes)) {
            const currentSelectedNodeValues = this.getSelectedNodes(stateNodes);
            if (
                !isEqual(
                    currentSelectedNodeValues,
                    prevState.selectedNodeValues
                )
            ) {
                this.setState(
                    { selectedNodeValues: currentSelectedNodeValues },
                    () => {
                        onFilterChange(currentSelectedNodeValues);
                    }
                );
            }
        }
        // If since state changed and is updating selected nodes
        // Select values have changed
        // Selected nodes different than what is selected in state, update state
        if (
            !isEqual(prevProps.selected, selected) &&
            !isEqual(selected, selectedNodeValues)
        ) {
            const updatedNodes = this.updateTreeSelectValues(
                stateNodes,
                selected
            );
            this.setState({
                nodes: updatedNodes,
                selectedNodeValues: selected
            });
        }
    }

    // Set up name, id, and state for each node based off label and value values
    initNodes = (nodes: any[]): TreeNode[] => {
        return nodes.map(node => {
            const state = node.state
                ? node.state
                : {
                      allChildrenSelected: false,
                      expanded: false,
                      selected: false
                  };
            const children =
                node.children && node.children.length > 0
                    ? this.initNodes(node.children)
                    : node.children;
            return {
                ...node,
                children,
                id: node.value,
                name: node.label,
                state
            };
        });
    };

    // Update nodes after updates
    handleChange = (nodes: TreeNode[]) => {
        this.setState({
            nodes: this.verifySelectState(nodes)
        });
    };

    /* --------------- Selected Node Functions --------------- */
    // Check to select state is correct: Make sure all children are selected if marked as selected, etc.
    // Note: Remember selected state is based off visible nodes!
    verifySelectState = (nodes: TreeNode[]): TreeNode[] => {
        return nodes.map(node => {
            // Verify childrens selected state and adjust own selected state
            if (node.children && node.children.length > 0) {
                const children = this.verifySelectState(node.children);
                const allChildrenSelected = children.every(node =>
                    get(node, "state.allChildrenSelected")
                );
                const selected = allChildrenSelected
                    ? allChildrenSelected
                    : !!children.find(node => get(node, "state.selected"));
                return {
                    ...node,
                    children,
                    state: {
                        ...node.state,
                        allChildrenSelected,
                        selected
                    }
                };
            } else if (node.children && node.children.length === 0) {
                return {
                    ...node,
                    state: {
                        ...node.state,
                        allChildrenSelected: get(node, "state.selected", false)
                    }
                };
            }
            return node;
        });
    };

    // Update tree with the proper select values, will update node and all children to be selected
    updateTreeSelectValues = (
        nodes: TreeNode[],
        selected: string[]
    ): TreeNode[] => {
        // Gets full list of nodes to update
        const fullSelectedNodeIdList = this.buildFullSelectedNodeList(
            nodes,
            selected,
            []
        );
        // Updated tree with nodes selected
        const updatedTree = this.selectNodes(
            this.clearSelected(nodes),
            true,
            fullSelectedNodeIdList
        );
        // Verify state of new tree is good and set to state
        return this.verifySelectState(updatedTree);
    };

    // Helper to list all node ids that need to be updated for selection
    buildFullSelectedNodeList = (
        nodes: TreeNode[],
        selected: string[],
        list: string[]
    ): string[] => {
        let updatedList = list;
        nodes.forEach(node => {
            if (selected.find(id => id === node.id)) {
                updatedList = updatedList.concat(
                    this.buildSelectedNodeList(node, [])
                );
            } else if (node.children && node.children.length > 0) {
                updatedList = this.buildFullSelectedNodeList(
                    node.children,
                    selected,
                    updatedList
                );
            }
        });
        return updatedList;
    };

    // Helper to get node ids of children to make list for selection update
    // This is selection of a single node and its children
    buildSelectedNodeList = (node: TreeNode, list: string[]): string[] => {
        list.push(node.id);
        if (node.children && node.children.length > 0) {
            node.children.forEach(child => {
                this.buildSelectedNodeList(child, list);
            });
        }
        return list;
    };

    // Helper to select or unselect nodes explicitly in list
    selectNodes = (
        nodes: TreeNode[],
        selected: boolean,
        list: string[]
    ): TreeNode[] => {
        return nodes.map(node => {
            // if id is found, update the state
            return {
                ...node,
                children: node.children
                    ? this.selectNodes(node.children, selected, list)
                    : [],
                state: list.find(id => id === node.id)
                    ? {
                          ...node.state,
                          selected
                      }
                    : node.state
            };
        });
    };

    // Returns back all nodes with updated selected state
    // Selects node and all children then verifies that checks are valid
    // ex. parent only selected if all children are selected
    nodeSelectionHandler = (
        nodes: TreeNode[],
        updatedNode: TreeNode
    ): TreeNode[] => {
        const { trackingComponentLabel } = this.props;
        // Track selection of nodes, only when selected
        if (updatedNode?.state?.selected) {
            trackEvent(
                getTrackingEventData(
                    "TreeNode",
                    trackingComponentLabel,
                    EVENT_NAME_SELECTION_CHOICE
                )
            );
        }
        // Go through filtered nodes and get list of ids to update to be selected
        const nodesToSelect = this.buildSelectedNodeList(updatedNode, []);
        // Select/unselect nodes that are in the list
        return this.selectNodes(
            nodes,
            get(updatedNode, "state.selected"),
            nodesToSelect
        );
    };

    // Helper to return value of highest level selected node
    // Highest level will have allChildrenSelected
    getSelectedNodeValues = (nodes: TreeNode[], selectedList: string[]) => {
        nodes.forEach(node => {
            if (get(node, "state.allChildrenSelected")) {
                selectedList.push(node.value);
            } else if (node.children && node.children.length > 0) {
                this.getSelectedNodeValues(node.children, selectedList);
            }
        });
    };

    // Get value of selected nodes to pass to filter
    getSelectedNodes = (nodes: TreeNode[]): string[] => {
        const selectedNodeValues: string[] = [];
        this.getSelectedNodeValues(nodes, selectedNodeValues);
        return selectedNodeValues;
    };

    // Helper clear select from all nodes
    clearSelected = (nodes: TreeNode[]): TreeNode[] => {
        return nodes.map(node => {
            const children =
                node.children && node.children.length > 0
                    ? this.clearSelected(node.children)
                    : node.children;
            return {
                ...node,
                children,
                state: {
                    ...node.state,
                    allChildrenSelected: false,
                    selected: false
                }
            };
        });
    };

    // Clear selection from all nodes
    clearAllSelected = () => {
        const { nodes } = this.state;
        this.setState({ nodes: this.clearSelected(nodes) });
    };

    /* --------------- Expand Node Functions --------------- */
    // DFS and on the way back up check if at least 1 children selected but not all selected
    expandPartiallySelectedNodes = (nodes: TreeNode[]): TreeNode[] => {
        const updatedNodes = nodes.map(node => {
            const children =
                node.children && get(node, "children.length") > 0
                    ? this.expandPartiallySelectedNodes(node.children)
                    : node.children;
            const expand =
                (get(node, "state.selected") &&
                    !get(node, "state.allChildrenSelected")) ||
                children.some(child => get(child, "state.expanded"));
            // If child is selected or already expanded, then expand node
            return {
                ...node,
                children: children,
                state: {
                    ...node.state,
                    expanded: expand
                }
            };
        });
        return updatedNodes;
    };

    // DFS and expand all nodes expect for leaf nodes
    expandAllNodes = (nodes: TreeNode[], expand: boolean): TreeNode[] => {
        return nodes.map(node => {
            const children =
                node.children && get(node, "children.length") > 0
                    ? this.expandAllNodes(node.children, expand)
                    : node.children;
            return {
                ...node,
                children,
                state: {
                    ...node.state,
                    expanded: children.length > 0 ? expand : false
                }
            };
        });
    };

    /* --------------- Filter Node Functions --------------- */
    // Return nodes that match search text
    filterNodes = (nodes: TreeNode[], searchText: string): TreeNode[] => {
        return JSON.parse(JSON.stringify(nodes)).filter(function iter(
            node: TreeNode
        ) {
            if (node.label.toLowerCase().includes(searchText.toLowerCase())) {
                return true;
            }
            if (!Array.isArray(node.children)) {
                return false;
            }
            const temp = node.children.filter(iter);
            if (temp.length) {
                node.children = temp;
                return true;
            }
            return false;
        });
    };

    onFilterSearch = (searchStr: string) => {
        const { nodes } = this.state;
        const updatedNodes =
            !isNil(searchStr) && !isEmptyString(searchStr)
                ? this.expandAllNodes(nodes, true)
                : this.expandPartiallySelectedNodes(nodes);
        // Since we are only updating expanded state we don't need to verify select state on nodes
        this.setState({ nodes: updatedNodes, search: searchStr });
    };

    render() {
        const { cssHeight, nodeLevels, t, trackingComponentLabel } = this.props;
        const { nodes, search, selectedNodeValues } = this.state;

        // Pass filtered nodes to tree and full list to filtering container
        // Verify selected state is correct for filtered nodes, used to make sure parent selection is correct
        const filteredNodes =
            !isNil(search) && !isEmptyString(search)
                ? this.verifySelectState(this.filterNodes(nodes, search))
                : nodes;

        // Get pills/tooltips of selected values
        const selectedPills = getCheckboxTreeFilterTooltips(
            nodes,
            nodeLevels,
            selectedNodeValues,
            t
        );

        if (nodes.length > 0) {
            const hasMultipleLevels = !!nodes.find(
                (node: TreeNode) => node.children && node.children.length > 0
            );
            return (
                <FlexboxWithBorder cssWidth="100%" flexDirection="column">
                    <CheckboxTreeFilterContainer>
                        <Flexbox
                            backgroundColor={theme.universalBackground}
                            boxSizing="border-box"
                            cssWidth="100%"
                            flexDirection="column"
                            padding="16px"
                        >
                            <Flexbox
                                alignItems="center"
                                justifyContent="flex-end"
                                paddingBottom="8px"
                            >
                                <PillsContainerWrapper>
                                    {selectedPills}
                                </PillsContainerWrapper>
                                {selectedNodeValues.length > 0 && (
                                    <Flexbox cssWidth="46px" marginLeft="8px">
                                        <FilterDropdownHeaderClearComponent
                                            onClick={this.clearAllSelected}
                                            padding="0px"
                                            trackingComponentLabel={
                                                trackingComponentLabel
                                            }
                                        >
                                            {t("common:filter.clear_all")}
                                        </FilterDropdownHeaderClearComponent>
                                    </Flexbox>
                                )}
                            </Flexbox>
                            <FilterDropdownSearchBar
                                changeHandler={this.onFilterSearch}
                                hideBottomBorder={true}
                                showBorder={true}
                                trackingComponentLabel={trackingComponentLabel}
                            />
                        </Flexbox>
                        <Flexbox
                            cssHeight={cssHeight ? cssHeight : DEFAULT_HEIGHT}
                        >
                            <TreeContainer
                                extensions={{
                                    updateTypeHandlers: {
                                        [UPDATE_TYPE.SELECT]:
                                            this.nodeSelectionHandler
                                    }
                                }}
                                nodes={filteredNodes}
                                onChange={this.handleChange}
                                unfilteredNodes={nodes}
                            >
                                {/* TreeContainer is external code - ignoring for now */}
                                {/* @ts-expect-error Need to type properly */}
                                {({ node, style, ...rest }) => (
                                    <Flexbox
                                        style={{
                                            ...style,
                                            paddingRight: "16px",
                                            width: "max-content"
                                        }}
                                    >
                                        {/* @ts-expect-error onChange missing but part of ...rest */}
                                        <TreeNodeValue
                                            node={node}
                                            hasMultipleLevels={
                                                hasMultipleLevels
                                            }
                                            {...rest}
                                        >
                                            {node.name}
                                        </TreeNodeValue>
                                    </Flexbox>
                                )}
                            </TreeContainer>
                        </Flexbox>
                    </CheckboxTreeFilterContainer>
                </FlexboxWithBorder>
            );
        } else {
            return null;
        }
    }
}

export default withTranslation()(CheckboxTreeFilterView);
