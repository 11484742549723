import * as React from "react";
import { NavLink as ReduxFirstRouterLink } from "redux-first-router-link";
import styled from "styled-components/macro";
import type {
    DefaultLinkProps,
    TruncatedLinkProps
} from "common/components/styled/Link";
import Tooltip from "common/components/Tooltip";
import {
    marginProps,
    paddingProps,
    sizeProps
} from "common/components/styled/util";
import { getTrackingEventData, trackEvent } from "common/util/tracking";
import type { TrackingComponentLabel } from "common/util/trackingEvents";
import { EVENT_NAME_PAGE_NAVIGATION } from "common/util/trackingEvents";

type DefaultNavLinkProps = {
    children?: React.ReactNode;
    to: SimpleAction<string>;
    trackingComponentLabel: TrackingComponentLabel;
    trackingEventValue?: string;
};

const NavLinkComponent = styled(({ textAlign, ...rest }) => (
    <ReduxFirstRouterLink {...rest} />
))<DefaultLinkProps>`
    color: ${props => (props.color ? props.color : props.theme.linkText)};
    font-family: "BrandonTextRegular";
    display: ${props => (props.display ? props.display : "inline-block")};
    text-align: ${props => (props.textAlign ? props.textAlign : "center")};
    text-decoration: ${props =>
        props.textDecoration ? props.textDecoration : "underline"};

    ${props =>
        props.backgroundColor && `background-color: ${props.backgroundColor};`}
    ${props => props.overflow && `overflow: ${props.overflow};`}
    ${props => props.textOverflow && `text-overflow: ${props.textOverflow};`}

    ${marginProps}
    ${paddingProps}
    ${sizeProps}

    :hover,
    :active {
        color: ${props =>
            props.hoverColor ? props.hoverColor : props.theme.linkHoverText};
        cursor: pointer;
        text-decoration: none;
    }
`;

type NavLinkProps = DefaultNavLinkProps & DefaultLinkProps;

const NavLink = ({
    trackingComponentLabel,
    trackingEventValue,
    ...rest
}: NavLinkProps) => {
    const onClick = () => {
        trackEvent(
            getTrackingEventData(
                NavLink.displayName,
                trackingComponentLabel,
                EVENT_NAME_PAGE_NAVIGATION,
                trackingEventValue
            )
        );
    };
    return <NavLinkComponent {...rest} onClick={onClick} />;
};
NavLink.displayName = "NavLink";

export default NavLink;

type TruncatedNavLinkProps = NavLinkProps & TruncatedLinkProps;

type TruncatedWrapperProps = {
    children: JSX.Element;
} & DefaultLinkProps;

const TruncLinkWrapper = styled(NavLinkComponent)<TruncatedWrapperProps>`
    overflow: hidden;
`;
TruncLinkWrapper.displayName = "TruncLinkWrapper";

export const TruncatedNavLink = (props: TruncatedNavLinkProps) => {
    const {
        text,
        tooltipBoundary,
        tooltipMaxWidth,
        trackingComponentLabel,
        trackingEventValue,
        ...rest
    } = props;

    const onClick = () => {
        trackEvent(
            getTrackingEventData(
                TruncatedNavLink.displayName,
                trackingComponentLabel,
                EVENT_NAME_PAGE_NAVIGATION,
                trackingEventValue
            )
        );
    };

    return (
        <TruncLinkWrapper {...rest} onClick={onClick}>
            <Tooltip
                boundary={tooltipBoundary}
                checkOverflow={true}
                cssWidth="100%"
                maxWidth={tooltipMaxWidth}
                text={text}
            />
        </TruncLinkWrapper>
    );
};
TruncatedNavLink.displayName = "TruncatedNavLink";
