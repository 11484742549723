import type { AnyAction } from "redux";
import noAction from "common/util/noAction";
import { DEFAULT_PAGE_SIZE, DESCENDING } from "common/util/query";
import {
    OFFER_PROMO_SALES,
    REQUEST_RECOMMENDATIONS,
    RECEIVE_RECOMMENDATIONS,
    UPDATE_RECOMMENDATIONS_SORT_BY,
    UPDATE_RECOMMENDATIONS_OFFSET
} from "recommendations/state/recommendationActions";

type OffersState = {
    offset: number;
    pageSize: number;
    sortBy: string | null;
    sortOrder: SortOrder;
    totalCount: number;
};

const reduceRecommendations = (state: any, obj: any): any => {
    return {
        offset: obj.offset,
        pageSize: obj.pageSize,
        totalCount: obj.totalItems
    };
};

export const defaultRecommendationOfferState: OffersState = {
    offset: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    sortBy: OFFER_PROMO_SALES,
    sortOrder: DESCENDING,
    totalCount: -1
};

const recommendations = (
    state: OffersState = defaultRecommendationOfferState,
    action: AnyAction = noAction
): OffersState => {
    switch (action.type) {
        case REQUEST_RECOMMENDATIONS:
            return state;
        case RECEIVE_RECOMMENDATIONS:
            return Object.assign(
                {},
                state,
                reduceRecommendations(state, action.recommendations)
            );
        case UPDATE_RECOMMENDATIONS_OFFSET:
            return Object.assign({}, state, { offset: action.offset });
        case UPDATE_RECOMMENDATIONS_SORT_BY:
            return Object.assign({}, state, {
                sortBy: action.sortBy,
                sortOrder: action.sortOrder
            });
        default:
            return state;
    }
};

export default recommendations;
