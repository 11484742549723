// Event Tracking Names - eventTrackingName
// Limited to number of custom events GA4 will handle
// event names should have - (dash)
export const EVENT_NAME_APPLICATION_SWITCH = "application-switch" as string;
export const EVENT_NAME_CANCEL_ACTION = "cancel-action" as string;
export const EVENT_NAME_DATA_INPUT = "data-input" as string;
export const EVENT_NAME_EXECUTE_ACTION = "execute-action" as string;
export const EVENT_NAME_FILTER_APPLY = "filter-apply" as string;
export const EVENT_NAME_FILTER_CLEAR = "filter-clear" as string;
export const EVENT_NAME_FILTER_SEARCH = "filter-search" as string;
export const EVENT_NAME_LOGGED_IN = "logged-in" as string;
export const EVENT_NAME_META_INIT = "meta-init" as string;
export const EVENT_NAME_OPEN_CLOSE = "open-close" as string;
export const EVENT_NAME_PAGE_NAVIGATION = "page-navigation" as string;
export const EVENT_NAME_SELECTION_CHOICE = "selection-choice" as string;
export const EVENT_NAME_TOGGLE_VIEW = "toggle-view" as string;

export type TrackingComponentLabel = string | string[];

export type TrackingEventName =
    | typeof EVENT_NAME_APPLICATION_SWITCH
    | typeof EVENT_NAME_CANCEL_ACTION
    | typeof EVENT_NAME_DATA_INPUT
    | typeof EVENT_NAME_EXECUTE_ACTION
    | typeof EVENT_NAME_FILTER_APPLY
    | typeof EVENT_NAME_FILTER_CLEAR
    | typeof EVENT_NAME_FILTER_SEARCH
    | typeof EVENT_NAME_OPEN_CLOSE
    | typeof EVENT_NAME_PAGE_NAVIGATION
    | typeof EVENT_NAME_SELECTION_CHOICE
    | typeof EVENT_NAME_TOGGLE_VIEW;

// Event Tracking Values - eventTrackingValue - not usually needed, usually page name or select id, etc.
export const EVENT_VALUE_CLOSE_MODAL = "Close Modal";
export const EVENT_VALUE_DELETE = "Delete";
export const EVENT_VALUE_DOWNLOAD_EXPORT = "Download or Export";
export const EVENT_VALUE_HOME = "Home";
export const EVENT_VALUE_OPEN_DRAWER = "Open Drawer";
export const EVENT_VALUE_OPEN_MODAL = "Open Modal";
export const EVENT_VALUE_TESTING_APPLICATION = "Testing Application";
