import { redirect } from "redux-first-router";
import type { RoutesMap } from "redux-first-router";
import commonRouteMap from "common/commonRouteMap";
import {
    confirmLeave,
    fixEntityIdPathSegment,
    resetLeave
} from "common/routes";
import { routeMap as administrationRouteMap } from "administration/routes";
import {
    ANALYSIS_CAMPAIGN_PAGE,
    ANALYSIS_CAMPAIGN_COMPARISON_PAGE,
    ANALYSIS_FACTOR_PAGE,
    ANALYSIS_SAVED_ANALYSES_PAGE,
    CAMPAIGN_PAGE,
    CAMPAIGN_OFFERS_PAGE,
    CAMPAIGN_TARGETING_PAGE,
    CAMPAIGN_TARGETING_EDIT_PAGE,
    CAMPAIGN_PPG_EDIT_PAGE,
    CAMPAIGN_PPGS_PAGE,
    CAMPAIGN_BUDGET_OPTIMIZATION_EDIT_PAGE,
    CAMPAIGN_BUDGET_OPTIMIZATION_PAGE,
    CAMPAIGN_REDEMPTION_EDIT_PAGE,
    CAMPAIGN_REDEMPTION_PAGE,
    CAMPAIGN_UPCS_PAGE,
    CAMPAIGN_OVERVIEW_PAGE,
    CAMPAIGN_TEST_RESULTS_PAGE,
    CAMPAIGN_CREATE_PAGE,
    CAMPAIGN_EDIT_PAGE,
    CAMPAIGNS_PAGE,
    COMPLIANCE_EVENT_PAGE,
    COMPLIANCE_OVERVIEW_PAGE,
    CREATE_TEST_PAGE,
    EVENTS_PAGE,
    GUIDELINES_VIEWER_PAGE,
    GUIDELINES_VIEWER_PRINT_PAGE,
    OFFER_ADVANCED_CREATE_PAGE,
    OFFER_AUTOMATED_CREATE_PAGE,
    OFFER_EDIT_PAGE,
    OFFER_RECOMMENDATIONS_PAGE,
    OFFER_RECOMMENDATIONS_REPORT_PRINT_PAGE,
    OPPORTUNITIES_PAGE,
    OVERVIEW_PAGE,
    PROMOTION_EDIT_PAGE,
    RECOMMENDATIONS_PAGE,
    REPLACE_IMAGE_PAGE,
    REPORTS_PAGE,
    SAVED_REPORTS_PAGE
} from "shell/state/pageActions";
import { isChipOnly } from "shell/util/allowableActionUtils";
import type { AppDispatch, GetState } from "store";

export const isPromoRouteMap = (page: string) => {
    return Object.prototype.hasOwnProperty.call(promoRouteMap, page);
};

export const promoRouteMap = {
    [ANALYSIS_CAMPAIGN_PAGE]: {
        path: "/analytics/campaign/:entityId?",
        fromPath: fixEntityIdPathSegment
    },
    [ANALYSIS_CAMPAIGN_COMPARISON_PAGE]: {
        path: "/analytics/comparison/:entityId?",
        fromPath: fixEntityIdPathSegment
    },
    [ANALYSIS_FACTOR_PAGE]: {
        path: "/analytics/factor/:entityId?",
        fromPath: fixEntityIdPathSegment
    },
    [ANALYSIS_SAVED_ANALYSES_PAGE]: "/analytics/saved",
    [CAMPAIGN_BUDGET_OPTIMIZATION_PAGE]: {
        path: "/campaign/:entityId/budget",
        fromPath: fixEntityIdPathSegment
    },
    [CAMPAIGN_BUDGET_OPTIMIZATION_EDIT_PAGE]: {
        path: "/campaign/:entityId/budget/edit",
        fromPath: fixEntityIdPathSegment,
        thunk: resetLeave,
        confirmLeave: confirmLeave([CAMPAIGN_BUDGET_OPTIMIZATION_EDIT_PAGE])
    },
    [CAMPAIGN_CREATE_PAGE]: {
        path: "/campaigns/new",
        thunk: resetLeave,
        confirmLeave: confirmLeave([CAMPAIGN_CREATE_PAGE])
    },
    [CAMPAIGN_EDIT_PAGE]: {
        path: "/campaigns/:entityId/edit",
        fromPath: fixEntityIdPathSegment,
        thunk: resetLeave,
        confirmLeave: confirmLeave([CAMPAIGN_EDIT_PAGE])
    },
    [CAMPAIGN_OFFERS_PAGE]: {
        path: "/campaign/:entityId/offers",
        fromPath: fixEntityIdPathSegment
    },
    [CAMPAIGN_OVERVIEW_PAGE]: {
        path: "/campaign/:entityId/overview",
        fromPath: fixEntityIdPathSegment
    },
    [CAMPAIGN_PAGE]: {
        path:
            "/campaign/:entityId/(overview|offers|targeting|budget|redemption|results)?",
        fromPath: fixEntityIdPathSegment
    },
    [CAMPAIGN_PPG_EDIT_PAGE]: {
        path: "/campaign/:entityId/ppgs/:campaignProductGroupEntityId/edit",
        fromPath: fixEntityIdPathSegment,
        thunk: resetLeave,
        confirmLeave: confirmLeave([CAMPAIGN_PPG_EDIT_PAGE])
    },
    [CAMPAIGN_PPGS_PAGE]: {
        path: "/campaign/:entityId/ppgs",
        fromPath: fixEntityIdPathSegment
    },
    [CAMPAIGN_REDEMPTION_PAGE]: {
        path: "/campaign/:entityId/redemption",
        fromPath: fixEntityIdPathSegment
    },
    [CAMPAIGN_REDEMPTION_EDIT_PAGE]: {
        path: "/campaign/:entityId/redemption/edit",
        fromPath: fixEntityIdPathSegment,
        thunk: resetLeave,
        confirmLeave: confirmLeave([CAMPAIGN_REDEMPTION_EDIT_PAGE])
    },
    [CAMPAIGN_TARGETING_PAGE]: {
        path: "/campaign/:entityId/targeting",
        fromPath: fixEntityIdPathSegment
    },
    [CAMPAIGN_TARGETING_EDIT_PAGE]: {
        path: "/campaign/:entityId/targeting/edit",
        fromPath: fixEntityIdPathSegment,
        thunk: resetLeave,
        confirmLeave: confirmLeave([CAMPAIGN_TARGETING_EDIT_PAGE])
    },
    [CAMPAIGN_TEST_RESULTS_PAGE]: {
        path: "/campaign/:entityId/results",
        fromPath: fixEntityIdPathSegment
    },
    [CAMPAIGN_UPCS_PAGE]: {
        path: "/campaign/:entityId/upcs",
        fromPath: fixEntityIdPathSegment
    },
    [CAMPAIGNS_PAGE]: "/campaigns/:subTab?",
    [COMPLIANCE_EVENT_PAGE]: {
        path: "/event/:eventId",
        fromPath: fixEntityIdPathSegment
    },
    [EVENTS_PAGE]: "/events",
    [COMPLIANCE_OVERVIEW_PAGE]: "/compliance/(overview)?",
    [CREATE_TEST_PAGE]: {
        path: "/test/:eventId?",
        fromPath: fixEntityIdPathSegment
    },
    [GUIDELINES_VIEWER_PAGE]: "/guidelines",
    [GUIDELINES_VIEWER_PRINT_PAGE]: "/guidelines/print",
    [OFFER_ADVANCED_CREATE_PAGE]: {
        path: "/campaign/:entityId/offers/advanced/new",
        fromPath: fixEntityIdPathSegment,
        thunk: resetLeave,
        confirmLeave: confirmLeave([OFFER_ADVANCED_CREATE_PAGE])
    },
    [OFFER_AUTOMATED_CREATE_PAGE]: {
        path: "/campaign/:entityId/offers/automated/new",
        fromPath: fixEntityIdPathSegment,
        thunk: resetLeave,
        confirmLeave: confirmLeave([OFFER_AUTOMATED_CREATE_PAGE])
    },
    [OFFER_EDIT_PAGE]: {
        path: "/campaign/:entityId/offers/:promotionEntityId/edit",
        fromPath: fixEntityIdPathSegment,
        thunk: resetLeave,
        confirmLeave: confirmLeave([OFFER_EDIT_PAGE])
    },
    [OFFER_RECOMMENDATIONS_PAGE]: {
        path: "/recommendations/:controlId",
        fromPath: fixEntityIdPathSegment
    },
    [OFFER_RECOMMENDATIONS_REPORT_PRINT_PAGE]: {
        path: "/recommendations/print/:controlId/:metric/:offerIds",
        fromPath: fixEntityIdPathSegment
    },
    [OPPORTUNITIES_PAGE]: "/testplanning",
    [OVERVIEW_PAGE]: {
        path: "/(index.html|dashboard)?",
        thunk: async function (dispatch: AppDispatch, getState: GetState) {
            const state = getState();
            const isInitialized = state.init.initialized;
            const allowableActions = state.init.allowableActions;
            const chipOnly = isChipOnly(allowableActions);
            if (isInitialized) {
                if (chipOnly) {
                    dispatch(redirect({ type: REPORTS_PAGE }));
                }
            }
        }
    },
    [PROMOTION_EDIT_PAGE]: {
        path: "/campaign/:entityId/offers/:promotionEntityId/edittext",
        fromPath: fixEntityIdPathSegment,
        thunk: resetLeave,
        confirmLeave: confirmLeave([PROMOTION_EDIT_PAGE])
    },
    [RECOMMENDATIONS_PAGE]: "/recommendations",
    [REPLACE_IMAGE_PAGE]: {
        path: "/campaign/:entityId/offers/replaceimage",
        fromPath: fixEntityIdPathSegment,
        thunk: resetLeave,
        confirmLeave: confirmLeave([REPLACE_IMAGE_PAGE])
    }
};

const chipRouteMap = {
    [REPORTS_PAGE]: "/reports",
    [SAVED_REPORTS_PAGE]: "/reports/saved"
};

export const routeMap: RoutesMap = Object.assign(
    {},
    promoRouteMap,
    chipRouteMap,
    commonRouteMap,
    administrationRouteMap
);

export const isRouteAction = (action: string) => {
    return Object.prototype.hasOwnProperty.call(routeMap, action);
};
