import * as React from "react";
import styled from "styled-components/macro";
import CenteredContentWrapper from "common/components/styled/CenteredContentWrapper";
import Flexbox from "common/components/styled/Flexbox";
import { ClickableSvgIcon } from "common/components/SvgIcon";
import theme from "common/components/theme";
import { ReactComponent as LeftArrowIcon } from "common/icons/LeftArrow.svg";
import type { TrackingComponentLabel } from "common/util/trackingEvents";
import { EVENT_NAME_PAGE_NAVIGATION } from "common/util/trackingEvents";

type HeaderLeftProps = {
    centered: boolean;
};

const HeaderLeft = styled.div<HeaderLeftProps>`
    display: flex;
    flex-direction: row;
    ${props => !props.centered && `height: 100%;`}
`;

type PageHeaderTitleProps = {
    fontSize?: string;
};

export const PageHeaderTitle = styled.span<PageHeaderTitleProps>`
    font-size: ${props => (props.fontSize ? props.fontSize : "24px")};
    font-family: "BrandonTextBold";
    width: 100%;
`;
PageHeaderTitle.displayName = "PageHeaderTitle";

export const PageHeaderSubTitle = styled.span`
    color: ${theme.modalSubText};
    font-size: 12px;
`;
PageHeaderSubTitle.displayName = "PageHeaderSubTitle";

const PageHeaderDescription = styled.div``;
PageHeaderDescription.displayName = "PageHeaderDescription";

type PageHeaderContainerProps = {
    backgroundColor?: string;
    children: React.ReactNode;
    cssHeight?: string;
    centered: boolean;
    minHeight?: string;
    padding?: string;
};

const CenteredPageHeaderContainerComponent = styled.div<
    Pick<PageHeaderContainerProps, "backgroundColor" | "minHeight">
>`
    border-bottom: 1px solid ${theme.border};
    color: ${theme.text};
    text-shadow: 0 2px 2px ${theme.bannerTextShadow};
    ${props =>
        props.backgroundColor && `background-color: ${props.backgroundColor};`}
    ${props => props.minHeight && `min-height: ${props.minHeight};`}
`;

const PageHeaderContainerComponent = styled(Flexbox)`
    align-items: center;
    border-bottom: 1px solid ${theme.border};
    color: ${theme.text};
    flex-direction: row;
    justify-content: space-between;
    padding: ${props => (props.padding ? props.padding : "16px 16px")};
    text-shadow: 0 2px 2px ${theme.bannerTextShadow};
    ${props =>
        props.backgroundColor && `background-color: ${props.backgroundColor};`}
    ${props => props.cssHeight && `height: ${props.cssHeight};`}
    ${props => props.minHeight && `min-height: ${props.minHeight};`}
`;
PageHeaderContainerComponent.displayName = "PageHeaderContainerComponent";

CenteredPageHeaderContainerComponent.displayName =
    "CenteredPageHeaderContainerComponent";

export const PageHeaderContainer = (props: PageHeaderContainerProps) => {
    const { backgroundColor, centered, children, minHeight } = props;
    if (centered) {
        return (
            <CenteredPageHeaderContainerComponent
                backgroundColor={backgroundColor}
                children={children}
                minHeight={minHeight}
            />
        );
    } else {
        return <PageHeaderContainerComponent {...props} />;
    }
};
PageHeaderContainer.displayName = "PageHeaderContainer";

type ClickTrackingProps =
    | {
          backHandler?: ClickHandler;
          trackingComponentLabel: TrackingComponentLabel;
          trackingEventValue: string;
      }
    | {
          backHandler?: undefined;
          trackingComponentLabel?: TrackingComponentLabel;
          trackingEventValue?: string;
      };

type PageHeaderProps = {
    backgroundColor?: string;
    centered?: boolean;
    cssHeight?: string;
    headerCenterContent?: React.ReactNode;
    headerDescription?: React.ReactNode;
    headerRightContent?: React.ReactNode;
    headerSubTitle?: string;
    headerTitle: React.ReactNode;
    headerTitleFontSize?: string;
    minHeight?: string;
    padding?: string;
    tabContent?: React.ReactNode;
} & ClickTrackingProps;

const PageHeader = ({
    backgroundColor = theme.background,
    backHandler,
    centered = false,
    cssHeight,
    headerCenterContent,
    headerDescription,
    headerRightContent,
    headerSubTitle,
    headerTitle,
    headerTitleFontSize,
    minHeight,
    padding,
    tabContent,
    trackingComponentLabel,
    trackingEventValue
}: PageHeaderProps) => {
    let RightContent = null;
    if (headerRightContent) {
        RightContent = headerRightContent;
    }
    let CenterContent = null;
    if (headerCenterContent) {
        CenterContent = headerCenterContent;
    }
    let Tabs = null;
    if (tabContent) {
        Tabs = tabContent;
    }
    const HeaderContent = (
        <React.Fragment>
            <HeaderLeft centered={centered}>
                {backHandler && (
                    <Flexbox>
                        <ClickableSvgIcon
                            align="flex-start"
                            color={theme.arrowIcon}
                            height="24px"
                            icon={LeftArrowIcon}
                            marginRight={centered ? "28px" : "16px"}
                            marginTop="4px"
                            onClick={backHandler}
                            trackingComponentLabel={trackingComponentLabel}
                            trackingEventName={EVENT_NAME_PAGE_NAVIGATION}
                            trackingEventValue={trackingEventValue}
                            width="24px"
                        />
                    </Flexbox>
                )}
                <Flexbox flexDirection="column">
                    <Flexbox alignItems="baseline" flexDirection="column">
                        <PageHeaderTitle fontSize={headerTitleFontSize}>
                            {headerTitle}
                        </PageHeaderTitle>
                        {headerSubTitle && (
                            <PageHeaderSubTitle>
                                {headerSubTitle}
                            </PageHeaderSubTitle>
                        )}
                    </Flexbox>
                    {headerDescription && (
                        <PageHeaderDescription>
                            {headerDescription}
                        </PageHeaderDescription>
                    )}
                </Flexbox>
            </HeaderLeft>
            {CenterContent}
            {RightContent}
        </React.Fragment>
    );

    let PageHeaderContent = null;

    if (centered) {
        PageHeaderContent = (
            <CenteredContentWrapper>
                <Flexbox
                    alignItems="center"
                    cssHeight={cssHeight}
                    flexDirection="row"
                    justifyContent="space-between"
                >
                    {HeaderContent}
                </Flexbox>
                {Tabs}
            </CenteredContentWrapper>
        );
    } else {
        PageHeaderContent = (
            <React.Fragment>
                {HeaderContent}
                {Tabs}
            </React.Fragment>
        );
    }

    return (
        <PageHeaderContainer
            backgroundColor={backgroundColor}
            cssHeight={cssHeight}
            centered={centered}
            minHeight={minHeight}
            padding={padding}
        >
            {PageHeaderContent}
        </PageHeaderContainer>
    );
};

PageHeader.displayName = "PageHeader";

export default PageHeader;
