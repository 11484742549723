import type { TFunction } from "i18next";
import type { AnyAction } from "redux";
import { writeEntity } from "common/shell/state/entityActions";
import {
    openMessageBox,
    BUTTON_CANCELYES,
    BUTTON_YES,
    ICON_QUESTION
} from "common/shell/state/messageBoxActions";
import { resetCanNotLeave } from "common/shell/state/navigationActions";
import {
    makeRequestThunk,
    METHOD_DELETE,
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT
} from "common/shell/state/requestActions";
import { success } from "common/shell/state/toastActions";
import type { PostPutMethod } from "common/shell/state/requestActions";
import { stringFilterCondition } from "common/util/filter";
import { ASCENDING_QUERY_API } from "common/util/query";

import {
    HOLIDAY_PAGE,
    HOLIDAY_CREATE_PAGE,
    HOLIDAY_EDIT_PAGE,
    HOLIDAYS_PAGE
} from "administration/state/pageActions";
import {
    HOLIDAY_FIELDS,
    holidaysPage
} from "administration/holidays/state/holidaysActions";
import { reduceOccurrence } from "administration/holidays/state/holidayStateUtils";
import { fetchHolidaysReinit } from "shell/state/initActions";
import type { ActionOrThunk, Actions, AppDispatch, RootState } from "store";

// action types
export const REQUEST_HOLIDAY = "REQUEST_HOLIDAY";
export const RECEIVE_HOLIDAY = "RECEIVE_HOLIDAY";
export const REQUEST_HOLIDAY_OCCURRENCES = "REQUEST_HOLIDAY_OCCURRENCES";

// Holiday consts
export const HOLIDAY_CUSTOM = "CUSTOM";
export const HOLIDAY_OCCURRENCE_DATES = "HOLIDAY_OCCURRENCE_DATES";
export const HOLIDAY_STANDARD = "STANDARD";

// actions creators
export const requestHoliday = (): AnyAction => ({
    type: REQUEST_HOLIDAY
});

export const receiveHoliday = (json: Json): AnyAction => ({
    type: RECEIVE_HOLIDAY,
    holiday: json
});

export const requestHolidayOccurrences = (): AnyAction => ({
    type: REQUEST_HOLIDAY_OCCURRENCES
});

export const holidayPage = (entityId: string): AnyAction => ({
    type: HOLIDAY_PAGE,

    payload: {
        // matches what is in routes.js
        entityId: entityId
    }
});

export const holidayCreatePage = (): AnyAction => ({
    type: HOLIDAY_CREATE_PAGE
});

export const holidayEditPage = (entityId: string): AnyAction => ({
    type: HOLIDAY_EDIT_PAGE,

    payload: {
        // matches what is in routes.js
        entityId: entityId
    }
});

export const handleDeleteHolidayConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
) => {
    if (button === BUTTON_YES) {
        dispatch(
            deleteHoliday(
                callbackData.entityId,
                callbackData.name,
                callbackData.callbackFunction
            )
        );
    }
};

export const deleteHolidayConfirmation = (
    holiday: any,
    t: TFunction,
    dispatch: AppDispatch,
    callbackFunction?: NoArgsHandler
) => {
    dispatch(
        openMessageBox({
            title: t("administration.delete_holiday"),
            callbackFunction: handleDeleteHolidayConfirmation,
            callbackData: { ...holiday, callbackFunction },
            dispatch: dispatch,
            message: {
                resourceKey: "administration.delete_holiday_confirmation",
                values: {
                    holiday: holiday.name
                }
            },
            icon: ICON_QUESTION,
            showCloseIcon: false,
            buttons: BUTTON_CANCELYES
        })
    );
};

export const handleUpdateHolidayConfirmation = (
    method: PostPutMethod,
    button: string,
    callbackData: any,
    dispatch: AppDispatch
) => {
    if (button === BUTTON_YES) {
        dispatch(writeHoliday(callbackData, method));
    }
};

export const updateHolidayConfirmation = (
    entity: any,
    method: PostPutMethod,
    t: TFunction,
    dispatch: AppDispatch
) => {
    dispatch(
        openMessageBox({
            title: t("administration.edit_holiday"),
            callbackFunction: handleUpdateHolidayConfirmation.bind(
                null,
                method
            ),
            callbackData: entity,
            dispatch: dispatch,
            message: {
                resourceKey: "administration.edit_confirmation"
            },
            icon: ICON_QUESTION,
            showCloseIcon: false,
            buttons: BUTTON_CANCELYES
        })
    );
};

export const handleUpdateOccurrenceConfirmation = (
    openModal: AnyFunction,
    button: string,
    callbackData: any,
    dispatch: AppDispatch
) => {
    if (button === BUTTON_YES) {
        dispatch(writeHolidayOccurrence(callbackData));
    } else {
        openModal();
    }
};

export const updateOccurrenceConfirmation = (
    openModal: AnyFunction,
    entity: any,
    t: TFunction,
    dispatch: AppDispatch,
    callbackFunction: NoArgsHandler
) => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            callbackData: { entity, callbackFunction },
            callbackFunction: handleUpdateOccurrenceConfirmation.bind(
                null,
                openModal
            ),
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey: "administration.edit_confirmation"
            },
            showCloseIcon: false,
            title: t("administration.edit_holiday_occurrence")
        })
    );
};

export function fetchHoliday() {
    return makeRequestThunk("api/entity/holiday", {
        method: METHOD_GET,
        urlPartFunc: function (state: RootState): string {
            return "/" + state.holiday.entityId;
        },
        queryParamsFunc: function (state: RootState): any {
            return {
                fields: HOLIDAY_FIELDS
            };
        },
        preRequestFunc: requestHoliday,
        okDispatchFunc: receiveHoliday
    });
}

export const getFilterCondition = (state: RootState): any => {
    if (state.holiday.entityId) {
        return stringFilterCondition(
            "holiday.entityId",
            state.holiday.entityId,
            "EQUALS"
        );
    } else {
        return null;
    }
};

const getHolidayOccurrencesRequest = (state: RootState): any => {
    return {
        entity: "HolidayOccurrence",
        filterCondition: getFilterCondition(state),
        maxResults: -1,
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: [
            "entityId",
            "startDate",
            "endDate",
            "holiday.entityId",
            "holiday.name"
        ],
        sortConditions: [
            {
                objectType: "SortCondition",
                sortProperty: "startDate",
                sortOrder: ASCENDING_QUERY_API
            }
        ],
        startIndex: 0
    };
};

export function fetchHolidayOccurrences() {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState): any {
            return getHolidayOccurrencesRequest(state);
        },
        preRequestFunc: requestHolidayOccurrences,
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.result.map(reduceOccurrence),
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

export function writeHoliday(entity: any, method: PostPutMethod) {
    const okDispatchFunc = (json: Json, state: RootState) => {
        const actions: ActionOrThunk[] = [];
        const values = {
            holiday: entity.name
        };
        let successMessage = {
            resourceKey: "administration.create_holiday_success",
            values: values
        };
        if (method === METHOD_PUT) {
            successMessage = {
                resourceKey: "administration.edit_holiday_success",
                values: values
            };
        }
        // Reinit has to be after page navigation so it doesn't get cancelled by abort controller
        actions.push(resetCanNotLeave());
        actions.push(holidayPage(json.entityId));
        actions.push(fetchHolidaysReinit());
        actions.push(success(successMessage));
        return actions;
    };
    return writeEntity(entity, method, { okDispatchFunc });
}

export function deleteHoliday(
    entityId: string,
    name: string,
    callbackFunction: NoArgsHandler
) {
    return makeRequestThunk("api/holiday/" + entityId, {
        isCancellable: false,
        method: METHOD_DELETE,
        okDispatchFunc: (json: Json, state: RootState) => {
            const actions: ActionOrThunk[] = [];
            const successMessage = {
                resourceKey: "administration.delete_holiday_success",
                values: {
                    holiday: name
                }
            };
            switch (state.page) {
                case HOLIDAY_PAGE:
                    actions.push(holidaysPage());
                    break;
                case HOLIDAYS_PAGE:
                    if (callbackFunction) {
                        callbackFunction();
                    }
                    break;
                default:
                    break;
            }
            actions.push(fetchHolidaysReinit());
            actions.push(success(successMessage));
            return actions;
        }
    });
}

export function writeHolidayOccurrence(callbackData: any) {
    const { entity, callbackFunction } = callbackData;
    const okDispatchFunc = (json: Json, state: RootState): Actions => {
        const actions: ActionOrThunk[] = [];
        const successMessage = {
            resourceKey: "administration.edit_holiday_occurrence_success"
        };
        if (callbackFunction) {
            callbackFunction();
        }
        actions.push(success(successMessage));
        return actions;
    };
    return writeEntity(entity, METHOD_PUT, { okDispatchFunc });
}
