import styled from "styled-components/macro";

const HierarchySubText = styled("div")`
    color: ${props => props.theme.modalSubText};
    font-family: "BrandonTextRegular";
    font-size: 12px;
    font-weight: normal;
`;

HierarchySubText.displayName = "HierarchySubText";

export default HierarchySubText;
