import * as React from "react";
import get from "lodash.get";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import styled from "styled-components/macro";
import {
    DropdownContainer,
    DropdownContent,
    DropdownGroupWrapper,
    DropdownItem,
    DropdownText,
    POSITION_CENTER
} from "common/components/Dropdown";
import SvgIcon from "common/components/SvgIcon";
import Flexbox from "common/components/styled/Flexbox";
import { marginPropTypes } from "common/components/styled/util";
import theme from "common/components/theme";
import { ReactComponent as RightChevronIcon } from "common/icons/RightChevron.svg";
import { FilterDropdownTrigger } from "common/filter/components/FilterDropdownView";
import FilterDropdownIcon from "common/filter/components/FilterDropdownIcon";
import { emptyFn } from "common/util/lang";
import { reduceObject } from "common/util/object";
import { containsIgnoreReactOutsideClick } from "common/util/outsideClick";
import {
    composeTrackingComponentLabel,
    getTrackingEventData,
    trackEvent
} from "common/util/tracking";
import { EVENT_NAME_SELECTION_CHOICE } from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";

const GroupParent = styled.div`
    color: ${theme.textDisabled};
    font-size: 10px;
    text-transform: uppercase;
`;

export type MenuSubOptionType = {
    children?: MenuSubOptionType[];
    disabled?: boolean;
    label: string;
    parentValue?: string;
    subLabel?: string;
    value: string;
};

type DropdownSubLabelProps = {
    subMenuWidth?: string;
};
const DropdownSubLabel = styled.div<DropdownSubLabelProps>`
    color: ${theme.subText};
    font-size: 12px;
    ${props => props.subMenuWidth && `white-space: break-spaces;`}
`;
DropdownSubLabel.displayName = "DropdownSubLabel";

const DropdownMenuOption = styled(DropdownItem)`
    display: flex;
    justify-content: space-between;
    min-width: 150px;
`;
DropdownMenuOption.displayName = "DropdownMenuOption";

const DropdownSelectedMenuOption = styled(DropdownMenuOption)`
    background-color: ${theme.dropdownSelected};
    color: ${theme.lightText};
    :hover {
        background-color: ${theme.dropdownSelected};
        color: ${theme.lightText};
    }
`;
DropdownSelectedMenuOption.displayName = "DropdownSelectedMenuOption";

const DropdownSubMenuOption = styled(DropdownMenuOption)`
    flex-direction: column;
    justify-content: flex-start;
`;
DropdownSubMenuOption.displayName = "DropdownSubMenuOption";

const DropdownSelectedSubMenuOption = styled(DropdownSubMenuOption)`
    background-color: ${theme.dropdownSelected};
    color: ${theme.lightText};
    :hover {
        background-color: ${theme.dropdownSelected};
        color: ${theme.lightText};
    }
    > ${DropdownSubLabel} {
        color: ${theme.lightText};
    }
`;
DropdownSelectedSubMenuOption.displayName = "DropdownSelectedSubMenuOption";

type DropdownSubMenuProps = {
    leftOffset?: number;
    topOffset?: number;
} & sizePropsType;

const DropdownSubMenu = styled(DropdownContent)<DropdownSubMenuProps>`
    margin-top: 0px;
    ${props => props.leftOffset && `left: ${props.leftOffset}px;`}
    ${props => props.topOffset && `top: ${props.topOffset}px;`}
    ${props => props.cssWidth && `width: ${props.cssWidth};`}
`;
DropdownSubMenu.displayName = "DropdownSubMenu";

const DropdownTrigger = styled(FilterDropdownTrigger)<sizePropsType>`
    display: flex;
    justify-content: space-between;
    height: ${props => (props.cssHeight ? props.cssHeight : "21px")};
`;
DropdownTrigger.displayName = "DropdownTrigger";

const DropdownTriggerLabel = styled.span<{ selected?: boolean }>`
    ${props => !props.selected && `color: ${theme.darkGreyText};`}
`;
DropdownTriggerLabel.displayName = "DropdownTriggerLabel";

const expandableArrow = (
    <SvgIcon color={theme.greyIcon} icon={RightChevronIcon} marginLeft="12px" />
);

type ExpandingDropdownOwnProps = {
    cssHeight?: string;
    cssMenuWidth?: string;
    cssSubMenuWidth?: string;
    cssWidth?: string;
    includeSelectedValueInTracking?: boolean;
    onChange: (menuItem: MenuSubOptionType) => void;
    options: MenuSubOptionType[];
    placeholder: string;
    selected: MenuSubOptionType | null;
    showSubLabelSelection?: boolean;
    trackingComponentLabel: TrackingComponentLabel;
} & marginPropsType;

type ExpandingDropdownProps = ExpandingDropdownOwnProps & WithTranslation;

type ExpandingDropdownState = {
    expandDropdown: boolean;
    isOpen: boolean;
    selectedMenuOption: MenuSubOptionType | null;
    subMenuLeftOffset: number;
    subMenuTopOffset: number;
};

class ExpandingDropdown extends React.Component<
    ExpandingDropdownProps,
    ExpandingDropdownState
> {
    static displayName = "ExpandingDropdown";
    static defaultProps = {
        onChange: emptyFn,
        showSubLabelSelection: false
    };

    constructor(props: ExpandingDropdownProps) {
        super(props);
        this.state = {
            expandDropdown: false,
            isOpen: false,
            selectedMenuOption: null,
            subMenuLeftOffset: 0,
            subMenuTopOffset: 0
        };
    }

    filterOptions = (options: MenuSubOptionType[]) => {
        return options.filter(option => get(option, "children.length", 0) > 0);
    };

    onExpandHandler = (
        menuItem: any,
        event?: React.SyntheticEvent<HTMLInputElement> | any
    ) => {
        const triggerNode = get(
            event,
            "currentTarget.parentNode.parentNode.parentNode"
        );
        const itemNode = event ? event.currentTarget : null;
        const leftOffset = itemNode
            ? itemNode.getBoundingClientRect().width + 4
            : 0;
        const topOffset =
            triggerNode && itemNode
                ? itemNode.getBoundingClientRect().top -
                  triggerNode.getBoundingClientRect().top
                : 0;
        this.setState({
            expandDropdown: true,
            selectedMenuOption: menuItem,
            subMenuLeftOffset: leftOffset,
            subMenuTopOffset: topOffset
        });
    };

    onSelectHandler = (menuItem: MenuSubOptionType) => {
        const { onChange } = this.props;
        onChange(menuItem);
        this.trackOptionSelect(menuItem);
        this.setState({
            expandDropdown: false,
            isOpen: false
        });
    };

    onTriggerClick = (evt: React.SyntheticEvent<HTMLDivElement>) => {
        const { options, selected } = this.props;
        if (this.state.isOpen) {
            this.setState({ expandDropdown: false, isOpen: false });
        } else {
            const filteredOptions = this.filterOptions(options);
            // Open selected section if value is present
            this.setState({ isOpen: true }, () => {
                if (selected) {
                    const menuOption = filteredOptions.find(option => {
                        return (
                            option.children?.filter(
                                child =>
                                    child.value === selected.value ||
                                    child.value === selected.parentValue
                            ).length ?? -1 > 0
                        );
                    });
                    const menuOptionLabel = menuOption
                        ? document.querySelector(
                              "div[data-id=" + menuOption.value + "]"
                          )
                        : null;
                    if (menuOption && menuOptionLabel) {
                        this.onExpandHandler(menuOption, {
                            currentTarget: menuOptionLabel
                        });
                    }
                }
            });
        }
    };

    onOutsideClick = (event: MouseEvent) => {
        if (!containsIgnoreReactOutsideClick(event)) {
            if (this.state.isOpen) {
                this.setState({ expandDropdown: false, isOpen: false });
            }
        }
    };

    trackOptionSelect = (menuItem: MenuSubOptionType) => {
        const { includeSelectedValueInTracking, trackingComponentLabel } =
            this.props;
        trackEvent(
            getTrackingEventData(
                ExpandingDropdown.displayName,
                includeSelectedValueInTracking
                    ? composeTrackingComponentLabel([
                          trackingComponentLabel,
                          menuItem.value
                      ])
                    : trackingComponentLabel,
                EVENT_NAME_SELECTION_CHOICE
            )
        );
    };

    render() {
        const {
            cssHeight,
            cssSubMenuWidth,
            cssMenuWidth,
            options,
            placeholder,
            selected,
            showSubLabelSelection,
            t
        } = this.props;
        const {
            expandDropdown,
            isOpen,
            selectedMenuOption,
            subMenuLeftOffset,
            subMenuTopOffset
        } = this.state;
        const filteredOptions = this.filterOptions(options);
        let dropdownLabel = placeholder
            ? placeholder
            : t("common:general.select_ellipsis");
        let dropdownSubLabel = null;
        if (selected) {
            const menuOption = filteredOptions.find(option => {
                return (
                    option.children?.filter(
                        child => child.value === selected.value
                    ).length ?? -1 > 0
                );
            });
            dropdownLabel = selected.label;
            if (!dropdownLabel && menuOption) {
                menuOption.children?.forEach(option => {
                    if (option.value === selected.value) {
                        dropdownLabel = option.label;
                    }
                });
            }
            if ((menuOption || selected.subLabel) && showSubLabelSelection) {
                dropdownSubLabel = (
                    <GroupParent>
                        {get(menuOption, "label") || selected.subLabel}
                    </GroupParent>
                );
            }
        }

        let triggerLabelSelected = false;
        if (selected && !isOpen) {
            triggerLabelSelected = true;
        }
        const Trigger = (
            <DropdownTrigger
                color={theme.text}
                cssHeight={
                    showSubLabelSelection && !cssHeight ? "34px" : cssHeight
                }
                onClick={this.onTriggerClick}
            >
                <Flexbox cssWidth="100%" justifyContent="space-between">
                    <DropdownText>
                        <Flexbox>
                            <DropdownTriggerLabel
                                selected={triggerLabelSelected}
                            >
                                {dropdownLabel}
                                {dropdownSubLabel}
                            </DropdownTriggerLabel>
                        </Flexbox>
                    </DropdownText>
                    <FilterDropdownIcon />
                </Flexbox>
            </DropdownTrigger>
        );

        const MenuSection =
            expandDropdown && selectedMenuOption ? (
                <DropdownSubMenu
                    cssWidth={cssSubMenuWidth}
                    leftOffset={subMenuLeftOffset}
                    topOffset={subMenuTopOffset}
                >
                    <DropdownGroupWrapper>
                        {selectedMenuOption.children?.map(
                            (menuItem: MenuSubOptionType) => {
                                const Component =
                                    selected &&
                                    (menuItem.value === selected.value ||
                                        menuItem.value === selected.parentValue)
                                        ? DropdownSelectedSubMenuOption
                                        : DropdownSubMenuOption;
                                return (
                                    <Component
                                        data-id={menuItem.value}
                                        disabled={menuItem.disabled}
                                        key={menuItem.value}
                                        onClick={
                                            !menuItem.disabled
                                                ? this.onSelectHandler.bind(
                                                      null,
                                                      menuItem
                                                  )
                                                : () => undefined
                                        }
                                    >
                                        <div>{menuItem.label}</div>
                                        <DropdownSubLabel
                                            subMenuWidth={cssSubMenuWidth}
                                        >
                                            {menuItem.subLabel}
                                        </DropdownSubLabel>
                                    </Component>
                                );
                            }
                        )}
                    </DropdownGroupWrapper>
                </DropdownSubMenu>
            ) : null;

        return (
            <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
                <DropdownContainer
                    backgroundColor={theme.background}
                    {...reduceObject(
                        this.props,
                        ...marginPropTypes,
                        "cssWidth"
                    )}
                >
                    {Trigger}
                    {isOpen && (
                        <DropdownContent
                            verticalPosition={POSITION_CENTER}
                            cssWidth={cssMenuWidth}
                        >
                            <DropdownGroupWrapper>
                                {filteredOptions.map(
                                    (menuItem: MenuSubOptionType) => {
                                        const MenuComponent =
                                            selectedMenuOption &&
                                            selectedMenuOption.value ===
                                                menuItem.value
                                                ? DropdownSelectedMenuOption
                                                : DropdownMenuOption;

                                        return (
                                            <MenuComponent
                                                data-id={menuItem.value}
                                                key={menuItem.value}
                                                onMouseEnter={this.onExpandHandler.bind(
                                                    null,
                                                    menuItem
                                                )}
                                            >
                                                {menuItem.label}
                                                {expandableArrow}
                                            </MenuComponent>
                                        );
                                    }
                                )}
                            </DropdownGroupWrapper>
                        </DropdownContent>
                    )}
                    {MenuSection}
                </DropdownContainer>
            </OutsideClickHandler>
        );
    }
}

export default withTranslation()(ExpandingDropdown);
