import type { TFunction } from "i18next";
import get from "lodash.get";
import type { AnyAction } from "redux";
import { writeEntity } from "common/shell/state/entityActions";
import {
    BUTTON_CANCELYES,
    BUTTON_YES,
    ICON_QUESTION,
    openMessageBox
} from "common/shell/state/messageBoxActions";
import { resetCanNotLeave } from "common/shell/state/navigationActions";
import {
    formatSortOrderForQueryAPI,
    makeRequestThunk,
    METHOD_DELETE,
    METHOD_GET,
    METHOD_POST
} from "common/shell/state/requestActions";
import { success } from "common/shell/state/toastActions";
import {
    andFilterCondition,
    freeFormFilterCondition,
    notFilterCondition,
    nullFilterCondition,
    orFilterCondition,
    singleValueFilterCondition
} from "common/util/filter";
import { DEFAULT_PAGE_SIZE, sortCondition } from "common/util/query";
import {
    ACTIVE_GUIDELINE_COLLECTION_STATUS,
    GUIDELINE_COLLECTION_NAME,
    GUIDELINE_COLLECTIONS_FIELDS,
    GUIDELINE_COLLECTIONS_LIST_FIELDS,
    reduceGuidelineCollection
} from "administration/guidelines/state/guidelineStateUtils";
import {
    GUIDELINE_COLLECTION_CREATE_PAGE,
    GUIDELINE_COLLECTION_EDIT_PAGE,
    GUIDELINE_COLLECTION_PAGE,
    GUIDELINE_COLLECTIONS_PAGE
} from "administration/state/pageActions";
import { GUIDELINE_COLLECTIONS } from "filter/state/filterActions";
import type { ActionOrThunk, Actions, AppDispatch, RootState } from "store";

export const RECEIVE_GUIDELINE_COLLECTION = "RECEIVE_GUIDELINE_COLLECTION";
export const RECEIVE_GUIDELINE_COLLECTIONS = "RECEIVE_GUIDELINE_COLLECTIONS";
export const REQUEST_GUIDELINE_COLLECTION = "REQUEST_GUIDELINE_COLLECTION";
export const REQUEST_GUIDELINE_COLLECTIONS = "REQUEST_GUIDELINE_COLLECTIONS";
export const UPDATE_SORT_GUIDELINE_COLLECTIONS =
    "UPDATE_SORT_GUIDELINE_COLLECTIONS";
export const UPDATE_START_INDEX_GUIDELINE_COLLECTIONS =
    "UPDATE_START_INDEX_GUIDELINE_COLLECTIONS";

export const guidelineCollectionsPage = (): AnyAction => ({
    type: GUIDELINE_COLLECTIONS_PAGE
});

export const guidelineCollectionPage = (collectionId: string): AnyAction => ({
    type: GUIDELINE_COLLECTION_PAGE,

    payload: {
        // matches what is in routes.js
        entityId: collectionId
    }
});

export const guidelineCollectionCreatePage = (): AnyAction => ({
    type: GUIDELINE_COLLECTION_CREATE_PAGE
});

export const guidelineCollectionEditPage = (
    collectionId: string
): AnyAction => ({
    type: GUIDELINE_COLLECTION_EDIT_PAGE,

    payload: {
        collectionId
    }
});

export const requestGuidelineCollections = (): AnyAction => ({
    type: REQUEST_GUIDELINE_COLLECTIONS
});

export const receiveGuidelineCollections = (json: Json): AnyAction => ({
    type: RECEIVE_GUIDELINE_COLLECTIONS,
    json: json
});

export const requestGuidelineCollection = (): AnyAction => ({
    type: REQUEST_GUIDELINE_COLLECTION
});

export const receiveGuidelineCollection = (json: Json): AnyAction => ({
    type: RECEIVE_GUIDELINE_COLLECTION,
    json: json
});

export const updateStartIndex = (startIndex: number): AnyAction => ({
    type: UPDATE_START_INDEX_GUIDELINE_COLLECTIONS,
    startIndex: startIndex
});

export const updateSortBy = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => ({
    type: UPDATE_SORT_GUIDELINE_COLLECTIONS,
    sortBy: sortBy,
    sortOrder: sortOrder
});

const getFilterCondition = (state: RootState): any => {
    const filterConditions = [],
        searchColumns = [GUIDELINE_COLLECTION_NAME],
        searchText = get(state, ["filters", GUIDELINE_COLLECTIONS, "search"]),
        showFilter = get(state, ["filters", GUIDELINE_COLLECTIONS, "show"]);
    if (searchText) {
        filterConditions.push(
            freeFormFilterCondition(searchText, searchColumns)
        );
    }
    // If show filter is empty or has more than 1 option then we show all since there are only active and inactive as options
    if (showFilter.length === 1) {
        const currentDate = new Date();
        const condition = orFilterCondition([
            andFilterCondition([
                nullFilterCondition("endDate"),
                singleValueFilterCondition(
                    "DateFilterValue",
                    "startDate",
                    currentDate,
                    "LESS_THAN_EQUAL_TO"
                )
            ]),
            andFilterCondition([
                singleValueFilterCondition(
                    "DateFilterValue",
                    "startDate",
                    currentDate,
                    "LESS_THAN_EQUAL_TO"
                ),
                singleValueFilterCondition(
                    "DateFilterValue",
                    "endDate",
                    currentDate,
                    "GREATER_THAN_EQUAL_TO"
                )
            ])
        ]);
        filterConditions.push(
            showFilter[0] === ACTIVE_GUIDELINE_COLLECTION_STATUS
                ? condition
                : notFilterCondition(condition)
        );
    }
    if (filterConditions.length) {
        if (filterConditions.length === 1) {
            return filterConditions[0];
        }
        return andFilterCondition(filterConditions);
    }
    return null;
};

const getGuidelineCollectionListQuery = (
    state: RootState,
    listMode?: boolean
): any => {
    const sortOrder = formatSortOrderForQueryAPI(
        get(state, "guidelines.guidelineCollections.sortOrder")
    );
    return {
        entity: "GuidelineCollection",
        filterCondition: getFilterCondition(state),
        maxResults: listMode ? -1 : DEFAULT_PAGE_SIZE,
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: listMode
            ? GUIDELINE_COLLECTIONS_LIST_FIELDS
            : GUIDELINE_COLLECTIONS_FIELDS,
        sortConditions: [
            sortCondition(
                get(state, "guidelines.guidelineCollections.sortBy"),
                sortOrder
            )
        ],
        startIndex: get(state, "guidelineCollection.startIndex", 0)
    };
};

export function createGuidelineCollection(requestBody: any) {
    return makeRequestThunk("api/guidelineCollection", {
        body: requestBody,
        isCancellable: false,
        method: METHOD_POST,
        preRequestFunc: requestGuidelineCollection,
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            actions.push(resetCanNotLeave());
            actions.push(receiveGuidelineCollection(json));
            actions.push(
                success({
                    resourceKey:
                        "administration.create_guideline_collection_success",
                    values: { guidelineCollection: requestBody.name }
                })
            );
            return actions;
        }
    });
}

export function fetchGuidelineCollections(listMode?: boolean) {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState): any {
            return getGuidelineCollectionListQuery(state, listMode);
        },
        preRequestFunc: requestGuidelineCollections,
        okDispatchFunc: receiveGuidelineCollections,
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.result.map(reduceGuidelineCollection),
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

export function fetchGuidelineCollection(entityId: string) {
    return makeRequestThunk("api/entity/guidelineCollection/" + entityId, {
        method: METHOD_GET,
        queryParamsFunc: function (state: RootState): any {
            return {
                fields: GUIDELINE_COLLECTIONS_FIELDS
            };
        },
        preRequestFunc: requestGuidelineCollection,
        okDispatchFunc: receiveGuidelineCollection
    });
}

export function editGuidelineCollection(entity: any) {
    const method = METHOD_POST;
    const okDispatchFunc = (json: Json, state: RootState): Actions => {
        const actions: ActionOrThunk[] = [];
        actions.push(resetCanNotLeave());
        actions.push(guidelineCollectionPage(json.entityId));
        actions.push(
            success({
                resourceKey: "administration.edit_guideline_collection_success",
                values: {
                    guidelineCollection: entity.name
                }
            })
        );
        return actions;
    };
    return writeEntity(entity, method, { okDispatchFunc });
}

export function deleteGuidelineCollection(
    entityId: string,
    name: string,
    callbackFunction: NoArgsHandler
) {
    return makeRequestThunk("api/entity/guidelineCollection/" + entityId, {
        isCancellable: false,
        method: METHOD_DELETE,
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            if (state.page === GUIDELINE_COLLECTION_PAGE) {
                actions.push(guidelineCollectionsPage());
            } else {
                if (callbackFunction) {
                    callbackFunction();
                }
            }
            actions.push(
                success({
                    resourceKey:
                        "administration.delete_guideline_collection_success",
                    values: {
                        guidelineCollection: name
                    }
                })
            );
            return actions;
        }
    });
}

export const handleDeleteGuidelineCollectionConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
) => {
    if (button === BUTTON_YES) {
        dispatch(
            deleteGuidelineCollection(
                callbackData.entityId,
                callbackData.name,
                callbackData.callbackFunction
            )
        );
    }
};

export const deleteGuidelineCollectionConfirmation = (
    guidelineCollection: any,
    t: TFunction,
    dispatch: AppDispatch,
    callbackFunction?: NoArgsHandler
) => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.yes")
            },
            callbackData: { ...guidelineCollection, callbackFunction },
            callbackFunction: handleDeleteGuidelineCollectionConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey:
                    "administration.delete_guideline_collection_confirmation",
                values: {
                    guidelineCollection: guidelineCollection.name
                }
            },
            showCloseIcon: false,
            title: t("administration.delete_guideline_collection")
        })
    );
};
