import get from "lodash.get";
import type { AnyAction } from "redux";
import { ASCENDING } from "common/util/query";
import {
    PPG_NAME,
    RECEIVE_AUTOMATED_DESIGN,
    RECEIVE_CAMPAIGN_PRODUCT_GROUPS,
    RECEIVE_GENERATE_AUTOMATED_DESIGN,
    RECEIVE_PRODUCT_GROUPS,
    REQUEST_AUTOMATED_DESIGN,
    REQUEST_CAMPAIGN_PRODUCT_GROUPS,
    REQUEST_GENERATE_AUTOMATED_DESIGN,
    REQUEST_PRODUCT_GROUPS,
    UPDATE_CAMPAIGN_PRODUCT_GROUPS_SORT,
    UPDATE_PRODUCT_GROUPS_SORT
} from "concept/state/conceptActions";
import {
    OFFER_ADVANCED_CREATE_PAGE,
    OFFER_EDIT_PAGE
} from "shell/state/pageActions";

type ConceptState = {
    automated: AutomatedDesign | null;
    campaignProductGroups: {
        sortBy: string;
        sortOrder: SortOrder;
        totalCount: number;
    };
    productGroups: {
        sortBy: string;
        sortOrder: SortOrder;
        totalCount: number;
    };
    promotionEntityId: string | null;
};

const defaultState: ConceptState = {
    automated: null,
    campaignProductGroups: {
        sortBy: PPG_NAME,
        sortOrder: ASCENDING,
        totalCount: 0
    },
    productGroups: {
        sortBy: PPG_NAME,
        sortOrder: ASCENDING,
        totalCount: 0
    },
    promotionEntityId: null
};

function concept(state: ConceptState = defaultState, action: AnyAction) {
    switch (action.type) {
        case OFFER_ADVANCED_CREATE_PAGE:
        case OFFER_EDIT_PAGE:
            return Object.assign({}, state, {
                promotionEntityId:
                    action.payload && action.payload.promotionEntityId
            });
        case REQUEST_AUTOMATED_DESIGN:
            return Object.assign({}, state, {
                automated: defaultState.automated
            });
        case REQUEST_GENERATE_AUTOMATED_DESIGN:
            return state;
        case RECEIVE_AUTOMATED_DESIGN:
        case RECEIVE_GENERATE_AUTOMATED_DESIGN:
            return Object.assign({}, state, {
                automated: action.json
            });
        case REQUEST_CAMPAIGN_PRODUCT_GROUPS:
            return state;
        /*            
            return Object.assign({}, state, {
                campaignProductGroups: defaultState.campaignProductGroups
            });
*/
        case REQUEST_PRODUCT_GROUPS:
            return state;
        /*            
            return Object.assign({}, state, {
                productGroups: defaultState.productGroups
            });
*/
        case RECEIVE_CAMPAIGN_PRODUCT_GROUPS:
            return Object.assign({}, state, {
                campaignProductGroups: {
                    ...state.campaignProductGroups,
                    totalCount: get(action, "json.totalCount")
                }
            });
        case RECEIVE_PRODUCT_GROUPS:
            return Object.assign({}, state, {
                productGroups: {
                    ...state.productGroups,
                    totalCount: get(action, "json.totalCount")
                }
            });
        case UPDATE_CAMPAIGN_PRODUCT_GROUPS_SORT:
            return Object.assign({}, state, {
                campaignProductGroups: {
                    ...state.campaignProductGroups,
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder
                }
            });
        case UPDATE_PRODUCT_GROUPS_SORT:
            return Object.assign({}, state, {
                productGroups: {
                    ...state.productGroups,
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder
                }
            });
        default:
            return state;
    }
}

export default concept;
