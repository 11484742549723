/*
Pulled from react-virtualized-tree version 3.4.1
*/
import get from "lodash.get";
import { getFlattenedTreePaths } from "common/filter/reactVirtualizedTree/state/selectors/getFlattenedTree";
import { getNodeFromPath } from "common/filter/reactVirtualizedTree/state/selectors/nodes";
import type {
    FlattenedTreeNode,
    TreeNode
} from "common/filter/reactVirtualizedTree/state/treeStateUtils";

export class State {
    flattenedTree: any = null;
    tree: any = null;

    constructor(tree: TreeNode[], flattenedTree?: string[][] | null) {
        this.tree = tree;
        this.flattenedTree = flattenedTree || getFlattenedTreePaths(tree);
    }
}

const validateState = (state: State) => {
    if (!(state instanceof State)) {
        throw new Error(`Expected a State instance but got ${typeof state}`);
    }
};

/**
 * Immutable structure that represents the TreeState.
 */
export default class TreeState {
    /**
     * Given a state, finds a node at a certain row index.
     * @param {State} state - The current state
     * @param {number} index - The visible row index
     * @return {State} An internal state representation
     */
    static getNodeAt = (state: State, index: number): FlattenedTreeNode => {
        validateState(state);

        const rowPath = get(state, ["flattenedTree", index]);

        if (!rowPath) {
            throw Error(
                `Tried to get node at row "${index}" but got nothing, the tree are ${get(
                    state,
                    "flattenedTree.length"
                )} visible rows`
            );
        }

        return getNodeFromPath(rowPath, state.tree);
    };

    /**
     * Given a state, finds a node deepness at a certain row index.
     * @param {State} state - The current state
     * @param {number} index - The visible row index
     * @return {number} The node deepness
     */
    static getNodeDeepness = (state: State, index: number): number => {
        validateState(state);

        const rowPath = get(state, ["flattenedTree", index]);

        if (!rowPath) {
            throw Error(
                `Tried to get node at row "${index}" but got nothing, the tree are ${get(
                    state,
                    "flattenedTree.length"
                )} visible rows`
            );
        }

        return rowPath.length - 1;
    };

    /**
     * Given a state and an index, finds the number of visible descendants
     * @param {State} state - The current state
     * @param {number} index - The visible row index
     * @return {number} The number of visible descendants
     */
    static getNumberOfVisibleDescendants = (
        state: State,
        index: number
    ): number => {
        const { id } = TreeState.getNodeAt(state, index);

        const { flattenedTree } = state;
        let i = 0;
        if (flattenedTree) {
            for (i = index; i < flattenedTree.length; i++) {
                const path = flattenedTree[i];

                if (!path.some((p: any) => p === id)) {
                    break;
                }
            }
        }

        return Math.max(i - 1 - index, 0);
    };

    /**
     * Given a state, gets the tree
     * @param {State} state - The current state
     * @return {Node[]} The tree
     */
    static getTree = (state: State): TreeNode[] | undefined | null => {
        validateState(state);

        return state.tree;
    };

    /**
     * Creates an instance of state.
     * @param {Node[]} tree - The original tree
     * @return {State} An internal state representation
     */
    static createFromTree = (tree: TreeNode[]): State => {
        if (!tree) {
            throw Error("A falsy tree was supplied in tree creation");
        }

        if (!Array.isArray(tree)) {
            throw Error("An invalid tree was supplied in creation");
        }

        return new State(tree);
    };
}
