import React from "react";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import AuthDialogPage, {
    AUTH_BUTTON_WIDTH,
    AUTH_INPUT_WIDTH,
    AuthError,
    AuthInstructions
} from "common/auth/components/AuthDialogPage";
import { securityQuestion } from "common/auth/state/authActions";
import Button from "common/components/Button";
import { CustomSelect } from "common/components/styled/Form";
import Input from "common/components/styled/Input";
import { FieldLabel } from "common/components/styled/Label";
import type { AppDispatch, RootState } from "store";

type SecurityQuestionDialogProps = PropsFromRedux & WithTranslation;

type SecurityQuestionDialogState = {
    securityAnswer: string;
    securityQuestionSelectedValue: OptionTypeOrNullOrUndefined;
};

class SecurityQuestionDialog extends React.Component<
    SecurityQuestionDialogProps,
    SecurityQuestionDialogState
> {
    static displayName = "SecurityQuestionDialog";
    state: SecurityQuestionDialogState = {
        securityAnswer: "",
        securityQuestionSelectedValue: null
    };

    onChangeSecurityAnswer = (
        event: React.SyntheticEvent<HTMLInputElement>
    ) => {
        this.setState({ securityAnswer: event.currentTarget.value });
    };

    onChangeSecurityQuestion = (
        securityQuestionSelectedValue: OptionTypeOrNullOrUndefined
    ) => {
        this.setState({ securityQuestionSelectedValue });
    };

    onClickSubmit = () => {
        if (this.state.securityQuestionSelectedValue) {
            this.props.securityQuestionAction(
                this.state.securityQuestionSelectedValue.value,
                this.state.securityAnswer
            );
        }
    };

    onKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === "Enter" && this.validate()) {
            this.onClickSubmit();
        }
    };

    validate = (): boolean => {
        return Boolean(
            this.state.securityQuestionSelectedValue?.value &&
                this.state.securityAnswer
        );
    };

    render() {
        const { error, questions, t } = this.props;
        const { securityQuestionSelectedValue } = this.state;
        const options = questions.map(question => {
            return {
                value: question.entityId,
                label: question.question
            };
        });
        return (
            <AuthDialogPage title={t("common:login.security_question")}>
                <AuthInstructions>
                    {t("common:login.security_question_instructions")}
                </AuthInstructions>
                <FieldLabel>{t("common:login.security_question")}</FieldLabel>
                <CustomSelect
                    name="securityQuestion"
                    options={options}
                    onChange={this.onChangeSecurityQuestion}
                    placeholder={t("common:login.select_question")}
                    trackingComponentLabel={[
                        SecurityQuestionDialog.displayName,
                        "Security Question"
                    ]}
                    value={securityQuestionSelectedValue}
                />
                <FieldLabel marginTop="10px">
                    {t("common:login.security_answer")}
                </FieldLabel>
                <Input
                    name="securityAnswer"
                    onChange={this.onChangeSecurityAnswer}
                    onKeyPress={this.onKeyPress}
                    placeholder={t("common:login.answer_question")}
                    type="text"
                    cssWidth={AUTH_INPUT_WIDTH}
                />
                <Button
                    cssWidth={AUTH_BUTTON_WIDTH}
                    disabled={!this.validate()}
                    onClick={this.onClickSubmit}
                    marginTop="24px"
                    text={t("common:general.submit")}
                    trackingComponentLabel={[
                        SecurityQuestionDialog.displayName,
                        "Submit"
                    ]}
                />
                <AuthError error={error} />
            </AuthDialogPage>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        error: state.auth.error,
        questions: state.auth.questions
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        securityQuestionAction: (questionId: string, answer: string) => {
            dispatch(securityQuestion(questionId, answer));
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(SecurityQuestionDialog));
