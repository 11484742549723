import get from "lodash.get";
import { CUSTOM } from "common/util/date";
import type { TrendRequestExtraOptions } from "reports/components/TrendView";
import { setRequestDates } from "common/reports/state/reportsActionUtils";
import { getReportStateKey } from "common/reports/state/reportsStateUtils";
import { PROMO_REPORT_TIME_PERIOD } from "reports/state/reportsStateUtils";
import type { ReportView, TrendSeries } from "reports/state/reportsStateUtils";
import {
    TAKE_RATE,
    TAKE_RATE_PURCHASE_DISTRIBUTION,
    TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON
} from "reports/takeRate/state/takeRateStateUtils";
import type { TakeRateStackedBarFilters } from "reports/takeRate/state/takeRateStateUtils";
import type { RootState } from "store";

export function getTrendChartRequest(
    series: TrendSeries[],
    savedWithStaticDates: boolean,
    extraOptions: TrendRequestExtraOptions
): any {
    const request: any = {
        objectType: "TakeRateReportChartRequest"
    };
    request.series = [];
    series.forEach((x: TrendSeries) => {
        if (x.seriesType === TAKE_RATE) {
            const seriesRequest: any = {
                channelIds: x.channels,
                holidayIds: x.holidays,
                name: x.name,
                objectType: "TakeRateReportChartSeriesRequest",
                offerTypes: x.offerTypes,
                productGroupIds: x.ppgs,
                promotionQuantities: x.promotionQuantities.map(Number)
            };
            if (savedWithStaticDates) {
                // find the data series that matches the series being saved
                const dataSeries = extraOptions.dataSeries
                    ? extraOptions.dataSeries
                    : [];
                const serie = dataSeries.find(
                    dataSeriesItem => dataSeriesItem.name === x.name
                );
                if (serie) {
                    const startDate = serie.options.startDate;
                    const endDate = serie.options.endDate;
                    seriesRequest.endDate = endDate;
                    seriesRequest.startDate = startDate;
                    seriesRequest.timePeriod = {
                        objectType: PROMO_REPORT_TIME_PERIOD,
                        value: CUSTOM
                    };
                }
            } else {
                setRequestDates(
                    seriesRequest,
                    x.time,
                    PROMO_REPORT_TIME_PERIOD
                );
            }
            request.series.push(seriesRequest);
        }
    });
    return request;
}

export const buildTakeRateStackedChartRequest = (
    state: RootState,
    savedWithStaticDates = false
) => {
    const filters: TakeRateStackedBarFilters = get(
        state,
        getReportStateKey(
            TAKE_RATE,
            TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON,
            "stackedBar.filters"
        )
    );
    const { channels, date, holidays, offerTypes, ppgs } = filters;
    const endDate = get(
        state,
        getReportStateKey(
            TAKE_RATE,
            TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON,
            "stackedBar.endDate"
        )
    );
    const startDate = get(
        state,
        getReportStateKey(
            TAKE_RATE,
            TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON,
            "stackedBar.startDate"
        )
    );
    const request: any = {
        channelIds: channels,
        holidayIds: holidays,
        objectType: "TakeRateReportStackedChartRequest",
        offerTypes: offerTypes,
        productGroupIds: ppgs
    };
    if (savedWithStaticDates) {
        request.endDate = endDate;
        request.startDate = startDate;

        request.timePeriod = {
            objectType: PROMO_REPORT_TIME_PERIOD,
            value: CUSTOM
        };
    } else {
        setRequestDates(request, date, PROMO_REPORT_TIME_PERIOD);
    }
    return request;
};

export const getTakeRateSavedReportsRequests = (
    reportView: ReportView,
    state: RootState,
    savedWithStaticDates = false
) => {
    const requests: any[] = [];
    const series = get(
        state,
        getReportStateKey(
            TAKE_RATE,
            TAKE_RATE_PURCHASE_DISTRIBUTION,
            "trend.configuration.series"
        )
    );
    const dataSeries = get(
        state,
        getReportStateKey(
            TAKE_RATE,
            TAKE_RATE_PURCHASE_DISTRIBUTION,
            "trend.chart.data"
        )
    );
    switch (reportView) {
        case TAKE_RATE_PURCHASE_DISTRIBUTION: {
            const request = getTrendChartRequest(series, savedWithStaticDates, {
                dataSeries: dataSeries
            });
            requests.push({
                objectType: "SavedReportRequest",
                serializedRequest: JSON.stringify(request)
            });
            break;
        }
        case TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON: {
            const request = buildTakeRateStackedChartRequest(
                state,
                savedWithStaticDates
            );
            requests.push({
                objectType: "SavedReportRequest",
                serializedRequest: JSON.stringify(request)
            });
            break;
        }
        default:
            break;
    }
    return requests;
};

export const getTakeRateSavedReportsState = (
    reportView: ReportView,
    state: RootState
) => {
    const uiState: Dictionary<string> = {};
    const viewBy = get(
        state,
        getReportStateKey(
            TAKE_RATE,
            TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON,
            "viewBy"
        )
    );
    switch (reportView) {
        case TAKE_RATE_PURCHASE_DISTRIBUTION:
            break;
        case TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON:
            uiState.viewBy = viewBy;
            break;
        default:
            break;
    }
    return JSON.stringify(uiState);
};
