import get from "lodash.get";
import type { AnyAction } from "redux";
import { success } from "common/shell/state/toastActions";

import type { RootState } from "store";

export const processExportSuccess = (
    json: Json,
    state: RootState
): AnyAction => {
    const email = get(state, "init.user.email");
    const successMessage = {
        resourceKey: "common:administration.export_email_sent",
        values: {
            email: email
        }
    };
    return success(successMessage);
};

export const processImportSuccess = (email: string): AnyAction => {
    const successMessage = {
        resourceKey: "common:administration.import_email_sent",
        values: {
            email: email
        }
    };
    return success(successMessage);
};
