import * as React from "react";
import Dropzone from "react-dropzone";
import type { DropzoneRef } from "react-dropzone";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import Button, {
    BUTTON_TYPE_PRIMARY,
    BUTTON_TYPE_SECONDARY,
    UntrackedButton
} from "common/components/Button";
import Modal, {
    ModalButtonRow,
    ModalMainContentFlex,
    ModalSeparator,
    ModalTitleRow,
    ModalWrapper
} from "common/components/Modal";
import Input from "common/components/styled/Input";
import { FieldLabel } from "common/components/styled/Label";
import Flexbox from "common/components/styled/Flexbox";
import { FieldTip, FormRow, FormSection } from "common/components/styled/Form";
import { IMAGE_FILE_TYPE, UploadContainer } from "common/components/UploadZone";
//import { uploadFile } from "common/shell/state/fileActions";
import { extractFilenameWithoutExtension } from "common/util/format";
import { uploadImage } from "administration/images/state/imagesActions";
import type { ImageCreateDto } from "administration/images/state/imagesActions";
import type { AppDispatch, RootState } from "store";

type ImageUploadModalOwnProps = {
    addToLibrary: boolean;
    base64: boolean;
    closeModalHandler: (imageCreateDto: ImageCreateDto | null) => void;
    hideSuccess: boolean;
    imageMaxHeight: number | null;
    imageMaxWidth: number | null;
    isOpen: boolean;
    promotionIds?: string[];
};

type ImageUploadModalProps = ImageUploadModalOwnProps &
    PropsFromRedux &
    WithTranslation;

type ImageUploadModalState = {
    file: File | null;
    fileName: string;
    name: string;
};

const defaultState: ImageUploadModalState = {
    fileName: "",
    file: null,
    name: ""
};

class ImageUploadModal extends React.Component<
    ImageUploadModalProps,
    ImageUploadModalState
> {
    static displayName = "ImageUploadModal";

    static defaultProps = {
        addToLibrary: false,
        base64: false,
        hideSuccess: true,
        imageMaxHeight: null,
        imageMaxWidth: null,
        promotionIds: []
    };

    dropzoneRef: React.RefObject<DropzoneRef>;
    nameInput: HTMLInputElement | null | undefined;

    state = defaultState;

    constructor(props: ImageUploadModalProps) {
        super(props);
        this.dropzoneRef = React.createRef<DropzoneRef>();
    }

    componentDidMount(): void {
        this.nameInput && this.nameInput.focus();
    }

    onClose = () => {
        const { closeModalHandler } = this.props;
        this.setState(defaultState);
        closeModalHandler(null);
    };

    onUpload = (): void => {
        const {
            addToLibrary,
            base64,
            closeModalHandler,
            hideSuccess,
            imageMaxHeight,
            imageMaxWidth,
            promotionIds,
            uploadFile
        } = this.props;
        const { file, name } = this.state;
        if (file) {
            const url = base64
                ? "api/smartimage/base64"
                : "api/smartimage/upload";
            uploadFile(
                url,
                file,
                name,
                addToLibrary,
                promotionIds,
                imageMaxHeight,
                imageMaxWidth,
                hideSuccess
            ).then(json => {
                this.setState(defaultState);
                closeModalHandler(json.result);
            });
        }
    };

    onChangeName = (event: React.SyntheticEvent<HTMLInputElement>): void => {
        this.setState({ name: event.currentTarget.value });
    };

    setNameInputRef = (input: HTMLInputElement) => {
        this.nameInput = input;
    };

    onFileChosen = (files: File[]) => {
        if (files.length > 0) {
            const file = files[0];
            this.setState({
                file,
                fileName: file.name,
                name: extractFilenameWithoutExtension(file.name)
            });
        }
    };

    render() {
        const { isOpen, t } = this.props;
        const { fileName, name } = this.state;
        const content = (
            <ModalWrapper cssHeight="360px" cssWidth="660px">
                <ModalTitleRow
                    closeHandler={this.onClose}
                    padding="16px"
                    title={t("image.upload_image")}
                    trackingComponentLabel={ImageUploadModal.displayName}
                />
                <ModalSeparator marginTop="0px" marginBottom="0px" />
                <ModalMainContentFlex padding="16px">
                    <FormSection>
                        <FormRow alignItems="flex-start">
                            <FieldLabel required={true} marginTop="8px">
                                {t("image.image")}
                            </FieldLabel>
                            <Flexbox flexDirection="column">
                                <Input
                                    name="file"
                                    type="text"
                                    cssWidth="310px"
                                    readOnly
                                    value={fileName}
                                    tabIndex={1}
                                />
                                <FieldTip>
                                    <div>
                                        {t("image.image_upload_filesize")}
                                    </div>
                                    <div>
                                        {t("image.image_upload_dimensions", {
                                            size: 600
                                        })}
                                    </div>
                                    <div>
                                        {t("image.image_upload_file_types")}
                                    </div>
                                </FieldTip>
                            </Flexbox>
                            <Dropzone
                                accept={IMAGE_FILE_TYPE}
                                onDrop={this.onFileChosen}
                                minSize={1}
                                multiple={false}
                                noDrag
                                ref={this.dropzoneRef}
                            >
                                {({ getInputProps, getRootProps }) => {
                                    return (
                                        <UploadContainer
                                            {...getRootProps()}
                                            onClick={() => {
                                                if (this.dropzoneRef.current) {
                                                    this.dropzoneRef.current.open();
                                                }
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <UntrackedButton
                                                marginLeft="16px"
                                                marginTop="2px"
                                                text={t(
                                                    "common:general.browse"
                                                )}
                                                type={BUTTON_TYPE_PRIMARY}
                                            />
                                        </UploadContainer>
                                    );
                                }}
                            </Dropzone>
                        </FormRow>
                        <FormRow alignItems="flex-start">
                            <FieldLabel required={true} marginTop="8px">
                                {t("common:general.name")}
                            </FieldLabel>
                            <Flexbox flexDirection="column">
                                <Input
                                    name="name"
                                    onChange={this.onChangeName}
                                    ref={this.setNameInputRef}
                                    type="text"
                                    cssWidth="310px"
                                    value={name}
                                    tabIndex={1}
                                />{" "}
                                <FieldTip>
                                    {t("image.image_upload_name")}
                                </FieldTip>
                            </Flexbox>
                        </FormRow>
                    </FormSection>
                </ModalMainContentFlex>
                <ModalButtonRow padding="16px">
                    <Button
                        marginLeft="20px"
                        onClick={this.onClose}
                        text={t("common:general.cancel")}
                        type={BUTTON_TYPE_SECONDARY}
                        trackingComponentLabel={[
                            ImageUploadModal.displayName,
                            "Close"
                        ]}
                    />
                    <Button
                        disabled={!name}
                        marginLeft="8px"
                        onClick={this.onUpload}
                        text={t("common:general.upload")}
                        type={BUTTON_TYPE_PRIMARY}
                        trackingComponentLabel={[
                            ImageUploadModal.displayName,
                            "Upload"
                        ]}
                    />
                </ModalButtonRow>
            </ModalWrapper>
        );
        return (
            <Modal
                closeOnExternalClick={true}
                closeHandler={this.onClose}
                isOpen={isOpen}
                trackingComponentLabel={ImageUploadModal.displayName}
            >
                {content}
            </Modal>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {};
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        /*        
        uploadFile: (
            url: string,
            file: File,
            hideSuccess: boolean,
            extraFormData: FormData
        ) => {
            return dispatch(uploadFile(url, file, hideSuccess, extraFormData));
        }
*/
        uploadFile: (
            url: string,
            file: File,
            name: string,
            addToLibrary = false,
            promotionIds: string[] = [],
            imageMaxHeight: number | null = null,
            imageMaxWidth: number | null = null,
            hideSuccess: boolean
        ) => {
            return dispatch(
                uploadImage(
                    url,
                    file,
                    name,
                    addToLibrary,
                    promotionIds,
                    imageMaxHeight,
                    imageMaxWidth,
                    hideSuccess
                )
            );
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(ImageUploadModal));
