import styled from "styled-components/macro";
import {
    borderRadius,
    marginProps,
    sizeProps
} from "common/components/styled/util";
import theme from "common/components/theme";

type TextAreaProps = Pick<flexboxPropsType, "flex"> &
    marginPropsType &
    sizePropsType;

const TextArea = styled.textarea<TextAreaProps>`
    ${marginProps}
    ${sizeProps}
    border: 1px solid ${theme.border};
    border-radius: ${borderRadius("input")};
    box-sizing: border-box;
    ${props => props.flex && `flex: ${props.flex};`}
    font-family: "BrandonTextRegular";
    font-size: 14px;
    height: ${props => (props.cssHeight ? props.cssHeight : "100px")};
    padding: 6px 12px;
    resize: none;
    width: ${props => (props.cssWidth ? props.cssWidth : "100%")};

    ::-webkit-input-placeholder {
        /* Chrome */
        color: ${theme.placeholder};
    }
    :-ms-input-placeholder {
        /* IE 10+ */
        color: ${theme.placeholder};
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        color: ${theme.placeholder};
    }
    :-moz-placeholder {
        /* Firefox 4 - 18 */
        color: ${theme.placeholder};
    }
`;
TextArea.displayName = "TextArea";

export default TextArea;
