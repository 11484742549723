import { APPLICATION_MATRIX } from "common/shell/util/types";
import {
    AGGREGATE_PREFIX,
    DASHBOARD_PREFIX,
    GRID_PREFIX
} from "common/shell/util/preferencesUtils";

export const PREFERENCE_PREFIX = APPLICATION_MATRIX.toLowerCase() + ":";

export const AGGREGATE_COMPLIANCE_OVERVIEW =
    AGGREGATE_PREFIX + "ComplianceOverview";
export const AGGREGATE_EVENTS_VIEW = AGGREGATE_PREFIX + "EventsView";
export const AGGREGATE_OPPORTUNITIES_OVERVIEW =
    AGGREGATE_PREFIX + "TestingOpportunitiesOverview";

export const DASHBOARD_GUIDELINES_COMPLIANCE =
    DASHBOARD_PREFIX + "GuidelinesCompliance";
export const DASHBOARD_GUIDELINES_COVERAGE =
    DASHBOARD_PREFIX + "GuidelinesCoverage";
export const DASHBOARD_TOP_PERFORMANCE = DASHBOARD_PREFIX + "TopPerformance";

export const GRID_ANALYSIS_COMPARISION_OFFERS =
    GRID_PREFIX + "GRID_ANALYSIS_COMPARISON_OFFERS";
export const GRID_ANALYSIS_CUSTOM_FACTORS =
    GRID_PREFIX + "AnalysisCustomFactors";
export const GRID_ANALYSIS_CUSTOM_FACTOR_VALUES =
    GRID_PREFIX + "AnalysisCustomFactorValues";
export const GRID_ANALYSIS_DEFAULT_SETTINGS =
    GRID_PREFIX + "AnalysisDefaultSettings";
export const GRID_ANALYSIS_DATA_TABLE = GRID_PREFIX + "AnalysisDataTable";
export const GRID_ANALYSIS_FACTOR_EXCLUDED_CAMPAIGNS =
    GRID_PREFIX + "AnalysisFactorExcludedCampaigns";
export const GRID_ANALYSIS_FACTOR_CAMPAIGNS =
    GRID_PREFIX + "AnalysisFactorCampaigns";
export const GRID_ANALYSIS_FACTOR_EXCLUDED_OFFERS =
    GRID_PREFIX + "AnalysisFactorExcludedOffers";
export const GRID_ANALYSIS_FACTOR_OFFERS = GRID_PREFIX + "AnalysisFactorOffers";
export const GRID_ANALYSIS_FACTOR_PROMOTION_DETAIL =
    GRID_PREFIX + "AnalysisFactorPromotionDetail";
export const GRID_ANALYSIS_OFFERS = GRID_PREFIX + "AnalysisOffers";
export const GRID_ANALYSIS_PAIRED_OFFERS =
    GRID_PREFIX + "GRID_ANALYSIS_PAIRED_OFFERS";
export const GRID_ANALYSIS_PRIMARY_OFFERS =
    GRID_PREFIX + "GRID_ANALYSIS_PRIMARY_OFFERS";
export const GRID_ANALYSIS_PROMOTION_METRICS =
    GRID_PREFIX + "AnalysisPromotionMetrics";
export const GRID_ANALYSIS_SAVED_ANALYSES =
    GRID_PREFIX + "AnalysisSavedAnalyses";
export const GRID_ANALYSIS_SEGMENTS = GRID_PREFIX + "AnalysisSegments";
export const GRID_ANALYSIS_SEGMENT_SETS = GRID_PREFIX + "AnalysisSegmentsSets";
export const GRID_AUTOMATED_DESIGN = GRID_PREFIX + "AutomatedDesign";
export const GRID_CAMPAIGN_OFFERS = GRID_PREFIX + "CampaignOffers";
export const GRID_CAMPAIGN_PPGS = GRID_PREFIX + "CampaignPPGs";
export const GRID_CAMPAIGN_TEST_RESULTS = GRID_PREFIX + "CampaignTestResults";
export const GRID_CAMPAIGN_UPCS = GRID_PREFIX + "CampaignUPCs";
export const GRID_COMPLIANCE_OVERVIEW = GRID_PREFIX + "ComplianceOverview";
export const GRID_EVENTS = GRID_PREFIX + "Events";
export const GRID_COMPLETED_CAMPAIGNS = GRID_PREFIX + "CompletedCampaigns";
export const GRID_DRAFT_CAMPAIGNS = GRID_PREFIX + "DraftCampaigns";
export const GRID_INREVIEW_CAMPAIGNS = GRID_PREFIX + "InReviewCampaigns";
export const GRID_PAUSED_CAMPAIGNS = GRID_PREFIX + "PausedCampaigns";
export const GRID_QUEUED_CAMPAIGNS = GRID_PREFIX + "QueuedCampaigns";
export const GRID_RUNNING_CAMPAIGNS = GRID_PREFIX + "RunningCampaigns";
export const GRID_GUIDELINE = GRID_PREFIX + "Guideline";
export const GRID_GUIDELINE_COLLECTION = GRID_PREFIX + "GuidelineCollection";
export const GRID_HOLIDAY = GRID_PREFIX + "Holiday";
export const GRID_HOLIDAY_OCCURRENCE = GRID_PREFIX + "HolidayOccurrence";
export const GRID_OPPORTUNITIES = GRID_PREFIX + "Opportunities";
export const GRID_PROMOTION_OFFER_LABELS = GRID_PREFIX + "PromotionOfferLabels";
export const GRID_RECOMMENDATION = GRID_PREFIX + "Recommendation";
export const GRID_REPLACE_IMAGES = GRID_PREFIX + "ReplaceImages";
export const GRID_SCORECARD = GRID_PREFIX + "Scorecard";
export const GRID_SAVED_REPORTS = GRID_PREFIX + "SavedReports";
export const GRID_SELECT_CAMPAIGN = GRID_PREFIX + "SelectCampaign";
export const GRID_SELECT_CAMPAIGN_OFFERS = GRID_PREFIX + "SelectCampaignOffers";
export const GRID_SELECT_CONTROL_OFFERS = GRID_PREFIX + "SelectControlOffers";
