import get from "lodash.get";
import type { AnyAction } from "redux";
import { stringFilterCondition } from "common/util/filter";
import {
    formatSortOrderForQueryAPI,
    makeRequestThunk,
    METHOD_POST,
    METHOD_PUT
} from "common/shell/state/requestActions";
import { PAGE_SIZE_UNLIMITED, sortCondition } from "common/util/query";
import type { RootState } from "store";

export const REQUEST_CAMPAIGN_PRODUCT_GROUPS =
    "REQUEST_CAMPAIGN_PRODUCT_GROUPS";
export const RECEIVE_CAMPAIGN_PRODUCT_GROUPS =
    "RECEIVE_CAMPAIGN_PRODUCT_GROUPS";
export const UPDATE_CAMPAIGN_PRODUCT_GROUPS_SORT =
    "UPDATE_CAMPAIGN_PRODUCT_GROUPS_SORT";

export const updateCampaignProductGroupsSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => {
    return {
        type: UPDATE_CAMPAIGN_PRODUCT_GROUPS_SORT,
        sortBy,
        sortOrder
    };
};

const requestCampaignProductGroups = (): AnyAction => ({
    type: REQUEST_CAMPAIGN_PRODUCT_GROUPS
});

const receiveCampaignProductGroups = (json: Json): AnyAction => ({
    type: RECEIVE_CAMPAIGN_PRODUCT_GROUPS,
    json: json
});

const getCampaignProductGroupsRequest = (
    state: RootState,
    fieldSet: string
) => {
    const sortOrder = formatSortOrderForQueryAPI(
        state.concept.campaignProductGroups.sortOrder
    );
    const sortConditions = [
        sortCondition(
            get(state, "products.campaignProductGroups.sortBy", ""),
            sortOrder
        )
    ];

    const filterCondition = stringFilterCondition(
        "campaign.entityId",
        get(state, "campaign.entityId"),
        "EQUALS"
    );

    return {
        entity: "CampaignProductGroup",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: PAGE_SIZE_UNLIMITED,
        model: "CampaignProductGroupModel/" + fieldSet,
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: null,
        sortConditions: sortConditions,
        startIndex: 0
    };
};

export function fetchCampaignProductGroups(fieldSet = "financialFields") {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getCampaignProductGroupsRequest(state, fieldSet);
        },
        preRequestFunc: requestCampaignProductGroups,
        okDispatchFunc: receiveCampaignProductGroups,
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.result,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

export function updateUPCs(ppg: any) {
    return makeRequestThunk("api/entity/campaignproductgroup/batch", {
        method: METHOD_PUT,
        body: [ppg],
        okResultFunc: (json: Json, state: RootState): any => {
            return json;
        }
    });
}
