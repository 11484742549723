import get from "lodash.get";
import type { AnyAction } from "redux";
import noAction from "common/util/noAction";
import { ASCENDING, DEFAULT_PAGE_SIZE } from "common/util/query";
import {
    RECEIVE_HOLIDAYS,
    REQUEST_HOLIDAYS,
    UPDATE_START_INDEX_HOLIDAYS,
    UPDATE_SORT_HOLIDAYS,
    UPDATE_SEARCH_HOLIDAYS,
    HOLIDAY_NAME
} from "administration/holidays/state/holidaysActions";

type HolidaysState = {
    maxResults: number;
    search: string;
    sortBy: string;
    sortOrder: SortOrder;
    startIndex: number;
    totalCount: number;
};

export const defaultState: HolidaysState = {
    maxResults: DEFAULT_PAGE_SIZE,
    search: "",
    sortBy: HOLIDAY_NAME,
    sortOrder: ASCENDING,
    startIndex: 0,
    totalCount: -1
};

export const reduceHolidays = (state: any, obj: any): HolidaysState => {
    return {
        startIndex: get(obj, "query.startIndex"),
        maxResults: get(obj, "query.maxResults"),
        totalCount: obj.totalCount,
        sortBy: state.sortBy,
        sortOrder: state.sortOrder,
        search: state.search
    };
};

const holidays = (
    state: HolidaysState = defaultState,
    action: AnyAction = noAction
): HolidaysState => {
    switch (action.type) {
        case REQUEST_HOLIDAYS:
            return state;
        case RECEIVE_HOLIDAYS:
            return Object.assign(
                {},
                state,
                reduceHolidays(state, action.holidays)
            );
        case UPDATE_SORT_HOLIDAYS:
            return Object.assign({}, state, {
                sortBy: action.sortBy,
                sortOrder: action.sortOrder
            });
        case UPDATE_SEARCH_HOLIDAYS:
            return Object.assign({}, state, { search: action.search });
        case UPDATE_START_INDEX_HOLIDAYS:
            return Object.assign({}, state, { startIndex: action.startIndex });
        default:
            return state;
    }
};

export default holidays;
