import type { AnyAction } from "redux";
import {
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import type { DateFilter } from "common/util/date";
import {
    buildSubstitutionComparisonReportRequest,
    buildSubstitutionSummaryReportRequest
} from "reports/substitution/state/substitutionActionUtils";
import type {
    SubstitutionReportAggregation,
    SubstitutionSummaryChartType,
    SubstitutionType
} from "reports/substitution/state/substitutionStateUtils";
import type { RootState } from "store";

export const CLEAR_SUBSTITUTION_COMPARISON_DATA =
    "CLEAR_SUBSTITUTION_COMPARISON_DATA";
export const CLEAR_SUBSTITUTION_SUMMARY_DATA =
    "CLEAR_SUBSTITUTION_SUMMARY_DATA";
export const RECEIVE_SUBSTITUTION_COMPARISON_CHART =
    "RECEIVE_SUBSTITUTION_COMPARISON_CHART";
export const RECEIVE_SUBSTITUTION_SUMMARY_CHARTS =
    "RECEIVE_SUBSTITUTION_SUMMARY_CHARTS";
export const RECEIVE_SUBSTITUTION_SUMMARY_PRODUCT_BREAKDOWN =
    "RECEIVE_SUBSTITUTION_SUMMARY_PRODUCT_BREAKDOWN";
export const REQUEST_SUBSTITUTION_COMPARISON_CHART =
    "REQUEST_SUBSTITUTION_COMPARISON_CHART";
export const REQUEST_SUBSTITUTION_SUMMARY_CHARTS =
    "REQUEST_SUBSTITUTION_SUMMARY_CHARTS";
export const REQUEST_SUBSTITUTION_SUMMARY_PRODUCT_BREAKDOWN =
    "REQUEST_SUBSTITUTION_SUMMARY_PRODUCT_BREAKDOWN";
export const UPDATE_SUBSTITUTION_COMPARISON_BENCHMARK_CATEGORIES =
    "UPDATE_SUBSTITUTION_COMPARISON_BENCHMARK_CATEGORIES";
export const UPDATE_SUBSTITUTION_SENTENCE_AGGREGATION =
    "UPDATE_SUBSTITUTION_SENTENCE_AGGREGATION";
export const UPDATE_SUBSTITUTION_SENTENCE_CATEGORIES =
    "UPDATE_SUBSTITUTION_SENTENCE_CATEGORIES";
export const UPDATE_SUBSTITUTION_SENTENCE_DATE =
    "UPDATE_SUBSTITUTION_SENTENCE_DATE";
export const UPDATE_SUBSTITUTION_SENTENCE_RETAILERS =
    "UPDATE_SUBSTITUTION_SENTENCE_RETAILERS";
export const UPDATE_SUBSTITUTION_SENTENCE_SELECTED_ITEM =
    "UPDATE_SUBSTITUTION_SENTENCE_SELECTED_ITEM";
export const UPDATE_SUBSTITUTION_SENTENCE_SELECTED_ITEMS =
    "UPDATE_SUBSTITUTION_SENTENCE_SELECTED_ITEMS";
export const UPDATE_SUBSTITUTION_SUMMARY_CHART_TYPE =
    "UPDATE_SUBSTITUTION_SUMMARY_CHART_TYPE";
export const UPDATE_SUBSTITUTION_SUMMARY_SUBSTITUTION_TYPE =
    "UPDATE_SUBSTITUTION_SUMMARY_SUBSTITUTION_TYPE";
export const UPDATE_SUBSTITUTION_SUMMARY_VIEW_BY =
    "UPDATE_SUBSTITUTION_SUMMARY_VIEW_BY";
export const VIEW_SUBSTITUTION_COMPARISON =
    "VIEW_SUBSTITUTION_COMPARISON";

// Substitution report actions
export const clearSubstitutionComparisonData = () => ({
    type: CLEAR_SUBSTITUTION_COMPARISON_DATA
});

export const clearSubstitutionSummaryData = () => ({
    type: CLEAR_SUBSTITUTION_SUMMARY_DATA
});

export const receiveSubstitutionComparisonChart = (json: Json): AnyAction => ({
    type: RECEIVE_SUBSTITUTION_COMPARISON_CHART,
    json
});

export const receiveSubstitutionSummaryCharts = (json: Json): AnyAction => ({
    type: RECEIVE_SUBSTITUTION_SUMMARY_CHARTS,
    json
});

export const receiveSubstitutionSummaryProductBreakdown = (
    json: Json
): AnyAction => ({
    type: RECEIVE_SUBSTITUTION_SUMMARY_PRODUCT_BREAKDOWN,
    json
});

export const requestSubstitutionComparisonChart = () => ({
    type: REQUEST_SUBSTITUTION_COMPARISON_CHART
});

export const requestSubstitutionSummaryCharts = () => ({
    type: REQUEST_SUBSTITUTION_SUMMARY_CHARTS
});

export const requestSubstitutionSummaryProductBreakdown = (): AnyAction => ({
    type: REQUEST_SUBSTITUTION_SUMMARY_PRODUCT_BREAKDOWN
});

// Common substitution sentence actions
export const updateSubstitutionSentenceAggregation = (
    aggregation: SubstitutionReportAggregation
): AnyAction => {
    return {
        type: UPDATE_SUBSTITUTION_SENTENCE_AGGREGATION,
        aggregation
    };
};

export const updateSubstitutionSentenceCategories = (
    categories: string[]
): AnyAction => {
    return {
        type: UPDATE_SUBSTITUTION_SENTENCE_CATEGORIES,
        categories
    };
};

export const updateSubstitutionSentenceDate = (date: DateFilter): AnyAction => {
    return {
        type: UPDATE_SUBSTITUTION_SENTENCE_DATE,
        date
    };
};

export const updateSubstitutionSentenceRetailer = (
    retailers: string[]
): AnyAction => {
    return {
        type: UPDATE_SUBSTITUTION_SENTENCE_RETAILERS,
        retailers
    };
};

export const updateSubstitutionSentenceSelectedItem = (
    selectedItem: OptionTypeOrNullOrUndefined
): AnyAction => {
    return {
        type: UPDATE_SUBSTITUTION_SENTENCE_SELECTED_ITEM,
        selectedItem
    };
};

export const updateSubstitutionSentenceSelectedItems = (
    selectedItems: OptionType[]
): AnyAction => {
    return {
        type: UPDATE_SUBSTITUTION_SENTENCE_SELECTED_ITEMS,
        selectedItems
    };
};

// Report specific actions
export const updateSubstitutionComparisonBenchmarkCategories = (
    categories: string[]
): AnyAction => {
    return {
        type: UPDATE_SUBSTITUTION_COMPARISON_BENCHMARK_CATEGORIES,
        categories
    };
};

export const updateSubstitutionSummaryChartType = (
    substitutionChartType: SubstitutionSummaryChartType
): AnyAction => {
    return {
        type: UPDATE_SUBSTITUTION_SUMMARY_CHART_TYPE,
        substitutionChartType
    };
};

export const updateSubstitutionSummarySubstitutionType = (
    substitutionType: SubstitutionType
): AnyAction => {
    return {
        type: UPDATE_SUBSTITUTION_SUMMARY_SUBSTITUTION_TYPE,
        substitutionType
    };
};

export const updateSubstitutionSummaryViewBy = (
    viewBy: SubstitutionReportAggregation
): AnyAction => {
    return {
        type: UPDATE_SUBSTITUTION_SUMMARY_VIEW_BY,
        viewBy
    };
};

export const viewSubstitutionComparison = (): AnyAction => ({
    type: VIEW_SUBSTITUTION_COMPARISON
});

// Substitution requests
export const fetchSubstitutionComparison = () => {
    return makeRequestThunk("api/report/substitutionComparison/chart", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState): any {
            return buildSubstitutionComparisonReportRequest(state, false);
        },
        preRequestFunc: requestSubstitutionComparisonChart,
        okDispatchFunc: receiveSubstitutionComparisonChart
    });
};

export const fetchSubstitutionSummaryProductBreakdown = () => {
    return makeRequestThunk("api/report/substitutionSummary/productBreakdown", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState): any {
            return buildSubstitutionSummaryReportRequest(state, false);
        },
        preRequestFunc: requestSubstitutionSummaryProductBreakdown,
        okDispatchFunc: receiveSubstitutionSummaryProductBreakdown
    });
};

export const fetchSubstitutionSummaryCharts = () => {
    return makeRequestThunk("api/report/substitutionSummary/charts", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState): any {
            return buildSubstitutionSummaryReportRequest(state, false);
        },
        preRequestFunc: requestSubstitutionSummaryCharts,
        okDispatchFunc: receiveSubstitutionSummaryCharts
    });
};
