/* Code re-purposed/grabbed from ag-grid-enterprise */

// not running typescript on it so included it in separate file

const subGroupIsSplit = (currentGroup: any, groupToAdd: any) => {
    if (Array.isArray(currentGroup.children)) {
        const existingChildIds = currentGroup.children.map((col: any) => {
            return getColumnId(col);
        });

        const childGroupAlreadyExists = existingChildIds.includes(
            getColumnId(groupToAdd)
        );

        const lastChild =
            currentGroup.children[currentGroup.children.length - 1];

        const lastChildIsDifferent =
            lastChild && getColumnId(lastChild) !== getColumnId(groupToAdd);

        return childGroupAlreadyExists && lastChildIsDifferent;
    }
    return false;
};

const addChildrenToGroup = (tree: any, groupId: string, colDef: any) => {
    // not a group
    if (!isColumnGroupDef(tree)) {
        return true;
    }

    const currentGroup = tree;
    const groupToAdd = colDef;

    if (subGroupIsSplit(currentGroup, groupToAdd)) {
        currentGroup.children.push(groupToAdd);
        return true;
    }

    if (currentGroup.groupId === groupId) {
        // add children that don't already exist to group
        const existingChildIds = currentGroup.children.map((col: any) => {
            return getColumnId(col);
        });

        const colDefAlreadyPresent = existingChildIds.includes(
            getColumnId(groupToAdd)
        );

        if (!colDefAlreadyPresent) {
            currentGroup.children.push(groupToAdd);
            return true;
        }
    } // recurse until correct group is found to add children

    if (Array.isArray(currentGroup.children)) {
        currentGroup.children.forEach((subGroup: any) => {
            return addChildrenToGroup(subGroup, groupId, colDef);
        });
    }
    return false;
};

const matchingRootGroupIds = (pathA: any, pathB: any) => {
    const bothPathsAreGroups =
        isColumnGroupDef(pathA) && isColumnGroupDef(pathB);

    return bothPathsAreGroups && getColumnId(pathA) === getColumnId(pathB);
};

const mergeTrees = (treeA: any, treeB: any): any[] => {
    // we can't just merge the leaf path trees as groups can be split apart - instead only merge if leaf
    // path groups with the same root group id are contiguous
    if (!isColumnGroupDef(treeB)) {
        return treeA;
    }

    const mergeResult = treeA;
    const groupToMerge = treeB;

    if (groupToMerge.children && groupToMerge.groupId) {
        const added = addChildrenToGroup(
            mergeResult,
            groupToMerge.groupId,
            groupToMerge.children[0]
        );

        if (added) {
            return mergeResult;
        }
    }

    if (Array.isArray(groupToMerge.children)) {
        groupToMerge.children.forEach((child: any) => {
            return mergeTrees(mergeResult, child);
        });
    }
    return mergeResult;
};

export const mergeLeafPathTrees = (leafPathTrees: any[]): any[] => {
    const mergeColDefs = [];

    for (let i = 1; i <= leafPathTrees.length; i++) {
        const first = leafPathTrees[i - 1];
        const second = leafPathTrees[i];

        if (matchingRootGroupIds(first, second)) {
            leafPathTrees[i] = mergeTrees(first, second);
        } else {
            mergeColDefs.push(first);
        }
    }

    return mergeColDefs;
};

const isColumnGroupDef = (colDef: any): boolean => {
    return colDef && typeof colDef.children !== "undefined";
};

export const getColumnId = (colDef: any): string => {
    if (isColumnGroupDef(colDef)) {
        return colDef.groupId;
    }
    return colDef.colId;
};
