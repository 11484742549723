import get from "lodash.get";
import type { AnyAction } from "redux";
import {
    formatSortOrderForQueryAPI,
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import {
    andFilterCondition,
    orFilterCondition,
    freeFormFilterCondition,
    stringFilterCondition,
    stringInFilterCondition
} from "common/util/filter";
import { creatorModifierQueryFields } from "common/util/object";
import { DEFAULT_PAGE_SIZE, sortCondition } from "common/util/query";
import { reduceGuideline } from "administration/guidelines/state/guidelineStateUtils";
import { GUIDELINES } from "filter/state/filterActions";
import type { FilterPageState } from "filter/state/filterReducers";
import type { RootState } from "store";

// Guideline fields
export const GUIDELINE_FIELDS: string[] = [
    "bundledProducts",
    "channels.entityId",
    "crossPromotion.entityId",
    "crossPromotion.notes",
    "crossPromotion.crossPromotionsNo.entityId",
    "crossPromotion.crossPromotionsNo.name",
    "crossPromotion.crossPromotionsYes.entityId",
    "crossPromotion.crossPromotionsYes.name",
    "entityId",
    "frequency.entityId",
    "frequency.frequency",
    "frequency.notes",
    "guidelineCollection.entityId",
    "guidelineCollection.name",
    "holidays.entityId",
    "holidays.name",
    "holidaySummary",
    "keyMessage",
    "promotedPriceGuidance.applyToEffectivePtc",
    "promotedPriceGuidance.conditionalMaxPromotedPrice",
    "promotedPriceGuidance.conditionalMinPromotedPrice",
    "promotedPriceGuidance.entityId",
    "promotedPriceGuidance.maxPromotedPrice",
    "promotedPriceGuidance.minPromotedPrice",
    "promotedPriceGuidance.notes",
    "promotedPriceGuidance.numOfEventsAllowed",
    "promotedPriceGuidance.promotedPriceTimePeriod",
    "name",
    "offerStructure.entityId",
    "offerStructure.notes",
    "offerStructure.offerStructureNo.entityId",
    "offerStructure.offerStructureNo.isOffer",
    "offerStructure.offerStructureNo.name",
    "offerStructure.offerStructureNo.offerIdentifier",
    "offerStructure.offerStructureNo.offerLabel",
    "offerStructure.offerStructureNo.offerStructure.displayName",
    "offerStructure.offerStructureNo.offerStructure.entityId",
    "offerStructure.offerStructureNo.offerStructure.name",
    "offerStructure.offerStructureNo.offerStructure.offerTypeDisplayName",
    "offerStructure.offerStructureNo.offerValues",
    "offerStructure.offerStructureNo.operation.displayName",
    "offerStructure.offerStructureNo.operation.entityId",
    "offerStructure.offerStructureNo.operation.value",
    "offerStructure.offerStructureNo.quantity",
    "offerStructure.offerStructureYes.isOffer",
    "offerStructure.offerStructureYes.entityId",
    "offerStructure.offerStructureYes.name",
    "offerStructure.offerStructureYes.offerIdentifier",
    "offerStructure.offerStructureYes.offerLabel",
    "offerStructure.offerStructureYes.offerStructure.displayName",
    "offerStructure.offerStructureYes.offerStructure.entityId",
    "offerStructure.offerStructureYes.offerStructure.name",
    "offerStructure.offerStructureYes.offerStructure.offerTypeDisplayName",
    "offerStructure.offerStructureYes.offerValues",
    "offerStructure.offerStructureYes.operation.displayName",
    "offerStructure.offerStructureYes.operation.entityId",
    "offerStructure.offerStructureYes.operation.value",
    "offerStructure.offerStructureYes.quantity",
    "products.entityId",
    "products.name",
    "productSummary",
    "recommendationMessage",
    "regions.entityId",
    "regions.name",
    "regionSummary",
    "retailers.entityId",
    "retailers.name",
    "retailerSummary",
    "supportingMaterials.entityId",
    "supportingMaterials.filename",
    "supportingMaterials.filesize",
    "supportingMaterials.thumbnailLink",
    "timing.entityId",
    "timing.notes",
    "timing.optimalTiming.entityId",
    "timing.optimalTiming.startMonth",
    "timing.optimalTiming.startDay",
    "timing.optimalTiming.endMonth",
    "timing.optimalTiming.endDay",
    "userSelectedRetailers.entityId",
    "visuals.entityId",
    "visuals.notes",
    "visuals.visualMaterials.entityId",
    "visuals.visualMaterials.filename",
    "visuals.visualMaterials.filesize",
    "visuals.visualMaterials.thumbnailLink"
].concat(creatorModifierQueryFields);

// Sort by
export const GUIDELINE_NAME = "name";
export const GUIDELINE_HOLIDAYS = "holidaySummary";
export const GUIDELINE_PPGS = "productSummary";
export const GUIDELINE_REGIONS = "regionSummary";
export const GUIDELINE_RETAILERS = "retailerSummary";

// action types
export const UPDATE_SORT_GUIDELINES = "UPDATE_SORT_GUIDELINES";
export const UPDATE_START_INDEX_GUIDELINES = "UPDATE_START_INDEX_GUIDELINES";
export const UPDATE_SEARCH_GUIDELINES = "UPDATE_SEARCH_GUIDELINES";
export const REQUEST_GUIDELINES = "REQUEST_GUIDELINES";
export const RECEIVE_GUIDELINES = "RECEIVE_GUIDELINES";

// Filter by
const GUIDELINE_HOLIDAY_ID = "holidays.entityId";
const GUIDELINE_PRODUCT_ID = "products.entityId";
const GUIDELINE_REGION_ID = "regions.entityId";
const GUIDELINE_RETAILERS_ID = "retailers.entityId";
const GUIDELINE_CHANNELS_RETAILERS_ID = "channels.retailers.entityId";

// actions creators
export const requestGuidelines = (): AnyAction => ({
    type: REQUEST_GUIDELINES
});

export const receiveGuidelines = (json: Json): AnyAction => ({
    type: RECEIVE_GUIDELINES,
    guidelines: json
});

export const updateStartIndex = (startIndex: number): AnyAction => ({
    type: UPDATE_START_INDEX_GUIDELINES,
    startIndex: startIndex
});

export const updateSortBy = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => ({
    type: UPDATE_SORT_GUIDELINES,
    sortBy: sortBy,
    sortOrder: sortOrder
});

export const updateSearch = (search: string): AnyAction => ({
    type: UPDATE_SEARCH_GUIDELINES,
    search: search.trim()
});

const getFilterCondition = (state: RootState): FilterCondition | null => {
    const filterConditions = [];
    const searchColumns = [GUIDELINE_NAME, GUIDELINE_PPGS, GUIDELINE_RETAILERS];
    const holidaysEnabled = get(state, "init.holidaysEnabled");
    const regionsEnabled = get(state, "init.preloadedEntities.filters.regions")
        .length;
    const searchText = get(state, ["filters", GUIDELINES, "search"], "");
    if (holidaysEnabled) {
        searchColumns.push(GUIDELINE_HOLIDAYS);
    }
    if (regionsEnabled) {
        searchColumns.push(GUIDELINE_REGIONS);
    }
    filterConditions.push(
        stringFilterCondition(
            "guidelineCollection.entityId",
            get(state, "guidelines.guidelineCollection.collectionId"),
            "EQUALS"
        )
    );
    if (searchText) {
        filterConditions.push(
            freeFormFilterCondition(searchText, searchColumns)
        );
    }
    if (get(state, ["filters", GUIDELINES, "holidays", "length"])) {
        filterConditions.push(
            stringInFilterCondition(
                GUIDELINE_HOLIDAY_ID,
                state.filters[GUIDELINES as keyof FilterPageState].holidays
            )
        );
    }
    if (get(state, ["filters", GUIDELINES, "products", "length"])) {
        filterConditions.push(
            stringInFilterCondition(
                GUIDELINE_PRODUCT_ID,
                state.filters[GUIDELINES as keyof FilterPageState].products
            )
        );
    }
    if (get(state, ["filters", GUIDELINES, "regions", "length"])) {
        filterConditions.push(
            stringInFilterCondition(
                GUIDELINE_REGION_ID,
                state.filters[GUIDELINES as keyof FilterPageState].regions
            )
        );
    }
    if (get(state, ["filters", GUIDELINES, "retailers", "length"])) {
        const retailerFilterConditions = [
            stringInFilterCondition(
                GUIDELINE_RETAILERS_ID,
                state.filters[GUIDELINES as keyof FilterPageState].retailers
            ),
            stringInFilterCondition(
                GUIDELINE_CHANNELS_RETAILERS_ID,
                state.filters[GUIDELINES as keyof FilterPageState].retailers
            )
        ];
        filterConditions.push(orFilterCondition(retailerFilterConditions));
    }
    if (filterConditions.length > 1) {
        return andFilterCondition(filterConditions);
    } else if (filterConditions.length === 1) {
        return filterConditions[0];
    }
    return null;
};

export const getGuidelineListQuery = (state: RootState): any => {
    const sortOrder = formatSortOrderForQueryAPI(
        get(state, "guidelines.guidelineCollection.sortOrder")
    );
    return {
        entity: "Guideline",
        filterCondition: getFilterCondition(state),
        maxResults: DEFAULT_PAGE_SIZE,
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: [...GUIDELINE_FIELDS],
        sortConditions: [
            sortCondition(
                get(state, "guidelines.guidelineCollection.sortBy"),
                sortOrder
            )
        ],
        startIndex: state.guidelines.guidelineCollection.startIndex
    };
};

export function fetchGuidelines() {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState): any {
            return getGuidelineListQuery(state);
        },
        preRequestFunc: requestGuidelines,
        okDispatchFunc: receiveGuidelines,
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.result.map(reduceGuideline),
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}
