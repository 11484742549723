import * as React from "react";
import styled from "styled-components/macro";
import {
    paddingProps,
    paddingPropTypes,
    sizeProps,
    sizePropTypes
} from "common/components/styled/util";
import theme from "common/components/theme";
import { reduceObject } from "common/util/object";

export const INPUT_WIDTH = "280px";
export const STD_WIDTH = "300px";
export const BUTTON_WIDTH = "302px";

const DialogPageBackground = styled.div`
    background-color: ${theme.universalBackground};
    bottom: 0;
    display: flex;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    flex-direction: column;
    z-index: 3;
`;
DialogPageBackground.displayName = "DialogPageBackground";

type DialogCardComponentProps = paddingPropsType & sizePropsType;

const DialogCardComponent = styled.div<DialogCardComponentProps>`
    background-color: ${theme.background};
    background-clip: border-box;
    border: 1px solid ${theme.border};
    border-radius: 3px;
    margin: auto;
    padding: 0;
    word-wrap: break-word;

    ${paddingProps}
    ${sizeProps}
`;
DialogCardComponent.displayName = "DialogCardComponent";

type DialogPageProps = {
    children: React.ReactNode;
} & paddingPropsType &
    sizePropsType;

const DialogPage = (props: DialogPageProps) => {
    return (
        <DialogPageBackground>
            <DialogCardComponent
                {...reduceObject(
                    props,
                    ...sizePropTypes.concat(paddingPropTypes)
                )}
            >
                {props.children}
            </DialogCardComponent>
        </DialogPageBackground>
    );
};
DialogPage.displayName = "DialogPage";

export default DialogPage;
