import * as React from "react";
import styled from "styled-components/macro";
import theme from "common/components/theme";
import Tooltip from "common/components/Tooltip";
import { withClickTracking } from "common/components/withClickTracking";
import type { WithClickTrackingProps } from "common/components/withClickTracking";
import {
    marginProps,
    paddingProps,
    sizeProps
} from "common/components/styled/util";
import {
    EVENT_NAME_OPEN_CLOSE,
    EVENT_NAME_PAGE_NAVIGATION
} from "common/util/trackingEvents";

export type DefaultLinkProps = {
    backgroundColor?: string;
    color?: string;
    display?: string;
    fontSize?: string;
    hoverColor?: string;
    overflow?: string;
    textAlign?: string;
    textDecoration?: string;
    textOverflow?: string;
} & marginPropsType &
    paddingPropsType &
    sizePropsType;

const DefaultLink = styled("a")<DefaultLinkProps>`
    color: ${props => (props.color ? props.color : theme.linkText)};
    font-family: "BrandonTextRegular";
    display: ${props => (props.display ? props.display : "inline-block")};
    text-align: ${props => (props.textAlign ? props.textAlign : "center")};
    text-decoration: ${props =>
        props.textDecoration ? props.textDecoration : "underline"};

    ${props =>
        props.backgroundColor && `background-color: ${props.backgroundColor};`}
    ${props => props.overflow && `overflow: ${props.overflow};`}
    ${props => props.textOverflow && `text-overflow: ${props.textOverflow};`}

    ${marginProps}
    ${paddingProps}
    ${sizeProps}

    :hover,
    :active {
        color: ${props =>
            props.hoverColor ? props.hoverColor : props.theme.linkHoverText};
        cursor: pointer;
        text-decoration: none;
    }
`;

type LinkProps = DefaultLinkProps &
    Omit<WithClickTrackingProps, "trackingComponentType">;

const Link = styled(DefaultLink).attrs((props: LinkProps) => ({
    trackingComponentLabel: props.trackingComponentLabel,
    trackingEventName: props.trackingEventName,
    trackingEventValue: props.trackingEventValue
}))`
    color: ${props => (props.color ? props.color : theme.linkText)};
`;
Link.displayName = "Link";

type TruncatedWrapperProps = {
    children: JSX.Element;
} & LinkProps;

const TruncLinkWrapper = styled(Link)<TruncatedWrapperProps>`
    overflow: hidden;
`;
TruncLinkWrapper.displayName = "TruncLinkWrapper";

export type TruncatedLinkProps = {
    text: string;
    tooltipBoundary?: string;
    tooltipMaxWidth?: string;
};

const TruncLink = (props: TruncatedLinkProps & LinkProps): JSX.Element => {
    const { text, tooltipBoundary, tooltipMaxWidth, ...rest } = props;
    return (
        <TruncLinkWrapper {...rest}>
            <Tooltip
                boundary={tooltipBoundary}
                checkOverflow={true}
                cssWidth="100%"
                maxWidth={tooltipMaxWidth}
                text={text}
            />
        </TruncLinkWrapper>
    );
};
TruncLink.displayName = "TruncatedLink";

export default withClickTracking(Link, Link.displayName, EVENT_NAME_OPEN_CLOSE);

export const UntrackedLink = DefaultLink;

export const TruncatedLink = withClickTracking(
    TruncLink,
    TruncLink.displayName,
    EVENT_NAME_PAGE_NAVIGATION
);
