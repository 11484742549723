export const AGGREGATE_PREFIX = "aggregate:";
export const DASHBOARD_PREFIX = "dashboard:";
export const GRID_PREFIX = "grid:";
export const REPORT_PREFIX = "report:";

export const GRID_TEAM = GRID_PREFIX + "Team";
export const GRID_TEAM_RETAILER = GRID_PREFIX + "TeamRetailer";
export const GRID_TEAM_USER = GRID_PREFIX + "TeamUser";
export const GRID_USER = GRID_PREFIX + "User";
export const GRID_DOWNLOADS = GRID_PREFIX + "Downloads";
