import {
    formatSortOrderForQueryAPI,
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import { reduceUser } from "common/administration/users/state/userStateUtils";
import type { UsersQueryResult } from "common/administration/users/state/userStateUtils";
import { USERS_PAGE } from "common/administration/state/pageActions";
import {
    booleanFilterCondition,
    andFilterCondition,
    freeFormFilterCondition
} from "common/util/filter";
import { sortCondition } from "common/util/query";
import type { RootState } from "store";

// action types
export const UPDATE_SORT_USERS = "UPDATE_SORT_USERS";
export const UPDATE_START_INDEX_USERS = "UPDATE_START_INDEX_USERS";
export const UPDATE_SEARCH_USERS = "UPDATE_SEARCH_USERS";
export const REQUEST_USERS = "REQUEST_USERS";
export const RECEIVE_USERS = "RECEIVE_USERS";

export type UsersActions =
    | SimpleAction<typeof USERS_PAGE>
    | ReceiveItems<typeof RECEIVE_USERS, UsersQueryResult>
    | RequestItems<typeof REQUEST_USERS>
    | UpdateSearch<typeof UPDATE_SEARCH_USERS>
    | UpdateSortBy<typeof UPDATE_SORT_USERS>
    | UpdateStartIndex<typeof UPDATE_START_INDEX_USERS>;

export const requestUsers = (): RequestItems<typeof REQUEST_USERS> => ({
    type: REQUEST_USERS
});

export const receiveUsers = (
    json: UsersQueryResult
): ReceiveItems<typeof RECEIVE_USERS, UsersQueryResult> => ({
    type: RECEIVE_USERS,
    items: json
});

export const updateStartIndex = (
    startIndex: number
): UpdateStartIndex<typeof UPDATE_START_INDEX_USERS> => ({
    type: UPDATE_START_INDEX_USERS,
    startIndex: startIndex
});

export const updateSortBy = (
    sortBy: string,
    sortOrder: SortOrder
): UpdateSortBy<typeof UPDATE_SORT_USERS> => ({
    type: UPDATE_SORT_USERS,
    sortBy: sortBy,
    sortOrder: sortOrder
});

export const updateSearch = (
    search: string
): UpdateSearch<typeof UPDATE_SEARCH_USERS> => ({
    type: UPDATE_SEARCH_USERS,
    search: search
});

export const usersPage = (): SimpleAction<typeof USERS_PAGE> => ({
    type: USERS_PAGE
});

const getFilterCondition = (state: RootState): FilterCondition => {
    const isHiddenFilterCondition = booleanFilterCondition("isHidden", false);
    if (state.users.search) {
        return andFilterCondition([
            isHiddenFilterCondition,
            freeFormFilterCondition(state.users.search, [
                "username",
                "lastName",
                "firstName",
                "team.name"
            ])
        ]);
    } else {
        return isHiddenFilterCondition;
    }
};

const getUsersRequest = (state: RootState): ListQueryRequest => {
    const sortOrder = formatSortOrderForQueryAPI(state.users.sortOrder);
    const sortConditions = [sortCondition(state.users.sortBy, sortOrder)];
    return {
        entity: "User",
        filterCondition: getFilterCondition(state),
        includeCrossTenantInstances: false,
        maxResults: state.users.maxResults,
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: [
            "email",
            "enabled",
            "entityId",
            "firstName",
            "fullName",
            "lastName",
            "username",
            "team.name",
            "team.entityId",
            "team.teamType.description",
            "team.teamType.name"
        ],
        sortConditions: sortConditions,
        startIndex: state.users.startIndex
    };
};

export function fetchUsers() {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getUsersRequest(state);
        },
        preRequestFunc: requestUsers,
        okDispatchFunc: receiveUsers,
        okResultFunc: (json: UsersQueryResult, state: RootState) => {
            return {
                items: json.result.map(reduceUser),
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}
