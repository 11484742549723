import * as React from "react";
import { Checkbox } from "common/components/CheckboxGroup";
import { marginPropTypes } from "common/components/styled/util";
import { ReactComponent as CalendarIcon } from "common/icons/Calendar.svg";
import CheckboxFilterDropdownView, {
    CheckboxDivider
} from "common/filter/components/CheckboxFilterDropdownView";
import { reduceObject } from "common/util/object";
import type { TrackingComponentLabel } from "common/util/trackingEvents";
import { composeTrackingComponentLabel } from "common/util/tracking";

type HolidayFilterDropdownViewProps = {
    buttonWidth?: string;
    highlightBackground?: boolean;
    highlightSelected?: boolean;
    holidayNodes: any[];
    holidaysSelected: string[];
    onHolidayFilterChange: (checked: string[]) => void;
    showIcon?: boolean;
    trackingComponentLabel: TrackingComponentLabel;
} & marginPropsType;

const HolidayFilterDropdownView = (props: HolidayFilterDropdownViewProps) => {
    const {
        buttonWidth = "180px",
        highlightBackground,
        highlightSelected,
        holidaysSelected,
        holidayNodes,
        onHolidayFilterChange,
        showIcon,
        trackingComponentLabel
    } = props;
    const iconProps = showIcon
        ? {
              icon: CalendarIcon,
              iconHeight: 16,
              iconWidth: 16
          }
        : {};
    const renderNodes = (
        nodes: any[],
        labelField: string,
        valueField: string
    ) => {
        const checkboxes = nodes.map(item => (
            <Checkbox
                label={item[labelField]}
                key={item[valueField]}
                value={item[valueField]}
            />
        ));
        /*the first node in the holiday filters list will be the non-holiday event.
            If the first showing node value matches the first node, and there's more than
            one, show the divider under it.

            search filtering happens inside of
        */
        if (
            nodes.length > 1 &&
            nodes[0][valueField] === holidayNodes[0][valueField]
        ) {
            checkboxes.splice(
                1,
                0,
                <CheckboxDivider key="divider" margin="8px -16px" />
            );
        }
        return checkboxes;
    };

    return (
        <CheckboxFilterDropdownView
            allLabelKey="general.all_holidays_slash_seasons"
            buttonWidth={buttonWidth}
            countLabelKey="general.holidays_slash_seasons_x"
            filterHeaderTextKey="general.holiday_slash_season_plural"
            highlightBackground={highlightBackground}
            highlightSelected={highlightSelected}
            name="holidaysFilter"
            nodes={holidayNodes}
            onFilterChange={onHolidayFilterChange}
            renderNodes={renderNodes}
            selected={holidaysSelected}
            trackingComponentLabel={composeTrackingComponentLabel([
                trackingComponentLabel,
                "Holiday Filter"
            ])}
            {...iconProps}
            {...reduceObject(props, ...marginPropTypes)}
        />
    );
};

HolidayFilterDropdownView.displayName = "HolidayFilterDropdownView";

export default HolidayFilterDropdownView;
