import createRavenMiddleware from "raven-for-redux";
import Raven from "raven-js";

export const getRavenMiddleware = () => {
    return createRavenMiddleware(Raven, {
        // don't return state
        stateTransformer: state => {
            return null;
        }
    });
};

export const reportError = (error: Error, info?: any) => {
    if (Raven.isSetup()) {
        if (info) {
            Raven.captureException(error, { extra: info });
        } else {
            Raven.captureException(error);
        }
    }
};
