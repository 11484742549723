import type { TFunction } from "i18next";
import type { AnyAction } from "redux";
import {
    receiveAsyncItems,
    requestAsyncItems
} from "common/administration/async/state/asyncActions";
import {
    USER_PAGE,
    USERS_PAGE,
    USER_CREATE_PAGE,
    USER_EDIT_PAGE
} from "common/administration/state/pageActions";
import { getTeamsFilterCondition } from "common/administration/teams/state/teamsActions";
import type { TeamQueryResult } from "common/administration/teams/state/teamsActions";
import { TEAM_NAME } from "common/administration/teams/state/teamStateUtils";
import { usersPage } from "common/administration/users/state/usersActions";
import type {
    GridUser,
    PersistUser
} from "common/administration/users/state/userStateUtils";
import { writeEntity } from "common/shell/state/entityActions";
import { fetchUsersReinit } from "common/shell/state/initActions";
import {
    openMessageBox,
    BUTTON_NOYES,
    BUTTON_YES,
    ICON_QUESTION
} from "common/shell/state/messageBoxActions";
import {
    resetCanNotLeave,
    setCanNotLeave
} from "common/shell/state/navigationActions";
import {
    makeRequestThunk,
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT
} from "common/shell/state/requestActions";
import { success } from "common/shell/state/toastActions";
import {
    andFilterCondition,
    notFilterCondition,
    stringInFilterCondition
} from "common/util/filter";
import {
    creatorModifierQueryFields,
    getEntityIdWithoutVersion
} from "common/util/object";
import { ASCENDING_QUERY_API, sortCondition } from "common/util/query";

import type { ActionOrThunk, Actions, AppDispatch, RootState } from "store";

// action types
export const REQUEST_USER = "REQUEST_USER";
export const RECEIVE_USER = "RECEIVE_USER";

export type UserActions =
    | EntityIdPayloadPageAction<typeof USER_EDIT_PAGE>
    | EntityIdPayloadPageAction<typeof USER_PAGE>
    | SimpleAction<typeof USER_CREATE_PAGE>
    | ReceiveItem<typeof RECEIVE_USER, GridUser>
    | RequestItem<typeof REQUEST_USER>;

export const requestUser = (): RequestItem<typeof REQUEST_USER> => ({
    type: REQUEST_USER
});

export const receiveUser = (
    json: GridUser
): ReceiveItem<typeof RECEIVE_USER, GridUser> => ({
    type: RECEIVE_USER,
    item: json
});

export const userPage = (
    entityId: string
): EntityIdPayloadPageAction<typeof USER_PAGE> => ({
    type: USER_PAGE,

    payload: {
        entityId: entityId
    }
});

export const userCreatePage = (): SimpleAction<typeof USER_CREATE_PAGE> => ({
    type: USER_CREATE_PAGE
});

export const userEditPage = (
    entityId: string
): EntityIdPayloadPageAction<typeof USER_EDIT_PAGE> => ({
    type: USER_EDIT_PAGE,

    payload: {
        entityId: entityId
    }
});

export function fetchUser() {
    return makeRequestThunk("api/entity/user", {
        method: METHOD_GET,
        urlPartFunc: function (state: RootState) {
            return "/" + state.user.entityId;
        },
        queryParamsFunc: function (state: RootState) {
            return {
                fields: [
                    "email",
                    "enabled",
                    "username",
                    "firstName",
                    "fullName",
                    "entityId",
                    "lastName",
                    "team.name",
                    "team.entityId",
                    "team.teamType.description",
                    "team.teamType.name"
                ].concat(creatorModifierQueryFields)
            };
        },
        preRequestFunc: requestUser,
        okDispatchFunc: receiveUser
    });
}

export function writeUser(entity: PersistUser, createAnother: boolean) {
    const method = entity.entityId ? METHOD_PUT : METHOD_POST;
    const okDispatchFunc = (
        json: WriteEntityResult,
        state: RootState
    ): Actions => {
        const actions: ActionOrThunk[] = [];
        const values = {
            username: entity.username
        };
        let successMessage = {
            resourceKey: "common:administration.create_user_success",
            values: values
        };
        if (method === METHOD_PUT) {
            successMessage = {
                resourceKey: "common:administration.edit_user_success",
                values: values
            };
        }
        if (!createAnother) {
            actions.push(resetCanNotLeave());
            actions.push(userPage(json.entityId));
        } else {
            actions.push(setCanNotLeave());
        }
        actions.push(fetchUsersReinit());
        actions.push(success(successMessage));
        return actions;
    };
    return writeEntity(entity, method, { okDispatchFunc });
}

export const resetPasswordConfirmation = (
    user: User,
    t: TFunction,
    dispatch: AppDispatch
) => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_NOYES,
            callbackData: user,
            callbackFunction: handleResetPasswordConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey:
                    "common:administration.reset_password_confirmation",
                values: {
                    username: user.username
                }
            },
            showCloseIcon: false,
            title: t("common:login.reset_password")
        })
    );
};

const handleResetPasswordConfirmation = (
    button: string,
    callbackData: User,
    dispatch: AppDispatch
) => {
    if (button === BUTTON_YES) {
        dispatch(resetPassword(callbackData.entityId, callbackData.username));
    }
};

export function resetPassword(entityId: string, username: string) {
    return makeRequestThunk("api/user/resetPassword/" + entityId, {
        isCancellable: false,
        method: METHOD_PUT,
        okDispatchFunc: (json: EmptyObject, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const successMessage = {
                resourceKey: "common:administration.reset_password_success",
                values: {
                    username: username
                }
            };
            if (state.page === USERS_PAGE) {
                actions.push(usersPage());
            } else {
                actions.push(userPage(getEntityIdWithoutVersion(entityId)));
            }
            actions.push(success(successMessage));
            return actions;
        }
    });
}

const getTeamsRequest = (
    search: string,
    offset: number,
    state: RootState,
    notSelected: string[] = []
): ListQueryRequest => {
    const sortConditions = [sortCondition(TEAM_NAME, ASCENDING_QUERY_API)];
    const filterConditions: FilterCondition[] = [];
    filterConditions.push(getTeamsFilterCondition(search, [TEAM_NAME]));
    if (notSelected.length) {
        filterConditions.push(
            notFilterCondition(stringInFilterCondition("entityId", notSelected))
        );
    }
    const filterCondition =
        filterConditions.length > 0
            ? filterConditions.length > 1
                ? andFilterCondition(filterConditions)
                : filterConditions[0]
            : null;

    return {
        entity: "Team",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: state.async.maxResults,
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: [
            TEAM_NAME,
            "entityId",
            "teamType.description",
            "teamType.name"
        ],
        sortConditions: sortConditions,
        startIndex: offset
    };
};

const receiveTeams = (json: TeamQueryResult): AnyAction => {
    return receiveAsyncItems({
        items: json.result.map((item: any) => {
            return {
                label: item.name,
                value: item.entityId,
                teamType: {
                    description: item.teamType.description?.value ?? "",
                    name: item.teamType.name ?? ""
                }
            };
        }),
        totalCount: json.totalCount
    });
};

export function fetchTeams(
    search: string,
    offset: number,
    notSelected: string[] = []
) {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getTeamsRequest(search, offset, state, notSelected);
        },
        preRequestFunc: requestAsyncItems,
        okDispatchFunc: receiveTeams,
        showLoadMask: false
    });
}
