import type { AnyAction } from "redux";
import { PROMOTION_EDIT_PAGE } from "shell/state/pageActions";

const defaultState: PromotionState = {
    entityId: ""
};

type PromotionState = {
    entityId: string;
};

function promotion(state: PromotionState = defaultState, action: AnyAction) {
    switch (action.type) {
        case PROMOTION_EDIT_PAGE:
            return Object.assign({}, defaultState, {
                entityId: action.payload && action.payload.promotionEntityId
            });
        default:
            return state;
    }
}

export default promotion;
