import {
    RECEIVE_USER,
    REQUEST_USER
} from "common/administration/users/state/userActions";
import type { UserActions } from "common/administration/users/state/userActions";
import {
    USER_PAGE,
    USER_CREATE_PAGE,
    USER_EDIT_PAGE
} from "common/administration/state/pageActions";
import {
    defaultState,
    reduceUser
} from "common/administration/users/state/userStateUtils";
import type { UserState } from "common/administration/users/state/userStateUtils";
import noAction from "common/util/noAction";

const user = (
    state: UserState = defaultState,
    action: UserActions = noAction
): UserState => {
    switch (action.type) {
        case USER_PAGE:
            return Object.assign({}, defaultState, {
                entityId: action.payload && action.payload.entityId
            });
        case USER_CREATE_PAGE:
            return defaultState;
        case USER_EDIT_PAGE:
            return Object.assign({}, defaultState, {
                entityId: action.payload && action.payload.entityId
            });
        case REQUEST_USER:
            return state;
        case RECEIVE_USER:
            return Object.assign({}, state, reduceUser(action.item));

        default:
            return state;
    }
};

export default user;
