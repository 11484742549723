import * as React from "react";
import { isEmptyString } from "common/util/lang";

export const getDisplayName = <T>(
    Component: React.ComponentType<T>
): string => {
    return (
        Component.displayName ||
        Component.name ||
        (typeof Component === "string" && !isEmptyString(Component)
            ? Component
            : "Unknown")
    );
};

// Force refresh load if lazy load fails
// https://gist.github.com/raphael-leger/4d703dea6c845788ff9eb36142374bdb#file-lazywithretry-js
// Need to figure out how to type componentImport...
export const lazyWithRetry = (
    componentImport: any
): React.LazyExoticComponent<React.ComponentType<any>> =>
    React.lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem("page-has-been-force-refreshed") ||
                "false"
        );

        try {
            const component = await componentImport();

            window.localStorage.setItem(
                "page-has-been-force-refreshed",
                "false"
            );

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                window.localStorage.setItem(
                    "page-has-been-force-refreshed",
                    "true"
                );
                return window.location.reload();
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    });

// retry loading
// https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
// https://gist.github.com/briancavalier/842626
export const retryLoad = (
    fn: () => Promise<any>,
    retriesLeft = 5,
    interval = 1000
): Promise<React.ReactNode> => {
    return new Promise<React.ReactNode>((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error: unknown) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // reject('maximum retries exceeded');
                        reject(error);
                        return;
                    }
                    // Passing on "reject" is the important part
                    retryLoad(fn, retriesLeft - 1, interval).then(
                        resolve,
                        reject
                    );
                }, interval);
            });
    });
};
