import * as React from "react";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import styled from "styled-components/macro";
import Button, { ICON_RIGHT } from "common/components/Button";
import type { ButtonType, IconPosition } from "common/components/Button";
import type {
    HorizontalPosition,
    VerticalPosition
} from "common/components/Dropdown";
import {
    DropdownContainer,
    DropdownContent,
    DropdownGroupWrapper,
    DropdownItem,
    DropdownSeparator,
    DropdownSubHeader,
    POSITION_LEFT,
    POSITION_BELOW
} from "common/components/Dropdown";
import { ReactComponent as DownCaretIcon } from "common/icons/DownCaret.svg";
import Flexbox from "common/components/styled/Flexbox";
import { marginPropTypes } from "common/components/styled/util";
import { reduceObject } from "common/util/object";
import { containsIgnoreReactOutsideClick } from "common/util/outsideClick";
import {
    composeTrackingComponentLabel,
    getTrackingEventData,
    trackEvent
} from "common/util/tracking";
import { EVENT_NAME_SELECTION_CHOICE } from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";
import type { RootState } from "store";

const ButtonWrapper = styled.div`
    display: inline-block;
    align-self: center;
`;
ButtonWrapper.displayName = "ButtonWrapper";

const SubLabel = styled.div`
    color: ${props => props.theme.defaultGreyText};
    font-size: 12px;
    white-space: wrap;
`;

type ButtonDropdownOwnProps = {
    customStyle?: React.ReactNode;
    description?: string;
    disabled?: boolean;
    horizontalPosition?: HorizontalPosition;
    horizontalPositionFunc?: () => HorizontalPosition;
    icon?: IconType;
    iconHeight?: string;
    iconPosition?: IconPosition;
    iconWidth?: string;
    includeSelectedValueInTracking?: boolean;
    label?: string;
    menuOptions: MenuItemType[];
    onClickHandler: (item: string) => void;
    trackingComponentLabel: TrackingComponentLabel;
    type?: ButtonType;
    verticalPosition?: VerticalPosition;
    verticalPositionFunc?: () => VerticalPosition;
} & marginPropsType;

type ButtonDropdownProps = ButtonDropdownOwnProps &
    PropsFromRedux &
    WithTranslation;

type ButtonDropdownState = {
    isOpen?: boolean;
};

class ButtonDropdown extends React.Component<
    ButtonDropdownProps,
    ButtonDropdownState
> {
    static displayName = "ButtonDropdown";

    constructor(props: ButtonDropdownProps) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    onClickHandler = (
        item: string,
        evt: React.SyntheticEvent<HTMLDivElement>
    ) => {
        const { includeSelectedValueInTracking, trackingComponentLabel } =
            this.props;
        // Do not send selected option value for now as id/enum is not too useful for analytics
        // tracking and we do not want to accidentally send sensitive value information out
        trackEvent(
            getTrackingEventData(
                ButtonDropdown.displayName,
                includeSelectedValueInTracking
                    ? composeTrackingComponentLabel([
                          trackingComponentLabel,
                          item
                      ])
                    : trackingComponentLabel,
                EVENT_NAME_SELECTION_CHOICE
            )
        );
        this.props.onClickHandler(item);
        this.setState({ isOpen: false });
        evt?.stopPropagation();
    };

    onTriggerClick = (evt: React.SyntheticEvent<HTMLButtonElement>) => {
        this.setState({ isOpen: !this.state.isOpen });
        evt?.stopPropagation();
    };

    onOutsideClick = (event: MouseEvent) => {
        if (!containsIgnoreReactOutsideClick(event)) {
            if (this.state.isOpen) {
                this.setState({ isOpen: false });
            }
        }
    };

    render() {
        const {
            allowableActions,
            customStyle,
            description,
            disabled,
            horizontalPosition,
            horizontalPositionFunc,
            icon,
            iconHeight,
            iconPosition,
            iconWidth,
            label,
            menuOptions,
            trackingComponentLabel,
            type,
            verticalPosition,
            verticalPositionFunc
        } = this.props;
        const filteredMenuOptions = menuOptions.filter(
            option =>
                !option.allowableAction ||
                (allowableActions[option.allowableAction] ?? false)
        );
        if (filteredMenuOptions && filteredMenuOptions.length > 0) {
            const ButtonTrigger = (
                <ButtonWrapper>
                    <Flexbox {...reduceObject(this.props, ...marginPropTypes)}>
                        <Button
                            customStyle={customStyle}
                            disabled={disabled}
                            icon={icon || DownCaretIcon}
                            iconHeight={iconHeight || "8px"}
                            iconPosition={iconPosition || ICON_RIGHT}
                            iconWidth={iconWidth || "8px"}
                            noMinWidth={true}
                            onClick={this.onTriggerClick}
                            text={label}
                            trackingComponentLabel={trackingComponentLabel}
                            type={type}
                        />
                    </Flexbox>
                </ButtonWrapper>
            );

            return (
                <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
                    <DropdownContainer>
                        {ButtonTrigger}
                        {this.state.isOpen && (
                            <DropdownContent
                                horizontalPosition={
                                    horizontalPosition || POSITION_LEFT
                                }
                                horizontalPositionFunc={horizontalPositionFunc}
                                verticalPosition={
                                    verticalPosition || POSITION_BELOW
                                }
                                verticalPositionFunc={verticalPositionFunc}
                                whiteSpace="normal"
                            >
                                <DropdownGroupWrapper>
                                    {description && (
                                        <React.Fragment>
                                            <DropdownSubHeader padding="10px 16px">
                                                {description}
                                            </DropdownSubHeader>
                                            <DropdownSeparator />
                                        </React.Fragment>
                                    )}
                                    {filteredMenuOptions.map(option => {
                                        let TextContent = option.label;
                                        if (option.subLabel) {
                                            TextContent = (
                                                <Flexbox flexDirection="column">
                                                    {option.label}
                                                    <SubLabel>
                                                        {option.subLabel}
                                                    </SubLabel>
                                                </Flexbox>
                                            );
                                        }
                                        return (
                                            <DropdownItem
                                                data-id={option.value}
                                                disabled={option.disabled}
                                                key={option.value}
                                                onClick={
                                                    !option.disabled
                                                        ? this.onClickHandler.bind(
                                                              null,
                                                              option.value
                                                          )
                                                        : undefined
                                                }
                                                whiteSpace="nowrap"
                                            >
                                                {TextContent}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownGroupWrapper>
                            </DropdownContent>
                        )}
                    </DropdownContainer>
                </OutsideClickHandler>
            );
        }
        return null;
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        allowableActions: state.init.allowableActions ?? {}
    };
};

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(ButtonDropdown));
