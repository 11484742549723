import "common/index.css"; // global CSS

// ag-grid
import { LicenseManager } from "ag-grid-enterprise";
import "common/grid.scss";

// license key for ag-grid
LicenseManager.setLicenseKey(
    "Using_this_{AG_Grid}_Enterprise_key_{AG-063242}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Instacart_company}_is_granted_a_{Multiple_Applications}_Developer_License_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{3}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{1_March_2026}____[v3]_[01]_MTc3MjMyMzIwMDAwMA==1c08148405f1f4c63af8f78c3b04e43f"
);
