import Raven from "raven-js";
import type { ServerMode, TrackingData } from "common/shell/util/types";
import { reduceObject } from "common/util/object";
import store from "store";
import { setErrorFound } from "common/shell/state/errorActions";
import { displayError } from "common/shell/state/requestActions";

const ravenOptions = {
    autoBreadcrumbs: {
        xhr: true, // XMLHttpRequest
        console: false, // console logging
        dom: true, // DOM interactions, i.e. clicks/typing
        location: true // url changes, including pushState/popState
    },
    debug: false,
    // See https://docs.sentry.io/clients/javascript/tips/
    ignoreErrors: [
        // Random plugins/extensions
        "top.GLOBALS",
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can't find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        // Facebook borked
        "fb_xd_fragment",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        "conduitPage"
    ],
    // See https://docs.sentry.io/clients/javascript/tips/
    ignoreUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ],
    // dataCallback is called when Raven is just about to send data to Sentry - allows for the modification of data before it is sent
    // This callback is called when
    // 1) code explicitly calls Raven.captureException - this happens when runtime errors are caught as in ErrorBoundary or requestActions
    // 2) an uncaught runtime exception has occurred
    // This callback is doing 2 things -
    // 1) It is displaying an generic error message (notification)
    // 2) It is setting errorFound to true in state so that the ErrorBoundary can pick up this change and unmount the whole React tree
    dataCallback: function (data: any) {
        store.dispatch(displayError());
        store.dispatch(setErrorFound());
        return data;
    }
};

export const initialize = (
    configUrl: string,
    serverMode: ServerMode,
    release: string
) => {
    if (!Raven.isSetup()) {
        const opts = Object.assign({}, ravenOptions, {
            environment: serverMode,
            release: release
        });
        Raven.config(configUrl, opts).install();
    }
};

export const setTrackingData = (trackingData: TrackingData) => {
    Raven.setUserContext(
        reduceObject(
            trackingData,
            ["userId", "id"],
            "tenantId",
            "teamType",
            "userType"
        )
    );
    Raven.setTagsContext(trackingData);
};
