import get from "lodash.get";
import isNil from "lodash.isnil";
import { LAST_365_DAYS } from "common/util/date";
import type { DateFilter } from "common/util/date";
import { EVENT_COMPLIANCE } from "event/state/eventsStateUtils";
import {
    CLEAR_ALL,
    CLEAR_ALL_FILTERS,
    EVENTS,
    COPY_FILTERS,
    CREATE_TEST,
    OPPORTUNITIES,
    OVERVIEW_TOP_PERFORMANCE,
    RECOMMENDATIONS,
    UPDATE_CAMPAIGN_END_DATE_FILTER,
    UPDATE_CAMPAIGN_FILTER,
    UPDATE_CAMPAIGN_START_DATE_FILTER,
    UPDATE_DATE_FILTER,
    UPDATE_HOLIDAY_FILTER,
    UPDATE_MANAGER_FILTER,
    UPDATE_PERFORMANCE_TIER_FILTER,
    UPDATE_PRODUCT_FILTER,
    UPDATE_PROMO_TYPE_FILTER,
    UPDATE_RANGE,
    UPDATE_REGION_FILTER,
    UPDATE_RETAILER_FILTER,
    UPDATE_SEARCH,
    UPDATE_SHOW,
    UPDATE_SHOW_COMPLIANCE,
    UPDATE_SHOW_TESTED,
    UPDATE_SHOW_TOGGLE
} from "filter/state/filterActions";
import type { FilterActions } from "filter/state/filterActions";
import type {
    EventsFilterState,
    FilterState,
    OpportunitiesFilterState,
    RecommendationsFilterState,
    TopPerformanceFilterState
} from "filter/utils/filterUtils";

export type FilterPageState = {
    compliance_overview: FilterState;
    create_test: EventsFilterState;
    events: EventsFilterState;
    guideline_collections: FilterState;
    guidelines: FilterState;
    opportunities: OpportunitiesFilterState;
    overview_compliance: FilterState;
    overview_coverage: FilterState;
    overview_top_performance: TopPerformanceFilterState;
    recommendations: RecommendationsFilterState;
};

export const defaultDate: DateFilter = {
    time: LAST_365_DAYS
};

export const defaultFilterState: FilterState = {
    date: defaultDate,
    holidays: [],
    managers: [],
    products: [],
    regions: [],
    retailers: [],
    search: "",
    show: [],
    showToggle: ""
};

const defaultOpportunitiesFilterState: OpportunitiesFilterState = {
    ...defaultFilterState,
    showTested: []
};

export const defaultEventsFilterState: EventsFilterState = {
    ...defaultFilterState,
    max: null,
    min: null,
    performanceTiers: [],
    promoTypes: [],
    showCompliance: [],
    showTested: [],
    showToggle: EVENT_COMPLIANCE
};

const defaultTopPerformanceFilterState: TopPerformanceFilterState = {
    ...defaultFilterState,
    promoTypes: []
};

export const defaultRecommendationsFilterState: RecommendationsFilterState = {
    ...defaultFilterState,
    campaign: null,
    campaignEndDate: null,
    campaignStartDate: null
};

export const defaultState: FilterPageState = {
    compliance_overview: defaultFilterState,
    create_test: defaultEventsFilterState,
    events: defaultEventsFilterState,
    guideline_collections: defaultFilterState,
    guidelines: defaultFilterState,
    opportunities: defaultOpportunitiesFilterState,
    overview_compliance: defaultFilterState,
    overview_coverage: defaultFilterState,
    overview_top_performance: defaultTopPerformanceFilterState,
    recommendations: defaultRecommendationsFilterState
};

const getPageDefaultState = (pageId: string) => {
    switch (pageId) {
        case CREATE_TEST:
            return defaultEventsFilterState;
        case EVENTS:
            return defaultEventsFilterState;
        case RECOMMENDATIONS:
            return defaultRecommendationsFilterState;
        case OPPORTUNITIES:
            return defaultOpportunitiesFilterState;
        case OVERVIEW_TOP_PERFORMANCE:
            return defaultTopPerformanceFilterState;
        default:
            return defaultFilterState;
    }
};

const filters = (
    state: FilterPageState = defaultState,
    action: FilterActions
): FilterPageState => {
    // @ts-expect-error pageId missing from type, need to fix
    const pageDefaultFilterState = getPageDefaultState(action.pageId);
    switch (action.type) {
        case CLEAR_ALL: {
            const clearAll: any = {};
            clearAll[action.pageId] = Object.assign({}, pageDefaultFilterState);
            return Object.assign({}, state, clearAll);
        }
        case CLEAR_ALL_FILTERS: {
            const clearFilters: any = {};
            const { date } = get(state, action.pageId);
            clearFilters[action.pageId] = Object.assign(
                {},
                pageDefaultFilterState,
                {
                    date
                }
            );
            return Object.assign({}, state, clearFilters);
        }
        case COPY_FILTERS: {
            const fromFilters = get(state, action.from);
            const toFilters: any = {};
            // If copying filters to view with default events filter, make sure show toggle is set
            if (action.to === EVENTS || action.to === CREATE_TEST) {
                if (isNil(fromFilters.showToggle)) {
                    fromFilters.showToggle = state.events.showToggle;
                }
            }
            toFilters[action.to] = { ...get(state, action.to), ...fromFilters };
            return Object.assign({}, state, toFilters);
        }
        case UPDATE_CAMPAIGN_END_DATE_FILTER: {
            const newCampaignEndDateFilters: any = {};
            newCampaignEndDateFilters[action.pageId] = Object.assign(
                {},
                get(state, action.pageId),
                {
                    campaignEndDate: action.campaignEndDate
                }
            );
            return Object.assign({}, state, newCampaignEndDateFilters);
        }
        case UPDATE_CAMPAIGN_FILTER: {
            const newCampaignFilters: any = {};
            newCampaignFilters[action.pageId] = Object.assign(
                {},
                get(state, action.pageId),
                {
                    campaign: action.campaign
                }
            );
            return Object.assign({}, state, newCampaignFilters);
        }
        case UPDATE_CAMPAIGN_START_DATE_FILTER: {
            const newCampaignStartDateFilters: any = {};
            newCampaignStartDateFilters[action.pageId] = Object.assign(
                {},
                get(state, action.pageId),
                {
                    campaignStartDate: action.campaignStartDate
                }
            );
            return Object.assign({}, state, newCampaignStartDateFilters);
        }
        case UPDATE_DATE_FILTER: {
            const newDateFilters: any = {};
            newDateFilters[action.pageId] = Object.assign(
                {},
                get(state, action.pageId),
                {
                    date: action.date
                }
            );
            return Object.assign({}, state, newDateFilters);
        }
        case UPDATE_HOLIDAY_FILTER: {
            const newHolidayFilters: any = {};
            newHolidayFilters[action.pageId] = Object.assign(
                {},
                get(state, action.pageId),
                {
                    holidays: action.holidays
                }
            );
            return Object.assign({}, state, newHolidayFilters);
        }
        case UPDATE_RETAILER_FILTER: {
            const newRetailerFilters: any = {};
            newRetailerFilters[action.pageId] = Object.assign(
                {},
                get(state, action.pageId),
                {
                    retailers: action.retailers
                }
            );
            return Object.assign({}, state, newRetailerFilters);
        }
        case UPDATE_PERFORMANCE_TIER_FILTER:
            return Object.assign({}, state, {
                [action.pageId]: {
                    ...get(state, action.pageId),
                    performanceTiers: action.performanceTiers
                }
            });
        case UPDATE_PRODUCT_FILTER: {
            const newProductFilters: any = {};
            newProductFilters[action.pageId] = Object.assign(
                {},
                get(state, action.pageId),
                {
                    products: action.products
                }
            );
            return Object.assign({}, state, newProductFilters);
        }
        case UPDATE_PROMO_TYPE_FILTER:
            return Object.assign({}, state, {
                [action.pageId]: {
                    ...get(state, action.pageId),
                    promoTypes: action.promoTypes
                }
            });
        case UPDATE_MANAGER_FILTER: {
            const newManagerFilters: any = {};
            newManagerFilters[action.pageId] = Object.assign(
                {},
                get(state, action.pageId),
                {
                    managers: action.managers
                }
            );
            return Object.assign({}, state, newManagerFilters);
        }
        case UPDATE_REGION_FILTER: {
            const newRegionFilters: any = {};
            newRegionFilters[action.pageId] = Object.assign(
                {},
                get(state, action.pageId),
                {
                    regions: action.regions
                }
            );
            return Object.assign({}, state, newRegionFilters);
        }
        case UPDATE_SEARCH: {
            const newSearch: any = {};
            newSearch[action.pageId] = Object.assign(
                {},
                get(state, action.pageId),
                {
                    search: action.search
                }
            );
            return Object.assign({}, state, newSearch);
        }
        case UPDATE_RANGE: {
            const newRange: any = {};
            newRange[action.pageId] = Object.assign(
                {},
                get(state, action.pageId),
                {
                    max: action.max,
                    min: action.min
                }
            );
            return Object.assign({}, state, newRange);
        }
        case UPDATE_SHOW: {
            const newShow: any = {};
            newShow[action.pageId] = Object.assign(
                {},
                get(state, action.pageId),
                {
                    show: action.show
                }
            );
            return Object.assign({}, state, newShow);
        }
        case UPDATE_SHOW_COMPLIANCE: {
            const newShowCompliance: any = {};
            newShowCompliance[action.pageId] = Object.assign(
                {},
                get(state, action.pageId),
                {
                    showCompliance: action.showCompliance
                }
            );
            return Object.assign({}, state, newShowCompliance);
        }
        case UPDATE_SHOW_TESTED: {
            const newShowTested: any = {};
            newShowTested[action.pageId] = Object.assign(
                {},
                get(state, action.pageId),
                {
                    showTested: action.showTested
                }
            );
            return Object.assign({}, state, newShowTested);
        }
        case UPDATE_SHOW_TOGGLE: {
            const newShowToggle: any = {};
            newShowToggle[action.pageId] = Object.assign(
                {},
                get(state, action.pageId),
                {
                    showToggle: action.showToggle
                }
            );
            return Object.assign({}, state, newShowToggle);
        }
        default:
            return state;
    }
};

export default filters;
