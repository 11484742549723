import type { TFunction } from "i18next";
import styled from "styled-components/macro";
import {
    PageHeaderSubTitle,
    PageHeaderTitle
} from "common/components/PageHeader";
import Flexbox from "common/components/styled/Flexbox";
import { FormRow } from "common/components/styled/Form";
import { FieldLabel } from "common/components/styled/Label";
import TooltipWrapper from "common/components/styled/TooltipWrapper";
import Tooltip from "common/components/Tooltip";
import i18n from "common/i18n";
import {
    openMessageBox,
    BUTTON_NOYES,
    BUTTON_NO,
    BUTTON_YES,
    ICON_QUESTION
} from "common/shell/state/messageBoxActions";
import { displayError } from "common/shell/state/requestActions";
import {
    COMPARISON_NONE,
    COMPARISON_PRIOR_PERIOD,
    COMPARISON_SAME_PERIOD_PRIOR_YEAR,
    CUSTOM,
    LAST_MONTH,
    LAST_3_MONTHS,
    LAST_365_DAYS,
    LAST_6_MONTHS,
    YEAR_TO_DATE
} from "common/util/date";
import type { AppDispatch } from "store";

export const COLUMN_DISPLAY_TYPE = "COLUMN";
export const DATA_TABLE_DISPLAY_TYPE = "DATA_TABLE";
export const STACKED_BAR_DISPLAY_TYPE = "STACKED_BAR";
export const TREND_DISPLAY_TYPE = "TREND";

type ReportEditedProps = {
    edited?: boolean;
};

export const ReportName = styled.div<ReportEditedProps>`
    font-size: 18px;
    ${props => props.edited && `font-family: BrandonTextItalic;`}
`;

export const ReportEdited = styled.div`
    font-size: 12px;
`;

// Used to filter out empty reports
export const NO_REPORT = "NO_REPORT";

export const reportDateNodes = (includeCustom = false): DateMenuItemType[] => {
    const reportDateNodes = [
        {
            id: LAST_MONTH,
            label: i18n.t("common:date.last_month")
        },
        {
            id: LAST_3_MONTHS,
            label: i18n.t("common:date.last_3_months")
        },
        {
            id: LAST_6_MONTHS,
            label: i18n.t("common:date.last_6_months")
        },
        {
            id: YEAR_TO_DATE,
            label: i18n.t("common:date.year_to_date")
        },
        {
            id: LAST_365_DAYS,
            label: i18n.t("common:date.last_365_days")
        }
    ];
    if (includeCustom) {
        reportDateNodes.push({
            id: CUSTOM,
            label: i18n.t("common:date.custom_date_range")
        });
    }
    return reportDateNodes;
};

export const reportComparisonDateNodes = (): DateMenuItemType[] => {
    const reportComparisonDateNodes = [
        {
            id: COMPARISON_NONE,
            label: i18n.t("common:date.no_comparison_period")
        },
        {
            id: COMPARISON_PRIOR_PERIOD,
            label: i18n.t("common:date.prior_period")
        },
        {
            id: COMPARISON_SAME_PERIOD_PRIOR_YEAR,
            label: i18n.t("common:date.same_period_prior_year")
        }
    ];
    return reportComparisonDateNodes;
};

export const getReportTitleSubTitleNode = (
    title: string,
    subTitle: React.ReactNode
) => {
    return (
        <Flexbox flexDirection="column">
            <PageHeaderTitle>{title}</PageHeaderTitle>
            <PageHeaderSubTitle>{subTitle}</PageHeaderSubTitle>
        </Flexbox>
    );
};

export const displayDuplicateNameError = (name: string) => {
    return displayError(
        i18n.t("common:reports.duplicate_series_name_error", {
            seriesName: name
        })
    );
};

export const TrendSeriesFormDiscardMessage = (
    dispatch: AppDispatch,
    callback: (leave: boolean) => void
) => {
    const handleConfirmLeaveConfirmation = (
        button: string,
        callbackData: any
    ) => {
        callbackData.callback(button === BUTTON_YES);
    };

    return openMessageBox({
        buttons: BUTTON_NOYES,
        buttonText: {
            [BUTTON_NO]: i18n.t("common:general.keep_editing"),
            [BUTTON_YES]: i18n.t("common:general.discard_changes")
        },
        callbackData: {
            callback: callback
        },
        callbackFunction: handleConfirmLeaveConfirmation,
        dispatch: dispatch,
        icon: ICON_QUESTION,
        message: {
            resourceKey: "common:reports.confirm_series_navigation_prompt"
        },
        showCloseIcon: false,
        title: i18n.t("common:reports.discard_series_changes")
    });
};

const READ_ONLY_SUMMARY_LIMIT = 5;

export const summarizeList = (
    values: string[],
    t: TFunction,
    limit: number = READ_ONLY_SUMMARY_LIMIT,
    mapper: (x: string) => string = x => x
): string => {
    let summary = values.slice(0, limit).map(mapper).join(", ");
    if (values.length > limit) {
        summary =
            summary +
            " " +
            t("common:general.and_x_more_no_ellipsis", {
                count: values.length - limit
            });
    }
    return summary;
};

export const genericSummarize = (
    allString: string,
    items: string[],
    t: TFunction,
    limit: number = READ_ONLY_SUMMARY_LIMIT,
    mapper: (x: string) => string = x => x
): string => {
    if (items.length === 0) {
        return allString;
    }
    return summarizeList(items, t, limit, mapper);
};

const ReadOnlyFieldLabel = styled(FieldLabel)`
    flex-shrink: 0;
`;

const ReadOnlyFieldValue = styled.div`
    align-items: flex-start;
`;

type ReadOnlyFormRowProps = {
    allString: string;
    displayValueMapper?: (x: string) => string;
    label: string;
    t: TFunction;
    values: string[];
};

export const ReadOnlyFormRow = (props: ReadOnlyFormRowProps) => {
    const { allString, displayValueMapper = x => x, label, t, values } = props;

    const allValues =
        values.length > 0
            ? values.map(displayValueMapper).join(", ")
            : allString;

    let readOnlyValue = null;
    if (values.length > READ_ONLY_SUMMARY_LIMIT) {
        // values are more than the limit, show a summarized version in a row and then all values in the tooltip
        const summarizedValues = genericSummarize(
            allString,
            values,
            t,
            READ_ONLY_SUMMARY_LIMIT,
            displayValueMapper
        );
        readOnlyValue = (
            <Tooltip maxWidth="500px">
                <ReadOnlyFieldValue>{summarizedValues}</ReadOnlyFieldValue>
                <TooltipWrapper>{allValues}</TooltipWrapper>
            </Tooltip>
        );
    } else {
        readOnlyValue = <ReadOnlyFieldValue>{allValues}</ReadOnlyFieldValue>;
    }

    return (
        <FormRow
            alignItems="baseline"
            fieldLabelWidth="150px"
            flexWrap="nowrap"
        >
            <ReadOnlyFieldLabel>{label}</ReadOnlyFieldLabel>
            {readOnlyValue}
        </FormRow>
    );
};

export const getFieldValueRow = (field: string, value: string): string => {
    return field + ": <b>" + value + "</b><br/>";
};
