import get from "lodash.get";
import moment from "moment";
import { reduceObject } from "common/util/object";

export const ITEM_TYPE_HOLIDAY = "holiday" as string;
export const ITEM_TYPE_PRODUCT = "productGroup" as string;
export const ITEM_TYPE_REGION = "region" as string;
export const ITEM_TYPE_RETAILER = "retailer" as string;

export const COVERAGE_CROSSPROMOTION = "crossPromotionCoverage" as string;
export const COVERAGE_FREQUENCY = "frequencyCoverage" as string;
export const COVERAGE_OFFERSTRUCTURE = "offerStructureCoverage" as string;
export const COVERAGE_PROMOTED_PRICE = "promotedPriceCoverage" as string;
export const COVERAGE_TIMING = "timingCoverage" as string;
export const COVERAGE_VISUALS = "visualCoverage" as string;

export const PERFORMANCE_COLUMN_A = "COLUMN_A" as string;
export const PERFORMANCE_COLUMN_B = "COLUMN_B" as string;
export const PERFORMANCE_COMPLIANCE_STATUS = "COMPLIANCE_STATUS" as string;
export const PERFORMANCE_DATE = "DATE" as string;

type CompliancePerMonthType = {
    x: string;
    y: number;
};

export type GuidelineComplianceItemType = {
    entityId: string;
    name: string;
    percentCompliant: string;
    type: string;
};

type GuidelineComplianceCountType = {
    holidays: number;
    ppgs: number;
    regions: number;
    retailers: number;
};

export type GuidelineComplianceType = {
    compliancePerMonth: CompliancePerMonthType[];
    initialTotalCount: GuidelineComplianceCountType;
    leastCompliantHolidays: GuidelineComplianceItemType[];
    leastCompliantPpgs: GuidelineComplianceItemType[];
    leastCompliantRegions: GuidelineComplianceItemType[];
    leastCompliantRetailers: GuidelineComplianceItemType[];
    mostCompliantHolidays: GuidelineComplianceItemType[];
    mostCompliantPpgs: GuidelineComplianceItemType[];
    mostCompliantRegions: GuidelineComplianceItemType[];
    mostCompliantRetailers: GuidelineComplianceItemType[];
    selectedGuidances: string[];
    totalCount: GuidelineComplianceCountType;
};

type CoverageType = {
    promoSalesForWeeksWithCoverage: string;
    promoSalesForWeeksWithoutCoverage: string;
    totalWeeks: number;
    weeksWithCoverage: number;
};

export type GuidelineCoverageType = {
    crossPromotionCoverage: CoverageType;
    frequencyCoverage: CoverageType;
    hasCurrentGuidelines: boolean;
    hasCurrentRecommendations: boolean;
    offerStructureCoverage: CoverageType;
    promotedPriceCoverage: CoverageType;
    timingCoverage: CoverageType;
    totalCoverage: CoverageType;
    visualCoverage: CoverageType;
};

export type TopPerformanceType = { series: any[] };

const reduceCompliancePerMonth = (
    compliantMonth: any
): CompliancePerMonthType => {
    const compliancePerMonth = reduceObject(
        compliantMonth,
        ["startOfMonth", "x"],
        ["percentCompliant", "y"]
    );
    compliancePerMonth.x = moment(compliancePerMonth.x).valueOf();
    return compliancePerMonth;
};

export const reduceGuidelineComplianceItem = (
    item: any,
    type: string
): GuidelineComplianceItemType => {
    return {
        entityId: get(item, `${type}.entityId`),
        name: get(item, `${type}.name`),
        percentCompliant: get(item, "percentCompliantFormatPercent"),
        type
    };
};

export const reduceGuidelineCompliance = (
    response: Json,
    selectedGuidances: string[],
    initialTotalCount: GuidelineComplianceCountType
): GuidelineComplianceType => {
    // LEAST COMPLIANT
    const leastCompliantHolidays = get(
        response,
        "leastCompliantHolidays",
        []
    ).map((item: any) =>
        reduceGuidelineComplianceItem(item, ITEM_TYPE_HOLIDAY)
    );
    const leastCompliantPpgs = get(
        response,
        "leastCompliantPpgs",
        []
    ).map((item: any) =>
        reduceGuidelineComplianceItem(item, ITEM_TYPE_PRODUCT)
    );
    const leastCompliantRegions = get(
        response,
        "leastCompliantRegions",
        []
    ).map((item: any) => reduceGuidelineComplianceItem(item, ITEM_TYPE_REGION));
    const leastCompliantRetailers = get(
        response,
        "leastCompliantRetailers",
        []
    ).map((item: any) =>
        reduceGuidelineComplianceItem(item, ITEM_TYPE_RETAILER)
    );

    // MOST COMPLIANT
    const mostCompliantHolidays = get(
        response,
        "mostCompliantHolidays",
        []
    ).map((item: any) =>
        reduceGuidelineComplianceItem(item, ITEM_TYPE_HOLIDAY)
    );
    const mostCompliantPpgs = get(
        response,
        "mostCompliantPpgs",
        []
    ).map((item: any) =>
        reduceGuidelineComplianceItem(item, ITEM_TYPE_PRODUCT)
    );
    const mostCompliantRegions = get(
        response,
        "mostCompliantRegions",
        []
    ).map((item: any) => reduceGuidelineComplianceItem(item, ITEM_TYPE_REGION));
    const mostCompliantRetailers = get(
        response,
        "mostCompliantRetailers",
        []
    ).map((item: any) =>
        reduceGuidelineComplianceItem(item, ITEM_TYPE_RETAILER)
    );

    const { holidays, ppgs, regions, retailers } = initialTotalCount;
    const holidayTotalCount =
        leastCompliantHolidays.length + mostCompliantHolidays.length;
    const ppgTotalCount = leastCompliantPpgs.length + mostCompliantPpgs.length;
    const regionTotalCount =
        leastCompliantRegions.length + mostCompliantRegions.length;
    const retailerTotalCount =
        leastCompliantRetailers.length + mostCompliantRetailers.length;
    return {
        compliancePerMonth: response.compliancePerMonth.map(
            reduceCompliancePerMonth
        ),
        initialTotalCount: {
            holidays: holidays === -1 ? holidayTotalCount : holidays,
            ppgs: ppgs === -1 ? ppgTotalCount : ppgs,
            regions: regions === -1 ? regionTotalCount : regions,
            retailers: retailers === -1 ? retailerTotalCount : retailers
        },
        leastCompliantHolidays,
        leastCompliantPpgs,
        leastCompliantRegions,
        leastCompliantRetailers,
        mostCompliantHolidays,
        mostCompliantPpgs,
        mostCompliantRegions,
        mostCompliantRetailers,
        selectedGuidances,
        totalCount: {
            holidays: holidayTotalCount,
            ppgs: ppgTotalCount,
            regions: regionTotalCount,
            retailers: retailerTotalCount
        }
    };
};

const reduceCoverage = (currentCoverage: any): CoverageType => {
    return {
        promoSalesForWeeksWithCoverage: get(
            currentCoverage,
            "promoSalesForWeeksWithCoverage",
            ""
        ),
        promoSalesForWeeksWithoutCoverage: get(
            currentCoverage,
            "promoSalesForWeeksWithoutCoverage",
            ""
        ),
        totalWeeks: get(currentCoverage, "totalWeeks", 0),
        weeksWithCoverage: get(currentCoverage, "weeksWithCoverage", 0)
    };
};

export const reduceGuidelineCoverage = (
    response: Json
): GuidelineCoverageType => {
    return {
        crossPromotionCoverage: reduceCoverage(response.crossPromotionCoverage),
        frequencyCoverage: reduceCoverage(response.frequencyCoverage),
        hasCurrentGuidelines: response.hasCurrentGuidelines,
        hasCurrentRecommendations: response.hasCurrentRecommendations,
        offerStructureCoverage: reduceCoverage(response.offerStructureCoverage),
        promotedPriceCoverage: reduceCoverage(response.promotedPriceCoverage),
        timingCoverage: reduceCoverage(response.timingCoverage),
        totalCoverage: reduceCoverage(response.totalCoverage),
        visualCoverage: reduceCoverage(response.visualCoverage)
    };
};

export const reduceTopPerformanceSeries = (series: any[]): any[] => {
    return series.map(column => {
        return {
            ...column,
            data: column.data
                ? column.data.map((value: any) => {
                      // Need to reduce null values to 0 so that they show up in column chart
                      // Mark the manually set 0 values as isNull
                      const pointValue = value[1];
                      if (pointValue === null) {
                          return {
                              entityId: value[2],
                              name: value[0],
                              y: 0,
                              isNull: true
                          };
                      } else {
                          return {
                              entityId: value[2],
                              name: value[0],
                              y: pointValue,
                              isNull: false
                          };
                      }
                  })
                : []
        };
    });
};
