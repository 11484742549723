import type { AnyAction } from "redux";
import {
    appRoot,
    getAuthToken,
    loggedOutAndAppRoot
} from "common/auth/state/commonAuthActions";
import {
    hideComponentLoadmask,
    showComponentLoadmask,
    hideLoadmask,
    showLoadmask
} from "common/shell/state/loadmaskActions";
import { abortRequestAndGetSignal } from "common/shell/state/requestStateUtils";
import { error } from "common/shell/state/toastActions";
import { getDefaultErrorMessage, reportError } from "common/shell/util/error";
import { completeUrl } from "common/util/url";
import {
    ASCENDING,
    ASCENDING_QUERY_API,
    DESCENDING,
    DESCENDING_QUERY_API
} from "common/util/query";
import type {
    ActionOrThunk,
    Actions,
    AppDispatch,
    GetState,
    RootState
} from "store";

export const METHOD_DELETE = "DELETE";
export const METHOD_GET = "GET";
export const METHOD_POST = "POST";
export const METHOD_PUT = "PUT";

export type PostPutMethod = typeof METHOD_POST | typeof METHOD_PUT;

export function formatSortOrderForQueryAPI(
    sortOrder: SortOrder
): SortOrderQueryApi {
    switch (sortOrder) {
        case ASCENDING:
            return ASCENDING_QUERY_API;
        case DESCENDING:
            return DESCENDING_QUERY_API;
        default:
            return ASCENDING_QUERY_API;
    }
}

export function isJson(response: Response): boolean {
    const contentType = response.headers.get("content-type");
    if (contentType) {
        return contentType.startsWith("application/json");
    }
    return false;
}

export function asQueryString(params: any): string {
    return Object.keys(params)
        .map(key => {
            return (
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            );
        })
        .join("&");
}

type Message = {
    message?: string;
};

export function getErrorMessage(json: Message): string {
    return (json && json.message) || getDefaultErrorMessage();
}

export function displayError(message: string = getDefaultErrorMessage()) {
    return error(message);
}

export function handleUnknownError(error: Error) {
    reportError(error);
}

export async function request(url: string, spec: any): Promise<any> {
    const response = await fetch(completeUrl(url), spec);
    return response;
}

type Result = {
    response: any;
    json: any;
};

export async function requestJson(url: string, spec: any): Promise<Result> {
    const response: any = await fetch(completeUrl(url), spec);
    const json: any = isJson(response) ? await response.json() : {};
    return { response, json };
}

export const BODY_TYPE_FILE = "file" as string;
const BODY_TYPE_JSON = "json" as string;
const BODY_TYPE_PLAIN = "plain" as string;
const BODY_TYPE_QUERY_STRING = "queryString" as string;

type BodyFunction = (state: RootState) => any;
type UrlPartFunction = (state: RootState) => string;
type Body = any | any[];
export type BodyType =
    | typeof BODY_TYPE_FILE
    | typeof BODY_TYPE_JSON
    | typeof BODY_TYPE_PLAIN
    | typeof BODY_TYPE_QUERY_STRING;
export type RequestMethod =
    | typeof METHOD_DELETE
    | typeof METHOD_GET
    | typeof METHOD_POST
    | typeof METHOD_PUT;
type ErrorFunction = (message: string) => Actions;
type LoggedOutFunction = (json: Json, state: RootState) => Actions;
export type OkDispatchFunction = (json: Json, state: RootState) => Actions;
export type OkResultFunction = (json: Json, state: RootState) => any;
type OkTest = (response: any, json: Json) => boolean;
type NotOkDispatchFunction = (
    response: any,
    json: Json,
    state: RootState
) => Actions | undefined | null;
type PreRequestFunction = () => Actions;

export type RequestOptions = {
    authToken?: string;
    body?: Body;
    bodyFunc?: BodyFunction;
    bodyType?: BodyType;
    checkLoggedOut?: boolean;
    errorFunc?: ErrorFunction;
    isCancellable?: boolean; // can request be aborted? Defaults to true. Requests that shouldn't be aborted are requests that write to the database and those when navigating to another page shouldn't abort (e.g. saving preferences, re-reading init values)
    loggedOutFunc?: LoggedOutFunction;
    method?: RequestMethod;
    notOkDispatchFunc?: NotOkDispatchFunction;
    okDispatchFunc?: OkDispatchFunction;
    okResultFunc?: OkResultFunction;
    okTest?: OkTest;
    preRequestFunc?: PreRequestFunction;
    queryParams?: any;
    queryParamsFunc?: BodyFunction;
    showComponentLoadMask?: boolean;
    showLoadMask?: boolean;
    signalKey?: string; // MUST BE UNIQUE - if request can be aborted and you want to individually control which request is cancelled, set a unique signalKey - see requestStateUtils.js
    urlPartFunc?: UrlPartFunction;
};

export const MISSING_OK_FUNC =
    "You need to specify an okDispatchFunc or okResultFunc option to handle successful requests.";

export function createRequestSpec(
    method: RequestMethod,
    authToken: string
): any {
    return {
        cache: "no-cache",
        headers: {
            Accept: "application/json, text/plain, */*",
            AuthToken: authToken,
            "Cache-Control": "no-cache"
        },
        method: method
    };
}

function addBody(requestSpec: any, body: Body, bodyType: BodyType) {
    if (bodyType === "json") {
        requestSpec.body = JSON.stringify(body);
        requestSpec.headers["Content-Type"] = "application/json";
    } else if (bodyType === "queryString") {
        if (!Array.isArray(body)) {
            requestSpec.body = asQueryString(body);
            requestSpec.headers["Content-Type"] =
                "application/x-www-form-urlencoded;charset=UTF-8";
        }
    } else if (bodyType === "file" || bodyType === "plain") {
        requestSpec.body = body;
    }
}

export function dispatchAll(dispatch: AppDispatch, actions?: Actions | null) {
    if (Array.isArray(actions)) {
        actions.forEach(a => dispatch(a as ActionOrThunk));
    } else if (actions) {
        dispatch(actions as ActionOrThunk);
    }
}

export function makeRequestThunk(url: string, options: RequestOptions) {
    return async function (dispatch: AppDispatch, getState: GetState) {
        // Need to figure out how to reformat promise to avoid async promise executor
        // eslint-disable-next-line no-async-promise-executor
        return new Promise<ResponseSuccessResult>(async (resolve, reject) => {
            const {
                authToken,
                body,
                bodyFunc,
                bodyType = "json",
                checkLoggedOut = true,
                errorFunc = displayError,
                isCancellable = true,
                loggedOutFunc = (
                    json: SuccessDto | AuthTokenDto,
                    state: RootState
                ) => {
                    return loggedOutAndAppRoot(json, state);
                },
                method = "GET",
                notOkDispatchFunc = (
                    response: any,
                    json: Json,
                    state: RootState
                ): AnyAction | undefined | null => {
                    return null;
                },
                okDispatchFunc = undefined,
                okResultFunc = undefined,
                okTest = (response: any, json: Json): boolean => {
                    return response.ok;
                },
                preRequestFunc,
                queryParams,
                queryParamsFunc,
                showComponentLoadMask = false,
                showLoadMask = true,
                signalKey,
                urlPartFunc
            } = options;

            let signal: AbortSignal | undefined = undefined;
            // if request is cancellable
            if (isCancellable) {
                // get signal to attach to request
                signal = abortRequestAndGetSignal(signalKey);
            }

            let hideComponentMask,
                hideMask,
                result,
                success = false;
            try {
                if (showLoadMask) {
                    hideMask = dispatch(showLoadmask());
                }
                if (showComponentLoadMask) {
                    if (!signalKey) {
                        throw Error(
                            "Component loadmask requires a signalKey for identification"
                        );
                    } else {
                        hideComponentMask = dispatch(
                            showComponentLoadmask(signalKey)
                        );
                    }
                }
                const requestSpec = createRequestSpec(
                    method,
                    authToken || getAuthToken(getState())
                );
                if (body) {
                    addBody(requestSpec, body, bodyType);
                } else {
                    if (bodyFunc) {
                        addBody(requestSpec, bodyFunc(getState()), bodyType);
                    }
                }
                if (preRequestFunc) {
                    dispatchAll(dispatch, preRequestFunc());
                }
                if (urlPartFunc) {
                    url += urlPartFunc(getState());
                }
                if (queryParams) {
                    url += "?" + asQueryString(queryParams);
                } else {
                    if (queryParamsFunc) {
                        url += "?" + asQueryString(queryParamsFunc(getState()));
                    }
                }
                // if there is an AbortSignal - attach it to request
                if (signal) {
                    requestSpec.signal = signal;
                }

                const { response, json } = await requestJson(url, requestSpec);
                if (checkLoggedOut && response.status === 401) {
                    dispatchAll(dispatch, loggedOutFunc(json, getState()));
                } else if (response.status === 301) {
                    // On 301 code navigate back to the appRoot
                    dispatch(appRoot());
                } else if (okTest(response, json)) {
                    if (!okDispatchFunc && !okResultFunc) {
                        dispatchAll(dispatch, displayError(MISSING_OK_FUNC));
                    }
                    if (okDispatchFunc) {
                        dispatchAll(dispatch, okDispatchFunc(json, getState()));
                    }
                    if (okResultFunc) {
                        result = okResultFunc(json, getState());
                    }
                    success = true;
                } else {
                    dispatchAll(
                        dispatch,
                        notOkDispatchFunc(response, json, getState()) ||
                            errorFunc(getErrorMessage(json))
                    );
                }
            } catch (error) {
                if (process.env.NODE_ENV === "test") {
                    console.log(error);
                }
                const err = error as Error;
                // don't do anything if abort error
                if (err.name !== "AbortError") {
                    // error message displayed in raven.js just before Sentry logs runtime exception
                    handleUnknownError(err);
                }
            } finally {
                if (hideMask) {
                    dispatch(hideLoadmask());
                }
                if (hideComponentMask && signalKey) {
                    dispatch(hideComponentLoadmask(signalKey));
                }
                resolve({ success, result });
            }
        });
    };
}
