import * as React from "react";
import { Suspense } from "react";
import get from "lodash.get";
import { withCookies } from "react-cookie";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import Login from "common/auth/components/Login";
import {
    RETRIEVE_PASSWORD,
    renewAuthorization,
    retrievePassword
} from "common/auth/state/authActions";
import {
    STATE_INITIAL,
    STATE_RETRIEVE_PASSWORD,
    STATE_LOGGED_IN,
    UNAUTHENTICATED_STATES
} from "common/auth/state/authReducers";
import AppError from "common/shell/components/AppError";
import Loadmask from "common/shell/components/Loadmask";
import SuspenseLoading from "common/shell/components/SuspenseLoading";
import { resetOnRouteChange } from "common/shell/state/navigationActions";
import { lazyWithRetry, retryLoad } from "common/util/component";
import { registerActor } from "common/util/routeActionMiddleware";
import { trackPageView } from "common/util/tracking";
import type { AppDispatch, RootState } from "store";

const AsyncMain = lazyWithRetry(() =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Can't find shell/components/Main module
    retryLoad(() => import("shell/components/Main"))
);

type AppOwnProps = {
    appWindowName: string;
    cookies: string;
};

type AppProps = AppOwnProps & PropsFromRedux & WithTranslation;

class App extends React.Component<AppProps> {
    componentDidMount() {
        const {
            appWindowName,
            authorizationAction,
            retrievePasswordToken,
            cookies,
            retrievePassword
        } = this.props;
        window.name = appWindowName;
        registerActor(trackPageView);
        registerActor(resetOnRouteChange);
        if (UNAUTHENTICATED_STATES.indexOf(this.props.state) === -1) {
            authorizationAction(cookies);
        } else {
            if (this.props.state === RETRIEVE_PASSWORD) {
                // this used to be done in a route with a thunk action but thunks on initial app load
                // don't seem to work anymore
                retrievePassword(retrievePasswordToken as string);
            }
        }
        window.addEventListener("beforeunload", () => {
            window.name = "";
        });
    }

    removeLoadingMask = () => {
        const loadingDiv = document.getElementById("loader");
        if (loadingDiv && loadingDiv.parentElement) {
            loadingDiv.parentElement.removeChild(loadingDiv);
        }
    };

    render() {
        const { error, state, t } = this.props;
        if (error) {
            this.removeLoadingMask();
            return (
                <AppError
                    message={t(
                        "common:general.application_initialization_error"
                    )}
                />
            );
        }

        if (state !== STATE_INITIAL && state !== STATE_RETRIEVE_PASSWORD) {
            this.removeLoadingMask();
            const MainContent =
                state === STATE_LOGGED_IN ? (
                    <Suspense fallback={<SuspenseLoading />}>
                        <Loadmask showSpinner={true} />
                        <AsyncMain />
                    </Suspense>
                ) : (
                    <React.Fragment>
                        <Loadmask showSpinner={true} />
                        <Login />
                    </React.Fragment>
                );
            return MainContent;
        }
        return null;
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        retrievePasswordToken: get(state, "location.payload.token", ""),
        error: get(state, "init.error"),
        state: get(state, "auth.state")
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        authorizationAction: (cookies: any) => {
            dispatch(renewAuthorization(cookies));
        },
        retrievePassword: (token: string) => {
            dispatch(retrievePassword(token));
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

// @ts-expect-error withCookies causes typescript errors
export default withTranslation()(withCookies(connector(App)));
