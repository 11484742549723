import get from "lodash.get";
import isNil from "lodash.isnil";
import {
    setRequestComparisonDate,
    setRequestDates,
    setRequestDatesStatic,
    setRequestSearch,
    setRequestSort
} from "common/reports/state/reportsActionUtils";
import { getReportStateKey } from "common/reports/state/reportsStateUtils";
import { getPreference } from "common/shell/util/preferences";
import { getDataTableGridId } from "reports/components/DataTableView";
import {
    AGGREGATE_BY_PPG,
    isUpcAggregate,
    PURCHASE_CYCLE,
    PURCHASE_CYCLE_AND_PROMOTIONS
} from "reports/purchaseCycle/state/purchaseCycleStateUtils";
import type { Aggregate } from "reports/purchaseCycle/state/purchaseCycleStateUtils";
import { getSubcategories } from "reports/state/reportsStateUtils";
import type {
    ReportFilters,
    ReportView
} from "reports/state/reportsStateUtils";
import type { RootState } from "store";

export const DATA_TABLE_URL = "api/report/purchasecycle/explore";
export const DATA_TABLE_EXPORT_URL = "api/report/purchasecycle/explore/export";

export function getAggregate(aggregates: Aggregate[]): Aggregate {
    return !isNil(aggregates) && aggregates.length > 0
        ? aggregates[0]
        : AGGREGATE_BY_PPG;
}

export function getDataTableRequest(
    reportView: ReportView,
    filters: ReportFilters,
    state: RootState,
    savedWithStaticDates = false
): any {
    const aggregate = getAggregate(
        get(
            state,
            getReportStateKey(
                PURCHASE_CYCLE,
                reportView,
                "dataTable.aggregates"
            )
        )
    );
    const categoryNodes =
        state.init.preloadedEntities.filters.reports
            ?.purchaseCycleUpcCategories ?? [];
    let categories: any[] = [];
    // if aggregation is UPC, translate into subcategories
    if (isUpcAggregate(aggregate) && filters.filterType === PURCHASE_CYCLE) {
        categories = getSubcategories(filters.subcategories, categoryNodes);
    }
    const request: any = {
        objectType: "PurchaseCycleReportTableRequest",
        aggregation: {
            objectType: "PurchaseCycleReportAggregation",
            value: aggregate
        },
        brands: filters.filterType === PURCHASE_CYCLE ? filters.brands : [],
        categories: categories,
        productGroupIds: filters.ppgs
    };
    if (savedWithStaticDates) {
        setRequestDatesStatic(
            request,
            get(filters, "date"),
            "PurchaseCycleReportTimePeriod",
            state,
            PURCHASE_CYCLE,
            reportView
        );
    } else {
        setRequestDates(
            request,
            get(filters, "date"),
            "PurchaseCycleReportTimePeriod"
        );
    }
    setRequestComparisonDate(request, get(filters, "comparisonDate"));
    setRequestSearch(request, PURCHASE_CYCLE, reportView, state);
    setRequestSort(request, PURCHASE_CYCLE, reportView, state);
    return request;
}

export const getPurchaseCycleSavedReportsRequests = (
    reportView: ReportView,
    state: RootState,
    savedWithStaticDates = false
) => {
    const requests = [];
    const filters = get(
        state,
        getReportStateKey(PURCHASE_CYCLE, reportView, "dataTable.filters")
    );
    switch (reportView) {
        case PURCHASE_CYCLE_AND_PROMOTIONS:
            requests.push({
                objectType: "SavedReportRequest",
                serializedRequest: JSON.stringify(
                    getDataTableRequest(
                        reportView,
                        filters,
                        state,
                        savedWithStaticDates
                    )
                )
            });
            break;
        default:
            break;
    }
    return requests;
};

export const getPurchaseCycleSavedReportsState = (
    reportView: ReportView,
    state: RootState
) => {
    const uiState: Dictionary<string> = {};
    const gridId = getDataTableGridId(reportView);
    const gridState = getPreference(gridId, state.preferences, {});
    switch (reportView) {
        case PURCHASE_CYCLE_AND_PROMOTIONS:
            uiState[gridId] = gridState;
            break;
        default:
            break;
    }
    return JSON.stringify(uiState);
};
