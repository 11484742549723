import type { Action } from "redux";

// action types
export const SET_SESSION_PREFERENCE = "SET_SESSION_PREFERENCE";

export type SessionPreferencesActions = SetSessionPreference;

interface SetSessionPreference extends Action {
    type: typeof SET_SESSION_PREFERENCE;
    preference: {
        name: string;
        value: any;
    };
}

export function setSessionPreference(
    name: string,
    value: any
): SetSessionPreference {
    return {
        type: SET_SESSION_PREFERENCE,
        preference: {
            name,
            value
        }
    };
}
