import React from "react";
import { nanoid } from "nanoid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Button, {
    BUTTON_TYPE_SECONDARY,
    BUTTON_TYPE_TERTIARY
} from "common/components/Button";
import Flexbox from "common/components/styled/Flexbox";
import Link from "common/components/styled/Link";
import theme from "common/components/theme";
import Tooltip from "common/components/Tooltip";
import TooltipWrapper from "common/components/styled/TooltipWrapper";

export const CHARACTERS: Dictionary<string> = {
    cent: "¢",
    copyright: "©",
    dollar: "$",
    euro: "€",
    registered: "®",
    trademark: "™",
    pound: "£"
};

const SpecialCharsContainer = styled.div`
    position: relative;
`;

const SymbolSelectorOverlay = styled.div<{ isOpen: boolean }>`
    background-color: ${theme.background};
    border: 1px solid ${theme.border};
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 ${theme.boxShadowColor}
    flex-direction: column;
    justify-content: flex-start;
    padding: 8px 0px 8px 8px;
    position: absolute;
    visibility: ${props => (props.isOpen ? "visible" : "hidden")};
    top: 38px;
    right: 0px;
    width: 128px;
    z-index: 500;
`;

type SymbolSelectorProps = {
    insertChar: (char: string) => void;
    isOpen: boolean;
};

const SymbolSelector = ({ insertChar, isOpen }: SymbolSelectorProps) => {
    const { t } = useTranslation();
    const symbolKeys = Object.keys(CHARACTERS);
    const onClick = (char: string) => {
        insertChar(char);
    };
    return (
        <SymbolSelectorOverlay isOpen={isOpen}>
            {t("common:general.symbol")}
            <Flexbox flexDirection="row" flexWrap="wrap">
                {symbolKeys.map((symbolKey: string) => {
                    return (
                        <Button
                            cssWidth="36px"
                            cssHeight="34px"
                            key={"symbol-" + nanoid(7)}
                            marginBottom="5px"
                            marginRight="5px"
                            noMinWidth={true}
                            onClick={onClick.bind(null, symbolKey)}
                            text={CHARACTERS[symbolKey]}
                            trackingComponentLabel="Select Symbol"
                            type={BUTTON_TYPE_SECONDARY}
                        />
                    );
                })}
            </Flexbox>
            <Link
                trackingComponentLabel="Close Special Character Input"
                onClick={onClick.bind(null, "")}
            >
                {t("common:general.close")}
            </Link>
        </SymbolSelectorOverlay>
    );
};

type SpecialCharsProps = {
    insertCharacter: (char: string) => void;
};

const SpecialChars = ({ insertCharacter }: SpecialCharsProps) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const onClick = () => {
        setIsOpen(true);
    };
    const closeOverlay = () => {
        setIsOpen(false);
    };
    const insertChar = (char: string) => {
        if (char) {
            insertCharacter(CHARACTERS[char]);
        }
        closeOverlay();
    };
    return (
        <SpecialCharsContainer>
            <Tooltip>
                <div>
                    <Button
                        cssWidth="36px"
                        cssHeight="38px"
                        marginLeft="2px"
                        noMinWidth={true}
                        text="✱"
                        type={BUTTON_TYPE_TERTIARY}
                        onClick={onClick}
                        trackingComponentLabel={SpecialChars.displayName}
                    />
                </div>
                <TooltipWrapper>
                    {t("common:general.insert_symbol")}
                </TooltipWrapper>
            </Tooltip>
            <SymbolSelector insertChar={insertChar} isOpen={isOpen} />
        </SpecialCharsContainer>
    );
};

SpecialChars.displayName = "SpecialChars";

export default SpecialChars;
