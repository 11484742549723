// Only basic typing done - this component was modified from https://github.com/jakezatecky/react-checkbox-tree
import * as React from "react";
import isEqual from "lodash.isequal";
import isNil from "lodash.isnil";
import { nanoid } from "nanoid";
import styled from "styled-components/macro";
import { isEmptyString } from "common/util/lang";
import CheckboxTreeNode from "common/components/CheckboxTreeNode";

const CheckboxTreeContainer = styled.div`
    ol {
        margin: 0;
        padding-left: 0;
        list-style-type: none;
        ol {
            padding-left: 28px;
        }
    }

    /* don't show hidden input field */
    input {
        display: none;
    }
`;

CheckboxTreeContainer.displayName = "CheckboxTreeContainer";

// Yes nodes should be typed, but COULD NOT get Flow to cooperate
type CheckboxTreeProps = {
    checked: string[];
    disabled: boolean;
    expandDisabled: boolean;
    expanded: string[];
    name: string;
    noCascade: boolean;
    nodes: ChkTree[];
    onCheck: (checked: string[]) => void;
    onExpand: (expanded: string[]) => void;
    optimisticToggle: boolean;
    suppressCheckboxForParents?: boolean;
    suppressIndentation?: boolean;
};

class CheckboxTree extends React.Component<CheckboxTreeProps> {
    static defaultProps = {
        checked: [],
        disabled: false,
        expandDisabled: false,
        expanded: [],
        name: "",
        noCascade: false,
        optimisticToggle: true,
        onCheck: () => undefined,
        onExpand: () => undefined
    };

    id: string;
    nodes: any;

    constructor(props: CheckboxTreeProps) {
        super(props);

        this.id = `rct-${nanoid(7)}`;
        this.nodes = {};

        this.flattenNodes(props.nodes);
        this.unserializeLists({
            checked: props.checked,
            expanded: props.expanded
        });
    }

    shouldComponentUpdate = (nextProps: CheckboxTreeProps): boolean => {
        const { nodes, checked, expanded } = nextProps;
        if (
            !isEqual(nodes, this.props.nodes) ||
            !isEqual(checked, this.props.checked) ||
            !isEqual(expanded, this.props.expanded)
        ) {
            this.flattenNodes(nodes);
            this.unserializeLists({ checked, expanded });
            return true;
        }
        return false;
    };

    onCheck = (node: any) => {
        const { noCascade, onCheck } = this.props;

        this.toggleChecked(node, node.checked, noCascade);
        onCheck(this.serializeList("checked"));
    };

    onExpand = (node: any) => {
        const { onExpand } = this.props;

        this.toggleNode("expanded", node, node.expanded);
        onExpand(this.serializeList("expanded"));
    };

    getFormattedNodes = (nodes: any[]): any[] => {
        return nodes.map((node: any): any => {
            const formatted = { ...node };

            const nd = this.nodes[node.value];
            if (nd) {
                formatted.checked = nd.checked;
                formatted.expanded = nd.expanded;
            }

            if (Array.isArray(node.children) && node.children.length > 0) {
                formatted.children = this.getFormattedNodes(formatted.children);
            } else {
                formatted.children = null;
            }

            return formatted;
        });
    };

    getCheckState = (node: any, noCascade: boolean): number => {
        if (node.children === null || noCascade) {
            return node.checked ? 1 : 0;
        }

        if (this.isEveryChildChecked(node)) {
            return 1;
        }

        if (this.isSomeChildChecked(node)) {
            return 2;
        }

        return 0;
    };

    getDisabledState = (
        node: any,
        parent: any,
        disabledProp: boolean,
        noCascade: boolean
    ): boolean => {
        if (disabledProp) {
            return true;
        }

        if (!noCascade && parent.disabled) {
            return true;
        }

        return Boolean(node.disabled);
    };

    toggleChecked = (node: any, isChecked: boolean, noCascade = false) => {
        if (isNil(node.children) || noCascade) {
            // Set the check status of a leaf node or an uncoupled parent
            this.toggleNode("checked", node, isChecked);
        } else {
            // Percolate check status down to all children
            node.children.forEach((child: any) => {
                this.toggleChecked(child, isChecked);
            });
        }
    };

    toggleNode = (key: string, node: any, toggleValue: boolean) => {
        this.nodes[node.value][key] = toggleValue;
    };

    flattenNodes = (nodes: ChkTree[]) => {
        if (!Array.isArray(nodes) || nodes.length === 0) {
            return;
        }

        nodes.forEach(node => {
            this.nodes[node.value] = {};
            this.flattenNodes(node.children);
        });
    };

    unserializeLists = (lists: any) => {
        // Reset values to false
        Object.keys(this.nodes).forEach(value => {
            Object.keys(lists).forEach(listKey => {
                if (this.nodes[value]) {
                    this.nodes[value][listKey] = false;
                }
            });
        });

        // Unserialize values and set their nodes to true
        Object.keys(lists).forEach(listKey => {
            lists[listKey].forEach((value: any) => {
                if (this.nodes[value]) {
                    this.nodes[value][listKey] = true;
                }
            });
        });
    };

    serializeList = (key: string): string[] => {
        const list: string[] = [];

        Object.keys(this.nodes).forEach(value => {
            if (this.nodes[value][key]) {
                list.push(value);
            }
        });

        return list;
    };

    isEveryChildChecked = (node: any): boolean => {
        return node.children.every((child: any) => {
            if (child.children !== null) {
                return this.isEveryChildChecked(child);
            }

            return child.checked;
        });
    };

    isSomeChildChecked = (node: any): boolean => {
        return node.children.some((child: any) => {
            if (child.children !== null) {
                return this.isSomeChildChecked(child);
            }

            return child.checked;
        });
    };

    renderTreeNodes = (nodes: any[], parent: any = {}) => {
        const {
            disabled,
            expandDisabled,
            noCascade,
            optimisticToggle,
            suppressCheckboxForParents,
            suppressIndentation
        } = this.props;
        const treeNodes = nodes.map(node => {
            const key = `${node.value}`;
            const checked = this.getCheckState(node, noCascade);
            const children = this.renderChildNodes(node);
            const nodeDisabled = this.getDisabledState(
                node,
                parent,
                disabled,
                noCascade
            );

            return (
                <CheckboxTreeNode
                    checked={checked}
                    disabled={nodeDisabled}
                    expandDisabled={expandDisabled}
                    expanded={node.expanded}
                    icon={node.icon}
                    key={key}
                    label={node.label}
                    onCheck={this.onCheck}
                    onExpand={this.onExpand}
                    optimisticToggle={optimisticToggle}
                    rawChildren={node.children}
                    suppressCheckboxForParents={suppressCheckboxForParents}
                    suppressIndentation={suppressIndentation}
                    treeId={this.id}
                    value={node.value}
                >
                    {children}
                </CheckboxTreeNode>
            );
        });

        return <ol>{treeNodes}</ol>;
    };

    renderChildNodes = (node: any) => {
        if (node.children !== null && node.expanded) {
            return this.renderTreeNodes(node.children, node);
        }

        return null;
    };

    renderHiddenInput = () => {
        const { name } = this.props;
        if (isNil(name) || isEmptyString(name)) {
            return null;
        }

        return this.renderJoinedHiddenInput();
    };

    renderJoinedHiddenInput = () => {
        const checked = this.props.checked.join(",");

        return <input name={this.props.name} type="hidden" value={checked} />;
    };

    render() {
        const nodes = this.getFormattedNodes(this.props.nodes);
        const treeNodes = this.renderTreeNodes(nodes);

        return (
            <CheckboxTreeContainer>
                {this.renderHiddenInput()}
                {treeNodes}
            </CheckboxTreeContainer>
        );
    }
}

export default CheckboxTree;
