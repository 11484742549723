import * as React from "react";
import { reduceObject } from "common/util/object";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import missingImageSquare from "common/images/missing_image_square.png";
import { marginProps, marginPropTypes } from "common/components/styled/util";

export const REPORT_SIZE = 140;
export const THUMBNAIL_LARGE = 100; // 100 x 100
export const THUMBNAIL_MEDIUM = 70; // 70 x 70
export const THUMBNAIL_SMALL = 44; // 44 x 44

type OfferImageWrapperProps = {
    size?: string;
};
const OfferImageWrapper = styled.div<OfferImageWrapperProps>`
    align-self: center;
    display: flex;
    height: ${props => props.size}px;
    margin-right: 16px;
    min-height: ${props => props.size}px;
    min-width: ${props => props.size}px;
    overflow: hidden;
    width: ${props => props.size}px;
    ${marginProps}
`;

OfferImageWrapper.displayName = "OfferImageWrapper";

const OfferImg = styled.img`
    margin: auto;
    vertical-align: middle;
    width: 100%;
`;

OfferImg.displayName = "OfferImg";

type OfferImageProps = {
    size?: number;
    src?: string;
} & marginPropsType;

const OfferImage = ({ src, size, ...rest }: OfferImageProps) => {
    const { t } = useTranslation();
    return (
        <OfferImageWrapper
            size={size}
            {...reduceObject(rest, ...marginPropTypes)}
        >
            <OfferImg
                src={src || missingImageSquare}
                alt={
                    src
                        ? t("general.product_image")
                        : t("common:general.missing_image")
                }
            />
        </OfferImageWrapper>
    );
};

OfferImage.defaultProps = {
    size: THUMBNAIL_LARGE
};

OfferImage.displayName = "OfferImage";

export default OfferImage;
