import {
    SHOW_TOAST,
    HIDE_TOAST,
    REMOVE_ALL_TOASTS
} from "common/shell/state/toastActions";
import type { Toast, ToastActions } from "common/shell/state/toastActions";
import noAction from "common/util/noAction";

export type ToastState = {
    uid: string;
} & Toast;

export const defaultState: ToastState[] = [];

const toasts = (
    state: ToastState[] = defaultState,
    action: ToastActions = noAction
): ToastState[] => {
    switch (action.type) {
        case SHOW_TOAST: {
            const { type, ...rest } = action;
            return [...state, { ...rest, uid: action.uid }];
        }
        case HIDE_TOAST:
            return state.filter(toast => {
                return toast.uid !== action.uid;
            });
        case REMOVE_ALL_TOASTS:
            return defaultState;
        default:
            return state;
    }
};

export default toasts;
