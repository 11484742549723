import isNil from "lodash.isnil";
import { isEmptyString } from "common/util/lang";
export const DONT_RUN = "DONT_RUN";
export const RUN = "RUN";

// Shared consts between offer structure and offer modal
export const DISPLAY_NAME_WIDTH = "362px"; // Dropdown width - 20px (padding) - 2px(border)
export const DROPDOWN_WIDTH = "384px";
export const QUANTITY_DROPDOWN_WIDTH = "250px"; // Quantity input and dropdown should add up to display name width
export const QUANTITY_INPUT_WIDTH = "89px";

export type OfferStructure = any;

export type SelectedOffer = {
    index: number;
    offerType: string;
};

export type Offer = {
    isOffer: boolean;
    name: string;
    offerIdentifier: string;
    offerLabel: string;
    offerValues: string;
};

export const defaultOfferInfo: Offer = {
    isOffer: false,
    name: "",
    offerIdentifier: "",
    offerLabel: "",
    offerValues: ""
};

export const parseOfferValues = (offerValues: string): string[] => {
    return !isNil(offerValues) && !isEmptyString(offerValues)
        ? offerValues.split("|").filter(Boolean)
        : [];
};

export const splitOfferValuesPair = (offerValue: string): string[] => {
    return !isNil(offerValue) && !isEmptyString(offerValue)
        ? offerValue.split(":")
        : [];
};
