import * as React from "react";
import type {
    IServerSideGetRowsParams,
    ValueGetterParams
} from "ag-grid-community";
import get from "lodash.get";
import isNil from "lodash.isnil";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import Button, {
    BUTTON_TYPE_PRIMARY,
    BUTTON_TYPE_SECONDARY
} from "common/components/Button";
import {
    ROW_SELECTION_MULTIPLE,
    ROW_SELECTION_SINGLE
} from "common/components/grid/AgDataGrid";
import type { DataGridType } from "common/components/grid/AgDataGrid";
import AgDataGridSelector from "common/components/grid/AgDataGridSelector";
import {
    CheckmarkCellRenderer,
    TruncatedCellRenderer
} from "common/components/grid/AgGridCellRenderers";
import Drawer, { DrawerFooter, DrawerOverlay } from "common/components/Drawer";
import NumberInput, {
    currencyFormatterBound,
    currencyParserBound
} from "common/components/NumberInput";
import { RadioGroup, Radio } from "common/components/RadioGroup";
import { INPUT_WIDTH } from "common/components/styled/DialogPage";
import Flexbox from "common/components/styled/Flexbox";
import { FormRow, FormSection } from "common/components/styled/Form";
import Input from "common/components/styled/Input";
import { FieldLabel } from "common/components/styled/Label";
import theme from "common/components/theme";
import TreeView from "common/components/TreeView";
import { NUMBER_INPUT_STEP } from "common/components/WidgetModal";
import {
    COLUMN_ALIGN_RIGHT,
    COLUMN_CUSTOM_RENDERER_ALIGN_CENTER,
    COLUMN_DEFAULT_ROW_HEIGHT,
    getRowsHandler,
    HEADER_ALIGN_CENTER
} from "common/components/grid/AgDataGridUtil";
import {
    fetchCampaignProductGroup,
    fetchCampaignProductGroups,
    fetchProductGroups,
    updateCampaignProductGroupsSort,
    updateProductGroupsSort,
    PPG_NAME,
    PPG_OFFER_DISPLAY_NAME,
    PPG_PRICE_TO_CONSUMER,
    PPG_USED_IN_CAMPAIGN
} from "concept/state/conceptActions";
import Header from "common/components/styled/Header";
import { emptyFn } from "common/util/lang";
import { composeTrackingComponentLabel } from "common/util/tracking";
import ProductGroupFields from "products/components/ProductGroupFields";
import type { AppDispatch, RootState } from "store";

const SORT_KEY_MAP = {};

// Consts for list selections
export const SELECT_PPG = "SELECT_PPG" as string;
export const USE_PPG_FROM_CAMPAIGN = "USE_PPG_FROM_CAMPAIGN" as string;
const THIRD_PARTY = "THIRD_PARTY" as string;
export type SelectMode =
    | typeof SELECT_PPG
    | typeof USE_PPG_FROM_CAMPAIGN
    | typeof THIRD_PARTY;

type PPGSelectorDrawerOwnProps = {
    closeHandler: NoArgsHandler;
    currencySymbol: string;
    editMode: boolean;
    includeThirdParty: boolean;
    isControl: boolean;
    isOpen: boolean;
    onSubmit: (
        selectedPPG: CampaignProductGroup | null,
        pggSelectMode: SelectMode,
        ppgsSelection: AdminProductGroup[],
        ppgSelection: CampaignProductGroup | null,
        ppgSelectionCampaign: CampaignProductGroup | null,
        ppgSelectionThirdParty: CampaignProductGroup | null
    ) => void;
    selectedCampaignPPG: CampaignProductGroup | null;
    selectedPPG: CampaignProductGroup | null;
    selectedPPGs: AdminProductGroup[];
    selectedThirdParty: CampaignProductGroup | null;
    selectMode: SelectMode;
    trackingComponentLabel: string;
};

type PPGSelectorDrawerProps = PPGSelectorDrawerOwnProps &
    PropsFromRedux &
    WithTranslation;

type PPGSelectorDrawerState = {
    previousPropsSelectedPPG: CampaignProductGroup | null;
    previousPropsSelectedPPGs: AdminProductGroup[];
    previousPropsSelectedThirdParty: CampaignProductGroup | null;
    previousPropsSelectMode: SelectMode;
    productGroupTreeNodes: any[];
    selectedCampaignPPG: CampaignProductGroup | null;
    selectedPPG: CampaignProductGroup | null;
    selectedPPGs: AdminProductGroup[];
    selectedThirdParty: CampaignProductGroup | null;
    selectMode: SelectMode;
};

const defaultState: PPGSelectorDrawerState = {
    productGroupTreeNodes: [],
    previousPropsSelectedPPG: null,
    previousPropsSelectedPPGs: [],
    previousPropsSelectedThirdParty: null,
    previousPropsSelectMode: SELECT_PPG,
    selectedCampaignPPG: null,
    selectedPPG: null,
    selectedPPGs: [],
    selectedThirdParty: null,
    selectMode: SELECT_PPG
};

class PPGSelectorDrawer extends React.Component<
    PPGSelectorDrawerProps,
    PPGSelectorDrawerState
> {
    static displayName = "PPGSelectorDrawer";
    static defaultProps = {
        editMode: false,
        includeThirdParty: false,
        isControl: false,
        isOpen: false,
        onSubmit: emptyFn
    };

    state = defaultState;

    /*    
    static getDerivedStateFromProps: GetDerivedStateFromProps<
        PPGSelectorDrawerProps,
        PPGSelectorDrawerState
    > = (props, state) => {
        // Any time the chartDesign prop changes reset any parts of state that is tied to that prop
        if (!isEqual(props.selectedPPG, state.previousPropsSelectedPPG)) {
            return {
                previousPropsSelectedPPG: props.selectedPPG,
                selectedPPG: props.selectedPPG
            };
        }
        if (!isEqual(props.selectedPPGs, state.previousPropsSelectedPPGs)) {
            return {
                previousPropsSelectedPPGs: props.selectedPPGs,
                selectedPPGs: props.selectedPPGs
            };
        }
        if (
            !isEqual(
                props.selectedThirdParty,
                state.previousPropsSelectedThirdParty
            )
        ) {
            return {
                previousPropsSelectedThirdParty: props.selectedThirdParty,
                selectedThirdParty: props.selectedThirdParty
            };
        }
        if (!isEqual(props.selectMode, state.previousPropsSelectMode)) {
            return {
                previousPropsSelectMode: props.selectMode,
                selectMode: props.selectMode
            };
        }
        return null;
    };
*/

    componentDidUpdate(prevProps: PPGSelectorDrawerProps): void {
        const {
            editMode,
            isOpen,
            selectedCampaignPPG,
            selectedPPG,
            selectedPPGs,
            selectedThirdParty,
            selectMode
        } = this.props;
        if (!prevProps.isOpen && isOpen) {
            // usually you don't set state from props but due to how this selector is used this is the easiest way
            this.setState(
                {
                    productGroupTreeNodes: [],
                    selectedCampaignPPG,
                    selectedPPG,
                    selectedPPGs,
                    selectedThirdParty,
                    selectMode
                },
                () => {
                    if (editMode) {
                        if (selectMode === SELECT_PPG) {
                            // get campaign product group
                            this.onPPGSelect(selectedPPGs, selectedPPG);
                        }
                    }
                }
            );
        }
    }

    onSelectModeChange = (value: SelectMode): void => {
        this.setState({
            selectMode: value
        });
    };

    productGroupFieldsValid = (
        campaignProductGroup: CampaignProductGroup | null
    ) => {
        if (campaignProductGroup) {
            return Boolean(
                campaignProductGroup.name &&
                    campaignProductGroup.offerDisplayName &&
                    campaignProductGroup.contributionPerUnit >= 0 &&
                    campaignProductGroup.manufacturerMargin >= 0 &&
                    campaignProductGroup.priceToConsumer >= 0 &&
                    campaignProductGroup.priceToRetailer >= 0 &&
                    campaignProductGroup.priceToWholesaler >= 0
            );
        } else {
            return false;
        }
    };

    thirdPartyFieldsValid = () => {
        const { selectedThirdParty } = this.state;
        if (selectedThirdParty) {
            return Boolean(
                selectedThirdParty.name &&
                    !isNil(selectedThirdParty.contributionPerUnit) &&
                    selectedThirdParty.contributionPerUnit >= 0 &&
                    !isNil(selectedThirdParty.priceToConsumer) &&
                    selectedThirdParty.priceToConsumer >= 0
            );
        } else {
            return false;
        }
    };

    isDrawerSelectionValid = (): boolean => {
        // Creating new list - more than 1 item selected and name set
        // Using existing list
        // Selecting all items
        const {
            selectedCampaignPPG,
            selectedPPG,
            selectedPPGs,
            selectedThirdParty,
            selectMode
        } = this.state;
        if (selectMode === USE_PPG_FROM_CAMPAIGN) {
            return !isNil(selectedCampaignPPG);
        } else {
            if (selectMode === THIRD_PARTY) {
                return (
                    !isNil(selectedThirdParty) && this.thirdPartyFieldsValid()
                );
            } else {
                return (
                    selectedPPGs &&
                    selectedPPGs.length > 0 &&
                    this.productGroupFieldsValid(selectedPPG)
                );
            }
        }
    };

    onDrawerSubmit = (): void => {
        const { closeHandler, onSubmit } = this.props;
        const {
            selectedCampaignPPG,
            selectedPPG,
            selectedPPGs,
            selectedThirdParty,
            selectMode
        } = this.state;
        // campaignProductGroup, selectMode always gets sent back
        // selectedPPG, selectedPPGs, selectedThirdParty needs to be set correctly
        let campaignProductGroup = null;
        switch (selectMode) {
            case SELECT_PPG:
                campaignProductGroup = selectedPPG;
                break;
            case THIRD_PARTY:
                campaignProductGroup = Object.assign(
                    {},
                    {
                        isCrossMerch: true,
                        objectType: "model/CampaignProductGroupModel"
                    },
                    selectedThirdParty
                );
                break;
            case USE_PPG_FROM_CAMPAIGN:
                campaignProductGroup = selectedCampaignPPG;
                break;
            default:
                break;
        }
        onSubmit(
            campaignProductGroup,
            selectMode,
            selectMode === SELECT_PPG ? selectedPPGs : [],
            selectMode === SELECT_PPG ? selectedPPG : null,
            selectMode === USE_PPG_FROM_CAMPAIGN ? selectedCampaignPPG : null,
            selectMode === THIRD_PARTY
                ? Object.assign({}, selectedThirdParty, {
                      isCrossMerch: true,
                      objectType: "model/CampaignProductGroupModel"
                  })
                : null
        );
        closeHandler();
    };

    onDrawerCancel = (): void => {
        const { closeHandler } = this.props;
        this.setState(
            {
                selectedCampaignPPG: null,
                selectedPPG: null,
                selectedPPGs: [],
                selectedThirdParty: null,
                selectMode: SELECT_PPG
            },
            () => {
                closeHandler();
            }
        );
    };

    getCommonColumnDefinitions = (): ColumnDefinition[] => {
        const { t } = this.props;
        return [
            {
                cellClass: [COLUMN_DEFAULT_ROW_HEIGHT],
                cellRenderer: TruncatedCellRenderer,
                cellRendererParams: {
                    textField: PPG_NAME
                },
                colId: PPG_NAME,
                field: PPG_NAME,
                flex: 2,
                headerName: t("common:general.name"),
                minWidth: 160
            },
            {
                cellClass: [COLUMN_DEFAULT_ROW_HEIGHT],
                cellRenderer: TruncatedCellRenderer,
                cellRendererParams: {
                    textField: PPG_OFFER_DISPLAY_NAME
                },
                colId: PPG_OFFER_DISPLAY_NAME,
                field: PPG_OFFER_DISPLAY_NAME,
                flex: 2,
                headerName: t("concept.offer_display_name"),
                minWidth: 160,
                sortable: false
            }
        ];
    };

    getCampaignPPGColumnDefinitions = (): ColumnDefinition[] => {
        const { t } = this.props;

        const commonColumnDefinitions = this.getCommonColumnDefinitions();
        return commonColumnDefinitions.concat([
            {
                cellClass: [COLUMN_DEFAULT_ROW_HEIGHT, COLUMN_ALIGN_RIGHT],
                colId: PPG_PRICE_TO_CONSUMER,
                field: PPG_PRICE_TO_CONSUMER,
                headerName: t("promotion.average_price_to_consumer"),
                valueGetter: (params: ValueGetterParams): string => {
                    const currencySymbol = get(params, "data.currencySymbol");
                    const value = get(params, ["data", PPG_PRICE_TO_CONSUMER]);
                    return currencySymbol + value;
                },
                width: 160
            }
        ]);
    };

    getPPGColumnDefinitions = (): ColumnDefinition[] => {
        const { t } = this.props;

        const commonColumnDefinitions = this.getCommonColumnDefinitions();
        return commonColumnDefinitions.concat([
            {
                cellClass: [COLUMN_CUSTOM_RENDERER_ALIGN_CENTER],
                cellRenderer: CheckmarkCellRenderer,
                cellRendererParams: {
                    renderField: PPG_USED_IN_CAMPAIGN
                },
                colId: PPG_USED_IN_CAMPAIGN,
                headerName: t("concept.used_in_campaign"),
                headerClass: HEADER_ALIGN_CENTER,
                sortable: true,
                width: 80
            }
        ]);
    };

    onCampaignPPGSelect = (selected: any[]): void => {
        const campaignProductGroup = selected[0];
        if (campaignProductGroup) {
            this.setState({
                selectedCampaignPPG: campaignProductGroup
            });
        }
    };

    onPPGSelect = (
        selected: AdminProductGroup[],
        selectedPPG?: CampaignProductGroup | null
    ): void => {
        const { fetchCampaignProductGroup } = this.props;
        if (selected.length > 0) {
            fetchCampaignProductGroup(selected).then(({ success, result }) => {
                if (success) {
                    this.setState({
                        productGroupTreeNodes: result.productGroupTreeNodes,
                        selectedPPG: selectedPPG
                            ? selectedPPG
                            : result.campaignProductGroup,
                        selectedPPGs: selected
                    });
                }
            });
        } else {
            this.setState({
                productGroupTreeNodes: [],
                selectedPPG: null,
                selectedPPGs: []
            });
        }
    };

    getCampaignPPGRowsSuccess = (
        result: any,
        params: IServerSideGetRowsParams,
        dataGrid: DataGridType
    ) => {
        const { selectedCampaignPPG } = this.state;
        const { filteredCount, items } = result;
        let selectedItems: any[] = [];
        if (selectedCampaignPPG) {
            selectedItems = [selectedCampaignPPG];
        }
        params.success({
            rowCount: filteredCount,
            rowData: items
        });
        dataGrid?.reselectItems(selectedItems);
    };

    getCampaignPPGRows = (
        params: IServerSideGetRowsParams,
        dataGrid: DataGridType,
        search: string
    ) => {
        const {
            fetchCampaignProductGroups,
            updateCampaignProductGroupsSort
        } = this.props;
        const fetchData = () => {
            return fetchCampaignProductGroups(search);
        };
        getRowsHandler({
            dataGrid: dataGrid,
            fetchData: fetchData,
            getRowsSuccess: this.getCampaignPPGRowsSuccess,
            params: params,
            sortKeyMap: SORT_KEY_MAP,
            updateSort: updateCampaignProductGroupsSort
        });
    };

    getPPGRowsSuccess = (
        result: any,
        params: IServerSideGetRowsParams,
        dataGrid: DataGridType
    ) => {
        const { selectedPPGs } = this.state;
        const { filteredCount, items } = result;
        let selectedItems: AdminProductGroup[] = [];
        if (selectedPPGs && selectedPPGs.length > 0) {
            selectedItems = selectedPPGs;
        }
        params.success({
            rowCount: filteredCount,
            rowData: items
        });
        dataGrid?.reselectItems(selectedItems);
    };

    getPPGRows = (
        params: IServerSideGetRowsParams,
        dataGrid: DataGridType,
        search: string
    ) => {
        const { fetchProductGroups, updateProductGroupsSort } = this.props;
        getRowsHandler({
            dataGrid: dataGrid,
            fetchData: fetchProductGroups.bind(null, search),
            getRowsSuccess: this.getPPGRowsSuccess,
            params: params,
            sortKeyMap: SORT_KEY_MAP,
            updateSort: updateProductGroupsSort
        });
    };

    onProductGroupFieldsChange = (fields: any) => {
        const { selectedPPG } = this.state;
        this.setState({
            selectedPPG: Object.assign({}, selectedPPG, fields)
        });
    };

    renderSelectPPGContent = (): React.ReactNode => {
        const {
            editMode,
            isOpen,
            ppgTotalCount,
            t,
            trackingComponentLabel
        } = this.props;
        const { selectedPPG, productGroupTreeNodes } = this.state;
        const content = (
            <Flexbox cssHeight="100%" flexDirection="row" padding="16px">
                <Flexbox flexDirection="column" cssWidth="50%">
                    <Header fontSize="16px">
                        {t("concept.available_ppgs")}
                    </Header>
                    <AgDataGridSelector
                        addCheckboxColumn={false}
                        columnDefinitions={this.getPPGColumnDefinitions()}
                        dataIdKey="productGroupEntityId"
                        getRows={this.getPPGRows}
                        headerText={"common:general.x_item"}
                        id="SelectPPGSelector"
                        okToQuery={true}
                        onSelectedRowsChange={this.onPPGSelect}
                        resetGrid={isOpen && !editMode}
                        rowSelection={ROW_SELECTION_MULTIPLE}
                        selectAll={true}
                        showColumnChooser={false}
                        showShowSelected={false}
                        totalCount={ppgTotalCount}
                        trackingComponentLabel={composeTrackingComponentLabel([
                            trackingComponentLabel,
                            PPGSelectorDrawer.displayName
                        ])}
                        writeToPreferences={false}
                    />
                </Flexbox>
                <Flexbox
                    flexDirection="column"
                    cssWidth="50%"
                    marginLeft="16px"
                >
                    <Header fontSize="16px">{t("concept.selected_ppg")}</Header>
                    <ProductGroupFields
                        allowNameEdit={false}
                        campaignProductGroup={selectedPPG}
                        onChange={this.onProductGroupFieldsChange}
                        showFinancials={true}
                        showInstacartBasePrice={false}
                    />

                    <TreeView
                        hasSearch={false}
                        nodes={productGroupTreeNodes}
                        trackingComponentLabel={PPGSelectorDrawer.displayName}
                    />
                </Flexbox>
            </Flexbox>
        );
        return content;
    };

    onThirdPartyProductChange = (
        event: React.SyntheticEvent<HTMLInputElement>
    ) => {
        const { selectedThirdParty } = this.state;
        const value = event.currentTarget.value;
        this.setState({
            selectedThirdParty: Object.assign({}, selectedThirdParty, {
                name: value,
                offerDisplayName: value
            })
        });
    };

    onThirdPartyPtcChange = (value: number | null): void => {
        const { selectedThirdParty } = this.state;
        this.setState({
            selectedThirdParty: Object.assign({}, selectedThirdParty, {
                priceToConsumer: value
            })
        });
    };

    onThirdPartyCpuChange = (value: number | null): void => {
        const { selectedThirdParty } = this.state;
        this.setState({
            selectedThirdParty: Object.assign({}, selectedThirdParty, {
                contributionPerUnit: value
            })
        });
    };

    renderSelectThirdParty = (): React.ReactNode => {
        const { currencySymbol, t } = this.props;
        const { selectedThirdParty } = this.state;
        const content = (
            <Flexbox cssHeight="100%" flexDirection="row" padding="16px">
                <FormSection>
                    <FormRow>
                        <FieldLabel required={true}>
                            {t("common:general.product")}
                        </FieldLabel>
                        <Input
                            cssWidth={INPUT_WIDTH}
                            name="product"
                            onChange={this.onThirdPartyProductChange}
                            tabIndex={1}
                            type="text"
                            value={selectedThirdParty?.name}
                        />
                    </FormRow>
                    <FormRow>
                        <FieldLabel required={true}>
                            {t("promotion.price_to_consumer")}
                        </FieldLabel>
                        <NumberInput
                            boxSizing="border-box"
                            cssWidth="100px"
                            formatter={currencyFormatterBound.bind(
                                null,
                                currencySymbol
                            )}
                            min={0}
                            name="ptc"
                            onChange={this.onThirdPartyPtcChange}
                            parser={currencyParserBound.bind(
                                null,
                                currencySymbol
                            )}
                            step={NUMBER_INPUT_STEP}
                            value={selectedThirdParty?.priceToConsumer}
                        />
                    </FormRow>
                    <FormRow>
                        <FieldLabel required={true}>
                            {t("promotion.contribution_per_unit")}
                        </FieldLabel>
                        <NumberInput
                            boxSizing="border-box"
                            cssWidth="100px"
                            formatter={currencyFormatterBound.bind(
                                null,
                                currencySymbol
                            )}
                            min={0}
                            name="cpu"
                            onChange={this.onThirdPartyCpuChange}
                            parser={currencyParserBound.bind(
                                null,
                                currencySymbol
                            )}
                            step={NUMBER_INPUT_STEP}
                            value={selectedThirdParty?.contributionPerUnit}
                        />
                    </FormRow>
                </FormSection>
            </Flexbox>
        );
        return content;
    };

    render() {
        const {
            campaignPPGTotalCount,
            editMode,
            includeThirdParty,
            isOpen,
            t,
            trackingComponentLabel
        } = this.props;
        const { selectMode } = this.state;

        let SelectionContent = null;
        if (selectMode === USE_PPG_FROM_CAMPAIGN) {
            SelectionContent = (
                <Flexbox cssHeight="100%" flexDirection="column" padding="16px">
                    <AgDataGridSelector
                        addCheckboxColumn={true}
                        columnDefinitions={this.getCampaignPPGColumnDefinitions()}
                        dataIdKey="entityId"
                        getRows={this.getCampaignPPGRows}
                        headerText={"common:general.x_item"}
                        id="UsePPGSelector"
                        okToQuery={true}
                        onSelectedRowsChange={this.onCampaignPPGSelect}
                        resetGrid={isOpen && !editMode}
                        rowSelection={ROW_SELECTION_SINGLE}
                        showColumnChooser={false}
                        showShowSelected={false}
                        totalCount={campaignPPGTotalCount}
                        trackingComponentLabel={composeTrackingComponentLabel([
                            trackingComponentLabel,
                            PPGSelectorDrawer.displayName
                        ])}
                        writeToPreferences={false}
                    />
                </Flexbox>
            );
        } else {
            if (selectMode === SELECT_PPG) {
                SelectionContent = this.renderSelectPPGContent();
            } else {
                if (selectMode === THIRD_PARTY) {
                    SelectionContent = this.renderSelectThirdParty();
                }
            }
        }

        const drawerFooter = (
            <DrawerFooter>
                <Button
                    marginLeft="20px"
                    onClick={this.onDrawerCancel}
                    text={t("common:general.cancel")}
                    type={BUTTON_TYPE_SECONDARY}
                    trackingComponentLabel={[
                        PPGSelectorDrawer.displayName,
                        "Cancel"
                    ]}
                />
                <Button
                    disabled={!this.isDrawerSelectionValid()}
                    marginLeft="8px"
                    onClick={this.onDrawerSubmit}
                    text={t("common:general.select")}
                    type={BUTTON_TYPE_PRIMARY}
                    trackingComponentLabel={[
                        PPGSelectorDrawer.displayName,
                        "Select"
                    ]}
                />
            </DrawerFooter>
        );

        return (
            <DrawerOverlay isOpen={isOpen}>
                <Drawer
                    closeHandler={this.onDrawerCancel}
                    cssWidth={1240}
                    footer={drawerFooter}
                    isOpen={isOpen}
                    title={t("concept.select_ppg")}
                >
                    <Flexbox
                        borderBottom={`1px solid ${theme.border}`}
                        flexDirection="column"
                    >
                        <Flexbox flex={1} justifyContent="space-between">
                            <RadioGroup
                                changeHandler={this.onSelectModeChange}
                                flexDirection="row"
                                margin="8px 24px"
                                name={"SelectMode"}
                                selectedValue={selectMode}
                                trackingComponentLabel={composeTrackingComponentLabel(
                                    [
                                        trackingComponentLabel,
                                        PPGSelectorDrawer.displayName,
                                        "Select PPG Mode"
                                    ]
                                )}
                            >
                                <Radio
                                    label={t("general.select_ppg")}
                                    marginRight="16px"
                                    name="select_ppg"
                                    value={SELECT_PPG}
                                />
                                <Radio
                                    label={t("concept.use_ppgs_from_campaign")}
                                    marginRight="16px"
                                    name="use_ppg"
                                    value={USE_PPG_FROM_CAMPAIGN}
                                />{" "}
                                {includeThirdParty && (
                                    <Radio
                                        label={t(
                                            "concept.use_third_party_product"
                                        )}
                                        name="third_party"
                                        value={THIRD_PARTY}
                                    />
                                )}
                            </RadioGroup>
                        </Flexbox>
                    </Flexbox>
                    {SelectionContent}
                </Drawer>
            </DrawerOverlay>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        campaignProductGroup: get(state, "concept.campaignProductGroup"),
        campaignPPGTotalCount: get(
            state,
            "concept.campaignProductGroups.totalCount",
            0
        ),
        ppgTotalCount: get(state, "concept.productGroups.totalCount", 0)
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        fetchCampaignProductGroup: (ppgs: any[]) => {
            return dispatch(fetchCampaignProductGroup(ppgs));
        },
        fetchCampaignProductGroups: (search: string) => {
            return dispatch(fetchCampaignProductGroups(search));
        },
        fetchProductGroups: (search: string) => {
            return dispatch(fetchProductGroups(search));
        },
        updateCampaignProductGroupsSort: (
            sortBy: string,
            sortOrder: SortOrder
        ): void => {
            dispatch(updateCampaignProductGroupsSort(sortBy, sortOrder));
        },
        updateProductGroupsSort: (
            sortBy: string,
            sortOrder: SortOrder
        ): void => {
            dispatch(updateProductGroupsSort(sortBy, sortOrder));
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(PPGSelectorDrawer));
