import * as React from "react";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import AskSecurityQuestionDialog from "common/auth/components/AskSecurityQuestionDialog";
import ChangePasswordDialog from "common/auth/components/ChangePasswordDialog";
import ForgotPasswordDialog from "common/auth/components/ForgotPasswordDialog";
import LoginDialog from "common/auth/components/LoginDialog";
import PasswordEmailDialog from "common/auth/components/PasswordEmailDialog";
import ResetPasswordDialog from "common/auth/components/ResetPasswordDialog";
import SecurityQuestionDialog from "common/auth/components/SecurityQuestionDialog";
import TenantDialog from "common/auth/components/TenantDialog";
import {
    STATE_CHOOSE_TENANT,
    STATE_FORGOT_PASSWORD,
    STATE_LOGGED_OUT,
    STATE_PASSWORD_EMAIL_SENT,
    STATE_PASSWORD_EXPIRED,
    STATE_SECURITY_QUESTION,
    STATE_CHANGE_PASSWORD,
    STATE_RESET_PASSWORD,
    STATE_ASK_SECURITY_QUESTION
} from "common/auth/state/authReducers";
import type { RootState } from "store";

type LoginProps = PropsFromRedux;

const Login = ({ state }: LoginProps) => {
    switch (state) {
        case STATE_FORGOT_PASSWORD:
            return <ForgotPasswordDialog />;
        case STATE_LOGGED_OUT:
            return <LoginDialog />;
        case STATE_CHOOSE_TENANT:
            return <TenantDialog />;
        case STATE_PASSWORD_EMAIL_SENT:
            return <PasswordEmailDialog />;
        case STATE_CHANGE_PASSWORD:
            // @ts-expect-error withCookies causes typescript errors
            return <ChangePasswordDialog cancelable={true} />;
        case STATE_PASSWORD_EXPIRED:
            return <ChangePasswordDialog />;
        case STATE_SECURITY_QUESTION:
            return <SecurityQuestionDialog />;
        case STATE_ASK_SECURITY_QUESTION:
            return <AskSecurityQuestionDialog />;
        case STATE_RESET_PASSWORD:
            return <ResetPasswordDialog />;
        default:
            return null;
    }
};
Login.displayName = "Login";

const mapStateToProps = (state: RootState) => {
    return {
        state: state.auth.state
    };
};

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Login);
