import * as React from "react";
import { withCookies } from "react-cookie";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import AuthDialogPage, {
    AuthError,
    AuthInstructions
} from "common/auth/components/AuthDialogPage";
import { selectTenant } from "common/auth/state/authActions";
import { UntrackedCustomSelect } from "common/components/styled/Form";
import type { Cookies, HasCookies } from "common/shell/util/types";
import type { AppDispatch, RootState } from "store";

type TenantDialogProps = PropsFromRedux & HasCookies & WithTranslation;

class TenantDialog extends React.Component<TenantDialogProps> {
    static displayName = "TenantDialog";
    tenant: HTMLSelectElement | undefined | null;

    componentDidMount() {
        if (this.tenant) {
            this.tenant.focus();
        }
    }

    onChange = (target: OptionType) => {
        const tenantId = target.value;
        if (tenantId) {
            this.props.tenantAction(this.props.cookies, tenantId);
        }
    };

    setTenantRef = (input: HTMLSelectElement) => {
        this.tenant = input;
    };

    render() {
        const { error, t, tenants } = this.props;
        const options = tenants.map(tenant => {
            return {
                value: tenant.entityId,
                label: tenant.name
            };
        });
        return (
            <AuthDialogPage
                title={t("common:login.choose_tenant")}
                suppressLogo={true}
            >
                <AuthInstructions>
                    {t("common:login.please_specify_tenant")}
                </AuthInstructions>
                {/* Using UntrackedSelect because we don't care about Eversight users selecting tenant */}
                <UntrackedCustomSelect
                    isSearchable={true}
                    name="tenant"
                    options={options}
                    onChange={this.onChange}
                    placeholder={t("common:login.select_tenant")}
                    ref={this.setTenantRef}
                />
                <AuthError error={error} />
            </AuthDialogPage>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        error: state.auth.error,
        tenants: state.auth.tenants
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        tenantAction: (cookies: Cookies, tenantId: string) => {
            dispatch(selectTenant(cookies, tenantId));
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

// @ts-expect-error withCookies causes typescript errors
export default withTranslation()(withCookies(connector(TenantDialog)));
