export const ADMINISTER_OTHER_TENANTS = "ADMINISTER_OTHER_TENANTS";
export const ADMINISTER_TENANT = "ADMINISTER_TENANT";
export const CAMPAIGN_ADD_FROM_CONTROL_LIBRARY =
    "CAMPAIGN_ADD_FROM_CONTROL_LIBRARY";
export const CAMPAIGN_ANALYZE = "CAMPAIGN_ANALYZE";
export const CAMPAIGN_CREATE = "CAMPAIGN_CREATE";
export const CAMPAIGN_CREATE_EDIT_DELETE_PROMOTION =
    "CAMPAIGN_CREATE_EDIT_DELETE_PROMOTION";
export const CAMPAIGN_DELETE = "CAMPAIGN_DELETE";
export const CAMPAIGN_EDIT = "CAMPAIGN_EDIT";
export const CAMPAIGN_EDIT_ALLOW_INTERNAL_TESTING =
    "CAMPAIGN_EDIT_ALLOW_INTERNAL_TESTING";
export const CAMPAIGN_EDIT_AVAILABLE_AT = "CAMPAIGN_EDIT_AVAILABLE_AT";
export const CAMPAIGN_EDIT_BID = "CAMPAIGN_EDIT_BID";
export const CAMPAIGN_EDIT_BUDGET_OPTIMIZATION =
    "CAMPAIGN_EDIT_BUDGET_OPTIMIZATION";
export const CAMPAIGN_EDIT_CONTROL = "CAMPAIGN_EDIT_CONTROL";
export const CAMPAIGN_EDIT_DATES = "CAMPAIGN_EDIT_DATES";
export const CAMPAIGN_EDIT_IMAGE = "CAMPAIGN_EDIT_IMAGE";
export const CAMPAIGN_EDIT_PLATFORMS_COUPON = "CAMPAIGN_EDIT_PLATFORMS_COUPON";
export const CAMPAIGN_EDIT_RETAILER = "CAMPAIGN_EDIT_RETAILER";
export const CAMPAIGN_EDIT_TARGETING = "CAMPAIGN_EDIT_TARGETING";
export const CAMPAIGN_EDIT_UPCS = "CAMPAIGN_EDIT_UPCS";
export const CAMPAIGN_MOVE_TO_DRAFT = "CAMPAIGN_MOVE_TO_DRAFT";
export const CAMPAIGN_PAUSE = "CAMPAIGN_PAUSE";
export const CAMPAIGN_QUEUE = "CAMPAIGN_QUEUE";
export const CAMPAIGN_REFRESH = "CAMPAIGN_REFRESH";
export const CAMPAIGN_REMOVE_AVAILABLE_AT = "CAMPAIGN_REMOVE_AVAILABLE_AT";
export const CAMPAIGN_SHOW_ALLOW_INTERNAL_TESTING =
    "CAMPAIGN_SHOW_ALLOW_INTERNAL_TESTING";
export const CAMPAIGN_START = "CAMPAIGN_START";
export const CAMPAIGN_STOP = "CAMPAIGN_STOP";
export const CAMPAIGN_SUBMIT_FOR_REVIEW = "CAMPAIGN_SUBMIT_FOR_REVIEW";
export const CAMPAIGN_VIEW_BUDGET_OPTIMIZATION =
    "CAMPAIGN_VIEW_BUDGET_OPTIMIZATION";
export const CAMPAIGN_VIEW_INTEGRATION = "CAMPAIGN_VIEW_INTEGRATION";
export const CAMPAIGN_VIEW_MANUFACTURER_DATA =
    "CAMPAIGN_VIEW_MANUFACTURER_DATA";
export const CAMPAIGN_VIEW_RETAILER_DATA = "CAMPAIGN_VIEW_RETAILER_DATA";
export const CAMPAIGN_VIEW_TEST_INSIGHTS = "CAMPAIGN_VIEW_TEST_INSIGHTS";
export const CAMPAIGN_VIEW_TEST_RESULTS = "CAMPAIGN_VIEW_TEST_RESULTS";
export const CAMPAIGN_VIEW_WHOLESALER_DATA = "CAMPAIGN_VIEW_WHOLESALER_DATA";
export const CHIP_VIEW = "CHIP_VIEW";
export const GUIDELINE_ADMIN = "GUIDELINE_ADMIN";
export const HOLIDAY_ADMIN = "HOLIDAY_ADMIN";
export const PROMO_TYPE_DETECTION_ADMIN = "PROMO_TYPE_DETECTION_ADMIN";
export const PROMO_VIEW = "PROMO_VIEW";
export const PROMOTION_EDIT_IMAGE = "PROMOTION_EDIT_IMAGE";
export const PROMOTION_IMPORT = "PROMOTION_IMPORT";
export const PROMOTION_START = "PROMOTION_START";
export const PROMOTION_STOP = "PROMOTION_STOP";

export const isChipOnly = (allowableActions: Dictionary<boolean>) => {
    const chipEnabled = allowableActions[CHIP_VIEW];
    const promoEnabled = allowableActions[PROMO_VIEW];
    return chipEnabled && !promoEnabled;
};
