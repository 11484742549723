import type { AnyAction } from "redux";
import { makeRequestThunk } from "common/shell/state/requestActions";

const REQUEST_OFFER_PREVIEW = "REQUEST_OFFER_PREVIEW";
export const RECEIVE_OFFER_PREVIEW = "RECEIVE_OFFER_PREVIEW";
export const CLOSE_OFFER_PREVIEW = "CLOSE_OFFER_PREVIEW";

export const requestOfferPreview = (): AnyAction => ({
    type: REQUEST_OFFER_PREVIEW
});

export const receiveOfferPreview = (json: any): AnyAction => ({
    type: RECEIVE_OFFER_PREVIEW,
    offerPreview: json
});

export const closeOfferPreview = (): AnyAction => ({
    type: CLOSE_OFFER_PREVIEW
});

export function fetchOfferPreview(previewPromotionId: string) {
    return makeRequestThunk("api/entity/promotion/" + previewPromotionId, {
        okDispatchFunc: receiveOfferPreview,
        preRequestFunc: requestOfferPreview,
        queryParams: {
            modelAndFieldSet: "PromotionModel/offerProofFields"
        }
    });
}
