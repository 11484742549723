import get from "lodash.get";
import { nanoid } from "nanoid";
import { reduceObject } from "common/util/object";
import { DESCENDING } from "common/util/query";
import { EVENTS } from "filter/state/filterActions";
import { buildTestStatusFilter } from "filter/utils/showFilterUtils";
import type { ComplianceValue } from "filter/utils/showFilterUtils";
import { buildPrimaryMetricRangeFilter } from "common/metrics/primaryMetricUtils";
import type { RootState } from "store";

// Const for column sorting and data keys
const CHANNEL = "channel";
const DISCOUNT_RANGE = "discountRange";
const EFFECTIVE_DISCOUNT_RANGE = "effectiveDiscountRange";
const START_DATE = "startDate";
const END_DATE = "endDate";
export const BASE_UNIT_PRICE_TO_CONSUMER = "baseUnitPriceToConsumer";
export const BUNDLED_WITH = "bundledWith";
export const DATES_RUN = START_DATE + "," + END_DATE;
export const EVENT_WEEKS = "eventWeeks";
export const EVENT_WEEKS_WHEN_BUNDLING_ADS = "eventWeeksWhenBundlingAds";
export const GUIDELINE_COMPLIANCE = "guidelineCompliance";
export const HOLIDAY_SEASON = "holiday";
export const IN_MARKET_AD_LINK = "inMarketAdImageLink";
export const MANAGER = "manager";
export const OFFER_GUIDANCE = "offerGuidance";
export const OFFER_LABEL = "offerLabel";
export const PERFORMANCE_TIER_CATEGORY = "performanceTierCategory";
export const PRODUCT_CATEGORY = "productCategory";
export const PRODUCT_GROUP_GROUP_ADS = "productGroupGroupAds";
export const PRODUCT_GROUP = "productGroup";
export const PROMO_TYPE = "promoType";
export const REGION = "region";
export const RETAILER = "retailer";
export const TEST_STATUS = "testStatus";
export const WEEK = "week";

// Toggle consts for compliance filter
export const EVENT_COMPLIANCE = "EVENT_COMPLIANCE";
export const GUIDANCE_COMPLIANCE = "GUIDANCE_COMPLIANCE";

//modes for event view
export const EVENTS_VIEW_DASHBOARD_MODE = "EVENTS_VIEW_DASHBOARD_MODE";
export const EVENTS_VIEW_COMPLIANCE_MODE = "EVENTS_VIEW_COMPLIANCE_MODE";
export const EVENTS_VIEW_ROOT_MODE = "EVENTS_VIEW_ROOT_MODE";
export const EVENTS_VIEW_TEST_MODE = "EVENTS_VIEW_TEST_MODE";

type Event = {
    adEndDate: string;
    adLink: string;
    adStartDate: string;
    end: string;
    pageLink?: string | null;
    pageType?: string;
    start: string;
};

export type EventsViewMode =
    | typeof EVENTS_VIEW_DASHBOARD_MODE
    | typeof EVENTS_VIEW_COMPLIANCE_MODE
    | typeof EVENTS_VIEW_ROOT_MODE
    | typeof EVENTS_VIEW_TEST_MODE;

type ComplianceRating = {
    objectType: string;
    value: string;
};

const MAX_NUMBER_OF_AGGREGATED_ADS = 20;

export type EventComplianceGuidanceFilter = {
    compliance: ComplianceRating;
    guidance: string;
    objectType: string;
};

export type AggregatedAd = {
    isNumerator: boolean;
    productGroup: string;
    retailer: string;
} & Event;

export type GuidelineComplianceGuidance = {
    compliance: string;
    name: string;
};

type GuidelineCompliance = {
    compliantCount: number;
    guidances: GuidelineComplianceGuidance[];
    hasUnknown: boolean;
    nonCompliantCount: number;
    unknownCount: number;
};

export type EventsItem = {
    endDate: string;
    inMarketAdCompliance: ComplianceValue;
    inMarketAdImage?: string;
    inMarketAdLocation?: string;
    inMarketAdPageLink?: string;
    isTestedUnderperformer: boolean;
    manager: string;
    mpp: string;
    offerLabel: string;
    performanceTierCategory: string;
    product: string;
    promotionEventId: string;
    promotionId: string;
    reason: string | undefined | null;
    retailer: string;
    startDate: string;
    systemPriceCompliance: ComplianceValue;
    unitPriceToConsumer: string;
    userPriceCompliance: ComplianceValue;
};

export type EventsState = {
    additionalDefaultColumns: string[];
    aggregatedAds: AggregatedAd[] | undefined | null;
    aggregatedAdsTotal: number | undefined | null;
    groupAds: boolean;
    groupByFields: string;
    mode: EventsViewMode;
    items: EventsItem[];
    offset: number;
    pageSize: number;
    sortBy: string;
    sortOrder: SortOrder;
    totalCount: number;
};

export const defaultEventsState: EventsState = {
    additionalDefaultColumns: [],
    aggregatedAds: null,
    aggregatedAdsTotal: null,
    groupAds: true,
    groupByFields: "",
    mode: EVENTS_VIEW_ROOT_MODE,
    items: [],
    offset: 0,
    pageSize: 1000,
    sortBy: DATES_RUN,
    sortOrder: DESCENDING,
    totalCount: -1
};

export const getEventsDetailRequest = (state: RootState): any => {
    let additionalFields = {};
    const show = get(state, ["filters", EVENTS, "showCompliance"]);
    const showToggle = get(state, ["filters", EVENTS, "showToggle"]);
    if (showToggle === EVENT_COMPLIANCE) {
        additionalFields = {
            complianceFilter: show,
            guidanceComplianceFilter: []
        };
    } else if (showToggle === GUIDANCE_COMPLIANCE) {
        additionalFields = {
            complianceFilter: [],
            guidanceComplianceFilter: buildGuidanceComplianceFilterObjects(show)
        };
    } else {
        additionalFields = {
            complianceFilter: [],
            guidanceComplianceFilter: []
        };
    }
    const performanceTiers = get(
        state,
        ["filters", EVENTS, "performanceTiers"],
        []
    );

    const performanceTierCategories = performanceTiers.map(
        (performanceTier: any) => {
            return {
                objectType: "PerformanceTierCategory",
                value: performanceTier
            };
        }
    );
    return {
        ...additionalFields,
        endDate: get(state, ["filters", EVENTS, "date", "endDate"]),
        groupBundledAds: get(state, "events.groupAds"),
        groupByFields: get(state, "events.groupByFields"),
        holidayIds: get(state, ["filters", EVENTS, "holidays"]),
        managerIds: get(state, ["filters", EVENTS, "managers"]),
        objectType: "EventsRequest",
        offset: get(state, "events.offset"),
        pageSize: get(state, "events.pageSize"),
        performanceTierCategories: performanceTierCategories,
        primaryMetricFilter: buildPrimaryMetricRangeFilter(
            get(state, ["filters", EVENTS, "min"]),
            get(state, ["filters", EVENTS, "max"]),
            get(state, "init.primaryMetric")
        ),
        productGroupIds: get(state, ["filters", EVENTS, "products"]),
        promoTypes: get(
            state,
            ["filters", EVENTS, "promoTypes"],
            []
        ).map((value: any) => ({ objectType: "PromoType", value })),
        regionIds: get(state, ["filters", EVENTS, "regions"]),
        retailerIds: get(state, ["filters", EVENTS, "retailers"]),
        search: get(state, ["filters", EVENTS, "search"]),
        sortBy: get(state, "events.sortBy"),
        sortOrder: get(state, "events.sortOrder"),
        startDate: get(state, ["filters", EVENTS, "date", "startDate"]),
        testStatusFilter: buildTestStatusFilter(
            get(state, ["filters", EVENTS, "showTested"], [])
        ),
        timePeriod: {
            objectType: "MatrixTimePeriod",
            value: get(state, ["filters", EVENTS, "date", "time"])
        }
    };
};

export const getAggregatedEventAdsRequest = (
    state: RootState,
    row: any
): any => {
    //let's make the same request we'd make for the events on the list
    const request = getEventsDetailRequest(state);
    const retailersFilter = request.retailerIds;
    const productsFilter = request.productGroupIds;
    let aggregatingOnRetailer = false;
    let aggregatingOnProductGroup = false;
    //we want the non-aggregated version of the events
    request.groupByFields = [];
    //We want to filter more specifically on what we've aggregated by.
    const groupByFields = get(state, "events.groupByFields", "").split(",");
    groupByFields.forEach(field => {
        switch (field) {
            case CHANNEL: {
                //if we've already setup the filter for retailer since we've aggregated on retailer, don't override it.
                if (aggregatingOnRetailer) {
                    break;
                }
                //We don't filter on channels, so we need to get all the children of the channel and filter by them.
                const channels = get(
                    state,
                    "init.preloadedEntities.filters.retailers",
                    []
                ).filter(c => c.entityIdWithoutVersion === row.channelId);
                if (channels.length) {
                    request.retailerIds = channels[0].retailers
                        .filter(
                            retailer =>
                                !retailersFilter.length ||
                                retailersFilter.indexOf(
                                    retailer.entityIdWithoutVersion
                                ) > -1
                        )
                        .map(retailer => retailer.entityIdWithoutVersion);
                }
                break;
            }
            case DISCOUNT_RANGE:
            case EFFECTIVE_DISCOUNT_RANGE:
                request.discountRange = row.discountRange;
                break;
            case OFFER_LABEL:
                request.includedOfferIdentifiers = [row.offerIdentifier];
                break;
            case PRODUCT_CATEGORY: {
                //if we've already setup the filter for productGroup since we've aggregated on productGroup, don't override it.
                if (aggregatingOnProductGroup) {
                    break;
                }
                //Like channel, we don't filter on category, but on group.
                const categories = get(
                    state,
                    "init.preloadedEntities.filters.products",
                    []
                ).filter(c => c.value === row.productCategory);
                if (categories.length) {
                    request.productGroupIds = categories[0].adminProductGroups
                        .filter(
                            productGroup =>
                                !productsFilter.length ||
                                productsFilter.indexOf(
                                    productGroup.entityIdWithoutVersion
                                ) > -1
                        )
                        .map(
                            productGroup => productGroup.entityIdWithoutVersion
                        );
                }
                break;
            }
            case PROMO_TYPE:
                request.promoTypes = [
                    { objectType: "PromoType", value: row.promoType.value }
                ];
                break;
            case PRODUCT_GROUP:
                aggregatingOnProductGroup = true;
                request.productGroupIds = [row.productGroupId];
                break;
            case RETAILER:
                aggregatingOnRetailer = true;
                request.retailerIds = [row.retailerId];
                break;
            default: {
                //most aggregate fields match this pattern, so this is our fallback.
                const fieldId = `${field}Id`;
                request[fieldId + "s"] = [row[fieldId]];
                break;
            }
        }
    });
    //get the latest ads first..
    request.sortBy = "startDate,endDate";
    request.sortOrder = "DESC";
    request.pageSize = MAX_NUMBER_OF_AGGREGATED_ADS;
    request.testStatusFilter = buildTestStatusFilter([]); // so test status is only for aggregated data.
    request.eventsWithImage = true; //we just want events with images

    return request;
};

export const buildGuidanceComplianceFilterObjects = (
    showFilters: string[]
): EventComplianceGuidanceFilter[] => {
    return showFilters.map(filterOption => {
        const filter = filterOption.split("-");
        const complianceRating = {
            objectType: "EventComplianceRating",
            value: filter[0]
        };
        const guidance = filter[1];
        return {
            compliance: complianceRating,
            guidance: guidance,
            objectType: "EventComplianceGuidanceFilter"
        };
    });
};

export const reduceAggregatedAd = (aggregatedAd: any) => {
    return {
        adEndDate: aggregatedAd.adEndDate,
        adStartDate: aggregatedAd.adStartDate,
        adLink: aggregatedAd.inMarketAdImageLink,
        end: aggregatedAd.endDate,
        isNumerator: aggregatedAd.isNumerator,
        pageLink: aggregatedAd.inMarketAdPageLink,
        pageType: aggregatedAd.inMarketAdLocation,
        productGroup: aggregatedAd.productGroup,
        retailer: aggregatedAd.retailer,
        start: aggregatedAd.startDate
    };
};

const reduceGuidelineCompliance = (
    guidelineCompliance: any
): GuidelineCompliance => {
    const guidances: {
        compliance: string;
        name: string;
    }[] = [];
    if (guidelineCompliance.items) {
        guidelineCompliance.items.forEach((guidance: any) => {
            guidances.push({
                compliance: get(guidance, "status.value"),
                name: guidance.displayName
            });
        });
    }

    return {
        compliantCount: guidelineCompliance.compliantCount,
        guidances: guidances,
        hasUnknown: guidelineCompliance.unknownCount > 0,
        nonCompliantCount: guidelineCompliance.nonCompliantCount,
        unknownCount: guidelineCompliance.unknownCount
    };
};

export const reduceEventItem = (item: any): EventsItem => {
    return Object.assign({}, item, {
        guidelineCompliance: item.guidelineCompliance
            ? reduceGuidelineCompliance(item.guidelineCompliance)
            : null,
        testStatus: get(item, "testStatus.displayName"),
        performanceTierCategory: item.performanceTierCategory
            ? reduceObject(item.performanceTierCategory, "displayName", "value")
            : null
    });
};

export const reduceCreateTestEventItem = (item: any): EventsItem => {
    return Object.assign({}, item, {
        entityId: nanoid(7),
        testStatus: get(item, "testStatus.displayName")
    });
};

export const reduceEvents = (events: any): any => {
    return {
        offset: events.offset,
        pageSize: events.pageSize,
        totalCount: events.totalItems
    };
};

// Function to build the guidance nodes in guidance compliance filter
export const buildGuidanceFilterNodes = (
    parentValue: ComplianceValue,
    guidanceFilters: DisplayNameObjectValueType[]
): ChkTree[] => {
    return guidanceFilters.map(guidance => {
        return {
            children: [],
            label: guidance.displayName,
            objectType: guidance.objectType,
            value: parentValue + "-" + guidance.value
        };
    });
};
