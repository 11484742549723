import get from "lodash.get";
import type { AnyAction } from "redux";
import { creatorModifierQueryFields } from "common/util/object";
import {
    formatSortOrderForQueryAPI,
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import { HOLIDAYS_PAGE } from "administration/state/pageActions";
import {
    andFilterCondition,
    freeFormFilterCondition,
    notFilterCondition,
    enumFilterCondition
} from "common/util/filter";
import { sortCondition } from "common/util/query";
import { reduceHoliday } from "administration/holidays/state/holidayStateUtils";
import type { RootState } from "store";

// Holiday consts
const HOLIDAY_NON_HOLIDAY = "NON_HOLIDAY";

// Sort by
export const HOLIDAY_NAME = "name";
export const HOLIDAY_PRODUCTS = "products";
export const HOLIDAY_DATES = "dates";
export const HOLIDAY_RECURRING = "recurring";

export const HOLIDAY_FIELDS: string[] = [
    "entityId",
    "name",
    "currentYearEndDate",
    "currentYearStartDate",
    "customEndDay",
    "customEndMonth",
    "customStartMonth",
    "customStartDay",
    "holidayType",
    "nonrecurringEndDate",
    "nonrecurringStartDate",
    "products",
    "recurring",
    "standardHoliday.entityId",
    "standardWeeksAfter",
    "standardWeeksBefore"
].concat(creatorModifierQueryFields);

export const HOLIDAY_PRODUCTS_LIST = "productsList";

// action types
export const UPDATE_SORT_HOLIDAYS = "UPDATE_SORT_HOLIDAYS";
export const UPDATE_START_INDEX_HOLIDAYS = "UPDATE_START_INDEX_HOLIDAYS";
export const UPDATE_SEARCH_HOLIDAYS = "UPDATE_SEARCH_HOLIDAYS";
export const REQUEST_HOLIDAYS = "REQUEST_HOLIDAYS";
export const RECEIVE_HOLIDAYS = "RECEIVE_HOLIDAYS";

// actions creators
export const requestHolidays = (): AnyAction => ({
    type: REQUEST_HOLIDAYS
});

export const receiveHolidays = (json: Json): AnyAction => ({
    type: RECEIVE_HOLIDAYS,
    holidays: json
});

export const updateStartIndex = (startIndex: number): AnyAction => ({
    type: UPDATE_START_INDEX_HOLIDAYS,
    startIndex: startIndex
});

export const updateSortBy = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => ({
    type: UPDATE_SORT_HOLIDAYS,
    sortBy: sortBy,
    sortOrder: sortOrder
});

export const updateSearch = (search: string): AnyAction => ({
    type: UPDATE_SEARCH_HOLIDAYS,
    search: search
});

export const holidaysPage = (): AnyAction => ({
    type: HOLIDAYS_PAGE
});

export const getFilterCondition = (state: RootState): any => {
    const isNotNonholidayFilter = notFilterCondition(
        enumFilterCondition("holidayType", HOLIDAY_NON_HOLIDAY, "HolidayType")
    );
    if (state.holidays.search) {
        return andFilterCondition([
            isNotNonholidayFilter,
            freeFormFilterCondition(state.holidays.search, ["name", "products"])
        ]);
    } else {
        return isNotNonholidayFilter;
    }
};

const getHolidaysRequest = (state: RootState): any => {
    const sortOrder = formatSortOrderForQueryAPI(
        get(state, "holidays.sortOrder")
    );

    return {
        entity: "Holiday",
        filterCondition: getFilterCondition(state),
        maxResults: state.holidays.maxResults,
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: HOLIDAY_FIELDS,
        sortConditions: [
            sortCondition(get(state, "holidays.sortBy"), sortOrder)
        ],
        startIndex: state.holidays.startIndex
    };
};

export function fetchHolidays() {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState): any {
            return getHolidaysRequest(state);
        },
        preRequestFunc: requestHolidays,
        okDispatchFunc: receiveHolidays,
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.result.map(reduceHoliday),
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}
