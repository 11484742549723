import omit from "lodash/omit";
import {
    REQUEST_PREFERENCES,
    RECEIVE_PREFERENCES,
    REMOVE_PREFERENCES,
    SET_PREFERENCE
} from "common/shell/state/preferencesActions";
import type { PreferencesActions } from "common/shell/state/preferencesActions";
import noAction from "common/util/noAction";

type PreferenceState = Dictionary<any>;

const reducePreferences = (preferences: any[]): any => {
    const prefs: PreferenceState = {};
    preferences.forEach(function (preference) {
        try {
            prefs[preference.name] = JSON.parse(preference.value);
        } catch (error) {
            // not JSON parsable - just return value
            prefs[preference.name] = preference.value;
        }
    });
    if (!prefs.initialized) {
        prefs.initialized = true;
    }
    return prefs;
};

export const defaultState = { initialized: false };

const preferences = (
    state: PreferenceState = defaultState,
    action: PreferencesActions = noAction
): PreferenceState => {
    switch (action.type) {
        case REQUEST_PREFERENCES:
            return defaultState;
        case RECEIVE_PREFERENCES:
            return reducePreferences(action.preferences);
        case SET_PREFERENCE: {
            const prefName = action.preference.name;
            return Object.assign({}, state, {
                [prefName]: action.preference.value
            });
        }
        case REMOVE_PREFERENCES: {
            return omit(state, action.names);
        }
        default:
            return state;
    }
};

export default preferences;
