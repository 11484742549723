import isNil from "lodash.isnil";
import { isEmptyString } from "common/util/lang";
import type { DateFilter } from "common/util/date";
import {
    getChannelRetailers,
    getProductCategoryProductGroups
} from "filter/utils/filterUtils";
import type {
    EventsFilterState,
    FilterState,
    OpportunitiesFilterState,
    TopPerformanceFilterState
} from "filter/utils/filterUtils";
import type { TestStatusValue } from "opportunities/state/opportunityStateUtils";
import type { ProductFilter } from "shell/state/initReducers";
import type { AppDispatch } from "store";

// action types
export const CLEAR_ALL = "CLEAR_ALL";
export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";
export const COPY_FILTERS = "COPY_FILTERS";
export const UPDATE_CAMPAIGN_END_DATE_FILTER =
    "UPDATE_CAMPAIGN_END_DATE_FILTER";
export const UPDATE_CAMPAIGN_FILTER = "UPDATE_CAMPAIGN_FILTER";
export const UPDATE_CAMPAIGN_START_DATE_FILTER =
    "UPDATE_CAMPAIGN_START_DATE_FILTER";
export const UPDATE_DATE_FILTER = "UPDATE_DATE_FILTER";
export const UPDATE_HOLIDAY_FILTER = "UPDATE_HOLIDAY_FILTER";
export const UPDATE_MANAGER_FILTER = "UPDATE_MANAGER_FILTER";
export const UPDATE_PERFORMANCE_TIER_FILTER = "UPDATE_PERFORMANCE_TIER_FILTER";
export const UPDATE_PRODUCT_FILTER = "UPDATE_PRODUCT_FILTER";
export const UPDATE_PROMO_TYPE_FILTER = "UPDATE_PROMO_TYPE_FILTER";
export const UPDATE_RANGE = "UPDATE_RANGE";
export const UPDATE_REGION_FILTER = "UPDATE_REGION_FILTER";
export const UPDATE_RETAILER_FILTER = "UPDATE_RETAILER_FILTER";
export const UPDATE_SEARCH = "UPDATE_SEARCH";
export const UPDATE_SHOW = "UPDATE_SHOW";
export const UPDATE_SHOW_COMPLIANCE = "UPDATE_SHOW_COMPLIANCE";
export const UPDATE_SHOW_TESTED = "UPDATE_SHOW_TESTED";
export const UPDATE_SHOW_TOGGLE = "UPDATE_SHOW_TOGGLE";

// Page Id for filters
export const COMPLIANCE_OVERVIEW = "compliance_overview" as string;
export const CREATE_TEST = "create_test" as string;
export const EVENTS = "events" as string;
export const GUIDELINE_COLLECTIONS = "guideline_collections" as string;
export const GUIDELINES = "guidelines" as string;
export const OPPORTUNITIES = "opportunities" as string;
export const OVERVIEW_COMPLIANCE = "overview_compliance" as string;
export const OVERVIEW_COVERAGE = "overview_coverage" as string;
export const OVERVIEW_TOP_PERFORMANCE = "overview_top_performance" as string;
export const RECOMMENDATIONS = "recommendations" as string;

export type FilterPageId =
    | typeof COMPLIANCE_OVERVIEW
    | typeof EVENTS
    | typeof GUIDELINE_COLLECTIONS
    | typeof GUIDELINES
    | typeof OPPORTUNITIES
    | typeof OVERVIEW_COMPLIANCE
    | typeof OVERVIEW_COVERAGE
    | typeof OVERVIEW_TOP_PERFORMANCE
    | typeof RECOMMENDATIONS;

type FilterStates =
    | FilterState
    | EventsFilterState
    | OpportunitiesFilterState
    | TopPerformanceFilterState;

export type FilterActions =
    | Clear<typeof CLEAR_ALL>
    | Clear<typeof CLEAR_ALL_FILTERS>
    | CopyFilters
    | UpdateCampaignEndDateFilter
    | UpdateCampaignFilter
    | UpdateCampaignStartDateFilter
    | UpdateDateFilter
    | UpdateHolidayFilter
    | UpdateManagerFilter
    | UpdatePerformanceTierFilter
    | UpdateProductFilter
    | UpdatePromoTypeFilter
    | UpdateRange
    | UpdateRegionFilter
    | UpdateRetailerFilter
    | UpdateSearch
    | UpdateShow
    | UpdateShowCompliance
    | UpdateShowTested
    | UpdateShowToggle;

interface Clear<T> extends Action {
    pageId: FilterPageId;
    type: T;
}

// action creators
export const clearAll = (pageId: FilterPageId): Clear<typeof CLEAR_ALL> => {
    return {
        type: CLEAR_ALL,
        pageId: pageId
    };
};

export const clearAllFilters = (
    pageId: FilterPageId
): Clear<typeof CLEAR_ALL_FILTERS> => {
    return {
        type: CLEAR_ALL_FILTERS,
        pageId: pageId
    };
};

interface CopyFilters extends Action {
    from: FilterPageId;
    to: FilterPageId;
    type: typeof COPY_FILTERS;
}

export const copyFilters = (
    from: FilterPageId,
    to: FilterPageId
): CopyFilters => {
    return {
        type: COPY_FILTERS,
        from: from,
        to: to
    };
};

interface UpdateCampaignFilter extends Action {
    campaign: OptionTypeOrNullOrUndefined;
    pageId: FilterPageId;
    type: typeof UPDATE_CAMPAIGN_FILTER;
}

export const updateCampaignFilter = (
    campaign: OptionTypeOrNullOrUndefined,
    pageId: FilterPageId
): UpdateCampaignFilter => {
    return {
        type: UPDATE_CAMPAIGN_FILTER,
        campaign: campaign,
        pageId: pageId
    };
};

interface UpdateCampaignStartDateFilter extends Action {
    campaignStartDate: Date | null;
    pageId: FilterPageId;
    type: typeof UPDATE_CAMPAIGN_START_DATE_FILTER;
}

export const updateCampaignStartDateFilter = (
    startDate: Date | null,
    pageId: FilterPageId
): UpdateCampaignStartDateFilter => {
    return {
        type: UPDATE_CAMPAIGN_START_DATE_FILTER,
        campaignStartDate: startDate,
        pageId: pageId
    };
};

interface UpdateCampaignEndDateFilter extends Action {
    campaignEndDate: Date | null;
    pageId: FilterPageId;
    type: typeof UPDATE_CAMPAIGN_END_DATE_FILTER;
}

export const updateCampaignEndDateFilter = (
    endDate: Date | null,
    pageId: FilterPageId
): UpdateCampaignEndDateFilter => {
    return {
        type: UPDATE_CAMPAIGN_END_DATE_FILTER,
        campaignEndDate: endDate,
        pageId: pageId
    };
};

interface UpdateHolidayFilter extends Action {
    holidays: string[];
    pageId: FilterPageId;
    type: typeof UPDATE_HOLIDAY_FILTER;
}

export const updateHolidayFilter = (
    holidays: string[],
    pageId: FilterPageId
): UpdateHolidayFilter => {
    return {
        type: UPDATE_HOLIDAY_FILTER,
        holidays: holidays,
        pageId: pageId
    };
};

interface UpdateRetailerFilter extends Action {
    retailers: string[];
    pageId: FilterPageId;
    type: typeof UPDATE_RETAILER_FILTER;
}

export const updateRetailerFilter = (
    retailers: string[],
    pageId: FilterPageId
): UpdateRetailerFilter => {
    return {
        type: UPDATE_RETAILER_FILTER,
        retailers: retailers,
        pageId: pageId
    };
};

interface UpdateProductFilter extends Action {
    products: string[];
    pageId: FilterPageId;
    type: typeof UPDATE_PRODUCT_FILTER;
}

export const updateProductFilter = (
    products: string[],
    pageId: FilterPageId
): UpdateProductFilter => {
    return {
        type: UPDATE_PRODUCT_FILTER,
        products: products,
        pageId: pageId
    };
};

interface UpdateManagerFilter extends Action {
    managers: string[];
    pageId: FilterPageId;
    type: typeof UPDATE_MANAGER_FILTER;
}

export const updateManagerFilter = (
    managers: string[],
    pageId: FilterPageId
): UpdateManagerFilter => {
    return {
        type: UPDATE_MANAGER_FILTER,
        managers: managers,
        pageId: pageId
    };
};

interface UpdateRegionFilter extends Action {
    regions: string[];
    pageId: FilterPageId;
    type: typeof UPDATE_REGION_FILTER;
}

export const updateRegionFilter = (
    regions: string[],
    pageId: FilterPageId
): UpdateRegionFilter => {
    return {
        type: UPDATE_REGION_FILTER,
        regions: regions,
        pageId: pageId
    };
};

interface UpdateDateFilter extends Action {
    date: DateFilter;
    pageId: FilterPageId;
    type: typeof UPDATE_DATE_FILTER;
}

export const updateDateFilter = (
    date: DateFilter,
    pageId: FilterPageId
): UpdateDateFilter => {
    return {
        type: UPDATE_DATE_FILTER,
        date: date,
        pageId: pageId
    };
};

interface UpdateSearch extends Action {
    search: string;
    pageId: FilterPageId;
    type: typeof UPDATE_SEARCH;
}

export const updateSearch = (
    search: string,
    pageId: FilterPageId
): UpdateSearch => {
    return {
        type: UPDATE_SEARCH,
        search: search,
        pageId: pageId
    };
};

interface UpdateRange extends Action {
    min: number | undefined | null;
    max: number | undefined | null;
    pageId: FilterPageId;
    type: typeof UPDATE_RANGE;
}

export const updateRange = (
    min: number | undefined | null,
    max: number | undefined | null,
    pageId: FilterPageId
): UpdateRange => {
    return {
        type: UPDATE_RANGE,
        min: min,
        max: max,
        pageId: pageId
    };
};

interface UpdatePerformanceTierFilter extends Action {
    performanceTiers: string[];
    pageId: FilterPageId;
    type: typeof UPDATE_PERFORMANCE_TIER_FILTER;
}

export const updatePerformanceTierFilter = (
    performanceTiers: string[],
    pageId: string
): UpdatePerformanceTierFilter => {
    return {
        type: UPDATE_PERFORMANCE_TIER_FILTER,
        pageId,
        performanceTiers
    };
};

interface UpdatePromoTypeFilter extends Action {
    promoTypes: string[];
    pageId: FilterPageId;
    type: typeof UPDATE_PROMO_TYPE_FILTER;
}

export const updatePromoTypeFilter = (
    promoTypes: string[],
    pageId: string
): UpdatePromoTypeFilter => {
    return {
        type: UPDATE_PROMO_TYPE_FILTER,
        pageId,
        promoTypes
    };
};

interface UpdateShowCompliance extends Action {
    showCompliance: string[];
    pageId: FilterPageId;
    type: typeof UPDATE_SHOW_COMPLIANCE;
}

export const updateShowCompliance = (
    showCompliance: string[],
    pageId: FilterPageId
): UpdateShowCompliance => {
    return {
        type: UPDATE_SHOW_COMPLIANCE,
        pageId,
        showCompliance
    };
};

interface UpdateShowTested extends Action {
    showTested: TestStatusValue[];
    pageId: FilterPageId;
    type: typeof UPDATE_SHOW_TESTED;
}

export const updateShowTested = (
    showTested: TestStatusValue[],
    pageId: FilterPageId
): UpdateShowTested => {
    return {
        type: UPDATE_SHOW_TESTED,
        pageId,
        showTested
    };
};

interface UpdateShow extends Action {
    show: string[];
    pageId: FilterPageId;
    type: typeof UPDATE_SHOW;
}

export const updateShow = (
    show: string[],
    pageId: FilterPageId
): UpdateShow => {
    return {
        type: UPDATE_SHOW,
        show: show,
        pageId: pageId
    };
};

interface UpdateShowToggle extends Action {
    showToggle: string;
    pageId: FilterPageId;
    type: typeof UPDATE_SHOW_TOGGLE;
}

export const updateShowToggle = (
    showToggle: string,
    pageId: FilterPageId
): UpdateShowToggle => {
    return {
        type: UPDATE_SHOW_TOGGLE,
        showToggle: showToggle,
        pageId: pageId
    };
};

// Function used to get selected ppg, retailer, holiday, region based off filter and selected row
export const rowDrilldownReducer = (
    filterState: FilterStates,
    rowData: any,
    allProductCategories: ProductFilter[],
    allRetailerChannels: any[]
): any => {
    const selection: any = {
        holidays: [],
        products: [],
        regions: [],
        retailers: []
    };
    const { holidays, products, regions, retailers } = filterState;
    const {
        channelId,
        holidayId,
        productCategory,
        productGroupId,
        regionId,
        retailerId
    } = rowData;

    // If row has holiday then it is most granular, else return the filter values
    if (!isNil(holidayId) && !isEmptyString(holidayId)) {
        selection.holidays = [holidayId];
    } else {
        selection.holidays = holidays;
    }

    // If row has manager then it is most granular, else return the filter values
    if (!isNil(regionId) && !isEmptyString(regionId)) {
        selection.regions = [regionId];
    } else {
        selection.regions = regions;
    }

    // If row has product group it is most granular
    // else look at product category and get intersection with filter if filter present
    // if both aren't present then return filter values
    if (!isNil(productGroupId) && !isEmptyString(productGroupId)) {
        selection.products = [productGroupId];
    } else if (!isNil(productCategory) && !isEmptyString(productCategory)) {
        const productCategoryProductGroups = getProductCategoryProductGroups(
            allProductCategories,
            productCategory
        );
        if (products.length > 0) {
            selection.products = productCategoryProductGroups.filter(ppg => {
                return products.indexOf(ppg) > -1;
            });
        } else {
            selection.products = productCategoryProductGroups;
        }
    } else {
        selection.products = products;
    }

    // Retailer logic is the same as product group / category
    if (!isNil(retailerId) && !isEmptyString(retailerId)) {
        selection.retailers = [retailerId];
    } else if (!isNil(channelId) && !isEmptyString(channelId)) {
        const channelRetailers = getChannelRetailers(
            allRetailerChannels,
            channelId
        );
        if (retailers.length > 0) {
            selection.retailers = channelRetailers.filter(retailer => {
                return retailers.indexOf(retailer) > -1;
            });
        } else {
            selection.retailers = channelRetailers;
        }
    } else {
        selection.retailers = retailers;
    }

    return selection;
};

export const applyFiltersToPage = (
    filterState: FilterStates,
    rowData: any,
    allProductCategories: ProductFilter[],
    allRetailerChannels: any[],
    dispatch: AppDispatch,
    pageId: FilterPageId
) => {
    const reducedValues = rowDrilldownReducer(
        filterState,
        rowData,
        allProductCategories,
        allRetailerChannels
    );

    if (rowData.managerId) {
        dispatch(updateManagerFilter([rowData.managerId], pageId));
    }
    dispatch(updateHolidayFilter(reducedValues.holidays, pageId));
    dispatch(updateProductFilter(reducedValues.products, pageId));
    dispatch(updateRegionFilter(reducedValues.regions, pageId));
    dispatch(updateRetailerFilter(reducedValues.retailers, pageId));
};
