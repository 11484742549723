import get from "lodash.get";
import { getEntityIdWithoutVersion, reduceObject } from "common/util/object";

export type HolidayListState = {
    endDate: string;
    entityId: string;
    entityIdWithoutVersion: string;
    holidayType: string;
    recurring: boolean;
    name: string;
    objectType: string;
    products: string[];
    productsList: string;
    startDate: string;
} & CreatorModifierData;

type HolidayOccurrence = {
    endDate: string;
    entityId: string;
    startDate: string;
};

export const reduceHoliday = (data: any): HolidayListState => {
    const holiday = reduceObject(
        data,
        ["currentYearEndDate", "endDate"],
        ["currentYearStartDate", "startDate"],
        "entityId",
        "holidayType",
        "name",
        "nonrecurringEndDate",
        "nonrecurringStartDate",
        "objectType",
        "products"
    );

    // holidays used to be recurring - assuming existing holidays will be migrated to have recurring = true but make sure here
    let recurring = get(data, "recurring");
    if (recurring === null) {
        recurring = true;
    }
    return Object.assign({}, holiday, {
        entityIdWithoutVersion: getEntityIdWithoutVersion(data.entityId),
        holidayType: get(data.holidayType, "value"),
        productsList: data.products.join(", "),
        recurring: recurring
    });
};

export const reduceOccurrence = (data: any): HolidayOccurrence => {
    return {
        startDate: data.startDate,
        endDate: data.endDate,
        entityId: data.entityId
    };
};
