import type { Action } from "redux";
import { getAuthToken } from "common/auth/state/commonAuthActions";
import {
    createRequestSpec,
    METHOD_GET,
    requestJson
} from "common/shell/state/requestActions";
import type { AppDispatch, GetState } from "store";

// action types
export const RECEIVE_RAVEN = "RECEIVE_RAVEN";

export interface ReceiveRaven extends Action {
    type: typeof RECEIVE_RAVEN;
    raven: any;
}

export const receiveRaven = (data: any): ReceiveRaven => ({
    type: RECEIVE_RAVEN,
    raven: data
});

export const initializeRaven = () => {
    return async function (dispatch: AppDispatch, getState: GetState) {
        try {
            const { response, json } = await requestJson(
                "api/meta/raven",
                createRequestSpec(METHOD_GET, getAuthToken(getState()))
            );
            if (response.ok) {
                dispatch(receiveRaven(json));
            } else {
                console.log("Error initializing raven:");
                console.dir(response);
                console.dir(json);
            }
        } catch (error) {
            console.dir(error);
        }
    };
};
