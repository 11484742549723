import * as React from "react";
import get from "lodash.get";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import AppError from "common/shell/components/AppError";
import { initializeRaven } from "common/shell/state/ravenActions";
import { reportError } from "common/shell/util/error";
import { initialize, setTrackingData } from "common/shell/util/raven";
import { release } from "generated/version";
import type { AppDispatch, RootState } from "store";

type AppErrorBoundaryOwnProps = HasChild;
type AppErrorBoundaryProps = AppErrorBoundaryOwnProps &
    PropsFromRedux &
    WithTranslation;
type AppErrorBoundaryState = {
    caughtError: boolean;
};

class AppErrorBoundary extends React.Component<
    AppErrorBoundaryProps,
    AppErrorBoundaryState
> {
    state: AppErrorBoundaryState = {
        caughtError: false
    };

    componentDidCatch(error: Error, errorInfo: any) {
        reportError(error, { extra: errorInfo });
        this.setState({ caughtError: true });
    }

    componentDidMount() {
        this.props.initAction();
    }

    componentDidUpdate(
        prevProps: AppErrorBoundaryProps,
        prevState: AppErrorBoundaryState
    ) {
        const { configUrl, enabled } = this.props.ravenConfig;
        if (enabled) {
            const { serverMode, trackingData } = this.props;
            initialize(configUrl, serverMode, release);
            setTrackingData(trackingData);
        }
    }

    render() {
        if (this.state.caughtError) {
            return (
                <AppError
                    message={this.props.t("common:general.generic_error")}
                />
            );
        }
        return this.props.children;
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        ravenConfig: state.raven,
        serverMode: get(state, "init.serverMode"),
        trackingData: get(state, "init.trackingData")
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        initAction: () => {
            dispatch(initializeRaven());
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(AppErrorBoundary));
