import * as React from "react";

import get from "lodash.get";
import type { ParseKeys, TFunction } from "i18next";
import styled from "styled-components/macro";

import Pill from "common/components/Pill";
import BoldWrapper from "common/components/styled/BoldWrapper";
import Flexbox from "common/components/styled/Flexbox";
import SvgIcon from "common/components/SvgIcon";
import theme from "common/components/theme";
import { ReactComponent as AlertIcon } from "common/icons/Alert.svg";
import { ReactComponent as CheckmarkCircleIcon } from "common/icons/CheckmarkCircle.svg";
import { ReactComponent as InformationIcon } from "common/icons/Information.svg";
import { ReactComponent as MinusCircleIcon } from "common/icons/MinusCircle.svg";
import { ReactComponent as WarningIcon } from "common/icons/Warning.svg";
import { ReactComponent as XCircleIcon } from "common/icons/XCircle.svg";
import { date2Str } from "common/util/format";
import { isAllowedGlobal } from "common/util/permission";
import { campaignAnalysisPage } from "analysis/state/analysisActions";
import {
    campaignEditPage,
    campaignSectionPage,
    deleteCampaignConfirmation,
    moveToDraftCampaignConfirmation,
    pauseCampaignConfirmation,
    queueCampaignConfirmation,
    startCampaignConfirmation,
    stopCampaignConfirmation,
    submitForReviewCampaignConfirmation
} from "campaign/state/campaignActions";
import type { ChecklistStatusType } from "campaign/state/campaignStateUtils";
import {
    ALERT_LEVEL_ERROR,
    ALERT_LEVEL_INFO,
    ALERT_LEVEL_WARNING,
    CAMPAIGN_STATE_DRAFT,
    CAMPAIGN_STATE_INREVIEW,
    CAMPAIGN_STATE_PAUSED,
    CAMPAIGN_STATE_RUNNING,
    CHECKLIST_STATUS_COMPLETE,
    CHECKLIST_STATUS_DISABLED,
    CHECKLIST_STATUS_INCOMPLETE,
    CHECKLIST_STATUS_SUFFICIENT
} from "campaign/state/campaignStateUtils";
import type { AlertLevels } from "campaign/state/campaignStateUtils";
import type { RedemptionMethodType } from "shell/state/initReducers";
import { CAMPAIGN_TEST_RESULTS_PAGE } from "shell/state/pageActions";
import { CAMPAIGN_SHOW_ALLOW_INTERNAL_TESTING } from "shell/util/allowableActionUtils";
import type { AppDispatch } from "store";

export const CAMPAIGN_ACTION_ANALYZE = "ANALYZE";
export const CAMPAIGN_ACTION_COPY = "COPY";
export const CAMPAIGN_ACTION_DELETE = "DELETE";
export const CAMPAIGN_ACTION_EDIT = "EDIT";
export const CAMPAIGN_ACTION_MOVE_TO_DRAFT = "MOVE_TO_DRAFT";
export const CAMPAIGN_ACTION_PAUSE = "PAUSE";
export const CAMPAIGN_ACTION_QUEUE = "QUEUE";
export const CAMPAIGN_ACTION_STOP = "STOP";
export const CAMPAIGN_ACTION_START = "START";
export const CAMPAIGN_ACTION_SUBMIT_FOR_REVIEW = "SUBMIT_FOR_REVIEW";
export const CAMPAIGN_ACTION_VIEW_TEST_RESULTS = "VIEW_TEST_RESULTS";

export const CHECKLIST_ITEM_ALERTS = "ALERTS";
export const CHECKLIST_ITEM_BARCODES = "BARCODES";
export const CHECKLIST_ITEM_BUDGET_AND_OPTIMIZATION = "BUDGET_AND_OPTIMIZATION";
export const CHECKLIST_ITEM_CONTROL_OFFERS = "CONTROL_OFFERS";
export const CHECKLIST_ITEM_EXPIRATION_DATE = "EXPIRATION_DATE";
export const CHECKLIST_ITEM_LAYOUT = "LAYOUT";
export const CHECKLIST_ITEM_OFFERS = "OFFERS";
export const CHECKLIST_ITEM_REDEMPTION_DATES = "REDEMPTION_DATES";
export const CHECKLIST_ITEM_REGISTER_BARCODES = "REGISTER_BARCODES";
export const CHECKLIST_ITEM_START_DATE = "START_DATE";
export const CHECKLIST_ITEM_START_END_DATES = "START_END_DATES";
export const CHECKLIST_ITEM_TARGETING = "TARGETING";
export const CHECKLIST_ITEM_UPCS = "UPCS";
export const CHECKLIST_ITEM_VENDOR_CODE = "VENDOR_CODE";

export const CHECKLIST_SUBITEM_BUDGET_AND_OPTIMIZATION =
    "BUDGET_AND_OPTIMIZATION";
export const CHECKLIST_SUBITEM_OFFERS = "OFFERS";
export const CHECKLIST_SUBITEM_OFFER_CONTENT_AND_LAYOUT =
    "OFFER_CONTENT_AND_LAYOUT";
export const CHECKLIST_SUBITEM_REDEMPTION_DETAILS = "REDEMPTION_DETAILS";
export const CHECKLIST_SUBITEM_TARGETING = "TARGETING";
export const CHECKLIST_SUBITEM_UPCS = "UPCS";

export const ADD_CONTROL_OFFER = "ADD_CONTROL_OFFER";
export const AUTOMATED_OFFER_CREATION = "AUTOMATED_OFFER_CREATION";
export const ADVANCED_OFFER_CREATION = "ADVANCED_OFFER_CREATION";
export const USE_PREVIOUS_OFFER = "USE_PREVIOUS_OFFER";

export const METRIC_COLUMN_PREFIX = "col";

export const NotYetImplemented = styled.div<marginPropsType>`
    border: 1px solid ${theme.error};
    color: ${theme.error};
    font-family: BrandonTextBold;
    margin-top: 8px;
    ${props => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
    padding: 5px;
`;

export const SectionContent = styled(Flexbox)`
    background-color: ${props => props.theme.background};
    flex-direction: column;
    min-width: 0px;
    height: 100%;
`;

export const inDraftOrReview = (status: string) => {
    if (status === CAMPAIGN_STATE_DRAFT || status === CAMPAIGN_STATE_INREVIEW) {
        return true;
    }
    return false;
};

export const notInDraftOrReview = (status: string) => {
    if (status !== CAMPAIGN_STATE_DRAFT && status !== CAMPAIGN_STATE_INREVIEW) {
        return true;
    }
    return false;
};

export const inPausedOrRunning = (status: string) => {
    if (status === CAMPAIGN_STATE_PAUSED || status === CAMPAIGN_STATE_RUNNING) {
        return true;
    }
    return false;
};

export const getAlertMessage = (
    alertLevel: AlertLevels,
    count: number,
    t: TFunction
): string => {
    let message = "";
    let resourceKey: ParseKeys;
    switch (alertLevel) {
        case ALERT_LEVEL_ERROR:
            resourceKey = "campaign.has_alert_error";
            break;
        case ALERT_LEVEL_WARNING:
            resourceKey = "campaign.has_alert_warning";
            break;
        default:
            resourceKey = "campaign.has_alert_error";
            break;
    }
    if (resourceKey) {
        message = t(resourceKey, {
            count: count
        });
    }
    return message;
};

export const getAlertIcon = (
    alertLevel: AlertLevels,
    t: TFunction,
    clickHandler?: (event: React.SyntheticEvent<HTMLButtonElement>) => void
): React.ReactNode => {
    let icon = null;
    let color = theme.warning;
    switch (alertLevel) {
        case ALERT_LEVEL_INFO:
            color = theme.linkText;
            icon = InformationIcon;
            break;
        case ALERT_LEVEL_ERROR:
            color = theme.error;
            icon = AlertIcon;
            break;
        case ALERT_LEVEL_WARNING:
            color = theme.warning;
            icon = WarningIcon;
            break;
        default:
            break;
    }

    const iconProps: any = {
        color: color,
        height: "24px",
        icon: icon,
        width: "24px"
    };

    if (clickHandler) {
        iconProps.cursor = "pointer";
        iconProps.onClick = clickHandler;
    }

    if (icon && color) {
        return <SvgIcon {...iconProps} />;
    } else {
        return null;
    }
};

export const getChecklistIcon = (
    checklistStatus: ChecklistStatusType
): React.ReactNode => {
    let icon = null;
    let color = null;
    if (checklistStatus === CHECKLIST_STATUS_DISABLED) {
        return (
            <Pill
                backgroundColor={theme.background}
                borderColor={theme.btnDisabledTextColor}
                circle={true}
                color={theme.background}
                fontSize="16px"
                text=""
            />
        );
    }

    switch (checklistStatus) {
        case CHECKLIST_STATUS_COMPLETE:
            color = theme.success;
            icon = CheckmarkCircleIcon;
            break;
        case CHECKLIST_STATUS_INCOMPLETE:
            color = theme.error;
            icon = XCircleIcon;
            break;
        case CHECKLIST_STATUS_SUFFICIENT:
            color = theme.warning;
            icon = MinusCircleIcon;
            break;
        default:
            break;
    }

    if (icon && color) {
        return <SvgIcon color={color} height="24px" icon={icon} width="24px" />;
    } else {
        return null;
    }
};

export const getChecklistItemComponent = (
    campaign: any,
    item: any,
    control: React.ReactNode = null,
    limitWidth = false,
    includeTopMargin = false
): React.ReactNode => {
    const label: string = get(item, "label", "");
    let labelContent = null;
    let labelControl = null;
    if (label) {
        const split = label.split("[[");
        if (split.length >= 2) {
            labelContent = (
                <React.Fragment>
                    {split.map((part: string, i: number) => {
                        if (i === 0) {
                            return part;
                        } else {
                            const split2 = part.split("]]");
                            return (
                                <React.Fragment key={"checklistText-" + i}>
                                    <BoldWrapper>{split2[0]}</BoldWrapper>
                                    {split2[1]}
                                </React.Fragment>
                            );
                        }
                    })}
                </React.Fragment>
            );
            /*                
            const split2 = split1[1].split("]]");
            const part2 = split2[0];
            const part3 = split2[1];
            labelContent = (
                <div style={{ marginLeft: "5px", marginRight: "20px" }}>
                    {part1}
                    <BoldWrapper>{part2}</BoldWrapper>
                    {part3}
                </div>
            );
*/
        } else {
            labelContent = label;
        }
    }
    labelControl = (
        <div style={{ marginLeft: "5px", marginRight: "20px" }}>
            {labelContent}
        </div>
    );
    const iconType = get(item, "status.value");
    let icon = null;
    if (iconType) {
        icon = getChecklistIcon(iconType);
    }
    let labelStyle = {};
    if (limitWidth) {
        labelStyle = {
            width: "500px"
        };
    }
    let marginTop = "0px";
    if (includeTopMargin) {
        marginTop = "5px;";
    }
    return (
        <Flexbox
            alignItems="center"
            flexDirection="row"
            key={get(item, "entityId")}
            marginBottom="5px"
            marginTop={marginTop}
        >
            <div style={{ width: "24px" }}>{icon}</div>
            <div style={labelStyle}>{labelControl}</div>
            {control}
        </Flexbox>
    );
};

export const processAction = (
    action: string,
    campaign: any,
    t: TFunction,
    dispatch: AppDispatch,
    refreshData: NoArgsHandler
) => {
    switch (action) {
        case CAMPAIGN_ACTION_ANALYZE:
            dispatch(campaignAnalysisPage(campaign.entityId));
            break;
        case CAMPAIGN_ACTION_DELETE:
            deleteCampaignConfirmation(campaign, t, dispatch);
            break;
        case CAMPAIGN_ACTION_EDIT:
            dispatch(campaignEditPage(campaign.entityId));
            break;
        case CAMPAIGN_ACTION_MOVE_TO_DRAFT:
            moveToDraftCampaignConfirmation(campaign, t, dispatch, refreshData);
            break;
        case CAMPAIGN_ACTION_PAUSE:
            pauseCampaignConfirmation(campaign, t, dispatch, refreshData);
            break;
        case CAMPAIGN_ACTION_QUEUE:
            queueCampaignConfirmation(campaign, t, dispatch, refreshData);
            break;
        case CAMPAIGN_ACTION_START:
            startCampaignConfirmation(campaign, t, dispatch, refreshData);
            break;
        case CAMPAIGN_ACTION_STOP:
            stopCampaignConfirmation(campaign, t, dispatch, refreshData);
            break;
        case CAMPAIGN_ACTION_SUBMIT_FOR_REVIEW:
            submitForReviewCampaignConfirmation(
                campaign,
                t,
                dispatch,
                refreshData
            );
            break;
        case CAMPAIGN_ACTION_VIEW_TEST_RESULTS:
            dispatch(
                campaignSectionPage(
                    CAMPAIGN_TEST_RESULTS_PAGE,
                    campaign.entityId
                )
            );
            break;
        default:
            break;
    }
};

export const isCampaignIncomplete = (campaign: any): boolean => {
    return get(campaign, "checklistStatus.value") === "INCOMPLETE";
};

export const getCampaignStatus = (campaign: any): string => {
    return get(campaign, "state.value", "");
};

export const requiresSpendPerDay = (campaign: any): boolean => {
    return get(
        campaign,
        "tenantTestPlatform.testPlatform.requiresSpendPerDay",
        false
    );
};

export const getDateRangeRunning = (campaign: any, t: TFunction): string => {
    const startDate = date2Str(get(campaign, "startDateFormatDate", ""));
    let endDate = date2Str(get(campaign, "endDateFormatDate", ""));

    if (!endDate) {
        endDate = t("campaign.ongoing");
    }
    return startDate + " - " + endDate;
};

const includeCouponLangauge = (tenantTestPlatform: any): boolean => {
    let include = false;
    if (get(tenantTestPlatform, "supportedCouponLocales", []).length > 1) {
        include = true;
    }
    return include;
};

export const supportsRedemptionMethod = (campaign: Campaign) => {
    const supportsRedemptionMethod = get(
        campaign,
        "tenantTestPlatform.testPlatform_supportsRedemptionMethod"
    );
    const availableRedemptionMethodCount =
        campaign.availableRedemptionMethods?.length ?? 0;
    if (supportsRedemptionMethod && availableRedemptionMethodCount > 0) {
        return true;
    }
    return false;
};

export const requiresExpirationDate = (tenantTestPlatform: any): boolean => {
    if (get(tenantTestPlatform, "testPlatform_requiresExpirationDate")) {
        return true;
    }
    return false;
};

export const requiresRedemptionDetails = (tenantTestPlatform: any): boolean => {
    let requiresRedemptionDetails = false;
    if (
        get(tenantTestPlatform, "testPlatform_supportsRedemptionMethod") ||
        get(tenantTestPlatform, "testPlatform_requiresExpirationDate") ||
        get(tenantTestPlatform, "testPlatform_requiresRestrictions") ||
        includeCouponLangauge(tenantTestPlatform)
    ) {
        requiresRedemptionDetails = true;
    }
    return requiresRedemptionDetails;
};

export const requiresFacebookCampaignType = (
    tenantTestPlatform: any
): boolean => {
    return get(tenantTestPlatform, "testPlatform_requiresFacebookCampaignType");
};

export const requiresControlOversamplingPercent = (
    tenantTestPlatform: any
): boolean => {
    return get(
        tenantTestPlatform,
        "testPlatform_requiresControlOversamplingPercent"
    );
};

export const requiresBudgetAndOptimization = (
    tenantTestPlatform: any
): boolean => {
    return (
        get(tenantTestPlatform, "testPlatform_requiresFacebookCampaignType") ||
        get(tenantTestPlatform, "testPlatform_requiresSpendPerDay") ||
        get(
            tenantTestPlatform,
            "testPlatform_requiresControlOversamplingPercent"
        )
    );
};

export const requiresRestrictions = (tenantTestPlatform: any) => {
    return get(tenantTestPlatform, "testPlatform_requiresRestrictions");
};

export const requiresRewardMechanism = (
    redemptionMethods: RedemptionMethodType[],
    redemptionMethodValue?: string
): boolean => {
    if (!redemptionMethodValue) {
        return false;
    }
    const redemptionMethod = redemptionMethods.find(
        (redemptionMethod: RedemptionMethodType) => {
            return redemptionMethod.value === redemptionMethodValue;
        }
    );
    if (!redemptionMethod) {
        return false;
    } else {
        return redemptionMethod.requiresRewardMechanism;
    }
};

export const includeKlipemTraffic = (
    tenantTestPlatform: any,
    allowableActions: any
): boolean => {
    const canShowOnKlipem = isAllowedGlobal(
        CAMPAIGN_SHOW_ALLOW_INTERNAL_TESTING,
        allowableActions
    );
    if (
        get(
            tenantTestPlatform,
            "testPlatform.userCanChooseToAllowInternalTesting"
        ) &&
        canShowOnKlipem
    ) {
        return true;
    }
    return false;
};

export const supportsProgress = (campaign: any): boolean => {
    return get(campaign, "supportsProgress", false);
};

export const supportsCeiOverTime = (campaign: any): boolean => {
    return get(campaign, "supportsCeiOverTime", false);
};

export const getRetailerSelectedText = (
    availableRetailers: any[],
    checked: string[],
    t: TFunction,
    labelField = "label",
    valueField = "value",
    childrenField = "children"
) => {
    let processedRetailers: string[] = [];
    const processedChannels: string[] = [];
    availableRetailers.forEach((channel: any) => {
        let allTrue = true;
        const retailersPerChannel: string[] = [];
        channel[childrenField].forEach((retailer: any) => {
            const isChannelRetailerSelected = checked.includes(
                get(retailer, valueField)
            );
            if (!isChannelRetailerSelected) {
                allTrue = false;
            } else {
                retailersPerChannel.push(get(retailer, labelField));
            }
        });
        if (allTrue) {
            processedChannels.push(get(channel, labelField));
        } else {
            processedRetailers = processedRetailers.concat(retailersPerChannel);
        }
    });
    let selectedText: string[] = [];
    processedChannels.sort();
    processedRetailers.sort();
    if (processedChannels.length > 0) {
        selectedText.push(t("general.channel_plural") + ":");
        selectedText = selectedText.concat(processedChannels);
    }
    if (processedRetailers.length > 0) {
        selectedText.push(t("common:general.retailer_plural") + ":");
        selectedText = selectedText.concat(processedRetailers);
    }
    return selectedText;
};

export const getRetailerFilterText = (
    availableRetailers: any[],
    count: number,
    t: TFunction,
    selected: string[],
    labelField = "label",
    childrenField = "children",
    allTextResourceKey = "general.select_specific_retailers_or_channels"
) => {
    // @ts-expect-error i18n can't find key from string
    let filterText: string = t(allTextResourceKey);
    if (count > 0) {
        filterText = "";
        let processedRetailers: string[] = [];
        const processedChannels: string[] = [];
        availableRetailers.forEach((channel: any) => {
            let allTrue = true;
            const retailersPerChannel: string[] = [];
            channel[childrenField].forEach((retailer: any) => {
                const isChannelRetailerSelected = selected.includes(
                    get(retailer, labelField)
                );
                if (!isChannelRetailerSelected) {
                    allTrue = false;
                } else {
                    retailersPerChannel.push(get(retailer, labelField));
                }
            });
            if (allTrue) {
                processedChannels.push(get(channel, labelField));
            } else {
                processedRetailers = processedRetailers.concat(
                    retailersPerChannel
                );
            }
        });
        if (processedChannels.length > 0) {
            filterText += t("general.channel_x", {
                count: processedChannels.length
            });
            filterText += "  ";
        }
        if (processedRetailers.length > 0) {
            filterText += t("general.retailers_x", {
                count: processedRetailers.length
            });
        }
    }
    return filterText;
};

export const getSelectedLeadCampaigns = (
    upstreamCampaigns: Campaign[],
    t: TFunction
) => {
    let upstreamCampaignsDisplayString = t("common:general.none_selected");
    const upstreamCampaignCount = upstreamCampaigns.length;
    if (upstreamCampaignCount > 0) {
        upstreamCampaignsDisplayString = "";
        for (let i = 0; i < upstreamCampaignCount; i += 1) {
            const upstreamCampaign = upstreamCampaigns[i];
            upstreamCampaignsDisplayString += upstreamCampaign.name;
            if (i < upstreamCampaignCount - 1) {
                upstreamCampaignsDisplayString += ", ";
            }
        }
    }
    return upstreamCampaignsDisplayString;
};
