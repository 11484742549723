//Colors
const color_primary = "#4990ef";
const color_primary_dark = "#3777cd";

const color_black = "#000000";
const color_black_text = "#424242";
const color_black_with_opacity_08 = "rgba(0, 0, 0, 0.08)";
const color_black_with_opacity_1 = "rgba(0, 0, 0, 0.1)";
const color_black_with_opacity_16 = "rgba(0, 0, 0, 0.16)";
const color_black_with_opacity_18 = "rgba(0, 0, 0, 0.18)";
const color_black_with_opacity_2 = "rgba(0, 0, 0, 0.2)";
const color_black_with_opacity_24 = "rgba(0, 0, 0, 0.24)";
const color_black_with_opacity_25 = "rgba(0, 0, 0, 0.25)";
const color_black_with_opacity_3 = "rgba(0, 0, 0, 0.3)";
const color_black_with_opacity_5 = "rgba(0, 0, 0, 0.5)";
const color_black_with_opacity_75 = "rgba(0, 0, 0, 0.75)";

const color_mercury = "#e7e6e6";
const color_fair_pink = "#feefec";
const color_coral = "#ED9AB0";
const color_darker_grey = "#7e8c97";
const color_dark_blueish_grey = "#4a5461";
const color_light_blueish_grey = "#ecf4ff";
const color_icon_grey = "#818e99";
const color_active_grey = "#bec5cb";
const color_disabled_grey = "#e8e8e8";

const color_lighter_grey = "#eff1f3";
const color_table_header = "#f1f1f1";
//const color_table_row_dark = "#fafafa";

const color_data_grid_column_header_background = "#f1f1f1";
const color_data_grid_column_header_color = "#424242";
const color_data_grid_row_hover = "#eff1f3";
const color_data_grid_row_alternate_background = "#fafafa";

const color_silver_sand = "#bbc0c4";
const color_warm_grey = "#fafafa";
const color_grey = "#dbe2e7";
const color_white_smoke = "#f6f6f6";
const color_white_smoke_tint = "#f7f7f7";
const color_white = "#ffffff";
const color_white_with_opacity_6 = "rgba(255, 255, 255, 0.6)";
const color_white_with_opacity_16 = "rgba(255, 255, 255, 0.16)";
const color_grey_blue = "#f6f9fe";

const color_orange = "#ffa600";
const color_dark_blue = "#2F74B3";
const color_light_blue = "#7FB1F3";
const color_light_blue_grey = "#92B2DE";
const color_top_blue = "#abcbd9";
const color_echo_blue = "#afb3bf";
const color_blue_zodiac = "#3e4453";
const color_info_blue = "#87ceeb";
const color_purple = "#8b80ff";
const color_magenta = "#b964d0";
const color_dark_green = "#478c3a";
const color_lime_green = "#86bd4d";
const color_sky_blue = "#75d4fc";
const color_dark_red = "#ba4c5a";
const color_dark_purple = "#842e9b";
const color_yellow = "#decf3f";

const color_move_panel_background = "#efefef";

const color_control_offer = "#ecaf4b";
const color_compliance_footer = "#fafafa";

const color_dark_grey = "#47494f";
const color_medium_dark_grey = "#70737C";
const color_default_grey = "#818e99";
const color_light_grey = "#d1d8dd";
const color_active_border = "#bec5cb";
const color_border = "#d1d8dd";
const color_universal_background = "#f3f3f3";

const color_success = "#3fbe7a";
//const color_success_light = "#56c68a";
const color_success_lighter = "#70dea3";
// const color_success_dark = "#2ca564";
const color_success_darker = "#258e56";
const color_warning = "#ecaf4b";
const color_warning_dark = "#d89f3d";
//const color_warning_light = "#edb75d";
//const color_warning_lighter = "#f8d190";
//const color_warning_dark = "#cf9941";
const color_warning_darker = "#b08338";
const color_alert = "#e54f4c";
//const color_alert_light = "#e86967";
//const color_alert_lighter = "#f38683";
const color_alert_dark = "#cb4a47";
//const color_alert_darker = "#b73f3c";

const color_primary_upper_gradient = "#64a5fd";
const color_primary_lower_gradient = "#4184de";
const color_primary_active_upper_gradient = "#3c7ed7";
const color_primary_active_lower_gradient = "#346ab3";
const color_primary_with_opacity_4 = "rgba(73, 144, 239, 0.4)";
const color_primary_light = "#d9e9ff";
const color_highlight = "#f7f1c0";
// const color_highlight = "#f2e8ef";

const color_success_upper_gradient = "#40c080";
const color_success_lower_gradient = "#2da76a";
const color_success_active_upper_gradient = "#3ab376";
const color_success_active_lower_gradient = "#21995d";

const color_secondary_emphasis_upper_gradient = "#ffffff";
const color_secondary_emphasis_lower_gradient = "#efefef";
const color_secondary_emphasis_active_upper_gradient = "#ffffff";
const color_secondary_emphasis_active_lower_gradient = "#e5e3e3";

const color_warning_upper_gradient = "#ecaf4b";
const color_warning_lower_gradient = "#db9f3d";
const color_warning_active_upper_gradient = "#d59c3d";
const color_warning_active_lower_gradient = "#ba832a";

const color_alert_upper_gradient = "#e54f4c";
const color_alert_lower_gradient = "#cb3c39";

const color_alert_active_upper_gradient = "#c93b38";
const color_alert_active_lower_gradient = "#ad2c29";

const color_navigation_header_background = "#4c5263";
const color_navigation_header_selected_text = "#ffffff";

const color_primary_banner_left_gradient = "#3bb879";
const color_primary_banner_right_gradient = "#3dbc71";
const color_secondary_banner_left_gradient = "#4e8fe7";
const color_secondary_banner_right_gradient = "#4787dc";

const color_panel_upper_gradient = "#f3f3f3";
const color_panel_lower_gradient = "#ffffff";

const color_eversight_green = "#60b56b";
const color_eversight_light_green = "#dff0e1";

const color_hover_table_row = "#eff1f3";

const color_stacked_bar_1 = "#2A9D8F";
const color_stacked_bar_2 = "#3068B3";
const color_stacked_bar_3 = "#A05195";
const color_stacked_bar_4 = "#4B7F52";
const color_stacked_bar_5 = "#006083";
const color_stacked_bar_6 = "#00A1C1";
const color_stacked_bar_7 = "#5E7CE2";
const color_stacked_bar_8 = "#665191";
const color_stacked_bar_9 = "#7EBDC2";

const dim_max_width = "1360px";
const dim_min_width = "840px";

//Border Radius
const common_border_radius = "4px";

export const borderRadius = {
    button: common_border_radius,
    card: common_border_radius,
    checkbox: common_border_radius,
    input: common_border_radius,
    wrapper: common_border_radius
};

//Box Shadows
const elevation1 = `0 1px 2px 0 ${color_black_with_opacity_08}`;
const elevation2 = `0 2px 6px 0 ${color_black_with_opacity_16}`;
const elevation3 = `0 2px 10px 0 ${color_black_with_opacity_24}`;

const boxShadow = {
    card: elevation1,
    dropdown: elevation2,
    fixedColumn: `4px 0 6px 0 ${color_black_with_opacity_08}`,
    notification: elevation3,
    popup: elevation3
};

const theme: Dictionary<string> = {
    actionStatus: color_medium_dark_grey,
    activeIcon: color_medium_dark_grey,
    alert: color_alert,
    allocationBar1: color_stacked_bar_1,
    allocationBar2: color_stacked_bar_2,
    allocationBar3: color_stacked_bar_3,
    allocationBar4: color_stacked_bar_4,
    allocationBar5: color_stacked_bar_5,
    allocationBar6: color_stacked_bar_6,
    allocationBar7: color_stacked_bar_7,
    allocationBar8: color_stacked_bar_8,
    allocationBar9: color_stacked_bar_9,
    allocationBarControl: color_highlight,
    appCardHover: color_hover_table_row,
    appHeaderBackgroundColor: color_blue_zodiac,
    appHeaderColor: color_echo_blue,
    areaTrendLineColor: color_dark_blueish_grey,
    arrowIcon: color_primary,
    attribute: color_white,
    attributeBg: color_default_grey,
    background: color_white,
    banner: color_primary_banner_left_gradient,
    bannerTextShadow: color_white_with_opacity_16,
    bannerGradient: color_primary_banner_right_gradient,
    betaColor: color_eversight_green,
    border: color_border,
    boxShadowColor: color_black_with_opacity_24,
    boxShadowBlackWithOpacity: color_black_with_opacity_16,
    brokenRules: color_warning,
    btnAlertBg: color_alert_upper_gradient,
    btnAlertBgGradient: color_alert_lower_gradient,
    btnAlertActiveBg: color_alert_active_lower_gradient,
    btnAlertActiveBgGradient: color_alert_active_upper_gradient,
    btnPrimaryTextColor: color_white,
    btnPrimaryBg: color_primary_lower_gradient,
    btnPrimaryBgGradient: color_primary_upper_gradient,
    btnPrimaryActiveBg: color_primary_active_lower_gradient,
    btnPrimaryActiveBgGradient: color_primary_active_upper_gradient,
    btnSuccessBg: color_success_lower_gradient,
    btnSuccessBgGradient: color_success_upper_gradient,
    btnSuccessActiveBg: color_success_active_lower_gradient,
    btnSuccessActiveBgGradient: color_success_active_upper_gradient,
    btnDisabledBg: color_border,
    btnDisabledTextColor: color_icon_grey,
    btnSecondaryBorderColor: color_border,
    btnSecondaryBorderHoverColor: color_active_grey,
    btnSecondaryBoxShadowHover: color_black_with_opacity_08,
    btnSecondaryBoxShadowActive: color_black_with_opacity_3,
    btnSecondaryBg: color_white,
    btnSecondaryEmphasisBg: color_secondary_emphasis_lower_gradient,
    btnSecondaryEmphasisBgGradient: color_secondary_emphasis_upper_gradient,
    btnSecondaryEmphasisBorderColor: color_light_grey,
    btnSecondaryEmphasisBorderHoverColor: color_active_border,
    btnSecondaryEmphasisBoxShadowHover: color_black_with_opacity_08,
    btnSecondaryEmphasisActiveBg:
        color_secondary_emphasis_active_lower_gradient,
    btnSecondaryEmphasisActiveBgGradient:
        color_secondary_emphasis_active_upper_gradient,
    btnWarningBg: color_warning_lower_gradient,
    btnWarningBgGradient: color_warning_upper_gradient,
    btnWarningActiveBg: color_warning_active_lower_gradient,
    btnWarningActiveBgGradient: color_warning_active_upper_gradient,
    cardBorderColor: color_active_border,
    cellHighlight: color_highlight,
    chartAxis: color_icon_grey,
    chartAxisBg: color_warm_grey,
    close: color_warning_darker,
    columnA: color_primary,
    columnB: color_primary_active_lower_gradient,
    complianceRowBg: color_compliance_footer,
    complianceFooterBg: color_compliance_footer,
    compliant: color_success_lower_gradient,
    compliantHover: color_success_upper_gradient,
    coverage: color_primary,
    coverageHover: color_primary_dark,
    controlOfferBg: color_control_offer,
    darkGreyText: color_darker_grey,
    dashboardAllRecommendations: color_primary_active_lower_gradient,
    dashboardOptimalRecommendations: color_dark_green,
    dashboardTopRecommendations: color_magenta,
    dataGridHeaderColumnColor: color_data_grid_column_header_color,
    dataGridHeaderColumnBg: color_data_grid_column_header_background,
    dataGridRowHover: color_data_grid_row_hover,
    dataGridRowAlternate: color_data_grid_row_alternate_background,
    dateToday: color_primary_light,
    defaultGreyText: color_default_grey,
    disabledIcon: color_border,
    disabledListItem: color_disabled_grey,
    donutCrossPromotions: color_purple,
    donutFrequency: color_alert_lower_gradient,
    donutMPP: color_magenta,
    donutOffer: color_success_lower_gradient,
    donutOptimalTiming: color_primary_active_lower_gradient,
    donutVisuals: color_warning_dark,
    donutTrack: color_table_header,
    dottedBorder: color_primary,
    drawerHeader: color_default_grey,
    dropdownSelected: color_primary,
    dropdownSelectedBg: color_primary_with_opacity_4,
    dropdownHover: color_table_header,
    emptyStateHover: color_grey_blue,
    errorBanner: color_alert,
    errorBannerGradient: color_alert_dark,
    filterContainerBg: color_warm_grey,
    filterDropdownHover: color_silver_sand,
    goodRecommendation: color_success,
    error: color_alert,
    eversightLogo: color_eversight_green,
    greyBgColor: color_lighter_grey,
    greyText: color_dark_grey,
    greyIcon: color_silver_sand,
    iconBackground: color_hover_table_row,
    iconMenu: color_icon_grey,
    imageHover: color_black_with_opacity_5,
    imageModal: color_black_with_opacity_75,
    info: color_info_blue,
    inProgress: color_primary_lower_gradient,
    inProgressGradient: color_primary_upper_gradient,
    inputBorder: color_border,
    inputInsetBorder: color_black_with_opacity_18,
    inputFocusBorder: color_primary_with_opacity_4,
    inputDisabledBg: color_light_grey,
    legend: color_icon_grey,
    lessExpensive: color_primary_light,
    lessLightText: color_white_with_opacity_6,
    lightGreyBg: color_white_smoke,
    lightGreyText: color_light_grey,
    lightText: color_white,
    lightTextShadow: color_black_with_opacity_25,
    linkBgColor: color_lighter_grey,
    linkText: color_primary,
    linkHoverText: color_primary_dark,
    listBorder: color_data_grid_row_hover,
    listSelectedBg: color_active_grey,
    listSeparator: color_grey,
    loadMask: color_eversight_green,
    loadMaskBg: color_light_grey,
    loadMaskBorder: color_eversight_light_green,
    lockBannerBg: color_success_lighter,
    maxWidth: dim_max_width,
    metricBorder: color_default_grey,
    minWidth: dim_min_width,
    menuBorder: color_grey,
    modalSubText: color_icon_grey,
    modalBottomRow: color_white_smoke_tint,
    modalButtonRow: color_table_header,
    moreExpensive: color_primary_dark,
    moreText: color_dark_grey,
    movePanelBg: color_move_panel_background,
    movePanelBorder: color_darker_grey,
    navigationHeaderBackground: color_navigation_header_background,
    navigationHeaderSelectedText: color_navigation_header_selected_text,
    negative: color_alert,
    noEventsMessageBg: color_table_header,
    noMarketTrackBg: color_black_with_opacity_5,
    noncompliant: color_alert_lower_gradient,
    nonCompliantHover: color_alert_upper_gradient,
    noteCardBackground: color_table_header,
    noCoverage: color_mercury,
    noCoverageHover: color_light_grey,
    noGuidance: color_mercury,
    noGuidanceHover: color_light_grey,
    opportunities: color_grey,
    outstandingTags: color_light_blue,
    outperform: color_top_blue,
    panel: color_panel_upper_gradient,
    panelGradient: color_panel_lower_gradient,
    placeholder: color_default_grey,
    positive: color_success,
    primaryBanner: color_primary_banner_left_gradient,
    primaryBannerGradient: color_primary_banner_right_gradient,
    queuedPending: color_orange,
    queuedTesting: color_primary,
    radioCheckbox: color_white,
    radioCheckboxBackground: color_primary,
    radioCheckboxDisabled: color_default_grey,
    recommendationCount: color_alert,
    relatedLineGroups: color_primary_dark,
    reference: color_yellow,
    refunded: color_dark_red,
    replacedByCompetitor: color_coral,
    replacedBySameBrand: color_light_blue_grey,
    replacedBySameManufacturer: color_dark_blue,
    reportTooltipBg: color_black,
    scenarioComplete: color_light_blue,
    scenarioDraft: color_default_grey,
    scenarioError: color_alert_dark,
    scenarioGenerating: color_default_grey,
    scenarioImplemented: color_stacked_bar_2,
    scenarioObjectiveChanged: color_light_blueish_grey,
    scenarioRuleChanged: color_light_blueish_grey,
    scenarioRuleRemoved: color_fair_pink,
    secondaryBanner: color_secondary_banner_left_gradient,
    secondaryBannerGradient: color_secondary_banner_right_gradient,
    selected: color_success,
    selectedIcon: color_primary,
    selectionHover: color_grey,
    similarlyExpensive: color_white,
    simpleTableHeader: color_table_header,
    sparkline: color_primary,
    subBannerBg: color_dark_blueish_grey,
    subTabInactive: color_icon_grey,
    subTabSelected: color_black_text,
    subText: color_icon_grey,
    success: color_success,
    successHover: color_success_darker,
    tableHeaderBg: color_warm_grey,
    tableInfoBg: color_warm_grey,
    tabUnselectedBackground: color_black_with_opacity_1,
    tag: color_icon_grey,
    tagAlert: color_alert,
    tested: color_primary,
    testedHover: color_primary_dark,
    testedExpired: color_darker_grey,
    testedExpiredGradient: color_light_grey,
    text: color_black_text,
    textDisabled: color_default_grey,
    textLessDark: color_black_with_opacity_5,
    toggleButtonBackground: color_table_header,
    toggleButtonBoxShadow: color_black_with_opacity_2,
    toggleBtnHover: color_white_smoke,
    tooltipBg: color_black_with_opacity_2,
    trend7DayMovingAvgMarkup: color_dark_purple,
    trend28DayMovingAvgMarkup: color_orange,
    trendDailyMarkup: color_dark_red,
    trendingDown: color_alert,
    trendingNeutral: color_icon_grey,
    trendingUp: color_success,
    trendMargin: color_sky_blue,
    trendSales: color_lime_green,
    trendUnits: color_dark_red,
    universalBackground: color_universal_background,
    unknownBar: color_darker_grey,
    unknownBarHover: color_light_grey,
    untested: color_mercury,
    untestedHover: color_light_grey,
    warning: color_warning,
    wizardActive: color_primary,
    //non colors ....
    // @ts-expect-error Fix by separating out of theme object and giving own type
    borderRadius,
    // @ts-expect-error Fix by separating out of theme object and giving own type
    boxShadow
};

export default theme;
