import get from "lodash.get";
import type { AnyAction } from "redux";
import { resetCanNotLeave } from "common/shell/state/navigationActions";
import {
    BODY_TYPE_FILE,
    formatSortOrderForQueryAPI,
    makeRequestThunk,
    METHOD_GET,
    METHOD_POST
} from "common/shell/state/requestActions";
import { success } from "common/shell/state/toastActions";
import {
    campaignOffersPage,
    resetCampaign
} from "campaign/state/campaignActions";
import type { ActionOrThunk, Actions, RootState } from "store";

export type ImageCreateDto = {
    COUPONUrl: string;
    EMAILUrl: string;
    GENERALUrl: string;
    LARGEUrl: string;
    MEDIUMUrl: string;
    ORIGINALUrl: string;
    SMALLUrl: string;
    THUMBNAILUrl: string;
    displayName: string;
    entityId: string;
    success: boolean;
};

export const RECEIVE_SMART_IMAGE_SETS = "RECEIVE_SMART_IMAGE_SETS";
export const REQUEST_SMART_IMAGE_SETS = "REQUEST_SMART_IMAGE_SETS";
export const UPDATE_SMART_IMAGE_SETS_SEARCH = "UPDATE_SMART_IMAGE_SETS_SEARCH";
export const UPDATE_SMART_IMAGE_SETS_SORT = "UPDATE_SMART_IMAGE_SETS_SORT";
export const UPDATE_SMART_IMAGE_SETS_START_INDEX =
    "UPDATE_SMART_IMAGE_SETS_START_INDEX";

const receiveSmartImageSets = (json: Json): AnyAction => ({
    type: RECEIVE_SMART_IMAGE_SETS,
    smartImageSets: json
});

const requestSmartImageSets = (): AnyAction => ({
    type: REQUEST_SMART_IMAGE_SETS
});

export const updateSmartImageSetsStartIndex = (
    startIndex: number
): AnyAction => {
    return {
        type: UPDATE_SMART_IMAGE_SETS_START_INDEX,
        startIndex
    };
};

export const updateSmartImageSetsSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => {
    return {
        type: UPDATE_SMART_IMAGE_SETS_SORT,
        sortBy,
        sortOrder
    };
};

const getSmartImageRequest = (
    state: RootState,
    search: string | null,
    campaignId: string | null = null,
    promotionIds: string | null = null,
    selectedImageId: string | null = null
) => {
    const sortOrder = formatSortOrderForQueryAPI(state.images.sortOrder);
    return {
        campaignId: campaignId,
        filterByRetailer: false,
        initialize: true,
        maxResults: get(state, "images.maxResults"),
        objectType: "SmartImageSetQuery",
        promotionIds: promotionIds,
        searchString: search,
        selectedImageId: selectedImageId,
        sortBy: get(state, "images.sortBy"),
        sortOrder: sortOrder,
        startIndex: get(state, "images.startIndex")
    };
};

const br2array = (str: string) => {
    return str.split("<br/>");
};

const reduceSmartImageSet = (data: SmartImageSet) => {
    return Object.assign({}, data, {
        products: br2array(data.productsFormatBr),
        retailers: br2array(data.retailersFormatBr)
    });
};

export function fetchSmartImageSets(search: string) {
    return makeRequestThunk("api/smartimage/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getSmartImageRequest(state, search);
        },
        preRequestFunc: requestSmartImageSets,
        okDispatchFunc: receiveSmartImageSets,
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.result.map(reduceSmartImageSet),
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

export function fetchReplaceImages(
    campaignEntityId: string,
    composition = false
) {
    return makeRequestThunk("api/campaign/", {
        method: METHOD_GET,
        urlPartFunc: function (state: RootState) {
            let urlPart = "/images";
            if (composition) {
                urlPart = "/composedImages";
            }
            return "/" + campaignEntityId + urlPart;
        },
        queryParams: {
            entity: "ImageSet",
            filterCondition: null,
            includeCrossTenantInstances: false,
            maxResults: -1,
            model: "SmartImageSetModel/readFields",
            objectType: "ListQuery",
            propertySelection: null,
            postSortCondition: null,
            startIndex: 0
        },
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.map(reduceSmartImageSet),
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

export function uploadImage(
    url: string,
    file: File,
    name: string,
    addToLibrary = false,
    promotionIds: string[] = [],
    imageMaxHeight: number | null = null,
    imageMaxWidth: number | null = null,
    hideSuccess = true
) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("displayName", name);
    // @ts-expect-error - non-string form body
    formData.append("addToLibrary", addToLibrary);
    if (promotionIds && promotionIds.length > 0) {
        // @ts-expect-error - non-string form body
        formData.append("promotionIds", promotionIds);
    }
    if (imageMaxHeight) {
        // @ts-expect-error - non-string form body
        formData.append("maxHeight", imageMaxHeight);
    }
    if (imageMaxWidth) {
        // @ts-expect-error - non-string form body
        formData.append("maxWidth", imageMaxWidth);
    }
    return makeRequestThunk(url, {
        method: METHOD_POST,
        urlPartFunc: function (state: RootState) {
            return "?requestSource=react";
        },
        bodyType: BODY_TYPE_FILE,
        body: formData,
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            if (!hideSuccess) {
                const successMessage = {
                    resourceKey: "promotion.image_upload_success"
                };
                actions.push(success(successMessage));
            }
            return actions;
        },
        okResultFunc: (json: Json, state: RootState): any => {
            return json;
        },
        showLoadMask: true
    });
}

export function replaceImage(
    campaignEntityId: string,
    replacementImageSetEntityId: string,
    originalImageSetEntityId: string
) {
    return makeRequestThunk("api/campaign/images/change", {
        method: METHOD_POST,
        body: {
            objectType: "ChangeCampaignImageRequest",
            campaignId: campaignEntityId,
            setTo: replacementImageSetEntityId,
            toChange: [originalImageSetEntityId]
        },
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const successMessage = {
                resourceKey: "image.replace_image_success"
            };
            actions.push(resetCanNotLeave());
            // reset campaign so CampaignView calls fetchCampaign
            actions.push(resetCampaign());
            actions.push(
                campaignOffersPage(
                    get(state, "campaign.entityIdWithoutVersion")
                )
            );
            actions.push(success(successMessage));
            return actions;
        },
        showLoadMask: true
    });
}
