import * as React from "react";
import type { TFunction } from "i18next";
import styled from "styled-components/macro";
import Button, {
    BUTTON_TYPE_PRIMARY,
    BUTTON_TYPE_SECONDARY
} from "common/components/Button";
import Checkbox from "common/components/Checkbox";
import Flexbox from "common/components/styled/Flexbox";
import { ButtonBar } from "common/components/styled/Form";
import Header from "common/components/styled/Header";
import HorizontalSpacer from "common/components/styled/HorizontalSpacer";
import Toolbar from "common/components/styled/Toolbar";
import theme from "common/components/theme";
import { composeTrackingComponentLabel } from "common/util/tracking";
import type { TrackingComponentLabel } from "common/util/trackingEvents";

const SPACER_HEIGHT = 8;
const HEADER_HEIGHT = 72;

export const FULL_PAGE_HEADER_HEIGHT = SPACER_HEIGHT + HEADER_HEIGHT;

const FullPageHeaderContainer = styled.div`
    border-top: 6px solid ${theme.primaryBanner};
`;

FullPageHeaderContainer.displayName = "FullPageHeaderContainer";

type IconAndHeaderProps = {
    header: string;
    img: React.ReactNode;
};

export const IconAndHeader = (props: IconAndHeaderProps) => {
    const { header, img } = props;
    return (
        <Flexbox alignItems={"center"} justifyContent={"center"}>
            {img}
            <HorizontalSpacer cssWidth={SPACER_HEIGHT + "px"} />
            <Header>{header}</Header>
        </Flexbox>
    );
};

type FullPageHeaderProps = {
    children: React.ReactNode;
};

type FullPageHeaderButtonsProps = {
    createAnother?: boolean;
    customCancelLabel?: string | null;
    customLabel?: string | null;
    disableCreateAnother?: boolean;
    disableSave?: boolean;
    hideCancel?: boolean;
    onCancelClick: NoArgsHandler;
    onChangeCreateAnother?: (
        event: React.SyntheticEvent<HTMLInputElement>
    ) => void;
    onEditPage: boolean;
    onSubmit?: (event: React.SyntheticEvent<any>) => void;
    t: TFunction;
    trackingComponentLabel: TrackingComponentLabel;
} & typeof defaultProps;

const defaultProps = {
    createAnother: false
};

export const FullPageHeaderButtons = (props: FullPageHeaderButtonsProps) => {
    const {
        createAnother,
        customCancelLabel,
        customLabel,
        disableCreateAnother,
        disableSave,
        hideCancel,
        onCancelClick,
        onChangeCreateAnother,
        onEditPage,
        onSubmit,
        t,
        trackingComponentLabel
    } = props;

    const buttonText = customLabel
        ? customLabel
        : onEditPage
        ? t("common:general.save")
        : t("common:general.create");

    return (
        <ButtonBar>
            {!disableCreateAnother && !onEditPage && onChangeCreateAnother && (
                <Checkbox
                    checked={createAnother}
                    label={t("common:administration.create_another")}
                    name="createAnother"
                    onChange={onChangeCreateAnother}
                    tabIndex={200}
                />
            )}
            {!hideCancel && (
                <Button
                    marginLeft="20px"
                    onClick={onCancelClick}
                    text={
                        customCancelLabel
                            ? customCancelLabel
                            : t("common:general.cancel")
                    }
                    tabIndex={201}
                    trackingComponentLabel={composeTrackingComponentLabel([
                        FullPageHeader.displayName,
                        trackingComponentLabel,
                        "Cancel"
                    ])}
                    type={BUTTON_TYPE_SECONDARY}
                />
            )}
            {onSubmit && (
                <Button
                    disabled={!!disableSave}
                    marginLeft="8px"
                    onClick={onSubmit}
                    tabIndex={202}
                    text={buttonText}
                    trackingComponentLabel={composeTrackingComponentLabel([
                        FullPageHeader.displayName,
                        trackingComponentLabel,
                        "Submit"
                    ])}
                    type={BUTTON_TYPE_PRIMARY}
                />
            )}
        </ButtonBar>
    );
};

FullPageHeaderButtons.displayName = "FullPageHeaderButtons";

const FullPageHeader = (props: FullPageHeaderProps) => {
    const ToolbarContent = (
        <Flexbox
            alignItems={"center"}
            cssHeight={"72px"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            margin={"0 16px"}
        >
            {props.children}
        </Flexbox>
    );
    return (
        <FullPageHeaderContainer>
            <Toolbar cssHeight={HEADER_HEIGHT + "px"}>{ToolbarContent}</Toolbar>
        </FullPageHeaderContainer>
    );
};

FullPageHeader.displayName = "FullPageHeader";

export default FullPageHeader;
