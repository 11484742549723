import update from "immutability-helper";
import get from "lodash.get";
import type { AnyAction } from "redux";
import noAction from "common/util/noAction";
import {
    LOAD_SAVED_REPORT,
    RECEIVE_REPORTS_DATA_TABLE,
    RECEIVE_REPORTS_TREND_CHART,
    RECEIVE_SAVED_REPORT,
    RECEIVE_SAVED_REPORTS,
    REQUEST_REPORTS_DATA_TABLE,
    REQUEST_REPORTS_TREND_CHART,
    REQUEST_SAVED_REPORTS,
    RESET_REPORT_STATE,
    UPDATE_REPORT_STYLE,
    UPDATE_REPORT_TYPE,
    UPDATE_REPORTS_DATA_TABLE_AGGREGATES,
    UPDATE_REPORTS_DATA_TABLE_FILTERS,
    UPDATE_REPORTS_DATA_TABLE_SEARCH,
    UPDATE_REPORTS_DATA_TABLE_SORT,
    UPDATE_REPORTS_STACKED_BAR_FILTERS,
    UPDATE_REPORTS_TREND_DATE,
    UPDATE_REPORTS_TREND_SERIES,
    UPDATE_REPORT_TYPE_FILTER_SAVED_REPORTS,
    UPDATE_SEARCH_SAVED_REPORTS,
    UPDATE_SORT_SAVED_REPORTS,
    UPDATE_START_INDEX_SAVED_REPORTS
} from "reports/state/reportsActions";
import {
    RECEIVE_REPORTS_TAKE_RATE_STACKED_BAR_CHART,
    REQUEST_REPORTS_TAKE_RATE_STACKED_BAR_CHART,
    UPDATE_TAKE_RATE_STACKED_BAR_VIEW_BY
} from "reports/takeRate/state/takeRateActions";
import type { ReportsState } from "reports/state/reportsStateUtils";
import {
    defaultState,
    reduceSavedReport,
    reduceTrendChart
} from "reports/state/reportsStateUtils";
import {
    CLEAR_SUBSTITUTION_COMPARISON_DATA,
    CLEAR_SUBSTITUTION_SUMMARY_DATA,
    RECEIVE_SUBSTITUTION_COMPARISON_CHART,
    RECEIVE_SUBSTITUTION_SUMMARY_CHARTS,
    RECEIVE_SUBSTITUTION_SUMMARY_PRODUCT_BREAKDOWN,
    REQUEST_SUBSTITUTION_COMPARISON_CHART,
    REQUEST_SUBSTITUTION_SUMMARY_CHARTS,
    REQUEST_SUBSTITUTION_SUMMARY_PRODUCT_BREAKDOWN,
    UPDATE_SUBSTITUTION_COMPARISON_BENCHMARK_CATEGORIES,
    UPDATE_SUBSTITUTION_SENTENCE_AGGREGATION,
    UPDATE_SUBSTITUTION_SENTENCE_CATEGORIES,
    UPDATE_SUBSTITUTION_SENTENCE_DATE,
    UPDATE_SUBSTITUTION_SENTENCE_RETAILERS,
    UPDATE_SUBSTITUTION_SENTENCE_SELECTED_ITEM,
    UPDATE_SUBSTITUTION_SENTENCE_SELECTED_ITEMS,
    UPDATE_SUBSTITUTION_SUMMARY_CHART_TYPE,
    UPDATE_SUBSTITUTION_SUMMARY_SUBSTITUTION_TYPE,
    UPDATE_SUBSTITUTION_SUMMARY_VIEW_BY,
    VIEW_SUBSTITUTION_COMPARISON
} from "reports/substitution/state/substitutionActions";
import {
    defaultSubstitutionComparisonState,
    defaultSubstitutionSummaryState,
    reduceSubstitutionComparisonChart,
    reduceSubstitutionSummaryCharts,
    reduceSubstitutionSummaryProductBreakdown,
    reduceViewSubstitutionComparison,
    SUBSTITUTION,
    SUBSTITUTION_COMPARISON,
    SUBSTITUTION_SUMMARY
} from "reports/substitution/state/substitutionStateUtils";
import {
    TAKE_RATE,
    TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON
} from "reports/takeRate/state/takeRateStateUtils";

const reports = (
    state: ReportsState = defaultState,
    action: AnyAction = noAction
): ReportsState => {
    const reportType = get(state, "reportType");
    const reportView = get(state, "reportView.value", "");
    switch (action.type) {
        case RECEIVE_REPORTS_DATA_TABLE:
            return update(state, {
                [reportType]: {
                    [reportView]: {
                        dataTable: {
                            comparisonEndDate: {
                                $set: get(
                                    action,
                                    "json.comparisonEndDate",
                                    null
                                )
                            },
                            comparisonStartDate: {
                                $set: get(
                                    action,
                                    "json.comparisonStartDate",
                                    null
                                )
                            },
                            endDate: {
                                $set: get(action, "json.endDate", null)
                            },
                            startDate: {
                                $set: get(action, "json.startDate", null)
                            },
                            totalCount: {
                                $set: get(action, "json.rows", []).length
                            }
                        }
                    }
                }
            });
        case RECEIVE_REPORTS_TREND_CHART:
            return update(state, {
                [reportType]: {
                    [reportView]: {
                        trend: {
                            chart: {
                                $set: reduceTrendChart(
                                    reportType,
                                    action.json,
                                    action.series
                                )
                            }
                        }
                    }
                }
            });
        case REQUEST_REPORTS_DATA_TABLE:
            return state;
        case REQUEST_REPORTS_TREND_CHART:
            return state;
        case RESET_REPORT_STATE:
            return Object.assign({}, defaultState, {
                savedReports: {
                    ...defaultState.savedReports,
                    reportTypes: state.savedReports.reportTypes
                }
            });
        case UPDATE_REPORT_STYLE:
            return update(state, {
                [reportType]: {
                    [reportView]: {
                        reportStyle: {
                            $set: action.reportStyle
                        }
                    }
                }
            });
        case UPDATE_REPORT_TYPE:
            return Object.assign({}, state, {
                reportType: action.reportType,
                reportView: action.reportView
            });
        case UPDATE_REPORTS_DATA_TABLE_AGGREGATES:
            return update(state, {
                [reportType]: {
                    [reportView]: {
                        dataTable: {
                            aggregates: {
                                $set: action.aggregates
                            }
                        }
                    }
                }
            });
        case UPDATE_REPORTS_DATA_TABLE_FILTERS:
            return update(state, {
                [reportType]: {
                    [reportView]: {
                        dataTable: {
                            filters: {
                                $set: action.filters
                            }
                        }
                    }
                }
            });
        case UPDATE_REPORTS_DATA_TABLE_SEARCH:
            return update(state, {
                [reportType]: {
                    [reportView]: {
                        dataTable: {
                            search: {
                                $set: action.search
                            }
                        }
                    }
                }
            });
        case UPDATE_REPORTS_DATA_TABLE_SORT:
            return update(state, {
                [reportType]: {
                    [reportView]: {
                        dataTable: {
                            sortBy: {
                                $set: action.sortBy
                            },
                            sortOrder: {
                                $set: action.sortOrder
                            }
                        }
                    }
                }
            });
        case UPDATE_REPORTS_STACKED_BAR_FILTERS:
            return update(state, {
                [reportType]: {
                    [reportView]: {
                        stackedBar: {
                            filters: {
                                $set: action.filters
                            }
                        }
                    }
                }
            });
        case UPDATE_REPORTS_TREND_DATE:
            return update(state, {
                [reportType]: {
                    [reportView]: {
                        trend: {
                            configuration: {
                                date: {
                                    $set: action.date
                                }
                            }
                        }
                    }
                }
            });
        case UPDATE_REPORTS_TREND_SERIES:
            return update(state, {
                [reportType]: {
                    [reportView]: {
                        trend: {
                            configuration: {
                                series: {
                                    $set: action.series
                                }
                            }
                        }
                    }
                }
            });
        // Take rate report reducers
        case REQUEST_REPORTS_TAKE_RATE_STACKED_BAR_CHART:
            return state;
        case RECEIVE_REPORTS_TAKE_RATE_STACKED_BAR_CHART:
            return update(state, {
                [TAKE_RATE]: {
                    [TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON]: {
                        stackedBar: {
                            bars: { $set: action.json.bars },
                            endDate: { $set: action.json.endDate },
                            meetsAggregationRequirement: {
                                $set: !!action.json.meetsAggregationRequirement
                            },
                            startDate: { $set: action.json.startDate }
                        }
                    }
                }
            });
        case UPDATE_TAKE_RATE_STACKED_BAR_VIEW_BY: {
            return update(state, {
                [TAKE_RATE]: {
                    [TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON]: {
                        viewBy: {
                            $set: action.viewBy
                        }
                    }
                }
            });
        }
        // Saved reports reducers
        case LOAD_SAVED_REPORT:
            return reduceSavedReport(action.json, state);
        case RECEIVE_SAVED_REPORT:
            return Object.assign({}, state, {
                savedReport: get(action, "json")
            });
        case RECEIVE_SAVED_REPORTS:
            return Object.assign({}, state, {
                savedReports: {
                    ...state.savedReports,
                    totalCount: get(action, "json.totalCount")
                }
            });
        case REQUEST_SAVED_REPORTS:
            return state;
        case UPDATE_REPORT_TYPE_FILTER_SAVED_REPORTS:
            return Object.assign({}, state, {
                savedReports: {
                    ...state.savedReports,
                    reportTypes: action.reportTypes
                }
            });
        case UPDATE_SEARCH_SAVED_REPORTS:
            return Object.assign({}, state, {
                savedReports: { ...state.savedReports, search: action.search }
            });
        case UPDATE_SORT_SAVED_REPORTS:
            return Object.assign({}, state, {
                savedReports: {
                    ...state.savedReports,
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder
                }
            });
        case UPDATE_START_INDEX_SAVED_REPORTS:
            return Object.assign({}, state, {
                savedReports: {
                    ...state.savedReports,
                    startIndex: action.startIndex
                }
            });
        // Common substitution sentence reducers
        case UPDATE_SUBSTITUTION_SENTENCE_AGGREGATION: {
            return update(state, {
                [SUBSTITUTION]: {
                    [reportView]: {
                        aggregation: {
                            $set: action.aggregation
                        }
                    }
                }
            });
        }
        case UPDATE_SUBSTITUTION_SENTENCE_CATEGORIES: {
            return update(state, {
                [SUBSTITUTION]: {
                    [reportView]: {
                        categories: {
                            $set: action.categories
                        }
                    }
                }
            });
        }
        case UPDATE_SUBSTITUTION_SENTENCE_DATE: {
            return update(state, {
                [SUBSTITUTION]: {
                    [reportView]: {
                        date: {
                            $set: action.date
                        }
                    }
                }
            });
        }
        case UPDATE_SUBSTITUTION_SENTENCE_RETAILERS: {
            return update(state, {
                [SUBSTITUTION]: {
                    [reportView]: {
                        retailers: {
                            $set: action.retailers
                        }
                    }
                }
            });
        }
        case UPDATE_SUBSTITUTION_SENTENCE_SELECTED_ITEM: {
            return update(state, {
                [SUBSTITUTION]: {
                    [reportView]: {
                        selectedItem: {
                            $set: action.selectedItem
                        }
                    }
                }
            });
        }
        case UPDATE_SUBSTITUTION_SENTENCE_SELECTED_ITEMS: {
            return update(state, {
                [SUBSTITUTION]: {
                    [reportView]: {
                        selectedItems: {
                            $set: action.selectedItems
                        }
                    }
                }
            });
        }
        // Substitution report reducers
        case CLEAR_SUBSTITUTION_COMPARISON_DATA:
            return update(state, {
                [SUBSTITUTION]: {
                    [SUBSTITUTION_COMPARISON]: {
                        benchmarkData: {
                            $set:
                                defaultSubstitutionComparisonState.benchmarkData
                        },
                        itemData: {
                            $set: defaultSubstitutionComparisonState.itemData
                        }
                    }
                }
            });
        case CLEAR_SUBSTITUTION_SUMMARY_DATA:
            return update(state, {
                [SUBSTITUTION]: {
                    [SUBSTITUTION_SUMMARY]: {
                        chartData: {
                            $set: defaultSubstitutionSummaryState.chartData
                        },
                        data: {
                            $set: defaultSubstitutionSummaryState.data
                        }
                    }
                }
            });
        case RECEIVE_SUBSTITUTION_COMPARISON_CHART: {
            const data = reduceSubstitutionComparisonChart(action.json);
            return update(state, {
                [SUBSTITUTION]: {
                    [SUBSTITUTION_COMPARISON]: {
                        benchmarkData: {
                            $set: data.benchmarkData
                        },
                        itemData: {
                            $set: data.itemData
                        }
                    }
                }
            });
        }
        case RECEIVE_SUBSTITUTION_SUMMARY_CHARTS:
            return update(state, {
                [SUBSTITUTION]: {
                    [SUBSTITUTION_SUMMARY]: {
                        chartData: {
                            $set: reduceSubstitutionSummaryCharts(action.json)
                        }
                    }
                }
            });
        case RECEIVE_SUBSTITUTION_SUMMARY_PRODUCT_BREAKDOWN:
            return update(state, {
                [SUBSTITUTION]: {
                    [SUBSTITUTION_SUMMARY]: {
                        data: {
                            $set: reduceSubstitutionSummaryProductBreakdown(
                                action.json
                            )
                        }
                    }
                }
            });
        case REQUEST_SUBSTITUTION_COMPARISON_CHART:
            return state;
        case REQUEST_SUBSTITUTION_SUMMARY_CHARTS:
            return state;
        case REQUEST_SUBSTITUTION_SUMMARY_PRODUCT_BREAKDOWN:
            return state;
        case UPDATE_SUBSTITUTION_COMPARISON_BENCHMARK_CATEGORIES: {
            return update(state, {
                [SUBSTITUTION]: {
                    [SUBSTITUTION_COMPARISON]: {
                        benchmarkCategories: {
                            $set: action.categories
                        }
                    }
                }
            });
        }
        case UPDATE_SUBSTITUTION_SUMMARY_CHART_TYPE: {
            return update(state, {
                [SUBSTITUTION]: {
                    [SUBSTITUTION_SUMMARY]: {
                        substitutionChartType: {
                            $set: action.substitutionChartType
                        }
                    }
                }
            });
        }
        case UPDATE_SUBSTITUTION_SUMMARY_SUBSTITUTION_TYPE: {
            return update(state, {
                [SUBSTITUTION]: {
                    [SUBSTITUTION_SUMMARY]: {
                        substitutionType: {
                            $set: action.substitutionType
                        }
                    }
                }
            });
        }
        case UPDATE_SUBSTITUTION_SUMMARY_VIEW_BY: {
            return update(state, {
                [SUBSTITUTION]: {
                    [SUBSTITUTION_SUMMARY]: {
                        viewBy: {
                            $set: action.viewBy
                        }
                    }
                }
            });
        }
        case VIEW_SUBSTITUTION_COMPARISON: {
            return reduceViewSubstitutionComparison(state);
        }
        default:
            return state;
    }
};

export default reports;
