import {
    defaultCreatorModifierState,
    reduceCreatorModifier
} from "common/util/object";

// Const for column ids
export const USER_TEAM = "userTeam";

// Sort by
export const USER_ENABLED = "enabled";
export const USER_ENTITY_ID = "entityId";
export const USER_FIRSTNAME = "firstName";
export const USER_FULLNAME = "fullName";
export const USER_LASTNAME = "lastName";
export const USER_TEAM_NAME = "team.name";
export const USER_USERNAME = "username";

export type PersistUser = {
    entityId?: string;
    team: {
        entityId: string;
        objectType: string;
    };
} & Omit<User, "entityId" | "fullName">;

export type GridUser = {
    team: NameEntityIdType;
} & User;

export type UsersQueryResult = {
    result: GridUser[];
} & ListQueryResult;

export type UserState = {
    team: NameEntityIdType & {
        teamType: { description: string; name: string };
    };
} & User &
    CreatorModifierData;

type UserTeam = {
    teamType: { description: string; name: string };
} & NameEntityIdType;

export const defaultState: UserState = {
    email: "",
    enabled: true,
    entityId: "",
    firstName: "",
    fullName: "",
    lastName: "",
    objectType: "",
    team: {
        entityId: "",
        name: "",
        teamType: {
            description: "",
            name: ""
        }
    },
    username: "",
    ...defaultCreatorModifierState
};

const reduceTeam = (data: any): UserTeam => {
    return {
        entityId: data.team.entityId,
        name: data.team.name,
        teamType: {
            description: data.team.teamType.description?.value ?? "",
            name: data.team.teamType.name ?? ""
        }
    };
};

export const reduceUser = (data: GridUser): UserState => {
    const creatorModifier = reduceCreatorModifier(data);
    return {
        email: data.email,
        enabled: data.enabled,
        entityId: data.entityId,
        firstName: data.firstName,
        fullName: data.fullName,
        lastName: data.lastName,
        objectType: data.objectType,
        team: reduceTeam(data),
        username: data.username,
        ...creatorModifier
    };
};
