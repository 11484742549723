import styled from "styled-components/macro";
import {
    flexboxProps,
    marginProps,
    paddingBlockProps,
    paddingInlineProps,
    paddingProps,
    sizeProps
} from "common/components/styled/util";
import theme from "common/components/theme";

type FieldSetWithHeaderProps = {
    fontSize?: string;
} & flexboxPropsType &
    marginPropsType &
    paddingBlockPropsType &
    paddingInlinePropsType &
    paddingPropsType &
    sizePropsType;

// Wrap title text with divider header to style
const FieldSetWithHeader = styled.fieldset<FieldSetWithHeaderProps>`
    background-color: ${theme.background};
    border: solid 1px ${theme.border};
    border-radius: 4px;
    padding-block-start: 10px;
    padding-block-end: 10px;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    position: relative;

    ${flexboxProps}
    ${marginProps}
    ${paddingBlockProps}
    ${paddingInlineProps}
    ${paddingProps}
    ${sizeProps}

    & > legend {
        color: ${theme.defaultGreyText};
        font-size: ${props => (props.fontSize ? props.fontSize : "16px")};
    }
`;
FieldSetWithHeader.displayName = "FieldSetWithHeader";

export default FieldSetWithHeader;
