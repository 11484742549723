import get from "lodash.get";
import type { AnyAction } from "redux";
import noAction from "common/util/noAction";
import {
    defaultCreatorModifierState,
    getEntityIdWithoutVersion
} from "common/util/object";
import { ASCENDING } from "common/util/query";
import { GUIDELINE_FIELD_NAME } from "administration/guidelines/state/guidelineActions";
import {
    RECEIVE_GUIDELINE_COLLECTION,
    REQUEST_GUIDELINE_COLLECTION,
    RECEIVE_GUIDELINE_COLLECTIONS,
    REQUEST_GUIDELINE_COLLECTIONS,
    UPDATE_START_INDEX_GUIDELINE_COLLECTIONS,
    UPDATE_SORT_GUIDELINE_COLLECTIONS
} from "administration/guidelines/state/guidelineCollectionsActions";
import {
    ALL_GUIDELINE_COLLECTION_STATUS,
    reduceGuidelineCollection
} from "administration/guidelines/state/guidelineStateUtils";
import type {
    GuidelineCollectionStatus,
    GuidelineListState
} from "administration/guidelines/state/guidelineStateUtils";
import {
    RECEIVE_GUIDELINES,
    REQUEST_GUIDELINES,
    UPDATE_START_INDEX_GUIDELINES,
    UPDATE_SORT_GUIDELINES,
    UPDATE_SEARCH_GUIDELINES
} from "administration/guidelines/state/guidelinesActions";
import {
    GUIDELINE_COLLECTION_EDIT_PAGE,
    GUIDELINE_COLLECTION_PAGE
} from "administration/state/pageActions";

type GuidelineCollectionObject = {
    endDate: string | undefined | null;
    entityId: string;
    name: string;
    startDate: string;
    status: GuidelineCollectionStatus;
} & CreatorModifierData;

export type GuidelineCollectionState = {
    collection?: GuidelineCollectionObject | null;
    collectionId: string;
    endDate?: string | null;
    entityId: string;
    guidelines: GuidelineListState[];
    name: string;
    search: string;
    sortBy: string;
    sortOrder: SortOrder;
    startDate: string;
    startIndex: number;
    status: any;
    totalCount: number;
} & CreatorModifierData;

type GuidelineCollectionsState = {
    options: OptionType[];
    sortBy: string;
    sortOrder: SortOrder;
    startIndex: number;
    statusFilter: GuidelineCollectionStatus;
    totalCount: number;
};

type GuidelinesState = {
    guidelineCollection: GuidelineCollectionState;
    guidelineCollections: GuidelineCollectionsState;
};

const defaultQueryValues = {
    startIndex: 0,
    totalCount: -1
};

export const defaultState: GuidelinesState = {
    guidelineCollections: {
        ...defaultQueryValues,
        options: [],
        sortBy: GUIDELINE_FIELD_NAME,
        sortOrder: ASCENDING,
        statusFilter: ALL_GUIDELINE_COLLECTION_STATUS
    },
    guidelineCollection: {
        ...defaultCreatorModifierState,
        ...defaultQueryValues,
        collectionId: "",
        endDate: null,
        entityId: "",
        guidelines: [],
        name: "",
        search: "",
        sortBy: "",
        sortOrder: ASCENDING,
        startDate: "",
        status: null
    }
};

export const reduceGuidelines = (state: any, obj: any): GuidelinesState => {
    return Object.assign({}, state, {
        guidelineCollection: {
            ...state.guidelineCollection,
            startIndex: get(obj, "query.startIndex"),
            totalCount: obj.totalCount
        }
    });
};

const reduceGuidelineCollections = (state: any, obj: any) => {
    return {
        options: obj.result.map((collection: any) => ({
            label: collection.name,
            value: getEntityIdWithoutVersion(collection.entityId)
        })),
        sortBy: get(state, "guidelineCollections.sortBy"),
        sortOrder: get(state, "guidelineCollections.sortOrder"),
        statusFilter: get(state, "guidelineCollections.statusFilter"),
        startIndex: get(obj, "query.startIndex"),
        totalCount: obj.totalCount
    };
};
const guidelines = (
    state: GuidelinesState = defaultState,
    action: AnyAction = noAction
): GuidelinesState => {
    switch (action.type) {
        case GUIDELINE_COLLECTION_EDIT_PAGE:
            return Object.assign({}, state, {
                guidelineCollection: {
                    ...defaultState.guidelineCollection,
                    entityId: get(action, "payload.collectionId")
                }
            });
        case GUIDELINE_COLLECTION_PAGE:
            return Object.assign({}, state, {
                guidelineCollection: {
                    ...defaultQueryValues,
                    collectionId: get(action, "payload.entityId"),
                    sortBy: GUIDELINE_FIELD_NAME,
                    sortOrder: ASCENDING
                }
            });
        case REQUEST_GUIDELINES:
            return state;
        case RECEIVE_GUIDELINES:
            return Object.assign(
                {},
                state,
                reduceGuidelines(state, action.guidelines)
            );
        case UPDATE_SORT_GUIDELINES:
            return Object.assign({}, state, {
                guidelineCollection: Object.assign(
                    {},
                    state.guidelineCollection,
                    {
                        sortBy: action.sortBy,
                        sortOrder: action.sortOrder
                    }
                )
            });
        case UPDATE_SORT_GUIDELINE_COLLECTIONS:
            return Object.assign({}, state, {
                guidelineCollections: Object.assign(
                    {},
                    state.guidelineCollections,
                    {
                        sortBy: action.sortBy,
                        sortOrder: action.sortOrder
                    }
                )
            });
        case UPDATE_SEARCH_GUIDELINES:
            return Object.assign({}, state, {
                guidelineCollection: Object.assign(
                    {},
                    state.guidelineCollection,
                    {
                        search: action.search
                    }
                )
            });
        case UPDATE_START_INDEX_GUIDELINES:
            return Object.assign({}, state, {
                guidelineCollection: Object.assign(
                    {},
                    state.guidelineCollection,
                    {
                        startIndex: action.startIndex
                    }
                )
            });
        case UPDATE_START_INDEX_GUIDELINE_COLLECTIONS:
            return Object.assign({}, state, {
                guidelineCollections: Object.assign(
                    {},
                    state.guidelineCollections,
                    {
                        startIndex: action.startIndex
                    }
                )
            });
        case REQUEST_GUIDELINE_COLLECTIONS:
            return state;
        case RECEIVE_GUIDELINE_COLLECTIONS:
            return Object.assign({}, state, {
                guidelineCollections: reduceGuidelineCollections(
                    state,
                    action.json
                )
            });
        case REQUEST_GUIDELINE_COLLECTION:
            return state;
        case RECEIVE_GUIDELINE_COLLECTION:
            return Object.assign({}, state, {
                guidelineCollection: {
                    ...state.guidelineCollection,
                    ...reduceGuidelineCollection(action.json)
                }
            });
        default:
            return state;
    }
};

export default guidelines;
