import * as React from "react";
import Dropzone from "react-dropzone";
import type { DropzoneRef } from "react-dropzone";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import styled from "styled-components/macro";
import { uploadFile } from "common/shell/state/fileActions";
import {
    composeTrackingComponentLabel,
    getTrackingEventData,
    trackEvent
} from "common/util/tracking";
import { EVENT_NAME_EXECUTE_ACTION } from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";
import type { AppDispatch } from "store";

// File types, 'accept' attribute
// Safest way is to use media types since different browers handle types differently
// There will be some nuances for microsoft file types
export const ALL_FILE_TYPE = undefined;
export const IMAGE_FILE_TYPE = "image/*";

/**
    UploadZone acts as a upload functionality wrapper. You can wrap any element
    and it should be given the upload functionality.
    Props
    - children: the elements you want to wrap to give upload functionality. Since
                the wrapper will have the onclick functionality you can disable on
                click for buttons and wrap the button with the uploadzone
    - uploadUrl: Endpoint to which to upload file to

    State function handlers (Use these to maintain file states across application)
    UploadZone DOES NOT keep track of file states. This should be done through
    the handlers below.
    - clearHandler: runs on uploadzone unmount to clear file state
    - errorHandler: state handler for when upload fails
    - reuploadHandler: initial state handler for reupload
    - stateHandler: initial state handler for first upload
    - successHandler: state handler for successful file upload
 */
type UploadZoneOwnProps = {
    children: React.ReactNode;
    clearHandler?: NoArgsHandler;
    disableClick?: boolean;
    disabled?: boolean;
    disableDrop?: boolean;
    errorHandler?: (uploadId: string) => void;
    fileType?: string | string[] | undefined;
    hideSuccess?: boolean;
    reuploadHandler?: (uploadId: string) => void;
    stateHandler?: (uploadId: string, file: File) => void;
    successHandler?: (uploadId: string, json: Json) => void;
    trackingComponentLabel: TrackingComponentLabel;
    uploadUrl: string;
};

type UploadZoneProps = UploadZoneOwnProps & PropsFromRedux;

type UploadFile = {
    readonly file: File;
    readonly id: string;
};

type UploadZoneState = {
    files: UploadFile[];
    uploadIndex: number;
};

export const UploadContainer = styled.div`
    display: inline-block;

    :focus {
        outline: none;
    }
`;

class UploadZone extends React.Component<UploadZoneProps, UploadZoneState> {
    dropzoneRef: React.RefObject<DropzoneRef>;

    constructor(props: UploadZoneProps) {
        super(props);
        this.dropzoneRef = React.createRef<DropzoneRef>();
        this.state = {
            files: [],
            uploadIndex: 0
        };
    }

    componentWillUnmount() {
        const { clearHandler } = this.props;
        if (clearHandler) {
            clearHandler();
        }
    }

    upload = (files: File[]) => {
        const { stateHandler, trackingComponentLabel } = this.props;
        let id = this.state.uploadIndex;
        const newFiles: UploadFile[] = [];
        files.forEach(file => {
            const uploadId = "upload-" + id.toString();
            id++;
            newFiles.push({ id: uploadId, file: file });
            if (stateHandler) {
                stateHandler(uploadId, file);
            }
            this.uploadFile(file, uploadId);
        });
        this.setState({
            files: this.state.files.concat(newFiles),
            uploadIndex: id
        });
        trackEvent(
            getTrackingEventData(
                "Upload Zone",
                composeTrackingComponentLabel([
                    trackingComponentLabel,
                    "Upload or Import"
                ]),
                EVENT_NAME_EXECUTE_ACTION
            )
        );
    };

    reupload = (uploadId: string) => {
        const { reuploadHandler } = this.props;
        if (reuploadHandler) {
            reuploadHandler(uploadId);
        }
        const uploadFile = this.state.files.find(file => {
            return file.id === uploadId;
        });
        if (uploadFile) {
            this.uploadFile(uploadFile.file, uploadId);
        }
    };

    uploadFile = (file: File, uploadId: string) => {
        const {
            errorHandler,
            hideSuccess,
            successHandler,
            uploadFile,
            uploadUrl
        } = this.props;
        const hideSuccessMessage = hideSuccess ? hideSuccess : false;
        uploadFile(uploadUrl, file, hideSuccessMessage)
            .then(json => {
                if (successHandler) {
                    successHandler(uploadId, json);
                }
            })
            .catch(() => {
                if (errorHandler) {
                    errorHandler(uploadId);
                }
            });
    };

    render() {
        const { children, disableClick, disableDrop, disabled, fileType } =
            this.props;
        const disableZone = disabled || (disableDrop && disableClick);
        const defaultProps = {
            accept: fileType,
            minSize: 1,
            multiple: false,
            onDrop: this.upload
        };
        let uploadZone = (
            <React.Fragment>
                <Dropzone disabled={disableZone} {...defaultProps}>
                    {({ getInputProps, getRootProps }) => {
                        return (
                            <UploadContainer {...getRootProps()}>
                                <input {...getInputProps()} />
                                {children}
                            </UploadContainer>
                        );
                    }}
                </Dropzone>
            </React.Fragment>
        );

        if (disableClick && !disableZone) {
            uploadZone = (
                <React.Fragment>
                    <Dropzone noClick {...defaultProps}>
                        {({ getInputProps, getRootProps }) => {
                            return (
                                <UploadContainer {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {children}
                                </UploadContainer>
                            );
                        }}
                    </Dropzone>
                </React.Fragment>
            );
        }

        if (disableDrop && !disableZone) {
            uploadZone = (
                <React.Fragment>
                    <Dropzone noDrag ref={this.dropzoneRef} {...defaultProps}>
                        {({ getInputProps, getRootProps }) => {
                            return (
                                <UploadContainer
                                    {...getRootProps()}
                                    onClick={() => {
                                        if (this.dropzoneRef.current) {
                                            this.dropzoneRef.current.open();
                                        }
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    {children}
                                </UploadContainer>
                            );
                        }}
                    </Dropzone>
                </React.Fragment>
            );
        }

        return uploadZone;
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        uploadFile: (url: string, file: File, hideSuccess: boolean) => {
            return dispatch(uploadFile(url, file, hideSuccess));
        }
    };
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UploadZone);
