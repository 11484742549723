/*
Pulled from react-virtualized-tree version 3.4.1
This component was modified because it contained deprecated context API
*/
import * as React from "react";
import { createSelector } from "reselect";
import { getFlattenedTree } from "common/filter/reactVirtualizedTree/state/selectors/getFlattenedTree";
import {
    deleteNodeFromTree,
    replaceNodeFromTree,
    getRowIndexFromId
} from "common/filter/reactVirtualizedTree/state/selectors/nodes";
import { UPDATE_TYPE } from "common/filter/reactVirtualizedTree/state/treeStateUtils";
import type {
    TreeNode,
    UpdateNode
} from "common/filter/reactVirtualizedTree/state/treeStateUtils";
import Tree from "common/filter/reactVirtualizedTree/components/Tree";

const DEFAULT_UPDATE_TYPES = {
    [UPDATE_TYPE.DELETE]: deleteNodeFromTree,
    [UPDATE_TYPE.UPDATE]: replaceNodeFromTree
};

const getExtensions = createSelector(
    e => e,
    (extensions = {}) => {
        // external code
        // @ts-expect-error Need to figure out typing of nested objects
        const { updateTypeHandlers = {} } = extensions;

        return {
            updateTypeHandlers: {
                ...DEFAULT_UPDATE_TYPES,
                ...updateTypeHandlers
            }
        };
    }
);

type TreeContainerExtension = {
    updateTypeHandlers: any;
};

type TreeContainerProps = {
    children: React.ReactNode;
    extensions?: TreeContainerExtension;
    nodeMarginLeft: number;
    nodes: TreeNode[];
    onChange: (nodes: TreeNode[]) => void;
    scrollToAlignment?: string;
    scrollToId?: number;
    unfilteredNodes: TreeNode[];
    width?: number;
};

export default class TreeContainer extends React.Component<TreeContainerProps> {
    static defaultProps = {
        nodeMarginLeft: 30
    };

    get nodes() {
        return this.props.unfilteredNodes || this.props.nodes;
    }

    handleChange = ({ node, type }: UpdateNode) => {
        const updatedNodes = getExtensions(
            this.props.extensions
        ).updateTypeHandlers[type](this.nodes, node);

        this.props.onChange(updatedNodes);
    };

    render() {
        const {
            children,
            nodeMarginLeft,
            nodes,
            scrollToAlignment,
            scrollToId,
            width
        } = this.props;
        const flattenedTree = getFlattenedTree(nodes);
        const rowIndex = getRowIndexFromId(flattenedTree, scrollToId);
        return (
            <Tree
                nodeMarginLeft={nodeMarginLeft}
                nodes={flattenedTree}
                onChange={this.handleChange}
                NodeRenderer={children}
                scrollToIndex={rowIndex}
                scrollToAlignment={scrollToAlignment}
                width={width}
            />
        );
    }
}
