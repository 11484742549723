import {
    RESET_ERROR_FOUND,
    SET_ERROR_FOUND
} from "common/shell/state/errorActions";
import type { ErrorActions } from "common/shell/state/errorActions";
import noAction from "common/util/noAction";

export const defaultState = false;

const error = (
    state: boolean = defaultState,
    action: ErrorActions = noAction
): boolean => {
    switch (action.type) {
        case RESET_ERROR_FOUND:
            return defaultState;
        case SET_ERROR_FOUND:
            return true;
        default:
            return state;
    }
};

export default error;
