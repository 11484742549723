import type { AnyAction } from "redux";
import {
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import { getDateStringFromDate } from "common/util/date";
import { GUIDELINE_CREATE_PAGE } from "administration/state/pageActions";
import type { PPGPrintState } from "ppgGuidelines/state/ppgGuidelinesReducers";
import { GUIDELINES_VIEWER_PAGE } from "shell/state/pageActions";
import type { RootState } from "store";

export const CLEAR_PPG_GUIDELINE = "CLEAR_PPG_GUIDELINE";
export const RECEIVE_PPG_GUIDELINE = "RECEIVE_PPG_GUIDELINE";
export const RECEIVE_PPG_GUIDELINE_SEARCH_OPTIONS =
    "RECEIVE_PPG_GUIDELINE_SEARCH_OPTIONS";
export const REQUEST_PPG_GUIDELINE = "REQUEST_PPG_GUIDELINE";
const REQUEST_PPG_GUIDELINE_SEARCH_OPTIONS =
    "REQUEST_PPG_GUIDELINE_SEARCH_OPTIONS";
export const UPDATE_PPG_GUIDELINES_OFFSET = "UPDATE_PPG_GUIDELINES_OFFSET";
export const UPDATE_PPG_GUIDELINES_PRINT_OPTIONS =
    "UPDATE_PPG_GUIDELINES_PRINT_OPTIONS";
export const UPDATE_PPG_GUIDELINES_SORT = "UPDATE_PPG_GUIDELINES_SORT";

const getPPGGuidelineRequestBody = (
    date: Date,
    productId?: string | null,
    retailerId?: string | null,
    regionId?: string | null,
    holidayId?: string | null
) => {
    return (state: RootState) => {
        return {
            objectType: "GuidelineViewerRequest",
            eventDate: getDateStringFromDate(date),
            productId,
            retailerId,
            regionId,
            holidayId
        };
    };
};

// Rowdata is used for preselecting values
export const guidelineCreatePage = (
    collectionId: string,
    rowData?: any | null
): AnyAction => ({
    type: GUIDELINE_CREATE_PAGE,

    payload: {
        collectionId,
        rowData
    }
});

export const ppgGuidelinesPage = (): AnyAction => ({
    type: GUIDELINES_VIEWER_PAGE
});

export const clearPPGGuideline = () => ({
    type: CLEAR_PPG_GUIDELINE
});

export const receivePPGGuideline = (json: any): AnyAction => ({
    type: RECEIVE_PPG_GUIDELINE,
    guidelineCardResponse: json
});

export const receivePPGGuidelineSearchOptions = (json: any): AnyAction => ({
    type: RECEIVE_PPG_GUIDELINE_SEARCH_OPTIONS,
    searchOptions: json
});

export const requestPPGGuideline = (): AnyAction => ({
    type: REQUEST_PPG_GUIDELINE
});

export const requestPPGGuidelineSearchOptions = (): AnyAction => ({
    type: REQUEST_PPG_GUIDELINE_SEARCH_OPTIONS
});

export const updatePPGGuidelinesOffset = (offset: number): AnyAction => ({
    type: UPDATE_PPG_GUIDELINES_OFFSET,
    offset: offset
});

export const updatePPGGuidelinesPrintOptions = (
    printOptions: PPGPrintState
): AnyAction => ({
    type: UPDATE_PPG_GUIDELINES_PRINT_OPTIONS,
    printOptions: printOptions
});

export const updatePPGGuidelinesSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => ({
    type: UPDATE_PPG_GUIDELINES_SORT,
    sortBy,
    sortOrder
});

export function fetchPPGGuideline(
    date: Date,
    ppgId: string,
    retailerId: string,
    regionId?: string | null,
    holidayId?: string | null
) {
    // Send in correct request body
    return makeRequestThunk("api/guideline/view", {
        bodyFunc: getPPGGuidelineRequestBody(
            date,
            ppgId,
            retailerId,
            regionId,
            holidayId
        ),
        method: METHOD_POST,
        preRequestFunc: requestPPGGuideline,
        okDispatchFunc: receivePPGGuideline
    });
}

export function fetchGuidelineSearchOptions(
    showLoadingMasking: boolean,
    date: Date,
    ppgId?: string | null,
    retailerId?: string | null,
    regionId?: string | null,
    holidayId?: string | null
) {
    return makeRequestThunk("/api/guideline/searchoptions", {
        preRequestFunc: requestPPGGuidelineSearchOptions,
        bodyFunc: getPPGGuidelineRequestBody(
            date,
            ppgId,
            retailerId,
            regionId,
            holidayId
        ),
        method: METHOD_POST,
        showLoadMask: showLoadingMasking,
        okDispatchFunc: receivePPGGuidelineSearchOptions
    });
}
