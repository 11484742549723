import update from "immutability-helper";
import { getDateFilter } from "common/reports/state/reportsStateUtils";
import { LAST_6_MONTHS } from "common/util/date";
import type { DateFilter } from "common/util/date";
import type { ReportsState } from "reports/state/reportsStateUtils";
import { CATEGORY_SUBCATEGORY_SEPARATOR } from "shell/state/initReducers";

// Report type
export const SUBSTITUTION = "substitution";

// Report view types, substitution reports
export const SUBSTITUTION_COMPARISON = "SUBSTITUTION_COMPARISON";
export const SUBSTITUTION_SUMMARY = "SUBSTITUTION_SUMMARY";

// Report chart types, should match SubstitutionSummaryChartType
export const SUBSTITUTION_TRENDS = "SUBSTITUTION_TRENDS";
export const SUBSTITUTION_TYPE_FREQUENCY = "SUBSTITUTION_TYPE_FREQUENCY";

// Report chart types, splitting up substitution trends between frequency and total
export const SUBSTITUTION_TRENDS_FREQUENCY_OVER_TIME =
    "SUBSTITUTION_TRENDS_FREQUENCY_OVER_TIME";
export const SUBSTITUTION_TRENDS_TOTAL_OVER_TIME =
    "SUBSTITUTION_TRENDS_TOTAL_OVER_TIME";

export type SubstitutionSummaryChartType =
    | typeof SUBSTITUTION_TRENDS_FREQUENCY_OVER_TIME
    | typeof SUBSTITUTION_TRENDS_TOTAL_OVER_TIME
    | typeof SUBSTITUTION_TYPE_FREQUENCY;

// Aggregation enums, should match SubstitutionReportAggregation
export const AGGREGATE_BY_BRAND = "BRAND";
export const AGGREGATE_BY_PPG = "PPG";
export const AGGREGATE_BY_UPC = "UPC";

export type SubstitutionReportAggregation =
    | typeof AGGREGATE_BY_BRAND
    | typeof AGGREGATE_BY_PPG
    | typeof AGGREGATE_BY_UPC;

// Consts should match SubstitutionReportSubstitutionType
export const SUBBING_IN = "SUBBING_IN";
export const SUBBING_OUT = "SUBBING_OUT";

export type SubstitutionType = typeof SUBBING_IN | typeof SUBBING_OUT;

// Report state
// Substitution sentence state
type SubstitutionSentenceState = {
    aggregation: SubstitutionReportAggregation;
    date: DateFilter;
    retailers: string[];
    selectedItem: OptionTypeOrNullOrUndefined;
    selectedItems: OptionType[];
};

const defaultSubstitutionSentenceState: SubstitutionSentenceState = {
    aggregation: AGGREGATE_BY_UPC,
    date: {
        endDate: "",
        startDate: "",
        time: LAST_6_MONTHS
    },
    retailers: [],
    selectedItem: null,
    selectedItems: []
};

// Substitution Comparison Data Types
export type SubstitutionComparisonData = {
    brandSubstitutionCount: number;
    brandSubstitutionCountFormatted: string;
    brandSubstitutionCountPercent: number;
    brandSubstitutionCountPercentFormatted: string;
    brandSubstitutionSales: number;
    brandSubstitutionSalesFormatted: string;
    brandSubstitutionSalesPercent: number;
    brandSubstitutionSalesPercentFormatted: string;
    competitorSubstitutionCount: number;
    competitorSubstitutionCountFormatted: string;
    competitorSubstitutionCountPercent: number;
    competitorSubstitutionCountPercentFormatted: string;
    competitorSubstitutionSales: number;
    competitorSubstitutionSalesFormatted: string;
    competitorSubstitutionSalesPercent: number;
    competitorSubstitutionSalesPercentFormatted: string;
    endDate: string;
    image: string | null;
    manufacturerSubstitutionCount: number;
    manufacturerSubstitutionCountFormatted: string;
    manufacturerSubstitutionCountPercent: number;
    manufacturerSubstitutionCountPercentFormatted: string;
    manufacturerSubstitutionSales: number;
    manufacturerSubstitutionSalesFormatted: string;
    manufacturerSubstitutionSalesPercent: number;
    manufacturerSubstitutionSalesPercentFormatted: string;
    productName: string;
    productSubname: string;
    refundCount: number;
    refundCountFormatted: string;
    refundCountPercent: number;
    refundCountPercentFormatted: string;
    refundSales: number;
    refundSalesFormatted: string;
    refundSalesPercent: number;
    refundSalesPercentFormatted: string;
    startDate: string;
    totalSubstitutionSales: number;
    totalSubstitutionSalesFormatted: string;
    totalSubstitutionUnits: number;
};

// Substitution Frequeuncy State Types
export type SubstitutionItem = {
    brand: string;
    imgSrc: string;
    name: string;
    packSize: string;
    priceIndex: number;
    priceIndexFormatted: string;
    substitutedCount: number;
    substitutedCountFormatted: string;
    substitutedSales: number;
    substitutedSalesFormatted: string;
    upc: string;
};

export type SubstitutionFrequencyData = {
    brandSubstitutionCount: number;
    brandSubstitutionCountFormatted: string;
    brandSubstitutionCountPercent: number;
    brandSubstitutionCountPercentFormatted: string;
    brandSubstitutionSales: number;
    brandSubstitutionSalesFormatted: string;
    brandSubstitutionSalesPercent: number;
    brandSubstitutionSalesPercentFormatted: string;
    competitorSubstitutionCount: number;
    competitorSubstitutionCountFormatted: string;
    competitorSubstitutionCountPercent: number;
    competitorSubstitutionCountPercentFormatted: string;
    competitorSubstitutionSales: number;
    competitorSubstitutionSalesFormatted: string;
    competitorSubstitutionSalesPercent: number;
    competitorSubstitutionSalesPercentFormatted: string;
    endDate: string | null;
    manufacturerSubstitutionCount: number;
    manufacturerSubstitutionCountFormatted: string;
    manufacturerSubstitutionCountPercent: number;
    manufacturerSubstitutionCountPercentFormatted: string;
    manufacturerSubstitutionSales: number;
    manufacturerSubstitutionSalesFormatted: string;
    manufacturerSubstitutionSalesPercent: number;
    manufacturerSubstitutionSalesPercentFormatted: string;
    refundCount: number;
    refundCountFormatted: string;
    refundCountPercent: number;
    refundCountPercentFormatted: string;
    refundSales: number;
    refundSalesFormatted: string;
    refundSalesPercent: number;
    refundSalesPercentFormatted: string;
    startDate: string | null;
    totalSubstitutionSales: number;
    totalSubstitutionSalesFormatted: string;
    totalSubstitutionUnits: number;
};

const defaultSubstitutionFrequencyData = {
    brandSubstitutionCount: 0,
    brandSubstitutionCountFormatted: "",
    brandSubstitutionCountPercent: 0,
    brandSubstitutionCountPercentFormatted: "",
    brandSubstitutionSales: 0,
    brandSubstitutionSalesFormatted: "",
    brandSubstitutionSalesPercent: 0,
    brandSubstitutionSalesPercentFormatted: "",
    competitorSubstitutionCount: 0,
    competitorSubstitutionCountFormatted: "",
    competitorSubstitutionCountPercent: 0,
    competitorSubstitutionCountPercentFormatted: "",
    competitorSubstitutionSales: 0,
    competitorSubstitutionSalesFormatted: "",
    competitorSubstitutionSalesPercent: 0,
    competitorSubstitutionSalesPercentFormatted: "",
    endDate: null,
    manufacturerSubstitutionCount: 0,
    manufacturerSubstitutionCountFormatted: "",
    manufacturerSubstitutionCountPercent: 0,
    manufacturerSubstitutionCountPercentFormatted: "",
    manufacturerSubstitutionSales: 0,
    manufacturerSubstitutionSalesFormatted: "",
    manufacturerSubstitutionSalesPercent: 0,
    manufacturerSubstitutionSalesPercentFormatted: "",
    refundCount: 0,
    refundCountFormatted: "",
    refundCountPercent: 0,
    refundCountPercentFormatted: "",
    refundSales: 0,
    refundSalesFormatted: "",
    refundSalesPercent: 0,
    refundSalesPercentFormatted: "",
    startDate: null,
    totalSubstitutionSales: 0,
    totalSubstitutionSalesFormatted: "",
    totalSubstitutionUnits: 0
};

// Substitution Comparison Report States
export type SubstitutionComparisonState = {
    benchmarkCategories: string[];
    benchmarkData: SubstitutionComparisonData[];
    categories: string[];
    endDate: string;
    itemData: SubstitutionComparisonData[];
    startDate: string;
} & SubstitutionSentenceState;

export const defaultSubstitutionComparisonState: SubstitutionComparisonState = {
    ...defaultSubstitutionSentenceState,
    aggregation: AGGREGATE_BY_BRAND,
    benchmarkCategories: [],
    benchmarkData: [],
    categories: [],
    endDate: "",
    itemData: [],
    startDate: ""
};

// Substitution Summary Report States
export type SubstitutionSummaryChartDataState = {
    substitutionFrequency: SubstitutionFrequencyData;
    substitutionFrequencyOverTime: SubstitutionFrequencyData[];
};

const defaultSubstitutionSummaryChartDataState: SubstitutionSummaryChartDataState = {
    substitutionFrequency: defaultSubstitutionFrequencyData,
    substitutionFrequencyOverTime: []
};

export type SubstitutionSummaryDataState = {
    baseItem: SubstitutionItem | null;
    endDate: string;
    items: SubstitutionItem[];
    startDate: string;
};

const defaultSubstitutionSummaryDataState: SubstitutionSummaryDataState = {
    baseItem: null,
    endDate: "",
    items: [],
    startDate: ""
};

export type SubstitutionSummaryState = {
    chartData: SubstitutionSummaryChartDataState;
    data: SubstitutionSummaryDataState;
    substitutionChartType: SubstitutionSummaryChartType;
    substitutionType: SubstitutionType;
    viewBy: SubstitutionReportAggregation;
} & SubstitutionSentenceState;

export const defaultSubstitutionSummaryState: SubstitutionSummaryState = {
    ...defaultSubstitutionSentenceState,
    chartData: defaultSubstitutionSummaryChartDataState,
    data: defaultSubstitutionSummaryDataState,
    substitutionChartType: SUBSTITUTION_TYPE_FREQUENCY,
    substitutionType: SUBBING_OUT,
    viewBy: AGGREGATE_BY_UPC
};

// State reducer functions
// Substitution Summary
const reduceSubsitutionItem = (json: Json): SubstitutionItem => {
    return {
        brand: json.brand,
        imgSrc: json.image,
        name: json.name,
        packSize: json.packSize,
        priceIndex: json.priceIndex,
        priceIndexFormatted: json.priceIndexFormatted,
        substitutedCount: json.substitutedCount,
        substitutedCountFormatted: json.substitutedCountFormatted,
        substitutedSales: json.substitutedSales,
        substitutedSalesFormatted: json.substitutedSalesFormatted,
        upc: json.upc
    };
};

export const reduceSubstitutionSummaryProductBreakdown = (
    json: Json
): SubstitutionSummaryDataState => {
    const baseItem = json.baseItem
        ? reduceSubsitutionItem(json.baseItem)
        : null;
    const items = json.items.map(reduceSubsitutionItem);
    return {
        baseItem: baseItem,
        endDate: json.endDate,
        items: items,
        startDate: json.startDate
    };
};

const reduceSubstitutionFrequencyChart = (
    json: Json
): SubstitutionFrequencyData => {
    return {
        brandSubstitutionCount: json.brandSubstitutionCount,
        brandSubstitutionCountFormatted: json.brandSubstitutionCountFormatted,
        brandSubstitutionCountPercent: json.brandSubstitutionCountPercent,
        brandSubstitutionCountPercentFormatted:
            json.brandSubstitutionCountPercentFormatted,
        brandSubstitutionSales: json.brandSubstitutionSales,
        brandSubstitutionSalesFormatted: json.brandSubstitutionSalesFormatted,
        brandSubstitutionSalesPercent: json.brandSubstitutionSalesPercent,
        brandSubstitutionSalesPercentFormatted:
            json.brandSubstitutionSalesPercentFormatted,
        competitorSubstitutionCount: json.competitorSubstitutionCount,
        competitorSubstitutionCountFormatted:
            json.competitorSubstitutionCountFormatted,
        competitorSubstitutionCountPercent:
            json.competitorSubstitutionCountPercent,
        competitorSubstitutionCountPercentFormatted:
            json.competitorSubstitutionCountPercentFormatted,
        competitorSubstitutionSales: json.competitorSubstitutionSales,
        competitorSubstitutionSalesFormatted:
            json.competitorSubstitutionSalesFormatted,
        competitorSubstitutionSalesPercent:
            json.competitorSubstitutionSalesPercent,
        competitorSubstitutionSalesPercentFormatted:
            json.competitorSubstitutionSalesPercentFormatted,
        endDate: json.endDate,
        manufacturerSubstitutionCount: json.manufacturerSubstitutionCount,
        manufacturerSubstitutionCountFormatted:
            json.manufacturerSubstitutionCountFormatted,
        manufacturerSubstitutionCountPercent:
            json.manufacturerSubstitutionCountPercent,
        manufacturerSubstitutionCountPercentFormatted:
            json.manufacturerSubstitutionCountPercentFormatted,
        manufacturerSubstitutionSales: json.manufacturerSubstitutionSales,
        manufacturerSubstitutionSalesFormatted:
            json.manufacturerSubstitutionSalesFormatted,
        manufacturerSubstitutionSalesPercent:
            json.manufacturerSubstitutionSalesPercent,
        manufacturerSubstitutionSalesPercentFormatted:
            json.manufacturerSubstitutionSalesPercentFormatted,
        refundCount: json.refundCount,
        refundCountFormatted: json.refundCountFormatted,
        refundCountPercent: json.refundCountPercent,
        refundCountPercentFormatted: json.refundCountPercentFormatted,
        refundSales: json.refundSales,
        refundSalesFormatted: json.refundSalesFormatted,
        refundSalesPercent: json.refundSalesPercent,
        refundSalesPercentFormatted: json.refundSalesPercentFormatted,
        startDate: json.startDate,
        totalSubstitutionSales: json.totalSubstitutionSales,
        totalSubstitutionSalesFormatted: json.totalSubstitutionSalesFormatted,
        totalSubstitutionUnits:
            json.brandSubstitutionCount +
            json.competitorSubstitutionCount +
            json.manufacturerSubstitutionCount +
            json.refundCount
    };
};

const findChartType = (json: Json, chartType: string): Json[] => {
    return (
        json.data.find(
            (chart: { chartType: { value: string } }) =>
                chart.chartType.value === chartType
        )?.data ?? []
    );
};

export const reduceSubstitutionSummaryCharts = (json: Json) => {
    const substitutionFrequencyData = findChartType(
        json,
        SUBSTITUTION_TYPE_FREQUENCY
    );
    const substitutionFrequencyOverTimeData = findChartType(
        json,
        SUBSTITUTION_TRENDS
    );
    return {
        substitutionFrequency:
            substitutionFrequencyData.map(
                reduceSubstitutionFrequencyChart
            )?.[0] || defaultSubstitutionFrequencyData,
        substitutionFrequencyOverTime: substitutionFrequencyOverTimeData.map(
            reduceSubstitutionFrequencyChart
        )
    };
};

// Substitution Comparison
const reduceSubstitutionComparisonItem = (
    json: Json
): SubstitutionComparisonData => {
    return {
        brandSubstitutionCount: json.brandSubstitutionCount,
        brandSubstitutionCountFormatted: json.brandSubstitutionCountFormatted,
        brandSubstitutionCountPercent: json.brandSubstitutionCountPercent,
        brandSubstitutionCountPercentFormatted:
            json.brandSubstitutionCountPercentFormatted,
        brandSubstitutionSales: json.brandSubstitutionSales,
        brandSubstitutionSalesFormatted: json.brandSubstitutionSalesFormatted,
        brandSubstitutionSalesPercent: json.brandSubstitutionSalesPercent,
        brandSubstitutionSalesPercentFormatted:
            json.brandSubstitutionSalesPercentFormatted,
        competitorSubstitutionCount: json.competitorSubstitutionCount,
        competitorSubstitutionCountFormatted:
            json.competitorSubstitutionCountFormatted,
        competitorSubstitutionCountPercent:
            json.competitorSubstitutionCountPercent,
        competitorSubstitutionCountPercentFormatted:
            json.competitorSubstitutionCountPercentFormatted,
        competitorSubstitutionSales: json.competitorSubstitutionSales,
        competitorSubstitutionSalesFormatted:
            json.competitorSubstitutionSalesFormatted,
        competitorSubstitutionSalesPercent:
            json.competitorSubstitutionSalesPercent,
        competitorSubstitutionSalesPercentFormatted:
            json.competitorSubstitutionSalesPercentFormatted,
        endDate: json.endDate,
        image: json.image,
        manufacturerSubstitutionCount: json.manufacturerSubstitutionCount,
        manufacturerSubstitutionCountFormatted:
            json.manufacturerSubstitutionCountFormatted,
        manufacturerSubstitutionCountPercent:
            json.manufacturerSubstitutionCountPercent,
        manufacturerSubstitutionCountPercentFormatted:
            json.manufacturerSubstitutionCountPercentFormatted,
        manufacturerSubstitutionSales: json.manufacturerSubstitutionSales,
        manufacturerSubstitutionSalesFormatted:
            json.manufacturerSubstitutionSalesFormatted,
        manufacturerSubstitutionSalesPercent:
            json.manufacturerSubstitutionSalesPercent,
        manufacturerSubstitutionSalesPercentFormatted:
            json.manufacturerSubstitutionSalesPercentFormatted,
        productName: json.productName,
        productSubname: json.productSubname,
        refundCount: json.refundCount,
        refundCountFormatted: json.refundCountFormatted,
        refundCountPercent: json.refundCountPercent,
        refundCountPercentFormatted: json.refundCountPercentFormatted,
        refundSales: json.refundSales,
        refundSalesFormatted: json.refundSalesFormatted,
        refundSalesPercent: json.refundSalesPercent,
        refundSalesPercentFormatted: json.refundSalesPercentFormatted,
        startDate: json.startDate,
        totalSubstitutionSales: json.totalSubstitutionSales,
        totalSubstitutionSalesFormatted: json.totalSubstitutionSalesFormatted,
        totalSubstitutionUnits:
            json.brandSubstitutionCount +
            json.competitorSubstitutionCount +
            json.manufacturerSubstitutionCount +
            json.refundCount
    };
};

export const reduceSubstitutionComparisonChart = (json: Json) => {
    const data = json.data || [];
    return {
        benchmarkData: data
            .filter((data: { benchmark: boolean }) => data.benchmark)
            .map(reduceSubstitutionComparisonItem),
        itemData: data
            .filter((data: { benchmark: boolean }) => !data.benchmark)
            .map(reduceSubstitutionComparisonItem)
    };
};

export const reduceViewSubstitutionComparison = (state: ReportsState) => {
    let reducedState = state;

    // copy over settings from substitution summary to substitution comparison state
    const substitutionSummaryState = state[SUBSTITUTION][SUBSTITUTION_SUMMARY];

    reducedState = update(state, {
        [SUBSTITUTION]: {
            [SUBSTITUTION_COMPARISON]: {
                aggregation: {
                    $set: substitutionSummaryState.aggregation
                },
                date: {
                    $set: substitutionSummaryState.date
                },
                retailers: {
                    $set: substitutionSummaryState.retailers
                },
                selectedItems: {
                    $set: substitutionSummaryState.selectedItem
                        ? [substitutionSummaryState.selectedItem]
                        : []
                }
            },
            [SUBSTITUTION_SUMMARY]: {
                $set: defaultSubstitutionSummaryState
            }
        }
    });

    return Object.assign({}, reducedState, {
        reportType: SUBSTITUTION,
        reportView: { value: SUBSTITUTION_COMPARISON }
    });
};

export const flattenCategories = (categories: IcCategory[]) => {
    const subcategories: string[] = [];

    categories.forEach((category: IcCategory) => {
        category.subcategories.forEach((subcategory: IcCategory) => {
            subcategories.push(
                category.name +
                    CATEGORY_SUBCATEGORY_SEPARATOR +
                    subcategory.name
            );
        });
    });

    return subcategories;
};

// Saved Reports
export const reduceSubstitutionSavedReport = (
    savedReport: SavedReport,
    requests: any[],
    uiState: Dictionary<string>,
    state: ReportsState
) => {
    let reducedState = state;
    const reportView = savedReport.type;
    switch (reportView) {
        case SUBSTITUTION_COMPARISON: {
            {
                const request = requests.find(request => {
                    return (
                        request.objectType ===
                        "SubstitutionComparisonReportRequest"
                    );
                });

                const selectedItems = (uiState.selectedItems as unknown) as OptionType[];
                const benchmarkCategories = flattenCategories(
                    request.benchmarkCategories
                );
                const categories = flattenCategories(
                    request.requestFilter.categories
                );
                let aggregation: SubstitutionReportAggregation = AGGREGATE_BY_UPC;
                if (request.upcs.length > 0) {
                    aggregation = AGGREGATE_BY_UPC;
                } else if (request.brands.length > 0) {
                    aggregation = AGGREGATE_BY_BRAND;
                } else if (request.productGroupIds.length > 0) {
                    aggregation = AGGREGATE_BY_PPG;
                }

                if (request) {
                    reducedState = update(state, {
                        [SUBSTITUTION]: {
                            [reportView]: {
                                aggregation: {
                                    $set: aggregation
                                },
                                benchmarkCategories: {
                                    $set: benchmarkCategories
                                },
                                categories: {
                                    $set: categories
                                },
                                date: {
                                    $set: getDateFilter(
                                        request.timePeriod.value,
                                        request.startDate,
                                        request.endDate
                                    )
                                },
                                retailers: {
                                    $set: request.retailers
                                },
                                selectedItems: {
                                    $set: selectedItems
                                }
                            }
                        }
                    });
                }
            }
            break;
        }
        case SUBSTITUTION_SUMMARY: {
            {
                const request = requests.find(request => {
                    return (
                        request.objectType ===
                        "SubstitutionSummaryReportRequest"
                    );
                });
                // Had to store selected option in uiState because controls use CustomSelect which need OptionType
                // No way to easily determine label from just the value since several of the CustomSelects are async
                const selectedItem = (uiState.selectedItem as unknown) as OptionTypeOrNullOrUndefined;
                let aggregation: SubstitutionReportAggregation = AGGREGATE_BY_UPC;
                if (request.upc) {
                    aggregation = AGGREGATE_BY_UPC;
                } else {
                    if (request.brand) {
                        aggregation = AGGREGATE_BY_BRAND;
                    } else {
                        if (request.productGroupId) {
                            aggregation = AGGREGATE_BY_PPG;
                        }
                    }
                }

                if (request) {
                    reducedState = update(state, {
                        [SUBSTITUTION]: {
                            [reportView]: {
                                aggregation: {
                                    $set: aggregation
                                },
                                date: {
                                    $set: getDateFilter(
                                        request.timePeriod.value,
                                        request.startDate,
                                        request.endDate
                                    )
                                },
                                retailers: {
                                    $set: request.retailers
                                },
                                selectedItem: {
                                    $set: selectedItem
                                },
                                substitutionChartType: {
                                    $set: uiState.substitutionChartType as SubstitutionSummaryChartType
                                },
                                substitutionType: {
                                    $set: request.substitutionType.value
                                },
                                viewBy: {
                                    $set: request.aggregation.value
                                }
                            }
                        }
                    });
                }
            }
            break;
        }
        default:
            break;
    }
    return Object.assign({}, reducedState, {
        reportType: SUBSTITUTION,
        reportView: { value: reportView }
    });
};
