import get from "lodash.get";
import type { DateFilter } from "common/util/date";
import type { ProductFilter } from "shell/state/initReducers";

export const PROMO_TYPE_DISPLAY = "DISPLAY";
export const PROMO_TYPE_TPR = "TPR";

export type FilterState = {
    date: DateFilter;
    holidays: string[];
    managers: string[];
    products: string[];
    regions: string[];
    retailers: string[];
    search: string;
    show: string[];
    showToggle: string;
};

export type OpportunitiesFilterState = {
    showTested: string[];
} & FilterState;

export type EventsFilterState = {
    max: number | undefined | null;
    min: number | undefined | null;
    performanceTiers: string[];
    promoTypes: string[];
    showCompliance: string[];
    showTested: string[];
} & FilterState;

export type TopPerformanceFilterState = {
    promoTypes: string[];
} & FilterState;

export type RecommendationsFilterState = {
    campaign: OptionTypeOrNullOrUndefined;
    campaignEndDate: Date | null;
    campaignStartDate: Date | null;
} & FilterState;

export const getProductCategoryProductGroups = (
    productCategories: ProductFilter[],
    categoryName: string
): string[] => {
    const productCategory = productCategories.find(category => {
        return category.value === categoryName;
    });
    if (productCategory && productCategory.adminProductGroups) {
        return productCategory.adminProductGroups.map(productGroup => {
            return productGroup.entityIdWithoutVersion;
        });
    }
    return [];
};

export const getChannelRetailers = (
    channels: any[],
    channelId: string
): string[] => {
    const channel = channels.find(ch => {
        return ch.entityIdWithoutVersion === channelId;
    });
    if (channel && channel.retailers) {
        return channel.retailers.map((retailer: any) => {
            return retailer.entityIdWithoutVersion;
        });
    }
    return [];
};

export const isTreeMultiOption = (
    tree: any[],
    childrenField = "children"
): boolean => {
    if (tree && tree.length) {
        if (tree.length > 1) {
            return true;
        }
        return tree[0][childrenField] && tree[0][childrenField].length > 1;
    }
    return false;
};

export const isTreeSingleOption = (
    tree: any[],
    childrenField = "children"
): boolean => {
    return (
        tree &&
        tree.length === 1 &&
        tree[0][childrenField] &&
        tree[0][childrenField].length === 1
    );
};

export const getSingleTreeOption = (tree: any[]): OptionType => {
    return {
        label: get(tree, "[0].children[0].label", ""),
        value: get(tree, "[0].children[0].value", "")
    };
};
