import * as React from "react";
import type { GetDerivedStateFromProps } from "react";
import isEqual from "lodash.isequal";
import styled from "styled-components/macro";
import type { Placement } from "tippy.js";
import {
    RadioCheckboxRadio,
    RadioCheckboxMark
} from "common/components/RadioCheckbox";
import Flexbox from "common/components/styled/Flexbox";
import { marginPropTypes, sizePropTypes } from "common/components/styled/util";
import theme from "common/components/theme";
import Tooltip from "common/components/Tooltip";
import { withRadioGroupChangeTracking } from "common/components/withRadioGroupChangeTracking";
import { reduceObject } from "common/util/object";
import { EVENT_NAME_SELECTION_CHOICE } from "common/util/trackingEvents";

export const RadioGroupWrapper = styled.div`
    margin: 8px 16px;
`;
RadioGroupWrapper.displayName = "RadioGroupWrapper";

export const RadioGroupHeader = styled.div`
    background-color: ${theme.greyBgColor};
    border-top: 1px solid ${theme.btnSecondaryBoxShadowActive};
    font-family: "BrandonTextBold";
    padding: 8px;
`;
RadioGroupHeader.displayName = "RadioGroupHeader";

type RadioProps = {
    disabled?: boolean;
    icon?: React.ReactNode;
    label: string;
    name?: string;
    onChange?: RadioGroupChangeHandler;
    overflowTooltip?: React.ReactNode;
    selectedValue?: string;
    tooltip?: string;
    tooltipPosition?: Placement;
    truncateLabel?: boolean;
    truncateTooltipPosition?: Placement;
    truncateWidth?: string;
    value: string;
} & marginPropsType &
    sizePropsType;

export class Radio extends React.Component<RadioProps> {
    radio: HTMLInputElement | undefined | null;

    onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === " ") {
            if (this.radio) {
                this.radio.click();
            }
        }
    };

    setRadioRef = (ref?: HTMLInputElement | null) => {
        this.radio = ref;
    };

    render() {
        const {
            icon,
            label,
            name,
            onChange,
            overflowTooltip,
            selectedValue,
            tooltip,
            tooltipPosition,
            truncateLabel,
            truncateTooltipPosition,
            truncateWidth
        } = this.props;
        const extraProps: {
            checked: boolean;
            onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
        } = { checked: false };
        if (selectedValue !== undefined) {
            extraProps.checked = this.props.value === selectedValue;
        } else {
            extraProps.checked = false;
        }
        if (typeof onChange === "function") {
            extraProps.onChange = onChange.bind(null, this.props.value);
        }
        const radioLabel = truncateLabel ? (
            <Flexbox cssWidth={truncateWidth ? truncateWidth : "100%"}>
                <Tooltip
                    boundary="window"
                    checkOverflow={true}
                    overflowTooltip={overflowTooltip}
                    placement={
                        truncateTooltipPosition
                            ? truncateTooltipPosition
                            : "right"
                    }
                    text={label}
                />
            </Flexbox>
        ) : (
            <div>{label}</div>
        );

        let radioLabelComponent = radioLabel;
        if (tooltip) {
            radioLabelComponent = (
                <Tooltip
                    boundary="window"
                    placement={tooltipPosition ? tooltipPosition : "bottom"}
                >
                    {radioLabel}
                    <div>{tooltip}</div>
                </Tooltip>
            );
        }

        return (
            <RadioCheckboxRadio
                paddingTop="9px"
                paddingBottom="10px"
                {...reduceObject(
                    this.props,
                    ...marginPropTypes,
                    ...sizePropTypes
                )}
            >
                <Flexbox cssWidth="100%" justifyContent="space-between">
                    {radioLabelComponent}
                    {icon}
                </Flexbox>
                <input
                    disabled={this.props.disabled}
                    name={name}
                    ref={this.setRadioRef}
                    type="radio"
                    value={this.props.value}
                    tabIndex={-1}
                    {...extraProps}
                />
                <RadioCheckboxMark onKeyDown={this.onKeyDown} tabIndex={0} />
            </RadioCheckboxRadio>
        );
    }
}

type RadioGroupProps = {
    name: string;
    selectedValue?: string;
    changeHandler: RadioGroupChangeHandler;
    children: React.ReactNode;
} & Pick<sizePropsType, "cssWidth"> &
    marginPropsType &
    Pick<flexboxPropsType, "justifyContent" | "flexDirection" | "flexWrap">;

type RadioGroupState = {
    selectedValue?: string;
    prevPropsSelectedValue?: string;
};

class RadioGroupComponent extends React.Component<
    RadioGroupProps,
    RadioGroupState
> {
    static displayName = "RadioGroup";

    constructor(props: RadioGroupProps) {
        super(props);
        this.state = {
            selectedValue: props.selectedValue,
            prevPropsSelectedValue: props.selectedValue
        };
    }

    static getDerivedStateFromProps: GetDerivedStateFromProps<
        RadioGroupProps,
        RadioGroupState
    > = (props, state) => {
        // Any time the selectedValues prop changes reset any parts of state that is tied to that prop
        if (!isEqual(props.selectedValue, state.prevPropsSelectedValue)) {
            return {
                selectedValue: props.selectedValue,
                prevPropsSelectedValue: props.selectedValue
            };
        }
        return null;
    };

    render() {
        const {
            changeHandler,
            children,
            flexDirection = "column",
            name,
            selectedValue,
            ...rest
        } = this.props;
        const radio = {
            onChange: this.onRadioChange,
            selectedValue: this.state.selectedValue,
            name: name
        };

        const childrenWithExtraProps = React.Children.map(
            this.props.children,
            child => {
                if (
                    React.isValidElement<RadioProps>(child) &&
                    child.type === Radio
                ) {
                    return React.cloneElement(child, radio);
                }
            }
        );
        return (
            <Flexbox flexDirection={flexDirection} {...rest}>
                {childrenWithExtraProps}
            </Flexbox>
        );
    }

    onRadioChange = (
        radioValue: string,
        event: React.SyntheticEvent<HTMLInputElement>
    ) => {
        this.setState({
            selectedValue: radioValue
        });

        this.props.changeHandler(radioValue, event);
    };
}

export const RadioGroup = withRadioGroupChangeTracking(
    RadioGroupComponent,
    RadioGroupComponent.displayName,
    EVENT_NAME_SELECTION_CHOICE
);
