import type { Action } from "redux";
import type { AppDispatch } from "store";

export const OPEN_MESSAGE_BOX = "OPEN_MESSAGE_BOX";
export const CLOSE_MESSAGE_BOX = "CLOSE_MESSAGE_BOX";

// These should be defined in MessageBox.js but this causes weirdness in tests when MessageBox is imported in reducers/actions
export const BUTTON_CANCEL = "BUTTON_CANCEL";
export const BUTTON_CANCELDELETE = "BUTTON_CANCELDELETE";
export const BUTTON_CANCELNOYES = "BUTTON_CANCELNOYES";
export const BUTTON_CANCELOK = "BUTTON_CANCELOK";
export const BUTTON_CANCELYES = "BUTTON_CANCELYES";
export const BUTTON_DELETE = "BUTTON_DELETE";
export const BUTTON_NO = "BUTTON_NO";
export const BUTTON_NOYES = "BUTTON_NOYES";
export const BUTTON_OK = "BUTTON_OK";
export const BUTTON_YES = "BUTTON_YES";
export const CLOSE = "CLOSE";
export const ICON_ALERT = "ICON_ALERT";
export const ICON_INFORMATION = "ICON_INFORMATION";
export const ICON_QUESTION = "ICON_QUESTION";
export const ICON_WARNING = "ICON_WARNING";

export type MessageBoxIconType =
    | typeof ICON_ALERT
    | typeof ICON_INFORMATION
    | typeof ICON_QUESTION
    | typeof ICON_WARNING;

export type MessageBoxButtons =
    | typeof BUTTON_CANCEL
    | typeof BUTTON_DELETE
    | typeof BUTTON_NO
    | typeof BUTTON_OK
    | typeof BUTTON_CANCELDELETE
    | typeof BUTTON_CANCELNOYES
    | typeof BUTTON_CANCELOK
    | typeof BUTTON_CANCELYES
    | typeof BUTTON_YES
    | typeof BUTTON_NOYES;

export type TransMessage = {
    resourceKey: string;
    values?: any;
};

export type MessageBoxOptions = {
    buttons: MessageBoxButtons;
    buttonText?: any;
    callbackData?: any;
    callbackFunction?: AnyFunction;
    cssWidth?: string;
    dispatch?: AppDispatch;
    icon?: MessageBoxIconType;
    message: TransMessage;
    showCloseIcon: boolean;
    title: string;
};

export type MessageBoxActions = OpenMessageBoxAction | CloseMessageBox;

interface OpenMessageBoxAction extends Action {
    type: typeof OPEN_MESSAGE_BOX;
}

export type OpenMessageBox = OpenMessageBoxAction & MessageBoxOptions;

export function openMessageBox(options: MessageBoxOptions): OpenMessageBox {
    const opts = Object.assign({}, options, { isOpen: true });
    return {
        type: OPEN_MESSAGE_BOX,
        ...opts
    };
}

interface CloseMessageBox extends Action {
    type: typeof CLOSE_MESSAGE_BOX;
}

export function closeMessageBox(): Action {
    return {
        type: CLOSE_MESSAGE_BOX
    };
}
