import {
    AUTHORIZATION_ERROR,
    AUTHORIZATION_RENEWED,
    CHOOSE_TENANT,
    CLEAR_ERROR,
    FORGOT_PASSWORD,
    PASSWORD_EMAIL_SENT,
    PASSWORD_EXPIRED,
    SECURITY_QUESTION_REQUIRED,
    SET_SECURITY_QUESTIONS,
    TO_LOG_IN,
    ASK_SECURITY_QUESTION,
    CHANGE_PASSWORD_DIALOG,
    CANCEL_CHANGE_PASSWORD,
    RESET_PASSWORD_DIALOG,
    RETRIEVE_PASSWORD
} from "common/auth/state/authActions";
import type { AuthActions } from "common/auth/state/authActions";
import { LOGGED_OUT } from "common/auth/state/commonAuthActions";
import noAction from "common/util/noAction";
import type { Question } from "common/shell/util/types";

export const STATE_CHOOSE_TENANT = "CHOOSE_TENANT";
export const STATE_FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const STATE_INITIAL = "INITIAL";
export const STATE_LOGGED_IN = "LOGGED_IN";
export const STATE_LOGGED_OUT = "LOGGED_OUT";
export const STATE_PASSWORD_EMAIL_SENT = "PASSWORD_EMAIL_SENT";
export const STATE_PASSWORD_EXPIRED = "PASSWORD_EXPIRED";
export const STATE_SECURITY_QUESTION = "SECURITY_QUESTION";
export const STATE_ASK_SECURITY_QUESTION = "ASK_SECURITY_QUESTION";
export const STATE_RESET_PASSWORD = "RESET_PASSWORD";
export const STATE_RETRIEVE_PASSWORD = "RETRIEVE_PASSWORD";
export const STATE_CHANGE_PASSWORD = "CHANGE_PASSWORD";

type LoginState =
    | typeof STATE_CHOOSE_TENANT
    | typeof STATE_FORGOT_PASSWORD
    | typeof STATE_INITIAL
    | typeof STATE_LOGGED_IN
    | typeof STATE_LOGGED_OUT
    | typeof STATE_PASSWORD_EMAIL_SENT
    | typeof STATE_PASSWORD_EXPIRED
    | typeof STATE_SECURITY_QUESTION
    | typeof STATE_ASK_SECURITY_QUESTION
    | typeof STATE_CHANGE_PASSWORD
    | typeof STATE_RETRIEVE_PASSWORD
    | typeof STATE_RESET_PASSWORD;

export const UNAUTHENTICATED_STATES: LoginState[] = [
    STATE_ASK_SECURITY_QUESTION,
    STATE_RETRIEVE_PASSWORD,
    STATE_RESET_PASSWORD
];

type AuthorizationState = {
    email: string;
    error: string;
    prevState?: LoginState;
    question: string;
    questionId: string;
    questions: Question[];
    state: LoginState;
    token: string;
    tenants: Tenant[];
};

export const defaultState: AuthorizationState = {
    email: "",
    error: "",
    question: "",
    questionId: "",
    questions: [],
    state: STATE_INITIAL,
    tenants: [],
    token: ""
};

const auth = (
    state: AuthorizationState = defaultState,
    action: AuthActions = noAction
): AuthorizationState => {
    switch (action.type) {
        case AUTHORIZATION_ERROR:
            return Object.assign({}, state, {
                error: action.error
            });
        case ASK_SECURITY_QUESTION:
            return Object.assign({}, state, {
                error: action.error,
                questionId: action.questionId,
                question: action.question,
                state: STATE_ASK_SECURITY_QUESTION
            });
        case RETRIEVE_PASSWORD:
            return Object.assign({}, state, {
                token: action.payload.token,
                state: STATE_RETRIEVE_PASSWORD
            });
        case CHANGE_PASSWORD_DIALOG:
            return Object.assign({}, state, {
                state: STATE_CHANGE_PASSWORD,
                //PROBABLY NOT BEST PRACTICE - don't use prev state unless there's a great reason.
                prevState:
                    state.state === STATE_CHANGE_PASSWORD
                        ? state.prevState
                        : state.state,
                error: action.error
            });
        case CANCEL_CHANGE_PASSWORD:
            return Object.assign({}, state, {
                state: state.prevState,
                //PROBABLY NOT BEST PRACTICE - don't use prev state unless there's a great reason.
                prevState: undefined,
                error: ""
            });
        case RESET_PASSWORD_DIALOG:
            return Object.assign({}, state, {
                state: STATE_RESET_PASSWORD,
                error: action.error
            });
        case CHOOSE_TENANT:
            return Object.assign({}, defaultState, {
                state: STATE_CHOOSE_TENANT,
                token: action.token,
                tenants: action.tenants
            });
        case CLEAR_ERROR:
            return Object.assign({}, state, {
                error: ""
            });
        case FORGOT_PASSWORD:
            return Object.assign({}, defaultState, {
                state: STATE_FORGOT_PASSWORD
            });
        case AUTHORIZATION_RENEWED:
            return Object.assign({}, defaultState, {
                state: STATE_LOGGED_IN,
                token: action.token
            });
        case TO_LOG_IN:
        case LOGGED_OUT:
            return Object.assign({}, defaultState, {
                state: STATE_LOGGED_OUT
            });
        case PASSWORD_EMAIL_SENT:
            return Object.assign({}, defaultState, {
                email: action.email,
                state: STATE_PASSWORD_EMAIL_SENT
            });
        case PASSWORD_EXPIRED:
            return Object.assign({}, defaultState, {
                state: STATE_PASSWORD_EXPIRED,
                error: action.error,
                token: action.token,
                tenants: action.tenants
            });
        case SECURITY_QUESTION_REQUIRED:
            return Object.assign({}, defaultState, {
                state: STATE_SECURITY_QUESTION,
                questions: action.questions,
                token: action.token,
                tenants: action.tenants
            });
        case SET_SECURITY_QUESTIONS:
            return Object.assign({}, state, {
                questions: action.questions
            });
        default:
            return state;
    }
};

export default auth;
