import type { TFunction } from "i18next";
import get from "lodash.get";
import type { AnyAction } from "redux";
import type { MenuSubOptionType } from "common/components/ExpandingDropdown";
import {
    COLUMN_DISPLAY_TYPE,
    DATA_TABLE_DISPLAY_TYPE,
    STACKED_BAR_DISPLAY_TYPE
} from "common/reports/components/reportsViewUtils";
import type { ReportStyle } from "common/reports/state/reportsStateUtils";
import type { ReportDef } from "common/shell/state/initStateUtils";
import {
    BUTTON_CANCELYES,
    BUTTON_OK,
    BUTTON_YES,
    ICON_INFORMATION,
    ICON_QUESTION,
    openMessageBox
} from "common/shell/state/messageBoxActions";
import {
    formatSortOrderForQueryAPI,
    makeRequestThunk,
    METHOD_DELETE,
    METHOD_POST,
    METHOD_PUT
} from "common/shell/state/requestActions";
import type { RequestMethod } from "common/shell/state/requestActions";
import { success } from "common/shell/state/toastActions";
import type { DateFilter } from "common/util/date";
import {
    enumFilterCondition,
    freeFormFilterCondition,
    multipleFilterCondition,
    stringInFilterCondition
} from "common/util/filter";
import { DEFAULT_PAGE_SIZE, sortCondition } from "common/util/query";
import {
    CONSUMER_CUSTOM,
    CONSUMER_PRODUCT_TRIAL,
    CONSUMER_PURCHASE_QUANTITIES
} from "reports/consumer/state/consumerStateUtils";
import type { Aggregate } from "reports/consumer/state/consumerStateUtils";
import {
    getConsumerSavedReportsDisplayType,
    getConsumerSavedReportsRequests,
    getConsumerSavedReportsState
} from "reports/consumer/state/consumerActionUtils";
import type {
    ReportFilters,
    ReportType,
    ReportView,
    TrendSeries
} from "reports/state/reportsStateUtils";
import { PURCHASE_CYCLE_AND_PROMOTIONS } from "reports/purchaseCycle/state/purchaseCycleStateUtils";
import {
    getPurchaseCycleSavedReportsRequests,
    getPurchaseCycleSavedReportsState
} from "reports/purchaseCycle/state/purchaseCycleActionUtils";
import {
    getTakeRateSavedReportsRequests,
    getTakeRateSavedReportsState
} from "reports/takeRate/state/takeRateActionUtils";
import {
    TAKE_RATE_PURCHASE_DISTRIBUTION,
    TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON
} from "reports/takeRate/state/takeRateStateUtils";
import {
    getSubstitutionSavedReportsRequests,
    getSubstitutionSavedReportsState
} from "reports/substitution/state/substitutionActionUtils";
import {
    SUBSTITUTION_COMPARISON,
    SUBSTITUTION_SUMMARY
} from "reports/substitution/state/substitutionStateUtils";
import { REPORTS_PAGE, SAVED_REPORTS_PAGE } from "shell/state/pageActions";
import type { AppDispatch, RootState } from "store";

export const LOAD_SAVED_REPORT = "LOAD_SAVED_REPORT";
export const RECEIVE_REPORTS_DATA_TABLE = "RECEIVE_REPORTS_DATA_TABLE";
export const RECEIVE_REPORTS_TREND_CHART = "RECEIVE_REPORTS_TREND_CHART";
export const REQUEST_REPORTS_DATA_TABLE = "REQUEST_REPORTS_DATA_TABLE";
export const REQUEST_REPORTS_TREND_CHART = "REQUEST_REPORTS_TREND_CHART";
export const RECEIVE_SAVED_REPORT = "RECEIVE_SAVED_REPORT";
export const REQUEST_SAVED_REPORTS = "REQUEST_SAVED_REPORTS";
export const RECEIVE_SAVED_REPORTS = "RECEIVE_SAVED_REPORTS";
export const RESET_REPORT_STATE = "RESET_REPORT_STATE";
export const UPDATE_REPORT_STYLE = "UPDATE_REPORT_STYLE";
export const UPDATE_REPORT_TYPE = "UPDATE_REPORT_TYPE";
export const UPDATE_REPORTS_DATA_TABLE_AGGREGATES =
    "UPDATE_REPORT_DATA_TABLE_AGGREGATES";
export const UPDATE_REPORTS_DATA_TABLE_FILTERS =
    "UPDATE_REPORTS_DATA_TABLE_FILTERS";
export const UPDATE_REPORTS_DATA_TABLE_SEARCH =
    "UPDATE_REPORTS_DATA_TABLE_SEARCH";
export const UPDATE_REPORTS_DATA_TABLE_SORT = "UPDATE_REPORTS_DATA_TABLE_SORT";
export const UPDATE_REPORTS_STACKED_BAR_FILTERS =
    "UPDATE_REPORTS_STACKED_BAR_FILTERS";
export const UPDATE_REPORTS_TREND_DATE = "UPDATE_REPORTS_TREND_DATE";
export const UPDATE_REPORTS_TREND_SERIES = "UPDATE_REPORTS_TREND_SERIES";
export const UPDATE_REPORT_TYPE_FILTER_SAVED_REPORTS =
    "UPDATE_REPORT_TYPE_FILTER_SAVED_REPORTS";
export const UPDATE_SEARCH_SAVED_REPORTS = "UPDATE_SEARCH_SAVED_REPORTS";
export const UPDATE_SORT_SAVED_REPORTS = "UPDATE_SORT_SAVED_REPORTS";
export const UPDATE_START_INDEX_SAVED_REPORTS =
    "UPDATE_START_INDEX_SAVED_REPORTS";

// Saved Report Status enum, should match SavedReportStatus.ped
const SAVED_REPORT_STATUS_COMPLETED = "COMPLETED";

// Generic report actions, Data Table, Trend Chart, Stacked Bar Chart, etc
export const reportsPage = (): AnyAction => {
    return {
        type: REPORTS_PAGE
    };
};

export const savedReportsPage = (): AnyAction => {
    return {
        type: SAVED_REPORTS_PAGE
    };
};

const requestSavedReports = (): AnyAction => ({
    type: REQUEST_SAVED_REPORTS
});

const receiveSavedReports = (json: Json): AnyAction => ({
    type: RECEIVE_SAVED_REPORTS,
    json: json
});

const receiveSavedReport = (json: Json): AnyAction => ({
    type: RECEIVE_SAVED_REPORT,
    json
});

export const resetReportState = (): AnyAction => {
    return {
        type: RESET_REPORT_STATE
    };
};

export const loadSavedReport = (json: SavedReport): AnyAction => ({
    type: LOAD_SAVED_REPORT,
    json: json
});

const receiveReportsDataTable = (json: Json): AnyAction => ({
    type: RECEIVE_REPORTS_DATA_TABLE,
    json
});

const requestReportsDataTable = (): AnyAction => ({
    type: REQUEST_REPORTS_DATA_TABLE
});

const receiveReportsTrendChart = (
    series: TrendSeries[],
    json: Json
): AnyAction => ({
    type: RECEIVE_REPORTS_TREND_CHART,
    series,
    json
});

const requestReportsTrendChart = (): AnyAction => ({
    type: REQUEST_REPORTS_TREND_CHART
});

export const updateReportsDataTableFilters = (
    filters: ReportFilters
): AnyAction => ({
    type: UPDATE_REPORTS_DATA_TABLE_FILTERS,
    filters
});

export const updateReportsDataTableSearch = (search: string): AnyAction => ({
    type: UPDATE_REPORTS_DATA_TABLE_SEARCH,
    search
});

export const updateReportsDataTableSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => ({
    type: UPDATE_REPORTS_DATA_TABLE_SORT,
    sortBy,
    sortOrder
});

export const updateReportsTrendDate = (date: DateFilter): AnyAction => ({
    type: UPDATE_REPORTS_TREND_DATE,
    date
});

export const updateReportsTrendSeries = (series: TrendSeries[]): AnyAction => ({
    type: UPDATE_REPORTS_TREND_SERIES,
    series
});

export const updateReportStackedBarFilters = (
    filters: ReportFilters
): AnyAction => ({
    type: UPDATE_REPORTS_STACKED_BAR_FILTERS,
    filters
});

export const updateReportType = (
    reportType: ReportType,
    reportView: MenuSubOptionType
): AnyAction => ({
    type: UPDATE_REPORT_TYPE,
    reportType,
    reportView
});

export const updateReportsDataTableAggregates = (
    aggregates: Aggregate[]
): AnyAction => {
    return {
        type: UPDATE_REPORTS_DATA_TABLE_AGGREGATES,
        aggregates
    };
};

export const updateReportStyle = (reportStyle: ReportStyle): AnyAction => {
    return {
        type: UPDATE_REPORT_STYLE,
        reportStyle
    };
};

export const updateSavedReportsStartIndex = (startIndex: number) => ({
    type: UPDATE_START_INDEX_SAVED_REPORTS,
    startIndex: startIndex
});

export const updateSavedReportsSortBy = (
    sortBy: string,
    sortOrder: SortOrder
) => ({
    type: UPDATE_SORT_SAVED_REPORTS,
    sortBy: sortBy,
    sortOrder: sortOrder
});

export const updateSavedReportsSearch = (search: string) => ({
    type: UPDATE_SEARCH_SAVED_REPORTS,
    search: search
});

export const updateSavedReportsReportTypeFilter = (
    reportTypes: string[]
): AnyAction => ({
    type: UPDATE_REPORT_TYPE_FILTER_SAVED_REPORTS,
    reportTypes
});

export const deleteSavedReportsConfirmation = (
    savedReport: SavedReport,
    t: TFunction,
    dispatch: AppDispatch,
    callbackFunction?: NoArgsHandler
): void => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.yes")
            },
            callbackData: { ...savedReport, callbackFunction },
            callbackFunction: handleDeleteSavedReportsConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey: "reports.delete_saved_report_confirmation",
                values: {
                    report: savedReport.name
                }
            },
            showCloseIcon: false,
            title: t("reports.delete_saved_report")
        })
    );
};

const handleDeleteSavedReportsConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
): void => {
    if (button === BUTTON_YES) {
        dispatch(
            deleteSavedReport(
                callbackData.entityId,
                callbackData.name,
                callbackData.callbackFunction
            )
        );
    }
};

const deleteSavedReport = (
    entityId: string,
    name: string,
    callbackFunction?: NoArgsHandler
) => {
    const url = "api/entity/savedreport/";
    return makeRequestThunk(url + entityId, {
        isCancellable: false,
        method: METHOD_DELETE,
        okDispatchFunc: (json: Json, state: RootState) => {
            const actions = [];
            const successMessage = {
                resourceKey: "reports.delete_saved_report_success",
                values: {
                    report: name
                }
            };
            if (callbackFunction) {
                callbackFunction();
            }
            actions.push(success(successMessage));
            return actions;
        }
    });
};

export const fetchReportDataTable = (
    reportView: ReportView,
    url: string,
    filters: ReportFilters,
    buildRequest: (
        reportView: ReportView,
        filters: ReportFilters,
        state: RootState
    ) => any,
    reduceRows: (rows: any[]) => any[]
) => {
    return makeRequestThunk(url, {
        method: METHOD_POST,
        bodyFunc: function (state: RootState): any {
            return buildRequest(reportView, filters, state);
        },
        preRequestFunc: requestReportsDataTable,
        okDispatchFunc: receiveReportsDataTable,
        okResultFunc: (json: Json, state: RootState): any => {
            const { rows } = json;
            const results: any[] = reduceRows(rows);
            return {
                count: results.length,
                items: results
            };
        },
        showLoadMask: false
    });
};

export const fetchReportTrendChart = (
    url: string,
    request: any,
    series: TrendSeries[]
) => {
    return makeRequestThunk(url, {
        method: METHOD_POST,
        bodyFunc: function (state: RootState): any {
            return request;
        },
        preRequestFunc: requestReportsTrendChart,
        okDispatchFunc: receiveReportsTrendChart.bind(null, series)
    });
};

const getSavedReportsRequest = (state: RootState): ListQueryRequest => {
    const sortOrder = formatSortOrderForQueryAPI(
        state.reports.savedReports.sortOrder
    );
    const sortConditions = [
        sortCondition(get(state, "reports.savedReports.sortBy"), sortOrder)
    ];
    // Only show completed saved reports, dont show generating reports
    const completedFilterCondition = enumFilterCondition(
        "status",
        SAVED_REPORT_STATUS_COMPLETED,
        "SavedReportStatus"
    );
    // Build search filter condtiion
    const search = get(state, "reports.savedReports.search");
    const filterSearchCondition = search
        ? freeFormFilterCondition(search, [
              "description",
              "creator.fullName",
              "creator.firstName",
              "creator.lastName",
              "modifiedBy.fullName",
              "modifiedBy.firstName",
              "modifiedBy.lastName",
              "name",
              "type"
          ])
        : null;

    // Build report type filter condition, filter by selected report types filter
    // If no report type filter, filter by all enabled report types from metadata
    let reportTypeFilterCondition;
    const reportTypes = state.reports.savedReports.reportTypes ?? [];
    const filteredReportTypes =
        reportTypes.length > 0
            ? reportTypes
            : state.init.preloadedEntities.metadata.reportTableFilterDefinitions.map(
                  (reportDef: ReportDef) => reportDef.value
              );
    if (filteredReportTypes.length > 0) {
        reportTypeFilterCondition = stringInFilterCondition(
            "type",
            filteredReportTypes
        );
    }

    let filterCondition: FilterCondition | null = null;
    const filterConditions = [];
    filterConditions.push(completedFilterCondition);
    if (filterSearchCondition) {
        filterConditions.push(filterSearchCondition);
    }
    if (reportTypeFilterCondition) {
        filterConditions.push(reportTypeFilterCondition);
    }
    if (filterConditions.length > 0) {
        if (filterConditions.length === 1) {
            filterCondition = filterConditions[0];
        } else {
            filterCondition = multipleFilterCondition("AND", filterConditions);
        }
    }

    return {
        entity: "SavedReport",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: DEFAULT_PAGE_SIZE,
        model: "SavedReportModel/readFields",
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: null,
        sortConditions: sortConditions,
        startIndex: state.reports.savedReports.startIndex
    };
};

const reduceSavedReports = (
    items: SavedReport[],
    reportDefinitions: ReportDef[]
): any[] => {
    return items.map((item: SavedReport) => {
        const reportDefinition = reportDefinitions.find(
            (definition: ReportDef) => {
                return definition.value === item.type;
            }
        );
        let typeAttributes: any = {};
        if (reportDefinition) {
            typeAttributes = {
                group: reportDefinition.group,
                groupLabel: reportDefinition.groupLabel,
                typeLabel: reportDefinition.label
            };
        }
        return {
            ...item,
            ...typeAttributes
        };
    });
};

export function fetchSavedReports() {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getSavedReportsRequest(state);
        },
        preRequestFunc: requestSavedReports,
        okDispatchFunc: receiveSavedReports,
        okResultFunc: (json: any, state: RootState) => {
            const reportDefinitions = get(
                state,
                "init.preloadedEntities.metadata.reportTableFilterDefinitions"
            );
            const items = reduceSavedReports(json.result, reportDefinitions);
            return {
                items: items,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

export const betaDisclaimerMessage = (t: TFunction, dispatch: AppDispatch) => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_OK,
            dispatch: dispatch,
            icon: ICON_INFORMATION,
            message: {
                resourceKey: "reports.disclaimer_beta_release_message"
            },
            showCloseIcon: false,
            title: t("reports.disclaimer_beta_release")
        })
    );
};

const getSavedReportEntity = (
    state: RootState,
    reportView: ReportView,
    name: string,
    description: string,
    savedWithStaticDates: boolean,
    savedReport: SavedReport | null
): SavedReport => {
    // DB - longtext - serialized JSON
    let requests: SavedReportRequest[] = [];
    let uiState = "";
    let displayType = DATA_TABLE_DISPLAY_TYPE;
    let entityId = "";
    if (savedReport) {
        entityId = savedReport?.entityId ?? "";
    }
    switch (reportView) {
        case CONSUMER_CUSTOM:
            requests = getConsumerSavedReportsRequests(
                reportView,
                state,
                savedWithStaticDates
            );
            uiState = getConsumerSavedReportsState(reportView, state);
            displayType = getConsumerSavedReportsDisplayType(reportView, state);
            break;
        case CONSUMER_PRODUCT_TRIAL:
            requests = getConsumerSavedReportsRequests(
                reportView,
                state,
                savedWithStaticDates
            );
            uiState = getConsumerSavedReportsState(reportView, state);
            displayType = getConsumerSavedReportsDisplayType(reportView, state);
            break;
        case CONSUMER_PURCHASE_QUANTITIES:
            requests = getConsumerSavedReportsRequests(
                reportView,
                state,
                savedWithStaticDates
            );
            uiState = getConsumerSavedReportsState(reportView, state);
            displayType = getConsumerSavedReportsDisplayType(reportView, state);
            break;
        case PURCHASE_CYCLE_AND_PROMOTIONS:
            requests = getPurchaseCycleSavedReportsRequests(
                reportView,
                state,
                savedWithStaticDates
            );
            uiState = getPurchaseCycleSavedReportsState(reportView, state);
            displayType = DATA_TABLE_DISPLAY_TYPE;
            break;
        case TAKE_RATE_PURCHASE_DISTRIBUTION:
            requests = getTakeRateSavedReportsRequests(
                reportView,
                state,
                savedWithStaticDates
            );
            uiState = getTakeRateSavedReportsState(reportView, state);
            displayType = COLUMN_DISPLAY_TYPE;
            break;
        case TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON:
            requests = getTakeRateSavedReportsRequests(
                reportView,
                state,
                savedWithStaticDates
            );
            uiState = getTakeRateSavedReportsState(reportView, state);
            displayType = STACKED_BAR_DISPLAY_TYPE;
            break;
        case SUBSTITUTION_SUMMARY:
            requests = getSubstitutionSavedReportsRequests(
                reportView,
                state,
                savedWithStaticDates
            );
            uiState = getSubstitutionSavedReportsState(reportView, state);
            displayType = DATA_TABLE_DISPLAY_TYPE;
            break;
        case SUBSTITUTION_COMPARISON:
            requests = getSubstitutionSavedReportsRequests(
                reportView,
                state,
                savedWithStaticDates
            );
            uiState = getSubstitutionSavedReportsState(reportView, state);
            displayType = STACKED_BAR_DISPLAY_TYPE;
            break;
        default:
            break;
    }
    return {
        description: description,
        // @ts-expect-error - no need to send in displayName
        displayType: {
            objectType: "ReportDisplayType",
            value: displayType
        },
        entityId: entityId,
        name: name,
        objectType: "SavedReport",
        requests: requests,
        savedWithStaticDates: savedWithStaticDates,
        state: uiState,
        type: reportView
    };
};

export const saveReport = (
    name: string,
    description: string,
    reportView: ReportView,
    savedWithStaticDates: boolean,
    savedReport: SavedReport | null
) => {
    let request: any;
    let method: RequestMethod = METHOD_POST;
    let url = "api/entity/savedreport";
    if (savedReport?.entityId) {
        url += "/" + savedReport?.entityId;
        method = METHOD_PUT;
    }

    return makeRequestThunk(url, {
        method: method,
        bodyFunc: function (state: RootState): any {
            request = getSavedReportEntity(
                state,
                reportView,
                name,
                description,
                savedWithStaticDates,
                savedReport
            );
            return request;
        },
        okDispatchFunc: (json: Json, state: RootState) => {
            const actions = [];
            actions.push(receiveSavedReport(Object.assign({}, request, json)));
            return actions;
        }
    });
};
