import * as React from "react";
import styled from "styled-components/macro";
import { marginProps } from "common/components/styled/util";
import theme from "common/components/theme";
import { withClickTracking } from "common/components/withClickTracking";
import { EVENT_NAME_EXECUTE_ACTION } from "common/util/trackingEvents";

type SvgWrapperProps = {
    align?: string;
    backgroundColor?: string;
    border?: string;
    borderRadius?: string;
    color?: string;
    cursor?: string;
    disableOutline?: boolean;
    height?: string;
    hoverColor?: string;
    opacity?: number;
    verticalAlign?: string;
    viewBox?: string;
    width?: string;
    zIndex?: number;
} & marginPropsType;

type SvgIconProps = SvgWrapperProps & {
    icon: IconType;
    title?: string;
} & marginPropsType;

const SvgWrapper = styled.svg<SvgWrapperProps>`
    align-self: ${props => (props.align ? props.align : "center")};
    display: flex;
    fill: ${props => props.color};
    fill-opacity: ${props => props.opacity};
    viewbox: ${props => (props.viewBox ? props.viewBox : "0 0 1024 1024")};
    z-index: ${props => props.zIndex};

    ${props =>
        props.backgroundColor && `background-color: ${props.backgroundColor};`}
    ${props => props.border && `border: ${props.border};`}
            ${props =>
        props.borderRadius && `border-radius: ${props.borderRadius};`}
    ${props => props.cursor && `cursor: ${props.cursor};`}
    ${props => props.height && `height: ${props.height};`}
    ${props => props.disableOutline && `outline: none;`}
    ${props => props.verticalAlign && `vertical-align: ${props.verticalAlign};`}
    ${props => props.width && `width: ${props.width};`}
    ${marginProps}

    :focus {
        outline: 0 !important;
    }

    :hover {
        fill: ${props => props.hoverColor || props.color};
    }
`;

// Using forwardRef for Tippy
const SvgIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
    ({ height, icon, title, width, ...rest }: SvgIconProps, ref) => {
        const Icon = icon;
        if (Icon) {
            return (
                <SvgWrapper height={height} ref={ref} width={width} {...rest}>
                    {title && <title>{title}</title>}
                    <Icon height={height} width={width} />
                </SvgWrapper>
            );
        } else {
            return null;
        }
    }
);

SvgIcon.displayName = "SvgIcon";

SvgIcon.defaultProps = {
    height: "16px",
    opacity: 1.0,
    width: "16px",
    zIndex: 2
};

export type SvgIconWithClickProps = {
    disabled?: boolean;
    onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
} & SvgIconProps;

const SvgIconWithClick = React.forwardRef<SVGSVGElement, SvgIconWithClickProps>(
    (props: SvgIconWithClickProps, ref): JSX.Element => {
        const { color, cursor, disabled, hoverColor, ...rest } = props;
        return (
            <SvgIcon
                color={disabled ? theme.disabledIcon : color}
                cursor={disabled ? "not-allowed" : "pointer"}
                hoverColor={disabled ? theme.disabledIcon : hoverColor}
                ref={ref}
                {...rest}
            />
        );
    }
);

SvgIconWithClick.defaultProps = {
    height: "16px",
    opacity: 1.0,
    width: "16px",
    zIndex: 2
};

SvgIconWithClick.displayName = "ClickableSvgIcon";

export default SvgIcon;

export const ClickableSvgIcon = withClickTracking(
    SvgIconWithClick,
    SvgIconWithClick.displayName,
    EVENT_NAME_EXECUTE_ACTION
);
