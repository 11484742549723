import * as React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import styled from "styled-components/macro";
import Flexbox from "common/components/styled/Flexbox";
import { FieldLabel } from "common/components/styled/Label";
import { marginProps } from "common/components/styled/util";
import SvgIcon, { ClickableSvgIcon } from "common/components/SvgIcon";
import theme from "common/components/theme";
import { ReactComponent as MenuIcon } from "common/icons/Menu.svg";
import { ReactComponent as XIcon } from "common/icons/X.svg";
import { containsIgnoreReactOutsideClick } from "common/util/outsideClick";
import { composeTrackingComponentLabel } from "common/util/tracking";
import { EVENT_NAME_OPEN_CLOSE } from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";

export type DrawerType = Drawer | null;
export const DEFAULT_WIDTH = 676;
export const LARGE_DRAWER_WIDTH = 1000;
export const SMALL_DRAWER_WIDTH = 600;

export const DrawerOverlay = styled.div<isOpenPropsType>`
    ${props =>
        props.isOpen &&
        "position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(255, 255, 255, 0.75); z-index: 999;"}
`;
DrawerOverlay.displayName = "DrawerOverlay";

export type DrawerWrapperProps = {
    cssWidth?: number;
    isSideDrawerOpen?: boolean;
} & isOpenPropsType &
    styledCSSType;

const DrawerWrapper = styled.div<DrawerWrapperProps>`
    background-color: white;
    display: ${props => (props.isOpen ? "flex" : "none")};
    flex-direction: column;
    height: 100%;
    position: absolute;
    right: ${props => (props.isOpen ? "0" : `-${props.cssWidth}`)}px;
    transition: 0.25s;
    transition-timing-function: cubic-bezier(0.02, 0.01, 0.47, 1);
    top: 0px;
    width: ${props => props.cssWidth}px;
    z-index: 101;
    ${props =>
        props.isOpen &&
        (!props.isSideDrawerOpen
            ? `box-shadow: -20px 0px 40px 0px ${theme.boxShadowColor};`
            : `border-left: solid 1px ${theme.border};`)}
    ${props => props.styledCSS && props.styledCSS}
`;
DrawerWrapper.displayName = "DrawerWrapper";

type SideDrawerWrapperProps = { drawerWidth: number } & styledCSSType;

const SideDrawerWrapper = styled(DrawerWrapper)<
    DrawerWrapperProps & SideDrawerWrapperProps
>`
    right: ${props => props.drawerWidth}px;
    z-index: 100;
    ${props => props.styledCSS && props.styledCSS}
`;

const MENU_SIZE = 16;
const SideDrawerButtonWrapper = styled.div`
    align-items: center;
    background-color: ${theme.background};
    border: 1px solid ${theme.border};
    border-radius: ${MENU_SIZE}px;
    cursor: pointer;
    display: flex;
    height: ${MENU_SIZE * 2}px;
    justify-content: center;
    position: absolute;
    left: -${MENU_SIZE}px;
    top: 80px;
    width: ${MENU_SIZE * 2}px;

    &:hover {
        background-color: ${theme.universalBackground};
    }
`;

const IconWrapper = styled.div`
    border-radius: 2px;
    cursor: pointer;
    padding-top: 4px;
    text-align: center;
    &:hover {
        background-color: ${theme.universalBackground};
    }
`;
IconWrapper.displayName = "IconWrapper";

const CloseIcon = ({ closeHandler }: { closeHandler: ClickHandler }) => {
    return (
        <IconWrapper onClick={closeHandler}>
            <SvgIcon
                color={theme.darkGreyText}
                height="14px"
                icon={XIcon}
                width="14px"
            />
        </IconWrapper>
    );
};
CloseIcon.displayName = "CloseIcon";

const DrawerScrollArea = styled(Flexbox)`
    ${props =>
        props.backgroundColor && `background-color: ${props.backgroundColor};`}
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
`;
DrawerScrollArea.displayName = "DrawerScrollArea";

export const DrawerContent = styled.div<
    Pick<sizePropsType, "cssHeight"> & Pick<flexboxPropsType, "overflow">
>`
    display: flex;
    flex-direction: column;
    flex: 1;
    ${props => props.cssHeight && `min-height: ${props.cssHeight};`}
    overflow: ${props => (props.overflow ? props.overflow : "auto")};
    padding: 0px 24px;
`;
DrawerContent.displayName = "DrawerContent";

export const DrawerHeaderTools = styled.div<backgroundColorPropsType>`
    ${props =>
        props.backgroundColor && `background-color: ${props.backgroundColor};`}
    border-bottom: ${theme.btnSecondaryEmphasisBorderColor} 1px solid;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    overflow: visible;
    padding: 0px 24px;
`;
DrawerHeaderTools.displayName = "DrawerHeaderTools";

export const DrawerHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 12px 8px 12px 0px;
`;
DrawerHeader.displayName = "DrawerHeader";

const DrawerHeaderActionTools = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    & > * {
        margin: 8px;
    }
    & > *:last-child {
        margin-right: 0px;
    }
`;
DrawerHeaderActionTools.displayName = "DrawerHeaderActionTools";

export const DrawerSection = styled(Flexbox)`
    flex-direction: column;
    margin: 24px 0px;
    ${marginProps}
`;
DrawerSection.displayName = "DrawerSection";

const DrawerSectionWithDivider = styled.div`
    border-top: ${theme.metricBorder} 1px solid;
    padding: 40px 0px;
`;
DrawerSectionWithDivider.displayName = "DrawerSectionWithDivider";

export const DrawerRow = styled(Flexbox)<{ fieldLabelWidth?: string }>`
    flex-wrap: wrap;

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    ${FieldLabel} {
        width: ${props =>
            props.fieldLabelWidth ? props.fieldLabelWidth : "160px"};
    }
`;
DrawerRow.displayName = "DrawerRow";

export const DrawerTitle = styled.div`
    color: ${theme.drawerHeader};
    font-family: "BrandonTextBold";
    font-size: 20px;
    ${marginProps}
`;
DrawerTitle.displayName = "DrawerTitle";

export const DrawerSubTitle = styled.div`
    color: ${theme.modalSubText};
    font-size: 14px;
    font-family: "BrandonTextRegular";
`;
DrawerSubTitle.displayName = "DrawerSubTitle";

const DrawerSectionTitle = styled.div`
    font-size: 16px;
    font-family: "BrandonTextBold";
    ${marginProps}
`;
DrawerSectionTitle.displayName = "DrawerSectionTitle";

export const DrawerFooter = styled(Flexbox)`
    align-items: center;
    border-top: ${theme.border} 1px solid;
    flex-shrink: 0;
    height: 68px;
    justify-content: flex-end;
    padding: 0px 24px;
`;

type DrawerProps = {
    backgroundColor?: string;
    children: React.ReactNode;
    clickOutsideHandler?: NoArgsHandler;
    closeHandler: NoArgsHandler;
    cssSideDrawerWidth?: number;
    cssWidth?: number;
    disableClickOutside?: boolean;
    footer?: React.ReactNode;
    headerActions?: React.ReactNode;
    isOpen: boolean;
    noScrolling?: boolean;
    onRef?: (ref: DrawerType) => void;
    openHandler?: NoArgsHandler;
    sideDrawerContent?: React.ReactNode;
    sideDrawerIcon?: IconType;
    sideDrawerStyledCSS?: any;
    subTitle?: React.ReactNode;
    title?: React.ReactNode;
    trackingComponentLabel?: TrackingComponentLabel;
    trackingSideComponentLabel?: TrackingComponentLabel;
    styledCSS?: any;
};

type DrawerStateProps = {
    isSideDrawerOpen: boolean;
};

class Drawer extends React.Component<DrawerProps, DrawerStateProps> {
    static displayName = "Drawer";
    state = {
        isSideDrawerOpen: false
    };

    componentDidMount() {
        const { onRef } = this.props;
        // set up ref access so parent can call this functions
        if (onRef) {
            onRef(this);
        }
    }

    componentDidUpdate(prevProps: DrawerProps) {
        const { isOpen } = this.props;
        if (!prevProps.isOpen && isOpen && this.props.openHandler) {
            this.props.openHandler();
        }
    }

    onOutsideClick = (event: MouseEvent) => {
        const { clickOutsideHandler, closeHandler, isOpen } = this.props;
        if (!containsIgnoreReactOutsideClick(event)) {
            if (isOpen) {
                if (clickOutsideHandler) {
                    clickOutsideHandler();
                } else {
                    closeHandler();
                }
            }
        }
    };

    setSideDrawerState = (state: boolean) => {
        this.setState({
            isSideDrawerOpen: state
        });
    };

    onSideDrawerClick = () => {
        const { isSideDrawerOpen } = this.state;
        this.setSideDrawerState(!isSideDrawerOpen);
    };

    render() {
        const {
            backgroundColor,
            children,
            closeHandler,
            cssSideDrawerWidth,
            cssWidth = DEFAULT_WIDTH,
            disableClickOutside,
            footer,
            headerActions,
            isOpen,
            noScrolling,
            sideDrawerContent,
            sideDrawerIcon,
            sideDrawerStyledCSS,
            styledCSS,
            subTitle,
            title,
            trackingComponentLabel = "",
            trackingSideComponentLabel = ""
        } = this.props;
        const { isSideDrawerOpen } = this.state;

        const childrenContent = !noScrolling ? (
            <DrawerScrollArea backgroundColor={backgroundColor}>
                {children}
            </DrawerScrollArea>
        ) : (
            <React.Fragment>{children}</React.Fragment>
        );
        return (
            <OutsideClickHandler
                disabled={disableClickOutside}
                onOutsideClick={this.onOutsideClick}
            >
                <DrawerWrapper
                    cssWidth={cssWidth}
                    isOpen={isOpen}
                    isSideDrawerOpen={isSideDrawerOpen}
                    styledCSS={styledCSS}
                >
                    {sideDrawerContent && (
                        <SideDrawerButtonWrapper
                            onClick={this.onSideDrawerClick}
                        >
                            <ClickableSvgIcon
                                color={theme.darkGreyText}
                                height="16px"
                                icon={
                                    sideDrawerIcon ? sideDrawerIcon : MenuIcon
                                }
                                onClick={this.onSideDrawerClick}
                                width="16px"
                                trackingComponentLabel={composeTrackingComponentLabel(
                                    [
                                        trackingComponentLabel,
                                        "Side Drawer",
                                        trackingSideComponentLabel,
                                        "Expand/Collapse"
                                    ]
                                )}
                                trackingEventName={EVENT_NAME_OPEN_CLOSE}
                            />
                        </SideDrawerButtonWrapper>
                    )}
                    <DrawerHeaderTools>
                        <DrawerHeader>
                            <DrawerTitle>{title}</DrawerTitle>
                            <DrawerSubTitle>{subTitle}</DrawerSubTitle>
                        </DrawerHeader>
                        <DrawerHeaderActionTools>
                            {headerActions}
                            <CloseIcon closeHandler={closeHandler} />
                        </DrawerHeaderActionTools>
                    </DrawerHeaderTools>
                    {childrenContent}
                    {footer}
                </DrawerWrapper>
                {sideDrawerContent && isOpen && isSideDrawerOpen && (
                    <React.Fragment>
                        <SideDrawerWrapper
                            cssWidth={cssSideDrawerWidth}
                            drawerWidth={cssWidth}
                            isOpen={true}
                            styledCSS={sideDrawerStyledCSS}
                        >
                            {sideDrawerContent}
                        </SideDrawerWrapper>
                    </React.Fragment>
                )}
            </OutsideClickHandler>
        );
    }
}

export default Drawer;
