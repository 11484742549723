import get from "lodash.get";
import { DEFAULT_PAGE_SIZE, getDefaultListState } from "common/util/query";
import {
    CREATED_BY_DATA_FIELD,
    CREATED_ON_DATA_FIELD,
    reduceByKeys
} from "common/util/object";
import { DESCENDING } from "common/util/query";
import cloneDeep from "lodash.clonedeep";

export const ALERT_LEVEL_ERROR = "ERROR" as string;
export const ALERT_LEVEL_INFO = "INFO" as string;
export const ALERT_LEVEL_WARNING = "WARNING" as string;

export type AlertLevels =
    | typeof ALERT_LEVEL_ERROR
    | typeof ALERT_LEVEL_INFO
    | typeof ALERT_LEVEL_WARNING;

export const CHECKLIST_STATUS_COMPLETE = "COMPLETE" as string;
export const CHECKLIST_STATUS_DISABLED = "DISABLED" as string;
export const CHECKLIST_STATUS_INCOMPLETE = "INCOMPLETE" as string;
export const CHECKLIST_STATUS_SUFFICIENT = "SUFFICIENT" as string;

export type ChecklistStatusType =
    | typeof CHECKLIST_STATUS_COMPLETE
    | typeof CHECKLIST_STATUS_DISABLED
    | typeof CHECKLIST_STATUS_INCOMPLETE
    | typeof CHECKLIST_STATUS_SUFFICIENT;

export const USE_ANY_RETAILER = "ANY" as string;
export const USE_SPECIFIC_RETAILER = "SPECIFIC" as string;

export type RetailerType =
    | typeof USE_ANY_RETAILER
    | typeof USE_SPECIFIC_RETAILER;

export const CAMPAIGN_NAME = "name";
const CAMPAIGN_CONFIDENCE_COLOR = "confidenceColor";
export const CAMPAIGN_CONFIDENCE_PERCENT = "confidencePercent";
export const CAMPAIGN_DAYS_RUNNING = "daysRunning";
export const CAMPAIGN_END_DATE = "endDate";
export const CAMPAIGN_HOLIDAY = "holiday";
export const CAMPAIGN_REGION = "regionSummary";
export const CAMPAIGN_RETAILER = "retailerSummary";
export const CAMPAIGN_STATE = "state.displayName";
export const CAMPAIGN_START_DATE = "startDate";
export const CAMPAIGN_TEST_OFFERS = "testOffers";
export const CAMPAIGN_TEST_PLATFORM = "testPlatform";
export const CAMPAIGN_TOTAL_SPEND = "totalSpend";

// sort constants
export const CAMPAIGN_TEST_PLATFORM_SORT =
    "tenantTestPlatform.testPlatform.displayName";

export const CAMPAIGN_STATE_COMPLETED = "COMPLETED";
export const CAMPAIGN_STATE_DRAFT = "DRAFT";
export const CAMPAIGN_STATE_INREVIEW = "INREVIEW";
export const CAMPAIGN_STATE_PAUSED = "PAUSED";
export const CAMPAIGN_STATE_QUEUED = "QUEUED";
export const CAMPAIGN_STATE_REQUEUED = "REQUEUED";
export const CAMPAIGN_STATE_RUNNING = "RUNNING";

export type CampaignTabs =
    | typeof CAMPAIGN_STATE_COMPLETED
    | typeof CAMPAIGN_STATE_DRAFT
    | typeof CAMPAIGN_STATE_INREVIEW
    | typeof CAMPAIGN_STATE_PAUSED
    | typeof CAMPAIGN_STATE_QUEUED
    | typeof CAMPAIGN_STATE_RUNNING;

export type CampaignStatus = CampaignTabs | typeof CAMPAIGN_STATE_REQUEUED;

export const CAMPAIGN_SCORECARD_CONTROL = "CONTROL" as string;
export const CAMPAIGN_SCORECARD_RECOMMENDED = "RECOMMENDED" as string;
export const CAMPAIGN_SCORECARD_CONSIDER = "CONSIDER" as string;

export const TARGETING_TYPE_DETAILED_TARGETING =
    "TARGETING_TYPE_DETAILED_TARGETING";
export const TARGETING_TYPE_LOCATION = "TARGETING_TYPE_LOCATION";
export const TARGETING_TYPE_LOCATION_BULK = "TARGETING_TYPE_LOCATION_BULK";

export type TargetingSelectItemType =
    | typeof TARGETING_TYPE_DETAILED_TARGETING
    | typeof TARGETING_TYPE_LOCATION
    | typeof TARGETING_TYPE_LOCATION_BULK;

export const TARGETING_ENTITY_PREFIX = "targeting-";

type CampaignFilterState = {
    filters: {
        endDate: Date | null;
        holidays: string[];
        products: string[];
        regions: string[];
        retailers: string[];
        startDate: Date | null;
    };
};

export type CampaignsState = {
    [CAMPAIGN_STATE_COMPLETED]: QueryState & CampaignFilterState;
    [CAMPAIGN_STATE_DRAFT]: QueryState & CampaignFilterState;
    [CAMPAIGN_STATE_INREVIEW]: QueryState & CampaignFilterState;
    [CAMPAIGN_STATE_PAUSED]: QueryState & CampaignFilterState;
    [CAMPAIGN_STATE_QUEUED]: QueryState & CampaignFilterState;
    [CAMPAIGN_STATE_RUNNING]: QueryState & CampaignFilterState;
    upstreamCampaigns: QueryState;
    subTab: CampaignTabs;
};

export const defaultFiltersState: CampaignFilterState = {
    filters: {
        endDate: null,
        holidays: [],
        products: [],
        regions: [],
        retailers: [],
        startDate: null
    }
};

export const defaultCampaignsState: CampaignsState = {
    [CAMPAIGN_STATE_COMPLETED]: Object.assign(
        {},
        getDefaultListState(
            CAMPAIGN_END_DATE,
            DEFAULT_PAGE_SIZE,
            "",
            DESCENDING
        ),
        defaultFiltersState
    ),
    [CAMPAIGN_STATE_DRAFT]: Object.assign(
        {},
        getDefaultListState(CAMPAIGN_NAME),
        defaultFiltersState
    ),
    [CAMPAIGN_STATE_INREVIEW]: Object.assign(
        {},
        getDefaultListState(CAMPAIGN_NAME),
        defaultFiltersState
    ),
    [CAMPAIGN_STATE_PAUSED]: Object.assign(
        {},
        getDefaultListState(CAMPAIGN_NAME),
        defaultFiltersState
    ),
    [CAMPAIGN_STATE_QUEUED]: Object.assign(
        {},
        getDefaultListState(CAMPAIGN_NAME),
        defaultFiltersState
    ),
    [CAMPAIGN_STATE_RUNNING]: Object.assign(
        {},
        getDefaultListState(CAMPAIGN_NAME),
        defaultFiltersState
    ),
    upstreamCampaigns: getDefaultListState(CAMPAIGN_NAME),
    subTab: CAMPAIGN_STATE_DRAFT
};

/*
export type Campaign = {
    allowableActions: Record<string, unknown>;
    availableCustomPhotoRebateOptions: CustomPhotoRebateOption[];
    availableRedemptionMethods: RedemptionMethod[];
    alertLevel: string;
    checklistItems: ChecklistItem[];
    checklistStatus: string;
    checklistMessage: string;
    confidenceColor: string;
    confidencePercent: string;
    couponLocale: string;
    createdBy: string;
    createdOn: string;
    daysRunning: number;
    endDate: string;
    entityId: string;
    hasBreakdowns: boolean;
    hasControlPromotions: boolean;
    hasOfferCost: boolean;
    hasPerformanceOffers: boolean;
    holiday: string;
    name: string;
    nameForAnalytics: string;
    numControlPromotions: number;
    pendingCalculations: boolean;
    productGroups: string[];
    regionList: string;
    regions: string[];
    regionSummary: string;
    regionSummaryTooltip: string;
    requiresImage: boolean;
    requiresSpendPerDay: boolean;
    retailerList: string;
    retailers: string[];
    retailerSummary: string;
    retailerSummaryTooltip: string;
    rolledUpMessages: RolledUpMessage[];
    showConsumerEngagement: boolean;
    showMargin: boolean;
    showTakeRate: boolean;
    startDate: string;
    state: CampaignState;
    status: string;
    successMetric: SuccessMetric;
    supportsCeiOverTime: boolean;
    supportsProgress: boolean;
    tenantTestPlatform: TenantTestPlatform;
    testOffers: number;
    testPlatform: string;
    totalSpend: string;
};
*/

type ProcessedCampaign = Campaign & {
    allowableActions: Record<string, unknown>;
};

export const reduceCampaign = (data: any): ProcessedCampaign => {
    return {
        allowableActions: reduceByKeys(
            get(data, "allowableActions", []),
            "allowed"
        ),
        alertLevelToShow: get(data, "alertLevelToShow"),
        availableCustomPhotoRebateOptions: get(
            data,
            "availableCustomPhotoRebateOptions",
            []
        ),
        availableRedemptionMethods: get(data, "availableRedemptionMethods", []),
        checklistItems: get(data, "checklistItems", []),
        checklistStatus: get(data, "checklistStatus"),
        [CAMPAIGN_CONFIDENCE_COLOR]: get(data, "testPhase_confidenceColor", ""),
        [CAMPAIGN_CONFIDENCE_PERCENT]: get(
            data,
            "testPhase_confidenceOfDifferenceFormatPercent",
            "0%"
        ),
        couponLocale: get(data, "couponLocale"),
        [CREATED_BY_DATA_FIELD]: get(data, "creator_fullName", ""),
        [CREATED_ON_DATA_FIELD]: get(data, "createdFormatDate", ""),
        [CAMPAIGN_DAYS_RUNNING]: get(
            data,
            "testPhase_daysRunningFormatNumber",
            0
        ),
        [CAMPAIGN_END_DATE]: get(data, "endDateFormatDate", ""),
        entityId: get(data, "entityId", ""),
        hasBreakdowns: get(data, "hasBreakdowns"),
        hasControlPromotions: get(data, "hasControlPromotions"),
        hasOfferCost: get(data, "hasOfferCost"),
        hasPerformanceOffers: get(data, "hasPerformanceOffers"),
        holiday: get(data, "holiday_name", ""),
        [CAMPAIGN_NAME]: get(data, "name", ""),
        nameForAnalytics: get(data, "nameForAnalytics", ""),
        numControlPromotions: get(data, "numControlPromotions"),
        objectType: "model/CampaignModel",
        pendingCalculations: get(data, "pendingCalculations"),
        productGroups: get(data, "productGroups", []),
        regions: get(data, "regions", []),
        regionList: get(data, "regionList", ""),
        [CAMPAIGN_REGION]: get(data, "regionSummary", ""),
        regionSummaryTooltip: get(data, "regionSummaryTooltip", ""),
        requiresImage: get(
            data,
            "tenantTestPlatform_testPlatform_requiresImage",
            false
        ),
        requiresSpendPerDay: get(
            data,
            "tenantTestPlatform_testPlatform_requiresSpendPerDay",
            false
        ),
        retailers: get(data, "retailers", []),
        retailerList: get(data, "retailerList", ""),
        [CAMPAIGN_RETAILER]: get(data, "retailerSummary", ""),
        retailerSummaryTooltip: get(data, "retailerSummaryTooltip", ""),
        rolledUpMessages: get(data, "rolledUpMessages", []),
        showConsumerEngagement: get(data, "showConsumerEngagement"),
        showMargin: get(data, "showMargin"),
        showTakeRate: get(data, "showTakeRate"),
        [CAMPAIGN_START_DATE]: get(data, "startDateFormatDate", ""),
        state: get(data, "state"),
        successMetric: get(data, "successMetric"),
        supportsCeiOverTime: get(
            data,
            "tenantTestPlatform_testPlatform_supportsCeiOverTime",
            false
        ),
        supportsProgress: get(
            data,
            "tenantTestPlatform_testPlatform_supportsProgress",
            false
        ),
        [CAMPAIGN_TEST_OFFERS]: get(data, "testOffers", 0),
        [CAMPAIGN_TEST_PLATFORM]: get(
            data,
            "tenantTestPlatform_testPlatform_displayName_value",
            ""
        ),
        tenantTestPlatform: get(data, "tenantTestPlatform", {}),
        [CAMPAIGN_TOTAL_SPEND]: get(
            data,
            "testPhase_totalSpendIncludingLeadFormatCurrency",
            ""
        )
    };
};

export const reduceCampaignProgress = (progress: any) => {
    const series = get(progress, "series", []);
    let atLeastOneValidSeries = false;
    const validSeriesIndices = [];
    const invalidSeriesIndices = [];
    for (let i = 0; i < series.length; i += 1) {
        const points = get(series[i], "points", []);
        const lastActualIndex = get(series[i], "lastActualIndex");
        if (points.length >= 1 && lastActualIndex >= 0) {
            atLeastOneValidSeries = true;
            validSeriesIndices.push(i);
        } else {
            invalidSeriesIndices.push(i);
        }
    }
    return {
        atLeastOneValidSeries: atLeastOneValidSeries,
        currencySymbol: get(progress, "currencySymbol"),
        hasSpendInformation: get(progress, "hasSpendInformation"),
        invalidSeriesIndices: invalidSeriesIndices,
        series: series,
        status: get(progress, "status"),
        statusMessage: get(progress, "statusMessage"),
        validSeriesIndices: validSeriesIndices
    };
};

export const reduceCampaignCei = (cei: any) => {
    const series = get(cei, "series", []);
    const periods = get(cei, "periods", []);
    return {
        periods: periods,
        series: series
    };
};

export const processTestInsights = (insights: any): any[] => {
    const displayMetrics = get(insights, "displayMetrics", []);
    const considerOffers = get(insights, "considerOffers", []);
    const controlOffers = get(insights, "controlOffers", []);
    const recommendedOffers = get(insights, "recommendedOffers", []);
    const selectedControlOfferId = get(
        insights,
        "selectedControlOfferId",
        null
    );

    const offers = [];

    if (displayMetrics.length > 0) {
        const controlGroup: any = {
            group: false,
            groupName: CAMPAIGN_SCORECARD_CONTROL,
            //            expanded: true,
            hierarchy: [CAMPAIGN_SCORECARD_CONTROL],
            offers: []
        };

        if (selectedControlOfferId) {
            const controlOffer: any = controlOffers.find((offer: any) => {
                return selectedControlOfferId === get(offer, "offer_entityId");
            });
            const cOffer = cloneDeep(controlOffer);
            cOffer.groupName = CAMPAIGN_SCORECARD_CONTROL;
            cOffer.group = false;
            cOffer.hierarchy = [
                CAMPAIGN_SCORECARD_CONTROL,
                controlOffer.groupName
            ];
            controlGroup.offers.push(cOffer);
        }
        offers.push(controlGroup);
        const recommendedGroup: any = {
            group: recommendedOffers.length > 0 ? true : false,
            groupName: CAMPAIGN_SCORECARD_RECOMMENDED,
            hierarchy: [CAMPAIGN_SCORECARD_RECOMMENDED],
            offers: []
        };
        for (let i = 0; i < recommendedOffers.length; i += 1) {
            const offer: any = recommendedOffers[i];
            const off = cloneDeep(offer);
            off.groupName = CAMPAIGN_SCORECARD_RECOMMENDED;
            //            off.groupName = get(off, "offer_offerLabelFormatHtml");
            off.group = false;
            off.hierarchy = [CAMPAIGN_SCORECARD_RECOMMENDED, off.groupName];
            recommendedGroup.offers.push(off);
        }
        if (recommendedGroup.offers.length > 0) {
            recommendedGroup.expanded = true;
        }
        offers.push(recommendedGroup);
        const considerGroup: any = {
            group: considerOffers.length > 0 ? true : false,
            groupName: CAMPAIGN_SCORECARD_CONSIDER,
            hierarchy: [CAMPAIGN_SCORECARD_CONSIDER],
            offers: []
        };
        for (let i = 0; i < considerOffers.length; i += 1) {
            const offer: any = considerOffers[i];
            const off = cloneDeep(offer);
            off.groupName = CAMPAIGN_SCORECARD_CONSIDER;
            //            off.groupName = get(off, "offer_offerLabelFormatHtml");
            off.group = false;
            off.hierarchy = [CAMPAIGN_SCORECARD_CONSIDER, off.groupName];
            considerGroup.offers.push(off);
        }
        if (!recommendedGroup.expanded && considerGroup.offers.length > 0) {
            considerGroup.expanded = true;
        }
        offers.push(considerGroup);
    }

    return offers;
};
