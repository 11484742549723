import styled from "styled-components/macro";
import {
    avoidPageBreakProps,
    borderRadius,
    boxShadow,
    flexboxProps,
    marginProps,
    paddingProps,
    positionProps,
    sizeProps,
    textProps
} from "common/components/styled/util";
import theme from "common/components/theme";

export type FlexboxWithBorderProps = {
    backgroundColor?: string;
    cursor?: string;
    lineHeight?: string;
    outline?: string;
    overflow?: string;
    textAlign?: string;
    withBoxShadow?: boolean;
} & flexboxPropsType &
    marginPropsType &
    paddingPropsType &
    positionPropsType &
    sizePropsType &
    textPropsType;

const FlexboxWithBorder = styled.div<FlexboxWithBorderProps>`
    word-wrap: break-word;
    background-clip: border-box;
    background-color: ${props =>
        props.backgroundColor ? props.backgroundColor : theme.background};
    border: 1px solid ${theme.border};
    border-radius: ${borderRadius("card")};
    box-shadow: ${props =>
        props.withBoxShadow ? boxShadow("card")(props) : "none"};
    margin: 0;
    min-width: 0;
    padding: 0;
    ${props => props.onClick && "cursor: pointer;"}
    ${props => props.cursor && `cursor: ${props.cursor};`}
    ${props => props.lineHeight && `line-height: ${props.lineHeight};`}
    ${props => props.outline && `outline: ${props.outline};`}
    ${props => props.overflow && `overflow: ${props.overflow};`}
    ${props => props.textAlign && `text-align: ${props.textAlign};`}

    ${avoidPageBreakProps}
    ${flexboxProps}
    ${marginProps}
    ${paddingProps}
    ${positionProps}
    ${sizeProps}
    ${textProps}
`;
FlexboxWithBorder.displayName = "FlexboxWithBorder";

export default FlexboxWithBorder;
