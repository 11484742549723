import get from "lodash.get";
import type { AnyAction } from "redux";
import { formatMonth } from "common/util/date";
import noAction from "common/util/noAction";
import {
    defaultCreatorModifierState,
    getEntityIdWithoutVersion,
    reduceCreatorModifier,
    reduceObject
} from "common/util/object";
import {
    RECEIVE_HOLIDAY,
    REQUEST_HOLIDAY,
    REQUEST_HOLIDAY_OCCURRENCES
} from "administration/holidays/state/holidayActions";
import type { HolidayListState } from "administration/holidays/state/holidayStateUtils";
import {
    HOLIDAY_PAGE,
    HOLIDAY_CREATE_PAGE,
    HOLIDAY_EDIT_PAGE
} from "administration/state/pageActions";

type HolidayState = {
    endDay: number;
    endMonth: OptionTypeOrNullOrUndefined;
    nonrecurringEndDate: string | undefined | null;
    nonrecurringStartDate: string | undefined | null;
    standardHolidayId: string;
    startDay: number;
    startMonth: OptionTypeOrNullOrUndefined;
    weeksAfter: number;
    weeksBefore: number;
} & HolidayListState;

export type ChildHolidayFormViewProps = {
    children: React.ReactNode;
    holiday: HolidayState;
    name: string;
    onChangeName: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    onChangeProducts: (products: OptionType[]) => void;
    page: string;
    products: any[];
    title: string;
};

export const defaultState: HolidayState = {
    endDate: "",
    endDay: 0,
    endMonth: null,
    entityId: "",
    entityIdWithoutVersion: "",
    holidayType: "",
    recurring: true,
    name: "",
    nonrecurringEndDate: null,
    nonrecurringStartDate: null,
    products: [],
    productsList: "",
    objectType: "",
    standardHolidayId: "",
    startDate: "",
    startDay: 0,
    startMonth: null,
    weeksBefore: 0,
    weeksAfter: 0,
    ...defaultCreatorModifierState
};

export const reduceHoliday = (data: any): HolidayState => {
    const holiday = reduceObject(
        data,
        ["customEndDay", "endDay"],
        ["customStartDay", "startDay"],
        "entityId",
        "name",
        "objectType",
        "holidayType",
        "nonrecurringEndDate",
        "nonrecurringStartDate",
        "products",
        ["standardWeeksAfter", "weeksAfter"],
        ["standardWeeksBefore", "weeksBefore"]
    );
    const startMonth = data.customStartMonth
        ? formatMonth(data.customStartMonth)
        : data.customStartMonth;
    const endMonth = data.customEndMonth
        ? formatMonth(data.customEndMonth)
        : data.customEndMonth;

    const creatorModifier = reduceCreatorModifier(data);

    // holidays used to only be recurring - assuming existing holidays will be migrated to have recurring = true but make sure here
    let recurring = get(data, "recurring");
    if (recurring === null) {
        recurring = true;
    }

    return Object.assign(
        {},
        holiday,
        {
            endMonth: endMonth,
            entityIdWithoutVersion: getEntityIdWithoutVersion(data.entityId),
            holidayType: get(data.holidayType, "value"),
            productsList: data.products.join(", "),
            recurring: recurring,
            standardHolidayId: data.standardHoliday
                ? data.standardHoliday.entityId
                : "",
            startMonth: startMonth
        },
        creatorModifier
    );
};

const holiday = (
    state: HolidayState = defaultState,
    action: AnyAction = noAction
): HolidayState => {
    switch (action.type) {
        case HOLIDAY_PAGE:
            return Object.assign({}, defaultState, {
                entityId: action.payload && action.payload.entityId
            });
        case HOLIDAY_CREATE_PAGE:
            return defaultState;
        case HOLIDAY_EDIT_PAGE:
            return Object.assign({}, defaultState, {
                entityId: action.payload && action.payload.entityId
            });
        case REQUEST_HOLIDAY:
            return state;
        case RECEIVE_HOLIDAY:
            return Object.assign({}, state, reduceHoliday(action.holiday));
        case REQUEST_HOLIDAY_OCCURRENCES:
            return state;
        default:
            return state;
    }
};

export default holiday;
