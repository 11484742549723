export const ANALYSIS_CAMPAIGN_PAGE = "ANALYSIS_CAMPAIGN_PAGE";
export const ANALYSIS_CAMPAIGN_COMPARISON_PAGE =
    "ANALYSIS_CAMPAIGN_COMPARISON_PAGE";
export const ANALYSIS_FACTOR_PAGE = "ANALYSIS_FACTOR_PAGE";
export const ANALYSIS_SAVED_ANALYSES_PAGE = "ANALYSIS_SAVED_ANALYSES_PAGE";
export const CAMPAIGN_CREATE_PAGE = "CAMPAIGN_CREATE_PAGE";
export const CAMPAIGN_EDIT_PAGE = "CAMPAIGN_EDIT_PAGE";
export const CAMPAIGN_OFFERS_PAGE = "CAMPAIGN_OFFERS_PAGE";
export const CAMPAIGN_PPG_EDIT_PAGE = "CAMPAIGN_PPG_EDIT_PAGE";
export const CAMPAIGN_PPGS_PAGE = "CAMPAIGN_PPGS_PAGE";
export const CAMPAIGN_BUDGET_OPTIMIZATION_PAGE =
    "CAMPAIGN_BUDGET_OPTIMIZATION_PAGE";
export const CAMPAIGN_BUDGET_OPTIMIZATION_EDIT_PAGE =
    "CAMPAIGN_BUDGET_OPTIMIZATION_EDIT_PAGE";
export const CAMPAIGN_REDEMPTION_PAGE = "CAMPAIGN_REDEMPTION_PAGE";
export const CAMPAIGN_REDEMPTION_EDIT_PAGE = "CAMPAIGN_REDEMPTION_EDIT_PAGE";
export const CAMPAIGN_TARGETING_PAGE = "CAMPAIGN_TARGETING_PAGE";
export const CAMPAIGN_TARGETING_EDIT_PAGE = "CAMPAIGN_TARGETING_EDIT_PAGE";
export const CAMPAIGN_UPCS_PAGE = "CAMPAIGN_UPCS_PAGE";
export const CAMPAIGN_OVERVIEW_PAGE = "CAMPAIGN_OVERVIEW_PAGE";
export const CAMPAIGN_PAGE = "CAMPAIGN_PAGE";
export const CAMPAIGN_TEST_RESULTS_PAGE = "CAMPAIGN_TEST_RESULTS_PAGE";
export const CAMPAIGNS_PAGE = "CAMPAIGNS_PAGE";

// compliance tracker pages
export const COMPLIANCE_EVENT_PAGE = "COMPLIANCE_EVENT_PAGE";
export const COMPLIANCE_OVERVIEW_PAGE = "COMPLIANCE_OVERVIEW_PAGE";

// event pages
export const EVENTS_PAGE = "EVENTS_PAGE";

// guideline pages
export const GUIDELINES_VIEWER_PAGE = "GUIDELINES_VIEWER_PAGE";
export const GUIDELINES_VIEWER_PRINT_PAGE = "GUIDELINES_VIEWER_PRINT_PAGE";

// offer pages
export const OFFER_ADVANCED_CREATE_PAGE = "OFFER_ADVANCED_CREATE_PAGE";
export const OFFER_AUTOMATED_CREATE_PAGE = "OFFER_AUTOMATED_CREATE_PAGE";
export const OFFER_EDIT_PAGE = "OFFER_EDIT_PAGE";

// promotion pages
export const PROMOTION_EDIT_PAGE = "PROMOTION_EDIT_PAGE";
export const REPLACE_IMAGE_PAGE = "REPLACE_IMAGE_PAGE";

// offer recommendation pages
export const OFFER_RECOMMENDATIONS_PAGE = "OFFER_RECOMMENDATIONS_PAGE";
export const OFFER_RECOMMENDATIONS_REPORT_PRINT_PAGE =
    "OFFER_RECOMMENDATIONS_REPORT_PRINT_PAGE";

export const CREATE_TEST_PAGE = "CREATE_TEST_PAGE";
export const OPPORTUNITIES_PAGE = "OPPORTUNITIES_PAGE";
export const OVERVIEW_PAGE = "OVERVIEW_PAGE";
export const RECOMMENDATIONS_PAGE = "RECOMMENDATIONS_PAGE";
export const REPORTS_PAGE = "REPORTS_PAGE";
export const SAVED_REPORTS_PAGE = "SAVED_REPORTS_PAGE";
