import { SIGNAL_KEY_PREFIX } from "common/shell/state/requestStateUtils";

// cpg promo request signal keys
export const SIGNAL_KEY_FETCH_GUIDELINES_COVERAGE =
    SIGNAL_KEY_PREFIX + "FetchGuidelinesCoverage";
export const SIGNAL_KEY_FETCH_GUIDELINES_COMPLIANCE =
    SIGNAL_KEY_PREFIX + "FetchGuidelinesCompliance";
export const SIGNAL_KEY_FETCH_RECENT_NOTIFICATIONS =
    SIGNAL_KEY_PREFIX + "FetchRecentNotifications";
export const SIGNAL_KEY_FETCH_TOP_PERFORMANCE =
    SIGNAL_KEY_PREFIX + "FetchTopPerformance";
