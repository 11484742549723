// actions
import get from "lodash.get";
import type { Action } from "redux";
import type { RootState } from "store";

export const HIDE_COMPONENT_LOADMASK = "HIDE_COMPONENT_LOADMASK";
export const HIDE_LOADMASK = "HIDE_LOADMASK";
export const SHOW_COMPONENT_LOADMASK = "SHOW_COMPONENT_LOADMASK";
export const SHOW_LOADMASK = "SHOW_LOADMASK";

export const isLoadingActive = (key: string, state: RootState): boolean => {
    let isActive = false;
    const keyArray = get(state, ["loadmask", "showComponentLoadmask", key], []);
    if (keyArray.length > 0) {
        isActive = true;
    }
    return isActive;
};

export type LoadmaskActions =
    | HideComponentLoadmask
    | HideLoadmask
    | ShowComponentLoadmask
    | ShowLoadmask;

interface HideComponentLoadmask extends Action {
    type: typeof HIDE_COMPONENT_LOADMASK;
    key: string;
}

export const hideComponentLoadmask = (
    signalKey: string
): HideComponentLoadmask => {
    return { type: HIDE_COMPONENT_LOADMASK, key: signalKey };
};

interface HideLoadmask extends Action {
    type: typeof HIDE_LOADMASK;
}

export const hideLoadmask = (): HideLoadmask => {
    return { type: HIDE_LOADMASK };
};

interface ShowComponentLoadmask extends Action {
    type: typeof SHOW_COMPONENT_LOADMASK;
    key: string;
}

export const showComponentLoadmask = (
    signalKey: string
): ShowComponentLoadmask => {
    return { type: SHOW_COMPONENT_LOADMASK, key: signalKey };
};

interface ShowLoadmask extends Action {
    type: typeof SHOW_LOADMASK;
}

export const showLoadmask = (): ShowLoadmask => {
    return { type: SHOW_LOADMASK };
};
