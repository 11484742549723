/*
Pulled from react-virtualized-tree version 3.4.1
This component was modified because it contained deprecated context API
*/
import * as React from "react";
import get from "lodash.get";
import {
    AutoSizer,
    List,
    CellMeasurerCache,
    CellMeasurer
} from "react-virtualized";
import type { Alignment } from "react-virtualized";
import TreeState, {
    State
} from "common/filter/reactVirtualizedTree/state/TreeState";
import type {
    FlattenedTreeNode,
    UpdateNode
} from "common/filter/reactVirtualizedTree/state/treeStateUtils";

const REACT_VIRTUALIZED_TREE_CLASS_NAME = "react-virtualized-tree";

type TreeProps = {
    nodes: FlattenedTreeNode[];
    nodeMarginLeft: number;
    NodeRenderer: React.ReactNode;
    onChange: (updateNode: UpdateNode) => void;
    scrollToAlignment?: string;
    scrollToIndex?: number;
    width?: number;
};

export default class Tree extends React.Component<TreeProps> {
    _list: HTMLDivElement | undefined | null;
    _cache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 20
    });

    getRowCount = (): number => {
        const { nodes } = this.props;

        return nodes instanceof State
            ? get(nodes, "flattenedTree.length")
            : nodes.length;
    };

    getNodeDeepness = (node: any, index: number): number => {
        const { nodes } = this.props;

        if (nodes instanceof State) {
            TreeState.getNodeDeepness(nodes, index);
        }

        return nodes instanceof State
            ? TreeState.getNodeDeepness(nodes, index)
            : node.deepness;
    };

    getNode = (index: number): FlattenedTreeNode => {
        const { nodes } = this.props;

        return nodes instanceof State
            ? {
                  ...TreeState.getNodeAt(nodes, index),
                  deepness: this.getNodeDeepness({}, index)
              }
            : nodes[index];
    };

    rowRenderer = ({ node, key, measure, style, NodeRenderer, index }: any) => {
        const { nodeMarginLeft, onChange } = this.props;

        return (
            <NodeRenderer
                key={key}
                style={{
                    ...style,
                    marginLeft: node.deepness * nodeMarginLeft,
                    userSelect: "none",
                    cursor: "pointer"
                }}
                node={node}
                onChange={onChange}
                measure={measure}
                index={index}
            />
        );
    };

    measureRowRenderer = (nodes: FlattenedTreeNode[]) => ({
        key,
        index,
        style,
        parent
    }: any) => {
        const { NodeRenderer } = this.props;
        const node = this.getNode(index);

        return (
            // @ts-expect-error CellMeasurer is not able to return as a JSX component, external code
            <CellMeasurer
                cache={this._cache}
                columnIndex={0}
                key={key}
                rowIndex={index}
                parent={parent}
            >
                {m =>
                    this.rowRenderer({
                        ...m,
                        index,
                        node,
                        key,
                        style,
                        NodeRenderer
                    })
                }
            </CellMeasurer>
        );
    };

    render() {
        const { nodes, scrollToAlignment, scrollToIndex, width } = this.props;

        return (
            // @ts-expect-error AutoSizer is not able to return as a JSX component, external code
            <AutoSizer disableWidth={Boolean(width)}>
                {({ height, width: autoWidth }) => (
                    // @ts-expect-error List is not able to return as a JSX component, external code
                    <List
                        className={REACT_VIRTUALIZED_TREE_CLASS_NAME}
                        deferredMeasurementCache={this._cache}
                        // @ts-expect-error List is not assignable as HTMLDiv, external code
                        ref={r => (this._list = r)}
                        height={height}
                        rowCount={this.getRowCount()}
                        rowHeight={this._cache.rowHeight}
                        rowRenderer={this.measureRowRenderer(nodes)}
                        width={width || autoWidth}
                        scrollToIndex={scrollToIndex}
                        // external code
                        scrollToAlignment={scrollToAlignment as Alignment}
                    />
                )}
            </AutoSizer>
        );
    }
}
