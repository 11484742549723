import get from "lodash.get";
import type { AnyAction } from "redux";
import { DESCENDING } from "common/util/query";
import {
    RECEIVE_TEST_PHASE_DATA,
    REQUEST_TEST_PHASE_DATA,
    UPDATE_TEST_PHASE_DATA_SORT
} from "testPhaseData/state/testPhaseDataActions";

const defaultState: Pick<
    ListQueryState,
    "sortBy" | "sortOrder" | "totalCount"
> = {
    sortBy: "consumerEngagementIndex",
    sortOrder: DESCENDING,
    totalCount: -1
};

function testPhaseData(state = defaultState, action: AnyAction) {
    switch (action.type) {
        case RECEIVE_TEST_PHASE_DATA:
            return Object.assign({}, state, {
                totalCount: get(action, "json.totalCount")
            });
        case REQUEST_TEST_PHASE_DATA:
            return state;
        case UPDATE_TEST_PHASE_DATA_SORT:
            return Object.assign({}, state, {
                sortBy: action.sortBy,
                sortOrder: action.sortOrder
            });
        default:
            return state;
    }
}

export default testPhaseData;
