import * as React from "react";
import type {
    ColDef,
    Column,
    GridReadyEvent,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    SortChangedEvent
} from "ag-grid-community";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import isNil from "lodash.isnil";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import type { MenuSubOptionType } from "common/components/ExpandingDropdown";
import ExportButton from "common/components/ExportButton";
import AgDataGrid, { SERVER_SIDE } from "common/components/grid/AgDataGrid";
import type { DataGridType } from "common/components/grid/AgDataGrid";
import {
    DATAGRID_COLUMN_ACTIONS,
    getRowsHandler
} from "common/components/grid/AgDataGridUtil";
import {
    AdministrationOuterTableWrapper,
    GridCount
} from "common/components/grid/CommonGridUtil";
import FiltersButtonDisplay from "common/components/grid/FiltersButtonDisplay";
import type { SelectedFilters } from "common/components/grid/FiltersButtonDisplay";
import Flexbox from "common/components/styled/Flexbox";
import theme from "common/components/theme";
import SearchBar from "common/filter/components/SearchBar";
import type { ReportStyle } from "common/reports/state/reportsStateUtils";
import { getReportStateKey } from "common/reports/state/reportsStateUtils";
import { GRID_PREFIX, REPORT_PREFIX } from "common/shell/util/preferencesUtils";
import { ASCENDING } from "common/util/query";
import { composeTrackingComponentLabel } from "common/util/tracking";
import type { TrackingComponentLabel } from "common/util/trackingEvents";
import ReportFiltersView from "reports/components/ReportFiltersView";
import {
    fetchReportDataTable,
    updateReportStyle,
    updateReportType,
    updateReportsDataTableFilters,
    updateReportsDataTableSearch,
    updateReportsDataTableSort
} from "reports/state/reportsActions";
import type {
    ReportFilters,
    ReportType,
    ReportView
} from "reports/state/reportsStateUtils";
import type { AppDispatch, RootState } from "store";
import styled from "styled-components/macro";

export type DataTableViewType = DataTableView | null;

export const getDataTableGridId = (reportView: ReportView): string => {
    return GRID_PREFIX + REPORT_PREFIX + reportView;
};

const GridCountHeader = styled(GridCount)`
    align-items: center;
    display: flex;
`;

type DataTableViewOwnProps = {
    additionalGridHeaderLeft?: React.ReactNode;
    additionalHeaderButtons?: React.ReactNode;
    autoColumnDefIds?: string[];
    columnDefinitions: ColumnDefinition[];
    defaultFilters: ReportFilters;
    exportUrl: string;
    getDataTableRequest: (
        reportView: ReportView,
        filters: ReportFilters,
        state: RootState
    ) => any;
    getGridRowMenu?: (rowData: any) => GridMenuItemType[];
    groupColumnDefinition?: ColDef<any>;
    groupKeyField?: string;
    hasBrandFilter?: boolean;
    hasCategoryFilter?: boolean;
    hasCategorySubcategoryFilter?: boolean;
    hasChannelFilter?: boolean;
    hasComparisonDateFilter?: boolean;
    hasDateFilter?: boolean;
    hasPpgFilter?: boolean;
    hasPromotionFilter?: boolean;
    hasUpcBrandFilter?: boolean;
    hasUpcPpgFilter?: boolean;
    onRef?: (ref: DataTableViewType) => void;
    reduceRows: (rows: any[]) => any[];
    reportDataDateHeader?: React.ReactNode;
    reportType: ReportType;
    reportView: ReportView;
    trackingComponentLabel: TrackingComponentLabel;
    url: string;
    writeOnlyToPreferenceState?: boolean;
    writeToPreferences?: boolean;
};

type DataTableViewProps = DataTableViewOwnProps &
    PropsFromRedux &
    WithTranslation;

type DataTableViewState = {
    filtersTooltip: SelectedFilters[];
    isDataTableFiltersOpen: boolean;
    rowCount: number;
    selectedRow: any;
};

class DataTableView extends React.Component<
    DataTableViewProps,
    DataTableViewState
> {
    static displayName = "DataTableView";
    dataGrid: DataGridType = null;

    state = {
        filtersTooltip: [],
        isDataTableFiltersOpen: false,
        isTrendSeriesModalOpen: false,
        rowCount: 0,
        selectedRow: null
    };

    componentDidMount() {
        const { defaultFilters, filters, onRef, updateFilters } = this.props;
        // set up ref access so parent can call this functions
        if (onRef) {
            onRef(this);
        }
        if (!filters) {
            // set default filters
            updateFilters(defaultFilters);
        }
        this.reFetchData();
    }

    componentDidUpdate(prevProps: DataTableViewProps) {
        const {
            columnDefinitions,
            filters,
            hasBrandFilter,
            hasCategoryFilter,
            hasCategorySubcategoryFilter,
            hasChannelFilter,
            hasComparisonDateFilter,
            hasDateFilter,
            hasPpgFilter,
            hasPromotionFilter,
            hasUpcBrandFilter,
            hasUpcPpgFilter,
            sortBy,
            sortOrder,
            updateFilters
        } = this.props;
        const newFilters = Object.assign({}, filters);
        let refetch = false;
        let updateSort = false;

        // Update filters based on hasFilters tests
        if (!hasBrandFilter && !hasUpcBrandFilter && filters.brands?.length) {
            newFilters.brands = [];
        }
        if (!hasCategoryFilter && filters.categories?.length) {
            newFilters.categories = [];
        }
        if (!hasCategorySubcategoryFilter && filters.subcategories?.length) {
            newFilters.subcategories = [];
        }
        if (!hasChannelFilter && filters.channels?.length) {
            newFilters.channels = [];
        }
        if (!hasDateFilter && filters.date) {
            newFilters.date = null;
        }
        if (!hasComparisonDateFilter && filters.comparisonDate) {
            newFilters.comparisonDate = null;
        }
        if (!hasPpgFilter && !hasUpcPpgFilter && filters.ppgs?.length) {
            newFilters.ppgs = [];
        }
        if (!hasPromotionFilter && filters.promotion) {
            newFilters.promotion = "";
        }
        // Filters changed, update filters an refetchData
        if (!isEqual(filters, newFilters)) {
            updateFilters(newFilters);
            refetch = true;
        } else if (!isEqual(filters, prevProps.filters)) {
            refetch = true;
        }
        // Avoid double fetch with else if, since updateColumnSort refetches data
        // Check if column definitions changed and reset column definitions
        if (
            !isEqual(columnDefinitions, prevProps.columnDefinitions) &&
            this.dataGrid
        ) {
            const newColumnDefinitions = [];
            newColumnDefinitions.push(...columnDefinitions);
            const defaultColumnDefinitions = this.dataGrid.getDefaultColumnsDefs();
            // See if there was an action column in the defaults.
            const actionColumnDefinition = defaultColumnDefinitions.find(
                (colDef: ColDef) => colDef.colId === DATAGRID_COLUMN_ACTIONS
            );
            // If there was an action column, it was added by the AgGrid component wrapper.
            // We need to add it to the new set of column defs to preserve it.
            if (actionColumnDefinition) {
                newColumnDefinitions.push(actionColumnDefinition);
            }
            updateSort = true;
            this.dataGrid.setColumnDefs(newColumnDefinitions);
            this.dataGrid.persistColumnPreferences();
        }
        // Check if sortBy or sortOrder changed, and update sort
        if (
            !isEqual(sortBy, prevProps.sortBy) ||
            !isEqual(sortOrder, prevProps.sortOrder)
        ) {
            updateSort = true;
        }

        // updateColumnSort will refetch data through getRows, avoid double fetching
        if (updateSort) {
            this.updateColumnSort();
        } else if (refetch) {
            this.reFetchData(false);
        }
    }

    // triggers a getRows call
    updateColumnSort = () => {
        const {
            autoColumnDefIds,
            columnDefinitions,
            groupColumnDefinition,
            sortBy,
            sortOrder
        } = this.props;
        // calling sort will trigger a reFetchData
        // Make sure sort and sortBy is correct
        if (this.dataGrid && sortBy && sortOrder) {
            const columns = this.dataGrid.getAllGridColumns();
            if (columns && columns.length > 0) {
                let colId = sortBy;
                if (autoColumnDefIds && autoColumnDefIds.includes(sortBy)) {
                    colId = "ag-Grid-AutoColumn";
                }
                const column = this.dataGrid.getColumn(colId);
                if (column && column.getSort() !== sortOrder) {
                    this.dataGrid.setSort(colId, sortOrder);
                } else if (!column) {
                    if (groupColumnDefinition) {
                        this.dataGrid.setSort(
                            groupColumnDefinition.field ?? "",
                            ASCENDING
                        );
                    } else if (
                        columnDefinitions &&
                        columnDefinitions.length > 0
                    ) {
                        this.dataGrid.setSort(
                            (columnDefinitions[0] as ColDef<any>).field ?? "",
                            ASCENDING
                        );
                    }
                }
            }
        }
    };

    onGridReady = (event: GridReadyEvent) => {
        this.updateColumnSort();
    };

    reFetchData = (resetScroll = true) => {
        this.dataGrid?.closeAllGroups();
        this.dataGrid?.reFetchData(resetScroll);
    };

    setAutoGroupColumnDef = (columnDef: ColDef) => {
        this.dataGrid?.setAutoColumnGroupDef(columnDef);
    };

    setColumnDefs = (columnDefs: ColDef[]) => {
        this.dataGrid?.setColumnDefs(columnDefs);
    };

    filtersTooltipChangeHandler = (filtersTooltip: SelectedFilters[]) => {
        this.setState({ filtersTooltip });
    };

    getFilterValues = (): ReportFilters => {
        return this.props.filters;
    };

    closeFiltersDrawer = () => {
        this.setState({
            isDataTableFiltersOpen: false
        });
    };

    openFiltersDrawer = () => {
        this.setState({
            isDataTableFiltersOpen: true
        });
    };

    onFilterChange = (
        prevFilters: ReportFilters,
        newFilters: ReportFilters
    ) => {
        this.reFetchData();
    };

    onClearFilters = () => {
        const { defaultFilters, updateFilters } = this.props;
        updateFilters(defaultFilters);
        this.reFetchData();
    };

    onSearchTextChange = (searchText: string) => {
        const { updateSearch } = this.props;
        updateSearch(searchText);
        this.reFetchData();
    };

    onSortChange = (event: SortChangedEvent) => {
        const { sortBy, sortOrder, updateSort } = this.props;
        if (this.dataGrid) {
            this.dataGrid.closeAllGroups();
            const columns: Column[] | null = this.dataGrid.getAllGridColumns();
            if (columns?.length) {
                const sortingColumn = columns.find(c => c.getSort());
                if (sortingColumn) {
                    let sortingField = sortingColumn.getColId();
                    if (sortingField === "ag-Grid-AutoColumn") {
                        sortingField = sortingColumn.getColDef().field ?? "";
                    }
                    if (
                        sortingField &&
                        (sortingField !== sortBy ||
                            sortingColumn.getSort() !== sortOrder)
                    ) {
                        updateSort(
                            sortingField,
                            sortingColumn.getSort() as SortOrder
                        );
                    }
                }
            }
        }
    };

    buildExportRequest = (state: RootState): any => {
        const { filters, getDataTableRequest, reportView } = this.props;
        return getDataTableRequest(reportView, filters, state);
    };

    getRowsSuccess = (result: any, params: IServerSideGetRowsParams) => {
        const { count, items } = result;
        params.success({
            rowCount: count,
            rowData: items
        });
        this.setState({
            rowCount: count
        });
    };

    getRows = (params: IServerSideGetRowsParams) => {
        const {
            defaultFilters,
            fetchDataTable,
            filters,
            getDataTableRequest,
            reduceRows,
            reportView,
            url
        } = this.props;
        // If the groupKeys property is not empty, then this is a parent/group row in a tree grid.
        // We just need to render its children.
        if (params.request.groupKeys.length > 0) {
            const children = params.parentNode.data.children ?? [];
            params.success({ rowCount: children.length, rowData: children });
        } else {
            const currentFilters = isNil(filters) ? defaultFilters : filters;
            const fetchData = () => {
                return fetchDataTable(
                    reportView,
                    url,
                    currentFilters,
                    getDataTableRequest,
                    reduceRows
                );
            };
            getRowsHandler({
                dataGrid: this.dataGrid,
                fetchData: fetchData,
                getRowsSuccess: this.getRowsSuccess,
                params: params
            });
        }
    };

    // This function is here for reports which uses a tree grid. Get the data field value containing the group key.
    getServerSideGroupKey = (data: any): string => {
        const { groupKeyField } = this.props;
        if (!isNil(groupKeyField)) {
            return data[groupKeyField];
        }
        return "";
    };

    // This function is also here for report views that use a tree grid.
    // If a data row has the children property defined and it is not empty,
    // then this is a parent/group row.
    isServerSideGroup = (data: any): boolean => {
        return get(data, "children.length", 0) > 0;
    };

    datasource: IServerSideDatasource = {
        getRows: this.getRows
    };

    render() {
        const {
            additionalGridHeaderLeft,
            additionalHeaderButtons,
            columnDefinitions,
            defaultFilters,
            exportUrl,
            filters,
            getGridRowMenu,
            groupColumnDefinition,
            hasBrandFilter,
            hasCategoryFilter,
            hasCategorySubcategoryFilter,
            hasChannelFilter,
            hasComparisonDateFilter,
            hasDateFilter,
            hasPpgFilter,
            hasPromotionFilter,
            hasUpcBrandFilter,
            hasUpcPpgFilter,
            reportDataDateHeader,
            reportType,
            reportView,
            search,
            t,
            trackingComponentLabel,
            writeOnlyToPreferenceState = false,
            writeToPreferences = true
        } = this.props;
        const { filtersTooltip, isDataTableFiltersOpen, rowCount } = this.state;
        const countHeader = t("common:general.x_item", {
            count: rowCount
        });
        const hasFilters =
            hasBrandFilter ||
            hasCategoryFilter ||
            hasCategorySubcategoryFilter ||
            hasChannelFilter ||
            hasComparisonDateFilter ||
            hasDateFilter ||
            hasPpgFilter ||
            hasPromotionFilter ||
            false;

        const isFilterSelected =
            !isNil(filters) && !isEqual(filters, defaultFilters);

        const gridHeaderLeft = (
            <Flexbox>
                <GridCountHeader>{countHeader}</GridCountHeader>
                {additionalGridHeaderLeft && (
                    <Flexbox marginRight="16px">
                        {additionalGridHeaderLeft}
                    </Flexbox>
                )}
            </Flexbox>
        );

        const gridHeaderRight = (
            <React.Fragment>
                <FiltersButtonDisplay
                    hideFilterButton={!hasFilters}
                    isSelected={isFilterSelected}
                    marginRight="10px"
                    onClear={this.onClearFilters}
                    onClick={this.openFiltersDrawer}
                    selectedFilters={filtersTooltip}
                    trackingComponentLabel={composeTrackingComponentLabel([
                        trackingComponentLabel,
                        "Filter Reports Data Table"
                    ])}
                />
                {additionalHeaderButtons}
                <SearchBar
                    searchText={search}
                    marginRight="10px"
                    onFilterChange={this.onSearchTextChange}
                    trackingComponentLabel={composeTrackingComponentLabel([
                        trackingComponentLabel,
                        "Search Reports Data Table"
                    ])}
                />
                <ExportButton
                    actionTokenUrl={exportUrl + "/init"}
                    downloadUrl={exportUrl}
                    exportInfo={this.buildExportRequest}
                    exportName={t("reports.table")}
                    trackingComponentLabel={[
                        "Reports Data Table",
                        reportType,
                        reportView
                    ]}
                />
            </React.Fragment>
        );

        const dataGridParams: any = {
            columnDefs: columnDefinitions,
            dataIdKey: "entityId",
            datasource: this.datasource,
            gridHeaderLeft: gridHeaderLeft,
            gridHeaderRight: gridHeaderRight,
            gridHeaderTop: reportDataDateHeader,
            id: getDataTableGridId(reportView),
            onGridReady: this.onGridReady,
            onRef: (ref: DataGridType) => (this.dataGrid = ref),
            onSortChanged: this.onSortChange,
            rowModelType: SERVER_SIDE,
            writeOnlyToPreferenceState: writeOnlyToPreferenceState,
            writeToPreferences: writeToPreferences
        };

        if (!isNil(groupColumnDefinition)) {
            dataGridParams.autoGroupColumnDef = groupColumnDefinition;
            dataGridParams.getServerSideGroupKey = this.getServerSideGroupKey;
            dataGridParams.isServerSideGroup = this.isServerSideGroup;
            dataGridParams.treeData = true;
        }

        dataGridParams.gridRowMenu = getGridRowMenu;

        return (
            <React.Fragment>
                <AdministrationOuterTableWrapper
                    backgroundColor={theme.universalBackground}
                >
                    <AgDataGrid {...dataGridParams} />
                </AdministrationOuterTableWrapper>
                <ReportFiltersView
                    closeHandler={this.closeFiltersDrawer}
                    defaultFilters={defaultFilters}
                    filtersTooltipChangeHandler={
                        this.filtersTooltipChangeHandler
                    }
                    hasBrandFilter={hasBrandFilter}
                    hasCategoryFilter={hasCategoryFilter}
                    hasCategorySubcategoryFilter={hasCategorySubcategoryFilter}
                    hasChannelFilter={hasChannelFilter}
                    hasComparisonDateFilter={hasComparisonDateFilter}
                    hasDateFilter={hasDateFilter}
                    hasPpgFilter={hasPpgFilter}
                    hasPromotionFilter={hasPromotionFilter}
                    hasUpcBrandFilter={hasUpcBrandFilter}
                    hasUpcPpgFilter={hasUpcPpgFilter}
                    isOpen={isDataTableFiltersOpen}
                    reportType={reportType}
                    reportView={reportView}
                    saveHandler={this.onFilterChange}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState, ownProps: DataTableViewOwnProps) => {
    const reportType = get(ownProps, "reportType");
    const reportView = get(ownProps, "reportView");
    return {
        filters: get(
            state,
            getReportStateKey(reportType, reportView, "dataTable.filters")
        ),
        search: get(
            state,
            getReportStateKey(reportType, reportView, "dataTable.search")
        ),
        sortBy: get(
            state,
            getReportStateKey(reportType, reportView, "dataTable.sortBy")
        ),
        sortOrder: get(
            state,
            getReportStateKey(reportType, reportView, "dataTable.sortOrder")
        )
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        fetchDataTable: (
            reportView: ReportView,
            url: string,
            filters: ReportFilters,
            buildRequest: (
                reportView: ReportView,
                filters: ReportFilters,
                state: RootState
            ) => any,
            reduceRows: (rows: any[]) => any[]
        ) => {
            return dispatch(
                fetchReportDataTable(
                    reportView,
                    url,
                    filters,
                    buildRequest,
                    reduceRows
                )
            );
        },
        updateFilters: (filters: ReportFilters) => {
            dispatch(updateReportsDataTableFilters(filters));
        },
        updateReportStyle: (reportStyle: ReportStyle) => {
            dispatch(updateReportStyle(reportStyle));
        },
        updateReportType: (
            reportType: ReportType,
            reportView: MenuSubOptionType
        ) => {
            dispatch(updateReportType(reportType, reportView));
        },
        updateSearch: (search: string) => {
            dispatch(updateReportsDataTableSearch(search));
        },
        updateSort: (sortBy: string, sortOrder: SortOrder) => {
            dispatch(updateReportsDataTableSort(sortBy, sortOrder));
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(DataTableView));
