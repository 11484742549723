import isNil from "lodash.isnil";
import {
    USER_ENABLED,
    USER_ENTITY_ID,
    USER_FULLNAME,
    USER_LASTNAME,
    USER_TEAM_NAME,
    USER_USERNAME
} from "common/administration/users/state/userStateUtils";
import type { TeamTypeType } from "common/shell/state/initStateUtils";
import { formatSortOrderForQueryAPI } from "common/shell/state/requestActions";
import {
    andFilterCondition,
    booleanFilterCondition,
    freeFormFilterCondition,
    notFilterCondition,
    stringFilterCondition,
    stringInFilterCondition,
    subqueryInFilterCondition
} from "common/util/filter";
import { isEmptyString } from "common/util/lang";
import {
    defaultCreatorModifierState,
    reduceCreatorModifier
} from "common/util/object";
import {
    ASCENDING,
    ASCENDING_QUERY_API,
    DEFAULT_PAGE_SIZE,
    PAGE_SIZE_UNLIMITED,
    sortCondition
} from "common/util/query";

// Sort by
export const TEAM_NAME = "name";
export const TEAM_TYPE = "teamType.name";

const RETAILER_ENTITY_ID = "entityId";
export const RETAILER_NAME = "displayName.value";
export const RETAILER_DISPLAY_NAME = "displayName";

// names for the drawers
export const RETAILERS_DRAWER = "RETAILERS_DRAWER";
export const USERS_DRAWER = "USERS_DRAWER";

export type GridTeam = {
    allowableActions: Dictionary<AllowableAction>;
    teamType: TeamType;
} & Team;

type DrawerState = {
    maxResults: number;
    sortBy: string;
    sortOrder: SortOrder;
    totalCount: number;
};

const retailersDefaultDrawer: DrawerState = {
    maxResults: DEFAULT_PAGE_SIZE,
    sortBy: RETAILER_DISPLAY_NAME,
    sortOrder: ASCENDING,
    totalCount: -1
};

const usersDefaultDrawer: DrawerState = {
    maxResults: DEFAULT_PAGE_SIZE,
    sortBy: USER_FULLNAME,
    sortOrder: ASCENDING,
    totalCount: -1
};

export type TeamState = {
    allowableActions: Dictionary<AllowableAction>;
    entityId: string;
    name: string;
    retailersDrawer: DrawerState;
    teamType: TeamTypeType;
    usersDrawer: DrawerState;
} & CreatorModifierData;

export const defaultState: TeamState = {
    allowableActions: {},
    entityId: "",
    name: "",
    teamType: {
        canAccessAllInstances: false,
        description: "",
        entityId: "",
        name: ""
    },
    retailersDrawer: retailersDefaultDrawer,
    usersDrawer: usersDefaultDrawer,
    ...defaultCreatorModifierState
};

const reduceTeamType = (data: GridTeam): TeamTypeType => {
    return {
        canAccessAllInstances: data.teamType.canAccessAllInstances,
        description: data.teamType.description?.value ?? "",
        name: data.teamType.name,
        entityId: data.teamType.entityId
    };
};

export const reduceGridTeam = (data: GridTeam) => {
    return {
        allowableActions: data.allowableActions,
        entityId: data.entityId,
        name: data.name,
        teamType: reduceTeamType(data)
    };
};

export const reduceTeam = (data: GridTeam, state: TeamState): TeamState => {
    const creatorModifier = reduceCreatorModifier(data);
    return {
        allowableActions: data.allowableActions,
        entityId: data.entityId,
        name: data.name,
        retailersDrawer: state.retailersDrawer,
        teamType: reduceTeamType(data),
        usersDrawer: state.usersDrawer,
        ...creatorModifier
    };
};

export const getTeamDrawerRetailersRequest = (
    search: string,
    maxResults: number,
    offset: number,
    sortBy: string,
    sortOrder: SortOrder,
    selected: string[],
    team: TeamState
): ListQueryRequest => {
    const filterConditions: FilterCondition[] = [];
    if (!isNil(search) && !isEmptyString(search)) {
        filterConditions.push(
            freeFormFilterCondition(search, [RETAILER_DISPLAY_NAME])
        );
    }
    if (selected.length) {
        filterConditions.push(stringInFilterCondition("entityId", selected));
    }
    filterConditions.push(
        notFilterCondition(
            subqueryInFilterCondition(
                "id",
                "Team",
                "retailers.id",
                stringFilterCondition("entityId", team.entityId, "EQUALS")
            )
        )
    );
    filterConditions.push(
        subqueryInFilterCondition(
            "id",
            "TenantConfiguration",
            "supportedRetailers.id"
        )
    );
    const filterCondition =
        filterConditions.length > 0
            ? filterConditions.length > 1
                ? andFilterCondition(filterConditions)
                : filterConditions[0]
            : null;

    const sOrder = formatSortOrderForQueryAPI(sortOrder);

    return {
        entity: "Retailer",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: maxResults,
        model: "RetailerModel/readFields",
        objectType: "ListQuery",
        postSortCondition: null,
        sortConditions: [sortCondition(sortBy, sOrder)],
        startIndex: offset
    };
};

export const getTeamDrawerUsersRequest = (
    search: string,
    maxResults: number,
    offset: number,
    sortBy: string,
    sortOrder: SortOrder,
    selected: string[],
    notSelected: string[]
): ListQueryRequest => {
    const filterConditions: FilterCondition[] = [
        booleanFilterCondition("isHidden", false)
    ];
    if (!isNil(search) && !isEmptyString(search)) {
        filterConditions.push(
            freeFormFilterCondition(search, [
                USER_FULLNAME,
                USER_TEAM_NAME,
                USER_USERNAME
            ])
        );
    }
    if (selected.length) {
        filterConditions.push(stringInFilterCondition("entityId", selected));
    }
    if (notSelected.length) {
        filterConditions.push(
            notFilterCondition(stringInFilterCondition("entityId", notSelected))
        );
    }
    const filterCondition = filterConditions.length
        ? filterConditions.length > 1
            ? andFilterCondition(filterConditions)
            : filterConditions[0]
        : null;

    const sOrder = formatSortOrderForQueryAPI(sortOrder);

    return {
        entity: "User",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: maxResults,
        objectType: "ListQuery",
        propertySelection: [
            USER_TEAM_NAME,
            USER_ENABLED,
            USER_ENTITY_ID,
            USER_FULLNAME,
            USER_LASTNAME,
            USER_USERNAME
        ],
        postSortCondition: null,
        sortConditions: [sortCondition(sortBy, sOrder)],
        startIndex: offset
    };
};

export const getTeamUsersRequest = (teamEntityId: string): ListQueryRequest => {
    const filterConditions = [booleanFilterCondition("isHidden", false)];
    filterConditions.push(
        stringFilterCondition("team.entityId", teamEntityId, "EQUALS")
    );
    return {
        entity: "User",
        filterCondition: andFilterCondition(filterConditions),
        includeCrossTenantInstances: false,
        maxResults: PAGE_SIZE_UNLIMITED,
        objectType: "ListQuery",
        propertySelection: [
            USER_ENABLED,
            USER_ENTITY_ID,
            USER_FULLNAME,
            USER_USERNAME
        ],
        postSortCondition: null,
        sortConditions: [sortCondition(USER_FULLNAME, ASCENDING_QUERY_API)],
        startIndex: 0
    };
};

export const getTeamRetailersRequest = (
    teamEntityId: string
): ListQueryRequest => {
    const filterConditions: FilterCondition[] = [];
    filterConditions.push(
        subqueryInFilterCondition(
            "id",
            "TenantConfiguration",
            "supportedRetailers.id"
        )
    );
    filterConditions.push(
        subqueryInFilterCondition(
            "id",
            "Team",
            "retailers.id",
            stringFilterCondition("entityId", teamEntityId, "EQUALS")
        )
    );
    return {
        entity: "Retailer",
        filterCondition: andFilterCondition(filterConditions),
        includeCrossTenantInstances: false,
        maxResults: PAGE_SIZE_UNLIMITED,
        objectType: "ListQuery",
        propertySelection: [RETAILER_NAME, RETAILER_ENTITY_ID],
        postSortCondition: null,
        sortConditions: [
            sortCondition(RETAILER_DISPLAY_NAME, ASCENDING_QUERY_API)
        ],
        startIndex: 0
    };
};
