import { CUSTOM } from "common/util/date";
import type { DateFilter, DateType } from "common/util/date";

// Report styles
export const DATA_TABLE = "DATA_TABLE";
export const TREND = "TREND";

export type ReportStyle = typeof DATA_TABLE | typeof TREND;

// Helpers to grab report states and init values
export const getReportStateKey = <ReportType, ReportView>(
    reportType: ReportType,
    reportView: ReportView,
    place?: string
): string => {
    return (
        "reports." + reportType + "." + reportView + (place ? "." + place : "")
    );
};

export const getDateFilter = (
    time: DateType,
    startDate: string,
    endDate: string
): DateFilter => {
    const date: DateFilter = {
        time
    };
    if (time === CUSTOM) {
        date.startDate = startDate;
        date.endDate = endDate;
    }
    return date;
};
