import get from "lodash.get";
import type { AnyAction } from "redux";
import { DESCENDING, DEFAULT_PAGE_SIZE } from "common/util/query";
import {
    RECEIVE_SMART_IMAGE_SETS,
    REQUEST_SMART_IMAGE_SETS,
    UPDATE_SMART_IMAGE_SETS_SEARCH,
    UPDATE_SMART_IMAGE_SETS_SORT,
    UPDATE_SMART_IMAGE_SETS_START_INDEX
} from "administration/images/state/imagesActions";

type ImagesState = {
    maxResults: number;
    search: string;
    sortBy: string;
    sortOrder: SortOrder;
    startIndex: number;
    totalCount: number;
};

const defaultImagesState: ImagesState = {
    maxResults: DEFAULT_PAGE_SIZE,
    search: "",
    sortBy: "relevance",
    sortOrder: DESCENDING,
    startIndex: 0,
    totalCount: -1
};

function images(state: ImagesState = defaultImagesState, action: AnyAction) {
    switch (action.type) {
        case REQUEST_SMART_IMAGE_SETS:
            return state;
        case RECEIVE_SMART_IMAGE_SETS:
            return Object.assign({}, state, {
                totalCount: get(action, "smartImageSets.totalCount")
            });
        case UPDATE_SMART_IMAGE_SETS_START_INDEX:
            return Object.assign({}, state, {
                startIndex: action.startIndex
            });
        case UPDATE_SMART_IMAGE_SETS_SEARCH:
            return Object.assign({}, state, {
                search: action.search
            });
        case UPDATE_SMART_IMAGE_SETS_SORT:
            return Object.assign({}, state, {
                sortBy: action.sortBy,
                sortOrder: action.sortOrder
            });

        default:
            return state;
    }
}

export default images;
