import type { AnyAction } from "redux";
export const RECEIVE_ASYNC_ITEMS = "RECEIVE_ASYNC_ITEMS" as string;
export const REQUEST_ASYNC_ITEMS = "REQUEST_ASYNC_ITEMS" as string;

export const receiveAsyncItems = (json: any): AnyAction => ({
    type: RECEIVE_ASYNC_ITEMS,
    json
});

export const requestAsyncItems = (): AnyAction => ({
    type: REQUEST_ASYNC_ITEMS
});
