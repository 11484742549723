import * as React from "react";
import TooltipWrapper from "common/components/styled/TooltipWrapper";

/* TODO: UPDATE THIS! Moved up to version 27 of ag-grid with new react functionality for customizing grid
    Default tooltip component used for ag grid. Trying to use styling similar to tippy so using tippy class names.
    Loaded this tooltip as defaultTooltip as part of frameworkComponents in AgDataGrid
    Notes:
    - Can use rowIndex to check if tooltip is for row or header, undefined for header
    - If using tooltip for header, always needs to pass "headerTooltip" field even if using more complex styling
      Use EMPTY_HEADER to trigger complex styling
    - Can pass in "tooltipField" as part of column definition to show simple tooltip for rows
    - Can pass in "headerTooltip" as part of column definition to show simple tooltip for header
    - Need to specify "tooltipComponent" for group headers
    - If need custom tooltip for both header and row data for same column definition then need to create new tooltip
      and load it into frameworkComponents in AgGrid. Should use conditional in this new component with rowIndex value
      to determine difference between row and header and default back to this AgGridTooltip component when possible
 */

export const LIGHT_THEME = "LIGHT_THEME";
const DARK_THEME = "DARK_THEME";
export const EMPTY_HEADER_TOOLTIP_HOLDER = "EMPTY_HEADER";

type AgGridTheme = typeof LIGHT_THEME | typeof DARK_THEME;

type AgGridTooltipProps = {
    rowIndex?: number;
    theme: AgGridTheme; // tooltipComponentsParams: Theme type
    tooltipContent?: React.ReactNode; // tooltipComponentsParams: For complex tooltip
    value: string; // column value
};

class AgGridTooltip extends React.PureComponent<AgGridTooltipProps> {
    static displayName = "AgGridTooltip";

    render() {
        const { theme, tooltipContent, value } = this.props;

        const content =
            value === EMPTY_HEADER_TOOLTIP_HOLDER ? tooltipContent : value;

        let tooltipStyle = "tippy-tooltip tooltip";
        switch (theme) {
            case LIGHT_THEME:
                tooltipStyle += " light-border-theme";
                break;
            case DARK_THEME:
                tooltipStyle += " dark-theme";
                break;
            default:
                tooltipStyle += " light-border-theme";
                break;
        }

        return (
            <div
                className="tippy-popper"
                data-animation="shift-away"
                data-state="visible"
                x-placement="bottom"
            >
                <div className={tooltipStyle} x-placement="bottom">
                    <div className="tippy-arrow"></div>
                    <div className="tippy-content">
                        <TooltipWrapper maxWidth="360px" minWidth="100px">
                            {content}
                        </TooltipWrapper>
                    </div>
                </div>
            </div>
        );
    }
}

export default AgGridTooltip;
