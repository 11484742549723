import update from "immutability-helper";
import get from "lodash.get";
import type { AnyAction } from "redux";
import { ASCENDING, DEFAULT_PAGE_SIZE, DESCENDING } from "common/util/query";
import {
    RECEIVE_ANALYSIS_CAMPAIGNS,
    RECEIVE_CAMPAIGN_COMPARISON_PROMOTIONS,
    RECEIVE_CHART_DESIGN,
    RECEIVE_CHART_DESIGN_BY_ID,
    RECEIVE_CHART_RECORD_DATA,
    RECEIVE_COMPARISON_CAMPAIGNS,
    RECEIVE_CUSTOM_FACTOR_DATA,
    RECEIVE_CUSTOM_FACTOR_DATA_CREATE,
    RECEIVE_FACTOR_ANALYSIS_CHART_LIST,
    RECEIVE_FACTOR_CAMPAIGNS,
    RECEIVE_FACTOR_OFFERS,
    RECEIVE_INITIAL_CHART_DESIGN,
    RECEIVE_OFFER_DATA,
    RECEIVE_PROMOTION_PAIRS,
    RECEIVE_SAVED_ANALYSES,
    RECEIVE_SAVED_ANALYSES_CAMPAIGNS,
    RECEIVE_SAVED_SEGMENT_SETS,
    REQUEST_ANALYSIS_CAMPAIGNS,
    REQUEST_CAMPAIGN_COMPARISON_PROMOTIONS,
    REQUEST_CHART_DESIGN,
    REQUEST_CHART_DESIGN_BY_ID,
    REQUEST_CHART_RECORD_DATA,
    REQUEST_COMPARISON_CAMPAIGNS,
    REQUEST_CUSTOM_FACTOR_DATA,
    REQUEST_CUSTOM_FACTOR_DATA_CREATE,
    REQUEST_FACTOR_ANALYSIS_CHART_LIST,
    REQUEST_FACTOR_CAMPAIGNS,
    REQUEST_FACTOR_OFFERS,
    REQUEST_INITIAL_CHART_DESIGN,
    REQUEST_OFFER_DATA,
    REQUEST_PROMOTION_PAIRS,
    REQUEST_SAVED_ANALYSES,
    REQUEST_SAVED_ANALYSES_CAMPAIGNS,
    REQUEST_SAVED_SEGMENT_SETS,
    RESET_CHART_DESIGN,
    UPDATE_ANALYSIS_CAMPAIGN,
    UPDATE_ANALYSIS_CHART_DEF,
    UPDATE_ANALYSIS_CHART_DESIGN,
    UPDATE_ANALYSIS_EDITED,
    UPDATE_ANALYSIS_LEGEND,
    UPDATE_ANALYSIS_OFFER_LABEL_TO_EDIT,
    UPDATE_ANALYSIS_TYPE,
    UPDATE_DISABLE_OUTSIDE_CLICK,
    UPDATE_EXPORT_DATA_TABLE_SETTINGS,
    UPDATE_FACTOR_ANALYSIS_CHART_LIST,
    UPDATE_FACTOR_ANALYSIS_CHART_LIST_CHARTS,
    UPDATE_SAVED_ANALYSIS_ANALYSIS_TYPE_FILTER,
    UPDATE_SAVED_ANALYSIS_CAMPAIGN_FILTER,
    UPDATE_SEARCH_SAVED_ANALYSES,
    UPDATE_SORT_ANALYSIS_CHART_RECORDS,
    UPDATE_SORT_ANALYSIS_CUSTOM_FACTORS,
    UPDATE_SORT_ANALYSIS_OFFERS,
    UPDATE_SORT_FACTOR_CAMPAIGNS,
    UPDATE_SORT_FACTOR_OFFERS,
    UPDATE_SORT_SAVED_ANALYSES,
    UPDATE_START_INDEX_FACTOR_CAMPAIGNS,
    UPDATE_START_INDEX_FACTOR_OFFERS,
    UPDATE_START_INDEX_SAVED_ANALYSES
} from "analysis/state/analysisActions";
import type { AnalysisType } from "analysis/state/analysisStateUtils";
import {
    ANALYSIS_TYPE_FACTOR,
    FACTOR_NAME,
    PROMOTION_NAME
} from "analysis/state/analysisStateUtils";
import {
    ANALYSIS_CAMPAIGN_COMPARISON_PAGE,
    ANALYSIS_CAMPAIGN_PAGE,
    ANALYSIS_FACTOR_PAGE
} from "shell/state/pageActions";

type AnalysisCampaignState = {
    analysisType: AnalysisType | null;
    campaign: Campaign | null;
    campaigns: Campaign[];
    chartDesign: ChartDesign | null;
    chartRecords: {
        sortBy: string;
        sortOrder: SortOrder;
        totalCount: number;
    };
    customFactors: {
        sortBy: string;
        sortOrder: SortOrder;
        totalCount: number;
    };
    disableClickOutside: boolean;
    edited: boolean;
    entityId: string;
    exportOptions: {
        gridColumns: string | null;
        gridSortConditions: string | null;
    };
    factor: {
        campaigns: {
            search: string;
            sortBy: string;
            sortOrder: SortOrder;
            startIndex: number;
            totalCount: number;
        };
        offers: {
            search: string;
            sortBy: string;
            sortOrder: SortOrder;
            startIndex: number;
            totalCount: number;
        };
    };
    factorAnalysisChartList: FactorAnalysisChartList | null;
    offerToEditEntityId: string;
    offers: {
        sortBy: string;
        sortOrder: SortOrder;
        totalCount: number;
    };
    savedAnalyses: {
        analysisTypes: AnalysisType[];
        campaigns: string[];
        maxResults: number;
        search: string;
        sortBy: string;
        sortOrder: SortOrder;
        startIndex: number;
        totalCount: number;
    };
    segmentSets: {
        totalCount: number;
    };
};

const defaultState: AnalysisCampaignState = {
    analysisType: null,
    campaign: null,
    campaigns: [],
    chartDesign: null,
    chartRecords: {
        sortBy: "promotion.name",
        sortOrder: ASCENDING,
        totalCount: -1
    },
    customFactors: {
        sortBy: FACTOR_NAME,
        sortOrder: ASCENDING,
        totalCount: -1
    },
    disableClickOutside: false,
    edited: false,
    entityId: "",
    exportOptions: {
        gridColumns: null,
        gridSortConditions: null
    },
    factor: {
        campaigns: {
            search: "",
            sortBy: "name",
            sortOrder: ASCENDING,
            startIndex: 0,
            totalCount: -1
        },
        offers: {
            search: "",
            sortBy: "name",
            sortOrder: ASCENDING,
            startIndex: 0,
            totalCount: -1
        }
    },
    factorAnalysisChartList: null,
    offerToEditEntityId: "",
    offers: {
        sortBy: PROMOTION_NAME,
        sortOrder: ASCENDING,
        totalCount: -1
    },
    savedAnalyses: {
        analysisTypes: [],
        campaigns: [],
        maxResults: DEFAULT_PAGE_SIZE,
        search: "",
        sortBy: "modifiedDate",
        sortOrder: DESCENDING,
        startIndex: 0,
        totalCount: -1
    },
    segmentSets: {
        totalCount: -1
    }
};

function analysis(
    state: AnalysisCampaignState = defaultState,
    action: AnyAction
) {
    switch (action.type) {
        case ANALYSIS_CAMPAIGN_PAGE:
            return Object.assign({}, defaultState, {
                entityId: action.payload && action.payload.entityId
            });
        case ANALYSIS_CAMPAIGN_COMPARISON_PAGE:
            return Object.assign({}, defaultState, {
                entityId: action.payload && action.payload.entityId
            });
        case ANALYSIS_FACTOR_PAGE:
            return Object.assign({}, defaultState, {
                entityId: action.payload && action.payload.entityId
            });
        case RECEIVE_ANALYSIS_CAMPAIGNS:
            return Object.assign({}, state, {
                campaigns: get(action, "json.result")
            });
        case REQUEST_ANALYSIS_CAMPAIGNS:
            return state;
        case RECEIVE_COMPARISON_CAMPAIGNS:
            return state;
        case REQUEST_COMPARISON_CAMPAIGNS:
            return state;
        case RECEIVE_CAMPAIGN_COMPARISON_PROMOTIONS:
            return state;
        case REQUEST_CAMPAIGN_COMPARISON_PROMOTIONS:
            return state;
        case RECEIVE_PROMOTION_PAIRS:
            return state;
        case REQUEST_PROMOTION_PAIRS:
            return state;
        case RECEIVE_INITIAL_CHART_DESIGN:
            return Object.assign({}, state, {
                chartDesign: get(action, "json")
            });
        case REQUEST_INITIAL_CHART_DESIGN:
            return Object.assign({}, state, {
                chartDesign: null
            });
        case RECEIVE_CHART_DESIGN:
            return Object.assign({}, state, {
                chartDesign: get(action, "json")
            });
        case REQUEST_CHART_DESIGN:
            return state;
        case RECEIVE_CHART_DESIGN_BY_ID:
            return Object.assign({}, state, {
                analysisType: get(action, "json.definition.chartType.value"),
                campaign: get(action, "json.definition.campaign"),
                chartDesign: get(action, "json"),
                entityId: ""
            });
        case REQUEST_CHART_DESIGN_BY_ID:
            return state;
        case RECEIVE_FACTOR_ANALYSIS_CHART_LIST:
            return Object.assign({}, state, {
                analysisType: ANALYSIS_TYPE_FACTOR,
                entityId: "",
                factorAnalysisChartList: action.json
            });
        case REQUEST_FACTOR_ANALYSIS_CHART_LIST:
            return state;
        case RECEIVE_FACTOR_CAMPAIGNS:
            return Object.assign({}, state, {
                factor: {
                    ...state.factor,
                    campaigns: {
                        ...state.factor.campaigns,
                        totalCount: get(action, "json.totalCount")
                    }
                }
            });
        case REQUEST_FACTOR_CAMPAIGNS:
            return state;
        case RECEIVE_FACTOR_OFFERS:
            return Object.assign({}, state, {
                factor: {
                    ...state.factor,
                    offers: {
                        ...state.factor.offers,
                        totalCount: get(action, "json.totalCount")
                    }
                }
            });
        case REQUEST_FACTOR_OFFERS:
            return state;
        case REQUEST_CHART_RECORD_DATA:
            return state;
        case RECEIVE_CHART_RECORD_DATA:
            return Object.assign({}, state, {
                chartRecords: {
                    ...state.chartRecords,
                    totalCount: get(action, "json.totalCount")
                }
            });
        case REQUEST_CUSTOM_FACTOR_DATA:
            return state;
        case RECEIVE_CUSTOM_FACTOR_DATA:
            return Object.assign({}, state, {
                customFactors: {
                    totalCount: get(action, "json.totalCount")
                }
            });
        case REQUEST_SAVED_SEGMENT_SETS:
            return state;
        case RECEIVE_SAVED_SEGMENT_SETS:
            return Object.assign({}, state, {
                segmentSets: {
                    totalCount: get(action, "json.totalCount")
                }
            });

        case REQUEST_SAVED_ANALYSES:
            return state;
        case RECEIVE_SAVED_ANALYSES:
            return Object.assign({}, state, {
                savedAnalyses: {
                    ...state.savedAnalyses,
                    totalCount: get(action, "json.totalCount")
                }
            });
        case REQUEST_SAVED_ANALYSES_CAMPAIGNS:
            return state;
        case RECEIVE_SAVED_ANALYSES_CAMPAIGNS:
            return state;
        case REQUEST_CUSTOM_FACTOR_DATA_CREATE:
            return state;
        case RECEIVE_CUSTOM_FACTOR_DATA_CREATE:
            return state;
        case REQUEST_OFFER_DATA:
            return state;
        case RECEIVE_OFFER_DATA:
            return Object.assign({}, state, {
                offers: {
                    totalCount: get(action, "json.totalCount")
                }
            });
        case RESET_CHART_DESIGN:
            return Object.assign({}, state, {
                chartDesign: defaultState.chartDesign,
                chartRecords: defaultState.chartRecords,
                customFactors: defaultState.customFactors,
                disableClickOutside: defaultState.disableClickOutside,
                edited: false,
                entityId: "",
                offerToEditEntityId: defaultState.offerToEditEntityId,
                offers: defaultState.offers,
                savedAnalyses: defaultState.savedAnalyses,
                segmentSets: defaultState.segmentSets
            });
        case UPDATE_ANALYSIS_CAMPAIGN:
            return Object.assign({}, state, {
                campaign: action.campaign
            });
        case UPDATE_SAVED_ANALYSIS_CAMPAIGN_FILTER:
            return Object.assign({}, state, {
                savedAnalyses: {
                    ...state.savedAnalyses,
                    campaigns: action.campaignEntityIds
                }
            });
        case UPDATE_SAVED_ANALYSIS_ANALYSIS_TYPE_FILTER:
            return Object.assign({}, state, {
                savedAnalyses: {
                    ...state.savedAnalyses,
                    analysisTypes: action.analysisTypes
                }
            });
        case UPDATE_ANALYSIS_CHART_DEF:
            return Object.assign({}, state, {
                chartDesign: {
                    ...state.chartDesign,
                    chartDef: action.chartDef
                }
            });
        case UPDATE_ANALYSIS_CHART_DESIGN:
            return Object.assign({}, state, {
                chartDesign: action.chartDesign
            });
        case UPDATE_ANALYSIS_LEGEND:
            return update(state, {
                chartDesign: {
                    definition: {
                        seriesIdentifier: {
                            $set: action.seriesIdentifier
                        }
                    }
                }
            });
        case UPDATE_ANALYSIS_EDITED:
            return Object.assign({}, state, {
                edited: action.edited
            });
        case UPDATE_ANALYSIS_OFFER_LABEL_TO_EDIT: {
            return Object.assign({}, state, {
                offerToEditEntityId: action.offerToEditEntityId
            });
        }
        case UPDATE_ANALYSIS_TYPE:
            return Object.assign({}, state, {
                analysisType: action.analysisType
            });
        case UPDATE_DISABLE_OUTSIDE_CLICK:
            return Object.assign({}, state, {
                disableClickOutside: action.disable
            });
        case UPDATE_EXPORT_DATA_TABLE_SETTINGS: {
            return Object.assign({}, state, {
                exportOptions: {
                    gridColumns: action.gridColumns,
                    gridSortConditions: action.gridSortConditions
                }
            });
        }
        case UPDATE_FACTOR_ANALYSIS_CHART_LIST:
            return Object.assign({}, state, {
                factorAnalysisChartList: action.factorAnalysisChartList
            });
        case UPDATE_FACTOR_ANALYSIS_CHART_LIST_CHARTS:
            return Object.assign({}, state, {
                factorAnalysisChartList: {
                    ...state.factorAnalysisChartList,
                    charts: action.charts
                }
            });
        case UPDATE_SEARCH_SAVED_ANALYSES:
            return Object.assign({}, state, {
                savedAnalyses: { ...state.savedAnalyses, search: action.search }
            });
        case UPDATE_SORT_ANALYSIS_CHART_RECORDS:
            return Object.assign({}, state, {
                chartRecords: {
                    ...state.chartRecords,
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder
                }
            });
        case UPDATE_SORT_ANALYSIS_CUSTOM_FACTORS:
            return Object.assign({}, state, {
                customFactors: {
                    ...state.customFactors,
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder
                }
            });
        case UPDATE_SORT_ANALYSIS_OFFERS:
            return Object.assign({}, state, {
                offers: {
                    ...state.offers,
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder
                }
            });
        case UPDATE_SORT_FACTOR_CAMPAIGNS:
            return Object.assign({}, state, {
                factor: {
                    ...state.factor,
                    campaigns: {
                        ...state.factor.campaigns,
                        sortBy: action.sortBy,
                        sortOrder: action.sortOrder
                    }
                }
            });
        case UPDATE_START_INDEX_FACTOR_CAMPAIGNS:
            return Object.assign({}, state, {
                factor: {
                    ...state.factor,
                    campaigns: {
                        ...state.factor.campaigns,
                        startIndex: action.startIndex
                    }
                }
            });
        case UPDATE_SORT_FACTOR_OFFERS:
            return Object.assign({}, state, {
                factor: {
                    ...state.factor,
                    offers: {
                        ...state.factor.offers,
                        sortBy: action.sortBy,
                        sortOrder: action.sortOrder
                    }
                }
            });
        case UPDATE_START_INDEX_FACTOR_OFFERS:
            return Object.assign({}, state, {
                factor: {
                    ...state.factor,
                    offers: {
                        ...state.factor.offers,
                        startIndex: action.startIndex
                    }
                }
            });
        case UPDATE_SORT_SAVED_ANALYSES:
            return Object.assign({}, state, {
                savedAnalyses: {
                    ...state.savedAnalyses,
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder
                }
            });
        case UPDATE_START_INDEX_SAVED_ANALYSES:
            return Object.assign({}, state, {
                savedAnalyses: {
                    ...state.savedAnalyses,
                    startIndex: action.startIndex
                }
            });
        default:
            return state;
    }
}

export default analysis;
