import * as React from "react";
import {
    RadioCheckboxCheckbox,
    RadioCheckboxMark
} from "common/components/RadioCheckbox";
import {
    marginPropTypes,
    paddingPropTypes
} from "common/components/styled/util";
import { reduceObject } from "common/util/object";

type CheckboxProps = {
    checked: boolean;
    disabled?: boolean;
    gridColumn?: boolean;
    label?: string;
    labelNode?: React.ReactNode;
    labelSelectable?: boolean;
    name: string;
    onChange?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    tabIndex?: number;
    value?: string;
} & marginPropsType &
    paddingPropsType;

class Checkbox extends React.Component<CheckboxProps> {
    checkbox: HTMLInputElement | undefined | null;

    static defaultProps = {
        gridColumn: false,
        labelSelectable: true
    };

    onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if (this.checkbox && event.key === " ") {
            this.checkbox.click();
        }
    };

    setCheckboxRef = (input?: HTMLInputElement | null) => {
        this.checkbox = input;
    };

    render() {
        const {
            checked,
            disabled,
            label,
            labelNode,
            labelSelectable,
            name,
            onChange,
            value,
            tabIndex,
            gridColumn
        } = this.props;
        let tIndex = 0;
        if (tabIndex) {
            tIndex = tabIndex;
        }
        return (
            <RadioCheckboxCheckbox
                disabled={disabled}
                labelSelectable={labelSelectable}
                {...reduceObject(this.props, ...marginPropTypes)}
                {...reduceObject(this.props, ...paddingPropTypes)}
            >
                {label}
                {labelNode}
                <input
                    checked={checked}
                    disabled={disabled}
                    id={name}
                    name={name}
                    onChange={onChange}
                    ref={this.setCheckboxRef}
                    tabIndex={-1}
                    type="checkbox"
                    value={value}
                />
                <RadioCheckboxMark
                    gridColumn={gridColumn}
                    onKeyDown={this.onKeyDown}
                    tabIndex={tIndex}
                />
            </RadioCheckboxCheckbox>
        );
    }
}

export default Checkbox;
