import * as React from "react";
import BaseModal from "react-modal";
import styled from "styled-components/macro";
import { FieldLabel } from "common/components/styled/Label";
import { flexboxProps } from "common/components/styled/util";
import theme from "common/components/theme";
import { RadioCheckboxCheckbox } from "common/components/RadioCheckbox";
import { ClickableSvgIcon } from "common/components/SvgIcon";
import Flexbox from "common/components/styled/Flexbox";
import { ReactComponent as XIcon } from "common/icons/X.svg";
import { emptyFn } from "common/util/lang";
import { getTrackingEventData, trackEvent } from "common/util/tracking";
import {
    EVENT_NAME_OPEN_CLOSE,
    EVENT_VALUE_CLOSE_MODAL,
    EVENT_VALUE_OPEN_MODAL
} from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";

type ModalWrapperProps = {
    backgroundColor?: string;
    color?: string;
    cssHeight?: string;
    cssWidth?: string;
    maxHeight?: string;
};

export const ModalWrapper = styled.div<ModalWrapperProps>`
    background-color: ${props =>
        props.backgroundColor ? props.backgroundColor : theme.background};
    border: 1px solid ${theme.border};
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 ${theme.boxShadowColor};
    color: ${props => (props.color ? props.color : props.theme.text)};
    display: flex;
    flex-direction: column;

    ${props => props.cssWidth && `width: ${props.cssWidth};`}
    ${props => props.cssHeight && `height: ${props.cssHeight};`}
    ${props => props.maxHeight && `max-height: ${props.maxHeight};`}
`;
ModalWrapper.displayName = "ModalWrapper";

export const ModalTitle = styled.div`
    display: inline-block;
    font-size: 16px;
    font-family: "BrandonTextBold";
    margin-right: 20px;
`;
ModalTitle.displayName = "ModalTitle";

export const ModalSubTitle = styled.div`
    color: ${theme.defaultGreyText};
    margin-top: 4px;
`;
ModalSubTitle.displayName = "ModalSubTitle";

export const ModalSeparator = styled.hr<marginPropsType>`
    color: ${theme.border};
    margin-top: ${props => (props.marginTop ? props.marginTop : "12px")};
    margin-bottom: ${props =>
        props.marginBottom ? props.marginBottom : "12px"};
    margin-left: 0px;
    margin-right: 0px;
`;
ModalSeparator.displayName = "ModalSeparator";

export const CloseIcon = styled.div`
    cursor: pointer;
    display: inline-block;
`;
CloseIcon.displayName = "CloseIcon";

export const ModalMainContent = styled.div<paddingPropsType>`
    padding: ${props => (props.padding ? props.padding : "24px")};
`;
ModalMainContent.displayName = "ModalMainContent";

export const ModalMainContentFlex = styled(ModalMainContent)`
    ${flexboxProps}
    flex: 1;
`;
ModalMainContentFlex.displayName = "ModalMainContentFlex";

type ModalRowProps = {
    fieldLabelWidth?: string;
};

export const ModalRow = styled(Flexbox)<ModalRowProps>`
    margin-bottom: 16px;

    ${FieldLabel} {
        width: ${props =>
            props.fieldLabelWidth ? props.fieldLabelWidth : "160px"};
    }
`;
ModalRow.displayName = "ModalRow";

export const ModalCheckBoxGroupWrapper = styled.div`
    border: 1px solid ${theme.border};

    ${RadioCheckboxCheckbox} {
        margin-left: 10px;
    }
`;
ModalCheckBoxGroupWrapper.displayName = "ModalCheckboxGroupWrapper";

type ModalButtonRowProps = Pick<paddingPropsType, "padding">;

export const ModalButtonRow = styled.div<ModalButtonRowProps>`
    align-items: center;
    background-color: ${theme.modalButtonRow};
    border-radius: 0 0 4px 4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: ${props => (props.padding ? props.padding : "16px 24px")};
`;
ModalButtonRow.displayName = "ModalButtonRow";

type ModalTitleRowWrapperProps = Pick<paddingPropsType, "padding"> &
    Pick<sizePropsType, "cssHeight">;

const ModalTitleRowWrapper = styled.div<ModalTitleRowWrapperProps>`
    padding: ${props => (props.padding ? props.padding : "24px 24px 0 24px")};
`;
ModalTitleRowWrapper.displayName = "ModalTitleRowWrapper";

type CustomModalProps = {
    overflow?: string;
};

// This custom modal style should help with dynamic modal height
const CustomModal = styled(BaseModal)<CustomModalProps>`
    bottom: 0px;
    box-shadow: 0 2px 10px 0 ${theme.boxShadowColor};
    left: 0px;
    margin: auto;
    outline: none;
    overflow-x: ${props => (props.overflow ? props.overflow : "auto")};
    overflow-y: ${props => (props.overflow ? props.overflow : "auto")};
    position: relative;
    right: 0px;
    top: 0px;
`;
CustomModal.displayName = "CustomModal";

type ModalProps = {
    children?: React.ReactNode;
    closeHandler: NoArgsHandler;
    closeOnExternalClick: boolean;
    isOpen: boolean;
    onAfterOpen?: NoArgsHandler;
    overflow?: string;
    trackingComponentLabel: TrackingComponentLabel;
};

type ModalTitleRowProps = {
    closeHandler: NoArgsHandler;
    padding?: string;
    subTitle?: string;
    title: string;
    trackingComponentLabel: TrackingComponentLabel;
};

export const ModalTitleRow = (props: ModalTitleRowProps) => {
    const { closeHandler, padding, subTitle, title, trackingComponentLabel } =
        props;
    const modalSubTitle = subTitle ? (
        <ModalSubTitle>{subTitle}</ModalSubTitle>
    ) : null;
    return (
        <ModalTitleRowWrapper padding={padding}>
            <Flexbox justifyContent={"space-between"}>
                <ModalTitle>{title}</ModalTitle>
                <ModalCloseIcon
                    closeHandler={closeHandler}
                    trackingComponentLabel={trackingComponentLabel}
                />
            </Flexbox>
            {modalSubTitle}
        </ModalTitleRowWrapper>
    );
};
ModalTitleRow.displayName = "ModalTitleRow";

type ModalCloseIconProps = {
    closeHandler: NoArgsHandler;
    trackingComponentLabel: TrackingComponentLabel;
};

export const ModalCloseIcon = (props: ModalCloseIconProps) => {
    const { closeHandler, trackingComponentLabel } = props;
    return (
        <CloseIcon>
            <ClickableSvgIcon
                color={theme.darkGreyText}
                height="16px"
                icon={XIcon}
                onClick={closeHandler}
                trackingComponentLabel={trackingComponentLabel}
                trackingEventName={EVENT_NAME_OPEN_CLOSE}
                trackingEventValue={EVENT_VALUE_CLOSE_MODAL}
                width="16px"
            />
        </CloseIcon>
    );
};

ModalCloseIcon.displayName = "ModalCloseIcon";

class Modal extends React.Component<ModalProps> {
    static displayName = "Modal";

    static defaultProps = {
        onAfterOpen: emptyFn,
        overflow: "auto"
    };

    componentDidUpdate = (prevProps: ModalProps) => {
        if (this.props.isOpen !== prevProps.isOpen) {
            if (this.props.isOpen) {
                trackEvent(
                    getTrackingEventData(
                        Modal.displayName,
                        this.props.trackingComponentLabel,
                        EVENT_NAME_OPEN_CLOSE,
                        EVENT_VALUE_OPEN_MODAL
                    )
                );
            } else {
                trackEvent(
                    getTrackingEventData(
                        Modal.displayName,
                        this.props.trackingComponentLabel,
                        EVENT_NAME_OPEN_CLOSE,
                        EVENT_VALUE_CLOSE_MODAL
                    )
                );
            }
        }
    };

    render() {
        const {
            children,
            closeHandler,
            closeOnExternalClick,
            isOpen,
            onAfterOpen,
            overflow
        } = this.props;
        return (
            <CustomModal
                className="ModalContent"
                contentLabel="Modal"
                isOpen={isOpen}
                onAfterOpen={onAfterOpen}
                onRequestClose={closeHandler}
                overflow={overflow}
                overlayClassName="ModalOverlay"
                shouldCloseOnOverlayClick={closeOnExternalClick}
            >
                {children}
            </CustomModal>
        );
    }
}

export default Modal;
