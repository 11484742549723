import get from "lodash.get";
import isEqual from "lodash.isequal";
import merge from "lodash.merge";
import startCase from "lodash.startcase";
import type { AnyAction, Store } from "redux";
import { defaultTrackingData } from "common/shell/state/initStateUtils";
import type { TrackingData } from "common/shell/util/types";
import { titleCase } from "common/util/format";
import { isEmptyString } from "common/util/lang";
import type {
    TrackingComponentLabel,
    TrackingEventName
} from "common/util/trackingEvents";
import store from "store";

type CommonEventParameters = {
    page: string;
    timestamp: number;
} & TrackingData;

let trackingData: TrackingData = defaultTrackingData;

const ga4Exists = (): boolean => {
    if ((window as any).gtag) {
        return true;
    } else {
        return false;
    }
};

export const initTracking = (tData: TrackingData) => {
    trackingData = tData;
};

// Need to peek into store to get current page as it seems pointless to have each item to track send this information down
// Usually don't like to access store in this fashion
const getCurrentPage = (): string => {
    // Avoid accessing store outside of redux. It's a necessary evil for util methods.
    const currentState = store.getState();
    const pageName = get(currentState, "page", "");
    const subTab = get(currentState, "location.payload.subTab", "");
    return constructPageName(pageName, subTab);
};

const stripOffPage = (str: string): string => {
    let newStr = str;
    if (str) {
        const endsWithPageIndex = str.lastIndexOf("_PAGE");
        if (endsWithPageIndex > -1) {
            newStr = str.substring(0, endsWithPageIndex);
        }
    }
    return newStr;
};

// Make page name pretty - strip of _PAGE
// Append sub tab if it exists
// Make it title case
const constructPageName = (page: string, subTab: string): string => {
    let pageName = "";
    // page may not be set (auth dialogs) so guard
    if (page) {
        pageName = stripOffPage(page);
        if (subTab) {
            pageName += " - " + subTab;
        }
        pageName = titleCase(pageName.toLowerCase());
    }
    return pageName;
};

const getCommonEventParameters = (page?: string): CommonEventParameters => {
    /* console.group("trackEvent");
    console.log(
        "%cpage: ",
        "color:#76ABDF;font-weight:bold;",
        page ? page : getCurrentPage()
    );
    console.groupEnd(); */
    return {
        application: trackingData.application,
        page: page ? page : getCurrentPage(),
        teamType: trackingData.teamType,
        tenantId: trackingData.tenantId,
        timestamp: Date.now(),
        userId: trackingData.userId,
        userType: trackingData.userType
    };
};

export const trackPageView = (store: Store, action: AnyAction) => {
    const subTab = get(action, "payload.subTab");
    const page = constructPageName(action.type, subTab);
    if (ga4Exists() && !isEqual(trackingData, defaultTrackingData)) {
        (window as any).gtag(
            "event",
            "page_view",
            Object.assign(
                {
                    //                    debug_mode: true,
                    page_title: page
                },
                getCommonEventParameters(page)
            )
        );
    }
};

const humanizeString = (eventAction: string): string => {
    const val = startCase(stripOffPage(eventAction));
    return titleCase(val.toLowerCase());
};

export const composeTrackingComponentLabel = (
    labels: (string | string[])[] = []
): TrackingComponentLabel => {
    return labels.filter(value => !isEmptyString(value)).flat();
    /* let componentLabel: string[] = [];

    if (Array.isArray(parts) && parts.length > 0) {
        for (let i = 0; i < parts.length; i++) {
            const item = parts[i];
            if (typeof item === "string") {
                componentLabel.push(item);
            } else {
                if (Array.isArray(item)) {
                    componentLabel = componentLabel.concat(item);
                }
            }
        }
    }

    return componentLabel; */
};

export const getTrackingEventData = (
    trackingComponentType = "",
    trackingComponentLabel: TrackingComponentLabel = "",
    trackingEventName: TrackingEventName,
    trackingEventValue = ""
): TrackingProps => {
    const componentType = humanizeString(trackingComponentType);
    let componentLabel = "";
    if (trackingComponentLabel instanceof Array) {
        const componentLabelParts: string[] = trackingComponentLabel.map(
            label => {
                return humanizeString(label);
            }
        );
        componentLabel = componentLabelParts.join(" - ");
    } else {
        componentLabel = humanizeString(trackingComponentLabel);
    }
    return {
        trackingComponentType: componentType,
        trackingComponentLabel: componentLabel,
        trackingEventName: trackingEventName, // don't transform event name
        trackingEventValue: trackingEventValue // don't transform event value
    };
};

type TrackingProps = {
    trackingComponentType: string;
    trackingComponentLabel: string;
    trackingEventName: TrackingEventName;
    trackingEventValue?: string;
};

/* const outputToConsole = (
    trackingComponentType: string,
    trackingComponentLabel: string,
    trackingEventName: string,
    trackingEventValue: string
) => {
    console.group("trackEvent");
    console.log(
        "%ccomponentType: ",
        "color:#76ABDF;font-weight:bold;",
        trackingComponentType
    );
    console.log(
        "%ccomponentLabel:",
        "color:#76ABDF;font-weight:bold;",
        trackingComponentLabel
    );
    console.log(
        "%ceventName:     ",
        "color:#76ABDF;font-weight:bold;",
        trackingEventName
    );
    console.log(
        "%ceventValue:    ",
        "color:#76ABDF;font-weight:bold;",
        trackingEventValue
    );
    console.groupEnd();
}; */

export const trackEvent = ({
    trackingComponentType = "",
    trackingComponentLabel = "",
    trackingEventName,
    trackingEventValue = ""
}: TrackingProps) => {
    if (ga4Exists() && !isEqual(trackingData, defaultTrackingData)) {
        if (trackingEventName) {
            const eventParameters = merge(
                {
                    componentType: trackingComponentType,
                    componentLabel: trackingComponentLabel,
                    eventValue: trackingEventValue
                    //                        debug_mode: true
                },
                getCommonEventParameters()
            );
            (window as any).gtag("event", trackingEventName, {
                ...eventParameters
            });
            /* outputToConsole(
                trackingComponentType,
                trackingComponentLabel,
                trackingEventName,
                trackingEventValue
            ); */
        }
    }
};
