import type { AnyAction, Middleware, Store } from "redux";
import { isRouteAction } from "routes";
import type { RootState } from "store";

type Actor = (store: Store, action: AnyAction) => void;

const actors: Actor[] = [];

export function registerActor(actor: Actor) {
    actors.push(actor);
}

export const routeActionMiddleware: Middleware<
    Record<string, unknown>,
    RootState
> = (storeApi: any) => next => action => {
    if (isRouteAction(action.type)) {
        actors.forEach(actor => {
            actor(storeApi, action);
        });
    }
    return next(action);
};
