import styled from "styled-components/macro";

const TruncatedDiv = styled.div<
    Pick<marginPropsType, "marginBottom" | "marginTop"> &
        Pick<sizePropsType, "maxWidth"> &
        Pick<flexboxPropsType, "alignSelf"> &
        Pick<textPropsType, "fontSize">
>`
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${props => props.alignSelf && `align-self: ${props.alignSelf};`}
    ${props => props.fontSize && `font-size: ${props.fontSize};`}
    ${props => props.maxWidth && `max-width: ${props.maxWidth};`}
    ${props => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
    ${props => props.marginTop && `margin-top: ${props.marginTop};`}
`;
TruncatedDiv.displayName = "TruncatedDiv";

export default TruncatedDiv;
