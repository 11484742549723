import update from "immutability-helper";
import { getDateFilter } from "common/reports/state/reportsStateUtils";
import { LAST_365_DAYS } from "common/util/date";
import type { DateFilter } from "common/util/date";
import type { ReportsState } from "reports/state/reportsStateUtils";

export const TAKE_RATE = "takeRate";

// Report view types
export const TAKE_RATE_PURCHASE_DISTRIBUTION = "PURCHASE_DISTRIBUTION";
export const TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON =
    "PURCHASE_DISTRIBUTION_COMPARISON";

export const VIEW_BY_BASKETS = "VIEW_BY_BASKETS";
export const VIEW_BY_UNITS = "VIEW_BY_UNITS";

export type TakeRateStackedBarViewBy =
    | typeof VIEW_BY_BASKETS
    | typeof VIEW_BY_UNITS;

export type TakeRateTrendSeries = {
    channels: string[];
    endDate: string;
    holidays: string[];
    name: string;
    offerTypes: string[];
    ppgs: string[];
    promotionQuantities: string[];
    seriesType: typeof TAKE_RATE;
    startDate: string;
    time: DateFilter;
};

export const defaultSeries: TakeRateTrendSeries = {
    channels: [],
    endDate: "",
    holidays: [],
    name: "",
    offerTypes: [],
    ppgs: [],
    promotionQuantities: [],
    seriesType: TAKE_RATE,
    startDate: "",
    time: { time: LAST_365_DAYS }
};

export type TakeRateStackedBarFilters = {
    channels: string[];
    date: DateFilter;
    filterType: "takeRate";
    holidays: string[];
    offerTypes: string[];
    ppgs: string[];
};

export type TakeRateStackedBarState = {
    stackedBar: {
        bars: any[];
        endDate: string;
        filters: TakeRateStackedBarFilters;
        meetsAggregationRequirement: boolean;
        startDate: string;
    };
    viewBy: TakeRateStackedBarViewBy;
};

export const defaultTakeRateStackedBarFilters: TakeRateStackedBarFilters = {
    channels: [],
    date: { time: LAST_365_DAYS },
    filterType: TAKE_RATE,
    holidays: [],
    offerTypes: [],
    ppgs: []
};

export const defaultTakeRateStackedBar: TakeRateStackedBarState = {
    stackedBar: {
        bars: [],
        endDate: "",
        filters: defaultTakeRateStackedBarFilters,
        meetsAggregationRequirement: true,
        startDate: ""
    },
    viewBy: VIEW_BY_BASKETS
};

export const reduceTakeRateSavedReport = (
    savedReport: SavedReport,
    requests: any[],
    uiState: Dictionary<string>,
    state: ReportsState
) => {
    let reducedState = state;
    const reportView = savedReport.type;
    switch (reportView) {
        case TAKE_RATE_PURCHASE_DISTRIBUTION:
            {
                const request = requests.find(request => {
                    return request.objectType === "TakeRateReportChartRequest";
                });
                if (request) {
                    const series: TakeRateTrendSeries[] = [];
                    let date = defaultSeries.time;
                    request.series.forEach((seriesRequest: any) => {
                        date = getDateFilter(
                            seriesRequest.timePeriod?.value,
                            seriesRequest.startDate,
                            seriesRequest.endDate
                        );
                        series.push({
                            channels: seriesRequest.channelIds,
                            endDate: seriesRequest.endDate,
                            holidays: seriesRequest.holidayIds,
                            name: seriesRequest.name,
                            offerTypes: seriesRequest.offerTypes,
                            ppgs: seriesRequest.productGroupIds,
                            promotionQuantities: seriesRequest.promotionQuantities.map(
                                String
                            ),
                            seriesType: TAKE_RATE,
                            startDate: seriesRequest.startDate,
                            time: date
                        });
                    });
                    reducedState = update(state, {
                        [TAKE_RATE]: {
                            [reportView]: {
                                trend: {
                                    configuration: {
                                        series: {
                                            $set: series
                                        }
                                    }
                                }
                            }
                        }
                    });
                }
            }
            break;
        case TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON:
            {
                const request = requests.find(request => {
                    return (
                        request.objectType ===
                        "TakeRateReportStackedChartRequest"
                    );
                });
                if (request) {
                    const barFilters = Object.assign(
                        {},
                        defaultTakeRateStackedBarFilters,
                        {
                            channels: request.channelIds,
                            date: getDateFilter(
                                request.timePeriod.value,
                                request.startDate,
                                request.endDate
                            ),
                            filterType: TAKE_RATE,
                            holidays: request.holidayIds,
                            offerTypes: request.offerTypes,
                            ppgs: request.productGroupIds
                        }
                    );
                    reducedState = update(state, {
                        [TAKE_RATE]: {
                            [reportView]: {
                                stackedBar: {
                                    bars: {
                                        $set:
                                            defaultTakeRateStackedBar.stackedBar
                                                .bars
                                    },
                                    endDate: {
                                        $set: request.endDate
                                    },
                                    filters: { $set: barFilters },
                                    meetsAggregationRequirement: {
                                        $set:
                                            defaultTakeRateStackedBar.stackedBar
                                                .meetsAggregationRequirement
                                    },
                                    startDate: {
                                        $set: request.startDate
                                    }
                                },
                                viewBy: {
                                    $set: uiState.viewBy as TakeRateStackedBarViewBy
                                }
                            }
                        }
                    });
                }
            }
            break;
        default:
            break;
    }
    return Object.assign({}, reducedState, {
        reportType: TAKE_RATE,
        reportView: { value: reportView }
    });
};
