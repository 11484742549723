import * as React from "react";
import type { GetDerivedStateFromProps } from "react";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Button, { BUTTON_TYPE_PRIMARY } from "common/components/Button";
import Link from "common/components/styled/Link";
import { borderRadius, boxShadow } from "common/components/styled/util";
import theme from "common/components/theme";
import { ToggleButtonGroupContainer } from "common/components/ToggleButtonGroup";
import { composeTrackingComponentLabel } from "common/util/tracking";
import {
    EVENT_NAME_OPEN_CLOSE,
    EVENT_VALUE_CLOSE_MODAL
} from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";

export const DURATION_INPUT_MAX = 9999;
export const DURATION_INPUT_MIN = 1;
export const DURATION_INPUT_ZERO = 0;
export const NUMBER_INPUT_MAX = 999999;
export const NUMBER_INPUT_MIN = 0;
export const NUMBER_INPUT_STEP = 1;

const SIDE_PADDING = 16;

const MARGIN_FROM_EDGE = 8;

const WidgetModalOverlay = styled.div`
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
`;
WidgetModalOverlay.displayName = "WidgetModalOverlay";

type WidgetModalWrapperProps = {
    left?: string;
    top?: string;
} & sizePropsType;

const WidgetModalWrapper = styled.div<WidgetModalWrapperProps>`
    background-color: ${theme.background};
    border-radius: ${props => borderRadius("card")(props)};
    border: solid 1px ${theme.border};
    box-shadow: ${props => boxShadow("dropdown")(props)};
    font-family: "BrandonTextRegular";
    left: ${props => props.left};
    position: absolute;
    top: ${props => props.top};
    width: ${props => props.cssWidth};
`;
WidgetModalWrapper.displayName = "WidgetModalWrapper";

const WidgetModalTitle = styled.div`
    border-bottom: solid 1px ${theme.border};
    display: flex;
    font-family: "BrandonTextBold";
    font-size: 14px;
    justify-content: space-between;
    padding: 6px ${SIDE_PADDING}px;
`;
WidgetModalTitle.displayName = "WidgetModalTitle";

const WidgetModalContent = styled.div<{ maxHeight?: string }>`
    padding: 10px ${SIDE_PADDING}px;
    ${props => props.maxHeight && `height: ${props.maxHeight}; overflow: auto;`}

    & > ${ToggleButtonGroupContainer} {
        margin-bottom: 8px;
        width: 100%;
    }
`;
WidgetModalContent.displayName = "WidgetModalContent";

type WidgetModalOwnProps = {
    cssWidth?: string;
    isOpen: boolean;
    maxContentHeight?: string;
    onApply: NoArgsHandler;
    onCancel: NoArgsHandler;
    title: string;
    trackingComponentLabel: TrackingComponentLabel;
    triggerRef: HTMLDivElement | undefined | null;
    valid?: boolean;
} & HasChild;

type WidgetModalProps = WithTranslation & WidgetModalOwnProps;

type WidgetModalState = {
    modalTop: number;
    modalLeft: number;
    triggerLeft: number;
    triggerTop: number;
};

class WidgetModal extends React.Component<WidgetModalProps, WidgetModalState> {
    modalRef: HTMLDivElement | undefined | null;
    overlayRef: HTMLDivElement | undefined | null;

    static defaultProps = {
        cssWidth: "250px"
    };

    state = {
        modalLeft: 0,
        modalTop: 0,
        triggerLeft: 0,
        triggerTop: 0
    };

    static getDerivedStateFromProps: GetDerivedStateFromProps<
        WidgetModalProps,
        WidgetModalState
    > = (props, state) => {
        // Any time the selectedValues prop changes reset any parts of state that is tied to that prop
        if (props.triggerRef) {
            const triggerPosition = props.triggerRef.getBoundingClientRect();
            const triggerLeft = triggerPosition.left;
            const triggerTop = triggerPosition.top;

            if (
                triggerLeft !== state.triggerLeft ||
                triggerTop !== state.triggerTop
            ) {
                return {
                    triggerLeft,
                    triggerTop,
                    modalLeft: triggerLeft,
                    modalTop: triggerTop
                };
            }
        }
        return null;
    };

    setOverlayRef = (element?: HTMLDivElement | null) => {
        this.overlayRef = element;
    };

    setModalRef = (element?: HTMLDivElement | null) => {
        this.modalRef = element;
    };

    componentDidMount() {
        this.helpModalPositioning();
    }

    componentDidUpdate() {
        this.helpModalPositioning();
    }

    helpModalPositioning = () => {
        const { modalLeft, modalTop } = this.state;
        let left = modalLeft;
        let top = modalTop;

        if (this.overlayRef && this.modalRef) {
            const overlayRect = this.overlayRef.getBoundingClientRect();

            if (this.modalRef) {
                const modalRect: DOMRect =
                    this.modalRef.getBoundingClientRect();
                //If the overlay is not wider than the modal, we're not even going to try to reposition.
                if (overlayRect.width > modalRect.width) {
                    if (
                        !(
                            overlayRect.bottom - MARGIN_FROM_EDGE >=
                            modalRect.bottom
                        )
                    ) {
                        top =
                            top -
                            (modalRect.bottom - overlayRect.bottom) -
                            MARGIN_FROM_EDGE;
                    }

                    if (
                        !(
                            overlayRect.right - MARGIN_FROM_EDGE >=
                            modalRect.right
                        )
                    ) {
                        left =
                            left -
                            (modalRect.right - overlayRect.right) -
                            MARGIN_FROM_EDGE;
                    }
                    // Trunc to avoid issue with super precise decimals not equaling each other
                    if (
                        Math.trunc(top) !== Math.trunc(modalTop) ||
                        Math.trunc(left) !== Math.trunc(modalLeft)
                    ) {
                        this.setState({
                            modalTop: top,
                            modalLeft: left
                        });
                    }
                }
            }
        }
    };

    render() {
        const {
            children,
            cssWidth,
            isOpen,
            maxContentHeight,
            onApply,
            onCancel,
            t,
            title,
            trackingComponentLabel,
            valid
        } = this.props;
        const { modalLeft, modalTop } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <WidgetModalOverlay ref={this.setOverlayRef}>
                <WidgetModalWrapper
                    cssWidth={cssWidth}
                    left={`${modalLeft}px`}
                    top={`${modalTop}px`}
                    ref={this.setModalRef}
                >
                    <WidgetModalTitle>
                        {title}
                        <Link
                            onClick={onCancel}
                            textDecoration="none"
                            trackingComponentLabel={trackingComponentLabel}
                            trackingEventName={EVENT_NAME_OPEN_CLOSE}
                            trackingEventValue={EVENT_VALUE_CLOSE_MODAL}
                        >
                            {t("common:general.cancel")}
                        </Link>
                    </WidgetModalTitle>
                    <WidgetModalContent maxHeight={maxContentHeight}>
                        {children}
                    </WidgetModalContent>
                    <Button
                        cssWidth="100%"
                        disabled={!valid}
                        onClick={onApply}
                        suppressBorderRadius={true}
                        text={t("common:general.apply")}
                        trackingComponentLabel={composeTrackingComponentLabel([
                            trackingComponentLabel,
                            "Submit"
                        ])}
                        type={BUTTON_TYPE_PRIMARY}
                    />
                </WidgetModalWrapper>
            </WidgetModalOverlay>
        );
    }
}

export default withTranslation()(WidgetModal);
