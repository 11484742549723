import * as React from "react";
import isNil from "lodash.isnil";
import range from "lodash.range";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components/macro";
import Flexbox from "common/components/styled/Flexbox";
import FlexGrow from "common/components/styled/FlexGrow";
import { CustomSelect } from "common/components/styled/Form";
import { ClickableSvgIcon } from "common/components/SvgIcon";
import {
    borderRadius,
    marginProps,
    marginPropTypes,
    userSelectNoneProps
} from "common/components/styled/util";
import theme from "common/components/theme";
import { ReactComponent as LeftChevronIcon } from "common/icons/LeftChevron.svg";
import { ReactComponent as RightChevronIcon } from "common/icons/RightChevron.svg";
import { getMonthOptions, MONTHS } from "common/util/date";
import { reduceObject } from "common/util/object";
import { EVENT_NAME_TOGGLE_VIEW } from "common/util/trackingEvents";

const DateRangeHeader = styled(Flexbox)`
    margin-left: 10px;
    margin-right: 10px;
    text-align: left;
`;

type DatePickerWrapperProps = {
    highlightSelected?: boolean;
} & sizePropsType;

/* Contains overrides to DatePicker component classes */
const DatePickerWrapper = styled.div<DatePickerWrapperProps>`
    /* Taken from Input.js */
    .datePickerInput {
        height: 17px; /* to match height of select component */
        width: ${props => (props.cssWidth ? props.cssWidth : "100px")};
        border-top-right-radius: ${borderRadius("input")};
        border-bottom-right-radius: ${borderRadius("input")};
        border-top-left-radius: ${borderRadius("input")};
        border-bottom-left-radius: ${borderRadius("input")};
        padding: 8px 10px 9px 10px; /* to match height of select component */
        font-family: inherit;

        :focus {
            box-shadow: 0 0 4px 0 ${theme.inputFocusBorder},
                inset 0 1px 3px 0 ${theme.inputInsetBorder};
            border: 1px solid ${theme.linkText};
            outline: none;
        }

        ::placeholder {
            color: ${theme.placeholder};
        }

        border: 1px solid
            ${props =>
                props.highlightSelected
                    ? props.theme.dropdownSelected
                    : props.theme.inputBorder};

        background: ${props =>
            props.highlightSelected ? props.theme.dropdownSelectedBg : "none"};
    }

    .react-datepicker__close-icon {
        :after {
            background-color: ${theme.background};
            color: ${theme.greyIcon};
            border: 1px solid ${theme.greyIcon};
            width: 14px;
            height: 14px;
        }
    }

    .calendarControl {
        border-radius: 0px;
        font-family: inherit;
        ${userSelectNoneProps}
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected,
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-range,
    .react-datepicker__day--in-range:hover,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day:hover {
        border-radius: 0px;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected,
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-range,
    .react-datepicker__day--in-range:hover {
        background-color: ${theme.linkText};
    }

    .react-datepicker__day--today,
    .react-datepicker__day--today:hover {
        background-color: ${theme.dateToday};
        color: ${theme.text};
    }

    .react-datepicker__day--today.react-datepicker__day--in-range,
    .react-datepicker__day--today:hover.react-datepicker__day--in-range:hover {
        background-color: ${theme.linkText};
        color: ${theme.background};
    }

    .react-datepicker__day--today.react-datepicker__day--selected,
    .react-datepicker__day--today:hover.react-datepicker__day--selected:hover {
        background-color: ${theme.linkText};
        color: ${theme.background};
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        line-height: 1.9rem;
        width: 1.9rem;
    }

    /* pop out over dropdown */
    .react-datepicker-popper {
        z-index: 30000 !important;
    }

    ${marginProps}
`;

/* Contains overrides to DatePicker component classes */
/* 
    For use when calendar popper is opened in a React portal - see below
    Take the corresponding classes out of DatePickerWrapper
 */
/*
const DateCalendarWrapper = styled.div`
    .calendarControl {
        border-radius: 0px;
        font-family: inherit;
        ${userSelectNoneProps}
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected,
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-range,
    .react-datepicker__day--in-range:hover,
    .react-datepicker__day:hover {
        border-radius: 0px;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        line-height: 1.9rem;
        width: 1.9rem;
    }

    // pop out over dropdown
    .react-datepicker-popper {
        z-index: 30000 !important;
    }
`;
*/

type DateChooserProps = {
    autoComplete: string;
    cssWidth?: string;
    dateFormat: string;
    disabled?: boolean;
    endDate: Date | undefined | null;
    endYear?: number;
    highlightSelected: boolean;
    isClearable: boolean;
    maxDate: Date | undefined | null;
    minDate: Date | undefined | null;
    name: string;
    onDateChange: (date: Date) => void;
    placeholderText: string;
    popperPlacement: string;
    selected: Date | undefined | null;
    selectsEnd: boolean;
    selectsStart: boolean;
    showPopperArrow: boolean;
    startDate: Date | undefined | null;
    startYear: number;
    tabIndex?: string;
} & marginPropsType;

type CustomHeaderProps = {
    date: Date;
    changeYear: AnyFunction;
    changeMonth: AnyFunction;
    decreaseMonth: AnyFunction;
    increaseMonth: AnyFunction;
    monthOptions: OptionType[];
    nextMonthButtonDisabled: boolean;
    prevMonthButtonDisabled: boolean;
    yearOptions: OptionType[];
};

// export to test
export const renderCustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    monthOptions,
    nextMonthButtonDisabled,
    prevMonthButtonDisabled,
    yearOptions
}: CustomHeaderProps) => {
    const year = moment(date).year();
    const month = moment(date).month();
    const selectedMonth = {
        label: monthOptions[month].label,
        value: monthOptions[month].value
    };
    const selectedYear = {
        label: year,
        value: year
    };

    return (
        <DateRangeHeader>
            <ClickableSvgIcon
                color={
                    prevMonthButtonDisabled
                        ? theme.btnDisabledTextColor
                        : theme.text
                }
                cursor={prevMonthButtonDisabled ? "default" : "pointer"}
                icon={LeftChevronIcon}
                marginRight="5px"
                onClick={() => {
                    if (!prevMonthButtonDisabled) {
                        decreaseMonth();
                    }
                }}
                trackingComponentLabel={[
                    DateChooser.displayName,
                    "Previous Month"
                ]}
                trackingEventName={EVENT_NAME_TOGGLE_VIEW}
            />
            <FlexGrow />
            <CustomSelect
                maxMenuHeight={200}
                name="month"
                onChange={(month: OptionTypeOrNullOrUndefined) => {
                    const selectedMonthValue = month?.value ?? "";
                    if (selectedMonthValue) {
                        const selectedMonthIndex =
                            MONTHS.indexOf(selectedMonthValue);
                        changeMonth(selectedMonthIndex);
                    }
                }}
                options={monthOptions}
                trackingComponentLabel={[DateChooser.displayName, "Month"]}
                value={selectedMonth}
                width="110px"
            />
            <FlexGrow />
            <CustomSelect
                maxMenuHeight={200}
                name="year"
                onChange={(year: OptionTypeOrNullOrUndefined) => {
                    const selectedYear = year?.value ?? "";
                    if (selectedYear) {
                        changeYear(selectedYear);
                    }
                }}
                options={yearOptions}
                trackingComponentLabel={[DateChooser.displayName, "Year"]}
                value={selectedYear}
                width="80px"
            />
            <FlexGrow />
            <ClickableSvgIcon
                color={
                    nextMonthButtonDisabled
                        ? theme.btnDisabledTextColor
                        : theme.text
                }
                cursor={nextMonthButtonDisabled ? "default" : "pointer"}
                icon={RightChevronIcon}
                marginLeft={"5px"}
                onClick={() => {
                    if (!nextMonthButtonDisabled) {
                        increaseMonth();
                    }
                }}
                trackingComponentLabel={[DateChooser.displayName, "Next Month"]}
                trackingEventName={EVENT_NAME_TOGGLE_VIEW}
            />
        </DateRangeHeader>
    );
};

/* 
    Used when IE wasn't displaying calendar popper over DateFilterDropdown. Saving code so if we need to display
    calendar control in a React portal we have it.
 */
/*
const CalendarContainer = ({ children }) => {
    const el = document.getElementById("root");
    return ReactDOM.createPortal(
        <DateCalendarWrapper>{children}</DateCalendarWrapper>,
        el
    );
};

and in DatePicker below -

popperContaner={CalendarContainer}
*/

const DateChooser = (props: DateChooserProps) => {
    const {
        autoComplete,
        dateFormat,
        disabled = false,
        endDate,
        /* end year to show in dropdown to select year - sometimes it is this year because you can't search into the future, sometime it is in the future because you can be setting a future date */
        endYear,
        highlightSelected,
        isClearable,
        maxDate,
        minDate,
        name,
        onDateChange,
        placeholderText,
        popperPlacement,
        selected,
        selectsEnd,
        selectsStart,
        showPopperArrow,
        startDate,
        /* start year to show in dropdown to select year - what is the earliest year with data? - hard-coding for now at 2018 */
        startYear,
        tabIndex
    } = props;
    const monthOptions = getMonthOptions();
    let endYr = endYear;
    if (!endYr) {
        endYr = moment(new Date()).year() + 1;
    }
    let years = range(startYear, endYr, 1);
    years = years.reverse();
    const yearOptions = years.map(year => {
        return {
            label: year,
            value: year
        };
    });

    let highlightSelectedProp = {};
    if (highlightSelected) {
        highlightSelectedProp = {
            highlightSelected: !isNil(selected)
        };
    }

    return (
        <DatePickerWrapper
            {...highlightSelectedProp}
            {...reduceObject(props, ...marginPropTypes, "cssWidth")}
        >
            <DatePicker
                autoComplete={autoComplete}
                calendarClassName="calendarControl"
                className="datePickerInput"
                dateFormat={dateFormat}
                disabled={disabled}
                endDate={endDate}
                isClearable={isClearable}
                maxDate={maxDate}
                minDate={minDate}
                name={name}
                onChange={onDateChange}
                placeholderText={placeholderText}
                popperPlacement={popperPlacement}
                renderCustomHeader={(props: CustomHeaderProps) => {
                    const newProps = Object.assign({}, props, {
                        monthOptions: monthOptions,
                        yearOptions: yearOptions
                    });
                    return renderCustomHeader(newProps);
                }}
                selected={selected}
                selectsEnd={selectsEnd}
                selectsStart={selectsStart}
                showPopperArrow={showPopperArrow}
                startDate={startDate}
                tabIndex={tabIndex}
            />
        </DatePickerWrapper>
    );
};

DateChooser.displayName = "DateChooser";

DateChooser.defaultProps = {
    autoComplete: "off",
    dateFormat: "MM/dd/yyyy",
    endDate: null,
    highlightSelected: false,
    isClearable: true,
    maxDate: new Date(9999, 11, 31), // Default max date to be Dec 31, 9999 to cap years at 4 digits
    minDate: null,
    placeholderText: "",
    popperPlacement: "bottom-start",
    selected: null,
    selectsEnd: false,
    selectsStart: false,
    showPopperArrow: false,
    startDate: null,
    startYear: 2013 /* start of Company */,
    tabIndex: "-1"
};

export default DateChooser;
