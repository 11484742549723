import * as React from "react";
import { Trans, withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import AuthDialogPage, {
    AUTH_WIDTH
} from "common/auth/components/AuthDialogPage";
import { toLogIn } from "common/auth/state/authActions";
import Label from "common/components/styled/Label";
import Link from "common/components/styled/Link";
import { EVENT_NAME_PAGE_NAVIGATION } from "common/util/trackingEvents";
import type { AppDispatch, RootState } from "store";

type PasswordEmailDialogProps = PropsFromRedux & WithTranslation;

class PasswordEmailDialog extends React.Component<PasswordEmailDialogProps> {
    static displayName = "PasswordEmailDialog";
    onClickBackToLogin = (event: React.SyntheticEvent<HTMLLinkElement>) => {
        event.preventDefault();
        this.props.toLogInAction();
    };

    render() {
        const { email, t } = this.props;
        return (
            <AuthDialogPage>
                <Label marginBottom="10px">
                    <Trans
                        i18nKey="common:login.an_email_was_sent"
                        values={{ email: email }}
                    />
                </Label>
                <Label marginBottom="10px">
                    {t("common:login.check_your_email")}
                </Label>
                <Label marginBottom="24px">
                    {t("common:login.contact_your_administrator")}
                </Label>
                <Link
                    cssWidth={AUTH_WIDTH}
                    onClick={this.onClickBackToLogin}
                    trackingComponentLabel="Return To Login"
                    trackingEventName={EVENT_NAME_PAGE_NAVIGATION}
                >
                    {t("common:login.back_to_login")}
                </Link>
            </AuthDialogPage>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        email: state.auth.email
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        toLogInAction: () => {
            dispatch(toLogIn());
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(PasswordEmailDialog));
