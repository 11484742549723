import get from "lodash.get";
import type { AnyAction } from "redux";
import { defaultCreatorModifierState, reduceObject } from "common/util/object";
import noAction from "common/util/noAction";
import {
    CLEAR_OFFER_INFO,
    RECEIVE_DUPLICATE_GUIDELINE,
    RECEIVE_GUIDELINE,
    RECEIVE_OFFER_INFO,
    REQUEST_GUIDELINE,
    REQUEST_OFFER_INFO
} from "administration/guidelines/state/guidelineActions";
import { defaultOfferInfo } from "administration/guidelines/state/offerModalStateUtils";
import type { Offer } from "administration/guidelines/state/offerModalStateUtils";
import {
    GUIDELINE_CREATE_PAGE,
    GUIDELINE_EDIT_PAGE,
    GUIDELINE_PAGE
} from "administration/state/pageActions";
import type { GuidelineObject } from "ppgGuidelines/state/ppgGuidelinesReducers";
import { reduceBaseGuideline } from "ppgGuidelines/state/ppgGuidelinesReducers";

type SelectedRowValues = {
    bundledWith: any[];
    channelId: string | undefined | null;
    productCategory: string | undefined | null;
    productGroupId: string | undefined | null;
    regionId: string | undefined | null;
    retailerId: string | undefined | null;
    holidayId: string | undefined | null;
};

type GuidelineState = {
    current: GuidelineObject;
    duplicate: GuidelineObject | undefined | null;
    offerInfo: Offer;
    selectedRowValues: SelectedRowValues | undefined | null;
};

const defaultGuidelineState: GuidelineObject = {
    applyToEffectivePTC: false,
    bundledProducts: false,
    collectionId: null,
    collectionName: null,
    conditionalMaxPromotedPrice: null,
    conditionalMinPromotedPrice: null,
    crossPromotionEntityId: "",
    crossPromotionNotes: "",
    crossPromotionsNo: [],
    crossPromotionsYes: [],
    entityId: "",
    frequency: -1,
    frequencyEntityId: "",
    frequencyNotes: "",
    holidays: [],
    holidaySummary: "",
    keyMessage: "",
    maxPromotedPrice: null,
    minPromotedPrice: null,
    name: "",
    offerStructureEntityId: "",
    offerStructureNo: [],
    offerStructureNotes: "",
    offerStructureYes: [],
    products: [],
    productSummary: "",
    promotedPriceEntityId: "",
    promotedPriceEventCount: null,
    promotedPriceNotes: "",
    promotedPricePpgs: [],
    promotedPriceTimePeriod: null,
    regions: [],
    regionSummary: "",
    retailers: [],
    retailerSummary: "",
    supportingMaterials: [],
    timing: [],
    timingEntityId: "",
    timingNotes: "",
    userSelectedRetailers: [],
    visualsEntityId: "",
    visualNotes: "",
    visualMaterials: [],
    ...defaultCreatorModifierState
};

export const defaultState: GuidelineState = {
    current: defaultGuidelineState,
    duplicate: null,
    offerInfo: defaultOfferInfo,
    selectedRowValues: null
};

const reduceSelectedRowValues = (
    rowData: any
): SelectedRowValues | undefined | null => {
    return rowData
        ? reduceObject(
              rowData,
              "bundledWith",
              "channelId",
              "productCategory",
              "productGroupId",
              "regionId",
              "retailerId",
              "holidayId"
          )
        : null;
};

const guideline = (
    state: GuidelineState = defaultState,
    action: AnyAction = noAction
): GuidelineState => {
    switch (action.type) {
        case GUIDELINE_PAGE:
        case GUIDELINE_EDIT_PAGE: {
            const entityId = get(action, "payload.entityId");
            return Object.assign({}, state, {
                current: {
                    ...defaultGuidelineState,
                    entityId: entityId
                }
            });
        }
        case GUIDELINE_CREATE_PAGE: {
            const collectionId = get(action, "payload.collectionId");
            const rowData = get(action, "payload.rowData");
            return Object.assign({}, defaultState, {
                current: {
                    ...defaultState.current,
                    collectionId
                },
                selectedRowValues: reduceSelectedRowValues(rowData)
            });
        }
        case RECEIVE_DUPLICATE_GUIDELINE:
            if (action.duplicateGuideline) {
                return Object.assign({}, state, {
                    duplicate: action.duplicateGuideline.entityId
                        ? reduceBaseGuideline(action.duplicateGuideline)
                        : null,
                    recommendationMessage:
                        action.duplicateGuideline.recommendationMessage
                });
            }
            return Object.assign({}, state, {
                duplicate: null,
                recommendationMessage: null
            });
        case RECEIVE_GUIDELINE:
            return Object.assign({}, state, {
                current: reduceBaseGuideline(action.guideline),
                duplicate: null
            });
        case REQUEST_GUIDELINE:
            return state;
        case CLEAR_OFFER_INFO:
            return Object.assign({}, state, {
                offerInfo: Object.assign({}, defaultOfferInfo)
            });
        case RECEIVE_OFFER_INFO:
            return Object.assign({}, state, {
                offerInfo: Object.assign(
                    {},
                    {
                        name: get(action, "json.name"),
                        isOffer: get(action, "json.isOffer"),
                        offerIdentifier: get(action, "json.offerIdentifier"),
                        offerLabel: get(action, "json.offerLabel"),
                        offerValues: get(action, "json.offerValues")
                    }
                )
            });
        case REQUEST_OFFER_INFO:
            return state;
        default:
            return state;
    }
};

export default guideline;
