import * as React from "react";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import DateChooser from "common/components/DateChooser";
import SvgIcon from "common/components/SvgIcon";
import theme from "common/components/theme";
import { ReactComponent as RightArrowIcon } from "common/icons/RightArrow.svg";
import { EVENT_NAME_EXECUTE_ACTION } from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";
import {
    composeTrackingComponentLabel,
    getTrackingEventData,
    trackEvent
} from "common/util/tracking";

type DateRangeFilterDropdownViewOwnProps = {
    endDate: Date | null | undefined;
    endYear: number;
    isEndDateClearable: boolean;
    isStartDateClearable: boolean;
    maxEndDate?: Date | null;
    maxStartDate?: Date | null;
    minEndDate?: Date | null;
    minStartDate?: Date | null;
    onEndDateChange: (date: Date) => void;
    onStartDateChange: (date: Date) => void;
    startDate: Date | null | undefined;
    trackingComponentLabel: TrackingComponentLabel;
};

type DateRangeFilterDropdownViewProps = DateRangeFilterDropdownViewOwnProps &
    WithTranslation;

const DateRangeFilterDropdownView = (
    props: DateRangeFilterDropdownViewProps
) => {
    const {
        endDate,
        endYear,
        isEndDateClearable,
        isStartDateClearable,
        maxEndDate,
        maxStartDate,
        minEndDate,
        minStartDate,
        onEndDateChange,
        onStartDateChange,
        startDate,
        t,
        trackingComponentLabel
    } = props;

    const trackDateChange = () => {
        trackEvent(
            getTrackingEventData(
                "Date Chooser",
                composeTrackingComponentLabel([
                    trackingComponentLabel,
                    "Date Range Filter"
                ]),
                EVENT_NAME_EXECUTE_ACTION
            )
        );
    };

    const startDateChange = (date: Date) => {
        onStartDateChange(date);
        trackDateChange();
    };

    const endDateChange = (date: Date) => {
        onEndDateChange(date);
        trackDateChange();
    };

    return (
        <React.Fragment>
            <DateChooser
                endDate={endDate}
                endYear={endYear}
                isClearable={isStartDateClearable}
                maxDate={maxStartDate}
                minDate={minStartDate}
                name="startDate"
                onDateChange={startDateChange}
                placeholderText={t("common:date.start_date")}
                selected={startDate}
                selectsStart={true}
                startDate={startDate}
            />
            <SvgIcon
                color={theme.iconMenu}
                height="18px"
                icon={RightArrowIcon}
                margin="0px 8px"
                width="18px"
            />
            <DateChooser
                endDate={endDate}
                endYear={endYear}
                isClearable={isEndDateClearable}
                maxDate={maxEndDate}
                minDate={minEndDate}
                name="endDate"
                onDateChange={endDateChange}
                placeholderText={t("common:date.end_date")}
                popperPlacement="bottom-end"
                selected={endDate}
                selectsEnd={true}
                startDate={startDate}
            />
        </React.Fragment>
    );
};

export default withTranslation()(DateRangeFilterDropdownView);
