import get from "lodash.get";
import isEmpty from "lodash.isempty";
import isFunction from "lodash.isfunction";
import LZString from "lz-string";
import type { RootState } from "store";

export const SIGNAL_KEY_PREFIX = "signalKey-";

// keeps all abort controllers for requests that should automatically get aborted on navigation change
const NAVIGATION_CHANGE_ABORT_REQUEST_CONTROLLERS: AbortController[] = [];

// keeps all abort controllers for requests that can be individually aborted (have a signalKey)
const SIGNAL_KEY_ABORT_REQUEST_CONTROLLERS = new Map();

// abort single request
function abortRequest(key: string) {
    const abortController = SIGNAL_KEY_ABORT_REQUEST_CONTROLLERS.get(key);
    if (abortController) {
        if (isFunction(abortController.abort)) {
            abortController.abort();
        }
    }
}

// abort all requests
export function abortAllRequests() {
    // if the HTTP request has already been aborted, or has already completed, the abort event is safely ignored
    // abort any signal key abort controllers
    SIGNAL_KEY_ABORT_REQUEST_CONTROLLERS.forEach((value, key, map) => {
        if (isFunction(value.abort)) {
            value.abort();
        }
    });
    // clear out SIGNAL_KEY_ABORT_REQUEST_CONTROLLERS
    SIGNAL_KEY_ABORT_REQUEST_CONTROLLERS.clear();
    // abort any navigation change abort controllers
    NAVIGATION_CHANGE_ABORT_REQUEST_CONTROLLERS.forEach(abortController => {
        if (isFunction(abortController.abort)) {
            abortController.abort();
        }
    });
    // clear out NAVIGATION_CHANGE_ABORT_REQUEST_CONTROLLERS
    NAVIGATION_CHANGE_ABORT_REQUEST_CONTROLLERS.length = 0;
}

export function abortRequestAndGetSignal(key?: string): AbortSignal {
    // abort previous request, if any
    // only aborts requests which have a signalKey
    if (key) {
        abortRequest(key);
    }
    // create new abort controller
    const abortController = new AbortController();
    // save in appropriate map storage
    if (key) {
        SIGNAL_KEY_ABORT_REQUEST_CONTROLLERS.set(key, abortController);
    } else {
        NAVIGATION_CHANGE_ABORT_REQUEST_CONTROLLERS.push(abortController);
    }
    // return signal to attach to request
    return abortController.signal;
}

export const getCompressedQueryParams = (queryObject: any): any => {
    let compressedQuery;
    if (!isEmpty(queryObject)) {
        compressedQuery = {
            qp: LZString.compressToEncodedURIComponent(
                JSON.stringify(queryObject)
            )
        };
    }
    return compressedQuery;
};

export const getDecompressedQueryParams = (state: RootState): any => {
    const qp = get(state, "location.query.qp");
    let queryParams;
    if (qp && !isEmpty(qp)) {
        const decompressedString =
            LZString.decompressFromEncodedURIComponent(qp);
        if (decompressedString) {
            queryParams = JSON.parse(decompressedString);
        }
    }
    return queryParams;
};
