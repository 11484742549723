import type { AnyAction } from "redux";
import { fixEntityIdPathSegment, fixListPathSegment } from "common/routes";
import noAction from "common/util/noAction";
import {
    CLOSE_CAMPAIGN_LIST,
    OPEN_CAMPAIGN_LIST,
    RECEIVE_CATEGORY_SORTED,
    RECEIVE_OFFER_RECOMMENDATIONS,
    RECEIVE_OFFER_RECOMMENDATIONS_REPORT,
    REQUEST_OFFER_RECOMMENDATIONS,
    REQUEST_OFFER_RECOMMENDATIONS_REPORT
} from "offerRecommendations/state/offerRecommendationActions";
import {
    defaultReportState,
    defaultState,
    getCampaignListModal,
    getControlId,
    reduceRecommendations,
    reduceRecommendationsReport,
    reduceSortedCategory
} from "offerRecommendations/state/offerRecommendationStateUtils";
import type { RecommendationState } from "offerRecommendations/state/offerRecommendationStateUtils";
import {
    OFFER_RECOMMENDATIONS_PAGE,
    OFFER_RECOMMENDATIONS_REPORT_PRINT_PAGE
} from "shell/state/pageActions";

const offerRecommendations = (
    state: RecommendationState = defaultState,
    action: AnyAction = noAction
): RecommendationState => {
    switch (action.type) {
        case OFFER_RECOMMENDATIONS_PAGE:
            return Object.assign({}, state, getControlId(action));
        case OFFER_RECOMMENDATIONS_REPORT_PRINT_PAGE:
            return Object.assign({}, state, {
                printOptions: {
                    controlId: fixEntityIdPathSegment(action.payload.controlId),
                    metric: fixEntityIdPathSegment(action.payload.metric),
                    offerIds: fixListPathSegment(action.payload.offerIds)
                }
            });
        case REQUEST_OFFER_RECOMMENDATIONS:
            return Object.assign({}, defaultState);
        case RECEIVE_OFFER_RECOMMENDATIONS:
            return Object.assign(
                {},
                state,
                reduceRecommendations(action.recommendations, state)
            );
        case REQUEST_OFFER_RECOMMENDATIONS_REPORT:
            return Object.assign({}, state, { report: defaultReportState });
        case RECEIVE_OFFER_RECOMMENDATIONS_REPORT:
            return Object.assign({}, state, {
                report: reduceRecommendationsReport(action.report)
            });
        case OPEN_CAMPAIGN_LIST:
            return Object.assign(
                {},
                state,
                getCampaignListModal(
                    true,
                    action.campaigns,
                    action.isSingleOffer
                )
            );
        case CLOSE_CAMPAIGN_LIST:
            return Object.assign({}, state, getCampaignListModal(false, null));
        case RECEIVE_CATEGORY_SORTED:
            return Object.assign(
                {},
                state,
                reduceSortedCategory(
                    state,
                    action.category,
                    action.sortBy,
                    action.offers
                )
            );
        default:
            return state;
    }
};

export default offerRecommendations;
