import * as React from "react";
import type {
    GetRowIdFunc,
    GetRowIdParams,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    RowSelectedEvent,
    SelectionChangedEvent
} from "ag-grid-community";
import type { ParseKeys } from "i18next";
import isEqual from "lodash.isequal";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Checkbox from "common/components/Checkbox";
import AgDataGrid, {
    ROW_SELECTION_MULTIPLE,
    SERVER_SIDE
} from "common/components/grid/AgDataGrid";
import type {
    DataGridType,
    RowSelection
} from "common/components/grid/AgDataGrid";
import {
    CHECKBOX_COLUMN,
    COLUMN_CUSTOM_RENDERER_ALIGN_CENTER,
    DEFAULT_ROW_HEIGHT
} from "common/components/grid/AgDataGridUtil";
import { GridHeaderItemCount } from "common/components/grid/CommonGridUtil";
import Flexbox from "common/components/styled/Flexbox";
import SearchBar from "common/filter/components/SearchBar";
import type { TrackingComponentLabel } from "common/util/trackingEvents";
import { DEFAULT_PAGE_SIZE } from "common/util/query";

const DATA_ID_KEY_DEFAULT = "entityId";

const HeaderRow = styled(Flexbox)`
    align-items: center;
    margin-bottom: 12px;
`;
HeaderRow.displayName = "HeaderRow";

type AgDataGridSelectorOwnProps = {
    addCheckboxColumn?: boolean;
    columnDefinitions: ColumnDefinition[];
    dataIdKey?: string;
    filterRow?: React.ReactNode;
    getRowId?: GetRowIdFunc;
    getRows: (
        params: IServerSideGetRowsParams,
        dataGrid: DataGridType,
        search: string,
        selected: string[]
    ) => void;
    headerInfo?: React.ReactNode;
    headerText: string;
    id: string;
    onSelectedRowsChange: (selectedRows: any[]) => void;
    /* 
     ag-grid will call getRows immediately - let AgDataGridSelector know when it is o.k. to query
     if it should be on mount - just set to true
     if it should query when the drawer is opened, set to isOpen AND get query to fire through compononentDidUpdate using resetGrid
    */
    okToQuery?: boolean;
    pageSize?: number;
    placeHolderText?: string;
    // TO DO - CreateTestView does refreshDrawer -> refreshGrid
    refreshGrid?: boolean;
    resetGrid: any;
    rowHeight?: number;
    rowSelection?: RowSelection;
    selectAll?: boolean;
    selectedRows?: any[];
    showColumnChooser?: boolean;
    showSearch?: boolean;
    showShowSelected?: boolean;
    totalCount: number;
    trackingComponentLabel: TrackingComponentLabel;
    writeToPreferences?: boolean;
};

type AgDataGridSelectorProps = AgDataGridSelectorOwnProps & WithTranslation;

type AgDataGridSelectorStateProps = {
    search: string;
    selectedRows: any[];
    showSelectedOnly: boolean;
};

class AgDataGridSelector extends React.Component<
    AgDataGridSelectorProps,
    AgDataGridSelectorStateProps
> {
    static displayName = "AgDataGridSelector";
    dataGrid: DataGridType = null;
    static defaultProps = {
        addCheckboxColumn: true,
        dataIdKey: DATA_ID_KEY_DEFAULT,
        pageSize: DEFAULT_PAGE_SIZE,
        rowHeight: DEFAULT_ROW_HEIGHT,
        rowSelection: ROW_SELECTION_MULTIPLE,
        selectAll: false,
        showColumnChooser: true,
        showSearch: true,
        showShowSelected: true,
        totalCount: 0,
        writeToPreferences: true
    };

    constructor(props: AgDataGridSelectorProps) {
        super(props);
        this.state = {
            search: "",
            selectedRows: props.selectedRows ? props.selectedRows : [],
            showSelectedOnly: false
        };
    }

    componentDidUpdate(prevProps: AgDataGridSelectorProps) {
        const {
            columnDefinitions,
            onSelectedRowsChange,
            refreshGrid,
            resetGrid
        } = this.props;
        if (this.dataGrid) {
            if (!isEqual(columnDefinitions, prevProps.columnDefinitions)) {
                this.dataGrid.setColumnDefs(this.getColumnDefinitions());
            }
            if (!!resetGrid && !isEqual(resetGrid, prevProps.resetGrid)) {
                this.dataGrid.deselectAll();
                onSelectedRowsChange([]);
                this.setState(
                    {
                        search: "",
                        selectedRows: [],
                        showSelectedOnly: false
                    },
                    this.reFetchData
                );
            }
            // Reset grid: Used for when grid is brought in and out of view (open closed from drawer)
            // Refresh Grid: Used when filter row is added to a drawer so filter onchange can trigger refetch from drawer
            else if (!!resetGrid && refreshGrid && !prevProps.refreshGrid) {
                this.reFetchData();
            }
        }
    }

    reFetchData = (resetScroll = true) => {
        const { selectedRows, showSelectedOnly } = this.state;
        if (selectedRows.length === 0 && showSelectedOnly) {
            this.setState({ showSelectedOnly: false });
        }
        this.dataGrid?.reFetchData(resetScroll);
    };

    getSelectedRowsId = (): string[] => {
        const dataIdKey = this.props.dataIdKey ?? DATA_ID_KEY_DEFAULT;
        const { selectedRows, showSelectedOnly } = this.state;
        return showSelectedOnly
            ? selectedRows.map(row => {
                  return row[dataIdKey];
              })
            : [];
    };

    // only works if all rows are queried (PAGE_SIZE_UNLIMITED) - SERVER_SIDE as CLIENT_SIDE
    onSelectionChanged = (event: SelectionChangedEvent) => {
        const { onSelectedRowsChange } = this.props;
        if (this.dataGrid) {
            const selectedRows =
                this.dataGrid.getSelectedRowsServerSideSelectionState();
            this.setState({ selectedRows });
            onSelectedRowsChange(selectedRows);
        }
    };

    onRowSelected = (event: RowSelectedEvent) => {
        const { onSelectedRowsChange } = this.props;
        const dataIdKey = this.props.dataIdKey ?? DATA_ID_KEY_DEFAULT;
        const { selectedRows } = this.state;
        let calculatedSelectedRows: any[] = ([] as any[]).concat(selectedRows);
        const { data, node } = event;
        if (node.isSelected()) {
            // add if it doesn't exist
            const index = selectedRows.findIndex((item: any) => {
                return item[dataIdKey] === data[dataIdKey];
            });
            if (index === -1) {
                calculatedSelectedRows = selectedRows.concat([data]);
            }
        } else {
            // remove
            calculatedSelectedRows = selectedRows.filter((item: any) => {
                return item[dataIdKey] !== data[dataIdKey];
            });
        }
        this.setState({
            selectedRows: calculatedSelectedRows
        });
        onSelectedRowsChange(calculatedSelectedRows);
    };

    onSearchTextChange = (searchText: string) => {
        this.setState({ search: searchText }, this.reFetchData);
    };

    onShowSelectedChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        this.setState(
            { showSelectedOnly: event.currentTarget.checked },
            this.reFetchData
        );
    };

    getRowId = (params: GetRowIdParams): string => {
        const { getRowId } = this.props;
        const dataIdKey = this.props.dataIdKey ?? DATA_ID_KEY_DEFAULT;
        const { data } = params;
        if (getRowId) {
            return getRowId(data);
        } else {
            return data[dataIdKey];
        }
    };

    getRows = (params: IServerSideGetRowsParams) => {
        const { getRows, okToQuery } = this.props;
        const { search } = this.state;
        if (okToQuery) {
            const selectedIds = this.getSelectedRowsId();
            getRows(params, this.dataGrid, search, selectedIds);
        } else {
            if (!params.api.isDestroyed()) {
                params.fail();
            }
        }
    };

    datasource: IServerSideDatasource = {
        getRows: this.getRows
    };

    getColumnDefinitions = (): ColumnDefinition[] => {
        const { addCheckboxColumn, columnDefinitions, selectAll } = this.props;
        let colDefs: ColumnDefinition[] = [];
        if (addCheckboxColumn && !selectAll) {
            colDefs.push({
                cellClass: [COLUMN_CUSTOM_RENDERER_ALIGN_CENTER],
                checkboxSelection: true,
                colId: CHECKBOX_COLUMN,
                headerName: "",
                lockVisible: true,
                resizable: false,
                sortable: false,
                width: 50
            });
        }
        colDefs = colDefs.concat(columnDefinitions);
        return colDefs;
    };

    render() {
        const {
            dataIdKey = DATA_ID_KEY_DEFAULT,
            filterRow,
            headerInfo,
            headerText,
            id,
            pageSize,
            placeHolderText,
            rowHeight,
            rowSelection,
            selectAll,
            showColumnChooser,
            showSearch,
            showShowSelected,
            t,
            totalCount,
            trackingComponentLabel,
            writeToPreferences
        } = this.props;
        const { search, selectedRows, showSelectedOnly } = this.state;
        const countHeader = t(headerText as ParseKeys, { count: totalCount });
        const headerLeft = (
            <React.Fragment>
                <GridHeaderItemCount
                    count={totalCount}
                    countHeader={countHeader}
                />
                {headerInfo}
            </React.Fragment>
        );
        const headerRight = (
            <React.Fragment>
                {showShowSelected && (
                    <Checkbox
                        checked={showSelectedOnly}
                        disabled={
                            selectedRows.length === 0 && !showSelectedOnly
                        }
                        label={t("common:general.show_selected_only")}
                        marginRight="16px"
                        name="showSelectedOnly"
                        onChange={this.onShowSelectedChange}
                    />
                )}
                {showSearch && (
                    <SearchBar
                        searchText={search}
                        onFilterChange={this.onSearchTextChange}
                        placeHolderText={placeHolderText}
                        trackingComponentLabel={trackingComponentLabel}
                    />
                )}
            </React.Fragment>
        );

        const columnDefinitions = this.getColumnDefinitions();

        const dataGridParams: any = {
            columnDefs: columnDefinitions,
            dataIdKey: dataIdKey,
            datasource: this.datasource,
            getRowId: this.getRowId,
            gridHeaderLeft: headerLeft,
            gridHeaderRight: headerRight,
            id: id,
            onRef: (ref: DataGridType) => (this.dataGrid = ref),
            pageSize: pageSize,
            rowHeight: rowHeight,
            rowModelType: SERVER_SIDE,
            rowSelection: rowSelection,
            showColumnChooser: showColumnChooser,
            writeToPreferences: writeToPreferences
        };

        if (selectAll) {
            dataGridParams.onSelectionChanged = this.onSelectionChanged;
            dataGridParams.selectAll = true;
        } else {
            dataGridParams.onRowSelected = this.onRowSelected;
        }

        return (
            <React.Fragment>
                {filterRow && <HeaderRow>{filterRow}</HeaderRow>}
                <AgDataGrid {...dataGridParams} />
            </React.Fragment>
        );
    }
}

export default withTranslation()(AgDataGridSelector);
