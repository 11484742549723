import get from "lodash.get";
import type { AnyAction } from "redux";
import {
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import { OPPORTUNITIES } from "filter/state/filterActions";
import {
    EXPIRED,
    getOpportunitiesOverviewRequest,
    TEST_COVERAGE,
    TESTED,
    UNTESTED
} from "opportunities/state/opportunityStateUtils";
import { OPPORTUNITIES_PAGE } from "shell/state/pageActions";
import type { RootState } from "store";
import type { TestStatusValue } from "opportunities/state/opportunityStateUtils";

// action types
export const REQUEST_OPPORTUNITIES = "REQUEST_OPPORTUNITIES";
export const RECEIVE_OPPORTUNITIES = "RECEIVE_OPPORTUNITIES";
export const UPDATE_OPPORTUNITIES_AGGREGATE = "UPDATE_OPPORTUNITIES_AGGREGATE";
export const UPDATE_OPPORTUNITIES_OFFSET = "UPDATE_OPPORTUNITIES_OFFSET";
export const UPDATE_OPPORTUNITIES_SORT_BY = "UPDATE_OPPORTUNITIES_SORT_BY";

// action creators
export const opportunitiesPage = (): AnyAction => ({
    type: OPPORTUNITIES_PAGE
});

export const requestOpportunities = (): AnyAction => ({
    type: REQUEST_OPPORTUNITIES
});

export const receiveOpportunities = (json: any): AnyAction => ({
    type: RECEIVE_OPPORTUNITIES,
    opportunities: json
});

export const updateOpportunitiesAggregate = (groupByFields: string) => ({
    type: UPDATE_OPPORTUNITIES_AGGREGATE,
    groupByFields: groupByFields
});

export const updateOpportunitiesOffset = (offset: number): AnyAction => ({
    type: UPDATE_OPPORTUNITIES_OFFSET,
    offset: offset
});

export const updateOpportunitiesSortBy = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => ({
    type: UPDATE_OPPORTUNITIES_SORT_BY,
    sortBy: sortBy,
    sortOrder: sortOrder
});

export const processOutFields = (outFields: string[], state: any): string => {
    const hasSalesData = get(
        state,
        "init.preloadedEntities.metadata.matrix.hasSalesData"
    );
    const processedOutFields: string[] = [];
    let testStatuses =
        get(state, ["filters", OPPORTUNITIES, "showTested"]) || [];
    outFields.forEach(field => {
        if (field === TEST_COVERAGE) {
            if (testStatuses.length === 0) {
                testStatuses = [TESTED, EXPIRED, UNTESTED];
            }
            testStatuses.forEach((testStatus: TestStatusValue) => {
                switch (testStatus) {
                    case TESTED:
                        processedOutFields.push("eventWeeksTested");
                        if (hasSalesData)
                            {processedOutFields.push("promoSalesTested");}
                        break;
                    case EXPIRED:
                        processedOutFields.push("eventWeeksExpired");
                        if (hasSalesData)
                            {processedOutFields.push("promoSalesExpired");}
                        break;
                    case UNTESTED:
                        processedOutFields.push("eventWeeksUntested");
                        if (hasSalesData)
                            {processedOutFields.push("promoSalesUntested");}
                        break;
                    default:
                        break;
                }
            });
        } else {
            processedOutFields.push(field);
        }
    });
    return processedOutFields.join(",");
};

// thunks
export function fetchOpportunitiesOverview() {
    return makeRequestThunk("api/matrix/opportunities", {
        bodyFunc: function (state: RootState): any {
            return getOpportunitiesOverviewRequest(state);
        },
        method: METHOD_POST,
        preRequestFunc: requestOpportunities,
        okDispatchFunc: receiveOpportunities,
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.items.map((item: any) => {
                    return Object.assign({}, item, {
                        maxEventWeeks: json.maxEventWeeks
                    });
                }),
                filteredCount: json.totalItems
            };
        },
        showLoadMask: false
    });
}

export const getOpportunitiesExportRequest = (
    state: RootState,
    outFields: string[]
): any => {
    const overviewRequest = getOpportunitiesOverviewRequest(state);
    const outFieldsString = processOutFields(outFields, state);
    return {
        ...overviewRequest,
        offset: 0,
        outFields: outFieldsString,
        pageSize: -1
    };
};
