import type { AnyAction } from "redux";
import flatten from "flat";
import remove from "lodash.remove";
import {
    makeRequestThunk,
    METHOD_PUT,
    METHOD_POST
} from "common/shell/state/requestActions";
import type {
    OkDispatchFunction,
    OkResultFunction,
    PostPutMethod
} from "common/shell/state/requestActions";
import type { RootState } from "store";

export const RECEIVE_UNIQUE_NAME = "RECEIVE_UNIQUE_NAME";
export const REQUEST_UNIQUE_NAME = "REQUEST_UNIQUE_NAME";

export const requestUniqueName = (): AnyAction => ({
    type: REQUEST_UNIQUE_NAME
});

export const receiveUniqueName = (json: Json): AnyAction => ({
    type: RECEIVE_UNIQUE_NAME,
    json: json
});

type WriteEntityOptions = {
    okDispatchFunc: OkDispatchFunction;
    okResultFunc?: OkResultFunction;
};

export const createFieldsList = (entity: any): string[] => {
    const fields = Object.keys(flatten(entity));

    remove(fields, function (field) {
        // remove all numbers
        return field.endsWith(".objectType") || field === "objectType";
    });

    return fields;
};

export function writeEntity(
    entity: any,
    method: PostPutMethod,
    writeEntityOptions: WriteEntityOptions,
    useAutomaticFieldList = false
) {
    const mth = method ? method : METHOD_POST;
    if (
        !entity ||
        !entity.objectType ||
        (mth === METHOD_PUT && !entity.entityId)
    ) {
        throw new Error(
            "writeEntity requires an entity containing an objectType and entityId as the first argument"
        );
    }

    const options: any = {
        body: entity,
        isCancellable: false,
        method: mth,
        urlPartFunc: function (state: RootState): string {
            let urlPart = "";
            if (method === METHOD_PUT) {
                urlPart = "/" + entity.entityId;
            }
            return urlPart;
        },
        ...writeEntityOptions
    };
    if (useAutomaticFieldList) {
        options.queryParamsFunc = function (state: RootState): any {
            return {
                fields: createFieldsList(entity)
            };
        };
    }

    const api = "api/entity/" + entity.objectType.toLowerCase();
    return makeRequestThunk(api, options);
}

export function fetchUniqueName(
    entityType: string,
    name: string,
    entityId: string
) {
    return makeRequestThunk("api/entity/" + entityType + "/uniqueName/", {
        urlPartFunc: function (state: RootState): string {
            return "/" + state.init.tenant.entityId;
        },
        method: METHOD_POST,
        body: {
            objectType: "StringData",
            data: name
        },
        preRequestFunc: requestUniqueName,
        okDispatchFunc: receiveUniqueName
    });
}
