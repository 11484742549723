import get from "lodash.get";
import type { TFunction } from "i18next";
import type { AnyAction } from "redux";
import {
    openMessageBox,
    BUTTON_NOYES,
    BUTTON_YES,
    ICON_QUESTION
} from "common/shell/state/messageBoxActions";
import {
    formatSortOrderForQueryAPI,
    makeRequestThunk,
    METHOD_POST,
    METHOD_PUT
} from "common/shell/state/requestActions";
import { success } from "common/shell/state/toastActions";
import {
    andFilterCondition,
    enumFilterCondition,
    freeFormFilterCondition,
    notFilterCondition,
    orFilterCondition,
    singleValueFilterCondition,
    stringFilterCondition,
    stringInFilterCondition
} from "common/util/filter";
import { ASCENDING_QUERY_API, sortCondition } from "common/util/query";
import {
    CAMPAIGN_NAME,
    CAMPAIGN_STATE_DRAFT,
    CAMPAIGN_STATE_COMPLETED,
    CAMPAIGN_STATE_PAUSED,
    CAMPAIGN_STATE_QUEUED,
    CAMPAIGN_STATE_RUNNING,
    reduceCampaign,
    CAMPAIGN_STATE_INREVIEW
} from "campaign/state/campaignStateUtils";
import { CAMPAIGNS_PAGE } from "shell/state/pageActions";
import type { ActionOrThunk, Actions, AppDispatch, RootState } from "store";

// action types
export const CLEAR_CAMPAIGNS_FILTERS = "CLEAR_CAMPAIGNS_FILTERS";
export const RECEIVE_CAMPAIGNS = "RECEIVE_CAMPAIGNS";
export const REQUEST_CAMPAIGNS = "REQUEST_CAMPAIGNS";
export const UPDATE_CAMPAIGNS_FILTER_END_DATE =
    "UPDATE_CAMPAIGNS_FILTER_END_DATE";
export const UPDATE_CAMPAIGNS_FILTER_HOLIDAY =
    "UPDATE_CAMPAIGNS_FILTER_HOLIDAY";
export const UPDATE_CAMPAIGNS_FILTER_PRODUCT =
    "UPDATE_CAMPAIGNS_FILTER_PRODUCT";
export const UPDATE_CAMPAIGNS_FILTER_REGION = "UPDATE_CAMPAIGNS_FILTER_REGION";
export const UPDATE_CAMPAIGNS_FILTER_RETAILER =
    "UPDATE_CAMPAIGNS_FILTER_RETAILER";
export const UPDATE_CAMPAIGNS_FILTER_START_DATE =
    "UPDATE_CAMPAIGNS_FILTER_START_DATE";
export const UPDATE_CAMPAIGNS_SEARCH = "UPDATE_CAMPAIGNS_SEARCH";
export const UPDATE_CAMPAIGNS_SORT = "UPDATE_CAMPAIGNS_SORT";
export const UPDATE_CAMPAIGNS_START_INDEX = "UPDATE_CAMPAIGNS_START_INDEX";
export const UPDATE_CAMPAIGNS_SUBTAB = "UPDATE_CAMPAIGNS_SUBTAB";

export const RECEIVE_UPSTREAM_CAMPAIGNS = "RECEIVE_UPSTREAM_CAMPAIGNS";
export const REQUEST_UPSTREAM_CAMPAIGNS = "REQUEST_UPSTREAM_CAMPAIGNS";
export const UPDATE_UPSTREAM_CAMPAIGNS_START_INDEX =
    "UPDATE_UPSTREAM_CAMPAIGNS_START_INDEX";
export const UPDATE_UPSTREAM_CAMPAIGNS_SORT = "UPDATE_UPSTREAM_CAMPAIGNS_SORT";

// actions creators

export const campaignsPage = (subTab?: string): AnyAction => {
    let sTab = CAMPAIGN_STATE_DRAFT.toLowerCase();
    if (subTab) {
        // verify subTab is o.k.
        // make lower case so it looks better on the url
        const processedTab = subTab.toLowerCase().trim();
        if (
            [
                CAMPAIGN_STATE_DRAFT.toLowerCase(),
                CAMPAIGN_STATE_INREVIEW.toLowerCase(),
                CAMPAIGN_STATE_QUEUED.toLowerCase(),
                CAMPAIGN_STATE_PAUSED.toLowerCase(),
                CAMPAIGN_STATE_RUNNING.toLowerCase(),
                CAMPAIGN_STATE_COMPLETED.toLowerCase()
            ].includes(processedTab)
        ) {
            sTab = processedTab;
        }
    }
    return {
        type: CAMPAIGNS_PAGE,
        payload: {
            subTab: sTab
        }
    };
};

export const updateCampaignsSearch = (search: string): AnyAction => {
    return {
        type: UPDATE_CAMPAIGNS_SEARCH,
        search
    };
};

export const updateCampaignsStartIndex = (startIndex: number): AnyAction => {
    return {
        type: UPDATE_CAMPAIGNS_START_INDEX,
        startIndex
    };
};

export const updateCampaignsSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => {
    return {
        type: UPDATE_CAMPAIGNS_SORT,
        sortBy,
        sortOrder
    };
};

export const updateSubTab = (subTab: string): AnyAction => {
    return {
        type: UPDATE_CAMPAIGNS_SUBTAB,
        subTab
    };
};

export const updateCampaignsFilterEndDate = (endDate: Date | null) => {
    return {
        type: UPDATE_CAMPAIGNS_FILTER_END_DATE,
        endDate: endDate
    };
};

export const updateCampaignsFilterHoliday = (holidays: string[]) => {
    return {
        type: UPDATE_CAMPAIGNS_FILTER_HOLIDAY,
        holidays: holidays
    };
};

export const updateCampaignsFilterProduct = (products: string[]) => {
    return {
        type: UPDATE_CAMPAIGNS_FILTER_PRODUCT,
        products: products
    };
};

export const updateCampaignsFilterRegion = (regions: string[]) => {
    return {
        type: UPDATE_CAMPAIGNS_FILTER_REGION,
        regions: regions
    };
};

export const updateCampaignsFilterRetailer = (retailers: string[]) => {
    return {
        type: UPDATE_CAMPAIGNS_FILTER_RETAILER,
        retailers: retailers
    };
};

export const updateCampaignsFilterStartDate = (startDate: Date | null) => {
    return {
        type: UPDATE_CAMPAIGNS_FILTER_START_DATE,
        startDate: startDate
    };
};

export const clearAllFilters = () => {
    return {
        type: CLEAR_CAMPAIGNS_FILTERS
    };
};

const requestCampaigns = (): AnyAction => ({
    type: REQUEST_CAMPAIGNS
});

const receiveCampaigns = (json: Json): AnyAction => ({
    type: RECEIVE_CAMPAIGNS,
    campaigns: json
});

const requestUpstreamCampaigns = (): AnyAction => ({
    type: REQUEST_UPSTREAM_CAMPAIGNS
});

const receiveUpstreamCampaigns = (json: Json): AnyAction => ({
    type: RECEIVE_UPSTREAM_CAMPAIGNS,
    json: json
});

export const updateUpstreamCampaignsSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => {
    return {
        type: UPDATE_UPSTREAM_CAMPAIGNS_SORT,
        sortBy,
        sortOrder
    };
};

export const updateUpstreamCampaignsStartIndex = (
    startIndex: number
): AnyAction => {
    return {
        type: UPDATE_UPSTREAM_CAMPAIGNS_START_INDEX,
        startIndex
    };
};

const getRetailersAndChannels = (
    availableRetailers: any[],
    selectedRetailers: string[]
) => {
    // need to calculate channels from selectedRetailers
    // also need to determine if we need to show warning re: no financial data
    let processedRetailerIds: string[] = [];
    const processedChannelIds: string[] = [];
    availableRetailers.forEach((channel: any) => {
        let allTrue = true;
        const retailersPerChannel: string[] = [];
        channel.retailers.forEach((retailer: any) => {
            const isChannelRetailerSelected = selectedRetailers.includes(
                retailer.entityIdWithoutVersion
            );
            if (!isChannelRetailerSelected) {
                allTrue = false;
            } else {
                retailersPerChannel.push(retailer.entityIdWithoutVersion);
            }
        });
        if (allTrue) {
            processedChannelIds.push(channel.entityIdWithoutVersion);
        } else {
            processedRetailerIds = processedRetailerIds.concat(
                retailersPerChannel
            );
        }
    });
    return {
        selectedChannels: processedChannelIds,
        selectedRetailers: processedRetailerIds
    };
};

export const getCampaignsRequest = (
    campaignState: string,
    state: RootState
): ListQueryRequest => {
    let sortOrder = get(state, ["campaigns", campaignState, "sortOrder"]);
    if (sortOrder) {
        sortOrder = sortOrder.toUpperCase();
    }
    const sortBy = get(state, ["campaigns", campaignState, "sortBy"]);
    const sortConditions = [sortCondition(sortBy, sortOrder)];
    // let's add a secondary sort by name to make sure the order is consistent
    // only add if sortBy isn't name
    if (sortBy !== CAMPAIGN_NAME) {
        sortConditions.push(sortCondition(CAMPAIGN_NAME, ASCENDING_QUERY_API));
    }
    const search = get(state, ["campaigns", campaignState, "search"]);
    const filterSearchCondition = search
        ? freeFormFilterCondition(search, [
              "channels.displayName",
              "creator.fullName",
              "creator.firstName",
              "creator.lastName",
              "holiday.name",
              "name",
              "productGroups.name",
              "regions.name",
              "retailers.displayName",
              "tenantTestPlatform.testPlatform.displayName"
          ])
        : null;
    const stateCondition: FilterCondition = enumFilterCondition(
        "state",
        campaignState,
        "CampaignState"
    );
    /*    
    if (campaignState === CAMPAIGN_STATE_QUEUED) {
        // add in paused state
        stateCondition = orFilterCondition([
            stateCondition,
            enumFilterCondition("state", CAMPAIGN_STATE_PAUSED, "CampaignState")
        ]);
    }
*/
    let filterCondition: FilterCondition = stateCondition;

    const otherFilterConditions: FilterCondition[] = [];

    // Add holiday filter
    const holidays = get(
        state,
        ["campaigns", campaignState, "filters", "holidays"],
        []
    );
    if (holidays.length > 0) {
        otherFilterConditions.push(
            stringInFilterCondition("holiday.entityId", holidays)
        );
    }

    // Add product filter
    const products = get(
        state,
        ["campaigns", campaignState, "filters", "products"],
        []
    );
    if (products.length > 0) {
        otherFilterConditions.push(
            orFilterCondition([
                stringInFilterCondition(
                    "productGroups.adminProductGroup.entityId",
                    products
                ),
                stringInFilterCondition(
                    "productGroups.adminProductGroup.components.entityId",
                    products
                )
            ])
        );
    }

    // Add region filter
    const regions = get(
        state,
        ["campaigns", campaignState, "filters", "regions"],
        []
    );

    if (regions.length > 0) {
        otherFilterConditions.push(
            stringInFilterCondition("regions.entityId", regions)
        );
    }

    // Add date filters
    const startDate = get(
        state,
        ["campaigns", campaignState, "filters", "startDate"],
        null
    );
    if (startDate) {
        otherFilterConditions.push(
            singleValueFilterCondition(
                "DateFilterValue",
                "endDate",
                startDate,
                "GREATER_THAN_EQUAL_TO"
            )
        );
    }
    const endDate = get(
        state,
        ["campaigns", campaignState, "filters", "endDate"],
        null
    );
    if (endDate) {
        otherFilterConditions.push(
            singleValueFilterCondition(
                "DateFilterValue",
                "endDate",
                endDate,
                "LESS_THAN_EQUAL_TO"
            )
        );
    }

    // Add retailer/channel filter
    const retailers = get(
        state,
        ["campaigns", campaignState, "filters", "retailers"],
        []
    );
    const availableRetailers = get(
        state,
        "init.preloadedEntities.filters.retailers",
        []
    );
    const retailersAndChannels = getRetailersAndChannels(
        availableRetailers,
        retailers
    );
    let retailerFilterCondition = null;
    if (retailersAndChannels.selectedRetailers.length > 0) {
        retailerFilterCondition = stringInFilterCondition(
            "retailers.entityId",
            retailersAndChannels.selectedRetailers
        );
    }
    let channelFilterCondition = null;
    if (retailersAndChannels.selectedChannels.length > 0) {
        channelFilterCondition = stringInFilterCondition(
            "channels.entityId",
            retailersAndChannels.selectedChannels
        );
    }
    const retailerChannelFilterConditions = [];
    if (retailerFilterCondition) {
        retailerChannelFilterConditions.push(retailerFilterCondition);
    }
    if (channelFilterCondition) {
        retailerChannelFilterConditions.push(channelFilterCondition);
    }
    if (retailerChannelFilterConditions.length > 0) {
        otherFilterConditions.push(
            orFilterCondition([
                ...retailerChannelFilterConditions,
                enumFilterCondition(
                    "retailerSelection",
                    "ANY",
                    "RetailerSelection"
                )
            ])
        );
    }

    // Add search condition
    if (filterSearchCondition) {
        otherFilterConditions.push(filterSearchCondition);
    }
    if (otherFilterConditions.length > 0) {
        filterCondition = andFilterCondition([
            ...otherFilterConditions,
            stateCondition
        ]);
    }

    return {
        entity: "Campaign",
        filterCondition: filterCondition,
        maxResults: get(state, ["campaigns", campaignState, "maxResults"]),
        model: "CampaignModel/campaignGrid",
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: null,
        sortConditions: sortConditions,
        startIndex: get(state, ["campaigns", campaignState, "startIndex"])
    };
};

const getUpstreamCampaignsRequest = (
    search: string,
    state: RootState
): ListQueryRequest => {
    const notCurrentCampaignFilterCondition = notFilterCondition(
        stringFilterCondition(
            "entityId",
            get(state, "campaign.entityId"),
            "EQUALS"
        )
    );

    const sortOrder = formatSortOrderForQueryAPI(
        get(state, "campaigns.upstreamCampaigns.sortOrder")
    );
    const sortConditions = [
        sortCondition(
            get(state, "campaigns.upstreamCampaigns.sortBy"),
            sortOrder
        )
    ];

    const filterSearchCondition = search
        ? freeFormFilterCondition(search, [
              "name",
              "creator.fullName",
              "creator.firstName",
              "creator.lastName"
          ])
        : null;

    let filterCondition: FilterCondition = notCurrentCampaignFilterCondition;

    if (filterSearchCondition) {
        filterCondition = andFilterCondition([
            notCurrentCampaignFilterCondition,
            filterSearchCondition
        ]);
    }

    return {
        entity: "Campaign",
        filterCondition: filterCondition,
        maxResults: get(state, "campaigns.upstreamCampaigns.maxResults"),
        model: "CampaignModel/campaignGrid",
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: null,
        sortConditions: sortConditions,
        startIndex: get(state, "campaigns.upstreamCampaigns.startIndex")
    };
};

// thunks

export function fetchCampaigns(campaignState: string = CAMPAIGN_STATE_DRAFT) {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getCampaignsRequest(campaignState, state);
        },
        preRequestFunc: requestCampaigns,
        okDispatchFunc: receiveCampaigns,
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.result.map(reduceCampaign),
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

export const ignoreAlertsConfirmation = (
    campaign: any,
    t: TFunction,
    dispatch: AppDispatch,
    reopenHandler: NoArgsHandler,
    callbackFunction: NoArgsHandler
): void => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_NOYES,
            callbackData: {
                campaign: campaign,
                callbackFunction: callbackFunction,
                reopenHandler: reopenHandler
            },
            callbackFunction: handleIgnoreAlertsConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey: "campaign.ignore_alerts_confirmation",
                values: {
                    name: campaign.name
                }
            },
            showCloseIcon: false,
            title: t("campaign.ignore_alerts_title")
        })
    );
};

const handleIgnoreAlertsConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
): void => {
    if (button === BUTTON_YES) {
        dispatch(ingoreAlerts(callbackData));
    } else {
        const { reopenHandler } = callbackData;
        reopenHandler();
    }
};

function ingoreAlerts(callbackData: any) {
    const entityId = get(callbackData, "campaign.entityId");
    const name = get(callbackData, "campaign.name");
    const callbackFunction = get(callbackData, "callbackFunction");
    return makeRequestThunk(
        "api/campaign/" + entityId + "/clearalertmessages",
        {
            isCancellable: false,
            method: METHOD_PUT,
            okDispatchFunc: (json: Json, state: RootState): Actions => {
                const actions: ActionOrThunk[] = [];
                const successMessage = {
                    resourceKey: "campaign.silence_alerts_notification",
                    values: {
                        name: name
                    }
                };
                if (callbackFunction) {
                    callbackFunction();
                }
                actions.push(success(successMessage));
                return actions;
            }
        }
    );
}

export const refreshOffersConfirmation = (
    campaign: any,
    refreshLegal: boolean,
    refreshOfferText: boolean,
    t: TFunction,
    dispatch: AppDispatch,
    reopenHandler: NoArgsHandler,
    callbackFunction: NoArgsHandler
): void => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_NOYES,
            callbackData: {
                campaign: campaign,
                callbackFunction: callbackFunction,
                refreshLegal: refreshLegal,
                refreshOfferText: refreshOfferText,
                reopenHandler: reopenHandler
            },
            callbackFunction: handleRefreshOffersConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey: "campaign.refresh_offers_confirmation",
                values: {
                    name: campaign.name
                }
            },
            showCloseIcon: false,
            title: t("campaign.refresh_offers_title")
        })
    );
};

const handleRefreshOffersConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
): void => {
    if (button === BUTTON_YES) {
        dispatch(refreshOffers(callbackData));
    } else {
        const { reopenHandler } = callbackData;
        reopenHandler();
    }
};

function refreshOffers(callbackData: any) {
    const entityId = get(callbackData, "campaign.entityId");
    const name = get(callbackData, "campaign.name");
    const callbackFunction = get(callbackData, "callbackFunction");
    const refreshLegal = get(callbackData, "refreshLegal");
    const refreshOfferText = get(callbackData, "refreshOfferText");
    return makeRequestThunk("api/campaign/" + entityId + "/refresh", {
        isCancellable: false,
        body: {
            objectType: "CampaignRefreshRequest",
            refreshLegal: refreshLegal,
            refreshOfferText: refreshOfferText
        },
        method: METHOD_PUT,
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const successMessage = {
                resourceKey: "campaign.refresh_offers_notification",
                values: {
                    name: name
                }
            };
            if (callbackFunction) {
                callbackFunction();
            }
            actions.push(success(successMessage));
            return actions;
        }
    });
}

export function fetchUpstreamCampaigns(search: string) {
    return makeRequestThunk("api/campaign/query/upstream", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getUpstreamCampaignsRequest(search, state);
        },
        preRequestFunc: requestUpstreamCampaigns,
        okDispatchFunc: receiveUpstreamCampaigns,
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.result.map(reduceCampaign),
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}
