import * as React from "react";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import isNil from "lodash.isnil";
import InputNumber from "rc-input-number";
import styled from "styled-components/macro";
import { inputComponentStyle } from "common/components/styled/Input";
import type { InputStyleProps } from "common/components/styled/Input";
import SvgIcon from "common/components/SvgIcon";
import theme from "common/components/theme";
import { ReactComponent as DownCaretIcon } from "common/icons/DownCaret.svg";
import { ReactComponent as UpCaretIcon } from "common/icons/UpCaret.svg";
import { isEmptyString } from "common/util/lang";

type NumberInputProps = {
    formatter?: ((value: string) => string) | null;
    max?: number;
    min?: number;
    onBlur?: AnyFunction;
    onChange: (num: number | null, isValid?: boolean) => void;
    onPressEnter?: AnyFunction;
    parser?: ((value: string) => string) | null;
    precision?: number;
    step?: number;
    tabIndex?: number;
    value: number | undefined | null;
    width?: string;
} & InputStyleProps;

export const basicFormatter = (value: string): string => {
    return value;
};

export const basicParser = (value: string): string => {
    return value;
};

export const currencyFormatter = (value: string): string => {
    if (isNil(value) || isEmptyString(value)) {
        return "";
    }
    return `$${value}`;
};

export const currencyFormatterBound = (
    currencySymbol: string,
    value: string
): string => {
    if (isNil(value) || isEmptyString(value)) {
        return "";
    }
    const symbol = currencySymbol ? currencySymbol : "$";
    return `${symbol}${value}`;
};

export const currencyParser = (value: string): string => {
    return value.replace("$", "");
};

export const currencyParserBound = (
    currencySymbol: string,
    value: string
): string => {
    const symbol = currencySymbol ? currencySymbol : "$";
    return value.replace(symbol, "");
};

export const percentFormatter = (value: string): string => {
    if (isNil(value) || isEmptyString(value)) {
        return "";
    }
    return `${value}%`;
};

export const percentParser = (value: string): string => {
    return value.replace("%", "");
};

export const pixelFormatter = (value: string): string => {
    if (isNil(value) || isEmptyString(value)) {
        return "";
    }
    return `${value}px`;
};

export const pixelParser = (value: string): string => {
    return value.replace("px", "");
};

function isNumberValidForDisplay(
    number: string,
    precision: number,
    min?: number | null,
    max?: number | null
): boolean {
    const canBeNegative = isNil(min) || isEmptyString(min) || min < 0;
    const pattern = canBeNegative
        ? precision === 0
            ? new RegExp("^-?\\d*$")
            : new RegExp("^-?\\d*\\.?\\d{0," + precision + "}$")
        : precision === 0
        ? new RegExp("^\\d*$")
        : new RegExp("^\\d*\\.?\\d{0," + precision + "}$");
    const numberValue = +number;
    if (
        !number ||
        (pattern.test(number) &&
            (isNil(max) || numberValue <= max) &&
            (isNil(min) || numberValue >= min) &&
            (canBeNegative || numberValue >= 0))
    ) {
        return true;
    }
    return false;
}

function isNumberValid(
    number: any,
    min?: number | null,
    max?: number | null
): boolean {
    return (
        !isNil(number) &&
        !isEmptyString(number) &&
        (isNil(min) || isEmptyString(min) || number >= min) &&
        (isNil(max) || isEmptyString(max) || number <= max)
    );
}
/*
 *  XXXXXXXXXX DO NOT EXTEND LIBRARIES!!! XXXXXXXXXX
 *
 *  in order to keep the input display from changing, we had to intercept the change to
 *  InputNumber's inputValue state value to keep the value from changing. If we don't
 *  do this, then the value changes from inputValue (which is whatever they type)
 *  to an acceptable value onBlur.
 *
 * */

// Update/fix this typing when moving up version that includes typescript for rc-input-number
type InputNumberProps = {
    downHandler: React.ReactNode;
    upHandler: React.ReactNode;
} & NumberInputProps;

class ExtendedInputNumber extends InputNumber {
    //DO NOT EXTEND LIBRARIES!!! Only in dire circumstances
    getValueFromEvent(event: React.SyntheticEvent<HTMLInputElement>) {
        const value = super.getValueFromEvent(event);
        const { min, max, parser, precision } = this.props;
        const { inputValue } = this.state;
        const canBeNegative = isNil(min) || min < 0;
        const eventType = get(event, "nativeEvent.inputType", "");
        let parsedValue = parser ? parser(value) : value;
        if (canBeNegative && isEqual(parsedValue, "-")) {
            if (
                isEqual(eventType, "deleteContentBackward") ||
                isEqual(eventType, "deleteContentForward")
            ) {
                parsedValue = "";
            } else if (isEqual(eventType, "insertText")) {
                parsedValue = "-0";
            }
        }
        if (isNumberValidForDisplay(parsedValue, precision, min, max)) {
            return parsedValue;
        }
        if (isNil(inputValue) || isEmptyString(inputValue)) {
            return "";
        }
        return inputValue + "";
    }
    //DO NOT EXTEND LIBRARIES!!! Only in dire circumstances
}

const StyledExtendedInputNumber = styled(
    (ExtendedInputNumber as unknown) as React.ComponentType<InputNumberProps>
)`
    align-items: center;
    display: flex;
    position: relative;

    input {
        text-align: right;
        ${inputComponentStyle}
    }

    .rc-input-number-handler-wrap {
        align-items: start;
        bottom: 0px;
        border-left: 1px solid ${theme.inputBorder};
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        right: 0px;
        top: 0px;
        width: 24px;

        .rc-input-number-handler {
            align-items: center;
            display: flex;
            height: 18px;
            justify-content: center;
            width: 23px;
        }

        .rc-input-number-handler-down {
            border-top: 1px solid ${theme.inputBorder};
        }
    }

    .rc-input-number-input-wrap {
        width: 100%;
    }

    .rc-input-number-input {
        overflow: hidden;
        padding-right: 32px;
        text-overflow: ellipsis;
        white-space: nowrap;
        ${props => props.boxSizing && `box-sizing: ${props.boxSizing};`}
        ${props => props.cssWidth && `width: ${props.cssWidth};`}
    }
`;

const NumberInput = (props: NumberInputProps) => {
    const { step, precision } = props;
    if (!isNil(step) && !isNil(precision)) {
        const stepString = step.toString();
        const precisionIndex = stepString.indexOf(".");
        const stepDecimalLength = stepString.length - (precisionIndex + 1);
        if (stepDecimalLength > precision && precisionIndex !== -1) {
            throw new Error(
                `Step (${step}) is mis-configured for the current precision (${precision}).`
            );
        }
    }

    const onChange = props.onChange
        ? (number: number | null) => {
              props.onChange(
                  number,
                  isNumberValid(number, props.min, props.max)
              );
          }
        : (number: number | null) => undefined;

    return (
        <StyledExtendedInputNumber
            {...props}
            data-lpignore={true}
            onChange={onChange}
            downHandler={
                <SvgIcon
                    color={theme.modalSubText}
                    height="10px"
                    icon={DownCaretIcon}
                    width="10px"
                />
            }
            upHandler={
                <SvgIcon
                    color={theme.modalSubText}
                    height="10px"
                    icon={UpCaretIcon}
                    width="10px"
                />
            }
        />
    );
};

NumberInput.defaultProps = {
    precision: 2,
    step: 0.01
};

NumberInput.displayName = "NumberInput";

export default NumberInput;
