import type { AnyAction } from "redux";
import noAction from "common/util/noAction";
import { reduceObject } from "common/util/object";
import {
    RECEIVE_PROMOTION_TYPE_DETECTION,
    REQUEST_PROMOTION_TYPE_DETECTION
} from "administration/promotionTypeDetection/state/promotionTypeDetectionActions";

type PromotionTypesState = {
    minAnyPromoPercentExecution: number;
    minAnyPromoPercentExecutionPercent: string;
    minDisplayPercentExecution: number;
    minDisplayPercentExecutionPercent: string;
    minFeaturePercentExecution: number;
    minFeaturePercentExecutionPercent: string;
};

export const defaultState: PromotionTypesState = {
    minAnyPromoPercentExecution: 0,
    minAnyPromoPercentExecutionPercent: "",
    minDisplayPercentExecution: 0,
    minDisplayPercentExecutionPercent: "",
    minFeaturePercentExecution: 0,
    minFeaturePercentExecutionPercent: ""
};

export const reducePromotionTypeDetection = (
    state: PromotionTypesState,
    tenantConfiguration: any
): PromotionTypesState => {
    return reduceObject(
        tenantConfiguration,
        "minAnyPromoPercentExecution",
        [
            "minAnyPromoPercentExecutionFormatPercent",
            "minAnyPromoPercentExecutionPercent"
        ],
        "minDisplayPercentExecution",
        [
            "minDisplayPercentExecutionFormatPercent",
            "minDisplayPercentExecutionPercent"
        ],
        "minFeaturePercentExecution",
        [
            "minFeaturePercentExecutionFormatPercent",
            "minFeaturePercentExecutionPercent"
        ]
    );
};

const promotionTypeDetection = (
    state: PromotionTypesState = defaultState,
    action: AnyAction = noAction
): PromotionTypesState => {
    switch (action.type) {
        case REQUEST_PROMOTION_TYPE_DETECTION:
            return defaultState;
        case RECEIVE_PROMOTION_TYPE_DETECTION:
            return reducePromotionTypeDetection(
                state,
                action.tenantConfiguration
            );
        default:
            return state;
    }
};

export default promotionTypeDetection;
