import type { AnyAction } from "redux";
import noAction from "common/util/noAction";
import {
    RECEIVE_RECENT_NOTIFICATIONS,
    REQUEST_RECENT_NOTIFICATIONS
} from "notifications/state/notificationsActions";
import type { NotificationType } from "notifications/state/notificationsStateUtils";
import { reduceNotification } from "notifications/state/notificationsStateUtils";

type NotificationsState = {
    recent: NotificationType[];
    recentTotalCount: number;
};

export const defaultState: NotificationsState = {
    recent: [],
    recentTotalCount: -1
};

const notifications = (
    state: NotificationsState = defaultState,
    action: AnyAction = noAction
): NotificationsState => {
    switch (action.type) {
        case RECEIVE_RECENT_NOTIFICATIONS:
            return Object.assign({}, state, {
                recent: action.notifications.result.map(reduceNotification),
                recentTotalCount: action.notifications.totalCount
            });
        case REQUEST_RECENT_NOTIFICATIONS:
            return defaultState;
        default:
            return state;
    }
};

export default notifications;
