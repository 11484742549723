import get from "lodash.get";
import { ReactComponent as EventIcon } from "common/icons/Event.svg";
import { ReactComponent as ExperimentIcon } from "common/icons/Experiment.svg";
import { ReactComponent as GuidanceIcon } from "common/icons/Guidance.svg";
import { ReactComponent as HistoryIcon } from "common/icons/History.svg";
import { reduceObject } from "common/util/object";

type LinkDetailsType = {
    campaignId: string | undefined | null;
    productGroupIds: string[] | undefined | null;
};

export type NotificationType = {
    created: string;
    displayString: string;
    linkDetails: LinkDetailsType;
    maxDate: string | undefined | null;
    minDate: string | undefined | null;
    objectType: string;
    tooltip: string | undefined | null;
};

// notification types
export const NOTIFICATION_TYPE_CAMPAIGN_END = "CampaignEndNotification";
export const NOTIFICATION_TYPE_CAMPAIGN_START = "CampaignStartNotification";
export const NOTIFICATION_TYPE_IMPORTED_EVENT = "NewImportedEventsNotification";
export const NOTIFICATION_TYPE_NEW_ACTIVE_GUIDELINE_COLLECTION =
    "NewActiveGuidelineCollectionNotification";

// notification fields list
export const NOTIFICATION_FIELD_CREATED = "created";
export const NOTIFICATION_FIELD_DISPLAY_STRING = "displayString";
export const NOTIFICATION_FIELD_ID = "entityId";
export const NOTIFICATION_FIELD_LINK_DETAILS_CAMPAIGN_ID =
    "linkDetails.campaignId";
export const NOTIFICATION_FIELD_LINK_DETAILS_END_DATE = "maxDate";
export const NOTIFICATION_FIELD_LINK_DETAILS_PRODUCT_GROUP_IDS =
    "linkDetails.productGroupIds";
export const NOTIFICATION_FIELD_LINK_DETAILS_START_DATE = "minDate";
export const NOTIFICATION_FIELD_TOOLTIP = "tooltip";
const NOTIFICATION_FIELD_OBJECT_TYPE = "objectType";

export const getNotificationIcon = (notificationType: string) => {
    switch (notificationType) {
        case NOTIFICATION_TYPE_CAMPAIGN_END:
        case NOTIFICATION_TYPE_NEW_ACTIVE_GUIDELINE_COLLECTION:
            return GuidanceIcon;
        case NOTIFICATION_TYPE_CAMPAIGN_START:
            return ExperimentIcon;
        case NOTIFICATION_TYPE_IMPORTED_EVENT:
            return EventIcon;
        default:
            return HistoryIcon;
    }
};

export const reduceNotification = (json: any): NotificationType => {
    const notification = reduceObject(
        json,
        NOTIFICATION_FIELD_CREATED,
        NOTIFICATION_FIELD_DISPLAY_STRING,
        NOTIFICATION_FIELD_LINK_DETAILS_END_DATE,
        NOTIFICATION_FIELD_LINK_DETAILS_START_DATE,
        NOTIFICATION_FIELD_OBJECT_TYPE,
        NOTIFICATION_FIELD_TOOLTIP
    );
    notification.linkDetails = {
        campaignId: get(json.linkDetails, "campaignId"),
        productGroupIds: get(json.linkDetails, "productGroupIds")
    };
    return notification;
};
