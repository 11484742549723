import type { AnyAction } from "redux";
import noAction from "common/util/noAction";
import {
    RECEIVE_COMPLIANCE_EVENT,
    RECEIVE_COMPLIANCE_GUIDANCE,
    RECEIVE_COMPLIANCE_OVERVIEW,
    REQUEST_COMPLIANCE_EVENT,
    REQUEST_COMPLIANCE_OVERVIEW,
    UPDATE_COMPLIANCE_EVENT,
    UPDATE_COMPLIANCE_OVERVIEW_AGGREGATE,
    UPDATE_COMPLIANCE_OVERVIEW_GUIDANCE,
    UPDATE_COMPLIANCE_OVERVIEW_OFFSET,
    UPDATE_COMPLIANCE_OVERVIEW_SORT
} from "compliance/state/complianceActions";
import {
    defaultComplianceState,
    processOverviewAggregate,
    processOverviewGuidance,
    reduceCompliance,
    reduceOverview,
    reducePromotionEventGuidelineCompliance
} from "compliance/state/complianceStateUtils";
import type { ComplianceState } from "compliance/state/complianceStateUtils";
import { COMPLIANCE_EVENT_PAGE } from "shell/state/pageActions";

const compliance = (
    state: ComplianceState = defaultComplianceState,
    action: AnyAction = noAction
): ComplianceState => {
    switch (action.type) {
        case COMPLIANCE_EVENT_PAGE:
            return Object.assign({}, state, {
                event: {
                    entityId: action.payload.eventId
                }
            });
        case REQUEST_COMPLIANCE_OVERVIEW:
            return state;
        case RECEIVE_COMPLIANCE_OVERVIEW: {
            const reducedOverview = reduceOverview(state, action.overview);
            return Object.assign({}, state, {
                overview: {
                    ...reducedOverview
                }
            });
        }
        case REQUEST_COMPLIANCE_EVENT:
            return state;
        case RECEIVE_COMPLIANCE_EVENT:
            return Object.assign({}, state, {
                event: reducePromotionEventGuidelineCompliance(action.event)
            });
        case RECEIVE_COMPLIANCE_GUIDANCE:
            return Object.assign({}, state, {
                event: {
                    ...state.event,
                    guidelineCompliance: {
                        ...state.event.guidelineCompliance,
                        [action.field]: reduceCompliance(action.guidance)
                    }
                }
            });
        case UPDATE_COMPLIANCE_OVERVIEW_SORT:
            return Object.assign({}, state, {
                overview: {
                    ...state.overview,
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder
                }
            });
        case UPDATE_COMPLIANCE_EVENT:
            return state;
        case UPDATE_COMPLIANCE_OVERVIEW_OFFSET:
            return Object.assign({}, state, {
                overview: {
                    ...state.overview,
                    offset: action.offset
                }
            });
        case UPDATE_COMPLIANCE_OVERVIEW_GUIDANCE:
            return processOverviewGuidance(state, action.guidances);
        case UPDATE_COMPLIANCE_OVERVIEW_AGGREGATE:
            return processOverviewAggregate(state, action.groupByFields);
        default:
            return state;
    }
};

export default compliance;
