import React from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import BaseModal from "react-modal";
import { Provider } from "react-redux";
import { unregister as unregisterServiceWorker } from "registerServiceWorker";
import { ThemeProvider } from "styled-components/macro";

import App from "common/App";
import theme from "common/components/theme";
import i18n from "common/i18n";
import AppErrorBoundary from "common/shell/components/AppErrorBoundary";

export const renderApplication = (store: any, appWindowName: string) => {
    // Warnings in the console if the Modal appElement is not explicitly set (occurred after React 16 upgrade)
    BaseModal.setAppElement("body");

    const AppCode = (
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <CookiesProvider>
                    <ThemeProvider theme={theme}>
                        <AppErrorBoundary>
                            {/* @ts-expect-error withCookies causes typescript error - App is wrapped in withCookies */}
                            <App appWindowName={appWindowName} />
                        </AppErrorBoundary>
                    </ThemeProvider>
                </CookiesProvider>
            </I18nextProvider>
        </Provider>
    );

    let RenderCode = AppCode;

    if (process.env.NODE_ENV !== "production") {
        RenderCode = <React.StrictMode>{AppCode}</React.StrictMode>;
    }

    ReactDOM.render(RenderCode, document.getElementById("root"));

    unregisterServiceWorker();
};
