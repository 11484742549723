import moment from "moment";
import type { AnyAction } from "redux";
import {
    andFilterCondition,
    booleanFilterCondition,
    enumFilterCondition,
    notFilterCondition,
    nullFilterCondition,
    orFilterCondition,
    singleValueFilterCondition
} from "common/util/filter";
import { sortCondition } from "common/util/query";
import { DESCENDING_QUERY_API } from "common/util/query";
import {
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import {
    NOTIFICATION_FIELD_CREATED,
    NOTIFICATION_FIELD_DISPLAY_STRING,
    NOTIFICATION_FIELD_ID,
    NOTIFICATION_FIELD_TOOLTIP,
    NOTIFICATION_FIELD_LINK_DETAILS_CAMPAIGN_ID,
    NOTIFICATION_FIELD_LINK_DETAILS_END_DATE,
    NOTIFICATION_FIELD_LINK_DETAILS_PRODUCT_GROUP_IDS,
    NOTIFICATION_FIELD_LINK_DETAILS_START_DATE
} from "notifications/state/notificationsStateUtils";
import { SIGNAL_KEY_FETCH_RECENT_NOTIFICATIONS } from "shell/state/requestStateUtils";
import type { RootState } from "store";

export const RECEIVE_RECENT_NOTIFICATIONS = "RECEIVE_RECENT_NOTIFICATIONS";
export const REQUEST_RECENT_NOTIFICATIONS = "REQUEST_RECENT_NOTIFICATIONS";

// action creators
export const receiveRecentNotifications = (json: Json): AnyAction => ({
    type: RECEIVE_RECENT_NOTIFICATIONS,
    notifications: json
});

export const requestRecentNotifications = (): AnyAction => ({
    type: REQUEST_RECENT_NOTIFICATIONS
});

const getFilterCondition = (serverMode?: string): any => {
    const conditions = [];
    const weeks = serverMode === "demo" ? 4 : 2;
    const startDate = moment().subtract(weeks, "week").startOf("day").toDate();

    // notification was created within the last N weeks
    conditions.push(
        singleValueFilterCondition(
            "DateTimeFilterValue",
            NOTIFICATION_FIELD_CREATED,
            startDate,
            "GREATER_THAN_EQUAL_TO"
        )
    );

    // valid running campaign notification is either silent or not archived
    const validRunningCampaignNotification = andFilterCondition([
        booleanFilterCondition("runningCampaign.silent", false),
        notFilterCondition(
            enumFilterCondition(
                "runningCampaign.state",
                "ARCHIVED",
                "CampaignState"
            )
        )
    ]);

    // valid completed campaign notification is either silent or not archived
    const validCompletedCampaignNotification = andFilterCondition([
        booleanFilterCondition("completedCampaign.silent", false),
        notFilterCondition(
            enumFilterCondition(
                "completedCampaign.state",
                "ARCHIVED",
                "CampaignState"
            )
        )
    ]);

    // valid non-campaign notification
    const validNonCampaignNotification = andFilterCondition([
        nullFilterCondition("runningCampaign.id"),
        nullFilterCondition("completedCampaign.id")
    ]);

    conditions.push(
        orFilterCondition([
            validRunningCampaignNotification,
            validCompletedCampaignNotification,
            validNonCampaignNotification
        ])
    );

    return andFilterCondition(conditions);
};

const getRecentNotificationsRequest = (state: RootState): any => {
    return {
        entity: "Notification",
        filterCondition: getFilterCondition(
            state.init && state.init.serverMode
        ),
        maxResults: -1,
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: [
            NOTIFICATION_FIELD_CREATED,
            NOTIFICATION_FIELD_DISPLAY_STRING,
            NOTIFICATION_FIELD_ID,
            NOTIFICATION_FIELD_TOOLTIP,
            NOTIFICATION_FIELD_LINK_DETAILS_CAMPAIGN_ID,
            NOTIFICATION_FIELD_LINK_DETAILS_END_DATE,
            NOTIFICATION_FIELD_LINK_DETAILS_PRODUCT_GROUP_IDS,
            NOTIFICATION_FIELD_LINK_DETAILS_START_DATE
        ],
        sortConditions: [
            sortCondition(NOTIFICATION_FIELD_CREATED, DESCENDING_QUERY_API)
        ],
        startIndex: 0
    };
};

export function fetchRecentNotifications() {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState): any {
            return getRecentNotificationsRequest(state);
        },
        preRequestFunc: requestRecentNotifications,
        okDispatchFunc: receiveRecentNotifications,
        showLoadMask: false,
        showComponentLoadMask: true,
        signalKey: SIGNAL_KEY_FETCH_RECENT_NOTIFICATIONS
    });
}
