import * as React from "react";
import styled from "styled-components/macro";
import SvgIcon from "common/components/SvgIcon";
import theme from "common/components/theme";
import { ReactComponent as ExpandIcon } from "common/icons/Expand.svg";
import type { OfferRecommendation } from "offerRecommendations/state/offerRecommendationStateUtils";
import OfferImage, { THUMBNAIL_MEDIUM } from "offers/components/OfferImage";

type HoverImageProps = {
    size?: number;
    top?: number;
};
const HoverImage = styled.div<HoverImageProps>`
    background: ${theme.imageHover};
    cursor: pointer;
    display: none;
    height: ${props => props.size}px;
    justify-content: center;
    position: absolute;
    top: ${props => props.top}px;
    width: ${props => props.size}px;
`;

HoverImage.displayName = "HoverImage";

const HoverImageWrapper = styled.div`
    :hover > ${HoverImage} {
        display: flex;
    }
`;

HoverImageWrapper.displayName = "HoverImageWrapper";

type HoverOfferImageType = {
    image?: string;
    offer?: OfferRecommendation;
    onImageClick: AnyFunction;
    requiresImage?: boolean;
    rowHeight: number;
    size: number;
};

const HoverOfferImage = (props: HoverOfferImageType) => {
    const {
        image,
        offer,
        onImageClick,
        rowHeight,
        requiresImage = true,
        size
    } = props;

    let imgSrc = undefined;
    if (image && requiresImage) {
        imgSrc = image;
    }
    return (
        <HoverImageWrapper>
            <OfferImage src={imgSrc} size={size} />
            <HoverImage
                onClick={onImageClick.bind(null, offer)}
                size={size}
                top={(rowHeight - size) / 2}
            >
                <SvgIcon
                    color={theme.lightText}
                    height="18px"
                    icon={ExpandIcon}
                    width="18px"
                />
            </HoverImage>
        </HoverImageWrapper>
    );
};

HoverOfferImage.defaultProps = {
    size: THUMBNAIL_MEDIUM
};

HoverOfferImage.displayName = "HoverOfferImage";

export default HoverOfferImage;
