import * as React from "react";
import type { GetDerivedStateFromProps } from "react";
import type { ParseKeys, TFunction } from "i18next";
import isEqual from "lodash.isequal";
import isNil from "lodash.isnil";
import xor from "lodash.xor";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { CheckboxGroup, Checkbox } from "common/components/CheckboxGroup";
import type {
    HorizontalPosition,
    VerticalPosition
} from "common/components/Dropdown";
import { marginProps, marginPropTypes } from "common/components/styled/util";
import FilterDropdownView from "common/filter/components/FilterDropdownView";
import theme from "common/components/theme";
import { processSelectedItemsText } from "common/util/filter";
import { isEmptyString } from "common/util/lang";
import { reduceObject } from "common/util/object";
import type { TrackingComponentLabel } from "common/util/trackingEvents";

const DEFAULT_LABEL_FIELD = "displayName";
const DEFAULT_VALUE_FIELD = "entityIdWithoutVersion";

type CheckboxFilterDropdownViewOwnProps = {
    allLabelKey: string;
    buttonWidth?: string;
    countLabelKey: string;
    disabled?: boolean;
    filterHeaderTextKey: string;
    filterSubHeaderTextKey?: string;
    filterTextListAll?: boolean;
    highlightItems?: string[];
    highlightBackground?: boolean;
    highlightSelected?: boolean;
    horizontalPosition?: HorizontalPosition;
    icon?: IconType;
    iconHeight?: number;
    iconWidth?: number;
    labelField?: string;
    minSelections?: number;
    maxSelections?: number;
    minWidth?: string;
    name: string;
    nodes: any[];
    onFilterChange: (checked: string[]) => void;
    renderNodes?: (
        nodes: any[],
        labelField: string,
        valueField: string,
        selectedValues?: string[],
        maxSelections?: number,
        highlightItems?: string[]
    ) => React.ReactNode;
    selected: string[];
    showSingleNode?: boolean;
    textColor?: string;
    trackingComponentLabel: TrackingComponentLabel;
    valueField?: string;
    verticalPosition?: VerticalPosition;
} & marginPropsType;
type CheckboxFilterDropdownViewProps = CheckboxFilterDropdownViewOwnProps &
    WithTranslation;
type CheckboxFilterDropdownViewState = {
    checked: string[];
    filteredNodes: any[];
    prevPropsSelected: string[];
};

export const CheckboxDivider = styled.div`
    border-bottom: 1px solid ${theme.border};
    margin: 0px -16px;
    ${marginProps}
`;

CheckboxDivider.displayName = "CheckboxDivider";

export const getFilterText = (
    count: number,
    allLabelKey: string,
    countLabelKey: string,
    t: TFunction
): string => {
    let filterText = t(allLabelKey as ParseKeys);
    if (count > 0) {
        filterText = t(countLabelKey as ParseKeys, {
            count: count
        });
    }
    return filterText;
};

const getFilterTextListAll = (
    selected: string[],
    allLabelKey: string,
    t: TFunction
): string => {
    if (selected.length > 0) {
        return selected.join(", ");
    }
    return t(allLabelKey as ParseKeys);
};

const renderNodes = (
    nodes: any[],
    labelField: string,
    valueField: string,
    selectedValues: string[] = [],
    maxSelections = -1,
    highlightItems: string[] = []
) => {
    return nodes.map(item => {
        const value = item[valueField];
        let disabled = false;
        if (maxSelections !== -1 && selectedValues.length >= maxSelections) {
            if (!selectedValues.includes(value)) {
                disabled = true;
            }
        }
        const hasHighlight = highlightItems && highlightItems.includes(value);
        return (
            <Checkbox
                disabled={disabled}
                hasHighlight={hasHighlight}
                icon={item.icon}
                label={item[labelField]}
                key={item[valueField]}
                value={item[valueField]}
            />
        );
    });
};

class CheckboxFilterDropdownView extends React.Component<
    CheckboxFilterDropdownViewProps,
    CheckboxFilterDropdownViewState
> {
    static defaultProps = {
        buttonWidth: "130px",
        disabled: false,
        highlightItems: [],
        labelField: DEFAULT_LABEL_FIELD,
        maxSelections: -1,
        minSelections: 0,
        minWidth: "300px",
        nodes: [],
        renderNodes,
        selected: [],
        valueField: DEFAULT_VALUE_FIELD
    };

    constructor(props: CheckboxFilterDropdownViewProps) {
        super(props);

        this.state = {
            checked: props.selected,
            filteredNodes: props.nodes ? props.nodes : [],
            prevPropsSelected: props.selected
        };
    }

    static getDerivedStateFromProps: GetDerivedStateFromProps<
        CheckboxFilterDropdownViewProps,
        CheckboxFilterDropdownViewState
    > = (props, state) => {
        // Any time the selected prop changes reset any parts of state that is tied to that prop
        if (!isEqual(props.selected, state.prevPropsSelected)) {
            return {
                checked: props.selected,
                prevPropsSelected: props.selected
            };
        }
        return null;
    };

    onCheck = (checked: string[]) => {
        this.setState({ checked: checked });
    };

    onFilterClear = () => {
        this.setState({ checked: [] });
    };

    onFilterOpen = () => {
        this.setSelectedToProps();
    };

    onFilterSearch = (searchStr: string) => {
        const filteredNodes = this.filterNodes(this.props.nodes, searchStr);
        this.setState({
            filteredNodes
        });
    };

    filterNodes = (nodes: any[], searchText: string): any[] => {
        // doing default assignment in deconstruction because of typescript
        const { labelField = DEFAULT_LABEL_FIELD } = this.props;
        return JSON.parse(JSON.stringify(nodes)).filter(function iter(
            node: any
        ) {
            if (
                node &&
                node[labelField]
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
            ) {
                return true;
            }
            return false;
        });
    };

    onFilterClose = (applyFilter: boolean) => {
        const selectedDiff = xor(this.state.checked, this.props.selected);
        const isApplyDisabled = this.isApplyDisabled();
        if (applyFilter && selectedDiff.length > 0 && !isApplyDisabled) {
            this.props.onFilterChange(this.state.checked);
        } else {
            this.setSelectedToProps();
        }
        this.setState({
            // reset nodes to remove potential filterSearch
            filteredNodes: this.props.nodes
        });
    };

    setSelectedToProps = () => {
        this.setState({
            checked: this.props.selected
        });
    };

    getSelectedLabelText = (nodes: any[], checked: string[]): string[] => {
        // doing default assignment in deconstruction because of typescript
        const {
            labelField = DEFAULT_LABEL_FIELD,
            valueField = DEFAULT_VALUE_FIELD
        } = this.props;
        const selectedNodes = nodes.filter(item => {
            return checked.indexOf(item[valueField]) !== -1;
        });
        return selectedNodes.map(item => {
            return item[labelField];
        });
    };

    getSelectedText = (nodes: any[], checked: string[]): string[] => {
        const { t } = this.props;
        const selectedText = this.getSelectedLabelText(nodes, checked);
        return processSelectedItemsText(selectedText, t);
    };

    isApplyDisabled = (): boolean => {
        const { minSelections } = this.props;
        const { checked } = this.state;
        if (minSelections) {
            return checked.length < minSelections;
        } else {
            return false;
        }
    };

    render() {
        const {
            allLabelKey,
            buttonWidth,
            countLabelKey,
            disabled,
            filterHeaderTextKey,
            filterSubHeaderTextKey,
            filterTextListAll,
            highlightItems,
            highlightBackground,
            highlightSelected,
            horizontalPosition,
            icon,
            iconHeight,
            iconWidth,
            labelField,
            maxSelections,
            minWidth,
            name,
            nodes,
            renderNodes,
            selected,
            showSingleNode,
            t,
            textColor,
            trackingComponentLabel,
            valueField,
            verticalPosition
        } = this.props;
        const { checked, filteredNodes } = this.state;
        const filterText = filterTextListAll
            ? getFilterTextListAll(
                  this.getSelectedLabelText(nodes, checked),
                  allLabelKey,
                  t
              )
            : getFilterText(selected.length, allLabelKey, countLabelKey, t);
        const selectedText = this.getSelectedText(nodes, checked);
        const showSearch = nodes.length > 8;
        const subHeaderText =
            !isNil(filterSubHeaderTextKey) &&
            !isEmptyString(filterSubHeaderTextKey)
                ? t(filterSubHeaderTextKey as ParseKeys)
                : null;
        const isApplyDisabled = this.isApplyDisabled();
        if (nodes.length > 1 || (showSingleNode && nodes.length > 0)) {
            return (
                <FilterDropdownView
                    isApplyDisabled={isApplyDisabled}
                    buttonWidth={buttonWidth}
                    clearHandler={this.onFilterClear}
                    closeHandler={this.onFilterClose}
                    disabled={disabled}
                    filterHeaderText={t(filterHeaderTextKey as ParseKeys)}
                    filterSubHeaderText={subHeaderText}
                    highlightBackground={highlightBackground}
                    highlightSelected={highlightSelected}
                    horizontalPosition={horizontalPosition}
                    icon={icon}
                    iconHeight={iconHeight}
                    iconWidth={iconWidth}
                    maxHeight="280px"
                    minWidth={minWidth}
                    name={name}
                    searchHandler={this.onFilterSearch}
                    selected={checked}
                    selectedText={selectedText}
                    showSearch={showSearch}
                    text={filterText}
                    textColor={textColor}
                    trackingComponentLabel={trackingComponentLabel}
                    openHandler={this.onFilterOpen}
                    verticalPosition={verticalPosition}
                    {...reduceObject(this.props, ...marginPropTypes)}
                >
                    <CheckboxGroup
                        name={name}
                        selectedValues={checked}
                        changeHandler={this.onCheck}
                    >
                        {renderNodes &&
                            filteredNodes &&
                            labelField &&
                            valueField &&
                            renderNodes(
                                filteredNodes,
                                labelField,
                                valueField,
                                checked,
                                maxSelections,
                                highlightItems
                            )}
                    </CheckboxGroup>
                </FilterDropdownView>
            );
        } else {
            return null;
        }
    }
}

export default withTranslation()(CheckboxFilterDropdownView);
