import get from "lodash.get";
import type { AnyAction } from "redux";
import {
    makeRequestThunk,
    METHOD_GET,
    METHOD_POST
} from "common/shell/state/requestActions";
import { DEFAULT_PAGE_SIZE, sortCondition } from "common/util/query";
import type { RootState } from "store";

// action types
export const RECEIVE_T_CONFIGURATION = "RECEIVE_T_CONFIGURATION";
export const RECEIVE_TENANT_TEST_PLATFORMS = "RECEIVE_TENANT_TEST_PLATFORMS";
export const REQUEST_T_CONFIGURATION = "REQUEST_T_CONFIGURATION";
export const REQUEST_TENANT_TEST_PLATFORMS = "REQUEST_TENANT_TEST_PLATFORMS";

// actions creators

const requestTenantConfiguration = (): AnyAction => ({
    type: REQUEST_T_CONFIGURATION
});

const receiveTenantConfiguration = (json: Json): AnyAction => ({
    type: RECEIVE_T_CONFIGURATION,
    json: json
});

const requestTenantTestPlatforms = (): AnyAction => ({
    type: RECEIVE_TENANT_TEST_PLATFORMS
});

const receiveTenantTestPlatforms = (json: Json): AnyAction => ({
    type: RECEIVE_TENANT_TEST_PLATFORMS,
    json: json
});

const getTenantTestPlatformsRequest = (state: RootState): ListQueryRequest => {
    return {
        entity: "TenantTestPlatform",
        filterCondition: null,
        includeCrossTenantInstances: false,
        maxResults: get(
            state,
            "tenant.tenantTestPlatforms.maxResults",
            DEFAULT_PAGE_SIZE
        ),
        model: "TenantTestPlatformModel/readFields",
        objectType: "ListQuery",
        postSortCondition: null,
        sortConditions: [sortCondition("testPlatform.displayName", "ASC")],
        startIndex: get(state, "tenant.tenantTestPlatforms.startIndex", 0)
    };
};

// thunks

export function fetchTenantTestPlatforms() {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getTenantTestPlatformsRequest(state);
        },
        preRequestFunc: requestTenantTestPlatforms,
        okDispatchFunc: receiveTenantTestPlatforms,
        okResultFunc: (json: Json, state: RootState) => {
            return json;
        }
    });
}

export function fetchTenantConfiguration(tenantId: string) {
    return makeRequestThunk("api/entity/tenantconfiguration/" + tenantId, {
        method: METHOD_GET,
        queryParams: {
            modelAndFieldSet: "TenantConfigurationModel/retailerFields"
        },
        preRequestFunc: requestTenantConfiguration,
        okDispatchFunc: receiveTenantConfiguration
    });
}
