import type { TFunction } from "i18next";
import { DOWNLOADS_PAGE } from "common/administration/state/pageActions";
import { reduceDownload } from "common/administration/users/state/downloadsStateUtils";
import type { DownloadsQueryResult } from "common/administration/users/state/downloadsStateUtils";
import { actionTokenDownloadThunk } from "common/shell/state/fileActions";
import {
    BUTTON_CANCELYES,
    BUTTON_YES,
    ICON_QUESTION,
    openMessageBox
} from "common/shell/state/messageBoxActions";
import {
    formatSortOrderForQueryAPI,
    makeRequestThunk,
    METHOD_DELETE,
    METHOD_POST
} from "common/shell/state/requestActions";
import { success } from "common/shell/state/toastActions";
import {
    booleanFilterCondition,
    andFilterCondition,
    freeFormFilterCondition,
    stringFilterCondition
} from "common/util/filter";
import { sortCondition } from "common/util/query";
import type { ActionOrThunk, Actions, AppDispatch, RootState } from "store";

// action types
export const RECEIVE_DOWNLOADS = "RECEIVE_DOWNLOADS";
export const REQUEST_DOWNLOADS = "REQUEST_DOWNLOADS";
export const UPDATE_SORT_DOWNLOADS = "UPDATE_SORT_DOWNLOADS";
export const UPDATE_START_INDEX_DOWNLOADS = "UPDATE_START_INDEX_DOWNLOADS";
export const UPDATE_SEARCH_DOWNLOADS = "UPDATE_SEARCH_DOWNLOADS";

export type DownloadsActions =
    | SimpleAction<typeof DOWNLOADS_PAGE>
    | ReceiveItems<typeof RECEIVE_DOWNLOADS, DownloadsQueryResult>
    | RequestItems<typeof REQUEST_DOWNLOADS>
    | UpdateSearch<typeof UPDATE_SEARCH_DOWNLOADS>
    | UpdateSortBy<typeof UPDATE_SORT_DOWNLOADS>
    | UpdateStartIndex<typeof UPDATE_START_INDEX_DOWNLOADS>;

export const downloadsPage = (): SimpleAction<typeof DOWNLOADS_PAGE> => ({
    type: DOWNLOADS_PAGE
});

export const requestDownloads = (): RequestItems<typeof REQUEST_DOWNLOADS> => ({
    type: REQUEST_DOWNLOADS
});

export const receiveDownloads = (
    json: DownloadsQueryResult
): ReceiveItems<typeof RECEIVE_DOWNLOADS, DownloadsQueryResult> => ({
    type: RECEIVE_DOWNLOADS,
    items: json
});

export function downloadFile(entityId: string) {
    return actionTokenDownloadThunk(
        "api/auth/token/init",
        entityId,
        "api/download/file/",
        "plain"
    );
}

export const updateStartIndex = (
    startIndex: number
): UpdateStartIndex<typeof UPDATE_START_INDEX_DOWNLOADS> => ({
    type: UPDATE_START_INDEX_DOWNLOADS,
    startIndex: startIndex
});

export const updateSortBy = (
    sortBy: string,
    sortOrder: SortOrder
): UpdateSortBy<typeof UPDATE_SORT_DOWNLOADS> => ({
    type: UPDATE_SORT_DOWNLOADS,
    sortBy: sortBy,
    sortOrder: sortOrder
});

export const updateSearch = (
    search: string
): UpdateSearch<typeof UPDATE_SEARCH_DOWNLOADS> => ({
    type: UPDATE_SEARCH_DOWNLOADS,
    search: search
});

export const getFilterCondition = (state: RootState): FilterCondition => {
    const isNotTempFilterCondition = booleanFilterCondition("temp", false);
    const isUserFilterCondition = stringFilterCondition(
        "creator.entityId",
        state.init.user.entityId,
        "EQUALS"
    );
    if (state.downloads.search) {
        return andFilterCondition([
            isNotTempFilterCondition,
            isUserFilterCondition,
            freeFormFilterCondition(state.downloads.search, ["fileName"])
        ]);
    } else {
        return andFilterCondition([
            isNotTempFilterCondition,
            isUserFilterCondition
        ]);
    }
};

const getDownloadsRequest = (state: RootState): ListQueryRequest => {
    const sortOrder = formatSortOrderForQueryAPI(state.downloads.sortOrder);
    return {
        entity: "Download",
        filterCondition: getFilterCondition(state),
        maxResults: state.downloads.maxResults,
        model: "DownloadModel/downloadList",
        objectType: "ListQuery",
        postSortCondition: null,
        sortConditions: [sortCondition(state.downloads.sortBy, sortOrder)],
        startIndex: state.downloads.startIndex
    };
};

export const fetchDownloads = () => {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getDownloadsRequest(state);
        },
        preRequestFunc: requestDownloads,
        okDispatchFunc: receiveDownloads,
        okResultFunc: (json: DownloadsQueryResult, state: RootState) => {
            return {
                items: json.result.map(reduceDownload),
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
};

export const deleteDownloadConfirmation = (
    download: any,
    t: TFunction,
    dispatch: AppDispatch,
    refreshFunction?: NoArgsHandler
) => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.yes")
            },
            callbackData: { ...download, refreshFunction: refreshFunction },
            callbackFunction: handleDeleteDownloadConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey:
                    "common:administration.delete_download_confirmation",
                values: {
                    download: download.fileName
                }
            },
            showCloseIcon: false,
            title: t("common:administration.delete_download")
        })
    );
};

export const handleDeleteDownloadConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
) => {
    if (button === BUTTON_YES) {
        dispatch(
            deleteDownload(
                callbackData.entityId,
                callbackData.fileName,
                callbackData.refreshFunction
            )
        );
    }
};

export const deleteDownload = (
    entityId: string,
    name: string,
    refreshFunction: NoArgsHandler
) => {
    const url = "api/entity/download/";
    return makeRequestThunk(url + entityId, {
        method: METHOD_DELETE,
        okDispatchFunc: (json: EmptyObject, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const successMessage = {
                resourceKey: "common:administration.delete_download_success",
                values: {
                    download: name
                }
            };
            if (refreshFunction) {
                refreshFunction();
            }
            actions.push(success(successMessage));
            return actions;
        }
    });
};
