import { reportError as ravenReportError } from "common/shell/util/ravenUtils";
import i18n from "common/i18n";

export const getDefaultErrorMessage = (): string => {
    return i18n.t("common:general.generic_error");
};

export const reportError = (error: Error, info?: any) => {
    ravenReportError(error, info);
};
