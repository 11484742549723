import type { TFunction } from "i18next";
import {
    TEAM_PAGE,
    TEAMS_PAGE,
    TEAM_CREATE_PAGE,
    TEAM_EDIT_PAGE
} from "common/administration/state/pageActions";
import type { UsersQueryResult } from "common/administration/users/state/userStateUtils";
import { teamsPage } from "common/administration/teams/state/teamsActions";
import {
    getTeamRetailersRequest,
    getTeamDrawerRetailersRequest,
    getTeamDrawerUsersRequest,
    getTeamUsersRequest
} from "common/administration/teams/state/teamStateUtils";
import type {
    GridTeam,
    TeamState
} from "common/administration/teams/state/teamStateUtils";
import { writeEntity } from "common/shell/state/entityActions";
import { fetchUsersReinit } from "common/shell/state/initActions";
import {
    openMessageBox,
    BUTTON_CANCELYES,
    BUTTON_YES,
    ICON_QUESTION
} from "common/shell/state/messageBoxActions";
import {
    resetCanNotLeave,
    setCanNotLeave
} from "common/shell/state/navigationActions";
import {
    makeRequestThunk,
    METHOD_DELETE,
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT
} from "common/shell/state/requestActions";
import { success } from "common/shell/state/toastActions";
import type { ActionOrThunk, Actions, AppDispatch, RootState } from "store";

// action types
export const RECEIVE_TEAM = "RECEIVE_TEAM";
export const RECEIVE_TEAM_RETAILERS_DRAWER = "RECEIVE_TEAM_RETAILERS_DRAWER";
export const RECEIVE_TEAM_USERS_DRAWER = "RECEIVE_TEAM_USERS_DRAWER";
export const REQUEST_TEAM = "REQUEST_TEAM";
export const REQUEST_TEAM_RETAILERS_DRAWER = "REQUEST_TEAM_RETAILERS_DRAWER";
export const REQUEST_TEAM_USERS_DRAWER = "REQUEST_TEAM_USERS_DRAWER";
export const UPDATE_TEAM_RETAILERS_DRAWER_SORT =
    "UPDATE_TEAM_RETAILERS_DRAWER_SORT";
export const UPDATE_TEAM_USERS_DRAWER_SORT = "UPDATE_TEAM_USERS_DRAWER_SORT";

type RetailerQueryResult = {
    result: Retailer[];
} & ListQueryResult;

export type TeamActions =
    | EntityIdPayloadPageAction<typeof TEAM_EDIT_PAGE>
    | EntityIdPayloadPageAction<typeof TEAM_PAGE>
    | SimpleAction<typeof TEAM_CREATE_PAGE>
    | ReceiveItem<typeof RECEIVE_TEAM, GridTeam>
    | ReceiveItems<typeof RECEIVE_TEAM_RETAILERS_DRAWER, RetailersQueryResult>
    | ReceiveItems<typeof RECEIVE_TEAM_USERS_DRAWER, UsersQueryResult>
    | RequestItem<typeof REQUEST_TEAM>
    | RequestItems<typeof REQUEST_TEAM_RETAILERS_DRAWER>
    | RequestItems<typeof REQUEST_TEAM_USERS_DRAWER>
    | UpdateSortBy<typeof UPDATE_TEAM_RETAILERS_DRAWER_SORT>
    | UpdateSortBy<typeof UPDATE_TEAM_USERS_DRAWER_SORT>;

export type PersistTeam = {
    entityId?: string;
    name: string;
    objectType: string;
    teamType: {
        entityId: string;
        objectType: string;
    };
};

export type PersistTeamUser = {
    entityId: string;
    objectType: string;
    team: {
        entityId: string;
        objectType: string;
    };
};

type RetailersQueryResult = {
    result: Retailer[];
} & ListQueryResult;

const requestTeam = (): RequestItem<typeof REQUEST_TEAM> => ({
    type: REQUEST_TEAM
});

const receiveTeam = (
    json: GridTeam
): ReceiveItem<typeof RECEIVE_TEAM, GridTeam> => ({
    type: RECEIVE_TEAM,
    item: json
});

export const teamPage = (
    entityId: string
): EntityIdPayloadPageAction<typeof TEAM_PAGE> => ({
    type: TEAM_PAGE,

    payload: {
        // matches what is in routes.js
        entityId: entityId
    }
});

export const teamCreatePage = (): SimpleAction<typeof TEAM_CREATE_PAGE> => ({
    type: TEAM_CREATE_PAGE
});

export const teamEditPage = (
    entityId: string
): EntityIdPayloadPageAction<typeof TEAM_EDIT_PAGE> => ({
    type: TEAM_EDIT_PAGE,

    payload: {
        // matches what is in routes.js
        entityId: entityId
    }
});

const receiveTeamRetailersDrawer = (
    json: RetailersQueryResult
): ReceiveItems<
    typeof RECEIVE_TEAM_RETAILERS_DRAWER,
    RetailersQueryResult
> => ({
    type: RECEIVE_TEAM_RETAILERS_DRAWER,
    items: json
});

const receiveTeamUsersDrawer = (
    json: UsersQueryResult
): ReceiveItems<typeof RECEIVE_TEAM_USERS_DRAWER, UsersQueryResult> => ({
    type: RECEIVE_TEAM_USERS_DRAWER,
    items: json
});

const requestTeamRetailersDrawer = (): RequestItems<
    typeof REQUEST_TEAM_RETAILERS_DRAWER
> => ({
    type: REQUEST_TEAM_RETAILERS_DRAWER
});

const requestTeamUsersDrawer = (): RequestItems<
    typeof REQUEST_TEAM_USERS_DRAWER
> => ({
    type: REQUEST_TEAM_USERS_DRAWER
});

export const updateTeamRetailersDrawerSort = (
    sortBy: string,
    sortOrder: SortOrder
): UpdateSortBy<typeof UPDATE_TEAM_RETAILERS_DRAWER_SORT> => ({
    type: UPDATE_TEAM_RETAILERS_DRAWER_SORT,
    sortBy,
    sortOrder
});

export const updateTeamUsersDrawerSort = (
    sortBy: string,
    sortOrder: SortOrder
): UpdateSortBy<typeof UPDATE_TEAM_USERS_DRAWER_SORT> => ({
    type: UPDATE_TEAM_USERS_DRAWER_SORT,
    sortBy,
    sortOrder
});

export function fetchTeam() {
    return makeRequestThunk("api/entity/team", {
        method: METHOD_GET,
        urlPartFunc: function (state: RootState): string {
            return "/" + state.team.entityId;
        },
        queryParamsFunc: function (state: RootState) {
            return {
                modelAndFieldSet: "TeamModel/readFields"
            };
        },
        preRequestFunc: requestTeam,
        okDispatchFunc: receiveTeam
    });
}

export const fetchTeamDrawerRetailers = (
    search: string,
    offset: number,
    selected: string[],
    team: TeamState
) => {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getTeamDrawerRetailersRequest(
                search,
                state.team.retailersDrawer.maxResults,
                offset,
                state.team.retailersDrawer.sortBy,
                state.team.retailersDrawer.sortOrder,
                selected,
                team
            );
        },
        preRequestFunc: requestTeamRetailersDrawer,
        okDispatchFunc: receiveTeamRetailersDrawer,
        okResultFunc: (json: RetailerQueryResult, state: RootState) => {
            const items = json.result ?? [];
            return {
                items: items,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
};

const getRetailersTeamRequest = (retailers: Retailer[]) => {
    const retailerEntityIds: string[] = [];
    retailers.forEach(retailer => {
        retailerEntityIds.push(retailer.entityId);
    });
    return {
        entityIds: retailerEntityIds,
        objectType: "EntityIdsDto"
    };
};

export const addRetailersToTeam = (
    retailers: Retailer[],
    team: TeamState,
    callbackFunction?: NoArgsHandler
) => {
    return makeRequestThunk("api/team/addObjects/" + team.entityId, {
        method: METHOD_PUT,
        bodyFunc: function (state: RootState) {
            return getRetailersTeamRequest(retailers);
        },
        okDispatchFunc: (json: WriteEntityIdsResult, state: RootState) => {
            if (callbackFunction) {
                callbackFunction();
            }
            const values = {
                team: team.name
            };
            const successMessage = {
                resourceKey:
                    "common:administration.add_retailers_to_team_success",
                values: values
            };
            return success(successMessage);
        }
    });
};

export function writeTeamUser(entity: PersistTeamUser, team: TeamState) {
    const okDispatchFunc = (json: WriteEntityResult, state: RootState) => {
        const actions: ActionOrThunk[] = [];
        const values = {
            team: team.name
        };
        const successMessage = {
            resourceKey: "common:administration.remove_user_from_team_success",
            values: values
        };
        actions.push(fetchUsersReinit());
        actions.push(success(successMessage));
        return actions;
    };
    return writeEntity(entity, METHOD_PUT, { okDispatchFunc });
}

const removeRetailersFromTeam = (
    retailers: Retailer[],
    team: TeamState,
    callbackFunction: NoArgsHandler
) => {
    return makeRequestThunk("api/team/removeObjects/" + team.entityId, {
        method: METHOD_PUT,
        bodyFunc: function (state: RootState) {
            return getRetailersTeamRequest(retailers);
        },
        okDispatchFunc: (json: WriteEntityIdsResult, state: RootState) => {
            if (callbackFunction) {
                callbackFunction();
            }
            const values = {
                team: team.name
            };
            const successMessage = {
                resourceKey:
                    "common:administration.remove_retailer_from_team_success",
                values: values
            };
            return success(successMessage);
        }
    });
};

type RemoveRetailerCallbackData = {
    retailer: Retailer;
    team: TeamState;
    refreshFunction: NoArgsHandler;
};

export const removeRetailerConfirmation = (
    team: TeamState,
    retailer: Retailer,
    t: TFunction,
    dispatch: AppDispatch,
    refreshFunction?: NoArgsHandler
) => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.yes")
            },
            callbackData: { team, retailer, refreshFunction: refreshFunction },
            callbackFunction: handleRemoveRetailerConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey:
                    "common:administration.remove_retailer_confirmation",
                values: {
                    retailer: retailer.displayName.value
                }
            },
            showCloseIcon: false,
            title: t("common:administration.remove_retailer")
        })
    );
};

const handleRemoveRetailerConfirmation = (
    button: string,
    callbackData: RemoveRetailerCallbackData,
    dispatch: AppDispatch
) => {
    if (button === BUTTON_YES) {
        dispatch(
            removeRetailersFromTeam(
                [callbackData.retailer],
                callbackData.team,
                callbackData.refreshFunction
            )
        );
    }
};

export const fetchTeamDrawerUsers = (
    search: string,
    offset: number,
    selected: string[],
    notSelected: string[]
) => {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getTeamDrawerUsersRequest(
                search,
                state.team.usersDrawer.maxResults,
                offset,
                state.team.usersDrawer.sortBy,
                state.team.usersDrawer.sortOrder,
                selected,
                notSelected
            );
        },
        preRequestFunc: requestTeamUsersDrawer,
        okDispatchFunc: receiveTeamUsersDrawer,
        okResultFunc: (json: UsersQueryResult, state: RootState) => {
            return {
                items: json.result,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
};

export const fetchTeamUsers = () => {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getTeamUsersRequest(state.team.entityId);
        },
        okResultFunc: (json: UsersQueryResult, state: RootState) => {
            return {
                items: json.result,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
};

export const fetchTeamRetailers = () => {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getTeamRetailersRequest(state.team.entityId);
        },
        okResultFunc: (json: RetailerQueryResult, state: RootState) => {
            return {
                items: json.result,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
};

type AddUsersToTeam = {
    entityId: string;
    fieldSet: string;
    objectType: string;
    team: {
        entityId: string;
        objectType: string;
    };
};

const getAddUsersToTeamRequest = (users: User[], team: TeamState) => {
    const teamEntityId = team.entityId;
    const usersToAdd: AddUsersToTeam[] = [];
    users.forEach(user => {
        usersToAdd.push({
            entityId: user.entityId,
            fieldSet: "teamOnly",
            objectType: "model/UserModel",
            team: {
                entityId: teamEntityId,
                objectType: "model/TeamModel"
            }
        });
    });
    return usersToAdd;
};

const addUsersToTeam = (
    users: User[],
    team: TeamState,
    callbackFunction: NoArgsHandler
) => {
    return makeRequestThunk("api/entity/user/batch", {
        method: METHOD_PUT,
        bodyFunc: function (state: RootState) {
            return getAddUsersToTeamRequest(users, team);
        },
        okDispatchFunc: (json: WriteEntityIdsResult, state: RootState) => {
            if (callbackFunction) {
                callbackFunction();
            }
            const values = {
                team: team.name
            };
            const successMessage = {
                resourceKey: "common:administration.add_users_to_team_success",
                values: values
            };
            return success(successMessage);
        }
    });
};

export function writeTeam(entity: PersistTeam, createAnother: boolean) {
    const method = entity.entityId ? METHOD_PUT : METHOD_POST;
    const okDispatchFunc = (
        json: WriteEntityResult,
        state: RootState
    ): Actions => {
        const actions: ActionOrThunk[] = [];
        const values = {
            name: entity.name
        };
        let successMessage = {
            resourceKey: "common:administration.create_team_success",
            values: values
        };
        if (method === METHOD_PUT) {
            successMessage = {
                resourceKey: "common:administration.edit_team_success",
                values: values
            };
        }
        if (!createAnother) {
            actions.push(resetCanNotLeave());
            actions.push(teamPage(json.entityId));
        } else {
            actions.push(setCanNotLeave());
        }
        actions.push(success(successMessage));
        return actions;
    };
    return writeEntity(entity, method, { okDispatchFunc });
}

export const deleteTeamConfirmation = (
    team: GridTeam | TeamState,
    t: TFunction,
    dispatch: AppDispatch,
    refreshFunction?: NoArgsHandler
) => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.yes")
            },
            callbackData: { ...team, refreshFunction: refreshFunction },
            callbackFunction: handleDeleteTeamConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey: "common:administration.delete_team_confirmation",
                values: {
                    team: team.name
                }
            },
            showCloseIcon: false,
            title: t("common:administration.delete_team")
        })
    );
};

const handleDeleteTeamConfirmation = (
    button: string,
    callbackData: DeleteCallbackData,
    dispatch: AppDispatch
) => {
    if (button === BUTTON_YES) {
        dispatch(
            deleteTeam(
                callbackData.entityId,
                callbackData.name,
                callbackData.refreshFunction
            )
        );
    }
};

const deleteTeam = (
    entityId: string,
    name: string,
    refreshFunction: NoArgsHandler
) => {
    const url = "api/entity/team/";
    return makeRequestThunk(url + entityId, {
        isCancellable: false,
        method: METHOD_DELETE,
        okDispatchFunc: (json: EmptyObject, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const successMessage = {
                resourceKey: "common:administration.delete_team_success",
                values: {
                    team: name
                }
            };
            switch (state.page) {
                case TEAMS_PAGE:
                    if (refreshFunction) {
                        refreshFunction();
                    }
                    break;
                case TEAM_PAGE:
                    actions.push(teamsPage());
                    break;
                default:
                    break;
            }
            //            actions.push(fetchTeamsReinit());
            actions.push(success(successMessage));
            return actions;
        }
    });
};

type AddUsersCallbackData = {
    users: User[];
    team: TeamState;
    callbackFunction: NoArgsHandler;
};

const handleAddUsersConfirmation = (
    button: string,
    callbackData: AddUsersCallbackData,
    dispatch: AppDispatch
) => {
    if (button === BUTTON_YES) {
        dispatch(
            addUsersToTeam(
                callbackData.users,
                callbackData.team,
                callbackData.callbackFunction
            )
        );
    }
};

export const addUsersConfirmation = (
    users: User[],
    team: TeamState,
    t: TFunction,
    dispatch: AppDispatch,
    callbackFunction?: NoArgsHandler
) => {
    dispatch(
        openMessageBox({
            title: t("common:administration.add_users"),
            callbackFunction: handleAddUsersConfirmation,
            callbackData: { users, team, callbackFunction },
            dispatch: dispatch,
            message: {
                resourceKey: "common:administration.add_user_confirmation",
                values: {
                    count: users.length,
                    team: team.name
                }
            },
            icon: ICON_QUESTION,
            showCloseIcon: false,
            buttons: BUTTON_CANCELYES
        })
    );
};
