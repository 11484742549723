import update from "immutability-helper";
import get from "lodash.get";
import { getDateFilter } from "common/reports/state/reportsStateUtils";
import {
    COMPARISON_SAME_PERIOD_PRIOR_YEAR,
    LAST_MONTH
} from "common/util/date";
import type { DateFilter } from "common/util/date";
import { ASCENDING } from "common/util/query";
import type { Metric } from "reports/state/metrics";
import {
    METRIC_TYPE_INTEGER,
    METRIC_TYPE_MONEY,
    METRIC_TYPE_NUMBER,
    METRIC_TYPE_PERCENT,
    METRIC_TYPE_PRICE
} from "reports/state/metrics";
import type { ReportsState } from "reports/state/reportsStateUtils";
import { CATEGORY_SUBCATEGORY_SEPARATOR } from "shell/state/initReducers";

export const CONSUMER = "consumer";

// Report view types
// Consumer
export const CONSUMER_CUSTOM = "CONSUMER_CUSTOM";
export const CONSUMER_PRODUCT_TRIAL = "CONSUMER_PRODUCT_TRIAL";
export const CONSUMER_PURCHASE_QUANTITIES = "CONSUMER_PURCHASE_QUANTITIES";
export const BASKETS = "baskets";
export const BASKETS_COMPARISON = "basketsComparison";
export const BASKETS_PER_BUYER = "basketsPerBuyer";
export const BASKETS_PER_BUYER_COMPARISON = "basketsPerBuyerComparison";
export const BRAND = "brand";
export const BUYERS = "buyers";
export const BUYERS_COMPARISON = "buyersComparison";
export const CATEGORY = "category";
export const CATEGORY_HH_PENETRATION_PCT = "categoryHhPenetrationPct";
export const CATEGORY_HH_PENETRATION_PCT_COMPARISON =
    "categoryHhPenetrationPctComparison";
export const CHANNEL = "channel";
export const GROUP_IDENTIFIER = "groupIdentifier";
export const MAX_UNITS_PER_BASKET = "maxUnitsPerBasket";
export const MAX_UNITS_PER_BASKET_COMPARISON = "maxUnitsPerBasketComparison";
export const MAX_UNITS_PER_BASKET_WEEK = "maxUnitsPerBasketWeek";
export const MIN_UNITS_PER_BASKET = "minUnitsPerBasket";
export const MIN_UNITS_PER_BASKET_COMPARISON = "minUnitsPerBasketComparison";
export const MIN_UNITS_PER_BASKET_WEEK = "minUnitsPerBasketWeek";
export const NEW_TO_BRAND_BUYERS_ALL = "newToBrandBuyersAll";
export const NEW_TO_BRAND_BUYERS_ALL_COMPARISON =
    "newToBrandBuyersAllComparison";
export const NEW_TO_BRAND_BUYERS_PCT_ALL = "newToBrandBuyersPctAll";
export const NEW_TO_BRAND_BUYERS_PCT_ALL_COMPARISON =
    "newToBrandBuyersPctAllComparison";
export const NEW_TO_BRAND_BUYERS_PCT_TENURED = "newToBrandBuyersPctTenured";
export const NEW_TO_BRAND_BUYERS_PCT_TENURED_COMPARISON =
    "newToBrandBuyersPctTenuredComparison";
export const NEW_TO_BRAND_BUYERS_SALES_ALL = "newToBrandBuyersSalesAll";
export const NEW_TO_BRAND_BUYERS_SALES_ALL_COMPARISON =
    "newToBrandBuyersSalesAllComparison";
export const NEW_TO_BRAND_BUYERS_SALES_TENURED = "newToBrandBuyersSalesTenured";
export const NEW_TO_BRAND_BUYERS_SALES_TENURED_COMPARISON =
    "newToBrandBuyersSalesTenuredComparison";
export const NEW_TO_BRAND_BUYERS_TENURED = "newToBrandBuyersTenured";
export const NEW_TO_BRAND_BUYERS_TENURED_COMPARISON =
    "newToBrandBuyersTenuredComparison";
export const NEW_TO_BRAND_CATEGORY_BUYERS_ALL = "newToBrandCategoryBuyersAll";
export const NEW_TO_BRAND_CATEGORY_BUYERS_ALL_COMPARISON =
    "newToBrandCategoryBuyersAllComparison";
export const NEW_TO_BRAND_CATEGORY_BUYERS_PCT_ALL =
    "newToBrandCategoryBuyersPctAll";
export const NEW_TO_BRAND_CATEGORY_BUYERS_PCT_ALL_COMPARISON =
    "newToBrandCategoryBuyersPctAllComparison";
export const NEW_TO_BRAND_CATEGORY_BUYERS_PCT_TENURED =
    "newToBrandCategoryBuyersPctTenured";
export const NEW_TO_BRAND_CATEGORY_BUYERS_PCT_TENURED_COMPARISON =
    "newToBrandCategoryBuyersPctTenuredComparison";
export const NEW_TO_BRAND_CATEGORY_BUYERS_SALES_ALL =
    "newToBrandCategoryBuyersSalesAll";
export const NEW_TO_BRAND_CATEGORY_BUYERS_SALES_ALL_COMPARISON =
    "newToBrandCategoryBuyersSalesAllComparison";
export const NEW_TO_BRAND_CATEGORY_BUYERS_SALES_TENURED =
    "newToBrandCategoryBuyersSalesTenured";
export const NEW_TO_BRAND_CATEGORY_BUYERS_SALES_TENURED_COMPARISON =
    "newToBrandCategoryBuyersSalesTenuredComparison";
export const NEW_TO_BRAND_CATEGORY_BUYERS_TENURED =
    "newToBrandCategoryBuyersTenured";
export const NEW_TO_BRAND_CATEGORY_BUYERS_TENURED_COMPARISON =
    "newToBrandCategoryBuyersTenuredComparison";
export const NEW_TO_CATEGORY_BUYERS_ALL = "newToCategoryBuyersAll";
export const NEW_TO_CATEGORY_BUYERS_ALL_COMPARISON =
    "newToCategoryBuyersAllComparison";
export const NEW_TO_CATEGORY_BUYERS_PCT_ALL = "newToCategoryBuyersPctAll";
export const NEW_TO_CATEGORY_BUYERS_PCT_ALL_COMPARISON =
    "newToCategoryBuyersPctAllComparison";
export const NEW_TO_CATEGORY_BUYERS_PCT_TENURED =
    "newToCategoryBuyersPctTenured";
export const NEW_TO_CATEGORY_BUYERS_PCT_TENURED_COMPARISON =
    "newToCategoryBuyersPctTenuredComparison";
export const NEW_TO_CATEGORY_BUYERS_SALES_ALL = "newToCategoryBuyersSalesAll";
export const NEW_TO_CATEGORY_BUYERS_SALES_ALL_COMPARISON =
    "newToCategoryBuyersSalesAllComparison";
export const NEW_TO_CATEGORY_BUYERS_SALES_TENURED =
    "newToCategoryBuyersSalesTenured";
export const NEW_TO_CATEGORY_BUYERS_SALES_TENURED_COMPARISON =
    "newToCategoryBuyersSalesTenuredComparison";
export const NEW_TO_CATEGORY_BUYERS_TENURED = "newToCategoryBuyersTenured";
export const NEW_TO_CATEGORY_BUYERS_TENURED_COMPARISON =
    "newToCategoryBuyersTenuredComparison";
export const NEW_TO_PRODUCT_BUYERS_ALL = "newToProductBuyersAll";
export const NEW_TO_PRODUCT_BUYERS_ALL_COMPARISON =
    "newToProductBuyersAllComparison";
export const NEW_TO_PRODUCT_BUYERS_PCT_ALL = "newToProductBuyersPctAll";
export const NEW_TO_PRODUCT_BUYERS_PCT_ALL_COMPARISON =
    "newToProductBuyersPctAllComparison";
export const NEW_TO_PRODUCT_BUYERS_PCT_TENURED = "newToProductBuyersPctTenured";
export const NEW_TO_PRODUCT_BUYERS_PCT_TENURED_COMPARISON =
    "newToProductBuyersPctTenuredComparison";
export const NEW_TO_PRODUCT_BUYERS_SALES_ALL = "newToProductBuyersSalesAll";
export const NEW_TO_PRODUCT_BUYERS_SALES_ALL_COMPARISON =
    "newToProductBuyersSalesAllComparison";
export const NEW_TO_PRODUCT_BUYERS_SALES_TENURED =
    "newToProductBuyersSalesTenured";
export const NEW_TO_PRODUCT_BUYERS_SALES_TENURED_COMPARISON =
    "newToProductBuyersSalesTenuredComparison";
export const NEW_TO_PRODUCT_BUYERS_TENURED = "newToProductBuyersTenured";
export const NEW_TO_PRODUCT_BUYERS_TENURED_COMPARISON =
    "newToProductBuyersTenuredComparison";
export const PRODUCT_GROUPS = "productGroups";
const PROMOTION_FILTER = "promotionFilter";
export const SALES = "sales";
export const SALES_COMPARISON = "salesComparison";
export const SALES_PER_BUYER = "salesPerBuyer";
export const SALES_PER_BUYER_COMPARISON = "salesPerBuyerComparison";
export const SALES_PER_BASKET = "salesPerBasket";
export const SALES_PER_BASKET_COMPARISON = "salesPerBasketComparison";
export const SALES_PER_UNIT = "salesPerUnit";
export const SALES_PER_UNIT_COMPARISON = "salesPerUnitComparison";
export const TOTAL_BASKET_SIZE = "totalBasketSize";
export const TOTAL_BASKET_SIZE_COMPARISON = "totalBasketSizeComparison";
export const UNITS = "units";
export const UNITS_COMPARISON = "unitsComparison";
export const UNITS_PER_BUYER = "unitsPerBuyer";
export const UNITS_PER_BUYER_COMPARISON = "unitsPerBuyerComparison";
export const UNITS_PER_BASKET = "unitsPerBasket";
export const UNITS_PER_BASKET_COMPARISON = "unitsPerBasketComparison";
export const UPC = "upc";
export const UPC_DESCRIPTION = "upcDescription";
export const UPC_PRODUCT_GROUPS = "upcProductGroups";

// Make sure these consts match ConsumerTrendsReportColumn enum values from backend
export const METRIC_BASKETS: Metric = {
    labelKey: "reports.baskets",
    name: "BASKETS",
    type: METRIC_TYPE_INTEGER
};

export const METRIC_BUYERS: Metric = {
    labelKey: "reports.buyers",
    name: "BUYERS",
    type: METRIC_TYPE_INTEGER
};

export const METRIC_CATEGORY_HH_PENETRATION_PCT: Metric = {
    labelKey: "reports.category_hh_penetration_pct",
    name: "CATEGORY_HH_PENETRATION_PCT",
    type: METRIC_TYPE_PERCENT
};

export const METRIC_NEW_TO_BRAND_BUYERS_ALL: Metric = {
    labelKey: "reports.new_to_brand_buyers_all",
    name: "NEW_TO_BRAND_BUYERS_ALL",
    type: METRIC_TYPE_INTEGER
};

export const METRIC_NEW_TO_BRAND_BUYERS_PCT_ALL: Metric = {
    labelKey: "reports.new_to_brand_buyers_pct_all",
    name: "NEW_TO_BRAND_BUYERS_PCT_ALL",
    type: METRIC_TYPE_PERCENT
};

export const METRIC_NEW_TO_BRAND_BUYERS_SALES_ALL: Metric = {
    labelKey: "reports.new_to_brand_buyers_sales_all",
    name: "NEW_TO_BRAND_BUYERS_SALES_ALL",
    type: METRIC_TYPE_MONEY
};

export const METRIC_NEW_TO_BRAND_BUYERS_PCT_TENURED: Metric = {
    labelKey: "reports.new_to_brand_buyers_pct_tenured",
    name: "NEW_TO_BRAND_BUYERS_PCT_TENURED",
    type: METRIC_TYPE_PERCENT
};

export const METRIC_NEW_TO_BRAND_BUYERS_SALES_TENURED: Metric = {
    labelKey: "reports.new_to_brand_buyers_sales_tenured",
    name: "NEW_TO_BRAND_BUYERS_SALES_TENURED",
    type: METRIC_TYPE_MONEY
};

export const METRIC_NEW_TO_BRAND_BUYERS_TENURED: Metric = {
    labelKey: "reports.new_to_brand_buyers_tenured",
    name: "NEW_TO_BRAND_BUYERS_TENURED",
    type: METRIC_TYPE_INTEGER
};

export const METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_ALL: Metric = {
    labelKey: "reports.new_to_brand_category_buyers_all",
    name: "NEW_TO_BRAND_CATEGORY_BUYERS_ALL",
    type: METRIC_TYPE_INTEGER
};

export const METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_PCT_ALL: Metric = {
    labelKey: "reports.new_to_brand_category_buyers_pct_all",
    name: "NEW_TO_BRAND_CATEGORY_BUYERS_PCT_ALL",
    type: METRIC_TYPE_PERCENT
};

export const METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_SALES_ALL: Metric = {
    labelKey: "reports.new_to_brand_category_buyers_sales_all",
    name: "NEW_TO_BRAND_CATEGORY_BUYERS_SALES_ALL",
    type: METRIC_TYPE_MONEY
};

export const METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_TENURED: Metric = {
    labelKey: "reports.new_to_brand_category_buyers_tenured",
    name: "NEW_TO_BRAND_CATEGORY_BUYERS_TENURED",
    type: METRIC_TYPE_INTEGER
};

export const METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_PCT_TENURED: Metric = {
    labelKey: "reports.new_to_brand_category_buyers_pct_tenured",
    name: "NEW_TO_BRAND_CATEGORY_BUYERS_PCT_TENURED",
    type: METRIC_TYPE_PERCENT
};

export const METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_SALES_TENURED: Metric = {
    labelKey: "reports.new_to_brand_category_buyers_sales_tenured",
    name: "NEW_TO_BRAND_CATEGORY_BUYERS_SALES_TENURED",
    type: METRIC_TYPE_MONEY
};

export const METRIC_NEW_TO_CATEGORY_BUYERS_ALL: Metric = {
    labelKey: "reports.new_to_category_buyers_all",
    name: "NEW_TO_CATEGORY_BUYERS_ALL",
    type: METRIC_TYPE_INTEGER
};

export const METRIC_NEW_TO_CATEGORY_BUYERS_PCT_ALL: Metric = {
    labelKey: "reports.new_to_category_buyers_pct_all",
    name: "NEW_TO_CATEGORY_BUYERS_PCT_ALL",
    type: METRIC_TYPE_PERCENT
};

export const METRIC_NEW_TO_CATEGORY_BUYERS_SALES_ALL: Metric = {
    labelKey: "reports.new_to_category_buyers_sales_all",
    name: "NEW_TO_CATEGORY_BUYERS_SALES_ALL",
    type: METRIC_TYPE_MONEY
};

export const METRIC_NEW_TO_CATEGORY_BUYERS_TENURED: Metric = {
    labelKey: "reports.new_to_category_buyers_tenured",
    name: "NEW_TO_CATEGORY_BUYERS_TENURED",
    type: METRIC_TYPE_INTEGER
};

export const METRIC_NEW_TO_CATEGORY_BUYERS_PCT_TENURED: Metric = {
    labelKey: "reports.new_to_category_buyers_pct_tenured",
    name: "NEW_TO_CATEGORY_BUYERS_PCT_TENURED",
    type: METRIC_TYPE_PERCENT
};

export const METRIC_NEW_TO_CATEGORY_BUYERS_SALES_TENURED: Metric = {
    labelKey: "reports.new_to_category_buyers_sales_tenured",
    name: "NEW_TO_CATEGORY_BUYERS_SALES_TENURED",
    type: METRIC_TYPE_MONEY
};

export const METRIC_NEW_TO_PRODUCT_BUYERS_ALL: Metric = {
    labelKey: "reports.new_to_ppg_buyers_all",
    name: "NEW_TO_PRODUCT_BUYERS_ALL",
    type: METRIC_TYPE_INTEGER
};

export const METRIC_NEW_TO_PRODUCT_BUYERS_PCT_ALL: Metric = {
    labelKey: "reports.new_to_ppg_buyers_pct_all",
    name: "NEW_TO_PRODUCT_BUYERS_PCT_ALL",
    type: METRIC_TYPE_PERCENT
};

export const METRIC_NEW_TO_PRODUCT_BUYERS_SALES_ALL: Metric = {
    labelKey: "reports.new_to_ppg_buyers_sales_all",
    name: "NEW_TO_PRODUCT_BUYERS_SALES_ALL",
    type: METRIC_TYPE_MONEY
};

export const METRIC_NEW_TO_PRODUCT_BUYERS_TENURED: Metric = {
    labelKey: "reports.new_to_ppg_buyers_tenured",
    name: "NEW_TO_PRODUCT_BUYERS_TENURED",
    type: METRIC_TYPE_INTEGER
};

export const METRIC_NEW_TO_PRODUCT_BUYERS_PCT_TENURED: Metric = {
    labelKey: "reports.new_to_ppg_buyers_pct_tenured",
    name: "NEW_TO_PRODUCT_BUYERS_PCT_TENURED",
    type: METRIC_TYPE_PERCENT
};

export const METRIC_NEW_TO_PRODUCT_BUYERS_SALES_TENURED: Metric = {
    labelKey: "reports.new_to_ppg_buyers_sales_tenured",
    name: "NEW_TO_PRODUCT_BUYERS_SALES_TENURED",
    type: METRIC_TYPE_MONEY
};

export const METRIC_BASKETS_PER_BUYER: Metric = {
    labelKey: "reports.baskets_per_buyer",
    name: "BASKETS_PER_BUYER",
    type: METRIC_TYPE_NUMBER
};

export const METRIC_SALES: Metric = {
    labelKey: "reports.sales",
    name: "SALES",
    type: METRIC_TYPE_MONEY
};

export const METRIC_SALES_PER_BUYER: Metric = {
    labelKey: "reports.sales_per_buyer",
    name: "SALES_PER_BUYER",
    type: METRIC_TYPE_PRICE
};

export const METRIC_SALES_PER_BASKET: Metric = {
    labelKey: "reports.sales_per_basket",
    name: "SALES_PER_BASKET",
    type: METRIC_TYPE_PRICE
};

export const METRIC_SALES_PER_UNIT: Metric = {
    labelKey: "reports.sales_per_unit",
    name: "SALES_PER_UNIT",
    type: METRIC_TYPE_PRICE
};

export const METRIC_UNITS: Metric = {
    labelKey: "reports.units",
    name: "UNITS",
    type: METRIC_TYPE_INTEGER
};

export const METRIC_UNITS_PER_BUYER: Metric = {
    labelKey: "reports.units_per_buyer",
    name: "UNITS_PER_BUYER",
    type: METRIC_TYPE_NUMBER
};

export const METRIC_UNITS_PER_BASKET: Metric = {
    labelKey: "reports.units_per_basket",
    name: "UNITS_PER_BASKET",
    type: METRIC_TYPE_NUMBER
};

export const METRIC_TOTAL_BASKET_SIZE: Metric = {
    labelKey: "reports.total_basket_size",
    name: "TOTAL_BASKET_SIZE",
    type: METRIC_TYPE_PRICE
};

// make sure this array contains all of the metrics above or saved reports trends will not work
export const CONSUMER_METRICS: Metric[] = [
    METRIC_BASKETS,
    METRIC_BUYERS,
    METRIC_CATEGORY_HH_PENETRATION_PCT,
    METRIC_NEW_TO_BRAND_BUYERS_ALL,
    METRIC_NEW_TO_BRAND_BUYERS_PCT_ALL,
    METRIC_NEW_TO_BRAND_BUYERS_PCT_TENURED,
    METRIC_NEW_TO_BRAND_BUYERS_SALES_ALL,
    METRIC_NEW_TO_BRAND_BUYERS_SALES_TENURED,
    METRIC_NEW_TO_BRAND_BUYERS_TENURED,
    METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_ALL,
    METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_PCT_ALL,
    METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_PCT_TENURED,
    METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_SALES_ALL,
    METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_SALES_TENURED,
    METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_TENURED,
    METRIC_NEW_TO_CATEGORY_BUYERS_ALL,
    METRIC_NEW_TO_CATEGORY_BUYERS_PCT_ALL,
    METRIC_NEW_TO_CATEGORY_BUYERS_PCT_TENURED,
    METRIC_NEW_TO_CATEGORY_BUYERS_SALES_ALL,
    METRIC_NEW_TO_CATEGORY_BUYERS_SALES_TENURED,
    METRIC_NEW_TO_CATEGORY_BUYERS_TENURED,
    METRIC_NEW_TO_PRODUCT_BUYERS_ALL,
    METRIC_NEW_TO_PRODUCT_BUYERS_PCT_ALL,
    METRIC_NEW_TO_PRODUCT_BUYERS_PCT_TENURED,
    METRIC_NEW_TO_PRODUCT_BUYERS_SALES_ALL,
    METRIC_NEW_TO_PRODUCT_BUYERS_SALES_TENURED,
    METRIC_NEW_TO_PRODUCT_BUYERS_TENURED,
    METRIC_BASKETS_PER_BUYER,
    METRIC_SALES,
    METRIC_SALES_PER_BUYER,
    METRIC_SALES_PER_BASKET,
    METRIC_SALES_PER_UNIT,
    METRIC_UNITS,
    METRIC_UNITS_PER_BUYER,
    METRIC_UNITS_PER_BASKET,
    METRIC_TOTAL_BASKET_SIZE
];

export type ConsumerMetric =
    | typeof METRIC_BASKETS
    | typeof METRIC_BUYERS
    | typeof METRIC_CATEGORY_HH_PENETRATION_PCT
    | typeof METRIC_NEW_TO_BRAND_BUYERS_ALL
    | typeof METRIC_NEW_TO_BRAND_BUYERS_PCT_ALL
    | typeof METRIC_NEW_TO_BRAND_BUYERS_PCT_TENURED
    | typeof METRIC_NEW_TO_BRAND_BUYERS_SALES_ALL
    | typeof METRIC_NEW_TO_BRAND_BUYERS_SALES_TENURED
    | typeof METRIC_NEW_TO_BRAND_BUYERS_TENURED
    | typeof METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_ALL
    | typeof METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_PCT_ALL
    | typeof METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_PCT_TENURED
    | typeof METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_SALES_ALL
    | typeof METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_SALES_TENURED
    | typeof METRIC_NEW_TO_BRAND_CATEGORY_BUYERS_TENURED
    | typeof METRIC_NEW_TO_CATEGORY_BUYERS_ALL
    | typeof METRIC_NEW_TO_CATEGORY_BUYERS_PCT_ALL
    | typeof METRIC_NEW_TO_CATEGORY_BUYERS_PCT_TENURED
    | typeof METRIC_NEW_TO_CATEGORY_BUYERS_SALES_ALL
    | typeof METRIC_NEW_TO_CATEGORY_BUYERS_SALES_TENURED
    | typeof METRIC_NEW_TO_CATEGORY_BUYERS_TENURED
    | typeof METRIC_NEW_TO_PRODUCT_BUYERS_ALL
    | typeof METRIC_NEW_TO_PRODUCT_BUYERS_PCT_ALL
    | typeof METRIC_NEW_TO_PRODUCT_BUYERS_PCT_TENURED
    | typeof METRIC_NEW_TO_PRODUCT_BUYERS_SALES_ALL
    | typeof METRIC_NEW_TO_PRODUCT_BUYERS_SALES_TENURED
    | typeof METRIC_NEW_TO_PRODUCT_BUYERS_TENURED
    | typeof METRIC_BASKETS_PER_BUYER
    | typeof METRIC_SALES
    | typeof METRIC_SALES_PER_BUYER
    | typeof METRIC_SALES_PER_BASKET
    | typeof METRIC_SALES_PER_UNIT
    | typeof METRIC_UNITS
    | typeof METRIC_UNITS_PER_BUYER
    | typeof METRIC_UNITS_PER_BASKET
    | typeof METRIC_TOTAL_BASKET_SIZE;

// These correspond to ConsumerTrendsReportAggregation enum values from the backend
export const AGGREGATE_BY_PPG = "PPG";
export const AGGREGATE_BY_PPG_AND_CHANNEL = "PPG_AND_CHANNEL";
export const AGGREGATE_BY_BRAND = "BRAND";
export const AGGREGATE_BY_BRAND_AND_CHANNEL = "BRAND_AND_CHANNEL";
export const AGGREGATE_BY_CATEGORY = "CATEGORY";
export const AGGREGATE_BY_CATEGORY_AND_CHANNEL = "CATEGORY_AND_CHANNEL";
export const AGGREGATE_BY_CHANNEL = "CHANNEL";
export const AGGREGATE_BY_UPC = "UPC";
export const AGGREGATE_BY_UPC_AND_CHANNEL = "UPC_AND_CHANNEL";

export type Aggregate =
    | typeof AGGREGATE_BY_PPG
    | typeof AGGREGATE_BY_PPG_AND_CHANNEL
    | typeof AGGREGATE_BY_BRAND
    | typeof AGGREGATE_BY_BRAND_AND_CHANNEL
    | typeof AGGREGATE_BY_CATEGORY
    | typeof AGGREGATE_BY_CATEGORY_AND_CHANNEL
    | typeof AGGREGATE_BY_CHANNEL
    | typeof AGGREGATE_BY_UPC
    | typeof AGGREGATE_BY_UPC_AND_CHANNEL;

export const isBrandAggregate = (aggregate: Aggregate): boolean => {
    return (
        aggregate === AGGREGATE_BY_BRAND ||
        aggregate === AGGREGATE_BY_BRAND_AND_CHANNEL
    );
};

export const isCategoryAggregate = (aggregate: Aggregate): boolean => {
    return (
        aggregate === AGGREGATE_BY_CATEGORY ||
        aggregate === AGGREGATE_BY_CATEGORY_AND_CHANNEL
    );
};

export const isChannelAggregate = (aggregate: Aggregate): boolean => {
    return (
        aggregate === AGGREGATE_BY_CHANNEL ||
        aggregate === AGGREGATE_BY_BRAND_AND_CHANNEL ||
        aggregate === AGGREGATE_BY_CATEGORY_AND_CHANNEL ||
        aggregate === AGGREGATE_BY_PPG_AND_CHANNEL ||
        aggregate === AGGREGATE_BY_UPC_AND_CHANNEL
    );
};

export const isPpgAggregate = (aggregate: Aggregate): boolean => {
    return (
        aggregate === AGGREGATE_BY_PPG ||
        aggregate === AGGREGATE_BY_PPG_AND_CHANNEL
    );
};

export const isUpcAggregate = (aggregate: Aggregate): boolean => {
    return (
        aggregate === AGGREGATE_BY_UPC ||
        aggregate === AGGREGATE_BY_UPC_AND_CHANNEL
    );
};

// Report Promotion filter types
export const ALL = "ALL";
const NON_PROMOTED = "NON_PROMOTED";
export const PROMOTED = "PROMOTED";

export type PromotionFilter =
    | typeof ALL
    | typeof NON_PROMOTED
    | typeof PROMOTED;

export type ConsumerReportFilters = {
    brands: string[];
    categories: string[];
    channels: string[];
    comparisonDate: DateFilter;
    date: DateFilter;
    filterType: "consumer";
    ppgs: any[];
    promotion: PromotionFilter;
    subcategories: string[];
};

export const consumerDefaultFilters: ConsumerReportFilters = {
    brands: [],
    categories: [],
    channels: [],
    comparisonDate: {
        time: COMPARISON_SAME_PERIOD_PRIOR_YEAR
    },
    date: {
        time: LAST_MONTH
    },
    filterType: CONSUMER,
    ppgs: [],
    promotion: ALL,
    subcategories: []
};

// Report data table types
export type ConsumerReportDataTableState = {
    aggregates: Aggregate[] | undefined | null;
    comparisonEndDate: Date | null;
    comparisonStartDate: Date | null;
    endDate: Date | null;
    filters: ConsumerReportFilters | undefined | null;
    search: string;
    sortBy: string;
    sortOrder: SortOrder;
    startDate: Date | null;
    totalCount: number;
};

export const defaultConsumerDataTableState: ConsumerReportDataTableState = {
    aggregates: [AGGREGATE_BY_PPG],
    comparisonEndDate: null,
    comparisonStartDate: null,
    endDate: null,
    filters: consumerDefaultFilters,
    search: "",
    sortBy: PRODUCT_GROUPS,
    sortOrder: ASCENDING,
    startDate: null,
    totalCount: -1
};

export type ConsumerTrendSeries = {
    aggregate: Aggregate;
    brands: string[];
    categories: string[];
    channels: string[];
    metric: ConsumerMetric | null | undefined;
    name: string;
    ppgs: string[];
    promotion: PromotionFilter;
    seriesType: typeof CONSUMER;
    upc: ReportUPC | null | undefined;
};

export const defaultSeries: ConsumerTrendSeries = {
    aggregate: AGGREGATE_BY_PPG,
    brands: [],
    categories: [],
    channels: [],
    metric: null,
    name: "",
    ppgs: [],
    promotion: ALL,
    seriesType: CONSUMER,
    upc: null
};

const DATA_TABLE_NEVER_PROPERTIES = [
    "entityId",
    "entityIdWithoutVersion",
    "fieldSet",
    "objectType"
];

const DATA_TABLE_ALWAYS_PROPERTIES = [
    BRAND,
    CATEGORY,
    CHANNEL,
    GROUP_IDENTIFIER,
    PRODUCT_GROUPS,
    PROMOTION_FILTER,
    UPC,
    UPC_DESCRIPTION
];

function reduceDataTableRow(row: any): any {
    const result: any = {};
    for (const prop in row) {
        if (DATA_TABLE_ALWAYS_PROPERTIES.includes(prop)) {
            result[prop] = row[prop];
        } else if (!DATA_TABLE_NEVER_PROPERTIES.includes(prop)) {
            if (row.meetsAggregationRequirement) {
                // Copy the value over if we meet the aggregation requirement.
                result[prop] = row[prop];
            } else {
                // Otherwise, set the value to null. If we leave it undefined, sorting doesn't work correctly.
                result[prop] = null;
            }
        }
    }
    return result;
}

function addChildren(rows: any[], dictionary: Dictionary<any>) {
    for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const parent = dictionary[row[GROUP_IDENTIFIER]];
        if (parent) {
            parent.children.push(row);
        }
    }
}

export function reduceDataTableRows(rows: any[]): any[] {
    const allRows: any[] = [];
    const allDictionary: Dictionary<any> = {};
    const nonPromotedRows: any[] = [];
    const promotedRows: any[] = [];

    for (let i = 0; i < rows.length; i++) {
        const row: any = reduceDataTableRow(rows[i]);
        const type = get(row, "promotionFilter.value");
        if (type === ALL) {
            row.children = [];
            allRows.push(row);
            allDictionary[row[GROUP_IDENTIFIER]] = row;
        } else if (type === NON_PROMOTED) {
            nonPromotedRows.push(row);
        } else if (type === PROMOTED) {
            promotedRows.push(row);
        }
    }
    if (allRows.length) {
        addChildren(promotedRows, allDictionary);
        addChildren(nonPromotedRows, allDictionary);
        return allRows;
    }
    if (nonPromotedRows.length) {
        return nonPromotedRows;
    }
    return promotedRows;
}

export const reduceConsumerSavedReport = (
    savedReport: SavedReport,
    requests: any[],
    uiState: Dictionary<string>,
    state: ReportsState
) => {
    let reducedState = state;
    const reportView = savedReport.type;
    const reportStyle = uiState.reportStyle;
    switch (reportView) {
        case CONSUMER_CUSTOM:
        case CONSUMER_PRODUCT_TRIAL:
        case CONSUMER_PURCHASE_QUANTITIES: {
            const dataTableRequest = requests.find(request => {
                return (
                    request.objectType === "ConsumerTrendsReportTableRequest"
                );
            });
            const trendRequest = requests.find(request => {
                return (
                    request.objectType === "ConsumerTrendsReportChartRequest"
                );
            });
            if (dataTableRequest && trendRequest) {
                const aggregates = [dataTableRequest.aggregation.value];
                let categories = [];
                const subcategories: string[] = [];
                if (
                    dataTableRequest.aggregation.value === AGGREGATE_BY_UPC ||
                    dataTableRequest.aggregation.value ===
                        AGGREGATE_BY_UPC_AND_CHANNEL
                ) {
                    dataTableRequest.categories.forEach(
                        (category: IcCategory) => {
                            category.subcategories.forEach(
                                (subcategory: IcCategory) => {
                                    subcategories.push(
                                        category.name +
                                            CATEGORY_SUBCATEGORY_SEPARATOR +
                                            subcategory.name
                                    );
                                }
                            );
                        }
                    );
                } else {
                    categories = dataTableRequest.categories.map(
                        (category: IcCategory) => category.name
                    );
                }
                const requestDate = getDateFilter(
                    dataTableRequest.timePeriod.value,
                    dataTableRequest.startDate,
                    dataTableRequest.endDate
                );
                const filters = Object.assign(
                    {},
                    defaultConsumerDataTableState.filters,
                    {
                        brands: dataTableRequest.brands,
                        categories: categories,
                        channels: dataTableRequest.channelIds,
                        comparisonDate: {
                            time: dataTableRequest.comparisonTimePeriod.value
                        },
                        date: requestDate,
                        filterType: CONSUMER,
                        ppgs: dataTableRequest.productGroupIds,
                        promotion: dataTableRequest.promotionFilter.value,
                        subcategories: subcategories
                    }
                );

                const series: ConsumerTrendSeries[] = [];
                const trendDate = getDateFilter(
                    trendRequest.timePeriod.value,
                    trendRequest.startDate,
                    trendRequest.endDate
                );
                trendRequest.series.forEach((seriesRequest: any) => {
                    series.push({
                        aggregate: seriesRequest.aggregation.value,
                        brands: seriesRequest.brands,
                        categories: seriesRequest.categories.map(
                            (category: IcCategory) => category.name
                        ),
                        channels: seriesRequest.channelIds,
                        metric: CONSUMER_METRICS.find(
                            metric => seriesRequest.metric.value === metric.name
                        ),
                        name: seriesRequest.name,
                        ppgs: seriesRequest.productGroupIds,
                        promotion: seriesRequest.promotionFilter.value,
                        seriesType: CONSUMER,
                        upc: seriesRequest.upc
                    });
                });
                reducedState = update(state, {
                    [CONSUMER]: {
                        [reportView]: {
                            dataTable: {
                                aggregates: {
                                    $set: aggregates
                                },
                                filters: {
                                    $set: filters
                                },
                                search: {
                                    $set: dataTableRequest.search
                                },
                                sortBy: {
                                    $set: dataTableRequest.sortBy
                                },
                                sortOrder: {
                                    $set: dataTableRequest.sortOrder
                                }
                            },
                            trend: {
                                configuration: {
                                    date: {
                                        $set: trendDate
                                    },
                                    series: {
                                        $set: series
                                    }
                                }
                            },
                            reportStyle: {
                                $set: reportStyle
                            }
                        }
                    }
                });
            }
            break;
        }
        default:
            break;
    }
    return Object.assign({}, reducedState, {
        reportType: CONSUMER,
        reportView: { value: reportView }
    });
};
