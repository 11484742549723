import * as React from "react";
import { getTrackingEventData, trackEvent } from "common/util/tracking";
import type {
    TrackingComponentLabel,
    TrackingEventName
} from "common/util/trackingEvents";

type WithRadioGroupChangeTrackingProps = {
    children?: React.ReactNode | React.ReactNode[];
    changeHandler: RadioGroupChangeHandler;
    trackingComponentLabel: TrackingComponentLabel;
    trackingEventName?: TrackingEventName;
};

export function withRadioGroupChangeTracking<Props>(
    WrappedComponent: ReactComponentCreator<
        Props & WithRadioGroupChangeTrackingProps
    >,
    trackingComponentType: string,
    trackingEventName: TrackingEventName = ""
): React.ComponentType<Props & WithRadioGroupChangeTrackingProps> {
    const WithRadioGroupChangeTracking = (
        props: Props & WithRadioGroupChangeTrackingProps
    ) => {
        let eventName = props.trackingEventName;
        if (!eventName) {
            eventName = trackingEventName;
        }
        const trackingComponentLabel = props.trackingComponentLabel;
        let wrappedProps = { ...props };
        if (props.changeHandler) {
            wrappedProps = Object.assign({}, props, {
                changeHandler: function (
                    radioValue: string,
                    event: React.SyntheticEvent<HTMLInputElement>
                ) {
                    if (eventName) {
                        // Do not send selected radio value for now as id/enum is not too useful for analytics
                        // tracking and we do not want to accidentally send sensitive value information out
                        trackEvent(
                            getTrackingEventData(
                                trackingComponentType,
                                trackingComponentLabel,
                                eventName
                                //radioValue
                            )
                        );
                    }
                    props.changeHandler(radioValue, event);
                }
            });
        }
        return <WrappedComponent {...wrappedProps} />;
    };
    WithRadioGroupChangeTracking.displayName =
        "WithRadioGroupChangeTracking(" + trackingComponentType + ")";
    return WithRadioGroupChangeTracking;
}
