import type { Action } from "redux";
import {
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import { PREFERENCE_PREFIX } from "shell/util/preferences";
import type { Actions, RootState } from "store";

// action types
export const REQUEST_PREFERENCES = "REQUEST_PREFERENCES";
export const RECEIVE_PREFERENCES = "RECEIVE_PREFERENCES";
export const REMOVE_PREFERENCES = "REMOVE_PREFERENCES";
export const SET_PREFERENCE = "SET_PREFERENCE";

export type PreferencesActions =
    | ReceivePreferences
    | RequestPreferences
    | RemovePreferences
    | SetPreference;

interface RequestPreferences extends Action {
    type: typeof REQUEST_PREFERENCES;
}

export function requestPreferences(): RequestPreferences {
    return {
        type: REQUEST_PREFERENCES
    };
}

interface ReceivePreferences extends Action {
    type: typeof RECEIVE_PREFERENCES;
    preferences: any;
}

export function receivePreferences(json: any): ReceivePreferences {
    return {
        type: RECEIVE_PREFERENCES,
        preferences: json
    };
}

interface SetPreference extends Action {
    type: typeof SET_PREFERENCE;
    preference: {
        name: string;
        value: any;
    };
}

export function setPreference(name: string, value: any): SetPreference {
    const prefixedName = PREFERENCE_PREFIX + name;
    return {
        type: SET_PREFERENCE,
        preference: {
            name: prefixedName,
            value: value
        }
    };
}

interface RemovePreferences extends Action {
    type: typeof REMOVE_PREFERENCES;
    names: string[];
}

export function removePreferences(names: string[]): RemovePreferences {
    const preferencesToRemove = names.map(name => PREFERENCE_PREFIX + name);
    return {
        type: REMOVE_PREFERENCES,
        names: preferencesToRemove
    };
}

export function fetchPreferences() {
    const url = "api/userpreference/like/" + PREFERENCE_PREFIX;
    return makeRequestThunk(url, {
        okDispatchFunc: receivePreferences,
        preRequestFunc: requestPreferences,
        showLoadMask: false
    });
}

export const writePreference = (name: string, value: any, doSet = false) => {
    const prefixedName = PREFERENCE_PREFIX + name;
    return makeRequestThunk("api/userpreference", {
        body: [
            {
                objectType: "UserPreferenceTO",
                name: prefixedName,
                value: JSON.stringify(value)
            }
        ],
        isCancellable: false,
        method: METHOD_POST,
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            if (doSet) {
                return setPreference(name, value);
            } else {
                return [];
            }
        },
        showLoadMask: false
    });
};
