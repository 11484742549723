import get from "lodash.get";
import isNil from "lodash.isnil";
import { isEmptyString } from "common/util/lang";

type Property = string | [string, string];

export const reduceArray = (arr: any[], ...properties: Property[]): any[] => {
    return arr.reduce((result, obj) => {
        result.push(reduceObject(obj, ...properties));
        return result;
    }, []);
};

export const reduceByKeys = (obj: any, property: string): any => {
    return Object.keys(obj).reduce((result: any, key: string): any => {
        result[key] = obj[key][property];
        return result;
    }, {});
};

export const reduceObject = (obj: any, ...properties: Property[]): any => {
    if (obj) {
        return properties.reduce((result: any, property: Property) => {
            if (Array.isArray(property)) {
                result[property[1]] = get(obj, property[0]);
            } else {
                result[property] = obj[property];
            }
            return result;
        }, {});
    }
    return {};
};

export const mapListEntityIdWithoutVersion = <T extends Record<string, any>>(
    identifier: keyof T
) => {
    return (item: T) => ({
        ...item,
        [identifier]: getEntityIdWithoutVersion(item[identifier])
    });
};

export const getEntityIdWithoutVersion = (
    entityId: string,
    entityName?: string | null
): string => {
    let entityIdWithoutVersion, cutStart, cutEnd;

    entityIdWithoutVersion = entityId || "";
    if (!isNil(entityId) && !isEmptyString(entityId)) {
        cutStart = entityId.indexOf("_");
        cutEnd = entityId.indexOf("@");
        if (cutEnd > -1 && !entityName) {
            entityName = entityId.substr(cutEnd + 1);
        }
        if (cutStart > -1 && entityName) {
            entityIdWithoutVersion =
                entityId.substr(0, cutStart) + "@" + entityName;
        }
    }
    return entityIdWithoutVersion;
};

export const getEntityIdListWithoutVersion = (entityId: string): string => {
    if (isNil(entityId) || isEmptyString(entityId)) {
        return "";
    }
    return entityId.replace(/_(\d)+@/g, "@");
};

export const getEntity = (entityId: string): string => {
    if (!isNil(entityId) && !isEmptyString(entityId)) {
        const atPos = entityId.indexOf("@");
        if (atPos > -1) {
            return entityId.substr(atPos + 1);
        }
    }
    return "";
};

export function getArrayValue<P>(list: P[], value: string | null): P | null {
    let found = null;
    if (!isNil(value) && !isEmptyString(value)) {
        found = list.find(item => get(item, "value") === value) || null;
    }
    if (isNil(found) && list.length > 0) {
        return get(list, "[0]", null);
    }
    return found;
}

export const addToArrayAtIndex = (
    array: any[],
    indexToAdd: number,
    value: any
): any[] => {
    return [
        ...array.slice(0, indexToAdd),
        value,
        ...array.slice(indexToAdd + 1)
    ];
};

export const insertIntoArrayAtIndex = (
    array: any[],
    indexToInsert: number,
    value: any
) => {
    return [
        ...array.slice(0, indexToInsert),
        value,
        ...array.slice(indexToInsert)
    ];
};

export const removeFromArrayAtIndex = (
    array: any[],
    indexToRemove: number
): any[] => {
    return [
        ...array.slice(0, indexToRemove),
        ...array.slice(indexToRemove + 1)
    ];
};

const CREATED_BY_QUERY_FIELD = "creatorName";
export const CREATED_ON_QUERY_FIELD = "created";
export const EDITED_BY_QUERY_FIELD = "modifiedByName";
export const EDITED_ON_QUERY_FIELD = "modifiedDate";

export const CREATED_BY_DATA_FIELD = "createdBy";
export const CREATED_ON_DATA_FIELD = "createdOn";
export const EDITED_BY_DATA_FIELD = "editedBy";
export const EDITED_ON_DATA_FIELD = "editedOn";

export const creatorModifierQueryFields = [
    CREATED_BY_QUERY_FIELD,
    CREATED_ON_QUERY_FIELD,
    EDITED_BY_QUERY_FIELD,
    EDITED_ON_QUERY_FIELD
];

export const defaultCreatorModifierState = {
    [CREATED_BY_DATA_FIELD]: "",
    [CREATED_ON_DATA_FIELD]: "",
    [EDITED_BY_DATA_FIELD]: "",
    [EDITED_ON_DATA_FIELD]: ""
};

export const reduceCreatorModifier = (data: any): CreatorModifierData => {
    const createdBy = get(data, CREATED_BY_QUERY_FIELD, "");
    const createdOn = get(data, CREATED_ON_QUERY_FIELD, "");
    const lastEditedBy = get(data, EDITED_BY_QUERY_FIELD, "");
    const lastEditedOn = get(data, EDITED_ON_QUERY_FIELD, "");
    const editedBy = lastEditedBy ? lastEditedBy : createdBy;
    const editedOn = lastEditedOn ? lastEditedOn : createdOn;
    return {
        createdBy,
        createdOn,
        editedBy,
        editedOn
    };
};

export const defaultEnumState = {
    displayName: "",
    objectType: "",
    value: ""
};

export const chunkArray = (array: any[], chunkSize: number): any[][] => {
    const chunked = [];
    const numberOfChunks = Math.ceil(array.length / chunkSize);
    for (let i = 0; i < numberOfChunks; i = i + 1) {
        const startIndex = i * chunkSize;
        const endIndex = startIndex + chunkSize;
        chunked.push(array.slice(i * chunkSize, endIndex));
    }
    return chunked;
};

// Options has to have value field
export const verifySelectedValues = (
    options: any[],
    selectedValues: string[]
): string[] => {
    const values: string[] = [];
    selectedValues.forEach(value => {
        const found = options.find(option => option.value === value);
        if (found) {
            values.push(value);
        }
    });
    return values;
};

// Flattens a multi layer tree structure
export const flattenTree = (tree: any[]): any[] => {
    let flattenedTree: any[] = [];
    tree.forEach(option => {
        flattenedTree.push(option);
        if (Array.isArray(option.children)) {
            flattenedTree = flattenedTree.concat(flattenTree(option.children));
        }
    });
    return flattenedTree;
};

// Verifying selected values are valid in a single layer tree object
// It only checks the children and only 1 layer of children
export const verifySingleLevelTreeSelectedValues = (
    options: any[],
    selectedValues: string[]
): string[] => {
    let flattenedOptions: any[] = [];
    options.forEach(option => {
        if (option.children) {
            flattenedOptions = flattenedOptions.concat(option.children);
        }
    });

    const values: string[] = [];
    selectedValues.forEach(value => {
        const found = flattenedOptions.find(option => option.value === value);
        if (found) {
            values.push(value);
        }
    });
    return values;
};
