import * as React from "react";
import get from "lodash.get";
import isNil from "lodash.isnil";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import {
    Dropdown,
    DropdownItem,
    POSITION_LEFT
} from "common/components/Dropdown";
import { emptyIdFn, isEmptyString } from "common/util/lang";
import theme from "common/components/theme";
import { composeTrackingComponentLabel } from "common/util/tracking";
import type { TrackingComponentLabel } from "common/util/trackingEvents";
import type { RootState } from "store";

type ActionsDropdownViewOwnProps = {
    actions: MenuItem[];
    clickHandler: IdHandler;
    displayCount: boolean;
    trackingComponentLabel: TrackingComponentLabel;
};

type ActionsDropdownViewProps = PropsFromRedux &
    ActionsDropdownViewOwnProps &
    WithTranslation;

const ActionsDropdownView = ({
    actions,
    allowableActions,
    clickHandler = emptyIdFn,
    displayCount,
    t,
    trackingComponentLabel
}: ActionsDropdownViewProps) => {
    // If allowable action is present and is not allowed set actions to be empty
    // Else show actions
    const filteredActions = actions.filter((item: MenuItem) => {
        const allowableAction = item.allowableAction;
        if (!isNil(allowableAction) && !isEmptyString(allowableAction)) {
            return !!get(allowableActions, allowableAction, false);
        }
        return true;
    });

    let actionsText = t("common:general.actions");
    if (displayCount) {
        actionsText = t("common:general.actions_x", {
            count: filteredActions.length
        });
    }
    if (filteredActions && filteredActions.length >= 1) {
        return (
            <Dropdown
                borderColor={theme.menuBorder}
                clickHandler={id => clickHandler(id)}
                horizontalPosition={POSITION_LEFT}
                hoverColor={theme.text}
                minWidth="170px"
                paddingBottom="6px"
                paddingLeft="8px"
                paddingRight="8px"
                paddingTop="6px"
                text={actionsText}
                trackingComponentLabel={composeTrackingComponentLabel([
                    trackingComponentLabel,
                    "Actions"
                ])}
            >
                {filteredActions.map(action => {
                    return (
                        <DropdownItem key={action.id} data-id={action.id}>
                            {action.text}
                        </DropdownItem>
                    );
                })}
            </Dropdown>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state: RootState) => {
    return {
        allowableActions: get(state, "init.allowableActions", {})
    };
};

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(ActionsDropdownView));
