import * as React from "react";
import get from "lodash.get";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import styled from "styled-components/macro";
import theme from "common/components/theme";
import { IGNORE_REACT_OUTSIDE_CLICK } from "common/util/outsideClick";
import type { RootState } from "store";

const LoadmaskWrapper = styled.div`
    position: fixed;
    z-index: 2000000;
    background-color: ${theme.lessLightText};
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    user-select: none;
`;
LoadmaskWrapper.displayName = "LoadmaskWrapper";

type LoadmaskContentProps = {
    loadingMessage?: string;
};

const LoadmaskContent = styled.div<LoadmaskContentProps>`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${props =>
        props.loadingMessage ? props.theme.loadMaskBg : "transparent"};
    border-radius: 2px;
    padding: 10px;
`;
LoadmaskContent.displayName = "LoadmaskContent";

type LoadmaskSpinnerProps = {
    position?: string;
    spinnerBorderWidth?: string;
    spinnerHeight?: number;
    spinnerWidth?: number;
} & Pick<marginPropsType, "marginTop" | "marginLeft">;

export const LoadmaskSpinner = styled.div<LoadmaskSpinnerProps>`
    ${props => props.position && `position: ${props.position};`}
    top: ${props => (props.spinnerHeight ? props.spinnerHeight / -2 : -10)}px;
    left: ${props => (props.spinnerWidth ? props.spinnerWidth / -2 : -10)}px;

    :before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        width: ${props =>
            props.spinnerWidth ? props.spinnerWidth + "px" : "20px"};
        height: ${props =>
            props.spinnerHeight ? props.spinnerHeight + "px" : "20px"};
        ${props => props.marginTop && `margin-top: ${props.marginTop};`}
        ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
        border-radius: 50%;
        border: ${props =>
                props.spinnerBorderWidth ? props.spinnerBorderWidth : "2px"}
            solid ${theme.loadMask};
        border-top-color: ${theme.loadMaskBorder};
        -webkit-animation: spinner 0.8s linear infinite;
        animation: spinner 0.8s linear infinite;
    }
`;
LoadmaskSpinner.displayName = "LoadmaskSpinner";

type LoadmaskMessageProps = {
    showSpinner: boolean;
};

const LoadmaskMessage = styled.div<LoadmaskMessageProps>`
    margin-top: ${props => (props.showSpinner ? "25px" : 0)};
    color: ${theme.text};
`;
LoadmaskMessage.displayName = "LoadmaskMessage";

type LoadmaskOwnProps = {
    loadingMessage?: string;
    showSpinner?: boolean;
};

type LoadmaskProps = LoadmaskOwnProps & PropsFromRedux;

class Loadmask extends React.PureComponent<LoadmaskProps> {
    static displayName = "Loadmask";
    render() {
        const {
            loadingMessage,
            showLoadmask,
            showSpinner = false
        } = this.props;
        const spinner = showSpinner ? <LoadmaskSpinner /> : null;
        const message = loadingMessage ? (
            <LoadmaskMessage showSpinner={showSpinner}>
                {loadingMessage}
            </LoadmaskMessage>
        ) : null;
        if (showLoadmask) {
            // loadmask id needed for pdf export
            return (
                <LoadmaskWrapper
                    className={IGNORE_REACT_OUTSIDE_CLICK}
                    id="loadmask"
                >
                    {(spinner || message) && (
                        <LoadmaskContent loadingMessage={loadingMessage}>
                            {spinner}
                            {message}
                        </LoadmaskContent>
                    )}
                </LoadmaskWrapper>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        showLoadmask: get(state, "loadmask.showLoadmask.length", 0) > 0
    };
};

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Loadmask);
