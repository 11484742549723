import * as React from "react";
import DialogPage from "common/components/styled/DialogPage";
import Error from "common/components/styled/Error";

type AppErrorProps = {
    message: string;
};

const AppError = (props: AppErrorProps) => (
    <DialogPage cssWidth="300px" padding="30px 60px">
        <Error>{props.message}</Error>
    </DialogPage>
);

export default AppError;
