import moment from "moment";
import { dateRange2Str } from "common/util/format";
import type { MATRIX, PRICE } from "common/util/url";

// dates
export const ALL_TIME = "ALL_TIME" as string;
export const CUSTOM = "CUSTOM" as string;
export const LAST_365_DAYS = "LAST_365_DAYS" as string;
export const LAST_365_DAYS_YEAR_AGO = "LAST_365_DAYS_YEAR_AGO" as string;
export const LAST_2_MONTHS = "LAST_2_MONTHS" as string;
export const LAST_3_MONTHS = "LAST_3_MONTHS" as string;
const LAST_30_DAYS = "LAST_30_DAYS" as string;
export const LAST_6_MONTHS = "LAST_6_MONTHS" as string;
export const LAST_MONTH = "LAST_MONTH" as string;
export const LAST_WEEK = "LAST_WEEK" as string;
export const LAST_YEAR = "LAST_YEAR" as string;
const LAST_YEAR_H1 = "LAST_YEAR_H1" as string;
const LAST_YEAR_H2 = "LAST_YEAR_H2" as string;
export const LAST_YEAR_YEAR_TO_DATE = "LAST_YEAR_YEAR_TO_DATE" as string;
const NEXT_30_DAYS = "NEXT_30_DAYS" as string;
const NEXT_90_DAYS = "NEXT_90_DAYS" as string;
export const SINCE_1_YEAR_AGO = "SINCE_1_YEAR_AGO" as string;
const THIS_YEAR_H1 = "THIS_YEAR_H1" as string;
export const YEAR_BEFORE_LAST = "YEAR_BEFORE_LAST" as string;
export const YEAR_TO_DATE = "YEAR_TO_DATE" as string;
export const COMPARISON_NONE = "NONE" as string;
export const COMPARISON_PRIOR_PERIOD = "PRIOR_PERIOD";
export const COMPARISON_SAME_PERIOD_PRIOR_YEAR = "SAME_PERIOD_PRIOR_YEAR";

export type DateMenuType = typeof MATRIX | typeof PRICE | typeof CUSTOM;

export type DateType =
    | typeof ALL_TIME
    | typeof COMPARISON_NONE
    | typeof COMPARISON_PRIOR_PERIOD
    | typeof COMPARISON_SAME_PERIOD_PRIOR_YEAR
    | typeof CUSTOM
    | typeof LAST_2_MONTHS
    | typeof LAST_3_MONTHS
    | typeof LAST_30_DAYS
    | typeof LAST_365_DAYS
    | typeof LAST_365_DAYS_YEAR_AGO
    | typeof LAST_6_MONTHS
    | typeof LAST_MONTH
    | typeof LAST_WEEK
    | typeof LAST_YEAR
    | typeof LAST_YEAR_H1
    | typeof LAST_YEAR_H2
    | typeof LAST_YEAR_YEAR_TO_DATE
    | typeof NEXT_30_DAYS
    | typeof NEXT_90_DAYS
    | typeof SINCE_1_YEAR_AGO
    | typeof THIS_YEAR_H1
    | typeof YEAR_BEFORE_LAST
    | typeof YEAR_TO_DATE;

export type DateFilter = {
    endDate?: string;
    label?: string;
    startDate?: string;
    time: DateType;
};

const JANUARY = "JANUARY" as string;
const FEBRUARY = "FEBRUARY" as string;
const MARCH = "MARCH" as string;
const APRIL = "APRIL" as string;
const MAY = "MAY" as string;
const JUNE = "JUNE" as string;
const JULY = "JULY" as string;
const AUGUST = "AUGUST" as string;
const SEPTEMBER = "SEPTEMBER" as string;
const OCTOBER = "OCTOBER" as string;
const NOVEMBER = "NOVEMBER" as string;
const DECEMBER = "DECEMBER" as string;

export const EPOCH_1_DAY = 86400000;

// Month type is for react-select and dropdown. What we receive from
// backend is MonthObj so formatMonth transforms it for us
type Month = OptionType;

type MonthObj = {
    displayName: string;
    objectType: string;
    value: string;
};

type CustomMonth =
    | typeof JANUARY
    | typeof FEBRUARY
    | typeof MARCH
    | typeof APRIL
    | typeof MAY
    | typeof JUNE
    | typeof JULY
    | typeof AUGUST
    | typeof SEPTEMBER
    | typeof OCTOBER
    | typeof NOVEMBER
    | typeof DECEMBER;

export const MONTHS: CustomMonth[] = [
    JANUARY,
    FEBRUARY,
    MARCH,
    APRIL,
    MAY,
    JUNE,
    JULY,
    AUGUST,
    SEPTEMBER,
    OCTOBER,
    NOVEMBER,
    DECEMBER
];

export const getMonthOptions = (): any[] => {
    return Array.from(moment.months(), (label, index) => {
        return {
            label: label,
            value: MONTHS[index]
        };
    });
};

export const getDaysInMonth = (month: OptionTypeOrNullOrUndefined): number => {
    return month ? moment().month(month.label).daysInMonth() : 31;
};

export const getDayOptions = (numDays: number): any[] => {
    return Array.from(Array(numDays), (_, num) => {
        return {
            value: num + 1,
            label: num + 1
        };
    });
};

export const monthToInt = (month: string): number => {
    return parseInt(moment().month(month).format("M"));
};

// This does not take a year for startDate and endDate
// Checks to see if month day range overlaps with a month day year range
export const rangesOverlap = (
    startMonth: OptionTypeOrNullOrUndefined,
    startDay: number,
    endMonth: OptionTypeOrNullOrUndefined,
    endDay: number,
    rangeStart: string,
    rangeEnd: string
): boolean => {
    if (
        !startMonth ||
        !startDay ||
        !endMonth ||
        !endDay ||
        rangeStart === "" ||
        rangeEnd === ""
    ) {
        return false;
    }

    const range1StartDate = moment(rangeStart, "YYYY-MM-DD");
    const range1EndDate = moment(rangeEnd, "YYYY-MM-DD");
    const startYear = range1StartDate.year();
    const endYear = range1EndDate.year();
    let range, range2StartDate;
    // If crossing years see if start1 is before end2
    if (endYear > startYear) {
        range = formatDateRange(
            startMonth,
            startDay,
            endMonth,
            endDay,
            endYear
        );
        range2StartDate = moment(range.startDate, "MM-DD-YYYY");
        if (moment(range2StartDate).isSameOrBefore(range1EndDate)) {
            return true;
        }
    }
    range = formatDateRange(startMonth, startDay, endMonth, endDay, startYear);
    range2StartDate = moment(range.startDate, "MM-DD-YYYY");
    const range2EndDate = moment(range.endDate, "MM-DD-YYYY");
    // Case 1: Start day is in range (true)
    // Case 2: End day is in range (true)
    // Case 3: Start Day is before and end day is after (true)
    return (
        moment(range2StartDate).isBetween(
            range1StartDate,
            range1EndDate,
            "day",
            "[]"
        ) ||
        moment(range2EndDate).isBetween(
            range1StartDate,
            range1EndDate,
            "day",
            "[]"
        ) ||
        (moment(range2StartDate).isBefore(range1StartDate) &&
            moment(range2EndDate).isAfter(range1EndDate))
    );
};

// Creates date range for year passed in
export const formatDateRange = (
    startMonth: Month,
    startDay: number,
    endMonth: Month,
    endDay: number,
    year: number
): any => {
    const sMonth = monthToInt(startMonth.label);
    const eMonth = monthToInt(endMonth.label);
    const nextYear = year + 1;

    const startDate = formatDate(sMonth, startDay, year);
    const endDate =
        eMonth < sMonth || (sMonth === eMonth && endDay < startDay)
            ? formatDate(eMonth, endDay, nextYear)
            : formatDate(eMonth, endDay, year);

    return {
        startDate: startDate,
        endDate: endDate
    };
};

export const formatDate = (
    month: number,
    day: number,
    year: number
): string => {
    return month + "-" + day + "-" + year;
};

export const formatMonth = (month: MonthObj): Month => {
    return {
        label: month.displayName,
        value: month.value
    };
};

export const formatMonthObj = (month: Month): MonthObj => {
    return {
        displayName: month.label,
        objectType: "Month",
        value: month.value
    };
};

export const getEndYear = (yearsIntoFuture: number): number => {
    const today = new Date();
    const endYear = moment(today).year() + yearsIntoFuture + 1;
    return endYear;
};

// to get around timezone issue where react date-picker was setting date 1 day off because of UTC
export const getDateForDatePicker = (dateString?: string | null) => {
    // expects date in YYYY-MM-DD format
    if (dateString) {
        const dateParts = dateString.split("-");
        return new Date(
            parseInt(dateParts[0]),
            // month is 0 based
            parseInt(dateParts[1]) - 1,
            parseInt(dateParts[2]),
            0,
            0,
            0
        );
    }
    return null;
};

// Used to format date to pass to backend
export const getDateStringFromDate = (
    date: Date | undefined | null | moment.Moment | undefined | null
): string => {
    return date ? moment(date).format("YYYY-MM-DD") : "";
};

// Used to get static dates
export const getStaticStartAndEndDates = (date: DateType) => {
    switch (date) {
        case LAST_365_DAYS:
            return {
                startDate: moment().subtract(1, "year").toDate(),
                endDate: moment().toDate()
            };
        case LAST_2_MONTHS:
            return {
                startDate: moment().subtract(2, "months").toDate(),
                endDate: moment().toDate()
            };
        case LAST_3_MONTHS:
            return {
                startDate: moment().subtract(3, "months").toDate(),
                endDate: moment().toDate()
            };
        case LAST_6_MONTHS:
            return {
                startDate: moment().subtract(6, "months").toDate(),
                endDate: moment().toDate()
            };
        case LAST_MONTH:
            return {
                startDate: moment().subtract(1, "month").toDate(),
                endDate: moment().toDate()
            };
        case LAST_WEEK:
            return {
                startDate: moment().subtract(1, "week").toDate(),
                endDate: moment().toDate()
            };
        case LAST_YEAR:
            return {
                startDate: moment().subtract(1, "year").toDate(),
                endDate: moment().toDate()
            };
        case LAST_YEAR_YEAR_TO_DATE:
            return {
                startDate: moment()
                    .startOf("year")
                    .subtract(1, "year")
                    .toDate(),
                endDate: moment().toDate()
            };
        case YEAR_TO_DATE:
            return {
                startDate: moment().startOf("year").toDate(),
                endDate: moment().toDate()
            };
        default:
            return {
                startDate: "",
                endDate: ""
            };
    }
};

export const staticDateRange2Str = (date: DateType): string => {
    const staticDates = getStaticStartAndEndDates(date);
    return dateRange2Str(staticDates.startDate, staticDates.endDate);
};
