import type { TFunction } from "i18next";
import get from "lodash.get";
import type { AnyAction } from "redux";
import {
    receiveAsyncItems,
    requestAsyncItems
} from "common/administration/async/state/asyncActions";
import {
    andFilterCondition,
    freeFormFilterCondition,
    notFilterCondition,
    stringFilterCondition
} from "common/util/filter";
import { sortCondition } from "common/util/query";
import {
    BUTTON_CANCELYES,
    BUTTON_OK,
    BUTTON_YES,
    ICON_ALERT,
    ICON_QUESTION,
    openMessageBox
} from "common/shell/state/messageBoxActions";
import { resetCanNotLeave } from "common/shell/state/navigationActions";
import {
    BODY_TYPE_FILE,
    makeRequestThunk,
    METHOD_DELETE,
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT
} from "common/shell/state/requestActions";
import type { PostPutMethod } from "common/shell/state/requestActions";
import { success } from "common/shell/state/toastActions";
import { sortByLabel } from "common/util/format";
import { getEntityIdWithoutVersion } from "common/util/object";
import {
    AUTOMATED_DESIGN_PAGE,
    CAMPAIGN_DETAIL_OVERVIEW_PAGE,
    openFullAppWindow
} from "common/util/url";
import { TARGETING_TYPE_LOCATION } from "campaign/state/campaignStateUtils";
import type { TargetingSelectItemType } from "campaign/state/campaignStateUtils";
import { campaignsPage } from "campaign/state/campaignsActions";
import {
    CAMPAIGN_STATE_COMPLETED,
    CAMPAIGN_STATE_DRAFT,
    CAMPAIGN_STATE_PAUSED,
    CAMPAIGN_STATE_QUEUED,
    CAMPAIGN_STATE_REQUEUED,
    CAMPAIGN_STATE_RUNNING,
    processTestInsights,
    TARGETING_ENTITY_PREFIX
} from "campaign/state/campaignStateUtils";
import type { ActionOrThunk, Actions, AppDispatch, RootState } from "store";
import {
    CAMPAIGN_CREATE_PAGE,
    CAMPAIGN_BUDGET_OPTIMIZATION_EDIT_PAGE,
    CAMPAIGN_EDIT_PAGE,
    CAMPAIGN_OFFERS_PAGE,
    CAMPAIGN_PAGE,
    CAMPAIGN_REDEMPTION_EDIT_PAGE,
    CAMPAIGN_TARGETING_EDIT_PAGE,
    CAMPAIGNS_PAGE,
    CAMPAIGN_TARGETING_PAGE,
    CAMPAIGN_PPG_EDIT_PAGE
} from "shell/state/pageActions";

// action types
export const RECEIVE_CAMPAIGN = "RECEIVE_CAMPAIGN";
export const RECEIVE_CAMPAIGN_CEI = "RECEIVE_CAMPAIGN_CEI";
export const RECEIVE_CAMPAIGN_PROGRESS = "RECEIVE_CAMPAIGN_PROGRESS";
export const RECEIVE_CAMPAIGN_TEST_INSIGHTS = "RECEIVE_CAMPAIGN_TEST_INSIGHTS";
const RECEIVE_TARGETING_INIT = "RECEIVE_TARGETING_INIT";
export const REQUEST_CAMPAIGN_CEI = "REQUEST_CAMPAIGN_CEI";
export const REQUEST_CAMPAIGN_PROGRESS = "REQUEST_CAMPAIGN_PROGRESS";
const REQUEST_CAMPAIGN_TEST_INSIGHTS = "REQUEST_CAMPAIGN_TEST_INSIGHTS";
export const REQUEST_CAMPAIGN = "REQUEST_CAMPAIGN";
const REQUEST_TARGETING_INIT = "REQUEST_TARGETING_INIT";
export const RESET_CAMPAIGN = "RESET_CAMPAIGN";

// actions creators

export const campaignPage = (entityId: string): AnyAction => ({
    type: CAMPAIGN_PAGE,
    payload: {
        // matches what is in routes.js
        entityId: entityId
    }
});

export const campaignCreatePage = (): AnyAction => ({
    type: CAMPAIGN_CREATE_PAGE
});

export const campaignEditPage = (entityId: string): AnyAction => ({
    type: CAMPAIGN_EDIT_PAGE,
    payload: {
        entityId
    }
});

export const campaignOffersPage = (entityId: string): AnyAction => ({
    type: CAMPAIGN_OFFERS_PAGE,
    payload: {
        entityId
    }
});

export const campaignBudgetAndOptimizationEditPage = (
    entityId: string
): AnyAction => ({
    type: CAMPAIGN_BUDGET_OPTIMIZATION_EDIT_PAGE,
    payload: {
        entityId
    }
});

export const campaignPPGEditPage = (
    entityId: string,
    campaignProductGroupEntityId: string
): AnyAction => ({
    type: CAMPAIGN_PPG_EDIT_PAGE,
    payload: {
        entityId,
        campaignProductGroupEntityId
    }
});

export const campaignRedemptionEditPage = (entityId: string): AnyAction => ({
    type: CAMPAIGN_REDEMPTION_EDIT_PAGE,
    payload: {
        entityId
    }
});

export const campaignTargetingEditPage = (entityId: string): AnyAction => ({
    type: CAMPAIGN_TARGETING_EDIT_PAGE,
    payload: {
        entityId
    }
});

export const campaignSectionPage = (
    page: string,
    entityId: string
): AnyAction => ({
    type: page,
    payload: {
        entityId
    }
});

const requestCampaignProgress = (): AnyAction => ({
    type: REQUEST_CAMPAIGN_PROGRESS
});

const receiveCampaignProgress = (json: Json): AnyAction => ({
    type: RECEIVE_CAMPAIGN_PROGRESS,
    json: json
});

const requestCampaignCei = (): AnyAction => ({
    type: REQUEST_CAMPAIGN_CEI
});

const receiveCampaignCei = (json: Json): AnyAction => ({
    type: RECEIVE_CAMPAIGN_CEI,
    json: json
});

const requestCampaignTestInsights = (): AnyAction => ({
    type: REQUEST_CAMPAIGN_TEST_INSIGHTS
});

const receiveCampaignTestInsights = (json: Json): AnyAction => ({
    type: RECEIVE_CAMPAIGN_TEST_INSIGHTS,
    json: json
});

const requestCampaign = (): AnyAction => ({
    type: REQUEST_CAMPAIGN
});

const receiveCampaign = (json: Json): AnyAction => ({
    type: RECEIVE_CAMPAIGN,
    campaign: json
});

export const resetCampaign = (): AnyAction => ({
    type: RESET_CAMPAIGN
});

const receiveTargetingInit = (): AnyAction => ({
    type: RECEIVE_TARGETING_INIT
});

const requestTargetingInit = (): AnyAction => ({
    type: REQUEST_TARGETING_INIT
});

export function fetchCampaignProgress(entityId: string) {
    return makeRequestThunk("api/campaign", {
        method: METHOD_GET,
        urlPartFunc: function (state: RootState): string {
            return "/" + entityId + "/progress";
        },
        preRequestFunc: requestCampaignProgress,
        okDispatchFunc: receiveCampaignProgress
    });
}

export function fetchCampaignTestInsights(
    entityId: string,
    controlOfferId: string
) {
    const entityIdWithoutVersion = getEntityIdWithoutVersion(entityId);
    return makeRequestThunk(
        "api/campaign/" + entityIdWithoutVersion + "/testinsights",
        {
            method: METHOD_POST,
            body: {
                objectType: "TestInsightsRequest",
                controlOfferId: controlOfferId
            },
            preRequestFunc: requestCampaignTestInsights,
            okDispatchFunc: receiveCampaignTestInsights,
            okResultFunc: (json: Json, state: RootState): any => {
                const insights = processTestInsights(json);
                return {
                    items: insights,
                    filteredCount: insights.length
                };
            },
            showLoadMask: false
        }
    );
}

export function fetchCampaignCei(entityId: string) {
    return makeRequestThunk("api/campaign", {
        method: METHOD_GET,
        urlPartFunc: function (state: RootState): string {
            return "/" + entityId + "/timeChart";
        },
        preRequestFunc: requestCampaignCei,
        okDispatchFunc: receiveCampaignCei
    });
}

export const deleteCampaignConfirmation = (
    campaign: any,
    t: TFunction,
    dispatch: AppDispatch,
    callbackFunction?: NoArgsHandler
): void => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.yes")
            },
            callbackData: { ...campaign, callbackFunction, t },
            callbackFunction: handleDeleteCampaignConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey: "campaign.delete_campaign_confirmation",
                values: {
                    campaign: campaign.name
                }
            },
            showCloseIcon: false,
            title: t("campaign.delete_campaign")
        })
    );
};

const handleDeleteCampaignConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
): void => {
    if (button === BUTTON_YES) {
        const t = callbackData.t;
        const name = callbackData.name;
        dispatch(
            openMessageBox({
                buttons: BUTTON_CANCELYES,
                buttonText: {
                    BUTTON_CANCEL: t("common:general.no"),
                    BUTTON_YES: t("common:general.yes")
                },
                callbackData: callbackData,
                callbackFunction: handleDeleteCampaignConfirmationConfirmation,
                dispatch: dispatch,
                icon: ICON_QUESTION,
                message: {
                    resourceKey:
                        "campaign.delete_campaign_confirmation_confirmation",
                    values: {
                        campaign: name
                    }
                },
                showCloseIcon: false,
                title: t("campaign.delete_campaign")
            })
        );
    }
};

const handleDeleteCampaignConfirmationConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
): void => {
    if (button === BUTTON_YES) {
        dispatch(
            deleteCampaign(
                callbackData.entityId,
                callbackData.name,
                callbackData.callbackFunction
            )
        );
    }
};

export const submitForReviewCampaignConfirmation = (
    campaign: any,
    t: TFunction,
    dispatch: AppDispatch,
    callbackFunction?: NoArgsHandler
): void => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.yes")
            },
            callbackData: { ...campaign, callbackFunction },
            callbackFunction: handleSubmitForReviewCampaignConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey: "campaign.submit_for_review_campaign_confirmation",
                values: {
                    campaign: campaign.name
                }
            },
            showCloseIcon: false,
            title: t("campaign.submit_for_review")
        })
    );
};

const handleSubmitForReviewCampaignConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
): void => {
    if (button === BUTTON_YES) {
        dispatch(
            updateInReviewCampaignState(
                callbackData.entityId,
                callbackData.name,
                "campaign.submit_for_review_campaign_success",
                callbackData.callbackFunction
            )
        );
    }
};

export const queueCampaignConfirmation = (
    campaign: any,
    t: TFunction,
    dispatch: AppDispatch,
    callbackFunction?: NoArgsHandler
): void => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.yes")
            },
            callbackData: { ...campaign, callbackFunction },
            callbackFunction: handleQueueCampaignConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey: "campaign.queue_campaign_confirmation",
                values: {
                    campaign: campaign.name
                }
            },
            showCloseIcon: false,
            title: t("campaign.queue_campaign")
        })
    );
};

const handleQueueCampaignConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
): void => {
    if (button === BUTTON_YES) {
        const currentState = get(callbackData, "state.value");
        let state = CAMPAIGN_STATE_QUEUED;
        if (currentState === CAMPAIGN_STATE_PAUSED) {
            state = CAMPAIGN_STATE_REQUEUED;
        }
        dispatch(
            updateCampaignState(
                callbackData.entityId,
                callbackData.name,
                state,
                "campaign.queue_campaign_success",
                callbackData.callbackFunction
            )
        );
    }
};

export const moveToDraftCampaignConfirmation = (
    campaign: any,
    t: TFunction,
    dispatch: AppDispatch,
    callbackFunction?: NoArgsHandler
): void => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.yes")
            },
            callbackData: { ...campaign, callbackFunction },
            callbackFunction: handleMoveToDraftCampaignConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey: "campaign.move_to_draft_campaign_confirmation",
                values: {
                    campaign: campaign.name
                }
            },
            showCloseIcon: false,
            title: t("campaign.move_to_draft_campaign")
        })
    );
};

const handleMoveToDraftCampaignConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
): void => {
    if (button === BUTTON_YES) {
        dispatch(
            updateCampaignState(
                callbackData.entityId,
                callbackData.name,
                CAMPAIGN_STATE_DRAFT,
                "campaign.move_to_draft_campaign_success",
                callbackData.callbackFunction
            )
        );
    }
};

export const pauseCampaignConfirmation = (
    campaign: any,
    t: TFunction,
    dispatch: AppDispatch,
    callbackFunction?: NoArgsHandler
): void => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.yes")
            },
            callbackData: { ...campaign, callbackFunction },
            callbackFunction: handlePauseCampaignConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey: "campaign.pause_campaign_confirmation",
                values: {
                    campaign: campaign.name
                }
            },
            showCloseIcon: false,
            title: t("campaign.pause_campaign")
        })
    );
};

const handlePauseCampaignConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
): void => {
    if (button === BUTTON_YES) {
        dispatch(
            updateCampaignState(
                callbackData.entityId,
                callbackData.name,
                CAMPAIGN_STATE_PAUSED,
                "campaign.pause_campaign_success",
                callbackData.callbackFunction
            )
        );
    }
};

export const startCampaignConfirmation = (
    campaign: any,
    t: TFunction,
    dispatch: AppDispatch,
    callbackFunction?: NoArgsHandler
): void => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.yes")
            },
            callbackData: { ...campaign, callbackFunction },
            callbackFunction: handleStartCampaignConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey: "campaign.start_campaign_confirmation",
                values: {
                    campaign: campaign.name
                }
            },
            showCloseIcon: false,
            title: t("campaign.start_campaign")
        })
    );
};

const handleStartCampaignConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
): void => {
    if (button === BUTTON_YES) {
        dispatch(
            updateCampaignState(
                callbackData.entityId,
                callbackData.name,
                CAMPAIGN_STATE_RUNNING,
                "campaign.start_campaign_success",
                callbackData.callbackFunction
            )
        );
    }
};

export const stopCampaignConfirmation = (
    campaign: any,
    t: TFunction,
    dispatch: AppDispatch,
    callbackFunction?: NoArgsHandler
): void => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.yes")
            },
            callbackData: { ...campaign, callbackFunction },
            callbackFunction: handleStopCampaignConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey: "campaign.stop_campaign_confirmation",
                values: {
                    campaign: campaign.name
                }
            },
            showCloseIcon: false,
            title: t("campaign.stop_campaign")
        })
    );
};

const handleStopCampaignConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
): void => {
    if (button === BUTTON_YES) {
        dispatch(
            updateCampaignState(
                callbackData.entityId,
                callbackData.name,
                CAMPAIGN_STATE_COMPLETED,
                "campaign.stop_campaign_success",
                callbackData.callbackFunction
            )
        );
    }
};

type ResetRefreshFunction = (targeting: Targeting) => void;

type ResetTargetingCallbackData = {
    entityId: string;
    refreshFunction: ResetRefreshFunction;
};

export const resetTargetingConfirmation = (
    entityId: string,
    t: TFunction,
    dispatch: AppDispatch,
    refreshFunction: (targeting: Targeting) => void
) => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.yes")
            },
            callbackData: { entityId, refreshFunction: refreshFunction },
            callbackFunction: handleResetTargetingConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey: "targeting.reset_warning"
            },
            showCloseIcon: false,
            title: t("targeting.reset_warning_title")
        })
    );
};

const handleResetTargetingConfirmation = (
    button: string,
    callbackData: ResetTargetingCallbackData,
    dispatch: AppDispatch
) => {
    if (button === BUTTON_YES) {
        dispatch(
            resetTargeting(callbackData.entityId, callbackData.refreshFunction)
        );
    }
};

const resetTargeting = (
    entityId: string,
    refreshFunction: ResetRefreshFunction
) => {
    const url = "api/targeting/reinit/";
    return makeRequestThunk(url + entityId, {
        isCancellable: false,
        method: METHOD_GET,
        okDispatchFunc: (json: any, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            if (refreshFunction) {
                refreshFunction(json);
            }
            return actions;
        }
    });
};

// thunks

const updateCampaignState = (
    entityId: string,
    name: string,
    state: string,
    resourceKey: string,
    callbackFunction?: NoArgsHandler
) => {
    const url = "api/campaign/state";
    return makeRequestThunk(url, {
        isCancellable: false,
        method: METHOD_PUT,
        body: {
            objectType: "CampaignStateUpdateRequest",
            campaignIds: [entityId],
            state: {
                objectType: "CampaignState",
                value: state
            }
        },
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const successMessage = {
                resourceKey: resourceKey,
                values: {
                    campaign: name
                }
            };
            if (callbackFunction) {
                callbackFunction();
            }
            actions.push(success(successMessage));
            return actions;
        }
    });
};

const updateInReviewCampaignState = (
    entityId: string,
    name: string,
    resourceKey: string,
    callbackFunction?: NoArgsHandler
) => {
    const url = "api/campaign/review/submit";
    return makeRequestThunk(url, {
        isCancellable: false,
        method: METHOD_POST,
        body: {
            objectType: "CampaignReviewRequest",
            campaignId: entityId,
            comment: null
        },
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const successMessage = {
                resourceKey: resourceKey,
                values: {
                    campaign: name
                }
            };
            if (callbackFunction) {
                callbackFunction();
            }
            actions.push(success(successMessage));
            return actions;
        }
    });
};

const deleteCampaign = (
    entityId: string,
    name: string,
    callbackFunction?: NoArgsHandler
) => {
    const url = "api/entity/campaign/";
    return makeRequestThunk(url + entityId, {
        isCancellable: false,
        method: METHOD_DELETE,
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const successMessage = {
                resourceKey: "campaign.delete_campaign_success",
                values: {
                    campaign: name
                }
            };
            const page = get(state, "page");
            if (page !== CAMPAIGNS_PAGE) {
                actions.push(campaignsPage());
            } else {
                if (callbackFunction) {
                    callbackFunction();
                }
            }
            actions.push(success(successMessage));
            return actions;
        }
    });
};

export function fetchCampaign(
    modelAndFieldSet = "CampaignModel/campaignDetails"
) {
    return makeRequestThunk("api/entity/campaign", {
        method: METHOD_GET,
        queryParams: {
            modelAndFieldSet: modelAndFieldSet
        },
        urlPartFunc: function (state: RootState): string {
            return "/" + get(state, "campaign.entityId");
        },
        preRequestFunc: requestCampaign,
        okDispatchFunc: receiveCampaign,
        okResultFunc: (json: Json, state: RootState) => {
            return json;
        }
    });
}

export function cloneCampaign(entityId: string, name: string) {
    return makeRequestThunk("api/campaign/clone", {
        method: METHOD_POST,
        body: {
            objectType: "CloneRequest",
            entityId: entityId,
            name: name
        },
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const successMessage = {
                resourceKey: "campaign.copy_campaign_success",
                values: {
                    campaign: name
                }
            };
            const showCampaignInPromoApp = get(
                state,
                "init.tenant.tenantConfiguration.showCampaignInPromoApp",
                false
            );
            if (showCampaignInPromoApp) {
                actions.push(campaignPage(json.entityId));
            }
            actions.push(success(successMessage));
            return actions;
        },
        okResultFunc: (json: Json, state: RootState) => {
            const showCampaignInPromoApp = get(
                state,
                "init.tenant.tenantConfiguration.showCampaignInPromoApp",
                false
            );
            if (!showCampaignInPromoApp) {
                const fullAppUrl = get(state, "init.fullAppUrl");
                const campaignEntityId = json.entityId;
                const url =
                    fullAppUrl +
                    "?" +
                    CAMPAIGN_DETAIL_OVERVIEW_PAGE +
                    "&campaignEntityId=" +
                    campaignEntityId;

                openFullAppWindow(url);
            }
        }
    });
}

export function writeCampaign(entity: any, method: PostPutMethod) {
    return makeRequestThunk("api/entity/campaign", {
        method: method,
        body: entity,
        isCancellable: false,
        urlPartFunc: function (state: RootState): string {
            let urlPart = "";
            if (method === METHOD_PUT) {
                urlPart = "/" + entity.entityId;
            }
            return urlPart;
        },
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const values = {
                name: entity.name
            };
            let successMessage = {
                resourceKey: "campaign.create_campaign_success",
                values: values
            };
            if (method === METHOD_PUT) {
                successMessage = {
                    resourceKey: "campaign.edit_campaign_success",
                    values: values
                };
            }
            actions.push(resetCanNotLeave());
            const showCampaignInPromoApp = get(
                state,
                "init.tenant.tenantConfiguration.showCampaignInPromoApp",
                false
            );
            if (showCampaignInPromoApp) {
                actions.push(campaignPage(json.entityId));
            } else {
                actions.push(campaignsPage(CAMPAIGN_STATE_DRAFT));
            }
            actions.push(success(successMessage));
            return actions;
        },
        okResultFunc: (json: Json, state: RootState) => {
            const showCampaignInPromoApp = get(
                state,
                "init.tenant.tenantConfiguration.showCampaignInPromoApp",
                false
            );
            if (!showCampaignInPromoApp) {
                let testingAppPage = AUTOMATED_DESIGN_PAGE;
                if (method === METHOD_PUT) {
                    testingAppPage = CAMPAIGN_DETAIL_OVERVIEW_PAGE;
                }
                const fullAppUrl = get(state, "init.fullAppUrl");
                const campaignEntityId = json.entityId;
                const url =
                    fullAppUrl +
                    "?" +
                    testingAppPage +
                    "&campaignEntityId=" +
                    campaignEntityId;

                openFullAppWindow(url);
            }
        }
    });
}

type RetailerChannel = Retailer & {
    entityIdWithoutVersion: string;
    hasFinancialData: boolean;
    channel: Channel;
};

const reduceRetailer = (retailer: RetailerChannel) => {
    return {
        label: get(retailer, "displayName.value"),
        objectType: "Retailer",
        value: retailer.entityIdWithoutVersion,
        hasFinancialData: retailer.hasFinancialData
    };
};

const processRetailers = (retailers: RetailerChannel[]) => {
    const channelsMap: Dictionary<any> = {};
    retailers.forEach((retailer: RetailerChannel) => {
        const channelEntityId = get(
            retailer,
            "channel.entityIdWithoutVersion",
            ""
        );
        if (channelEntityId) {
            const channel = channelsMap[channelEntityId];
            const reducedRetailer = reduceRetailer(retailer);
            if (!channel) {
                channelsMap[channelEntityId] = {
                    label: get(retailer, "channel.displayName.value"),
                    objectType: "Channel",
                    value: channelEntityId,
                    children: [reducedRetailer]
                };
            } else {
                channelsMap[channelEntityId].children.push(reducedRetailer);
            }
        }
    });
    const channels = Object.values(channelsMap);
    return sortByLabel(channels);
};

export function fetchRetailersWithFinancialDataCheck(entityId: string) {
    return makeRequestThunk(
        "api/productData/retailersWithFinancialDataCheck/" + entityId,
        {
            method: METHOD_GET,
            okResultFunc: (json: Json, state: RootState) => {
                // json should be a list of approver teams
                // look for teamType Promolytics Shadow
                return processRetailers(json);
            },
            showLoadMask: false
        }
    );
}

export const displayNotYetImplementedMessageBox = (
    functionality: string,
    dispatch: AppDispatch
) => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_OK,
            icon: ICON_ALERT,
            message: {
                resourceKey: "common:general.x_not_yet_implemented",
                values: {
                    functionality
                }
            },
            showCloseIcon: true,
            title: "Not Yet Implemented"
        })
    );
};

export function fetchDiscountDistribution(campaignEntityId: string) {
    return makeRequestThunk("api/simplechart/histogram/" + campaignEntityId, {
        method: METHOD_GET,
        okResultFunc: (json: Json, state: RootState) => {
            return json;
        }
    });
}

export function saveCampaignDetails(
    campaign: Campaign,
    successKey: string,
    goToPage: string
) {
    return makeRequestThunk("api/entity/campaign/" + campaign.entityId, {
        method: METHOD_PUT,
        body: campaign,
        isCancellable: false,
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const successMessage = {
                resourceKey: successKey
            };
            actions.push(resetCanNotLeave());
            // reset campaign so CampaignView calls fetchCampaign
            actions.push(resetCampaign());
            actions.push(campaignSectionPage(goToPage, json.entityId));
            actions.push(success(successMessage));
            return actions;
        }
    });
}

export function initTargeting() {
    return makeRequestThunk("api/targeting/init", {
        method: METHOD_GET,
        urlPartFunc: function (state: RootState): string {
            return "/" + get(state, "campaign.entityId");
        },
        preRequestFunc: requestTargetingInit,
        okDispatchFunc: receiveTargetingInit,
        okResultFunc: (json: Json, state: RootState) => {
            return json;
        }
    });
}

const processTargetingRemovePlaceholderEntityId = (targeting: Targeting) => {
    // remove fake entityIds
    const location = targeting.location;
    const locations: TargetingLocation[] = [];
    location.forEach((loc: TargetingLocation) => {
        if (loc.entityId && loc.entityId.startsWith(TARGETING_ENTITY_PREFIX)) {
            locations.push(Object.assign({}, loc, { entityId: null }));
        } else {
            locations.push(loc);
        }
    });
    const affinity = targeting.affinity;
    const affinities: TargetingAffinity[] = [];
    affinity.forEach((aff: TargetingAffinity) => {
        if (aff.entityId && aff.entityId.startsWith(TARGETING_ENTITY_PREFIX)) {
            affinities.push(Object.assign({}, aff, { entityId: null }));
        } else {
            affinities.push(aff);
        }
    });
    return Object.assign({}, targeting, {
        location: locations,
        affinity: affinities
    });
};

export function estimateReach(targeting: Targeting) {
    return makeRequestThunk("api/targeting/estimatereach", {
        method: METHOD_POST,
        urlPartFunc: function (state: RootState): string {
            return "/" + get(state, "campaign.entityId");
        },
        body: processTargetingRemovePlaceholderEntityId(targeting),
        okResultFunc: (json: Json, state: RootState) => {
            return json;
        }
    });
}

export function reinitTargeting() {
    return makeRequestThunk("api/targeting/reinit", {
        method: METHOD_GET,
        urlPartFunc: function (state: RootState): string {
            return "/" + get(state, "campaign.entityId");
        },
        okResultFunc: (json: Json, state: RootState) => {
            return json;
        }
    });
}

type TargetingOptionQueryResult = {
    result: TargetingLocationValue[] | TargetingAffinityValue[];
} & ListQueryResult;

const receiveTargetingOptions = (
    json: TargetingOptionQueryResult
): AnyAction => {
    return receiveAsyncItems({
        items: json.result.map((item: any) => {
            return {
                label: item.displayName,
                value: item.value,
                item: item
            };
        }),
        totalCount: json.totalCount
    });
};

export function fetchTargetingOptions(
    search: string,
    campaignId: string,
    testPlatformId: string,
    targetingType: TargetingSelectItemType
) {
    let url;
    let body;
    if (targetingType === TARGETING_TYPE_LOCATION) {
        url = "api/targeting/query/location";
        body = {
            campaignId: campaignId,
            objectType: "TargetingLocationQuery",
            queryString: search,
            testPlatformId: testPlatformId
        };
    } else {
        url = "api/targeting/query/affinity";
        body = {
            objectType: "TargetingAffinityQuery",
            queryString: search,
            testPlatformId: testPlatformId
        };
    }
    return makeRequestThunk(url, {
        method: METHOD_POST,
        body: body,
        preRequestFunc: requestAsyncItems,
        okDispatchFunc: receiveTargetingOptions,
        showLoadMask: false
    });
}

export function writeTargeting(targeting: Targeting, campaignEntityId: string) {
    return makeRequestThunk("api/targeting/save", {
        method: METHOD_POST,
        urlPartFunc: function (state: RootState): string {
            return "/" + campaignEntityId;
        },
        body: targeting,
        isCancellable: false,
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const successMessage = {
                resourceKey: "targeting.edit_notification"
            };
            actions.push(resetCanNotLeave());
            // reset campaign so CampaignView calls fetchCampaign
            actions.push(resetCampaign());
            actions.push(
                campaignSectionPage(CAMPAIGN_TARGETING_PAGE, campaignEntityId)
            );
            actions.push(success(successMessage));
            return actions;
        }
    });
}

export const uploadPostalCodes = (
    file: File,
    targetingId: string,
    countryCode: string,
    exclude: boolean
) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("targetingId", targetingId);
    formData.append("countryCode", countryCode);
    formData.append("exclude", exclude.toString());
    return makeRequestThunk("api/targeting/upload/location", {
        method: METHOD_POST,
        urlPartFunc: function (state: RootState) {
            return "?requestSource=react";
        },
        bodyType: BODY_TYPE_FILE,
        body: formData,
        okResultFunc: (json: Json, state: RootState): any => {
            return json;
        },
        showLoadMask: true
    });
};

export const uploadPostalCodesConfirm = (
    targetingId: string,
    uploadId: string
) => {
    return makeRequestThunk(
        "api/targeting/upload/confirm/" + targetingId + "/" + uploadId,
        {
            method: METHOD_GET,
            okResultFunc: (json: Json, state: RootState): any => {
                return json;
            },
            showLoadMask: true
        }
    );
};

const getAudiencesRequest = (
    campaignId: string,
    tenantTestPlatformId: string,
    sortBy: string,
    srtOrder: string,
    startIndex: number,
    srch: string
) => {
    let sortOrder = srtOrder;
    if (sortOrder) {
        sortOrder = sortOrder.toUpperCase();
    }
    const sortConditions = [
        sortCondition(sortBy, sortOrder as SortOrderQueryApi)
    ];
    const search = srch;
    const filterSearchCondition = search
        ? freeFormFilterCondition(search, ["targeting.audience", "name"])
        : null;

    const campaignFilterCondition = notFilterCondition(
        stringFilterCondition("entityId", campaignId, "EQUALS")
    );

    const filterCondition = filterSearchCondition
        ? andFilterCondition([campaignFilterCondition, filterSearchCondition])
        : campaignFilterCondition;

    return {
        entity: "Campaign",
        filterCondition: filterCondition,
        maxResults: 100,
        model: "CampaignModel/audience",
        objectType: "AudienceListQuery",
        postSortCondition: null,
        propertySelection: null,
        search: search,
        sortConditions: sortConditions,
        startIndex: startIndex,
        tenantTestPlatform: tenantTestPlatformId
    };
};

export function fetchAudiences(
    campaignId: string,
    tenantTestPlatformId: string,
    sortBy: string,
    sortOrder: string,
    startIndex: number,
    search: string
) {
    return makeRequestThunk("api/campaign/query/audience", {
        method: METHOD_POST,
        body: getAudiencesRequest(
            campaignId,
            tenantTestPlatformId,
            sortBy,
            sortOrder,
            startIndex,
            search
        ),
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.result,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

export function fetchAudienceDetail(
    fromCampaignId: string,
    toCampaignId: string
) {
    return makeRequestThunk("api/targeting/copy", {
        method: METHOD_GET,
        urlPartFunc: function (state: RootState): string {
            return "/" + fromCampaignId + "/" + toCampaignId;
        },
        okResultFunc: (json: Json, state: RootState): any => {
            return json;
        },
        showLoadMask: false
    });
}
