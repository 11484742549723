/*
Pulled from react-virtualized-tree version 3.4.1
*/
import type {
    FlattenedTreeNode,
    TreeNode
} from "common/filter/reactVirtualizedTree/state/treeStateUtils";

const isNodeExpanded = (node: TreeNode): boolean =>
    node.state && node.state.expanded;
const nodeHasChildren = (node: TreeNode): boolean =>
    node.children && node.children.length > 0;

export const getFlattenedTree = (
    nodes: TreeNode[],
    parents: string[] = []
): FlattenedTreeNode[] =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore External code, Typing issue with TreeNode and previous value
    nodes.reduce((flattenedTree, node) => {
        const deepness = parents.length;
        const nodeWithHelpers = { ...node, deepness, parents };

        if (!nodeHasChildren(node) || !isNodeExpanded(node)) {
            return [...flattenedTree, nodeWithHelpers];
        }

        return [
            ...flattenedTree,
            nodeWithHelpers,
            ...getFlattenedTree(node.children, [...parents, node.id])
        ];
    }, []);

export const getFlattenedTreePaths = (
    nodes: TreeNode[],
    parents: string[] = []
): string[][] => {
    const paths = [];

    for (const node of nodes) {
        const { id } = node;

        if (!nodeHasChildren(node) || !isNodeExpanded(node)) {
            paths.push(parents.concat(id));
        } else {
            paths.push(parents.concat(id));
            paths.push(
                ...getFlattenedTreePaths(node.children, [...parents, id])
            );
        }
    }

    return paths;
};

/* const doesChangeAffectFlattenedTree = (
    previousNode: TreeNode,
    nextNode: TreeNode
): boolean => {
    return isNodeExpanded(previousNode) !== isNodeExpanded(nextNode);
}; */
