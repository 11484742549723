import * as React from "react";
import { DropdownSVGWrapper } from "common/components/Dropdown";
import SvgIcon from "common/components/SvgIcon";
import theme from "common/components/theme";
import { ReactComponent as DownCaretIcon } from "common/icons/DownCaret.svg";
import { ReactComponent as UpCaretIcon } from "common/icons/UpCaret.svg";

type FilterDropdownIconProps = {
    isOpen?: boolean;
    zIndex?: number;
};

const FilterDropdownIcon = ({ isOpen, zIndex }: FilterDropdownIconProps) => (
    <DropdownSVGWrapper>
        <SvgIcon
            color={theme.darkGreyText}
            height="8px"
            icon={isOpen ? UpCaretIcon : DownCaretIcon}
            marginLeft="5px"
            width="8px"
            zIndex={zIndex}
        />
    </DropdownSVGWrapper>
);

FilterDropdownIcon.displayName = "FilterDropdownIcon";

export default FilterDropdownIcon;
