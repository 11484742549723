import get from "lodash.get";
import type { AnyAction } from "redux";
import {
    RECEIVE_T_CONFIGURATION,
    RECEIVE_TENANT_TEST_PLATFORMS,
    REQUEST_T_CONFIGURATION,
    REQUEST_TENANT_TEST_PLATFORMS
} from "campaign/state/tenantActions";

type TenantState = {
    tenantConfiguration: any;
    tenantTestPlatforms: any[];
};

const defaultState: TenantState = {
    tenantConfiguration: undefined,
    tenantTestPlatforms: []
};

function tenant(state: TenantState = defaultState, action: AnyAction) {
    switch (action.type) {
        case RECEIVE_T_CONFIGURATION:
            return Object.assign({}, state, {
                tenantConfiguration: action.json
            });
        case REQUEST_T_CONFIGURATION:
            return state;
        case RECEIVE_TENANT_TEST_PLATFORMS:
            return Object.assign({}, state, {
                tenantTestPlatforms: get(action, "json.result")
            });
        case REQUEST_TENANT_TEST_PLATFORMS:
            return state;
        default:
            return state;
    }
}

export default tenant;
