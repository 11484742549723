import { getDefaultListState } from "common/util/query";
import {
    RECEIVE_TEAMS,
    REQUEST_TEAMS,
    UPDATE_SEARCH_TEAMS,
    UPDATE_START_INDEX_TEAMS,
    UPDATE_SORT_TEAMS
} from "common/administration/teams/state/teamsActions";
import type { TeamsActions } from "common/administration/teams/state/teamsActions";
import { TEAM_NAME } from "common/administration/teams/state/teamStateUtils";
import noAction from "common/util/noAction";

const teams = (
    state: QueryState = getDefaultListState(TEAM_NAME),
    action: TeamsActions = noAction
): QueryState => {
    switch (action.type) {
        case REQUEST_TEAMS:
            return state;
        case RECEIVE_TEAMS:
            return Object.assign({}, state, {
                totalCount: action.items.totalCount
            });
        case UPDATE_SEARCH_TEAMS:
            return Object.assign({}, state, { search: action.search });
        case UPDATE_SORT_TEAMS:
            return Object.assign({}, state, {
                sortBy: action.sortBy,
                sortOrder: action.sortOrder
            });
        case UPDATE_START_INDEX_TEAMS:
            return Object.assign({}, state, { startIndex: action.startIndex });
        default:
            return state;
    }
};

export default teams;
