import * as React from "react";
import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { marginPropTypes } from "common/components/styled/util";
import CheckboxTreeFilterDropdownView from "common/filter/components/CheckboxTreeFilterDropdownView";
import type { CommonCheckboxTreeFilterDropdownViewProps } from "common/filter/components/CheckboxTreeFilterDropdownView";
import { reduceArray, reduceObject } from "common/util/object";
import type { ProductFilter } from "shell/state/initReducers";
import { composeTrackingComponentLabel } from "common/util/tracking";

// Const for leaf node
export const PRODUCT_LEAF_NODE_IDENTIFIER = "AdminProductGroup";

// filterTextListAll is a boolean flag that swaps the filtertext to list all
type ProductFilterDropdownViewProps = {
    filterTextListAll?: boolean;
} & CommonCheckboxTreeFilterDropdownViewProps;

export const translateProductsToTreeNodes = (
    productsFilter: ProductFilter[]
): ChkTree[] => {
    const nodes: ChkTree[] = [];
    productsFilter.map(productFilter => {
        return nodes.push({
            children: reduceArray(
                productFilter.adminProductGroups,
                ["entityIdWithoutVersion", "value"],
                ["displayName", "label"],
                "objectType"
            ),
            label: productFilter.displayName,
            objectType: productFilter.objectType,
            value: productFilter.value
        });
    });
    return nodes;
};

export const getFilterText = (
    count: number,
    t: TFunction,
    selected: string[]
): string => {
    if (count > 0) {
        return t("general.ppgs_x", {
            count: count
        });
    }
    return t("general.all_ppgs");
};

const getFilterTextListAll = (
    count: number,
    t: TFunction,
    selected: string[]
): string => {
    if (count > 0) {
        return selected.join(", ");
    }
    return t("common:general.select_ppg");
};

const ProductFilterDropdownView = (props: ProductFilterDropdownViewProps) => {
    const {
        buttonWidth,
        filterTextListAll,
        highlightBackground,
        highlightSelected,
        icon,
        iconHeight,
        iconWidth,
        nodes,
        onFilterChange,
        selected,
        trackingComponentLabel
    } = props;
    const { t } = useTranslation();
    return (
        <CheckboxTreeFilterDropdownView
            buttonWidth={buttonWidth}
            filterHeaderText={t("general.ppg_plural")}
            getFilterText={
                filterTextListAll ? getFilterTextListAll : getFilterText
            }
            highlightBackground={highlightBackground}
            highlightSelected={highlightSelected}
            icon={icon}
            iconHeight={iconHeight}
            iconWidth={iconWidth}
            leafNodeObjectType={PRODUCT_LEAF_NODE_IDENTIFIER}
            maxHeight="280px"
            minWidth="300px"
            name="productsFilter"
            nodes={nodes}
            onFilterChange={onFilterChange}
            selected={selected}
            showSearch={true}
            trackingComponentLabel={composeTrackingComponentLabel([
                trackingComponentLabel,
                "Product Filter"
            ])}
            {...reduceObject(props, ...marginPropTypes)}
        />
    );
};

ProductFilterDropdownView.defaultProps = {
    filterTextListAll: false,
    nodes: [],
    selected: []
};

export default ProductFilterDropdownView;
