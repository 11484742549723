import styled from "styled-components/macro";
import Flexbox from "common/components/styled/Flexbox";
import type { FlexboxProps } from "common/components/styled/Flexbox";
import { borderRadius } from "common/components/styled/util";
import theme from "common/components/theme";

// Styling for line groups list
export const List = styled(Flexbox)`
    border: ${theme.border} 1px solid;
    border-radius: ${borderRadius("card")};
    flex-direction: column;
    width: ${props => (props.cssWidth ? props.cssWidth : "100%")};
    ${props => props.maxHeight && `max-height: ${props.maxHeight};`}
    ${props => props.maxHeight && `overflow-y: auto;`}
`;

type ListItemsProp = { padding?: string; selected?: boolean } & FlexboxProps;

export const ListItem = styled(Flexbox)<ListItemsProp>`
    ${props => props.selected && `background-color: ${theme.listSelectedBg};`}
    border-bottom: ${theme.appCardHover} 1px solid;
    justify-content: space-between;
    padding: ${props => (props.padding ? props.padding : "16px")};

    &:last-child {
        border-bottom: none;
    }
`;

export const ListItemSubText = styled.div`
    color: ${theme.subText};
    font-size: 14px;
`;
