import styled from "styled-components/macro";
import {
    borderProps,
    marginProps,
    paddingProps,
    sizeProps,
    textProps,
    textTransformProp
} from "common/components/styled/util";
import theme from "common/components/theme";

type HeaderProps = {
    fontSize?: string;
    pageHeader?: boolean;
    required?: boolean;
} & borderPropsType &
    marginPropsType &
    paddingPropsType &
    sizePropsType &
    textPropsType &
    textTransformPropType;

const Header = styled.div<HeaderProps>`
    :after {
        ${props => props.required && `content: ' *';`}
        color: ${theme.alert};
    }

    color: ${props => (props.color ? props.color : theme.greyText)};

    font-size: ${props =>
        props.fontSize ? props.fontSize : props.pageHeader ? "24px" : "20px"};
    font-family: ${props =>
        props.fontFamily ? props.fontFamily : '"BrandonTextBold"'};
    ${props => props.pageHeader && "margin-top: 20px;"}
    ${borderProps}
    ${marginProps}
    ${paddingProps}
    ${sizeProps}
    ${textProps}
    ${textTransformProp}
`;
Header.displayName = "Header";

export default Header;
