import type { AnyAction } from "redux";
import auth from "common/auth/state/authReducers";
import { LOGGED_OUT } from "common/auth/state/commonAuthActions";
import error from "common/shell/state/errorReducers";
import loadmask from "common/shell/state/loadmaskReducers";
import { OPEN_MESSAGE_BOX } from "common/shell/state/messageBoxActions";
import messageBox from "common/shell/state/messageBoxReducers";
import navigation from "common/shell/state/navigationReducers";
import preferences from "common/shell/state/preferencesReducers";
import raven from "common/shell/state/ravenReducers";
import sessionPreferences from "common/shell/state/sessionPreferencesReducers";
import toasts from "common/shell/state/toastReducers";
import { emptyFn } from "common/util/lang";
import { reduceObject } from "common/util/object";
import type { RootState } from "store";

type NonSerializableItems = {
    messageBoxCallbackFunction: AnyFunction;
};

export const nonSerializableItems: NonSerializableItems = {
    messageBoxCallbackFunction: emptyFn
};

export const commonReducers = {
    auth,
    loadmask,
    raven,
    toasts,
    navigation,
    messageBox,
    error,
    preferences,
    sessionPreferences
};

export const preprocessReducers = (
    state: RootState,
    action: AnyAction
): RootState => {
    let newState = state;
    switch (action.type) {
        case LOGGED_OUT:
            // Clear out the state except for the raven configuration, which only depends on the server.
            newState = reduceObject(state, "raven");
            break;
        case OPEN_MESSAGE_BOX:
            if (action.callbackFunction) {
                // Store the non-serializable message box callback function
                nonSerializableItems.messageBoxCallbackFunction =
                    action.callbackFunction;
            } else {
                // Reset the non-serializable message box callback function
                nonSerializableItems.messageBoxCallbackFunction = emptyFn;
            }
            delete action.callbackFunction;
            break;
        default:
            break;
    }
    return newState;
};
