import * as React from "react";
import type { GetDerivedStateFromProps } from "react";
import type { TFunction } from "i18next";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import xor from "lodash.xor";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { CheckboxGroupWrapper } from "common/components/CheckboxGroup";
import CheckboxTree from "common/components/CheckboxTree";
import type { HorizontalPosition } from "common/components/Dropdown";
import { marginPropTypes } from "common/components/styled/util";
import FilterDropdownView from "common/filter/components/FilterDropdownView";
import {
    expandAllParentNodes,
    expandSelectedNodes,
    flattenChkTreeNodes,
    processSelectedItemsText
} from "common/util/filter";
import { reduceArray, reduceObject } from "common/util/object";
import type { TrackingComponentLabel } from "common/util/trackingEvents";

//for use with components built off of the CommonCheckboxTreeFilterDropdownView
export type CommonCheckboxTreeFilterDropdownViewProps = {
    buttonWidth?: string;
    customSelectedText?: (checked: string[], t: TFunction) => any[];
    disabled?: boolean;
    hasSelectedTextTree?: boolean;
    highlightBackground?: boolean;
    highlightSelected?: boolean;
    horizontalPosition?: HorizontalPosition;
    icon?: IconType;
    iconHeight?: number;
    iconWidth?: number;
    maxWidth?: string;
    minWidth?: string;
    nodes: ChkTree[];
    onFilterChange: (checked: string[], label: string) => void;
    selected: string[];
    textColor?: string;
    trackingComponentLabel: TrackingComponentLabel;
} & marginPropsType;

type CheckboxTreeFilterDropdownViewOwnProps = {
    filterHeaderText: string;
    filterSubHeaderText?: string;
    getFilterText: (count: number, t: TFunction, selected: string[]) => string;
    leafNodeObjectType: string; //This is how we identify a leaf node. the tree is made up of groups and leaves.
    name: string;
    showSearch: boolean;
} & CommonCheckboxTreeFilterDropdownViewProps;

type CheckboxTreeFilterDropdownViewProps =
    CheckboxTreeFilterDropdownViewOwnProps & WithTranslation;

type CheckboxTreeFilterDropdownViewState = {
    checked: string[];
    expanded: string[];
    nodes: ChkTree[];
    prevPropsSelected: string[];
};

type ChkTreeable = {
    [childFieldName: string]: ChkTreeable[];
} & {
    displayName: string;
    entityIdWithoutVersion: string;
    objectType: string;
};

export const translateToTreeNodes = (
    nodeables: ChkTreeable[],
    childFieldName: string
): ChkTree[] => {
    const nodes = nodeables.map(node => {
        return {
            children: reduceArray(
                node[childFieldName],
                ["entityIdWithoutVersion", "value"],
                ["displayName", "label"],
                "objectType"
            ),
            label: node.displayName,
            objectType: node.objectType,
            value: node.entityIdWithoutVersion
        };
    });
    return nodes;
};

class CheckboxTreeFilterDropdownView extends React.Component<
    CheckboxTreeFilterDropdownViewProps,
    CheckboxTreeFilterDropdownViewState
> {
    flattenedNodes: any = {};

    static defaultProps: { nodes: ChkTree[]; selected: string[] } = {
        nodes: [],
        selected: []
    };

    constructor(props: CheckboxTreeFilterDropdownViewProps) {
        super(props);

        const expanded = expandSelectedNodes(props.nodes, props.selected);
        flattenChkTreeNodes(props.nodes, this.flattenedNodes);
        this.state = {
            checked: props.selected,
            expanded: expanded,
            nodes: props.nodes,
            prevPropsSelected: props.selected
        };
    }

    componentDidUpdate(prevProps: CheckboxTreeFilterDropdownViewProps) {
        const { nodes } = this.props;
        // If nodes are updated, update flattendedNodes and node state
        if (!isEqual(prevProps.nodes, nodes)) {
            flattenChkTreeNodes(nodes, this.flattenedNodes);
            this.setState({ nodes: nodes });
        }
    }

    static getDerivedStateFromProps: GetDerivedStateFromProps<
        CheckboxTreeFilterDropdownViewProps,
        CheckboxTreeFilterDropdownViewState
    > = (props, state) => {
        // Any time the selected (array) prop changes reset any parts of state that is tied to that prop
        if (!isEqual(state.prevPropsSelected, props.selected)) {
            return {
                checked: props.selected,
                expanded: expandSelectedNodes(state.nodes, props.selected),
                prevPropsSelected: props.selected
            };
        }
        return null;
    };

    onCheck = (checked: string[]) => {
        this.setState({ checked: checked });
    };

    onExpand = (expanded: string[]) => {
        this.setState({ expanded: expanded });
    };

    onFilterClear = () => {
        this.setState({ checked: [] });
    };

    onFilterClose = (applyFilter: boolean) => {
        const { onFilterChange, selected, nodes, t } = this.props;
        const { checked } = this.state;
        const selectedDiff = xor(checked, selected);
        if (applyFilter && selectedDiff.length > 0) {
            const selectedLabels = this.getSelectedLabelText(checked);
            const label = selectedLabels.join(
                " " + t("common:general.or") + " "
            );
            onFilterChange(checked, label);
        } else {
            this.setSelectedToProps();
        }
        this.setState({
            // reset nodes and expanded to remove potential filterSearch
            nodes: nodes,
            expanded: expandSelectedNodes(nodes, checked)
        });
    };

    onFilterOpen = () => {
        this.setSelectedToProps();
    };

    onFilterSearch = (searchStr: string) => {
        const { nodes } = this.props;
        const { checked } = this.state;
        const filteredNodes = this.filterNodes(nodes, searchStr);
        const expanded =
            searchStr.length > 0
                ? expandAllParentNodes(filteredNodes)
                : expandSelectedNodes(nodes, checked);

        this.setState({
            nodes: filteredNodes,
            expanded: expanded
        });
    };

    getSelectedLabelText = (checked: string[]): string[] => {
        return checked
            .map(item => {
                return get(this.flattenedNodes, [item, "label"]);
            })
            .filter(Boolean);
    };

    getSelectedText = (checked: string[], t: TFunction): string[] => {
        const selectedText = this.getSelectedLabelText(checked);
        selectedText.sort();
        return processSelectedItemsText(selectedText, t);
    };

    filterNodes = (nodes: ChkTree[], searchText: string): ChkTree[] => {
        return JSON.parse(JSON.stringify(nodes)).filter(function iter(
            node: ChkTree
        ) {
            if (node.label.toLowerCase().includes(searchText.toLowerCase())) {
                return true;
            }
            if (!Array.isArray(node.children)) {
                return false;
            }
            const temp = node.children.filter(iter);
            if (temp.length) {
                node.children = temp;
                return true;
            }
            return false;
        });
    };

    setSelectedToProps = () => {
        this.setState({
            checked: this.props.selected
        });
    };

    render() {
        const {
            buttonWidth,
            customSelectedText,
            disabled,
            filterHeaderText,
            filterSubHeaderText,
            getFilterText,
            hasSelectedTextTree,
            highlightBackground,
            highlightSelected,
            horizontalPosition,
            icon,
            iconHeight,
            iconWidth,
            leafNodeObjectType,
            name,
            maxWidth,
            minWidth,
            selected,
            showSearch,
            t,
            textColor,
            trackingComponentLabel
        } = this.props;
        const { checked, expanded, nodes } = this.state;
        const filterText = getFilterText(
            selected.length,
            t,
            this.getSelectedLabelText(selected)
        );
        const selectedText = customSelectedText
            ? customSelectedText(checked, t)
            : this.getSelectedText(checked, t);
        // Object.values fails flow
        const leafNodes = Object.keys(this.flattenedNodes).map(key => {
            return this.flattenedNodes[key];
        });
        //why not count nodes where we don't have children? we could have a group with zero children.
        const leaves = leafNodes.filter(node => {
            return node.objectType === leafNodeObjectType;
        });
        if (leaves.length > 1) {
            return (
                <FilterDropdownView
                    buttonWidth={buttonWidth}
                    clearHandler={this.onFilterClear}
                    closeHandler={this.onFilterClose}
                    disabled={disabled}
                    filterHeaderText={filterHeaderText}
                    filterSubHeaderText={filterSubHeaderText}
                    hasSelectedTextTree={hasSelectedTextTree}
                    highlightBackground={highlightBackground}
                    highlightSelected={highlightSelected}
                    horizontalPosition={horizontalPosition}
                    icon={icon}
                    iconHeight={iconHeight}
                    iconWidth={iconWidth}
                    maxHeight="280px"
                    maxWidth={maxWidth ? maxWidth : "300px"}
                    minWidth={minWidth ? minWidth : "300px"}
                    name={name}
                    openHandler={this.onFilterOpen}
                    searchHandler={this.onFilterSearch}
                    selected={checked}
                    selectedText={selectedText}
                    showSearch={showSearch}
                    text={filterText}
                    textColor={textColor}
                    trackingComponentLabel={trackingComponentLabel}
                    {...reduceObject(this.props, ...marginPropTypes)}
                >
                    <CheckboxGroupWrapper>
                        <CheckboxTree
                            checked={checked}
                            expanded={expanded}
                            nodes={nodes}
                            onCheck={this.onCheck}
                            onExpand={this.onExpand}
                        />
                    </CheckboxGroupWrapper>
                </FilterDropdownView>
            );
        } else {
            return null;
        }
    }
}

export default withTranslation()(CheckboxTreeFilterDropdownView);
