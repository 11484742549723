import styled from "styled-components/macro";
import { marginProps } from "common/components/styled/util";
import theme from "common/components/theme";

const Toolbar = styled.div<marginPropsType & Pick<sizePropsType, "cssHeight">>`
    background-color: ${theme.background};
    height: ${props => (props.cssHeight ? props.cssHeight : "60px")};
    min-height: 60px;
    border-bottom: 1px solid ${theme.border};
    ${marginProps}
`;
Toolbar.displayName = "Toolbar";

export default Toolbar;
