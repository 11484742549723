import * as React from "react";
import { getTrackingEventData, trackEvent } from "common/util/tracking";
import type {
    TrackingComponentLabel,
    TrackingEventName
} from "common/util/trackingEvents";

type WithChangeTrackingProps = {
    children?: React.ReactNode | React.ReactNode[];
    onChange: ChangeHandler;
    trackingComponentLabel: TrackingComponentLabel;
    trackingEventName?: TrackingEventName;
    trackEventValue?: boolean;
};

export function withChangeTracking<Props>(
    WrappedComponent: ReactComponentCreator<Props & WithChangeTrackingProps>,
    trackingComponentType: string,
    trackingEventName: TrackingEventName = ""
): React.ComponentType<Props & WithChangeTrackingProps> {
    const WithChangeTracking = (props: Props & WithChangeTrackingProps) => {
        let eventName = props.trackingEventName;
        if (!eventName) {
            eventName = trackingEventName;
        }
        const trackEventValue = props.trackEventValue
            ? props.trackEventValue
            : false;
        const trackingComponentLabel = props.trackingComponentLabel;
        let wrappedProps = { ...props };
        if (props.onChange) {
            wrappedProps = Object.assign({}, props, {
                onChange: function (selected?: OptionType) {
                    if (eventName) {
                        let eventValue = "";
                        if (selected && trackEventValue) {
                            if (Array.isArray(selected)) {
                                eventValue = selected
                                    .map((sel: OptionType) => sel.value)
                                    .join(" | ");
                            } else {
                                eventValue = selected.value;
                            }
                        }
                        trackEvent(
                            getTrackingEventData(
                                trackingComponentType,
                                trackingComponentLabel,
                                eventName,
                                eventValue
                            )
                        );
                    }
                    if (props.onChange) {
                        props.onChange(selected);
                    }
                }
            });
        }
        return <WrappedComponent {...wrappedProps} />;
    };
    WithChangeTracking.displayName =
        "WithChangeTracking(" + trackingComponentType + ")";
    return WithChangeTracking;
}
