import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import DialogPage, {
    BUTTON_WIDTH,
    INPUT_WIDTH,
    STD_WIDTH
} from "common/components/styled/DialogPage";
import Error from "common/components/styled/Error";
import Header from "common/components/styled/Header";
import theme from "common/components/theme";

import logo from "common/images/eversight_logo_green_with_text_header.png";

type AuthDialogPageProps = {
    children: React.ReactNode;
    cssWidth?: string;
    suppressLogo?: boolean;
    title?: React.ReactNode;
};

export const AUTH_BUTTON_WIDTH = BUTTON_WIDTH;
export const AUTH_INPUT_WIDTH = INPUT_WIDTH;
export const AUTH_WIDTH = STD_WIDTH;
export const DOUBLE_COLUMN_AUTH_WIDTH = "664px";

type AuthErrorType = {
    error: string | undefined | null;
};

export const AuthError = ({ error }: AuthErrorType) => {
    if (error) {
        return (
            <Error margin="24px auto 0px auto" cssWidth={AUTH_WIDTH}>
                {error}
            </Error>
        );
    }
    return null;
};

AuthError.displayName = "AuthError";

export const AuthLeftColumn = styled.div`
    border-right: 1px solid ${theme.border};
    padding-right: 32px;
    width: ${STD_WIDTH};
`;
AuthLeftColumn.displayName = "AuthLeftColumn";

export const AuthInstructions = styled.div`
    margin-bottom: 24px;
`;
AuthInstructions.displayName = "AuthInstructions";

export const AuthRightColumn = styled.div`
    padding-left: 32px;
    width: ${STD_WIDTH};
`;
AuthRightColumn.displayName = "AuthRightColumn";

export const AuthSubText = styled.div`
    font-size: 12px;
    margin-top: 6px;
`;
AuthSubText.displayName = "AuthSubText";

const Logo = styled.img`
    margin-bottom: 24px;
`;
Logo.displayName = "Logo";

const AuthDialogPage = (props: AuthDialogPageProps) => {
    const { children, cssWidth, suppressLogo, title } = props;
    const { t } = useTranslation();
    return (
        <DialogPage cssWidth={cssWidth} padding="32px">
            <form onSubmit={e => e.preventDefault()}>
                {!suppressLogo && (
                    <Logo
                        alt={t("common:general.eversight_logo")}
                        title={t("common:general.eversight_logo")}
                        src={logo}
                    />
                )}
                {title && (
                    <Header fontSize={"26px"} marginBottom="24px">
                        {title}
                    </Header>
                )}
                {children}
            </form>
        </DialogPage>
    );
};
AuthDialogPage.displayName = "AuthDialogPage";

AuthDialogPage.defaultProps = {
    cssWidth: AUTH_WIDTH
};

export default AuthDialogPage;
