import get from "lodash.get";
import type { AnyAction } from "redux";
import { AGGREGATE_OFFER_LABEL } from "aggregation/aggregationUtils";
import noAction from "common/util/noAction";
import { reduceObject } from "common/util/object";
import { ASCENDING, DEFAULT_PAGE_SIZE } from "common/util/query";

import { reduceEvents } from "event/state/eventsStateUtils";
import {
    CLEAR_CREATE_TEST_INFO,
    CLOSE_CREATE_TEST,
    CLOSE_REQUEST_TEST,
    CREATE_TEST_SUCCESS,
    OPEN_REQUEST_TEST,
    RECEIVE_CREATE_TEST_INFO,
    RECEIVE_DRAWER_CONTROL_OFFERS,
    RECEIVE_REQUEST_TEST_MESSAGE,
    REQUEST_DRAWER_CONTROL_OFFERS,
    REQUEST_TEST_SUCCESS,
    UPDATE_CREATE_TEST_DRAWER_SORT
} from "offers/state/createTestActions";
import type {
    CreateTestDrawer,
    CreateTestModalInfo,
    CreateTestRequestModel
} from "offers/state/createTestStateUtils";
import { CREATE_TEST_PAGE } from "shell/state/pageActions";

export const defaultDrawer: CreateTestDrawer = {
    items: [],
    pageSize: DEFAULT_PAGE_SIZE,
    sortBy: AGGREGATE_OFFER_LABEL,
    sortOrder: ASCENDING,
    totalCount: -1
};

export const defaultRequest: CreateTestRequestModel = {
    controlOffers: [],
    campaignName: "",
    holidayId: "",
    pageId: "",
    pages: [],
    platformId: "",
    platforms: [],
    ppgIds: [],
    regionIds: [],
    retailerIds: []
};

export const defaultState: CreateTestModalInfo = {
    drawer: defaultDrawer,
    eventId: "",
    isCreateOpen: false,
    isRequestOpen: false,
    offer: null,
    request: defaultRequest,
    requestMessage: "",
    success: null
};

const offerCreate = (
    state: CreateTestModalInfo = defaultState,
    action: AnyAction = noAction
) => {
    switch (action.type) {
        case CLEAR_CREATE_TEST_INFO:
            return defaultState;
        case CLOSE_CREATE_TEST:
            return defaultState;
        case CLOSE_REQUEST_TEST:
            return Object.assign({}, state, {
                ...state,
                isRequestOpen: false,
                requestMessage: ""
            });
        case CREATE_TEST_PAGE: {
            const eventId = get(action, "payload.eventId");
            return Object.assign({}, state, {
                ...state,
                eventId: eventId,
                isCreateOpen: false,
                isRequestOpen: false,
                request: eventId ? defaultRequest : state.request
            });
        }
        case CREATE_TEST_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                isCreateOpen: true,
                success: action.campaignEntityId
            });
        case OPEN_REQUEST_TEST:
            return Object.assign({}, state, {
                ...state,
                isRequestOpen: true
            });
        case RECEIVE_CREATE_TEST_INFO: {
            const request = action["request"];
            return Object.assign({}, state, {
                ...state,
                request: {
                    ...reduceObject(
                        request,
                        "campaignName",
                        "holidayId",
                        "pageId",
                        "pages",
                        "platformId",
                        "platforms",
                        "ppgIds",
                        "regionIds",
                        "retailerIds"
                    ),
                    controlOffers: request.controlOffers.map((offer: any) => {
                        return reduceObject(
                            offer,
                            "eventId",
                            "fieldSet",
                            "image",
                            "objectType",
                            "offerIdentifier",
                            "offerLabel",
                            "runInMarket",
                            "tooltipText"
                        );
                    })
                }
            });
        }
        case RECEIVE_DRAWER_CONTROL_OFFERS: {
            const reducedEvents = reduceEvents(action.eventOffers);
            return Object.assign({}, state, {
                drawer: {
                    pageSize: reducedEvents.pageSize,
                    sortBy: get(state, "drawer.sortBy"),
                    sortOrder: get(state, "drawer.sortOrder"),
                    totalCount: reducedEvents.totalCount
                }
            });
        }
        case RECEIVE_REQUEST_TEST_MESSAGE:
            return Object.assign({}, state, {
                requestMessage: action.message
            });
        case REQUEST_DRAWER_CONTROL_OFFERS:
            return state;
        case REQUEST_TEST_SUCCESS:
            return defaultState;
        case UPDATE_CREATE_TEST_DRAWER_SORT:
            return Object.assign({}, state, {
                drawer: {
                    ...state.drawer,
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder
                }
            });
        default:
            return state;
    }
};

export default offerCreate;
