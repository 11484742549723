import type { Action } from "redux";
import i18n from "common/i18n";
import {
    openMessageBox,
    BUTTON_OK,
    ICON_WARNING
} from "common/shell/state/messageBoxActions";
import type { OpenMessageBox } from "common/shell/state/messageBoxActions";
import { makeRequestThunk } from "common/shell/state/requestActions";
import { version } from "generated/version.js";
import { INIT_URL } from "shell/state/initActions";
import type { InitMetaDataResponse } from "shell/state/initReducers";

// action types
export const INITIALIZATION_ERROR = "INITIALIZATION_ERROR";
export const RECEIVE_INIT = "RECEIVE_INIT";
export const RECEIVE_USERS_REINIT = "RECEIVE_USERS_REINIT";
export const REQUEST_INIT = "REQUEST_INIT";

export interface InitializationError extends Action {
    type: typeof INITIALIZATION_ERROR;
}

export function initializationError(error: string): InitializationError {
    return {
        type: INITIALIZATION_ERROR
    };
}

export interface RequestInit extends Action {
    type: typeof REQUEST_INIT;
}

export function requestInit(): RequestInit {
    return {
        type: REQUEST_INIT
    };
}

export function refreshWindow() {
    window.location.reload();
}

interface ReceiveInitAction extends Action {
    type: typeof RECEIVE_INIT;
    init: InitMetaDataResponse;
}

export type ReceiveInit = OpenMessageBox | ReceiveInitAction;

export function receiveInit(json: InitMetaDataResponse): ReceiveInit {
    if (json.versionString !== version) {
        return openMessageBox({
            buttons: BUTTON_OK,
            buttonText: {
                BUTTON_OK: i18n.t("common:general.refresh")
            },
            callbackFunction: refreshWindow,
            message: {
                resourceKey: "common:general.refresh_application"
            },
            icon: ICON_WARNING,
            showCloseIcon: false,
            title: i18n.t("common:general.application_refresh_needed")
        });
    }
    return {
        type: RECEIVE_INIT,
        init: json
    };
}

export function fetchInit() {
    return makeRequestThunk(INIT_URL, {
        errorFunc: initializationError,
        isCancellable: false,
        okDispatchFunc: receiveInit,
        preRequestFunc: requestInit
    });
}

export interface ReceiveUsersReinit extends Action {
    type: typeof RECEIVE_USERS_REINIT;
    init: InitMetaDataResponse;
}

export const receiveUsersReinit = (
    json: InitMetaDataResponse
): ReceiveUsersReinit => {
    return {
        type: RECEIVE_USERS_REINIT,
        init: json
    };
};

export function fetchUsersReinit() {
    return makeRequestThunk(INIT_URL, {
        isCancellable: false,
        okDispatchFunc: receiveUsersReinit,
        showLoadMask: false
    });
}
