import * as React from "react";
import { memo } from "react";
import type { ICellRendererParams } from "ag-grid-community";
import get from "lodash.get";
import type { ParseKeys, TFunction } from "i18next";
import styled from "styled-components/macro";
import { POSITION_CENTER } from "common/components/Dropdown";
import type { MenuSubOptionType } from "common/components/ExpandingDropdown";
import Flexbox from "common/components/styled/Flexbox";
import { CustomSelect, SELECT_WIDTH } from "common/components/styled/Form";
import CheckboxFilterDropdownView from "common/filter/components/CheckboxFilterDropdownView";
import DateFilterDropdown from "common/filter/components/DateFilterDropdown";
import chartColumn48 from "common/images/chart-column-48.png";
import chartDataTable48 from "common/images/chart-data-table-48.png";
import chartLine48 from "common/images/chart-line-48.png";
import chartStackedBar48 from "common/images/chart-stacked-bar-48.png";
import {
    COLUMN_DISPLAY_TYPE,
    DATA_TABLE_DISPLAY_TYPE,
    genericSummarize,
    NO_REPORT,
    reportDateNodes,
    STACKED_BAR_DISPLAY_TYPE,
    TREND_DISPLAY_TYPE,
    summarizeList
} from "common/reports/components/reportsViewUtils";
import type { ReportDef } from "common/shell/state/initStateUtils";
import { CUSTOM } from "common/util/date";
import type { DateFilter } from "common/util/date";
import { date2Str, dateRange2Str } from "common/util/format";
import { MATRIX } from "common/util/url";
import HolidayFilterDropdownView from "filter/components/HolidayFilterDropdownView";
import ProductFilterDropdownView, {
    translateProductsToTreeNodes
} from "filter/components/ProductFilterDropdownView";
import {
    CONSUMER,
    CONSUMER_CUSTOM,
    CONSUMER_PRODUCT_TRIAL,
    CONSUMER_PURCHASE_QUANTITIES
} from "reports/consumer/state/consumerStateUtils";
import {
    PURCHASE_CYCLE,
    PURCHASE_CYCLE_AND_PROMOTIONS
} from "reports/purchaseCycle/state/purchaseCycleStateUtils";
import type { Metric } from "reports/state/metrics";
import type { ReportType, ReportView } from "reports/state/reportsStateUtils";
import { tableFilterMap } from "reports/state/reportsStateUtils";
import {
    SUBSTITUTION,
    SUBSTITUTION_COMPARISON,
    SUBSTITUTION_SUMMARY
} from "reports/substitution/state/substitutionStateUtils";
import {
    TAKE_RATE,
    TAKE_RATE_PURCHASE_DISTRIBUTION,
    TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON
} from "reports/takeRate/state/takeRateStateUtils";
import type { ProductFilter } from "shell/state/initReducers";

export const ReportTypeImageRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        if (colDef && data) {
            const reportDisplayType = get(data, "displayType.value");

            let icon = null;
            switch (reportDisplayType) {
                case COLUMN_DISPLAY_TYPE:
                    icon = chartColumn48;
                    break;
                case DATA_TABLE_DISPLAY_TYPE:
                    icon = chartDataTable48;
                    break;
                case STACKED_BAR_DISPLAY_TYPE:
                    icon = chartStackedBar48;
                    break;
                case TREND_DISPLAY_TYPE:
                    icon = chartLine48;
                    break;
            }
            if (icon) {
                return (
                    <img
                        src={icon}
                        height="38px"
                        width="38px"
                        alt="Report Type Icon"
                    />
                );
            }
        }
        return null;
    }
);

const SUMMARY_LIMIT = 3;

export const ComparisonMetricWrapper = styled(Flexbox)`
    flex-direction: column;
    flex-grow: 1;
    font-variant: common-ligatures tabular-nums;
    line-height: 1.4;
    margin: auto 0;
    min-width: 0;
    text-align: right;
`;
ComparisonMetricWrapper.displayName = "ComparisonMetricWrapper";

export const getReportView = (
    reportTableFilterDefinitions: ReportDef[],
    reportView: ReportView
): ReportDef => {
    const view = tableFilterMap[reportView];
    const tableFilter = reportTableFilterDefinitions.find(
        tableFilter => tableFilter.value === view
    );
    return {
        group: tableFilter ? tableFilter.group : "",
        groupLabel: tableFilter ? tableFilter.groupLabel : "",
        label: tableFilter ? tableFilter.label : "",
        subLabel: tableFilter ? tableFilter.subLabel : "",
        value: tableFilter ? reportView : NO_REPORT,
        supportsStaticDates: tableFilter
            ? tableFilter.supportsStaticDates
            : false
    } as ReportDef;
};

export const getReportViews = (
    reportType: ReportType,
    reportTableFilterDefinitions: any[]
): MenuSubOptionType[] => {
    let reportViews = [];
    switch (reportType) {
        case CONSUMER:
            reportViews = [
                getReportView(
                    reportTableFilterDefinitions,
                    CONSUMER_PRODUCT_TRIAL
                ),
                getReportView(
                    reportTableFilterDefinitions,
                    CONSUMER_PURCHASE_QUANTITIES
                ),
                getReportView(reportTableFilterDefinitions, CONSUMER_CUSTOM)
            ];
            break;
        case TAKE_RATE:
            reportViews = [
                getReportView(
                    reportTableFilterDefinitions,
                    TAKE_RATE_PURCHASE_DISTRIBUTION
                ),
                getReportView(
                    reportTableFilterDefinitions,
                    TAKE_RATE_PURCHASE_DISTRIBUTION_COMPARISON
                )
            ];
            break;
        case PURCHASE_CYCLE:
            reportViews = [
                getReportView(
                    reportTableFilterDefinitions,
                    PURCHASE_CYCLE_AND_PROMOTIONS
                )
            ];
            break;
        case SUBSTITUTION:
            reportViews = [
                getReportView(
                    reportTableFilterDefinitions,
                    SUBSTITUTION_SUMMARY
                ),
                getReportView(
                    reportTableFilterDefinitions,
                    SUBSTITUTION_COMPARISON
                )
            ];
            break;
    }
    return reportViews.filter(report => report.value !== NO_REPORT);
};

export const getBrandFilterControl = (
    highlight: boolean,
    nodes: any[],
    onChange: (checked: string[]) => void,
    selected: string[],
    trackingLabels: string[],
    width: string = SELECT_WIDTH
): React.ReactNode => {
    return (
        <CheckboxFilterDropdownView
            allLabelKey="reports.all_brands"
            buttonWidth={width}
            countLabelKey="reports.brand_x"
            filterHeaderTextKey="common:general.brand_plural"
            highlightSelected={highlight}
            labelField="displayName"
            name="brandFilter"
            nodes={nodes}
            onFilterChange={onChange}
            selected={selected}
            trackingComponentLabel={trackingLabels}
            valueField="displayName"
        />
    );
};

export const getCategoryFilterControl = (
    highlight: boolean,
    nodes: any[],
    onChange: (checked: string[]) => void,
    selected: string[],
    trackingLabels: string[],
    width: string = SELECT_WIDTH
): React.ReactNode => {
    return (
        <CheckboxFilterDropdownView
            allLabelKey="common:general.all_categories"
            buttonWidth={width}
            countLabelKey="reports.category_x"
            filterHeaderTextKey="general.category_plural"
            highlightSelected={highlight}
            labelField="displayName"
            name="categoryFilter"
            nodes={nodes}
            onFilterChange={onChange}
            selected={selected}
            trackingComponentLabel={trackingLabels}
            valueField="displayName"
        />
    );
};

export const getChannelFilterControl = (
    highlight: boolean,
    nodes: any[],
    onChange: (checked: string[]) => void,
    selected: string[],
    trackingLabels: string[],
    width: string = SELECT_WIDTH
): React.ReactNode => {
    return (
        <CheckboxFilterDropdownView
            allLabelKey="general.all_channels"
            buttonWidth={width}
            countLabelKey="general.channel_x"
            filterHeaderTextKey="general.channel_plural"
            highlightSelected={highlight}
            name="channelFilter"
            nodes={nodes}
            onFilterChange={onChange}
            selected={selected}
            trackingComponentLabel={trackingLabels}
        />
    );
};

export const getDateFilterControl = (
    highlight: boolean,
    label: string,
    onChange: (date: DateFilter) => void,
    selected: DateFilter | undefined | null,
    trackingLabels: string[],
    customMenuItems: DateMenuItemType[] = []
): React.ReactNode => {
    const menuItems =
        customMenuItems.length > 0 ? customMenuItems : reportDateNodes(true);
    return (
        <DateFilterDropdown
            app={MATRIX}
            buttonWidth={SELECT_WIDTH}
            customMenuItems={menuItems}
            highlightTrigger={highlight}
            horizontalPosition={POSITION_CENTER}
            menuHeaderText={label}
            menuType={CUSTOM}
            onDateFilterChange={onChange}
            selected={selected}
            trackingComponentLabel={trackingLabels}
        />
    );
};

export const getHolidayFilterControl = (
    highlight: boolean,
    nodes: any[],
    onChange: (checked: string[]) => void,
    selected: string[],
    trackingLabels: string[],
    width: string = SELECT_WIDTH
): React.ReactNode => {
    return (
        <HolidayFilterDropdownView
            buttonWidth={width}
            highlightSelected={highlight}
            holidaysSelected={selected}
            holidayNodes={nodes}
            onHolidayFilterChange={onChange}
            trackingComponentLabel={trackingLabels}
        />
    );
};

export const getOfferTypeFilterControl = (
    highlight: boolean,
    nodes: any[],
    onChange: (checked: string[]) => void,
    selected: string[],
    trackingLabels: string[],
    width: string = SELECT_WIDTH
): React.ReactNode => {
    return (
        <CheckboxFilterDropdownView
            allLabelKey="general.all_offer_types"
            buttonWidth={width}
            countLabelKey="general.offer_type_x"
            filterHeaderTextKey="general.offer_type_plural"
            highlightSelected={highlight}
            name="offerTypeFilter"
            nodes={nodes}
            onFilterChange={onChange}
            selected={selected}
            trackingComponentLabel={trackingLabels}
            valueField="value"
        />
    );
};

export const getPpgFilterControl = (
    highlight: boolean,
    nodes: any[],
    onChange: (checked: string[]) => void,
    selected: string[],
    trackingLabels: string[],
    width: string = SELECT_WIDTH
): React.ReactNode => {
    return (
        <ProductFilterDropdownView
            buttonWidth={width}
            highlightSelected={highlight}
            nodes={translateProductsToTreeNodes(nodes)}
            onFilterChange={onChange}
            selected={selected}
            trackingComponentLabel={trackingLabels}
        />
    );
};

export const buildPromotionQuantityNodes = (
    maxPromotionQuantity: number,
    t: TFunction
) => {
    const nodes = [
        {
            label: t("reports.non_promoted"),
            value: "0"
        }
    ];
    for (let x = 1; x <= maxPromotionQuantity; x++) {
        const value = x.toString();
        nodes.push({
            label: value,
            value: value
        });
    }
    return nodes;
};

export const getPromotionQuantityFilterControl = (
    highlight: boolean,
    maxPromotionQuantity: number,
    onChange: (checked: string[]) => void,
    selected: string[],
    trackingLabels: string[],
    t: TFunction,
    width: string = SELECT_WIDTH
): React.ReactNode => {
    const nodes = buildPromotionQuantityNodes(maxPromotionQuantity, t);
    return (
        <CheckboxFilterDropdownView
            allLabelKey="reports.all_promo_quantities_plus_non_promoted"
            buttonWidth={width}
            countLabelKey="reports.promotion_quantity_x"
            filterHeaderTextKey="reports.promotion_quantity_plural"
            highlightSelected={highlight}
            labelField="label"
            name="promotionQuantityFilter"
            nodes={nodes}
            onFilterChange={onChange}
            selected={selected}
            trackingComponentLabel={trackingLabels}
            valueField="value"
        />
    );
};

const upcFilter = (option: any, searchText: string) => {
    const label = option.label ? option.label : "";
    const value = option.value ? option.value : "";
    if (
        label.toLowerCase().includes(searchText.toLowerCase()) ||
        value.toLowerCase().includes(searchText.toLowerCase())
    ) {
        return true;
    } else {
        return false;
    }
};

export const getUPCFilterControl = (
    highlight: boolean,
    nodes: any[],
    onChange: (option?: OptionTypeOrNullOrUndefined) => void,
    selected: ReportUPC | undefined | null,
    trackingLabels: string[],
    width: string = SELECT_WIDTH
): React.ReactNode => {
    const options = nodes.map((upc: LabelValueType) => {
        return {
            label: upc.label,
            subLabel: upc.value,
            value: upc.value
        };
    });
    options.sort((a: OptionType, b: OptionType) => {
        const aLabel = a.label ? a.label : "";
        const bLabel = b.label ? b.label : "";
        return aLabel.localeCompare(bLabel);
    });
    const selectedOption = options.find(
        (option: OptionType) => option.value === selected?.upc
    );
    return (
        <CustomSelect
            cssWidth={width}
            filterOption={upcFilter}
            highlightSelected={highlight}
            isSearchable={true}
            name="upc"
            options={options}
            onChange={onChange}
            trackingComponentLabel={trackingLabels}
            value={selectedOption}
        />
    );
};

export const getPromotionFilterControl = (
    highlight: boolean,
    nodes: any[],
    onChange: (option?: OptionTypeOrNullOrUndefined) => void,
    selected: string,
    trackingLabels: string[],
    width: string = SELECT_WIDTH
): React.ReactNode => {
    const options = nodes.map((promotion: any) => {
        return {
            label: promotion.displayName,
            value: promotion.value
        };
    });
    const selectedOption = options.find(
        (option: OptionType) => option.value === selected
    );
    return (
        <CustomSelect
            cssWidth={width}
            highlightSelected={highlight}
            isSearchable={false}
            name="promotionFilter"
            options={options}
            onChange={onChange}
            trackingComponentLabel={trackingLabels}
            trackEventValue={true}
            value={selectedOption}
        />
    );
};

export const createMetricsOptions = (
    metrics: Metric[],
    t: TFunction
): OptionType[] => {
    return metrics.map(x => {
        return {
            value: x.name,
            label: t(x.labelKey as ParseKeys)
        };
    });
};

export const getOptionLabel = (option?: any, labelField = "label"): string => {
    return option ? get(option, labelField, "") : "";
};

export const getSelectedOption = (
    selected?: string,
    nodes?: any[],
    valueField = "value"
): any => {
    return (
        selected && nodes && nodes.find(x => get(x, valueField) === selected)
    );
};

export const ppgDisplayName = (
    ppgId: string,
    nodes: ProductFilter[]
): string => {
    for (let i = 0; i < nodes.length; i++) {
        const ppgs = nodes[i].adminProductGroups;
        for (let j = 0; j < ppgs.length; j++) {
            const ppg = ppgs[j];
            if (ppg.entityIdWithoutVersion === ppgId) {
                return ppg.displayName;
            }
        }
    }
    return "";
};

export const entityDisplayName = (
    entityId: string,
    entities: any[],
    valueField = "entityIdWithoutVersion",
    labelField = "displayName"
): string => {
    for (let i = 0; i < entities.length; i++) {
        const entity = entities[i];
        if (entity[valueField] === entityId) {
            return entity[labelField];
        }
    }
    return "";
};

export const flattenPpgNodes = (nodes: ProductFilter[]): OptionType[] => {
    const options: OptionType[] = [];
    for (let i = 0; i < nodes.length; i++) {
        const ppgs = nodes[i].adminProductGroups;
        for (let j = 0; j < ppgs.length; j++) {
            const ppg = ppgs[j];
            options.push({
                value: ppg.entityIdWithoutVersion,
                label: ppg.displayName
            });
        }
    }
    return options;
};

// Consts for max values shown in summaries
export const SUMMARY_MAX_OFFER_TYPES = 4;
export const SUMMARY_MAX_PPGS = 2;
export const SUMMARY_MAX_PROMO_QUANTITES = 8;

export const summarizeBrands = (
    brands: string[],
    t: TFunction,
    limit: number = SUMMARY_LIMIT
): string => {
    return genericSummarize(t("reports.all_brands"), brands, t, limit);
};

export const summarizeCategories = (
    categories: string[],
    t: TFunction,
    limit: number = SUMMARY_LIMIT
): string => {
    return genericSummarize(
        t("common:general.all_categories"),
        categories,
        t,
        limit
    );
};

export const summarizeUpc = (upc: ReportUPC | undefined | null): string => {
    if (upc) {
        const upcDescription = upc.upcDescription ? upc.upcDescription : "";
        const upcUpc = upc.upc ? upc.upc : "";
        return upcDescription + " (" + upcUpc + ")";
    }
    return "";
};

export const summarizeChannels = (
    channels: string[],
    nodes: any[],
    t: TFunction,
    limit: number = SUMMARY_LIMIT
): string => {
    return genericSummarize(
        t("general.all_channels"),
        channels,
        t,
        limit,
        (x: string) => entityDisplayName(x, nodes)
    );
};

export const summarizeHolidays = (
    holidays: string[],
    nodes: any[],
    t: TFunction,
    limit: number = SUMMARY_LIMIT
): string => {
    return genericSummarize(
        t("general.all_holidays_slash_seasons"),
        holidays,
        t,
        limit,
        (x: string) => entityDisplayName(x, nodes)
    );
};

export const summarizeOfferTypes = (
    offerTypes: string[],
    nodes: any[],
    t: TFunction,
    limit: number = SUMMARY_MAX_OFFER_TYPES
): string => {
    return genericSummarize(
        t("general.all_offer_types"),
        offerTypes,
        t,
        limit,
        (x: string) => entityDisplayName(x, nodes, "value", "displayName")
    );
};

export const summarizePpgs = (
    ppgs: string[],
    nodes: ProductFilter[],
    t: TFunction,
    limit: number = SUMMARY_MAX_PPGS
): string => {
    return genericSummarize(
        t("reports.all_ppgs"),
        ppgs,
        t,
        limit,
        (x: string) => ppgDisplayName(x, nodes)
    );
};

export const summarizePromotion = (
    promotion: string,
    promotionNodes: any[]
): string => {
    return getOptionLabel(
        getSelectedOption(promotion, promotionNodes),
        "displayName"
    );
};

export const summarizePromotionQuantities = (
    promotionQuantities: string[],
    maxPromotionQuantity: number,
    t: TFunction,
    limit: number = SUMMARY_MAX_PROMO_QUANTITES
): string => {
    if (promotionQuantities.length === 0) {
        return t("reports.all_promo_quantities_plus_non_promoted");
    }
    const nodes = buildPromotionQuantityNodes(maxPromotionQuantity, t);
    return summarizeList(promotionQuantities, t, limit, (x: string) =>
        entityDisplayName(x, nodes, "value", "label")
    );
};

export const summarizeTime = (time: DateFilter): string => {
    const dateNodes = reportDateNodes(true);
    const dateNode = dateNodes.find(node => node.id === time.time);
    if (time.time !== CUSTOM && dateNode) {
        return dateNode?.label ?? "";
    } else if (time.label) {
        return time.label;
    } else if (time.startDate && time.endDate) {
        return dateRange2Str(time.startDate, time.endDate);
    }
    return "";
};

export const getReportDataDateHeader = (
    startDate: Date | null,
    endDate: Date | null,
    comparisonStartDate: Date | null,
    comparisonEndDate: Date | null,
    t: TFunction
): React.ReactNode => {
    let startEndDate = null;
    if (startDate && endDate) {
        startEndDate = (
            <Flexbox flexDirection="row" marginRight="16px">
                {t("common:date.current_period_x_y", {
                    startDate: date2Str(startDate),
                    endDate: date2Str(endDate)
                })}
            </Flexbox>
        );
    }
    let comparisonStartEndDate = null;
    if (comparisonStartDate && comparisonEndDate) {
        comparisonStartEndDate = (
            <Flexbox flexDirection="row">
                {t("common:date.comparison_period_x_y", {
                    startDate: date2Str(comparisonStartDate),
                    endDate: date2Str(comparisonEndDate)
                })}
            </Flexbox>
        );
    }
    if (startEndDate || comparisonStartEndDate) {
        return (
            <Flexbox marginBottom="4px">
                {startEndDate}
                {comparisonStartEndDate}
            </Flexbox>
        );
    }
    return null;
};
