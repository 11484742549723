import i18n from "i18next";
//import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from "i18next-http-backend";
import type { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const publicUrl = process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "";
const LOAD_PATH = publicUrl + "/locales/{{lng}}/{{ns}}.json";

i18n.use(HttpBackend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //  .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init<HttpBackendOptions>({
        backend: {
            loadPath: LOAD_PATH
        },
        // moving from v20 - need to change plural syntax, so keep compatibility to old syntax for now
        compatibilityJSON: "v3",

        debug:
            process.env.NODE_ENV === "production" ||
            process.env.NODE_ENV === "test"
                ? false
                : true,
        fallbackLng: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            formatSeparator: ",",
            format: function (value, format, lng) {
                if (format === "uppercase") {
                    return value.toUpperCase();
                }
                if (format === "lowercase") {
                    return value.toLowerCase();
                }
                return value;
            },
            skipOnVariables: false // moving from v20 - change to default (now set to true), do not know impact, keep as before which was false
        },
        lng: "en-US",
        load: "currentOnly",
        ns: ["resource", "common"],
        returnNull: false,
        react: {
            useSuspense: false
        }
    });

export default i18n;
