import get from "lodash.get";
import type { AnyAction } from "redux";
import {
    andFilterCondition,
    freeFormFilterCondition,
    stringFilterCondition
} from "common/util/filter";
import {
    resetCanNotLeave,
    setCanNotLeave
} from "common/shell/state/navigationActions";
import {
    formatSortOrderForQueryAPI,
    makeRequestThunk,
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT
} from "common/shell/state/requestActions";
import type { PostPutMethod } from "common/shell/state/requestActions";
import { success } from "common/shell/state/toastActions";
import { PAGE_SIZE_UNLIMITED, sortCondition } from "common/util/query";
import {
    campaignOffersPage,
    resetCampaign
} from "campaign/state/campaignActions";
import { CREATE, EDIT } from "concept/components/OfferAdvancedFormView";
import type { CreateEditMode } from "concept/components/OfferAdvancedFormView";
import {
    OFFER_ADVANCED_CREATE_PAGE,
    OFFER_AUTOMATED_CREATE_PAGE,
    OFFER_EDIT_PAGE
} from "shell/state/pageActions";
import type { ActionOrThunk, Actions, RootState } from "store";

export const PPG_NAME = "name";
export const PPG_OFFER_DISPLAY_NAME = "offerDisplayName";
export const PPG_PRICE_TO_CONSUMER = "priceToConsumer";
export const PPG_USED_IN_CAMPAIGN = "usedInCampaign";

// action types

export const RECEIVE_AUTOMATED_DESIGN = "RECEIVE_AUTOMATED_DESIGN";
export const RECEIVE_CAMPAIGN_PRODUCT_GROUPS =
    "RECEIVE_CAMPAIGN_PRODUCT_GROUPS";
export const RECEIVE_GENERATE_AUTOMATED_DESIGN =
    "RECEIVE_GENERATE_AUTOMATED_DESIGN";
export const RECEIVE_PRODUCT_GROUPS = "RECEIVE_PRODUCT_GROUPS";
export const REQUEST_AUTOMATED_DESIGN = "REQUEST_AUTOMATED_DESIGN";
export const REQUEST_CAMPAIGN_PRODUCT_GROUPS =
    "REQUEST_CAMPAIGN_PRODUCT_GROUPS";
export const REQUEST_GENERATE_AUTOMATED_DESIGN =
    "REQUEST_GENERATE_AUTOMATED_DESIGN";
export const REQUEST_PRODUCT_GROUPS = "REQUEST_PRODUCT_GROUPS";
export const UPDATE_CAMPAIGN_PRODUCT_GROUPS_SORT =
    "UPDATE_CAMPAIGN_PRODUCT_GROUPS_SORT";
export const UPDATE_PRODUCT_GROUPS_SORT = "UPDATE_PRODUCT_GROUPS_SORT";

// actions creators

export const offerAutomatedCreatePage = (entityId: string): AnyAction => ({
    type: OFFER_AUTOMATED_CREATE_PAGE,
    payload: {
        entityId
    }
});

export const offerAdvancedCreatePage = (
    entityId: string,
    promotionEntityId?: string
): AnyAction => {
    return {
        type: OFFER_ADVANCED_CREATE_PAGE,
        payload: {
            entityId,
            promotionEntityId: promotionEntityId ? promotionEntityId : null
        }
    };
};

export const offerEditPage = (
    entityId: string,
    promotionEntityId: string
): AnyAction => ({
    type: OFFER_EDIT_PAGE,
    payload: {
        entityId,
        promotionEntityId
    }
});

export const requestAutomatedDesign = (): AnyAction => ({
    type: REQUEST_AUTOMATED_DESIGN
});

const receiveAutomatedDesign = (json: Json): AnyAction => ({
    type: RECEIVE_AUTOMATED_DESIGN,
    json: json
});

const requestGenerateAutomatedDesign = (): AnyAction => ({
    type: REQUEST_GENERATE_AUTOMATED_DESIGN
});

const receiveGenerateAutomatedDesign = (json: Json): AnyAction => ({
    type: RECEIVE_GENERATE_AUTOMATED_DESIGN,
    json: json
});

const requestCampaignProductGroups = (): AnyAction => ({
    type: REQUEST_CAMPAIGN_PRODUCT_GROUPS
});

const receiveCampaignProductGroups = (json: Json): AnyAction => ({
    type: RECEIVE_CAMPAIGN_PRODUCT_GROUPS,
    json: json
});

const requestProductGroups = (): AnyAction => ({
    type: REQUEST_PRODUCT_GROUPS
});

const receiveProductGroups = (json: Json): AnyAction => ({
    type: RECEIVE_PRODUCT_GROUPS,
    json: json
});

export const updateCampaignProductGroupsSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => {
    return {
        type: UPDATE_CAMPAIGN_PRODUCT_GROUPS_SORT,
        sortBy,
        sortOrder
    };
};

export const updateProductGroupsSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => {
    return {
        type: UPDATE_PRODUCT_GROUPS_SORT,
        sortBy,
        sortOrder
    };
};

const getCampaignProductGroupsRequest = (search: string, state: RootState) => {
    const sortOrder = formatSortOrderForQueryAPI(
        state.concept.campaignProductGroups.sortOrder
    );
    const sortConditions = [
        sortCondition(
            get(state, "concept.campaignProductGroups.sortBy"),
            sortOrder
        )
    ];
    const filterSearchCondition = search
        ? freeFormFilterCondition(search, ["name", "offerDisplayName"])
        : null;

    const entityIdCondition = stringFilterCondition(
        "campaign.entityId",
        get(state, "campaign.entityId"),
        "EQUALS"
    );

    let filterCondition: FilterCondition = entityIdCondition;
    if (filterSearchCondition) {
        filterCondition = andFilterCondition([
            filterSearchCondition,
            entityIdCondition
        ]);
    }

    return {
        entity: "CampaignProductGroup",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: PAGE_SIZE_UNLIMITED,
        model: "CampaignProductGroupModel/financialFields",
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: null,
        sortConditions: sortConditions,
        startIndex: 0
    };
};

const getProductGroupsRequest = (search: string, state: RootState) => {
    const sortOrder = formatSortOrderForQueryAPI(
        state.concept.productGroups.sortOrder
    );
    const sortConditions = [
        sortCondition(get(state, "concept.productGroups.sortBy"), sortOrder)
    ];
    const filterCondition = search
        ? freeFormFilterCondition(search, ["name", "offerDisplayName"])
        : null;

    return {
        entity: "ProductGroup",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: PAGE_SIZE_UNLIMITED,
        model: "ProductGroupModel/listFields",
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: null,
        sortConditions: sortConditions,
        startIndex: 0
    };
};

// thunks

export function fetchAutomatedDesign() {
    return makeRequestThunk("api/automateddesign", {
        method: METHOD_GET,
        urlPartFunc: function (state: RootState) {
            return "/" + get(state, "campaign.entityId");
        },
        preRequestFunc: requestAutomatedDesign,
        okDispatchFunc: receiveAutomatedDesign
    });
}

export const reduceProductGroupNodes = (selectedNodes: TreeNode[]) => {
    const treeNodes: any[] = [];

    selectedNodes.forEach(selectedNode => {
        let children: any[] = [];
        const label = selectedNode.displayName;
        const value = selectedNode.nodeEntityId;
        // If there are children recurse
        if (selectedNode.children && selectedNode.children.length > 0) {
            children = reduceProductGroupNodes(selectedNode.children);
        }
        treeNodes.push({
            children,
            label,
            value
        });
    });

    return treeNodes;
};

const getProductGroupTreeNodes = (campaignProductGroup: any) => {
    return reduceProductGroupNodes(
        get(campaignProductGroup, "adminProductGroup.selectedNodes", [])
    );
};

export function fetchCampaignProductGroup(ppgs: any[]) {
    return makeRequestThunk("api/productgroup/campaign", {
        method: METHOD_GET,
        urlPartFunc: function (state: RootState) {
            const ppgIds = ppgs
                .map((ppg: any) => ppg.productGroupEntityId)
                .join(",");
            return "/" + get(state, "campaign.entityId") + "/" + ppgIds;
        },
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                campaignProductGroup: json,
                productGroupTreeNodes: getProductGroupTreeNodes(json)
            };
        },
        showLoadMask: true
    });
}

export function fetchCampaignProductGroups(search: string) {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getCampaignProductGroupsRequest(search, state);
        },
        preRequestFunc: requestCampaignProductGroups,
        okDispatchFunc: receiveCampaignProductGroups,
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.result,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

export function fetchProductGroups(search: string) {
    return makeRequestThunk("api/productgroup/productgroupquery", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getProductGroupsRequest(search, state);
        },
        urlPartFunc: function (state: RootState) {
            return "/" + get(state, "campaign.entityId");
        },
        preRequestFunc: requestProductGroups,
        okDispatchFunc: receiveProductGroups,
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.result,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

export function generateAutomatedDesign(automatedDesign: AutomatedDesign) {
    return makeRequestThunk("api/automateddesign/view", {
        method: METHOD_POST,
        body: automatedDesign,
        preRequestFunc: requestGenerateAutomatedDesign,
        okDispatchFunc: receiveGenerateAutomatedDesign,
        okResultFunc: (json: Json, state: RootState): any => {
            return json;
        }
    });
}

export function createAutomatedDesign(automatedDesign: AutomatedDesign) {
    return makeRequestThunk("api/automateddesign/create", {
        method: METHOD_POST,
        body: automatedDesign,
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            actions.push(resetCanNotLeave());
            // reset campaign so CampaignView calls fetchCampaign
            actions.push(resetCampaign());
            actions.push(
                campaignOffersPage(
                    get(state, "campaign.entityIdWithoutVersion")
                )
            );
            return actions;
        }
    });
}

/*
        var i, offerTypesStore, offerTemplateStore, type, testPlatformOfferTemplate, offerTemplate;

        this.setOfferTypeMap(new Ext.util.HashMap());
        offerTypesStore = new Precipio.store.core.BaseMemoryDestroyableStore({
            fields: ['displayName', 'value'],
            data: []
        });
        for (i = 0; i < this.getTestPlatformOfferTemplateStore().data.items.length; i += 1) {
            testPlatformOfferTemplate = this.getTestPlatformOfferTemplateStore().data.items[i];
            offerTemplate = testPlatformOfferTemplate.getOfferTemplate();
            type = offerTemplate.getOfferType();
            offerTemplateStore = this.getOfferTypeMap().get(type.get("value"));
            if (!offerTemplateStore) {
                offerTypesStore.add({
                    displayName: type.get('displayName'),
                    value: type.get("value")
                });
                offerTemplateStore = new Precipio.store.core.BaseMemoryDestroyableStore({
                    fields: [
                        'entityId',
                        { name: 'offerTypeDisplayName', type: 'auto', mapping: 'offerTemplate.offerType.displayName'},
                        { name: 'offerTemplate_allowsMustBuy', type: 'auto', mapping: 'offerTemplate.allowsMustBuy'},
                        { name: 'displayName', type: 'auto', mapping: 'offerTemplate.displayName'}
                    ],
                    data: [testPlatformOfferTemplate]
                });
                this.getOfferTypeMap().add(type.get("value"), offerTemplateStore);
            } else {
                offerTemplateStore.add(testPlatformOfferTemplate);
            }
        }
*/

const reduceOfferTemplates = (json: Json) => {
    const testPlatformOfferTemplates = json.result;
    const offerTypes: Dictionary<any> = {};
    testPlatformOfferTemplates.forEach((testPlatformOfferTemplate: any) => {
        const offerTemplate = testPlatformOfferTemplate.offerTemplate;
        const offerTemplateOfferType = offerTemplate.offerType;
        const offerType = offerTypes[offerTemplateOfferType.value];
        if (!offerType) {
            offerTypes[offerTemplateOfferType.value] = {
                offerType: offerTemplateOfferType,
                offerTemplates: [testPlatformOfferTemplate]
            };
        } else {
            offerType.offerTemplates.push(testPlatformOfferTemplate);
        }
    });
    return offerTypes;
};

export function fetchTestPlatformOfferTemplates() {
    return makeRequestThunk("api/testPlatformOfferTemplates", {
        method: METHOD_GET,
        urlPartFunc: function (state: RootState) {
            return "/" + get(state, "campaign.entityId");
        },
        queryParamsFunc: function (state: RootState) {
            return {
                modelAndFieldSet: "TestPlatformOfferTemplateModel/designFields",
                includeCrossTenantInstances: false
            };
        },
        okResultFunc: (json: Json, state: RootState): any => {
            return reduceOfferTemplates(json);
        },
        showLoadMask: true
    });
}

const getConceptRequest = (
    state: RootState,
    testPlatformOfferTemplateId: string | null,
    promotionId: string | null,
    conceptDesign: ConceptDesign | null = null,
    allowCrossMerchandiseToLead = true,
    singleOfferConcept = true
) => {
    const campaignEntityId = get(state, "campaign.entityId", "");
    return {
        allowCrossMerchandiseToLead: allowCrossMerchandiseToLead,
        campaignId: campaignEntityId,
        conceptDesignTO: conceptDesign,
        objectType: "ConceptRequest",
        promotionId: promotionId,
        singleOfferConcept: singleOfferConcept,
        testPlatformOfferTemplateId: testPlatformOfferTemplateId
    };
};

export function fetchConceptDesignByOfferTemplate(
    testPlatformOfferTemplateId: string
) {
    return makeRequestThunk("api/concept/get", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getConceptRequest(state, testPlatformOfferTemplateId, null);
        },
        okResultFunc: (json: Json, state: RootState): any => {
            return json;
        },
        showLoadMask: true
    });
}

export function fetchConceptDesignByPromotion(promotionId: string) {
    return makeRequestThunk("api/concept/get", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getConceptRequest(state, null, promotionId);
        },
        okResultFunc: (json: Json, state: RootState): any => {
            return json;
        },
        showLoadMask: true
    });
}

export function fetchConceptSentence(conceptDesign: ConceptDesign) {
    return makeRequestThunk("api/concept/sentence", {
        method: METHOD_POST,
        body: conceptDesign,
        okResultFunc: (json: Json, state: RootState): any => {
            return json;
        },
        showLoadMask: true
    });
}

export function fetchConceptPreview(conceptDesign: ConceptDesign) {
    return makeRequestThunk("api/concept/preview", {
        method: METHOD_POST,
        body: conceptDesign,
        okResultFunc: (json: Json, state: RootState): any => {
            return json;
        },
        showLoadMask: true
    });
}

export function writeConcept(
    conceptDesign: ConceptDesign,
    method: PostPutMethod,
    createEditMode: CreateEditMode
) {
    return makeRequestThunk("api/concept", {
        method: method,
        body: conceptDesign,
        isCancellable: false,
        urlPartFunc: function (state: RootState): string {
            let urlPart = "";
            if (method === METHOD_PUT) {
                urlPart = "/" + conceptDesign.entityId;
            }
            return urlPart;
        },
        okResultFunc: (json: Json, state: RootState): any => {
            return json;
        },
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            let successMessage = {
                resourceKey: "promotion.create_offer_success"
            };
            if (method === METHOD_PUT) {
                successMessage = {
                    resourceKey: "promotion.edit_offer_success"
                };
            }
            if (createEditMode === CREATE || createEditMode === EDIT) {
                actions.push(resetCanNotLeave());
                // reset campaign so CampaignView calls fetchCampaign
                actions.push(resetCampaign());
                actions.push(
                    campaignOffersPage(get(conceptDesign, "campaign.entityId"))
                );
            } else {
                actions.push(setCanNotLeave());
            }
            actions.push(success(successMessage));
            return actions;
        },
        showLoadMask: true
    });
}

export const addOffersFromCampaign = (
    campaignEntityId: string,
    offerIds: string[]
) => {
    return makeRequestThunk("api/copyPromotions/" + campaignEntityId, {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return {
                entityIds: offerIds,
                objectType: "EntityIdsDto"
            };
        },
        isCancellable: false,
        okResultFunc: (json: Json, state: RootState): any => {
            return json;
        },
        showLoadMask: true
    });
};

export const addControlOffers = (
    campaignEntityId: string,
    promotionIds: string[]
) => {
    return makeRequestThunk("api/campaign/addfromlibrary", {
        method: METHOD_PUT,
        body: {
            campaignId: campaignEntityId,
            objectType: "AddFromControlLibraryRequest",
            promotionIds: promotionIds
        },
        isCancellable: false,
        okResultFunc: (json: Json, state: RootState): any => {
            return json;
        },
        showLoadMask: true
    });
};
