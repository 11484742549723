import get from "lodash.get";
import type { Action, Store } from "redux";
import { back, pathToAction } from "redux-first-router";
import { abortAllRequests } from "common/shell/state/requestStateUtils";
import { resetErrorFound } from "common/shell/state/errorActions";
import type { AppDispatch, GetState } from "store";

// action types
export const RESET_CAN_NOT_LEAVE = "RESET_CAN_NOT_LEAVE";
export const SET_CAN_NOT_LEAVE = "SET_CAN_NOT_LEAVE";
export const SET_SELECTED_APP = "SET_SELECTED_APP";

export type NavigationActions =
    | ResetCanNotLeave
    | SetCanNotLeave
    | SetSelectedApp;

interface ResetCanNotLeave extends Action {
    type: typeof RESET_CAN_NOT_LEAVE;
}

export const resetCanNotLeave = (): ResetCanNotLeave => ({
    type: RESET_CAN_NOT_LEAVE
});

interface SetCanNotLeave extends Action {
    type: typeof SET_CAN_NOT_LEAVE;
}

export const setCanNotLeave = (): SetCanNotLeave => ({
    type: SET_CAN_NOT_LEAVE
});

interface SetSelectedApp extends Action {
    type: typeof SET_SELECTED_APP;
    selectedApp: string;
}

export const setSelectedApp = (selectedApp: string): SetSelectedApp => ({
    type: SET_SELECTED_APP,
    selectedApp: selectedApp
});

export const resetOnRouteChange = (store: Store, action: Action) => {
    const state = store.getState();
    // Check we aren't navigating to same page
    if (get(state, "page") !== get(action, "type")) {
        abortAllRequests();
        store.dispatch(resetErrorFound());
        store.dispatch(resetCanNotLeave());
    }
};

export const goBack = (defaultAction?: NoArgsHandler | null) => {
    return (dispatch: AppDispatch, getState: GetState) => {
        const state = getState();
        const prev = get(state, "location.prev", {});
        const routesMap = get(state, "location.routesMap", {});
        if (prev.type) {
            back();
            return;
        }
        if (defaultAction) {
            defaultAction();
            return;
        }
        dispatch(pathToAction("/", routesMap));
        return;
    };
};
