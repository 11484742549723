import * as React from "react";
import { memo } from "react";
import type {
    ICellRendererParams,
    IServerSideGetRowsParams
} from "ag-grid-community";
import get from "lodash.get";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import Button, {
    BUTTON_TYPE_PRIMARY,
    BUTTON_TYPE_SECONDARY
} from "common/components/Button";
import Drawer, { DrawerFooter, DrawerOverlay } from "common/components/Drawer";
import type {
    DataGridType,
    RowSelection
} from "common/components/grid/AgDataGrid";
import {
    ROW_SELECTION_MULTIPLE,
    ROW_SELECTION_SINGLE
} from "common/components/grid/AgDataGrid";
import AgDataGridSelector from "common/components/grid/AgDataGridSelector";
import {
    COLUMN_CUSTOM_RENDERER_ALIGN_LEFT,
    COLUMN_IMAGE_THUMBNAIL_ROW_HEIGHT,
    DATA_TYPE_DATE,
    getRowsHandler,
    IMAGE_THUMBNAIL_HEIGHT
} from "common/components/grid/AgDataGridUtil";
import {
    DateCellRenderer,
    TruncatedCellRenderer
} from "common/components/grid/AgGridCellRenderers";
import Flexbox from "common/components/styled/Flexbox";
import type { TrackingComponentLabel } from "common/util/trackingEvents";
import { composeTrackingComponentLabel } from "common/util/tracking";
import {
    fetchSmartImageSets,
    updateSmartImageSetsStartIndex,
    updateSmartImageSetsSort
} from "administration/images/state/imagesActions";
import { CommaJoinCellRenderer } from "campaign/components/CampaignCellRenderers";
import type { AppDispatch, RootState } from "store";

export const ImageCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams): React.ReactElement | null => {
        const { colDef, data } = params;
        if (colDef && data) {
            const imageField = get(colDef, "cellRendererParams.imageField");
            const imageSrc = get(data, imageField);
            if (imageSrc) {
                return (
                    <Flexbox cssHeight="100px" cssWidth="100px">
                        <img alt="" src={imageSrc} style={{ margin: "auto" }} />
                    </Flexbox>
                );
            }
        }
        return null;
    }
);

export const SMART_IMAGE_SET_DISPLAY_NAME = "displayName";
const SMART_IMAGE_SET_LAST_USED_DATE = "lastUsedFormatDate";
const SMART_IMAGE_SET_PRODUCTS = "products";
const SMART_IMAGE_SET_RETAILERS = "retailers";
const SMART_IMAGE_SET_UPLOADED_BY = "creator_fullName";
const SMART_IMAGE_SET_UPLOADED_DATE = "createdFormatDate";
export const SMART_IMAGE_SET_URL = "THUMBNAILUrl";

const SORT_KEY_MAP: Dictionary<string> = {
    [SMART_IMAGE_SET_UPLOADED_BY]: "creator.fullName",
    [SMART_IMAGE_SET_UPLOADED_DATE]: "created",
    [SMART_IMAGE_SET_LAST_USED_DATE]: "lastUsed"
};

type SmartImageSetSelectorOwnProps = {
    closeHandler: NoArgsHandler;
    isOpen: boolean;
    isSingleSelect: boolean;
    onSubmit: (selected: any[]) => void;
    rowSelection: RowSelection;
    selectedImages: EntityIdType[];
    trackingComponentLabel: TrackingComponentLabel;
};

type SmartImageSetSelectorProps = SmartImageSetSelectorOwnProps &
    PropsFromRedux &
    WithTranslation;

type SmartImageSetSelectorState = {
    selectedSmartImageSets: SmartImageSet[];
};

class SmartImageSetSelector extends React.Component<
    SmartImageSetSelectorProps,
    SmartImageSetSelectorState
> {
    static displayName = "SmartImageSetSelector";

    state = {
        selectedSmartImageSets: []
    };

    getRowsSuccess = (
        result: any,
        params: IServerSideGetRowsParams,
        dataGrid: DataGridType
    ) => {
        const { selectedImages } = this.props;
        const { filteredCount, items } = result;
        params.success({
            rowData: items,
            rowCount: filteredCount
        });
        // Reselect items
        let selectedItems: EntityIdType[] = [];
        if (selectedImages && selectedImages.length > 0) {
            selectedItems = selectedImages;
        }
        dataGrid?.reselectItems(selectedItems);
    };

    getDrawerRows = (
        params: IServerSideGetRowsParams,
        dataGrid: DataGridType,
        search: string
    ) => {
        const { fetchSmartImageSets, isOpen, updateSort } = this.props;
        const fetchData = () => {
            return fetchSmartImageSets(search);
        };
        if (isOpen) {
            getRowsHandler({
                dataGrid: dataGrid,
                fetchData: fetchData,
                getRowsSuccess: this.getRowsSuccess,
                params: params,
                sortKeyMap: SORT_KEY_MAP,
                updateSort: updateSort
            });
        }
    };

    getColumnDefinitions = (): ColumnDefinition[] => {
        const { t } = this.props;
        return [
            {
                cellClass: [COLUMN_IMAGE_THUMBNAIL_ROW_HEIGHT],
                cellRenderer: ImageCellRenderer,
                cellRendererParams: {
                    imageField: SMART_IMAGE_SET_URL,
                    imageWidth: "100px",
                    imageHeight: "100px"
                },
                colId: SMART_IMAGE_SET_URL,
                field: SMART_IMAGE_SET_URL,
                headerName: t("image.image"),
                sortable: false,
                width: 120
            },
            {
                cellClass: [COLUMN_IMAGE_THUMBNAIL_ROW_HEIGHT],
                cellRenderer: TruncatedCellRenderer,
                cellRendererParams: {
                    textField: SMART_IMAGE_SET_DISPLAY_NAME
                },
                colId: SMART_IMAGE_SET_DISPLAY_NAME,
                field: SMART_IMAGE_SET_DISPLAY_NAME,
                flex: 2,
                headerName: t("administration.display_name"),
                minWidth: 160
            },
            {
                cellClass: [COLUMN_IMAGE_THUMBNAIL_ROW_HEIGHT],
                colId: SMART_IMAGE_SET_UPLOADED_BY,
                field: SMART_IMAGE_SET_UPLOADED_BY,
                headerName: t("common:general.uploaded_by")
            },
            {
                cellClass: [COLUMN_IMAGE_THUMBNAIL_ROW_HEIGHT],
                cellRenderer: DateCellRenderer,
                cellRendererParams: {
                    dateType: DATA_TYPE_DATE,
                    field: SMART_IMAGE_SET_UPLOADED_DATE
                },
                colId: SMART_IMAGE_SET_UPLOADED_DATE,
                field: SMART_IMAGE_SET_UPLOADED_DATE,
                headerName: t("common:general.uploaded"),
                width: 140
            },
            {
                cellClass: [COLUMN_CUSTOM_RENDERER_ALIGN_LEFT],
                cellRenderer: CommaJoinCellRenderer,
                cellRendererParams: {
                    itemsField: SMART_IMAGE_SET_RETAILERS
                },
                colId: SMART_IMAGE_SET_RETAILERS,
                field: SMART_IMAGE_SET_RETAILERS,
                flex: 1,
                headerName: t("common:general.retailer_plural"),
                minWidth: 360
            },
            {
                cellClass: [COLUMN_CUSTOM_RENDERER_ALIGN_LEFT],
                cellRenderer: CommaJoinCellRenderer,
                cellRendererParams: {
                    itemsField: SMART_IMAGE_SET_PRODUCTS
                },
                colId: SMART_IMAGE_SET_PRODUCTS,
                field: SMART_IMAGE_SET_PRODUCTS,
                flex: 1,
                headerName: t("general.ppg_plural"),
                minWidth: 360
            },
            {
                cellClass: [COLUMN_IMAGE_THUMBNAIL_ROW_HEIGHT],
                cellRenderer: DateCellRenderer,
                cellRendererParams: {
                    dateType: DATA_TYPE_DATE,
                    field: SMART_IMAGE_SET_LAST_USED_DATE
                },
                colId: SMART_IMAGE_SET_LAST_USED_DATE,
                field: SMART_IMAGE_SET_LAST_USED_DATE,
                headerName: t("common:general.last_used"),
                width: 140
            }
        ];
    };

    onImageSelect = (selected: SmartImageSet[]): void => {
        this.setState({
            selectedSmartImageSets: selected
        });
    };

    onDrawerCancel = (): void => {
        const { closeHandler } = this.props;
        this.setState(
            {
                selectedSmartImageSets: []
            },
            () => {
                closeHandler();
            }
        );
    };

    onDrawerSubmit = (): void => {
        const { onSubmit } = this.props;
        const { selectedSmartImageSets } = this.state;
        this.setState(
            {
                selectedSmartImageSets: []
            },
            () => {
                onSubmit(selectedSmartImageSets);
            }
        );
    };

    render() {
        const {
            isOpen,
            /*            
            isSingleSelect,
*/
            rowSelection = ROW_SELECTION_MULTIPLE,
            t,
            totalCount,
            trackingComponentLabel
        } = this.props;

        const { selectedSmartImageSets } = this.state;

        let title = t("image.select_images");
        if (rowSelection === ROW_SELECTION_SINGLE) {
            title = t("image.select_image");
        }

        const drawerFooter = (
            <DrawerFooter>
                <Button
                    marginLeft="20px"
                    onClick={this.onDrawerCancel}
                    text={t("common:general.cancel")}
                    type={BUTTON_TYPE_SECONDARY}
                    trackingComponentLabel={[
                        SmartImageSetSelector.displayName,
                        "Cancel"
                    ]}
                />
                <Button
                    disabled={selectedSmartImageSets.length < 1}
                    marginLeft="8px"
                    onClick={this.onDrawerSubmit}
                    text={t("common:general.select")}
                    type={BUTTON_TYPE_PRIMARY}
                    trackingComponentLabel={[
                        SmartImageSetSelector.displayName,
                        "Select"
                    ]}
                />
            </DrawerFooter>
        );

        return (
            <DrawerOverlay isOpen={isOpen}>
                <Drawer
                    closeHandler={this.onDrawerCancel}
                    cssWidth={1240}
                    footer={drawerFooter}
                    isOpen={isOpen}
                    title={title}
                >
                    <Flexbox
                        cssHeight="100%"
                        flexDirection="column"
                        padding="16px"
                    >
                        <AgDataGridSelector
                            addCheckboxColumn={true}
                            columnDefinitions={this.getColumnDefinitions()}
                            getRows={this.getDrawerRows}
                            headerText="image.x_image"
                            id="SmartImageSetSelector"
                            okToQuery={true}
                            onSelectedRowsChange={this.onImageSelect}
                            resetGrid={isOpen}
                            rowHeight={IMAGE_THUMBNAIL_HEIGHT}
                            rowSelection={rowSelection}
                            showColumnChooser={false}
                            showShowSelected={false}
                            totalCount={totalCount}
                            trackingComponentLabel={composeTrackingComponentLabel(
                                [
                                    trackingComponentLabel,
                                    SmartImageSetSelector.displayName
                                ]
                            )}
                            writeToPreferences={false}
                        />
                    </Flexbox>
                </Drawer>
            </DrawerOverlay>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        totalCount: get(state, "images.totalCount")
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        fetchSmartImageSets: (search: string) => {
            return dispatch(fetchSmartImageSets(search));
        },
        updateStartIndex: (startIndex: number) => {
            dispatch(updateSmartImageSetsStartIndex(startIndex));
        },
        updateSort: (sortBy: string, sortOrder: SortOrder) => {
            dispatch(updateSmartImageSetsSort(sortBy, sortOrder));
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(SmartImageSetSelector));
