import analysis from "analysis/state/analysisReducers";
import async from "administration/async/state/asyncReducers";
import campaign from "campaign/state/campaignReducers";
import campaigns from "campaign/state/campaignsReducers";
import concept from "concept/state/conceptReducers";
import downloads from "common/administration/users/state/downloadsReducers";
import entity from "common/shell/state/entityReducers";
import guideline from "administration/guidelines/state/guidelineReducers";
import guidelines from "administration/guidelines/state/guidelinesReducers";
import holiday from "administration/holidays/state/holidayReducers";
import holidays from "administration/holidays/state/holidaysReducers";
import images from "administration/images/state/imagesReducers";
import init from "shell/state/initReducers";
import promotionTypeDetection from "administration/promotionTypeDetection/state/promotionTypeDetectionReducers";
import page from "common/shell/state/pageReducers";
import compliance from "compliance/state/complianceReducers";
import events from "event/state/eventsReducers";
import filters from "filter/state/filterReducers";
import notifications from "notifications/state/notificationsReducers";
import offerRecommendations from "offerRecommendations/state/offerRecommendationReducers";
import createTest from "offers/state/createTestReducers";
import offerPreview from "offers/state/offerPreviewReducers";
import opportunities from "opportunities/state/opportunityReducers";
import overview from "overview/state/overviewReducers";
import ppgGuidelines from "ppgGuidelines/state/ppgGuidelinesReducers";
import product from "products/state/productReducers";
import products from "products/state/productsReducers";
import promotion from "promotions/state/promotionReducers";
import promotions from "promotions/state/promotionsReducers";
import recommendations from "recommendations/state/recommendationReducers";
import reports from "reports/state/reportsReducers";
import team from "common/administration/teams/state/teamReducers";
import teams from "common/administration/teams/state/teamsReducers";
import tenant from "campaign/state/tenantReducers";
import testPhaseData from "testPhaseData/state/testPhaseDataReducers";
import user from "common/administration/users/state/userReducers";
import users from "common/administration/users/state/usersReducers";

const reducers = {
    analysis,
    async,
    campaign,
    campaigns,
    compliance,
    concept,
    createTest,
    downloads, // included although we don't have downloads to keep Typescript happy
    entity,
    events,
    filters,
    guideline,
    guidelines,
    holiday,
    holidays,
    images,
    init,
    notifications,
    offerPreview,
    offerRecommendations,
    opportunities,
    overview,
    page,
    ppgGuidelines,
    product,
    products,
    promotion,
    promotions,
    promotionTypeDetection,
    recommendations,
    reports,
    team,
    teams,
    tenant,
    testPhaseData,
    user,
    users
};

export default reducers;
