export const PROMOTION_DISCOUNT_PERCENT = "discountPercentFormatPercent";
export const PROMOTION_EFFECTIVE_DISCOUNT_PERCENT =
    "effectiveDiscountPercentFormatPercent";
export const PROMOTION_EFFECTIVE_TRADE_RATE = "effectiveTradeRateFormatPercent";
export const PROMOTION_EFFECTIVE_UNIT_PRICE_TO_CONSUMER =
    "effectiveUnitPriceToConsumer";
export const PROMOTION_ESTIMATED_REDEMPTION_DISCOUNT_COSTS =
    "estimatedRedemptionDiscountCostsFormatCurrency";
export const PROMOTION_ESTIMATED_TOTAL_REDEMPTION_FEES =
    "estimatedTotalRedemptionFeesFormatCurrency";
export const PROMOTION_IS_CONTROL = "isControl";
export const PROMOTION_MAX_BUY_QUANTITY = "maxBuyQuantity";
export const PROMOTION_NAME = "name";
export const PROMOTION_OFFER_LABEL = "offerLabel";
export const PROMOTION_PRICE_TO_CONSUMER =
    "totalPriceToConsumerAfterDiscountFormatCurrency";
export const PROMOTION_PRICE_TO_CONSUMER_EVERY_DAY =
    "totalPriceToConsumerBeforeDiscountFormatCurrency";
export const PROMOTION_SENTENCE = "sentenceFormatHtml";
export const PROMOTION_REDEMPTION_METHOD = "redemptionMethod.displayName";
export const PROMOTION_TOTAL_DISCOUNT = "totalDollarsOffFormatCurrency";
export const PROMOTION_UNIT_PRICE_TO_CONSUMER = "pricePerUnitFormatCurrency";
export const PROMOTION_UNIT_PRICE_TO_CONSUMER_EVERY_DAY =
    "pricePerUnitBeforeDiscountFormatCurrency";

export const CONTROL_OFFER_PROMOTION_NAME = "promotion_name";
export const CONTROL_OFFER_PROMOTION_OFFER_LABEL = "promotion_offerLabel";
export const CONTROL_OFFER_PROMOTION_OFFER_SUMMARY = "promotion_sentence";
export const CONTROL_OFFER_PRODUCT_GROUP = "productGroup_offerDisplayName";

export const reducePromotion = (promotion: any, testPlatformName: string) => {
    return Object.assign({}, promotion, {
        testPlatformName: testPlatformName
    });
};
