import * as React from "react";
import type { ColDef, GridApi } from "ag-grid-community";
import { ProvidedColumnGroup } from "ag-grid-community";
import difference from "lodash.difference";
import get from "lodash.get";
import isFunction from "lodash.isfunction";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import Button, { BUTTON_TYPE_TERTIARY } from "common/components/Button";
import { CheckboxGroupWrapper } from "common/components/CheckboxGroup";
import CheckboxTree from "common/components/CheckboxTree";
import {
    DropdownContainer,
    DropdownContent,
    DropdownSeparator,
    POSITION_BELOW,
    POSITION_LEFT
} from "common/components/Dropdown";
import {
    CHECKBOX_COLUMN,
    COLUMN_TYPE_AGGREGATION,
    getCombinedColumnState,
    ICON_COLUMN,
    PREVIEW_COLUMN,
    STATUS_COLUMN
} from "common/components/grid/AgDataGridUtil";
import type { ColumnPreferences } from "common/components/grid/AgDataGridUtil";
import { mergeLeafPathTrees } from "common/components/grid/AgGridUtil";
import BoldWrapper from "common/components/styled/BoldWrapper";
import Flexbox from "common/components/styled/Flexbox";
import Link from "common/components/styled/Link";
import { marginPropTypes } from "common/components/styled/util";
import FilterDropdownSearchBar from "common/filter/components/FilterDropdownSearchBar";
import { FilterDropdownScrollableContent } from "common/filter/components/FilterDropdownView";
import { ReactComponent as TableColumnsIcon } from "common/icons/TableColumns.svg";
import {
    setPreference,
    writePreference
} from "common/shell/state/preferencesActions";
import { expandNodes } from "common/util/filter";
import { reduceObject } from "common/util/object";
import { containsIgnoreReactOutsideClick } from "common/util/outsideClick";
import {
    composeTrackingComponentLabel,
    getTrackingEventData,
    trackEvent
} from "common/util/tracking";
import {
    EVENT_NAME_OPEN_CLOSE,
    EVENT_NAME_SELECTION_CHOICE,
    EVENT_NAME_TOGGLE_VIEW
} from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";
import type { AppDispatch } from "store";

export type ColumnChooserOwnProps = {
    dataGridId: string;
    defaultColumnDefs: ColDef[];
    displayedColumns: string[];
    gridApi: GridApi | undefined;
    gridReady: boolean;
    onResetColumns?: NoArgsHandler;
    trackingComponentLabel: TrackingComponentLabel;
    writeOnlyToPreferenceState?: boolean;
    writeToPreferences?: boolean;
} & marginPropsType &
    sizePropsType;

type ColumnChooserProps = ColumnChooserOwnProps &
    PropsFromRedux &
    WithTranslation;

type ColumnChooserState = {
    readonly expandAll: boolean;
    readonly expanded: string[];
    readonly isOpen: boolean;
    readonly searchStr: string;
};

class AgColumnChooser extends React.Component<
    ColumnChooserProps,
    ColumnChooserState
> {
    static displayName = "AgColumnChooser";
    static defaultProps = {
        onResetColumns: undefined,
        writeOnlyToPreferenceState: false,
        writeToPreferences: true
    };

    state = {
        expandAll: true,
        expanded: [],
        isOpen: false,
        searchStr: ""
    };

    // Track hiding/showing of columns in google analytics
    trackColumnShowHide = (show: boolean) => {
        const { trackingComponentLabel } = this.props;
        trackEvent(
            getTrackingEventData(
                AgColumnChooser.displayName,
                composeTrackingComponentLabel([
                    trackingComponentLabel,
                    show ? "Show Columns" : "Hide Columns"
                ]),
                EVENT_NAME_SELECTION_CHOICE
            )
        );
    };

    onClickHandler = (newCheckedItems: string[]) => {
        const { displayedColumns, gridApi } = this.props;
        if (gridApi) {
            // click resulted in either items being unchecked or checked
            // compare currently displayed items against new checked items to determine which values have been added or removed
            const removed = difference(displayedColumns, newCheckedItems);
            if (removed.length > 0) {
                this.trackColumnShowHide(false);
                gridApi.setColumnsVisible(removed, false);
            }
            const added = difference(newCheckedItems, displayedColumns);
            if (added.length > 0) {
                this.trackColumnShowHide(true);
                gridApi.setColumnsVisible(added, true);
            }
        }
    };

    onTriggerClick = (evt: React.SyntheticEvent<HTMLButtonElement>) => {
        if (this.state.isOpen) {
            this.setState({ isOpen: false });
        } else {
            this.setState({ isOpen: true, searchStr: "" });
        }
    };

    onOutsideClick = (event: MouseEvent) => {
        if (!containsIgnoreReactOutsideClick(event)) {
            this.closeChooser();
        }
    };

    closeChooser = (persistState = true) => {
        if (this.state.isOpen) {
            if (persistState) {
                this.setStateIntoPreferences();
            }
            this.setState({ isOpen: false });
        }
    };

    setStateIntoPreferences = () => {
        const {
            dataGridId,
            gridApi,
            setPreference,
            writePreference,
            writeOnlyToPreferenceState,
            writeToPreferences
        } = this.props;
        if ((writeToPreferences || writeOnlyToPreferenceState) && gridApi) {
            const newColumnState = getCombinedColumnState(gridApi);
            if (writeOnlyToPreferenceState) {
                // writes only to redux state
                setPreference(dataGridId, newColumnState);
            } else {
                // writes to both redux state and database
                writePreference(dataGridId, newColumnState);
            }
        }
    };

    onSearchChange = (searchStr: string) => {
        this.setState({ searchStr: searchStr });
    };

    onReset = () => {
        const { defaultColumnDefs, gridApi, onResetColumns } = this.props;
        // columns need to be reset to default
        if (onResetColumns) {
            onResetColumns();
        } else {
            // Check if gridApi is available to use
            if (gridApi) {
                // Use column API to reset column state, needed for "alignedGrids"
                gridApi.resetColumnState();
                const displayedGridColumns = gridApi.getAllDisplayedColumns();
                // get aggregation columns and save them off
                // aggregation is controlled outside of grid/column chooser
                const aggregateColumns: ColDef[] = [];
                displayedGridColumns.forEach(displayedGridColumn => {
                    const colDef = displayedGridColumn.getColDef();
                    if (
                        get(displayedGridColumn, "colDef.type") ===
                        COLUMN_TYPE_AGGREGATION
                    ) {
                        aggregateColumns.push(colDef);
                    }
                });
                // get default columns minus any initial aggregation columns
                const nonAggregateDefaultColumns: ColDef[] = [];
                defaultColumnDefs.forEach(defaultColumnDef => {
                    if (defaultColumnDef.type !== COLUMN_TYPE_AGGREGATION) {
                        nonAggregateDefaultColumns.push(defaultColumnDef);
                    }
                });
                const resetColumns: ColDef[] = aggregateColumns.concat(
                    nonAggregateDefaultColumns
                );
                gridApi.setGridOption("columnDefs", []);
                gridApi.setGridOption("columnDefs", resetColumns);
                this.setStateIntoPreferences();
            }
        }
    };

    onExpand = (expanded: string[]) => {
        // once any expand or collapse is triggered, set expandAll to false and use expanded to set the expanded nodes of the tree
        this.setState({ expandAll: false, expanded: expanded });
    };

    // Begin code repurposed from ag-grid-enterprise
    getLeafPathTree = (node: any, childDef: any): any => {
        const { t } = this.props;
        let leafPathTree; // build up tree in reverse order

        if (node instanceof ProvidedColumnGroup) {
            if (node.isPadding()) {
                // skip over padding groups
                leafPathTree = childDef;
            } else {
                const groupDef = Object.assign({}, node.getColGroupDef()); // ensure group contains groupId
                groupDef.groupId = node.getGroupId();
                /* begin additional code not in ag-grid-enterprise */
                // put in values for chktree
                let label = "";
                if (groupDef.headerName) {
                    label = groupDef.headerName;
                } else {
                    if (isFunction(groupDef.headerValueGetter)) {
                        // This currently works only because no headerValueGetter is using params argument that should be passed back
                        // @ts-expect-error Need to pass ag grid params as argument
                        label = groupDef.headerValueGetter();
                    }
                }
                // adding a prop that isn't on ColGroupDef for chktree processing
                // @ts-expect-error Label is not prop of ColGroupDef, added by us to display column chooser
                groupDef.label = label;
                // code used to be this which masked headerValueGetter potential issue
                /*  groupDef.label = groupDef.headerName
                    ? groupDef.headerName
                    : groupDef.headerValueGetter
                    ? groupDef.headerValueGetter()
                    : ""; */
                // split groups will have same groupId - appending unqiue value to end didn't work with search so removed that logic in PL-56850
                // adding a prop that isn't on ColGroupDef for chktree processing
                // @ts-expect-error Value is not prop of ColGroupDef, added by us to display column chooser
                groupDef.value = groupDef.groupId;
                /* end additional code not in ag-grid-enterprise */
                groupDef.children = [childDef];
                leafPathTree = groupDef;
            }
        } else {
            const colDef = Object.assign({}, node.getColDef()); // ensure col contains colId

            colDef.colId = node.getColId();
            /* begin additional code not in ag-grid-enterprise */
            // put in values for chktree
            let label = "";
            if (colDef.headerValueGetter) {
                label = colDef.headerValueGetter(colDef.cellRendererParams);
            } else {
                if (colDef.headerName) {
                    label = colDef.headerName;
                } else {
                    if (colDef.colId === CHECKBOX_COLUMN) {
                        label = t("common:general.select");
                    } else {
                        if (colDef.colId === PREVIEW_COLUMN) {
                            label = t("common:general.preview");
                        } else {
                            if (colDef.colId === ICON_COLUMN) {
                                label = t("common:general.icon");
                            } else {
                                if (colDef.colId === STATUS_COLUMN) {
                                    label = t("common:general.status");
                                }
                            }
                        }
                    }
                }
            }
            colDef.label = label;
            colDef.value = colDef.colId;
            colDef.disabled = colDef.lockVisible ? true : false;
            /* end additional code not in ag-grid-enterprise */
            leafPathTree = colDef;
        } // walk tree

        const parent = node.getOriginalParent();

        if (parent) {
            // keep walking up the tree until we reach the root
            return this.getLeafPathTree(parent, leafPathTree);
        } else {
            // we have reached the root - exit with resulting leaf path tree
            return leafPathTree;
        }
    }; // obtain a sorted list of all grid columns

    getColumnTree = (searchStr: string): any[] => {
        const { gridApi, t } = this.props;
        let mergedColumnDefs = [];
        if (gridApi) {
            const allGridColumns = gridApi.getAllGridColumns();
            // get all primary columns
            const allProcessedGridColumns = allGridColumns.filter(column => {
                const colDef = column.getColDef();
                const colGroupDef = column
                    .getOriginalParent()
                    ?.getColGroupDef();
                let headerName = colDef.headerName ?? "";
                const colDefGroupHeaderName = colGroupDef?.headerName ?? "";
                if (!headerName && colDef.colId === CHECKBOX_COLUMN) {
                    headerName = t("common:general.select");
                }
                // Function to help with search
                const isInSearch = (value: string) => {
                    return (
                        value.toLowerCase().indexOf(searchStr.toLowerCase()) >
                        -1
                    );
                };
                const inSearch =
                    isInSearch(headerName) || isInSearch(colDefGroupHeaderName);
                const isPrimary = column.isPrimary();
                const noShowRowGroup = !colDef.showRowGroup;
                return isPrimary && noShowRowGroup && inSearch;
            });
            const leafPathTrees: any[] = [];
            allProcessedGridColumns.forEach(column => {
                const leafPathTree = this.getLeafPathTree(
                    column,
                    column.getColDef()
                );
                leafPathTrees.push(leafPathTree);
            });
            mergedColumnDefs = mergeLeafPathTrees(leafPathTrees);
        }
        return mergedColumnDefs;
    };
    // End code repurposed from ag-grid-enterprise

    containsTree = (items: any[]): boolean => {
        let containsTree = false;
        for (const item of items) {
            if (item.children && item.children.length > 0) {
                containsTree = true;
                break;
            }
        }
        return containsTree;
    };

    render() {
        const { displayedColumns, gridApi, t, trackingComponentLabel } =
            this.props;
        const { expandAll, expanded, searchStr } = this.state;
        let CheckboxContainer = null;
        if (gridApi) {
            const columnChooserItems = this.getColumnTree(searchStr);
            const containsTree = this.containsTree(columnChooserItems);
            const expandedItems: any[] = [];
            if (expandAll) {
                expandNodes(columnChooserItems, expandedItems);
            }

            CheckboxContainer = (
                <CheckboxTree
                    checked={displayedColumns}
                    expanded={expandAll ? expandedItems : expanded}
                    nodes={columnChooserItems}
                    onCheck={this.onClickHandler}
                    onExpand={this.onExpand}
                    suppressIndentation={containsTree ? false : true}
                />
            );
        }

        return (
            <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
                <DropdownContainer
                    {...reduceObject(this.props, ...marginPropTypes)}
                >
                    <Button
                        cssHeight="36px"
                        icon={TableColumnsIcon}
                        onClick={this.onTriggerClick}
                        trackingComponentLabel={composeTrackingComponentLabel([
                            trackingComponentLabel,
                            "Column Chooser"
                        ])}
                        trackingEventName={EVENT_NAME_OPEN_CLOSE}
                        type={BUTTON_TYPE_TERTIARY}
                    />
                    {this.state.isOpen && (
                        <DropdownContent
                            cssWidth="275px"
                            horizontalPosition={POSITION_LEFT}
                            verticalPosition={POSITION_BELOW}
                        >
                            <Flexbox
                                cssHeight="40px"
                                alignItems="center"
                                justifyContent="space-between"
                                marginRight="16px"
                                marginLeft="16px"
                            >
                                <BoldWrapper>
                                    {t("common:general.columns")}
                                </BoldWrapper>
                                <Link
                                    onClick={this.onReset}
                                    trackingComponentLabel={composeTrackingComponentLabel(
                                        [
                                            trackingComponentLabel,
                                            "Reset Columns"
                                        ]
                                    )}
                                    trackingEventName={EVENT_NAME_TOGGLE_VIEW}
                                >
                                    {t("common:filter.reset")}
                                </Link>
                            </Flexbox>
                            <DropdownSeparator />
                            <FilterDropdownSearchBar
                                changeHandler={this.onSearchChange}
                                searchText={searchStr}
                                trackingComponentLabel={composeTrackingComponentLabel(
                                    [trackingComponentLabel, "Search Columns"]
                                )}
                            />
                            <FilterDropdownScrollableContent cssHeight="325px">
                                <CheckboxGroupWrapper>
                                    {CheckboxContainer}
                                </CheckboxGroupWrapper>
                            </FilterDropdownScrollableContent>
                        </DropdownContent>
                    )}
                </DropdownContainer>
            </OutsideClickHandler>
        );
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setPreference: (prefName: string, value: any) => {
            dispatch(setPreference(prefName, value));
        },
        writePreference: (name: string, value: ColumnPreferences) => {
            dispatch(writePreference(name, value, true));
        }
    };
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(AgColumnChooser));
