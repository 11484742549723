import * as React from "react";
import styled from "styled-components/macro";
import {
    ExpandCollapseButton,
    TreeNodeIcon,
    TreeNodeIconWrapper,
    TreeNodeItem,
    TreeNodeLabel,
    TreeNodeTitle
} from "common/components/TreeNode";
import checkboxDisabledSelectedIcon from "common/images/checkbox_disabled_selected.png";
import checkboxDisabledUnselectedIcon from "common/images/checkbox_disabled_unselected.png";
import checkboxSelectedIcon from "common/images/checkbox_selected.png";
import collapseIcon from "common/images/minus_square.png";
import expandIcon from "common/images/plus_square.png";
import checkboxUnselectedIcon from "common/images/checkbox_unselected.png";

export const ExpandCollapseSpacer = styled.span<{ hasCheckbox?: boolean }>`
    flex-shrink: 0;
    width: ${props => (props.hasCheckbox ? "26px" : "4px")};
`;

ExpandCollapseSpacer.displayName = "ExpandCollapseSpacer";

type CheckboxTreeNodeProps = {
    checked: number;
    children: React.ReactNode;
    disabled: boolean;
    expandDisabled: boolean;
    expanded: boolean;
    icon: React.ReactNode;
    label: string;
    onCheck: (checkData: any) => void;
    onExpand: (expandData: any) => void;
    optimisticToggle: boolean;
    rawChildren: any[];
    suppressCheckboxForParents?: boolean;
    suppressIndentation?: boolean;
    treeId: string;
    value: string;
};

class CheckboxTreeNode extends React.Component<CheckboxTreeNodeProps> {
    static defaultProps = {
        children: null,
        expandDisabled: false,
        rawChildren: []
    };

    onCheck = () => {
        let isChecked = false;

        // Toggle off state to checked
        if (this.props.checked === 0) {
            isChecked = true;
        }

        // Toggle partial state based on cascade model
        if (this.props.checked === 2) {
            isChecked = this.props.optimisticToggle;
        }

        this.props.onCheck({
            value: this.props.value,
            checked: isChecked,
            children: this.props.rawChildren
        });
    };

    onExpand = () => {
        this.props.onExpand({
            value: this.props.value,
            expanded: !this.props.expanded
        });
    };

    hasChildren = () => {
        return this.props.rawChildren !== null;
    };

    renderCollapseButton = () => {
        const {
            expandDisabled,
            suppressCheckboxForParents,
            suppressIndentation
        } = this.props;

        if (!this.hasChildren()) {
            return suppressIndentation ? null : (
                <ExpandCollapseSpacer
                    hasCheckbox={!suppressCheckboxForParents}
                />
            );
        }

        return (
            <ExpandCollapseButton
                aria-label="Toggle"
                disabled={expandDisabled}
                title="Toggle"
                type="button"
                onClick={this.onExpand}
            >
                {this.renderCollapseIcon()}
            </ExpandCollapseButton>
        );
    };

    renderCollapseIcon = () => {
        if (!this.props.expanded) {
            return <TreeNodeIcon src={expandIcon} />;
        }
        return <TreeNodeIcon src={collapseIcon} />;
    };

    renderCheckboxIcon = () => {
        let iconSrc;
        if (this.props.checked === 0) {
            if (this.props.disabled) {
                iconSrc = checkboxDisabledUnselectedIcon;
            } else {
                iconSrc = checkboxUnselectedIcon;
            }
        } else {
            if (this.props.checked === 1) {
                if (this.props.disabled) {
                    iconSrc = checkboxDisabledSelectedIcon;
                } else {
                    iconSrc = checkboxSelectedIcon;
                }
            } else {
                if (this.props.disabled) {
                    iconSrc = checkboxDisabledUnselectedIcon;
                } else {
                    iconSrc = checkboxUnselectedIcon;
                }
            }
        }

        return <TreeNodeIcon src={iconSrc} />;
    };

    renderChildren = () => {
        if (!this.props.expanded) {
            return null;
        }

        return this.props.children;
    };

    render() {
        const {
            checked,
            disabled,
            icon,
            label,
            treeId,
            value,
            suppressCheckboxForParents
        } = this.props;
        const inputId = `${treeId}-${value.split(" ").join("_")}`;
        const hasChildren = this.hasChildren();
        const showCheckbox = !(suppressCheckboxForParents && hasChildren);

        return (
            <li>
                <TreeNodeItem>
                    {this.renderCollapseButton()}
                    <TreeNodeLabel
                        checkboxDisabled={disabled}
                        htmlFor={inputId}
                    >
                        {showCheckbox && (
                            <React.Fragment>
                                <input
                                    checked={checked === 1}
                                    disabled={disabled}
                                    id={inputId}
                                    type="checkbox"
                                    onChange={this.onCheck}
                                />
                                {this.renderCheckboxIcon()}
                            </React.Fragment>
                        )}
                        {icon && (
                            <TreeNodeIconWrapper hasCheckbox={showCheckbox}>
                                {icon}
                            </TreeNodeIconWrapper>
                        )}
                        <TreeNodeTitle
                            hasCheckbox={showCheckbox}
                            hasIcon={icon ? true : false}
                        >
                            {label}
                        </TreeNodeTitle>
                    </TreeNodeLabel>
                </TreeNodeItem>
                {this.renderChildren()}
            </li>
        );
    }
}

export default CheckboxTreeNode;
