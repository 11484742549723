import * as React from "react";
import { GROUP_AUTO_COLUMN_ID } from "ag-grid-community";
import type {
    CellEditingStoppedEvent,
    CheckboxSelectionCallbackParams,
    ColDef,
    ColGroupDef,
    Column,
    ColumnMovedEvent,
    ColumnResizedEvent,
    DragStoppedEvent,
    GetDataPath,
    GetRowIdFunc,
    GridApi,
    GridOptions,
    GridReadyEvent,
    HeaderCheckboxSelectionCallbackParams,
    IRowNode,
    IServerSideDatasource,
    IServerSideGroupSelectionState,
    IServerSideSelectionState,
    IsServerSideGroupOpenByDefaultParams,
    RedrawRowsParams,
    RowClassParams,
    RowDragEvent,
    RowGroupOpenedEvent,
    RowHeightParams,
    RowModelType,
    RowSelectedEvent,
    RowStyle,
    SelectionChangedEvent,
    SortChangedEvent,
    StartEditingCellParams,
    ValueGetterParams
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import isFunction from "lodash.isfunction";
import isNumber from "lodash.isnumber";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import AgColumnChooser from "common/components/grid/AgColumnChooser";
import type { ColumnChooserOwnProps } from "common/components/grid/AgColumnChooser";
import {
    COLUMN_RENDERER_GRID_ROW_MENU,
    COLUMN_TYPE_AGGREGATION,
    DATAGRID_COLUMN_ACTIONS,
    DEFAULT_HEADER_HEIGHT,
    DEFAULT_ROW_HEIGHT,
    flattenColumnDefinitions,
    getColumnDefinitionIds,
    getCombinedColumnState,
    InsetTableToolbar,
    InsetTableNoHeaderToolbar,
    PINNED_LEFT,
    PINNED_RIGHT,
    sortComparator,
    TableToolbar,
    TableWrapper
} from "common/components/grid/AgDataGridUtil";
import type { ColumnPreferences } from "common/components/grid/AgDataGridUtil";
import { GridRowMenuRenderer } from "common/components/grid/AgGridCellRenderers";
import AgGridTooltip, {
    LIGHT_THEME
} from "common/components/grid/AgGridTooltip";
import { mergeLeafPathTrees } from "common/components/grid/AgGridUtil";
import { TableAndHeaderWrapper } from "common/components/grid/CommonGridUtil";
import LoadingCellRenderer from "common/components/grid/LoadingCellRenderer";
import NoRowsGridOverlay from "common/components/grid/NoRowsGridOverlay";
import Flexbox from "common/components/styled/Flexbox";
import FlexGrow from "common/components/styled/FlexGrow";
import {
    setPreference,
    writePreference
} from "common/shell/state/preferencesActions";
import { getPreference } from "common/shell/util/preferences";
import { isEmptyString } from "common/util/lang";
import { ASCENDING, DEFAULT_PAGE_SIZE, DESCENDING } from "common/util/query";
import { getTrackingEventData, trackEvent } from "common/util/tracking";
import { EVENT_NAME_OPEN_CLOSE } from "common/util/trackingEvents";
import type { AppDispatch, RootState } from "store";

// Event consts from ag grid, they should match ag grid actions
const UI_COLUMN_MOVED_EVENT = "uiColumnMoved";
const UI_COLUMN_RESIZED_EVENT = "uiColumnResized";

export type AgGridReactRefType = {
    api?: GridApi<any> | null | undefined;
};

export type DataGridType = AgDataGrid | null;

export const DEFAULT_SORT_ORDER = [ASCENDING, DESCENDING] as SortOrder[];
export const REVERSE_SORT_ORDER = [
    ...DEFAULT_SORT_ORDER
].reverse() as SortOrder[];

export const CLIENT_SIDE = "clientSide" as RowModelType;
export const SERVER_SIDE = "serverSide" as RowModelType;

export const ROW_SELECTION_SINGLE = "single";
export const ROW_SELECTION_MULTIPLE = "multiple";

export type RowSelection =
    | typeof ROW_SELECTION_SINGLE
    | typeof ROW_SELECTION_MULTIPLE;

export const GRID_THEME_NO_BORDER = "ag-theme-eversight-noborder";
export const GRID_THEME_NO_BORDER_NO_HEADER =
    "ag-theme-eversight-noheader-noborder";

const defaultColDef: ColDef = {
    autoHeaderHeight: true,
    filter: false,
    menuTabs: [], // Needed to hide legacy header icon, can probably remove in v32
    resizable: true,
    sortable: true,
    suppressHeaderContextMenu: true,
    suppressHeaderFilterButton: true,
    suppressHeaderMenuButton: true,
    suppressMovable: false,
    tooltipComponent: "defaultTooltip",
    // Need this default value getter because refreshCells force doesn't display the value 0 properly.
    // It makes it empty so we have to make it a string
    valueGetter: function (params: ValueGetterParams): string | undefined {
        if (params.colDef.field) {
            const fieldContainsDots = params.colDef.field.indexOf(".") >= 0;
            const value: string | number = getValueUsingField(
                params.data,
                params.colDef.field,
                fieldContainsDots
            );
            if (isNumber(value)) {
                return value.toString();
            }
            if (typeof value === "string") {
                return value;
            }
        }
    },
    wrapHeaderText: true
};

const isFirstColumn = (
    params:
        | CheckboxSelectionCallbackParams
        | HeaderCheckboxSelectionCallbackParams
) => {
    const displayedColumns = params.api.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
};

const selectAllDefaultColDef: ColDef = {
    ...defaultColDef,
    checkboxSelection: isFirstColumn,
    headerCheckboxSelection: isFirstColumn
};

const getValueUsingField = (
    data: any,
    field: string,
    fieldContainsDots: boolean
): string | number => {
    if (!field || !data) {
        return "";
    }
    // if no '.', then it's not a deep value
    if (!fieldContainsDots) {
        return data[field];
    }
    // otherwise it is a deep value, so need to dig for it
    const fields = field.split(".");
    let currentObject = data;
    for (let i = 0; i < fields.length; i++) {
        if (currentObject == null) {
            return "";
        }
        currentObject = currentObject[fields[i]];
    }
    return currentObject;
};

type FlattenedColumn = ColGroupDef & { id: string };

type DataGridOwnProps = {
    alignedGrids?: AgGridReactRefType[] | undefined;
    autoGroupColumnDef?: ColDef;
    children?: React.ReactNode;
    columnChooserCallback?: (columnChooser: React.ReactNode) => void;
    columnDefs: ColumnDefinition[];
    customGridHeader?: React.ReactNode;
    dataIdKey?: string;
    datasource?: IServerSideDatasource;
    debug?: boolean;
    detailCellRenderer?: React.ElementType;
    detailRowAutoHeight?: boolean;
    disableStaticMarkup?: boolean;
    enableCellTextSelection?: boolean;
    enterNavigatesVertically?: boolean;
    enterNavigatesVerticallyAfterEdit?: boolean;
    getDataPath?: (rowData: GetDataPath<any>) => string[];
    getRowHeight?: (params: RowHeightParams<any, any>) => number;
    getRowId?: GetRowIdFunc;
    getRowStyle?: (params: RowClassParams) => RowStyle | undefined;
    getServerSideGroupKey?: (rowData: any) => string;
    gridHeaderLeft?: React.ReactNode;
    gridHeaderPadding?: string;
    gridHeaderRight?: React.ReactNode;
    gridHeaderTop?: React.ReactNode;
    gridRowMenu?: (rowData: any) => GridMenuItemType[];
    gridTheme?: string;
    headerHeight?: number;
    hideGridRowMenuColumn?: boolean;
    id: string;
    isRowSelectable?: (rowNode: any) => boolean;
    isServerSideGroup?: (rowData: any) => boolean;
    massActions?: React.ReactNode;
    masterDetail?: boolean;
    noRowsOverlayMessage?: string;
    onAgReactRef?: (ref: AgGridReactRefType | null) => void;
    onCellEditingStopped?: (event: CellEditingStoppedEvent) => void;
    onDisplayedColumnsChanged?: NoArgsHandler;
    onGridReady?: (event: GridReadyEvent) => void;
    onRef?: (ref: DataGridType) => void;
    onResetColumns?: NoArgsHandler;
    onRowDragEnd?: (event: RowDragEvent) => void;
    onRowSelected?: (event: RowSelectedEvent) => void;
    onSelectionChanged?: (event: SelectionChangedEvent) => void;
    onSortChanged?: (event: SortChangedEvent) => void;
    pageSize?: number;
    rowData?: any[];
    rowDragManaged?: boolean;
    rowHeight?: number;
    rowModelType: RowModelType;
    rowSelection?: RowSelection;
    selectAll?: boolean;
    showColumnChooser?: boolean;
    sortingOrder?: SortOrder[];
    stopEditingWhenCellsLoseFocus?: boolean;
    suppressDragLeaveHidesColumns?: boolean;
    suppressGroupRowsSticky?: boolean;
    suppressMovableColumns?: boolean;
    suppressMultiSort?: boolean;
    suppressRowClickSelection?: boolean;
    suppressRowHoverHighlight?: boolean;
    treeData?: boolean;
    writeInitialPreferences?: boolean; // Write initial preferences if column preferences is not yet set (null)
    writeOnlyToPreferenceState?: boolean; // Write preferences only to state, not to database
    writeToPreferences?: boolean; // persist preferences to database
};

type DataGridProps = DataGridOwnProps & PropsFromRedux & WithTranslation;

type DataGridState = {
    closedGroups: Set<string>;
    columnMoved: boolean;
    columnResized: any[] | undefined | null;
    displayedColumns: string[];
    expandAll: boolean;
    gridReady: boolean;
    openGroups: Set<string>;
    previouslyOpenedGroupKeys: Set<string>;
};

class AgDataGrid extends React.Component<DataGridProps, DataGridState> {
    static displayName = "AgDataGrid";
    calculatedColumnDefs: ColDef[] | undefined = undefined;
    containerRef: HTMLDivElement | undefined | null;
    defaultColumnDefinitions: ColDef[];
    gridApi: GridApi | undefined = undefined;
    initialSortColId: string | undefined | null = undefined;
    autoGroupColumnDef: ColDef | undefined = undefined;
    columnTypes: { [key: string]: ColDef<any> } = {};

    static defaultProps = {
        dataIdKey: "",
        debug: false,
        detailCellRenderer: undefined,
        detailRowAutoHeight: false,
        disableStaticMarkup: false,
        enableCellTextSelection: false,
        enterNavigatesVertically: false,
        enterNavigatesVerticallyAfterEdit: false,
        getDataPath: undefined,
        getRowHeight: undefined,
        gridHeaderLeft: null,
        gridHeaderPadding: "",
        gridHeaderRight: null,
        gridHeaderTop: null,
        gridTheme: "ag-theme-eversight",
        headerHeight: DEFAULT_HEADER_HEIGHT,
        massActions: null,
        masterDetail: false,
        noRowsOverlayMessage: "",
        pageSize: DEFAULT_PAGE_SIZE,
        rowDragManaged: false,
        rowHeight: DEFAULT_ROW_HEIGHT,
        rowModelType: SERVER_SIDE as RowModelType,
        showColumnChooser: true,
        sortingOrder: DEFAULT_SORT_ORDER,
        stopEditingWhenCellsLoseFocus: false,
        suppressDragLeaveHidesColumns: true,
        suppressGroupRowsSticky: false,
        suppressMovableColumns: false,
        suppressMultiSort: true,
        suppressRowClickSelection: true,
        suppressRowHoverHighlight: false,
        treeData: false,
        writeInitialPreferences: false,
        writeOnlyToPreferenceState: false,
        writeToPreferences: true
    };

    constructor(props: DataGridProps) {
        super(props);
        this.state = {
            closedGroups: new Set(),
            columnMoved: false,
            columnResized: null,
            displayedColumns: [],
            expandAll: false,
            gridReady: false,
            openGroups: new Set(),
            previouslyOpenedGroupKeys: new Set()
        };
        this.initialSortColId = this.getInitialSortColumn();
        this.autoGroupColumnDef = this.getAutoGroupColumnDef();
        this.defaultColumnDefinitions = this.getDefaultColumnsDefs();
        this.calculateColumnsFromPreferences();
        // Custom defined default column types
        this.columnTypes = {
            [COLUMN_TYPE_AGGREGATION]: {
                lockPosition: PINNED_LEFT,
                lockVisible: true
            }
        };
    }

    componentWillUnmount() {
        const { columnChooserCallback, onRef } = this.props;
        if (onRef) {
            onRef(null);
            this.gridApi = undefined;
            this.setState({
                gridReady: false
            });
        }
        // Unmount column chooser
        if (columnChooserCallback) {
            columnChooserCallback(null);
        }
    }

    applyDefaultColumnHeaderTooltipStyle = (
        columnDefs: ColumnDefinition[]
    ): ColumnDefinition[] => {
        return columnDefs.map((columnDef: ColumnDefinition) => {
            const children = get(columnDef, "children", []);
            if (children.length > 0) {
                return {
                    ...columnDef,
                    children:
                        this.applyDefaultColumnHeaderTooltipStyle(children)
                };
            }
            if (columnDef.headerTooltip && !columnDef.tooltipComponentParams) {
                return {
                    ...columnDef,
                    tooltipComponentParams: {
                        cssWidth: "350px",
                        theme: LIGHT_THEME
                    }
                };
            }
            return columnDef;
        });
    };

    applyDefaultColumnComparator = (
        columnDefs: ColumnDefinition[]
    ): ColDef[] => {
        return columnDefs.map(columnDef => {
            const children = get(columnDef, "children", []);
            if (children.length > 0) {
                return {
                    ...columnDef,
                    children: this.applyDefaultColumnComparator(children)
                };
            }
            const comparator = get(columnDef, "comparator");
            const colId = get(columnDef, "colId");
            if (!comparator && colId) {
                return {
                    ...columnDef,
                    comparator: sortComparator.bind(null, colId)
                };
            } else {
                return columnDef;
            }
        });
    };

    closeAllGroups = () => {
        this.setState({
            openGroups: new Set()
        });
    };

    getDefaultColumnsDefs = (): ColDef[] => {
        const {
            allowableActions,
            columnDefs,
            gridRowMenu,
            hideGridRowMenuColumn,
            id,
            rowHeight,
            rowModelType,
            t
        } = this.props;
        // Filter out columns that don't have proper allowable actions set
        const filteredColumnDefs = columnDefs.filter(columnDef => {
            const allowableAction = get(
                columnDef,
                "cellRendererParams.allowableAction"
            );
            return !allowableAction || allowableActions[allowableAction];
        });
        let columns =
            this.applyDefaultColumnHeaderTooltipStyle(filteredColumnDefs);

        // set default sortComparator to column if client side or full store grid
        if (rowModelType === CLIENT_SIDE) {
            columns = this.applyDefaultColumnComparator(columns);
        }

        if (columns) {
            if (gridRowMenu && !hideGridRowMenuColumn) {
                columns.push({
                    cellClass: COLUMN_RENDERER_GRID_ROW_MENU,
                    cellRenderer: GridRowMenuRenderer,
                    cellRendererParams: {
                        gridId: id,
                        gridRowMenu: gridRowMenu,
                        getMenuContainerRef: this.getContainerRef,
                        rowHeight: rowHeight
                    },
                    colId: DATAGRID_COLUMN_ACTIONS,
                    field: DATAGRID_COLUMN_ACTIONS,
                    headerName: t("common:general.actions"),
                    lockVisible: true,
                    pinned: PINNED_RIGHT,
                    sortable: false,
                    width: 84
                });
            }
        }
        return columns;
    };

    getInitialSortColumn = (): string | undefined | null => {
        const { columnPreferences } = this.props;
        let sortColumn = null;

        const { column } = columnPreferences;
        if (column) {
            if (Array.isArray(column) && column.length > 0) {
                sortColumn = column.find(
                    item => item.sort === DESCENDING || item.sort === ASCENDING
                );
            }
        }
        return sortColumn ? sortColumn.colId : null;
    };

    shouldRemoveInitialSort = (initialSort: string, colId: string): boolean => {
        // if there is an initial sort col id, check to see if we should remove it
        let remove = false;
        if (this.initialSortColId) {
            // if column has initialSort set
            if (initialSort) {
                if (colId !== this.initialSortColId) {
                    remove = true;
                }
            }
        }
        return remove;
    };

    getAutoGroupColumnDef = (): ColDef | undefined => {
        const { autoGroupColumnDef } = this.props;
        let newAutoGroupColumnDef: ColDef = {};
        if (autoGroupColumnDef) {
            newAutoGroupColumnDef = Object.assign({}, autoGroupColumnDef);
            let initialSort = null;
            if (newAutoGroupColumnDef) {
                initialSort = newAutoGroupColumnDef.initialSort;
            }
            if (
                initialSort &&
                this.shouldRemoveInitialSort(initialSort, GROUP_AUTO_COLUMN_ID)
            ) {
                delete newAutoGroupColumnDef.initialSort;
            }
        }
        return isEmpty(newAutoGroupColumnDef)
            ? undefined
            : newAutoGroupColumnDef;
    };

    // Custom fields have been added on to column preferences, remove them here!
    // headerLabel in preferences to keep track of header labels, used for export option selector
    cleanColumnPreferencesColumn = (
        columnPreferences: any[]
    ): any[] | undefined | null => {
        if (columnPreferences) {
            return columnPreferences.map(columnPref => {
                const { headerLabel, ...rest } = columnPref;
                return {
                    ...rest
                };
            });
        }
        return null;
    };

    calculateColumnsFromPreferences = () => {
        const { autoGroupColumnDef, columnPreferences } = this.props;

        const column = this.cleanColumnPreferencesColumn(
            columnPreferences.column
        );
        if (column) {
            // calculate new columns
            const flattenedColumns: FlattenedColumn[] =
                flattenColumnDefinitions(this.defaultColumnDefinitions);
            // Get column def ids to compare counts vs preferences
            const columnDefIds = getColumnDefinitionIds(
                this.defaultColumnDefinitions,
                autoGroupColumnDef
            );
            const leafPathTrees = [];
            if (
                Array.isArray(column) &&
                column.length > 0 &&
                column.length === columnDefIds.length
            ) {
                let desyncedColumnDef = false;
                for (let i = 0; i < column.length; i += 1) {
                    const savedColPref = column[i];
                    // find the column in the flattened set
                    const columnDef = flattenedColumns.find(
                        item => item.id === savedColPref.colId
                    );
                    if (columnDef) {
                        const newColumnDef = Object.assign(
                            {},
                            columnDef,
                            savedColPref
                        );
                        if (
                            this.shouldRemoveInitialSort(
                                newColumnDef.initialSort,
                                newColumnDef.colId
                            )
                        ) {
                            delete newColumnDef.initialSort;
                        }
                        // remove id put in by flatten
                        delete newColumnDef.id;
                        const leafPathTree = this.getLeafPathTree(
                            newColumnDef,
                            newColumnDef,
                            flattenedColumns
                        );
                        leafPathTrees.push(leafPathTree);
                    } else if (savedColPref.colId !== GROUP_AUTO_COLUMN_ID) {
                        // If column not found and is not an GROUP_AUTO_COLUMN_ID column then exit
                        desyncedColumnDef = true;
                        break;
                    }
                }
                // If column preference not found in column def use default column defs
                if (desyncedColumnDef) {
                    this.calculatedColumnDefs = this.getDefaultColumnsDefs();
                } else {
                    this.calculatedColumnDefs =
                        mergeLeafPathTrees(leafPathTrees);
                }
            } else {
                // If column preference and column def counts out of sync use default column defs
                this.calculatedColumnDefs = this.getDefaultColumnsDefs();
            }
        } else {
            this.calculatedColumnDefs = this.getDefaultColumnsDefs();
        }
    };

    getLeafPathTree = (
        node: any,
        childDef: any,
        flattenedColumns: any[]
    ): ColGroupDef => {
        let leafPathTree: ColGroupDef; // build up tree in reverse order

        if (node.groupId) {
            // group
            const groupDef = Object.assign({}, node);
            // take out id put in from flatten as it is not an ag-grid column property
            delete groupDef.id;
            // take out parent in from flatten as it is not an ag-grid column property
            delete childDef.parent;
            groupDef.children = [childDef];
            leafPathTree = groupDef;
        } else {
            const colDef = Object.assign({}, node);
            // take out id in from flatten as it is not an ag-grid column property
            delete colDef.id;
            leafPathTree = colDef;
        }

        const parent = flattenedColumns.find(item => item.id === node.parent);

        if (parent) {
            return this.getLeafPathTree(parent, leafPathTree, flattenedColumns);
        } else {
            // we have reached the root - exit with resulting leaf path tree
            return leafPathTree;
        }
    };

    // Used for grid menu
    setContainerRef = (element?: HTMLDivElement | null) => {
        this.containerRef = element;
    };

    getContainerRef = () => {
        return this.containerRef;
    };

    componentDidMount() {
        const { onRef } = this.props;

        // set up ref access so that parent can call grid functions
        if (onRef) {
            onRef(this);
        }
    }

    componentDidUpdate(prevProps: DataGridProps, prevState: DataGridState) {
        const { columnChooserCallback } = this.props;
        const { displayedColumns } = this.state;
        // Send back column chooser component for this grid
        if (
            !isEqual(displayedColumns, prevState.displayedColumns) &&
            columnChooserCallback
        ) {
            const columnChooser = this.getColumnChooser();
            columnChooserCallback(columnChooser);
        }
    }

    getColumnChooser = (): React.ReactNode => {
        const {
            id,
            onResetColumns,
            writeOnlyToPreferenceState,
            writeToPreferences
        } = this.props;
        const { displayedColumns, gridReady } = this.state;

        const columnChooserParams: ColumnChooserOwnProps = {
            defaultColumnDefs: this.defaultColumnDefinitions,
            displayedColumns: displayedColumns,
            dataGridId: id,
            gridApi: this.gridApi,
            gridReady: gridReady,
            marginLeft: "10px",
            onResetColumns: onResetColumns,
            trackingComponentLabel: id,
            writeOnlyToPreferenceState: writeOnlyToPreferenceState,
            writeToPreferences: writeToPreferences
        };

        return <AgColumnChooser {...columnChooserParams} />;
    };

    // grab ahold of the grid and column interfaces
    onGridReady = (event: GridReadyEvent) => {
        this.gridApi = event.api;
        const { columnPreferences, onGridReady, writeInitialPreferences } =
            this.props;
        this.setDisplayedColumns();
        if (isFunction(onGridReady)) {
            onGridReady(event);
        }
        if (
            writeInitialPreferences &&
            Object.keys(columnPreferences).length === 0
        ) {
            this.persistColumnPreferences();
        }
        this.setState({
            gridReady: true
        });
    };

    reFetchData = (resetScroll = true) => {
        const { datasource, rowModelType } = this.props;
        if (this.gridApi) {
            if (rowModelType === SERVER_SIDE) {
                if (!resetScroll) {
                    // gets rid of client side cache and calls getRows with a startRow/endRow of previous call, scroll position maintained
                    // purge = true causes loading indicator to show, but no longer maintains scroll position so we dont use it anymore
                    this.gridApi.refreshServerSide();
                } else {
                    if (datasource) {
                        // resets datasource and calls getRows with a startRow of 0, scroll position returned to top
                        this.gridApi.setGridOption(
                            "serverSideDatasource",
                            datasource
                        );
                    }
                }
            }
        }
    };

    getServerSideSelectionState = ():
        | IServerSideSelectionState
        | IServerSideGroupSelectionState
        | null => {
        if (this.gridApi) {
            return this.gridApi.getServerSideSelectionState();
        }
        return null;
    };

    // Given the selection state, determine if any rows are selected
    isServerSideRowSelected = (): boolean => {
        if (this.gridApi) {
            const selectionState =
                this.gridApi.getServerSideSelectionState() as IServerSideSelectionState;
            if (
                (selectionState && selectionState.selectAll) ||
                selectionState.toggledNodes.length > 0
            ) {
                return true;
            }
        }
        return false;
    };

    getSelectedNodes = () => {
        return this.gridApi ? this.gridApi.getSelectedNodes() : [];
    };

    // Used when rowModelType = SERVER_SIDE but using the grid CLEINT_SIDE - PAGE_SIZE_UNLIMITED
    // So all rows are loaded into the grid and we can get the selected rows using this function
    getSelectedRowsServerSideSelectionState = (): any[] => {
        const { dataIdKey } = this.props;
        const idKey = dataIdKey ? dataIdKey : "entityId";
        const selectedItems: any[] = [];
        const selectionState =
            this.getServerSideSelectionState() as IServerSideSelectionState;
        if (selectionState && this.gridApi) {
            this.gridApi.forEachNode((node: IRowNode<any>) => {
                const item = node.data;
                if (item) {
                    const entityId = item[idKey] ?? "";
                    if (
                        !isEmptyString(entityId) &&
                        ((selectionState.selectAll === false &&
                            selectionState.toggledNodes.indexOf(entityId) >=
                                0) ||
                            (selectionState.selectAll === true &&
                                selectionState.toggledNodes.indexOf(
                                    entityId
                                ) === -1))
                    ) {
                        selectedItems.push(item);
                    }
                }
            });
        }
        return selectedItems;
    };

    getSelectedRows = (): any[] => {
        return this.gridApi ? this.gridApi.getSelectedRows() : [];
    };

    deselectAll = () => {
        if (this.gridApi) {
            this.gridApi.deselectAll();
        }
    };

    refreshHeaders = () => {
        if (this.gridApi) {
            this.gridApi.refreshHeader();
        }
    };

    resetColumnDefs = () => {
        this.setColumnDefs(this.getDefaultColumnsDefs());
    };

    setAutoColumnGroupDef = (columnDef: ColDef) => {
        if (this.gridApi) {
            this.gridApi.setGridOption("autoGroupColumnDef", columnDef);
        }
    };

    setColumnDefs = (columnDefs: ColDef[]) => {
        if (this.gridApi) {
            this.gridApi.setGridOption("columnDefs", columnDefs);
        }
    };

    setPinnedBottomRowData = (rows: any[]) => {
        if (this.gridApi) {
            this.gridApi.setGridOption("pinnedBottomRowData", rows);
        }
    };

    setPinnedTopRowData = (rows: any[]) => {
        if (this.gridApi) {
            this.gridApi.setPinnedTopRowData(rows);
        }
    };

    // when column move or column resize has stopped
    onDragStopped = (params: DragStoppedEvent) => {
        const { columnMoved, columnResized } = this.state;

        if (columnMoved || columnResized) {
            this.persistColumnPreferences();
        }

        if (columnMoved) {
            this.setState({
                columnMoved: false
            });
        }

        // this forces a specific column to refresh
        // needed for columns with TrucatedDiv which needs to recalculate width to determine if tooltip is necessary (whether truncation has occurred)
        if (columnResized && this.gridApi) {
            this.gridApi.refreshCells({
                columns: columnResized.map(column => column.colId),
                force: true
            });
            this.setState({
                columnResized: null
            });
        }
    };

    onColumnResized = (event: ColumnResizedEvent) => {
        // column can be resized programmatically or by the grid itself
        // only care when a user resizes column (uiColumnResized)
        if (event.source === UI_COLUMN_RESIZED_EVENT && event.columns) {
            this.setState({
                columnResized: event.columns
            });
        }
    };

    onColumnMoved = (event: ColumnMovedEvent) => {
        // column can be resized programmatically or by the grid itself
        // only care when a user resizes column (uiColumnDragged)
        if (event.source === UI_COLUMN_MOVED_EVENT && event.columns) {
            this.setState({
                columnMoved: true
            });
        }
    };

    isServerSideGroupOpenByDefault = (
        params: IsServerSideGroupOpenByDefaultParams
    ) => {
        const { dataIdKey } = this.props;
        const { openGroups } = this.state;
        const { data } = params;

        let expanded = false;
        if (data && data.expanded !== undefined) {
            expanded = data.expanded;
        } else if (openGroups.size > 0 && dataIdKey) {
            const nodeId = get(data, dataIdKey);
            if (openGroups.has(nodeId)) {
                expanded = true;
            }
        }
        return expanded;
    };

    // Track expanding/collapsing of rows in google analytics
    trackRowGroupOpened = (expand: boolean) => {
        const { id } = this.props;
        trackEvent(
            getTrackingEventData(
                AgDataGrid.displayName,
                [id, expand ? "Expand Row" : "Collapse Row"],
                EVENT_NAME_OPEN_CLOSE
            )
        );
    };

    onRowGroupOpened = (event: RowGroupOpenedEvent) => {
        // this gets called whether there is a programmatic open/close of nodes or user open/close of nodes
        const { dataIdKey } = this.props;
        const { openGroups, previouslyOpenedGroupKeys } = this.state;
        if (dataIdKey) {
            const rowIdOpenOrClosed = get(event, `data.${dataIdKey}`);
            const expanded = get(event, "expanded");
            // create a new state so React knows something has changed
            const newOpenGroups = new Set(openGroups);
            const newPreviouslyOpenedGroupKeys = new Set(
                previouslyOpenedGroupKeys
            );
            if (expanded) {
                newOpenGroups.add(rowIdOpenOrClosed);
                newPreviouslyOpenedGroupKeys.add(rowIdOpenOrClosed);
            } else {
                newOpenGroups.delete(rowIdOpenOrClosed);
            }
            // Track collapse/expand
            this.trackRowGroupOpened(expanded);
            this.setState({
                openGroups: newOpenGroups,
                previouslyOpenedGroupKeys: newPreviouslyOpenedGroupKeys
            });
        }
    };

    getPreviouslyOpenedGroupKeys = () => {
        const { previouslyOpenedGroupKeys } = this.state;
        return previouslyOpenedGroupKeys;
    };

    refreshTreeGridCells = () => {
        const openedGroupKeys = this.getPreviouslyOpenedGroupKeys();
        if (this.gridApi) {
            this.gridApi.refreshCells({ force: true });
            if (openedGroupKeys && openedGroupKeys.size > 0) {
                openedGroupKeys.forEach(key => {
                    if (this.gridApi) {
                        this.gridApi.refreshServerSide({
                            route: [key],
                            purge: true
                        });
                    }
                });
            }
        }
    };

    reselectItems = (selectedRows: any[]) => {
        const { dataIdKey } = this.props;
        if (dataIdKey && this.gridApi) {
            this.gridApi.forEachNode((node: IRowNode) => {
                const nodeId = get(node, "data." + dataIdKey);
                if (selectedRows.find(row => row[dataIdKey] === nodeId)) {
                    node.setSelected(true, false);
                }
            });
        }
    };

    hideOverlay = () => {
        if (this.gridApi) {
            this.gridApi.hideOverlay();
        }
    };

    showNoRowsOverlay = () => {
        if (this.gridApi) {
            this.gridApi.showNoRowsOverlay();
        }
    };

    getColumn = (columnId: string): Column | null => {
        if (this.gridApi) {
            return this.gridApi.getColumn(columnId);
        }
        return null;
    };

    getAllGridColumns = (): Column[] | null => {
        if (this.gridApi) {
            return this.gridApi.getAllGridColumns();
        }
        return null;
    };

    redrawRows = (params: RedrawRowsParams) => {
        if (this.gridApi) {
            this.gridApi.redrawRows(params);
        }
    };

    redrawAllRows = () => {
        if (this.gridApi) {
            this.gridApi.redrawRows();
        }
    };

    forEachNode = (
        callBackFunction: (rowNode: IRowNode, index: number) => void
    ) => {
        if (this.gridApi) {
            this.gridApi.forEachNode(callBackFunction);
        }
    };

    setRowData = (rowData: any[]) => {
        if (this.gridApi) {
            this.gridApi.setGridOption("rowData", rowData);
        }
    };

    startEditingCell = (params: StartEditingCellParams) => {
        if (this.gridApi) {
            this.gridApi.startEditingCell(params);
        }
    };

    persistColumnPreferences = () => {
        const {
            id,
            setPreference,
            writePreference,
            writeOnlyToPreferenceState,
            writeToPreferences
        } = this.props;

        if (
            (writeToPreferences || writeOnlyToPreferenceState) &&
            this.gridApi
        ) {
            // column and columnGroup state could have changed

            const newColumnPreferences = getCombinedColumnState(this.gridApi);
            if (writeOnlyToPreferenceState) {
                // writes only to redux state
                setPreference(id, newColumnPreferences);
            } else {
                // writes to both redux state and database
                writePreference(id, newColumnPreferences, true);
            }
        }
    };

    setSort = (colId: string, sortOrder: SortOrder) => {
        if (this.gridApi) {
            this.gridApi.applyColumnState({
                state: [{ colId: colId, sort: sortOrder }],
                defaultState: { sort: null }
            });
        }
    };

    doOnSortChanged = (event: SortChangedEvent) => {
        const { onSortChanged } = this.props;
        this.persistColumnPreferences();
        if (onSortChanged) {
            onSortChanged(event);
        }
    };

    getAllDisplayedColumns = (): Column[] => {
        if (this.gridApi) {
            return this.gridApi.getAllDisplayedColumns();
        }
        return [];
    };

    setDisplayedColumns = () => {
        const columnsDisplayed = this.getAllDisplayedColumns();
        const displayedColumns: string[] = [];
        columnsDisplayed.forEach((columnDisplayed: Column) => {
            displayedColumns.push(columnDisplayed.getColId());
        });

        this.setState({
            displayedColumns: displayedColumns
        });
    };

    onDisplayedColumnsChanged = () => {
        const { onDisplayedColumnsChanged } = this.props;
        this.setDisplayedColumns();
        if (onDisplayedColumnsChanged) {
            onDisplayedColumnsChanged();
        }
    };

    getCheckedExportColumns = (): string[] => {
        const checked: string[] = [];
        if (this.gridApi) {
            const columnsDisplayed = this.gridApi.getAllDisplayedColumns();
            columnsDisplayed.forEach((column: Column) => {
                if (column.getColId() !== DATAGRID_COLUMN_ACTIONS) {
                    checked.push(column.getColId());
                }
            });
        }
        return checked;
    };

    onSelectionChanged = (event: SelectionChangedEvent) => {
        const { onSelectionChanged } = this.props;
        if (onSelectionChanged) {
            window.getSelection()?.removeAllRanges();
            onSelectionChanged(event);
        }
    };

    onRowSelected = (event: RowSelectedEvent) => {
        const { onRowSelected } = this.props;
        if (onRowSelected) {
            window.getSelection()?.removeAllRanges();
            onRowSelected(event);
        }
    };

    render() {
        const {
            alignedGrids,
            customGridHeader,
            datasource,
            debug,
            detailCellRenderer,
            detailRowAutoHeight,
            disableStaticMarkup,
            enableCellTextSelection,
            enterNavigatesVertically,
            enterNavigatesVerticallyAfterEdit,
            getDataPath,
            getRowHeight,
            getRowId,
            getRowStyle,
            getServerSideGroupKey,
            gridHeaderLeft,
            gridHeaderPadding,
            gridHeaderRight,
            gridHeaderTop,
            gridTheme,
            headerHeight,
            isRowSelectable,
            isServerSideGroup,
            localeText,
            massActions,
            masterDetail,
            noRowsOverlayMessage,
            onCellEditingStopped,
            onRowDragEnd,
            onRowSelected,
            onSelectionChanged,
            pageSize,
            rowData,
            rowDragManaged,
            rowHeight,
            rowModelType,
            rowSelection,
            onAgReactRef,
            selectAll,
            showColumnChooser,
            sortingOrder,
            stopEditingWhenCellsLoseFocus,
            suppressDragLeaveHidesColumns,
            suppressMovableColumns,
            suppressMultiSort,
            suppressRowClickSelection,
            suppressGroupRowsSticky,
            suppressRowHoverHighlight,
            treeData
        } = this.props;

        const components = {
            customLoadingCellRenderer: LoadingCellRenderer,
            defaultTooltip: AgGridTooltip
        };

        // GridHeader split into left or right, or can be overridden with customGridHeader
        const columnChooser =
            showColumnChooser && this.calculatedColumnDefs
                ? this.getColumnChooser()
                : null;

        let TableComponent = TableToolbar;
        if (gridTheme === GRID_THEME_NO_BORDER) {
            TableComponent = InsetTableToolbar;
        } else {
            if (gridTheme === GRID_THEME_NO_BORDER_NO_HEADER) {
                TableComponent = InsetTableNoHeaderToolbar;
            }
        }

        let GridHeader = null;
        if (
            gridHeaderLeft ||
            gridHeaderRight ||
            (showColumnChooser && this.calculatedColumnDefs)
        ) {
            if (gridHeaderTop) {
                GridHeader = (
                    <TableComponent padding={gridHeaderPadding}>
                        <Flexbox flexDirection="column" cssWidth="100%">
                            {gridHeaderTop}
                            <Flexbox
                                alignItems="center"
                                justifyContent="flex-end"
                                flexWrap="wrap"
                            >
                                {gridHeaderLeft}
                                <FlexGrow />
                                <Flexbox alignItems="center">
                                    {gridHeaderRight}
                                    {columnChooser}
                                    {massActions}
                                </Flexbox>
                            </Flexbox>
                        </Flexbox>
                    </TableComponent>
                );
            } else {
                GridHeader = (
                    <TableComponent
                        justifyContent="flex-end"
                        padding={gridHeaderPadding}
                    >
                        {gridHeaderLeft}
                        <FlexGrow />
                        <Flexbox alignItems="center">
                            {gridHeaderRight}
                            {columnChooser}
                            {massActions}
                        </Flexbox>
                    </TableComponent>
                );
            }
        } else {
            if (customGridHeader) {
                GridHeader = customGridHeader;
            }
        }

        // Choose default column definitions
        let defaultColumnDef = defaultColDef;
        if (selectAll) {
            defaultColumnDef = selectAllDefaultColDef;
        }

        // disableStaticMarkup is specific to ag-react-grid
        const agGridParams: GridOptions & { disableStaticMarkup?: boolean } = {
            alignedGrids: alignedGrids,
            blockLoadDebounceMillis: 800,
            columnDefs: this.calculatedColumnDefs,
            components: components,
            debug: debug,
            enableCellTextSelection: enableCellTextSelection,
            enterNavigatesVertically: enterNavigatesVertically,
            enterNavigatesVerticallyAfterEdit:
                enterNavigatesVerticallyAfterEdit,
            defaultColDef: defaultColumnDef,
            disableStaticMarkup: disableStaticMarkup,
            headerHeight: headerHeight,
            localeText: localeText,
            loadingCellRenderer: "customLoadingCellRenderer",
            noRowsOverlayComponent: NoRowsGridOverlay,
            onCellEditingStopped: onCellEditingStopped,
            onColumnMoved: this.onColumnMoved,
            onColumnResized: this.onColumnResized,
            onDragStopped: this.onDragStopped,
            onDisplayedColumnsChanged: this.onDisplayedColumnsChanged,
            onGridReady: this.onGridReady,
            onRowDragEnd: onRowDragEnd,
            onRowGroupOpened: this.onRowGroupOpened,
            onSortChanged: this.doOnSortChanged,
            reactiveCustomComponents: true,
            rowHeight: rowHeight,
            rowModelType: rowModelType,
            sortingOrder: sortingOrder,
            stopEditingWhenCellsLoseFocus: stopEditingWhenCellsLoseFocus,
            suppressBrowserResizeObserver: true,
            suppressColumnVirtualisation:
                process.env.NODE_ENV === "test" ? true : false,
            suppressContextMenu: true,
            suppressDragLeaveHidesColumns: suppressDragLeaveHidesColumns,
            suppressGroupRowsSticky: suppressGroupRowsSticky,
            suppressMovableColumns: suppressMovableColumns,
            suppressMultiSort: suppressMultiSort,
            tooltipShowDelay: 0.325
        };

        if (onSelectionChanged) {
            agGridParams.onSelectionChanged = this.onSelectionChanged;
        }

        if (onRowSelected) {
            agGridParams.onRowSelected = this.onRowSelected;
        }

        if (masterDetail) {
            if (detailCellRenderer) {
                agGridParams.detailCellRenderer = detailCellRenderer;
            }
            agGridParams.detailRowAutoHeight = detailRowAutoHeight;
        }

        if (noRowsOverlayMessage) {
            agGridParams.noRowsOverlayComponentParams = {
                message: noRowsOverlayMessage
            };
        }

        if (this.columnTypes) {
            agGridParams.columnTypes = this.columnTypes;
        }

        if (noRowsOverlayMessage) {
            agGridParams.noRowsOverlayComponentParams = {
                message: noRowsOverlayMessage
            };
        }

        // ensureDomOrder must be true if enableCellTextSelection is true
        if (enableCellTextSelection) {
            agGridParams.ensureDomOrder = true;
        }

        if (getRowId) {
            agGridParams.getRowId = getRowId;
        }

        if (getRowStyle) {
            agGridParams.getRowStyle = getRowStyle;
        }

        if (getRowHeight) {
            agGridParams.getRowHeight = getRowHeight;
        }

        if (rowModelType === SERVER_SIDE) {
            if (this.autoGroupColumnDef) {
                agGridParams.autoGroupColumnDef = this.autoGroupColumnDef;
            }
            if (datasource) {
                agGridParams.serverSideDatasource = datasource;
            }
            if (getServerSideGroupKey) {
                agGridParams.getServerSideGroupKey = getServerSideGroupKey;
            }
            if (isServerSideGroup) {
                agGridParams.isServerSideGroup = isServerSideGroup;
            }
            if (getServerSideGroupKey && isServerSideGroup) {
                agGridParams.isServerSideGroupOpenByDefault =
                    this.isServerSideGroupOpenByDefault;
            }
            agGridParams.treeData = treeData;
        }

        if (rowModelType !== CLIENT_SIDE) {
            agGridParams.cacheBlockSize = pageSize;
        }

        if (rowModelType === CLIENT_SIDE) {
            agGridParams.rowData = rowData;
            agGridParams.treeData = treeData;
            if (rowDragManaged) {
                agGridParams.rowDragManaged = true;
            }
            if (getDataPath) {
                agGridParams.getDataPath = getDataPath;
            }
        }

        if (rowSelection) {
            agGridParams.rowSelection = rowSelection as RowSelection;
            if (rowSelection === ROW_SELECTION_MULTIPLE && treeData) {
                agGridParams.groupSelectsChildren = true;
            }
        }

        if (suppressRowClickSelection) {
            agGridParams.suppressRowClickSelection = suppressRowClickSelection;
        }

        if (suppressRowHoverHighlight) {
            agGridParams.suppressRowHoverHighlight = suppressRowHoverHighlight;
        }

        if (isRowSelectable) {
            agGridParams.isRowSelectable = isRowSelectable;
        }

        return (
            <TableAndHeaderWrapper noBorder={true} ref={this.setContainerRef}>
                {GridHeader}
                <TableWrapper gridTheme={gridTheme}>
                    <AgGridReact
                        {...agGridParams}
                        ref={
                            onAgReactRef
                                ? newRef => {
                                      onAgReactRef(newRef);
                                  }
                                : undefined
                        }
                    />
                </TableWrapper>
            </TableAndHeaderWrapper>
        );
    }
}

const mapStateToProps = (state: RootState, ownProps: DataGridOwnProps) => {
    return {
        allowableActions: get(state, "init.allowableActions", {}),
        columnPreferences: getPreference(ownProps.id, state.preferences, {}),
        localeText: get(state, "init.aggridLocaleText")
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setPreference: (prefName: string, value: any) => {
            dispatch(setPreference(prefName, value));
        },
        writePreference: (
            name: string,
            value: ColumnPreferences,
            doSet = false
        ) => {
            dispatch(writePreference(name, value, doSet));
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true
});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(AgDataGrid));
