import get from "lodash.get";
import isNil from "lodash.isnil";
import type { AnyAction } from "redux";
import i18n from "common/i18n";
import {
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import { error, success } from "common/shell/state/toastActions";
import { isEmptyString } from "common/util/lang";
import { reduceObject } from "common/util/object";
import { AGGREGATE_OFFER_LABEL } from "aggregation/aggregationUtils";
import { reduceCreateTestEventItem } from "event/state/eventsStateUtils";
import { CREATE_TEST } from "filter/state/filterActions";
import { buildTestStatusFilter } from "filter/utils/showFilterUtils";
import { buildPrimaryMetricRangeFilter } from "common/metrics/primaryMetricUtils";
import type { CreateTestViewState } from "offers/components/CreateTestView";
import type {
    CreateTestCreateModel,
    CreateTestRequestModel
} from "offers/state/createTestStateUtils";
import { overviewPage } from "overview/state/overviewActions";
import { CREATE_TEST_PAGE } from "shell/state/pageActions";
import type { ActionOrThunk, Actions, RootState } from "store";

export const CONTROL_OFFERS_DRAWER = "CONTROL_OFFERS_DRAWER";

// action types
export const CLEAR_CREATE_TEST_INFO = "CLEAR_CREATE_TEST_INFO";
export const CLOSE_CREATE_TEST = "CLOSE_CREATE_TEST";
export const CLOSE_REQUEST_TEST = "CLOSE_REQUEST_TEST";
export const CREATE_TEST_SUCCESS = "CREATE_TEST_SUCCESS";
export const OPEN_REQUEST_TEST = "OPEN_REQUEST_TEST";
export const RECEIVE_CREATE_TEST_INFO = "RECEIVE_CREATE_TEST_INFO";
export const RECEIVE_DRAWER_CONTROL_OFFERS = "RECEIVE_DRAWER_CONTROL_OFFERS";
export const RECEIVE_REQUEST_TEST_MESSAGE = "RECEIVE_REQUEST_TEST_MESSAGE";
export const REQUEST_DRAWER_CONTROL_OFFERS = "REQUEST_DRAWER_CONTROL_OFFERS";
export const REQUEST_TEST_SUCCESS = "REQUEST_TEST_SUCCESS";
export const UPDATE_CREATE_TEST_DRAWER_SORT = "UPDATE_CREATE_TEST_DRAWER_SORT";

// action creators
export const createTestPage = (eventId?: string | null): AnyAction => {
    if (!isNil(eventId) && !isEmptyString(eventId)) {
        return {
            type: CREATE_TEST_PAGE,
            payload: { eventId }
        };
    }
    return {
        type: CREATE_TEST_PAGE
    };
};

export const clearCreateTestInfo = (): AnyAction => {
    return {
        type: CLEAR_CREATE_TEST_INFO
    };
};

export const closeCreateTest = (): AnyAction => {
    return {
        type: CLOSE_CREATE_TEST
    };
};

export const openRequestTest = (): AnyAction => {
    return {
        type: OPEN_REQUEST_TEST
    };
};

export const closeRequestTest = (): AnyAction => {
    return {
        type: CLOSE_REQUEST_TEST
    };
};
export const requestTestSuccess = (): AnyAction => {
    return {
        type: REQUEST_TEST_SUCCESS
    };
};

export const createTestSuccess = (campaignEntityId: string): AnyAction => {
    return {
        type: CREATE_TEST_SUCCESS,
        campaignEntityId: campaignEntityId
    };
};

export const receiveCreateTestInfo = (json: any): AnyAction => ({
    type: RECEIVE_CREATE_TEST_INFO,
    request: json
});

const receiveRequestTestMessage = (json: any): AnyAction => ({
    type: RECEIVE_REQUEST_TEST_MESSAGE,
    message: json.emailMessage
});

export const receiveDrawerControlOffers = (json: any): AnyAction => ({
    type: RECEIVE_DRAWER_CONTROL_OFFERS,
    eventOffers: json
});

export const requestDrawerControlOffers = (): AnyAction => ({
    type: REQUEST_DRAWER_CONTROL_OFFERS
});

export const updateCreateTestDrawerSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => ({
    type: UPDATE_CREATE_TEST_DRAWER_SORT,
    sortBy: sortBy,
    sortOrder: sortOrder
});

// thunks
export const getCreateTestInfo = (eventId: string) => {
    return makeRequestThunk("api/matrix/test/create", {
        okDispatchFunc: receiveCreateTestInfo,
        notOkDispatchFunc: (
            response: any,
            json: Json,
            state: RootState
        ): Actions | undefined | null => {
            if (
                json.objectType === "ErrorDto" &&
                json.message === "Access denied."
            ) {
                // redirect to overview if access is denied
                return overviewPage();
            }
        },
        preRequestFunc: clearCreateTestInfo,
        queryParamsFunc: function (state: RootState): any {
            return { eventId };
        }
    });
};

const buildRequestBodyFromState = (
    state: CreateTestViewState
): CreateTestCreateModel => {
    return {
        campaignName: state.campaignName,
        controlOffers: state.selectedControlOffers,
        holidayId: get(state, "selectedHoliday.value"),
        pageId: get(state, "selectedPage.value", ""),
        platformId: get(state, "selectedPlatform.value", ""),
        ppgIds: state.selectedPPGs,
        regionIds: state.selectedRegions,
        retailerIds: state.selectedRetailers
    };
};

export const validateCreateTestInfo = (
    request: CreateTestRequestModel,
    state: CreateTestViewState
) => {
    return makeRequestThunk("api/matrix/test/validate", {
        bodyFunc: function () {
            return {
                ...request,
                ...buildRequestBodyFromState(state),
                fieldSet: "readFields",
                objectType: "model/CreateTestRequestModel"
            };
        },
        method: METHOD_POST,
        okDispatchFunc: receiveCreateTestInfo,
        preRequestFunc: clearCreateTestInfo,
        notOkDispatchFunc: (
            response: any,
            json: Json,
            state: RootState
        ): Actions | undefined | null => {
            if (json.objectType === "ErrorDto") {
                return error(json.message);
            }
        }
    });
};

export const createTest = (request: any) => {
    return makeRequestThunk("api/matrix/test/create", {
        bodyFunc: function () {
            return {
                ...reduceObject(
                    request,
                    "campaignName",
                    "controlOffers",
                    "holidayId",
                    "pageId",
                    "platformId",
                    "ppgIds",
                    "regionIds",
                    "retailerIds"
                ),
                fieldSet: "createFields",
                objectType: "model/CreateTestRequestModel"
            };
        },
        isCancellable: false,
        method: METHOD_POST,
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            actions.push(createTestSuccess(json.message));
            return actions;
        },
        notOkDispatchFunc: (
            response: any,
            json: Json,
            state: RootState
        ): Actions | undefined | null => {
            if (json.objectType === "ErrorDto") {
                return error(json.message);
            }
        }
    });
};

export const getRequestMessage = (request: any) => {
    return makeRequestThunk("api/matrix/test/message", {
        bodyFunc: function () {
            return {
                ...request,
                fieldSet: "readFields",
                objectType: "model/CreateTestRequestModel"
            };
        },
        method: METHOD_POST,
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            return [receiveRequestTestMessage(json)];
        }
    });
};

export const requestTest = (requestTestMessage: string) => {
    return makeRequestThunk("api/matrix/test/request", {
        bodyFunc: function (state) {
            return {
                emailMessage: requestTestMessage,
                objectType: "RequestTestRequest"
            };
        },
        isCancellable: false,
        method: METHOD_POST,
        okDispatchFunc: (): Actions => {
            return [
                success(i18n.t("opportunities.request_a_test_successful")),
                requestTestSuccess()
            ];
        },
        notOkDispatchFunc: (): AnyAction => {
            return error(i18n.t("opportunities.request_a_test_error"));
        }
    });
};

export function fetchDrawerControlOffers(
    search: string,
    offset: number,
    selected: string[],
    exclude: string[],
    componentState: any
) {
    return makeRequestThunk("api/matrix/test/flaggedEvents", {
        bodyFunc: function (state: RootState): any {
            return {
                endDate: get(state, [
                    "filters",
                    CREATE_TEST,
                    "date",
                    "endDate"
                ]),
                excludedOfferIdentifiers: exclude,
                groupBundledAds: true,
                groupByFields: [AGGREGATE_OFFER_LABEL].join(","),
                hideNA: true,
                holidayIds: get(state, ["filters", CREATE_TEST, "holidays"]),
                holidaysInTest: get(componentState, "selectedHoliday.value")
                    ? [get(componentState, "selectedHoliday.value")]
                    : [],
                includedOfferIdentifiers: selected,
                managerIds: get(state, ["filters", CREATE_TEST, "managers"]),
                objectType: "FlaggedEventsRequest",
                offset: offset,
                pageSize: get(state, "createTest.drawer.pageSize"),
                ppgsInTest: componentState.selectedPPGs,
                primaryMetricFilter: buildPrimaryMetricRangeFilter(
                    get(state, ["filters", CREATE_TEST, "min"]),
                    get(state, ["filters", CREATE_TEST, "max"]),
                    get(state, "init.primaryMetric")
                ),
                productGroupIds: get(state, [
                    "filters",
                    CREATE_TEST,
                    "products"
                ]),
                regionIds: get(state, ["filters", CREATE_TEST, "regions"]),
                regionsInTest: componentState.selectedRegions,
                retailerIds: get(state, ["filters", CREATE_TEST, "retailers"]),
                retailersInTest: componentState.selectedRetailers,
                search: search,
                sortBy: get(state, "createTest.drawer.sortBy"),
                sortOrder: get(state, "createTest.drawer.sortOrder"),
                startDate: get(state, [
                    "filters",
                    CREATE_TEST,
                    "date",
                    "startDate"
                ]),
                testStatusFilter: buildTestStatusFilter(
                    get(state, ["filters", CREATE_TEST, "showTested"], [])
                ),
                timePeriod: {
                    objectType: "MatrixTimePeriod",
                    value: get(state, ["filters", CREATE_TEST, "date", "time"])
                }
            };
        },
        method: METHOD_POST,
        preRequestFunc: requestDrawerControlOffers,
        okDispatchFunc: receiveDrawerControlOffers,
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.items.map(reduceCreateTestEventItem),
                filteredCount: json.totalItems
            };
        },
        showLoadMask: false
    });
}
