import get from "lodash.get";
import type { AnyAction } from "redux";
import {
    makeRequestThunk,
    METHOD_GET,
    METHOD_POST
} from "common/shell/state/requestActions";
import {
    COMPLIANCE_COMPLIANCE,
    getComplianceDetailRequest
} from "compliance/state/complianceStateUtils";
import { COMPLIANCE_OVERVIEW } from "filter/state/filterActions";
import {
    COMPLIANT,
    NO_GUIDANCE,
    NON_COMPLIANT,
    UNKNOWN
} from "filter/utils/showFilterUtils";
import type { ComplianceValue } from "filter/utils/showFilterUtils";
import {
    COMPLIANCE_EVENT_PAGE,
    COMPLIANCE_OVERVIEW_PAGE
} from "shell/state/pageActions";
import type { RootState } from "store";

export const RECEIVE_COMPLIANCE_EVENT = "RECEIVE_COMPLIANCE_EVENT";
export const RECEIVE_COMPLIANCE_GUIDANCE = "RECEIVE_COMPLIANCE_GUIDANCE";
export const RECEIVE_COMPLIANCE_OVERVIEW = "RECEIVE_COMPLIANCE_OVERVIEW";
export const REQUEST_COMPLIANCE_EVENT = "REQUEST_COMPLIANCE_EVENT";
export const REQUEST_COMPLIANCE_OVERVIEW = "REQUEST_COMPLIANCE_OVERVIEW";
export const UPDATE_COMPLIANCE_EVENT = "UPDATE_COMPLIANCE_EVENT";
export const UPDATE_COMPLIANCE_OVERVIEW_GUIDANCE =
    "UPDATE_COMPLIANCE_OVERVIEW_GUIDANCE";
export const UPDATE_COMPLIANCE_OVERVIEW_AGGREGATE =
    "UPDATE_COMPLIANCE_OVERVIEW_AGGREGATE";
export const UPDATE_COMPLIANCE_OVERVIEW_OFFSET =
    "UPDATE_COMPLIANCE_OVERVIEW_OFFSET";
export const UPDATE_COMPLIANCE_OVERVIEW_SORT =
    "UPDATE_COMPLIANCE_OVERVIEW_SORT";

export const compliancePage = (): AnyAction => ({
    type: COMPLIANCE_OVERVIEW_PAGE
});

export const complianceEventPage = (eventId: string): AnyAction => ({
    type: COMPLIANCE_EVENT_PAGE,

    payload: {
        eventId
    }
});

export const requestComplianceOverview = (): AnyAction => ({
    type: REQUEST_COMPLIANCE_OVERVIEW
});

export const receiveComplianceOverview = (json: Json): AnyAction => ({
    type: RECEIVE_COMPLIANCE_OVERVIEW,
    overview: json
});

export const requestComplianceEvent = (): AnyAction => ({
    type: REQUEST_COMPLIANCE_EVENT
});

export const receiveComplianceEvent = (json: Json): AnyAction => ({
    type: RECEIVE_COMPLIANCE_EVENT,
    event: json
});

export const receiveComplianceGuidance = (
    field: string,
    json: Json
): AnyAction => ({
    type: RECEIVE_COMPLIANCE_GUIDANCE,
    field: field,
    guidance: json
});

export const updateComplianceOverviewOffset = (offset: number): AnyAction => ({
    type: UPDATE_COMPLIANCE_OVERVIEW_OFFSET,
    offset: offset
});

export const updateEvent = (): AnyAction => ({
    type: UPDATE_COMPLIANCE_EVENT
});

export const updateComplianceOverviewSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => ({
    type: UPDATE_COMPLIANCE_OVERVIEW_SORT,
    sortBy: sortBy,
    sortOrder: sortOrder
});

export const updateComplianceOverviewGuidance = (
    guidances: string[]
): AnyAction => ({
    type: UPDATE_COMPLIANCE_OVERVIEW_GUIDANCE,
    guidances: guidances
});

export const updateComplianceOverviewAggregate = (
    groupByFields: string
): AnyAction => ({
    type: UPDATE_COMPLIANCE_OVERVIEW_AGGREGATE,
    groupByFields: groupByFields
});

export const processOutFields = (
    outFields: string[],
    state: RootState
): string => {
    const hasSalesData = get(
        state,
        "init.preloadedEntities.metadata.matrix.hasSalesData"
    );
    const processedOutFields: string[] = [];
    let eventCompliances =
        get(state, ["filters", COMPLIANCE_OVERVIEW, "showCompliance"]) || [];
    outFields.forEach(field => {
        if (field === COMPLIANCE_COMPLIANCE) {
            if (eventCompliances.length === 0) {
                eventCompliances = [
                    COMPLIANT,
                    NON_COMPLIANT,
                    NO_GUIDANCE,
                    UNKNOWN
                ];
            }
            eventCompliances.forEach((eventCompliance: ComplianceValue) => {
                switch (eventCompliance) {
                    case COMPLIANT:
                        processedOutFields.push("eventWeeksCompliant");
                        if (hasSalesData)
                            {processedOutFields.push("promoSalesCompliant");}
                        break;
                    case NON_COMPLIANT:
                        processedOutFields.push("eventWeeksNonCompliant");
                        if (hasSalesData)
                            {processedOutFields.push("promoSalesNonCompliant");}
                        break;
                    case NO_GUIDANCE:
                        processedOutFields.push("eventWeeksNoGuidance");
                        if (hasSalesData)
                            {processedOutFields.push("promoSalesNoGuidance");}
                        break;
                    case UNKNOWN:
                        processedOutFields.push("eventWeeksUnknown");
                        if (hasSalesData)
                            {processedOutFields.push("promoSalesUnknown");}
                        break;
                    default:
                        break;
                }
            });
        } else {
            processedOutFields.push(field);
        }
    });
    return processedOutFields.join(",");
};

// thunks
export function fetchComplianceOverview() {
    return makeRequestThunk("api/matrix/compliance", {
        bodyFunc: function (state: RootState): any {
            return getComplianceDetailRequest(state);
        },
        method: METHOD_POST,
        preRequestFunc: requestComplianceOverview,
        okDispatchFunc: receiveComplianceOverview,
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.items.map((item: any) => {
                    return Object.assign({}, item, {
                        maxEventWeeks: json.maxEventWeeks
                    });
                }),
                filteredCount: json.totalItems
            };
        },
        showLoadMask: false
    });
}

export function fetchComplianceEvent(entityId: string) {
    return makeRequestThunk("api/entity/PromotionEvent/" + entityId, {
        method: METHOD_GET,
        queryParamsFunc: function (state: RootState): any {
            return {
                modelAndFieldSet: "PromotionEventModel/complianceDetail"
            };
        },
        preRequestFunc: requestComplianceEvent,
        okDispatchFunc: receiveComplianceEvent
    });
}

export function updateComplianceGuidance(
    field: string,
    entityId: string,
    status: string
) {
    return makeRequestThunk(
        "/api/guideline/compliance/update/" + entityId + "/" + status,
        {
            isCancellable: false,
            method: METHOD_POST,
            okDispatchFunc: json => receiveComplianceGuidance(field, json)
        }
    );
}

export function resetComplianceGuidance(field: string, entityId: string) {
    return makeRequestThunk("/api/guideline/compliance/reset/" + entityId, {
        isCancellable: false,
        method: METHOD_POST,
        okDispatchFunc: json => receiveComplianceGuidance(field, json)
    });
}

export const getComplianceOverviewExportRequest = (
    state: RootState,
    outFields: string[]
): any => {
    const overviewRequest = getComplianceDetailRequest(state);
    const outFieldsString = processOutFields(outFields, state);
    return {
        ...overviewRequest,
        offset: 0,
        outFields: outFieldsString,
        pageSize: -1
    };
};
