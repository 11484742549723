import styled from "styled-components/macro";
import {
    avoidPageBreakProps,
    borderProps,
    flexboxProps,
    marginProps,
    paddingProps,
    positionProps,
    sizeProps,
    textProps
} from "common/components/styled/util";

export type FlexboxProps = {
    backgroundColor?: string;
    color?: string;
    cursor?: string;
    gap?: string;
    lineHeight?: string | number;
    outline?: string;
    overflow?: string;
    overflowX?: string;
    overflowY?: string;
    rowGap?: string;
    textAlign?: string;
} & borderPropsType &
    flexboxPropsType &
    marginPropsType &
    paddingPropsType &
    positionPropsType &
    sizePropsType &
    textPropsType;

const Flexbox = styled.div<FlexboxProps>`
    ${props => props.onClick && "cursor: pointer;"}
    ${props =>
        props.backgroundColor && `background-color: ${props.backgroundColor};`}
    ${props => props.cursor && `cursor: ${props.cursor};`}
    ${props => props.gap && `gap: ${props.gap};`}
    ${props => props.lineHeight && `line-height: ${props.lineHeight};`}
    ${props => props.outline && `outline: ${props.outline};`}
    ${props => props.overflow && `overflow: ${props.overflow};`}
    ${props => props.overflowX && `overflow-x: ${props.overflowX};`}
    ${props => props.overflowY && `overflow-y: ${props.overflowY};`}
    ${props => props.rowGap && `row-gap: ${props.rowGap};`}
    ${props => props.textAlign && `text-align: ${props.textAlign};`}
    ${avoidPageBreakProps}
    ${borderProps}
    ${flexboxProps}
    ${marginProps}
    ${paddingProps}
    ${positionProps}
    ${sizeProps}
    ${textProps}
`;
Flexbox.displayName = "Flexbox";

export default Flexbox;
