import * as React from "react";
import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import styled from "styled-components/macro";
import Button, {
    BUTTON_TYPE_PRIMARY,
    BUTTON_TYPE_SECONDARY,
    BUTTON_TYPE_TERTIARY
} from "common/components/Button";
import ButtonDropdown from "common/components/ButtonDropdown";
import Checkbox from "common/components/Checkbox";
import { POSITION_CENTER } from "common/components/Dropdown";
import ExpandingDropdown from "common/components/ExpandingDropdown";
import type { MenuSubOptionType } from "common/components/ExpandingDropdown";
import FullPageHeader from "common/components/FullPageHeader";
import { ROW_SELECTION_MULTIPLE } from "common/components/grid/AgDataGrid";
import Input from "common/components/styled/Input";
import NumberInput, {
    currencyFormatterBound,
    currencyParserBound,
    percentFormatter,
    percentParser
} from "common/components/NumberInput";
import { Radio, RadioGroup } from "common/components/RadioGroup";
import SplitButton from "common/components/SplitButton";
import { INPUT_WIDTH } from "common/components/styled/DialogPage";
import FieldSetWithHeader from "common/components/styled/FieldSetWithHeader";
import Flexbox from "common/components/styled/Flexbox";
import {
    AddPlaceholder,
    ButtonBar,
    CustomSelect,
    FormRow,
    SELECT_WIDTH
} from "common/components/styled/Form";
import Header from "common/components/styled/Header";
import { FieldLabel } from "common/components/styled/Label";
import { List } from "common/components/styled/ListWithBorder";
import TruncatedDiv from "common/components/styled/TruncatedDiv";
import TooltipWrapper from "common/components/styled/TooltipWrapper";
import SvgIcon, { ClickableSvgIcon } from "common/components/SvgIcon";
import theme from "common/components/theme";
import Tooltip from "common/components/Tooltip";
import { NUMBER_INPUT_STEP } from "common/components/WidgetModal";
import { ReactComponent as DeleteIcon } from "common/icons/Delete.svg";
import { ReactComponent as DownCaretIcon } from "common/icons/DownCaret.svg";
import { ReactComponent as EditIcon } from "common/icons/Edit.svg";
import { ReactComponent as HelpIcon } from "common/icons/Help.svg";
import { ReactComponent as PlusIcon } from "common/icons/Plus.svg";
import { goBack } from "common/shell/state/navigationActions";
import { METHOD_POST, METHOD_PUT } from "common/shell/state/requestActions";
import type { PostPutMethod } from "common/shell/state/requestActions";
import {
    translateDatabaseToDisplayForPercent,
    translateDisplayToDatabaseForPercent
} from "common/util/format";
import ImageUploadModal from "administration/images/components/ImageUploadModal";
import SmartImageSetSelector from "administration/images/components/SmartImageSetSelector";
import { NotYetImplemented } from "campaign/components/campaignViewUtils";
import { campaignOffersPage } from "campaign/state/campaignActions";
import PPGSelectorDrawer, {
    SELECT_PPG,
    USE_PPG_FROM_CAMPAIGN
} from "concept/components/PPGSelectorDrawer";
import type { SelectMode } from "concept/components/PPGSelectorDrawer";
import SubstitutionTokens from "concept/components/SubstitutionTokens";
import {
    fetchConceptDesignByOfferTemplate,
    fetchConceptDesignByPromotion,
    fetchConceptPreview,
    fetchConceptSentence,
    fetchTestPlatformOfferTemplates,
    writeConcept
} from "concept/state/conceptActions";
import {
    OFFER_ADVANCED_CREATE_PAGE,
    OFFER_EDIT_PAGE
} from "shell/state/pageActions";
import type { AppDispatch, RootState } from "store";

const USE_DEFAULT_IMAGE = "USE_DEFAULT_IMAGE";
const TEST_IMAGES = "TEST_IMAGES";

const ADD_FROM_IMAGE_LIBRARY = "ADD_FROM_IMAGE_LIBRARY";
const UPLOAD_IMAGE = "UPLOAD_IMAGE";

export const CREATE = "CREATE";
const CREATE_ANOTHER = "CREATE_ANOTHER";
const CREATE_ANOTHER_COPY = "CREATE_ANOTHER_COPY";
export const EDIT = "EDIT";

export type CreateEditMode =
    | typeof CREATE
    | typeof CREATE_ANOTHER
    | typeof CREATE_ANOTHER_COPY
    | typeof EDIT;

const CONCEPT_VARIABLE_TYPE_FLOAT = "FLOAT";
//const CONCEPT_VARIABLE_TYPE_FREE_FORM_PRODUCT = "FREE_FORM_PRODUCT";
const CONCEPT_VARIABLE_TYPE_INTEGER = "INTEGER";
const CONCEPT_VARIABLE_TYPE_PERCENT = "PERCENT";
const CONCEPT_VARIABLE_TYPE_PRODUCT = "PRODUCT";
//const CONCEPT_VARIABLE_TYPE_QUANTITY_OFFER = "QUANTITY_OFFER";
//const CONCEPT_VARIABLE_TYPE_SHARED_QUANTITY_OFFER = "SHARED_QUANTITY_OFFER";
//const CONCEPT_VARIABLE_TYPE_SPEND_OFFER = "SPEND_OFFER";
const CONCEPT_VARIABLE_TYPE_STRING = "STRING";

const ASTERISK = "*";

/*
const ThumbnailStack = styled("div")`
    position: relative;
    margin-bottom: 8px;
`;

const ThumbnailStackRight = styled("div")`
    background-color: ${theme.disabledListItem};
    border: 1px solid ${theme.listSelectedBg};
    transform: rotate(5deg);
    position: absolute;
    height: 108px;
    width: 108px;
`;

const ThumbnailStackLeft = styled("div")`
    background-color: ${theme.listSelectedBg};
    position: absolute;
    transform: rotate(10deg);
    height: 108px;
    width: 108px;
`;
*/

const ProductImagesWrapper = styled(Flexbox)`
    border: 1px solid ${theme.untested};
    margin-bottom: 8px;
    margin-top: 8px;
    min-height: 148px;
    overflow-x: auto;
    padding: 8px;
    width: 684px;
`;

const HeaderSection = styled(Flexbox)`
    color: ${theme.iconMenu};
    font-size: 16px;
`;

export const ImageWrapper = styled(Flexbox)`
    background-color: ${theme.background};
    border: 1px solid ${theme.untested};
    height: 100px;
    overflow: hidden;
    padding: 3px;
    position: relative;
    width: 100px;
`;

export const ThumbnailImage = styled("img")`
    background-color: #ffffff;
    margin: auto;
`;

const FormWrapper = styled(Flexbox)`
    background-color: ${theme.background};
    border: 1px solid ${theme.border};
    flex-direction: column;
    height: 100%;
    margin: 16px;
    overflow: auto;
    padding: 16px;
`;

const VariableName = styled("div")`
    color: ${theme.defaultGreyText};
    font-size: 13px;
`;

const VariableValid = styled("div")<{ isValid: boolean }>`
    background-color: ${props => (props.isValid ? theme.success : theme.alert)};
    margin-right: 2px;
    width: 5px;
`;

const RequiredVariableAsterisk = styled("div")`
    color: ${theme.alert};
    font-size: 20px;
    height: 26px;
    margin-left: -1px;
`;

const SentenceText = styled(Flexbox)`
    align-items: center;
    font-size: 16px;
    height: 38px;
    white-space: nowrap;
`;

const PPGItem = styled(Flexbox)`
    align-items: center;
    background-color: ${theme.background};
    justify-content: space-between;
    padding: 0 16px;
`;

const SINGLE_PPG = "SINGLE_PPG";
const MULTIPLE_PPGS = "MULTIPLE_PPGS";

type OfferAdvancedFormViewProps = PropsFromRedux & WithTranslation;

type PpgSelectorDrawerOptions = {
    ppgSelectMode: SelectMode;
    ppgSelected: CampaignProductGroup | null;
    ppgSelectedCampaign: CampaignProductGroup | null;
    ppgSelectedThirdParty: CampaignProductGroup | null;
    ppgsSelected: AdminProductGroup[];
};

type OfferAdvancedFormViewState = {
    blacklistedPromotions: BlacklistedPromotionDesign[];
    conceptDesign: ConceptDesign | null;
    createEditMode: CreateEditMode | null;
    disableImageParametersOnClickOutside: boolean;
    freeShippingCost: number | null;
    freeShippingSelected: boolean;
    invalidConceptVariableDataType: string | null;
    imageParametersOpen: boolean;
    isImageUploadOpen: boolean;
    isPPGDrawerEdit: boolean;
    isPPGDrawerOpen: boolean;
    isSmartImageSetSelectorOpen: boolean;
    offerTypeOptions: OptionType[];
    offerTypes: Dictionary<any>;
    ppgSelectorDrawerOptions: Dictionary<PpgSelectorDrawerOptions>;
    ppgSelectorDrawerCVDIdentifier: string | null;
    selectedOfferTemplate: MenuSubOptionType | null;
    selectedOfferType: OptionTypeOrNullOrUndefined;
    selectedSmartImageSets: SmartImageSet[];
    sentence: OfferTemplateSentence | null;
    showRequiredParametersOnly: boolean;
};

const defaultState: OfferAdvancedFormViewState = {
    blacklistedPromotions: [],
    conceptDesign: null,
    createEditMode: null,
    disableImageParametersOnClickOutside: false,
    freeShippingCost: null,
    freeShippingSelected: false,
    imageParametersOpen: false,
    invalidConceptVariableDataType: null,
    isImageUploadOpen: false,
    isPPGDrawerEdit: false,
    isPPGDrawerOpen: false,
    isSmartImageSetSelectorOpen: false,
    offerTypeOptions: [],
    offerTypes: [],
    ppgSelectorDrawerOptions: {},
    ppgSelectorDrawerCVDIdentifier: null,
    selectedOfferTemplate: null,
    selectedOfferType: null,
    selectedSmartImageSets: [],
    sentence: null,
    showRequiredParametersOnly: true
};

class OfferAdvancedFormView extends React.Component<
    OfferAdvancedFormViewProps,
    OfferAdvancedFormViewState
> {
    static displayName = "OfferAdvancedFormView";

    state = defaultState;

    componentDidMount(): void {
        const { page, promotionEntityId } = this.props;
        if (promotionEntityId) {
            this.fetchConceptDesignByPromotion(
                promotionEntityId,
                page === OFFER_ADVANCED_CREATE_PAGE
            );
        } else {
            this.fetchOfferTemplatesForCreate();
        }
    }

    fetchOfferTemplatesForCreate = (
        testPlatformOfferTemplate?: TestPlatformOfferTemplate
    ) => {
        const { fetchOfferTemplates } = this.props;
        fetchOfferTemplates().then(
            ({ success, result }: ResponseSuccessResult) => {
                const offerTypes = Object.values(result);
                const offerTypeOptions = offerTypes.map(
                    (offerTypeElement: any) => {
                        return {
                            label: offerTypeElement.offerType.displayName,
                            value: offerTypeElement.offerType.value
                        };
                    }
                );
                let selectedOfferType = null;
                let selectedOfferTemplate = null;
                if (testPlatformOfferTemplate) {
                    // is this create and is there a promotionEntityId = createUsingExisting
                    // need to set up selectedOfferType and selectedOfferStructure
                    selectedOfferType = {
                        label:
                            testPlatformOfferTemplate.offerTemplate.offerType
                                .displayName,
                        value:
                            testPlatformOfferTemplate.offerTemplate.offerType
                                .value
                    };
                    selectedOfferTemplate = {
                        label:
                            testPlatformOfferTemplate.offerTemplate.displayName,
                        value: testPlatformOfferTemplate.offerTemplate.entityId,
                        // @ts-expect-error - adding an extra parameter to menu option
                        testPlatformOfferTemplateId:
                            // @ts-expect-error - adding an extra parameter to menu option
                            testPlatformOfferTemplate.entityId
                    };
                }
                this.setState({
                    offerTypeOptions: offerTypeOptions,
                    offerTypes: result,
                    selectedOfferType,
                    selectedOfferTemplate
                });
            }
        );
    };

    fetchConceptDesignByPromotion = (
        promotionEntityId: string,
        requeryOfferTemplates: boolean
    ) => {
        const { fetchConceptDesignByPromotion } = this.props;
        fetchConceptDesignByPromotion(promotionEntityId).then(
            ({ success, result }: ResponseSuccessResult) => {
                // TO DO
                // remove when backend implements API changes
                const invalidConceptVariableDataType = this.isOfferStructureNotYetImplemented(
                    result
                );
                const ppgSelectorDrawerOptions = this.processCampaignProductGroups(
                    result
                );
                this.setState({
                    conceptDesign: result,
                    invalidConceptVariableDataType: invalidConceptVariableDataType,
                    ppgSelectorDrawerOptions: ppgSelectorDrawerOptions
                });
                if (requeryOfferTemplates) {
                    // need to query for offer types and templates
                    this.fetchOfferTemplatesForCreate(
                        result.testPlatformOfferTemplate
                    );
                }
                this.fetchSentence(result);
            }
        );
    };

    componentWillUnmount() {
        this.setState(defaultState);
    }

    processCampaignProductGroups = (conceptDesign: ConceptDesign) => {
        const ppgSelectorDrawerOptions: Dictionary<PpgSelectorDrawerOptions> = {};
        if (conceptDesign) {
            conceptDesign.variables.forEach(
                (variable: ConceptVariableDesign) => {
                    if (variable.type.value === CONCEPT_VARIABLE_TYPE_PRODUCT) {
                        ppgSelectorDrawerOptions[variable.identifier] = {
                            ppgSelectMode: USE_PPG_FROM_CAMPAIGN,
                            ppgSelected: null,
                            ppgSelectedCampaign: variable.productGroups[0],
                            ppgSelectedThirdParty: null,
                            ppgsSelected: []
                        };
                    }
                }
            );
        }
        return ppgSelectorDrawerOptions;
    };

    checkValidityAndPreview = () => {
        const { fetchConceptPreview } = this.props;
        const { conceptDesign } = this.state;
        const valid = this.validate();
        if (valid && conceptDesign) {
            fetchConceptPreview(conceptDesign).then(
                ({ success, result }: ResponseSuccessResult) => {
                    this.setState({
                        blacklistedPromotions: result.promotions
                    });
                }
            );
        } else {
            this.setState({
                blacklistedPromotions: []
            });
        }
    };

    getSelectedValue = (variable: ConceptVariableDesign) => {
        let selectedValue;
        let value;
        if (variable.selectedValues.length > 0) {
            value = variable.selectedValues[0].value;
        }
        switch (variable.type.value) {
            case CONCEPT_VARIABLE_TYPE_FLOAT:
                if (value) {
                    selectedValue = parseFloat(value);
                }
                break;
            case CONCEPT_VARIABLE_TYPE_INTEGER:
                if (value) {
                    selectedValue = parseInt(value);
                }
                break;
            case CONCEPT_VARIABLE_TYPE_PERCENT:
                if (value) {
                    selectedValue = parseFloat(value);
                }
                break;
            case CONCEPT_VARIABLE_TYPE_PRODUCT:
                if (variable.productGroups.length > 0) {
                    selectedValue = variable.productGroups[0].name;
                }
                break;
            case CONCEPT_VARIABLE_TYPE_STRING:
                selectedValue = value;
                break;
        }
        return selectedValue;
    };

    isDesignVariableValid = (type: string, value: any) => {
        let isValid = false;
        switch (type) {
            case CONCEPT_VARIABLE_TYPE_FLOAT:
            case CONCEPT_VARIABLE_TYPE_INTEGER:
            case CONCEPT_VARIABLE_TYPE_PERCENT:
                if (value > 0) {
                    isValid = true;
                }
                break;
            case CONCEPT_VARIABLE_TYPE_PRODUCT:
                if (value) {
                    isValid = true;
                }
                break;
            case CONCEPT_VARIABLE_TYPE_STRING:
                if (value) {
                    isValid = true;
                }
                break;
        }
        return isValid;
    };

    validate = (): boolean => {
        const { conceptDesign } = this.state;
        let isValid = false;
        if (conceptDesign) {
            isValid = true;

            if (conceptDesign.freeShippingSelected) {
                if (conceptDesign.freeShippingCost === null) {
                    return false;
                }
            }

            if (conceptDesign.testImages && conceptDesign.images.length < 1) {
                return false;
            }

            const variables = conceptDesign.variables;
            for (let i = 0; i < variables.length; i += 1) {
                const variable = variables[i];
                if (variable.required) {
                    const selectedValue = this.getSelectedValue(variable);
                    isValid = this.isDesignVariableValid(
                        variable.type.value,
                        selectedValue
                    );
                    if (!isValid) {
                        break;
                    }
                }
            }
        }
        return isValid;
    };

    onBackClick = (): void => {
        const { campaignEntityId, campaignOffersPage, goBack } = this.props;
        goBack(() => {
            campaignOffersPage(campaignEntityId);
        });
    };

    openPPGDrawer = (identifier: string): void => {
        this.setState({
            isPPGDrawerEdit: false,
            isPPGDrawerOpen: true,
            ppgSelectorDrawerCVDIdentifier: identifier
        });
    };

    closePPGDrawer = (): void => {
        this.setState({
            isPPGDrawerOpen: false,
            ppgSelectorDrawerCVDIdentifier: null
        });
    };

    onEditPPG = (identifier: string): void => {
        this.setState({
            isPPGDrawerEdit: true,
            isPPGDrawerOpen: true,
            ppgSelectorDrawerCVDIdentifier: identifier
        });
    };

    fetchSentence = (conceptDesign: ConceptDesign | null) => {
        const { fetchConceptSentence } = this.props;
        if (conceptDesign) {
            fetchConceptSentence(conceptDesign).then(
                ({ success, result }: ResponseSuccessResult) => {
                    const { conceptDesign } = this.state;
                    const mustBuyApplicable =
                        result?.mustBuyApplicable ?? false;
                    this.setState(
                        {
                            sentence: result,
                            conceptDesign: Object.assign({}, conceptDesign, {
                                testMustBuy: !mustBuyApplicable
                                    ? false
                                    : conceptDesign?.testMustBuy,
                                testMustBuyVersusOpen: !mustBuyApplicable
                                    ? false
                                    : conceptDesign?.testMustBuyVersusOpen
                            })
                        },
                        () => {
                            this.checkValidityAndPreview();
                        }
                    );
                }
            );
        }
    };

    onRemovePPG = (identifier: string) => {
        const { conceptDesign, ppgSelectorDrawerOptions } = this.state;
        if (conceptDesign) {
            const newConceptDesign = cloneDeep(conceptDesign);
            const variableToChange = this.findConceptVariable(
                newConceptDesign,
                identifier
            );
            if (variableToChange) {
                variableToChange.productGroups = [];
            }
            const newPpgSelectorDrawerOptions = cloneDeep(
                ppgSelectorDrawerOptions
            );
            delete newPpgSelectorDrawerOptions[identifier];
            this.setState(
                {
                    conceptDesign: newConceptDesign,
                    ppgSelectorDrawerOptions: newPpgSelectorDrawerOptions
                },
                () => {
                    this.fetchSentence(newConceptDesign);
                }
            );
        }
    };

    onPPGSelect = (
        campaignProductGroup: CampaignProductGroup | null,
        ppgSelectMode: SelectMode,
        ppgsSelected: AdminProductGroup[],
        ppgSelected: CampaignProductGroup | null,
        ppgSelectedCampaign: CampaignProductGroup | null,
        ppgSelectedThirdParty: CampaignProductGroup | null
    ): void => {
        const {
            conceptDesign,
            ppgSelectorDrawerCVDIdentifier,
            ppgSelectorDrawerOptions
        } = this.state;
        if (conceptDesign && ppgSelectorDrawerCVDIdentifier) {
            const productGroups: CampaignProductGroup[] = [];
            if (campaignProductGroup) {
                productGroups.push(campaignProductGroup);
            }
            const newConceptDesign = cloneDeep(conceptDesign);
            const variableToChange = this.findConceptVariable(
                newConceptDesign,
                ppgSelectorDrawerCVDIdentifier
            );
            if (variableToChange) {
                variableToChange.productGroups = productGroups;
            }

            const newPpgSelectorDrawerOptions = cloneDeep(
                ppgSelectorDrawerOptions
            );
            newPpgSelectorDrawerOptions[ppgSelectorDrawerCVDIdentifier] = {
                ppgSelected,
                ppgSelectedCampaign,
                ppgSelectedThirdParty,
                ppgsSelected,
                ppgSelectMode
            };

            this.setState(
                {
                    conceptDesign: newConceptDesign,
                    ppgSelectorDrawerOptions: newPpgSelectorDrawerOptions
                },
                () => {
                    this.fetchSentence(newConceptDesign);
                }
            );
        }
    };

    onChangeOfferType = (
        selectedOfferType: OptionTypeOrNullOrUndefined
    ): void => {
        this.setState({
            invalidConceptVariableDataType: null,
            selectedOfferType,
            selectedOfferTemplate: null,
            conceptDesign: null,
            sentence: null
        });
    };

    isOfferStructureNotYetImplemented = (
        conceptDesign: ConceptDesign
    ): string | null => {
        let cvdt = null;
        const nonImplementedDataTypes: string[] = [
            "QUANTITY_OFFER",
            "SHARED_QUANTITY_OFFER",
            "SPEND_OFFER"
        ];
        if (conceptDesign && conceptDesign.variables.length > 0) {
            conceptDesign.variables.forEach(
                (variable: ConceptVariableDesign) => {
                    if (nonImplementedDataTypes.includes(variable.type.value)) {
                        cvdt = variable.type.displayName;
                    } else {
                        if (
                            variable.quantityVariable &&
                            nonImplementedDataTypes.includes(
                                variable.quantityVariable.type.value
                            )
                        ) {
                            cvdt = variable.quantityVariable.type.displayName;
                        }
                    }
                }
            );
        }
        return cvdt;
    };

    onChangeOfferTemplate = (
        selectedOfferTemplate: MenuSubOptionType | null
    ): void => {
        const { fetchConceptDesignByOfferTemplate } = this.props;
        if (selectedOfferTemplate) {
            this.setState({ selectedOfferTemplate });
            fetchConceptDesignByOfferTemplate(
                // @ts-expect-error selectedOfferTemplate overloaded with testPlatformOfferTemplateId
                selectedOfferTemplate.testPlatformOfferTemplateId
            ).then(({ success, result }: ResponseSuccessResult) => {
                // TO DO
                // remove when backend implements API changes
                const invalidConceptVariableDataType = this.isOfferStructureNotYetImplemented(
                    result
                );
                this.setState({
                    conceptDesign: result,
                    invalidConceptVariableDataType: invalidConceptVariableDataType
                });
                this.fetchSentence(result);
            });
        }
    };

    onSubmit = (createEditMode: CreateEditMode): void => {
        const { page, writeConcept } = this.props;
        const {
            conceptDesign,
            offerTypeOptions,
            offerTypes,
            selectedOfferTemplate,
            selectedOfferType
        } = this.state;
        if (conceptDesign) {
            const method = page === OFFER_EDIT_PAGE ? METHOD_PUT : METHOD_POST;
            writeConcept(conceptDesign, method, createEditMode).then(
                ({ success, result }: ResponseSuccessResult) => {
                    if (success) {
                        if (createEditMode === CREATE_ANOTHER) {
                            this.setState(
                                Object.assign({}, defaultState, {
                                    offerTypeOptions,
                                    offerTypes
                                })
                            );
                        } else {
                            if (createEditMode === CREATE_ANOTHER_COPY) {
                                this.setState(
                                    Object.assign({}, defaultState, {
                                        offerTypeOptions,
                                        offerTypes,
                                        selectedOfferTemplate,
                                        selectedOfferType
                                    }),
                                    () => {
                                        this.fetchConceptDesignByPromotion(
                                            result.firstResult,
                                            false
                                        );
                                    }
                                );
                            }
                        }
                    }
                }
            );
        }
    };

    getTemplateOptions = (
        selectedOfferType: OptionTypeOrNullOrUndefined,
        single = true
    ) => {
        const { offerTypes } = this.state;
        const options: any[] = [];
        if (selectedOfferType) {
            const offerType = offerTypes[selectedOfferType.value];
            if (offerType) {
                offerType.offerTemplates.forEach(
                    (testPlatformOfferTemplate: any) => {
                        if (
                            single &&
                            !testPlatformOfferTemplate.offerTemplate
                                .multipleProducts
                        ) {
                            options.push({
                                label:
                                    testPlatformOfferTemplate.offerTemplate
                                        .displayName,
                                value:
                                    testPlatformOfferTemplate.offerTemplate
                                        .entityId,
                                testPlatformOfferTemplateId:
                                    testPlatformOfferTemplate.entityId
                            });
                        } else if (
                            !single &&
                            testPlatformOfferTemplate.offerTemplate
                                .multipleProducts
                        ) {
                            options.push({
                                label:
                                    testPlatformOfferTemplate.offerTemplate
                                        .displayName,
                                value:
                                    testPlatformOfferTemplate.offerTemplate
                                        .entityId,
                                testPlatformOfferTemplateId:
                                    testPlatformOfferTemplate.entityId
                            });
                        }
                    }
                );
            }
        }
        return options;
    };

    createConceptVariableDesignValue = (text: string) => {
        return {
            displayName: text,
            displayValue: "",
            entityId: null,
            fieldSet: "designFields",
            isNone: false,
            objectType: "model/ConceptVariableDesignValueModel",
            value: text
        };
    };

    findConceptVariable = (
        conceptDesign: ConceptDesign,
        identifier: string
    ) => {
        let foundConceptVariable = null;
        const variables = conceptDesign.variables;
        for (let i = 0; i < variables.length; i += 1) {
            const variable = variables[i];
            if (variable.identifier === identifier) {
                foundConceptVariable = variable;
                break;
            } else {
                if (variable.quantityVariable) {
                    if (variable.quantityVariable.identifier === identifier) {
                        foundConceptVariable = variable.quantityVariable;
                        break;
                    }
                }
            }
        }
        return foundConceptVariable;
    };

    onIntegerChange = (identifier: string, value: number | null): void => {
        const { conceptDesign } = this.state;
        if (conceptDesign) {
            const values: ConceptVariableDesignValue[] = [];
            if (value) {
                values.push(
                    this.createConceptVariableDesignValue(value.toString())
                );
            }
            const newConceptDesign = cloneDeep(conceptDesign);
            const variableToChange = this.findConceptVariable(
                newConceptDesign,
                identifier
            );
            if (variableToChange) {
                variableToChange.selectedValues = values;
            }
            this.setState(
                {
                    conceptDesign: newConceptDesign
                },
                () => {
                    this.fetchSentence(newConceptDesign);
                }
            );
        }
    };

    renderInteger = (conceptVariableDesign: ConceptVariableDesign) => {
        const { showRequiredParametersOnly } = this.state;
        let selectedValue = null;
        if (conceptVariableDesign.selectedValues.length > 0) {
            selectedValue = parseInt(
                conceptVariableDesign.selectedValues[0].value,
                10
            );
        }
        let asterisk = "";
        if (conceptVariableDesign.required) {
            asterisk = ASTERISK;
        }
        let isValid = false;
        if (selectedValue && selectedValue > 0) {
            isValid = true;
        }

        if (!conceptVariableDesign.required && showRequiredParametersOnly) {
            return null;
        }
        return (
            <Flexbox
                key={"integer-" + conceptVariableDesign.identifier}
                flexDirection="column"
                marginRight="16px"
            >
                <Flexbox flexDirection="row" alignItems="center">
                    <RequiredVariableAsterisk>
                        {asterisk}
                    </RequiredVariableAsterisk>
                    <VariableName>
                        {conceptVariableDesign.displayName}
                    </VariableName>
                </Flexbox>
                <Flexbox flexDirection="row">
                    <VariableValid isValid={isValid} />
                    <NumberInput
                        boxSizing="border-box"
                        cssWidth="100px"
                        min={1}
                        name={conceptVariableDesign.name}
                        onChange={this.onIntegerChange.bind(
                            null,
                            conceptVariableDesign.identifier
                        )}
                        precision={0}
                        step={NUMBER_INPUT_STEP}
                        value={selectedValue}
                    />
                </Flexbox>
            </Flexbox>
        );
    };

    onFloatChange = (identifier: string, value: number | null): void => {
        const { conceptDesign } = this.state;
        if (conceptDesign) {
            const values: ConceptVariableDesignValue[] = [];
            if (value) {
                values.push(
                    this.createConceptVariableDesignValue(value.toString())
                );
            }
            const newConceptDesign = cloneDeep(conceptDesign);
            const variableToChange = this.findConceptVariable(
                newConceptDesign,
                identifier
            );
            if (variableToChange) {
                variableToChange.selectedValues = values;
            }
            this.setState(
                {
                    conceptDesign: newConceptDesign
                },
                () => {
                    this.fetchSentence(newConceptDesign);
                }
            );
        }
    };

    renderFloat = (conceptVariableDesign: ConceptVariableDesign) => {
        const { conceptDesign, showRequiredParametersOnly } = this.state;
        const currencySymbol =
            conceptDesign?.financialDataSetCurrencySymbol ?? "$";
        let selectedValue = null;
        if (conceptVariableDesign.selectedValues.length > 0) {
            selectedValue = parseFloat(
                conceptVariableDesign.selectedValues[0].value
            );
        }
        let asterisk = "";
        if (conceptVariableDesign.required) {
            asterisk = ASTERISK;
        }
        let isValid = false;
        if (selectedValue && selectedValue > 0) {
            isValid = true;
        }

        if (!conceptVariableDesign.required && showRequiredParametersOnly) {
            return null;
        }
        return (
            <Flexbox
                key={"float-" + conceptVariableDesign.identifier}
                flexDirection="column"
                marginRight="16px"
            >
                <Flexbox flexDirection="row" alignItems="center">
                    <RequiredVariableAsterisk>
                        {asterisk}
                    </RequiredVariableAsterisk>
                    <VariableName>
                        {conceptVariableDesign.displayName}
                    </VariableName>
                </Flexbox>
                <Flexbox flexDirection="row">
                    <VariableValid isValid={isValid} />
                    <NumberInput
                        boxSizing="border-box"
                        cssWidth="100px"
                        formatter={currencyFormatterBound.bind(
                            null,
                            currencySymbol
                        )}
                        min={0}
                        name={conceptVariableDesign.name}
                        onChange={this.onFloatChange.bind(
                            null,
                            conceptVariableDesign.identifier
                        )}
                        parser={currencyParserBound.bind(null, currencySymbol)}
                        step={NUMBER_INPUT_STEP}
                        value={selectedValue}
                    />
                </Flexbox>
            </Flexbox>
        );
    };

    onPercentChange = (identifier: string, value: number | null): void => {
        const { conceptDesign } = this.state;
        if (conceptDesign) {
            const values: ConceptVariableDesignValue[] = [];
            if (value) {
                values.push(
                    this.createConceptVariableDesignValue(
                        translateDisplayToDatabaseForPercent(value).toString()
                    )
                );
            }
            const newConceptDesign = cloneDeep(conceptDesign);
            const variableToChange = this.findConceptVariable(
                newConceptDesign,
                identifier
            );
            if (variableToChange) {
                variableToChange.selectedValues = values;
            }
            this.setState(
                {
                    conceptDesign: newConceptDesign
                },
                () => {
                    this.fetchSentence(newConceptDesign);
                }
            );
        }
    };

    renderPercent = (conceptVariableDesign: ConceptVariableDesign) => {
        const { showRequiredParametersOnly } = this.state;

        let selectedValue = null;
        if (conceptVariableDesign.selectedValues.length > 0) {
            selectedValue = parseFloat(
                conceptVariableDesign.selectedValues[0].value
            );
            selectedValue = translateDatabaseToDisplayForPercent(selectedValue);
        }
        let asterisk = "";
        if (conceptVariableDesign.required) {
            asterisk = ASTERISK;
        }
        let isValid = false;
        if (selectedValue && selectedValue > 0) {
            isValid = true;
        }

        if (!conceptVariableDesign.required && showRequiredParametersOnly) {
            return null;
        }
        return (
            <Flexbox
                key={"percent-" + conceptVariableDesign.identifier}
                flexDirection="column"
                marginRight="16px"
            >
                <Flexbox flexDirection="row" alignItems="center">
                    <RequiredVariableAsterisk>
                        {asterisk}
                    </RequiredVariableAsterisk>
                    <VariableName>
                        {conceptVariableDesign.displayName}
                    </VariableName>
                </Flexbox>
                <Flexbox flexDirection="row">
                    <VariableValid isValid={isValid} />
                    <NumberInput
                        boxSizing="border-box"
                        cssWidth="100px"
                        formatter={percentFormatter}
                        min={0}
                        name={conceptVariableDesign.name}
                        onChange={this.onPercentChange.bind(
                            null,
                            conceptVariableDesign.identifier
                        )}
                        parser={percentParser}
                        step={NUMBER_INPUT_STEP}
                        value={selectedValue}
                    />
                </Flexbox>
            </Flexbox>
        );
    };

    renderProduct = (conceptVariableDesign: ConceptVariableDesign) => {
        const { t } = this.props;
        const { showRequiredParametersOnly } = this.state;

        let selectedValue = null;
        if (conceptVariableDesign.productGroups.length > 0) {
            selectedValue =
                conceptVariableDesign.productGroups[0].offerDisplayName;
        }
        let asterisk = "";
        if (conceptVariableDesign.required) {
            asterisk = ASTERISK;
        }
        let isValid = false;
        if (selectedValue) {
            isValid = true;
        }

        if (!conceptVariableDesign.required && showRequiredParametersOnly) {
            return null;
        }

        let ControlContent = null;
        if (!selectedValue) {
            ControlContent = (
                <AddPlaceholder
                    onClick={this.openPPGDrawer.bind(
                        null,
                        conceptVariableDesign.identifier
                    )}
                    title={t("concept.select_ppg")}
                    trackingComponentLabel="Select PPG"
                    wrapperProps={{
                        cssHeight: "38px",
                        cssWidth: SELECT_WIDTH,
                        marginRight: "16px",
                        marginBottom: "0px",
                        paddingLeft: "0px",
                        paddingRight: "0px"
                    }}
                />
            );
        } else {
            ControlContent = (
                <List cssWidth={SELECT_WIDTH} marginRight="16px">
                    <PPGItem justifyContent="center" cssHeight="38px">
                        <Tooltip
                            checkOverflow={true}
                            cssWidth="100%"
                            ignoreHeightOverflow={true}
                            text={selectedValue}
                        />
                        <Flexbox>
                            <Flexbox alignItems="center">
                                <ClickableSvgIcon
                                    color={theme.iconMenu}
                                    cursor="pointer"
                                    hoverColor={theme.text}
                                    icon={EditIcon}
                                    marginLeft="8px"
                                    onClick={this.onEditPPG.bind(
                                        null,
                                        conceptVariableDesign.identifier
                                    )}
                                    trackingComponentLabel={[
                                        OfferAdvancedFormView.displayName,
                                        "Edit Selected PPG"
                                    ]}
                                />
                            </Flexbox>
                            <Flexbox alignItems="center">
                                <ClickableSvgIcon
                                    color={theme.iconMenu}
                                    cursor="pointer"
                                    hoverColor={theme.text}
                                    icon={DeleteIcon}
                                    marginLeft="8px"
                                    onClick={this.onRemovePPG.bind(
                                        null,
                                        conceptVariableDesign.identifier
                                    )}
                                    trackingComponentLabel={[
                                        OfferAdvancedFormView.displayName,
                                        "Delete Selected PPG"
                                    ]}
                                />
                            </Flexbox>
                        </Flexbox>
                    </PPGItem>
                </List>
            );
        }

        return (
            <Flexbox
                key={"product-" + conceptVariableDesign.identifier}
                flexDirection="column"
            >
                <Flexbox flexDirection="row" alignItems="center">
                    <RequiredVariableAsterisk>
                        {asterisk}
                    </RequiredVariableAsterisk>
                    <VariableName>
                        {conceptVariableDesign.displayName}
                    </VariableName>
                </Flexbox>
                <Flexbox flexDirection="row">
                    <VariableValid isValid={isValid} />
                    {ControlContent}
                </Flexbox>
            </Flexbox>
        );
    };

    onStringChange = (
        identifier: string,
        event: React.SyntheticEvent<HTMLInputElement>
    ): void => {
        const { conceptDesign } = this.state;
        if (conceptDesign) {
            const text = event.currentTarget.value;
            const values: ConceptVariableDesignValue[] = [];
            if (text) {
                values.push(this.createConceptVariableDesignValue(text));
            }
            const newConceptDesign = cloneDeep(conceptDesign);
            const variableToChange = this.findConceptVariable(
                newConceptDesign,
                identifier
            );
            if (variableToChange) {
                variableToChange.selectedValues = values;
            }
            this.setState(
                {
                    conceptDesign: newConceptDesign
                },
                () => {
                    this.fetchSentence(newConceptDesign);
                }
            );
        }
    };

    renderString = (conceptVariableDesign: ConceptVariableDesign) => {
        const { showRequiredParametersOnly } = this.state;

        let selectedValue = "";
        if (conceptVariableDesign.selectedValues.length > 0) {
            selectedValue = conceptVariableDesign.selectedValues[0].value;
        }
        let asterisk = "";
        if (conceptVariableDesign.required) {
            asterisk = ASTERISK;
        }
        const isValid = true;

        if (!conceptVariableDesign.required && showRequiredParametersOnly) {
            return null;
        }
        return (
            <Flexbox
                flexDirection="column"
                marginRight="16px"
                key={"string-" + conceptVariableDesign.identifier}
            >
                <Flexbox flexDirection="row" alignItems="center">
                    <RequiredVariableAsterisk>
                        {asterisk}
                    </RequiredVariableAsterisk>
                    <VariableName>
                        {conceptVariableDesign.displayName}
                    </VariableName>
                </Flexbox>
                <Flexbox flexDirection="row">
                    <VariableValid isValid={isValid} />
                    <Input
                        cssWidth={INPUT_WIDTH}
                        name={conceptVariableDesign.name}
                        onChange={this.onStringChange.bind(
                            null,
                            conceptVariableDesign.identifier
                        )}
                        type="text"
                        value={selectedValue}
                    />
                </Flexbox>
            </Flexbox>
        );
    };

    renderSentenceText = (text: string, index: number) => {
        const spacer = "";
        return (
            <Flexbox
                key={"sentence-" + index}
                flexDirection="column"
                marginRight="16px"
            >
                <Flexbox flexDirection="row" alignItems="center">
                    <RequiredVariableAsterisk>
                        {spacer}
                    </RequiredVariableAsterisk>
                    <VariableName>{spacer}</VariableName>
                </Flexbox>
                <SentenceText>{text}</SentenceText>
            </Flexbox>
        );
    };

    /*
    renderImageContent = () => {
        const { page, t } = this.props;
        const { conceptDesign, sentence } = this.state;

        if (page === OFFER_EDIT_PAGE) {
            return null;
        }

        let imageUrl = "";
        let imageName = "";
        let imageCount = 0;
        let sentenceImageUrl = "";
        let sentenceImageName = "";
        let sentenceImageCount = 0;
        if (sentence && sentence.defaultImages.length > 0) {
            sentenceImageUrl = sentence.defaultImages[0].THUMBNAILUrl;
            sentenceImageName = sentence.defaultImages[0].displayName;
            sentenceImageCount = sentence.defaultImages.length;
        }
        const testImages = conceptDesign?.testImages ?? false;
        if (testImages) {
            const cdImages = conceptDesign?.images ?? [];
            if (cdImages.length > 0) {
                imageUrl = cdImages[0].THUMBNAILUrl;
                imageName = cdImages[0].displayName;
                imageCount = cdImages.length;
            }
            const testDefaultImages = conceptDesign?.testDefaultImages ?? true;
            if (testDefaultImages) {
                imageCount += sentenceImageCount;
            }
        } else {
            imageUrl = sentenceImageUrl;
            imageName = sentenceImageName;
            imageCount = sentenceImageCount;
        }

        let ImageContent = (
            <ImageWrapper>
                <ThumbnailImage src={imageUrl} />
            </ImageWrapper>
        );
        if (imageCount > 1) {
            ImageContent = (
                <ThumbnailStack>
                    <ThumbnailStackLeft />
                    <ThumbnailStackRight />
                    <ImageWrapper>
                        <ThumbnailImage src={imageUrl} />
                    </ImageWrapper>
                </ThumbnailStack>
            );
        }

        if (imageUrl) {
            return (
                <FieldSetWithHeader
                    marginRight="16px"
                    paddingBlockEnd="16px"
                    paddingBlockStart="8px"
                    paddingInlineEnd="16px"
                    paddingInlineStart="16px"
                >
                    <legend>{t("general.product_image")}</legend>
                    <Flexbox
                        flexDirection="column"
                        alignItems="center"
                        flexWrap="wrap"
                        cssWidth="106px"
                    >
                        {ImageContent}
                        {imageName && (
                            <Tooltip>
                                <TruncatedDiv
                                    alignSelf="center"
                                    fontSize="13px"
                                    maxWidth="108px"
                                >
                                    {imageName}
                                </TruncatedDiv>
                                <TooltipWrapper maxWidth="350px">
                                    <div>{imageName}</div>
                                </TooltipWrapper>
                            </Tooltip>
                        )}
                        <Flexbox flexDirection="row">
                            {imageCount > 1 && (
                                <Link
                                    marginRight="8px"
                                    onClick={this.onEditImageParameters}
                                    trackingComponentLabel={
                                        (OfferAdvancedFormView.displayName,
                                        "Image Count")
                                    }
                                >
                                    {t("image.x_image", { count: imageCount })}
                                </Link>
                            )}
                            <ClickableSvgIcon
                                color={theme.iconMenu}
                                cursor="pointer"
                                hoverColor={theme.text}
                                icon={EditIcon}
                                onClick={this.onEditImageParameters}
                                trackingComponentLabel={[
                                    OfferAdvancedFormView.displayName,
                                    "Edit Image"
                                ]}
                            />
                        </Flexbox>
                    </Flexbox>
                </FieldSetWithHeader>
            );
        } else {
            return (
                <FieldSetWithHeader
                    marginRight="16px"
                    paddingBlockEnd="16px"
                    paddingBlockStart="8px"
                    paddingInlineEnd="16px"
                    paddingInlineStart="16px"
                >
                    <legend>{t("common:general.image")}</legend>
                    <SvgIcon
                        icon={NoImageIcon}
                        height="100px"
                        width="100px"
                        color={theme.iconMenu}
                    />
                </FieldSetWithHeader>
            );
        }
        //        return null;
    };
    */

    renderFinancials = () => {
        const { t } = this.props;
        const { blacklistedPromotions } = this.state;
        if (blacklistedPromotions.length > 0) {
            const blacklistedPromotion = blacklistedPromotions[0];
            return (
                <React.Fragment>
                    <HeaderSection marginTop="16px" marginBottom="16px">
                        {t("concept.offer_financials")}
                    </HeaderSection>
                    <Flexbox>
                        <FormRow fieldLabelWidth="100px" marginRight="24px">
                            <FieldLabel marginBottom="0px">
                                {t("promotion.discount_percent")}
                            </FieldLabel>
                            {blacklistedPromotion.discountPercentFormatPercent}
                        </FormRow>
                        <FormRow fieldLabelWidth="120px" marginRight="24px">
                            <FieldLabel marginBottom="0px">
                                {t("promotion.total_discount")}
                            </FieldLabel>
                            {blacklistedPromotion.totalDollarsOffFormatCurrency}
                        </FormRow>
                    </Flexbox>
                </React.Fragment>
            );
        }
        return null;
    };

    renderSentence = () => {
        const { conceptDesign, sentence } = this.state;
        const items: React.ReactNode[] = [];
        if (conceptDesign && sentence) {
            const segments = sentence.offerTemplateLinks;
            segments.forEach((link: OfferTemplateLink, index: number) => {
                const text = link.text.trim();
                if (text) {
                    if (link.identifier) {
                        const conceptVariable = this.findConceptVariable(
                            conceptDesign,
                            link.identifier
                        );
                        if (conceptVariable) {
                            switch (conceptVariable.type.value) {
                                case CONCEPT_VARIABLE_TYPE_FLOAT:
                                    items.push(
                                        this.renderFloat(conceptVariable)
                                    );
                                    break;
                                case CONCEPT_VARIABLE_TYPE_INTEGER:
                                    items.push(
                                        this.renderInteger(conceptVariable)
                                    );
                                    break;
                                case CONCEPT_VARIABLE_TYPE_PERCENT:
                                    items.push(
                                        this.renderPercent(conceptVariable)
                                    );
                                    break;
                                case CONCEPT_VARIABLE_TYPE_PRODUCT:
                                    items.push(
                                        this.renderProduct(conceptVariable)
                                    );
                                    break;
                                case CONCEPT_VARIABLE_TYPE_STRING:
                                    items.push(
                                        this.renderString(conceptVariable)
                                    );
                                    break;
                            }
                        }
                    } else {
                        // total hack
                        if (
                            text === "PPG A" ||
                            text === "PPG B" ||
                            text === "PPG C"
                        ) {
                            const conceptVariable = this.findConceptVariableHack(
                                conceptDesign,
                                text
                            );
                            if (conceptVariable) {
                                items.push(this.renderProduct(conceptVariable));
                            }
                        } else {
                            items.push(this.renderSentenceText(text, index));
                        }
                    }
                }
            });
        }
        return items;
    };

    findConceptVariableHack = (
        conceptDesign: ConceptDesign,
        linkText: string
    ) => {
        let foundConceptVariable = null;
        const variables = conceptDesign.variables;
        for (let i = 0; i < variables.length; i += 1) {
            const variable = variables[i];
            if (variable.displayName === linkText) {
                foundConceptVariable = variable;
                break;
            }
        }
        return foundConceptVariable;
    };

    onChangeShowRequiredParametersOnly = (
        event: React.SyntheticEvent<HTMLInputElement>
    ) => {
        this.setState({
            showRequiredParametersOnly: event.currentTarget.checked
        });
    };

    onChangeTestMustBuy = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { conceptDesign } = this.state;
        const testMustBuy = event.currentTarget.checked;
        this.setState({
            conceptDesign: Object.assign({}, conceptDesign, {
                testMustBuy: testMustBuy,
                testMustBuyVersusOpen: !testMustBuy
                    ? false
                    : conceptDesign?.testMustBuyVersusOpen
            })
        });
    };

    onChangeTestMustBuyVsOpen = (
        event: React.SyntheticEvent<HTMLInputElement>
    ) => {
        const { conceptDesign } = this.state;
        this.setState({
            conceptDesign: Object.assign({}, conceptDesign, {
                testMustBuyVersusOpen: event.currentTarget.checked
            })
        });
    };

    renderMustBuy = () => {
        const { page, t } = this.props;
        const { conceptDesign, sentence } = this.state;
        if (page === OFFER_EDIT_PAGE) {
            return null;
        }
        if (sentence?.mustBuyApplicable) {
            return (
                <React.Fragment>
                    <HeaderSection marginTop="16px">
                        {t("concept.must_buy")}
                    </HeaderSection>
                    <Flexbox flexDirection="column">
                        <Flexbox alignItems="center" marginTop="16px">
                            <Checkbox
                                checked={conceptDesign?.testMustBuy ?? false}
                                label={t("concept.add_must_buy")}
                                name="testMustBuy"
                                onChange={this.onChangeTestMustBuy}
                            />
                            <Tooltip
                                trackingComponentLabel={[
                                    OfferAdvancedFormView.displayName,
                                    "Add Must Buy"
                                ]}
                            >
                                <SvgIcon
                                    color={theme.darkGreyText}
                                    height="16px"
                                    icon={HelpIcon}
                                    marginLeft="8px"
                                    width="16px"
                                />
                                {t("concept.add_must_buy_help_text")}
                            </Tooltip>
                        </Flexbox>
                        {conceptDesign?.testMustBuy && (
                            <Flexbox alignItems="center" marginTop="8px">
                                <Checkbox
                                    checked={
                                        conceptDesign?.testMustBuyVersusOpen
                                    }
                                    label={t("concept.test_must_buy")}
                                    name="testMustBuyVersusOpen"
                                    onChange={this.onChangeTestMustBuyVsOpen}
                                />
                                <Tooltip
                                    trackingComponentLabel={[
                                        OfferAdvancedFormView.displayName,
                                        "Test Must Buy"
                                    ]}
                                >
                                    <SvgIcon
                                        color={theme.darkGreyText}
                                        height="16px"
                                        icon={HelpIcon}
                                        marginLeft="8px"
                                        width="16px"
                                    />
                                    {t("concept.test_must_buy_help_text")}
                                </Tooltip>
                            </Flexbox>
                        )}
                    </Flexbox>
                </React.Fragment>
            );
        }
        return null;
    };

    onChangeFreeShipping = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { conceptDesign } = this.state;
        const freeShippingSelected = event.currentTarget.checked;
        let freeShippingCost: number | null | undefined =
            conceptDesign?.freeShippingCost;
        if (!freeShippingSelected) {
            freeShippingCost = null;
        }
        this.setState({
            conceptDesign: Object.assign({}, conceptDesign, {
                freeShippingSelected,
                freeShippingCost
            })
        });
    };

    onFreeShippingCostChange = (value: number | null): void => {
        const { conceptDesign } = this.state;
        this.setState({
            conceptDesign: Object.assign({}, conceptDesign, {
                freeShippingCost: value
            })
        });
    };

    renderFreeShipping = () => {
        const { t } = this.props;
        const { conceptDesign } = this.state;
        const currencySymbol =
            conceptDesign?.financialDataSetCurrencySymbol ?? "$";
        if (conceptDesign?.freeShippingOptionAvailable) {
            return (
                <React.Fragment>
                    <HeaderSection marginTop="16px">
                        {t("concept.free_shipping")}
                    </HeaderSection>
                    <Flexbox flexDirection="column">
                        <Flexbox alignItems="center" marginTop="16px">
                            <Checkbox
                                checked={
                                    conceptDesign?.freeShippingSelected ?? false
                                }
                                label={t("concept.add_free_shipping")}
                                name="addFreeShipping"
                                onChange={this.onChangeFreeShipping}
                            />
                            <Tooltip
                                trackingComponentLabel={[
                                    OfferAdvancedFormView.displayName,
                                    "Add Free Shipping"
                                ]}
                            >
                                <SvgIcon
                                    color={theme.darkGreyText}
                                    height="16px"
                                    icon={HelpIcon}
                                    marginLeft="8px"
                                    width="16px"
                                />

                                {t("concept.add_free_shipping_info")}
                            </Tooltip>
                        </Flexbox>
                        {conceptDesign?.freeShippingSelected && (
                            <Flexbox alignItems="center" marginTop="8px">
                                <FieldLabel
                                    marginBottom="0px"
                                    marginRight="16px"
                                    required={true}
                                >
                                    {t("concept.shipping_cost")}
                                </FieldLabel>
                                <NumberInput
                                    boxSizing="border-box"
                                    cssWidth="100px"
                                    formatter={currencyFormatterBound.bind(
                                        null,
                                        currencySymbol
                                    )}
                                    min={0}
                                    name="freeShipping"
                                    onChange={this.onFreeShippingCostChange}
                                    parser={currencyParserBound.bind(
                                        null,
                                        currencySymbol
                                    )}
                                    step={NUMBER_INPUT_STEP}
                                    value={conceptDesign?.freeShippingCost}
                                />

                                <Tooltip
                                    trackingComponentLabel={[
                                        OfferAdvancedFormView.displayName,
                                        "Shipping Cost"
                                    ]}
                                >
                                    <SvgIcon
                                        color={theme.darkGreyText}
                                        height="16px"
                                        icon={HelpIcon}
                                        marginLeft="8px"
                                        width="16px"
                                    />
                                    {t("concept.shipping_cost_info")}
                                </Tooltip>
                            </Flexbox>
                        )}
                    </Flexbox>
                </React.Fragment>
            );
        }
        return null;
    };

    openImageUpload = (): void => {
        this.setState({
            isImageUploadOpen: true
        });
    };

    closeImageUpload = (): void => {
        this.setState({
            isImageUploadOpen: false
        });
    };

    openSmartImageSetDrawer = (): void => {
        this.setState({
            isSmartImageSetSelectorOpen: true
        });
    };

    closeSmartImageSetDrawer = (): void => {
        this.setState({
            isSmartImageSetSelectorOpen: false
        });
    };

    onSmartImageSetSelect = (smartImageSets: SmartImageSet[]): void => {
        const { conceptDesign } = this.state;
        const images = this.mapSmartImageSetToConceptVariableImage(
            smartImageSets
        );
        this.setState({
            conceptDesign: Object.assign({}, conceptDesign, { images }),
            isSmartImageSetSelectorOpen: false,
            selectedSmartImageSets: smartImageSets
        });
    };

    onChangeTestImages = (radioValue: string) => {
        const testImages = radioValue === TEST_IMAGES;
        const { conceptDesign } = this.state;
        if (conceptDesign) {
            this.setState({
                conceptDesign: Object.assign({}, conceptDesign, { testImages })
            });
        }
    };

    onChangeTestDefaultImages = (
        event: React.SyntheticEvent<HTMLInputElement>
    ) => {
        const { conceptDesign } = this.state;
        this.setState({
            conceptDesign: Object.assign({}, conceptDesign, {
                testDefaultImages: event.currentTarget.checked
            })
        });
    };

    onRemoveImage = (entityId: string) => {
        const { conceptDesign, selectedSmartImageSets } = this.state;
        const images = conceptDesign?.images ?? [];
        this.setState({
            conceptDesign: Object.assign({}, conceptDesign, {
                images: images.filter(image => {
                    return image.imageId !== entityId;
                })
            }),
            selectedSmartImageSets: selectedSmartImageSets.filter(image => {
                return image.entityId !== entityId;
            })
        });
    };

    onTestImagesAddClick = (addType: string): void => {
        switch (addType) {
            case UPLOAD_IMAGE:
                this.openImageUpload();
                break;
            case ADD_FROM_IMAGE_LIBRARY:
                this.openSmartImageSetDrawer();
                break;
            default:
                break;
        }
    };

    renderImageTesting = () => {
        const { page, t } = this.props;
        const {
            conceptDesign,
            isImageUploadOpen,
            isSmartImageSetSelectorOpen,
            selectedSmartImageSets,
            sentence
        } = this.state;
        if (page === OFFER_EDIT_PAGE) {
            return null;
        }
        if (
            get(
                conceptDesign,
                "testPlatformOfferTemplate.testPlatform.requiresImage"
            )
        ) {
            const testImages = conceptDesign?.testImages ?? false;
            const testDefaultImages = conceptDesign?.testDefaultImages ?? true;
            const selectedTestImages = conceptDesign?.images ?? [];
            const defaultImages: React.ReactNode[] = [];
            if (sentence && sentence.defaultImages.length > 0) {
                sentence.defaultImages.forEach(defaultImage => {
                    const imageName = defaultImage.displayName;
                    defaultImages.push(
                        <Flexbox
                            alignItems="center"
                            cssWidth="106px"
                            flexDirection="column"
                            //                        flexWrap="wrap"
                            key={"image-" + defaultImage.imageId}
                        >
                            <ImageWrapper>
                                <ThumbnailImage
                                    src={defaultImage.THUMBNAILUrl}
                                />
                            </ImageWrapper>
                            {imageName && (
                                <Tooltip>
                                    <TruncatedDiv
                                        alignSelf="center"
                                        fontSize="13px"
                                        maxWidth="108px"
                                    >
                                        {imageName}
                                    </TruncatedDiv>
                                    <TooltipWrapper maxWidth="350px">
                                        <div>{imageName}</div>
                                    </TooltipWrapper>
                                </Tooltip>
                            )}
                        </Flexbox>
                    );
                });
            } else {
                defaultImages.push(
                    <div key={"image-no-default-product-image"}>
                        {t("concept.no_default_product_image")}
                    </div>
                );
            }

            const imagesToTest: React.ReactNode[] = [];
            if (selectedTestImages.length > 0) {
                selectedTestImages.forEach((image: ConceptVariableImage) => {
                    const imageName = image.displayName;
                    imagesToTest.push(
                        <Flexbox
                            alignItems="center"
                            cssWidth="106px"
                            flexDirection="column"
                            //                        flexWrap="wrap"
                            key={"image-" + image.imageId}
                            marginRight="8px"
                        >
                            <ImageWrapper>
                                <ThumbnailImage src={image.THUMBNAILUrl} />
                            </ImageWrapper>
                            {imageName && (
                                <Tooltip>
                                    <TruncatedDiv
                                        alignSelf="center"
                                        fontSize="13px"
                                        maxWidth="108px"
                                    >
                                        {imageName}
                                    </TruncatedDiv>
                                    <TooltipWrapper maxWidth="350px">
                                        <div>{imageName}</div>
                                    </TooltipWrapper>
                                </Tooltip>
                            )}
                            <ClickableSvgIcon
                                color={theme.iconMenu}
                                cursor="pointer"
                                hoverColor={theme.text}
                                icon={DeleteIcon}
                                onClick={this.onRemoveImage.bind(
                                    null,
                                    image.imageId
                                )}
                                trackingComponentLabel={[
                                    OfferAdvancedFormView.displayName,
                                    "Delete Image"
                                ]}
                            />
                        </Flexbox>
                    );
                });
            }

            const TestImagesButton = (
                <Flexbox cssHeight="22px">
                    <SvgIcon
                        color={theme.text}
                        icon={PlusIcon}
                        marginRight="8px"
                    />
                    <SvgIcon
                        color={theme.text}
                        height="8px"
                        icon={DownCaretIcon}
                        width="8px"
                    />
                </Flexbox>
            );
            const testImagesMenuOptions = [
                {
                    label: t("image.upload_image"),
                    value: UPLOAD_IMAGE
                },
                {
                    label: t("image.add_from_image_library"),
                    value: ADD_FROM_IMAGE_LIBRARY
                }
            ];

            const selectedImageMode = testImages
                ? TEST_IMAGES
                : USE_DEFAULT_IMAGE;

            const ImageParameterContent = (
                <React.Fragment>
                    <SmartImageSetSelector
                        closeHandler={this.closeSmartImageSetDrawer}
                        isOpen={isSmartImageSetSelectorOpen}
                        isSingleSelect={false}
                        onSubmit={this.onSmartImageSetSelect}
                        rowSelection={ROW_SELECTION_MULTIPLE}
                        selectedImages={selectedSmartImageSets}
                        trackingComponentLabel={
                            OfferAdvancedFormView.displayName
                        }
                    />
                    <ImageUploadModal
                        closeModalHandler={this.closeImageUpload}
                        isOpen={isImageUploadOpen}
                    />
                    <HeaderSection marginTop="16px" marginBottom="16px">
                        {t("concept.image_testing")}
                    </HeaderSection>
                    <div>{t("concept.do_image_testing")}</div>
                    <RadioGroup
                        changeHandler={this.onChangeTestImages}
                        flexDirection="row"
                        name="selectImageMode"
                        selectedValue={selectedImageMode}
                        trackingComponentLabel={[
                            OfferAdvancedFormView.displayName,
                            "Image Mode"
                        ]}
                    >
                        <Radio
                            label={t(
                                "concept.no_use_single_default_product_image"
                            )}
                            name="defaultImage"
                            value={USE_DEFAULT_IMAGE}
                        />
                        <Radio
                            label={t("concept.yes_test_different_images")}
                            marginLeft="16px"
                            name="testImages"
                            value={TEST_IMAGES}
                        />
                    </RadioGroup>
                    {selectedImageMode === TEST_IMAGES && (
                        <React.Fragment>
                            <Flexbox alignItems="center">
                                <FieldLabel
                                    marginBottom="0px"
                                    marginRight="8px"
                                >
                                    {t("concept.test_images")}
                                </FieldLabel>
                                <ButtonDropdown
                                    customStyle={TestImagesButton}
                                    horizontalPosition={POSITION_CENTER}
                                    menuOptions={testImagesMenuOptions}
                                    onClickHandler={this.onTestImagesAddClick}
                                    trackingComponentLabel="Test Images"
                                    type={BUTTON_TYPE_TERTIARY}
                                />
                            </Flexbox>
                            <ProductImagesWrapper>
                                {imagesToTest}
                            </ProductImagesWrapper>
                            <FieldLabel>
                                {t("image.default_product_image")}
                            </FieldLabel>
                            <Checkbox
                                checked={testDefaultImages}
                                label={t("concept.test_default_product_image")}
                                marginBottom="8px"
                                name={"testDefaultImages"}
                                onChange={this.onChangeTestDefaultImages}
                            />
                            {defaultImages}
                        </React.Fragment>
                    )}
                </React.Fragment>
            );
            return ImageParameterContent;
        }
        return null;
    };

    onSplitButtonClick = (option: string) => {
        switch (option) {
            case CREATE:
                this.onSubmit(CREATE);
                break;
            case CREATE_ANOTHER:
                this.onSubmit(CREATE_ANOTHER);
                break;
            case CREATE_ANOTHER_COPY:
                this.onSubmit(CREATE_ANOTHER_COPY);
                break;
            default:
                break;
        }
    };

    onEditImageParameters = () => {
        this.setState({
            imageParametersOpen: true
        });
    };

    onCloseImageParameters = () => {
        this.setState({
            imageParametersOpen: false
        });
    };

    mapSmartImageSetToConceptVariableImage = (
        smartImageSets: SmartImageSet[]
    ) => {
        // translate SmartImageSet into ConceptVariableImage
        const images: ConceptVariableImage[] = [];
        if (smartImageSets.length > 0) {
            smartImageSets.forEach(smartImageSet => {
                images.push({
                    imageId: smartImageSet.entityId,
                    displayName: smartImageSet.displayName,
                    THUMBNAILUrl: smartImageSet.THUMBNAILUrl,
                    MEDIUMUrl: smartImageSet.MEDIUMUrl,
                    objectType: "model/ConceptVariableImageModel"
                });
            });
        }
        return images;
    };

    render() {
        const { page, t } = this.props;
        const {
            conceptDesign,
            invalidConceptVariableDataType,
            isPPGDrawerEdit,
            isPPGDrawerOpen,
            offerTypeOptions,
            ppgSelectorDrawerOptions,
            ppgSelectorDrawerCVDIdentifier,
            selectedOfferTemplate,
            selectedOfferType,
            sentence,
            showRequiredParametersOnly
        } = this.state;
        const isValid = this.validate();

        let editMode = false;
        let title = t("concept.advanced_offer_creation");
        if (page === OFFER_EDIT_PAGE) {
            editMode = true;
            title = t("offer.edit_offer");
        }

        let ppgSelectMode = SELECT_PPG;
        let ppgSelected = null;
        let ppgSelectedCampaign = null;
        let ppgSelectedThirdParty = null;
        let ppgsSelected: AdminProductGroup[] = [];

        if (ppgSelectorDrawerCVDIdentifier) {
            const currentPpgSelectorDrawerOptions =
                ppgSelectorDrawerOptions[ppgSelectorDrawerCVDIdentifier];

            if (currentPpgSelectorDrawerOptions) {
                ppgSelected = currentPpgSelectorDrawerOptions.ppgSelected;
                ppgSelectedCampaign =
                    currentPpgSelectorDrawerOptions.ppgSelectedCampaign;
                ppgSelectedThirdParty =
                    currentPpgSelectorDrawerOptions.ppgSelectedThirdParty;
                ppgsSelected = currentPpgSelectorDrawerOptions.ppgsSelected;
                ppgSelectMode = currentPpgSelectorDrawerOptions.ppgSelectMode;
            }
        }

        const currencySymbol =
            conceptDesign?.financialDataSetCurrencySymbol ?? "$";

        const createOptions = [
            {
                disabled: false,
                label: t("common:general.create"),
                value: CREATE
            },
            {
                disabled: false,
                label: t("concept.create_and_create_another"),
                value: CREATE_ANOTHER
            },
            {
                disabled: false,
                label: t("concept.create_and_create_another_using_existing"),
                value: CREATE_ANOTHER_COPY
            }
        ];

        const defaultOption = createOptions[0];

        const includeThirdParty = get(
            conceptDesign,
            "testPlatformOfferTemplate.testPlatform.supportsThirdPartyProduct"
        );

        return (
            <React.Fragment>
                <PPGSelectorDrawer
                    closeHandler={this.closePPGDrawer}
                    currencySymbol={currencySymbol}
                    includeThirdParty={includeThirdParty}
                    isControl={false}
                    isOpen={isPPGDrawerOpen}
                    editMode={isPPGDrawerEdit}
                    onSubmit={this.onPPGSelect}
                    selectMode={ppgSelectMode}
                    selectedPPG={ppgSelected}
                    selectedCampaignPPG={ppgSelectedCampaign}
                    selectedThirdParty={ppgSelectedThirdParty}
                    selectedPPGs={ppgsSelected}
                    trackingComponentLabel={OfferAdvancedFormView.displayName}
                />
                <FullPageHeader>
                    <Header>{title}</Header>
                    <ButtonBar>
                        <Button
                            marginRight="16px"
                            onClick={this.onBackClick}
                            text={t("common:general.cancel")}
                            tabIndex={201}
                            trackingComponentLabel={[
                                OfferAdvancedFormView.displayName,
                                "Cancel"
                            ]}
                            type={BUTTON_TYPE_SECONDARY}
                        />
                        {!editMode && (
                            <SplitButton
                                defaultOption={defaultOption}
                                disabled={!isValid}
                                menuOptions={createOptions}
                                onClickHandler={this.onSplitButtonClick}
                                trackingComponentLabel={[
                                    OfferAdvancedFormView.displayName,
                                    "Create"
                                ]}
                            />
                        )}
                        {editMode && (
                            <Button
                                disabled={!isValid}
                                onClick={this.onSubmit.bind(null, EDIT)}
                                text={t("common:general.save")}
                                tabIndex={202}
                                trackingComponentLabel={[
                                    OfferAdvancedFormView.displayName,
                                    "Save"
                                ]}
                                type={BUTTON_TYPE_PRIMARY}
                            />
                        )}
                    </ButtonBar>
                </FullPageHeader>

                <FormWrapper>
                    <Flexbox alignItems="center" flexWrap="wrap" gap="16px">
                        {!editMode && (
                            <React.Fragment>
                                <FieldLabel required={true} marginBottom="0px">
                                    {t("general.offer_type")}
                                </FieldLabel>
                                <CustomSelect
                                    cssWidth="200px"
                                    isSearchable={true}
                                    marginRight="8px"
                                    name="offerType"
                                    options={offerTypeOptions}
                                    onChange={this.onChangeOfferType}
                                    placeholder={t("concept.select_offer_type")}
                                    tabIndex={5}
                                    trackingComponentLabel={[
                                        OfferAdvancedFormView.displayName,
                                        "Offer Type"
                                    ]}
                                    value={selectedOfferType}
                                    zIndexMenu={100}
                                />
                            </React.Fragment>
                        )}
                        {selectedOfferType && !editMode && (
                            <React.Fragment>
                                <FieldLabel required={true} marginBottom="0px">
                                    {t("concept.offer_structure")}
                                </FieldLabel>
                                <ExpandingDropdown
                                    cssHeight="22px"
                                    cssMenuWidth="280px"
                                    cssSubMenuWidth="480px"
                                    cssWidth="500px"
                                    onChange={this.onChangeOfferTemplate}
                                    options={[
                                        {
                                            children: this.getTemplateOptions(
                                                selectedOfferType,
                                                true
                                            ),
                                            label: t("concept.single_ppg"),
                                            value: SINGLE_PPG
                                        },
                                        {
                                            children: this.getTemplateOptions(
                                                selectedOfferType,
                                                false
                                            ),
                                            label: t("concept.multiple_ppgs"),
                                            value: MULTIPLE_PPGS
                                        }
                                    ]}
                                    placeholder={t(
                                        "concept.select_offer_structure"
                                    )}
                                    selected={selectedOfferTemplate}
                                    showSubLabelSelection={true}
                                    trackingComponentLabel={[
                                        OfferAdvancedFormView.displayName,
                                        "Offer Template"
                                    ]}
                                />
                            </React.Fragment>
                        )}
                        {sentence && (
                            <Checkbox
                                checked={showRequiredParametersOnly}
                                label={t(
                                    "concept.show_required_parameters_only"
                                )}
                                name={"disabled"}
                                onChange={
                                    this.onChangeShowRequiredParametersOnly
                                }
                            />
                        )}
                        {sentence && <SubstitutionTokens />}
                    </Flexbox>
                    {invalidConceptVariableDataType && (
                        <NotYetImplemented>
                            Offer structures which contain a concept variable
                            data type of {invalidConceptVariableDataType} are
                            not yet implemented
                        </NotYetImplemented>
                    )}
                    {sentence && (
                        <Flexbox
                            marginTop="16px"
                            flexDirection="column"
                            position="relative"
                        >
                            <Flexbox>
                                <FieldSetWithHeader
                                    paddingBlockEnd="16px"
                                    paddingBlockStart="0px"
                                    paddingInlineEnd="16px"
                                    paddingInlineStart="16px"
                                >
                                    <legend>
                                        {t("concept.offer_structure")}
                                    </legend>

                                    <Flexbox
                                        flexWrap="wrap"
                                        alignContent="center"
                                    >
                                        {this.renderSentence()}
                                    </Flexbox>
                                </FieldSetWithHeader>
                            </Flexbox>
                            {this.renderFinancials()}
                            {this.renderMustBuy()}
                            {this.renderFreeShipping()}
                            {this.renderImageTesting()}
                        </Flexbox>
                    )}
                </FormWrapper>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        campaignEntityId: get(state, "campaign.entityId"),
        page: get(state, "page"),
        promotionEntityId: get(state, "concept.promotionEntityId")
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        campaignOffersPage: (entityId: string) => {
            dispatch(campaignOffersPage(entityId));
        },
        fetchOfferTemplates: () => {
            return dispatch(fetchTestPlatformOfferTemplates());
        },
        fetchConceptDesignByOfferTemplate: (
            testPlatformOfferTemplateId: string
        ) => {
            return dispatch(
                fetchConceptDesignByOfferTemplate(testPlatformOfferTemplateId)
            );
        },
        fetchConceptDesignByPromotion: (promotionEntityId: string) => {
            return dispatch(fetchConceptDesignByPromotion(promotionEntityId));
        },
        fetchConceptPreview: (conceptDesign: ConceptDesign) => {
            return dispatch(fetchConceptPreview(conceptDesign));
        },
        fetchConceptSentence: (conceptDesign: ConceptDesign) => {
            return dispatch(fetchConceptSentence(conceptDesign));
        },
        goToOffersPage: (entityId: string) => {
            dispatch(campaignOffersPage(entityId));
        },
        goBack: (defaultAction: NoArgsHandler) => {
            dispatch(goBack(defaultAction));
        },
        writeConcept: (
            conceptDesign: ConceptDesign,
            method: PostPutMethod,
            createEditMode: CreateEditMode
        ) => {
            return dispatch(
                writeConcept(conceptDesign, method, createEditMode)
            );
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(OfferAdvancedFormView));
