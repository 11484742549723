import * as React from "react";
import type { GetDerivedStateFromProps } from "react";
import isEqual from "lodash.isequal";
import styled from "styled-components/macro";
import {
    RadioCheckboxCheckbox,
    RadioCheckboxMark,
    RadioCheckboxTag
} from "common/components/RadioCheckbox";
import Flexbox from "common/components/styled/Flexbox";
import theme from "common/components/theme";

export const CheckboxGroupWrapper = styled.div`
    margin: 8px 16px;
`;
CheckboxGroupWrapper.displayName = "CheckboxGroupWrapper";

const CheckboxWrapper = styled.div<backgroundColorPropsType>`
    ${props =>
        props.backgroundColor && `background-color: ${props.backgroundColor};`}

    padding: 0px 16px;
`;
CheckboxWrapper.displayName = "CheckboxWrapper";

const TagWrapper = styled.span`
    margin: auto;
`;
TagWrapper.displayName = "TagWrapper";

type CheckboxContentWrapperProps = {
    wrapCheckboxes: boolean;
};
const CheckboxContentWrapper = styled.div<CheckboxContentWrapperProps>`
    ${props => props.wrapCheckboxes && `display: flex;`}
    ${props => props.wrapCheckboxes && `flex-direction: row;`}
    ${props => props.wrapCheckboxes && `flex-wrap: wrap;`}
    width: 100%;
`;
CheckboxContentWrapper.displayName = "CheckboxContentWrapper";

const CheckboxIconWrapper = styled.div`
    display: flex;
    padding: 0px 8px 0px 0px;
`;
CheckboxIconWrapper.displayName = "CheckboxIconWrapper";

type CheckboxProps = {
    applyPadding?: boolean;
    checkboxWidth?: string;
    disabled?: boolean;
    hasHighlight?: boolean;
    icon?: React.ReactNode;
    label: React.ReactNode;
    labelSelectable?: boolean;
    onChange?: (
        checkboxValue: string,
        event: React.ChangeEvent<HTMLInputElement>
    ) => void;
    selectedValues?: string[];
    tag?: React.ReactNode;
    value?: string;
};

export class Checkbox extends React.Component<CheckboxProps> {
    static displayName = "Checkbox";
    checkbox: HTMLInputElement | undefined | null;

    onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if (this.checkbox && event.key === " ") {
            this.checkbox.click();
        }
    };

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onChange } = this.props;
        const selectedValue = this.props.value ? this.props.value : "";
        if (onChange) {
            onChange(selectedValue, event);
        }
    };

    setCheckboxRef = (input?: HTMLInputElement | null) => {
        this.checkbox = input;
    };

    render() {
        const {
            applyPadding = true,
            checkboxWidth,
            disabled,
            hasHighlight = false,
            icon,
            label,
            labelSelectable = true,
            selectedValues,
            tag
        } = this.props;
        const optional: { checked?: boolean } = {};
        const selectedValue = this.props.value ? this.props.value : "";
        if (selectedValues) {
            optional.checked = selectedValues.indexOf(selectedValue) >= 0;
        } else {
            optional.checked = false;
        }
        const wrapperProps: {
            backgroundColor?: string;
        } = {};
        if (hasHighlight) {
            wrapperProps.backgroundColor = theme.cellHighlight;
        }

        const checkboxContent = (
            <RadioCheckboxCheckbox
                checkboxWidth={checkboxWidth}
                disabled={disabled}
                labelSelectable={labelSelectable}
                margin="auto 0px"
                paddingBottom="10px"
                paddingTop="9px"
            >
                <Flexbox alignItems="center">
                    {icon && <CheckboxIconWrapper>{icon}</CheckboxIconWrapper>}
                    <div>{label}</div>
                    <input
                        disabled={disabled}
                        onChange={this.onChange}
                        ref={this.setCheckboxRef}
                        value={selectedValue}
                        tabIndex={-1}
                        type="checkbox"
                        {...optional}
                    />
                    <RadioCheckboxMark
                        onKeyDown={this.onKeyDown}
                        tabIndex={0}
                    />
                </Flexbox>
            </RadioCheckboxCheckbox>
        );
        if (tag) {
            return (
                <RadioCheckboxTag>
                    {checkboxContent}
                    <TagWrapper>{tag}</TagWrapper>
                </RadioCheckboxTag>
            );
        } else {
            if (applyPadding) {
                return (
                    <CheckboxWrapper {...wrapperProps}>
                        {checkboxContent}
                    </CheckboxWrapper>
                );
            } else {
                return checkboxContent;
            }
        }
    }
}

type CheckboxGroupProps = {
    applyCheckboxPadding: boolean;
    changeHandler: (
        filterData: string[],
        event: React.SyntheticEvent<HTMLInputElement>
    ) => void;
    checkboxWidth?: string;
    children: React.ReactNode;
    name: string;
    selectedValues?: string[];
    wrapCheckboxes: boolean;
};

type CheckboxGroupState = {
    selectedValues: string[];
    prevPropsSelectedValues: string[];
};

export class CheckboxGroup extends React.Component<
    CheckboxGroupProps,
    CheckboxGroupState
> {
    static displayName = "CheckboxGroup";
    static defaultProps = {
        applyCheckboxPadding: true,
        wrapCheckboxes: false
    };

    constructor(props: CheckboxGroupProps) {
        super(props);
        const selValues = this.props.selectedValues || [];
        this.state = {
            selectedValues: selValues,
            prevPropsSelectedValues: selValues
        };
    }

    static getDerivedStateFromProps: GetDerivedStateFromProps<
        CheckboxGroupProps,
        CheckboxGroupState
    > = (props, state) => {
        // Any time the selectedValues prop changes reset any parts of state that is tied to that prop
        if (
            props.selectedValues &&
            !isEqual(props.selectedValues, state.prevPropsSelectedValues)
        ) {
            return {
                selectedValues: props.selectedValues,
                prevPropsSelectedValues: props.selectedValues
            };
        }
        return null;
    };

    render() {
        const { wrapCheckboxes } = this.props;
        const checkbox = {
            applyPadding: this.props.applyCheckboxPadding,
            checkboxWidth: this.props.checkboxWidth,
            onChange: this.onCheckboxChange,
            selectedValues: this.state.selectedValues,
            name: this.props.name
        };

        const childrenWithExtraProps = React.Children.map(
            this.props.children,
            child => {
                if (
                    React.isValidElement<CheckboxProps>(child) &&
                    child.type === Checkbox
                ) {
                    return React.cloneElement(child, checkbox);
                }
            }
        );

        return (
            <CheckboxContentWrapper wrapCheckboxes={wrapCheckboxes}>
                {childrenWithExtraProps}
            </CheckboxContentWrapper>
        );
    }

    onCheckboxChange = (
        checkboxValue: string,
        event: React.SyntheticEvent<HTMLInputElement>
    ) => {
        let newValue;
        if (event.currentTarget.checked) {
            newValue = this.state.selectedValues.concat(checkboxValue);
        } else {
            newValue = this.state.selectedValues.filter(
                v => v !== checkboxValue
            );
        }

        this.setState({ selectedValues: newValue });

        this.props.changeHandler(newValue, event);
    };
}
