import get from "lodash.get";
import type { AnyAction } from "redux";
import {
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import { actionTokenDownloadThunk } from "common/shell/state/fileActions";
import { PAGE_SIZE_UNLIMITED } from "common/util/query";
import { RECOMMENDATIONS } from "filter/state/filterActions";
import { RECOMMENDATIONS_PAGE } from "shell/state/pageActions";
import type { RootState } from "store";

// Recommendation sort by
export const CAMPAIGN = "campaigns";
export const MOST_RECENT_CAMPAIGN_START_DATE = "mostRecentCampaignStartDate";
export const OFFER_RETAILER = "retailer";
export const OFFER_MANAGER = "manager";
export const OFFER_REGION = "region";
export const OFFER_CURRENT_OFFER = "controlOfferLabel";
export const OFFER_PROMO_SALES = "promoSales";
export const OFFER_EVENT_WEEKS = "eventWeeks";
export const OFFER_RECOMMENDED_OFFER = "recommendedOffer";
export const PROJECTED_INCREMENTAL_SALES_LIFT = "projectedSalesLift";
export const PROJECTED_INCREMENTAL_UNIT_LIFT = "projectedUnitLift";

// action types
export const REQUEST_RECOMMENDATIONS = "REQUEST_RECOMMENDATIONS";
export const RECEIVE_RECOMMENDATIONS = "RECEIVE_RECOMMENDATIONS";
export const UPDATE_RECOMMENDATIONS_OFFSET = "UPDATE_RECOMMENDATIONS_OFFSET";
export const UPDATE_RECOMMENDATIONS_SORT_BY = "UPDATE_RECOMMENDATIONS_SORT_BY";

// action creators
function getRecommendationsPipelineDetailRequest(state: RootState): any {
    const campaign = get(state, ["filters", RECOMMENDATIONS, "campaign"]);
    return {
        campaignEndDate: get(state, [
            "filters",
            RECOMMENDATIONS,
            "campaignEndDate"
        ]),
        campaignId: campaign ? campaign.value : null,
        campaignStartDate: get(state, [
            "filters",
            RECOMMENDATIONS,
            "campaignStartDate"
        ]),
        holidayIds: get(state, ["filters", RECOMMENDATIONS, "holidays"]),
        managerIds: get(state, ["filters", RECOMMENDATIONS, "managers"]),
        objectType: "RecommendationsRequestTO",
        offset: get(state, "recommendations.offset"),
        pageSize: get(state, "recommendations.pageSize"),
        productGroupIds: get(state, ["filters", RECOMMENDATIONS, "products"]),
        regionIds: get(state, ["filters", RECOMMENDATIONS, "regions"]),
        retailerIds: get(state, ["filters", RECOMMENDATIONS, "retailers"]),
        search: get(state, ["filters", RECOMMENDATIONS, "search"]),
        sortBy: get(state, "recommendations.sortBy"),
        sortOrder: get(state, "recommendations.sortOrder")
    };
}

export function _getRecommendationsPipelineDetailRequest(
    state: RootState
): any {
    return Object.assign({}, getRecommendationsPipelineDetailRequest(state), {
        offset: 0,
        pageSize: PAGE_SIZE_UNLIMITED
    });
}

export const recommendationsPage = (): AnyAction => ({
    type: RECOMMENDATIONS_PAGE
});

export const updateRecommendationsOffset = (offset: number): AnyAction => ({
    type: UPDATE_RECOMMENDATIONS_OFFSET,
    offset: offset
});

export const requestRecommendations = (): AnyAction => ({
    type: REQUEST_RECOMMENDATIONS
});

export const receiveRecommendations = (json: any): AnyAction => ({
    type: RECEIVE_RECOMMENDATIONS,
    recommendations: json
});

export const updateRecommendationsSortBy = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => ({
    type: UPDATE_RECOMMENDATIONS_SORT_BY,
    sortBy: sortBy,
    sortOrder: sortOrder
});

// thunks
export function fetchRecommendations() {
    // Send in correct request body
    return makeRequestThunk("api/matrix/recommendations", {
        bodyFunc: getRecommendationsPipelineDetailRequest,
        method: METHOD_POST,
        preRequestFunc: requestRecommendations,
        okDispatchFunc: receiveRecommendations,
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.items,
                filteredCount: json.totalItems
            };
        },
        showLoadMask: false
    });
}

export function exportRecommendations() {
    return actionTokenDownloadThunk(
        "api/matrix/recommendations/download/init",
        _getRecommendationsPipelineDetailRequest,
        "api/matrix/recommendations/download?otat="
    );
}
