import styled from "styled-components/macro";

type TooltipWrapperProps = {
    textAlign?: string;
} & Pick<sizePropsType, "cssWidth" | "maxWidth" | "minWidth">;

const TooltipWrapper = styled.div<TooltipWrapperProps>`
    font-family: BrandonTextRegular;
    font-size: 14px;
    overflow-wrap: break-word;
    ${props => props.cssWidth && `width: ${props.cssWidth};`}
    ${props => props.maxWidth && `max-width: ${props.maxWidth};`}
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    ${props => props.textAlign && `text-align: ${props.textAlign};`}
`;
TooltipWrapper.displayName = "TooltipWrapper";

export default TooltipWrapper;
