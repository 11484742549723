import {
    TEAMS_PAGE,
    TEAM_PAGE,
    TEAM_CREATE_PAGE,
    TEAM_EDIT_PAGE,
    USERS_PAGE,
    USER_PAGE,
    USER_CREATE_PAGE,
    USER_EDIT_PAGE
} from "common/administration/state/pageActions";
import {
    confirmLeave,
    fixEntityIdPathSegment,
    resetLeave
} from "common/routes";
import {
    GUIDELINE_COLLECTION_CREATE_PAGE,
    GUIDELINE_COLLECTION_EDIT_PAGE,
    GUIDELINE_COLLECTION_PAGE,
    GUIDELINE_COLLECTIONS_PAGE,
    GUIDELINE_CREATE_PAGE,
    GUIDELINE_EDIT_PAGE,
    GUIDELINE_PAGE,
    HOLIDAY_CREATE_PAGE,
    HOLIDAY_EDIT_PAGE,
    HOLIDAY_PAGE,
    HOLIDAYS_PAGE,
    PROMOTION_TYPE_DETECTION_EDIT_PAGE,
    PROMOTION_TYPE_DETECTION_PAGE
} from "administration/state/pageActions";

export const routeMap = {
    [GUIDELINE_COLLECTION_CREATE_PAGE]: {
        path: "/administration/guidelineCollection/new",
        thunk: resetLeave,
        confirmLeave: confirmLeave([GUIDELINE_COLLECTION_CREATE_PAGE])
    },
    [GUIDELINE_COLLECTION_EDIT_PAGE]: {
        path: "/administration/guidelineCollections/:collectionId/edit",
        fromPath: fixEntityIdPathSegment,
        thunk: resetLeave,
        confirmLeave: confirmLeave([GUIDELINE_COLLECTION_EDIT_PAGE])
    },
    [GUIDELINE_CREATE_PAGE]: {
        path: "/administration/guidelineCollections/:collectionId/new",
        fromPath: fixEntityIdPathSegment,
        thunk: resetLeave,
        confirmLeave: confirmLeave([GUIDELINE_CREATE_PAGE, GUIDELINE_EDIT_PAGE])
    },
    [GUIDELINE_COLLECTIONS_PAGE]: "/administration/guidelineCollections",
    [GUIDELINE_COLLECTION_PAGE]: {
        path: "/administration/guidelineCollections/:entityId",
        fromPath: fixEntityIdPathSegment
    },

    [GUIDELINE_PAGE]: {
        path: "/administration/guideline/:entityId",
        fromPath: fixEntityIdPathSegment
    },
    [GUIDELINE_EDIT_PAGE]: {
        path: "/administration/guideline/:entityId/edit",
        fromPath: fixEntityIdPathSegment,
        thunk: resetLeave,
        confirmLeave: confirmLeave([GUIDELINE_EDIT_PAGE])
    },
    [HOLIDAY_CREATE_PAGE]: {
        path: "/administration/holidays/new",
        thunk: resetLeave,
        confirmLeave: confirmLeave([HOLIDAY_CREATE_PAGE])
    },
    [HOLIDAYS_PAGE]: "/administration/holidays",
    [HOLIDAY_PAGE]: {
        path: "/administration/holidays/:entityId",
        fromPath: fixEntityIdPathSegment
    },
    [HOLIDAY_EDIT_PAGE]: {
        path: "/administration/holidays/:entityId/edit",
        fromPath: fixEntityIdPathSegment,
        thunk: resetLeave,
        confirmLeave: confirmLeave([HOLIDAY_EDIT_PAGE])
    },
    [PROMOTION_TYPE_DETECTION_PAGE]: "/administration/promotionTypeDetection",
    [PROMOTION_TYPE_DETECTION_EDIT_PAGE]: {
        path: "/administration/promotionTypeDetection/edit",
        thunk: resetLeave,
        confirmLeave: confirmLeave([PROMOTION_TYPE_DETECTION_EDIT_PAGE])
    },
    [TEAM_CREATE_PAGE]: {
        path: "/administration/teams/new",
        thunk: resetLeave,
        confirmLeave: confirmLeave([TEAM_CREATE_PAGE])
    },
    [TEAM_EDIT_PAGE]: {
        path: "/administration/teams/:entityId/edit",
        fromPath: fixEntityIdPathSegment,
        thunk: resetLeave,
        confirmLeave: confirmLeave([TEAM_EDIT_PAGE])
    },
    [TEAMS_PAGE]: "/administration/teams",
    [TEAM_PAGE]: {
        path: "/administration/teams/:entityId",
        fromPath: fixEntityIdPathSegment
    },
    [USER_CREATE_PAGE]: {
        path: "/administration/users/new",
        thunk: resetLeave,
        confirmLeave: confirmLeave([USER_CREATE_PAGE])
    },
    [USER_EDIT_PAGE]: {
        path: "/administration/users/:entityId/edit",
        fromPath: fixEntityIdPathSegment,
        thunk: resetLeave,
        confirmLeave: confirmLeave([USER_EDIT_PAGE])
    },
    [USERS_PAGE]: "/administration/users",
    [USER_PAGE]: {
        path: "/administration/users/:entityId",
        fromPath: fixEntityIdPathSegment
    }
};
