import * as React from "react";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import styled from "styled-components/macro";
import type { Placement } from "tippy.js";
import {
    DropdownContainer,
    DropdownContent,
    DropdownHeader,
    DropdownSubHeader,
    DropdownSVGWrapper,
    POSITION_CENTER,
    POSITION_BELOW
} from "common/components/Dropdown";
import type {
    DropdownContentProps,
    HorizontalPosition,
    VerticalPosition
} from "common/components/Dropdown";
import SvgIcon from "common/components/SvgIcon";
import {
    borderRadius,
    marginPropTypes,
    paddingPropTypes,
    sizePropTypes,
    sizeProps
} from "common/components/styled/util";
import { buildSelectedTextTree } from "common/util/filter";
import { reduceObject } from "common/util/object";
import theme from "common/components/theme";
import { emptyFn } from "common/util/lang";
import type { TrackingComponentLabel } from "common/util/trackingEvents";
import {
    EVENT_NAME_FILTER_APPLY,
    EVENT_NAME_FILTER_CLEAR
} from "common/util/trackingEvents";
import FilterDropdownSearchBar from "common/filter/components/FilterDropdownSearchBar";
import Tooltip from "common/components/Tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import Flexbox from "common/components/styled/Flexbox";
import TooltipWrapper from "common/components/styled/TooltipWrapper";
import TruncatedDiv from "common/components/styled/TruncatedDiv";
import {
    withClickTracking,
    withOutsideClickTracking
} from "common/components/withClickTracking";
import FilterDropdownIcon from "common/filter/components/FilterDropdownIcon";
import { containsIgnoreReactOutsideClick } from "common/util/outsideClick";

export const FilterDropdownScrollableContent = styled.div<
    Pick<sizePropsType, "cssHeight" | "maxHeight" | "minWidth">
>`
    overflow-y: auto;
    overflow-x: hidden;
    ${sizeProps};
`;
FilterDropdownScrollableContent.displayName = "FilterDropdownScrollableContent";

export type FilterDropdownTriggerProps = {
    disabled?: boolean;
    isSelected?: boolean;
    highlightBackground?: boolean;
    highlightSelected?: boolean;
    textColor?: string;
} & paddingPropsType;

export const FilterDropdownTrigger = styled.div<FilterDropdownTriggerProps>`
    ${props =>
        props.disabled
            ? `background-color: ${props.theme.lightGreyText};`
            : "cursor: pointer;"}
    color: ${props =>
        props.disabled
            ? props.theme.textDisabled
            : props.textColor
            ? props.textColor
            : props.theme.text};
    ${props =>
        props.isSelected &&
        props.highlightSelected &&
        props.highlightBackground &&
        !props.disabled &&
        `background-color: ${props.theme.dropdownSelectedBg}`};
    border: 1px solid
        ${props =>
            props.isSelected && props.highlightSelected
                ? props.theme.dropdownSelected
                : props.theme.border};
    border-radius: ${borderRadius("card")};
    padding: ${props => (props.padding ? props.padding : "6px 12px 7px 12px")};

    &:hover {
        ${props =>
            !props.disabled &&
            `border-color: ${props.theme.filterDropdownHover};`}
    }
    &:hover ${DropdownSVGWrapper} > * {
        ${props => !props.disabled && `fill: ${props.color};`}
    }
`;
FilterDropdownTrigger.displayName = "FilterDropdownTrigger";

export const FilterDropdownContent = styled(DropdownContent)`
    overflow-x: hidden;
`;
FilterDropdownContent.displayName = "FilterDropdownContent";

export const FilterDropdownContainer = styled(DropdownContainer)<
    Pick<sizePropsType, "cssWidth">
>`
    white-space: normal;
    background-color: ${theme.background};
    ${props => props.cssWidth && `width: ${props.cssWidth};`}
`;
FilterDropdownContainer.displayName = "FilterDropdownContainer";

type FilterDropdownTextProps = {
    maxWidth?: string;
};

export const FilterDropdownText = styled.div<FilterDropdownTextProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 3px;
    max-width: ${props => (props.maxWidth ? props.maxWidth : undefined)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
    svg {
        fill: ${theme.defaultGreyText};
    }
`;
FilterDropdownText.displayName = "FilterDropdownText";

type FilterDropdownHeaderProps = {
    hideBottomBorder?: boolean;
};

export const FilterDropdownHeader = styled.div<FilterDropdownHeaderProps>`
    align-items: center;
    border-bottom: 1px solid ${theme.border};
    display: flex;
    flex-direction: column;

    ${props => props.hideBottomBorder && `border-bottom: none;`}
`;
FilterDropdownHeader.displayName = "FilterDropdownHeader";

export const FilterDropdownTitle = styled.div`
    align-items: baseline;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;
FilterDropdownTitle.displayName = "FilterDropdownTitle";

const FilterDropdownHeaderClear = styled.div<Pick<paddingPropsType, "padding">>`
    color: ${theme.linkText};
    cursor: pointer;
    font-size: 12px;
    ${props =>
        props.padding ? `padding: ${props.padding};` : `padding-right: 16px;`}
`;
FilterDropdownHeaderClear.displayName = "FilterDropdownHeaderClear";

export const FilterDropdownHeaderClearComponent = withClickTracking(
    FilterDropdownHeaderClear,
    FilterDropdownHeaderClear.displayName ?? "FilterDropdownHeaderClear",
    EVENT_NAME_FILTER_CLEAR
);

const FilterApplyButton = styled.button`
    background-color: ${props =>
        props.disabled ? props.theme.btnDisabledBg : props.theme.btnPrimaryBg};
    ${props =>
        !props.disabled &&
        `background: linear-gradient(
        ${props.theme.btnPrimaryBgGradient},
        ${props.theme.btnPrimaryBg}
    );`}
    color: ${props =>
        props.disabled
            ? props.theme.btnDisabledTextColor
            : props.theme.btnPrimaryTextColor};
    cursor: pointer;
    width: 100%;
    height: 42px;
`;
FilterApplyButton.displayName = "FilterApplyButton";

export const FilterApplyButtonComponent = withClickTracking(
    FilterApplyButton,
    FilterApplyButton.displayName ?? "FilterApplyButton",
    EVENT_NAME_FILTER_APPLY
);

type FilterSubMenuProps = {
    subMenuRightOffset?: string;
    subMenuTopOffset?: string;
} & DropdownContentProps;

const FilterSubMenu = styled(DropdownContent)<FilterSubMenuProps>`
    ${props =>
        props.subMenuRightOffset && `right: ${props.subMenuRightOffset};`}
    ${props => props.subMenuTopOffset && `top: ${props.subMenuTopOffset};`}
`;

type FilterDropdownOwnProps = {
    buttonWidth: string;
    children: React.ReactNode;
    clearHandler: NoArgsHandler;
    closeHandler: (applyFilter: boolean) => void;
    disabled?: boolean;
    dropdownOpenHandler?: NoArgsHandler; // This handler is used to make sure menu is open and elements rendered in DOM
    filterHeaderText: string;
    filterSubHeaderText?: string;
    hasSelectedTextTree?: boolean;
    highlightBackground?: boolean;
    highlightSelected?: boolean;
    horizontalPosition?: HorizontalPosition;
    icon?: IconType;
    iconHeight?: string;
    iconWidth?: string;
    isApplyDisabled?: boolean;
    name: string;
    openHandler: NoArgsHandler;
    scrollable: boolean;
    searchHandler?: (searchStr: string) => void;
    selected: string[];
    selectedText: string[];
    showSearch: boolean;
    subMenuContent?: React.ReactNode; // Submenu node to expand out for dropdowns with submenu on the right
    subMenuRightOffset?: number; // This should be the width of the menu so we can position submenu properly
    subMenuTopOffset?: number; // To position the menu next to the selected option we need the top offset
    text: string;
    textColor?: string;
    textMaxWidth?: string;
    tooltipPosition?: Placement;
    trackingComponentLabel: TrackingComponentLabel;
    verticalPosition?: VerticalPosition;
} & marginPropsType &
    paddingPropsType &
    sizePropsType;
type FilterDropdownViewProps = FilterDropdownOwnProps & WithTranslation;

type FilterDropdownState = {
    isOpen: boolean;
};

class FilterDropdownView extends React.Component<
    FilterDropdownViewProps,
    FilterDropdownState
> {
    dropdownContainerRef: HTMLDivElement | undefined | null;
    dropdownContentRef: HTMLDivElement | undefined | null;
    dropdownText: HTMLDivElement | undefined | null;

    static defaultProps = {
        clearHandler: emptyFn,
        closeHandler: emptyFn,
        filterHeaderText: "",
        filterSubHeaderText: "",
        highlightBackground: true,
        horizontalPosition: POSITION_CENTER,
        isApplyDisabled: false,
        openHandler: emptyFn,
        scrollable: true,
        selected: [],
        selectedText: [],
        showSearch: false,
        text: "",
        verticalPosition: POSITION_BELOW
    };

    state = {
        isOpen: false
    };

    componentDidMount() {
        this.setDropdownTextTitle();
    }

    componentDidUpdate(
        prevProps: FilterDropdownViewProps,
        prevState: FilterDropdownState
    ) {
        const { dropdownOpenHandler } = this.props;
        this.setDropdownTextTitle();
        if (!prevState.isOpen && this.state.isOpen) {
            if (dropdownOpenHandler) {
                dropdownOpenHandler();
            }
            if (this.dropdownContentRef) {
                this.dropdownContentRef.scrollIntoView();
            }
        }
    }

    onSearchChange = (searchStr: string) => {
        const { searchHandler } = this.props;
        if (searchHandler) {
            searchHandler(searchStr);
        }
    };

    onClear = (evt: React.SyntheticEvent<HTMLDivElement>) => {
        this.props.clearHandler();
        evt.stopPropagation();
    };

    onApply = (evt: React.SyntheticEvent<HTMLButtonElement>) => {
        this.setState({ isOpen: false });
        this.props.closeHandler(true);
    };

    onTriggerClick = (evt: React.SyntheticEvent<HTMLDivElement>) => {
        if (!this.state.isOpen) {
            this.setState({ isOpen: true });
            this.props.openHandler();
        } else {
            this.setState({ isOpen: false });
            this.props.closeHandler(true);
        }
    };

    onOutsideClick = (event: MouseEvent) => {
        if (!containsIgnoreReactOutsideClick(event)) {
            if (this.state.isOpen) {
                withOutsideClickTracking(
                    this.props.trackingComponentLabel,
                    EVENT_NAME_FILTER_APPLY
                );
                this.setState({ isOpen: false });
                this.props.closeHandler(true);
            }
        }
    };

    setDropdownTextTitle = () => {
        if (
            this.dropdownText &&
            this.dropdownText.offsetWidth < this.dropdownText.scrollWidth
        ) {
            this.dropdownText.title = this.props.text;
        }
    };

    // We get the top offset of the dropdown container and subtract the subMenu top offset
    // to position the side sub menu properly.
    getSubMenuTopOffset = (): string => {
        const { subMenuTopOffset } = this.props;
        const containerOffset = this.dropdownContainerRef
            ? this.dropdownContainerRef.getBoundingClientRect().top
            : 0;
        return subMenuTopOffset && containerOffset
            ? subMenuTopOffset - containerOffset + "px"
            : "0px";
    };

    setDropdownContainerRef = (element?: HTMLDivElement | null) => {
        this.dropdownContainerRef = element;
    };

    setDropdownContentRef = (element?: HTMLDivElement | null) => {
        this.dropdownContentRef = element;
    };

    setDropdownTextRef = (element?: HTMLDivElement | null) => {
        this.dropdownText = element;
    };

    render() {
        const {
            children,
            disabled,
            filterHeaderText,
            filterSubHeaderText,
            hasSelectedTextTree,
            highlightBackground,
            highlightSelected,
            icon,
            iconHeight,
            iconWidth,
            isApplyDisabled,
            name,
            scrollable,
            selected,
            selectedText,
            subMenuContent,
            t,
            text,
            textColor,
            tooltipPosition,
            trackingComponentLabel
        } = this.props;
        const DropdownValue = text ? (
            <FilterDropdownText ref={this.setDropdownTextRef}>
                {icon && (
                    <Flexbox>
                        <SvgIcon
                            icon={icon}
                            width={iconWidth}
                            height={iconHeight}
                            marginRight="8px"
                        />
                    </Flexbox>
                )}
                <TruncatedDiv>{text}</TruncatedDiv>
            </FilterDropdownText>
        ) : icon ? (
            <SvgIcon icon={icon} width={iconWidth} height={iconHeight} />
        ) : null;
        const FilterTrigger = (
            <FilterDropdownTrigger
                color={theme.text}
                textColor={textColor ? textColor : theme.text}
                disabled={disabled}
                highlightBackground={highlightBackground}
                highlightSelected={highlightSelected}
                onClick={disabled ? emptyFn : this.onTriggerClick}
                isSelected={!!selected.length}
                {...reduceObject(this.props, ...paddingPropTypes)}
            >
                <Flexbox justifyContent={"space-between"}>
                    {DropdownValue}
                    <FilterDropdownIcon zIndex={0} />
                </Flexbox>
            </FilterDropdownTrigger>
        );
        let FilterTriggerComponent = FilterTrigger;
        const selectedTextTooltip = hasSelectedTextTree
            ? buildSelectedTextTree(selectedText)
            : selectedText.map((item, index) => {
                  return <div key={index}>{item}</div>;
              });
        if (selectedText.length > 0 && !this.state.isOpen) {
            FilterTriggerComponent = (
                <Tooltip
                    id={name}
                    placement={tooltipPosition ? tooltipPosition : "bottom"}
                >
                    {FilterTrigger}
                    <TooltipWrapper>{selectedTextTooltip}</TooltipWrapper>
                </Tooltip>
            );
        }

        const FilterDropdownHeaderClearComponent = withClickTracking(
            FilterDropdownHeaderClear,
            FilterDropdownHeaderClear.displayName ??
                "FilterDropdownHeaderClear",
            EVENT_NAME_FILTER_CLEAR
        );
        const filterContent = scrollable ? (
            <FilterDropdownScrollableContent
                {...reduceObject(this.props, ...sizePropTypes)}
            >
                {children}
            </FilterDropdownScrollableContent>
        ) : (
            children
        );

        return (
            <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
                <FilterDropdownContainer
                    {...reduceObject(this.props, ...marginPropTypes, [
                        "buttonWidth",
                        "cssWidth"
                    ])}
                    ref={this.setDropdownContainerRef}
                >
                    {FilterTriggerComponent}
                    {this.state.isOpen && subMenuContent && (
                        <FilterSubMenu
                            {...reduceObject(
                                this.props,
                                "horizontalPosition",
                                "subMenuRightOffset",
                                "verticalPosition"
                            )}
                            subMenuTopOffset={this.getSubMenuTopOffset()}
                        >
                            {subMenuContent}
                        </FilterSubMenu>
                    )}
                    {this.state.isOpen && (
                        <FilterDropdownContent
                            {...reduceObject(
                                this.props,
                                "horizontalPosition",
                                "verticalPosition"
                            )}
                            ref={this.setDropdownContentRef}
                        >
                            <FilterDropdownHeader>
                                <FilterDropdownTitle>
                                    {filterHeaderText && (
                                        <DropdownHeader
                                            hasSubHeader={
                                                filterSubHeaderText
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {filterHeaderText}
                                        </DropdownHeader>
                                    )}
                                    {selected.length > 0 && (
                                        <FilterDropdownHeaderClearComponent
                                            onClick={this.onClear}
                                            trackingComponentLabel={
                                                trackingComponentLabel
                                            }
                                        >
                                            {t("common:filter.clear_all_x", {
                                                count: selected.length
                                            })}
                                        </FilterDropdownHeaderClearComponent>
                                    )}
                                </FilterDropdownTitle>
                                {filterSubHeaderText && (
                                    <DropdownSubHeader>
                                        {filterSubHeaderText}
                                    </DropdownSubHeader>
                                )}
                            </FilterDropdownHeader>
                            {this.props.showSearch && (
                                <FilterDropdownSearchBar
                                    trackingComponentLabel={
                                        trackingComponentLabel
                                    }
                                    changeHandler={this.onSearchChange}
                                />
                            )}
                            {filterContent}
                            <FilterApplyButtonComponent
                                disabled={isApplyDisabled}
                                onClick={this.onApply}
                                trackingComponentLabel={trackingComponentLabel}
                            >
                                {t("common:general.apply")}
                            </FilterApplyButtonComponent>
                        </FilterDropdownContent>
                    )}
                </FilterDropdownContainer>
            </OutsideClickHandler>
        );
    }
}

export default withTranslation()(FilterDropdownView);
