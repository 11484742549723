import * as React from "react";
import { memo } from "react";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";

type LoadingCellRendererOwnProps = {
    node: any;
};

type LoadingCellRendererProps = LoadingCellRendererOwnProps & WithTranslation;

const LoadingCellRenderer = memo<LoadingCellRendererProps>(
    (params: LoadingCellRendererProps) => {
        const { node, t } = params;
        if (node && node.failedLoad) {
            return null;
        } else {
            return (
                <div className="ag-loading">
                    <span className="ag-loading-icon">
                        <span className="ag-icon ag-icon-loading" />
                    </span>
                    <span className="ag-loading-text">
                        {t("common:aggrid.loadingOoo")}
                    </span>
                </div>
            );
        }
    }
);

export default withTranslation()(LoadingCellRenderer);
