import type { TFunction } from "i18next";
import get from "lodash.get";
import type { AnyAction } from "redux";
import {
    BUTTON_CANCELYES,
    BUTTON_YES,
    ICON_QUESTION,
    openMessageBox
} from "common/shell/state/messageBoxActions";
import {
    formatSortOrderForQueryAPI,
    makeRequestThunk,
    METHOD_DELETE,
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT
} from "common/shell/state/requestActions";
import { success } from "common/shell/state/toastActions";
import {
    andFilterCondition,
    booleanFilterCondition,
    enumFilterCondition,
    enumInFilterCondition,
    freeFormFilterCondition,
    notNullFilterCondition,
    nullFilterCondition,
    orFilterCondition,
    singleValueFilterCondition,
    stringFilterCondition,
    stringInFilterCondition,
    subqueryInFilterCondition
} from "common/util/filter";
import {
    ASCENDING_QUERY_API,
    DEFAULT_PAGE_SIZE,
    DESCENDING_QUERY_API,
    PAGE_SIZE_UNLIMITED,
    sortCondition
} from "common/util/query";
import {
    CAMPAIGN_STATE_COMPLETED,
    CAMPAIGN_STATE_PAUSED,
    CAMPAIGN_STATE_RUNNING
} from "campaign/state/campaignStateUtils";
import {
    ANALYSIS_TYPE_CAMPAIGN_COMPARISON,
    getChartDisplayType,
    reduceChartRecordData
} from "analysis/state/analysisStateUtils";
import type {
    BreakdownSegmentSetGroup,
    OfferLabelPersist,
    AnalysisType
} from "analysis/state/analysisStateUtils";
import {
    ANALYSIS_CAMPAIGN_COMPARISON_PAGE,
    ANALYSIS_CAMPAIGN_PAGE,
    ANALYSIS_FACTOR_PAGE,
    ANALYSIS_SAVED_ANALYSES_PAGE
} from "shell/state/pageActions";
import type { ActionOrThunk, Actions, AppDispatch, RootState } from "store";

// action types
export const RECEIVE_ANALYSIS_CAMPAIGNS = "RECEIVE_ANALYSIS_CAMPAIGNS";
export const REQUEST_ANALYSIS_CAMPAIGNS = "REQUEST_ANALYSIS_CAMPAIGNS";
export const RECEIVE_COMPARISON_CAMPAIGNS = "RECEIVE_COMPARISION_CAMPAIGNS";
export const REQUEST_COMPARISON_CAMPAIGNS = "REQUEST_COMPARISION_CAMPAIGNS";
export const RECEIVE_CAMPAIGN_COMPARISON_PROMOTIONS =
    "RECEIVE_CAMPAIGN_COMPARISON_PROMOTIONS";
export const REQUEST_CAMPAIGN_COMPARISON_PROMOTIONS =
    "REQUEST_CAMPAIGN_COMPARISON_PROMOTIONS";
export const RECEIVE_PROMOTION_PAIRS = "REQUEST_PROMOTION_PAIRS";
export const REQUEST_PROMOTION_PAIRS = "REQUEST_PROMOTION_PAIRS";
//export const RECEIVE_AVAILABLE_BREAKDOWNS = "RECEIVE_AVAILABLE_BREAKDOWNS";
//export const REQUEST_AVAILABLE_BREAKDOWNS = "REQUEST_AVAILABLE_BREAKDOWNS";
export const RECEIVE_SAVED_SEGMENT_SETS = "RECEIVE_SAVED_SEGMENT_SETS";
export const REQUEST_SAVED_SEGMENT_SETS = "REQUEST_SAVED_SEGMENT_SETS";
export const RECEIVE_SAVED_ANALYSES = "RECEIVE_SAVED_ANALYSES";
export const REQUEST_SAVED_ANALYSES = "REQUEST_SAVED_ANALYSES";
export const RECEIVE_SAVED_ANALYSES_CAMPAIGNS =
    "RECEIVE_SAVED_ANALYSES_CAMPAIGNS";
export const REQUEST_SAVED_ANALYSES_CAMPAIGNS =
    "REQUEST_SAVED_ANALYSES_CAMPAIGNS";
export const RECEIVE_CHART_DESIGN = "RECEIVE_CHART_DESIGN";
export const REQUEST_CHART_DESIGN = "REQUEST_CHART_DESIGN";
export const RECEIVE_CHART_DESIGN_BY_ID = "RECEIVE_CHART_DESIGN_BY_ID";
export const REQUEST_CHART_DESIGN_BY_ID = "REQUEST_CHART_DESIGN_BY_ID";
export const RECEIVE_CHART_RECORD_DATA = "RECEIVE_CHART_RECORD_DATA";
export const REQUEST_CHART_RECORD_DATA = "REQUEST_CHART_RECORD_DATA";
export const RECEIVE_CUSTOM_FACTOR_DATA = "RECEIVE_CUSTOM_FACTOR_DATA";
export const REQUEST_CUSTOM_FACTOR_DATA = "REQUEST_CUSTOM_FACTOR_DATA";
export const RECEIVE_FACTOR_ANALYSIS_CHART_LIST =
    "RECEIVE_FACTOR_ANALYSIS_CHART_LIST";
export const REQUEST_FACTOR_ANALYSIS_CHART_LIST =
    "REQUEST_FACTOR_ANALYSIS_CHART_LIST";
export const RECEIVE_FACTOR_CAMPAIGNS = "RECEIVE_FACTOR_CAMPAIGNS";
export const REQUEST_FACTOR_CAMPAIGNS = "REQUEST_FACTOR_CAMPAIGNS";
export const RECEIVE_FACTOR_OFFERS = "RECEIVE_FACTOR_OFFERS";
export const REQUEST_FACTOR_OFFERS = "REQUEST_FACTOR_OFFERS";
export const RECEIVE_INITIAL_CHART_DESIGN = "RECEIVE_INITIAL_CHART_DESIGN";
export const REQUEST_INITIAL_CHART_DESIGN = "REQUEST_INITIAL_CHART_DESIGN";
export const RECEIVE_OFFER_DATA = "RECEIVE_OFFER_DATA";
export const REQUEST_OFFER_DATA = "REQUEST_OFFER_DATA";
export const RECEIVE_CUSTOM_FACTOR_DATA_CREATE =
    "RECEIVE_CUSTOM_FACTOR_DATA_CREATE";
export const REQUEST_CUSTOM_FACTOR_DATA_CREATE =
    "REQUEST_CUSTOM_FACTOR_DATA_CREATE";
export const RESET_CHART_DESIGN = "RESET_CHART_DESIGN";
export const UPDATE_ANALYSIS_CAMPAIGN = "UPDATE_ANALYSIS_CAMPAIGN";
export const UPDATE_SAVED_ANALYSIS_CAMPAIGN_FILTER =
    "UPDATE_SAVED_ANALYSIS_CAMPAIGN_FILTER";
export const UPDATE_SAVED_ANALYSIS_ANALYSIS_TYPE_FILTER =
    "UPDATE_SAVED_ANALYSIS_ANALYSIS_TYPE_FILTER";
export const UPDATE_ANALYSIS_CHART_DEF = "UPDATE_ANALYSIS_CHART_DEF";
export const UPDATE_ANALYSIS_CHART_DESIGN = "UPDATE_ANALYSIS_CHART_DESIGN";
export const UPDATE_ANALYSIS_EDITED = "UPDATE_ANALYSIS_EDITED";
export const UPDATE_ANALYSIS_LEGEND = "UPDATE_ANALYSIS_LEGEND";
export const UPDATE_ANALYSIS_OFFER_LABEL_TO_EDIT =
    "UPDATE_ANALYSIS_OFFER_LABEL_TO_EDIT";
export const UPDATE_ANALYSIS_TYPE = "UPDATE_ANALYSIS_TYPE";
export const UPDATE_DISABLE_OUTSIDE_CLICK = "UPDATE_DISABLE_OUTSIDE_CLICK";
export const UPDATE_EXPORT_DATA_TABLE_SETTINGS =
    "UPDATE_EXPORT_DATA_TABLE_SETTINGS";
export const UPDATE_FACTOR_ANALYSIS_CHART_LIST =
    "UPDATE_FACTOR_ANALYSIS_CHART_LIST";
export const UPDATE_FACTOR_ANALYSIS_CHART_LIST_CHARTS =
    "UPDATE_FACTOR_ANALYSIS_CHART_LIST_CHARTS";
export const UPDATE_SEARCH_SAVED_ANALYSES = "UPDATE_SEARCH_SAVED_ANALYSES";
export const UPDATE_SORT_ANALYSIS_CHART_RECORDS =
    "UPDATE_SORT_ANALYSIS_CHART_RECORDS";
export const UPDATE_SORT_ANALYSIS_CUSTOM_FACTORS =
    "UPDATE_SORT_ANALYSIS_CUSTOM_FACTORS";
export const UPDATE_SORT_ANALYSIS_OFFERS = "UPDATE_SORT_ANALYSIS_OFFERS";
export const UPDATE_SORT_FACTOR_CAMPAIGNS = "UPDATE_SORT_FACTOR_CAMPAIGNS";
export const UPDATE_START_INDEX_FACTOR_CAMPAIGNS =
    "UPDATE_START_INDEX_FACTOR_CAMPAIGNS";
export const UPDATE_SORT_FACTOR_OFFERS = "UPDATE_SORT_FACTOR_OFFERS";
export const UPDATE_START_INDEX_FACTOR_OFFERS =
    "UPDATE_START_INDEX_FACTOR_OFFERS";
export const UPDATE_SORT_SAVED_ANALYSES = "UPDATE_SORT_SAVED_ANALYSES";
export const UPDATE_START_INDEX_SAVED_ANALYSES =
    "UPDATE_START_INDEX_SAVED_ANALYSES";

export const campaignAnalysisPage = (entityId?: string): AnyAction => {
    if (entityId) {
        return {
            type: ANALYSIS_CAMPAIGN_PAGE,
            payload: {
                // matches what is in routes.js
                entityId: entityId
            }
        };
    } else {
        return {
            type: ANALYSIS_CAMPAIGN_PAGE
        };
    }
};

export const campaignComparisonPage = (entityId?: string): AnyAction => {
    if (entityId) {
        return {
            type: ANALYSIS_CAMPAIGN_COMPARISON_PAGE,
            payload: {
                // matches what is in routes.js
                entityId: entityId
            }
        };
    } else {
        return {
            type: ANALYSIS_CAMPAIGN_COMPARISON_PAGE
        };
    }
};

export const factorAnalysisPage = (entityId?: string): AnyAction => {
    if (entityId) {
        return {
            type: ANALYSIS_FACTOR_PAGE,
            payload: {
                // matches what is in routes.js
                entityId: entityId
            }
        };
    } else {
        return {
            type: ANALYSIS_FACTOR_PAGE
        };
    }
};

export const savedAnalysesPage = (): AnyAction => {
    return {
        type: ANALYSIS_SAVED_ANALYSES_PAGE
    };
};

const requestAnalysisCampaigns = (): AnyAction => ({
    type: REQUEST_ANALYSIS_CAMPAIGNS
});

const receiveAnalysisCampaigns = (json: Json): AnyAction => ({
    type: RECEIVE_ANALYSIS_CAMPAIGNS,
    json: json
});

const requestComparisonCampaigns = (): AnyAction => ({
    type: REQUEST_COMPARISON_CAMPAIGNS
});

const requestCampaignComparisonPromotions = (): AnyAction => ({
    type: REQUEST_CAMPAIGN_COMPARISON_PROMOTIONS
});

const receiveCampaignComparisonPromotions = (json: Json): AnyAction => ({
    type: RECEIVE_CAMPAIGN_COMPARISON_PROMOTIONS,
    json: json
});

const requestFactorOffers = (): AnyAction => ({
    type: REQUEST_FACTOR_OFFERS
});

const receiveFactorOffers = (json: Json): AnyAction => ({
    type: RECEIVE_FACTOR_OFFERS,
    json: json
});

const requestFactorCampaigns = (): AnyAction => ({
    type: REQUEST_FACTOR_CAMPAIGNS
});

const receiveFactorCampaigns = (json: Json): AnyAction => ({
    type: RECEIVE_FACTOR_CAMPAIGNS,
    json: json
});

const requestPromotionPairs = (): AnyAction => ({
    type: REQUEST_PROMOTION_PAIRS
});

const receivePromotionPairs = (json: Json): AnyAction => ({
    type: RECEIVE_PROMOTION_PAIRS,
    json: json
});

const requestOfferData = (): AnyAction => ({
    type: REQUEST_OFFER_DATA
});

const receiveOfferData = (json: Json): AnyAction => ({
    type: RECEIVE_OFFER_DATA,
    json: json
});

const requestCustomFactorData = (): AnyAction => ({
    type: REQUEST_CUSTOM_FACTOR_DATA
});

const receiveCustomFactorData = (json: Json): AnyAction => ({
    type: RECEIVE_CUSTOM_FACTOR_DATA,
    json: json
});

/*
export const requestAvailableBreakdowns = (): AnyAction => ({
    type: REQUEST_AVAILABLE_BREAKDOWNS
});

export const receiveAvailableBreakdowns = (json: Json): AnyAction => ({
    type: RECEIVE_AVAILABLE_BREAKDOWNS,
    json: json
});
*/

const requestSavedSegmentSets = (): AnyAction => ({
    type: REQUEST_SAVED_SEGMENT_SETS
});

const receiveSavedSegmentSets = (json: Json): AnyAction => ({
    type: RECEIVE_SAVED_SEGMENT_SETS,
    json: json
});

const requestSavedAnalyses = (): AnyAction => ({
    type: REQUEST_SAVED_ANALYSES
});

const receiveSavedAnalyses = (json: Json): AnyAction => ({
    type: RECEIVE_SAVED_ANALYSES,
    json: json
});

const requestSavedAnalysesCampaigns = (): AnyAction => ({
    type: REQUEST_SAVED_ANALYSES_CAMPAIGNS
});

const receiveSavedAnalysesCampaigns = (json: Json): AnyAction => ({
    type: RECEIVE_SAVED_ANALYSES_CAMPAIGNS,
    json: json
});

const requestChartRecordData = (): AnyAction => ({
    type: REQUEST_CHART_RECORD_DATA
});

const receiveChartRecordData = (json: Json): AnyAction => ({
    type: RECEIVE_CHART_RECORD_DATA,
    json: json
});

const requestInitialChartDesign = (): AnyAction => ({
    type: REQUEST_INITIAL_CHART_DESIGN
});

const receiveInitialChartDesign = (json: Json): AnyAction => ({
    type: RECEIVE_INITIAL_CHART_DESIGN,
    json: json
});

const requestChartDesign = (): AnyAction => ({
    type: REQUEST_CHART_DESIGN
});

const receiveChartDesign = (json: Json): AnyAction => ({
    type: RECEIVE_CHART_DESIGN,
    json: json
});

const requestChartDesignById = (): AnyAction => ({
    type: REQUEST_CHART_DESIGN_BY_ID
});

const receiveChartDesignById = (json: Json): AnyAction => ({
    type: RECEIVE_CHART_DESIGN_BY_ID,
    json: json
});

const requestFactorAnalysisChartList = (): AnyAction => ({
    type: REQUEST_FACTOR_ANALYSIS_CHART_LIST
});

const receiveFactorAnalysisChartList = (json: Json): AnyAction => ({
    type: RECEIVE_FACTOR_ANALYSIS_CHART_LIST,
    json: json
});

export const resetChartDesign = (): AnyAction => ({
    type: RESET_CHART_DESIGN
});

const requestCustomFactorDataCreate = (): AnyAction => ({
    type: REQUEST_CUSTOM_FACTOR_DATA_CREATE
});

const receiveCustomFactorDataCreate = (json: Json): AnyAction => ({
    type: RECEIVE_CUSTOM_FACTOR_DATA_CREATE,
    json: json
});

export const updateAnalysisLegend = (seriesIdentifier: string): AnyAction => ({
    type: UPDATE_ANALYSIS_LEGEND,
    seriesIdentifier: seriesIdentifier
});

export const updateAnalysisCampaign = (campaign: Campaign): AnyAction => ({
    type: UPDATE_ANALYSIS_CAMPAIGN,
    campaign: campaign
});

export const updateSavedAnalysesCampaignFilter = (
    campaignEntityIds: string[]
): AnyAction => ({
    type: UPDATE_SAVED_ANALYSIS_CAMPAIGN_FILTER,
    campaignEntityIds
});

export const updateSavedAnalysesAnalysisTypeFilter = (
    analysisTypes: string[]
): AnyAction => ({
    type: UPDATE_SAVED_ANALYSIS_ANALYSIS_TYPE_FILTER,
    analysisTypes
});

export const updateAnalysisChartDesign = (
    chartDesign: ChartDesign | null
): AnyAction => ({
    type: UPDATE_ANALYSIS_CHART_DESIGN,
    chartDesign: chartDesign
});

export const updateAnalysisType = (
    analysisType: AnalysisType | null
): AnyAction => ({
    type: UPDATE_ANALYSIS_TYPE,
    analysisType: analysisType
});

export const updateAnalysisChartRecordsSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => ({
    type: UPDATE_SORT_ANALYSIS_CHART_RECORDS,
    sortBy: sortBy,
    sortOrder: sortOrder
});

export const updateAnalysisOffersSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => ({
    type: UPDATE_SORT_ANALYSIS_OFFERS,
    sortBy: sortBy,
    sortOrder: sortOrder
});

export const updateExportDataTableSettings = (
    gridColumns: string | null,
    gridSortConditions: string | null
): AnyAction => ({
    type: UPDATE_EXPORT_DATA_TABLE_SETTINGS,
    gridColumns: gridColumns,
    gridSortConditions: gridSortConditions
});

export const updateAnalysisCustomFactorsSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => ({
    type: UPDATE_SORT_ANALYSIS_CUSTOM_FACTORS,
    sortBy: sortBy,
    sortOrder: sortOrder
});

export const updateDisableOutsideClick = (disable: boolean): AnyAction => ({
    type: UPDATE_DISABLE_OUTSIDE_CLICK,
    disable
});

export const updateFactorAnalysis = (
    factorAnalysisChartList: FactorAnalysisChartList
) => ({
    type: UPDATE_FACTOR_ANALYSIS_CHART_LIST,
    factorAnalysisChartList
});

export const updateFactorAnalysisCharts = (charts: FactorAnalysisChart[]) => ({
    type: UPDATE_FACTOR_ANALYSIS_CHART_LIST_CHARTS,
    charts
});

export const updateOfferLabelToEdit = (
    offerToEditEntityId: string
): AnyAction => ({
    type: UPDATE_ANALYSIS_OFFER_LABEL_TO_EDIT,
    offerToEditEntityId
});

export const updateAnalysisEdited = (edited: boolean): AnyAction => ({
    type: UPDATE_ANALYSIS_EDITED,
    edited
});

export const updateSavedAnalysesStartIndex = (startIndex: number) => ({
    type: UPDATE_START_INDEX_SAVED_ANALYSES,
    startIndex: startIndex
});

export const updateSavedAnalysisSortBy = (
    sortBy: string,
    sortOrder: SortOrder
) => ({
    type: UPDATE_SORT_SAVED_ANALYSES,
    sortBy: sortBy,
    sortOrder: sortOrder
});

export const updateSavedAnalysesSearch = (search: string) => ({
    type: UPDATE_SEARCH_SAVED_ANALYSES,
    search: search
});

export const updateFactorCampaignsStartIndex = (startIndex: number) => ({
    type: UPDATE_START_INDEX_FACTOR_CAMPAIGNS,
    startIndex: startIndex
});

export const updateFactorCampaignsSortBy = (
    sortBy: string,
    sortOrder: SortOrder
) => ({
    type: UPDATE_SORT_FACTOR_CAMPAIGNS,
    sortBy: sortBy,
    sortOrder: sortOrder
});

export const updateFactorOffersStartIndex = (startIndex: number) => ({
    type: UPDATE_START_INDEX_FACTOR_OFFERS,
    startIndex: startIndex
});

export const updateFactorOffersSortBy = (
    sortBy: string,
    sortOrder: SortOrder
) => ({
    type: UPDATE_SORT_FACTOR_OFFERS,
    sortBy: sortBy,
    sortOrder: sortOrder
});

const getAnalysisCampaignsRequest = (): ListQueryRequest => {
    const sortConditions = [
        sortCondition("state", ASCENDING_QUERY_API),
        sortCondition("endDate", DESCENDING_QUERY_API)
    ];
    const stateConditions: FilterCondition[] = [
        enumFilterCondition("state", CAMPAIGN_STATE_COMPLETED, "CampaignState"),
        enumFilterCondition("state", CAMPAIGN_STATE_PAUSED, "CampaignState"),
        enumFilterCondition("state", CAMPAIGN_STATE_RUNNING, "CampaignState")
    ];
    const filterCondition = orFilterCondition(stateConditions);
    return {
        entity: "Campaign",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: PAGE_SIZE_UNLIMITED,
        model: "CampaignModel/campaignSelector",
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: [],
        sortConditions: sortConditions,
        startIndex: 0
    };
};

export function fetchAnalysisCampaigns() {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getAnalysisCampaignsRequest();
        },
        preRequestFunc: requestAnalysisCampaigns,
        okDispatchFunc: receiveAnalysisCampaigns,
        okResultFunc: (json: any, state: RootState) => {
            return { items: json.result };
        }
    });
}

const getDefaultChartDesignRequest = (analysisType: AnalysisType | null) => {
    const displayType = getChartDisplayType(analysisType);
    return {
        chartDisplayType: {
            objectType: "model/ChartDisplayTypeModel",
            value: displayType
        },
        chartType: {
            objectType: "model/ChartTypeModel",
            value: analysisType
        },
        entityId: null,
        fieldSet: "initializeFields",
        objectType: "model/ChartSessionModel",
        settings: []
    };
};

export function fetchInitialChartDesignCampaignComparison() {
    return makeRequestThunk("api/chart/initialize", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            const analysisType = state.analysis.analysisType;
            return getDefaultChartDesignRequest(analysisType);
        },
        preRequestFunc: requestInitialChartDesign,
        okDispatchFunc: receiveInitialChartDesign
    });
}

export function fetchInitialChartDesign() {
    return makeRequestThunk("api/chart/initialize", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            const analysisType = state.analysis.analysisType;
            return getDefaultChartDesignRequest(analysisType);
        },
        urlPartFunc: function (state: RootState): string {
            const analysisType = state.analysis.analysisType;
            if (analysisType !== ANALYSIS_TYPE_CAMPAIGN_COMPARISON) {
                const campaignId = get(state, "analysis.campaign.entityId", "");
                return "/" + campaignId;
            } else {
                return "";
            }
        },
        preRequestFunc: requestInitialChartDesign,
        okDispatchFunc: receiveInitialChartDesign
    });
}

export function fetchChartDesign(chartDefinition: ChartDefinition) {
    return makeRequestThunk("api/chart/load", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return Object.assign(
                {},
                { fieldSet: "persistFields" },
                chartDefinition
            );
        },
        preRequestFunc: requestChartDesign,
        okDispatchFunc: receiveChartDesign
    });
}

export function fetchChartDesignById(reportId: string) {
    return makeRequestThunk("api/chart/load/" + reportId, {
        method: METHOD_GET,
        preRequestFunc: requestChartDesignById,
        okDispatchFunc: receiveChartDesignById
    });
}

export function fetchLocalChartDesign(chartDefinition: ChartDefinition) {
    return makeRequestThunk("api/chart/load", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return Object.assign(
                {},
                { fieldSet: "persistFields" },
                chartDefinition
            );
        },
        okResultFunc: (json: Json, state: RootState) => {
            return json;
        }
    });
}

export function fetchFactorAnalysisChartList(
    chartList: FactorAnalysisChartList
) {
    return makeRequestThunk("api/factor-analysis/load-multiple", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return chartList;
        },
        // re-using receive chart list since reducer is the same
        okDispatchFunc: receiveFactorAnalysisChartList,
        showLoadMask: false
    });
}

export function fetchFactorAnalysisChartListById(reportId: string) {
    return makeRequestThunk("api/factor-analysis/load-multiple/" + reportId, {
        method: METHOD_GET,
        preRequestFunc: requestFactorAnalysisChartList,
        okDispatchFunc: receiveFactorAnalysisChartList,
        showLoadMask: false
    });
}

export function updateOfferLabels(
    offerLabels: OfferLabelPersist[],
    hideSuccess = true
) {
    return makeRequestThunk("api/promotion/shortLabel", {
        method: METHOD_PUT,
        body: offerLabels,
        okDispatchFunc: (json: Json, state: RootState) => {
            const actions = [];
            if (!hideSuccess) {
                const successMessage = {
                    resourceKey: "promotion.edit_offer_label_success"
                };
                actions.push(success(successMessage));
            }
            return actions;
        },
        okResultFunc: (json: Json, state: RootState) => {
            return json;
        }
    });
}

const getOffersRequest = (state: RootState) => {
    const sortConditions = [
        sortCondition("promotionName", ASCENDING_QUERY_API)
    ];
    return {
        chartDefinition: get(state, "analysis.chartDesign.definition"),
        groupByPromotion: true,
        includeAll: true,
        includeReferencePromotion: true,
        maxResults: PAGE_SIZE_UNLIMITED,
        objectType: "ChartQuery",
        propertySelection: [],
        sortConditions: sortConditions,
        startIndex: 0
    };
};

export function fetchOffers() {
    return makeRequestThunk("api/chart/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getOffersRequest(state);
        },
        preRequestFunc: requestOfferData,
        okDispatchFunc: receiveOfferData,
        okResultFunc: (json: any, state: RootState) => {
            return {
                items: json.result.map((data: ChartRecord) => {
                    return reduceChartRecordData(data);
                }),
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

const getCustomFactorsRequest = (state: RootState): ListQueryRequest => {
    const sortConditions = [sortCondition("name", ASCENDING_QUERY_API)];

    let filterCondition: FilterCondition | null = null;
    const chartDesign = get(state, "analysis.chartDesign");
    if (chartDesign) {
        filterCondition = stringFilterCondition(
            "campaign.entityId",
            get(chartDesign, "definition.campaign.entityId"),
            "EQUALS"
        );
    }

    return {
        entity: "CustomFactor",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: PAGE_SIZE_UNLIMITED,
        model: "CustomFactorModel/gridFields",
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: [],
        sortConditions: sortConditions,
        startIndex: 0
    };
};

export function fetchCustomFactors() {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getCustomFactorsRequest(state);
        },
        preRequestFunc: requestCustomFactorData,
        okDispatchFunc: receiveCustomFactorData,
        okResultFunc: (json: any, state: RootState) => {
            return {
                items: json.result,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

export function fetchCustomFactorDataCreateEdit(customFactorEntityId: string) {
    return makeRequestThunk("api/customfactor/", {
        method: METHOD_GET,
        preRequestFunc: requestCustomFactorDataCreate,
        okDispatchFunc: receiveCustomFactorDataCreate,
        urlPartFunc: function (state: RootState): string {
            const campaignId = get(state, "analysis.campaign.entityId", "");
            const chartDefinitionId = get(
                state,
                "analysis.chartDesign.definition.entityId",
                ""
            );
            if (customFactorEntityId) {
                return "edit/" + customFactorEntityId + "/" + chartDefinitionId;
            } else {
                return "create/" + campaignId + "/" + chartDefinitionId;
            }
        },
        okResultFunc: (json: any, state: RootState) => {
            return json;
        },
        showLoadMask: false
    });
}

export function writeCustomFactor(customFactor: CustomFactor) {
    return makeRequestThunk("api/customfactor/save", {
        method: METHOD_POST,
        body: customFactor,
        okResultFunc: () => {
            return;
        }
    });
}

export function deleteCustomFactor(entityId: string) {
    const url = "api/entity/customfactor/";
    return makeRequestThunk(url + entityId, {
        isCancellable: false,
        method: METHOD_DELETE,
        okResultFunc: () => {
            return;
        }
    });
}

export function fetchPromotionDetailData(
    entityId: string,
    modelAndFieldSet = "PromotionModel/promotionDetailWindow"
) {
    const url = "api/entity/promotion/";
    return makeRequestThunk(url + entityId, {
        queryParamsFunc: function (state: RootState) {
            return {
                modelAndFieldSet: modelAndFieldSet
            };
        },
        method: METHOD_GET,
        okResultFunc: (json: any, state: RootState) => {
            return json;
        }
    });
}

export function fetchFinancialChartRecord(chartRecordId: string) {
    return makeRequestThunk("api/entity/chartrecord/" + chartRecordId, {
        method: METHOD_GET,
        queryParamsFunc: function (state: RootState) {
            return {
                modelAndFieldSet: "ChartRecordModel/financialReadFields"
            };
        },
        okResultFunc: (json: any, state: RootState) => {
            return json;
        }
    });
}

export function saveChart(chartDefinition: ChartDefinition) {
    return makeRequestThunk("api/chart/save", {
        method: METHOD_POST,
        body: chartDefinition,
        okDispatchFunc: (json: any, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            actions.push(receiveChartDesignById(json));
            actions.push(updateAnalysisEdited(false));
            return actions;
        }
    });
}

export function saveFactorChartList(
    factorAnalysisChartList: FactorAnalysisChartList
) {
    return makeRequestThunk("api/factor-analysis/save-multiple", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return factorAnalysisChartList;
        },
        okDispatchFunc: (json: any, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            actions.push(receiveFactorAnalysisChartList(json));
            actions.push(updateAnalysisEdited(false));
            return actions;
        }
    });
}

const getChartRecordsRequest = (state: RootState) => {
    const sortOrder = formatSortOrderForQueryAPI(
        get(state, "analysis.chartRecords.sortOrder")
    );
    const sortConditions = [
        sortCondition(get(state, "analysis.chartRecords.sortBy"), sortOrder)
    ];

    const chartDesign = get(state, "analysis.chartDesign");
    let chartDef;
    if (chartDesign) {
        chartDef = chartDesign.definition;
    }

    return {
        chartDefinition: chartDef,
        groupByPromotion: true,
        includeAll: false,
        includeReferencePromotion: true,
        maxResults: PAGE_SIZE_UNLIMITED,
        objectType: "ChartQuery",
        propertySelection: [],
        sortConditions: sortConditions,
        startIndex: 0
    };
};

export function fetchChartRecords() {
    return makeRequestThunk("api/chart/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getChartRecordsRequest(state);
        },
        preRequestFunc: requestChartRecordData,
        okDispatchFunc: receiveChartRecordData,
        okResultFunc: (json: any, state: RootState) => {
            return {
                items: json.result.map((data: ChartRecord) => {
                    return reduceChartRecordData(data);
                }),
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

export function fetchAvailableBreakdowns(campaignId: string) {
    return makeRequestThunk("api/campaign/breakdown/selector", {
        method: METHOD_GET,
        queryParamsFunc: function (state: RootState) {
            return {
                campaignId: campaignId
            };
        },
        okResultFunc: (json: any, state: RootState) => {
            return json.availableBreakdowns;
        }
    });
}

const getSavedSegmentSetsRequest = (
    testPlatformId: string,
    availableBreakdowns: AvailableBreakdown[]
): ListQueryRequest => {
    const sortConditions = [sortCondition("name", ASCENDING_QUERY_API)];

    const testPlatformCondition = stringFilterCondition(
        "tenantTestPlatform.testPlatform.entityId",
        testPlatformId,
        "EQUALS"
    );

    let filterCondition;

    if (availableBreakdowns && availableBreakdowns.length > 0) {
        const breakdownConditions: FilterCondition[] = [];
        for (let i = 0; i < availableBreakdowns.length; i += 1) {
            breakdownConditions.push(
                stringFilterCondition(
                    "breakdown.entityId",
                    availableBreakdowns[i].breakdown.entityId,
                    "EQUALS"
                )
            );
        }
        const breakdownOrCondition = orFilterCondition(breakdownConditions);

        filterCondition = andFilterCondition([
            testPlatformCondition,
            breakdownOrCondition
        ]);
    } else {
        filterCondition = testPlatformCondition;
    }

    return {
        entity: "SegmentSet",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: PAGE_SIZE_UNLIMITED,
        model: "SegmentSetModel/readFields",
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: [],
        sortConditions: sortConditions,
        startIndex: 0
    };
};

export function fetchSavedSegmentSets(
    testPlatformId: string,
    availableBreakdowns: AvailableBreakdown[]
) {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getSavedSegmentSetsRequest(
                testPlatformId,
                availableBreakdowns
            );
        },
        preRequestFunc: requestSavedSegmentSets,
        okDispatchFunc: receiveSavedSegmentSets,
        okResultFunc: (json: any, state: RootState) => {
            const items: BreakdownSegmentSetGroup[] = [];
            json.result.forEach((item: BreakdownSegmentSet) => {
                items.push(
                    Object.assign({}, item, {
                        group: true,
                        displayName: item.name
                    })
                );
            });
            return {
                items: items,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

const getComparisonCampaignsRequest = (): ListQueryRequest => {
    const sortConditions = [sortCondition("name", ASCENDING_QUERY_API)];
    const stateConditions: FilterCondition[] = [
        enumFilterCondition("state", CAMPAIGN_STATE_COMPLETED, "CampaignState"),
        enumFilterCondition("state", CAMPAIGN_STATE_PAUSED, "CampaignState"),
        enumFilterCondition("state", CAMPAIGN_STATE_RUNNING, "CampaignState")
    ];

    const ctrGT0FilterCondition = singleValueFilterCondition(
        "NumberFilterValue",
        "testPhase.testPhaseData.ctr",
        0,
        "GREATER_THAN"
    );

    const ctrNotNullFilterCondition = notNullFilterCondition(
        "testPhase.testPhaseData.ctr"
    );
    const stateFilterConditions = orFilterCondition(stateConditions);
    const filterCondition = andFilterCondition([
        stateFilterConditions,
        ctrGT0FilterCondition,
        ctrNotNullFilterCondition
    ]);

    return {
        entity: "Campaign",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: PAGE_SIZE_UNLIMITED,
        model: "CampaignModel/selectCampaign",
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: null,
        sortConditions: sortConditions,
        startIndex: 0
    };
};

export function fetchComparisonCampaigns() {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getComparisonCampaignsRequest();
        },
        preRequestFunc: requestComparisonCampaigns,
        okResultFunc: (json: any, state: RootState) => {
            return json;
        },
        showLoadMask: false
    });
}

const getCampaignComparisonPromotionsRequest = (
    campaignEntityId: string
): ListQueryRequest => {
    const sortConditions = [sortCondition("offerLabel", ASCENDING_QUERY_API)];

    const campaignFilterCondition = stringFilterCondition(
        "campaign.entityId",
        campaignEntityId,
        "EQUALS"
    );

    const ctrGT0FilterCondition = singleValueFilterCondition(
        "NumberFilterValue",
        "testPhaseData.ctr",
        0,
        "GREATER_THAN"
    );

    const ctrNotNullFilterCondition = notNullFilterCondition(
        "testPhaseData.ctr"
    );

    const filterCondition = andFilterCondition([
        campaignFilterCondition,
        ctrGT0FilterCondition,
        ctrNotNullFilterCondition
    ]);

    return {
        entity: "Promotion",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: PAGE_SIZE_UNLIMITED,
        model: "PromotionModel/offerComparisonReadFields",
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: null,
        sortConditions: sortConditions,
        startIndex: 0
    };
};

export function fetchCampaignComparisonPromotions(campaignEntityId: string) {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getCampaignComparisonPromotionsRequest(campaignEntityId);
        },
        preRequestFunc: requestCampaignComparisonPromotions,
        okDispatchFunc: receiveCampaignComparisonPromotions,
        okResultFunc: (json: any, state: RootState) => {
            return {
                items: json.result,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

const getPromotionPairsRequest = (
    primaryEntityId: string,
    comparisonEntityId: string,
    promotionPairs: PromotionPair[]
) => {
    return {
        comparisonCampaign: {
            objectType: "model/CampaignModel",
            entityId: comparisonEntityId
        },
        fieldSet: "promotionPairRefreshRequestPersistFields",
        objectType: "model/PromotionPairRefreshRequestModel",
        primaryCampaign: {
            objectType: "model/CampaignModel",
            entityId: primaryEntityId
        },
        promotionPairs: promotionPairs
    };
};

export function fetchPromotionPairs(
    primaryEntityId: string,
    comparisonEntityId: string,
    promotionPairs: PromotionPair[]
) {
    return makeRequestThunk("api/promotionpair", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getPromotionPairsRequest(
                primaryEntityId,
                comparisonEntityId,
                promotionPairs
            );
        },
        preRequestFunc: requestPromotionPairs,
        okDispatchFunc: receivePromotionPairs,
        okResultFunc: (json: any, state: RootState) => {
            return {
                items: json.result,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

const getSavedAnalysesRequest = (state: RootState): ListQueryRequest => {
    const sortOrder = formatSortOrderForQueryAPI(
        state.analysis.savedAnalyses.sortOrder
    );
    const sortConditions = [
        sortCondition(get(state, "analysis.savedAnalyses.sortBy"), sortOrder)
    ];
    const search = get(state, "analysis.savedAnalyses.search");
    const filterSearchCondition = search
        ? freeFormFilterCondition(search, [
              "campaign.name",
              "modifiedBy.fullName",
              "modifiedBy.firstName",
              "modifiedBy.lastName",
              "name"
          ])
        : null;

    const savedFilterCondition = booleanFilterCondition("saved", true);

    const hiddenFilterCondition = booleanFilterCondition("hidden", false);

    const stateCondition = enumFilterCondition(
        "campaign.state",
        CAMPAIGN_STATE_COMPLETED,
        "CampaignState"
    );

    const stateNullCondition = nullFilterCondition("campaign.state");

    let campaignFilterCondition = null;
    const campaignFilterIds = get(
        state,
        "analysis.savedAnalyses.campaigns",
        []
    );
    if (campaignFilterIds.length > 0) {
        campaignFilterCondition = orFilterCondition([
            stringInFilterCondition("campaign.entityId", campaignFilterIds),
            stringInFilterCondition(
                "comparisonCampaign.entityId",
                campaignFilterIds
            )
        ]);
    }

    let analysisTypeFilterCondition = null;
    const analysisTypes = get(
        state,
        "analysis.savedAnalyses.analysisTypes",
        []
    );
    if (analysisTypes.length > 0) {
        analysisTypeFilterCondition = enumInFilterCondition(
            "chartType",
            analysisTypes,
            "ChartType"
        );
    }

    const filterConditions: FilterCondition[] = [
        savedFilterCondition,
        hiddenFilterCondition,
        orFilterCondition([stateCondition, stateNullCondition])
    ];

    if (filterSearchCondition) {
        filterConditions.push(filterSearchCondition);
    }

    if (campaignFilterCondition) {
        filterConditions.push(campaignFilterCondition);
    }

    if (analysisTypeFilterCondition) {
        filterConditions.push(analysisTypeFilterCondition);
    }

    const filterCondition = andFilterCondition(filterConditions);

    return {
        entity: "ChartDefinition",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: DEFAULT_PAGE_SIZE,
        model: "ChartDefinitionModel/savedReports",
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: null,
        sortConditions: sortConditions,
        startIndex: state.analysis.savedAnalyses.startIndex
    };
};

export function fetchSavedAnalyses() {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getSavedAnalysesRequest(state);
        },
        preRequestFunc: requestSavedAnalyses,
        okDispatchFunc: receiveSavedAnalyses,
        okResultFunc: (json: any, state: RootState) => {
            return {
                items: json.result,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}

export const deleteSavedAnalysesConfirmation = (
    analysis: ChartDefinition,
    t: TFunction,
    dispatch: AppDispatch,
    callbackFunction?: NoArgsHandler
): void => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.yes")
            },
            callbackData: { ...analysis, callbackFunction },
            callbackFunction: handleDeleteSavedAnalysisConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey: "analysis.delete_saved_analysis_confirmation",
                values: {
                    analysis: analysis.name
                }
            },
            showCloseIcon: false,
            title: t("analysis.delete_saved_analysis")
        })
    );
};

const handleDeleteSavedAnalysisConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
): void => {
    if (button === BUTTON_YES) {
        dispatch(
            deleteSavedAnalysis(
                callbackData.entityId,
                callbackData.name,
                callbackData.callbackFunction
            )
        );
    }
};

const deleteSavedAnalysis = (
    entityId: string,
    name: string,
    callbackFunction?: NoArgsHandler
) => {
    const url = "api/entity/chartdefinition/";
    return makeRequestThunk(url + entityId, {
        isCancellable: false,
        method: METHOD_DELETE,
        okDispatchFunc: (json: Json, state: RootState) => {
            const actions = [];
            const successMessage = {
                resourceKey: "analysis.delete_saved_analysis_success",
                values: {
                    analysis: name
                }
            };
            if (callbackFunction) {
                callbackFunction();
            }
            actions.push(success(successMessage));
            return actions;
        }
    });
};

const getSavedAnalysesCampaignsRequest = (
    state: RootState
): ListQueryRequest => {
    const sortConditions = [sortCondition("name", ASCENDING_QUERY_API)];

    const savedCampaignFilterCondition = subqueryInFilterCondition(
        "id",
        "ChartDefinition",
        "campaign.id",
        booleanFilterCondition("saved", true)
    );

    const savedComparisonCampaignFilterCondition = subqueryInFilterCondition(
        "id",
        "ChartDefinition",
        "comparisonCampaign.id",
        booleanFilterCondition("saved", true)
    );

    const stateCondition = enumFilterCondition(
        "state",
        CAMPAIGN_STATE_COMPLETED,
        "CampaignState"
    );

    const filterConditions: FilterCondition[] = [
        stateCondition,
        orFilterCondition([
            savedCampaignFilterCondition,
            savedComparisonCampaignFilterCondition
        ])
    ];

    const filterCondition = andFilterCondition(filterConditions);

    return {
        entity: "Campaign",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: PAGE_SIZE_UNLIMITED,
        model: "CampaignModel/campaignSelector",
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: null,
        sortConditions: sortConditions,
        startIndex: state.analysis.savedAnalyses.startIndex
    };
};

export const fetchSavedAnalysesCampaigns = () => {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getSavedAnalysesCampaignsRequest(state);
        },
        preRequestFunc: requestSavedAnalysesCampaigns,
        okDispatchFunc: receiveSavedAnalysesCampaigns,
        okResultFunc: (json: any, state: RootState) => {
            return json.result;
        },
        showLoadMask: false
    });
};

type FactorAnalysisSearchRequestType = {
    factorAnalysisEntityId: string | null;
    invertExclusion: boolean;
    parameters: FactorAnalysisParameters;
};

const getExclusionRequestParams = (request: any) => {
    return {
        ...request,
        pageSize: -1,
        searchTerm: "",
        sortOrder: ASCENDING_QUERY_API,
        sortProperty: "name",
        startIndex: 0
    };
};

const getFactorOffersRequest = (
    props: FactorAnalysisSearchRequestType & { state: RootState }
) => {
    const {
        factorAnalysisEntityId,
        invertExclusion,
        parameters,
        state
    } = props;

    let request = {
        factorAnalysisEntityId: factorAnalysisEntityId,
        invertExclusion: invertExclusion,
        objectType: "FactorAnalysisSearchRequest",
        pageSize: DEFAULT_PAGE_SIZE,
        parameters: parameters,
        searchTerm: state.analysis.factor.offers.search,
        sortOrder: formatSortOrderForQueryAPI(
            state.analysis.factor.offers.sortOrder
        ),
        sortProperty: state.analysis.factor.offers.sortBy,
        startIndex: state.analysis.factor.offers.startIndex
    };

    // If grabbing excluded offers grab them all
    if (invertExclusion) {
        request = getExclusionRequestParams(request);
    }

    return request;
};

export const fetchFactorOffers = (props: FactorAnalysisSearchRequestType) => {
    const { factorAnalysisEntityId, invertExclusion, parameters } = props;
    return makeRequestThunk("api/factor-analysis/promotion/search", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getFactorOffersRequest({
                factorAnalysisEntityId: factorAnalysisEntityId
                    ? factorAnalysisEntityId
                    : null,
                invertExclusion,
                parameters,
                state
            });
        },
        preRequestFunc: requestFactorOffers,
        okDispatchFunc: receiveFactorOffers,
        okResultFunc: (json: any, state: RootState) => {
            return {
                items: json.result,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
};

const getFactorCampaignsRequest = (
    props: FactorAnalysisSearchRequestType & { state: RootState }
) => {
    const {
        factorAnalysisEntityId,
        invertExclusion,
        parameters,
        state
    } = props;
    let request = {
        factorAnalysisEntityId: factorAnalysisEntityId,
        invertExclusion: invertExclusion,
        objectType: "FactorAnalysisSearchRequest",
        pageSize: DEFAULT_PAGE_SIZE,
        parameters: parameters,
        searchTerm: state.analysis.factor.campaigns.search,
        sortOrder: formatSortOrderForQueryAPI(
            get(state, "analysis.factor.campaigns.sortOrder")
        ),
        sortProperty: state.analysis.factor.campaigns.sortBy,
        startIndex: state.analysis.factor.campaigns.startIndex
    };

    // If grabbing excluded offers grab them all
    if (invertExclusion) {
        request = getExclusionRequestParams(request);
    }

    return request;
};

export const fetchFactorCampaigns = (
    props: FactorAnalysisSearchRequestType
) => {
    const { factorAnalysisEntityId, invertExclusion, parameters } = props;
    return makeRequestThunk("api/factor-analysis/campaign/search", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getFactorCampaignsRequest({
                factorAnalysisEntityId: factorAnalysisEntityId
                    ? factorAnalysisEntityId
                    : null,
                invertExclusion,
                parameters,
                state
            });
        },
        preRequestFunc: requestFactorCampaigns,
        okDispatchFunc: receiveFactorCampaigns,
        okResultFunc: (json: any, state: RootState) => {
            return {
                items: json.result,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
};

export const fetchFactorCounts = (parameters: FactorAnalysisParameters) => {
    return makeRequestThunk("api/factor-analysis/count", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return parameters;
        },
        okResultFunc: (json: any, state: RootState) => {
            return json;
        },
        showLoadMask: false
    });
};

export const fetchInitialFactorAnalysis = () => {
    return makeRequestThunk("api/factor-analysis/init", {
        method: METHOD_GET,
        okResultFunc: (json: any, state: RootState) => {
            return json;
        },
        showLoadMask: false
    });
};

export const generateFactorAnalysis = (
    name: string,
    description: string,
    parameters: FactorAnalysisParameters[]
) => {
    return makeRequestThunk("api/factor-analysis/submit-multiple", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return {
                headline: description,
                name,
                objectType: "FactorAnalysisParametersList",
                parameters
            };
        },
        okDispatchFunc: (json: any, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const successMessage = json.data;
            actions.push(savedAnalysesPage());
            actions.push(success(successMessage));
            return actions;
        }
    });
};

export const fetchDefaultSettings = (chartDef: ChartDefinition) => {
    return makeRequestThunk("api/chart/settings", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return chartDef;
        },
        okResultFunc: (json: any, state: RootState) => {
            return json;
        },
        showLoadMask: false
    });
};

export const saveDefaultSettings = (chartDef: ChartDefinition) => {
    return makeRequestThunk("api/chart/settings/save", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return chartDef;
        },
        okResultFunc: (json: any, state: RootState) => {
            return json;
        }
    });
};
