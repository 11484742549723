import get from "lodash.get";
import type { AnyAction } from "redux";
import { reduceByKeys } from "common/util/object";
import {
    CHECKLIST_ITEM_BARCODES,
    CHECKLIST_ITEM_REGISTER_BARCODES,
    CHECKLIST_ITEM_VENDOR_CODE
} from "campaign/components/campaignViewUtils";
import {
    CAMPAIGN_NAME,
    reduceCampaignCei,
    reduceCampaignProgress
} from "campaign/state/campaignStateUtils";
import {
    RECEIVE_CAMPAIGN,
    RECEIVE_CAMPAIGN_CEI,
    RECEIVE_CAMPAIGN_PROGRESS,
    RECEIVE_CAMPAIGN_TEST_INSIGHTS,
    REQUEST_CAMPAIGN,
    REQUEST_CAMPAIGN_CEI,
    REQUEST_CAMPAIGN_PROGRESS,
    RESET_CAMPAIGN
} from "campaign/state/campaignActions";
import { reduceCampaignTestInsights } from "testPhaseData/state/testPhaseDataStateUtils";
import {
    CAMPAIGN_BUDGET_OPTIMIZATION_EDIT_PAGE,
    CAMPAIGN_BUDGET_OPTIMIZATION_PAGE,
    CAMPAIGN_EDIT_PAGE,
    CAMPAIGN_OFFERS_PAGE,
    CAMPAIGN_OVERVIEW_PAGE,
    CAMPAIGN_PAGE,
    CAMPAIGN_PPG_EDIT_PAGE,
    CAMPAIGN_PPGS_PAGE,
    CAMPAIGN_REDEMPTION_EDIT_PAGE,
    CAMPAIGN_REDEMPTION_PAGE,
    CAMPAIGN_TARGETING_PAGE,
    CAMPAIGN_TARGETING_EDIT_PAGE,
    CAMPAIGN_TEST_RESULTS_PAGE,
    CAMPAIGN_UPCS_PAGE,
    OFFER_ADVANCED_CREATE_PAGE,
    OFFER_AUTOMATED_CREATE_PAGE,
    OFFER_EDIT_PAGE,
    REPLACE_IMAGE_PAGE,
    PROMOTION_EDIT_PAGE
} from "shell/state/pageActions";
import { getDefaultListState } from "common/util/query";

type CampaignState = {
    cei: {
        periods: any[];
        series: any[];
    };
    discountDistribution: SimpleChart | null;
    entityId: string;
    insights: {
        displayMetrics: any[];
        selectedControlOfferId?: string;
        selectedControlOfferLabel?: string;
    };
    name: string;
    progress: {
        atLeastOneValidSeries: boolean;
        currencySymbol: string;
        hasSpendInformation?: boolean;
        invalidSeriesIndices: string[];
        series: Dictionary<any>;
        status: any;
        statusMessage: string;
        validSeriesIndices: string[];
    };
    upstreamCampaigns: QueryState;
};

const defaultState: CampaignState = {
    cei: {
        periods: [],
        series: []
    },
    discountDistribution: null,
    entityId: "",
    insights: {
        displayMetrics: [],
        selectedControlOfferId: undefined,
        selectedControlOfferLabel: undefined
    },
    name: "",
    progress: {
        atLeastOneValidSeries: false,
        currencySymbol: "",
        hasSpendInformation: undefined,
        invalidSeriesIndices: [],
        series: [],
        status: undefined,
        statusMessage: "",
        validSeriesIndices: []
    },
    upstreamCampaigns: getDefaultListState(CAMPAIGN_NAME)
};

const reduceCampaign = (campaign: Campaign): any => {
    const cItems = get(campaign, "checklistItems", []);
    const checklistItems = cItems.filter(checklistItem => {
        return ![
            CHECKLIST_ITEM_BARCODES,
            CHECKLIST_ITEM_REGISTER_BARCODES,
            CHECKLIST_ITEM_VENDOR_CODE
        ].includes(checklistItem.field.name);
    });
    return Object.assign({}, campaign, {
        // @ts-expect-error - allowableActions
        allowableActions: reduceByKeys(campaign.allowableActions, "allowed"),
        checklistItems: checklistItems,
        supportsCeiOverTime: get(
            campaign,
            "tenantTestPlatform_testPlatform_supportsCeiOverTime",
            false
        ),
        supportsProgress: get(
            campaign,
            "tenantTestPlatform_testPlatform_supportsProgress",
            false
        )
    });
};

function campaign(state: CampaignState = defaultState, action: AnyAction) {
    switch (action.type) {
        case CAMPAIGN_EDIT_PAGE:
        case CAMPAIGN_PAGE:
            return Object.assign({}, defaultState, {
                entityId: action.payload && action.payload.entityId
            });
        case CAMPAIGN_BUDGET_OPTIMIZATION_EDIT_PAGE:
        case CAMPAIGN_BUDGET_OPTIMIZATION_PAGE:
        case CAMPAIGN_REDEMPTION_EDIT_PAGE:
        case CAMPAIGN_REDEMPTION_PAGE:
        case CAMPAIGN_OFFERS_PAGE:
        case CAMPAIGN_OVERVIEW_PAGE:
        case CAMPAIGN_PPG_EDIT_PAGE:
        case CAMPAIGN_PPGS_PAGE:
        case CAMPAIGN_TARGETING_PAGE:
        case CAMPAIGN_TARGETING_EDIT_PAGE:
        case CAMPAIGN_TEST_RESULTS_PAGE:
        case CAMPAIGN_UPCS_PAGE:
        case OFFER_ADVANCED_CREATE_PAGE:
        case OFFER_AUTOMATED_CREATE_PAGE:
        case OFFER_EDIT_PAGE:
        case PROMOTION_EDIT_PAGE:
        case REPLACE_IMAGE_PAGE:
            return Object.assign({}, state, {
                entityId: action.payload && action.payload.entityId
            });
        case RECEIVE_CAMPAIGN:
            return Object.assign(
                {},
                state,
                reduceCampaign(get(action, "campaign"))
            );
        case REQUEST_CAMPAIGN:
        case RESET_CAMPAIGN:
            return Object.assign({}, defaultState, {
                entityId: state.entityId
            });
        case RECEIVE_CAMPAIGN_CEI:
            return Object.assign({}, state, {
                cei: reduceCampaignCei(get(action, "json"))
            });
        case REQUEST_CAMPAIGN_CEI:
            return state;
        case RECEIVE_CAMPAIGN_PROGRESS:
            return Object.assign({}, state, {
                progress: reduceCampaignProgress(get(action, "json"))
            });
        case REQUEST_CAMPAIGN_PROGRESS:
            return Object.assign({}, state, {
                progress: defaultState.progress
            });
        case RECEIVE_CAMPAIGN_TEST_INSIGHTS:
            return Object.assign({}, state, {
                insights: reduceCampaignTestInsights(get(action, "json"))
            });
        default:
            return state;
    }
}

export default campaign;
