import get from "lodash.get";

export const IGNORE_REACT_OUTSIDE_CLICK = "ignore-react-click-outside";

const containsIgnoreReactOutsideClickHelper = (
    element: HTMLElement
): boolean => {
    const classList = get(element, "classList");
    const parentNode = get(element, "parentElement");
    if (classList && classList.contains(IGNORE_REACT_OUTSIDE_CLICK)) {
        return true;
    } else if (parentNode) {
        return containsIgnoreReactOutsideClickHelper(parentNode);
    }
    return false;
};

export const containsIgnoreReactOutsideClick = (event: MouseEvent): boolean => {
    if (event) {
        return containsIgnoreReactOutsideClickHelper(
            event.target as HTMLElement
        );
    }
    return false;
};
