import type { AnyAction } from "redux";
import noAction from "common/util/noAction";
import {
    REQUEST_OPPORTUNITIES,
    RECEIVE_OPPORTUNITIES,
    UPDATE_OPPORTUNITIES_AGGREGATE,
    UPDATE_OPPORTUNITIES_SORT_BY,
    UPDATE_OPPORTUNITIES_OFFSET
} from "opportunities/state/opportunityActions";
import {
    defaultOpportunitiesOverviewState,
    reduceOverview
} from "opportunities/state/opportunityStateUtils";
import type { OpportunitiesOverview } from "opportunities/state/opportunityStateUtils";

const opportunities = (
    state: OpportunitiesOverview = defaultOpportunitiesOverviewState,
    action: AnyAction = noAction
): OpportunitiesOverview => {
    switch (action.type) {
        case REQUEST_OPPORTUNITIES:
            return state;
        case RECEIVE_OPPORTUNITIES:
            return Object.assign(
                {},
                state,
                reduceOverview(state, action.opportunities)
            );
        case UPDATE_OPPORTUNITIES_SORT_BY:
            return Object.assign({}, state, {
                sortBy: action.sortBy,
                sortOrder: action.sortOrder
            });
        case UPDATE_OPPORTUNITIES_OFFSET:
            return Object.assign({}, state, { offset: action.offset });
        case UPDATE_OPPORTUNITIES_AGGREGATE:
            return Object.assign({}, state, {
                groupByFields: action.groupByFields
            });
        default:
            return state;
    }
};

export default opportunities;
