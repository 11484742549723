import styled from "styled-components/macro";

const SubText = styled("div")`
    color: ${props => props.theme.modalSubText};
    font-size: 12px;
    font-weight: normal;
`;

SubText.displayName = "SubText";

export default SubText;
