import * as React from "react";
import type { TFunction } from "i18next";
import styled from "styled-components/macro";
import { reduceObject } from "common/util/object";

export const MAX_TOOLTIP_ITEMS = 10;

export const reduceLabelValue = (
    item: DisplayNameEntityIdType
): LabelValueType => {
    const { displayName, entityId } = item;
    return { label: displayName, value: entityId };
};

export const expandNodes = (nodes: ChkTree[], expanded: string[]) => {
    if (Array.isArray(nodes)) {
        nodes.forEach(node => {
            if (Array.isArray(node.children) && node.children.length > 0) {
                expanded.push(node.value);
                expandNodes(node.children, expanded);
            }
        });
    }
};

export const expandAllParentNodes = (nodes: ChkTree[]): string[] => {
    const expanded: string[] = [];
    expandNodes(nodes, expanded);
    return expanded;
};

const findSelectedChildNode = (
    selectedValue: string,
    node: ChkTree,
    expanded: string[]
): boolean => {
    // Check if child is selected
    if (Array.isArray(node.children) && node.children.length > 0) {
        const found =
            node.children.find(child =>
                findSelectedChildNode(selectedValue, child, expanded)
            ) !== undefined;
        if (found && !expanded.includes(node.value)) {
            expanded.push(node.value);
        }
        return found;
    }
    // Is child node
    else {
        return node.value === selectedValue;
    }
};

export const expandSelectedNodes = (
    nodes: ChkTree[],
    selected: string[]
): string[] => {
    const expanded: string[] = [];
    if (selected.length) {
        selected.forEach(selectedValue => {
            nodes.forEach(node => {
                findSelectedChildNode(selectedValue, node, expanded);
            });
        });
    }
    return expanded;
};

export const flattenChkTreeNodes = (nodes: ChkTree[], collection: any) => {
    if (!Array.isArray(nodes) || nodes.length === 0) {
        return;
    }

    nodes.forEach(node => {
        collection[node.value] = reduceObject(
            node,
            "label",
            "value",
            "objectType"
        );
        flattenChkTreeNodes(node.children, collection);
    });
};

export const processSelectedItemsText = (
    selectedText: string[],
    t: TFunction
): string[] => {
    let processedSelectedText = [];
    if (selectedText.length > MAX_TOOLTIP_ITEMS) {
        processedSelectedText = selectedText.slice(0, MAX_TOOLTIP_ITEMS);
        processedSelectedText.push(
            t("common:general.and_x_more", {
                count: selectedText.length - MAX_TOOLTIP_ITEMS
            })
        );
    } else {
        processedSelectedText = selectedText;
    }
    return processedSelectedText;
};

const FilterSelectedTextChild = styled.div`
    margin-left: 10px;
`;
FilterSelectedTextChild.displayName = "FilterSelectedTextChild";

export const buildSelectedTextTree = (tree: any[]) => {
    return tree.map(parent => {
        const childrenNodes = parent.children.map((child: any) => {
            return (
                <FilterSelectedTextChild key={child}>
                    {child}
                </FilterSelectedTextChild>
            );
        });
        return (
            <React.Fragment key={parent.displayName}>
                <div>{parent.displayName}</div>
                {childrenNodes}
            </React.Fragment>
        );
    });
};

export const freeFormFilterCondition = (
    searchString: string,
    properties: string[]
): FreeFormFilterCondition => {
    return {
        objectType: "FreeFormFilterCondition",
        searchString: searchString,
        properties: properties
    };
};

export const andFilterCondition = (
    conditions: FilterCondition[]
): MultiFilterCondition => {
    return multipleFilterCondition("AND", conditions);
};

export const orFilterCondition = (
    conditions: FilterCondition[]
): MultiFilterCondition => {
    return multipleFilterCondition("OR", conditions);
};

export const multipleFilterCondition = (
    operation: string,
    conditions: FilterCondition[]
): MultiFilterCondition => {
    return {
        objectType: "MultiConditionFilterCondition",
        operation: operation,
        conditions: conditions
    };
};

export const booleanFilterCondition = (
    property: string,
    value: boolean
): SingleValueFilterCondition => {
    return singleValueFilterCondition("BooleanFilterValue", property, value);
};

export const enumFilterCondition = (
    property: string,
    value: string,
    enumType: string
): SingleValueFilterCondition => {
    return singleValueFilterCondition("EnumFilterValue", property, {
        objectType: enumType,
        value: value
    });
};

const enumFilterValues = (
    enumType: string,
    values: string[]
): EnumFilterValue[] => {
    const fValues: EnumFilterValue[] = [];
    for (let i = 0; i < values.length; i += 1) {
        fValues.push({
            objectType: "EnumFilterValue",
            value: {
                objectType: enumType,
                value: values[i]
            }
        });
    }
    return fValues;
};

export const enumInFilterCondition = (
    property: string,
    values: string[],
    enumType: string
): SinglePropertyMultiValueFilterCondition => {
    return {
        objectType: "SinglePropertyMultiValueFilterCondition",
        property: property,
        filterValues: enumFilterValues(enumType, values)
    };
};

export const inFilterCondition = (
    type: string,
    property: string,
    values: string[]
): SinglePropertyMultiValueFilterCondition => {
    return {
        objectType: "SinglePropertyMultiValueFilterCondition",
        property: property,
        filterValues: filterValues(type, values)
    };
};

export const filterValue = (type: string, value: string): FilterValue => {
    return {
        objectType: type,
        value: value
    };
};

export const filterValues = (type: string, values: string[]): FilterValue[] => {
    const fValues = [];
    for (let i = 0; i < values.length; i += 1) {
        fValues.push(filterValue(type, values[i]));
    }
    return fValues;
};

export const numberFilterCondition = (
    property: string,
    value: number,
    operator: string
): SingleValueFilterCondition => {
    return singleValueFilterCondition(
        "NumberFilterValue",
        property,
        value,
        operator
    );
};

export const stringFilterCondition = (
    property: string,
    value: string,
    operator: string
): SingleValueFilterCondition => {
    return singleValueFilterCondition(
        "StringFilterValue",
        property,
        value,
        operator
    );
};

export const stringInFilterCondition = (
    property: string,
    values: string[]
): SinglePropertyMultiValueFilterCondition => {
    return inFilterCondition("StringFilterValue", property, values);
};

export const singleValueFilterCondition = (
    type: string,
    property: string,
    value: number | boolean | string | Date | FilterValue,
    operator?: string
): SingleValueFilterCondition => {
    const opr = operator || "EQUALS";
    return {
        objectType: "SinglePropertySingleValueFilterCondition",
        property: property,
        value: {
            objectType: type,
            value: value
        },
        comparisonOperation: opr
    };
};

export const notNullFilterCondition = (
    property: string
): NonNullFilterCondition => {
    return {
        objectType: "SinglePropertyNotNullFilterCondition",
        property: property
    };
};

export const nullFilterCondition = (property: string): NullFilterCondition => {
    return {
        objectType: "SinglePropertyNullFilterCondition",
        property: property
    };
};

export const subqueryExistsFilterCondition = (
    property: string,
    subEntity: string,
    subProperty: string,
    filterCondition: FilterCondition
): SubQueryFilterCondition => {
    return subqueryFilterCondition(
        "SubQueryExistsFilterCondition",
        property,
        subEntity,
        subProperty,
        filterCondition
    );
};

export const subqueryInFilterCondition = (
    property: string,
    subEntity: string,
    subProperty: string,
    filterCondition?: FilterCondition
): SubQueryFilterCondition => {
    return subqueryFilterCondition(
        "SubQueryInFilterCondition",
        property,
        subEntity,
        subProperty,
        filterCondition
    );
};

const subqueryFilterCondition = (
    type: string,
    property: string,
    subEntity: string,
    subProperty: string,
    filterCondition?: FilterCondition
): SubQueryFilterCondition => {
    return {
        objectType: type,
        property: property,
        subEntity: subEntity,
        subProperty: subProperty,
        filterCondition: filterCondition
    };
};

export const notFilterCondition = (
    condition: FilterCondition
): NotFilterCondition => {
    return {
        objectType: "NotFilterCondition",
        invertedCondition: condition
    };
};
