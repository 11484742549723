import { nanoid } from "nanoid";
import type { Action } from "redux";
import type { TransMessage } from "common/shell/state/messageBoxActions";

export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";
export const REMOVE_ALL_TOASTS = "REMOVE_ALL_TOASTS";

export const TOAST_TYPE_ERROR = "error";
export const TOAST_TYPE_WARNING = "warning";
export const TOAST_TYPE_INFO = "info";
export const TOAST_TYPE_SUCCESS = "success";

export type ToastType =
    | typeof TOAST_TYPE_ERROR
    | typeof TOAST_TYPE_INFO
    | typeof TOAST_TYPE_SUCCESS
    | typeof TOAST_TYPE_WARNING;

export type ToastMessageType = string | TransMessage;

export type Toast = {
    autoDismiss: boolean;
    message: ToastMessageType;
    toastType: ToastType;
};

export type ToastActions = ShowToast | HideToast | RemoveAllToasts;

export interface ShowToast extends Action {
    autoDismiss: boolean;
    message: ToastMessageType;
    toastType: ToastType;
    type: typeof SHOW_TOAST;
    uid: string;
}

export const error = (message: ToastMessageType): ShowToast => {
    return showToast(
        Object.assign(
            {},
            { autoDismiss: false, toastType: TOAST_TYPE_ERROR as ToastType },
            { message: message }
        )
    );
};

export const info = (message: ToastMessageType): ShowToast => {
    return showToast(
        Object.assign(
            {},
            { autoDismiss: true, toastType: TOAST_TYPE_INFO as ToastType },
            { message: message }
        )
    );
};

export const success = (message: ToastMessageType): ShowToast => {
    return showToast(
        Object.assign(
            {},
            { autoDismiss: true, toastType: TOAST_TYPE_SUCCESS as ToastType },
            { message: message }
        )
    );
};

export const warning = (message: ToastMessageType): ShowToast => {
    return showToast(
        Object.assign(
            {},
            { autoDismiss: true, toastType: TOAST_TYPE_WARNING as ToastType },
            { message: message }
        )
    );
};

const showToast = (opts: Toast): ShowToast => {
    return {
        type: SHOW_TOAST,
        ...opts,
        uid: "toast-" + nanoid(7)
    };
};

interface HideToast extends Action {
    type: typeof HIDE_TOAST;
    uid: string;
}

export const hideToast = (uid: string): HideToast => {
    return {
        type: HIDE_TOAST,
        uid
    };
};

interface RemoveAllToasts extends Action {
    type: typeof REMOVE_ALL_TOASTS;
}

export const removeAll = (): RemoveAllToasts => {
    return { type: REMOVE_ALL_TOASTS };
};
