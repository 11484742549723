import * as React from "react";
import isEqual from "lodash.isequal";
import isNil from "lodash.isnil";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Input from "common/components/styled/Input";
import { marginProps, marginPropTypes } from "common/components/styled/util";
import SvgIcon from "common/components/SvgIcon";
import type { SvgIconWithClickProps } from "common/components/SvgIcon";
import theme from "common/components/theme";
import { ReactComponent as SearchIcon } from "common/icons/Search.svg";
import { ReactComponent as XCircleIcon } from "common/icons/XCircle.svg";
import { reduceObject } from "common/util/object";
import {
    composeTrackingComponentLabel,
    getTrackingEventData,
    trackEvent
} from "common/util/tracking";
import {
    EVENT_NAME_FILTER_CLEAR,
    EVENT_NAME_FILTER_SEARCH
} from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";

export const EMPTY_SEARCH_TEXT = "";

type ClearIconProps = Pick<SvgIconWithClickProps, "onClick">;

const ClearIcon = styled(SvgIcon)<ClearIconProps>`
    color: ${theme.greyIcon};
    cursor: pointer;
    display: inline-block;
    fill: currentColor;
    position: absolute;
    right: 12px;
    stroke: currentColor;
    stroke-width: 0;
    top: 50%;
    transform: translateY(-50%);
`;
ClearIcon.displayName = "ClearIcon";

type SearchSvgIconProps = {
    iconSize?: number;
};

const SearchSvgIcon = styled(SvgIcon)<SearchSvgIconProps>`
    color: ${theme.greyIcon};
    display: inline-block;
    fill: currentColor;
    height: ${props => props.iconSize + "px"};
    left: 12px;
    pointer-events: none;
    position: absolute;
    stroke: currentColor;
    top: 50%;
    transform: translateY(-50%);
    width: ${props => props.iconSize + "px"};
`;
SearchSvgIcon.displayName = "SearchSvgIcon";

type SearchBarWrapperProps = Pick<flexboxPropsType, "alignSelf"> &
    Pick<sizePropsType, "minWidth" | "cssWidth">;

const SearchBarWrapper = styled.form<SearchBarWrapperProps>`
    align-self: ${props => props.alignSelf};
    background-color: ${theme.background};
    display: inline-block;
    position: relative;
    width: ${props => props.cssWidth};
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    ${marginProps}
`;
SearchBarWrapper.displayName = "SearchBarWrapper";

type SearchChangeHandler = (searchText: string) => void; //executes on change - fires with each change
type SearchFilterChangeHandler = (searchText: string) => void; //executes on submit - fires when you apply your change

type CommonSearchBarProps = {
    alignSelf?: string;
    cssWidth?: string;
    iconSize?: number;
    minWidth?: string;
    placeHolderText?: string;
    searchText?: string;
    trackingComponentLabel: TrackingComponentLabel;
} & marginPropsType;
type OnChangeSearchBarOwnProps = {
    changeHandler: SearchChangeHandler;
    onFilterChange?: SearchFilterChangeHandler;
} & CommonSearchBarProps;
type OnChangeSearchBarProps = OnChangeSearchBarOwnProps & WithTranslation;

type OnSubmitSearchBarOwnProps = {
    changeHandler?: SearchChangeHandler;
    onFilterChange: SearchFilterChangeHandler;
} & CommonSearchBarProps;
type OnSubmitSearchBarProps = OnSubmitSearchBarOwnProps & WithTranslation;
type SearchBarProps = OnChangeSearchBarProps | OnSubmitSearchBarProps;

type SearchBarState = {
    searchText: string;
};

class SearchBar extends React.Component<SearchBarProps, SearchBarState> {
    static displayName = "SearchBar";

    static defaultProps = {
        alignSelf: "center",
        cssWidth: "240px",
        iconSize: 16,
        searchText: EMPTY_SEARCH_TEXT
    };

    searchInput: HTMLInputElement | undefined | null;

    state: SearchBarState = {
        searchText: this.props.searchText || EMPTY_SEARCH_TEXT
    };

    executeSearch = (searchText: string) => {
        const { onFilterChange } = this.props;
        if (onFilterChange) {
            onFilterChange(searchText);
        }
    };

    onSubmit = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const { trackingComponentLabel } = this.props;
        e.preventDefault();
        if (!isNil(trackingComponentLabel)) {
            trackEvent(
                getTrackingEventData(
                    SearchBar.displayName,
                    composeTrackingComponentLabel([
                        trackingComponentLabel,
                        "Search"
                    ]),
                    EVENT_NAME_FILTER_SEARCH
                )
            );
        }
        this.executeSearch(this.state.searchText);
    };

    onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        e.stopPropagation();
        const { changeHandler } = this.props;
        const element = e.target as any;
        this.setState({
            searchText: element.value
        });
        if (changeHandler) {
            changeHandler(element.value);
        }
    };

    componentDidUpdate(prevProps: SearchBarProps) {
        const searchText = this.props.searchText || EMPTY_SEARCH_TEXT;
        if (!isEqual(searchText, prevProps.searchText)) {
            this.setState({
                searchText: searchText
            });
            if (this.searchInput) {
                this.searchInput.value = searchText || EMPTY_SEARCH_TEXT;
            }
        }
    }

    onClearSearch = () => {
        const { changeHandler, trackingComponentLabel } = this.props;
        this.setState({ searchText: EMPTY_SEARCH_TEXT });
        if (this.searchInput) {
            this.searchInput.value = EMPTY_SEARCH_TEXT;
        }
        if (!isNil(trackingComponentLabel)) {
            trackEvent(
                getTrackingEventData(
                    SearchBar.displayName,
                    composeTrackingComponentLabel([
                        trackingComponentLabel,
                        "Clear"
                    ]),
                    EVENT_NAME_FILTER_CLEAR
                )
            );
        }
        this.executeSearch(EMPTY_SEARCH_TEXT);
        if (changeHandler) {
            changeHandler(EMPTY_SEARCH_TEXT);
        }
    };

    onClick = (event: React.SyntheticEvent) => {
        event.stopPropagation();
    };

    setSearchInputRef = (input: HTMLInputElement) => {
        this.searchInput = input;
    };

    render() {
        const { alignSelf, cssWidth, iconSize, minWidth, placeHolderText, t } =
            this.props;
        let ClearIconContent = null;
        const searchText = this.props.searchText || this.state.searchText;
        if (searchText) {
            ClearIconContent = (
                <ClearIcon
                    height="14px"
                    icon={XCircleIcon}
                    onClick={this.onClearSearch}
                    title={t("common:filter.clear_search")}
                    width="14px"
                />
            );
        }
        return (
            <SearchBarWrapper
                alignSelf={alignSelf}
                cssWidth={cssWidth}
                minWidth={minWidth}
                onSubmit={this.onSubmit}
                {...reduceObject(this.props, ...marginPropTypes)}
            >
                <SearchSvgIcon icon={SearchIcon} iconSize={iconSize} />
                <Input
                    boxSizing="border-box"
                    cssHeight="36px"
                    cssWidth="100%"
                    defaultValue={searchText}
                    name={t("common:filter.search")}
                    onChange={this.onChange}
                    paddingLeft="36px"
                    paddingRight="34px"
                    placeholder={placeHolderText || EMPTY_SEARCH_TEXT}
                    onBlur={e =>
                        (e.target.placeholder =
                            placeHolderText || EMPTY_SEARCH_TEXT)
                    }
                    onClick={this.onClick}
                    onFocus={e => (e.target.placeholder = EMPTY_SEARCH_TEXT)}
                    ref={this.setSearchInputRef}
                />
                {ClearIconContent}
            </SearchBarWrapper>
        );
    }
}

export default withTranslation()(SearchBar);
