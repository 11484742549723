import * as React from "react";
import type { ParseKeys } from "i18next";
import { useTranslation } from "react-i18next";
import Button, {
    BUTTON_TYPE_TERTIARY,
    ICON_RIGHT
} from "common/components/Button";
import Flexbox from "common/components/styled/Flexbox";
import FlexboxWithBorder from "common/components/styled/FlexboxWithBorder";
import { marginPropTypes } from "common/components/styled/util";
import { ClickableSvgIcon } from "common/components/SvgIcon";
import theme from "common/components/theme";
import Tooltip from "common/components/Tooltip";
import { ReactComponent as MoreLinesIcon } from "common/icons/MoreLines.svg";
import { ReactComponent as XCircleIcon } from "common/icons/XCircle.svg";
import { processSelectedItemsText } from "common/util/filter";
import { truncateTooltipItems } from "common/util/format";
import { reduceObject } from "common/util/object";
import { composeTrackingComponentLabel } from "common/util/tracking";
import { EVENT_NAME_FILTER_CLEAR } from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";

export const MAX_FILTER_TOOLTIPS = 5;

type SelectedFilterTooltip = {
    displayName: string; // Should be resource string unless showDisplayNameValue is true
    labelField?: string; // node field for tooltip labels
    nodes?: any[]; // Array of nodes
    noTooltip?: boolean; // Don't show tooltip just display name
    selected: string[]; // Array of value fields that are selected
    showDisplayNameValue?: boolean; // Explicitly show displayName string
    tooltipNode?: React.ReactNode; // Custom tooltip to show
    valueField?: string; // node field for unique value
};

export type SelectedFilters = {
    customTooltip?: React.ReactNode;
    tooltip?: SelectedFilterTooltip;
};

type FiltersButtonDisplayProps = {
    cssHeight?: string;
    hasBorder?: boolean;
    hasText?: boolean;
    hideFilterButton?: boolean;
    isSelected: boolean;
    maxFilterTooltipsCount?: number;
    onClear: NoArgsHandler;
    onClick: NoArgsHandler;
    selectedFilters: SelectedFilters[];
    trackingComponentLabel: TrackingComponentLabel;
} & marginPropsType;
const FiltersButtonDisplay = (props: FiltersButtonDisplayProps) => {
    const {
        cssHeight = "36px",
        hasBorder = true,
        hasText = true,
        hideFilterButton = false,
        isSelected = false,
        maxFilterTooltipsCount = MAX_FILTER_TOOLTIPS,
        onClear,
        onClick,
        selectedFilters = [],
        trackingComponentLabel,
        ...rest
    } = props;
    const { t } = useTranslation();
    let filterTooltips = selectedFilters
        .map((selectedFilter, index) => {
            const { customTooltip, tooltip } = selectedFilter;
            if (customTooltip) {
                return customTooltip;
            } else if (tooltip) {
                const {
                    displayName = "",
                    labelField = "label",
                    nodes = [],
                    noTooltip = false,
                    selected,
                    showDisplayNameValue = false,
                    tooltipNode,
                    valueField = "value"
                } = tooltip;
                if (selected.length > 0) {
                    const filterDisplayName = showDisplayNameValue
                        ? displayName
                        : t(displayName as ParseKeys, {
                              count: selected.length
                          });
                    const filterDisplayNameNode = (
                        <Flexbox
                            alignItems="center"
                            key={filterDisplayName + "-" + index}
                            marginRight="8px"
                        >
                            {filterDisplayName}
                        </Flexbox>
                    );
                    if (noTooltip) {
                        return filterDisplayNameNode;
                    } else {
                        let selectedTooltipNode = null;
                        if (tooltipNode) {
                            selectedTooltipNode = <div>{tooltipNode}</div>;
                        } else {
                            const selectedItems = processSelectedItemsText(
                                nodes
                                    .filter(item => {
                                        return (
                                            selected.indexOf(
                                                item[valueField]
                                            ) !== -1
                                        );
                                    })
                                    .map(item => {
                                        return item[labelField];
                                    }),
                                t
                            ).map((item, index) => {
                                return <div key={index}>{item}</div>;
                            });
                            const items = truncateTooltipItems(selectedItems);
                            selectedTooltipNode = <div>{items}</div>;
                        }
                        return (
                            <Tooltip
                                key={
                                    index +
                                    "-" +
                                    filterDisplayName +
                                    "-" +
                                    selected.length
                                }
                            >
                                {filterDisplayNameNode}
                                {selectedTooltipNode}
                            </Tooltip>
                        );
                    }
                }
            }
            return null;
        })
        .filter(x => x);

    if (filterTooltips.length > maxFilterTooltipsCount) {
        filterTooltips = filterTooltips
            .slice(0, maxFilterTooltipsCount)
            .concat([
                <Flexbox
                    alignItems="center"
                    key="plus_x_more_node"
                    marginRight="8px"
                >
                    {t("common:general.plus_x_more", {
                        count: filterTooltips.length - maxFilterTooltipsCount
                    })}
                </Flexbox>
            ]);
    }

    if (isSelected) {
        filterTooltips.push(
            <ClickableSvgIcon
                color={theme.greyIcon}
                icon={XCircleIcon}
                key="clear_filters"
                hoverColor={theme.darkGreyText}
                marginRight="8px"
                onClick={onClear}
                title={t("common:filter.clear_all")}
                trackingComponentLabel={composeTrackingComponentLabel([
                    trackingComponentLabel,
                    FiltersButtonDisplay.displayName,
                    "Clear All"
                ])}
                trackingEventName={EVENT_NAME_FILTER_CLEAR}
            />
        );
    }

    const buttonProps: any = {
        cssHeight: cssHeight,
        borderColor: isSelected ? theme.linkText : null,
        icon: MoreLinesIcon,
        iconPosition: ICON_RIGHT,
        noMinWidth: true,
        onClick: onClick,
        type: BUTTON_TYPE_TERTIARY
    };

    if (hasText) {
        buttonProps.text = t("common:aggrid.filters");
    }

    // Dont apply margin if there is a border around button
    const margin = hasBorder ? { marginLeft: "0px", marginRight: "0px" } : {};

    const buttonAndTooltips = (
        <React.Fragment>
            {filterTooltips}
            {!hideFilterButton && (
                <Button
                    trackingComponentLabel={composeTrackingComponentLabel([
                        trackingComponentLabel,
                        FiltersButtonDisplay.displayName,
                        "Open Filters"
                    ])}
                    {...buttonProps}
                    {...rest}
                    {...margin}
                />
            )}
        </React.Fragment>
    );

    if (hasBorder) {
        return (
            <FlexboxWithBorder
                backgroundColor={theme.universalBackground}
                padding="8px 16px"
                {...reduceObject(props, ...marginPropTypes)}
            >
                {buttonAndTooltips}
            </FlexboxWithBorder>
        );
    } else {
        return <Flexbox>{buttonAndTooltips}</Flexbox>;
    }
};
FiltersButtonDisplay.displayName = "FiltersButtonDisplay";

export default FiltersButtonDisplay;
