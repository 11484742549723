import { DOWNLOADS_PAGE } from "common/administration/state/pageActions";
import {
    RECEIVE_DOWNLOADS,
    REQUEST_DOWNLOADS,
    UPDATE_START_INDEX_DOWNLOADS,
    UPDATE_SORT_DOWNLOADS,
    UPDATE_SEARCH_DOWNLOADS
} from "common/administration/users/state/downloadsActions";
import type { DownloadsActions } from "common/administration/users/state/downloadsActions";
import {
    defaultState,
    reduceDownloads
} from "common/administration/users/state/downloadsStateUtils";
import type { DownloadsState } from "common/administration/users/state/downloadsStateUtils";
import noAction from "common/util/noAction";

const downloads = (
    state: DownloadsState = defaultState,
    action: DownloadsActions = noAction
): DownloadsState => {
    switch (action.type) {
        case DOWNLOADS_PAGE:
            return state;
        case REQUEST_DOWNLOADS:
            return state;
        case RECEIVE_DOWNLOADS:
            return Object.assign(
                {},
                state,
                reduceDownloads(state, action.items)
            );
        case UPDATE_SORT_DOWNLOADS:
            return Object.assign({}, state, {
                sortBy: action.sortBy,
                sortOrder: action.sortOrder
            });
        case UPDATE_SEARCH_DOWNLOADS:
            return Object.assign({}, state, { search: action.search });
        case UPDATE_START_INDEX_DOWNLOADS:
            return Object.assign({}, state, { startIndex: action.startIndex });
        default:
            return state;
    }
};

export default downloads;
