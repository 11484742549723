import type { Cookies as ReactCookies } from "react-cookie";

// 3rd-party react-cookies object.
/* export type Cookies = {
    get: (name: string) => string;
    remove: (name: string, options: any) => void;
    set: (name: string, value: string, options: any) => void;
}; */

export type Cookies = ReactCookies;

export type HasCookies = {
    cookies: ReactCookies;
};

export type Question = {
    entityId: string;
    question: string;
};

export type LocalTenant = {
    entityId: string;
    name: string;
};

export const APPLICATION_MATRIX = "Matrix" as string;
export const APPLICATION_PRICE = "Price" as string;
export const ENGLISH_LOCALE = "en-US" as string;
export const UNKNOWN = "Unknown" as string;

export type TrackingApplicationType =
    | typeof APPLICATION_MATRIX
    | typeof APPLICATION_PRICE
    | typeof UNKNOWN;

export type ServerMode =
    | "demo"
    | "development"
    | "production"
    | "staging"
    | typeof UNKNOWN;

type UserType = "Customer" | "Eversight" | "Test" | typeof UNKNOWN;

export type TrackingData = {
    application: TrackingApplicationType;
    teamType: string;
    tenantId: string;
    userId: string;
    userType: UserType;
};
