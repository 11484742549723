import * as React from "react";
import Flexbox from "common/components/styled/Flexbox";
import type { FlexboxProps } from "common/components/styled/Flexbox";
import SpecialChars from "common/components/SpecialChars";
import Input from "common/components/styled/Input";
import TextArea from "common/components/styled/TextArea";

export type SpecialCharsComponentProps = {
    id: string;
    isTextArea?: boolean;
    onChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    wrapperProps: FlexboxProps & { tabIndex?: number };
    value: string;
};

class SpecialCharsComponent extends React.Component<SpecialCharsComponentProps> {
    static displayName = "SpecialCharsComponent";

    insertCharacterInput = (char: string) => {
        const { id } = this.props;

        const input = document.getElementById(id) as HTMLInputElement;
        if (input) {
            let value = input.value;
            if (input.selectionEnd && input.selectionStart) {
                value =
                    value.slice(0, input.selectionStart) +
                    char +
                    value.slice(input.selectionEnd);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                    window.HTMLInputElement.prototype,
                    "value"
                ).set;
                if (nativeInputValueSetter) {
                    nativeInputValueSetter.call(input, value);
                    const evt = new Event("input", { bubbles: true });
                    input.dispatchEvent(evt);
                }
            }
        }
    };

    insertCharacterTextArea = (char: string) => {
        const { id } = this.props;

        const textArea = document.getElementById(id) as HTMLTextAreaElement;
        if (textArea) {
            let value = textArea.value;
            if (textArea.selectionEnd && textArea.selectionStart) {
                value =
                    value.slice(0, textArea.selectionStart) +
                    char +
                    value.slice(textArea.selectionEnd);
                const nativeTextAreaValueSetter =
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    Object.getOwnPropertyDescriptor(
                        window.HTMLTextAreaElement.prototype,
                        "value"
                    ).set;
                if (nativeTextAreaValueSetter) {
                    nativeTextAreaValueSetter.call(textArea, value);
                    const evt = new Event("input", {
                        bubbles: true
                    });
                    textArea.dispatchEvent(evt);
                }
            }
        }
    };

    onChangeInput = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        const { onChange } = this.props;
        onChange(event);
    };

    render() {
        const { id, isTextArea = false, wrapperProps, value } = this.props;

        let insertChar = this.insertCharacterInput;

        let InputComponent = (
            <Input
                id={id}
                name={id}
                value={value}
                onChange={this.onChangeInput}
                {...wrapperProps}
            />
        );

        if (isTextArea) {
            InputComponent = (
                <TextArea
                    id={id}
                    name={id}
                    value={value}
                    onChange={this.onChangeInput}
                    {...wrapperProps}
                />
            );
            insertChar = this.insertCharacterTextArea;
        }

        return (
            <Flexbox>
                {InputComponent}
                <SpecialChars insertCharacter={insertChar} />
            </Flexbox>
        );
    }
}

SpecialCharsComponent.displayName = "SpecialCharsComponent";

export default SpecialCharsComponent;
