import * as React from "react";
import { useTranslation } from "react-i18next";

type NoRowsGridOverlayProps = {
    message?: string;
};

const NoRowsGridOverlay = (props: NoRowsGridOverlayProps) => {
    const { message } = props;
    const { t } = useTranslation();
    let msg = message;
    if (!msg) {
        msg = t("common:general.no_results_found");
    }

    return <div className="ag-overlay-loading-center">{msg}</div>;
};
NoRowsGridOverlay.displayName = "NoRowsGridOverlay";

export default NoRowsGridOverlay;
