import type { AnyAction } from "redux";
import {
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import { buildTakeRateStackedChartRequest } from "reports/takeRate/state/takeRateActionUtils";
import type { TakeRateStackedBarViewBy } from "reports/takeRate/state/takeRateStateUtils";
import type { RootState } from "store";

export const RECEIVE_REPORTS_TAKE_RATE_STACKED_BAR_CHART =
    "RECEIVE_REPORTS_TAKE_RATE_STACKED_BAR_CHART";
export const REQUEST_REPORTS_TAKE_RATE_STACKED_BAR_CHART =
    "REQUEST_REPORTS_TAKE_RATE_STACKED_BAR_CHART";
export const UPDATE_TAKE_RATE_STACKED_BAR_VIEW_BY =
    "UPDATE_TAKE_RATE_STACKED_BAR_VIEW_BY";

// Take rate report actions
const receiveReportsTakeRateStackedChart = (json: Json): AnyAction => ({
    type: RECEIVE_REPORTS_TAKE_RATE_STACKED_BAR_CHART,
    json
});

const requestReportsTakeRateStackedChart = (): AnyAction => ({
    type: REQUEST_REPORTS_TAKE_RATE_STACKED_BAR_CHART
});

export const updateTakeRateStackedBarViewBy = (
    viewBy: TakeRateStackedBarViewBy
): AnyAction => {
    return {
        type: UPDATE_TAKE_RATE_STACKED_BAR_VIEW_BY,
        viewBy
    };
};

export const fetchTakeRateStackedChart = () => {
    return makeRequestThunk("api/report/takerate/stacked", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState): any {
            return buildTakeRateStackedChartRequest(state);
        },
        preRequestFunc: requestReportsTakeRateStackedChart,
        okDispatchFunc: receiveReportsTakeRateStackedChart
    });
};
