import type { Action } from "redux";

// action types
export const RESET_ERROR_FOUND = "RESET_ERROR";
export const SET_ERROR_FOUND = "SET_ERROR_FOUND";

export type ErrorActions = ResetErrorFound | SetErrorFound;

interface ResetErrorFound extends Action {
    type: typeof RESET_ERROR_FOUND;
}

export const resetErrorFound = (): ResetErrorFound => ({
    type: RESET_ERROR_FOUND
});

interface SetErrorFound extends Action {
    type: typeof SET_ERROR_FOUND;
}

export const setErrorFound = (): SetErrorFound => ({
    type: SET_ERROR_FOUND
});
