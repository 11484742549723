import type { Action } from "redux";
import noAction from "common/util/noAction";

export const defaultState = "";

const page = (
    state: string = defaultState,
    action: Action = noAction
): string => {
    const type = action.type ?? "";
    // this is not the best way to check if an action is a page, however, importing pages from pages.js
    // brings in the views which results in circular dependencies
    if (type.endsWith("_PAGE")) {
        return action.type;
    }
    return state;
};

export default page;
