import get from "lodash.get";

// valid values 'none', 'single', 'multiple'
export const SELECTION_TYPE_NONE = "none";
export const SELECTION_TYPE_SINGLE = "single";
// none - doesn't rely on selection, single - can only be active with 1 item selected, multiple - can be active with 1 or more items selected

// when allowable actions for an object are not reduced to just permision = true/false
export const dataInstanceActionAllowed = (
    obj: any,
    allowableAction: string
): boolean => {
    const objAllowedActions = get(obj, "allowableActions");
    if (objAllowedActions) {
        const action = objAllowedActions[allowableAction];
        if (action) {
            return action.allowed;
        }
    }
    return false;
};

// when allowable actions for an object are reduced to just permision = true/false
export const isAllowedDataInstance = (
    permission: string,
    selectionType: string,
    records: any[]
): boolean => {
    let isAllowed = true;
    if (records.length < 1 && selectionType !== "none") {
        isAllowed = false;
    } else {
        if (selectionType === "single" && records.length > 1) {
            isAllowed = false;
        } else {
            for (let i = 0; i < records.length; i += 1) {
                const record = records[i];
                const allowableActions: Dictionary<boolean> = get(
                    record,
                    "allowableActions"
                );
                if (allowableActions) {
                    const isActionAllowed = allowableActions[permission];
                    if (!isActionAllowed) {
                        isAllowed = false;
                        break;
                    }
                }
            }
        }
    }
    return isAllowed;
};

export const isAllowedForAny = (
    permission: string,
    records: any[]
): boolean => {
    let isAllowed = false;

    if (records) {
        for (let i = 0; i < records.length; i += 1) {
            const record = records[i];
            const allowableActions: Dictionary<boolean> = get(
                record,
                "allowableActions"
            );
            if (allowableActions) {
                const isActionAllowed = allowableActions[permission];
                if (isActionAllowed) {
                    isAllowed = true;
                    break;
                }
            }
        }
    }
    return isAllowed;
};

export const isAllowedGlobal = (
    permission: string,
    allowableActions: Dictionary<boolean>
): boolean => {
    let isAllowed = true;

    if (permission) {
        isAllowed = allowableActions[permission];
    }
    return isAllowed;
};
