import get from "lodash.get";
import {
    creatorModifierQueryFields,
    getEntityIdWithoutVersion,
    reduceCreatorModifier,
    reduceObject
} from "common/util/object";

export const GUIDELINE_COLLECTION_NAME = "name";
export const GUIDELINE_COLLECTION_START_DATE = "startDate";
export const GUIDELINE_COLLECTION_END_DATE = "endDate";
export const GUIDELINE_COLLECTION_STATUS = "status";

export const ACTIVE_GUIDELINE_COLLECTION_STATUS =
    "ACTIVE_GUIDELINE_COLLECTION_STATUS";
export const INACTIVE_GUIDELINE_COLLECTION_STATUS =
    "INACTIVE_GUIDELINE_COLLECTION_STATUS";
export const ALL_GUIDELINE_COLLECTION_STATUS =
    "ALL_GUIDELINE_COLLECTION_STATUS";

export type GuidelineCollectionStatus =
    | typeof ACTIVE_GUIDELINE_COLLECTION_STATUS
    | typeof INACTIVE_GUIDELINE_COLLECTION_STATUS
    | typeof ALL_GUIDELINE_COLLECTION_STATUS;

export type GuidelineListState = {
    entityId: string;
    entityIdWithoutVersion: string;
    name: string;
    objectType: string;
};

// Used for cross promotions modal objects
export type PPGModel = {
    entityId: string;
    name: string;
    objectType: string;
};

export const GUIDELINE_COLLECTIONS_FIELDS: string[] = [
    "entityId",
    GUIDELINE_COLLECTION_NAME,
    GUIDELINE_COLLECTION_START_DATE,
    GUIDELINE_COLLECTION_END_DATE,
    GUIDELINE_COLLECTION_STATUS
].concat(creatorModifierQueryFields);

export const GUIDELINE_COLLECTIONS_LIST_FIELDS: string[] = [
    "entityId",
    GUIDELINE_COLLECTION_NAME
];

export const reduceGuidelineCollection = (data: any): GuidelineListState => {
    const guideline = reduceObject(data, ...GUIDELINE_COLLECTIONS_FIELDS);
    const creatorModifier = reduceCreatorModifier(data);
    return Object.assign(
        {},
        guideline,
        {
            entityIdWithoutVersion: getEntityIdWithoutVersion(data.entityId)
        },
        creatorModifier
    );
};

export const reduceGuideline = (data: any): GuidelineListState => {
    const creatorModifier = reduceCreatorModifier(data);
    const guideline = reduceObject(
        data,
        "bundledProducts",
        "entityId",
        "holidays",
        "holidaySummary",
        "name",
        "objectType",
        "products",
        "productSummary",
        "regions",
        "regionSummary",
        "retailers",
        "retailerSummary"
    );
    return Object.assign(
        {},
        guideline,
        {
            entityIdWithoutVersion: getEntityIdWithoutVersion(data.entityId),
            collectionId: get(data, "guidelineCollection.entityId", "")
        },
        creatorModifier
    );
};
