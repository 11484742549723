import type { AnyAction } from "redux";
import {
    RECEIVE_ASYNC_ITEMS,
    REQUEST_ASYNC_ITEMS
} from "common/administration/async/state/asyncActions";
import noAction from "common/util/noAction";
import { DEFAULT_PAGE_SIZE } from "common/util/query";

type AsyncState = {
    items: any[];
    maxResults: number;
    totalCount: number;
};

export const defaultState: AsyncState = {
    items: [],
    maxResults: DEFAULT_PAGE_SIZE,
    totalCount: -1
};

const async = (
    state: AsyncState = defaultState,
    action: AnyAction = noAction
): AsyncState => {
    switch (action.type) {
        case RECEIVE_ASYNC_ITEMS:
            return Object.assign({}, state, {
                items: action.json.items,
                totalCount: action.json.totalCount
            });
        case REQUEST_ASYNC_ITEMS:
            return state;
        default:
            return state;
    }
};

export default async;
