import type { AnyAction } from "redux";
import { CAMPAIGN_PPG_EDIT_PAGE } from "shell/state/pageActions";

type ProductState = {
    campaignProductGroupEntityId: string | null;
};

const defaultState: ProductState = {
    campaignProductGroupEntityId: null
};

function product(state: ProductState = defaultState, action: AnyAction) {
    switch (action.type) {
        case CAMPAIGN_PPG_EDIT_PAGE:
            return Object.assign({}, state, {
                campaignProductGroupEntityId:
                    action.payload &&
                    action.payload.campaignProductGroupEntityId
            });
        default:
            return state;
    }
}

export default product;
