import * as React from "react";
import type { ParseKeys } from "i18next";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import isNil from "lodash.isnil";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import Button, {
    BUTTON_TYPE_PRIMARY,
    BUTTON_TYPE_SECONDARY
} from "common/components/Button";
import Drawer, { DrawerFooter, DrawerOverlay } from "common/components/Drawer";
import { MAX_FILTER_TOOLTIPS } from "common/components/grid/FiltersButtonDisplay";
import type { SelectedFilters } from "common/components/grid/FiltersButtonDisplay";
import Flexbox from "common/components/styled/Flexbox";
import FlexboxWithBorder from "common/components/styled/FlexboxWithBorder";
import FlexGrow from "common/components/styled/FlexGrow";
import { FormRow, FormSection } from "common/components/styled/Form";
import { FieldLabel } from "common/components/styled/Label";
import Tooltip from "common/components/Tooltip";
import theme from "common/components/theme";
import CheckboxTreeFilterView from "common/filter/components/CheckboxTreeFilterView";
import {
    reportDateNodes,
    reportComparisonDateNodes
} from "common/reports/components/reportsViewUtils";
import { getReportStateKey } from "common/reports/state/reportsStateUtils";
import { CUSTOM } from "common/util/date";
import type { DateFilter } from "common/util/date";
import { date2Str, dateRange2Str } from "common/util/format";
import { isEmptyString } from "common/util/lang";
import { composeTrackingComponentLabel } from "common/util/tracking";
import {
    EVENT_NAME_FILTER_APPLY,
    EVENT_NAME_FILTER_CLEAR
} from "common/util/trackingEvents";
import {
    flattenPpgNodes,
    getBrandFilterControl,
    getCategoryFilterControl,
    getChannelFilterControl,
    getDateFilterControl,
    getHolidayFilterControl,
    getOfferTypeFilterControl,
    getPpgFilterControl,
    getPromotionFilterControl
} from "reports/components/reportsViewUtils";
import { CONSUMER } from "reports/consumer/state/consumerStateUtils";
import type { PromotionFilter } from "reports/consumer/state/consumerStateUtils";
import { PURCHASE_CYCLE } from "reports/purchaseCycle/state/purchaseCycleStateUtils";
import {
    updateReportStackedBarFilters,
    updateReportsDataTableFilters
} from "reports/state/reportsActions";
import type {
    ReportFilters,
    ReportType,
    ReportView
} from "reports/state/reportsStateUtils";
import { TAKE_RATE } from "reports/takeRate/state/takeRateStateUtils";
import { CATEGORY_SUBCATEGORY_SEPARATOR } from "shell/state/initReducers";
import type { RetailerFilter } from "shell/state/initReducers";
import type { AppDispatch, RootState } from "store";

const FILTER_LABEL_WIDTH = "200px";

type ReportFiltersViewOwnProps = {
    closeHandler: NoArgsHandler;
    defaultFilters: ReportFilters;
    filtersTooltipChangeHandler: (filtersTooltip: SelectedFilters[]) => void;
    hasBrandFilter?: boolean;
    hasCategoryFilter?: boolean;
    hasCategorySubcategoryFilter?: boolean;
    hasChannelFilter?: boolean;
    hasComparisonDateFilter?: boolean;
    hasDateFilter?: boolean;
    hasHolidayFilter?: boolean;
    hasPpgFilter?: boolean;
    hasOfferTypeFilter?: boolean;
    hasPromotionFilter?: boolean;
    hasUpcBrandFilter?: boolean;
    hasUpcPpgFilter?: boolean;
    isOpen: boolean;
    reportType: ReportType;
    reportView: ReportView;
    saveHandler: (
        prevFilters: ReportFilters,
        newFilters: ReportFilters
    ) => void;
};

type ReportFiltersViewProps = ReportFiltersViewOwnProps &
    PropsFromRedux &
    WithTranslation;

type ReportFiltersViewState = {
    filters: ReportFilters;
};

class ReportFiltersView extends React.Component<
    ReportFiltersViewProps,
    ReportFiltersViewState
> {
    static displayName = "ReportFiltersView";
    constructor(props: ReportFiltersViewProps) {
        const { defaultFilters } = props;
        super(props);
        this.state = {
            filters: defaultFilters
        };
    }

    componentDidMount() {
        const { savedFilters } = this.props;
        this.updateFiltersTooltip();
        // Set filter state to be saved filters on mount if not null
        if (!isNil(savedFilters)) {
            this.setState({
                filters: savedFilters
            });
        }
    }

    componentDidUpdate(prevProps: ReportFiltersViewProps) {
        const { defaultFilters, savedFilters } = this.props;

        if (!isEqual(savedFilters, prevProps.savedFilters)) {
            // If saved filters changed update it
            if (!isNil(savedFilters)) {
                this.setState({
                    filters: savedFilters
                });
            } else {
                this.setState({
                    filters: defaultFilters
                });
            }
        }

        // Update tooltips if saved filters change
        if (!isEqual(prevProps.savedFilters, savedFilters)) {
            this.updateFiltersTooltip();
        }
    }

    getBrandFilterControls = (nodes: any) => {
        const { reportType, reportView, t } = this.props;
        const { filters } = this.state;
        if (
            (filters.filterType === CONSUMER ||
                filters.filterType === PURCHASE_CYCLE) &&
            nodes.length > 1
        ) {
            const { brands } = filters;
            return (
                <FormRow fieldLabelWidth={FILTER_LABEL_WIDTH}>
                    <FieldLabel>{t("common:general.brand_plural")}</FieldLabel>
                    {getBrandFilterControl(
                        true,
                        nodes,
                        this.onBrandFilterChange,
                        brands,
                        [
                            ReportFiltersView.displayName,
                            reportType,
                            reportView,
                            "Filter Drawer",
                            "Brand Filter"
                        ]
                    )}
                </FormRow>
            );
        }
        return null;
    };

    getBrandFilterRow = () => {
        const {
            consumerTrendBrandNodes,
            purchaseCycleBrandNodes,
            reportType
        } = this.props;

        // Select correct brand nodes to use
        let nodes: any[] = [];
        if (reportType === CONSUMER && consumerTrendBrandNodes.length > 0) {
            nodes = consumerTrendBrandNodes;
        } else if (
            reportType === PURCHASE_CYCLE &&
            purchaseCycleBrandNodes.length > 0
        ) {
            nodes = purchaseCycleBrandNodes;
        }
        return this.getBrandFilterControls(nodes);
    };

    getUpcBrandFilterRow = () => {
        const {
            consumerTrendUpcBrandNodes,
            purchaseCycleUpcBrandNodes,
            reportType
        } = this.props;

        // Select correct brand nodes to use
        let nodes: any[] = [];
        if (reportType === CONSUMER && consumerTrendUpcBrandNodes.length > 0) {
            nodes = consumerTrendUpcBrandNodes;
        } else if (
            reportType === PURCHASE_CYCLE &&
            purchaseCycleUpcBrandNodes.length > 0
        ) {
            nodes = purchaseCycleUpcBrandNodes;
        }

        return this.getBrandFilterControls(nodes);
    };

    getCategoryFilterRow = () => {
        const {
            consumerTrendCategories,
            reportType,
            reportView,
            t
        } = this.props;
        const { filters } = this.state;
        if (
            filters.filterType === CONSUMER &&
            consumerTrendCategories.length > 1
        ) {
            const { categories } = filters;
            return (
                <FormRow fieldLabelWidth={FILTER_LABEL_WIDTH}>
                    <FieldLabel>{t("general.category_plural")}</FieldLabel>
                    {getCategoryFilterControl(
                        true,
                        consumerTrendCategories,
                        this.onCategoryFilterChange,
                        categories,
                        [
                            ReportFiltersView.displayName,
                            reportType,
                            reportView,
                            "Filter Drawer",
                            "Category Filter"
                        ]
                    )}
                </FormRow>
            );
        }
        return null;
    };

    getChannelFilterRow = () => {
        const {
            consumerTrendChannelNodes,
            reportType,
            reportView,
            t,
            takeRateChannelNodes
        } = this.props;
        const { filters } = this.state;

        // Select correct channel nodes to use
        let nodes: RetailerFilter[] = [];
        if (reportType === CONSUMER && consumerTrendChannelNodes.length > 0) {
            nodes = consumerTrendChannelNodes;
        } else if (
            reportType === TAKE_RATE &&
            takeRateChannelNodes.length > 0
        ) {
            nodes = takeRateChannelNodes;
        }

        if (
            (filters.filterType === CONSUMER ||
                filters.filterType === TAKE_RATE) &&
            nodes.length > 1
        ) {
            const { channels } = filters;
            return (
                <FormRow fieldLabelWidth={FILTER_LABEL_WIDTH}>
                    <FieldLabel>{t("general.channel_plural")}</FieldLabel>
                    {getChannelFilterControl(
                        true,
                        nodes,
                        this.onChannelFilterChange,
                        channels,
                        [
                            ReportFiltersView.displayName,
                            reportType,
                            reportView,
                            "Filter Drawer",
                            "Channel Filter"
                        ]
                    )}
                </FormRow>
            );
        }
        return null;
    };

    getDateFilterRow = () => {
        const {
            defaultFilters,
            purchaseCycleTimePeriods,
            reportType,
            reportView,
            t
        } = this.props;
        const { filters } = this.state;
        const { date } = filters;
        let dateLabel = t("common:date.current_period");
        if (reportType === TAKE_RATE) {
            dateLabel = t("common:aggrid.time");
        }

        let dateMenuItems: DateMenuItemType[] = [];
        if (reportType === PURCHASE_CYCLE) {
            dateMenuItems = purchaseCycleTimePeriods;
        }

        return (
            <FormRow fieldLabelWidth={FILTER_LABEL_WIDTH}>
                <FieldLabel>{dateLabel}</FieldLabel>
                {getDateFilterControl(
                    !isEqual(defaultFilters.date, date),
                    dateLabel,
                    this.onDateFilterChange,
                    date,
                    [
                        ReportFiltersView.displayName,
                        reportType,
                        reportView,
                        "Filter Drawer",
                        "Date Filter"
                    ],
                    dateMenuItems
                )}
            </FormRow>
        );
    };

    getComparisonDateFilterRow = () => {
        const { defaultFilters, reportType, reportView, t } = this.props;
        const { filters } = this.state;
        // @ts-expect-error - comparisonDate not on other report types
        const { comparisonDate } = filters;
        const dateLabel = t("common:date.comparison_period");

        const dateMenuItems: DateMenuItemType[] = reportComparisonDateNodes();

        return (
            <FormRow fieldLabelWidth={FILTER_LABEL_WIDTH}>
                <FieldLabel>{dateLabel}</FieldLabel>
                {getDateFilterControl(
                    // @ts-expect-error - comparisonDate not on other report types
                    !isEqual(defaultFilters.comparisonDate, comparisonDate),
                    dateLabel,
                    this.onComparisonDateFilterChange,
                    comparisonDate,
                    [
                        ReportFiltersView.displayName,
                        reportType,
                        reportView,
                        "Filter Drawer",
                        "Comparison Date Filter"
                    ],
                    dateMenuItems
                )}
            </FormRow>
        );
    };

    getHolidayFilterRow = () => {
        const { holidayNodes, reportType, reportView, t } = this.props;
        const { filters } = this.state;
        if (filters.filterType === TAKE_RATE && holidayNodes.length > 1) {
            const { holidays } = filters;
            return (
                <FormRow fieldLabelWidth={FILTER_LABEL_WIDTH}>
                    <FieldLabel>
                        {t("general.holiday_slash_season_plural")}
                    </FieldLabel>
                    {getHolidayFilterControl(
                        true,
                        holidayNodes,
                        this.onHolidaysChange,
                        holidays,
                        [
                            ReportFiltersView.displayName,
                            reportType,
                            reportView,
                            "Holidays"
                        ]
                    )}
                </FormRow>
            );
        }
    };

    getOfferTypeFilterRow = () => {
        const { offerTypeNodes, reportType, reportView, t } = this.props;
        const { filters } = this.state;
        if (filters.filterType === TAKE_RATE && offerTypeNodes.length > 0) {
            const { offerTypes } = filters;
            return (
                <FormRow fieldLabelWidth={FILTER_LABEL_WIDTH}>
                    <FieldLabel>{t("general.offer_type_plural")}</FieldLabel>
                    {getOfferTypeFilterControl(
                        true,
                        offerTypeNodes,
                        this.onOfferTypesChange,
                        offerTypes,
                        [
                            ReportFiltersView.displayName,
                            reportType,
                            reportView,
                            "Filter Drawer",
                            "Offer Types"
                        ]
                    )}
                </FormRow>
            );
        }
        return null;
    };

    getPpgFilterControls = (nodes: any) => {
        const { reportType, reportView, t } = this.props;
        const { filters } = this.state;
        const { ppgs } = filters;
        if (
            nodes.length > 1 ||
            (nodes.length === 1 && nodes[0]?.adminProductGroups?.length > 1)
        ) {
            return (
                <FormRow fieldLabelWidth={FILTER_LABEL_WIDTH}>
                    <FieldLabel>{t("general.ppg_plural")}</FieldLabel>
                    {getPpgFilterControl(
                        true,
                        nodes,
                        this.onPpgFilterChange,
                        ppgs,
                        [
                            ReportFiltersView.displayName,
                            reportType,
                            reportView,
                            "Filter Drawer",
                            "PPG Filter"
                        ]
                    )}
                </FormRow>
            );
        }
        return null;
    };

    getPpgFilterRow = () => {
        const {
            consumerTrendPPGNodes,
            purchaseCyclePPGNodes,
            reportType,
            takeRatePPGNodes
        } = this.props;

        // Select correct ppg nodes to use
        let nodes: any[] = [];
        if (reportType === CONSUMER && consumerTrendPPGNodes.length > 0) {
            nodes = consumerTrendPPGNodes;
        } else if (reportType === TAKE_RATE && takeRatePPGNodes.length > 0) {
            nodes = takeRatePPGNodes;
        } else if (
            reportType === PURCHASE_CYCLE &&
            purchaseCyclePPGNodes.length > 0
        ) {
            nodes = purchaseCyclePPGNodes;
        }

        return this.getPpgFilterControls(nodes);
    };

    getUpcPpgFilterRow = () => {
        const {
            consumerTrendUpcPPGNodes,
            purchaseCycleUpcPPGNodes,
            reportType
        } = this.props;

        // Select correct ppg nodes to use
        let nodes: any[] = [];
        if (reportType === CONSUMER && consumerTrendUpcPPGNodes.length > 0) {
            nodes = consumerTrendUpcPPGNodes;
        } else if (
            reportType === PURCHASE_CYCLE &&
            purchaseCycleUpcPPGNodes.length > 0
        ) {
            nodes = purchaseCycleUpcPPGNodes;
        }

        return this.getPpgFilterControls(nodes);
    };

    getCategorySubcategoryFilter = () => {
        const {
            consumerTrendUpcCategories,
            purchaseCycleUpcCategories,
            reportType,
            reportView,
            t
        } = this.props;

        const { filters } = this.state;
        if (
            (filters.filterType === CONSUMER &&
                consumerTrendUpcCategories.length > 1) ||
            (filters.filterType === PURCHASE_CYCLE &&
                purchaseCycleUpcCategories.length > 1)
        ) {
            const { subcategories } = filters;
            const nodeLevels = [
                {
                    name: t("general.category"),
                    level: 1,
                    pluralName: t("general.category_plural")
                },
                {
                    name: t("general.subcategory"),
                    level: 2,
                    pluralName: t("general.subcategory_plural")
                }
            ];

            const nodes =
                reportType === PURCHASE_CYCLE
                    ? purchaseCycleUpcCategories
                    : consumerTrendUpcCategories;

            return (
                <Flexbox flexDirection="column" marginBottom="20px">
                    <FieldLabel>{t("general.category_plural")}</FieldLabel>
                    <CheckboxTreeFilterView
                        name="cateogorySubcategoryTreeFilter"
                        nodeLevels={nodeLevels}
                        nodes={nodes}
                        onFilterChange={this.onCategorySubcategoryFilterChange}
                        selected={subcategories}
                        trackingComponentLabel={composeTrackingComponentLabel([
                            reportType,
                            reportView,
                            "Category / Subcategory Filter"
                        ])}
                    />
                </Flexbox>
            );
        }
        return null;
    };

    getPromotionFilterRow = () => {
        const {
            defaultFilters,
            promotionNodes,
            reportType,
            reportView,
            t
        } = this.props;
        const { filters } = this.state;
        if (
            filters.filterType === CONSUMER &&
            defaultFilters.filterType === CONSUMER &&
            promotionNodes.length > 1
        ) {
            const { promotion } = filters;
            const highlight = promotion !== defaultFilters.promotion;
            return (
                <FormRow fieldLabelWidth={FILTER_LABEL_WIDTH}>
                    <FieldLabel>{t("general.promotion_plural")}</FieldLabel>
                    {getPromotionFilterControl(
                        highlight,
                        promotionNodes,
                        this.onPromotionFilterChange,
                        promotion,
                        [
                            ReportFiltersView.displayName,
                            reportType,
                            reportView,
                            "Filter Drawer",
                            "Promotion Filter"
                        ]
                    )}
                </FormRow>
            );
        }
        return null;
    };

    getRightPaneFilters = () => {
        const {
            hasBrandFilter,
            hasCategoryFilter,
            hasCategorySubcategoryFilter,
            hasChannelFilter,
            hasComparisonDateFilter,
            hasDateFilter,
            hasHolidayFilter,
            hasOfferTypeFilter,
            hasPpgFilter,
            hasPromotionFilter,
            hasUpcBrandFilter,
            hasUpcPpgFilter
        } = this.props;
        return (
            <FormSection marginTop="0px">
                {hasCategorySubcategoryFilter &&
                    this.getCategorySubcategoryFilter()}
                {hasPpgFilter && this.getPpgFilterRow()}
                {hasUpcPpgFilter && this.getUpcPpgFilterRow()}
                {hasBrandFilter && this.getBrandFilterRow()}
                {hasUpcBrandFilter && this.getUpcBrandFilterRow()}
                {hasCategoryFilter && this.getCategoryFilterRow()}
                {hasChannelFilter && this.getChannelFilterRow()}
                {hasOfferTypeFilter && this.getOfferTypeFilterRow()}
                {hasHolidayFilter && this.getHolidayFilterRow()}
                {hasPromotionFilter && this.getPromotionFilterRow()}
                {hasDateFilter && this.getDateFilterRow()}
                {hasComparisonDateFilter && this.getComparisonDateFilterRow()}
            </FormSection>
        );
    };

    getBrandFilterTooltip = (): SelectedFilters => {
        const {
            consumerTrendBrandNodes,
            consumerTrendUpcBrandNodes,
            defaultFilters,
            hasUpcBrandFilter,
            purchaseCycleBrandNodes,
            purchaseCycleUpcBrandNodes,
            reportType,
            savedFilters
        } = this.props;
        // State may not be set yet so check if using default filters or saved filters from redux store
        const filters = savedFilters ? savedFilters : defaultFilters;
        const { brands } = filters;
        let nodes = null;
        switch (reportType) {
            case CONSUMER:
                nodes = hasUpcBrandFilter
                    ? consumerTrendUpcBrandNodes
                    : consumerTrendBrandNodes;
                break;
            case PURCHASE_CYCLE:
                nodes = hasUpcBrandFilter
                    ? purchaseCycleUpcBrandNodes
                    : purchaseCycleBrandNodes;
                break;
        }
        if (nodes) {
            return {
                tooltip: {
                    displayName: "reports.brand_x",
                    labelField: "displayName",
                    nodes: nodes,
                    selected: brands,
                    valueField: "displayName"
                }
            };
        }
        return { customTooltip: "" };
    };

    getCategoryFilterTooltip = (): SelectedFilters => {
        const {
            consumerTrendCategories,
            defaultFilters,
            savedFilters
        } = this.props;
        // State may not be set yet so check if using default filters or saved filters from redux store
        const filters = savedFilters ? savedFilters : defaultFilters;
        const { categories } = filters;
        return {
            tooltip: {
                displayName: "reports.category_x",
                labelField: "displayName",
                nodes: consumerTrendCategories,
                selected: categories,
                valueField: "displayName"
            }
        };
    };

    getCategorySubcategoryFilterTooltip = (): SelectedFilters | null => {
        const { defaultFilters, savedFilters, t } = this.props;
        // State may not be set yet so check if using default filters or saved filters from redux store
        const filters = savedFilters ? savedFilters : defaultFilters;
        const { subcategories } = filters;

        if (subcategories.length > 0) {
            let subCategoriesProcessed = subcategories.map(
                (subcategory: string, index: number) => {
                    let tooltipText = subcategory;
                    if (subcategory.includes(CATEGORY_SUBCATEGORY_SEPARATOR)) {
                        const [category, subcat] = subcategory.split(
                            CATEGORY_SUBCATEGORY_SEPARATOR
                        );
                        tooltipText = `${category} - ${subcat}`;
                    }
                    return <div key={"subcat - " + index}>{tooltipText}</div>;
                }
            );

            if (subCategoriesProcessed.length > MAX_FILTER_TOOLTIPS) {
                subCategoriesProcessed = subCategoriesProcessed
                    .slice(0, MAX_FILTER_TOOLTIPS)
                    .concat([
                        <Flexbox
                            alignItems="center"
                            key="plus_x_more_node"
                            marginRight="8px"
                        >
                            {t("common:general.plus_x_more", {
                                count:
                                    subCategoriesProcessed.length -
                                    MAX_FILTER_TOOLTIPS
                            })}
                        </Flexbox>
                    ]);
            }

            const filterDisplayName = t("reports.category_x" as ParseKeys, {
                count: subcategories.length
            });
            const filterDisplayNameNode = (
                <Flexbox
                    alignItems="center"
                    key="subcategories"
                    marginRight="8px"
                >
                    {filterDisplayName}
                </Flexbox>
            );

            const tooltip = (
                <Tooltip key="subcategoriesTooltip">
                    {filterDisplayNameNode}
                    <div>{subCategoriesProcessed}</div>
                </Tooltip>
            );

            return {
                customTooltip: tooltip
            };
        }
        return null;
    };

    getChannelFilterTooltip = (): SelectedFilters => {
        const {
            consumerTrendChannelNodes,
            defaultFilters,
            reportType,
            savedFilters,
            takeRateChannelNodes
        } = this.props;
        // State may not be set yet so check if using default filters or saved filters from redux store
        const filters = savedFilters ? savedFilters : defaultFilters;
        const { channels } = filters;
        if (reportType === CONSUMER) {
            return {
                tooltip: {
                    displayName: "general.channel_x",
                    labelField: "displayName",
                    nodes: consumerTrendChannelNodes,
                    selected: channels,
                    valueField: "entityIdWithoutVersion"
                }
            };
        } else if (reportType === TAKE_RATE) {
            return {
                tooltip: {
                    displayName: "general.channel_x",
                    labelField: "displayName",
                    nodes: takeRateChannelNodes,
                    selected: channels,
                    valueField: "entityIdWithoutVersion"
                }
            };
        }
        return { customTooltip: "" };
    };

    getDateFilterTooltip = (): SelectedFilters => {
        const {
            defaultFilters,
            purchaseCycleTimePeriods,
            reportType,
            savedFilters
        } = this.props;
        // State may not be set yet so check if using default filters or saved filters from redux store
        const filters = savedFilters ? savedFilters : defaultFilters;
        const { date } = filters;
        const timePeriods =
            reportType === PURCHASE_CYCLE
                ? purchaseCycleTimePeriods
                : reportDateNodes();
        return {
            tooltip: {
                displayName:
                    !isNil(date.label) && !isEmptyString(date.label)
                        ? date.label
                        : date.time === CUSTOM
                        ? dateRange2Str(date.startDate, date.endDate)
                        : get(
                              timePeriods.find(
                                  timePeriod => timePeriod.id === date.time
                              ),
                              "label",
                              ""
                          ),
                nodes: timePeriods,
                noTooltip: true,
                selected: [""],
                showDisplayNameValue: true
            }
        };
    };

    getComparisonDateFilterTooltip = (): SelectedFilters => {
        const { defaultFilters, savedFilters } = this.props;
        // State may not be set yet so check if using default filters or saved filters from redux store
        const filters = savedFilters ? savedFilters : defaultFilters;
        const { comparisonDate } = filters;
        const timePeriods = reportComparisonDateNodes();
        return {
            tooltip: {
                displayName:
                    !isNil(comparisonDate.label) &&
                    !isEmptyString(comparisonDate.label)
                        ? comparisonDate.label
                        : get(
                              timePeriods.find(
                                  timePeriod =>
                                      timePeriod.id === comparisonDate.time
                              ),
                              "label",
                              ""
                          ),
                nodes: timePeriods,
                noTooltip: true,
                selected: [""],
                showDisplayNameValue: true
            }
        };
    };

    getHolidayFilterTooltip = (): SelectedFilters => {
        const {
            defaultFilters,
            holidayNodes,
            reportType,
            savedFilters
        } = this.props;
        // State may not be set yet so check if using default filters or saved filters from redux store
        const filters = savedFilters ? savedFilters : defaultFilters;
        if (reportType === TAKE_RATE) {
            const { holidays } = filters;
            return {
                tooltip: {
                    displayName: "general.holidays_slash_seasons_x",
                    labelField: "displayName",
                    nodes: holidayNodes,
                    selected: holidays,
                    valueField: "entityIdWithoutVersion"
                }
            };
        }
        return { customTooltip: "" };
    };

    getOfferTypeFilterTooltip = (): SelectedFilters => {
        const {
            defaultFilters,
            offerTypeNodes,
            reportType,
            savedFilters
        } = this.props;
        // State may not be set yet so check if using default filters or saved filters from redux store
        const filters = savedFilters ? savedFilters : defaultFilters;
        if (reportType === TAKE_RATE) {
            const { offerTypes } = filters;
            return {
                tooltip: {
                    displayName: "general.offer_type_x",
                    labelField: "displayName",
                    nodes: offerTypeNodes,
                    selected: offerTypes
                }
            };
        }
        return { customTooltip: "" };
    };

    getPpgFilterTooltip = (): SelectedFilters => {
        const {
            consumerTrendPPGNodes,
            consumerTrendUpcPPGNodes,
            defaultFilters,
            hasUpcPpgFilter,
            purchaseCyclePPGNodes,
            purchaseCycleUpcPPGNodes,
            reportType,
            savedFilters,
            takeRatePPGNodes
        } = this.props;
        // State may not be set yet so check if using default filters or saved filters from redux store
        const filters = savedFilters ? savedFilters : defaultFilters;
        const { ppgs } = filters;

        let nodes = null;
        switch (reportType) {
            case CONSUMER:
                nodes = hasUpcPpgFilter
                    ? consumerTrendUpcPPGNodes
                    : consumerTrendPPGNodes;
                break;
            case TAKE_RATE:
                nodes = takeRatePPGNodes;
                break;
            case PURCHASE_CYCLE:
                nodes = hasUpcPpgFilter
                    ? purchaseCycleUpcPPGNodes
                    : purchaseCyclePPGNodes;
                break;
        }
        if (nodes) {
            return {
                tooltip: {
                    displayName: "reports.ppg_x",
                    nodes: flattenPpgNodes(nodes),
                    selected: ppgs
                }
            };
        }
        return { customTooltip: "" };
    };

    getPromotionFilterTooltip = (): SelectedFilters => {
        const { defaultFilters, promotionNodes, savedFilters } = this.props;
        // State may not be set yet so check if using default filters or saved filters from redux store
        const filters = savedFilters ? savedFilters : defaultFilters;
        const { promotion } = filters;
        const selectedNode = promotionNodes.find(
            node => node.value === promotion
        );
        return {
            tooltip: {
                displayName: get(selectedNode, "displayName", ""),
                noTooltip: true,
                selected: promotion,
                showDisplayNameValue: true
            }
        };
    };

    getFiltersTooltip = (): SelectedFilters[] => {
        const {
            hasBrandFilter,
            hasCategoryFilter,
            hasCategorySubcategoryFilter,
            hasChannelFilter,
            hasComparisonDateFilter,
            hasDateFilter,
            hasHolidayFilter,
            hasOfferTypeFilter,
            hasPpgFilter,
            hasPromotionFilter,
            hasUpcBrandFilter,
            hasUpcPpgFilter
        } = this.props;
        const tooltips: SelectedFilters[] = [];
        if (hasPpgFilter) {
            tooltips.push(this.getPpgFilterTooltip());
        }
        if (hasBrandFilter) {
            tooltips.push(this.getBrandFilterTooltip());
        }
        if (hasCategoryFilter) {
            tooltips.push(this.getCategoryFilterTooltip());
        }
        if (hasCategorySubcategoryFilter) {
            const catSubcatFitler = this.getCategorySubcategoryFilterTooltip();
            if (catSubcatFitler) {
                tooltips.push(catSubcatFitler);
            }
        }
        if (hasChannelFilter) {
            tooltips.push(this.getChannelFilterTooltip());
        }
        if (hasOfferTypeFilter) {
            tooltips.push(this.getOfferTypeFilterTooltip());
        }
        if (hasHolidayFilter) {
            tooltips.push(this.getHolidayFilterTooltip());
        }
        if (hasPromotionFilter) {
            tooltips.push(this.getPromotionFilterTooltip());
        }
        if (hasDateFilter) {
            tooltips.push(this.getDateFilterTooltip());
        }
        if (hasComparisonDateFilter) {
            tooltips.push(this.getComparisonDateFilterTooltip());
        }
        if (hasUpcBrandFilter) {
            tooltips.push(this.getBrandFilterTooltip());
        }
        if (hasUpcPpgFilter) {
            tooltips.push(this.getPpgFilterTooltip());
        }
        return tooltips;
    };

    updateFiltersTooltip = () => {
        const { filtersTooltipChangeHandler } = this.props;
        filtersTooltipChangeHandler(this.getFiltersTooltip());
    };

    leaveHandler = (leave: boolean) => {
        const { closeHandler, defaultFilters, savedFilters } = this.props;
        if (leave) {
            closeHandler();
            // If canceling changes, reset to saved filter values
            if (isNil(savedFilters)) {
                this.setState({
                    filters: defaultFilters
                });
            } else {
                this.setState({
                    filters: savedFilters
                });
            }
        }
    };

    onCancel = () => {
        this.leaveHandler(true);
    };

    onReset = () => {
        const { defaultFilters } = this.props;
        this.setState({
            filters: defaultFilters
        });
    };

    onShowResults = () => {
        // Filters within the drawer have their own outside click
        // Slight delay in order for other outside clicks to resolve first
        // Since filters only update redux store their shouldn't be a timing issue
        setTimeout(() => {
            this.onSave();
        }, 100);
    };

    onSave = () => {
        const {
            closeHandler,
            reportType,
            savedFilters,
            saveHandler,
            updateDataTableFilters,
            updateStackedBarFilters
        } = this.props;
        const { filters } = this.state;
        if (!isEqual(savedFilters, filters)) {
            if (reportType === CONSUMER || reportType === PURCHASE_CYCLE) {
                updateDataTableFilters(filters);
            } else if (reportType === TAKE_RATE) {
                updateStackedBarFilters(filters);
            }
            saveHandler(savedFilters, filters);
        }
        closeHandler();
    };

    onBrandFilterChange = (brands: string[]) => {
        const { filters } = this.state;
        if (
            filters.filterType === CONSUMER ||
            filters.filterType === PURCHASE_CYCLE
        ) {
            this.setState({
                filters: {
                    ...filters,
                    brands
                }
            });
        }
    };

    onCategoryFilterChange = (categories: string[]) => {
        const { filters } = this.state;
        if (filters.filterType === CONSUMER) {
            this.setState({
                filters: {
                    ...filters,
                    categories
                }
            });
        }
    };

    onChannelFilterChange = (channels: string[]) => {
        const { filters } = this.state;
        if (
            filters.filterType === CONSUMER ||
            filters.filterType === TAKE_RATE
        ) {
            this.setState({
                filters: {
                    ...filters,
                    channels
                }
            });
        }
    };

    onDateFilterChange = (newDate: DateFilter) => {
        const { filters } = this.state;
        const date = { ...newDate };
        if (
            newDate.endDate &&
            newDate.startDate &&
            newDate.endDate === newDate.startDate
        ) {
            date.label = date2Str(newDate.startDate);
        }
        this.setState({
            filters: {
                ...filters,
                date
            }
        });
    };

    onComparisonDateFilterChange = (newComparisonDate: DateFilter) => {
        const { filters } = this.state;
        const comparisonDate = { ...newComparisonDate };
        if (
            newComparisonDate.endDate &&
            newComparisonDate.startDate &&
            newComparisonDate.endDate === newComparisonDate.startDate
        ) {
            comparisonDate.label = date2Str(newComparisonDate.startDate);
        }
        this.setState({
            filters: {
                ...filters,
                // @ts-expect-error - comparisonDate not on other report types
                comparisonDate
            }
        });
    };

    onHolidaysChange = (holidays: string[]) => {
        const { filters } = this.state;
        if (filters.filterType === TAKE_RATE) {
            this.setState({
                filters: {
                    ...filters,
                    holidays
                }
            });
        }
    };

    onOfferTypesChange = (offerTypes: string[]) => {
        const { filters } = this.state;
        if (filters.filterType === TAKE_RATE) {
            this.setState({
                filters: {
                    ...filters,
                    offerTypes
                }
            });
        }
    };

    onPpgFilterChange = (ppgs: string[]) => {
        const { filters } = this.state;
        this.setState({
            filters: {
                ...filters,
                ppgs
            }
        });
    };

    onPromotionFilterChange = (option?: OptionTypeOrNullOrUndefined): void => {
        const { filters } = this.state;
        if (option) {
            const promotion = option.value as PromotionFilter;
            if (filters.filterType === CONSUMER) {
                this.setState({
                    filters: {
                        ...filters,
                        promotion
                    }
                });
            }
        }
    };

    onCategorySubcategoryFilterChange = (selected: string[]) => {
        const { filters } = this.state;
        if (
            filters.filterType === CONSUMER ||
            filters.filterType === PURCHASE_CYCLE
        ) {
            this.setState({
                filters: {
                    ...filters,
                    subcategories: selected
                }
            });
        }
    };

    render() {
        const {
            hasCategorySubcategoryFilter,
            isOpen,
            reportType,
            reportView,
            t
        } = this.props;
        const rightPaneFilters = this.getRightPaneFilters();
        const drawerFooter = (
            <DrawerFooter>
                <Button
                    onClick={this.onReset}
                    text={t("common:filter.reset_filters")}
                    trackingComponentLabel={[
                        ReportFiltersView.displayName,
                        reportType,
                        reportView,
                        "Reset Filters"
                    ]}
                    trackingEventName={EVENT_NAME_FILTER_CLEAR}
                    type={BUTTON_TYPE_SECONDARY}
                />
                <FlexGrow />
                <Button
                    marginLeft="20px"
                    onClick={this.onCancel}
                    text={t("common:general.cancel")}
                    trackingComponentLabel={[
                        ReportFiltersView.displayName,
                        reportType,
                        reportView,
                        "Cancel"
                    ]}
                    type={BUTTON_TYPE_SECONDARY}
                />
                <Button
                    marginLeft="8px"
                    onClick={this.onSave}
                    text={t("common:filter.show_results")}
                    trackingComponentLabel={[
                        ReportFiltersView.displayName,
                        reportType,
                        reportView,
                        "Apply Filters"
                    ]}
                    trackingEventName={EVENT_NAME_FILTER_APPLY}
                    type={BUTTON_TYPE_PRIMARY}
                />
            </DrawerFooter>
        );

        const drawerWidth = hasCategorySubcategoryFilter ? 500 : 400;

        return (
            <DrawerOverlay isOpen={isOpen}>
                <Drawer
                    clickOutsideHandler={this.onShowResults}
                    closeHandler={this.onCancel}
                    cssWidth={drawerWidth}
                    footer={drawerFooter}
                    isOpen={isOpen}
                    title={t("common:aggrid.filters")}
                >
                    <Flexbox
                        backgroundColor={theme.universalBackground}
                        boxSizing="border-box"
                        flex={1}
                        flexDirection="row"
                        padding="12px 24px"
                    >
                        <FlexboxWithBorder
                            flex={1}
                            flexDirection="row"
                            padding="16px"
                        >
                            <Flexbox flexDirection="column">
                                {rightPaneFilters}
                            </Flexbox>
                        </FlexboxWithBorder>
                    </Flexbox>
                </Drawer>
            </DrawerOverlay>
        );
    }
}

const mapStateToProps = (
    state: RootState,
    ownProps: ReportFiltersViewOwnProps
) => {
    const reportType = get(ownProps, "reportType");
    const reportView = get(ownProps, "reportView");
    let filterPath = "dataTable.filters";
    if (reportType === TAKE_RATE) {
        filterPath = "stackedBar.filters";
    }
    return {
        consumerTrendBrandNodes:
            state.init.preloadedEntities.filters.reports?.consumerTrendBrands ??
            [],
        consumerTrendCategories:
            state.init.preloadedEntities.filters.reports
                ?.consumerTrendCategories ?? [],
        consumerTrendChannelNodes:
            state.init.preloadedEntities.filters.reports
                ?.consumerTrendChannels ?? [],
        consumerTrendPPGNodes:
            state.init.preloadedEntities.filters.reports
                ?.consumerTrendProducts ?? [],
        consumerTrendUpcBrandNodes:
            state.init.preloadedEntities.filters.reports
                ?.consumerTrendUpcBrands ?? [],
        consumerTrendUpcCategories:
            state.init.preloadedEntities.filters.reports
                ?.consumerTrendUpcCategories ?? [],
        consumerTrendUpcPPGNodes:
            state.init.preloadedEntities.filters.reports
                ?.consumerTrendUpcProductGroupings ?? [],
        holidayNodes: state.init.preloadedEntities.filters?.holidays ?? [],
        offerTypeNodes:
            state.init.preloadedEntities.filters?.reports?.offerTypes ?? [],
        purchaseCycleBrandNodes:
            state.init.preloadedEntities.filters.reports?.purchaseCycleBrands ??
            [],
        purchaseCyclePPGNodes:
            state.init.preloadedEntities.filters.reports
                ?.purchaseCycleProducts ?? [],
        purchaseCycleTimePeriods:
            state.init.preloadedEntities.filters.reports
                ?.purchaseCycleTimePeriods ?? [],
        purchaseCycleUpcBrandNodes:
            state.init.preloadedEntities.filters.reports
                ?.purchaseCycleUpcBrands ?? [],
        purchaseCycleUpcCategories:
            state.init.preloadedEntities.filters.reports
                ?.purchaseCycleUpcCategories ?? [],
        purchaseCycleUpcPPGNodes:
            state.init.preloadedEntities.filters.reports
                ?.purchaseCycleUpcProductGroupings ?? [],
        promotionNodes:
            state.init.preloadedEntities.filters?.promotionFilters ?? [],
        savedFilters: get(
            state,
            getReportStateKey(reportType, reportView, filterPath)
        ),
        takeRateChannelNodes:
            state.init.preloadedEntities.filters.reports?.takeRateChannels ??
            [],
        takeRatePPGNodes:
            state.init.preloadedEntities.filters.reports?.takeRateProducts ?? []
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        updateDataTableFilters: (filters: ReportFilters) => {
            dispatch(updateReportsDataTableFilters(filters));
        },
        updateStackedBarFilters: (filters: ReportFilters) => {
            dispatch(updateReportStackedBarFilters(filters));
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(ReportFiltersView));
