import * as React from "react";
import { memo } from "react";
import type {
    ICellRendererParams,
    IServerSideGetRowsParams
} from "ag-grid-community";
import type { TFunction } from "i18next";
import get from "lodash.get";
import { useTranslation, withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import AgDataGrid, { SERVER_SIDE } from "common/components/grid/AgDataGrid";
import type { DataGridType } from "common/components/grid/AgDataGrid";
import {
    COLUMN_ALIGN_RIGHT,
    COLUMN_DEFAULT_ROW_HEIGHT,
    DEFAULT_ROW_HEIGHT,
    getRowsHandler
} from "common/components/grid/AgDataGridUtil";
import {
    GridHeaderItemCount,
    OuterTableWrapper
} from "common/components/grid/CommonGridUtil";
import Flexbox from "common/components/styled/Flexbox";
import HorizontalSpacer from "common/components/styled/HorizontalSpacer";
import theme from "common/components/theme";
import SvgIcon from "common/components/SvgIcon";
import Tooltip from "common/components/Tooltip";
import { ReactComponent as WarningIcon } from "common/icons/Warning.svg";
import {
    isAllowedDataInstance,
    SELECTION_TYPE_SINGLE
} from "common/util/permission";
import { campaignPPGEditPage } from "campaign/state/campaignActions";
import {
    CAMPAIGN_PRODUCT_GROUP_CONTRIBUTION_PER_UNIT,
    CAMPAIGN_PRODUCT_GROUP_INSTACART_AVERAGE_PRICE_TO_CONSUMER,
    CAMPAIGN_PRODUCT_GROUP_MANUFACTURER_MARGIN,
    CAMPAIGN_PRODUCT_GROUP_NAME,
    CAMPAIGN_PRODUCT_GROUP_NUMBER_OF_OFFERS,
    CAMPAIGN_PRODUCT_GROUP_OFFER_DISPLAY_NAME,
    CAMPAIGN_PRODUCT_GROUP_PRICE_TO_CONSUMER,
    CAMPAIGN_PRODUCT_GROUP_PRICE_TO_RETAILER,
    CAMPAIGN_PRODUCT_GROUP_PRICE_TO_WHOLESALER
} from "products/state/productStateUtils";
import {
    fetchCampaignProductGroups,
    updateCampaignProductGroupsSort
} from "products/state/productsActions";
import { CAMPAIGN_CREATE_EDIT_DELETE_PROMOTION } from "shell/util/allowableActionUtils";
import { GRID_CAMPAIGN_PPGS } from "shell/util/preferences";
import type { AppDispatch, RootState } from "store";

export const getInstacartBasePriceComponent = (
    basePriceUpToDate: boolean,
    averagePrice: string,
    t: TFunction
): React.ReactElement | string => {
    if (basePriceUpToDate) {
        return averagePrice;
    } else {
        return (
            <Tooltip>
                <Flexbox
                    cssHeight="100%"
                    cssWidth="100%"
                    justifyContent="space-between"
                >
                    <SvgIcon
                        color={theme.warning}
                        cursor="pointer"
                        height="24px"
                        icon={WarningIcon}
                        title={t("common:icons.warning")}
                        width="24px"
                    />
                    <HorizontalSpacer cssWidth="8px" />
                    <Flexbox margin="auto 0">{averagePrice}</Flexbox>
                </Flexbox>
                <div>
                    {t(
                        "promotion.instacart_average_price_to_consumer_refreshing"
                    )}
                </div>
            </Tooltip>
        );
    }
};

const InstacartAveragePriceCellRenderer = memo<ICellRendererParams>(
    //@ts-expect-error - not sure what is going on here
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        const { t } = useTranslation();
        if (colDef && data) {
            const basePriceUpToDate = get(data, "basePriceUpToDate", true);
            const averagePrice = get(data, "instacartBasePriceFormatCurrency");
            return getInstacartBasePriceComponent(
                basePriceUpToDate,
                averagePrice,
                t
            );
        }
        return null;
    }
);

const SORT_KEY_MAP = {
    [CAMPAIGN_PRODUCT_GROUP_CONTRIBUTION_PER_UNIT]: "contributionPerUnit",
    [CAMPAIGN_PRODUCT_GROUP_INSTACART_AVERAGE_PRICE_TO_CONSUMER]:
        "instacartBasePrice",
    [CAMPAIGN_PRODUCT_GROUP_MANUFACTURER_MARGIN]: "manufacturerMargin",
    [CAMPAIGN_PRODUCT_GROUP_PRICE_TO_CONSUMER]: "priceToConsumer",
    [CAMPAIGN_PRODUCT_GROUP_PRICE_TO_RETAILER]: "priceToRetailer",
    [CAMPAIGN_PRODUCT_GROUP_PRICE_TO_WHOLESALER]: "priceToWholesaler"
};

type CampaignPPGsViewProps = PropsFromRedux & WithTranslation;

type CampaignPPGsViewState = {
    isOfferModalOpen: boolean;
    selectedPPg: any;
};

class CampaignPPGsView extends React.Component<
    CampaignPPGsViewProps,
    CampaignPPGsViewState
> {
    static displayName = "CampaignPPGsView";

    dataGrid: DataGridType = null;

    state: CampaignPPGsViewState = {
        isOfferModalOpen: false,
        selectedPPg: null
    };

    onOfferImageClick = (row: Promotion): void => {
        this.setState({
            isOfferModalOpen: true,
            selectedPPg: row
        });
    };

    onOfferModalClose = (): void => {
        this.setState({ isOfferModalOpen: false });
    };

    // Grid row menu options and click handlers
    gridRowMenu = (rowData: Promotion): GridMenuItemType[] => {
        const { campaign, t } = this.props;
        const menuItems: GridMenuItemType[] = [];
        if (
            isAllowedDataInstance(
                CAMPAIGN_CREATE_EDIT_DELETE_PROMOTION,
                SELECTION_TYPE_SINGLE,
                [campaign]
            )
        ) {
            menuItems.push({
                data: rowData,
                label: t("common:general.edit"),
                onClickHandler: this.onEditCampaignProductGroup,
                value: "Edit Campaign Product Group"
            });
        }
        return menuItems;
    };

    getColumnDefinitions = (): ColumnDefinition[] => {
        const { campaign, t, tenantType } = this.props;

        let columnDefinitions: ColumnDefinition[] = [];
        columnDefinitions = [
            {
                cellClass: [COLUMN_DEFAULT_ROW_HEIGHT],
                colId: CAMPAIGN_PRODUCT_GROUP_NAME,
                field: CAMPAIGN_PRODUCT_GROUP_NAME,
                flex: 1,
                headerName: t("common:general.name")
            },
            {
                cellClass: [COLUMN_DEFAULT_ROW_HEIGHT],
                colId: CAMPAIGN_PRODUCT_GROUP_OFFER_DISPLAY_NAME,
                field: CAMPAIGN_PRODUCT_GROUP_OFFER_DISPLAY_NAME,
                flex: 1,
                headerName: t("concept.offer_display_name")
            },
            {
                cellClass: [COLUMN_ALIGN_RIGHT, COLUMN_DEFAULT_ROW_HEIGHT],
                colId: CAMPAIGN_PRODUCT_GROUP_NUMBER_OF_OFFERS,
                field: CAMPAIGN_PRODUCT_GROUP_NUMBER_OF_OFFERS,
                headerName: t("campaign.number_of_offers"),
                sortable: false,
                width: 130
            },
            {
                cellClass: [COLUMN_ALIGN_RIGHT, COLUMN_DEFAULT_ROW_HEIGHT],
                colId: CAMPAIGN_PRODUCT_GROUP_PRICE_TO_CONSUMER,
                field: CAMPAIGN_PRODUCT_GROUP_PRICE_TO_CONSUMER,
                headerName: t("promotion.price_to_consumer"),
                width: 120
            }
        ];

        if (campaign.hasOfferCost) {
            columnDefinitions.push({
                cellClass: [COLUMN_ALIGN_RIGHT, COLUMN_DEFAULT_ROW_HEIGHT],
                cellRenderer: InstacartAveragePriceCellRenderer,
                colId: CAMPAIGN_PRODUCT_GROUP_INSTACART_AVERAGE_PRICE_TO_CONSUMER,
                field: CAMPAIGN_PRODUCT_GROUP_INSTACART_AVERAGE_PRICE_TO_CONSUMER,
                headerName: t("promotion.instacart_average_price_to_consumer"),
                width: 140
            });
        }

        columnDefinitions.push({
            cellClass: [COLUMN_ALIGN_RIGHT, COLUMN_DEFAULT_ROW_HEIGHT],
            colId: CAMPAIGN_PRODUCT_GROUP_PRICE_TO_RETAILER,
            field: CAMPAIGN_PRODUCT_GROUP_PRICE_TO_RETAILER,
            headerName: t("common:general.price_to_retailer"),
            width: 160
        });

        if (tenantType !== "TWO_TIER") {
            columnDefinitions.push({
                cellClass: [COLUMN_ALIGN_RIGHT, COLUMN_DEFAULT_ROW_HEIGHT],
                colId: CAMPAIGN_PRODUCT_GROUP_PRICE_TO_WHOLESALER,
                field: CAMPAIGN_PRODUCT_GROUP_PRICE_TO_WHOLESALER,
                headerName: t("common:general.price_to_wholesaler"),
                width: 170
            });
        }

        columnDefinitions.push({
            cellClass: [COLUMN_ALIGN_RIGHT, COLUMN_DEFAULT_ROW_HEIGHT],
            colId: CAMPAIGN_PRODUCT_GROUP_MANUFACTURER_MARGIN,
            field: CAMPAIGN_PRODUCT_GROUP_MANUFACTURER_MARGIN,
            headerName: t("promotion.manufacturer_margin"),
            width: 170
        });

        if (campaign.hasCrossMerch) {
            columnDefinitions.push({
                cellClass: [COLUMN_ALIGN_RIGHT, COLUMN_DEFAULT_ROW_HEIGHT],
                colId: CAMPAIGN_PRODUCT_GROUP_CONTRIBUTION_PER_UNIT,
                field: CAMPAIGN_PRODUCT_GROUP_CONTRIBUTION_PER_UNIT,
                headerName: t("promotion.contribution_per_unit"),
                width: 170
            });
        }

        return columnDefinitions;
    };

    reFetchData = (resetScroll = true): void => {
        if (this.dataGrid) {
            this.dataGrid.reFetchData(resetScroll);
        }
    };

    onEditCampaignProductGroup = (rowData: CampaignProductGroup) => {
        const { campaign, goToPPGEditPage } = this.props;
        goToPPGEditPage(campaign.entityId, rowData.entityId);
    };

    getRows = (params: IServerSideGetRowsParams) => {
        const { fetchData, updateSort } = this.props;
        getRowsHandler({
            dataGrid: this.dataGrid,
            fetchData: fetchData,
            params: params,
            sortKeyMap: SORT_KEY_MAP,
            updateSort: updateSort
        });
    };

    datasource = {
        getRows: this.getRows
    };

    render() {
        const { campaign, t, totalCount } = this.props;

        const columnDefinitions = this.getColumnDefinitions();
        const countHeader = t("events.x_ppg", {
            count: totalCount
        });
        const gridHeaderLeft = (
            <React.Fragment>
                <GridHeaderItemCount
                    count={totalCount}
                    countHeader={countHeader}
                />
            </React.Fragment>
        );

        let gridRowMenu = undefined;
        if (
            isAllowedDataInstance(
                CAMPAIGN_CREATE_EDIT_DELETE_PROMOTION,
                SELECTION_TYPE_SINGLE,
                [campaign]
            )
        ) {
            gridRowMenu = this.gridRowMenu;
        }

        const dataGridParams = {
            columnDefs: columnDefinitions,
            dataIdKey: "entityId",
            datasource: this.datasource,
            gridHeaderLeft: gridHeaderLeft,
            gridRowMenu: gridRowMenu,
            id: GRID_CAMPAIGN_PPGS,
            onRef: (ref: DataGridType) => (this.dataGrid = ref),
            rowHeight: DEFAULT_ROW_HEIGHT,
            rowModelType: SERVER_SIDE
        };

        return (
            <React.Fragment>
                <OuterTableWrapper
                    backgroundColor={theme.background}
                    paddingBottom="0px"
                    paddingLeft="0px"
                    paddingRight="0px"
                    paddingTop="0px"
                >
                    <AgDataGrid {...dataGridParams} />
                </OuterTableWrapper>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        allowableActions: get(state, "init.allowableActions"),
        campaign: get(state, "campaign"),
        search: get(state, "products.campaignProductGroups.search"),
        tenantType: get(state, "init.tenant.type"),
        totalCount: get(state, "products.campaignProductGroups.totalCount")
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        fetchData: () => {
            return dispatch(fetchCampaignProductGroups());
        },
        goToPPGEditPage: (
            entityId: string,
            campaignProductGroupEntityId: string
        ) => {
            dispatch(
                campaignPPGEditPage(entityId, campaignProductGroupEntityId)
            );
        },
        updateSort: (sortBy: string, sortOrder: SortOrder) => {
            dispatch(updateCampaignProductGroupsSort(sortBy, sortOrder));
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(CampaignPPGsView));
