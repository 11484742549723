import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { createHashHistory } from "history";
import qs from "qs";
import type { AnyAction, Reducer } from "redux";
import { connectRoutes } from "redux-first-router";
import type { ThunkAction } from "redux-thunk";
import { logger } from "redux-logger";
import { commonReducers, preprocessReducers } from "common/reducers";
import NavigateAwayMessage from "common/shell/components/NavigateAwayMessage";
import { getRavenMiddleware } from "common/shell/util/ravenUtils";
import { routeActionMiddleware } from "common/util/routeActionMiddleware";
import reducers from "reducers";
import { routeMap } from "routes";

type ConfirmLeaveCallback = (leave: boolean) => void;

const connectRoutesOptions = {
    // allows for routes to appear in browser address bar, provides browser history functionality, uses # so that it is client side routing
    createHistory: createHashHistory,
    displayConfirmLeave: (
        resourceKey: string,
        callback: ConfirmLeaveCallback
    ) => {
        store.dispatch(
            NavigateAwayMessage(store.dispatch, resourceKey, callback)
        );
    },
    querySerializer: qs
};

// raven error logging middleware
const ravenMiddleware = getRavenMiddleware();

// hook up routes
// the routes object has a reducer, enhancer and middleware
const getRoutes = (): any => {
    return connectRoutes(routeMap, connectRoutesOptions);
};

const getMiddleware = (routes: any): any => {
    let middleware = [
        routes.middleware, // router middleware
        routeActionMiddleware, // run registered actors when route actions are dispatched
        ravenMiddleware
    ];

    if (
        process.env.NODE_ENV !== "production" &&
        process.env.NODE_ENV !== "test"
    ) {
        middleware = [...middleware, logger];
    }
    return middleware;
};

const routes = getRoutes();

const locationReducer: Reducer<LocationState> = routes.reducer;

const combinedReducer = combineReducers({
    ...commonReducers,
    ...reducers,
    location: locationReducer // location reducer
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    const newState = preprocessReducers(state, action);
    return combinedReducer(newState, action);
};

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            getMiddleware(routes) as ReturnType<typeof getDefaultMiddleware>
        ),
    enhancers: [routes.enhancer]
});

// For testing only
export const getTestingStore = (initialState: Record<string, unknown>) => {
    return configureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({ serializableCheck: false }).concat(
                getMiddleware(routes) as ReturnType<typeof getDefaultMiddleware>
            ),
        enhancers: [routes.enhancer],
        preloadedState: initialState
    });
};

export type RootState = ReturnType<typeof combinedReducer>;
export type AppDispatch = typeof store.dispatch;
// https://redux.js.org/usage/usage-with-typescript#type-checking-redux-thunks
type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    AnyAction
>;
export type ActionOrThunk = AnyAction | AppThunk;
export type Actions = AnyAction | AppThunk | ActionOrThunk[];
export type GetState = () => any;

export default store;
