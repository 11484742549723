import type { AnyAction } from "redux";
import noAction from "common/util/noAction";
import {
    CLEAR_AGGREGATED_ADS,
    RECEIVE_AGGREGATED_ADS,
    RECEIVE_EVENTS,
    REQUEST_EVENTS,
    UPDATE_EVENTS_ADDITIONAL_DEFAULT_COLUMNS,
    UPDATE_EVENTS_AGGREGATE,
    UPDATE_EVENTS_GROUP_ADS,
    UPDATE_EVENTS_OFFSET,
    UPDATE_EVENTS_SORT
} from "event/state/eventsActions";
import {
    defaultEventsState,
    reduceAggregatedAd,
    reduceEvents
} from "event/state/eventsStateUtils";
import type { EventsState } from "event/state/eventsStateUtils";
import { EVENTS_PAGE } from "shell/state/pageActions";

const events = (
    state: EventsState = defaultEventsState,
    action: AnyAction = noAction
): EventsState => {
    switch (action.type) {
        case EVENTS_PAGE:
            return Object.assign({}, state, {
                groupByFields: action.aggregations
                    ? action.aggregations.join(",")
                    : state.groupByFields,
                mode: action.mode || state.mode
            });
        case CLEAR_AGGREGATED_ADS:
            return Object.assign({}, state, {
                aggregatedAds: null,
                aggregatedAdsTotal: null
            });
        case RECEIVE_AGGREGATED_ADS: {
            const aggregatedAds = action.aggregatedAds.items.map(
                reduceAggregatedAd
            );
            const aggregatedAdsTotal = action.aggregatedAds.totalItems;
            return Object.assign({}, state, {
                aggregatedAds,
                aggregatedAdsTotal
            });
        }
        case REQUEST_EVENTS:
            return state;
        case RECEIVE_EVENTS: {
            const reducedEvents = reduceEvents(action.events);
            return Object.assign({}, state, {
                ...reducedEvents,
                sortBy: state.sortBy,
                sortOrder: state.sortOrder
            });
        }
        // Used for drilldowns that require showing additional columns by default
        // Default columns are already shown so array only needs extra columns
        case UPDATE_EVENTS_ADDITIONAL_DEFAULT_COLUMNS:
            return Object.assign({}, state, {
                additionalDefaultColumns: action.additionalDefaultColumns
            });
        case UPDATE_EVENTS_AGGREGATE:
            return Object.assign({}, state, {
                groupByFields: action.groupByFields
            });
        case UPDATE_EVENTS_GROUP_ADS:
            return Object.assign({}, state, {
                groupAds: action.groupAds
            });
        case UPDATE_EVENTS_OFFSET:
            return Object.assign({}, state, {
                offset: action.offset
            });

        case UPDATE_EVENTS_SORT:
            return Object.assign({}, state, {
                sortBy: action.sortBy,
                sortOrder: action.sortOrder
            });

        default:
            return state;
    }
};

export default events;
