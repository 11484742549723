import styled from "styled-components/macro";
import { LoadmaskSpinner } from "common/shell/components/Loadmask";

type SuspenseLoadingContentProps = {
    loadingMessage?: string;
};

const SuspenseLoadingContent = styled.div<SuspenseLoadingContentProps>`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    background-color: ${props =>
        props.loadingMessage ? props.theme.loadMaskBg : "transparent"};
    border-radius: 2px;
    padding: 10px;
    width: 100%;
`;

const SuspenseLoading = () => {
    return (
        <SuspenseLoadingContent>
            <LoadmaskSpinner />
        </SuspenseLoadingContent>
    );
};

SuspenseLoading.displayName = "SuspenseLoading";

export default SuspenseLoading;
