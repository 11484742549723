// Consts for sorting and columns
export const DISCOUNT = "discount";
export const EFFECTIVE_DISCOUNT = "effectiveDiscount";
export const EFFECTIVE_PRICE_TO_CONSUMER = "effectivePriceToConsumer";
export const UNIT_PRICE_TO_CONSUMER = "unitPriceToConsumer";

// Consts for possible primary metric
export const DISCOUNT_PM = "DISCOUNT";
export const EFFECTIVE_DISCOUNT_PM = "EFFECTIVE_DISCOUNT";
export const EFFECTIVE_PRICE_TO_CONSUMER_PM = "EFFECTIVE_PRICE_TO_CONSUMER";
export const UNIT_PRICE_TO_CONSUMER_PM = "UNIT_PRICE_TO_CONSUMER";

// Range consts
const RANGE_PERCENT = "PERCENT";
const RANGE_NUMBER = "NUMBER";

export const isDiscountPrimaryMetric = (primaryMetric: string): boolean => {
    return (
        primaryMetric === DISCOUNT_PM || primaryMetric === EFFECTIVE_DISCOUNT_PM
    );
};

export const isPriceToConsumerMetric = (primaryMetric: string): boolean => {
    return (
        primaryMetric === UNIT_PRICE_TO_CONSUMER_PM ||
        primaryMetric === EFFECTIVE_PRICE_TO_CONSUMER_PM
    );
};

export const isEffectivePrimaryMetric = (primaryMetric: string): boolean => {
    return (
        primaryMetric === EFFECTIVE_DISCOUNT_PM ||
        primaryMetric === EFFECTIVE_PRICE_TO_CONSUMER_PM
    );
};

export const buildPrimaryMetricRangeFilter = (
    min: number,
    max: number,
    primaryMetric: string
): any => {
    const valueType = isDiscountPrimaryMetric(primaryMetric)
        ? RANGE_PERCENT
        : RANGE_NUMBER;
    return {
        max: max,
        min: min,
        objectType: "RangeFilter",
        type: { objectType: "RangeFilterType", value: valueType }
    };
};
