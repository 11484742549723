import get from "lodash.get";
import {
    setRequestDates,
    setRequestDatesStatic
} from "common/reports/state/reportsActionUtils";
import { getReportStateKey } from "common/reports/state/reportsStateUtils";
import {
    getSubcategories,
    PROMO_REPORT_TIME_PERIOD
} from "reports/state/reportsStateUtils";
import type { ReportView } from "reports/state/reportsStateUtils";
import {
    AGGREGATE_BY_BRAND,
    AGGREGATE_BY_PPG,
    AGGREGATE_BY_UPC,
    SUBSTITUTION,
    SUBSTITUTION_COMPARISON,
    SUBSTITUTION_SUMMARY
} from "reports/substitution/state/substitutionStateUtils";
import type {
    SubstitutionComparisonState,
    SubstitutionSummaryState
} from "reports/substitution/state/substitutionStateUtils";
import type { RootState } from "store";

export const buildSubstitutionComparisonReportRequest = (
    state: RootState,
    savedWithStaticDates: boolean
) => {
    const substitutionComparisonState: SubstitutionComparisonState = get(
        state,
        getReportStateKey(SUBSTITUTION, SUBSTITUTION_COMPARISON, "")
    );
    const {
        aggregation,
        benchmarkCategories,
        categories,
        date,
        retailers,
        selectedItems
    } = substitutionComparisonState;
    const categoryNodes =
        state.init.preloadedEntities.filters.reports
            ?.substitutionUpcCategories ?? [];

    const request = {
        benchmarkCategories: getSubcategories(
            benchmarkCategories,
            categoryNodes
        ),
        brands: [] as string[],
        objectType: "SubstitutionComparisonReportRequest",
        productGroupIds: [] as string[],
        requestFilter: {
            brands: [],
            categories: getSubcategories(categories, categoryNodes),
            objectType: "SubstitutionReportRequestFilter"
        },
        retailers: retailers,
        upcs: [] as string[]
    };

    // Set selected item based on aggregation
    const selectedValues = selectedItems.map(item => item.value);
    switch (aggregation) {
        case AGGREGATE_BY_BRAND:
            request.brands = selectedValues;
            break;
        case AGGREGATE_BY_PPG:
            request.productGroupIds = selectedValues;
            break;
        case AGGREGATE_BY_UPC:
            request.upcs = selectedValues;
            break;
        default:
            break;
    }

    if (savedWithStaticDates) {
        setRequestDatesStatic(
            request,
            date,
            PROMO_REPORT_TIME_PERIOD,
            state,
            SUBSTITUTION,
            SUBSTITUTION_COMPARISON,
            "data"
        );
    } else {
        setRequestDates(request, date, PROMO_REPORT_TIME_PERIOD);
    }

    return request;
};

export const buildSubstitutionSummaryReportRequest = (
    state: RootState,
    savedWithStaticDates: boolean
) => {
    const substitutionSummaryState: SubstitutionSummaryState = get(
        state,
        getReportStateKey(SUBSTITUTION, SUBSTITUTION_SUMMARY, "")
    );

    const {
        aggregation,
        date,
        retailers,
        selectedItem,
        substitutionType,
        viewBy
    } = substitutionSummaryState;

    const request = {
        aggregation: {
            objectType: "SubstitutionReportAggregation",
            value: viewBy
        },
        brand: "",
        objectType: "SubstitutionSummaryReportRequest",
        productGroupId: "",
        requestFilter: {
            brands: [],
            categories: [],
            objectType: "SubstitutionReportRequestFilter"
        },
        retailers: retailers,
        substitutionType: {
            objectType: "SubstitutionReportSubstitutionType",
            value: substitutionType
        },
        upc: ""
    };

    // Set selected item based on aggregation
    const selectedValue = selectedItem?.value || "";
    switch (aggregation) {
        case AGGREGATE_BY_BRAND:
            request.brand = selectedValue;
            break;
        case AGGREGATE_BY_PPG:
            request.productGroupId = selectedValue;
            break;
        case AGGREGATE_BY_UPC:
            request.upc = selectedValue;
            break;
        default:
            break;
    }

    if (savedWithStaticDates) {
        setRequestDatesStatic(
            request,
            date,
            PROMO_REPORT_TIME_PERIOD,
            state,
            SUBSTITUTION,
            SUBSTITUTION_SUMMARY,
            "data"
        );
    } else {
        setRequestDates(request, date, PROMO_REPORT_TIME_PERIOD);
    }
    return request;
};

export const getSubstitutionSavedReportsRequests = (
    reportView: ReportView,
    state: RootState,
    savedWithStaticDates = false
) => {
    const requests: any[] = [];
    switch (reportView) {
        case SUBSTITUTION_COMPARISON: {
            const request = buildSubstitutionComparisonReportRequest(
                state,
                savedWithStaticDates
            );
            requests.push({
                objectType: "SavedReportRequest",
                serializedRequest: JSON.stringify(request)
            });
            break;
        }
        case SUBSTITUTION_SUMMARY: {
            const request = buildSubstitutionSummaryReportRequest(
                state,
                savedWithStaticDates
            );
            requests.push({
                objectType: "SavedReportRequest",
                serializedRequest: JSON.stringify(request)
            });
            break;
        }
        default:
            break;
    }
    return requests;
};

export const getSubstitutionSavedReportsState = (
    reportView: ReportView,
    state: RootState
) => {
    const uiState: Dictionary<string> = {};
    switch (reportView) {
        case SUBSTITUTION_COMPARISON: {
            uiState.selectedItems = get(
                state,
                getReportStateKey(
                    SUBSTITUTION,
                    SUBSTITUTION_COMPARISON,
                    "selectedItems"
                )
            );
            break;
        }
        case SUBSTITUTION_SUMMARY: {
            const substitutionChartType = get(
                state,
                getReportStateKey(
                    SUBSTITUTION,
                    SUBSTITUTION_SUMMARY,
                    "substitutionChartType"
                )
            );
            uiState.substitutionChartType = substitutionChartType;
            uiState.selectedItem = get(
                state,
                getReportStateKey(
                    SUBSTITUTION,
                    SUBSTITUTION_SUMMARY,
                    "selectedItem"
                )
            );
            break;
        }
        default:
            break;
    }
    return JSON.stringify(uiState);
};
