import i18n from "common/i18n";

export const getAgGridLocaleText = () => {
    return {
        // Set Filter
        selectAll: i18n.t("common:aggrid.selectAll"),
        selectAllSearchResults: i18n.t("common:aggrid.selectAllSearchResults"),
        searchOoo: i18n.t("common:aggrid.searchOoo"),
        blanks: i18n.t("common:aggrid.blanks"),
        noMatches: i18n.t("common:aggrid.noMatches"),

        // Number Filter & Text Filter
        filterOoo: i18n.t("common:aggrid.filterOoo"),
        equals: i18n.t("common:aggrid.equals"),
        notEqual: i18n.t("common:aggrid.notEqual"),
        empty: i18n.t("common:aggrid.empty"),

        // Number Filter
        lessThan: i18n.t("common:aggrid.lessThan"),
        greaterThan: i18n.t("common:aggrid.greaterThan"),
        lessThanOrEqual: i18n.t("common:aggrid.lessThanOrEqual"),
        greaterThanOrEqual: i18n.t("common:aggrid.greaterThanOrEqual"),
        inRange: i18n.t("common:aggrid.inRange"),
        inRangeStart: i18n.t("common:aggrid.inRangeStart"),
        inRangeEnd: i18n.t("common:aggrid.inRangeEnd"),

        // Text Filter
        contains: i18n.t("common:aggrid.contains"),
        notContains: i18n.t("common:aggrid.notContains"),
        startsWith: i18n.t("common:aggrid.startsWith"),
        endsWith: i18n.t("common:aggrid.endsWith"),

        // Date Filter
        dateFormatOoo: i18n.t("common:aggrid.dateFormatOoo"),

        // Filter Conditions
        andCondition: i18n.t("common:aggrid.andCondition"),
        orCondition: i18n.t("common:aggrid.orCondition"),

        // Filter Buttons
        applyFilter: i18n.t("common:aggrid.applyFilter"),
        resetFilter: i18n.t("common:aggrid.resetFilter"),
        clearFilter: i18n.t("common:aggrid.clearFilter"),
        cancelFilter: i18n.t("common:aggrid.cancelFilter"),

        // Filter Titles
        textFilter: i18n.t("common:aggrid.textFilter"),
        numberFilter: i18n.t("common:aggrid.numberFilter"),
        dateFilter: i18n.t("common:aggrid.dateFilter"),
        setFilter: i18n.t("common:aggrid.setFilter"),

        // Side Bar
        columns: i18n.t("common:aggrid.columns"),
        filters: i18n.t("common:aggrid.filters"),

        // Columns Tool Panel
        pivotMode: i18n.t("common:aggrid.pivotMode"),
        groups: i18n.t("common:aggrid.groups"),
        rowGroupColumnsEmptyMessage: i18n.t(
            "common:aggrid.rowGroupColumnsEmptyMessage"
        ),
        values: i18n.t("common:aggrid.values"),
        valueColumnsEmptyMessage: i18n.t(
            "common:aggrid.valueColumnsEmptyMessage"
        ),
        pivots: i18n.t("common:aggrid.pivots"),
        pivotColumnsEmptyMessage: i18n.t(
            "common:aggrid.pivotColumnsEmptyMessage"
        ),

        // Header of the Default Group Column
        group: i18n.t("common:aggrid.group"),

        // Other
        loadingOoo: i18n.t("common:aggrid.loadingOoo"),
        noRowsToShow: i18n.t("common:aggrid.noRowsToShow"),
        enabled: i18n.t("common:aggrid.enabled"),

        //  Menu
        pinColumn: i18n.t("common:aggrid.pinColumn"),
        pinLeft: i18n.t("common:aggrid.pinLeft"),
        pinRight: i18n.t("common:aggrid.pinRight"),
        noPin: i18n.t("common:aggrid.noPin"),
        valueAggregation: i18n.t("common:aggrid.valueAggregation"),
        autosizeThiscolumn: i18n.t("common:aggrid.autosizeThiscolumn"),
        autosizeAllColumns: i18n.t("common:aggrid.autosizeAllColumns"),
        groupBy: i18n.t("common:aggrid.groupBy"),
        ungroupBy: i18n.t("common:aggrid.ungroupBy"),
        resetColumns: i18n.t("common:aggrid.resetColumns"),
        expandAll: i18n.t("common:aggrid.expandAll"),
        collapseAll: i18n.t("common:aggrid.collapseAll"),
        copy: i18n.t("common:aggrid.copy"),
        ctrlC: i18n.t("common:aggrid.ctrlC"),
        copyWithHeaders: i18n.t("common:aggrid.copyWithHeaders"),
        paste: i18n.t("common:aggrid.paste"),
        ctrlV: i18n.t("common:aggrid.ctrlV"),
        export: i18n.t("common:aggrid.export"),
        csvExport: i18n.t("common:aggrid.csvExport"),
        excelExport: i18n.t("common:aggrid.excelExport"),
        excelXmlExport: i18n.t("common:aggrid.excelXmlExport"),

        // Enterprise Menu Aggregation and Status Bar
        sum: i18n.t("common:aggrid.sum"),
        min: i18n.t("common:aggrid.min"),
        max: i18n.t("common:aggrid.max"),
        none: i18n.t("common:aggrid.none"),
        count: i18n.t("common:aggrid.count"),
        avg: i18n.t("common:aggrid.avg"),
        filteredRows: i18n.t("common:aggrid.filteredRows"),
        selectedRows: i18n.t("common:aggrid.selectedRows"),
        totalRows: i18n.t("common:aggrid.totalRows"),
        totalAndFilteredRows: i18n.t("common:aggrid.totalAndFilteredRows"),
        more: i18n.t("common:aggrid.more"),
        to: i18n.t("common:aggrid.to"),
        of: i18n.t("common:aggrid.of"),
        page: i18n.t("common:aggrid.page"),
        nextPage: i18n.t("common:aggrid.nextPage"),
        lastPage: i18n.t("common:aggrid.lastPage"),
        firstPage: i18n.t("common:aggrid.firstPage"),
        previousPage: i18n.t("common:aggrid.previousPage"),

        // Enterprise Menu (Charts)
        pivotChartAndPivotMode: i18n.t("common:aggrid.pivotChartAndPivotMode"),
        pivotChart: i18n.t("common:aggrid.pivotChart"),
        chartRange: i18n.t("common:aggrid.chartRange"),

        columnChart: i18n.t("common:aggrid.columnChart"),
        groupedColumn: i18n.t("common:aggrid.groupedColumn"),
        stackedColumn: i18n.t("common:aggrid.stackedColumn"),
        normalizedColumn: i18n.t("common:aggrid.normalizedColumn"),

        barChart: i18n.t("common:aggrid.barChart"),
        groupedBar: i18n.t("common:aggrid.groupedBar"),
        stackedBar: i18n.t("common:aggrid.stackedBar"),
        normalizedBar: i18n.t("common:aggrid.normalizedBar"),

        pieChart: i18n.t("common:aggrid.pieChart"),
        pie: i18n.t("common:aggrid.pie"),
        doughnut: i18n.t("common:aggrid.doughnut"),

        line: i18n.t("common:aggrid.line"),

        xyChart: i18n.t("common:aggrid.xyChart"),
        scatter: i18n.t("common:aggrid.scatter"),
        bubble: i18n.t("common:aggrid.bubble"),

        areaChart: i18n.t("common:aggrid.areaChart"),
        area: i18n.t("common:aggrid.area"),
        stackedArea: i18n.t("common:aggrid.stackedArea"),
        normalizedArea: i18n.t("common:aggrid.normalizedArea"),

        histogramChart: i18n.t("common:aggrid.histogramChart"),

        // Charts
        pivotChartTitle: i18n.t("common:aggrid.pivotChartTitle"),
        rangeChartTitle: i18n.t("common:aggrid.rangeChartTitle"),
        settings: i18n.t("common:aggrid.settings"),
        data: i18n.t("common:aggrid.data"),
        format: i18n.t("common:aggrid.format"),
        categories: i18n.t("common:aggrid.categories"),
        defaultCategory: i18n.t("common:aggrid.defaultCategory"),
        series: i18n.t("common:aggrid.series"),
        xyValues: i18n.t("common:aggrid.xyValues"),
        paired: i18n.t("common:aggrid.paired"),
        axis: i18n.t("common:aggrid.axis"),
        navigator: i18n.t("common:aggrid.navigator"),
        color: i18n.t("common:aggrid.color"),
        thickness: i18n.t("common:aggrid.thickness"),
        xType: i18n.t("common:aggrid.xType"),
        automatic: i18n.t("common:aggrid.automatic"),
        category: i18n.t("common:aggrid.category"),
        number: i18n.t("common:aggrid.number"),
        time: i18n.t("common:aggrid.time"),
        xRotation: i18n.t("common:aggrid.xRotation"),
        yRotation: i18n.t("common:aggrid.yRotation"),
        ticks: i18n.t("common:aggrid.ticks"),
        width: i18n.t("common:aggrid.width"),
        height: i18n.t("common:aggrid.height"),
        length: i18n.t("common:aggrid.length"),
        padding: i18n.t("common:aggrid.padding"),
        spacing: i18n.t("common:aggrid.spacing"),
        chart: i18n.t("common:aggrid.chart"),
        title: i18n.t("common:aggrid.title"),
        titlePlaceholder: i18n.t("common:aggrid.titlePlaceholder"),
        background: i18n.t("common:aggrid.background"),
        font: i18n.t("common:aggrid.font"),
        top: i18n.t("common:aggrid.top"),
        right: i18n.t("common:aggrid.right"),
        bottom: i18n.t("common:aggrid.bottom"),
        left: i18n.t("common:aggrid.left"),
        labels: i18n.t("common:aggrid.labels"),
        size: i18n.t("common:aggrid.size"),
        minSize: i18n.t("common:aggrid.minSize"),
        maxSize: i18n.t("common:aggrid.maxSize"),
        legend: i18n.t("common:aggrid.legend"),
        position: i18n.t("common:aggrid.position"),
        markerSize: i18n.t("common:aggrid.markerSize"),
        markerStroke: i18n.t("common:aggrid.markerStroke"),
        markerPadding: i18n.t("common:aggrid.markerPadding"),
        itemSpacing: i18n.t("common:aggrid.itemSpacing"),
        itemPaddingX: i18n.t("common:aggrid.itemPaddingX"),
        itemPaddingY: i18n.t("common:aggrid.itemPaddingY"),
        layoutHorizontalSpacing: i18n.t(
            "common:aggrid.layoutHorizontalSpacing"
        ),
        layoutVerticalSpacing: i18n.t("common:aggrid.layoutVerticalSpacing"),
        strokeWidth: i18n.t("common:aggrid.strokeWidth"),
        offset: i18n.t("common:aggrid.offset"),
        offsets: i18n.t("common:aggrid.offsets"),
        tooltips: i18n.t("common:aggrid.tooltips"),
        callout: i18n.t("common:aggrid.callout"),
        markers: i18n.t("common:aggrid.markers"),
        shadow: i18n.t("common:aggrid.shadow"),
        blur: i18n.t("common:aggrid.blur"),
        xOffset: i18n.t("common:aggrid.xOffset"),
        yOffset: i18n.t("common:aggrid.yOffset"),
        lineWidth: i18n.t("common:aggrid.lineWidth"),
        normal: i18n.t("common:aggrid.normal"),
        bold: i18n.t("common:aggrid.bold"),
        italic: i18n.t("common:aggrid.italic"),
        boldItalic: i18n.t("common:aggrid.boldItalic"),
        predefined: i18n.t("common:aggrid.predefined"),
        fillOpacity: i18n.t("common:aggrid.fillOpacity"),
        strokeOpacity: i18n.t("common:aggrid.strokeOpacity"),
        histogramBinCount: i18n.t("common:aggrid.histogramBinCount"),
        columnGroup: i18n.t("common:aggrid.columnGroup"),
        barGroup: i18n.t("common:aggrid.barGroup"),
        pieGroup: i18n.t("common:aggrid.pieGroup"),
        lineGroup: i18n.t("common:aggrid.lineGroup"),
        scatterGroup: i18n.t("common:aggrid.scatterGroup"),
        areaGroup: i18n.t("common:aggrid.areaGroup"),
        histogramGroup: i18n.t("common:aggrid.histogramGroup"),
        groupedColumnTooltip: i18n.t("common:aggrid.groupedColumnTooltip"),
        stackedColumnTooltip: i18n.t("common:aggrid.stackedColumnTooltip"),
        normalizedColumnTooltip: i18n.t(
            "common:aggrid.normalizedColumnTooltip"
        ),
        groupedBarTooltip: i18n.t("common:aggrid.groupedBarTooltip"),
        stackedBarTooltip: i18n.t("common:aggrid.stackedBarTooltip"),
        normalizedBarTooltip: i18n.t("common:aggrid.normalizedBarTooltip"),
        pieTooltip: i18n.t("common:aggrid.pieTooltip"),
        doughnutTooltip: i18n.t("common:aggrid.doughnutTooltip"),
        lineTooltip: i18n.t("common:aggrid.lineTooltip"),
        groupedAreaTooltip: i18n.t("common:aggrid.groupedAreaTooltip"),
        stackedAreaTooltip: i18n.t("common:aggrid.stackedAreaTooltip"),
        normalizedAreaTooltip: i18n.t("common:aggrid.normalizedAreaTooltip"),
        scatterTooltip: i18n.t("common:aggrid.scatterTooltip"),
        bubbleTooltip: i18n.t("common:aggrid.bubbleTooltip"),
        histogramTooltip: i18n.t("common:aggrid.histogramTooltip"),
        noDataToChart: i18n.t("common:aggrid.noDataToChart"),
        pivotChartRequiresPivotMode: i18n.t(
            "common:aggrid.pivotChartRequiresPivotMode"
        ),
        chartSettingsToolbarTooltip: i18n.t(
            "common:aggrid.chartSettingsToolbarTooltip"
        ),
        chartLinkToolbarTooltip: i18n.t(
            "common:aggrid.chartLinkToolbarTooltip"
        ),
        chartUnlinkToolbarTooltip: i18n.t(
            "common:aggrid.chartUnlinkToolbarTooltip"
        ),
        chartDownloadToolbarTooltip: i18n.t(
            "common:aggrid.chartDownloadToolbarTooltip"
        ),

        // ARIA
        ariaHidden: i18n.t("common:aggrid.ariaHidden"),
        ariaVisible: i18n.t("common:aggrid.ariaVisible"),
        ariaChecked: i18n.t("common:aggrid.ariaChecked"),
        ariaUnchecked: i18n.t("common:aggrid.ariaUnchecked"),
        ariaIndeterminate: i18n.t("common:aggrid.ariaIndeterminate"),
        ariaColumnSelectAll: i18n.t("common:aggrid.ariaColumnSelectAll"),
        ariaInputEditor: i18n.t("common:aggrid.ariaInputEditor"),
        ariaDateFilterInput: i18n.t("common:aggrid.ariaDateFilterInput"),
        ariaFilterInput: i18n.t("common:aggrid.ariaFilterInput"),
        ariaFilterColumnsInput: i18n.t("common:aggrid.ariaFilterColumnsInput"),
        ariaFilterValue: i18n.t("common:aggrid.ariaFilterValue"),
        ariaFilterFromValue: i18n.t("common:aggrid.ariaFilterFromValue"),
        ariaFilterToValue: i18n.t("common:aggrid.ariaFilterToValue"),
        ariaFilteringOperator: i18n.t("common:aggrid.ariaFilteringOperator"),
        ariaColumnToggleVisibility: i18n.t(
            "common:aggrid.ariaColumnToggleVisibility"
        ),
        ariaColumnGroupToggleVisibility: i18n.t(
            "common:aggrid.ariaColumnGroupToggleVisibility"
        ),
        ariaRowSelect: i18n.t("common:aggrid.ariaRowSelect"),
        ariaRowDeselect: i18n.t("common:aggrid.ariaRowDeselect"),
        ariaRowToggleSelection: i18n.t("common:aggrid.ariaRowToggleSelection"),
        ariaRowSelectAll: i18n.t("common:aggrid.ariaRowSelectAll"),
        ariaSearch: i18n.t("common:aggrid.ariaSearch"),
        ariaSearchFilterValues: i18n.t("common:aggrid.ariaSearchFilterValues")
    };
};
