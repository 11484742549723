import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import SearchBar from "common/filter/components/SearchBar";
import type { TrackingComponentLabel } from "common/util/trackingEvents";

type FilterDropdownSearchBarWrapperProps = {
    hideBottomBorder: boolean;
    showBorder: boolean;
};

const FilterDropdownSearchBarWrapper = styled.div<FilterDropdownSearchBarWrapperProps>`
    width: 100%;
    input {
        padding-left: calc(1em + 10px + 8px);
        padding-right: calc(1em + 10px + 8px);
        box-sizing: border-box;
        height: 34px;
        width: 100%;
        font-size: inherit;
        font-family: inherit;
        border: none;
        ${props =>
            props.showBorder && `border: 1px solid ${props.theme.border};`}
        ${props =>
            !props.hideBottomBorder &&
            `border-bottom: 1px solid ${props.theme.border};`}
        border-radius: 0px;
        box-shadow: none;
        &:focus {
            outline: none;
        }
    }
`;

FilterDropdownSearchBarWrapper.displayName = "FilterDropdownSearchBarWrapper";

type FilterDropdownSearchBarProps = {
    changeHandler: (searchText: string) => void;
    hideBottomBorder?: boolean;
    searchText?: string;
    showBorder?: boolean;
    trackingComponentLabel: TrackingComponentLabel;
};

const FilterDropdownSearchBar = ({
    changeHandler,
    hideBottomBorder = false,
    searchText,
    showBorder = false,
    trackingComponentLabel
}: FilterDropdownSearchBarProps) => {
    const { t } = useTranslation();
    return (
        <FilterDropdownSearchBarWrapper
            hideBottomBorder={hideBottomBorder}
            showBorder={showBorder}
        >
            <SearchBar
                iconSize={16}
                cssWidth="100%"
                changeHandler={changeHandler}
                placeHolderText={t("common:filter.search")}
                searchText={searchText}
                trackingComponentLabel={trackingComponentLabel}
            />
        </FilterDropdownSearchBarWrapper>
    );
};

export default FilterDropdownSearchBar;
