import * as React from "react";
import { memo } from "react";
import type { ICellRendererParams } from "ag-grid-community";
import get from "lodash.get";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
    getAgDataGridTextTruncateNode,
    LARGE_ROW_HEIGHT
} from "common/components/grid/AgDataGridUtil";
import Flexbox from "common/components/styled/Flexbox";
import HierarchySubText from "common/components/styled/HierarchySubText";
import TooltipWrapper from "common/components/styled/TooltipWrapper";
import TruncatedDiv from "common/components/styled/TruncatedDiv";
import SvgIcon, { ClickableSvgIcon } from "common/components/SvgIcon";
import theme from "common/components/theme";
import Tooltip from "common/components/Tooltip";
import { ReactComponent as BarChartIcon } from "common/icons/BarChart4Bars.svg";
import { ReactComponent as CheckmarkCircleIcon } from "common/icons/CheckmarkCircle.svg";
import { ReactComponent as HelpIcon } from "common/icons/Help.svg";
import { ReactComponent as LineChartIcon } from "common/icons/LineChart.svg";
import { ReactComponent as ProgressRoundIcon } from "common/icons/ProgressRound.svg";
import { ReactComponent as UnknownIcon } from "common/icons/Unknown.svg";
import { ReactComponent as XCircleIcon } from "common/icons/XCircle.svg";
import ActivationIcon from "common/images/eversight-activation-icon.png";
import { commaJoinWithAnd } from "common/util/format";
import {
    EVENT_NAME_OPEN_CLOSE,
    EVENT_VALUE_OPEN_MODAL
} from "common/util/trackingEvents";
import {
    getAlertMessage,
    getAlertIcon,
    getChecklistIcon,
    METRIC_COLUMN_PREFIX,
    supportsCeiOverTime,
    supportsProgress
} from "campaign/components/campaignViewUtils";
import {
    ALERT_LEVEL_ERROR,
    ALERT_LEVEL_WARNING,
    CAMPAIGN_SCORECARD_CONSIDER,
    CAMPAIGN_SCORECARD_CONTROL,
    CAMPAIGN_SCORECARD_RECOMMENDED
} from "campaign/state/campaignStateUtils";
import HoverOfferImage from "offers/components/HoverOfferImage";

const ActivatedIcon = styled("img")`
    margin-right: 5px;
`;

ActivatedIcon.displayName = "ActivatedIcon";

const METRIC_RATING_BAD = "BAD";
const METRIC_RATING_GOOD = "GOOD";
const METRIC_RATING_UNKNOWN = "UNKNOWN";

export const AlertCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        const { t } = useTranslation();
        if (colDef && data) {
            const alertLevel = get(data, "alertLevelToShow.value");
            if (
                alertLevel === ALERT_LEVEL_ERROR ||
                alertLevel === ALERT_LEVEL_WARNING
            ) {
                const clickHandler = get(
                    colDef,
                    "cellRendererParams.clickHandler"
                );
                const rolledUpMessages = get(data, "rolledUpMessages", []);
                const alertCount = rolledUpMessages.length;
                const tooltip = getAlertMessage(alertLevel, alertCount, t);
                const icon = getAlertIcon(
                    alertLevel,
                    t,
                    clickHandler.bind(null, data)
                );
                // TO DO - on click of icon to open up alert message dialog
                return (
                    <Tooltip>
                        {icon}
                        <div>{tooltip}</div>
                    </Tooltip>
                );
            }
        }
        return null;
    }
);

export const ChecklistStatusCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { data } = params;
        if (data) {
            const checklistStatus = get(data, "checklistStatus.value");
            const tooltip = get(data, "checklistStatus.displayName");
            const icon = getChecklistIcon(checklistStatus);
            // TO DO - on click of icon to open up alert message dialog
            return (
                <Tooltip>
                    {icon}
                    <div>{tooltip}</div>
                </Tooltip>
            );
        }
        return null;
    }
);

export const CommaJoinCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        const { t } = useTranslation();
        if (colDef && data) {
            const itemsField = get(colDef, "cellRendererParams.itemsField");
            const textField = get(colDef, "cellRendererParams.textField");
            const items = get(data, itemsField, []);
            let processedItems = items;
            if (items.length > 0) {
                if (textField) {
                    processedItems = [];
                    items.forEach((item: any) => {
                        processedItems.push(item[textField]);
                    });
                }
                const text = commaJoinWithAnd(processedItems, t);
                if (items.length <= 1) {
                    return getAgDataGridTextTruncateNode(
                        text,
                        true,
                        "100%",
                        "left"
                    );
                } else {
                    return (
                        <Tooltip placement="left">
                            <TruncatedDiv>{text}</TruncatedDiv>
                            <TooltipWrapper maxWidth="540px">
                                {processedItems.map(
                                    (item: any, index: string) => {
                                        return <div key={index}>{item}</div>;
                                    }
                                )}
                            </TooltipWrapper>
                        </Tooltip>
                    );
                }
            }
        }
        return null;
    }
);

export const MultipleItemCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        const { t } = useTranslation();
        if (colDef && data) {
            const textField = get(colDef, "cellRendererParams.textField");
            const tooltipListField = get(
                colDef,
                "cellRendererParams.tooltipListField"
            );
            let text = get(data, textField, "");
            const tooltipList = get(data, tooltipListField, "");
            let tooltipItems = [];
            if (tooltipList) {
                tooltipItems = tooltipList.split("<br>");
            }
            tooltipItems.sort();
            if (tooltipItems.length > 0) {
                text = commaJoinWithAnd(tooltipItems, t);
            }
            if (tooltipItems.length <= 1) {
                return getAgDataGridTextTruncateNode(
                    text,
                    true,
                    "100%",
                    "left"
                );
            } else {
                return (
                    <Tooltip placement="left">
                        <div>{text}</div>
                        <TooltipWrapper maxWidth="350px">
                            {tooltipItems.map((item: any, index: string) => {
                                return <div key={index}>{item}</div>;
                            })}
                        </TooltipWrapper>
                    </Tooltip>
                );
            }
        }
        return null;
    }
);

export const AnalyticsCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        const { t } = useTranslation();
        if (colDef && data) {
            const clickHandler = get(colDef, "cellRendererParams.clickHandler");
            return (
                <Tooltip>
                    <ClickableSvgIcon
                        onClick={clickHandler.bind(null, data)}
                        color={theme.allocationBar2}
                        icon={BarChartIcon}
                        height="24px"
                        width="24px"
                        trackingComponentLabel="View Analytics"
                    />
                    <div>{t("campaign.view_analytics")}</div>
                </Tooltip>
            );
        }
        return null;
    }
);

export const ProgressChartCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        const { t } = useTranslation();
        if (colDef && data) {
            const supports = supportsProgress(data);
            if (supports) {
                const color = get(data, "confidenceColor");
                const clickHandler = get(
                    colDef,
                    "cellRendererParams.clickHandler"
                );
                return (
                    <Tooltip>
                        <ClickableSvgIcon
                            onClick={clickHandler.bind(null, data)}
                            color={color}
                            icon={ProgressRoundIcon}
                            height="24px"
                            width="24px"
                            trackingComponentLabel="View Campaign Progress"
                        />
                        <div>{t("campaign.view_campaign_progress")}</div>
                    </Tooltip>
                );
            }
        }
        return null;
    }
);

export const CeiChartCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        const { t } = useTranslation();
        if (colDef && data) {
            const supports = supportsCeiOverTime(data);
            if (supports) {
                const clickHandler = get(
                    colDef,
                    "cellRendererParams.clickHandler"
                );
                return (
                    <Tooltip>
                        <ClickableSvgIcon
                            color="grey"
                            cursor="pointer"
                            onClick={clickHandler.bind(null, data)}
                            height="24px"
                            icon={LineChartIcon}
                            trackingComponentLabel="Cei Chart"
                            trackingEventName={EVENT_NAME_OPEN_CLOSE}
                            trackingEventValue={EVENT_VALUE_OPEN_MODAL}
                            width="24px"
                        />
                        <div>
                            {t("campaign.view_consumer_engagement_over_time")}
                        </div>
                    </Tooltip>
                );
            }
        }
        return null;
    }
);

export const ScorecardOfferCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        const { t } = useTranslation();
        if (colDef && data) {
            const {
                offer_isControl: isControl,
                offer_offerLabelFormatHtml: offer,
                offer_THUMBNAILUrl: imageLink,
                groupName
            } = data;
            if (offer) {
                const offerImageClickHandler = get(
                    colDef,
                    "cellRendererParams.offerImageClickHandler",
                    true
                );

                const requiresImage = get(
                    colDef,
                    "cellRendererParams.requiresImage",
                    true
                );

                const offerLabelNode = getAgDataGridTextTruncateNode(offer);
                let control = null;
                if (
                    (groupName === CAMPAIGN_SCORECARD_CONSIDER ||
                        groupName === CAMPAIGN_SCORECARD_RECOMMENDED) &&
                    isControl
                ) {
                    control = (
                        <HierarchySubText>
                            {t("general.control")}
                        </HierarchySubText>
                    );
                }

                const activated = get(offer, "activated");
                let activatedIcon = null;
                if (activated) {
                    let activationTooltip = "";
                    const activatedCount = get(offer, "activatedCount");
                    const scheduledCount = get(offer, "activatedCount");

                    if (activatedCount) {
                        activationTooltip = t("promotion.activation_count", {
                            count: activatedCount
                        });
                    }
                    if (scheduledCount) {
                        if (activatedCount) {
                            activationTooltip += ", ";
                        }
                        activationTooltip += t(
                            "promotion.scheduled_activation_count",
                            { count: scheduledCount }
                        );
                    }
                    activatedIcon = (
                        <Tooltip placement="top">
                            <ActivatedIcon
                                alt={t("promotion.flag_alt_text")}
                                height="16px"
                                src={ActivationIcon}
                                width="16px"
                            />
                            {activationTooltip}
                        </Tooltip>
                    );
                }

                return (
                    <Flexbox alignItems="center">
                        <HoverOfferImage
                            image={imageLink}
                            offer={data}
                            onImageClick={offerImageClickHandler}
                            requiresImage={requiresImage}
                            rowHeight={LARGE_ROW_HEIGHT}
                        />
                        <Flexbox
                            flexDirection="column"
                            flexGrow={1}
                            minWidth="0"
                            justifyContent="center"
                        >
                            <Flexbox flexDirection="row" alignItems="center">
                                {activatedIcon} {offerLabelNode}
                            </Flexbox>
                            {control}
                        </Flexbox>
                    </Flexbox>
                );
            } else {
                const tooltipOfferText = get(
                    colDef,
                    "cellRendererParams.selectedControlOfferLabel"
                );
                let label = null;
                let tooltip = null;
                const offers = get(data, "offers", []);
                const numOffers = offers.length;
                switch (groupName) {
                    case CAMPAIGN_SCORECARD_CONSIDER:
                        label = t("general.other_x", { count: numOffers });
                        if (numOffers > 0) {
                            tooltip = t("general.other_tooltip");
                        }
                        break;
                    case CAMPAIGN_SCORECARD_CONTROL:
                        label = t("general.control");
                        break;
                    case CAMPAIGN_SCORECARD_RECOMMENDED:
                        label = t("general.recommended_x", {
                            count: numOffers
                        });
                        if (numOffers > 0) {
                            label = t("general.recommended_performance_x", {
                                count: numOffers
                            });
                            tooltip = t("general.recommended_tooltip", {
                                offer: tooltipOfferText
                            });
                        }
                        break;
                    default:
                        break;
                }
                let icon = null;
                if (tooltip) {
                    icon = (
                        <Tooltip
                            key={"section-tooltip-" + groupName}
                            placement="top"
                        >
                            <SvgIcon
                                icon={HelpIcon}
                                marginLeft="10px"
                                color={
                                    groupName === CAMPAIGN_SCORECARD_RECOMMENDED
                                        ? theme.background
                                        : theme.text
                                }
                            />
                            {tooltip}
                        </Tooltip>
                    );
                }
                return (
                    <Flexbox flexDirection="row">
                        {label}
                        {icon}
                    </Flexbox>
                );
            }
        }
        return null;
    }
);

export const ScorecardMetricCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        const { t } = useTranslation();
        if (colDef && data) {
            const colId = get(colDef, "colId");
            let value;
            let rating;
            // strip off "col"
            if (colId) {
                const index = parseInt(colId.replace(METRIC_COLUMN_PREFIX, ""));
                const metricValues = get(data, "metricValues", []);
                if (metricValues.length >= index + 1) {
                    value = metricValues[index].formattedValue;
                    rating = metricValues[index].rating.value;
                }

                if (value) {
                    let iconContent = null;
                    let icon;
                    let iconTooltip;
                    if (
                        [
                            METRIC_RATING_BAD,
                            METRIC_RATING_GOOD,
                            METRIC_RATING_UNKNOWN
                        ].includes(rating)
                    ) {
                        switch (rating) {
                            case METRIC_RATING_BAD:
                                icon = (
                                    <SvgIcon
                                        icon={XCircleIcon}
                                        marginRight="5px"
                                        color={theme.error}
                                    />
                                );
                                iconTooltip = t(
                                    "campaign.insight_metric_bad_tip"
                                );
                                break;
                            case METRIC_RATING_GOOD:
                                icon = (
                                    <SvgIcon
                                        icon={CheckmarkCircleIcon}
                                        marginRight="5px"
                                        color={theme.success}
                                    />
                                );
                                iconTooltip = t(
                                    "campaign.insight_metric_good_tip"
                                );
                                break;
                            case METRIC_RATING_UNKNOWN:
                                icon = (
                                    <SvgIcon
                                        icon={UnknownIcon}
                                        marginRight="5px"
                                        color={theme.btnDisabledTextColor}
                                    />
                                );
                                iconTooltip = t(
                                    "campaign.insight_metric_unknown_tip"
                                );
                                break;
                            default:
                                break;
                        }
                        iconContent = (
                            <Tooltip
                                key={`section-tooltip-rct-${nanoid(7)}`}
                                placement="top"
                            >
                                {icon}
                                {iconTooltip}
                            </Tooltip>
                        );
                    }
                    return (
                        <Flexbox flexDirection="row">
                            {iconContent}
                            {value}
                        </Flexbox>
                    );
                }
            }
        }
        return null;
    }
);
