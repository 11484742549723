export const MATRIX = "MATRIX" as string;
export const PRICE = "PRICE" as string;

export type AppType = typeof MATRIX | typeof PRICE;

const FULL_APP_WINDOW_NAME = "fullapp";

export const CAMPAIGN_DETAIL_OVERVIEW_PAGE =
    "Precipio.view.campaign.CampaignDetailOverviewView";
export const AUTOMATED_DESIGN_PAGE =
    "Precipio.view.concept.AutomatedDesignView";

const getPublicUrl = (): string => {
    return process.env.PUBLIC_URL || "";
};

export function completeUrl(url: string): string {
    const root: string = getPublicUrl();
    if (url.startsWith("/")) {
        return root + url;
    }
    return root + "/" + url;
}

export const downloadFile = (url: string) => {
    window.location.href = completeUrl(url);
};

export const isSecure = (): boolean => {
    return /^https/i.test(window.location.protocol);
};

export const openFullAppWindow = (url: string) => {
    openWindow(url, FULL_APP_WINDOW_NAME);
};

export const addUrlParameter = (url: string, key: string, value: string) => {
    if (url.indexOf("?") > -1) {
        return `${url}&${key}=${value}`;
    }
    return `${url}?${key}=${value}`;
};

export const openWindow = (url: string, windowName = "_self") => {
    window.open(url, windowName);
};
