import get from "lodash.get";
import type { AnyAction } from "redux";
import { ASCENDING } from "common/util/query";
import { CAMPAIGN_PRODUCT_GROUP_NAME } from "products/state/productStateUtils";
import {
    RECEIVE_CAMPAIGN_PRODUCT_GROUPS,
    REQUEST_CAMPAIGN_PRODUCT_GROUPS,
    UPDATE_CAMPAIGN_PRODUCT_GROUPS_SORT
} from "products/state/productsActions";

type ProductsState = {
    campaignProductGroups: {
        sortBy: string;
        sortOrder: SortOrder;
        totalCount: number;
    };
};

const defaultState: ProductsState = {
    campaignProductGroups: {
        sortBy: CAMPAIGN_PRODUCT_GROUP_NAME,
        sortOrder: ASCENDING,
        totalCount: 0
    }
};

function products(state: ProductsState = defaultState, action: AnyAction) {
    switch (action.type) {
        case REQUEST_CAMPAIGN_PRODUCT_GROUPS:
            return state;
        case RECEIVE_CAMPAIGN_PRODUCT_GROUPS:
            return Object.assign({}, state, {
                campaignProductGroups: {
                    ...state.campaignProductGroups,
                    totalCount: get(action, "json.totalCount")
                }
            });
        case UPDATE_CAMPAIGN_PRODUCT_GROUPS_SORT:
            return Object.assign({}, state, {
                campaignProductGroups: {
                    ...state.campaignProductGroups,
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder
                }
            });
        default:
            return state;
    }
}

export default products;
