import * as React from "react";
import { nanoid } from "nanoid";
import styled from "styled-components/macro";
import SvgIcon from "common/components/SvgIcon";
import { borderRadius } from "common/components/styled/util";
import theme from "common/components/theme";
import Tooltip from "common/components/Tooltip";
import { getTrackingEventData, trackEvent } from "common/util/tracking";
import { EVENT_NAME_TOGGLE_VIEW } from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";

export const TOGGLE_SIZE_SMALL = "sm" as string;
export const TOGGLE_SIZE_MEDIUM = "md" as string;

type TOGGLE_SIZE = typeof TOGGLE_SIZE_SMALL | typeof TOGGLE_SIZE_MEDIUM;

type ToggleButtonGroupContainerProps = {
    cssWidth?: string;
    inset?: boolean;
};

export const ToggleButtonGroupContainer = styled.div<ToggleButtonGroupContainerProps>`
    display: inline-block;
    white-space: nowrap;
    ${props => props.cssWidth && `width: ${props.cssWidth};`}

    ${props =>
        props.inset &&
        `
        width: 100%;
         & > ${ToggleButtonButton}:first-child {
            border-left: none;
            border-radius: 0px;
         }
         & > ${ToggleButtonButton}:last-child {
            border-radius: 0px;
            border-right: none;
        }
    `};
`;
ToggleButtonGroupContainer.displayName = "ToggleButtonGroupContainer";

type ToggleButtonButtonProps = {
    cssWidth?: string;
    size?: TOGGLE_SIZE;
};
const ToggleButtonButton = styled.button.attrs(props => ({
    type: "button"
}))<ToggleButtonButtonProps>`
    align-self: center;
    border-top: 1px solid ${theme.border};
    border-right: 1px solid ${theme.border};
    border-bottom: 1px solid ${theme.border};
    box-shadow: none;
    font-family: "BrandonTextRegular";

    margin: 0px;

    background-color: ${theme.background};
    color: ${theme.text};
    fill: ${theme.linkText};
    cursor: pointer;

    :first-child {
        border-left: 1px solid ${theme.border};
        border-radius: ${borderRadius("button")} 0 0 ${borderRadius("button")};
    }

    :last-child {
        border-radius: 0 ${borderRadius("button")} ${borderRadius("button")} 0;
    }

    :hover {
        background-color: ${theme.toggleBtnHover};
    }

    /*    
    :focus {
        outline: 0;
    }
    */

    & > span {
        vertical-align: middle;
    }

    font-size: ${props => (props.size === TOGGLE_SIZE_SMALL ? "12px" : "14px")};
    line-height: ${props =>
        props.size === TOGGLE_SIZE_SMALL ? "12px" : "14px"};
    padding: ${props =>
        props.size === TOGGLE_SIZE_SMALL
            ? "3px 6px 3px 6px"
            : "8px 12px 8px 12px"};
    ${props => props.cssWidth && `width: ${props.cssWidth};`}
`;
ToggleButtonButton.displayName = "ToggleButtonButton";

const ToggleButtonButtonSelected = styled(ToggleButtonButton)`
    background-color: ${theme.toggleButtonBackground};
    box-shadow: inset 0 1px 2px 0 ${theme.toggleButtonBoxShadow};
    cursor: auto;
    fill: ${theme.text};
`;
ToggleButtonButtonSelected.displayName = "ToggleButtonButtonSelected";

type ToggleButtonGroupPassedProps = {
    onClick: (
        value: string,
        event: React.SyntheticEvent<HTMLButtonElement>
    ) => void;
    selectedValue?: string;
    size?: string;
};

type ToggleButtonProps = {
    cssWidth?: string;
    icon?: IconType;
    label: string;
    toggleButton?: ToggleButtonGroupPassedProps;
    tooltip?: string;
    trackingComponentLabel?: TrackingComponentLabel;
    value: string;
};

export class ToggleButton extends React.Component<ToggleButtonProps> {
    static displayName = "ToggleButton";
    onClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        // Do not send selected option value for now as id/enum is not too useful for analytics
        // tracking and we do not want to accidentally send sensitive value information out
        trackEvent(
            getTrackingEventData(
                ToggleButton.displayName,
                this.props.trackingComponentLabel,
                EVENT_NAME_TOGGLE_VIEW
                //this.props.value
            )
        );
        this.props.toggleButton?.onClick?.(this.props.value, event);
    };

    render() {
        const { cssWidth, icon, label, toggleButton, tooltip, value } =
            this.props;

        const selectedValue = toggleButton?.selectedValue;
        const size = toggleButton?.size;

        let ToggleButtonComponent = ToggleButtonButton;

        let svgSize = "16px";
        if (size === TOGGLE_SIZE_SMALL) {
            svgSize = "12px";
        }
        if (selectedValue && selectedValue === value) {
            ToggleButtonComponent = ToggleButtonButtonSelected;
        }
        const ButtonContent = (
            <ToggleButtonComponent
                size={size}
                onClick={this.onClick}
                tabIndex={0}
                cssWidth={cssWidth}
            >
                {icon && (
                    <SvgIcon icon={icon} height={svgSize} width={svgSize} />
                )}
                <span>{label}</span>
            </ToggleButtonComponent>
        );
        if (tooltip) {
            const tooltipId = "toggle-button-tooltip-" + nanoid(7);
            return (
                <Tooltip id={tooltipId}>
                    {ButtonContent}
                    {tooltip}
                </Tooltip>
            );
        } else {
            return ButtonContent;
        }
    }
}

type ToggleButtonGroupProps = {
    changeHandler: (
        value: string,
        event: React.SyntheticEvent<HTMLButtonElement>
    ) => void;
    children: React.ReactNode;
    cssWidth?: string;
    inset?: boolean;
    name?: string;
    selectedValue?: string;
    size?: TOGGLE_SIZE;
    trackingComponentLabel: TrackingComponentLabel;
};

export class ToggleButtonGroup extends React.Component<ToggleButtonGroupProps> {
    static displayName = "ToggleButtonGroup";
    static defaultProps = {
        size: TOGGLE_SIZE_MEDIUM
    };

    render() {
        const {
            children,
            changeHandler,
            selectedValue,
            size,
            trackingComponentLabel,
            ...rest
        } = this.props;

        const childrenWithExtraProps = React.Children.map(
            this.props.children,
            child => {
                if (
                    React.isValidElement<ToggleButtonProps>(child) &&
                    child.type === ToggleButton
                ) {
                    return React.cloneElement(child, {
                        toggleButton: {
                            onClick: this.onToggleChange,
                            selectedValue: selectedValue,
                            size: size
                        },
                        trackingComponentLabel: trackingComponentLabel
                    });
                }
            }
        );
        return (
            <ToggleButtonGroupContainer {...rest}>
                {childrenWithExtraProps}
            </ToggleButtonGroupContainer>
        );
    }

    onToggleChange = (
        value: string,
        event: React.SyntheticEvent<HTMLButtonElement>
    ) => {
        if (typeof this.props.changeHandler === "function") {
            this.props.changeHandler(value, event);
        }
    };
}
