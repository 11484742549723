import type { TFunction } from "i18next";
import get from "lodash.get";
import type { AggregateOption } from "common/components/AgAggregationChooser";
import type { RootState } from "store";

export const AGGREGATE_CHANNEL = "channel";
export const AGGREGATE_DISCOUNT_RANGE = "discountRange";
export const AGGREGATE_EFFECTIVE_DISCOUNT_RANGE = "effectiveDiscountRange";
export const AGGREGATE_HOLIDAY = "holiday";
export const AGGREGATE_MANAGER = "manager";
export const AGGREGATE_OFFER_LABEL = "offerLabel";
export const AGGREGATE_PRODUCT_CATEGORY = "productCategory";
export const AGGREGATE_PRODUCT_GROUP = "productGroup";
export const AGGREGATE_PROMO_TYPE = "promoType";
export const AGGREGATE_REGION = "region";
export const AGGREGATE_RETAILER = "retailer";
export const AGGREGATE_WEEK = "week";

export const ALL_AGGREGATES = [
    AGGREGATE_CHANNEL,
    AGGREGATE_DISCOUNT_RANGE,
    AGGREGATE_EFFECTIVE_DISCOUNT_RANGE,
    AGGREGATE_HOLIDAY,
    AGGREGATE_MANAGER,
    AGGREGATE_OFFER_LABEL,
    AGGREGATE_PRODUCT_CATEGORY,
    AGGREGATE_PRODUCT_GROUP,
    AGGREGATE_PROMO_TYPE,
    AGGREGATE_REGION,
    AGGREGATE_RETAILER,
    AGGREGATE_WEEK
];

// Returns the base aggregate options
// Base does not include discount range, effective discount range, offer label
export const getBaseAggregateOptions = (
    state: RootState,
    t: TFunction
): AggregateOption[] => {
    const holidaysEnabled = get(state, "init.holidaysEnabled");
    const managersEnabled =
        get(state, "init.preloadedEntities.filters.managers", []).length > 0;
    const productsEnabled =
        get(state, "init.preloadedEntities.filters.products", []).length > 0;
    const regionsEnabled =
        get(state, "init.preloadedEntities.filters.regions", []).length > 0;
    const retailersEnabled =
        get(state, "init.preloadedEntities.filters.retailers", []).length > 0;
    const options = [];
    if (managersEnabled) {
        options.push({
            id: AGGREGATE_MANAGER,
            displayName: t("general.manager")
        });
    }
    if (retailersEnabled) {
        options.push({
            id: AGGREGATE_RETAILER,
            displayName: t("general.retailer")
        });
        options.push({
            id: AGGREGATE_CHANNEL,
            displayName: t("general.channel")
        });
    }
    if (productsEnabled) {
        options.push({
            id: AGGREGATE_PRODUCT_GROUP,
            displayName: t("common:general.ppg")
        });
        options.push({
            id: AGGREGATE_PRODUCT_CATEGORY,
            displayName: t("general.product_category")
        });
    }
    if (regionsEnabled) {
        options.push({
            id: AGGREGATE_REGION,
            displayName: t("general.region")
        });
    }
    if (holidaysEnabled) {
        options.push({
            id: AGGREGATE_HOLIDAY,
            displayName: t("general.holiday_slash_season")
        });
    }
    return options;
};
