import * as React from "react";
import isNil from "lodash.isnil";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import styled from "styled-components/macro";
import TooltipWrapper from "common/components/styled/TooltipWrapper";
import {
    borderRadius,
    hidePrintProps,
    marginProps
} from "common/components/styled/util";
import SvgIcon from "common/components/SvgIcon";
import theme from "common/components/theme";
import Tooltip from "common/components/Tooltip";
import { withClickTracking } from "common/components/withClickTracking";
import { isEmptyString } from "common/util/lang";
import { EVENT_NAME_EXECUTE_ACTION } from "common/util/trackingEvents";
import type { RootState } from "store";

type DefaultButtonProps = {
    fontSize?: string;
    hasText?: boolean;
} & ButtonProps;

const DefaultButtonWrapper = styled.button<DefaultButtonProps>`
    color: ${props =>
        props.disabled
            ? theme.btnDisabledTextColor
            : theme.btnPrimaryTextColor};
    ${props =>
        !props.disabled && `text-shadow: 0 1px 1px ${theme.lightTextShadow};`}
    font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
    font-family: "BrandonTextRegular";
    ${props => props.cssWidth && `width: ${props.cssWidth};`}
    ${props => props.cssHeight && `height: ${props.cssHeight};`}
    padding: ${props => (props.padding ? props.padding : "7px 12px")};
    ${props =>
        !props.suppressBorderRadius &&
        `
        border-radius: ${
            props.insetInInput ? "0px" : borderRadius("button")(props)
        };
        border-top-right-radius: ${borderRadius("button")(props)};
        border-bottom-right-radius: ${borderRadius("button")(props)};    
    `}
    background-color: ${props =>
        props.disabled ? theme.btnDisabledBg : theme.btnPrimaryBg};
    ${props =>
        !props.disabled &&
        `background: linear-gradient(
        ${theme.btnPrimaryBgGradient},
        ${theme.btnPrimaryBg}
    );`}
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    ${marginProps}
    ${hidePrintProps}
    ${props =>
        !props.disabled &&
        `
    :hover {
        background-color: ${theme.btnPrimaryActiveBg};
        background: linear-gradient(
            ${theme.btnPrimaryActiveBgGradient},
            ${theme.btnPrimaryActiveBg}
        );
        outline: none;
        box-shadow: 0 1px 2px 0 ${theme.toggleButtonBoxShadow};
    }

    :active {
        background-color: ${theme.btnPrimaryActiveBg};
        background: linear-gradient(
            ${theme.btnPrimaryActiveBgGradient},
            ${theme.btnPrimaryActiveBg}
        );
        outline: none;
    }`}
    
    ${props => props.hasText && !props.noMinWidth && "min-width: 112px;"}
    ${props => !props.focus && `:focus { outline: none; }`};
`;

DefaultButtonWrapper.displayName = "DefaultButtonWrapper";

const SecondaryButtonWrapper = styled(DefaultButtonWrapper)`
    color: ${props =>
        props.disabled
            ? theme.btnDisabledTextColor
            : props.color
            ? props.color
            : theme.linkText};
    border: 1px solid
        ${props =>
            props.borderColor
                ? props.borderColor
                : theme.btnSecondaryBorderColor};
    text-shadow: none;
    background: none;
    background-color: ${props =>
        props.disabled ? theme.btnDisabledBg : theme.btnSecondaryBg};
    padding: ${props => (props.padding ? props.padding : "6px 12px")};

    path: {
        fill: ${props =>
            props.disabled ? theme.btnDisabledTextColor : theme.linkText};
    }

    ${props =>
        !props.disabled &&
        `
    :hover {
        background: none;
        background-color: ${theme.btnSecondaryBg};
        border: 1px solid ${theme.btnSecondaryBorderHoverColor};
        outline: none;        
        box-shadow: 0 1px 2px 0 ${theme.btnSecondaryBoxShadowHover};
    }

    :active {
        background: none;
        background-color: ${theme.btnSecondaryBg};
        border: 1px solid ${theme.btnSecondaryBorderHoverColor};
        outline: none;
        box-shadow: inset 0 1px 3px 0
            ${theme.btnSecondaryBoxShadowActive};
    }`};

    :hover path,
    :active path {
        fill: ${props =>
            props.disabled
                ? theme.btnDisabledTextColor
                : props.hoverColor
                ? props.hoverColor
                : theme.btnSecondaryBg};
    }
    ${props => !props.focus && `:focus { outline: none; }`}
`;

SecondaryButtonWrapper.displayName = "SecondaryButtonWrapper";

const SecondaryEmphasisButtonWrapper = styled(DefaultButtonWrapper)`
    color: ${props =>
        props.disabled ? theme.btnDisabledTextColor : theme.text};
    text-shadow: none;
    ${props =>
        !props.disabled &&
        `border: 1px solid
        ${theme.btnSecondaryEmphasisBorderColor}
    `};
    ${props => !props.disabled && `padding: 6px 12px;`}
    background-color: ${props =>
        props.disabled ? theme.btnDisabledBg : theme.btnSecondaryEmphasisBg};
    ${props =>
        !props.disabled &&
        `background: linear-gradient(
        ${theme.btnSecondaryEmphasisBgGradient},
        ${theme.btnSecondaryEmphasisBg}
    )`};
    ${props =>
        !props.disabled &&
        `
    :hover {
        background-color: ${theme.btnSecondaryEmphasisActiveBg};
        background: linear-gradient(
            ${theme.btnSecondaryEmphasisActiveBgGradient},
            ${theme.btnSecondaryEmphasisActiveBg}
        );
        box-shadow: 0 1px 2px 0 ${theme.btnSecondaryEmphasisBoxShadowHover};
        outline: none;
        border: 1px solid ${theme.btnSecondaryEmphasisBorderHoverColor};
    }

    :active {
        background-color: ${theme.btnSecondaryEmphasisActiveBg};
        background: linear-gradient(
            ${theme.btnSecondaryEmphasisActiveBgGradient},
            ${theme.btnSecondaryEmphasisActiveBg}
        );
        outline: none;
        border: 1px solid
            ${theme.btnSecondaryEmphasisBorderHoverColor};
    }`};
`;

SecondaryEmphasisButtonWrapper.displayName = "SecondaryEmphasisButtonWrapper";

const TertiaryButtonWrapper = styled(DefaultButtonWrapper)`
    color: ${props =>
        props.disabled ? theme.btnDisabledTextColor : theme.text};
    border: 1px solid
        ${props =>
            props.borderColor
                ? props.borderColor
                : theme.btnSecondaryBorderColor};
    text-shadow: none;
    background: none;
    background-color: ${props =>
        props.disabled ? theme.btnDisabledBg : theme.background};
    padding: 6px 12px;

    path: {
        fill: ${props =>
            props.disabled ? theme.btnDisabledTextColor : theme.text};
    }

    ${props =>
        !props.disabled &&
        `
    :hover {
        background: none;
        background-color: ${theme.btnSecondaryBg};
        border: 1px solid ${
            props.borderColor
                ? props.borderColor
                : theme.btnSecondaryBorderHoverColor
        };
        outline: none;        
        box-shadow: 0 1px 2px 0 ${theme.btnSecondaryBoxShadowHover};
    }

    :active {
        background: none;
        background-color: ${theme.btnSecondaryBg};
        border: 1px solid ${theme.btnSecondaryBorderHoverColor};
        outline: none;
        box-shadow: inset 0 1px 3px 0
            ${theme.btnSecondaryBoxShadowActive};
    }`};

    ${props => !props.focus && `:focus { outline: none; }`};
    :hover path,
    :active path {
        fill: ${props =>
            props.disabled ? theme.btnDisabledTextColor : theme.text};
    }
`;

TertiaryButtonWrapper.displayName = "TertiaryButtonWrapper";

const AlertButtonWrapper = styled(DefaultButtonWrapper)`
    background-color: ${props =>
        props.disabled ? theme.btnDisabledBg : theme.btnAlertBg};
    ${props =>
        !props.disabled &&
        `background: linear-gradient(
            ${theme.btnAlertBg},
            ${theme.btnAlertBgGradient}
        )`};
    ${props =>
        !props.disabled &&
        `
    :hover {
        background-color: ${theme.btnAlertActiveBg};
        background: linear-gradient(
            ${theme.btnAlertActiveBgGradient},
            ${theme.btnAlertActiveBg}
        );
    }

    :active {
        background-color: ${theme.btnAlertActiveBg};
        background: linear-gradient(
            ${theme.btnAlertActiveBg},
            ${theme.btnAlertActiveBgGradient}
        );
    }`};
`;

AlertButtonWrapper.displayName = "AlertButtonWrapper";

const SuccessButtonWrapper = styled(DefaultButtonWrapper)`
    background-color: ${props =>
        props.disabled ? theme.btnDisabledBg : theme.btnSuccessBg};
    ${props =>
        !props.disabled &&
        `background: linear-gradient(
            ${theme.btnSuccessBg},
            ${theme.btnSuccessBgGradient}
        )`};
    ${props =>
        !props.disabled &&
        `
    :hover {
        background-color: ${theme.btnSuccessActiveBg};
        background: linear-gradient(
            ${theme.btnSuccessActiveBgGradient},
            ${theme.btnSuccessActiveBg}
        );
    }

    :active {
        background-color: ${theme.btnSuccessActiveBg};
        background: linear-gradient(
            ${theme.btnSuccessActiveBg},
            ${theme.btnSuccessActiveBgGradient}
        );
    }`};
`;

SuccessButtonWrapper.displayName = "SuccessButtonWrapper";

const WarningButtonWrapper = styled(DefaultButtonWrapper)`
    background-color: ${props =>
        props.disabled ? theme.btnDisabledBg : theme.btnWarningBg};
    ${props =>
        !props.disabled &&
        `background: linear-gradient(
            ${theme.btnWarningBgGradient},
            ${theme.btnWarningBg}
        )`};
    ${props =>
        !props.disabled &&
        `
    :hover {
        background-color: ${theme.btnWarningActiveBg};
        background: linear-gradient(
            ${theme.btnWarningActiveBgGradient},
            ${theme.btnWarningActiveBg}
        );
    }`};

    :active {
        background-color: ${theme.btnWarningActiveBg};
        background: linear-gradient(
            ${theme.btnWarningActiveBg},
            ${theme.btnWarningActiveBgGradient}
        );
    }
`;

WarningButtonWrapper.displayName = "WarningButtonWrapper";

const SplitButtonLeftWrapper = styled(DefaultButtonWrapper)`
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 1px solid white;
`;

SplitButtonLeftWrapper.displayName = "SplitButtonLeftWrapper";

const SplitButtonRightWrapper = styled(DefaultButtonWrapper)`
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
`;

SplitButtonRightWrapper.displayName = "SplitButtonRightWrapper";

const ContextMenuButtonWrapper = styled(DefaultButtonWrapper)`
    background: transparent;
    :focus {
        outline: none;
    }
    :hover path,
    :active path {
        fill: ${theme.lightText};
    }
`;

ContextMenuButtonWrapper.displayName = "ContextMenuButtonWrapper";

const ClickableIconButtonWrapper = styled(DefaultButtonWrapper)`
    background: transparent;

    :hover {
        background: none;
        background-color: transparent;
        border: none;
        outline: none;        
        box-shadow: none;
    }

    :active {
        background: none;
        background-color: transparent;
        border: none;
        outline: none;        
        box-shadow: none;
    }

    :focus {
        outline: none;
    }
    :hover path
`;

ClickableIconButtonWrapper.displayName = "IconButtonWrapper";

const TextAndIcon = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
`;

TextAndIcon.displayName = "TextAndIcon";

const Text = styled.div`
    display: inline-block;
    white-space: nowrap;
`;

Text.displayName = "Text";

export const ICON_LEFT = "left";
export const ICON_RIGHT = "right";

const ICON_MARGIN = "7px";

export const BUTTON_TYPE_ALERT = "BUTTON_TYPE_ALERT" as string;
export const BUTTON_TYPE_CLICKABLE_ICON =
    "BUTTON_TYPE_CLICKABLE_ICON" as string;
export const BUTTON_TYPE_CONTEXT_MENU = "BUTTON_TYPE_CONTEXT_MENU" as string;
export const BUTTON_TYPE_PRIMARY = "BUTTON_TYPE_PRIMARY" as string;
export const BUTTON_TYPE_SECONDARY = "BUTTON_TYPE_SECONDARY" as string;
export const BUTTON_TYPE_SECONDARY_EMPHASIS =
    "BUTTON_TYPE_SECONDARY_EMPHASIS" as string;
export const BUTTON_TYPE_SPLIT_LEFT = "BUTTON_TYPE_SPLIT_LEFT" as string;
export const BUTTON_TYPE_SPLIT_RIGHT = "BUTTON_TYPE_SPLIT_RIGHT" as string;
export const BUTTON_TYPE_SUCCESS = "BUTTON_TYPE_SUCCESS" as string;
export const BUTTON_TYPE_TERTIARY = "BUTTON_TYPE_TERTIARY" as string;
export const BUTTON_TYPE_WARNING = "BUTTON_TYPE_WARNING" as string;

export type IconPosition = typeof ICON_LEFT | typeof ICON_RIGHT;

export type ButtonType =
    | typeof BUTTON_TYPE_ALERT
    | typeof BUTTON_TYPE_CLICKABLE_ICON
    | typeof BUTTON_TYPE_CONTEXT_MENU
    | typeof BUTTON_TYPE_PRIMARY
    | typeof BUTTON_TYPE_SECONDARY
    | typeof BUTTON_TYPE_SECONDARY_EMPHASIS
    | typeof BUTTON_TYPE_SPLIT_LEFT
    | typeof BUTTON_TYPE_SPLIT_RIGHT
    | typeof BUTTON_TYPE_SUCCESS
    | typeof BUTTON_TYPE_TERTIARY
    | typeof BUTTON_TYPE_WARNING;

type ButtonProps = {
    allowableAction?: string;
    borderColor?: string;
    color?: string;
    customStyle?: React.ReactNode;
    disabled?: boolean;
    disabledTooltip?: string;
    focus?: boolean;
    hoverColor?: string;
    icon?: IconType;
    iconHeight?: string;
    iconPosition?: IconPosition;
    iconWidth?: string;
    insetInInput?: boolean;
    noMinWidth?: boolean;
    onClick?: ButtonClickHandler;
    submit?: boolean;
    suppressBorderRadius?: boolean;
    tabIndex?: number;
    text?: string;
    type?: ButtonType;
} & marginPropsType &
    sizePropsType &
    paddingPropsType;

type ButtonOwnProps = ButtonProps & PropsFromRedux;

const Button = (props: ButtonOwnProps) => {
    const {
        allowableAction,
        allowableActions,
        customStyle,
        disabled = false,
        disabledTooltip,
        focus = true,
        icon,
        iconHeight,
        iconPosition = ICON_LEFT,
        iconWidth,
        insetInInput = false,
        tabIndex,
        text,
        submit = false,
        type = BUTTON_TYPE_PRIMARY,
        ...rest
    } = props;

    // Don't show button if allowableAction isn't true
    let isAllowed = true;
    if (!isNil(allowableAction) && !isEmptyString(allowableAction)) {
        isAllowed = allowableActions[allowableAction] ?? false;
    }
    if (!isAllowed) {
        return null;
    }

    let ButtonContent: React.ReactNode = text;
    let tIndex = 0;
    if (props.disabled) {
        tIndex = -1;
    } else if (tabIndex) {
        tIndex = tabIndex;
    }
    if (icon) {
        let iconColor = theme.lightText;
        if (type === BUTTON_TYPE_SECONDARY) {
            iconColor = props.color ? props.color : theme.linkText;
        }
        if (type === BUTTON_TYPE_TERTIARY) {
            iconColor = theme.text;
        }
        if (type === BUTTON_TYPE_CONTEXT_MENU) {
            iconColor = theme.iconMenu;
        }
        if (type === BUTTON_TYPE_CLICKABLE_ICON) {
            iconColor = props.color ? props.color : theme.linkText;
        }
        if (props.disabled) {
            iconColor = theme.btnDisabledTextColor;
        }
        let margin = "0px";
        if (text) {
            margin = ICON_MARGIN;
        }
        if (iconPosition === ICON_LEFT) {
            ButtonContent = (
                <TextAndIcon>
                    <SvgIcon
                        color={iconColor}
                        height={iconHeight}
                        icon={icon}
                        marginRight={margin}
                        width={iconWidth}
                        zIndex={0}
                    />
                    <Text>{text}</Text>
                </TextAndIcon>
            );
        } else {
            ButtonContent = (
                <TextAndIcon>
                    <Text>{text}</Text>
                    <SvgIcon
                        color={iconColor}
                        height={iconHeight}
                        icon={icon}
                        marginLeft={margin}
                        width={iconWidth}
                        zIndex={0}
                    />
                </TextAndIcon>
            );
        }
    }
    if (customStyle) {
        ButtonContent = customStyle;
    }

    let ButtonWrapper;
    switch (type) {
        case BUTTON_TYPE_ALERT:
            ButtonWrapper = AlertButtonWrapper;
            break;
        case BUTTON_TYPE_CLICKABLE_ICON:
            ButtonWrapper = ClickableIconButtonWrapper;
            break;
        case BUTTON_TYPE_CONTEXT_MENU:
            ButtonWrapper = ContextMenuButtonWrapper;
            break;
        case BUTTON_TYPE_SECONDARY:
            ButtonWrapper = SecondaryButtonWrapper;
            break;
        case BUTTON_TYPE_SECONDARY_EMPHASIS:
            ButtonWrapper = SecondaryEmphasisButtonWrapper;
            break;
        case BUTTON_TYPE_SPLIT_LEFT:
            ButtonWrapper = SplitButtonLeftWrapper;
            break;
        case BUTTON_TYPE_SPLIT_RIGHT:
            ButtonWrapper = SplitButtonRightWrapper;
            break;
        case BUTTON_TYPE_TERTIARY:
            ButtonWrapper = TertiaryButtonWrapper;
            break;
        case BUTTON_TYPE_SUCCESS:
            ButtonWrapper = SuccessButtonWrapper;
            break;
        case BUTTON_TYPE_WARNING:
            ButtonWrapper = WarningButtonWrapper;
            break;
        default:
            ButtonWrapper = DefaultButtonWrapper;
            break;
    }

    const ButtonComponent = (
        <ButtonWrapper
            {...rest}
            disabled={disabled}
            focus={focus}
            insetInInput={insetInInput}
            tabIndex={tIndex}
            type={submit ? "submit" : "button"}
            hasText={!!text}
        >
            {ButtonContent}
        </ButtonWrapper>
    );

    if (
        props.disabled &&
        !isNil(disabledTooltip) &&
        !isEmptyString(disabledTooltip)
    ) {
        return (
            <Tooltip>
                <div>{ButtonComponent}</div>
                <TooltipWrapper>{disabledTooltip}</TooltipWrapper>
            </Tooltip>
        );
    }
    return ButtonComponent;
};
Button.displayName = "Button";

const mapStateToProps = (state: RootState) => {
    return {
        allowableActions: state.init.allowableActions
    };
};

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const UntrackedButton = connector(Button);

export default connector(
    withClickTracking(Button, Button.displayName, EVENT_NAME_EXECUTE_ACTION)
);
