import * as React from "react";
import type { ColDef } from "ag-grid-community";
import isEqual from "lodash.isequal";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Button, { BUTTON_TYPE_SECONDARY } from "common/components/Button";
import { CheckboxGroup, Checkbox } from "common/components/CheckboxGroup";
import { COLUMN_TYPE_AGGREGATION } from "common/components/grid/AgDataGridUtil";
import Modal, {
    ModalButtonRow,
    ModalCheckBoxGroupWrapper,
    ModalMainContent,
    ModalTitleRow,
    ModalWrapper
} from "common/components/Modal";
import HorizontalSpacer from "common/components/styled/HorizontalSpacer";
import Flexbox from "common/components/styled/Flexbox";
import Header from "common/components/styled/Header";
import theme from "common/components/theme";
import { FilterDropdownScrollableContent } from "common/filter/components/FilterDropdownView";
import checkboxDashSelectedIcon from "common/images/checkbox_dash_selected.png";
import checkboxSelectedIcon from "common/images/checkbox_selected.png";
import checkboxUnselectedIcon from "common/images/checkbox_unselected.png";
import { composeTrackingComponentLabel } from "common/util/tracking";
import { EVENT_NAME_CANCEL_ACTION } from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";

const SelectAllWrapper = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;

    input {
        display: none;
    }
`;
SelectAllWrapper.displayName = "SelectAllWrapper";

const SelectAll = styled.img`
    height: 18px;
    user-select: none;
    width: 18px;
`;
SelectAll.displayName = "SelectAll";

export type ExportOption = {
    disabled: boolean;
    label: string;
    value: string;
};

export type ExportOptionsObject = {
    exportOptions: ExportOption[];
    exportOptionsChecked: string[];
};

export const getExportColumns = (
    columnDefinitions: ColDef[],
    aggregateColumnsCheckedDisabled = true
): ExportOption[] => {
    const options: ExportOption[] = [];
    columnDefinitions.forEach(column => {
        let disabled = false;
        if (
            aggregateColumnsCheckedDisabled &&
            column.type === COLUMN_TYPE_AGGREGATION
        ) {
            disabled = true;
        }
        options.push({
            disabled: disabled,
            label: column.headerName ? column.headerName : "",
            value: column.colId ? column.colId : ""
        });
    });
    return options;
};

type ExportOptionSelectorOwnProps = {
    closeModalHandler: NoArgsHandler;
    checkForResetOfCheckedOptions?: boolean;
    columnHeader?: string;
    customControls?: React.ReactNode;
    exportHandler: AnyFunction;
    exportName: string;
    exportOptions: ExportOption[];
    exportOptionsChecked?: string[];
    isOpen: boolean;
    subText?: string;
    trackingComponentLabel: TrackingComponentLabel;
};

type ExportOptionSelectorProps = ExportOptionSelectorOwnProps & WithTranslation;

type ExportOptionSelectorState = {
    optionsChecked: string[];
};

class ExportOptionSelector extends React.Component<
    ExportOptionSelectorProps,
    ExportOptionSelectorState
> {
    static displayName = "ExportOptionSelector";

    state: ExportOptionSelectorState = {
        optionsChecked: []
    };

    componentDidMount() {
        const { exportOptionsChecked } = this.props;
        if (exportOptionsChecked) {
            this.setState({ optionsChecked: exportOptionsChecked });
        }
    }

    componentDidUpdate(prevProps: ExportOptionSelectorProps) {
        const {
            checkForResetOfCheckedOptions = false,
            exportOptionsChecked,
            isOpen
        } = this.props;
        const { optionsChecked } = this.state;
        // Update the checked selection when modal is closed on column chooser changes
        if (
            !isOpen &&
            !isEqual(optionsChecked, exportOptionsChecked) &&
            exportOptionsChecked
        ) {
            this.setState({ optionsChecked: exportOptionsChecked });
        }
        if (
            isOpen &&
            !isEqual(prevProps.exportOptionsChecked, exportOptionsChecked) &&
            exportOptionsChecked &&
            checkForResetOfCheckedOptions
        ) {
            this.setState({ optionsChecked: exportOptionsChecked });
        }
    }

    onCheck = (checked: string[]) => {
        this.setState({ optionsChecked: checked });
    };

    isValid = (): boolean => {
        const { optionsChecked } = this.state;
        return optionsChecked.length > 0;
    };

    // Use to reorder the checked options to be in dropdown order
    orderOptionsChecked = (checked: string[]): string[] => {
        const { exportOptions } = this.props;
        const orderedCheck: string[] = [];
        exportOptions.forEach((option: ExportOption) => {
            const found = checked.find((check: string) => {
                return check === option.value;
            });
            if (found) {
                orderedCheck.push(found);
            }
        });
        return orderedCheck;
    };

    onSelectAll = () => {
        const { exportOptions } = this.props;
        const { optionsChecked } = this.state;
        // All selected -> unselect all (except for disabled options, keep those in same state)
        // Partial select or non select -> select all
        if (exportOptions.length === optionsChecked.length) {
            const checked: string[] = [];
            exportOptions.forEach((option: ExportOption) => {
                if (
                    option.disabled &&
                    optionsChecked.includes(option.value) &&
                    option.value
                ) {
                    checked.push(option.value);
                }
            });
            this.setState({ optionsChecked: checked });
        } else {
            this.setState({
                optionsChecked: exportOptions.map(option => option.value)
            });
        }
    };

    onSubmit = () => {
        const { optionsChecked } = this.state;
        const orderedOptionsChecked = this.orderOptionsChecked(optionsChecked);
        this.props.exportHandler(orderedOptionsChecked);
    };

    render() {
        const {
            closeModalHandler,
            columnHeader,
            customControls = null,
            exportName,
            exportOptions,
            isOpen,
            subText,
            t,
            trackingComponentLabel
        } = this.props;
        const { optionsChecked } = this.state;
        const isSelected = optionsChecked.length > 0;
        const selectAllIcon = isSelected
            ? optionsChecked.length === exportOptions.length
                ? checkboxSelectedIcon
                : checkboxDashSelectedIcon
            : checkboxUnselectedIcon;
        const headerText = columnHeader
            ? columnHeader
            : t("common:aggrid.columns");
        const content = (
            <ModalWrapper cssHeight="100%" cssWidth="600px">
                <ModalTitleRow
                    closeHandler={closeModalHandler}
                    subTitle={subText}
                    title={t("common:general.export") + " " + exportName}
                    trackingComponentLabel={composeTrackingComponentLabel([
                        trackingComponentLabel,
                        ExportOptionSelector.displayName
                    ])}
                />
                <ModalMainContent>
                    {customControls}
                    <Flexbox
                        backgroundColor={theme.dataGridHeaderColumnBg}
                        border={`1px solid ${theme.border}`}
                        borderBottom="none"
                        padding="8px 0px 8px 26px"
                    >
                        <SelectAllWrapper onClick={this.onSelectAll}>
                            <input
                                checked={isSelected}
                                readOnly={true}
                                type="checkbox"
                            />
                            <SelectAll src={selectAllIcon} />
                        </SelectAllWrapper>
                        <Header fontSize="16px" marginLeft="10px">
                            {headerText}
                        </Header>
                    </Flexbox>
                    <ModalCheckBoxGroupWrapper>
                        <FilterDropdownScrollableContent cssHeight="240px">
                            <CheckboxGroup
                                name="exportOptions"
                                selectedValues={optionsChecked}
                                changeHandler={this.onCheck}
                            >
                                {exportOptions.map(option => (
                                    <Checkbox
                                        disabled={option.disabled}
                                        label={option.label}
                                        key={option.value}
                                        value={option.value}
                                    />
                                ))}
                            </CheckboxGroup>
                        </FilterDropdownScrollableContent>
                    </ModalCheckBoxGroupWrapper>
                </ModalMainContent>
                <ModalButtonRow>
                    <Button
                        cssWidth="130px"
                        onClick={closeModalHandler}
                        text={t("common:general.cancel")}
                        trackingComponentLabel={composeTrackingComponentLabel([
                            trackingComponentLabel,
                            ExportOptionSelector.displayName,
                            "Cancel"
                        ])}
                        trackingEventName={EVENT_NAME_CANCEL_ACTION}
                        type={BUTTON_TYPE_SECONDARY}
                    />
                    <HorizontalSpacer cssWidth="8px" />
                    <Button
                        cssWidth="130px"
                        disabled={!this.isValid()}
                        onClick={this.onSubmit}
                        text={t("common:general.export_x", {
                            count: optionsChecked.length
                        })}
                        trackingComponentLabel={composeTrackingComponentLabel([
                            trackingComponentLabel,
                            ExportOptionSelector.displayName,
                            "Export"
                        ])}
                    />
                </ModalButtonRow>
            </ModalWrapper>
        );
        return (
            <Modal
                closeOnExternalClick={false}
                closeHandler={closeModalHandler}
                isOpen={isOpen}
                trackingComponentLabel={composeTrackingComponentLabel([
                    trackingComponentLabel,
                    ExportOptionSelector.displayName
                ])}
            >
                {content}
            </Modal>
        );
    }
}

ExportOptionSelector.displayName = "ExportOptionSelector";

export default withTranslation()(ExportOptionSelector);
