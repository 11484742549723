import get from "lodash.get";
import type { AnyAction } from "redux";
import { ASCENDING, DEFAULT_PAGE_SIZE } from "common/util/query";
import { PROMOTION_NAME } from "promotions/state/promotionStateUtils";
import {
    RECEIVE_CAMPAIGN_OFFERS,
    RECEIVE_CONTROL_OFFERS,
    RECEIVE_PROMOTIONS,
    REQUEST_CAMPAIGN_OFFERS,
    REQUEST_CONTROL_OFFERS,
    REQUEST_PROMOTIONS,
    UPDATE_CAMPAIGN_OFFERS_SORT,
    UPDATE_CONTROL_OFFERS_OFFSET,
    UPDATE_CONTROL_OFFERS_SORT,
    UPDATE_PROMOTIONS_CONTROL_OFFERS_ONLY,
    UPDATE_PROMOTIONS_LAYOUT_ISSUES_ONLY,
    UPDATE_PROMOTIONS_SEARCH,
    UPDATE_PROMOTIONS_SORT
} from "promotions/state/promotionsActions";

const defaultState: PromotionsState = {
    offers: {
        filters: {
            controlOffersOnly: false,
            layoutIssuesOnly: false,
            search: ""
        },
        sortBy: PROMOTION_NAME,
        sortOrder: ASCENDING,
        totalCount: -1
    },
    campaignOffers: {
        sortBy: PROMOTION_NAME,
        sortOrder: ASCENDING,
        totalCount: -1
    },
    controlOffers: {
        offset: 0,
        pageSize: DEFAULT_PAGE_SIZE,
        sortBy: "promotion.name",
        sortOrder: ASCENDING,
        totalCount: -1
    }
};

type PromotionsState = {
    offers: {
        filters: {
            controlOffersOnly: boolean;
            layoutIssuesOnly: boolean;
            search: string;
        };
    } & Pick<ListQueryState, "sortBy" | "sortOrder" | "totalCount">;
    campaignOffers: Pick<ListQueryState, "sortBy" | "sortOrder" | "totalCount">;
    controlOffers: ListQueryState;
};

function promotions(state: PromotionsState = defaultState, action: AnyAction) {
    switch (action.type) {
        case RECEIVE_CAMPAIGN_OFFERS:
            return Object.assign({}, state, {
                campaignOffers: {
                    ...state.campaignOffers,
                    totalCount: get(action, "json.totalCount")
                }
            });
        case REQUEST_CAMPAIGN_OFFERS:
            return state;
        case RECEIVE_CONTROL_OFFERS:
            return Object.assign({}, state, {
                controlOffers: {
                    ...state.controlOffers,
                    totalCount: get(action, "json.totalCount")
                }
            });
        case REQUEST_CONTROL_OFFERS:
            return state;
        case RECEIVE_PROMOTIONS:
            return Object.assign({}, state, {
                offers: {
                    ...state.offers,
                    totalCount: get(action, "json.totalCount")
                }
            });
        case REQUEST_PROMOTIONS:
            return state;
        case UPDATE_CAMPAIGN_OFFERS_SORT:
            return Object.assign({}, state, {
                campaignOffers: {
                    ...state.campaignOffers,
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder
                }
            });
        case UPDATE_CONTROL_OFFERS_OFFSET:
            return Object.assign({}, state, {
                controlOffers: {
                    ...state.controlOffers,
                    offset: action.offset
                }
            });
        case UPDATE_CONTROL_OFFERS_SORT:
            return Object.assign({}, state, {
                controlOffers: {
                    ...state.controlOffers,
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder
                }
            });
        case UPDATE_PROMOTIONS_CONTROL_OFFERS_ONLY:
            return Object.assign({}, state, {
                offers: {
                    ...state.offers,
                    filters: {
                        ...state.offers.filters,
                        controlOffersOnly: action.controlOffersOnly
                    }
                }
            });
        case UPDATE_PROMOTIONS_LAYOUT_ISSUES_ONLY:
            return Object.assign({}, state, {
                offers: {
                    ...state.offers,
                    filters: {
                        ...state.offers.filters,
                        layoutIssuesOnly: action.layoutIssuesOnly
                    }
                }
            });
        case UPDATE_PROMOTIONS_SEARCH:
            return Object.assign({}, state, {
                offers: {
                    ...state.offers,
                    filters: {
                        ...state.offers.filters,
                        search: action.search
                    }
                }
            });
        case UPDATE_PROMOTIONS_SORT:
            return Object.assign({}, state, {
                offers: {
                    ...state.offers,
                    sortBy: action.sortBy,
                    sortOrder: action.sortOrder
                }
            });
        default:
            return state;
    }
}

export default promotions;
