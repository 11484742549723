import get from "lodash.get";
import isNil from "lodash.isnil";
import { isEmptyString } from "common/util/lang";
import { getEntityIdWithoutVersion } from "common/util/object";
import { DEFAULT_PAGE_SIZE, DESCENDING } from "common/util/query";
import { COMPLIANCE_OVERVIEW } from "filter/state/filterActions";
import type { ComplianceValue } from "filter/utils/showFilterUtils";
import type { RootState } from "store";

// Data keys for data and sorting and column chooser
export const COMPLIANCE_EVENT_WEEKS = "eventWeeks";
export const COMPLIANCE_COMPLIANT_EVENT_WEEKS = "eventWeeksCompliant";
export const COMPLIANCE_NON_COMPLIANT_EVENT_WEEKS = "eventWeeksNonCompliant";
export const COMPLIANCE_NO_GUIDANCE_EVENT_WEEKS = "eventWeeksNoGuidance";
export const COMPLIANCE_UNKNOWN_EVENT_WEEKS = "eventWeeksUnknown";
export const COMPLIANCE_COMPLIANCE = "COMPLIANCE";

// Reducer consts
const OVERVIEW = "overview";

type AssociatedPromotionEvent = {
    displayName: string;
    eventCompliance: any;
    guidelineCompliance: any;
    value: string;
};

type ComplianceEvent = {
    adEndDate: string;
    adStartDate: string;
    associatedPromotionEvents: AssociatedPromotionEvent[];
    bundledWith: string[];
    endDate: string;
    entityId: string;
    guidelineAttachments: any[];
    guidelineCompliance: any;
    guidelineKeyMessage: string;
    holiday: string;
    image: string;
    imageLarge: string;
    offerLabel: string;
    pageLink: string;
    pageType: string;
    pricePerUnit: string;
    productsString: string;
    promoType: any;
    retailerSummary: string;
    region: string;
    startDate: string;
};

type ComplianceOverview = {
    groupByFields: string;
    maxEventWeeks: number;
    measureOnGuidances: string[];
    offset: number;
    pageSize: number;
    sortBy: string;
    sortOrder: SortOrder;
    totalCount: number;
};

export type ComplianceState = {
    event: ComplianceEvent;
    overview: ComplianceOverview;
};

// Default State
const defaultComplianceEventState: ComplianceEvent = {
    adEndDate: "",
    adStartDate: "",
    associatedPromotionEvents: [],
    bundledWith: [],
    endDate: "",
    entityId: "",
    guidelineAttachments: [],
    guidelineCompliance: {},
    guidelineKeyMessage: "",
    holiday: "",
    image: "",
    imageLarge: "",
    offerLabel: "",
    pageLink: "",
    pageType: "",
    pricePerUnit: "",
    productsString: "",
    promoType: {},
    retailerSummary: "",
    region: "",
    startDate: ""
};

export const defaultComplianceOverviewState: ComplianceOverview = {
    groupByFields: "",
    maxEventWeeks: 0,
    measureOnGuidances: [],
    offset: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    sortBy: COMPLIANCE_EVENT_WEEKS,
    sortOrder: DESCENDING,
    totalCount: -1
};

export const defaultComplianceState = {
    event: defaultComplianceEventState,
    overview: defaultComplianceOverviewState
};

// Reduce promoted price string into array of name strings
export const reducePromotedPricePpgs = (
    promotedPricePpgs?: string | null
): any[] | undefined | null => {
    if (isNil(promotedPricePpgs) || isEmptyString(promotedPricePpgs)) {
        return null;
    }
    return promotedPricePpgs.split(",").map(ppg => ({ name: ppg }));
};

// Common compliance functions for action and reducer
export const reduceOverview = (
    state: any,
    overview: any
): ComplianceOverview => {
    return {
        groupByFields: get(state, "overview.groupByFields"),
        maxEventWeeks: overview.maxEventWeeks,
        measureOnGuidances: get(state, "overview.measureOnGuidances"),
        offset: overview.offset,
        pageSize: overview.pageSize,
        sortBy: get(state, "overview.sortBy"),
        sortOrder: get(state, "overview.sortOrder"),
        totalCount: overview.totalItems
    };
};

export const processOverviewGuidance = (
    state: any,
    guidances: string[]
): any => {
    const newState = Object.assign({}, state.overview, {
        measureOnGuidances: guidances
    });
    return Object.assign({}, state, {
        overview: newState
    });
};

export const processOverviewAggregate = (
    state: any,
    groupByFields: string
): any => {
    const newState = Object.assign({}, state.overview, {
        groupByFields: groupByFields
    });
    return Object.assign({}, state, {
        overview: newState
    });
};

// Compliance show filter functions
export const getSelectedGuidanceFilters = (
    compliance: ComplianceValue[],
    guidances: string[],
    fullComplianceList: ComplianceValue[]
): string[] => {
    const selectedNodes: string[] = [];
    let selectedComplianceValues = compliance;
    // Show all so filter can just be empty
    if (compliance.length === 0 && guidances.length === 0) {
        return [];
    }
    // No guidance selected so return compliance
    else if (guidances.length === 0) {
        return compliance;
    } else if (compliance.length === 0) {
        selectedComplianceValues = fullComplianceList;
    }
    selectedComplianceValues.forEach(complianceValue => {
        guidances.forEach(guidance => {
            selectedNodes.push(complianceValue + "-" + guidance);
        });
    });
    return selectedNodes;
};

export const getComplianceDetailRequest = (state: RootState): any => {
    return {
        complianceFilter: get(state, [
            "filters",
            COMPLIANCE_OVERVIEW,
            "showCompliance"
        ]),
        endDate: get(state, [
            "filters",
            COMPLIANCE_OVERVIEW,
            "date",
            "endDate"
        ]),
        groupByFields: get(state, ["compliance", OVERVIEW, "groupByFields"]),
        guidanceFilter: get(state, [
            "compliance",
            OVERVIEW,
            "measureOnGuidances"
        ]),
        holidayIds: get(state, ["filters", COMPLIANCE_OVERVIEW, "holidays"]),
        managerIds: get(state, ["filters", COMPLIANCE_OVERVIEW, "managers"]),
        objectType: "ComplianceSummaryRequest",
        offset: get(state, ["compliance", OVERVIEW, "offset"]),
        pageSize: get(state, ["compliance", OVERVIEW, "pageSize"]),
        productGroupIds: get(state, [
            "filters",
            COMPLIANCE_OVERVIEW,
            "products"
        ]),
        regionIds: get(state, ["filters", COMPLIANCE_OVERVIEW, "regions"]),
        retailerIds: get(state, ["filters", COMPLIANCE_OVERVIEW, "retailers"]),
        search: get(state, ["filters", COMPLIANCE_OVERVIEW, "search"]),
        sortBy: get(state, ["compliance", OVERVIEW, "sortBy"]),
        sortOrder: get(state, ["compliance", OVERVIEW, "sortOrder"]),
        startDate: get(state, [
            "filters",
            COMPLIANCE_OVERVIEW,
            "date",
            "startDate"
        ]),
        timePeriod: {
            objectType: "MatrixTimePeriod",
            value: get(state, ["filters", COMPLIANCE_OVERVIEW, "date", "time"])
        }
    };
};

export const reduceCompliance = (compliance: any): any => {
    const endDate = get(compliance, "details.endDate");
    const startDate = get(compliance, "details.startDate");
    const current = new Date();
    const active =
        !endDate ||
        (new Date(endDate) >= current && new Date(startDate) <= current);

    return {
        active,
        endDate,
        entityId: get(compliance, "entityId"),
        message: get(compliance, "changedFromMessage"),
        startDate,
        status: {
            displayName: get(compliance, "status.displayName"),
            value: get(compliance, "status.value")
        }
    };
};

const reduceTimings = (timings: any[]): any[] => {
    if (timings && timings.length) {
        return timings.map(timing => ({
            endDay: timing.endDay,
            endMonth: { label: get(timing, "endMonth.displayName") },
            startDay: timing.startDay,
            startMonth: { label: get(timing, "startMonth.displayName") }
        }));
    }
    return [];
};

const reduceSupportingMaterials = (supportingMaterials: any[]): any[] => {
    if (supportingMaterials && supportingMaterials.length) {
        return supportingMaterials.map(supportingMaterial => ({
            entityId: supportingMaterial.entityId,
            name: supportingMaterial.filename,
            size: supportingMaterial.filesize,
            thumbnail: supportingMaterial.thumbnailLink,
            file: supportingMaterial
        }));
    }
    return [];
};

const reduceAssociatedPromotionEvents = (
    associatedPromotionEvent: any
): AssociatedPromotionEvent => {
    return {
        displayName: associatedPromotionEvent.productGroupName,
        eventCompliance: associatedPromotionEvent.eventCompliance,
        guidelineCompliance: associatedPromotionEvent.guidelineCompliance,
        value: associatedPromotionEvent.promotionEventId
    };
};

export const reducePromotionEventGuidelineCompliance = (
    event: any
): ComplianceEvent => {
    const {
            adEndDate,
            adStartDate,
            associatedPromotionEvents,
            bundledWith,
            endDate,
            entityId,
            eventImage,
            guidelineKeyMessage,
            holiday,
            pageLink,
            promotion_campaign_retailerSummary,
            promotion_offerLabel,
            promotion_productsString,
            pageType,
            pricePerUnitAfterDiscountFormatCurrency,
            promoType,
            region,
            startDate
        } = event,
        matrixRecommendations = get(
            event,
            "recommendationCompliance_details.matrixRecommendation"
        );
    return {
        adEndDate,
        adStartDate,
        associatedPromotionEvents: associatedPromotionEvents.map(
            reduceAssociatedPromotionEvents
        ),
        bundledWith: bundledWith.map((ppg: any) => ppg.name),
        endDate,
        entityId: getEntityIdWithoutVersion(entityId),
        guidelineAttachments: reduceSupportingMaterials(
            get(event, "guidelineAttachments", [])
        ),
        guidelineKeyMessage,
        holiday,
        offerLabel: promotion_offerLabel,
        pageLink,
        pageType,
        pricePerUnit: pricePerUnitAfterDiscountFormatCurrency,
        productsString: promotion_productsString,
        retailerSummary: promotion_campaign_retailerSummary,
        region,
        startDate,
        image: eventImage,
        imageLarge: eventImage,
        guidelineCompliance: {
            applyToEffectivePTC: get(
                event,
                "promotedPriceCompliance_details.applyToEffectivePtc"
            ),
            conditionalMaxPromotedPrice: get(
                event,
                "promotedPriceCompliance_details.conditionalMaxPromotedPrice"
            ),
            conditionalMinPromotedPrice: get(
                event,
                "promotedPriceCompliance_details.conditionalMinPromotedPrice"
            ),
            crossPromotionCompliance: reduceCompliance(
                event.crossPromotionCompliance
            ),
            crossPromotionConflict: get(
                event,
                "crossPromotionCompliance.hasConflict"
            ),
            crossPromotionFrom: get(
                event,
                "crossPromotionCompliance.fromMessage"
            ),
            crossPromotionNotes: get(
                event,
                "crossPromotionCompliance.details.notes"
            ),
            crossPromotionsNo: get(
                event,
                "crossPromotionCompliance_details.crossPromotionsNo",
                []
            ),
            crossPromotionsYes: get(
                event,
                "crossPromotionCompliance_details.crossPromotionsYes",
                []
            ),
            frequency: get(event, "frequencyCompliance_details.frequency"),
            frequencyCompliance: reduceCompliance(event.frequencyCompliance),
            frequencyConflict: get(event, "frequencyCompliance.hasConflict"),
            frequencyFrom: get(event, "frequencyCompliance.fromMessage"),
            frequencyNotes: get(event, "frequencyCompliance.details.notes"),
            maxPromotedPrice: get(
                event,
                "promotedPriceCompliance_details.maxPromotedPrice"
            ),
            minPromotedPrice: get(
                event,
                "promotedPriceCompliance_details.minPromotedPrice"
            ),
            promotedPriceCompliance: reduceCompliance(
                event.promotedPriceCompliance
            ),
            promotedPriceConflict: get(
                event,
                "promotedPriceCompliance.hasConflict"
            ),
            promotedPriceEventCount: get(
                event,
                "promotedPriceCompliance_details.numOfEventsAllowed"
            ),

            promotedPriceFrom: get(
                event,
                "promotedPriceCompliance.fromMessage"
            ),
            promotedPriceNotes: get(
                event,
                "promotedPriceCompliance.details.notes"
            ),
            promotedPricePpgs: reducePromotedPricePpgs(event.promotedPricePpgs),
            promotedPriceTimePeriod: get(
                event,
                "promotedPriceCompliance_details.promotedPriceTimePeriod"
            ),
            offerStructureCompliance: reduceCompliance(
                event.offerStructureCompliance
            ),
            offerStructureConflict: get(
                event,
                "offerStructureCompliance.hasConflict"
            ),
            offerStructureFrom: get(
                event,
                "offerStructureCompliance.fromMessage"
            ),
            offerStructureNotes: get(
                event,
                "offerStructureCompliance.details.notes"
            ),
            offerStructureNo: get(
                event,
                "offerStructureCompliance_details.offerStructureNo",
                []
            ),
            offerStructureYes: get(
                event,
                "offerStructureCompliance_details.offerStructureYes",
                []
            ),
            recommendationCompliance: reduceCompliance(
                event.recommendationCompliance
            ),
            recommendationGuidance: {
                matrixRecommendations: matrixRecommendations
                    ? [matrixRecommendations]
                    : []
            },
            timing: reduceTimings(
                get(event, "timingCompliance_details.optimalTiming", [])
            ),
            timingCompliance: reduceCompliance(event.timingCompliance),
            timingConflict: get(event, "timingCompliance.hasConflict"),
            timingFrom: get(event, "timingCompliance.fromMessage"),
            timingNotes: get(event, "timingCompliance.details.notes"),
            visualMaterials: reduceSupportingMaterials(
                get(event, "visualsCompliance_details.visualMaterials", [])
            ),
            visualNotes: get(event, "visualsCompliance.details.notes"),
            visualsCompliance: reduceCompliance(event.visualsCompliance),
            visualsConflict: get(event, "visualsCompliance.hasConflict"),
            visualsFrom: get(event, "visualsCompliance.fromMessage")
        },
        promoType
    };
};
