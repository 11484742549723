import get from "lodash.get";
import type { AnyAction } from "redux";
import { writeEntity } from "common/shell/state/entityActions";
import { resetCanNotLeave } from "common/shell/state/navigationActions";
import {
    makeRequestThunk,
    METHOD_GET,
    METHOD_PUT
} from "common/shell/state/requestActions";
import { success } from "common/shell/state/toastActions";
import { receiveTenantConfiguration } from "shell/state/initActions";

import {
    PROMOTION_TYPE_DETECTION_EDIT_PAGE,
    PROMOTION_TYPE_DETECTION_PAGE
} from "administration/state/pageActions";
import type { ActionOrThunk, Actions, RootState } from "store";

export const RECEIVE_PROMOTION_TYPE_DETECTION =
    "RECEIVE_PROMOTION_TYPE_DETECTION";
export const REQUEST_PROMOTION_TYPE_DETECTION =
    "REQUEST_PROMOTION_TYPE_DETECTION";

export const promotionTypeDetectionEditPage = (): AnyAction => ({
    type: PROMOTION_TYPE_DETECTION_EDIT_PAGE
});

export const promotionTypeDetectionPage = (): AnyAction => ({
    type: PROMOTION_TYPE_DETECTION_PAGE
});

export const receivePromotionTypeDetection = (json: Json): AnyAction => ({
    type: RECEIVE_PROMOTION_TYPE_DETECTION,
    tenantConfiguration: json
});

export const requestPromotionTypeDetection = (): AnyAction => ({
    type: REQUEST_PROMOTION_TYPE_DETECTION
});

export function fetchPromotionTypeDetection() {
    return makeRequestThunk("api/entity/tenantconfiguration", {
        method: METHOD_GET,
        urlPartFunc: (state: RootState): string => {
            const entityId = get(
                state,
                "init.tenant.tenantConfiguration.entityId"
            );
            return "/" + entityId;
        },
        queryParamsFunc: function (state: RootState): any {
            return {
                modelAndFieldSet:
                    "TenantConfigurationModel/promotionTypeDetectionFields"
            };
        },
        preRequestFunc: requestPromotionTypeDetection,
        okDispatchFunc: receivePromotionTypeDetection
    });
}

export function writePromotionTypeDetection(entity: any) {
    const okDispatchFunc = (
        json: TenantConfiguration,
        state: RootState
    ): Actions => {
        const actions: ActionOrThunk[] = [];
        const successMessage = {
            resourceKey: "administration.edit_promotion_type_detection_success"
        };
        actions.push(resetCanNotLeave());
        actions.push(receiveTenantConfiguration(json));
        actions.push(promotionTypeDetectionPage());
        actions.push(success(successMessage));
        return actions;
    };
    return writeEntity(entity, METHOD_PUT, { okDispatchFunc }, true);
}
