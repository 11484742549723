import isEqual from "lodash.isequal";
import isFunction from "lodash.isfunction";
import { error, success } from "common/shell/state/toastActions";
import {
    BODY_TYPE_FILE,
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import type { RequestOptions } from "common/shell/state/requestActions";
import type { BodyType } from "common/shell/state/requestActions";
import { reportError } from "common/shell/util/error";

import { downloadFile } from "common/util/url";
import type { Actions, AppDispatch, GetState, RootState } from "store";

export type ActionTokenUrl = string;
export type ExportInfo = ((a: any) => any) | any;
export type DownloadUrl = ((b: any, a: RootState) => string) | string;

export function uploadFile(url: string, file: File, hideSuccess: boolean) {
    return async function (dispatch: AppDispatch, getState: GetState) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", file);
            const options: RequestOptions = {
                bodyType: BODY_TYPE_FILE,
                body: formData,
                isCancellable: false,
                method: METHOD_POST,
                notOkDispatchFunc: (
                    response: Response,
                    json: ErrorDto,
                    state: RootState
                ): Actions | undefined | null => {
                    reject();
                    const errorMessage = json.message
                        ? json.message
                        : {
                              resourceKey: "common:general.upload_failed",
                              values: {
                                  fileName: file.name
                              }
                          };
                    return [error(errorMessage)];
                },
                okDispatchFunc: (
                    json: SuccessDto | ErrorDto,
                    state: RootState
                ) => {
                    if (!isEqual(json.objectType, "ErrorDto")) {
                        resolve(json);
                        return hideSuccess
                            ? []
                            : [
                                  success({
                                      resourceKey:
                                          "common:general.upload_successful",
                                      values: {
                                          fileName: file.name
                                      }
                                  })
                              ];
                    } else {
                        reject();
                        const errorMessage = json.message
                            ? json.message
                            : {
                                  resourceKey: "common:general.upload_failed",
                                  values: {
                                      fileName: file.name
                                  }
                              };
                        return [error(errorMessage)];
                    }
                },
                showLoadMask: false
            };

            dispatch(makeRequestThunk(url, options));
        });
    };
}

export function getActionTokenThunk(
    actionTokenUrl: ActionTokenUrl,
    exportInfo: ExportInfo,
    bodyType: BodyType
) {
    return async function (dispatch: AppDispatch, getState: GetState) {
        return new Promise<TokenResult>((resolve, reject) => {
            const options: RequestOptions = {
                bodyType: bodyType,
                ...(isFunction(exportInfo)
                    ? { bodyFunc: exportInfo }
                    : { body: exportInfo }),
                isCancellable: false,
                method: METHOD_POST,
                okDispatchFunc: (json: Json, state: RootState) => {
                    resolve(json);
                    return [];
                },
                okTest: (response: Response, json: Json) => {
                    return json.token;
                }
            };

            dispatch(makeRequestThunk(actionTokenUrl, options)).then(
                ({ success }) => {
                    if (!success) {
                        reject();
                    }
                }
            );
        });
    };
}

export function actionTokenDownloadThunk(
    actionTokenUrl: ActionTokenUrl,
    exportInfo: ExportInfo,
    downloadUrl: DownloadUrl,
    bodyType: BodyType = "json"
) {
    return async function (dispatch: AppDispatch, getState: GetState) {
        return new Promise<TokenResult>((resolve, reject) => {
            try {
                dispatch(
                    getActionTokenThunk(actionTokenUrl, exportInfo, bodyType)
                ).then((tokenObj: TokenResult) => {
                    if (tokenObj.token) {
                        downloadFile(
                            isFunction(downloadUrl)
                                ? downloadUrl(tokenObj, getState())
                                : downloadUrl + tokenObj.token
                        );
                        resolve(tokenObj);
                    } else {
                        reject(tokenObj);
                    }
                }, reject);
            } catch (error) {
                reportError(error as Error);
                reject(error);
            }
        });
    };
}
