import type { TFunction } from "i18next";
import get from "lodash.get";
import type { AnyAction } from "redux";
import { writeEntity } from "common/shell/state/entityActions";
import { actionTokenDownloadThunk } from "common/shell/state/fileActions";
import {
    BUTTON_CANCELYES,
    BUTTON_YES,
    ICON_QUESTION,
    openMessageBox
} from "common/shell/state/messageBoxActions";
import {
    resetCanNotLeave,
    setCanNotLeave
} from "common/shell/state/navigationActions";
import { success } from "common/shell/state/toastActions";
import {
    makeRequestThunk,
    METHOD_DELETE,
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT
} from "common/shell/state/requestActions";

import { guidelineCollectionPage } from "administration/guidelines/state/guidelineCollectionsActions";
import { GUIDELINE_FIELDS } from "administration/guidelines/state/guidelinesActions";
import {
    GUIDELINE_EDIT_PAGE,
    GUIDELINE_PAGE
} from "administration/state/pageActions";
import { eventsPage } from "event/state/eventsActions";
import { EVENTS_VIEW_ROOT_MODE } from "event/state/eventsStateUtils";
import type { GuidelineSupportingMaterialsState } from "ppgGuidelines/state/ppgGuidelinesReducers";
import { EVENTS_PAGE } from "shell/state/pageActions";
import type { ActionOrThunk, Actions, AppDispatch, RootState } from "store";

// action types
export const CLEAR_OFFER_INFO = "CLEAR_OFFER_INFO";
export const RECEIVE_DUPLICATE_GUIDELINE = "RECEIVE_DUPLICATE_GUIDELINE";
export const RECEIVE_GUIDELINE = "RECEIVE_GUIDELINE";
export const RECEIVE_OFFER_INFO = "RECEIVE_OFFER_INFO";
export const REQUEST_GUIDELINE = "REQUEST_GUIDELINE";
export const REQUEST_OFFER_INFO = "REQUEST_OFFER_INFO";

// field names
export const GUIDELINE_FIELD_NAME = "name";

// actions creators

export const clearDuplicateGuideline = (): AnyAction => ({
    type: RECEIVE_DUPLICATE_GUIDELINE,
    guideline: null
});

export const guidelinePage = (entityId: string): AnyAction => ({
    type: GUIDELINE_PAGE,

    payload: {
        // matches what is in routes.js
        entityId: entityId
    }
});

export const guidelineEditPage = (entityId: string): AnyAction => ({
    type: GUIDELINE_EDIT_PAGE,

    payload: {
        // matches what is in routes.js
        entityId: entityId
    }
});

export const requestGuideline = (): AnyAction => ({
    type: REQUEST_GUIDELINE
});

export const receiveGuideline = (json: Json): AnyAction => ({
    type: RECEIVE_GUIDELINE,
    guideline: json
});

export const clearOfferInfo = (): AnyAction => ({
    type: CLEAR_OFFER_INFO
});

export const requestOfferInfo = (): AnyAction => ({
    type: REQUEST_OFFER_INFO
});

export const receiveOfferInfo = (json: Json): AnyAction => ({
    type: RECEIVE_OFFER_INFO,
    json
});

export const handleDeleteGuidelineConfirmation = (
    button: string,
    callbackData: any,
    dispatch: AppDispatch
) => {
    if (button === BUTTON_YES) {
        dispatch(
            deleteGuideline(
                callbackData.collectionId,
                callbackData.entityId,
                callbackData.name,
                callbackData.callbackFunction
            )
        );
    }
};

export const deleteGuidelineConfirmation = (
    guideline: any,
    t: TFunction,
    dispatch: AppDispatch,
    callbackFunction?: NoArgsHandler
) => {
    dispatch(
        openMessageBox({
            buttons: BUTTON_CANCELYES,
            buttonText: {
                BUTTON_CANCEL: t("common:general.no"),
                BUTTON_YES: t("common:general.yes")
            },
            callbackData: { ...guideline, callbackFunction },
            callbackFunction: handleDeleteGuidelineConfirmation,
            dispatch: dispatch,
            icon: ICON_QUESTION,
            message: {
                resourceKey: "administration.delete_guideline_confirmation",
                values: {
                    guideline: guideline.name
                }
            },
            showCloseIcon: false,
            title: t("administration.delete_guideline")
        })
    );
};

export function deleteGuideline(
    collectionId: string,
    entityId: string,
    name: string,
    callbackFunction: NoArgsHandler
) {
    return makeRequestThunk("api/entity/guideline/" + entityId, {
        isCancellable: false,
        method: METHOD_DELETE,
        okDispatchFunc: (json: Json, state: RootState): Actions => {
            const actions: ActionOrThunk[] = [];
            const successMessage = {
                resourceKey: "administration.delete_guideline_success",
                values: {
                    guideline: name
                }
            };
            if (state.page === GUIDELINE_PAGE) {
                actions.push(guidelineCollectionPage(collectionId));
            } else {
                if (callbackFunction) {
                    callbackFunction();
                }
            }
            actions.push(success(successMessage));
            return actions;
        }
    });
}

export function fetchGuideline() {
    return makeRequestThunk("api/entity/guideline", {
        method: METHOD_GET,
        urlPartFunc: function (state: RootState): string {
            return "/" + state.guideline.current.entityId;
        },
        queryParamsFunc: function (state: RootState): any {
            return {
                fields: GUIDELINE_FIELDS
            };
        },
        preRequestFunc: requestGuideline,
        okDispatchFunc: receiveGuideline
    });
}

export function writeGuideline(
    resourceKey: string,
    entity: any,
    createAnother: boolean
) {
    const method = entity.entityId ? METHOD_PUT : METHOD_POST;
    const okDispatchFunc = (json: Json, state: RootState): Actions => {
        const actions: ActionOrThunk[] = [];
        const successMessage = {
            resourceKey: resourceKey,
            values: {
                guideline: entity.name
            }
        };
        if (!createAnother) {
            actions.push(resetCanNotLeave());
            if (get(state, "location.prev.type") === EVENTS_PAGE) {
                actions.push(eventsPage(EVENTS_VIEW_ROOT_MODE));
            } else {
                actions.push(guidelinePage(json.entityId));
            }
        } else {
            actions.push(setCanNotLeave());
        }
        actions.push(success(successMessage));
        return actions;
    };
    return writeEntity(entity, method, { okDispatchFunc });
}

// Guideline file actions
export function downloadSupportingMaterial(
    file: GuidelineSupportingMaterialsState
) {
    return actionTokenDownloadThunk(
        "api/guideline/supportingmaterials/download/init",
        file,
        "api/guideline/supportingmaterials/download?otat="
    );
}

export function receiveDuplicateGuideline(json: Json): AnyAction {
    return {
        type: RECEIVE_DUPLICATE_GUIDELINE,
        duplicateGuideline: json
    };
}

export function checkForDuplicateGuideline(guideline: any) {
    return makeRequestThunk("api/guideline/validate", {
        method: METHOD_POST,
        body: guideline,
        okDispatchFunc: receiveDuplicateGuideline
    });
}

export function getOfferInfo(offerLabel: string) {
    return makeRequestThunk("api/guideline/offerguidance/select", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState): any {
            return {
                objectType: "OfferGuidanceRequest",
                select: offerLabel
            };
        },
        preRequestFunc: requestOfferInfo,
        okDispatchFunc: receiveOfferInfo
    });
}
