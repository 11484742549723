import get from "lodash.get";
import isNil from "lodash.isnil";
import {
    DATA_TABLE_DISPLAY_TYPE,
    TREND_DISPLAY_TYPE
} from "common/reports/components/reportsViewUtils";
import type { TrendRequestExtraOptions } from "reports/components/TrendView";
import {
    setRequestComparisonDate,
    setRequestDates,
    setRequestDatesStatic,
    setRequestSearch,
    setRequestSort
} from "common/reports/state/reportsActionUtils";
import {
    getReportStateKey,
    TREND
} from "common/reports/state/reportsStateUtils";
import { getPreference } from "common/shell/util/preferences";
import { CUSTOM } from "common/util/date";
import { getDataTableGridId } from "reports/components/DataTableView";
import {
    AGGREGATE_BY_PPG,
    ALL,
    CONSUMER,
    CONSUMER_CUSTOM,
    CONSUMER_PRODUCT_TRIAL,
    CONSUMER_PURCHASE_QUANTITIES,
    isUpcAggregate,
    PRODUCT_GROUPS,
    UPC_PRODUCT_GROUPS
} from "reports/consumer/state/consumerStateUtils";
import type { Aggregate } from "reports/consumer/state/consumerStateUtils";
import {
    getSubcategories,
    PROMO_REPORT_TIME_PERIOD
} from "reports/state/reportsStateUtils";
import type {
    ReportFilters,
    ReportView,
    TrendSeries
} from "reports/state/reportsStateUtils";
import type { RootState } from "store";

const CONSUMER_TRENDS_REPORT_AGGREGATION = "ConsumerTrendsReportAggregation";
const CONSUMER_TRENDS_REPORT_PROMOTION_FILTER =
    "ConsumerTrendsReportPromotionFilter";
const CONSUMER_TRENDS_REPORT_TABLE_FILTER = "ConsumerTrendsReportTableFilter";

export const DATA_TABLE_URL = "api/report/consumertrends/explore";
export const DATA_TABLE_EXPORT_URL = "api/report/consumertrends/explore/export";
export const TREND_CHART_URL = "api/report/consumertrends/chart";
export const TREND_CHART_EXPORT_URL = "api/report/consumertrends/chart/export";

export function getAggregate(aggregates: Aggregate[]): Aggregate {
    return !isNil(aggregates) && aggregates.length > 0
        ? aggregates[0]
        : AGGREGATE_BY_PPG;
}

export function getDataTableRequest(
    reportView: ReportView,
    filters: ReportFilters,
    state: RootState,
    savedWithStaticDates = false
): any {
    const aggregate = getAggregate(
        get(
            state,
            getReportStateKey(CONSUMER, reportView, "dataTable.aggregates")
        )
    );
    let categories: any[] = [];
    if (filters.filterType === CONSUMER) {
        let categoryNodes = [];
        // if aggregation is UPC, translate into subcategories
        if (isUpcAggregate(aggregate)) {
            categoryNodes =
                state.init.preloadedEntities.filters.reports
                    ?.consumerTrendUpcCategories ?? [];
            categories = getSubcategories(filters.subcategories, categoryNodes);
        } else {
            categoryNodes =
                state.init.preloadedEntities.filters.reports
                    ?.consumerTrendCategories ?? [];
            categories = getSubcategories(filters.categories, categoryNodes);
        }
    }
    const request: any = {
        objectType: "ConsumerTrendsReportTableRequest",
        tableFilter: {
            objectType: CONSUMER_TRENDS_REPORT_TABLE_FILTER,
            value: reportView
        },
        aggregation: {
            objectType: CONSUMER_TRENDS_REPORT_AGGREGATION,
            value: aggregate
        },
        brands: filters.filterType === CONSUMER ? filters.brands : [],
        categories: categories,
        channelIds: filters.filterType === CONSUMER ? filters.channels : [],
        productGroupIds: filters.ppgs,
        promotionFilter: {
            objectType: CONSUMER_TRENDS_REPORT_PROMOTION_FILTER,
            value: filters.filterType === CONSUMER ? filters.promotion : ALL
        }
    };
    if (savedWithStaticDates) {
        setRequestDatesStatic(
            request,
            get(filters, "date"),
            PROMO_REPORT_TIME_PERIOD,
            state,
            CONSUMER,
            reportView
        );
    } else {
        setRequestDates(
            request,
            get(filters, "date"),
            PROMO_REPORT_TIME_PERIOD
        );
    }
    setRequestComparisonDate(request, get(filters, "comparisonDate"));
    setRequestSearch(request, CONSUMER, reportView, state);
    setRequestSort(request, CONSUMER, reportView, state);
    if (request.sortBy === UPC_PRODUCT_GROUPS) {
        request.sortBy = PRODUCT_GROUPS;
    }
    return request;
}

export function getTrendChartRequest(
    series: TrendSeries[],
    savedWithStaticDates: boolean,
    extraOptions: TrendRequestExtraOptions
): any {
    const {
        reportView,
        dateFilter,
        consumerTrendCategoryNodes,
        startDate,
        endDate
    } = extraOptions;
    const request: any = {
        objectType: "ConsumerTrendsReportChartRequest"
    };
    // Only set to custom static if there are dates to set
    if (savedWithStaticDates && endDate && startDate) {
        request.endDate = endDate;
        request.startDate = startDate;

        request.timePeriod = {
            objectType: PROMO_REPORT_TIME_PERIOD,
            value: CUSTOM
        };
    } else {
        setRequestDates(request, dateFilter, PROMO_REPORT_TIME_PERIOD);
    }
    request.series = [];
    series.forEach((x: TrendSeries) => {
        if (x.seriesType === CONSUMER) {
            const categories = consumerTrendCategoryNodes
                ? getSubcategories(x.categories, consumerTrendCategoryNodes)
                : [];
            const seriesRequest = {
                objectType: "ConsumerTrendsReportTrendSeriesRequest",
                name: x.name,
                metric: {
                    objectType: "ConsumerTrendsReportColumn",
                    value: get(x, "metric.name")
                },
                aggregation: {
                    objectType: CONSUMER_TRENDS_REPORT_AGGREGATION,
                    value: x.aggregate
                },
                tableFilter: {
                    objectType: CONSUMER_TRENDS_REPORT_TABLE_FILTER,
                    value: reportView
                },
                promotionFilter: {
                    objectType: CONSUMER_TRENDS_REPORT_PROMOTION_FILTER,
                    value: x.promotion
                },
                brands: x.brands,
                categories: categories,
                channelIds: x.channels,
                productGroupIds: x.ppgs,
                upc: x.upc
            };
            request.series.push(seriesRequest);
        }
    });
    return request;
}

export const getConsumerSavedReportsRequests = (
    reportView: ReportView,
    state: RootState,
    savedWithStaticDates: boolean
) => {
    const requests = [];
    const filters = get(
        state,
        getReportStateKey(CONSUMER, reportView, "dataTable.filters")
    );

    const series = get(
        state,
        getReportStateKey(CONSUMER, reportView, "trend.configuration.series")
    );

    const trendDate = get(
        state,
        getReportStateKey(CONSUMER, reportView, "trend.configuration.date")
    );

    const consumerTrendCategoryNodes = get(
        state,
        "init.preloadedEntities.filters.reports.consumerTrendCategories",
        []
    );

    const endDate = get(
        state,
        getReportStateKey(CONSUMER, reportView, "trend.chart.endDate")
    );

    const startDate = get(
        state,
        getReportStateKey(CONSUMER, reportView, "trend.chart.startDate")
    );

    switch (reportView) {
        case CONSUMER_CUSTOM:
        case CONSUMER_PURCHASE_QUANTITIES:
        case CONSUMER_PRODUCT_TRIAL:
            requests.push({
                objectType: "SavedReportRequest",
                serializedRequest: JSON.stringify(
                    getDataTableRequest(
                        reportView,
                        filters,
                        state,
                        savedWithStaticDates
                    )
                )
            });
            requests.push({
                objectType: "SavedReportRequest",
                serializedRequest: JSON.stringify(
                    getTrendChartRequest(series, savedWithStaticDates, {
                        consumerTrendCategoryNodes: consumerTrendCategoryNodes,
                        dateFilter: trendDate,
                        endDate: endDate,
                        reportView: reportView,
                        startDate: startDate
                    })
                )
            });
            break;
        default:
            break;
    }
    return requests;
};

export const getConsumerSavedReportsState = (
    reportView: ReportView,
    state: RootState
) => {
    const uiState: Dictionary<string> = {};
    const gridId = getDataTableGridId(reportView);
    const gridState = getPreference(gridId, state.preferences, {});
    const reportStyle = get(
        state,
        getReportStateKey(CONSUMER, reportView, "reportStyle")
    );
    switch (reportView) {
        case CONSUMER_CUSTOM:
        case CONSUMER_PURCHASE_QUANTITIES:
        case CONSUMER_PRODUCT_TRIAL:
            uiState[gridId] = gridState;
            uiState.reportStyle = reportStyle;
            break;
        default:
            break;
    }
    return JSON.stringify(uiState);
};

export const getConsumerSavedReportsDisplayType = (
    reportView: ReportView,
    state: RootState
) => {
    const reportStyle = get(
        state,
        getReportStateKey(CONSUMER, reportView, "reportStyle")
    );
    if (reportStyle === TREND) {
        return TREND_DISPLAY_TYPE;
    }
    return DATA_TABLE_DISPLAY_TYPE;
};
