import type { Action } from "redux";
import { makeRequestThunk } from "common/shell/state/requestActions";
import type {
    InitializationError,
    ReceiveInit,
    ReceiveUsersReinit,
    RequestInit
} from "common/shell/state/initActions";
import type { ReceiveRaven } from "common/shell/state/ravenActions";
import type { InitMetaDataResponse } from "shell/state/initReducers";

export const INIT_URL = "/api/meta/init";

export const RECEIVE_HOLIDAYS_REINIT = "RECEIVE_HOLIDAYS_REINIT";
export const RECEIVE_TENANT_CONFIGURATION = "RECEIVE_TENANT_CONFIGURATION";

export type InitActions =
    | InitializationError
    | ReceiveInit
    | ReceiveHolidaysReinit
    | ReceiveRaven
    | ReceiveTenantConfiguration
    | ReceiveUsersReinit
    | RequestInit;

interface ReceiveHolidaysReinit extends Action {
    type: typeof RECEIVE_HOLIDAYS_REINIT;
    init: InitMetaDataResponse;
}

export const receiveHolidaysReinit = (
    json: InitMetaDataResponse
): ReceiveHolidaysReinit => {
    return {
        type: RECEIVE_HOLIDAYS_REINIT,
        init: json
    };
};

export function fetchHolidaysReinit() {
    return makeRequestThunk(INIT_URL, {
        isCancellable: false,
        okDispatchFunc: receiveHolidaysReinit,
        showLoadMask: false
    });
}

interface ReceiveTenantConfiguration extends Action {
    type: typeof RECEIVE_TENANT_CONFIGURATION;
    tenantConfiguration: TenantConfiguration;
}

export const receiveTenantConfiguration = (
    json: TenantConfiguration
): ReceiveTenantConfiguration => {
    return {
        type: RECEIVE_TENANT_CONFIGURATION,
        tenantConfiguration: json
    };
};
