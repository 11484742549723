import get from "lodash.get";
import isNil from "lodash.isnil";
import type { GridTeam } from "common/administration/teams/state/teamStateUtils";
import {
    formatSortOrderForQueryAPI,
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import {
    andFilterCondition,
    booleanFilterCondition,
    freeFormFilterCondition
} from "common/util/filter";
import { isEmptyString } from "common/util/lang";
import { sortCondition } from "common/util/query";
import { TEAMS_PAGE } from "common/administration/state/pageActions";
import {
    reduceGridTeam,
    TEAM_NAME,
    TEAM_TYPE
} from "common/administration/teams/state/teamStateUtils";
import type { RootState } from "store";

// action types
export const REQUEST_TEAMS = "REQUEST_TEAMS";
export const RECEIVE_TEAMS = "RECEIVE_TEAMS";
export const UPDATE_SEARCH_TEAMS = "UPDATE_SEARCH_TEAMS";
export const UPDATE_SORT_TEAMS = "UPDATE_SORT_TEAMS";
export const UPDATE_START_INDEX_TEAMS = "UPDATE_START_INDEX_TEAMS";

export type TeamQueryResult = {
    result: GridTeam[];
} & ListQueryResult;

export type TeamsActions =
    | SimpleAction<typeof TEAMS_PAGE>
    | ReceiveItems<typeof RECEIVE_TEAMS, TeamQueryResult>
    | RequestItems<typeof REQUEST_TEAMS>
    | UpdateSearch<typeof UPDATE_SEARCH_TEAMS>
    | UpdateSortBy<typeof UPDATE_SORT_TEAMS>
    | UpdateStartIndex<typeof UPDATE_START_INDEX_TEAMS>;

export const teamsPage = (): SimpleAction<typeof TEAMS_PAGE> => ({
    type: TEAMS_PAGE
});

export const requestTeams = (): RequestItems<typeof REQUEST_TEAMS> => ({
    type: REQUEST_TEAMS
});

export const receiveTeams = (
    json: TeamQueryResult
): ReceiveItems<typeof RECEIVE_TEAMS, TeamQueryResult> => ({
    type: RECEIVE_TEAMS,
    items: json
});

export const updateStartIndex = (
    startIndex: number
): UpdateStartIndex<typeof UPDATE_START_INDEX_TEAMS> => ({
    type: UPDATE_START_INDEX_TEAMS,
    startIndex: startIndex
});

export const updateSortBy = (
    sortBy: string,
    sortOrder: SortOrder
): UpdateSortBy<typeof UPDATE_SORT_TEAMS> => ({
    type: UPDATE_SORT_TEAMS,
    sortBy: sortBy,
    sortOrder: sortOrder
});

export const updateSearch = (
    search: string
): UpdateSearch<typeof UPDATE_SEARCH_TEAMS> => ({
    type: UPDATE_SEARCH_TEAMS,
    search: search
});

export const getTeamsFilterCondition = (
    search: string,
    fields: string[] = [TEAM_NAME, TEAM_TYPE]
): FilterCondition => {
    const isHiddenFilterCondition = booleanFilterCondition("hidden", false);
    if (!isNil(search) && !isEmptyString(search)) {
        return andFilterCondition([
            isHiddenFilterCondition,
            freeFormFilterCondition(search, fields)
        ]);
    } else {
        return isHiddenFilterCondition;
    }
};

const getTeamsRequest = (state: RootState): ListQueryRequest => {
    const sortOrder = formatSortOrderForQueryAPI(state.teams.sortOrder);
    const sortConditions = [sortCondition(get(state, "teams.sortBy"), sortOrder)];
    const search = get(state, "teams.search");
    return {
        entity: "Team",
        filterCondition: getTeamsFilterCondition(search),
        includeCrossTenantInstances: false,
        maxResults: state.teams.maxResults,
        model: "TeamModel/readFields",
        objectType: "ListQuery",
        postSortCondition: null,
        sortConditions: sortConditions,
        startIndex: state.teams.startIndex
    };
};

export const fetchTeams = () => {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getTeamsRequest(state);
        },
        preRequestFunc: requestTeams,
        okDispatchFunc: receiveTeams,
        okResultFunc: (json: TeamQueryResult, state: RootState) => {
            return {
                items: json.result.map(item => {
                    return reduceGridTeam(item);
                }),
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
};
