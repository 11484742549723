import get from "lodash.get";
import { getAgGridLocaleText } from "common/components/grid/aggrid_locale_text";
import i18n from "common/i18n";
import { ENGLISH_LOCALE, UNKNOWN } from "common/shell/util/types";
import type {
    LocalTenant,
    ServerMode,
    TrackingApplicationType,
    TrackingData
} from "common/shell/util/types";
import {
    getEntityIdWithoutVersion,
    reduceArray,
    reduceByKeys
} from "common/util/object";
import type { InitMetaDataResponse } from "shell/state/initReducers";

type EntityPropertyMetadata = {
    dataType: DisplayNameValueType[];
    displayName: string;
    displayNamePlural: string;
    entityClass: string;
    enumValues: Enum[];
    filterable: boolean;
    name: string;
    objectType: string;
};

type EntityMetadata = {
    entity: string;
    entityDisplayName: string;
    entityDisplayNamePlural: string;
    entityPropertyMetadata: EntityPropertyMetadata[];
    objectType: string;
};

export type FilterValueNode = {
    displayName: string;
    entityId: string;
    value: string;
};

export type FilterNode = {
    displayName: string;
    entityId: string;
    entityIdWithoutVersion: string;
    objectType: string;
};

export type ReportDef = {
    group: string;
    groupLabel: string;
    supportsStaticDates: boolean;
} & LabelSublabelValueType;

export type TeamTypeType = { description: string } & Pick<
    TeamType,
    "canAccessAllInstances" | "entityId" | "name"
>;

type UserType = {
    isHidden: boolean;
} & User;

export type TenantWithLocale = {
    applications: Application[];
    currencySymbol: string;
    locale: string;
    tenantConfiguration: TenantConfiguration | undefined;
    tenantConfigurationEntityId: string;
    type: string;
} & LocalTenant;

export type CommonInitState = {
    additionalTenants: LocalTenant[];
    aggridLocaleText: Dictionary<string>;
    allowableActions: Dictionary<boolean>;
    canAccessMultipleTenants: boolean;
    error: boolean;
    initialized: boolean;
    labels: Dictionary<string>;
    serverMode: ServerMode;
    tenant: TenantWithLocale;
    trackingData: TrackingData;
    user: UserType;
    version: string;
};

export type CommonInitMetaDataResponse = {
    additionalTenants: Tenant[];
    allowableActions: Dictionary<AllowableAction>;
    canAccessMultipleTenants: boolean;
    chartColors: string[];
    entityMetadataList: EntityMetadata[];
    labels: Dictionary<string>;
    objectType: string;
    referenceColor: string;
    serverModeString: string;
    tenant: Tenant;
    trackingData: TrackingData;
    user: UserType;
    versionString: string;
};

const defaultTenant: TenantWithLocale = {
    applications: [],
    currencySymbol: "$",
    entityId: "",
    locale: "",
    name: "",
    tenantConfiguration: undefined,
    tenantConfigurationEntityId: "",
    type: ""
};

export const defaultTrackingData: TrackingData = {
    application: UNKNOWN,
    teamType: UNKNOWN,
    tenantId: UNKNOWN,
    userId: UNKNOWN,
    userType: UNKNOWN
};

const defaultUser: UserType = {
    email: "",
    enabled: true,
    entityId: "",
    firstName: "",
    fullName: "",
    isHidden: false,
    lastName: "",
    objectType: "User",
    username: ""
};

export const defaultCommonState = {
    allowableActions: {},
    additionalTenants: [],
    aggridLocaleText: {},
    canAccessMultipleTenants: false,
    error: false,
    initialized: false,
    labels: {},
    serverMode: UNKNOWN,
    tenant: defaultTenant,
    trackingData: defaultTrackingData,
    user: defaultUser,
    version: ""
};

// in the future can tie to individual user preferences
export const getLocale = (): string => {
    return ENGLISH_LOCALE;
};

export const reduceTenant = (json: InitMetaDataResponse): TenantWithLocale => {
    const applications = (json.tenant.applications ?? []).sort(
        (a: Application, b: Application) => {
            return a.displayName > b.displayName ? 1 : -1;
        }
    );
    return {
        applications: reduceArray(applications, "displayName", "value"),
        entityId: json.tenant.entityId,
        currencySymbol: json.tenant.tenantConfiguration.defaultCurrencySymbol,
        locale: json.tenant.defaultLocale.value,
        name: json.tenant.name,
        tenantConfiguration: json.tenant.tenantConfiguration,
        tenantConfigurationEntityId: json.tenant.tenantConfiguration.entityId,
        type: json.tenant.type.value
    };
};

export const processLabels = (labels: Dictionary<string>, locale: string) => {
    if (labels) {
        i18n.addResources(locale, "server", labels);
    }
};

export const reduceCommonInit = (
    json: InitMetaDataResponse,
    app: TrackingApplicationType
): CommonInitState => {
    const labels = json.labels;
    const locale = getLocale();
    // we have labels, add them to resource bundle
    processLabels(labels, locale);
    return {
        allowableActions: reduceByKeys(json.allowableActions, "allowed"),
        additionalTenants: reduceArray(
            json.additionalTenants,
            "entityId",
            "name"
        ),
        aggridLocaleText: getAgGridLocaleText(),
        canAccessMultipleTenants: json.canAccessMultipleTenants,
        error: false,
        initialized: true,
        labels: labels,
        serverMode: json.serverModeString,
        tenant: reduceTenant(json),
        trackingData: {
            application: app,
            teamType: json.trackingData.teamType,
            tenantId: json.trackingData.tenantId,
            userId: json.trackingData.userId,
            userType: json.trackingData.userType
        },
        user: {
            email: json.user.email,
            enabled: json.user.enabled,
            entityId: getEntityIdWithoutVersion(json.user.entityId),
            firstName: json.user.firstName,
            fullName: json.user.fullName,
            isHidden: json.user.isHidden,
            lastName: json.user.lastName,
            objectType: json.user.objectType,
            username: json.user.username
        },
        version: json.versionString
    };
};

export const reduceNode = (name: string, obj: any): FilterNode => {
    return {
        displayName: get(obj, name),
        entityIdWithoutVersion: getEntityIdWithoutVersion(obj.entityId),
        entityId: obj.entityId,
        objectType: obj.objectType
    };
};
