import get from "lodash.get";
import type { AnyAction } from "redux";
import type { ConfirmLeave } from "redux-first-router";
import { setCanNotLeave } from "common/shell/state/navigationActions";
import type { AppDispatch, GetState } from "store";

//Don't use an arrow function for this. For whatever reason, this get undefined in routes.js.
export function fixEntityIdPathSegment(pathSegment: string) {
    return pathSegment.replace("%40", "@");
}

export function fixListPathSegment(pathSegment: string) {
    return pathSegment.replace(/%2C/gi, ",").replace(/%40/gi, "@");
}

export const routeAction = (
    type: string,
    payload: any,
    query: any
): AnyAction => {
    const action: AnyAction = { type: type };
    if (payload) {
        action.payload = payload;
    }
    if (query) {
        action.meta = { query };
    }
    return action;
};

export const confirmLeave = (page: string[]): ConfirmLeave => {
    return (state: any, action: any) => {
        const canLeave = get(state, "navigation.canLeave");
        if (!page.includes(action.type) && !canLeave) {
            return "common:general.confirm_navigation_prompt";
        }
        return null;
    };
};

export const resetLeave = (dispatch: AppDispatch, getState: GetState) => {
    dispatch(setCanNotLeave());
};
