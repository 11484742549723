//@flow
import * as React from "react";
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import { FieldLabel } from "common/components/styled/Label";
import Flexbox from "common/components/styled/Flexbox";
import { FormRow, FormSection } from "common/components/styled/Form";
import NumberInput, {
    currencyFormatterBound,
    currencyParserBound
} from "common/components/NumberInput";
import SpecialCharsComponent from "common/components/SpecialCharsComponent";
import Tooltip from "common/components/Tooltip";
import { NUMBER_INPUT_STEP } from "common/components/WidgetModal";
import { notInDraftOrReview } from "campaign/components/campaignViewUtils";
import { getInstacartBasePriceComponent } from "campaign/components/CampaignPPGsView";
import type { RootState } from "store";

type ProductGroupFieldsOwnProps = {
    alignment: string;
    allowNameEdit: boolean;
    campaignProductGroup: CampaignProductGroup | null;
    onChange: (productGroupFields: any) => void;
    showFinancials: boolean;
    showInstacartBasePrice: boolean;
};

type ProductGroupFieldsProps = ProductGroupFieldsOwnProps &
    PropsFromRedux &
    WithTranslation;

class ProductGroupFields extends React.Component<ProductGroupFieldsProps> {
    nameInput: HTMLInputElement | undefined | null;
    offerDisplayNameInput: HTMLInputElement | undefined | null;

    static defaultProps = {
        alignment: "column",
        allowNameEdit: true,
        campaignProductGroup: null,
        showFinancials: false,
        showInstacartBasePrice: false
    };

    componentDidMount(): void {
        this.nameInput && this.nameInput.focus();
    }

    setNameInputRef = (input: HTMLInputElement) => {
        this.nameInput = input;
    };

    setOfferDisplayNameInputRef = (input: HTMLInputElement) => {
        this.offerDisplayNameInput = input;
    };

    onChangeName = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        const { onChange } = this.props;
        onChange({
            name: e.target.value
        });
    };

    onChangeOfferDisplayName = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        const { onChange } = this.props;
        onChange({
            offerDisplayName: e.target.value
        });
    };

    onPriceToConsumerChange = (value: number | null): void => {
        const { onChange } = this.props;
        let val = value;
        // @ts-expect-error "" returned
        if (val === "") {
            val = null;
        }
        onChange({
            priceToConsumer: val
        });
    };

    onPriceToRetailerChange = (value: number | null): void => {
        const { onChange } = this.props;
        let val = value;
        // @ts-expect-error "" returned
        if (val === "") {
            val = null;
        }
        onChange({
            priceToRetailer: val
        });
    };

    onPriceToWholesalerChange = (value: number | null): void => {
        const { onChange } = this.props;
        let val = value;
        // @ts-expect-error "" returned
        if (val === "") {
            val = null;
        }
        onChange({
            priceToWholesaler: val
        });
    };

    onManufacturerMarginChange = (value: number | null): void => {
        const { onChange } = this.props;
        let val = value;
        // @ts-expect-error "" returned
        if (val === "") {
            val = null;
        }
        onChange({
            manufacturerMargin: val
        });
    };

    onContributionPerUnitChange = (value: number | null): void => {
        const { onChange } = this.props;
        let val = value;
        // @ts-expect-error "" returned
        if (val === "") {
            val = null;
        }
        onChange({
            contributionPerUnit: val
        });
    };

    renderFinancialFields = (): React.ReactNode => {
        const {
            campaign,
            campaignProductGroup,
            currencySymbol,
            showFinancials,
            showInstacartBasePrice,
            t,
            tenantType
        } = this.props;
        const isCrossMerch = get(campaignProductGroup, "isCrossMerch");
        const contributionPerUnit = get(
            campaignProductGroup,
            "contributionPerUnit"
        );
        const manufacturerMargin = get(
            campaignProductGroup,
            "manufacturerMargin"
        );
        const ptc = get(campaignProductGroup, "priceToConsumer");
        const ptr = get(campaignProductGroup, "priceToRetailer");
        const ptw = get(campaignProductGroup, "priceToWholesaler");
        const basePriceUpToDate = get(
            campaignProductGroup,
            "basePriceUpToDate",
            true
        );
        const instacartBasePriceFormatCurrency = get(
            campaignProductGroup,
            "instacartBasePriceFormatCurrency",
            ""
        );
        if (showFinancials) {
            return (
                <React.Fragment>
                    <FormRow fieldLabelWidth="200px">
                        <FieldLabel required={true}>
                            {t("promotion.average_price_to_consumer")}
                        </FieldLabel>
                        <NumberInput
                            boxSizing="border-box"
                            cssWidth="100%"
                            formatter={currencyFormatterBound.bind(
                                null,
                                currencySymbol
                            )}
                            min={0.1}
                            name="avgPriceToConsumer"
                            onChange={this.onPriceToConsumerChange}
                            parser={currencyParserBound.bind(
                                null,
                                currencySymbol
                            )}
                            step={NUMBER_INPUT_STEP}
                            value={ptc}
                        />
                    </FormRow>
                    {campaign.hasOfferCost && showInstacartBasePrice && (
                        <FormRow fieldLabelWidth="200px">
                            <FieldLabel>
                                {t(
                                    "promotion.instacart_average_price_to_consumer"
                                )}
                            </FieldLabel>
                            <div>
                                {getInstacartBasePriceComponent(
                                    basePriceUpToDate,
                                    instacartBasePriceFormatCurrency,
                                    t
                                )}
                            </div>
                        </FormRow>
                    )}
                    {!isCrossMerch && (
                        <FormRow fieldLabelWidth="200px">
                            <FieldLabel>
                                {t("promotion.average_price_to_retailer")}
                            </FieldLabel>
                            <NumberInput
                                boxSizing="border-box"
                                cssWidth="100%"
                                formatter={currencyFormatterBound.bind(
                                    null,
                                    currencySymbol
                                )}
                                min={0.1}
                                name="avgPriceToRetailer"
                                onChange={this.onPriceToRetailerChange}
                                parser={currencyParserBound.bind(
                                    null,
                                    currencySymbol
                                )}
                                step={NUMBER_INPUT_STEP}
                                value={ptr}
                            />
                        </FormRow>
                    )}
                    {tenantType !== "TWO_TIER" && !isCrossMerch && (
                        <FormRow fieldLabelWidth="200px">
                            <FieldLabel>
                                {t("promotion.average_price_to_wholesaler")}
                            </FieldLabel>
                            <NumberInput
                                boxSizing="border-box"
                                cssWidth="100%"
                                formatter={currencyFormatterBound.bind(
                                    null,
                                    currencySymbol
                                )}
                                min={0.1}
                                name="avgPriceToWholesaler"
                                onChange={this.onPriceToWholesalerChange}
                                parser={currencyParserBound.bind(
                                    null,
                                    currencySymbol
                                )}
                                step={NUMBER_INPUT_STEP}
                                value={ptw}
                            />
                        </FormRow>
                    )}
                    {!isCrossMerch && (
                        <FormRow fieldLabelWidth="200px">
                            <FieldLabel>
                                {t("promotion.manufacturer_margin")}
                            </FieldLabel>
                            <NumberInput
                                boxSizing="border-box"
                                cssWidth="100%"
                                formatter={currencyFormatterBound.bind(
                                    null,
                                    currencySymbol
                                )}
                                min={0.1}
                                name="manufacturerMargin"
                                onChange={this.onManufacturerMarginChange}
                                parser={currencyParserBound.bind(
                                    null,
                                    currencySymbol
                                )}
                                step={NUMBER_INPUT_STEP}
                                value={manufacturerMargin}
                            />
                        </FormRow>
                    )}
                    {isCrossMerch && (
                        <FormRow fieldLabelWidth="200px">
                            <FieldLabel required={true}>
                                {t("promotion.contribution_per_unit")}
                            </FieldLabel>
                            <NumberInput
                                boxSizing="border-box"
                                cssWidth="100%"
                                formatter={currencyFormatterBound.bind(
                                    null,
                                    currencySymbol
                                )}
                                min={0.1}
                                name="contributionPerUnit"
                                onChange={this.onContributionPerUnitChange}
                                parser={currencyParserBound.bind(
                                    null,
                                    currencySymbol
                                )}
                                step={NUMBER_INPUT_STEP}
                                value={contributionPerUnit}
                            />
                        </FormRow>
                    )}
                </React.Fragment>
            );
        }
        return null;
    };

    render() {
        const {
            alignment,
            allowNameEdit,
            campaign,
            campaignProductGroup = {},
            t
        } = this.props;

        const status = get(campaign, "state.value");
        const allowDisplayNameEdit = !notInDraftOrReview(status);

        if (!isEmpty(campaignProductGroup)) {
            let nameField;
            let displayNameField;

            const name = get(campaignProductGroup, "name");
            const offerDisplayName = get(
                campaignProductGroup,
                "offerDisplayName"
            );

            if (allowNameEdit) {
                nameField = (
                    <SpecialCharsComponent
                        isTextArea={false}
                        id="name"
                        onChange={this.onChangeName}
                        value={name}
                        wrapperProps={{
                            cssWidth: "376px",
                            tabIndex: 1
                        }}
                    />
                );
            } else {
                nameField = (
                    <Tooltip
                        checkOverflow={true}
                        cssWidth="100%"
                        ignoreHeightOverflow={true}
                        text={name}
                    />
                );
            }

            if (allowDisplayNameEdit) {
                displayNameField = (
                    <SpecialCharsComponent
                        isTextArea={false}
                        id="offerDisplayName"
                        onChange={this.onChangeOfferDisplayName}
                        value={offerDisplayName}
                        wrapperProps={{
                            cssWidth: "320px"
                        }}
                    />
                );
            } else {
                displayNameField = (
                    <Tooltip
                        checkOverflow={true}
                        cssWidth="100%"
                        ignoreHeightOverflow={true}
                        text={offerDisplayName}
                    />
                );
            }

            if (alignment === "column") {
                return (
                    <FormSection rowMarginBottom="12px">
                        <FormRow fieldLabelWidth="200px">
                            <FieldLabel
                                minWidth="200px"
                                required={allowNameEdit ? true : false}
                            >
                                {t("common:general.name")}
                            </FieldLabel>
                            {nameField}
                        </FormRow>
                        <FormRow fieldLabelWidth="200px">
                            <FieldLabel
                                required={allowDisplayNameEdit ? true : false}
                            >
                                {t("promotion.offer_display_name")}
                            </FieldLabel>
                            {displayNameField}
                        </FormRow>
                        {this.renderFinancialFields()}
                    </FormSection>
                );
            } else {
                return (
                    <Flexbox flexDirection="row">
                        <FormSection rowMarginBottom="12px">
                            <FormRow fieldLabelWidth="200px" flexWrap="nowrap">
                                <FieldLabel
                                    minWidth="200px"
                                    required={allowNameEdit ? true : false}
                                >
                                    {t("common:general.name")}
                                </FieldLabel>
                                {nameField}
                            </FormRow>
                            <FormRow fieldLabelWidth="200px">
                                <FieldLabel
                                    required={
                                        allowDisplayNameEdit ? true : false
                                    }
                                >
                                    {t("promotion.offer_display_name")}
                                </FieldLabel>
                                {displayNameField}
                            </FormRow>
                        </FormSection>{" "}
                        <FormSection
                            rowMarginBottom="12px"
                            marginTop="0px"
                            marginLeft="24px"
                        >
                            {this.renderFinancialFields()}
                        </FormSection>
                    </Flexbox>
                );
            }
        }
        return null;
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        campaign: get(state, "campaign"),
        currencySymbol: get(state, "campaign.couponCurrencySymbol"),
        tenantType: get(state, "init.tenant.type")
    };
};

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(ProductGroupFields));
