import type { AnyAction } from "redux";
import { actionTokenDownloadThunk } from "common/shell/state/fileActions";
import {
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import {
    EVENTS_VIEW_COMPLIANCE_MODE,
    EVENTS_VIEW_TEST_MODE,
    getAggregatedEventAdsRequest,
    getEventsDetailRequest,
    reduceEventItem
} from "event/state/eventsStateUtils";
import type { EventsViewMode } from "event/state/eventsStateUtils";
import type { RootState } from "store";

import { EVENTS_PAGE } from "shell/state/pageActions";

export const CLEAR_AGGREGATED_ADS = "CLEAR_AGGREGATED_ADS";
export const RECEIVE_AGGREGATED_ADS = "RECEIVE_AGGREGATED_ADS";
export const RECEIVE_EVENTS = "RECEIVE_EVENTS";
export const REQUEST_EVENTS = "REQUEST_EVENTS";
export const UPDATE_EVENTS_ADDITIONAL_DEFAULT_COLUMNS =
    "UPDATE_EVENTS_ADDITIONAL_DEFAULT_COLUMNS";
export const UPDATE_EVENTS_AGGREGATE = "UPDATE_EVENTS_AGGREGATE";
export const UPDATE_EVENTS_GROUP_ADS = "UPDATE_EVENTS_GROUP_ADS";
export const UPDATE_EVENTS_OFFSET = "UPDATE_EVENTS_OFFSET";
export const UPDATE_EVENTS_SORT = "UPDATE_EVENTS_SORT";

export const eventsPage = (
    mode?: EventsViewMode,
    aggregations?: string[] | null
): AnyAction => ({
    type: EVENTS_PAGE,
    aggregations,
    mode
});

export const complianceEventsPage = (
    aggregations?: string[] | null
): AnyAction => eventsPage(EVENTS_VIEW_COMPLIANCE_MODE, aggregations);

export const testEventsPage = (aggregations?: string[] | null): AnyAction =>
    eventsPage(EVENTS_VIEW_TEST_MODE, aggregations);

export const requestEvents = (): AnyAction => ({
    type: REQUEST_EVENTS
});

export const receiveEvents = (json: Json): AnyAction => ({
    type: RECEIVE_EVENTS,
    events: json
});

export const clearAggregatedAds = (): AnyAction => ({
    type: CLEAR_AGGREGATED_ADS
});

export const receiveAggregatedAds = (json: Json): AnyAction => ({
    type: RECEIVE_AGGREGATED_ADS,
    aggregatedAds: json
});

export const getEventsExportRequest = (
    state: RootState,
    outFields: string[]
): any => {
    const eventsRequest = getEventsDetailRequest(state);
    const outFieldsString = outFields.join(",");
    return {
        ...eventsRequest,
        offset: 0,
        outFields: outFieldsString,
        pageSize: -1
    };
};

export function fetchEvents() {
    return makeRequestThunk("api/matrix/events", {
        bodyFunc: function (state: RootState): any {
            return getEventsDetailRequest(state);
        },
        method: METHOD_POST,
        preRequestFunc: requestEvents,
        okDispatchFunc: receiveEvents,
        okResultFunc: (json: Json, state: RootState): any => {
            return {
                items: json.items.map(reduceEventItem),
                filteredCount: json.totalItems
            };
        },
        showLoadMask: false
    });
}

export function fetchAdsForAggregatedEvents(row: any) {
    return makeRequestThunk("api/matrix/events", {
        bodyFunc: function (state: RootState): any {
            return getAggregatedEventAdsRequest(state, row);
        },
        method: METHOD_POST,
        preRequestFunc: clearAggregatedAds,
        okDispatchFunc: receiveAggregatedAds
    });
}

export const updateEventsAdditionalDefaultColumns = (
    additionalDefaultColumns: string[]
): AnyAction => ({
    type: UPDATE_EVENTS_ADDITIONAL_DEFAULT_COLUMNS,
    additionalDefaultColumns: additionalDefaultColumns
});

export const updateEventsAggregate = (groupByFields: string): AnyAction => ({
    type: UPDATE_EVENTS_AGGREGATE,
    groupByFields: groupByFields
});

export const updateEventsGroupAds = (groupAds: boolean): AnyAction => ({
    type: UPDATE_EVENTS_GROUP_ADS,
    groupAds: groupAds
});

export const updateEventsOffset = (offset: number): AnyAction => ({
    type: UPDATE_EVENTS_OFFSET,
    offset: offset
});

export const updateEventsSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => ({
    type: UPDATE_EVENTS_SORT,
    sortBy: sortBy,
    sortOrder: sortOrder
});

// Download event data - for eversight users
export function downloadEventData(eventId: string) {
    return actionTokenDownloadThunk(
        "api/matrix/debug/download/init/" + eventId,
        null,
        "api/matrix/debug/download?otat="
    );
}
