import * as React from "react";
import type { TFunction } from "i18next";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import isNil from "lodash.isnil";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import styled from "styled-components/macro";
import {
    DropdownContainer,
    DropdownContent,
    DropdownGroupWrapper,
    DropdownHeader,
    DropdownItem,
    DropdownSelectedItem,
    DropdownSeparator,
    DropdownText,
    POSITION_LEFT,
    POSITION_BELOW
} from "common/components/Dropdown";
import type {
    HorizontalPosition,
    VerticalPosition
} from "common/components/Dropdown";
import SvgIcon from "common/components/SvgIcon";
import Flexbox from "common/components/styled/Flexbox";
import { marginPropTypes } from "common/components/styled/util";
import theme from "common/components/theme";
import { ReactComponent as CalendarIcon } from "common/icons/Calendar.svg";
import DateRangeFilterDropdownView from "common/filter/components/DateRangeFilterDropdownView";
import { FilterDropdownTrigger } from "common/filter/components/FilterDropdownView";
import type { FilterDropdownTriggerProps } from "common/filter/components/FilterDropdownView";
import FilterDropdownIcon from "common/filter/components/FilterDropdownIcon";
import { FilterApplyButtonComponent } from "common/filter/components/FilterDropdownView";
import {
    getDateForDatePicker,
    getDateStringFromDate,
    getEndYear,
    staticDateRange2Str
} from "common/util/date";
import { isEmptyString } from "common/util/lang";
import { reduceObject } from "common/util/object";
import { containsIgnoreReactOutsideClick } from "common/util/outsideClick";
import {
    composeTrackingComponentLabel,
    getTrackingEventData,
    trackEvent
} from "common/util/tracking";
import { EVENT_NAME_FILTER_APPLY } from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";
import {
    ALL_TIME,
    COMPARISON_NONE,
    COMPARISON_PRIOR_PERIOD,
    COMPARISON_SAME_PERIOD_PRIOR_YEAR,
    CUSTOM,
    LAST_365_DAYS,
    LAST_365_DAYS_YEAR_AGO,
    LAST_3_MONTHS,
    LAST_6_MONTHS,
    LAST_MONTH,
    LAST_WEEK,
    LAST_YEAR,
    LAST_YEAR_YEAR_TO_DATE,
    SINCE_1_YEAR_AGO,
    YEAR_BEFORE_LAST,
    YEAR_TO_DATE
} from "common/util/date";
import type { DateFilter, DateMenuType, DateType } from "common/util/date";
import { dateRange2Str } from "common/util/format";
import { MATRIX, PRICE } from "common/util/url";
import type { AppType } from "common/util/url";

const DateDropdownContent = styled(DropdownContent)`
    width: 316px;
    /* must have both or IE puts scrollbars and doesn't render calendar popper correctly */
    overflow-x: visible;
    overflow-y: visible;
`;

DateDropdownContent.displayName = "DateDropdownContent";

type DateDropdownTriggerProps = {
    buttonWidth?: string;
} & FilterDropdownTriggerProps;

const DateDropdownTrigger = styled(
    FilterDropdownTrigger
)<DateDropdownTriggerProps>`
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
    ${props => !props.buttonWidth && "width: 190px;"}
`;

DateDropdownTrigger.displayName = "DateDropdownTrigger";

const DateRangeContent = styled.div`
    height: 34px;
    padding: 16px;
`;

type DateFilterDropdownOwnProps = {
    app: AppType;
    buttonWidth?: string;
    customMenuItems?: DateMenuItemType[];
    disabled?: boolean;
    endYear?: number;
    horizontalPosition?: HorizontalPosition;
    highlightBackground?: boolean;
    highlightTrigger?: boolean;
    maxDate?: string;
    menuHeaderText?: string;
    menuType?: DateMenuType;
    noMaxDate?: boolean;
    onDateFilterChange: (date: DateFilter) => void;
    onlyFutureDates?: boolean;
    selected: DateFilter | undefined | null;
    showCalendarIcon?: boolean;
    showDates?: boolean;
    showMenuHeader?: boolean;
    trackingComponentLabel: TrackingComponentLabel;
    verticalPosition?: VerticalPosition;
} & marginPropsType;
type DateFilterDropdownProps = DateFilterDropdownOwnProps & WithTranslation;

type DateFilterDropdownState = {
    customType: DateType;
    endDate?: Date | null;
    isCustomSelected?: boolean;
    isOpen?: boolean;
    previousEndDate?: Date | null;
    previousSelectedMenuId?: string;
    previousStartDate?: Date | null;
    startDate?: Date | null;
};

class DateFilterDropdown extends React.Component<
    DateFilterDropdownProps,
    DateFilterDropdownState
> {
    static displayName = "DateFilterDropdown";
    static defaultProps = {
        disabled: false,
        highlightBackground: true,
        horizontalPosition: POSITION_LEFT,
        noMaxDate: false,
        onlyFutureDates: false,
        verticalPosition: POSITION_BELOW,
        showCalendarIcon: true,
        showDates: false,
        showMenuHeader: true
    };

    constructor(props: DateFilterDropdownProps) {
        super(props);
        const { selected } = this.props;
        const st: any = {
            isOpen: false,
            isCustomSelected: false,
            customType: CUSTOM
        };
        const selectedTime = get(selected, "time");
        if (this.isMenuItemCustom(selectedTime)) {
            st.isCustomSelected = true;
            st.endDate = getDateForDatePicker(get(selected, "endDate"));
            st.startDate = getDateForDatePicker(get(selected, "startDate"));
        }
        this.state = st;
    }

    componentDidUpdate(prevProps: DateFilterDropdownProps) {
        const { selected } = this.props;
        const { endDate, startDate } = this.state;
        const selectedTime = get(selected, "time");
        const prevSelectedTime = get(prevProps, "selected.time");
        // if we changed from custom to some other date type, reset date in state
        if (
            this.isMenuItemCustom(prevSelectedTime) &&
            prevSelectedTime !== selectedTime
        ) {
            this.setState({
                endDate: null,
                isCustomSelected: false,
                startDate: null
            });
        }
        // Custom date can be manually set and in those cases we need to update the state
        if (this.isMenuItemCustom(selectedTime) && (!endDate || !startDate)) {
            const updatedStartDate = !startDate
                ? getDateForDatePicker(get(selected, "startDate"))
                : startDate;
            const updatedEndDate = !endDate
                ? getDateForDatePicker(get(selected, "endDate"))
                : endDate;
            if (updatedStartDate !== startDate || updatedEndDate !== endDate) {
                this.setState({
                    isCustomSelected: true,
                    endDate: updatedEndDate,
                    startDate: updatedStartDate
                });
            }
        }
    }

    trackDateChange = (time: DateType) => {
        const { trackingComponentLabel } = this.props;
        // Do not send selected option value for now as id/enum is not too useful for analytics
        // tracking and we do not want to accidentally send sensitive value information out
        trackEvent(
            getTrackingEventData(
                DateFilterDropdown.displayName,
                composeTrackingComponentLabel([
                    trackingComponentLabel,
                    "Date Filter"
                ]),
                EVENT_NAME_FILTER_APPLY
                //time
            )
        );
    };

    onDatesChange = (dates: any) => {
        const { onDateFilterChange } = this.props;
        const { customType } = this.state;
        const { endDate, startDate } = dates;
        this.trackDateChange(customType);
        if (startDate && endDate && this.isDateRangeValid(startDate, endDate)) {
            const dateLabel = dateRange2Str(startDate, endDate);
            onDateFilterChange({
                time: customType,
                startDate: getDateStringFromDate(startDate),
                endDate: getDateStringFromDate(endDate),
                label: dateLabel
            });
            this.setState({
                isOpen: false,
                // good dates, set previous values
                previousEndDate: endDate,
                previousStartDate: startDate
            });
        }
    };

    onStartDateChange = (date: Date) => {
        this.setState({ startDate: date });
    };

    onEndDateChange = (date: Date) => {
        this.setState({ endDate: date });
    };

    onClickHandler = (menuItem: any) => {
        if (this.isMenuItemCustom(menuItem.id)) {
            this.setState({
                isOpen: true,
                isCustomSelected: true
            });
        } else {
            // not custom, zero out start and end dates and set isCustomSelected to false
            this.setState({
                endDate: null,
                isOpen: false,
                isCustomSelected: false,
                startDate: null
            });
            const selected = this.props.selected;
            if (selected && selected.time !== menuItem.id) {
                // set previous menu id for when we have to revert back to good known state
                this.setState({
                    previousSelectedMenuId: menuItem.id
                });
                this.trackDateChange(menuItem.id);
                this.props.onDateFilterChange({
                    time: menuItem.id,
                    label: menuItem.label
                });
            }
        }
    };

    onTriggerClick = (evt: React.SyntheticEvent<HTMLDivElement>) => {
        if (get(evt, "currentTarget.attributes.disabled")) {
            evt.stopPropagation();
        } else {
            if (this.state.isOpen) {
                this.setState({ isOpen: false });
            } else {
                this.setState({ isOpen: true });
            }
        }
    };

    onOutsideClick = (event: MouseEvent) => {
        const { endDate, isCustomSelected, previousSelectedMenuId, startDate } =
            this.state;
        const { app, menuType, t } = this.props;
        const dateMenuType = menuType ? menuType : app;
        if (!containsIgnoreReactOutsideClick(event)) {
            if (isCustomSelected && (!startDate || !endDate)) {
                // custom was selected but both dates were not selected
                // need to revert back to previously good state
                const { previousEndDate, previousStartDate } = this.state;
                if (isCustomSelected && previousEndDate && previousStartDate) {
                    // last good state was custom with valid dates
                    // reset start and end dates back to previous start and end dates
                    this.setState({
                        endDate: previousEndDate,
                        startDate: previousStartDate
                    });
                } else {
                    // go back to previous menu choice
                    const menuItems = this.getMenuItems(t, dateMenuType);
                    if (menuItems.length > 0) {
                        // reset start and end date, and make sure it's custom selected = false
                        this.setState({
                            endDate: null,
                            isCustomSelected: false,
                            startDate: null
                        });
                        let menuItem = menuItems[0];
                        if (previousSelectedMenuId) {
                            // find appropriate menu item
                            const previousSelectedMenu = menuItems.find(
                                menuItem => {
                                    return (
                                        menuItem.id === previousSelectedMenuId
                                    );
                                }
                            );
                            if (previousSelectedMenu) {
                                menuItem = previousSelectedMenu;
                            }
                        }
                        this.onClickHandler(menuItem);
                    }
                }
            } else if (isCustomSelected && startDate && endDate) {
                // don't requery if dropdown isn't open
                if (this.state.isOpen) {
                    this.onDatesChange({ startDate, endDate });
                }
            }
            if (this.state.isOpen) {
                this.setState({ isOpen: false });
            }
        }
    };

    isDateRangeValid = (
        startDate?: Date | null,
        endDate?: Date | null
    ): boolean => {
        const { isCustomSelected } = this.state;
        if (
            isCustomSelected &&
            endDate &&
            startDate &&
            moment(endDate).isSameOrAfter(startDate)
        ) {
            return true;
        }
        return false;
    };

    onApply = (evt: React.SyntheticEvent<HTMLButtonElement>) => {
        const { endDate, startDate } = this.state;
        this.onDatesChange({ startDate, endDate });
    };

    getMatrixMenuItems = (t: TFunction): DateMenuItemType[] => {
        return [
            {
                id: LAST_6_MONTHS,
                label: t("common:date.last_6_months")
            },
            {
                id: YEAR_TO_DATE,
                label: t("common:date.year_to_date")
            },
            {
                id: LAST_YEAR_YEAR_TO_DATE,
                label: t("common:date.last_year_ytd")
            },
            {
                id: LAST_YEAR,
                label: t("common:date.last_year")
            },
            {
                id: YEAR_BEFORE_LAST,
                label: t("common:date.year_before_last")
            },
            {
                id: LAST_365_DAYS,
                label: t("common:date.last_365_days")
            },
            {
                id: LAST_365_DAYS_YEAR_AGO,
                label: t("common:date.last_365_days_year_ago")
            },
            {
                id: CUSTOM,
                label: t("common:date.custom_date_range")
            }
        ];
    };

    getPriceMenuItems = (t: TFunction): DateMenuItemType[] => {
        return [
            {
                id: LAST_MONTH,
                label: t("common:date.last_month")
            },
            {
                id: LAST_WEEK,
                label: t("common:date.last_week")
            },
            {
                id: CUSTOM,
                label: t("common:date.custom_date_range")
            }
        ];
    };

    getMenuItems = (
        t: TFunction,
        menuType: DateMenuType
    ): DateMenuItemType[] => {
        const { customMenuItems } = this.props;
        switch (menuType) {
            case MATRIX:
                return this.getMatrixMenuItems(t);
            case PRICE:
                return this.getPriceMenuItems(t);
            case CUSTOM:
                return customMenuItems ? customMenuItems : [];
            default:
                return this.getMatrixMenuItems(t);
        }
    };

    isMenuItemCustom = (type?: string): boolean => {
        if (type) {
            return type === CUSTOM;
        }
        return false;
    };

    render() {
        const {
            app,
            buttonWidth,
            disabled,
            endYear,
            highlightBackground,
            highlightTrigger,
            maxDate,
            menuHeaderText,
            menuType,
            noMaxDate,
            onlyFutureDates,
            selected,
            showCalendarIcon,
            showDates,
            showMenuHeader,
            t,
            trackingComponentLabel
        } = this.props;
        const { endDate, startDate } = this.state;
        const dateMenuType = menuType ? menuType : app;
        const menuItems = this.getMenuItems(t, dateMenuType);
        let dropdownLabel = "";
        let dateLabel = "";
        if (selected) {
            dateLabel = staticDateRange2Str(selected.time);
            switch (selected.time) {
                case ALL_TIME:
                    dropdownLabel = t("common:date.all_time");
                    break;
                case COMPARISON_NONE:
                    dropdownLabel = t("common:general.none");
                    break;
                case COMPARISON_PRIOR_PERIOD:
                    dropdownLabel = t("common:date.prior_period");
                    break;
                case COMPARISON_SAME_PERIOD_PRIOR_YEAR:
                    dropdownLabel = t("common:date.same_period_prior_year");
                    break;
                case CUSTOM:
                    dropdownLabel = t("common:date.custom_date_range");
                    if (startDate && endDate) {
                        dateLabel = dateRange2Str(startDate, endDate);
                    }
                    break;
                case LAST_365_DAYS:
                    dropdownLabel = t("common:date.last_365_days");
                    break;
                case LAST_365_DAYS_YEAR_AGO:
                    dropdownLabel = t("common:date.last_365_days_year_ago");
                    break;
                case LAST_3_MONTHS:
                    dropdownLabel = t("common:date.last_3_months");
                    break;
                case LAST_6_MONTHS:
                    dropdownLabel = t("common:date.last_6_months");
                    break;
                case LAST_MONTH:
                    dropdownLabel = t("common:date.last_month");
                    break;
                case LAST_WEEK:
                    dropdownLabel = t("common:date.last_week");
                    break;
                case LAST_YEAR_YEAR_TO_DATE:
                    dropdownLabel = t("common:date.last_year_ytd");
                    break;
                case LAST_YEAR:
                    dropdownLabel = t("common:date.last_year");
                    break;
                case SINCE_1_YEAR_AGO:
                    dropdownLabel = t("common:date.since_1_year_ago");
                    break;
                case YEAR_BEFORE_LAST:
                    dropdownLabel = t("common:date.year_before_last");
                    break;
                case YEAR_TO_DATE:
                    dropdownLabel = t("common:date.year_to_date");
                    break;
                default:
                    if (selected.label) {
                        dropdownLabel = selected.label;
                    } else {
                        const selectedMenuItem = menuItems.find(
                            menuItem => menuItem.id === selected.time
                        );
                        if (selectedMenuItem) {
                            dropdownLabel = selectedMenuItem.label || "";
                        }
                    }
                    break;
            }
        } else {
            dropdownLabel = t("common:date.last_365_days");
        }

        const dateChooserEndYear = endYear ? endYear : getEndYear(0);

        const dateRangeValid = this.isDateRangeValid(startDate, endDate);

        const today = new Date();
        let calculatedMaxDate = getDateForDatePicker(maxDate);
        if (!calculatedMaxDate) {
            calculatedMaxDate = today;
        }
        if (noMaxDate) {
            calculatedMaxDate = null;
        }
        let maxDateForStartDate = calculatedMaxDate;
        if (this.state.endDate) {
            maxDateForStartDate = this.state.endDate;
        }

        let minDate = null;
        if (onlyFutureDates) {
            minDate = today;
        }
        let minDateForEndDate = minDate;
        if (this.state.startDate) {
            minDateForEndDate = this.state.startDate;
        }

        // Only clearable if new date is different from initial selected date
        const isStartDateClearable = !isEqual(
            startDate,
            getDateForDatePicker(get(selected, "startDate"))
        );
        const isEndDateClearable = !isEqual(
            endDate,
            getDateForDatePicker(get(selected, "endDate"))
        );

        const FilterTrigger = (
            <DateDropdownTrigger
                buttonWidth={buttonWidth}
                color={theme.text}
                disabled={disabled}
                highlightBackground={highlightBackground}
                highlightSelected={highlightTrigger}
                isSelected={highlightTrigger}
                onClick={this.onTriggerClick}
            >
                <Flexbox cssWidth={"100%"} justifyContent={"space-between"}>
                    <DropdownText>
                        <Flexbox>
                            <Flexbox>
                                {showCalendarIcon && (
                                    <SvgIcon
                                        color={
                                            disabled
                                                ? theme.textDisabled
                                                : theme.iconMenu
                                        }
                                        icon={CalendarIcon}
                                        marginRight="8px"
                                    />
                                )}
                            </Flexbox>
                            <span>{dropdownLabel}</span>
                            {showDates && dateLabel && (
                                <Flexbox color={theme.subText} marginLeft="8px">
                                    {t("common:general.parenthesis_text", {
                                        text: dateLabel
                                    })}
                                </Flexbox>
                            )}
                        </Flexbox>
                    </DropdownText>
                    <FilterDropdownIcon />
                </Flexbox>
            </DateDropdownTrigger>
        );
        const menuHeader =
            !isNil(menuHeaderText) && !isEmptyString(menuHeaderText)
                ? menuHeaderText
                : t("common:general.date_plural");
        const containsMenuItems = menuItems.length > 0;

        return (
            <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
                <DropdownContainer
                    backgroundColor={theme.background}
                    {...reduceObject(this.props, ...marginPropTypes, [
                        "buttonWidth",
                        "cssWidth"
                    ])}
                >
                    {FilterTrigger}
                    {this.state.isOpen && (
                        <DateDropdownContent
                            {...reduceObject(
                                this.props,
                                "horizontalPosition",
                                "verticalPosition"
                            )}
                        >
                            {showMenuHeader && (
                                <React.Fragment>
                                    <DropdownHeader>
                                        {menuHeader}
                                    </DropdownHeader>
                                    <DropdownSeparator />
                                </React.Fragment>
                            )}
                            <DropdownGroupWrapper>
                                {menuItems.map(menuItem => {
                                    const MenuItemComponent =
                                        (selected &&
                                            menuItem.id === selected.time &&
                                            !this.state.isCustomSelected) ||
                                        (this.isMenuItemCustom(menuItem.id) &&
                                            this.state.isCustomSelected)
                                            ? DropdownSelectedItem
                                            : DropdownItem;
                                    return (
                                        <MenuItemComponent
                                            key={menuItem.id}
                                            data-id={menuItem.id}
                                            onClick={this.onClickHandler.bind(
                                                null,
                                                menuItem
                                            )}
                                        >
                                            {menuItem.label}
                                        </MenuItemComponent>
                                    );
                                })}
                                {this.state.isCustomSelected && (
                                    <React.Fragment>
                                        {containsMenuItems && (
                                            <DropdownSeparator />
                                        )}
                                        <DateRangeContent>
                                            <Flexbox justifyContent="space-between">
                                                <DateRangeFilterDropdownView
                                                    endDate={this.state.endDate}
                                                    endYear={dateChooserEndYear}
                                                    isEndDateClearable={
                                                        isEndDateClearable
                                                    }
                                                    isStartDateClearable={
                                                        isStartDateClearable
                                                    }
                                                    maxEndDate={
                                                        calculatedMaxDate
                                                    }
                                                    maxStartDate={
                                                        maxDateForStartDate
                                                    }
                                                    minEndDate={
                                                        minDateForEndDate
                                                    }
                                                    minStartDate={minDate}
                                                    onEndDateChange={
                                                        this.onEndDateChange
                                                    }
                                                    onStartDateChange={
                                                        this.onStartDateChange
                                                    }
                                                    startDate={
                                                        this.state.startDate
                                                    }
                                                    trackingComponentLabel={
                                                        trackingComponentLabel
                                                    }
                                                />
                                            </Flexbox>
                                        </DateRangeContent>
                                    </React.Fragment>
                                )}
                            </DropdownGroupWrapper>
                            {this.state.isCustomSelected && (
                                <FilterApplyButtonComponent
                                    disabled={!dateRangeValid}
                                    onClick={this.onApply}
                                    trackingComponentLabel={composeTrackingComponentLabel(
                                        [trackingComponentLabel, "Date Filter"]
                                    )}
                                >
                                    {t("common:general.apply")}
                                </FilterApplyButtonComponent>
                            )}
                        </DateDropdownContent>
                    )}
                </DropdownContainer>
            </OutsideClickHandler>
        );
    }
}

export default withTranslation()(DateFilterDropdown);
