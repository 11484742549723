import isNumber from "lodash.isnumber";

export const isEmptyString = (value: any): boolean => {
    return typeof value === "string" && value.trim() === "";
};

export const emptyFn = () => undefined;

export const emptyIdFn = (id: string) => undefined;

export const nonNullable = <T>(value: T): value is NonNullable<T> => {
    return value !== null && value !== undefined;
};

export const isInteger = (value: any): boolean => {
    return (
        isNumber(value) && Number.isFinite(value) && Math.floor(value) === value
    );
};
