import get from "lodash.get";
import { DEFAULT_PAGE_SIZE, DESCENDING } from "common/util/query";
import { OPPORTUNITIES } from "filter/state/filterActions";
import { buildTestStatusFilter } from "filter/utils/showFilterUtils";
import type { RootState } from "store";

// Consts for tested show filter
export const EXPIRED = "EXPIRED" as string;
export const TESTED = "TESTED" as string;
export const UNTESTED = "UNTESTED" as string;

export type TestStatusValue = typeof EXPIRED | typeof TESTED | typeof UNTESTED;

// Data keys for data and sorting and column chooser
export const EVENT_WEEKS = "eventWeeks";
export const EVENT_WEEKS_TESTED = "eventWeeksTested";
export const EVENT_WEEKS_UNTESTED = "eventWeeksUntested";
export const TEST_COVERAGE = "TEST_COVERAGE";

export type OpportunitiesOverview = {
    groupByFields: string;
    maxEventWeeks: number;
    offset: number;
    pageSize: number;
    sortBy: string;
    sortOrder: SortOrder;
    totalCount: number;
};

export const defaultOpportunitiesOverviewState: OpportunitiesOverview = {
    groupByFields: "",
    maxEventWeeks: 0,
    offset: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    sortBy: EVENT_WEEKS,
    sortOrder: DESCENDING,
    totalCount: -1
};

export const reduceOverview = (
    state: any,
    overview: any
): OpportunitiesOverview => {
    return {
        groupByFields: get(state, "groupByFields"),
        maxEventWeeks: overview.maxEventWeeks,
        offset: overview.offset,
        pageSize: overview.pageSize,
        sortBy: get(state, "sortBy"),
        sortOrder: get(state, "sortOrder"),
        totalCount: overview.totalItems
    };
};

export const getOpportunitiesOverviewRequest = (state: RootState): any => {
    return {
        endDate: get(state, ["filters", OPPORTUNITIES, "date", "endDate"]),
        groupByFields: get(state, "opportunities.groupByFields"),
        holidayIds: get(state, ["filters", OPPORTUNITIES, "holidays"]),
        managerIds: get(state, ["filters", OPPORTUNITIES, "managers"]),
        objectType: "TestingOpportunitiesRequest",
        offset: get(state, "opportunities.offset"),
        pageSize: get(state, "opportunities.pageSize"),
        productGroupIds: get(state, ["filters", OPPORTUNITIES, "products"]),
        regionIds: get(state, ["filters", OPPORTUNITIES, "regions"]),
        retailerIds: get(state, ["filters", OPPORTUNITIES, "retailers"]),
        search: get(state, ["filters", OPPORTUNITIES, "search"]),
        sortBy: get(state, "opportunities.sortBy"),
        sortOrder: get(state, "opportunities.sortOrder"),
        startDate: get(state, ["filters", OPPORTUNITIES, "date", "startDate"]),
        testStatusFilter: buildTestStatusFilter(
            get(state, ["filters", OPPORTUNITIES, "showTested"], [])
        ),
        timePeriod: {
            objectType: "MatrixTimePeriod",
            value: get(state, ["filters", OPPORTUNITIES, "date", "time"])
        }
    };
};
