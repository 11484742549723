import type { AnyAction } from "redux";
import { RECEIVE_RAVEN } from "common/shell/state/ravenActions";
import noAction from "common/util/noAction";
import { reduceObject } from "common/util/object";

type RavenState = {
    configUrl: string;
    enabled: boolean;
};

export const defaultState: RavenState = {
    configUrl: "",
    enabled: false
};

const ravenReducer = (
    state: RavenState = defaultState,
    action: AnyAction = noAction
): RavenState => {
    switch (action.type) {
        case RECEIVE_RAVEN:
            return Object.assign(
                {},
                state,
                reduceObject(action.raven, "configUrl", "enabled")
            );
        default:
            return state;
    }
};

export default ravenReducer;
