import type { AnyAction } from "redux";
import get from "lodash.get";
import {
    RECEIVE_UNIQUE_NAME,
    REQUEST_UNIQUE_NAME
} from "common/shell/state/entityActions";

type EntityState = {
    uniqueName: string;
};

export const defaultState = {
    uniqueName: ""
};

const entity = (state: EntityState = defaultState, action: AnyAction) => {
    switch (action.type) {
        case RECEIVE_UNIQUE_NAME:
            return Object.assign({}, state, {
                uniqueName: get(action, "json.data")
            });
        case REQUEST_UNIQUE_NAME:
            return defaultState;
        default:
            return state;
    }
};

export default entity;
