import * as React from "react";
import { withCookies } from "react-cookie";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import AuthDialogPage, {
    AUTH_BUTTON_WIDTH,
    AUTH_INPUT_WIDTH,
    AuthError
} from "common/auth/components/AuthDialogPage";
import {
    forgotPassword,
    getUserNameFromCookie,
    removeUserNameCookie,
    setUserNameCookie,
    login
} from "common/auth/state/authActions";
import Button from "common/components/Button";
import Checkbox from "common/components/Checkbox";
import Flexbox from "common/components/styled/Flexbox";
import Input from "common/components/styled/Input";
import { FieldLabel } from "common/components/styled/Label";
import Link from "common/components/styled/Link";
import type { Cookies, HasCookies } from "common/shell/util/types";
import { EVENT_NAME_PAGE_NAVIGATION } from "common/util/trackingEvents";
import type { AppDispatch, RootState } from "store";

type LoginDialogProps = PropsFromRedux & HasCookies & WithTranslation;

type LoginDialogState = {
    password: string;
    remember: boolean;
    userName: string;
};

class LoginDialog extends React.Component<LoginDialogProps, LoginDialogState> {
    static displayName = "LoginDialog";
    passwordInput: HTMLInputElement | undefined | null;
    userNameInput: HTMLInputElement | undefined | null;

    constructor(props: LoginDialogProps) {
        super(props);
        const userName = getUserNameFromCookie(props.cookies) || "";
        this.state = {
            userName: userName,
            remember: userName.length > 0,
            password: ""
        };
    }

    componentDidMount() {
        if (this.userNameInput && !this.userNameInput.value) {
            this.userNameInput.focus();
        } else if (this.passwordInput) {
            this.passwordInput.focus();
        }
    }

    onChangePassword = (event: React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({ password: event.currentTarget.value });
    };

    onChangeRemember = (event: React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({ remember: event.currentTarget.checked });
    };

    onChangeUserName = (event: React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({ userName: event.currentTarget.value });
    };

    onClickForgotPassword = (event: React.SyntheticEvent<HTMLLinkElement>) => {
        event.preventDefault();
        this.props.forgotPasswordAction();
    };

    onClickSignIn = () => {
        const { cookies } = this.props;
        if (this.state.remember) {
            setUserNameCookie(cookies, this.state.userName);
        } else {
            removeUserNameCookie(cookies);
        }
        this.props.loginAction(
            cookies,
            this.state.userName,
            this.state.password
        );
    };

    setUserNameInputRef = (input: HTMLInputElement) => {
        this.userNameInput = input;
    };

    setPasswordInputRef = (input: HTMLInputElement) => {
        this.passwordInput = input;
    };

    render() {
        const { error, t } = this.props;

        return (
            <AuthDialogPage>
                <FieldLabel>{t("common:general.email")}</FieldLabel>
                <Input
                    cssWidth={AUTH_INPUT_WIDTH}
                    hideLastpassIcon={false}
                    name="email"
                    onChange={this.onChangeUserName}
                    ref={this.setUserNameInputRef}
                    type="text"
                    value={this.state.userName}
                />
                <FieldLabel marginTop="10px">
                    {t("common:login.password")}
                </FieldLabel>
                <Input
                    cssWidth={AUTH_INPUT_WIDTH}
                    hideLastpassIcon={false}
                    name="password"
                    onChange={this.onChangePassword}
                    ref={this.setPasswordInputRef}
                    type="password"
                />
                <Flexbox margin="24px 0px" justifyContent="space-between">
                    <Checkbox
                        checked={this.state.remember}
                        label={t("common:login.remember_email")}
                        name="remember"
                        onChange={this.onChangeRemember}
                    />
                    <Link
                        onClick={this.onClickForgotPassword}
                        trackingComponentLabel="Forgot Password"
                        trackingEventName={EVENT_NAME_PAGE_NAVIGATION}
                    >
                        {t("common:login.forgot_password?")}
                    </Link>
                </Flexbox>
                <Button
                    cssWidth={AUTH_BUTTON_WIDTH}
                    disabled={!(this.state.userName && this.state.password)}
                    onClick={this.onClickSignIn}
                    submit={true}
                    text={t("common:login.sign_in")}
                    trackingComponentLabel={[LoginDialog.displayName, "Submit"]}
                />
                <AuthError error={error} />
            </AuthDialogPage>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return { error: state.auth.error };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        forgotPasswordAction: () => {
            dispatch(forgotPassword());
        },
        loginAction: (cookies: Cookies, userName: string, password: string) => {
            dispatch(login(cookies, userName, password));
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

// @ts-expect-error withCookies causes typescript errors
export default withTranslation()(withCookies(connector(LoginDialog)));
