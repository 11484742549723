import {
    RECEIVE_USERS,
    REQUEST_USERS,
    UPDATE_START_INDEX_USERS,
    UPDATE_SORT_USERS,
    UPDATE_SEARCH_USERS
} from "common/administration/users/state/usersActions";
import type { UsersActions } from "common/administration/users/state/usersActions";
import { USER_USERNAME } from "common/administration/users/state/userStateUtils";
import noAction from "common/util/noAction";
import { getDefaultListState } from "common/util/query";

const users = (
    state: QueryState = getDefaultListState(USER_USERNAME),
    action: UsersActions = noAction
): QueryState => {
    switch (action.type) {
        case REQUEST_USERS:
            return state;
        case RECEIVE_USERS:
            return Object.assign({}, state, {
                totalCount: action.items.totalCount
            });
        case UPDATE_SORT_USERS:
            return Object.assign({}, state, {
                sortBy: action.sortBy,
                sortOrder: action.sortOrder
            });
        case UPDATE_SEARCH_USERS:
            return Object.assign({}, state, { search: action.search });
        case UPDATE_START_INDEX_USERS:
            return Object.assign({}, state, { startIndex: action.startIndex });
        default:
            return state;
    }
};

export default users;
