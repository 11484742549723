import type { TFunction } from "i18next";

// Const for compliant events show filter
export const COMPLIANT = "COMPLIANT";
export const NO_GUIDANCE = "NOGUIDANCE"; // no guidance
export const NON_COMPLIANT = "NONCOMPLIANT";
export const UNKNOWN = "UNKNOWN"; // has guidance but it's unknown compliance
const UNTESTED = "UNTESTED";

const MAX_SHOW_FILTER_ITEMS = 8;

export type ComplianceValue =
    | typeof COMPLIANT
    | typeof NO_GUIDANCE
    | typeof NON_COMPLIANT
    | typeof UNTESTED
    | typeof UNKNOWN;

// Functions to display correct tooltip on filter
export const showEventsSelectedText = (
    guidanceFilters: any[],
    selected: string[],
    t: TFunction
): any[] => {
    const filterCount = selected.length;
    const selectedTextTree = [];
    const compliantGuidances: string[] = [];
    const nonCompliantGuidances: string[] = [];
    const noGuidances: string[] = [];
    const unknownCompliance: string[] = [];
    selected.forEach((filterOption, index) => {
        if (index < MAX_SHOW_FILTER_ITEMS) {
            const filter = filterOption.split("-");
            const filterType = filter[0];
            const guidance = guidanceFilters.find(guidance => {
                return guidance.value === filter[1];
            });
            const guidanceName = guidance ? guidance.displayName : "";
            switch (filterType) {
                case COMPLIANT:
                    compliantGuidances.push(guidanceName);
                    break;
                case NON_COMPLIANT:
                    nonCompliantGuidances.push(guidanceName);
                    break;
                case NO_GUIDANCE:
                    noGuidances.push(guidanceName);
                    break;
                case UNKNOWN:
                    unknownCompliance.push(guidanceName);
                    break;
                default:
                    break;
            }
        }
    });

    if (compliantGuidances.length > 0) {
        selectedTextTree.push({
            displayName: t("compliance.compliant"),
            children: compliantGuidances
        });
    }
    if (nonCompliantGuidances.length > 0) {
        selectedTextTree.push({
            displayName: t("compliance.non_compliant"),
            children: nonCompliantGuidances
        });
    }
    if (noGuidances.length > 0) {
        selectedTextTree.push({
            displayName: t("events.no_guidance"),
            children: noGuidances
        });
    }
    if (unknownCompliance.length > 0) {
        selectedTextTree.push({
            displayName: t("compliance.events_with_unknown_compliance"),
            children: unknownCompliance
        });
    }
    if (filterCount > MAX_SHOW_FILTER_ITEMS) {
        selectedTextTree.push({
            displayName: t("common:general.and_x_more", {
                count: filterCount - MAX_SHOW_FILTER_ITEMS
            }),
            children: []
        });
    }

    return selectedTextTree;
};

export const buildTestStatusFilter = (filters: string[]): any => {
    return filters.map(option => {
        return {
            objectType: "TestStatusValue",
            value: option
        };
    });
};
