import {
    RESET_CAN_NOT_LEAVE,
    SET_CAN_NOT_LEAVE,
    SET_SELECTED_APP
} from "common/shell/state/navigationActions";
import type { NavigationActions } from "common/shell/state/navigationActions";
import noAction from "common/util/noAction";

type NavigationState = {
    canLeave: boolean;
    selectedApp: string;
};

export const defaultState = {
    canLeave: true,
    selectedApp: ""
};

const navigation = (
    state: NavigationState = defaultState,
    action: NavigationActions = noAction
): NavigationState => {
    switch (action.type) {
        case RESET_CAN_NOT_LEAVE:
            return Object.assign({}, state, {
                canLeave: true
            });
        case SET_CAN_NOT_LEAVE:
            return Object.assign({}, state, {
                canLeave: false
            });
        case SET_SELECTED_APP:
            return Object.assign({}, state, {
                selectedApp: action.selectedApp
            });
        default:
            return state;
    }
};

export default navigation;
