import {
    marginProps,
    paddingProps,
    textProps
} from "common/components/styled/util";
import styled from "styled-components/macro";

type BoldWrapperProps = marginPropsType & paddingPropsType & textPropsType;

const BoldWrapper = styled.span<BoldWrapperProps>`
    font-family: "BrandonTextBold";
    ${marginProps}
    ${paddingProps}
    ${textProps}
`;
BoldWrapper.displayName = "BoldWrapper";

export default BoldWrapper;
