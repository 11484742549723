import i18n from "common/i18n";
import {
    openMessageBox,
    BUTTON_NOYES,
    BUTTON_NO,
    BUTTON_YES,
    ICON_QUESTION
} from "common/shell/state/messageBoxActions";
import type { AppDispatch } from "store";

const NavigateAwayMessage = (
    dispatch: AppDispatch,
    resourceKey: string,
    callback: (leave: boolean) => void,
    isForm?: boolean
) => {
    const handleConfirmLeaveConfirmation = (
        button: string,
        callbackData: any,
        dispatch: AppDispatch
    ) => {
        let callbackValue = false;
        if (button === BUTTON_YES) {
            callbackValue = true;
        }
        callbackData.callback(callbackValue);
    };

    return openMessageBox({
        buttons: BUTTON_NOYES,
        buttonText: {
            [BUTTON_NO]: isForm
                ? i18n.t("common:general.leave_form_no")
                : i18n.t("common:general.leave_page_no"),
            [BUTTON_YES]: isForm
                ? i18n.t("common:general.leave_form_yes")
                : i18n.t("common:general.leave_page_yes")
        },
        callbackData: {
            callback: callback
        },
        callbackFunction: handleConfirmLeaveConfirmation,
        dispatch: dispatch,
        icon: ICON_QUESTION,
        message: { resourceKey: resourceKey },
        showCloseIcon: false,
        title: i18n.t("common:general.confirm_navigation")
    });
};

export default NavigateAwayMessage;
