import get from "lodash.get";
import type { AnyAction } from "redux";
import {
    formatSortOrderForQueryAPI,
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import { stringFilterCondition } from "common/util/filter";
import { PAGE_SIZE_UNLIMITED, sortCondition } from "common/util/query";
import type { RootState } from "store";

export const RECEIVE_TEST_PHASE_DATA = "RECEIVE_TEST_PHASE_DATA";
export const REQUEST_TEST_PHASE_DATA = "REQUEST_TEST_PHASE_DATA";
export const UPDATE_TEST_PHASE_DATA_SORT = "UPDATE_TEST_PHASE_DATA_SORT";

const requestTestPhaseData = (): AnyAction => ({
    type: REQUEST_TEST_PHASE_DATA
});

const receiveTestPhaseData = (json: Json): AnyAction => ({
    type: RECEIVE_TEST_PHASE_DATA,
    json: json
});

export const updateTestPhaseDataSort = (
    sortBy: string,
    sortOrder: SortOrder
): AnyAction => {
    return {
        type: UPDATE_TEST_PHASE_DATA_SORT,
        sortBy,
        sortOrder
    };
};

const getTestPhaseDataRequest = (state: RootState): ListQueryRequest => {
    const campaignEntityId = get(state, "campaign.entityId");
    const sortOrder = formatSortOrderForQueryAPI(state.testPhaseData.sortOrder);
    const sortConditions = [
        sortCondition(get(state, "testPhaseData.sortBy"), sortOrder)
    ];
    const filterCondition = stringFilterCondition(
        "promotion.campaign.entityId",
        campaignEntityId,
        "EQUALS"
    );

    return {
        entity: "TestPhaseData",
        filterCondition: filterCondition,
        includeCrossTenantInstances: false,
        maxResults: PAGE_SIZE_UNLIMITED,
        model: "TestPhaseDataModel/listQuery",
        objectType: "ListQuery",
        postSortCondition: null,
        propertySelection: null,
        sortConditions: sortConditions,
        startIndex: 0
    };
};

export function fetchTestPhaseData() {
    return makeRequestThunk("api/query", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState) {
            return getTestPhaseDataRequest(state);
        },
        preRequestFunc: requestTestPhaseData,
        okDispatchFunc: receiveTestPhaseData,
        okResultFunc: (json: Json, state: RootState) => {
            return {
                items: json.result,
                filteredCount: json.totalCount
            };
        },
        showLoadMask: false
    });
}
