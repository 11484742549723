import * as React from "react";
import get from "lodash.get";
import { nanoid } from "nanoid";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Button from "common/components/Button";
import Modal, {
    ModalButtonRow,
    ModalMainContent,
    ModalSeparator,
    ModalTitleRow,
    ModalWrapper
} from "common/components/Modal";
import BoldWrapper from "common/components/styled/BoldWrapper";
import type { DescriptionToken } from "shell/state/initReducers";
import type { RootState } from "store";

type OfferTextSubstitutionTokensModalProps = {
    closeModalHandler: NoArgsHandler;
    isOpen: boolean;
} & PropsFromRedux &
    WithTranslation;

const ModalContent = styled(ModalMainContent)`
    overflow-y: scroll;
`;

class OfferTextSubstitutionTokensModal extends React.Component<OfferTextSubstitutionTokensModalProps> {
    static displayName = "OfferTextSubstitutionTokensModal";

    onClose = () => {
        const { closeModalHandler } = this.props;
        closeModalHandler();
    };

    renderTokens = (substitutionTokens: DescriptionToken[]) => {
        const tokens: React.ReactNode[] = [];

        substitutionTokens.forEach((token: DescriptionToken) => {
            tokens.push(
                <div
                    style={{ marginTop: "16px", marginBottom: "16px" }}
                    key={"token-" + nanoid(7)}
                >
                    <BoldWrapper>{token.token}</BoldWrapper>
                    <div>{token.description}</div>
                </div>
            );
        });
        return tokens;
    };

    render() {
        const { isOpen, substitutionTokens, t } = this.props;
        const content = (
            <ModalWrapper cssHeight="660px" cssWidth="802px">
                <ModalTitleRow
                    closeHandler={this.onClose}
                    padding="16px"
                    title={t("concept.substitution_tokens")}
                    trackingComponentLabel={[
                        OfferTextSubstitutionTokensModal.displayName,
                        "Close"
                    ]}
                />
                <ModalSeparator marginTop="0px" marginBottom="0px" />
                <ModalContent padding="16px">
                    <div>{t("concept.substitution_tokens_description")}</div>
                    {this.renderTokens(substitutionTokens)}
                </ModalContent>
                <ModalButtonRow padding="16px">
                    <Button
                        cssWidth={"104px"}
                        onClick={this.onClose}
                        text={t("common:general.close")}
                        trackingComponentLabel={[
                            OfferTextSubstitutionTokensModal.displayName,
                            "Close"
                        ]}
                    />
                </ModalButtonRow>
            </ModalWrapper>
        );
        return (
            <Modal
                closeOnExternalClick={true}
                closeHandler={this.onClose}
                isOpen={isOpen}
                trackingComponentLabel={[
                    OfferTextSubstitutionTokensModal.displayName,
                    "Close"
                ]}
            >
                {isOpen && content}
            </Modal>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        substitutionTokens: get(
            state,
            "init.preloadedEntities.metadata.offerTextSubstitutionTokens",
            []
        )
    };
};

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(OfferTextSubstitutionTokensModal));
