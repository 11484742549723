import { formatDefaultLocale } from "d3-format";
import d3_en_CA from "d3-format/locale/en-CA.json";
import d3_en_GB from "d3-format/locale/en-GB.json";
import d3_en_US from "d3-format/locale/en-US.json";
import d3_es_ES from "d3-format/locale/es-ES.json";
import d3_es_MX from "d3-format/locale/es-MX.json";
import d3_fr_CA from "d3-format/locale/fr-CA.json";
import type { TFunction } from "i18next";
import isNil from "lodash.isnil";
import isNumber from "lodash.isnumber";
import round from "lodash.round";
import startCase from "lodash.startcase";
import upperFirst from "lodash.upperfirst";
import moment from "moment";
import "moment/locale/en-ca";
import "moment/locale/en-gb";
import "moment/locale/es";
import "moment/locale/fr-ca";
import sanitizeHtml from "sanitize-html";
import i18n from "common/i18n";
import { isEmptyString } from "common/util/lang";

const HTML_CHARS: Dictionary<string> = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#x27;",
    "/": "&#x2F;",
    "`": "&#x60;"
};

const unknown = (): string => {
    return "";
};

// Really ugly. We need to keep this up-to-date as new locales are supported. Better if the server sent us a currency
// symbol, whether or not it should be a prefix or suffix, and the grouping separator.
// Need to expand Locale type def;
type Locale = any;

const getD3Locale = (localeStr: string): Locale => {
    switch (localeStr) {
        case "en_CA":
            return d3_en_CA;
        case "en_GB":
            return d3_en_GB;
        case "es_ES":
            return d3_es_ES;
        case "es_MX":
            return d3_es_MX;
        case "fr_CA":
            return d3_fr_CA;
        default:
            return d3_en_US;
    }
};

const getMomentLocale = (localeStr: string): string => {
    switch (localeStr) {
        case "en_CA":
            return "en-CA";
        case "en_GB":
            return "en-GB";
        case "es_ES":
        case "es_MX":
            return "es";
        case "fr_CA":
            return "fr-CA";
        default:
            return "en-US";
    }
};

export const initializeLocale = (localeStr: string) => {
    const d3Locale = getD3Locale(localeStr);
    formatDefaultLocale(d3Locale);
    const momentLocale = getMomentLocale(localeStr);
    moment.locale(momentLocale);
};

export const date2StrUTC = (datetime?: string | null, format = "L"): string => {
    if (isNil(datetime) || isEmptyString(datetime)) {
        return unknown();
    }
    return moment(datetime).utc().format(format);
};

export const date2Str = (
    datetime?: Date | string | null,
    format = "L"
): string => {
    if (isNil(datetime) || isEmptyString(datetime)) {
        return unknown();
    }
    return moment(datetime).format(format);
};

export const dateRange2Str = (
    startDate: Date | string | null,
    endDate: Date | string | null
): string => {
    if (endDate && startDate) {
        return date2Str(startDate) + " - " + date2Str(endDate);
    }
    return "";
};

export const dateTime2Str = (
    datetime?: string | null,
    format = "L LT"
): string => {
    return date2Str(datetime, format);
};

export const fromNow = (datetime: string): string => {
    if (isNil(datetime) || isEmptyString(datetime)) {
        return unknown();
    }
    return moment(datetime).fromNow();
};

export const titleCase = (str: string): string => {
    if (str) {
        return startCase(str);
    }
    return str;
};

export const sentenceCase = (str: string): string => {
    if (str) {
        return upperFirst(str.toLowerCase());
    }
    return str;
};

export const formatYesNo = (value: boolean): string => {
    return value ? i18n.t("common:general.yes") : i18n.t("common:general.no");
};

export const fileSize = (size: number): string => {
    const byteLimit = 1024,
        kbLimit = 1048576,
        mbLimit = 1073741824;

    let out;
    if (size < byteLimit) {
        if (size === 1) {
            out = "1 byte";
        } else {
            out = size + " bytes";
        }
    } else if (size < kbLimit) {
        out = Math.round((size * 10) / byteLimit) / 10 + " KB";
    } else if (size < mbLimit) {
        out = Math.round((size * 10) / kbLimit) / 10 + " MB";
    } else {
        out = Math.round((size * 10) / mbLimit) / 10 + " GB";
    }
    return out;
};

export const formatCurrency = (value: number): string => {
    return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
    });
};

export const formatPercentage = (value: number | string): string => {
    return value + "%";
};

export const percent = (value: number, precision = 0, hideSymbol = false) => {
    let percent = "";

    // Can't assume all percents will be positive and between 0 and 100
    if (isNumber(value)) {
        let percentNumber = value * 100;
        // okay - very odd - if toFixed started out negative and results in 0 then the value is actually -0 (signed 0) - and it displays
        // checking for === 0 or === -0 doesn't work
        // abs doesn't retain precision - only for 0, don't sweat it
        if (percentNumber >= 0 && value <= 0) {
            percentNumber = Math.abs(percentNumber);
        }
        percent = percentAsIs(percentNumber, precision, hideSymbol);
    }
    return percent;
};

// like percent(), but don't multiply by 100
export const percentAsIs = (
    value: number,
    precision = 0,
    hideSymbol = false
) => {
    let percent = "";

    // Can't assume all percents will be positive and between 0 and 100
    if (isNumber(value)) {
        percent = round(value, precision).toString();
        if (!hideSymbol) {
            percent = formatPercentage(percent);
        }
    }
    return percent;
};

export const withCountText = (
    x: number | any[],
    withCountKey: string,
    zeroCountKey?: string | null
) => {
    const count = Array.isArray(x) ? x.length : x;
    return !count && !isNil(zeroCountKey) && !isEmptyString(zeroCountKey)
        ? i18n.t(zeroCountKey)
        : i18n.t(withCountKey, { count });
};

export const sanitizeForUseWithDangerouslySetInnerHtml = (str: string) => {
    return sanitizeHtml(str);
};

export const removeBoldBrackets = (str: string): string => {
    let processedString = str;
    if (processedString) {
        processedString = processedString.replace(/\[\[/g, "");
        processedString = processedString.replace(/\]\]/g, "");
    }
    return processedString;
};

const MAX_TOOLTIP_ITEMS = 20;
export const truncateTooltipItems = (
    items: React.ReactNode[],
    maxTooltipItems: number = MAX_TOOLTIP_ITEMS,
    wrapWithDiv = true
) => {
    const maxItemString = i18n.t("common:general.plus_x_more", {
        count: items.length - maxTooltipItems
    });
    const maxItemTooltip = wrapWithDiv ? (
        <div key={"-1"}>{maxItemString}</div>
    ) : (
        maxItemString
    );
    if (items.length > maxTooltipItems) {
        items = items.slice(0, maxTooltipItems).concat([maxItemTooltip]);
    }
    return items;
};

//const DOT = String.fromCharCode(8901);
export const MIDDOT = String.fromCharCode(183);
//const RAQUO = String.fromCharCode(187);
//const RARROW = String.fromCharCode(8594);
//const RSAQUO = String.fromCharCode(8250);

/**
     Returns a copy of the specified string with special HTML characters
     escaped. The following characters will be converted to their
     corresponding character entities:

     & < > " ' / `

     This implementation is based on the [OWASP HTML escaping recommendations][1].
     In addition to the characters in the OWASP
     recommendations, the <code>&#x60;</code> character is also escaped,
     since IE interprets it as an attribute delimiter.

     If str is not already a string, it will be coerced to a string.

     [1]: http://www.owasp.org/index.php/XSS_(Cross_Site_Scripting)_Prevention_Cheat_Sheet

     */
export const escape = (str: string) => {
    if (str) {
        return str.toString().replace(/[&<>"'`]/g, htmlReplacer);
    }
    return str;
};

/**
 * Regex replacer for HTML escaping.
 */
const htmlReplacer = (match: string) => {
    return HTML_CHARS[match];
};

export const toCsv = (values: string[]) => {
    let csv = "";

    for (let i = 0; i < values.length; i += 1) {
        if (i > 0) {
            csv += ",";
        }
        csv += values[i];
    }
    return csv;
};

export const commaJoinWithAnd = (items: string[], t: TFunction) => {
    let result = "";

    for (let i = 0; i < items.length; i += 1) {
        result = result + items[i];
        if (i + 2 === items.length) {
            result = result + " " + t("common:general.and") + " ";
        } else if (i + 2 < items.length) {
            result = result + ", ";
        }
    }

    return result;
};

// From CPG Testing
export const translateDatabaseToDisplayForPercent = (value: any) => {
    return Math.round(value * 10000) / 100;
};

export const translateDisplayToDatabaseForPercent = (value: any) => {
    return Math.round(value * 100) / 10000;
};

export const extractFilenameWithoutExtension = (str: string) => {
    return str.replace(/\.[^/.]+$/, "");
};

// Sort array helper
export const sortByLabel = (
    arr: any[],
    identifier = "label",
    reverse = false
) => {
    return arr.sort((a, b) => {
        const aLabel = a[identifier].toLowerCase();
        const bLabel = b[identifier].toLowerCase();
        if (aLabel < bLabel) {
            return reverse ? 1 : -1;
        }
        if (aLabel > bLabel) {
            return reverse ? -1 : 1;
        }
        return 0;
    });
};

export const naturalSort = (a: any, b: any) =>
    a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });
