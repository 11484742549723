import get from "lodash.get";
import isNil from "lodash.isnil";
import { css } from "styled-components/macro";
import theme from "common/components/theme";

const themeValue = (key: string) => {
    return (props: any) => get(props.theme, key);
};
export const borderRadius = (key: string) => themeValue("borderRadius." + key);

export const boxShadow = (key: string) => themeValue("boxShadow." + key);

export const marginProps = css<marginPropsType>`
    ${props => props.margin && `margin: ${props.margin};`}
    ${props => props.marginTop && `margin-top: ${props.marginTop};`}
    ${props => props.marginRight && `margin-right: ${props.marginRight};`}
    ${props => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
    ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
`;

export const marginPropTypes = [
    "margin",
    "marginBottom",
    "marginLeft",
    "marginRight",
    "marginTop"
];

export const paddingProps = css<paddingPropsType>`
    ${props => props.padding && `padding: ${props.padding};`}
    ${props => props.paddingBottom && `padding-bottom: ${props.paddingBottom};`}
    ${props => props.paddingLeft && `padding-left: ${props.paddingLeft};`}
    ${props => props.paddingRight && `padding-right: ${props.paddingRight};`}
    ${props => props.paddingTop && `padding-top: ${props.paddingTop};`}
`;

export const paddingPropTypes = [
    "padding",
    "paddingBottom",
    "paddingLeft",
    "paddingRight",
    "paddingTop"
];

export const paddingBlockProps = css<paddingBlockPropsType>`
    ${props =>
        props.paddingBlockStart &&
        `padding-block-start: ${props.paddingBlockStart};`}
    ${props =>
        props.paddingBlockEnd && `padding-block-end: ${props.paddingBlockEnd};`}
`;

export const paddingInlineProps = css<paddingInlinePropsType>`
    ${props =>
        props.paddingInlineStart &&
        `padding-inline-start: ${props.paddingInlineStart};`}
    ${props =>
        props.paddingInlineEnd &&
        `padding-inline-end: ${props.paddingInlineEnd};`}
`;

export const sizeProps = css<sizePropsType>`
    ${props => props.boxSizing && `box-sizing: ${props.boxSizing};`}
    ${props => props.cssWidth && `width: ${props.cssWidth};`}
    ${props => props.cssHeight && `height: ${props.cssHeight};`}
    ${props => props.maxWidth && `max-width: ${props.maxWidth};`}
    ${props => props.maxHeight && `max-height: ${props.maxHeight};`}
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    ${props => props.minHeight && `min-height: ${props.minHeight};`}
`;

export const sizePropTypes = [
    "boxSizing",
    "cssHeight",
    "cssWidth",
    "maxHeight",
    "maxWidth",
    "minHeight",
    "minWidth"
];

export const flexboxProps = css<flexboxPropsType>`
    align-content: ${props =>
        props.alignContent ? props.alignContent : "stretch"};
    align-items: ${props => (props.alignItems ? props.alignItems : "stretch")};
    ${props => props.alignSelf && `align-self: ${props.alignSelf};`}
    display: flex;
    flex-direction: ${props =>
        props.flexDirection ? props.flexDirection : "row"};
    flex-grow: ${props => props.flexGrow};
    flex-wrap: ${props => (props.flexWrap ? props.flexWrap : "nowrap")};
    justify-content: ${props =>
        props.justifyContent ? props.justifyContent : "flex-start"};
    ${props => props.flexShrink && `flex-shrink: ${props.flexShrink};`}
    ${props => !isNil(props.flex) && `flex: ${props.flex};`}
    ${props => props.overflow && `overflow: ${props.overflow};`}
`;

export const borderProps = css<borderPropsType>`
    ${props => props.border && `border: ${props.border};`}
    ${props => props.borderBottom && `border-bottom: ${props.borderBottom};`}
    ${props => props.borderLeft && `border-left: ${props.borderLeft};`}
    ${props => props.borderRadius && `border-radius: ${props.borderRadius};`}
    ${props => props.borderRight && `border-right: ${props.borderRight};`}
    ${props => props.borderTop && `border-top: ${props.borderTop};`}
`;

export const cardBorderContainerProps = css`
    min-width: 0;
    word-wrap: break-word;
    box-shadow: 0 3px 8px 0 rgba(219, 226, 230, 0.8);
    border: 1px solid ${theme.border};
    border-radius: 4px;
    background-color: ${theme.background};
    background-clip: border-box;
    margin: 0;
    padding: 0;
`;

export const lineBreakProps = css`
    white-space: pre-wrap;
`;

export const moveBorderContainerProps = css`
    min-width: 0;
    word-wrap: break-word;
    border: 1px dashed ${theme.movePanelBorder};
    border-radius: 4px;
    background-color: ${theme.movePanelBg};
    background-clip: border-box;
    margin: 0;
    padding: 0;
`;

export const positionProps = css<positionPropsType>`
    ${props => props.bottom && `bottom: ${props.bottom};`}
    ${props => props.left && `left: ${props.left};`}
    ${props => props.right && `right: ${props.right};`}
    ${props => props.position && `position: ${props.position};`}
    ${props => props.top && `top: ${props.top};`}
`;

export const textTransformProp = css<textTransformPropType>`
    ${props => props.textTransform && `text-transform: ${props.textTransform};`}
`;

export const textProps = css<textPropsType>`
    ${props => props.color && `color: ${props.color};`}
    ${props => props.fontFamily && `font-family: ${props.fontFamily};`}
    ${props => props.fontSize && `font-size: ${props.fontSize};`}
`;

export const avoidPageBreakProps = css`
    @media print {
        break-inside: avoid;
    }
`;

export const hideBorderPrintProps = css`
    @media print {
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
`;

export const hidePrintProps = css`
    @media print {
        display: none;
    }
`;

export const hidePaddingPrintProps = css`
    @media print {
        padding: 0;
    }
`;

export const mediaPrintProps = css`
    @media print {
        background-color: ${theme.background};
        border: none;
        display: block;
        height: auto;
        overflow: visible;
        width: auto;
    }
`;

export const userSelectNoneProps = css`
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
`;
