import {
    HIDE_COMPONENT_LOADMASK,
    HIDE_LOADMASK,
    SHOW_COMPONENT_LOADMASK,
    SHOW_LOADMASK
} from "common/shell/state/loadmaskActions";
import type { LoadmaskActions } from "common/shell/state/loadmaskActions";
import noAction from "common/util/noAction";

type LoadmaskState = {
    showComponentLoadmask: any;
    showLoadmask: Date[];
};

export const defaultState: LoadmaskState = {
    /*
     plain javascript object
     signal keys are properties
     each property is an array with dates in it similar to the array of dates for showLoadmask
     */
    showComponentLoadmask: {},
    showLoadmask: []
};

const loadmask = (
    state: LoadmaskState = defaultState,
    action: LoadmaskActions = noAction
): LoadmaskState => {
    const { showComponentLoadmask, showLoadmask } = state;
    let key: string;
    let newState: LoadmaskState;
    switch (action.type) {
        case HIDE_COMPONENT_LOADMASK:
            key = action.key;
            if (
                showComponentLoadmask[key] &&
                Array.isArray(showComponentLoadmask[key])
            ) {
                newState = Object.assign({}, showComponentLoadmask, {
                    [key]: [...showComponentLoadmask[key].slice(1)]
                });
            } else {
                newState = showComponentLoadmask;
            }
            return {
                ...state,
                showComponentLoadmask: newState
            };
        case HIDE_LOADMASK:
            return { ...state, showLoadmask: [...showLoadmask.slice(1)] };
        case SHOW_COMPONENT_LOADMASK: {
            key = action.key;
            let newValues = [];
            if (
                showComponentLoadmask[key] &&
                Array.isArray(showComponentLoadmask[key])
            ) {
                newValues = showComponentLoadmask[key];
            }
            newState = Object.assign({}, showComponentLoadmask, {
                [key]: [...newValues, new Date()]
            });
            return {
                ...state,
                showComponentLoadmask: newState
            };
        }
        case SHOW_LOADMASK:
            return { ...state, showLoadmask: [...showLoadmask, new Date()] };

        default:
            return state;
    }
};

export default loadmask;
