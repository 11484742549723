export const CAMPAIGN_PRODUCT_GROUP_EFFECTIVE_UPCS = "effectiveUpcs";
export const CAMPAIGN_PRODUCT_GROUP_CONTRIBUTION_PER_UNIT =
    "contributionPerUnitFormatCurrency";
export const CAMPAIGN_PRODUCT_GROUP_INSTACART_AVERAGE_PRICE_TO_CONSUMER =
    "instacartBasePriceFormatCurrency";
export const CAMPAIGN_PRODUCT_GROUP_MANUFACTURER_MARGIN =
    "manufacturerMarginFormatCurrency";
export const CAMPAIGN_PRODUCT_GROUP_NAME = "name";
export const CAMPAIGN_PRODUCT_GROUP_NUMBER_OF_OFFERS = "numPromotions";
export const CAMPAIGN_PRODUCT_GROUP_OFFER_DISPLAY_NAME = "offerDisplayName";
export const CAMPAIGN_PRODUCT_GROUP_PRICE_TO_CONSUMER =
    "priceToConsumerFormatCurrency";
export const CAMPAIGN_PRODUCT_GROUP_PRICE_TO_RETAILER =
    "priceToRetailerFormatCurrency";
export const CAMPAIGN_PRODUCT_GROUP_PRICE_TO_WHOLESALER =
    "priceToWholesalerFormatCurrency";
