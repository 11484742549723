import * as React from "react";
import styled from "styled-components/macro";
import theme from "common/components/theme";

type OuterTableWrapperProps = {
    backgroundColor?: string;
} & paddingPropsType;

export const OuterTableWrapper = styled.div<OuterTableWrapperProps>`
    align-items: center;
    background-color: ${props =>
        props.backgroundColor ? props.backgroundColor : theme.background};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-width: ${theme.minWidth};
    padding: 0 40px;
    ${props =>
        props.paddingBottom && `padding-bottom:  ${props.paddingBottom};`}
    ${props => props.paddingLeft && `padding-left:  ${props.paddingLeft};`}    
    ${props => props.paddingRight && `padding-right:  ${props.paddingRight};`}
        ${props => props.paddingTop && `padding-top:  ${props.paddingTop};`}
`;
OuterTableWrapper.displayName = "OuterTableWrapper";

type AdministrationOuterTableWrapperProps = {
    alignItems?: string;
    cssHeight?: string;
    cssWidth?: string;
} & OuterTableWrapperProps;

export const AdministrationOuterTableWrapper = styled(
    OuterTableWrapper
)<AdministrationOuterTableWrapperProps>`
    ${props => props.alignItems && `align-items: ${props.alignItems};`}
    ${props => props.cssHeight && `height: ${props.cssHeight};`}
    padding: ${props => (props.padding ? props.padding : "0px")};
    width: ${props => (props.cssWidth ? props.cssWidth : "100%")};
`;
AdministrationOuterTableWrapper.displayName = "AdministrationOuterTableWrapper";

type TableAndHeaderWrapperProps = {
    noBorder?: boolean;
};

export const TableAndHeaderWrapper = styled.div<TableAndHeaderWrapperProps>`
    ${props => !props.noBorder && `border: 1px solid ${props.theme.border};`}
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    width: 100%;
`;
TableAndHeaderWrapper.displayName = "TableAndHeaderWrapper";

export const GridCount = styled.div`
    color: ${theme.defaultGreyText};
    margin-right: 10px;
    white-space: nowrap;
`;
GridCount.displayName = "GridCount";

type GridHeaderItemCountProps = {
    count: number;
    countHeader: string;
};

export const GridHeaderItemCount = ({
    count,
    countHeader
}: GridHeaderItemCountProps) => {
    if (count > -1) {
        return <GridCount>{countHeader}</GridCount>;
    }
    return null;
};
GridHeaderItemCount.displayName = "GridHeaderItemCount";
