import get from "lodash.get";
import type { AnyAction } from "redux";
import {
    makeRequestThunk,
    METHOD_POST
} from "common/shell/state/requestActions";
import { CUSTOM } from "common/util/date";
import {
    buildGuidanceComplianceFilterObjects,
    EVENT_COMPLIANCE,
    GUIDANCE_COMPLIANCE
} from "event/state/eventsStateUtils";
import type { EventComplianceGuidanceFilter } from "event/state/eventsStateUtils";
import {
    OVERVIEW_COMPLIANCE,
    OVERVIEW_COVERAGE,
    OVERVIEW_TOP_PERFORMANCE
} from "filter/state/filterActions";
import {
    PERFORMANCE_COLUMN_A,
    PERFORMANCE_COLUMN_B,
    PERFORMANCE_COMPLIANCE_STATUS,
    PERFORMANCE_DATE
} from "overview/state/overviewStateUtils";
import { OVERVIEW_PAGE } from "shell/state/pageActions";
import {
    SIGNAL_KEY_FETCH_GUIDELINES_COMPLIANCE,
    SIGNAL_KEY_FETCH_GUIDELINES_COVERAGE,
    SIGNAL_KEY_FETCH_TOP_PERFORMANCE
} from "shell/state/requestStateUtils";
import type { RootState } from "store";

export const RECEIVE_GUIDELINE_COMPLIANCE_OVERVIEW =
    "RECEIVE_GUIDELINE_COMPLIANCE_OVERVIEW";
export const REQUEST_GUIDELINE_COMPLIANCE_OVERVIEW =
    "REQUEST_GUIDELINE_COMPLIANCE_OVERVIEW";
export const RECEIVE_GUIDELINE_COVERAGE_OVERVIEW =
    "RECEIVE_GUIDELINE_COVERAGE_OVERVIEW";
export const REQUEST_GUIDELINE_COVERAGE_OVERVIEW =
    "REQUEST_GUIDELINE_COVERAGE_OVERVIEW";
export const RECEIVE_TOP_PERFORMANCE_OVERVIEW =
    "RECEIVE_TOP_PERFORMANCE_OVERVIEW";
export const REQUEST_TOP_PERFORMANCE_OVERVIEW =
    "REQUEST_TOP_PERFORMANCE_OVERVIEW";
export const RESET_GUIDELINE_COMPLIANCE_OVERVIEW =
    "RESET_GUIDELINE_COMPLIANCE_OVERVIEW";
export const UPDATE_SELECTED_GUIDANCES = "UPDATE_SELECTED_GUIDANCES";

export const overviewPage = (): AnyAction => ({
    type: OVERVIEW_PAGE
});

export const receiveGuidelineComplianceOverview = (json: Json): AnyAction => ({
    type: RECEIVE_GUIDELINE_COMPLIANCE_OVERVIEW,
    response: json
});

export const resetGuidelineCoverageOverview = (): AnyAction => ({
    type: RESET_GUIDELINE_COMPLIANCE_OVERVIEW
});

export const requestGuidelineComplianceOverview = (): AnyAction => ({
    type: REQUEST_GUIDELINE_COMPLIANCE_OVERVIEW
});

export const receiveGuidelineCoverageOverview = (json: Json): AnyAction => ({
    type: RECEIVE_GUIDELINE_COVERAGE_OVERVIEW,
    response: json
});

export const requestGuidelineCoverageOverview = (): AnyAction => ({
    type: REQUEST_GUIDELINE_COVERAGE_OVERVIEW
});

export const receiveTopPerformanceOverview = (json: Json): AnyAction => ({
    type: RECEIVE_TOP_PERFORMANCE_OVERVIEW,
    response: json
});

export const requestTopPerformanceOverview = (): AnyAction => ({
    type: REQUEST_TOP_PERFORMANCE_OVERVIEW
});

export const updateSelectedGuidances = (
    selectedGuidances: string[]
): AnyAction => ({
    type: UPDATE_SELECTED_GUIDANCES,
    selectedGuidances
});

const buildDateSeriesDefinition = (date: any): any => {
    const dateOption = date.option;
    let dateObject = {};
    if (dateOption.id === CUSTOM) {
        dateObject = {
            endDate: dateOption.endDate,
            startDate: dateOption.startDate,
            timePeriod: {
                objectType: "MatrixTimePeriod",
                value: CUSTOM
            }
        };
    } else {
        dateObject = {
            timePeriod: { objectType: "MatrixTimePeriod", value: dateOption.id }
        };
    }
    return {
        definition: { ...dateObject, objectType: "EventsRequest" },
        name: date.name,
        objectType: "MatrixChartSeriesDefinition"
    };
};

const buildComplianceSeriesDefinition = (complianceStatus: any): any => {
    const definition: {
        complianceFilter: any[];
        guidanceComplianceFilter: EventComplianceGuidanceFilter[];
        objectType: string;
    } = {
        complianceFilter: [],
        guidanceComplianceFilter: [],
        objectType: "EventsRequest"
    };
    if (complianceStatus.toggle === EVENT_COMPLIANCE) {
        definition.complianceFilter = get(complianceStatus, "option", []);
    } else if (complianceStatus.toggle === GUIDANCE_COMPLIANCE) {
        definition.guidanceComplianceFilter = buildGuidanceComplianceFilterObjects(
            get(complianceStatus, "option", [])
        );
    }
    return {
        definition,
        name: complianceStatus.name,
        objectType: "MatrixChartSeriesDefinition"
    };
};

const getGuidelineComplianceOverviewRequest = (state: RootState): any => {
    return {
        endDate: get(state, `filters.${OVERVIEW_COMPLIANCE}.date.endDate`),
        guidanceTypeFilter: get(
            state,
            "overview.guidelineCompliance.selectedGuidances",
            []
        ),
        objectType: "GuidelineComplianceOverviewRequest",
        startDate: get(state, `filters.${OVERVIEW_COMPLIANCE}.date.startDate`),
        timePeriod: {
            objectType: "MatrixTimePeriod",
            value: get(state, `filters.${OVERVIEW_COMPLIANCE}.date.time`)
        }
    };
};

export const getGuidelineCoverageOverviewRequest = (state: RootState): any => {
    return {
        endDate: get(state, `filters.${OVERVIEW_COVERAGE}.date.endDate`),
        objectType: "GuidelineCoverageOverviewRequest",
        startDate: get(state, `filters.${OVERVIEW_COVERAGE}.date.startDate`),
        timePeriod: {
            objectType: "MatrixTimePeriod",
            value: get(state, `filters.${OVERVIEW_COVERAGE}.date.time`)
        }
    };
};

const getTopPerformanceOverviewRequest = (
    state: RootState,
    componentState: any
): any => {
    // Get column options and push to array
    const columnOptions = [];
    const optionA = get(componentState, "columnAOption");
    const optionAToggle = get(componentState, "columnAToggleOption");
    const optionB = get(componentState, "columnBOption");
    const optionBToggle = get(componentState, "columnBToggleOption");
    if (optionA) {
        columnOptions.push({
            name: PERFORMANCE_COLUMN_A,
            option: optionA,
            toggle: optionAToggle
        });
    }
    if (optionB) {
        columnOptions.push({
            name: PERFORMANCE_COLUMN_B,
            option: optionB,
            toggle: optionBToggle
        });
    }
    // Build series definition
    let series = [];
    switch (get(componentState, "xAxisSecondaryOption.value", "")) {
        case PERFORMANCE_DATE:
            series = columnOptions.map(buildDateSeriesDefinition);
            break;
        case PERFORMANCE_COMPLIANCE_STATUS:
            series = columnOptions.map(buildComplianceSeriesDefinition);
            break;
        default:
            break;
    }
    // Apply date filter if secondary x axis option is not date
    const dateFilter =
        get(componentState, "xAxisSecondaryOption.value") !== PERFORMANCE_DATE
            ? {
                  endDate: get(
                      state,
                      `filters.${OVERVIEW_TOP_PERFORMANCE}.date.endDate`
                  ),
                  startDate: get(
                      state,
                      `filters.${OVERVIEW_TOP_PERFORMANCE}.date.startDate`
                  ),
                  timePeriod: {
                      objectType: "MatrixTimePeriod",
                      value: get(
                          state,
                          `filters.${OVERVIEW_TOP_PERFORMANCE}.date.time`
                      )
                  }
              }
            : {};
    return {
        holidayIds: get(state, `filters.${OVERVIEW_TOP_PERFORMANCE}.holidays`),
        objectType: "MatrixChartRequest",
        productGroupIds: get(
            state,
            `filters.${OVERVIEW_TOP_PERFORMANCE}.products`
        ),
        promoTypes: get(
            state,
            `filters.${OVERVIEW_TOP_PERFORMANCE}.promoTypes`,
            []
        ).map(value => ({ objectType: "PromoType", value })),
        regionIds: get(state, `filters.${OVERVIEW_TOP_PERFORMANCE}.regions`),
        retailerIds: get(
            state,
            `filters.${OVERVIEW_TOP_PERFORMANCE}.retailers`
        ),
        series: series,
        xAxis: get(componentState, "xAxisPrimaryOption.value", ""),
        yAxis: get(componentState, "yAxisOption.value", ""),
        ...dateFilter
    };
};

export function fetchGuidelineComplianceOverview() {
    return makeRequestThunk("api/matrix/overview/compliance", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState): any {
            return getGuidelineComplianceOverviewRequest(state);
        },
        preRequestFunc: requestGuidelineComplianceOverview,
        okDispatchFunc: receiveGuidelineComplianceOverview,
        showLoadMask: false,
        showComponentLoadMask: true,
        signalKey: SIGNAL_KEY_FETCH_GUIDELINES_COMPLIANCE
    });
}

export function fetchGuidelineCoverageOverview() {
    return makeRequestThunk("api/matrix/overview/coverage", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState): any {
            return getGuidelineCoverageOverviewRequest(state);
        },
        preRequestFunc: requestGuidelineCoverageOverview,
        okDispatchFunc: receiveGuidelineCoverageOverview,
        showLoadMask: false,
        showComponentLoadMask: true,
        signalKey: SIGNAL_KEY_FETCH_GUIDELINES_COVERAGE
    });
}

export function fetchTopPerformanceOverview(componentState: any) {
    return makeRequestThunk("api/matrix/overview/performance", {
        method: METHOD_POST,
        bodyFunc: function (state: RootState): any {
            return getTopPerformanceOverviewRequest(state, componentState);
        },
        preRequestFunc: requestTopPerformanceOverview,
        okDispatchFunc: receiveTopPerformanceOverview,
        showLoadMask: false,
        showComponentLoadMask: true,
        signalKey: SIGNAL_KEY_FETCH_TOP_PERFORMANCE
    });
}
