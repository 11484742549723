import styled from "styled-components/macro";
import {
    borderRadius,
    marginProps,
    sizeProps,
    userSelectNoneProps
} from "common/components/styled/util";
import theme from "common/components/theme";

/* Custom display for radio and checkbox to replace standard browser input control */
type RadioCheckboxMarkProps = {
    gridColumn?: boolean;
} & Pick<positionPropsType, "top"> &
    Pick<React.HTMLProps<HTMLInputElement>, "onKeyDown" | "tabIndex">;

export const RadioCheckboxMark = styled.div<RadioCheckboxMarkProps>`
    ${props => !props.gridColumn && `position: absolute;`}
    ${props =>
        !props.gridColumn && (props.top ? `top: ${props.top};` : "top: 0;")}
    ${props => !props.gridColumn && "left: 0;"}
    height: 18px;
    width: 18px;
    background: ${theme.lightText};
    border: 1px solid ${theme.inputBorder};
    border-radius: ${borderRadius("checkbox")};
    bottom: 0;
    margin: auto;

    :after {
        content: "";
        ${props => !props.gridColumn && `position: absolute;`}
        ${props => props.gridColumn && `margin-left: 6px;`}
        ${props => props.gridColumn && `margin-top: 1px;`}
        display: none; /* Hide check */
    }
`;
RadioCheckboxMark.displayName = "RadioCheckboxMark";

type RadioCheckboxProps = disabledPropsType &
    Pick<paddingPropsType, "paddingBottom" | "paddingLeft" | "paddingTop">;

/* Basic radio/checkbox control styles */
const RadioCheckbox = styled.label<RadioCheckboxProps>`
    display: block;
    position: relative;
    padding-top: ${props => (props.paddingTop ? props.paddingTop : "0")};
    padding-bottom: ${props =>
        props.paddingBottom ? props.paddingBottom : "0"};
    padding-left: ${props => (props.paddingLeft ? props.paddingLeft : "28px")};
    ${props => !props.disabled && `cursor: pointer;`}
    font-size: 14px;

    /* Hide default browser input control */
    input {
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    /* Checked */
    input:checked ~ ${RadioCheckboxMark} {
        background: ${theme.radioCheckboxBackground};
    }

    /* Hide default browser input control when disabled */
    input:disabled ~ ${RadioCheckboxMark} {
        background: ${theme.inputDisabledBg};
        pointer-events: none;
    }

    input:checked ~ ${RadioCheckboxMark}:after {
        display: block; /* Show check */
    }
`;
RadioCheckbox.displayName = "RadioCheckbox";

export const RadioCheckboxRadio = styled(RadioCheckbox)`
    /* Make radio buttons circular */
    align-items: center;
    display: flex;

    & ${RadioCheckboxMark} {
        border-radius: 50%;
    }

    /* Radio button inner circle */
    & ${RadioCheckboxMark}:after {
        left: 6px;
        top: 6px;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background: ${theme.lightText};
    }

    /* Disabled radio circle color */
    input:disabled ~ ${RadioCheckboxMark}:after {
        background: ${theme.radioCheckboxDisabled};
    }

    :last-child {
        margin-bottom: 0px;
    }

    ${marginProps}
    ${sizeProps}
`;
RadioCheckboxRadio.displayName = "RadioCheckboxRadio";

type RadioCheckboxCheckboxProps = {
    checkboxWidth?: string;
    labelSelectable: boolean;
} & marginPropsType;
export const RadioCheckboxCheckbox = styled(
    RadioCheckbox
)<RadioCheckboxCheckboxProps>`
    ${marginProps}

    ${props => !props.labelSelectable && `${userSelectNoneProps}`}
    ${props => props.checkboxWidth && `width: ${props.checkboxWidth};`} 

    /* Checkbox tick */
    & ${RadioCheckboxMark}:after {
        left: 6px;
        top: 1px;
        width: 4px;
        height: 10px;
        border: solid ${theme.lightText};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }

    /* Disabled check color */
    input:disabled ~ ${RadioCheckboxMark}:after {
        border-color: ${theme.radioCheckboxDisabled};
    }

    :last-child {
        margin-bottom: 0px;
    }
`;
RadioCheckboxCheckbox.displayName = "RadioCheckboxCheckbox";

type RadioCheckboxTagProps = {
    group?: boolean;
};
export const RadioCheckboxTag = styled.div<RadioCheckboxTagProps>`
    align-items: stretch;
    display: flex;
    flex-direction: row;
    margin-bottom: ${props => (props.group ? "8px" : "0px")};
    padding: 0px 16px;

    label {
        margin-bottom: 0px;
        flex: 1;
    }

    span {
        color: ${theme.greyText};
        margin-left: 5px;
    }

    :last-child {
        margin-bottom: 0px;
    }
`;
RadioCheckboxTag.displayName = "RadioCheckboxTag";
