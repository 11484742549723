import styled from "styled-components/macro";
import theme from "common/components/theme";

type CenteredContentWrapperProps = Pick<
    sizePropsType,
    "boxSizing" | "cssHeight" | "cssWidth"
>;

const CenteredContentWrapper = styled.div<CenteredContentWrapperProps>`
    margin: 0 auto;
    max-width: ${theme.maxWidth};
    min-width: ${theme.minWidth};
    padding: 0px 40px;
    ${props => props.boxSizing && `box-sizing: ${props.boxSizing};`}
    ${props => props.cssHeight && `height: ${props.cssHeight};`}
    ${props => props.cssWidth && `width: ${props.cssWidth};`}
`;
CenteredContentWrapper.displayName = "CenteredContentWrapper";

export default CenteredContentWrapper;
