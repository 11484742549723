import * as React from "react";
import styled from "styled-components/macro";
import { marginProps } from "common/components/styled/util";
import theme from "common/components/theme";

type FieldLabelProps = {
    children: React.ReactNode;
    color?: string;
    cssWidth?: string;
    marginBottom?: string;
    minWidth?: string;
    required?: boolean;
} & marginPropsType;

export const FieldLabel = styled.label<FieldLabelProps>`
    :after {
        ${props => props.required && `content: ' *';`}
        color: ${theme.alert};
    }
    color: ${props => (props.color ? props.color : theme.defaultGreyText)};
    ${props => props.cssWidth && `width: ${props.cssWidth};`};
    font-family: "BrandonTextMedium";
    font-size: 14px;
    display: block;
    margin-bottom: ${props =>
        props.marginBottom ? props.marginBottom : "8px"};
    ${props => props.minWidth && `min-width: ${props.minWidth};`};
    ${marginProps}
`;
FieldLabel.displayName = "FieldLabel";

export const DTFieldLabel = (props: FieldLabelProps) => {
    return (
        <dt>
            <FieldLabel {...props} />
        </dt>
    );
};
DTFieldLabel.displayName = "DTFieldLabel";

const Label = styled.div`
    margin: 5px 0;
    ${marginProps}
`;
Label.displayName = "Label";

export default Label;
