// Excluding from testing since only consts are defined

// actual value should be what user sees in url after administration - should be section id
// watch out for case
// e.g. http://app.eversightlabs.com/#/administration/users

// include app specific administration page actions here

export const GUIDELINE_COLLECTION_CREATE_PAGE =
    "GUIDELINE_COLLECTION_CREATE_PAGE";
export const GUIDELINE_COLLECTION_EDIT_PAGE = "GUIDELINE_COLLECTION_EDIT_PAGE";
export const GUIDELINE_COLLECTION_PAGE = "GUIDELINE_COLLECTION_PAGE";
export const GUIDELINE_COLLECTIONS_PAGE = "GUIDELINE_COLLECTIONS_PAGE";

export const GUIDELINE_CREATE_PAGE = "GUIDELINE_CREATE_PAGE";
export const GUIDELINE_EDIT_PAGE = "GUIDELINE_EDIT_PAGE";
export const GUIDELINE_PAGE = "GUIDELINE_PAGE";

export const HOLIDAY_CREATE_PAGE = "HOLIDAY_CREATE_PAGE";
export const HOLIDAY_EDIT_PAGE = "HOLIDAY_EDIT_PAGE";
export const HOLIDAY_PAGE = "HOLIDAY_PAGE";
export const HOLIDAYS_PAGE = "HOLIDAYS_PAGE";

export const PROMOTION_TYPE_DETECTION_EDIT_PAGE =
    "PROMOTION_TYPE_DETECTION_EDIT_PAGE";
export const PROMOTION_TYPE_DETECTION_PAGE = "PROMOTION_TYPE_DETECTION_PAGE";
