import get from "lodash.get";

export const TEST_PHASE_DATA_CONFIDENCE_OF_DIFFERENCE =
    "confidenceOfDifferenceFormatPercent";
export const TEST_PHASE_DATA_PROMOTION_DISCOUNT_PERCENT =
    "promotion_discountPercentFormatPercent";
export const TEST_PHASE_DATA_PROMOTION_EFFECTIVE_DISCOUNT_PERCENT =
    "promotion_effectiveDiscountPercentFormatPercent";
export const TEST_PHASE_DATA_PROMOTION_EFFECTIVE_UNIT_PRICE_TO_CONSUMER =
    "promotion_effectivePriceToConsumerFormatCurrency";
export const TEST_PHASE_DATA_PROMOTION_EFFECTIVE_TRADE_RATE =
    "promotion_effectiveTradeRateFormatPercent";
export const TEST_PHASE_DATA_PROMOTION_IS_CONTROL = "promotion_isControl";
export const TEST_PHASE_DATA_PROMOTION_NAME = "promotion_name";
export const TEST_PHASE_DATA_PROMOTION_OFFER_LABEL = "promotion_offerLabel";
export const TEST_PHASE_DATA_PROMOTION_OFFER_SUMMARY = "promotion_sentence";
export const TEST_PHASE_DATA_PROMOTION_STATUS = "promotion_state.displayName";
export const TEST_PHASE_DATA_PROMOTION_SYSTEM_MARGIN_PER_OFFER_AFTER_DISCOUNT =
    "promotion_systemMarginPerOfferAfterDiscountFormatCurrency";
export const TEST_PHASE_DATA_PROMOTION_UNIT_PRICE_TO_CONSUMER =
    "promotion_pricePerUnitFormatCurrency";

export const reduceCampaignTestInsights = (insights: any) => {
    const displayMetrics = get(insights, "displayMetrics", []);
    const controlOffers = get(insights, "controlOffers", []);
    const selectedControlOfferId = get(
        insights,
        "selectedControlOfferId",
        null
    );
    let selectedControlOfferLabel = null;
    if (selectedControlOfferId) {
        const controlOffer = controlOffers.find((offer: any) => {
            return selectedControlOfferId === get(offer, "offer_entityId");
        });
        selectedControlOfferLabel = get(
            controlOffer,
            "offer_offerLabelFormatHtml"
        );
    }
    return {
        controlOffers,
        displayMetrics,
        selectedControlOfferId: selectedControlOfferId,
        selectedControlOfferLabel: selectedControlOfferLabel
    };
};
