import { SET_SESSION_PREFERENCE } from "common/shell/state/sessionPreferencesActions";
import type { SessionPreferencesActions } from "common/shell/state/sessionPreferencesActions";

type SessionPreferenceState = Dictionary<any>;

export const defaultState = {};

const sessionPreferences = (
    state: SessionPreferenceState = defaultState,
    action: SessionPreferencesActions
): SessionPreferenceState => {
    switch (action.type) {
        case SET_SESSION_PREFERENCE: {
            const prefName = action.preference.name;
            return Object.assign({}, state, {
                [prefName]: action.preference.value
            });
        }
        default:
            return state;
    }
};

export default sessionPreferences;
