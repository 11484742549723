import { GROUP_AUTO_COLUMN_ID } from "ag-grid-community";
import type {
    ColDef,
    ColGroupDef,
    Column,
    ColumnState,
    IServerSideGetRowsParams,
    IServerSideGetRowsRequest,
    GridApi,
    ValueGetterParams
} from "ag-grid-community";
import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import isNil from "lodash.isnil";
import styled from "styled-components/macro";
import type { Placement } from "tippy.js";
import type { DataGridType } from "common/components/grid/AgDataGrid";
import type {
    ExportOption,
    ExportOptionsObject
} from "common/components/AgExportOptionSelector";
import { getColumnId } from "common/components/grid/AgGridUtil";
import theme from "common/components/theme";
import { paddingProps } from "common/components/styled/util";
import Tooltip from "common/components/Tooltip";
import { date2Str, sentenceCase } from "common/util/format";
import { isEmptyString } from "common/util/lang";
import { ASCENDING } from "common/util/query";

// Checkbox column const
export const CHECKBOX_COLUMN = "checkboxSelection";
// Icon column const
export const ICON_COLUMN = "iconColumn";
// Preview column const
export const PREVIEW_COLUMN = "previewColumn";
// Status column const
export const STATUS_COLUMN = "statusColumn";

export type ColumnPreferences = {
    column: ColumnState[];
    columnGroup: {
        groupId: string;
        open: boolean;
    }[];
};

export const DEFAULT_HEADER_HEIGHT = 48;

export const DEFAULT_ROW_HEIGHT = 48;
export const LARGE_ROW_HEIGHT = 88;
export const IMAGE_THUMBNAIL_HEIGHT = 104;

export const DATAGRID_COLUMN_ACTIONS = "dataGridColumnActions";

export const PINNED_RIGHT = "right" as const;
export const PINNED_LEFT = "left" as const;

// Cell class names
export const CELL_NO_HIGHLIGHT = "ag-cell-no-highlight";

// the following type column types come from ag-grid
export const COLUMN_TYPE_NUMERIC = "numericColumn" as string;
export const COLUMN_TYPE_AGGREGATION = "aggregation" as string;

// For cell class when a cell renderer used
export const COLUMN_CUSTOM_RENDERER_ALIGN_CENTER = "cell-flexbox-align-center";
export const COLUMN_CUSTOM_RENDERER_ALIGN_LEFT = "cell-flexbox-align-left";
export const COLUMN_CUSTOM_RENDERER_ALIGN_RIGHT = "cell-flexbox-align-right";

// Grid row menu column styling, helps with the margin for mouse over on button
export const COLUMN_RENDERER_GRID_ROW_MENU = "cell-flexbox-align-grid-row-menu";

// Sets line height
// Allows for centering of text vertically for non-custom cell renderers
export const COLUMN_DEFAULT_ROW_HEIGHT = "cell-default-row-height";
export const COLUMN_LARGE_ROW_HEIGHT = "cell-large-row-height";
export const COLUMN_IMAGE_THUMBNAIL_ROW_HEIGHT =
    "cell-image-thumbnail-row-height";

// center aligns, left aligns, right aligns text horizontally
//const COLUMN_ALIGN_CENTER = "cell-align-center";
export const COLUMN_ALIGN_RIGHT = "cell-align-right";
export const COLUMN_DATA_MODIFIED = "cell-data-modified";
export const COLUMN_GREEN_STRIPE = "cell-green-stripe";
export const COLUMN_YELLOW_STRIPE = "cell-yellow-stripe";

export const HEADER_ALIGN_CENTER = "header-align-center";
export const GROUP_HEADER_ALIGN_CENTER = "group-header-align-center";

// Data type
export const DATA_TYPE_DATE = "DATE";
export const DATA_TYPE_DATE_TIME = "DATE_TIME";

type TableToolbarProps = paddingPropsType &
    Pick<flexboxPropsType, "justifyContent" | "flexDirection">;
export const TableToolbar = styled.div<TableToolbarProps>`
    align-items: center;
    background: ${theme.background};
    border-top: 1px solid ${theme.border};
    border-right: 1px solid ${theme.border};
    border-left: 1px solid ${theme.border};
    display: flex;
    flex-direction: ${props =>
        props.flexDirection ? props.flexDirection : "row"};
    flex-wrap: wrap;
    ${props =>
        props.justifyContent && `justify-content: ${props.justifyContent};`}
    padding: 12px 8px 12px 8px;
    ${paddingProps};
`;

TableToolbar.displayName = "TableToolbar";

export const InsetTableToolbar = styled(TableToolbar)`
    border-bottom: 1px solid ${theme.border};
    border-left: none;
    border-right: none;
    border-top: none;
`;

TableToolbar.displayName = "TableToolbar";

export const InsetTableNoHeaderToolbar = styled(TableToolbar)`
    border-bottom: 1px solid ${theme.border};
    border-left: none;
    border-right: none;
    border-top: 1px solid ${theme.border};
`;

type TableWrapperProps = {
    gridTheme?: string;
};

export const TableWrapper = styled.div.attrs(
    ({ gridTheme }: TableWrapperProps) => ({
        className: gridTheme ? gridTheme : "ag-theme-eversight"
    })
)<TableWrapperProps>`
    height: 100%;
    width: 100%;
`;

TableWrapper.displayName = "TableWrapper";

export const getCombinedColumnState = (gridApi: GridApi): ColumnPreferences => {
    const columnDefinitions: ColumnDefinition[] = [];
    const allColumns = gridApi.getAllGridColumns();
    if (allColumns && allColumns.length > 0) {
        const autoGroupColumnDef: ColDef = get(
            allColumns,
            "[0].gridOptionsWrapper.gridOptions.autoGroupColumnDef",
            {}
        );
        if (!isEmpty(autoGroupColumnDef)) {
            columnDefinitions.push({
                ...autoGroupColumnDef,
                colId: GROUP_AUTO_COLUMN_ID
            });
        }
        allColumns.forEach((column: Column) => {
            const originalParent = column.getOriginalParent();
            let colGroupDef: ColGroupDef | null = null;
            if (originalParent) {
                colGroupDef = originalParent.getColGroupDef();
            }
            let parentHeaderName = "";
            if (colGroupDef && colGroupDef.headerName) {
                parentHeaderName = sentenceCase(colGroupDef.headerName);
            }
            const colDef = column.getColDef();
            const headerName = parentHeaderName
                ? parentHeaderName + " " + colDef.headerName
                : colDef.headerName;
            columnDefinitions.push({
                ...column.getColDef(),
                headerName: headerName
            });
        });
    }

    const columnState = gridApi
        .getColumnState()
        .map((columnState: ColumnState) => {
            const columnDefinition: ColDef | undefined = columnDefinitions.find(
                (colDef: ColDef) => colDef.colId === columnState.colId
            );
            const headerName = columnDefinition
                ? columnDefinition.headerName
                : "";
            return { ...columnState, headerLabel: headerName };
        });
    const columnGroupState = gridApi.getColumnGroupState();

    return {
        column: columnState,
        columnGroup: columnGroupState
    };
};

export const getAgDataGridTextTruncateNode = (
    text: string,
    marginAuto = true,
    cssWidth?: string,
    tooltipPlacement?: Placement
) => {
    const truncatedWidth = cssWidth ? cssWidth : "100%";
    const conditionalOptions: {
        marginAuto?: boolean;
        placement?: Placement;
    } = {};
    if (marginAuto) {
        conditionalOptions.marginAuto = true;
    }
    if (tooltipPlacement) {
        conditionalOptions.placement = tooltipPlacement;
    }
    return (
        <Tooltip
            checkOverflow={true}
            cssWidth={truncatedWidth}
            ignoreHeightOverflow={true}
            {...conditionalOptions}
            text={text}
        />
    );
};

const flattenNodeGenerator = (
    node: any,
    parent: any,
    index: number,
    stack: any[]
) => {
    return function flattenNode(list: any[]) {
        node.id = getColumnId(node);
        list.push(node);

        if (node.children) {
            for (let i = 0, len = node.children.length; i < len; i++) {
                stack.push(
                    flattenNodeGenerator(node.children[i], node, i, stack)
                );
            }
        }

        if (parent && parent.children) {
            // Records children' id'
            parent.children[index] = node.id;
            node.parent = parent.id;
        }

        return list;
    };
};

export const flattenColumnDefinitions = (tr: any): any[] => {
    let list: any[] = [];
    const stack: any[] = [];
    const tree = cloneDeep(tr);

    if (Array.isArray(tree) && tree.length) {
        // Object Array
        for (let i = 0, len = tree.length; i < len; i++) {
            stack.push(
                flattenNodeGenerator(
                    tree[i],
                    { id: "root" }, // placeholder
                    i,
                    stack
                )
            );
        }
    } else {
        // One object tree
        stack.push(flattenNodeGenerator(tree, { id: "root" }, 0, stack));
    }

    while (stack.length) {
        list = stack.shift()(list);
    }

    return list;
};

const recurseChildrenColumnIds = (
    columnDefinitions: ColDef[],
    columnIds: string[]
) => {
    columnDefinitions.forEach((columnDef: ColDef) => {
        const groupId = get(columnDef, "groupId");
        const children = get(columnDef, "children");
        if (groupId && children) {
            recurseChildrenColumnIds(children, columnIds);
        } else {
            if (columnDef.colId) {
                columnIds.push(columnDef.colId);
            }
        }
    });
};

export const getColumnDefinitionIds = (
    columnDefinitions: ColDef[],
    autoGroupColumnDef?: ColDef
): string[] => {
    const columnIds = [];
    if (autoGroupColumnDef) {
        columnIds.push(GROUP_AUTO_COLUMN_ID);
    }
    recurseChildrenColumnIds(columnDefinitions, columnIds);
    return columnIds;
};

// Helpers for ag data grid cell copy
export const cellValueGetter = (
    params: ValueGetterParams,
    key: string
): string => {
    return get(params, "data." + key, "");
};

export const cellDateValueGetter = (
    params: ValueGetterParams,
    key: string
): string => {
    return date2Str(get(params, "data." + key, ""));
};

export const cellListValueGetter = (
    params: ValueGetterParams,
    key: string,
    fieldKey = "displayName",
    displayAllText = ""
): string => {
    const list = get(params, ["data", key], []);
    if (!isEmptyString(displayAllText) && list && list.length === 0) {
        return displayAllText;
    }
    return list.map((obj: any) => get(obj, fieldKey)).join(", ");
};

// Used to flattened column definition groups to use for export option column values
export const getFlattendedGroupColumnDefinitions = (
    columnDefs: (ColDef | ColGroupDef)[],
    defaultColumnDefs: ColDef[] = [],
    headerMap: any = {}
): ColDef[] => {
    const columnDefinitions = columnDefs;
    const flattenedColumnDefinitions = defaultColumnDefs;
    columnDefinitions.forEach(columnDefinition => {
        if (
            Object.prototype.hasOwnProperty.call(columnDefinition, "children")
        ) {
            const groupDef = columnDefinition as ColGroupDef;
            groupDef.children.forEach(child => {
                let groupHeader = "";
                if (groupDef.groupId) {
                    groupHeader = headerMap[groupDef.groupId];
                }
                let childHeaderName = child.headerName;
                if (groupHeader) {
                    childHeaderName = groupHeader + " " + child.headerName;
                }
                flattenedColumnDefinitions.push({
                    ...child,
                    headerName: childHeaderName
                });
            });
        } else {
            flattenedColumnDefinitions.push(columnDefinition);
        }
    });

    return flattenedColumnDefinitions;
};

const isExportOptionPreferencesValid = (
    columnPreferences: ColumnState[] | undefined | null,
    flattenedColumnDefinitions: ColDef[]
) => {
    let valid = true;
    const preferences = columnPreferences?.filter(
        preference => preference.colId !== DATAGRID_COLUMN_ACTIONS
    );
    if (
        !preferences ||
        preferences.length !== flattenedColumnDefinitions.length
    ) {
        valid = false;
    } else {
        for (let i = 0; i < preferences.length; i++) {
            const columnDef = flattenedColumnDefinitions.find(
                item => item.colId === preferences[i].colId
            );
            if (!columnDef) {
                valid = false;
                break;
            }
        }
    }
    return valid;
};

export const getExportOptionColumnValues = (
    columnPreferences: ColumnPreferences | undefined | null,
    flattenedColumnDefinitions: ColDef[],
    sortKeyMap: Dictionary<string> = {},
    alwaysSelected: string[] = [],
    ignoreColumns: string[] = []
): ExportOptionsObject => {
    const exportOptions: ExportOption[] = [];
    const exportOptionsChecked: string[] = [];

    // Check if preferences and current column definitions match up
    const validPreferences = isExportOptionPreferencesValid(
        columnPreferences?.column,
        flattenedColumnDefinitions
    );

    if (validPreferences) {
        columnPreferences?.column.forEach(column => {
            if (!ignoreColumns.includes(column.colId)) {
                const columnDefinition = flattenedColumnDefinitions.find(
                    colDef => colDef.colId === column.colId
                );
                // Column preferences includes actions column and we don't want to export that so ignore it!
                if (columnDefinition) {
                    const colId = columnDefinition.colId ?? "";
                    exportOptions.push({
                        disabled: alwaysSelected.includes(colId),
                        label: columnDefinition.headerName ?? "",
                        value: getSortKeyMapId(sortKeyMap, colId)
                    });
                } else if (column.colId !== DATAGRID_COLUMN_ACTIONS) {
                    exportOptions.push({
                        disabled: alwaysSelected.includes(column.colId),
                        label: get(column, "headerLabel", ""),
                        value: getSortKeyMapId(sortKeyMap, column.colId)
                    });
                }
                if (
                    column.colId !== DATAGRID_COLUMN_ACTIONS &&
                    (alwaysSelected.includes(column.colId) ||
                        column.colId === GROUP_AUTO_COLUMN_ID ||
                        !column.hide)
                ) {
                    exportOptionsChecked.push(
                        getSortKeyMapId(sortKeyMap, column.colId)
                    );
                }
            }
        });
    } else if (flattenedColumnDefinitions) {
        flattenedColumnDefinitions.forEach(columnDefinition => {
            const colId = columnDefinition.colId ?? "";
            if (!ignoreColumns.includes(colId)) {
                exportOptions.push({
                    disabled: alwaysSelected.includes(colId),
                    label: columnDefinition.headerName ?? "",
                    value: getSortKeyMapId(sortKeyMap, colId)
                });
                if (
                    alwaysSelected.includes(colId) ||
                    colId === GROUP_AUTO_COLUMN_ID ||
                    !columnDefinition.hide
                ) {
                    exportOptionsChecked.push(
                        getSortKeyMapId(sortKeyMap, colId)
                    );
                }
            }
        });
    }

    return {
        exportOptions,
        exportOptionsChecked
    };
};

// Sort util helpers
export const updateSortAndOffset = (
    request: IServerSideGetRowsRequest,
    sortKeyMap: Dictionary<string | number>,
    updateSort?: (sortBy: string, sortOrder: SortOrder) => void,
    updateOffset?: (offset: number) => void
) => {
    const sort = request.sortModel[0]?.sort ?? ASCENDING;
    const sortColumnId = request.sortModel[0]?.colId;

    if (sortColumnId && sortKeyMap && updateSort) {
        const sortBy = getSortKeyMapId(sortKeyMap, sortColumnId);
        updateSort(sortBy, sort);
    }
    if (updateOffset && !isNil(request.startRow)) {
        updateOffset(request.startRow);
    }
};

// GetRow Handler
const defaultRowsSuccess = (result: any, params: IServerSideGetRowsParams) => {
    const { filteredCount, items } = result;
    params.success({
        rowCount: filteredCount,
        rowData: items
    });
};

export type getRowsHandlerType = {
    countKey?: string; // key to check result count
    dataGrid: DataGridType;
    fetchData: () => Promise<ResponseSuccessResult>;
    getRowsSuccess?: (
        result: any,
        params: IServerSideGetRowsParams,
        dataGrid: DataGridType
    ) => any; // Handler will use the defaultRowsSuccess function
    params: IServerSideGetRowsParams;
    sortKeyMap?: Dictionary<string | number>;
    updateOffset?: (offset: number) => void;
    updateSort?: (sortBy: string, sortOrder: SortOrder) => void;
};

export const getRowsHandler = (props: getRowsHandlerType): any => {
    const {
        countKey = "filteredCount",
        dataGrid,
        fetchData,
        getRowsSuccess = defaultRowsSuccess,
        params,
        sortKeyMap = {},
        updateOffset,
        updateSort
    } = props;
    const request = params.request;
    dataGrid?.hideOverlay();
    updateSortAndOffset(request, sortKeyMap, updateSort, updateOffset);
    fetchData().then(({ success, result }) => {
        if (success) {
            if (result[countKey] < 1) {
                dataGrid?.showNoRowsOverlay();
            }
            getRowsSuccess(result, params, dataGrid);
        } else {
            if (!params.api.isDestroyed()) {
                params.fail();
            }
        }
    });
};

export const getSortKeyMapId = (sortMap: any, colId: string): string => {
    return sortMap[colId] || colId;
};

export const sortComparator = (
    sortField: string,
    valueA: any,
    valueB: any,
    nodeA: any,
    nodeB: any,
    isInverted: boolean
): number => {
    let valA = get(nodeA.data, sortField);
    let valB = get(nodeB.data, sortField);
    if (!isNaN(valA)) {
        valA = +valA;
    }
    if (!isNaN(valB)) {
        valB = +valB;
    }
    if (valA > valB) {
        return 1;
    }
    if (valA < valB) {
        return -1;
    }
    return 0;
};
