import get from "lodash.get";
import { fromNow } from "common/util/format";
import { getEntityIdWithoutVersion, reduceObject } from "common/util/object";
import { ASCENDING, DESCENDING } from "common/util/query";

// Offer recommendation types
type RecommendedOffer = {
    offerCount: number;
    offerLabel: string;
    replacementEntityId: string;
};

export type OfferRecommendation = {
    controlOfferEntityId: string;
    controlOfferLabel: string;
    events: Event[];
    eventWeeks: number;
    image: string;
    manager: string;
    offerId: string;
    onlyAbondoned: boolean;
    productGroup: string;
    promoSalesFormatCurrency: string;
    recommendedOffer: RecommendedOffer;
    retailer: string;
};

export const MAKE_TOP_OFFER = "MAKE_TOP_OFFER";
export const RECOMMEND_OFFER = "RECOMMEND_OFFER";
export const CONSIDER_OFFER = "CONSIDER_OFFER";
export const ABANDON_OFFER = "ABANDON_OFFER";
export const RESET_OFFER = "RESET_OFFER";

export type OfferMove =
    | typeof MAKE_TOP_OFFER
    | typeof RECOMMEND_OFFER
    | typeof CONSIDER_OFFER
    | typeof ABANDON_OFFER
    | typeof RESET_OFFER;

export const ABANDON = "ABANDON";
export const CONSIDER = "CONSIDER";
export const RECOMMENDED = "RECOMMENDED";
export const TOP = "TOP";

export type OfferCategory =
    | typeof ABANDON
    | typeof CONSIDER
    | typeof RECOMMENDED
    | typeof TOP;

type CampaignListModalInfo = {
    campaigns: MatrixRecommendationObjectCampaign[];
    isOpen: boolean;
    isSingleOffer: boolean;
};

type OfferRecommendationPrintState = {
    controlId: string;
    metric: string;
    offerIds: string;
};

export type ProductWithCategory = {
    name: string;
    productCategory?: string;
};

export const BETTER = "BETTER";
export const EVEN = "EVEN";
export const WORSE = "WORSE";

export type ImpactValue = typeof BETTER | typeof EVEN | typeof WORSE;

type Impact = {
    displayName: string;
    value: ImpactValue;
};

export type Value = {
    display: string;
    impact: Impact;
    tableDisplay: string;
    title: string;
    tooltip: string;
};

type BaseOffer = {
    display: string;
    identifier: string;
    image: string;
    imageLarge: string;
    values: Value[];
};

export type Offer = {
    campaigns: MatrixRecommendationObjectCampaign[];
    offerType: string;
    originalCategory: OfferCategory;
    previewPromotionId: string;
} & BaseOffer;

export type ProofText = {
    bold: boolean;
    value: string;
};

export type ReportOffer = {
    isAbandoned: boolean;
    proofTexts: ProofText[];
} & BaseOffer;

export type Metric = {
    identifier: string;
    isSelectable: boolean;
    name: string;
};

type ChartRecord = {
    horizontalAxisLabel: string; // Data label for horizontal axis
    isControlOffer: boolean;
    primaryAxisLabel?: string; // Data label for primary axis
    quaternaryAxisLabel?: string; // Data label for quaternary axis
    quinaryAxisLabel?: string; // Data label for quinary axis
    secondaryAxisLabel?: string; // Data label for secondary axis
    tertiaryAxisLabel?: string; // Data label for tertiary axis
    verticalAxisLabel: string; // Data label for vertical axis
    verticalAxisValue: number; // Data value for vertical axis
};

type RecommendationReport = {
    ceiApplies: boolean;
    chartRecords: ChartRecord[];
    control: ReportOffer | undefined | null;
    hasPredictedIncrementalUnitLift: boolean;
    holiday?: string;
    horizontalAxisLabel: string; // Chart horizontal axis label
    metrics: Metric[];
    offers: ReportOffer[];
    primaryAxisLabel?: string; // Chart axis label below horizontal
    product: string;
    products: ProductWithCategory[];
    quaternaryAxisLabel?: string; // Chart axis label below tertiary
    quinaryAxisLabel?: string; // Chart axis label below quaternary
    region?: string;
    retailer: string;
    secondaryAxisLabel?: string; // Chart axis label below primary
    tertiaryAxisLabel?: string; // Chart axis label below secondary
    verticalAxisLabel: string; // Chart vertical axis label
};

export type RecommendationState = {
    abandon: Offer[];
    abandonSortBy: string;
    abandonSortOrder: SortOrder;
    campaignListModal: CampaignListModalInfo;
    campaigns: MatrixRecommendationObjectCampaign[];
    channelId: string;
    channelText?: string;
    consider: Offer[];
    considerSortBy: string;
    considerSortOrder: SortOrder;
    control: Offer | undefined | null;
    controlId: string;
    eventWeeks: number;
    holiday?: string;
    metrics: Metric[];
    modifiedBy: string;
    modifiedOn: string;
    printOptions: OfferRecommendationPrintState;
    product: string;
    productGroupId: string;
    products: ProductWithCategory[];
    recommended: Offer[];
    recommendedSortBy: string;
    recommendedSortOrder: SortOrder;
    region?: string;
    report: RecommendationReport;
    retailer: string;
    top: Offer | undefined | null;
    value: string;
    valueLabel: string;
};

// Reducer helpers and functions
function categoryKey(category: OfferCategory): string {
    return category.toLowerCase();
}

function sortByKey(category: OfferCategory): string {
    return categoryKey(category) + "SortBy";
}

function sortOrderKey(category: OfferCategory): string {
    return categoryKey(category) + "SortOrder";
}

function reduceBaseOffer(data: any): BaseOffer | undefined | null {
    return data
        ? {
              display: data.display,
              identifier: data.identifier,
              image: data.image,
              imageLarge: data.imageLarge,
              values: data.values.map(reduceValue)
          }
        : null;
}

function reduceChartRecord(data: any): ChartRecord {
    return reduceObject(
        data,
        "horizontalAxisLabel",
        "isAbandoned",
        "isControlOffer",
        "primaryAxisLabel",
        "quaternaryAxisLabel",
        "quinaryAxisLabel",
        "secondaryAxisLabel",
        "tertiaryAxisLabel",
        "verticalAxisLabel",
        "verticalAxisValue"
    );
}

function reduceMetric(data: any): Metric {
    // const metric = reduceObject(data, "identifier", "isSelectable", "name");
    // metric.isSelectable = true;
    // return metric;
    return reduceObject(data, "identifier", "isSelectable", "name");
}

function reduceOffer(data: any): Offer | undefined | null {
    const offer = reduceBaseOffer(data);
    return offer
        ? {
              ...offer,
              campaigns: get(data, "campaigns", []),
              previewPromotionId: data.previewPromotionId,
              offerType: data?.offerTemplate?.offerType?.value,
              originalCategory: get(data, "originalCategory.value")
          }
        : null;
}

function reduceProofText(data: any): ProofText {
    return reduceObject(data, "bold", "value");
}

function reduceReportOffer(data: any): ReportOffer | undefined | null {
    const offer = reduceBaseOffer(data);
    return offer
        ? {
              ...offer,
              isAbandoned: data.isAbandoned,
              proofTexts: data.sectionProofs.map(reduceProofText)
          }
        : null;
}

function reduceValue(value: any): Value {
    return {
        display: value.display,
        tableDisplay: value.tableDisplay,
        impact: value.impact
            ? reduceObject(value.impact, "displayName", "value")
            : {
                  displayName: "",
                  value: null
              },
        tooltip: value.tooltip,
        title: value.title
    };
}

// exported Getters / Reducers
export function getCampaignListModal(
    isOpen: boolean,
    campaigns: MatrixRecommendationObjectCampaign[] | null,
    isSingleOffer = false
): any {
    if (campaigns) {
        return {
            campaignListModal: {
                campaigns,
                isOpen,
                isSingleOffer
            }
        };
    }
    return {
        campaignListModal: {
            isOpen: isOpen
        }
    };
}

export function getControlId(action: any): any {
    // translate category shown on url to actual value to send down to API
    return {
        controlId: get(action, "payload.controlId")
    };
}

export function getSortOrder(
    state: RecommendationState,
    category: OfferCategory,
    sortBy: string
): SortOrder {
    const sKey = sortByKey(category);
    const sBy = get(state, sKey);
    if (sBy === sortBy) {
        const oKey = sortOrderKey(category);
        const sortOrder = get(state, oKey);
        if (sortOrder === ASCENDING) {
            return DESCENDING;
        }
    }
    return ASCENDING;
}

export function reduceSortedCategory(
    state: RecommendationState,
    category: OfferCategory,
    sortBy: string,
    offers: any[]
) {
    return {
        [categoryKey(category)]: offers.map(reduceOffer),
        [sortByKey(category)]: sortBy,
        [sortOrderKey(category)]: getSortOrder(state, category, sortBy)
    };
}

export function reduceRecommendations(
    obj: any,
    state: any
): RecommendationState {
    return {
        abandon: obj.abandon.map(reduceOffer),
        abandonSortBy: obj.abandonSortBy,
        abandonSortOrder: obj.abandonSortOrder,
        campaignListModal: state.campaignListModal,
        campaigns: get(obj, "campaigns", []),
        channelId: getEntityIdWithoutVersion(obj.channel_entityId),
        channelText: obj.channelText,
        consider: obj.consider.map(reduceOffer),
        considerSortBy: obj.considerSortBy,
        considerSortOrder: obj.considerSortOrder,
        control: reduceOffer(obj.control),
        controlId: obj.controlId,
        eventWeeks: obj.eventWeeks,
        holiday: obj.holiday,
        metrics: obj.metrics.map(reduceMetric),
        modifiedBy: obj.modifiedByName,
        modifiedOn: fromNow(obj.modifiedDateFormatDate),
        printOptions: defaultPrintState,
        product: obj.product,
        productGroupId: getEntityIdWithoutVersion(obj.productGroup_entityId),
        products: obj.products,
        recommended: obj.recommended.map(reduceOffer),
        recommendedSortBy: obj.recommendedSortBy,
        recommendedSortOrder: obj.recommendedSortOrder,
        region: obj.region,
        report: defaultReportState,
        retailer: obj.retailer,
        top: reduceOffer(obj.top),
        value: obj.valueFormatCurrency,
        valueLabel: obj.valueLabel
    };
}

export function reduceRecommendationsReport(obj: any): RecommendationReport {
    return {
        ceiApplies: obj.consumerEngagementIsApplicable,
        chartRecords: obj.chartRecords.map(reduceChartRecord),
        control: reduceReportOffer(obj.control),
        hasPredictedIncrementalUnitLift: obj.hasPredictedIncrementalUnitLift,
        holiday: obj.holiday,
        horizontalAxisLabel: obj.horizontalAxisLabel,
        metrics: obj.metrics.map(reduceMetric),
        offers: obj.offers.map(reduceReportOffer),
        primaryAxisLabel: obj.primaryAxisLabel,
        quaternaryAxisLabel: obj.quaternaryAxisLabel,
        quinaryAxisLabel: obj.quinaryAxisLabel,
        product: obj.product,
        products: obj.products,
        region: obj.region,
        retailer: obj.retailer,
        secondaryAxisLabel: obj.secondaryAxisLabel,
        tertiaryAxisLabel: obj.tertiaryAxisLabel,
        verticalAxisLabel: obj.verticalAxisLabel
    };
}

// Default states
const defaultPrintState: OfferRecommendationPrintState = {
    controlId: "",
    metric: "",
    offerIds: ""
};

export const defaultReportState: RecommendationReport = {
    ceiApplies: true,
    chartRecords: [],
    control: {
        display: "",
        identifier: "",
        image: "",
        imageLarge: "",
        isAbandoned: false,
        proofTexts: [],
        values: []
    },
    hasPredictedIncrementalUnitLift: true,
    holiday: "",
    horizontalAxisLabel: "",
    metrics: [],
    offers: [],
    product: "",
    products: [],
    region: "",
    retailer: "",
    verticalAxisLabel: ""
};

export const defaultState: any = {
    abandon: [],
    campaignListModal: {
        isOpen: false
    },
    consider: [],
    controlId: "",
    control: {},
    printOptions: defaultPrintState,
    recommended: [],
    report: defaultReportState
};
