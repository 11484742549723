export const PAGE_SIZE_UNLIMITED = -1;
export const DEFAULT_PAGE_SIZE = 100;

// using ag-grid ascending and descending values, "asc" and "desc"
// Typed as SortOrder on our side for our APIs, equivalent to SortDirection in ag-grid
export const ASCENDING = "asc" as SortOrder;
export const DESCENDING = "desc" as SortOrder;

// Sort order used for query APIs
export const ASCENDING_QUERY_API = "ASC" as SortOrderQueryApi;
export const DESCENDING_QUERY_API = "DESC" as SortOrderQueryApi;

export const sortCondition = (
    property: string,
    order: SortOrderQueryApi
): any => {
    return {
        objectType: "SortCondition",
        sortProperty: property,
        sortOrder: order
    };
};

export const getDefaultListState = (
    sortBy: string,
    maxResults: number = DEFAULT_PAGE_SIZE,
    search = "",
    sortOrder: SortOrder = ASCENDING,
    startIndex = 0,
    totalCount = -1
): QueryState => {
    return {
        maxResults: maxResults,
        search: search,
        sortBy: sortBy,
        sortOrder: sortOrder,
        startIndex: startIndex,
        totalCount: totalCount
    };
};
