import * as React from "react";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import isNil from "lodash.isnil";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { ExpandCollapseSpacer } from "common/components/CheckboxTreeNode";
import Flexbox from "common/components/styled/Flexbox";
import FlexboxWithBorder from "common/components/styled/FlexboxWithBorder";
import theme from "common/components/theme";
import {
    ExpandCollapseButton,
    TreeNodeIcon,
    TreeNodeItem,
    TreeNodeLabel,
    TreeNodeTitle
} from "common/components/TreeNode";
import FilterDropdownSearchBar from "common/filter/components/FilterDropdownSearchBar";
import TreeContainer from "common/filter/reactVirtualizedTree/components/TreeContainer";
import { UPDATE_TYPE } from "common/filter/reactVirtualizedTree/state/treeStateUtils";
import type { TreeNode } from "common/filter/reactVirtualizedTree/state/treeStateUtils";
import collapseIcon from "common/images/minus_square.png";
import expandIcon from "common/images/plus_square.png";
import { isEmptyString } from "common/util/lang";
import { getEntityIdWithoutVersion } from "common/util/object";
import type { TrackingComponentLabel } from "common/util/trackingEvents";

// Add node to newTree if level matches, else go down tree until node level is found

type FindNodeType = {
    children: FindNodeType[];
    value: string;
};

const findNodeChildren = (
    node: FindNodeType,
    nodeValue: string
): any[] | undefined | null => {
    if (
        isEqual(
            getEntityIdWithoutVersion(node.value),
            getEntityIdWithoutVersion(nodeValue)
        )
    ) {
        return node.children;
    } else if (node.children) {
        return getNodeChildren(node.children, nodeValue);
    }
    return null;
};

// Find node and return its children as tree
export const getNodeChildren = (
    nodes: FindNodeType[],
    nodeValue: string
): TreeNode[] | undefined | null => {
    if (isNil(nodeValue) || isEmptyString(nodeValue)) {
        return null;
    }
    for (let x = 0; x < nodes.length; x++) {
        const children = findNodeChildren(nodes[x], nodeValue);
        if (children) {
            return children;
        }
    }
    return null;
};

const TreeSelectWrapper = styled.div`
    display: flex;

    /* don't show hidden input field */
    input {
        display: none;
    }
`;
TreeSelectWrapper.displayName = "TreeSelectWrapper";

type TreeNodeOnChangeProps = {
    node: TreeNode;
    type: number;
};

type TreeNodeProps = {
    children: React.ReactNode[];
    hasMultipleLevels: boolean;
    node: TreeNode;
    onChange: (props: TreeNodeOnChangeProps) => void;
};

const TreeNodeValue = ({
    children,
    hasMultipleLevels,
    node,
    onChange
}: TreeNodeProps) => {
    const isExpanded = get(node, "state.expanded", false);

    const onExpand = () => {
        onChange({
            node: {
                ...node,
                state: {
                    ...node.state,
                    expanded: !isExpanded
                }
            },
            type: UPDATE_TYPE.UPDATE
        });
    };

    const expandCollapseIcon = isExpanded ? collapseIcon : expandIcon;
    const expandCollapseButton =
        node.children && node.children.length > 0 ? (
            <ExpandCollapseButton
                aria-label="Toggle"
                onClick={onExpand}
                title="Toggle"
                type="button"
            >
                <TreeNodeIcon src={expandCollapseIcon} />
            </ExpandCollapseButton>
        ) : hasMultipleLevels ? (
            <ExpandCollapseSpacer hasCheckbox={false} />
        ) : null;

    return (
        <TreeNodeItem>
            {expandCollapseButton}
            <TreeNodeLabel>
                <TreeNodeTitle hasCheckbox={false}>{children}</TreeNodeTitle>
            </TreeNodeLabel>
        </TreeNodeItem>
    );
};

type TreeViewOwnProps = {
    cssHeight?: string;
    hasSearch: boolean;
    nodes: any[];
    trackingComponentLabel: TrackingComponentLabel;
};

type TreeViewProps = TreeViewOwnProps & WithTranslation;

type TreeViewState = {
    nodes: TreeNode[];
    search: string;
};

class TreeView extends React.Component<TreeViewProps, TreeViewState> {
    static displayName = "TreeView";
    static defaultProps = {
        hasSearch: false,
        nodes: []
    };

    state = {
        nodes: [],
        search: ""
    };

    componentDidMount() {
        const { nodes } = this.props;
        const initialNodes = this.initNodes(nodes);

        this.setState({
            nodes: initialNodes
        });
    }

    componentDidUpdate(prevProps: TreeViewProps, prevState: TreeViewState) {
        const { nodes } = this.props;
        // Nodes changed so update state
        if (!isEqual(prevProps.nodes, nodes)) {
            const initialNodes = this.initNodes(nodes);
            this.setState({
                nodes: initialNodes,
                search: ""
            });
        }
    }

    // Set up name, id, and state for each node based off label and value values
    initNodes = (nodes: any[]): TreeNode[] => {
        return nodes.map(node => {
            const state = node.state
                ? node.state
                : {
                      expanded: true
                  };
            const children =
                node.children && node.children.length > 0
                    ? this.initNodes(node.children)
                    : node.children;
            return {
                ...node,
                children,
                id: node.value,
                name: node.label,
                state
            };
        });
    };

    /* --------------- Expand Node Functions --------------- */
    // DFS and on the way back up check if at least 1 children selected but not all selected
    expandPartiallySelectedNodes = (nodes: TreeNode[]): TreeNode[] => {
        const updatedNodes = nodes.map(node => {
            const children =
                node.children && get(node, "children.length") > 0
                    ? this.expandPartiallySelectedNodes(node.children)
                    : node.children;
            const expand =
                (get(node, "state.selected") &&
                    !get(node, "state.allChildrenSelected")) ||
                children.some(child => get(child, "state.expanded"));
            // If child is selected or already expanded, then expand node
            return {
                ...node,
                children: children,
                state: {
                    ...node.state,
                    expanded: expand
                }
            };
        });
        return updatedNodes;
    };

    // DFS and expand all nodes expect for leaf nodes
    expandAllNodes = (nodes: TreeNode[], expand: boolean): TreeNode[] => {
        return nodes.map(node => {
            const children =
                node.children && get(node, "children.length") > 0
                    ? this.expandAllNodes(node.children, expand)
                    : node.children;
            return {
                ...node,
                children,
                state: {
                    ...node.state,
                    expanded: children.length > 0 ? expand : false
                }
            };
        });
    };

    /* --------------- Filter Node Functions --------------- */
    // Return nodes that match search text
    filterNodes = (nodes: TreeNode[], searchText: string): TreeNode[] => {
        return JSON.parse(JSON.stringify(nodes)).filter(function iter(
            node: TreeNode
        ) {
            let temp: TreeNode[] = [];
            if (node.label.toLowerCase().includes(searchText.toLowerCase())) {
                return true;
            }
            if (!Array.isArray(node.children)) {
                return false;
            }
            temp = node.children.filter(iter);
            if (temp.length) {
                node.children = temp;
                return true;
            }
            return false;
        });
    };

    onFilterSearch = (searchStr: string) => {
        const { nodes } = this.state;
        const updatedNodes =
            !isNil(searchStr) && !isEmptyString(searchStr)
                ? this.expandAllNodes(nodes, true)
                : this.expandPartiallySelectedNodes(nodes);
        // Since we are only updating expanded state we don't need to verify select state on nodes
        this.setState({ nodes: updatedNodes, search: searchStr });
    };

    handleChange = (nodes: TreeNode[]) => {
        this.setState({
            nodes: nodes
        });
    };

    render() {
        const { cssHeight, hasSearch, trackingComponentLabel } = this.props;
        const { nodes, search } = this.state;

        // Pass filtered nodes to tree and full list to filtering container
        // Verify selected state is correct for filtered nodes, used to make sure parent selection is correct
        const filteredNodes =
            !isNil(search) && !isEmptyString(search)
                ? this.filterNodes(nodes, search)
                : nodes;

        if (nodes.length > 0) {
            const hasMultipleLevels = !!nodes.find(
                (node: TreeNode) => node.children && node.children.length > 0
            );
            return (
                <FlexboxWithBorder
                    cssWidth="100%"
                    cssHeight="100%"
                    flexDirection="column"
                >
                    {hasSearch && (
                        <Flexbox
                            backgroundColor={theme.universalBackground}
                            boxSizing="border-box"
                            cssWidth="100%"
                            flexDirection="column"
                            padding="16px"
                        >
                            <FilterDropdownSearchBar
                                changeHandler={this.onFilterSearch}
                                hideBottomBorder={true}
                                showBorder={true}
                                trackingComponentLabel={trackingComponentLabel}
                            />
                        </Flexbox>
                    )}
                    <Flexbox cssHeight={cssHeight ? cssHeight : "100%"}>
                        <TreeContainer
                            nodes={filteredNodes}
                            onChange={this.handleChange}
                            unfilteredNodes={nodes}
                        >
                            {/* @ts-expect-error - TreeContainer is external code - ignoring for now */}
                            {({ node, style, ...rest }) => (
                                <Flexbox
                                    style={{
                                        ...style,
                                        paddingRight: "16px",
                                        width: "max-content"
                                    }}
                                >
                                    {/* @ts-expect-error - TreeContainer is external code - ignoring for now */}
                                    <TreeNodeValue
                                        node={node}
                                        hasMultipleLevels={hasMultipleLevels}
                                        {...rest}
                                    >
                                        {node.name}
                                    </TreeNodeValue>
                                </Flexbox>
                            )}
                        </TreeContainer>
                    </Flexbox>
                </FlexboxWithBorder>
            );
        } else {
            return null;
        }
    }
}

export default withTranslation()(TreeView);
