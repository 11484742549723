import * as React from "react";
import styled from "styled-components/macro";
import theme from "common/components/theme";

const NEGATIVE_TYPE = "NEGATIVE";
const POSITIVE_TYPE = "POSITIVE";

const getIncrementalType = (value: number): string | undefined | null => {
    return value ? (value > 0 ? POSITIVE_TYPE : NEGATIVE_TYPE) : null;
};

type IncrementalValueProps = {
    children: React.ReactNode;
    color?: string;
    fontSize?: string;
    value: number;
};

const IncrementalValueWrapper = styled.div<fontSizePropsType>`
    color: ${props => props.color};
    font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
    line-height: 1.4;
    white-space: nowrap;
`;
IncrementalValueWrapper.displayName = "IncrementalValueWrapper";

const IncrementalValue = (props: IncrementalValueProps) => {
    const { children, color, fontSize, value } = props;
    const incrementalType = getIncrementalType(value);
    let incrementalColor = "";
    switch (incrementalType) {
        case POSITIVE_TYPE:
            incrementalColor = theme.success;
            break;
        case NEGATIVE_TYPE:
            incrementalColor = theme.alert;
            break;
        default:
            incrementalColor = color ? color : theme.lightGreyText;
            break;
    }
    return (
        <IncrementalValueWrapper color={incrementalColor} fontSize={fontSize}>
            {children}
        </IncrementalValueWrapper>
    );
};
IncrementalValue.displayName = "IncrementalValue";

export default IncrementalValue;
