// Refactored out of common/authActions to stop circular dependencies
import { getPageAfterLogout } from "shell/state/authActions";
import type { ActionOrThunk, GetState, AppDispatch, RootState } from "store";

export const LOGGED_OUT = "LOGGED_OUT";

export const loggedOut = (): SimpleAction<typeof LOGGED_OUT> => ({
    type: LOGGED_OUT
});

export const getAuthToken = (state: RootState): string => {
    let authToken = state?.auth?.token;
    if (!authToken) {
        authToken = "";
    }
    return authToken;
};

export const appRoot = () => {
    return (dispatch: AppDispatch, getState: GetState) => {
        const state = getState();
        const selectedApp = state.navigation.selectedApp;
        const page = getPageAfterLogout(selectedApp);
        dispatch({
            type: page
        });
    };
};

export const loggedOutAndAppRoot = (
    json: SuccessDto | AuthTokenDto,
    state: RootState
): ActionOrThunk[] => {
    const actions: ActionOrThunk[] = [];
    actions.push(loggedOut());
    actions.push(appRoot());
    return actions;
};
