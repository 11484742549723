// actions
export const ADMINISTRATION_PAGE = "ADMINISTRATION_PAGE";
export const DOWNLOADS_PAGE = "DOWNLOADS_PAGE";
export const TEAM_CREATE_PAGE = "TEAM_CREATE_PAGE";
export const TEAM_EDIT_PAGE = "TEAM_EDIT_PAGE";
export const TEAMS_PAGE = "TEAMS_PAGE";
export const TEAM_PAGE = "TEAM_PAGE";
export const USER_CREATE_PAGE = "USER_CREATE_PAGE";
export const USER_EDIT_PAGE = "USER_EDIT_PAGE";
export const USER_PAGE = "USER_PAGE";
export const USERS_PAGE = "USERS_PAGE";
