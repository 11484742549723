import * as React from "react";
import Recaptcha from "react-google-recaptcha";
import type { ReCAPTCHA } from "react-google-recaptcha";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import { withCookies } from "react-cookie";
import AuthDialogPage, {
    AUTH_BUTTON_WIDTH,
    AUTH_INPUT_WIDTH,
    AUTH_WIDTH,
    AuthError
} from "common/auth/components/AuthDialogPage";
import {
    clearError,
    getUserNameFromCookie,
    sendForgotPasswordEmail,
    toLogIn
} from "common/auth/state/authActions";
import Button from "common/components/Button";
import { FieldLabel } from "common/components/styled/Label";
import Link from "common/components/styled/Link";
import Input from "common/components/styled/Input";
import type { HasCookies } from "common/shell/util/types";
import { EVENT_NAME_PAGE_NAVIGATION } from "common/util/trackingEvents";
import type { AppDispatch, RootState } from "store";

type ForgotPasswordDialogProps = PropsFromRedux & HasCookies & WithTranslation;

type ForgotPasswordDialogState = {
    email: string;
    token: string | null;
};

class ForgotPasswordDialog extends React.Component<
    ForgotPasswordDialogProps,
    ForgotPasswordDialogState
> {
    static displayName = "ForgotPasswordDialog";
    emailInput: HTMLInputElement | undefined | null;
    recaptcha: ReCAPTCHA | undefined | null;

    constructor(props: ForgotPasswordDialogProps) {
        super(props);
        const userName = getUserNameFromCookie(props.cookies) || "";
        this.state = { email: userName, token: "" };
    }

    clearError = () => {
        if (this.props.error) {
            this.props.clearErrorAction();
        }
    };

    componentDidMount() {
        if (this.emailInput) {
            this.emailInput.focus();
        }
    }

    onChangeEmail = (event: React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({ email: event.currentTarget.value });
    };

    onChangeRecaptcha = (value: string | null) => {
        this.clearError();
        this.setState({ token: value });
    };

    onClickBackToLogin = (event: React.SyntheticEvent<HTMLLinkElement>) => {
        event.preventDefault();
        this.props.toLogInAction();
    };

    onClickNext = () => {
        this.clearError();
        this.props.forgotPasswordAction(this.state.email, this.state.token);
    };

    setEmailInputRef = (input: HTMLInputElement) => {
        this.emailInput = input;
    };

    setRecaptchaRef = (input: ReCAPTCHA) => {
        this.recaptcha = input;
    };

    render() {
        const { error, t } = this.props;
        if (error) {
            this.recaptcha?.reset();
        }
        return (
            <AuthDialogPage title={t("common:login.forgot_password")}>
                <FieldLabel>{t("common:general.email")}</FieldLabel>
                <Input
                    ref={this.setEmailInputRef}
                    name="email"
                    onChange={this.onChangeEmail}
                    type="text"
                    value={this.state.email}
                    cssWidth={AUTH_INPUT_WIDTH}
                    marginBottom="24px"
                />
                <Recaptcha
                    ref={this.setRecaptchaRef}
                    sitekey="6Le3gRAUAAAAAJ1yTjeEAxMfn0cxSXnmfGpMC_OB"
                    onChange={this.onChangeRecaptcha}
                />
                <Button
                    cssWidth={AUTH_BUTTON_WIDTH}
                    disabled={!(this.state.email && this.state.token)}
                    marginTop="24px"
                    onClick={this.onClickNext}
                    submit={true}
                    text={t("common:general.next")}
                    trackingComponentLabel={[
                        ForgotPasswordDialog.displayName,
                        "Submit"
                    ]}
                />
                <AuthError error={error} />
                <Link
                    cssWidth={AUTH_WIDTH}
                    marginTop="24px"
                    onClick={this.onClickBackToLogin}
                    trackingComponentLabel="Return To Login"
                    trackingEventName={EVENT_NAME_PAGE_NAVIGATION}
                >
                    {t("common:login.back_to_login")}
                </Link>
            </AuthDialogPage>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        error: state.auth.error
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        clearErrorAction: () => {
            dispatch(clearError());
        },
        forgotPasswordAction: (email: string, token: string | null) => {
            dispatch(sendForgotPasswordEmail(email, token));
        },
        toLogInAction: () => {
            dispatch(toLogIn());
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

// @ts-expect-error withCookies causes typescript errors
export default withTranslation()(withCookies(connector(ForgotPasswordDialog)));
