import * as React from "react";
import get from "lodash.get";
import isNil from "lodash.isnil";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
//import ReactHoverObservable from "react-hover-observer";
import styled from "styled-components/macro";
import {
    BUTTON_TYPE_CONTEXT_MENU,
    UntrackedButton
} from "common/components/Button";
import {
    DropdownContainer,
    DropdownItem,
    DropdownSectionHeader,
    DropdownSeparator
} from "common/components/Dropdown";
import {
    DEFAULT_ROW_HEIGHT,
    LARGE_ROW_HEIGHT
} from "common/components/grid/AgDataGridUtil";
import {
    boxShadow,
    sizeProps,
    sizePropTypes
} from "common/components/styled/util";
import theme from "common/components/theme";
import { ReactComponent as EllipsesIcon } from "common/icons/Ellipses.svg";
import { isEmptyString } from "common/util/lang";
import { reduceObject } from "common/util/object";
import { getTrackingEventData, trackEvent } from "common/util/tracking";
import { EVENT_NAME_SELECTION_CHOICE } from "common/util/trackingEvents";
import type { RootState } from "store";

const DEFAULT_MIN_WIDTH = 200;
const GRID_SIDE_MARGIN = 25;
const GRID_TOP_DEFAULT_MARGIN = 7;
const GRID_TOP_LARGE_MARGIN = 27;

type GridRowDropdownContentWrapperProps = positionPropsType;

const GridRowDropdownContentWrapper = styled.div<GridRowDropdownContentWrapperProps>`
    background: ${theme.background};
    border: 1px solid ${theme.menuBorder};
    border-radius: 2px;
    box-shadow: ${boxShadow("dropdown")};
    color: ${theme.text};
    display: block;
    font-size: 14px;
    margin-top: 2px;
    overflow-y: auto;
    position: absolute;
    top: ${props => props.top}px;
    z-index: 20000;
    ${props => props.left && `left: ${props.left}px;`}
    ${props => props.right && `right: ${props.right}px;`}
    ${sizeProps};
`;
GridRowDropdownContentWrapper.displayName = "GridRowDropdownContentWrapper";

type AgGridRowMenuDefaultProps = {
    getMenuContainerRef: () => HTMLDivElement | null;
    rowHeight: number;
};

type AgGridRowMenuOwnProps = {
    className: string;
    gridId: string;
    gridRowMenu: (rowData: any) => GridMenuItemType[];
    rowData: any;
};

type AgGridRowMenuProps = AgGridRowMenuDefaultProps &
    AgGridRowMenuOwnProps &
    PropsFromRedux;

type AgGridRowMenuState = {
    isOpen: boolean;
};

type GridRowDropdownContentProps = {
    buttonRef: HTMLDivElement | undefined | null;
    getMenuContainerRef: () => HTMLDivElement | null;
    menuItems: any[];
    onClickHandler: (menuItem: GridMenuItemType) => void;
    rowHeight: number;
} & sizePropsType;

type GridRowDropdownContentState = {
    top: number;
};

const getTopMargin = (rowHeight: number): number => {
    let topMargin = GRID_TOP_DEFAULT_MARGIN;
    if (rowHeight === LARGE_ROW_HEIGHT) {
        topMargin = GRID_TOP_LARGE_MARGIN;
    }
    return topMargin;
};

class GridRowDropdownContent extends React.Component<
    GridRowDropdownContentProps,
    GridRowDropdownContentState
> {
    menuRef: HTMLDivElement | undefined | null;

    state = {
        top: 0
    };

    componentDidMount() {
        this.positionMenu();
    }

    componentDidUpdate() {
        this.positionMenu();
    }

    setMenuRef = (element: HTMLDivElement) => {
        this.menuRef = element;
    };

    positionMenu = () => {
        const { buttonRef, getMenuContainerRef, rowHeight } = this.props;
        const { top } = this.state;

        const menuContainerRef = getMenuContainerRef();

        // if we have a trigger, use that positioning, otherwise just zero it.
        const buttonRect = buttonRef
            ? buttonRef.getBoundingClientRect()
            : { bottom: 0, top: 0 };
        const containerRect = menuContainerRef
            ? menuContainerRef.getBoundingClientRect()
            : { bottom: 0, height: 0, top: 0 };
        const menuRect = this.menuRef
            ? this.menuRef.getBoundingClientRect()
            : { height: 0 };

        if (menuRect.height >= containerRect.height) {
            return; // the menu is larger than or equal to the container, there's no positioning that's going to save it.
        }

        const positionBelowMenu =
            menuRect.height + buttonRect.bottom <= containerRect.bottom;

        const topMargin = getTopMargin(rowHeight);
        let belowOffset = 2;
        if (topMargin === GRID_TOP_DEFAULT_MARGIN) {
            belowOffset = 6;
        }
        const newTop =
            (positionBelowMenu
                ? buttonRect.bottom - belowOffset - topMargin
                : buttonRect.top - menuRect.height - 2 + topMargin) -
            containerRect.top;
        if (top !== newTop) {
            this.setState({ top: newTop });
        }
    };

    render() {
        const { buttonRef, getMenuContainerRef, menuItems, onClickHandler } =
            this.props;
        const { top } = this.state;
        const menuContainerRef = getMenuContainerRef();
        const right =
            buttonRef && menuContainerRef
                ? menuContainerRef.getBoundingClientRect().right -
                  buttonRef.getBoundingClientRect().right +
                  GRID_SIDE_MARGIN
                : 0;
        let position: {
            left?: number;
            right?: number;
        } = { right };
        // See if dropdown is out of window
        if (right + DEFAULT_MIN_WIDTH > window.innerWidth) {
            position = {
                left:
                    buttonRef && menuContainerRef
                        ? buttonRef.getBoundingClientRect().left -
                          menuContainerRef.getBoundingClientRect().left +
                          GRID_SIDE_MARGIN
                        : 0
            };
        }

        return (
            <GridRowDropdownContentWrapper
                {...reduceObject(this.props, ...sizePropTypes)}
                ref={this.setMenuRef}
                top={top}
                {...position}
            >
                {menuItems.map((menuItem, index) => {
                    if (menuItem.isMenuSeparator) {
                        return <DropdownSeparator key={index + "-separator"} />;
                    } else {
                        if (menuItem.isMenuHeader) {
                            return (
                                <DropdownSectionHeader
                                    key={index + "-section-header"}
                                >
                                    {menuItem.label}
                                </DropdownSectionHeader>
                            );
                        } else {
                            return (
                                <DropdownItem
                                    key={index}
                                    onClick={() => {
                                        onClickHandler(menuItem);
                                    }}
                                >
                                    {menuItem.label}
                                </DropdownItem>
                            );
                        }
                    }
                })}
            </GridRowDropdownContentWrapper>
        );
    }
}

class AgGridRowMenu extends React.Component<
    AgGridRowMenuProps,
    AgGridRowMenuState
> {
    static displayName = "AgGridRowMenu";
    static defaultProps: AgGridRowMenuDefaultProps = {
        getMenuContainerRef: () => null,
        rowHeight: DEFAULT_ROW_HEIGHT
    };

    buttonRef: HTMLDivElement | undefined | null;

    constructor(props: AgGridRowMenuProps) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    setButtonRef = (element?: HTMLDivElement | null) => {
        this.buttonRef = element;
    };

    onClickHandler = (menuItem: GridMenuItemType) => {
        const { gridId } = this.props;
        trackEvent(
            getTrackingEventData(
                AgGridRowMenu.displayName,
                [gridId, menuItem.value],
                EVENT_NAME_SELECTION_CHOICE
            )
        );
        menuItem.onClickHandler(menuItem.data);
        this.setState({ isOpen: false });
    };

    onTriggerClick = () => {
        if (this.state.isOpen) {
            this.setState({ isOpen: false });
        } else {
            this.setState({ isOpen: true });
        }
    };

    onMouseLeave = () => {
        this.setState({ isOpen: false });
    };

    render() {
        const {
            allowableActions,
            getMenuContainerRef,
            gridRowMenu,
            rowData,
            rowHeight
        } = this.props;

        let menuItems: GridMenuItemType[] = [];
        if (rowData) {
            menuItems = gridRowMenu(rowData).filter(item => {
                const allowableAction = item.allowableAction;
                if (
                    !isNil(allowableAction) &&
                    !isEmptyString(allowableAction)
                ) {
                    return !!get(allowableActions, allowableAction, false);
                }
                return true;
            });
        }

        const topMargin = getTopMargin(rowHeight);

        const menuContainer = getMenuContainerRef();

        // don't show menu item if no menus
        if (menuItems.length <= 0) {
            return null;
        } else {
            return (
                <DropdownContainer
                    margin="auto"
                    onMouseLeave={this.onMouseLeave}
                    ref={this.setButtonRef}
                >
                    <UntrackedButton
                        icon={EllipsesIcon}
                        margin={`${topMargin}px ${GRID_SIDE_MARGIN}px`}
                        onClick={this.onTriggerClick}
                        padding="4px 8px"
                        type={BUTTON_TYPE_CONTEXT_MENU}
                    />
                    {this.state.isOpen &&
                        menuContainer &&
                        ReactDOM.createPortal(
                            <GridRowDropdownContent
                                buttonRef={this.buttonRef}
                                closeHandler={() => {
                                    this.setState({ isOpen: false });
                                }}
                                getMenuContainerRef={getMenuContainerRef}
                                menuItems={menuItems}
                                onClickHandler={this.onClickHandler}
                                rowHeight={rowHeight}
                                {...reduceObject(this.props, ...sizePropTypes)}
                            />,
                            menuContainer
                        )}
                </DropdownContainer>
            );
        }
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        allowableActions: get(state, "init.allowableActions", {})
    };
};

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AgGridRowMenu);
