import type { AnyAction } from "redux";
import {
    RECEIVE_OFFER_PREVIEW,
    CLOSE_OFFER_PREVIEW
} from "offers/state/offerPreviewActions";
import noAction from "common/util/noAction";

type OfferPreviewState = {
    height: number;
    isOpen: boolean;
    minHeight: number;
    previewHtml: string;
    width: number;
};

export const defaultState: OfferPreviewState = {
    isOpen: false,
    previewHtml: "",
    height: 0,
    minHeight: 0,
    width: 0
};

const reduceOfferPreview = (data: any): OfferPreviewState => {
    return {
        isOpen: true,
        previewHtml: data.primaryPreviewFormatHtml,
        height: data.primaryPreview_height,
        minHeight: data.primaryPreview_minHeight,
        width: data.primaryPreview_width
    };
};

const offerPreview = (
    state: OfferPreviewState = defaultState,
    action: AnyAction = noAction
): OfferPreviewState => {
    switch (action.type) {
        case RECEIVE_OFFER_PREVIEW:
            return Object.assign(
                {},
                state,
                reduceOfferPreview(action.offerPreview)
            );
        case CLOSE_OFFER_PREVIEW:
            return defaultState;
        default:
            return state;
    }
};

export default offerPreview;
