import isNil from "lodash.isnil";
import { isEmptyString } from "common/util/lang";
import { PREFERENCE_PREFIX } from "shell/util/preferences";

export const getPreference = (
    name: string,
    preferences: any,
    defaultValue: any = null
): any => {
    let preference = defaultValue;
    if (name && preferences) {
        const pref = preferences[PREFERENCE_PREFIX + name];
        if (!isNil(pref) && !isEmptyString(pref)) {
            preference = pref;
        }
    }
    return preference;
};
