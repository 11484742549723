import React from "react";
import { withCookies } from "react-cookie";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import AuthDialogPage, {
    AUTH_BUTTON_WIDTH,
    AUTH_INPUT_WIDTH,
    AuthError,
    AuthInstructions
} from "common/auth/components/AuthDialogPage";
import { resetPassword } from "common/auth/state/authActions";
import Button from "common/components/Button";
import Input from "common/components/styled/Input";
import { FieldLabel } from "common/components/styled/Label";
import type { Cookies, HasCookies } from "common/shell/util/types";
import type { AppDispatch, RootState } from "store";

type ResetPasswordDialogProps = PropsFromRedux & HasCookies & WithTranslation;

type ResetPasswordDialogState = {
    confirmNewPassword: string;
    newPassword: string;
};

class ResetPasswordDialog extends React.Component<
    ResetPasswordDialogProps,
    ResetPasswordDialogState
> {
    static displayName = "ResetPasswordDialog";
    state: ResetPasswordDialogState = {
        newPassword: "",
        confirmNewPassword: ""
    };

    newPasswordInput: HTMLInputElement | undefined | null;

    componentDidMount() {
        this.newPasswordInput && this.newPasswordInput.focus();
    }

    onChangeConfirmPassword = (
        event: React.SyntheticEvent<HTMLInputElement>
    ) => {
        this.setState({ confirmNewPassword: event.currentTarget.value });
    };

    onChangeNewPassword = (event: React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({ newPassword: event.currentTarget.value });
    };

    onClickSubmit = () => {
        this.props.resetPassword(
            this.props.cookies,
            this.props.token,
            this.state.newPassword,
            this.state.confirmNewPassword
        );
    };

    validate = (): boolean => {
        const { newPassword, confirmNewPassword } = this.state;
        return Boolean(newPassword && confirmNewPassword);
    };

    setNewPasswordInputRef = (input: HTMLInputElement) => {
        this.newPasswordInput = input;
    };

    render() {
        const { error, t } = this.props;
        return (
            <AuthDialogPage title={t("common:login.reset_password")}>
                <AuthInstructions>
                    {t("common:login.password_requirements")}
                </AuthInstructions>
                <FieldLabel>{t("common:login.new_password")}</FieldLabel>
                <Input
                    marginBottom="16px"
                    name="newPassword"
                    onChange={this.onChangeNewPassword}
                    type="password"
                    cssWidth={AUTH_INPUT_WIDTH}
                    ref={this.setNewPasswordInputRef}
                />
                <FieldLabel>{t("common:login.confirm_password")}</FieldLabel>
                <Input
                    name="confirmPassword"
                    onChange={this.onChangeConfirmPassword}
                    type="password"
                    cssWidth={AUTH_INPUT_WIDTH}
                />
                <Button
                    cssWidth={AUTH_BUTTON_WIDTH}
                    disabled={!this.validate()}
                    marginTop="24px"
                    onClick={this.onClickSubmit}
                    submit={true}
                    text={t("common:general.submit")}
                    trackingComponentLabel={[
                        ResetPasswordDialog.displayName,
                        "Submit"
                    ]}
                />
                <AuthError error={error} />
            </AuthDialogPage>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        error: state.auth.error,
        token: state.auth.token
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        resetPassword: (
            cookies: Cookies,
            token: string,
            newPassword: string,
            confirmNewPassword: string
        ) => {
            dispatch(
                resetPassword(cookies, token, newPassword, confirmNewPassword)
            );
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

// @ts-expect-error withCookies causes typescript errors
export default withTranslation()(withCookies(connector(ResetPasswordDialog)));
