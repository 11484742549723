import * as React from "react";
import Tippy from "@tippy.js/react";
import type { Placement } from "tippy.js";
import isEqual from "lodash.isequal";
import styled from "styled-components/macro";
// only import stylesheets for themes needed
import "tippy.js/themes/light-border.css";
import TruncatedDiv from "common/components/styled/TruncatedDiv";
import Flexbox from "common/components/styled/Flexbox";
import TooltipWrapper from "common/components/styled/TooltipWrapper";
import { getTrackingEventData, trackEvent } from "common/util/tracking";
import { EVENT_NAME_OPEN_CLOSE } from "common/util/trackingEvents";
import type { TrackingComponentLabel } from "common/util/trackingEvents";

const isTextOverflow = (
    element?: HTMLDivElement | null,
    ignoreHeightOverflow?: boolean
): boolean => {
    if (element) {
        if (ignoreHeightOverflow) {
            return element.clientWidth < element.scrollWidth;
        } else {
            return (
                element.clientWidth < element.scrollWidth ||
                element.scrollHeight > element.clientHeight
            );
        }
    } else {
        return false;
    }
};

// Styled components
export const TooltipHeader = styled.div`
    font-size: 12px;
    margin-bottom: 4px;
`;
TooltipHeader.displayName = "TooltipHeader";

export const TooltipTitleRow = styled(Flexbox)`
    font-family: "BrandonTextBold";
    font-size: 14px;
    margin: 4px 0;
`;
TooltipTitleRow.displayName = "TooltipTitleRow";

export const TooltipRow = styled(Flexbox)`
    font-size: 14px;
    margin: 4px 0;

    &:first-child {
        margin-top: 0px;
    }

    &:last-child {
        margin-bottom: 0px;
    }
`;
TooltipRow.displayName = "ToolTipRow";

export const THEME_DEFAULT = "light-border" as string;
export const THEME_DARK = "" as string;

export type TooltipTheme = typeof THEME_DEFAULT | typeof THEME_DARK;

type TooltipProps = {
    boundary?: string;
    checkOverflow: boolean;
    children?: React.ReactNode;
    cssWidth?: string;
    id?: string;
    ignoreHeightOverflow?: boolean;
    marginAuto?: boolean;
    maxWidth?: string;
    overflowTooltip?: React.ReactNode;
    placement: Placement;
    text?: string;
    tooltipTheme?: TooltipTheme;
    trackingComponentLabel?: TrackingComponentLabel;
};

type TooltipState = {
    overflow: boolean;
};

class Tooltip extends React.Component<TooltipProps, TooltipState> {
    element: HTMLDivElement | undefined | null;

    static displayName = "Tooltip";

    static defaultProps = {
        checkOverflow: false,
        ignoreHeightOverflow: false,
        marginAuto: false,
        placement: "bottom",
        trackingComponentLabel: ""
    };

    constructor(props: TooltipProps) {
        super(props);

        this.state = {
            overflow: false
        };
    }

    shouldComponentUpdate(nextProps: TooltipProps): boolean {
        const { ignoreHeightOverflow } = this.props;
        const { overflow } = this.state;
        const newOverflow = isTextOverflow(this.element, ignoreHeightOverflow);
        if (overflow !== newOverflow) {
            this.setOverflow();
            return true;
        } else if (!isEqual(nextProps, this.props)) {
            return true;
        }
        return false;
    }

    checkOverflow = () => {
        const { checkOverflow } = this.props;
        const { overflow } = this.state;
        if (checkOverflow && !overflow) {
            this.setOverflow();
        }
    };

    setOverflow = () => {
        const { checkOverflow, ignoreHeightOverflow } = this.props;
        if (checkOverflow) {
            const overflow = isTextOverflow(this.element, ignoreHeightOverflow);
            this.setState({ overflow });
        }
    };

    onShown = () => {
        const { trackingComponentLabel } = this.props;
        if (trackingComponentLabel) {
            trackEvent(
                getTrackingEventData(
                    Tooltip.displayName,
                    trackingComponentLabel,
                    EVENT_NAME_OPEN_CLOSE
                )
            );
        }
    };

    setElementRef = (element?: HTMLDivElement | null) => {
        // set reference to element so that we can use this for componentDidUpdate
        this.element = element;

        // component has been mounted, so we will calculate initial overflow for element
        this.setOverflow();
    };

    render() {
        const {
            boundary,
            checkOverflow,
            children,
            cssWidth,
            marginAuto,
            maxWidth,
            overflowTooltip,
            placement,
            text,
            tooltipTheme = THEME_DEFAULT,
            trackingComponentLabel
        } = this.props;
        const { overflow } = this.state;
        if (React.Children.count(children) !== 2 && !checkOverflow) {
            throw Error(
                "Tooltip requires two children - the triggering element and the tooltip content."
            );
        }

        const chld = React.Children.toArray(children);
        const trigger = chld[0];

        // Need to update tippy, and fixing this typing in future
        let triggerWithProps: React.ReactElement<
            any,
            string | React.JSXElementConstructor<any>
        >;
        let content: React.ReactNode;

        if (checkOverflow) {
            const marginProps: { marginBottom?: string; marginTop?: string } =
                {};
            if (marginAuto) {
                marginProps.marginBottom = "auto";
                marginProps.marginTop = "auto";
            }
            triggerWithProps = (
                <TruncatedDiv
                    {...marginProps}
                    maxWidth={cssWidth}
                    onMouseOver={this.checkOverflow}
                    ref={this.setElementRef}
                >
                    {text}
                </TruncatedDiv>
            );
            content = overflowTooltip ? (
                overflowTooltip
            ) : (
                <TooltipWrapper>{text}</TooltipWrapper>
            );
        } else {
            triggerWithProps = trigger as React.ReactElement<
                any,
                string | React.JSXElementConstructor<any>
            >;
            content = chld[1];
        }

        let tooltipProperties = {
            arrow: true,
            ...(maxWidth ? { maxWidth } : {}),
            //maxWidth: maxWidth ? maxWidth : 400,
            placement: placement
        };
        if (trackingComponentLabel) {
            tooltipProperties = Object.assign({}, tooltipProperties, {
                onShown: this.onShown
            });
        }

        if (checkOverflow) {
            // overflow tooltip should be light colored
            tooltipProperties = Object.assign({}, tooltipProperties, {
                theme: THEME_DEFAULT
            });
        }

        tooltipProperties = Object.assign({}, tooltipProperties, {
            theme: tooltipTheme
        });

        if (boundary) {
            tooltipProperties = Object.assign({}, tooltipProperties, {
                boundary
            });
        }
        /*
         trigger="manual" onCreate={tip => (this.tip = tip)}
         */
        // only use tippy if we are explicitly checking for overflow and there is overflow,
        // or we just want to use the tooltip for something like help text
        // z-index is so tooltips appear over dropdowns
        if (!checkOverflow || (checkOverflow && overflow)) {
            return (
                <Tippy
                    className="tooltip"
                    // @ts-expect-error Need to update tippy, it is using deprecated typing
                    content={content}
                    zIndex={20001}
                    {...tooltipProperties}
                >
                    {triggerWithProps}
                </Tippy>
            );
        }
        // otherwise return tooltip with ref to handle resize scenarios
        return triggerWithProps;
    }
}

export default Tooltip;
