import * as React from "react";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import AuthDialogPage, {
    AUTH_BUTTON_WIDTH,
    AUTH_INPUT_WIDTH,
    AUTH_WIDTH,
    AuthError,
    AuthInstructions
} from "common/auth/components/AuthDialogPage";
import { answerSecurityQuestion, toLogIn } from "common/auth/state/authActions";
import { appRoot } from "common/auth/state/commonAuthActions";
import Button from "common/components/Button";
import Input from "common/components/styled/Input";
import Label from "common/components/styled/Label";
import Link from "common/components/styled/Link";
import { EVENT_NAME_PAGE_NAVIGATION } from "common/util/trackingEvents";
import type { AppDispatch, RootState } from "store";

type AskSecurityQuestionDialogProps = PropsFromRedux & WithTranslation;

type AskSecurityQuestionDialogState = {
    securityAnswer: string;
};

class AskSecurityQuestionDialog extends React.Component<
    AskSecurityQuestionDialogProps,
    AskSecurityQuestionDialogState
> {
    static displayName = "AskSecurityQuestionDialog";
    state: AskSecurityQuestionDialogState = {
        securityAnswer: ""
    };

    securityAnswer: HTMLInputElement | undefined | null;

    componentDidMount() {
        this.securityAnswer && this.securityAnswer.focus();
    }

    onClickBackToLogin = (event: React.SyntheticEvent<HTMLLinkElement>) => {
        event.preventDefault();
        this.props.toLogInAction();
    };

    onChangeSecurityAnswer = (
        event: React.SyntheticEvent<HTMLInputElement>
    ) => {
        this.setState({ securityAnswer: event.currentTarget.value });
    };

    onClickSubmit = () => {
        const { token, questionId, answerSecurityQuestion } = this.props;
        if (questionId && token) {
            answerSecurityQuestion(
                token,
                questionId,
                this.state.securityAnswer
            );
        }
    };

    validate = (): boolean => {
        return Boolean(this.state.securityAnswer);
    };

    setSecurityAnswerRef = (input: HTMLInputElement) => {
        this.securityAnswer = input;
    };

    render() {
        const { error, question, t } = this.props;
        return (
            <AuthDialogPage title={t("common:login.security_question")}>
                {question && (
                    <React.Fragment>
                        <AuthInstructions>
                            {t(
                                "common:login.answer_the_following_security_question"
                            )}
                        </AuthInstructions>
                        <Label>{question}</Label>
                        <Input
                            name="securityAnswer"
                            onChange={this.onChangeSecurityAnswer}
                            ref={this.setSecurityAnswerRef}
                            type="text"
                            cssWidth={AUTH_INPUT_WIDTH}
                            marginBottom="24px"
                        />
                        <Button
                            disabled={!this.validate()}
                            onClick={this.onClickSubmit}
                            cssWidth={AUTH_BUTTON_WIDTH}
                            text={t("common:general.submit")}
                            trackingComponentLabel={[
                                AskSecurityQuestionDialog.displayName,
                                "Submit"
                            ]}
                            submit={true}
                        />
                    </React.Fragment>
                )}
                <AuthError error={error} />
                {!question && (
                    <Link
                        onClick={this.onClickBackToLogin}
                        cssWidth={AUTH_WIDTH}
                        marginTop="24px"
                        trackingComponentLabel="Return To Login"
                        trackingEventName={EVENT_NAME_PAGE_NAVIGATION}
                    >
                        {t("common:login.back_to_login")}
                    </Link>
                )}
            </AuthDialogPage>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        error: state.auth.error,
        question: state.auth.question,
        questionId: state.auth.questionId,
        token: state.auth.token
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        answerSecurityQuestion: (
            token: string,
            questionId: string,
            answer: string
        ) => {
            dispatch(answerSecurityQuestion(token, questionId, answer));
        },
        toLogInAction: () => {
            dispatch(appRoot());
            dispatch(toLogIn());
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withTranslation()(connector(AskSecurityQuestionDialog));
