export const METRIC_TYPE_INTEGER = "integer";
export const METRIC_TYPE_INTEGER_COMPARISON = "integerComparison";
export const METRIC_TYPE_MONEY = "money";
export const METRIC_TYPE_MONEY_COMPARISON = "moneyComparison";
export const METRIC_TYPE_NUMBER = "number";
export const METRIC_TYPE_NUMBER_COMPARISON = "numberComparison";
export const METRIC_TYPE_PERCENT = "percent";
export const METRIC_TYPE_PERCENT_COMPARISON = "percentComparison";
export const METRIC_TYPE_PRICE = "price";

export type MetricType =
    | typeof METRIC_TYPE_INTEGER
    | typeof METRIC_TYPE_MONEY
    | typeof METRIC_TYPE_NUMBER
    | typeof METRIC_TYPE_PERCENT
    | typeof METRIC_TYPE_PRICE;

export type MetricTypeComparison =
    | typeof METRIC_TYPE_INTEGER_COMPARISON
    | typeof METRIC_TYPE_MONEY_COMPARISON
    | typeof METRIC_TYPE_NUMBER_COMPARISON
    | typeof METRIC_TYPE_PERCENT_COMPARISON;

export type Metric = {
    labelKey: string;
    name: string;
    type: MetricType;
};
