import get from "lodash.get";
import type { AnyAction } from "redux";
import noAction from "common/util/noAction";
import {
    RECEIVE_GUIDELINE_COMPLIANCE_OVERVIEW,
    RECEIVE_GUIDELINE_COVERAGE_OVERVIEW,
    RECEIVE_TOP_PERFORMANCE_OVERVIEW,
    REQUEST_GUIDELINE_COMPLIANCE_OVERVIEW,
    REQUEST_GUIDELINE_COVERAGE_OVERVIEW,
    REQUEST_TOP_PERFORMANCE_OVERVIEW,
    RESET_GUIDELINE_COMPLIANCE_OVERVIEW,
    UPDATE_SELECTED_GUIDANCES
} from "overview/state/overviewActions";
import {
    reduceGuidelineCompliance,
    reduceGuidelineCoverage,
    reduceTopPerformanceSeries
} from "overview/state/overviewStateUtils";
import type {
    GuidelineComplianceType,
    GuidelineCoverageType,
    TopPerformanceType
} from "overview/state/overviewStateUtils";

type OverviewState = {
    guidelineCompliance: GuidelineComplianceType;
    guidelineCoverage: GuidelineCoverageType;
    topPerformance: TopPerformanceType;
};

const defaultCoverageState = {
    promoSalesForWeeksWithCoverage: "",
    promoSalesForWeeksWithoutCoverage: "",
    totalWeeks: -1,
    weeksWithCoverage: 0
};

const defaultTotalCount = {
    holidays: -1,
    ppgs: -1,
    regions: -1,
    retailers: -1
};

export const defaultGuidelineCompliance: GuidelineComplianceType = {
    compliancePerMonth: [],
    initialTotalCount: defaultTotalCount,
    mostCompliantHolidays: [],
    mostCompliantPpgs: [],
    mostCompliantRegions: [],
    mostCompliantRetailers: [],
    leastCompliantHolidays: [],
    leastCompliantPpgs: [],
    leastCompliantRegions: [],
    leastCompliantRetailers: [],
    selectedGuidances: [],
    totalCount: defaultTotalCount
};

export const defaultGuidelineCoverageState = {
    crossPromotionCoverage: defaultCoverageState,
    frequencyCoverage: defaultCoverageState,
    hasCurrentGuidelines: false,
    hasCurrentRecommendations: false,
    offerStructureCoverage: defaultCoverageState,
    promotedPriceCoverage: defaultCoverageState,
    timingCoverage: defaultCoverageState,
    totalCoverage: defaultCoverageState,
    visualCoverage: defaultCoverageState
};

const defaultTopPerformanceState = {
    series: []
};

export const defaultState: OverviewState = {
    guidelineCompliance: defaultGuidelineCompliance,
    guidelineCoverage: defaultGuidelineCoverageState,
    topPerformance: defaultTopPerformanceState
};

const overview = (
    state: OverviewState = defaultState,
    action: AnyAction = noAction
): OverviewState => {
    switch (action.type) {
        case RECEIVE_GUIDELINE_COMPLIANCE_OVERVIEW: {
            const selectedGuidances = get(
                state,
                "guidelineCompliance.selectedGuidances"
            );
            const totalCount = get(
                state,
                "guidelineCompliance.initialTotalCount"
            );
            return Object.assign({}, state, {
                guidelineCompliance: reduceGuidelineCompliance(
                    action.response,
                    selectedGuidances,
                    totalCount
                )
            });
        }
        case RECEIVE_GUIDELINE_COVERAGE_OVERVIEW:
            return Object.assign({}, state, {
                guidelineCoverage: reduceGuidelineCoverage(action.response)
            });
        case RECEIVE_TOP_PERFORMANCE_OVERVIEW:
            return Object.assign({}, state, {
                topPerformance: {
                    series: reduceTopPerformanceSeries(
                        get(action, "response.series", [])
                    )
                }
            });

        case REQUEST_GUIDELINE_COMPLIANCE_OVERVIEW:
            return state;
        case REQUEST_GUIDELINE_COVERAGE_OVERVIEW:
            return Object.assign({}, state, {
                guidelineCoverage: defaultGuidelineCoverageState
            });
        case REQUEST_TOP_PERFORMANCE_OVERVIEW:
            return Object.assign({}, state, {
                topPerformance: defaultTopPerformanceState
            });
        case RESET_GUIDELINE_COMPLIANCE_OVERVIEW:
            return Object.assign({}, state, {
                guidelineCompliance: {
                    ...defaultGuidelineCompliance,
                    initialTotalCount:
                        state.guidelineCompliance.initialTotalCount,
                    selectedGuidances:
                        state.guidelineCompliance.selectedGuidances
                }
            });
        case UPDATE_SELECTED_GUIDANCES:
            return Object.assign({}, state, {
                guidelineCompliance: {
                    ...state.guidelineCompliance,
                    selectedGuidances: action.selectedGuidances
                }
            });
        default:
            return state;
    }
};

export default overview;
