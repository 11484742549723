export const UPDATE_TYPE = {
    ADD: 0,
    DELETE: 1,
    UPDATE: 2,
    SELECT: 3
};

// Tree Node has 3 states
// expanded - Is node collapsed or expanded to show children
// allChildrenSelected - Are all children selected? true - Show a check mark, false - Show a dash
// selected - Self or children are selected, look at allChildrenSelected to see if partially or fully selected
type TreeNodeState = {
    allChildrenSelected: boolean;
    expanded: boolean;
    selected: boolean;
};

export type TreeNode = {
    children: TreeNode[];
    id: string;
    label: string;
    level: string;
    name: string;
    state: TreeNodeState;
    value: string;
};

export type FlattenedTreeNode = {
    children: FlattenedTreeNode[];
    deepness: number;
    parents: string[];
} & TreeNode;
export type UpdateNode = {
    node: FlattenedTreeNode;
    type: number;
};
