import * as React from "react";
import get from "lodash.get";
import isNil from "lodash.isnil";
import styled from "styled-components/macro";
import type { Placement } from "tippy.js";
import Flexbox from "common/components/styled/Flexbox";
import { FieldLabel } from "common/components/styled/Label";
import {
    borderRadius,
    hidePrintProps,
    marginPropTypes,
    marginProps,
    paddingPropTypes,
    paddingProps,
    sizePropTypes,
    sizeProps
} from "common/components/styled/util";
import SvgIcon from "common/components/SvgIcon";
import theme from "common/components/theme";
import Tooltip from "common/components/Tooltip";
import { ReactComponent as DownCaretIcon } from "common/icons/DownCaret.svg";
import { isEmptyString } from "common/util/lang";
import { reduceObject } from "common/util/object";
import { getTrackingEventData, trackEvent } from "common/util/tracking";
import { EVENT_NAME_SELECTION_CHOICE } from "common/util/trackingEvents";
import type {
    TrackingComponentLabel,
    TrackingEventName
} from "common/util/trackingEvents";

export const POSITION_ABOVE = "above" as string;
export const POSITION_BELOW = "below" as string;

export type VerticalPosition = typeof POSITION_ABOVE | typeof POSITION_BELOW;

export const POSITION_LEFT = "left" as string;
export const POSITION_CENTER = "center" as string;
export const POSITION_RIGHT = "right" as string;

export type HorizontalPosition =
    | typeof POSITION_LEFT
    | typeof POSITION_CENTER
    | typeof POSITION_RIGHT;

type DropdownContainerProps = {
    backgroundColor?: string;
    children: React.ReactNode | React.ReactNode[];
    display?: string;
} & marginPropsType;

export const DropdownContainer = styled.div<DropdownContainerProps>`
    align-self: center;
    border-radius: ${borderRadius("input")};
    display: ${props => props.display || "inline-block"};
    position: relative;
    white-space: nowrap;
    ${props =>
        props.backgroundColor && `background-color: ${props.backgroundColor};`}
    ${marginProps}
    ${sizeProps}
    ${hidePrintProps}
`;
DropdownContainer.displayName = "DropdownContainer";

// Component used for specific hover color styling in trigger
// We want hover color to propagate to this elemnt
export const DropdownSVGWrapper = styled(Flexbox)``;
DropdownSVGWrapper.displayName = "DropdownSVGWrapper";

type DropdownTriggerprops = {
    borderColor?: string;
    hoverColor?: string;
};

export const DropdownTrigger = styled.div<DropdownTriggerprops>`
    color: ${props => props.color || theme.text};
    cursor: pointer;
    fill: ${props => props.color || theme.text};
    ${paddingProps}
    ${props =>
        props.borderColor &&
        `border: 1px solid ${props.borderColor};
        border-radius: ${borderRadius("input")(props)};
    `};

    &:hover,
    &:hover ${DropdownSVGWrapper} > * {
        color: ${props => props.hoverColor || props.color};
        border-color: ${theme.filterDropdownHover};
        fill: ${props => props.hoverColor || props.color};
    }
`;
DropdownTrigger.displayName = "DropdownTriggerContainer";

type DropdownTextProps = {
    textFontSize?: string;
    textLetterSpacing?: string;
    textLineHeight?: string;
} & sizePropsType;

export const DropdownText = styled.div<DropdownTextProps>`
    display: inline-block;
    margin-right: 3px;
    max-width: ${props => (props.maxWidth ? props.maxWidth : undefined)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: ${props =>
        props.textLetterSpacing ? props.textLetterSpacing : undefined};
    line-height: ${props =>
        props.textLineHeight ? props.textLineHeight : undefined};
    font-size: ${props => (props.textFontSize ? props.textFontSize : "14px")};
`;
DropdownText.displayName = "DropdownText";

export type DropdownContentProps = {
    horizontalPosition?: HorizontalPosition;
    horizontalPositionFunc?: () => string;
    shadow?: boolean;
    verticalPosition?: string;
    verticalPositionFunc?: () => string;
    whiteSpace?: string;
} & sizePropsType;

export const DropdownContent = styled.div<DropdownContentProps>`
    background: ${theme.background};
    border: 1px solid ${theme.menuBorder};
    border-radius: ${borderRadius("card")};
    bottom: ${props =>
        ((props.verticalPositionFunc && props.verticalPositionFunc()) ||
            props.verticalPosition) === POSITION_ABOVE
            ? "30px"
            : undefined};
    color: ${theme.text};
    display: block;
    font-size: 14px;
    margin-top: 2px;
    overflow-y: auto;
    position: absolute;
    right: ${props =>
        ((props.horizontalPositionFunc && props.horizontalPositionFunc()) ||
            props.horizontalPosition) === POSITION_LEFT
            ? "0"
            : undefined};
    left: ${props =>
        ((props.horizontalPositionFunc && props.horizontalPositionFunc()) ||
            props.horizontalPosition) === POSITION_RIGHT
            ? "90%"
            : ((props.horizontalPositionFunc &&
                  props.horizontalPositionFunc()) ||
                  props.horizontalPosition) === POSITION_CENTER
            ? "0"
            : undefined};
    z-index: 20000;
    box-shadow: ${props =>
        props.shadow
            ? `0 2px 6px 0 ${theme.boxShadowBlackWithOpacity}`
            : "none"};
    ${sizeProps};
    ${props => props.whiteSpace && `white-space: ${props.whiteSpace};`}
`;
DropdownContent.defaultProps = {
    shadow: true
};

DropdownContent.displayName = "DropdownContent";

type DropdownItemProps = {
    showBorderBottom?: boolean;
    whiteSpace?: string;
} & disabledPropsType &
    paddingPropsType;

export const DropdownItem = styled.div<DropdownItemProps>`
    ${props =>
        props.showBorderBottom && `border-bottom: 1px solid ${theme.border};`}
    ${props =>
        props.disabled
            ? `color: ${theme.textDisabled};`
            : props.color && `color: ${props.color};`}
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    padding: ${props => (props.padding ? props.padding : "8px 16px")};
    ${props => props.whiteSpace && `white-space: ${props.whiteSpace};`}

    :hover {
        ${props =>
            !props.disabled && `background-color: ${theme.dropdownHover};`}
    }

    &:last-child {
        border-bottom: none;
    }
`;
DropdownItem.displayName = "DropdownItem";

export const DropdownSectionHeader = styled(FieldLabel)`
    margin-bottom: 0;
    padding: 12px 16px 0;
`;
DropdownSectionHeader.displayName = "DropdownSectionHeader";

type DropdownSelectedItemProps = paddingPropsType;

export const DropdownSelectedItem = styled.div<DropdownSelectedItemProps>`
    cursor: pointer;
    background-color: ${theme.dropdownSelected};
    color: ${theme.lightText};
    padding: ${props => (props.padding ? props.padding : "8px 16px")};
`;

DropdownSelectedItem.displayName = "DropdownSelectedItem";

export const DropdownSeparator = styled.div`
    border-top: 1px solid ${theme.listSeparator};
    cursor: none;
`;
DropdownSeparator.displayName = "DropdownSeparator";

type DropdownHeaderProps = { hasSubHeader?: boolean };

export const DropdownHeader = styled.div<DropdownHeaderProps>`
    font-family: "BrandonTextBold";
    margin: 4px 0;
    padding: ${props =>
        props.hasSubHeader ? "9px 16px 0px 16px" : "9px 16px 10px"};
`;
DropdownHeader.displayName = "DropdownHeader";

export const DropdownSubHeader = styled.div<paddingPropsType>`
    align-self: flex-start;
    color: ${theme.subText};
    font-size: 12px;
    padding: ${props => (props.padding ? props.padding : "0px 16px 10px")};
`;
DropdownSubHeader.displayName = "DropdownSubHeader";

export const DropdownGroupWrapper = styled.div<marginPropsType>`
    margin: ${props => (props.margin ? props.margin : "8px 0")};
`;
DropdownGroupWrapper.displayName = "DropdownGroupWrapper";

export type DownloadClickHandler = (id: string) => void;

// icon, iconColor, iconHeight, iconPosition, and iconWidth is used as a group in order to style svgicon as a dropdown
type DropdownProps = {
    borderColor?: string;
    children: React.ReactNode;
    clickHandler: DownloadClickHandler;
    color?: string;
    horizontalPosition?: HorizontalPosition;
    hoverColor: string;
    icon: IconType;
    iconColor: string;
    iconHeight: string;
    iconPosition: Placement;
    iconTooltip: string;
    iconWidth: string;
    inlineElement?: React.ReactNode;
    shadow?: boolean;
    text: string;
    textFontSize?: string;
    textLetterSpacing?: string;
    textLineHeight?: string;
    textMaxWidth?: string;
    trackingComponentLabel: TrackingComponentLabel;
    trackingEventName?: TrackingEventName;
    verticalPosition?: VerticalPosition;
} & marginPropsType &
    paddingPropsType &
    sizePropsType;

type DropdownState = {
    isOpen: boolean;
};

export class Dropdown extends React.Component<DropdownProps, DropdownState> {
    static displayName = "Dropdown";

    dropdownText: HTMLDivElement | undefined | null;
    dropdownTrigger: HTMLDivElement | undefined | null;

    static defaultProps = {
        horizontalPosition: POSITION_CENTER,
        hoverColor: theme.darkGreyText,
        icon: DownCaretIcon,
        iconColor: theme.darkGreyText,
        iconHeight: "8px",
        iconPosition: "bottom",
        iconTooltip: "",
        iconWidth: "8px",
        text: "",
        verticalPosition: POSITION_BELOW
    };

    state = {
        isOpen: false
    };

    componentDidMount() {
        window.addEventListener("click", this.onWindowClick);
        window.addEventListener("touchstart", this.onWindowClick);
        this.setDropdownTextTitle();
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.onWindowClick);
        window.removeEventListener("touchstart", this.onWindowClick);
    }

    componentDidUpdate() {
        this.setDropdownTextTitle();
    }

    getTargetId(
        evt: React.SyntheticEvent<HTMLDivElement>
    ): string | undefined | null {
        let element: any = evt.target;
        while (element) {
            const id = get(element, "dataset.id");
            if (id) {
                return id;
            }
            element = element.parentElement;
        }
    }

    onDropdownClick = (evt: React.SyntheticEvent<HTMLDivElement>) => {
        const { trackingComponentLabel, trackingEventName } = this.props;
        const id = this.getTargetId(evt);
        const disabled = get(evt, "target.attributes.disabled");
        if (disabled) {
            evt.stopPropagation();
        } else if (id) {
            trackEvent(
                getTrackingEventData(
                    Dropdown.displayName,
                    trackingComponentLabel,
                    trackingEventName ?? EVENT_NAME_SELECTION_CHOICE,
                    id
                )
            );
            this.props.clickHandler(id);
        }
    };

    onTriggerClick = (evt: React.SyntheticEvent<HTMLDivElement>) => {
        // evt.stopPropagation();
        this.setState({ isOpen: true });
    };

    onWindowClick = (evt: MouseEvent | TouchEvent) => {
        const target: EventTarget | null = evt.target;
        if (
            this.state.isOpen &&
            (!this.dropdownTrigger ||
                (target instanceof Node &&
                    !this.dropdownTrigger.contains(target)))
        ) {
            this.setState({ isOpen: false });
        }
    };

    setDropdownTextTitle = () => {
        if (
            this.dropdownText &&
            this.dropdownText.offsetWidth < this.dropdownText.scrollWidth
        ) {
            this.dropdownText.title = this.props.text;
        }
    };

    render() {
        const {
            children,
            color,
            text,
            textMaxWidth,
            icon,
            iconColor,
            iconWidth,
            iconHeight,
            iconTooltip,
            iconPosition,
            inlineElement,
            hoverColor,
            shadow
        } = this.props;
        const SvgIconComponent = (
            <DropdownSVGWrapper>
                <SvgIcon
                    icon={icon}
                    color={iconColor || color}
                    hoverColor={hoverColor}
                    width={iconWidth}
                    height={iconHeight}
                    marginLeft={
                        !isNil(text) && !isEmptyString(text) ? "8px" : "0px"
                    }
                />
            </DropdownSVGWrapper>
        );
        let DropdownIconComponent;
        if (!isNil(iconTooltip) && !isEmptyString(iconTooltip)) {
            DropdownIconComponent = (
                <Tooltip placement={iconPosition}>
                    {SvgIconComponent}
                    <div>{iconTooltip}</div>
                </Tooltip>
            );
        } else {
            DropdownIconComponent = SvgIconComponent;
        }
        return (
            <DropdownContainer
                {...reduceObject(this.props, ...marginPropTypes)}
            >
                <DropdownTrigger
                    color={color}
                    hoverColor={hoverColor}
                    ref={element => {
                        this.dropdownTrigger = element;
                    }}
                    onClick={this.onTriggerClick}
                    {...reduceObject(
                        this.props,
                        ...paddingPropTypes,
                        "borderColor"
                    )}
                >
                    <Flexbox>
                        {text && (
                            <DropdownText
                                maxWidth={textMaxWidth}
                                ref={element => {
                                    this.dropdownText = element;
                                }}
                                {...reduceObject(
                                    this.props,
                                    "textLetterSpacing",
                                    "textLineHeight",
                                    "textFontSize"
                                )}
                            >
                                <Flexbox>{text}</Flexbox>
                            </DropdownText>
                        )}
                        {DropdownIconComponent}
                        {inlineElement}
                    </Flexbox>
                </DropdownTrigger>
                {this.state.isOpen && (
                    <DropdownContent
                        onClick={this.onDropdownClick}
                        {...reduceObject(
                            this.props,
                            ...sizePropTypes
                                .concat("horizontalPosition")
                                .concat("verticalPosition")
                        )}
                        shadow={shadow}
                    >
                        {children}
                    </DropdownContent>
                )}
            </DropdownContainer>
        );
    }
}
