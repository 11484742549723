import React from "react";
import styled, { css } from "styled-components/macro";
import {
    borderRadius,
    marginProps,
    paddingProps,
    sizeProps
} from "common/components/styled/util";
import theme from "common/components/theme";

export type InputStyleProps = {
    name: string;
    hideLastpassIcon?: boolean;
    withInsetButton?: boolean;
} & Pick<flexboxPropsType, "flex"> &
    marginPropsType &
    paddingPropsType &
    sizePropsType;

type InputProps = InputStyleProps & JSX.IntrinsicElements["input"];

export const inputComponentStyle = css<{
    disabled?: boolean;
    flex?: number;
    withInsetButton?: boolean;
}>`
    border: 1px solid ${theme.inputBorder};
    border-radius: ${props =>
        props.withInsetButton ? "0px" : borderRadius("input")(props)};
    border-top-left-radius: ${borderRadius("input")};
    border-bottom-left-radius: ${borderRadius("input")};
    padding: 8px 10px 9px 10px; /* to match height of select component */
    background-color: ${props =>
        props.disabled ? theme.inputDisabledBg : theme.background};
    box-shadow: ${props =>
        props.disabled
            ? "none"
            : `inset 0 1px 3px 0 ${theme.inputInsetBorder}`};
    ${props => props.flex && `flex: ${props.flex}`}
    font-family: inherit;
    ${props => props.withInsetButton && `border-right: none;`}
    ${marginProps}
    ${paddingProps}
    ${sizeProps}

    :focus {
        box-shadow: 0 0 4px 0 ${theme.inputFocusBorder},
            inset 0 1px 3px 0 ${theme.inputInsetBorder};
        border: 1px solid ${theme.linkText};
        outline: none;
    }

    ::-webkit-input-placeholder {
        /* Chrome */
        color: ${theme.placeholder};
    }
    :-ms-input-placeholder {
        /* IE 10+ */
        color: ${theme.placeholder};
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        color: ${theme.placeholder};
    }
    :-moz-placeholder {
        /* Firefox 4 - 18 */
        color: ${theme.placeholder};
    }
`;

const InputComponent = styled.input`
    ${inputComponentStyle}
`;
InputComponent.displayName = "InputComponent";

const Input = React.forwardRef<HTMLInputElement, Omit<InputProps, "ref">>(
    (props, ref) => {
        return (
            <InputComponent
                data-lpignore={props.hideLastpassIcon}
                ref={ref}
                {...props}
            />
        );
    }
);

Input.defaultProps = {
    hideLastpassIcon: true,
    withInsetButton: false
};
Input.displayName = "Input";

export default Input;
