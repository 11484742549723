import styled from "styled-components/macro";
import {
    marginProps,
    paddingProps,
    sizeProps
} from "common/components/styled/util";
import theme from "common/components/theme";

type ErrorProps = marginPropsType & paddingPropsType & sizePropsType;

const Error = styled.div<ErrorProps>`
    color: ${theme.error};
    ${marginProps}
    ${paddingProps}
    ${sizeProps}
`;
Error.displayName = "Error";

export default Error;
