import * as React from "react";
import { memo } from "react";
import type { ICellRendererParams } from "ag-grid-community";
import type { ParseKeys, TFunction } from "i18next";
import get from "lodash.get";
import isNil from "lodash.isnil";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Button, { BUTTON_TYPE_CONTEXT_MENU } from "common/components/Button";
import {
    COLUMN_CUSTOM_RENDERER_ALIGN_LEFT,
    DATA_TYPE_DATE,
    DATA_TYPE_DATE_TIME,
    getAgDataGridTextTruncateNode
} from "common/components/grid/AgDataGridUtil";
import AgGridRowMenu from "common/components/grid/AgGridRowMenu";
import TooltipWrapper from "common/components/styled/TooltipWrapper";
import SvgIcon, { ClickableSvgIcon } from "common/components/SvgIcon";
import Flexbox from "common/components/styled/Flexbox";
import { TruncatedLink } from "common/components/styled/Link";
import { TruncatedNavLink } from "common/components/styled/NavLink";
import TruncatedDiv from "common/components/styled/TruncatedDiv";
import theme, { borderRadius } from "common/components/theme";
import Tooltip from "common/components/Tooltip";
import { ReactComponent as CheckmarkIcon } from "common/icons/Checkmark.svg";
import { ReactComponent as WarningIcon } from "common/icons/Warning.svg";
import { date2Str, dateTime2Str } from "common/util/format";
import { isEmptyString } from "common/util/lang";
import {
    EVENT_NAME_OPEN_CLOSE,
    EVENT_NAME_PAGE_NAVIGATION,
    EVENT_VALUE_OPEN_MODAL
} from "common/util/trackingEvents";
import type {
    TrackingComponentLabel,
    TrackingEventName
} from "common/util/trackingEvents";
import { IGNORE_REACT_OUTSIDE_CLICK } from "common/util/outsideClick";
import { EDITED_BY_DATA_FIELD, EDITED_ON_DATA_FIELD } from "common/util/object";

export const RangeCell = styled(Flexbox)`
    align-items: flex-end;
    flex-direction: column;
    justify-content: right;
    line-height: 1.4;
    text-align: right;
`;

RangeCell.displayName = "RangeCell";

export const OverflowHiddenCell = styled(Flexbox)`
    align-items: center;
    line-height: 1.4;
    overflow: hidden;
`;

export const SubText = styled.div`
    color: ${theme.modalSubText};
    font-family: "BrandonTextRegular";
    font-size: 12px;
    font-weight: normal;
`;

export const FakeEditableTextCell = styled(Flexbox)`
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    background-color: white;
    border: 1px solid darkgray;
    box-shadow: 1px 1px 1px 0 lightgray inset;
    line-height: 34px;
    margin-top: 4px;
    padding-left: 4px;
    overflow: hidden;
    padding-right: 4px;
    height: 36px;
`;
FakeEditableTextCell.displayName = "FakeEditableTextCell";

export const linkCellRenderer = (
    entityId: string,
    name: string,
    onClickHandler: (a: React.SyntheticEvent<HTMLLinkElement>) => void,
    trackingComponentLabel: TrackingComponentLabel = "",
    trackingEventName: TrackingEventName = "",
    trackingEventValue = "",
    tooltipMaxWidth: string | undefined = undefined,
    tooltipBoundary: string | undefined = undefined
) => {
    return (
        <TruncatedLink
            data-id={entityId}
            onClick={onClickHandler}
            textAlign="left"
            text={name}
            tooltipMaxWidth={tooltipMaxWidth}
            tooltipBoundary={tooltipBoundary}
            trackingComponentLabel={trackingComponentLabel}
            trackingEventName={trackingEventName}
            trackingEventValue={trackingEventValue}
        />
    );
};

export const IconCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        if (colDef && data) {
            const field = get(colDef, "cellRendererParams.field");
            const icon = get(colDef, "cellRendererParams.icon");
            const tooltipField = get(colDef, "cellRendererParams.tooltipField");
            let tooltip = "";
            if (tooltipField) {
                tooltip = get(data, tooltipField);
            } else {
                tooltip = get(colDef, "cellRendererParams.tooltip");
            }

            const size = get(colDef, "cellRendererParams.size", "16px");
            const color = get(colDef, "cellRendererParams.color", "");

            let render = get(colDef, "cellRendererParams.field");
            const not = get(colDef, "cellRendererParams.not", false);
            if (not) {
                render = !render;
            }
            if ((tooltipField && tooltip) || (render && data[field] === true)) {
                return (
                    <Tooltip>
                        <SvgIcon
                            color={color}
                            height={size}
                            icon={icon}
                            width={size}
                        />
                        <div>{tooltip}</div>
                    </Tooltip>
                );
            }
        }
        return null;
    }
);

export const EnumCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        if (colDef && data) {
            const field = get(colDef, "cellRendererParams.field");
            return getAgDataGridTextTruncateNode(
                get(data, `${field}.displayName`)
            );
        }
        return null;
    }
);

export const ViewNavLinkCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        if (colDef && data) {
            const entityIdField = get(
                colDef,
                "cellRendererParams.entityIdField",
                "entityId"
            );
            const nameField = get(
                colDef,
                "cellRendererParams.nameField",
                "name"
            );
            const getToPayload = get(colDef, "cellRendererParams.getToPayload");
            let toPayload;
            if (getToPayload) {
                toPayload = getToPayload(data);
            }
            const name = get(data, nameField);
            const entityId = get(data, entityIdField);
            const onViewClick = get(colDef, "cellRendererParams.onViewClick");

            const trackingComponentLabel = get(
                colDef,
                "cellRendererParams.trackingComponentLabel",
                ""
            );

            const trackingEventValue = get(
                colDef,
                "cellRendererParams.trackingEventValue",
                ""
            );

            // Build icon if needed
            const buildIcon = get(colDef, "cellRendererParams.buildIcon");
            const icon = buildIcon ? buildIcon(data) : null;

            // On view click
            let clickProps = {};
            if (onViewClick) {
                clickProps = {
                    onClick: onViewClick.bind(null, entityId)
                };
            }

            return (
                <Flexbox justifyContent="space-between">
                    <TruncatedNavLink
                        data-id={entityId}
                        {...clickProps}
                        display="flex"
                        textAlign="left"
                        text={name}
                        to={toPayload}
                        trackingComponentLabel={trackingComponentLabel}
                        trackingEventValue={trackingEventValue}
                    />
                    {icon}
                </Flexbox>
            );
        }
        return null;
    }
);

export const ViewCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        if (colDef && data) {
            // Return value, single field or entire data row
            const entityIdField = get(
                colDef,
                "cellRendererParams.entityIdField",
                "entityId"
            );
            const entityId = get(data, entityIdField);
            const getRowData = get(
                colDef,
                "cellRendererParams.getRowData",
                false
            );
            const onClickData = getRowData ? data : entityId;
            // Display name value
            const nameField = get(
                colDef,
                "cellRendererParams.nameField",
                "name"
            );
            const name = get(data, nameField);
            // On view click and track labels
            const onViewClick = get(colDef, "cellRendererParams.onViewClick");
            const trackingComponentLabel = get(
                colDef,
                "cellRendererParams.trackingComponentLabel",
                ""
            );
            // if tracking component label specified but event name isn't, default to page-navigation
            const trackingEventName = get(
                colDef,
                "cellRendererParams.trackingEventName",
                trackingComponentLabel ? EVENT_NAME_PAGE_NAVIGATION : ""
            );
            const trackingEventValue = get(
                colDef,
                "cellRendererParams.trackingEventValue",
                ""
            );
            // Clickable link
            const link = onViewClick
                ? linkCellRenderer(
                      entityId,
                      name,
                      onViewClick.bind(null, onClickData),
                      trackingComponentLabel,
                      trackingEventName,
                      trackingEventValue
                  )
                : name;
            // Add icon if needed
            const icon = get(colDef, "cellRendererParams.icon");
            const iconOnClick = get(colDef, "cellRendererParams.iconOnClick");
            const iconTrackingComponentLabel = get(
                colDef,
                "cellRendererParams.iconTrackingComponentLabel",
                ""
            );
            const iconTrackingEventName = get(
                colDef,
                "cellRendererParams.iconTrackingEventName",
                EVENT_NAME_OPEN_CLOSE
            );
            const iconTrackingEventValue = get(
                colDef,
                "cellRendererParams.iconTrackingEventValue",
                EVENT_VALUE_OPEN_MODAL
            );
            if (icon && iconOnClick) {
                return (
                    <Flexbox cssWidth="100%" justifyContent="space-between">
                        {link}
                        <Flexbox
                            backgroundColor={theme.universalBackground}
                            borderRadius={borderRadius["wrapper"]}
                            padding="4px"
                        >
                            <ClickableSvgIcon
                                color={theme.darkGreyText}
                                cursor="pointer"
                                height="16px"
                                icon={icon}
                                onClick={iconOnClick.bind(null, data)}
                                trackingComponentLabel={
                                    iconTrackingComponentLabel
                                }
                                trackingEventName={iconTrackingEventName}
                                trackingEventValue={iconTrackingEventValue}
                                width="16px"
                            />
                        </Flexbox>
                    </Flexbox>
                );
            } else {
                return link;
            }
        }
        return null;
    }
);

export const CheckmarkCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        if (colDef && data) {
            const renderField = get(colDef, "cellRendererParams.renderField");
            const not = get(colDef, "cellRendererParams.not", false);
            let render = get(data, renderField);
            if (not) {
                render = !render;
            }
            if (render === true) {
                return (
                    <SvgIcon
                        height="16px"
                        icon={CheckmarkIcon}
                        verticalAlign="middle"
                        width="16px"
                    />
                );
            }
        }
        return null;
    }
);

export const DateCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        let date = null;
        if (colDef && data) {
            // Format date properly
            const dateType = get(colDef, "cellRendererParams.dateType");
            const field = get(colDef, "cellRendererParams.field");
            if (dateType === DATA_TYPE_DATE) {
                date = date2Str(data[field]);
            } else {
                if (dateType === DATA_TYPE_DATE_TIME) {
                    date = dateTime2Str(data[field]);
                } else {
                    date = data[field];
                }
            }

            // Display icon if needed
            const icon = get(colDef, "cellRendererParams.icon");
            const iconColor = get(colDef, "cellRendererParams.iconColor");
            const iconTooltip = get(colDef, "cellRendererParams.iconTooltip");
            const showIcon = get(colDef, "cellRendererParams.showIconFunc");
            if (icon && (!showIcon || (showIcon && showIcon(data)))) {
                const iconComponent = (
                    <Tooltip>
                        <SvgIcon
                            color={iconColor}
                            height="16px"
                            icon={icon}
                            width="16px"
                        />
                        <div>{iconTooltip}</div>
                    </Tooltip>
                );

                return (
                    <Flexbox cssWidth="100%" justifyContent="space-between">
                        {date}
                        <Flexbox alignItems="center" marginLeft="8px">
                            {iconComponent}
                        </Flexbox>
                    </Flexbox>
                );
            } else {
                return date;
            }
        }
        return date;
    }
);

export const DateRangeCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        const { t } = useTranslation();
        if (colDef && data) {
            const startDateField = get(
                colDef,
                "cellRendererParams.startDateField",
                "startDate"
            );
            const endDateField = get(
                colDef,
                "cellRendererParams.endDateField",
                "endDate"
            );
            const allowWrap = get(
                colDef,
                "cellRendererParams.allowWrap",
                false
            );
            const dateRange = dateRangeRenderer(
                data,
                t,
                startDateField,
                endDateField
            );
            if (!allowWrap) {
                return getAgDataGridTextTruncateNode(dateRange);
            } else {
                return (
                    <div style={{ lineHeight: 1.5, whiteSpace: "normal" }}>
                        {dateRange}
                    </div>
                );
            }
        }
        return null;
    }
);

export const dateRangeRenderer = (
    data: any,
    t: TFunction,
    startDateField: string,
    endDateField: string
): string => {
    const forecastFrom = data[startDateField];
    const forecastUntil = data[endDateField];
    const showRange =
        !isNil(forecastFrom) &&
        !isEmptyString(forecastFrom) &&
        !isNil(forecastUntil) &&
        !isEmptyString(forecastUntil);
    return showRange
        ? t("common:general.text_dash_text", {
              beforeText: date2Str(forecastFrom),
              afterText: date2Str(forecastUntil)
          })
        : "";
};

export const TruncatedCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        if (colDef && data) {
            const textField = get(colDef, "cellRendererParams.textField");
            const text = get(data, textField);
            const icon = get(colDef, "cellRendererParams.icon");
            const iconColor = get(colDef, "cellRendererParams.iconColor");
            const iconTooltip = get(colDef, "cellRendererParams.iconTooltip");
            const showIcon = get(colDef, "cellRendererParams.showIconFunc");
            const TruncName = getAgDataGridTextTruncateNode(text);
            if (icon && (!showIcon || (showIcon && showIcon(data)))) {
                const iconComponent = (
                    <Tooltip>
                        <SvgIcon
                            color={iconColor}
                            height="16px"
                            icon={icon}
                            width="16px"
                        />
                        <div>{iconTooltip}</div>
                    </Tooltip>
                );

                return (
                    <Flexbox cssWidth="100%" justifyContent="space-between">
                        {TruncName}
                        {iconComponent}
                    </Flexbox>
                );
            } else {
                return TruncName;
            }
        }
        return null;
    }
);

export const ActionIconCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        if (colDef && data) {
            const onClick = get(colDef, "cellRendererParams.onClick");
            const tooltip = get(colDef, "cellRendererParams.tooltip");
            const icon = get(colDef, "cellRendererParams.icon");
            const trackingComponentLabel = get(
                colDef,
                "cellRendererParams.trackingComponentLabel",
                ""
            );
            const isActionableField = get(
                colDef,
                "cellRendererParams.isActionableField"
            );
            let isActionable = true;
            if (isActionableField) {
                isActionable = data[isActionableField];
            }
            if (isActionable) {
                const ActionButton = (
                    <div className="grid-row-menu">
                        <Button
                            icon={icon}
                            onClick={onClick.bind(null, data)}
                            padding="4px 6px"
                            trackingComponentLabel={trackingComponentLabel}
                            type={BUTTON_TYPE_CONTEXT_MENU}
                        />
                    </div>
                );
                if (tooltip) {
                    return (
                        <Tooltip>
                            {ActionButton}
                            {tooltip}
                        </Tooltip>
                    );
                } else {
                    return ActionButton;
                }
            }
        }
        return null;
    }
);

export const WarningsCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        const { t } = useTranslation();
        if (colDef && data) {
            const { warnings } = data;
            const openWarningsModal = get(
                colDef,
                "cellRendererParams.openWarningsModal"
            );
            const trackingComponentLabel = get(
                colDef,
                "cellRendererParams.trackingComponentLabel",
                ""
            );
            const resourceKey: ParseKeys = get(
                colDef,
                "cellRendererParams.resourceKey"
            );
            // normally would simply nest server:general.line_group in resource.json
            // however, with count being used server:general.line_group turns to server:general.line_group_plural which is incorrect
            // TODO: Should move this cell renderer out of common or configure to be more general, seems price specific as of right now
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore Remove ignore once above is resolved
            const lineGroup = t("server:general.line_group");
            if (warnings && warnings.length > 0) {
                // Need Flexbox around ClickableSvgIcon due to Tooltip
                return (
                    <Tooltip>
                        <Flexbox>
                            <ClickableSvgIcon
                                color={theme.warning}
                                cursor="pointer"
                                height="24px"
                                icon={WarningIcon}
                                onClick={openWarningsModal.bind(null, data)}
                                title={t("common:icons.warning")}
                                trackingComponentLabel={trackingComponentLabel}
                                trackingEventName={EVENT_NAME_OPEN_CLOSE}
                                trackingEventValue={EVENT_VALUE_OPEN_MODAL}
                                width="24px"
                            />
                        </Flexbox>
                        <div>
                            {t(resourceKey, {
                                lineGroup: lineGroup,
                                count: warnings.length
                            })}
                        </div>
                    </Tooltip>
                );
            }
        }
        return null;
    }
);

export const cellTooltipRenderer = (
    dataCell: React.ReactNode,
    tooltip: string
) => {
    // honor line breaks in tooltip text
    const tooltipLines = tooltip.split("\n");
    if (tooltipLines.length === 1) {
        return (
            <Tooltip key={"cell-tooltip"}>
                {dataCell}
                <div>{tooltip}</div>
            </Tooltip>
        );
    } else {
        //render each line of tooltip in a separate div
        const tooltipDivs = tooltipLines.map((item, index) => {
            return <div key={index}>{item}</div>;
        });

        return (
            <Tooltip key={"cell-tooltip"}>
                {dataCell}
                <TooltipWrapper maxWidth="350px">{tooltipDivs}</TooltipWrapper>
            </Tooltip>
        );
    }
};

export const TooltipCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        if (colDef && data) {
            const textField = get(colDef, "cellRendererParams.textField");
            const text = get(data, textField);
            if (text) {
                const tooltipField = get(
                    colDef,
                    "cellRendererParams.tooltipField"
                );
                if (tooltipField) {
                    const tooltip = get(data, tooltipField);
                    if (tooltip) {
                        const dataCell = <TruncatedDiv>{text}</TruncatedDiv>;
                        return cellTooltipRenderer(dataCell, tooltip);
                    }
                }
                return getAgDataGridTextTruncateNode(text);
            }
        }
        return null;
    }
);

export const DataWithTooltipCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        const { t } = useTranslation();
        if (colDef && data) {
            const field = colDef.field ? colDef.field : "";
            const fieldData = data[field];
            if (fieldData) {
                const tooltip = get(data, `tooltips.${field}`);
                if (tooltip) {
                    const dataCell = <TruncatedDiv>{fieldData}</TruncatedDiv>;
                    return cellTooltipRenderer(dataCell, tooltip);
                }
                return getAgDataGridTextTruncateNode(fieldData);
            } else if (fieldData === null) {
                const dataCell: React.ReactNode = (
                    <TruncatedDiv>{t("common:general.n_a")}</TruncatedDiv>
                );
                const tooltip = get(data, `tooltips.${field}`);
                if (tooltip) {
                    return cellTooltipRenderer(dataCell, tooltip);
                }
                return dataCell;
            }
        }
        return null;
    }
);

// Component version used for styling other cell renderers
// Use renderer version for ag grid
export const TextSubTextHierarchyCellRendererComponent = (
    params: ICellRendererParams
) => {
    const { colDef, data } = params;
    if (colDef && data) {
        // Get hierarchy text
        const hierarchyField = get(
            colDef,
            "cellRendererParams.hierarchyField",
            null
        );
        let hierarchy = "";
        if (hierarchyField) {
            hierarchy = get(data, hierarchyField);
        }

        // Check if there is onClick action needed for name
        const nameField = get(colDef, "cellRendererParams.nameField", "name");
        let name = get(data, nameField);
        if (isNil(name)) {
            name = get(colDef, "cellRendererParams.defaultName");
        }
        const onClick = get(colDef, "cellRendererParams.onClick");
        const trackingComponentLabel = get(
            colDef,
            "cellRendererParams.trackingComponentLabel",
            ""
        );

        // if tracking component label specified but event name isn't, default to page-navigation
        const trackingEventName = get(
            colDef,
            "cellRendererParams.trackingEventName",
            trackingComponentLabel ? EVENT_NAME_PAGE_NAVIGATION : ""
        );
        const trackingEventValue = get(
            colDef,
            "cellRendererParams.trackingEventValue",
            ""
        );
        let TruncName = getAgDataGridTextTruncateNode(name);
        if (onClick) {
            TruncName = (
                <TruncatedLink
                    onClick={onClick.bind(null, data)}
                    text={name}
                    textAlign="start"
                    trackingComponentLabel={trackingComponentLabel}
                    trackingEventName={trackingEventName}
                    trackingEventValue={trackingEventValue}
                />
            );
        }

        // Add icon if needed
        const icon = get(colDef, "cellRendererParams.icon");
        const iconOnClick = get(colDef, "cellRendererParams.iconOnClick");
        const iconTrackingComponentLabel = get(
            colDef,
            "cellRendererParams.iconTrackingComponentLabel",
            ""
        );
        const iconTrackingEventName = get(
            colDef,
            "cellRendererParams.iconTrackingEventName",
            EVENT_NAME_OPEN_CLOSE
        );
        const iconTrackingEventValue = get(
            colDef,
            "cellRendererParams.iconTrackingEventValue",
            EVENT_VALUE_OPEN_MODAL
        );

        let iconComponent = null;
        if (icon && iconOnClick) {
            iconComponent = (
                <Flexbox
                    backgroundColor={theme.universalBackground}
                    borderRadius={borderRadius["wrapper"]}
                    margin="auto"
                    padding="4px"
                >
                    <ClickableSvgIcon
                        color={theme.darkGreyText}
                        cursor="pointer"
                        height="16px"
                        icon={icon}
                        onClick={iconOnClick.bind(null, data)}
                        trackingComponentLabel={iconTrackingComponentLabel}
                        trackingEventName={iconTrackingEventName}
                        trackingEventValue={iconTrackingEventValue}
                        width="16px"
                    />
                </Flexbox>
            );
        }

        const truncWidth = isNil(iconComponent) ? "100%" : "calc(100% - 24px)";
        return (
            <Flexbox cssWidth="100%" justifyContent="space-between">
                <Flexbox cssWidth={truncWidth} flexDirection="column">
                    {TruncName}
                    {!isEmptyString(hierarchy) && (
                        <SubText>
                            {getAgDataGridTextTruncateNode(hierarchy)}
                        </SubText>
                    )}
                </Flexbox>
                {iconComponent}
            </Flexbox>
        );
    }
    return null;
};
export const TextSubTextHierarchyCellRenderer = memo<ICellRendererParams>(
    TextSubTextHierarchyCellRendererComponent
);

export const TextSubTextHierarchyNavLinkCellRenderer =
    memo<ICellRendererParams>((params: ICellRendererParams) => {
        const { colDef, data } = params;
        if (colDef && data) {
            const entityIdField = get(
                colDef,
                "cellRendererParams.entityIdField",
                "entityId"
            );
            const nameField = get(
                colDef,
                "cellRendererParams.nameField",
                "name"
            );
            const name = get(data, nameField);
            const entityId = get(data, entityIdField);
            const onViewClick = get(colDef, "cellRendererParams.onViewClick");
            const getToPayload = get(colDef, "cellRendererParams.getToPayload");
            let toPayload;
            if (getToPayload) {
                toPayload = getToPayload(data);
            }
            const hierarchyField = get(
                colDef,
                "cellRendererParams.hierarchyField",
                null
            );
            let hierarchy = "";
            if (hierarchyField) {
                hierarchy = get(data, hierarchyField);
            }

            let clickProps = {};
            if (onViewClick) {
                clickProps = {
                    onClick: onViewClick.bind(null, entityId)
                };
            }

            const trackingComponentLabel = get(
                colDef,
                "cellRendererParams.trackingComponentLabel",
                ""
            );

            const trackingEventValue = get(
                colDef,
                "cellRendererParams.trackingEventValue",
                ""
            );

            return (
                <Flexbox cssWidth="100%" flexDirection="column" margin="auto">
                    <TruncatedNavLink
                        {...clickProps}
                        data-id={entityId}
                        textAlign="left"
                        text={name}
                        to={toPayload}
                        trackingComponentLabel={trackingComponentLabel}
                        trackingEventValue={trackingEventValue}
                    />
                    <SubText>
                        {getAgDataGridTextTruncateNode(hierarchy, false)}
                    </SubText>
                </Flexbox>
            );
        }
        return null;
    });

export const GridRowMenuRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams): React.ReactElement | null => {
        const { colDef, data } = params;
        if (colDef && data) {
            const gridRowMenu = get(colDef, "cellRendererParams.gridRowMenu");
            const getMenuContainerRef = get(
                colDef,
                "cellRendererParams.getMenuContainerRef"
            );
            const rowHeight = get(colDef, "cellRendererParams.rowHeight");
            const gridId = get(colDef, "cellRendererParams.gridId");
            if (
                gridRowMenu &&
                !data.hideGridRowMenu &&
                gridId &&
                getMenuContainerRef
            ) {
                return (
                    <AgGridRowMenu
                        className={
                            IGNORE_REACT_OUTSIDE_CLICK + " grid-row-menu"
                        }
                        getMenuContainerRef={getMenuContainerRef}
                        gridId={gridId}
                        gridRowMenu={gridRowMenu}
                        rowData={data}
                        rowHeight={rowHeight}
                    />
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
);

const EditedOnEditedByCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams): React.ReactElement | null => {
        const { colDef, data } = params;
        const { t } = useTranslation();
        if (colDef && data) {
            const editedOnKey = get(colDef, "cellRendererParams.editedOn");
            const editedByKey = get(colDef, "cellRendererParams.editedBy");
            if (editedOnKey && editedByKey) {
                const editedOnDateStr = date2Str(data[editedOnKey]);
                const editedOnTooltip = t("common:general.edited_on_x", {
                    date: editedOnDateStr
                });
                const editedByTooltip = t("common:general.edited_by_x", {
                    user: data[editedByKey]
                });
                return (
                    <Tooltip>
                        <div>{editedOnDateStr}</div>
                        <div>
                            <div>{editedOnTooltip}</div>
                            <div>{editedByTooltip}</div>
                        </div>
                    </Tooltip>
                );
            }
        }
        return null;
    }
);

export const getEditedOnEditedByColumnDefinition = (
    t: TFunction,
    colId: string = EDITED_ON_DATA_FIELD,
    editedOnKey: string = EDITED_ON_DATA_FIELD,
    editedByKey: string = EDITED_BY_DATA_FIELD,
    sortable = true
): ColumnDefinition => {
    return {
        cellClass: [COLUMN_CUSTOM_RENDERER_ALIGN_LEFT],
        cellRenderer: EditedOnEditedByCellRenderer,
        cellRendererParams: {
            editedOn: editedOnKey,
            editedBy: editedByKey
        },
        colId: colId,
        headerName: t("common:general.edited_on"),
        sortable: sortable,
        width: 140
    };
};

export const FakeEditableTextCellRenderer = memo<ICellRendererParams>(
    (params: ICellRendererParams) => {
        const { colDef, data } = params;
        if (colDef && data) {
            const textField = get(colDef, "cellRendererParams.textField");
            const text = get(data, textField);
            return <FakeEditableTextCell>{text}</FakeEditableTextCell>;
        }
        return null;
    }
);
