import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Flexbox from "common/components/styled/Flexbox";
import Link from "common/components/styled/Link";
import { ClickableSvgIcon } from "common/components/SvgIcon";
import theme from "common/components/theme";
import { ReactComponent as InformationIcon } from "common/icons/Information.svg";
import OfferTextSubstitutionTokensModal from "concept/components/OfferTextSubstitutionTokensModal";

const SubstitutionTokens = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const openHandler = () => {
        setIsOpen(true);
    };
    return (
        <React.Fragment>
            <Flexbox alignItems="center" flexDirection="row">
                <ClickableSvgIcon
                    color={theme.linkText}
                    icon={InformationIcon}
                    marginRight="8px"
                    onClick={openHandler}
                    trackingComponentLabel={SubstitutionTokens.displayName}
                />
                <Link
                    onClick={openHandler}
                    trackingComponentLabel="Substitution Tokens"
                >
                    {t("concept.substitution_tokens")}
                </Link>
            </Flexbox>
            <OfferTextSubstitutionTokensModal
                isOpen={isOpen}
                closeModalHandler={() => {
                    setIsOpen(false);
                }}
            />
        </React.Fragment>
    );
};

SubstitutionTokens.displayName = "SubstitutionTokens";

export default SubstitutionTokens;
