import * as React from "react";
import { withCookies } from "react-cookie";
import { withTranslation } from "react-i18next";
import type { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import type { ConnectedProps } from "react-redux";
import AuthDialogPage, {
    AUTH_INPUT_WIDTH,
    AuthError,
    AuthInstructions,
    AuthLeftColumn,
    AuthRightColumn,
    AuthSubText,
    DOUBLE_COLUMN_AUTH_WIDTH
} from "common/auth/components/AuthDialogPage";
import {
    authorizationError,
    cancelChangePassword,
    changePassword,
    securityQuestions
} from "common/auth/state/authActions";
import Button, { BUTTON_TYPE_SECONDARY } from "common/components/Button";
import Flexbox from "common/components/styled/Flexbox";
import { CustomSelect } from "common/components/styled/Form";
import Input from "common/components/styled/Input";
import { FieldLabel } from "common/components/styled/Label";
import type { Cookies, HasCookies } from "common/shell/util/types";
import { EVENT_NAME_CANCEL_ACTION } from "common/util/trackingEvents";
import type { AppDispatch, RootState } from "store";

type ChangePasswordDialogOwnProps = {
    cancelable?: boolean;
};

type ChangePasswordDialogProps = ChangePasswordDialogOwnProps &
    PropsFromRedux &
    HasCookies &
    WithTranslation;

type ChangePasswordDialogState = {
    confirmPassword: string;
    newPassword: string;
    oldPassword: string;
    securityAnswer: string;
    securityQuestionSelectedValue: OptionTypeOrNullOrUndefined;
};

class ChangePasswordDialog extends React.Component<
    ChangePasswordDialogProps,
    ChangePasswordDialogState
> {
    static displayName = "ChangePasswordDialog";
    state: ChangePasswordDialogState = {
        confirmPassword: "",
        newPassword: "",
        oldPassword: "",
        securityAnswer: "",
        securityQuestionSelectedValue: null
    };

    oldPasswordInput: HTMLInputElement | undefined | null;

    componentDidMount() {
        this.props.questionAction();
        if (this.oldPasswordInput) {
            this.oldPasswordInput.focus();
        }
    }

    onChangeConfirmPassword = (
        event: React.SyntheticEvent<HTMLInputElement>
    ) => {
        this.setState({ confirmPassword: event.currentTarget.value });
    };

    onChangeNewPassword = (event: React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({ newPassword: event.currentTarget.value });
    };

    onChangeOldPassword = (event: React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({ oldPassword: event.currentTarget.value });
    };

    onChangeSecurityAnswer = (
        event: React.SyntheticEvent<HTMLInputElement>
    ) => {
        this.setState({ securityAnswer: event.currentTarget.value });
    };

    onChangeSecurityQuestion = (
        securityQuestionSelectedValue: OptionTypeOrNullOrUndefined
    ) => {
        this.setState({ securityQuestionSelectedValue });
    };

    onClickSubmit = () => {
        const {
            confirmPassword,
            newPassword,
            oldPassword,
            securityAnswer
        } = this.state;
        if (
            newPassword === confirmPassword &&
            this.state.securityQuestionSelectedValue
        ) {
            this.props.changePasswordAction(
                this.props.cookies,
                oldPassword,
                newPassword,
                this.state.securityQuestionSelectedValue.value,
                securityAnswer
            );
        } else {
            this.props.errorAction(
                this.props.t("common:login.password_mismatch")
            );
        }
    };

    onClickCancel = () => {
        this.props.cancelAction();
    };

    validate = (): boolean => {
        const {
            confirmPassword,
            newPassword,
            oldPassword,
            securityAnswer
        } = this.state;
        return Boolean(
            oldPassword &&
                newPassword &&
                confirmPassword &&
                this.state.securityQuestionSelectedValue?.value &&
                securityAnswer
        );
    };

    setOldPasswordInputRef = (input: HTMLInputElement) => {
        this.oldPasswordInput = input;
    };

    render() {
        const { cancelable, error, questions, t } = this.props;
        const { securityQuestionSelectedValue } = this.state;
        const options = questions.map(question => {
            return {
                value: question.entityId,
                label: question.question
            };
        });
        return (
            <AuthDialogPage
                title={t("common:login.change_password")}
                cssWidth={DOUBLE_COLUMN_AUTH_WIDTH}
            >
                <Flexbox marginBottom="32px">
                    <AuthLeftColumn>
                        <FieldLabel>
                            {t("common:login.old_password")}
                        </FieldLabel>
                        <Input
                            ref={this.setOldPasswordInputRef}
                            name="oldPassword"
                            onChange={this.onChangeOldPassword}
                            type="password"
                            cssWidth={AUTH_INPUT_WIDTH}
                        />
                        <FieldLabel marginTop="24px">
                            {t("common:login.new_password")}
                        </FieldLabel>
                        <Input
                            name="newPassword"
                            onChange={this.onChangeNewPassword}
                            type="password"
                            cssWidth={AUTH_INPUT_WIDTH}
                        />
                        <AuthSubText>
                            {t("common:login.password_requirements")}
                        </AuthSubText>
                        <FieldLabel marginTop="24px">
                            {t("common:login.confirm_password")}
                        </FieldLabel>
                        <Input
                            name="confirmPassword"
                            onChange={this.onChangeConfirmPassword}
                            type="password"
                            cssWidth={AUTH_INPUT_WIDTH}
                        />
                    </AuthLeftColumn>
                    <AuthRightColumn>
                        <AuthInstructions>
                            {t("common:login.security_question_instructions")}
                        </AuthInstructions>
                        <FieldLabel>
                            {t("common:login.security_question")}
                        </FieldLabel>
                        <CustomSelect
                            name="securityQuestion"
                            options={options}
                            onChange={this.onChangeSecurityQuestion}
                            placeholder={t("common:login.select_question")}
                            trackingComponentLabel={[
                                ChangePasswordDialog.displayName,
                                "Security Question"
                            ]}
                            value={securityQuestionSelectedValue}
                        />
                        <FieldLabel marginTop="24px">
                            {t("common:login.security_answer")}
                        </FieldLabel>
                        <Input
                            name="securityAnswer"
                            onChange={this.onChangeSecurityAnswer}
                            placeholder={t("common:login.answer_question")}
                            type="text"
                            cssWidth={AUTH_INPUT_WIDTH}
                        />
                    </AuthRightColumn>
                </Flexbox>
                <Flexbox justifyContent="center">
                    {cancelable && (
                        <Button
                            cssWidth="186px"
                            marginRight="8px"
                            onClick={this.onClickCancel}
                            text={t("common:general.cancel")}
                            trackingComponentLabel={[
                                ChangePasswordDialog.displayName,
                                "Cancel"
                            ]}
                            trackingEventName={EVENT_NAME_CANCEL_ACTION}
                            type={BUTTON_TYPE_SECONDARY}
                        />
                    )}
                    <Button
                        cssWidth="186px"
                        disabled={!this.validate()}
                        marginLeft={cancelable ? "8px" : "0px"}
                        onClick={this.onClickSubmit}
                        submit={true}
                        text={t("common:general.submit")}
                        trackingComponentLabel={[
                            ChangePasswordDialog.displayName,
                            "Submit"
                        ]}
                    />
                </Flexbox>
                <AuthError error={error} />
            </AuthDialogPage>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        error: state.auth.error,
        questions: state.auth.questions
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        changePasswordAction: (
            cookies: Cookies,
            oldPassword: string,
            newPassword: string,
            questionId: string,
            answer: string
        ) => {
            dispatch(
                changePassword(
                    cookies,
                    oldPassword,
                    newPassword,
                    questionId,
                    answer
                )
            );
        },
        errorAction: (error: string) => {
            dispatch(authorizationError(error));
        },
        questionAction: () => {
            dispatch(securityQuestions());
        },
        cancelAction: () => {
            dispatch(cancelChangePassword());
        }
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

// @ts-expect-error withCookies causes typescript errors
export default withTranslation()(withCookies(connector(ChangePasswordDialog)));
