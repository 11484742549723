import update from "immutability-helper";
import {
    DATA_TABLE,
    getDateFilter
} from "common/reports/state/reportsStateUtils";
import {
    COMPARISON_SAME_PERIOD_PRIOR_YEAR,
    LAST_365_DAYS
} from "common/util/date";
import type { DateFilter } from "common/util/date";
import { ASCENDING } from "common/util/query";
import type { ReportsState } from "reports/state/reportsStateUtils";
import { CATEGORY_SUBCATEGORY_SEPARATOR } from "shell/state/initReducers";

export const PURCHASE_CYCLE = "purchaseCycle";

// Report view types, purchase cycle reports
export const PURCHASE_CYCLE_AND_PROMOTIONS = "PURCHASE_CYCLE_AND_PROMOTIONS";

// Report field consts
export const BRAND = "brand";
export const CATEGORY = "category";
export const PRODUCT_GROUPS = "productGroups";
export const UPC = "upc";
export const UPC_DESCRIPTION = "upcDescription";

// Column mapping consts
export const BUYERS = "buyers";
export const BUYERS_COMPARISON = "buyersComparison";
export const REPEAT_BUYERS = "repeatBuyers";
export const REPEAT_BUYERS_COMPARISON = "repeatBuyersComparison";
export const REPEAT_BUYERS_PCT = "repeatBuyersPct";
export const REPEAT_BUYERS_PCT_COMPARISON = "repeatBuyersPctComparison";
export const PURCHASE_CYCLE_QTY = "purchaseCycle";
export const PURCHASE_CYCLE_QTY_COMPARISON = "purchaseCycleComparison";
export const PURCHASE_CYCLE_AFTER_PROMO = "purchaseCycleAfterPromo";
export const PURCHASE_CYCLE_AFTER_PROMO_COMPARISON =
    "purchaseCycleAfterPromoComparison";
export const PURCHASE_CYCLE_UP_TO_PROMO = "purchaseCycleUpToPromo";
export const PURCHASE_CYCLE_UP_TO_PROMO_COMPARISON =
    "purchaseCycleUpToPromoComparison";
export const PURCHASE_CYCLE_PROMO_TO_NON_PROMO = "purchaseCyclePromoToNonPromo";
export const PURCHASE_CYCLE_PROMO_TO_NON_PROMO_COMPARISON =
    "purchaseCyclePromoToNonPromoComparison";
export const PURCHASE_CYCLE_PROMO_TO_PROMO = "purchaseCyclePromoToPromo";
export const PURCHASE_CYCLE_PROMO_TO_PROMO_COMPARISON =
    "purchaseCyclePromoToPromoComparison";
export const PURCHASE_CYCLE_NON_PROMO_TO_NON_PROMO =
    "purchaseCycleNonPromoToNonPromo";
export const PURCHASE_CYCLE_NON_PROMO_TO_NON_PROMO_COMPARISON =
    "purchaseCycleNonPromoToNonPromoComparison";
export const PURCHASE_CYCLE_NON_PROMO_TO_PROMO = "purchaseCycleNonPromoToPromo";
export const PURCHASE_CYCLE_NON_PROMO_TO_PROMO_COMPARISON =
    "purchaseCycleNonPromoToPromoComparison";

// These correspond to PurchaseCycleReportAggregation enum values from the backend
export const AGGREGATE_BY_BRAND = "BRAND";
export const AGGREGATE_BY_PPG = "PPG";
export const AGGREGATE_BY_UPC = "UPC";

export type Aggregate =
    | typeof AGGREGATE_BY_PPG
    | typeof AGGREGATE_BY_BRAND
    | typeof AGGREGATE_BY_UPC;

export const isBrandAggregate = (aggregate: Aggregate): boolean => {
    return aggregate === AGGREGATE_BY_BRAND;
};

export const isPpgAggregate = (aggregate: Aggregate): boolean => {
    return aggregate === AGGREGATE_BY_PPG;
};

export const isUpcAggregate = (aggregate: Aggregate): boolean => {
    return aggregate === AGGREGATE_BY_UPC;
};

export type PurchaseCycleReportFilters = {
    brands: string[];
    comparisonDate: DateFilter;
    date: DateFilter;
    filterType: "purchaseCycle";
    ppgs: any[];
    subcategories: string[];
};

export const purchaseCycleDefaultFilters: PurchaseCycleReportFilters = {
    brands: [],
    comparisonDate: {
        time: COMPARISON_SAME_PERIOD_PRIOR_YEAR
    },
    date: {
        time: LAST_365_DAYS
    },
    filterType: PURCHASE_CYCLE,
    ppgs: [],
    subcategories: []
};

// Report data table types
export type PurchaseCycleReportDataTableState = {
    aggregates: Aggregate[];
    comparisonEndDate: Date | null;
    comparisonStartDate: Date | null;
    endDate: Date | null;
    filters: PurchaseCycleReportFilters | undefined | null;
    search: string;
    sortBy: string;
    sortOrder: SortOrder;
    startDate: Date | null;
    totalCount: number;
};

export const defaultPurchaseCycleDataTableState: PurchaseCycleReportDataTableState = {
    aggregates: [AGGREGATE_BY_PPG],
    comparisonEndDate: null,
    comparisonStartDate: null,
    endDate: null,
    filters: purchaseCycleDefaultFilters,
    search: "",
    sortBy: PRODUCT_GROUPS,
    sortOrder: ASCENDING,
    startDate: null,
    totalCount: -1
};

export const reducePurchaseCycleSavedReport = (
    savedReport: SavedReport,
    requests: any[],
    uiState: Dictionary<string>,
    state: ReportsState
) => {
    let reducedState = state;
    const reportView = savedReport.type;
    switch (reportView) {
        case PURCHASE_CYCLE_AND_PROMOTIONS:
            {
                const request = requests.find(request => {
                    return (
                        request.objectType === "PurchaseCycleReportTableRequest"
                    );
                });
                if (request) {
                    const aggregates = [request.aggregation.value];
                    const requestDate = getDateFilter(
                        request.timePeriod.value,
                        request.startDate,
                        request.endDate
                    );
                    const subcategories: string[] = [];
                    request.categories.forEach((category: IcCategory) => {
                        category.subcategories.forEach(
                            (subcategory: IcCategory) => {
                                subcategories.push(
                                    category.name +
                                        CATEGORY_SUBCATEGORY_SEPARATOR +
                                        subcategory.name
                                );
                            }
                        );
                    });
                    const filters = Object.assign(
                        {},
                        defaultPurchaseCycleDataTableState.filters,
                        {
                            brands: request.brands,
                            comparisonDate: {
                                time: request.comparisonTimePeriod.value
                            },
                            date: requestDate,
                            filterType: PURCHASE_CYCLE,
                            ppgs: request.productGroupIds,
                            subcategories: subcategories
                        }
                    );
                    reducedState = update(state, {
                        [PURCHASE_CYCLE]: {
                            [reportView]: {
                                dataTable: {
                                    aggregates: {
                                        $set: aggregates
                                    },
                                    filters: {
                                        $set: filters
                                    },
                                    search: {
                                        $set: request.search
                                    },
                                    sortBy: {
                                        $set: request.sortBy
                                    },
                                    sortOrder: {
                                        $set: request.sortOrder
                                    }
                                },
                                reportStyle: {
                                    $set: DATA_TABLE
                                }
                            }
                        }
                    });
                }
            }
            break;
        default:
            break;
    }
    return Object.assign({}, reducedState, {
        reportType: PURCHASE_CYCLE,
        reportView: { value: reportView }
    });
};
