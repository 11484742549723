import get from "lodash.get";
import moment from "moment";
import type { AnyAction } from "redux";
import { formatMonth } from "common/util/date";
import noAction from "common/util/noAction";
import {
    getEntityIdListWithoutVersion,
    getEntityIdWithoutVersion,
    reduceCreatorModifier,
    reduceObject
} from "common/util/object";
import { reducePromotedPricePpgs } from "compliance/state/complianceStateUtils";
import {
    CLEAR_PPG_GUIDELINE,
    RECEIVE_PPG_GUIDELINE,
    RECEIVE_PPG_GUIDELINE_SEARCH_OPTIONS,
    REQUEST_PPG_GUIDELINE,
    UPDATE_PPG_GUIDELINES_PRINT_OPTIONS
} from "ppgGuidelines/state/ppgGuidelinesActions";
import {
    GUIDELINES_VIEWER_PAGE,
    GUIDELINES_VIEWER_PRINT_PAGE
} from "shell/state/pageActions";

export type GuidelineSupportingMaterialsState = {
    entityId: string;
    filename: string;
    filesize: number;
    objectType: string;
};

export const GuidelineSupportingMaterialsDefaultState: GuidelineSupportingMaterialsState = {
    entityId: "",
    filename: "",
    filesize: -1,
    objectType: "GuidelineSupportingMaterial"
};

export type GuidelineSupportingMaterial = {
    error: boolean;
    id: string;
    name: string;
    size: number;
    file: GuidelineSupportingMaterialsState;
    thumbnail?: string;
    uploading: boolean;
};

export type Timing = {
    endDay: number;
    endMonth: OptionTypeOrNullOrUndefined;
    entityId: string;
    objectType: string;
    startDay: number;
    startMonth: OptionTypeOrNullOrUndefined;
};

export const TimingDefaultState = {
    endDay: 0,
    endMonth: null,
    entityId: "",
    objectType: "GuidelineOptimalTiming",
    startDay: 0,
    startMonth: null
};

type GuidanceComplianceStatus = {
    displayName: string;
    value: string;
};

type GuidanceCompliance = {
    entityId: string;
    message: string;
    status: GuidanceComplianceStatus;
};

export type GuidelineObject = {
    applyToEffectivePTC: boolean;
    bundledProducts: boolean;
    collectionId?: string | null;
    collectionName: string | undefined | null;
    crossPromotionCompliance?: GuidanceCompliance | null;
    crossPromotionConflict?: boolean;
    crossPromotionEntityId: string;
    crossPromotionFrom?: string | null;
    crossPromotionNotes: string;
    crossPromotionsNo: any[];
    crossPromotionsYes: any[];
    conditionalMaxPromotedPrice: number | null;
    conditionalMinPromotedPrice: number | null;
    entityId: string;
    frequency: number;
    frequencyCompliance?: GuidanceCompliance | null;
    frequencyConflict?: boolean;
    frequencyEntityId: string;
    frequencyFrom?: string | null;
    frequencyNotes: string;
    holidays: any[];
    holidaySummary: string;
    keyMessage: string;
    keyMessageConflict?: boolean;
    keyMessageFrom?: string | null;
    maxPromotedPrice: number | null;
    minPromotedPrice: number | null;
    name: string;
    offerStructureCompliance?: GuidanceCompliance | null;
    offerStructureConflict?: boolean;
    offerStructureEntityId: string;
    offerStructureFrom?: string | null;
    offerStructureNotes: string;
    offerStructureNo: any[];
    offerStructureYes: any[];
    products: any[];
    productSummary: string;
    promotedPriceCompliance?: GuidanceCompliance | null;
    promotedPriceEntityId: string;
    promotedPriceEventCount: number | undefined | null;
    promotedPriceNotes: string;
    promotedPricePpgs: any[];
    promotedPriceTimePeriod: any;
    recommendationCompliance?: GuidanceCompliance | null;
    recommendationGuidance?: any | null;
    regions: any[];
    regionSummary: string;
    retailers: any[];
    retailerSummary: string;
    supportingMaterialConflict?: boolean;
    supportingMaterials: GuidelineSupportingMaterial[];
    supportingMaterialsFrom?: string | null;
    timing: Timing[];
    timingCompliance?: GuidanceCompliance | null;
    timingConflict?: boolean;
    timingEntityId: string;
    timingFrom?: string | null;
    timingNotes: string;
    userSelectedRetailers: any[];
    visualNotes: string;
    visualsCompliance?: GuidanceCompliance | null;
    visualsEntityId: string;
    visualsConflict?: boolean;
    visualMaterials: GuidelineSupportingMaterial[];
    visualsFrom?: string | null;
} & CreatorModifierData;

type PPGSearchOptions = {
    bundledProducts: any[] | undefined | null;
    holidays: any[] | undefined | null;
    products: any[] | undefined | null;
    regions: any[] | undefined | null;
    retailers: any[] | undefined | null;
};

export type PPGPrintState = {
    date: Date;
    holiday: string | undefined | null;
    product: string | undefined | null;
    region: string | undefined | null;
    retailer: string | undefined | null;
};

export const defaultPrintState = {
    date: moment().toDate(),
    holiday: null,
    product: null,
    region: null,
    retailer: null
};

type PPGGuidelinesState = {
    ppgGuideline: any | undefined | null;
    searchOptions: PPGSearchOptions;
    printOptions: PPGPrintState;
};

const reduceMonths = (timing: any[]): Timing[] => {
    timing.forEach(interval => {
        interval.startMonth = interval.startMonth
            ? formatMonth(interval.startMonth)
            : null;
        interval.endMonth = interval.endMonth
            ? formatMonth(interval.endMonth)
            : null;
    });
    return timing;
};

export const reduceSupportingMaterials = (
    files: GuidelineSupportingMaterial[]
): GuidelineSupportingMaterialsState[] => {
    const supportingMaterials: GuidelineSupportingMaterialsState[] = [];
    files.forEach(file => {
        if (file.file) {
            supportingMaterials.push(file.file);
        }
    });
    return supportingMaterials;
};

export const buildSupportingMaterials = (
    files: any[]
): GuidelineSupportingMaterial[] => {
    return files.map(file => {
        return {
            error: false,
            id: file.entityId,
            name: file.filename,
            size: file.filesize,
            file: file,
            thumbnail: file.thumbnailLink,
            uploading: false
        };
    });
};

export const defaultPPGGuidelinesState: PPGGuidelinesState = {
    ppgGuideline: null,
    searchOptions: {
        bundledProducts: null,
        holidays: null,
        products: null,
        regions: null,
        retailers: null
    },
    printOptions: defaultPrintState
};

export const reduceBaseGuideline = (guideline: any): GuidelineObject => {
    const creatorModifier = reduceCreatorModifier(guideline);
    return Object.assign(
        {},
        reduceObject(
            guideline,
            "bundledProducts",
            "channels",
            "entityId",
            "holidays",
            "holidaySummary",
            "keyMessage",
            "name",
            "products",
            "productSummary",
            "recommendationGuidance",
            "regions",
            "regionSummary",
            "retailers",
            "retailerSummary",
            "userSelectedRetailers"
        ),
        {
            applyToEffectivePTC: get(
                guideline,
                "promotedPriceGuidance.applyToEffectivePtc"
            ),
            collectionId: get(guideline, "guidelineCollection.entityId"),
            collectionName: get(guideline, "guidelineCollection.name"),
            conditionalMaxPromotedPrice: get(
                guideline,
                "promotedPriceGuidance.conditionalMaxPromotedPrice"
            ),
            conditionalMinPromotedPrice: get(
                guideline,
                "promotedPriceGuidance.conditionalMinPromotedPrice"
            ),
            crossPromotionEntityId: get(guideline, "crossPromotion.entityId"),
            crossPromotionNotes:
                get(guideline, "crossPromotion.notes", "") || "",
            crossPromotionsNo: get(
                guideline,
                "crossPromotion.crossPromotionsNo",
                []
            ),
            crossPromotionsYes: get(
                guideline,
                "crossPromotion.crossPromotionsYes",
                []
            ),
            frequency: get(guideline, "frequency.frequency"),
            frequencyEntityId: get(guideline, "frequency.entityId"),
            frequencyNotes: get(guideline, "frequency.notes", "") || "",
            keyMessage: get(guideline, "keyMessage", ""),
            maxPromotedPrice: get(
                guideline,
                "promotedPriceGuidance.maxPromotedPrice"
            ),
            minPromotedPrice: get(
                guideline,
                "promotedPriceGuidance.minPromotedPrice"
            ),
            offerStructureEntityId: get(guideline, "offerStructure.entityId"),
            offerStructureNo: get(
                guideline,
                "offerStructure.offerStructureNo",
                []
            ),
            offerStructureNotes:
                get(guideline, "offerStructure.notes", "") || "",
            offerStructureYes: get(
                guideline,
                "offerStructure.offerStructureYes",
                []
            ),
            promotedPriceEntityId: get(
                guideline,
                "promotedPriceGuidance.entityId"
            ),
            promotedPriceEventCount: get(
                guideline,
                "promotedPriceGuidance.numOfEventsAllowed"
            ),
            promotedPriceNotes:
                get(guideline, "promotedPriceGuidance.notes", "") || "",
            promotedPricePpgs: reducePromotedPricePpgs(
                guideline.promotedPricePpgs
            ),
            promotedPriceTimePeriod: get(
                guideline,
                "promotedPriceGuidance.promotedPriceTimePeriod"
            ),
            supportingMaterials: buildSupportingMaterials(
                get(guideline, "supportingMaterials", [])
            ),
            timing: reduceMonths(get(guideline, "timing.optimalTiming", [])),
            timingEntityId: get(guideline, "timing.entityId"),
            timingNotes: get(guideline, "timing.notes", "") || "",
            visualsEntityId: get(guideline, "visuals.entityId"),
            visualNotes: get(guideline, "visuals.notes", "") || "",
            visualMaterials: buildSupportingMaterials(
                get(guideline, "visuals.visualMaterials", [])
            )
        },
        creatorModifier
    );
};

const reduceCompositeGuideline = (guideline: any): any => {
    return Object.assign(
        {},
        reduceObject(
            guideline,
            "crossPromotionConflict",
            "crossPromotionFrom",
            "frequencyConflict",
            "frequencyFrom",
            "isComposite",
            "keyMessageConflict",
            "keyMessageFrom",
            "offerStructureConflict",
            "offerStructureFrom",
            "promotedPriceConflict",
            "promotedPriceFrom",
            "supportingMaterialConflict",
            "supportingMaterialsFrom",
            "timingConflict",
            "timingFrom",
            "visualConflict",
            "visualsFrom"
        )
    );
};

export const reducePPGGuidelineResponse = (guideline: any): GuidelineObject => {
    return Object.assign(
        {},
        reduceBaseGuideline(guideline),
        reduceCompositeGuideline(guideline)
    );
};

const reduceSearchOption = (item: NameEntityIdType) => {
    return {
        name: item.name,
        entityId: getEntityIdWithoutVersion(item.entityId)
    };
};

const reduceGuidelineSearchOptions = (
    state: PPGGuidelinesState,
    searchOptions: any
): PPGGuidelinesState => {
    const {
        bundledProducts,
        holidays,
        products,
        regions,
        retailers
    } = searchOptions;
    return {
        ...state,
        searchOptions: {
            ...state.searchOptions,
            bundledProducts: bundledProducts.map((item: any) => ({
                bundleId: getEntityIdListWithoutVersion(item.bundleId),
                name: item.name,
                nameList: item.nameList
            })),
            products: products.map(reduceSearchOption),
            retailers: retailers.map((item: any) => ({
                entityId: getEntityIdWithoutVersion(item.entityId),
                name: item.name,
                retailers: item.retailers.map(reduceSearchOption)
            })),
            holidays: holidays.map(reduceSearchOption),
            regions: regions.map(reduceSearchOption)
        }
    };
};

const ppgGuidelines = (
    state: PPGGuidelinesState = defaultPPGGuidelinesState,
    action: AnyAction = noAction
): PPGGuidelinesState => {
    switch (action.type) {
        case RECEIVE_PPG_GUIDELINE:
            return Object.assign({}, state, {
                ppgGuideline: reducePPGGuidelineResponse(
                    action.guidelineCardResponse
                )
            });
        case RECEIVE_PPG_GUIDELINE_SEARCH_OPTIONS:
            return reduceGuidelineSearchOptions(state, action.searchOptions);
        case CLEAR_PPG_GUIDELINE:
            return Object.assign({}, state, {
                ppgGuideline: null,
                printOptions: defaultPrintState
            });
        case GUIDELINES_VIEWER_PAGE:
            return Object.assign({}, state, {
                ppgGuideline: null
            });
        case GUIDELINES_VIEWER_PRINT_PAGE:
            return Object.assign({}, state, {
                ppgGuideline: null
            });
        case UPDATE_PPG_GUIDELINES_PRINT_OPTIONS:
            return Object.assign({}, state, {
                printOptions: action.printOptions
            });
        case REQUEST_PPG_GUIDELINE:
        default:
            return state;
    }
};

export default ppgGuidelines;
