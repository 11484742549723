import type { AggregateOption } from "common/components/AgAggregationChooser";
import {
    INITIALIZATION_ERROR,
    RECEIVE_INIT,
    REQUEST_INIT
} from "common/shell/state/initActions";
import {
    defaultCommonState,
    reduceCommonInit,
    reduceNode
} from "common/shell/state/initStateUtils";
import type {
    CommonInitState,
    CommonInitMetaDataResponse,
    FilterNode,
    FilterValueNode,
    ReportDef,
    TeamTypeType,
    TenantWithLocale
} from "common/shell/state/initStateUtils";
import { RECEIVE_RAVEN } from "common/shell/state/ravenActions";
import noAction from "common/util/noAction";
import { getEntityIdWithoutVersion, reduceObject } from "common/util/object";
import {
    RECEIVE_HOLIDAYS_REINIT,
    RECEIVE_TENANT_CONFIGURATION
} from "shell/state/initActions";
import type { InitActions } from "shell/state/initActions";

const TRACKING_APPLICATION = "Matrix";

export const CATEGORY_SUBCATEGORY_SEPARATOR = ":::";

export type InitMetaDataResponse = {
    chartColors: string[];
    preloadedEntities: PreloadedEntities;
    referenceColor: string;
    serverUrl: string;
} & CommonInitMetaDataResponse;

export type DescriptionToken = {
    description: string;
    token: string;
};

export type RedemptionMethodType = {
    displayName: string;
    isPhoto: boolean;
    isPrint: boolean;
    requiresRewardMechanism: boolean;
    value: string;
};

export const defaultState = Object.assign({}, defaultCommonState, {
    chartColors: [],
    fullAppUrl: "",
    guidelinesEnabled: false,
    holidaysEnabled: false,
    performanceTierSource: "",
    ppgList: undefined,
    preloadedEntities: {
        filters: {
            guidances: [],
            holidays: [],
            managers: [],
            offerStructures: {},
            products: [],
            performanceTiers: [],
            promotionFilters: [],
            promoTypes: [],
            regions: [],
            reports: {
                consumerTrendBrands: [],
                consumerTrendCategories: [],
                consumerTrendChannels: [],
                consumerTrendProducts: [],
                consumerTrendUpcBrands: [],
                consumerTrendUpcCategories: [],
                consumerTrendUpcProductGroupings: [],
                consumerTrendUpcs: [],
                icAggregateOptions: [],
                offerTypes: [],
                purchaseCycleBrands: [],
                purchaseCycleProducts: [],
                purchaseCycleTimePeriods: [],
                purchaseCycleUpcBrands: [],
                purchaseCycleUpcCategories: [],
                purchaseCycleUpcProductGroupings: [],
                salesDecompRetailers: [],
                substitutionRetailers: [],
                substitutionUpcCategories: [],
                substitutionUpcProductGroupings: [],
                takeRateChannels: [],
                takeRateProducts: []
            },
            retailers: [],
            standardHolidays: []
        },
        metadata: {
            activeGuidelineCollection: undefined,
            facebookPages: [],
            marginViews: [],
            matrix: undefined,
            offerTextSubstitutionTokens: [],
            promotedPriceTimePeriodValues: [],
            redemptionMethods: [],
            reportMaxTakeRatePromotionQuantity: 0,
            reportTableFilterDefinitions: [],
            standardHolidays: [],
            targetRatios: [],
            teamTypes: []
        }
    },
    primaryMetric: "",
    referenceColor: "decf3f",
    replaceDiscountWithPrimaryMetric: "",
    showAnalyticsInPromoApp: false,
    showCampaignInPromoApp: false
});

export type RetailerFilter = {
    displayName: string;
    entityIdWithoutVersion: string;
    objectType: string;
    retailers: FilterNode[];
};

export type ProductFilter = {
    adminProductGroups: FilterNode[];
    displayName: string;
    entityId: string;
    entityIdWithoutVersion: string;
    objectType: string;
    value: string;
};

type MatrixMetaData = {
    hasAnyPromoExecution: boolean;
    hasCPIU: boolean;
    hasExecutionDenominator: boolean;
    hasLift: boolean;
    hasPromoData: boolean;
    hasPromoTypeDetection: boolean;
    hasSalesData: boolean;
    performanceTierFilter: PerformanceTierCategory[];
    promotedPriceTimePeriodValues: PromotedPriceTimePeriod[];
    promoTypeFilter: PromoType[];
};

type MatrixMetaDataType = Omit<
    MatrixMetaData,
    | "performanceTierFilter"
    | "promotedPriceTimePeriodValues"
    | "promoTypeFilter"
>;

type HolidayNodeType = {
    endDate: string;
    startDate: string;
} & FilterNode;

type PreloadedEntitiesState = {
    filters: {
        guidances: DisplayNameObjectValueType[];
        holidays: HolidayNodeType[];
        managers: FilterNode[];
        offerStructures: Dictionary<LabelValueType[]>;
        products: ProductFilter[];
        performanceTiers: PerformanceTierCategory[];
        promotionFilters: ConsumerTrendsReportPromotionFilter[];
        promoTypes: PromoType[];
        regions: FilterNode[];
        retailers: RetailerFilter[];
        standardHolidays: FilterNode[];
        reports: {
            consumerTrendBrands: FilterNode[];
            consumerTrendCategories: TreeFilterNode[];
            consumerTrendChannels: RetailerFilter[];
            consumerTrendProducts: ProductFilter[];
            consumerTrendUpcBrands: FilterNode[];
            consumerTrendUpcCategories: TreeFilterNode[];
            consumerTrendUpcProductGroupings: ProductFilter[];
            consumerTrendUpcs: OptionType[];
            icAggregateOptions: AggregateOption[];
            offerTypes: DisplayNameObjectValueType[];
            purchaseCycleBrands: FilterNode[];
            purchaseCycleProducts: ProductFilter[];
            purchaseCycleTimePeriods: DateMenuItemType[];
            purchaseCycleUpcBrands: FilterNode[];
            purchaseCycleUpcCategories: TreeFilterNode[];
            purchaseCycleUpcProductGroupings: ProductFilter[];
            salesDecompRetailers: OptionType[];
            substitutionRetailers: OptionType[];
            substitutionUpcCategories: TreeFilterNode[];
            substitutionUpcProductGroupings: TreeFilterNode[];
            takeRateChannels: RetailerFilter[];
            takeRateProducts: ProductFilter[];
        };
    };
    metadata: {
        activeGuidelineCollection: GuidelineCollection | undefined;
        facebookPages: FacebookPage[];
        marginViews: DisplayNameValueType[];
        matrix: MatrixMetaDataType | undefined;
        offerTextSubstitutionTokens: DescriptionToken[];
        promotedPriceTimePeriodValues: PromotedPriceTimePeriod[];
        redemptionMethods: RedemptionMethodType[];
        reportMaxTakeRatePromotionQuantity: number;
        reportTableFilterDefinitions: ReportDef[];
        standardHolidays: FilterNode[];
        targetRatios: TargetRatio[];
        teamTypes: TeamTypeType[];
    };
};

type InitState = {
    chartColors: string[];
    fullAppUrl: string;
    guidelinesEnabled: boolean;
    holidaysEnabled: boolean;
    performanceTierSource: string;
    ppgList: FilterValueNode[] | undefined;
    preloadedEntities: PreloadedEntitiesState;
    primaryMetric: string;
    referenceColor: string;
    replaceDiscountWithPrimaryMetric: string;
    showAnalyticsInPromoApp: boolean;
    showCampaignInPromoApp: boolean;
} & CommonInitState;

type PreloadedEntities = {
    ConsumerTrendsReportAggregation: ConsumerTrendsReportAggregation[];
    ConsumerTrendsReportPromotionFilter: ConsumerTrendsReportPromotionFilter[];
    CurrentGuidelineCollection: GuidelineCollection[];
    GuidanceFilter: GuidanceTypeDefinition[];
    FacebookPage: FacebookPage[];
    Holiday: Holiday[];
    IcCategory: IcCategory[];
    IcUpcCategory: IcCategory[];
    ManagerFilter: ProductRetailerManager[];
    MarginView: DisplayNameValueType[];
    MatrixMetaData: MatrixMetaData[];
    OfferStructureFilter: OfferTemplate[];
    OfferTextSubstitutionToken: DescriptionToken[];
    OfferType: Enum[];
    ProductFilter: ProductGroupingDefinition[];
    RedemptionMethod: RedemptionMethodType[];
    Region: Region[];
    ReportMetaData: ReportMetaData[];
    ReportTableFilterDefinition: ReportTableFilterDefinition[];
    RetailerFilter: Channel[];
    StandardHoliday: StandardHoliday[];
    TargetRatio: TargetRatio[];
    TeamType: TeamType[];
};

const reduceFilterNode = (
    obj: GuidanceTypeDefinition | Enum
): DisplayNameObjectValueType => {
    return {
        displayName: obj.displayName,
        objectType: obj.objectType,
        value: obj.value || obj.displayName
    };
};

const reduceRetailers = (channels: Channel[]): RetailerFilter[] => {
    return channels.map(reduceChannel);
};

const reduceChannel = (channel: Channel): RetailerFilter => {
    return {
        displayName: channel.displayName.value,
        entityIdWithoutVersion: getEntityIdWithoutVersion(channel.entityId),
        objectType: channel.objectType,
        retailers: channel.retailers.map(
            reduceNode.bind(null, "displayName.value")
        )
    };
};

const reduceICChannels = (
    channels: ReportMetaDataChannel[],
    filterField?: keyof ReportMetaDataChannel
): RetailerFilter[] => {
    let filteredChannels = channels;
    if (filterField) {
        filteredChannels = filteredChannels.filter(
            channel => channel?.[filterField]
        );
    }

    return filteredChannels.map(channel => {
        return {
            displayName: channel.channel.displayName.value,
            entityIdWithoutVersion: getEntityIdWithoutVersion(
                channel.channel.entityId
            ),
            objectType: channel.objectType,
            retailers: []
        };
    });
};

export const reduceProducts = (
    productGroupingDefinition: ProductGroupingDefinition[]
): ProductFilter[] => {
    return productGroupingDefinition.map(reduceProductGroupingDefinition);
};

const reduceProductGroupingDefinition = (
    productGroupingDefinition: ProductGroupingDefinition
): ProductFilter => {
    return {
        displayName: productGroupingDefinition.displayName,
        // 2 entityIds are only used for typing
        entityId: productGroupingDefinition.displayName,
        entityIdWithoutVersion: productGroupingDefinition.displayName,
        value: productGroupingDefinition.displayName,
        objectType: productGroupingDefinition.objectType,
        adminProductGroups: productGroupingDefinition.productGroups.map(
            reduceNode.bind(null, "name")
        )
    };
};

const reduceICBrands = (
    brands: ReportMetaDataBrand[],
    filterField: keyof ReportMetaDataBrand
): FilterNode[] => {
    return brands
        .filter(brand => brand[filterField])
        .map(reduceNode.bind(null, "brand"));
};

const reduceICProducts = (
    productGroupingDefinition: ReportMetaDataProductGrouping[],
    filterField?: keyof ReportMetaDataProductGroup
): ProductFilter[] => {
    return productGroupingDefinition
        .map(productGrouping =>
            reduceICProductGroupingDefinition(productGrouping, filterField)
        )
        .filter(
            productGrouping => productGrouping.adminProductGroups.length > 0
        );
};

const reduceICProductGroupingDefinition = (
    productGroupingDefinition: ReportMetaDataProductGrouping,
    filterField?: keyof ReportMetaDataProductGroup
): ProductFilter => {
    let productGroups = productGroupingDefinition.productGroups;
    if (filterField) {
        productGroups = productGroups.filter(
            productGroup => productGroup[filterField]
        );
    }

    return {
        displayName: productGroupingDefinition.displayName,
        // 2 entityIds are only used for typing
        entityId: productGroupingDefinition.displayName,
        entityIdWithoutVersion: productGroupingDefinition.displayName,
        value: productGroupingDefinition.displayName,
        objectType: productGroupingDefinition.objectType,
        adminProductGroups: productGroups.map(productGroup => {
            return reduceNode("name", productGroup.productGroup);
        })
    };
};

export const reduceICUpc = (
    upcs: ReportMetaDataUpc[],
    filterField: keyof ReportMetaDataUpc
): OptionType[] => {
    return upcs
        .filter(upc => upc[filterField])
        .map(upc => {
            return {
                label: upc.upcDescription,
                value: upc.upc
            };
        });
};

const reduceICRetailers = (
    retailers: ReportMetaDataRetailer[],
    filterField: keyof ReportMetaDataRetailer
) => {
    return retailers
        .filter(retailer => retailer[filterField])
        .map(retailer => {
            return {
                label: retailer.retailer,
                value: retailer.retailer
            };
        });
};

const reduceMatrixMetadata = (obj: MatrixMetaData): MatrixMetaDataType => {
    return {
        hasAnyPromoExecution: obj.hasAnyPromoExecution,
        hasCPIU: obj.hasCPIU,
        hasExecutionDenominator: obj.hasExecutionDenominator,
        hasLift: obj.hasLift,
        hasPromoData: obj.hasPromoData,
        hasPromoTypeDetection: obj.hasPromoTypeDetection,
        hasSalesData: obj.hasSalesData
    };
};

export const reduceHolidayNode = (
    name: string,
    obj: Holiday
): HolidayNodeType => {
    const node = reduceNode("name", obj);
    return {
        ...node,
        startDate: obj.currentYearStartDate,
        endDate: obj.currentYearEndDate
    };
};

const reduceOfferStructures = (
    offerStructureFilter: OfferTemplate[]
): Dictionary<LabelValueType[]> => {
    const offerStructures: Dictionary<LabelValueType[]> = {};
    offerStructureFilter.forEach(offerStructure => {
        const { displayName, entityId, offerTypeDisplayName } = offerStructure;
        const structures = offerStructures[offerTypeDisplayName] || [];
        structures.push({
            label: displayName,
            value: entityId
        });
        offerStructures[offerTypeDisplayName] = structures;
    });
    return offerStructures;
};

const reduceTenantConfiguration = (
    tenantConfiguration: TenantConfiguration,
    state: InitState
): { tenant: TenantWithLocale } => {
    return {
        tenant: {
            ...state.tenant,
            tenantConfigurationEntityId: tenantConfiguration.entityId
        }
    };
};

type ReportMetaDataCategory = {
    enabledForConsumerTrendsReport: boolean;
    enabledForPurchaseCycleReport: boolean;
    enabledForSubstitutionReport: boolean;
};

export type TreeFilterNode = {
    children: TreeFilterNode[];
    displayName: string;
    label: string;
    objectType: string;
    value: string;
};

const reduceICCategory = (category: IcCategory, parent: string) => {
    let value = category.name;
    if (parent) {
        value = parent + CATEGORY_SUBCATEGORY_SEPARATOR + category.name;
    }
    return {
        children: [],
        displayName: category.name,
        label: category.name,
        objectType: category.objectType,
        value: value
    };
};

const reduceICCategories = (
    categories: IcCategory[],
    filterField: keyof ReportMetaDataCategory
) => {
    const reducedCategories: TreeFilterNode[] = [];
    categories.forEach((category: IcCategory) => {
        if (category[filterField]) {
            const reducedCategory = reduceICCategory(category, "");
            let subcategories = [];
            subcategories = category.subcategories.map(subCategory => {
                return reduceICCategory(subCategory, category.name);
            });
            reducedCategories.push(
                Object.assign({}, reducedCategory, {
                    children: subcategories
                })
            );
        }
    });
    return reducedCategories;
};

const reducePreloadedEntities = (
    preloadedEntities: PreloadedEntities
): PreloadedEntitiesState => {
    const teamTypes = (preloadedEntities.TeamType ?? []).map(teamType => ({
        canAccessAllInstances: teamType.canAccessAllInstances,
        description: teamType.description?.value ?? "",
        entityId: teamType.entityId,
        name: teamType.name
    }));
    return {
        filters: {
            guidances: preloadedEntities.GuidanceFilter.map(reduceFilterNode),
            holidays: preloadedEntities.Holiday.map(
                reduceHolidayNode.bind(null, "name")
            ),
            managers: preloadedEntities.ManagerFilter.map(
                reduceNode.bind(null, "name")
            ),
            offerStructures: reduceOfferStructures(
                preloadedEntities.OfferStructureFilter
            ),
            products: reduceProducts(preloadedEntities.ProductFilter),
            performanceTiers:
                preloadedEntities.MatrixMetaData?.[0].performanceTierFilter ??
                [],
            promotionFilters:
                preloadedEntities.ConsumerTrendsReportPromotionFilter ?? [],
            promoTypes:
                preloadedEntities.MatrixMetaData?.[0].promoTypeFilter ?? [],
            regions: preloadedEntities.Region.map(
                reduceNode.bind(null, "name")
            ),
            reports: {
                consumerTrendBrands: reduceICBrands(
                    preloadedEntities.ReportMetaData?.[0]?.brands ?? [],
                    "enabledForConsumerTrendsReport"
                ),
                consumerTrendCategories: reduceICCategories(
                    preloadedEntities.IcCategory,
                    "enabledForConsumerTrendsReport"
                ),
                consumerTrendChannels: reduceICChannels(
                    preloadedEntities.ReportMetaData?.[0]?.channels ?? [],
                    "enabledForConsumerTrendsReport"
                ),
                consumerTrendProducts: reduceICProducts(
                    preloadedEntities.ReportMetaData?.[0]?.productGroupings ??
                        [],
                    "enabledForConsumerTrendsReport"
                ),
                consumerTrendUpcBrands: reduceICBrands(
                    preloadedEntities.ReportMetaData?.[0]?.upcBrands ?? [],
                    "enabledForConsumerTrendsReport"
                ),
                consumerTrendUpcCategories: reduceICCategories(
                    preloadedEntities.IcUpcCategory,
                    "enabledForConsumerTrendsReport"
                ),
                consumerTrendUpcProductGroupings: reduceICProducts(
                    preloadedEntities.ReportMetaData?.[0]
                        ?.upcProductGroupings ?? [],
                    "enabledForConsumerTrendsReport"
                ),
                consumerTrendUpcs: reduceICUpc(
                    preloadedEntities.ReportMetaData?.[0]?.upcs ?? [],
                    "enabledForConsumerTrendsReport"
                ),
                icAggregateOptions: preloadedEntities.ConsumerTrendsReportAggregation.map(
                    x => {
                        return {
                            id: x.value,
                            displayName: x.displayName
                        };
                    }
                ),
                offerTypes: preloadedEntities.ReportMetaData?.[0]?.offerTypes.map(
                    reduceFilterNode
                ),
                purchaseCycleBrands: reduceICBrands(
                    preloadedEntities.ReportMetaData?.[0]?.brands ?? [],
                    "enabledForPurchaseCycleReport"
                ),
                purchaseCycleProducts: reduceICProducts(
                    preloadedEntities.ReportMetaData?.[0]?.productGroupings ??
                        [],
                    "enabledForPurchaseCycleReport"
                ),
                purchaseCycleTimePeriods: (
                    preloadedEntities.ReportMetaData?.[0]
                        ?.purchaseCycleReportTimePeriods ?? []
                ).map((time: GenericEnum) => ({
                    id: time.value,
                    label: time.displayName
                })),
                purchaseCycleUpcBrands: reduceICBrands(
                    preloadedEntities.ReportMetaData?.[0]?.upcBrands ?? [],
                    "enabledForPurchaseCycleReport"
                ),
                purchaseCycleUpcCategories: reduceICCategories(
                    preloadedEntities.IcUpcCategory,
                    "enabledForPurchaseCycleReport"
                ),
                purchaseCycleUpcProductGroupings: reduceICProducts(
                    preloadedEntities.ReportMetaData?.[0]
                        ?.upcProductGroupings ?? [],
                    "enabledForPurchaseCycleReport"
                ),
                salesDecompRetailers: reduceICRetailers(
                    preloadedEntities.ReportMetaData?.[0]?.retailers ?? [],
                    "enabledForSalesDecompReport"
                ),
                substitutionRetailers: reduceICRetailers(
                    preloadedEntities.ReportMetaData?.[0]?.retailers ?? [],
                    "enabledForSubstitutionReport"
                ),
                substitutionUpcCategories: reduceICCategories(
                    preloadedEntities.IcUpcCategory,
                    "enabledForSubstitutionReport"
                ),
                substitutionUpcProductGroupings: reduceICProducts(
                    preloadedEntities.ReportMetaData?.[0]
                        ?.upcProductGroupings ?? [],
                    "enabledForSubstitutionReport"
                ).map(productGrouping => ({
                    children: productGrouping.adminProductGroups.map(
                        productGroup => ({
                            children: [],
                            displayName: productGroup.displayName,
                            label: productGroup.displayName,
                            objectType: productGroup.objectType,
                            value: productGroup.entityIdWithoutVersion
                        })
                    ),
                    displayName: productGrouping.displayName,
                    label: productGrouping.displayName,
                    objectType: productGrouping.objectType,
                    value: productGrouping.value
                })),
                takeRateChannels: reduceICChannels(
                    preloadedEntities.ReportMetaData?.[0]?.channels ?? [],
                    "enabledForTakeRateReport"
                ),
                takeRateProducts: reduceICProducts(
                    preloadedEntities.ReportMetaData?.[0]?.productGroupings ??
                        [],
                    "enabledForTakeRateReport"
                )
            },
            retailers: reduceRetailers(preloadedEntities.RetailerFilter),
            standardHolidays: preloadedEntities.StandardHoliday.map(
                reduceNode.bind(null, "name")
            )
        },
        metadata: {
            activeGuidelineCollection:
                preloadedEntities.CurrentGuidelineCollection?.[0],
            facebookPages: preloadedEntities.FacebookPage ?? [],
            marginViews: preloadedEntities.MarginView ?? [],
            matrix: reduceMatrixMetadata(preloadedEntities.MatrixMetaData?.[0]),
            offerTextSubstitutionTokens: (
                preloadedEntities.OfferTextSubstitutionToken ?? []
            ).map(substitutionToken => ({
                description: substitutionToken.description,
                token: substitutionToken.token
            })),
            promotedPriceTimePeriodValues:
                preloadedEntities.MatrixMetaData?.[0]
                    .promotedPriceTimePeriodValues ?? [],
            redemptionMethods: preloadedEntities.RedemptionMethod.map(
                redemptionMethod => ({
                    displayName: redemptionMethod.displayName,
                    isPhoto: redemptionMethod.isPhoto,
                    isPrint: redemptionMethod.isPrint,
                    requiresRewardMechanism:
                        redemptionMethod.requiresRewardMechanism,
                    value: redemptionMethod.value
                })
            ),
            reportMaxTakeRatePromotionQuantity:
                preloadedEntities.ReportMetaData?.[0]
                    ?.maxTakeRatePromotionQuantity ?? 0,
            reportTableFilterDefinitions: (
                preloadedEntities.ReportTableFilterDefinition ?? []
            ).map(tableFilter => ({
                group: tableFilter.group,
                groupLabel: tableFilter.groupLabel,
                label: tableFilter.label,
                subLabel: tableFilter.subLabel,
                supportsStaticDates: tableFilter.supportsStaticDates,
                value: tableFilter.type
            })),
            standardHolidays: preloadedEntities.StandardHoliday.map(
                reduceNode.bind(null, "name")
            ),
            targetRatios: preloadedEntities.TargetRatio ?? [],
            teamTypes: teamTypes
        }
    };
};

// HACKY METRIC SWAP MAY REMOVE LATER
// These consts are duplicated/copied from primaryMetricUtils because we can't import that file here
const DISCOUNT_PM = "DISCOUNT";
const EFFECTIVE_DISCOUNT_PM = "EFFECTIVE_DISCOUNT";
const EFFECTIVE_PRICE_TO_CONSUMER_PM = "EFFECTIVE_PRICE_TO_CONSUMER";
const UNIT_PRICE_TO_CONSUMER_PM = "UNIT_PRICE_TO_CONSUMER";
const primaryMetricSwap = (primaryMetric: { value: string }): string => {
    if (!primaryMetric) {
        return "";
    }
    switch (primaryMetric.value) {
        case EFFECTIVE_DISCOUNT_PM:
            return DISCOUNT_PM;
        case EFFECTIVE_PRICE_TO_CONSUMER_PM:
            return UNIT_PRICE_TO_CONSUMER_PM;
        default:
            return primaryMetric.value;
    }
};

const reduceHolidays = (
    init: InitMetaDataResponse,
    state: InitState
): { preloadedEntities: PreloadedEntitiesState } => {
    const holidays = init.preloadedEntities.Holiday ?? [];
    return {
        preloadedEntities: {
            ...state.preloadedEntities,
            filters: {
                ...(state.preloadedEntities.filters ?? {}),
                holidays: holidays.map(reduceHolidayNode.bind(null, "name"))
            }
        }
    };
};

export const reduceInit = (json: InitMetaDataResponse): InitState => {
    const commonInit = reduceCommonInit(json, TRACKING_APPLICATION);
    const tenantConfig = Object.assign(
        {},
        commonInit.tenant.tenantConfiguration
    );

    return {
        ...commonInit,
        chartColors: json.chartColors,
        fullAppUrl: json.serverUrl,
        guidelinesEnabled: tenantConfig.guidelinesEnabled,
        holidaysEnabled: tenantConfig.holidaysEnabled,
        performanceTierSource: Object.assign(
            {},
            tenantConfig.performanceTierMetric
        ).value,
        ppgList: undefined,
        preloadedEntities: reducePreloadedEntities(json.preloadedEntities),
        primaryMetric: primaryMetricSwap(
            tenantConfig.replaceDiscountWithPrimaryMetric
        ),
        referenceColor: json.referenceColor,
        replaceDiscountWithPrimaryMetric:
            tenantConfig.replaceDiscountWithPrimaryMetric.value,
        showAnalyticsInPromoApp: tenantConfig.showAnalyticsInPromoApp,
        showCampaignInPromoApp: tenantConfig.showCampaignInPromoApp
    };
};

const initReducer = (
    state: InitState = defaultState,
    action: InitActions = noAction
): InitState => {
    switch (action.type) {
        case REQUEST_INIT:
            return defaultState;
        case RECEIVE_INIT:
            return Object.assign({}, state, reduceInit(action.init));
        case INITIALIZATION_ERROR:
            return Object.assign({}, state, {
                error: true
            });
        case RECEIVE_RAVEN:
            return Object.assign(
                {},
                state,
                reduceObject(action.raven, "serverMode")
            );
        case RECEIVE_HOLIDAYS_REINIT:
            return Object.assign({}, state, reduceHolidays(action.init, state));
        case RECEIVE_TENANT_CONFIGURATION:
            return Object.assign(
                {},
                state,
                reduceTenantConfiguration(action.tenantConfiguration, state)
            );
        default:
            return state;
    }
};

export default initReducer;
