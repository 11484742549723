import { reduceCreatorModifier } from "common/util/object";
import { ASCENDING, DEFAULT_PAGE_SIZE } from "common/util/query";

export type DownloadsQueryResult = {
    result: Download[];
} & ListQueryResult;

type Download = {
    description: string;
    entityId: string;
    fileName: string;
    fileSizeFormatBytes: string;
    objectType: string;
} & CreatorModifierData;

export type DownloadState = {
    fileSize: string;
} & Omit<Download, "fileSizeFormatBytes">;

export type DownloadsState = {
    maxResults: number;
    search: string;
    sortBy: string;
    sortOrder: SortOrder;
    startIndex: number;
    totalCount: number;
};

export const defaultState: DownloadsState = {
    maxResults: DEFAULT_PAGE_SIZE,
    search: "",
    sortBy: "fileName",
    sortOrder: ASCENDING,
    startIndex: 0,
    totalCount: -1
};

export const reduceDownloads = (
    state: DownloadsState,
    obj: DownloadsQueryResult
): DownloadsState => {
    return {
        maxResults: obj.query.maxResults,
        search: state.search,
        sortBy: state.sortBy,
        sortOrder: state.sortOrder,
        startIndex: obj.query.startIndex,
        totalCount: obj.totalCount
    };
};

export const reduceDownload = (data: Download): DownloadState => {
    const creatorModifier = reduceCreatorModifier(data);
    return {
        description: data.description,
        entityId: data.entityId,
        fileName: data.fileName,
        fileSize: data.fileSizeFormatBytes,
        objectType: data.objectType,
        ...creatorModifier
    };
};
